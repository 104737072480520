import PropTypes from 'prop-types';

export const navigation = ({ ...rest }) =>
  PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
    navigate: PropTypes.func,
    replace: PropTypes.func,
    popToTop: PropTypes.func,
    setParams: PropTypes.func,
    addListener: PropTypes.func,
    ...rest,
  });
export const route = ({ ...rest }) =>
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    params: PropTypes.object,
    ...rest,
  });

export const theme = ({ ...rest }) =>
  PropTypes.shape({
    key: PropTypes.string,
    primary: PropTypes.string,
    light: PropTypes.string,
    lighter: PropTypes.string,
    dark: PropTypes.string,
    darker: PropTypes.string,
    translucentHighlight: PropTypes.string,
    fontColor: PropTypes.string,
    ...rest,
  });

export const group = ({ ...rest }) =>
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    class: PropTypes.string,
    privacy: PropTypes.string,
    avatar_url: PropTypes.string,
    splash_url: PropTypes.string,
    description: PropTypes.string,
    guidelines: PropTypes.string,
    guidelines_updated_at: PropTypes.string,
    application_pending: PropTypes.bool,
    join_request_pending: PropTypes.bool,
    ...rest,
  });

export const group_member = ({ ...rest }) =>
  PropTypes.shape({
    group_id: PropTypes.string,
    persona_id: PropTypes.string,
    role_id: PropTypes.string,
    role_name: PropTypes.string,
    ...rest,
  });

export const group_invitation = ({ ...rest }) =>
  PropTypes.shape({
    invite_token: PropTypes.string,
    persona_id: PropTypes.string,
    group_id: PropTypes.string,
    role_id: PropTypes.string,
    role_name: PropTypes.string,
    inviter_id: PropTypes.string,
    inviter: PropTypes.object,
    ...rest,
  });

export const group_invitations = ({ ...rest }) =>
  PropTypes.arrayOf(
    PropTypes.shape({
      invite_token: PropTypes.string,
      persona_id: PropTypes.string,
      group_id: PropTypes.string,
      role_id: PropTypes.string,
      role_name: PropTypes.string,
      inviter_id: PropTypes.string,
      inviter: PropTypes.object,
      ...rest,
    }),
  );

export const issue = ({ ...rest }) =>
  PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    action_taken: PropTypes.string,
    explanation: PropTypes.string,
    type: PropTypes.string,
    activity: PropTypes.object,
    original_action: PropTypes.string,
    reasons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        body: PropTypes.string,
      }),
    ),
    moderator: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    reportee: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      avatar_url: PropTypes.string,
    }),
    reporter: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      avatar_url: PropTypes.string,
    }),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    report_time: PropTypes.string,
    category: PropTypes.string,
    ...rest,
  });

export const persona = ({ ...rest }) =>
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    handle: PropTypes.string,
    avatar_url: PropTypes.string,
    splash_url: PropTypes.string,
    role_id: PropTypes.string,
    role_name: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    ...rest,
  });

export const activity = ({ ...rest }) =>
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string,
    origin_group: group().isRequired,
    origin_group_member: group_member(),
    user: persona().isRequired,
    linkPreview: PropTypes.object,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    removed: PropTypes.bool,
    can_participate: PropTypes.bool.isRequired,
    frozen: PropTypes.bool,
    is_frozen: PropTypes.bool.isRequired,
    own_like: PropTypes.bool.isRequired,
    reaction_counts: PropTypes.shape({
      comment: PropTypes.number,
      like: PropTypes.number,
    }),
    ...rest,
  });

export const comment = ({ ...rest }) =>
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    activity_id: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    user: persona().isRequired,
    data: reaction().isRequired,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    own_like: PropTypes.bool.isRequired,
    children_count: PropTypes.shape({
      comment: PropTypes.number,
      like: PropTypes.number,
    }),
    latest_children: PropTypes.shape({
      comment: PropTypes.array,
      comment_like: PropTypes.array,
      comment_reply: PropTypes.array,
    }),
    ...rest,
  });

export const reaction = ({ ...rest }) =>
  PropTypes.shape({
    object: PropTypes.string,
    group_id: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    comment_image: PropTypes.string,
    removed: PropTypes.bool,
    ...rest,
  });

export const ad = ({ ...rest }) =>
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    image_urls: PropTypes.arrayOf(PropTypes.string),
    advertiser: PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar_url: PropTypes.string.isRequired,
    }).isRequired,
    link_url: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    ...rest,
  });

export const setting = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  value_label: PropTypes.string,
});
