export const HOMESCREEN_TAG = `HOMESCREEN`;
export const MODERATION_DRAWER = `MODERATION_DRAWER`;
export const COMMUNITY_FEED_TITLE = `COMMUNITY_FEED_TITLE`;
export const COMMUNITY_JOIN = `COMMUNITY_JOIN`;
export const COC_AGREE = `COC_AGREE`;
export const SAVE_AND_JOIN = `SAVE_AND_JOIN`;
export const COMMUNITY_DOTS = `COMMUNITY_DOTS`;
export const COMMUNITY_FEED_SCROLLVIEW = `COMMUNITY_FEED_SCROLLVIEW`;
export const COMMUNITY_FEED_ACTIVITY = `COMMUNITY_FEED_ACTIVITY`;
export const COMMUNITY_TIP_JAR = `COMMUNITY_TIP_JAR`;
export const AGE_RESTRICTION_OK = `AGE_RESTRICTION_OK`;
