import { PropTypes } from 'prop-types';
import React, { useState, useCallback } from 'react';
import { Platform, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Colors, Typography } from '../common-styles';
import {
  BottomSheetModal,
  KeyboardPaddingView,
  MeshIcon,
  NoThemeButton,
  SafeAreaView,
  ThemedButton,
  ThemedDeleteButton,
} from '../common-ui';
import { TYPES } from './helpers';
import { commonPropTypes } from '../common-util';

import { saveQuestion, editQuestion, removeQuestion } from '../redux/applicationSlice';

/**
 * TYPES:
 * - [4] Multiple choice
 * - [5] Checkboxes
 * - [6] Written answer
 */

const newTypes = [TYPES[4], TYPES[5], TYPES[6]];

export const EditCommunityApplicationQuestion = React.memo(({ navigation, route }) => {
  const { isEditingQuestion, question: current_question, defaultQuestion } = route.params;
  const { type: edit_type, options: edit_options } = current_question || {};
  const dispatch = useDispatch();
  const [question, setQuestion] = useState(isEditingQuestion ? current_question : defaultQuestion);
  const [questionTypeModal, setQuestionTypeModal] = useState(false);
  const [selectedType, setSelectedType] = useState(isEditingQuestion ? edit_type : defaultQuestion?.type);

  const [showType, setShowType] = useState(question.type.name);
  const [options, setOptions] = useState(edit_options ?? []);

  React.useEffect(() => {
    const hasInvalidOptions = !options?.length || options.some((option) => option.value.trim() === ``);
    const disabled = !question?.key?.trim()?.length || (showType !== `Written answer` && hasInvalidOptions);
    navigation.setOptions({
      headerTitle: isEditingQuestion ? `Edit question` : `Add question`,
      headerLeft: () => (
        <NoThemeButton
          clear
          title="Cancel"
          color={Colors.iconColor}
          onPress={() => navigation.goBack()}
          containerStyle={{ left: 10 }}
        />
      ),
      headerRight: () => (
        <ThemedButton clear title="Done" onPress={() => onSave()} containerStyle={{ right: 10 }} disabled={disabled} />
      ),
    });
  }, [isEditingQuestion, navigation, onSave, options, question?.key, showType]);

  const onSave = useCallback(() => {
    const { questionIndex } = route.params;
    const newQuestionItem = {
      key: question.key,
      type: question.type,
    };
    if (question.type.name !== `Written answer`) newQuestionItem.options = options;
    if (isEditingQuestion) {
      dispatch(editQuestion({ newQuestionItem, questionIndex }));
    } else {
      dispatch(saveQuestion(newQuestionItem));
    }
    navigation.goBack();
  }, [dispatch, isEditingQuestion, navigation, options, question.key, question.type, route.params]);

  const deleteOption = (index) => {
    const filteredItems = options.filter((opt, ind) => ind !== index);
    setOptions(filteredItems);
  };

  const onChangeQuestionKey = (text) => {
    setQuestion((prev) => ({ ...prev, key: text }));
  };

  const onChangeOptionText = (text, index) => {
    options[index] = { id: index, value: text };
    setOptions([...options]);
  };
  const onChangeQuestionType = () => {
    const { id, name } = selectedType;
    setShowType(name);
    setQuestionTypeModal(false);
    setQuestion((prev) => ({ ...prev, type: { id, name } }));
    if (name === `Written answer`) setOptions([]);
  };

  const addNewOption = () => {
    setOptions((prev) => [...prev, { id: prev.length, value: `` }]);
  };

  const removeCurrentQuestion = () => {
    const { isEditingQuestion, questionIndex } = route.params;
    if (isEditingQuestion) {
      dispatch(removeQuestion(questionIndex));
    }
    navigation.goBack();
  };
  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView alwaysBounceVertical={false} keyboardShouldPersistTaps="handled">
          <View style={localStyles.questionContainer}>
            <Text style={{ ...Typography.text(`plustwo`, `gray`), marginBottom: 6 }}>Question</Text>
            <TextInput
              autoFocus
              multiline
              value={(question && question.key) || question.key}
              onChangeText={onChangeQuestionKey}
              style={localStyles.inputStyle}
              textAlignVertical="center"
            />
            <Text style={{ ...Typography.text(`gray`), marginTop: 16, marginBottom: 6 }}>Question Type</Text>
            <TouchableOpacity onPress={() => setQuestionTypeModal(true)} activeOpacity={0.8}>
              <View style={{ ...localStyles.selector }}>
                <Text style={Typography.text(`bold`)}>{(question && question.type && question.type.name) || ``}</Text>
                <MeshIcon name="chevron-down" color={Colors.iconColor} size={18} style={{ marginTop: 1 }} />
              </View>
            </TouchableOpacity>

            {showType && (
              <View>
                <View style={{ marginTop: 16, marginBottom: 6 }}>
                  <Text style={{ color: Colors.textGray }}>Responses</Text>
                </View>
                <OptionList
                  type={showType}
                  options={options}
                  onChangeOptionText={onChangeOptionText}
                  deleteOption={deleteOption}
                />
                <NoThemeButton
                  clear
                  outline
                  rounded={false}
                  color={Colors.gray}
                  title="Add responses"
                  titleStyle={{ ...Typography.text(`theme`, `base`), marginLeft: 8 }}
                  buttonStyle={{ justifyContent: `flex-start`, borderColor: Colors.lightGray }}
                  leftIcon={<MeshIcon name="circle-plus" size={22} color={Colors.brandPurple} />}
                  onPress={addNewOption}
                />
              </View>
            )}

            <ThemedDeleteButton
              rounded
              containerStyle={{ marginHorizontal: 16, marginBottom: 50, marginTop: 20 }}
              buttonStyle={{ height: 40 }}
              title="Delete question"
              onPress={removeCurrentQuestion}
              titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.brandPurple }}
            />
            {questionTypeModal && (
              <BottomSheetModal
                visible={questionTypeModal}
                onPressCancel={() => setQuestionTypeModal(false)}
                showCancelBtn={false}
                title="Question type"
                onPressConfirm={onChangeQuestionType}
              >
                <View style={{ marginTop: 24 }}>
                  {newTypes.map((type) => {
                    return (
                      <View key={type.id}>
                        <View>
                          <TouchableOpacity onPress={() => setSelectedType(type)}>
                            <View style={{ display: `flex`, flexDirection: `row`, marginHorizontal: 16, marginBottom: 36 }}>
                              <MeshIcon name={type.iconName} color={Colors.iconColor} />
                              <View style={{ display: `flex`, flexDirection: `row`, justifyContent: `space-between`, flex: 1 }}>
                                <Text style={{ ...Typography.text(), marginLeft: 10, marginTop: 4 }}>{type.name}</Text>
                                <MeshIcon
                                  name={selectedType.name === type.name ? `circle-check` : `radio-off`}
                                  color={selectedType.name === type.name ? Colors.brandPurple : Colors.iconColor}
                                />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </BottomSheetModal>
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
});

EditCommunityApplicationQuestion.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const OptionList = ({ type, options, onChangeOptionText, deleteOption }) => {
  const optionIcon = type === `Multiple choice` ? `radio-on` : `check-box-selected`;
  const extraStyle = type === `Checkboxes` ? { alignItems: `center` } : undefined;
  return (
    <View>
      {options.map((option, index) => {
        return (
          <View key={option.id} style={{ display: `flex`, flexDirection: `row`, marginBottom: 6, ...extraStyle }}>
            <MeshIcon name={optionIcon} color={Colors.taupe} style={{ marginRight: 8, marginTop: 4 }} />
            <View style={localStyles.inputStyleMulti}>
              <TextInput
                multiline
                autoFocus
                value={option.value}
                style={{ marginTop: 0, flex: 1, fontWeight: `500` }}
                onChangeText={(text) => onChangeOptionText(text, index)}
              />
              <MeshIcon
                name="close"
                color={Colors.iconColor}
                size={18}
                onPress={() => deleteOption(index)}
                style={Platform.OS === `android` ? localStyles.inputMeshIcon : undefined}
              />
            </View>
          </View>
        );
      })}
    </View>
  );
};

OptionList.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ).isRequired,
  onChangeOptionText: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
};

//#region constants and styles
const localStyles = {
  selector: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    borderWidth: 1,
    borderColor: `#D8D8D8`,
    paddingLeft: 5,
    paddingRight: 6,
    borderRadius: 4,
    height: 40,
  },
  questionContainer: {
    flex: 1,
    padding: 16,
  },
  inputStyle: {
    borderRadius: 4,
    borderColor: Colors.dividerColor,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputStyleMulti: {
    flex: 1,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.dividerColor,
    borderWidth: 1,
    height: Platform.OS === `ios` ? `auto` : 40,
    padding: Platform.OS === `ios` ? 10 : 0,
    // padding: 10,
  },
  headerText: {
    ...Typography.text(`large`, `bold`, `black`),
    marginBottom: 10,
  },
  inputMeshIcon: {
    padding: 10,
  },
};
//#endregion
