import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  useWindowDimensions,
  StyleSheet,
  NativeSyntheticEvent,
  NativeScrollEvent,
  ListRenderItem,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { GET_COMMUNITY_CATEGORIES, FOLLOW_CATEGORY, UNFOLLOW_CATEGORY, refetchQueriesFor, SETTING_QUERY } from '../graphql';
import { MeshIcon, ErrorBoundary } from '../common-ui';
import { category_images } from './exploreHelpers';
import { Colors } from '../common-styles';
import { AppContext } from '../../AppContext';
import { Events, PendoTrackEvent } from '../pendo/events';
import { CategoryItem, Setting, SettingOptionData, SettingOptionInput } from '../common-types/types';
import { ExploreNavigatorScreens, MainTabsNavigator } from '../common-types/navigation-types';
import { standarHeroAssetHeight } from '../common-styles/spacing';
import { useAppDispatch } from '../redux/store';
import { toggleMutedByScroll } from '../redux/exploreSlice';

type InterestsNavigator = StackNavigationProp<ExploreNavigatorScreens, `Explore`>;
type InterestListProps = {
  refresh?: () => void;
};

export const InterestList: React.FC<InterestListProps> = ({ children, refresh }) => {
  const flatlistRef = useRef<FlatList>(null);
  const navigation = useNavigation<InterestsNavigator>();
  const dispatch = useAppDispatch();
  const { user, isGuestMode } = React.useContext(AppContext);
  const [interests, setInterests] = useState<string[]>([]);
  const [categories, setCategories] = useState<CategoryItem[]>([]);

  const settingQuery = {
    settingQuery: {
      user_id: user?.id,
      query: `user.setting.interests`,
    },
  };

  const { data: categoriesQuery, loading: loading_categories } = useQuery<SettingOptionData, SettingOptionInput>(
    GET_COMMUNITY_CATEGORIES,
    {
      variables: {
        settingQuery: {
          query: `group.setting.type`,
        },
      },
      fetchPolicy: `cache-and-network`,
      partialRefetch: true,
      notifyOnNetworkStatusChange: true,
      // skip: !user?.id,
    },
  );

  const {
    data: followingQuery,
    loading,
    refetch,
  } = useQuery(SETTING_QUERY, {
    variables: settingQuery,
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    skip: !user?.id || isGuestMode,
  });

  // Effect #1: get user's Interests
  useEffect(() => {
    if (isGuestMode) return;
    const settings: Setting[] = followingQuery?.getSettings ?? [];
    if (settings.length) {
      const [interestSetting] = settings;
      const parsedInterests = JSON.parse((interestSetting.value as string) || `[]`);
      setInterests(parsedInterests);
    }
  }, [isGuestMode, followingQuery?.getSettings]);

  // Effect #2: set categories available
  useEffect(() => {
    const keys = Object.keys(category_images);
    const currentlyAvailableSet = new Set(keys);
    const [settingOptions] = categoriesQuery?.getSettingOptions || [];
    const categories = settingOptions?.options.map((category) => {
      return {
        ...category,
        following: isGuestMode ? false : interests.indexOf(category.value) > -1,
      };
    });
    const filteredCategories = categories ? categories.filter((cat) => currentlyAvailableSet.has(cat.value)) : [];
    setCategories(filteredCategories);
  }, [isGuestMode, categoriesQuery, interests]);

  // Effect #3: set Tab Pressed in order to scroll up
  useEffect(() => {
    const parent = navigation.getParent<MainTabsNavigator>();
    const unsubscribe = parent.addListener(`tabPress`, (_e) => {
      if (flatlistRef?.current) flatlistRef?.current?.scrollToOffset({ animated: false, offset: 0 });
    });
    return unsubscribe;
  }, [navigation, flatlistRef]);

  const windowWidth = useWindowDimensions().width;

  const [follow, _response2] = useMutation(FOLLOW_CATEGORY, {
    refetchQueries: [
      {
        query: SETTING_QUERY,
        variables: settingQuery,
      },
    ],
  });
  const [unfollow, _response3] = useMutation(UNFOLLOW_CATEGORY, {
    refetchQueries: [
      {
        query: SETTING_QUERY,
        variables: settingQuery,
      },
    ],
  });

  const toggleFollow = useCallback(
    async (category) => {
      if (category.following) {
        setInterests(interests.filter((interest) => category.value !== interest));
        await unfollow({
          variables: { category: category.value, user_id: user?.id },
          refetchQueries: refetchQueriesFor(`Setting`),
        });
      } else {
        setInterests([...interests, category.value].sort());
        await follow({
          variables: { category: category.value, user_id: user?.id },
          refetchQueries: refetchQueriesFor(`Setting`),
        });
      }
      await refetch();
    },
    [user?.id, follow, unfollow, refetch, interests],
  );

  const goToCategory = useCallback(
    (category) => {
      PendoTrackEvent(Events.EXPLORE_CATEGORY, {
        category_name: category.value,
        source: `explore`,
      });
      navigation.navigate(`CategoryListView`, { categoryData: category });
    },
    [navigation],
  );

  const renderItem: ListRenderItem<CategoryItem> = useCallback(
    ({ item }) => {
      return (
        <View style={{ backgroundColor: `white` }}>
          <CategoryCard
            key={item.id}
            category={item}
            toggleFollow={toggleFollow}
            goToCategory={goToCategory}
            windowWidth={windowWidth / 2}
          />
        </View>
      );
    },
    [windowWidth, goToCategory, toggleFollow],
  );

  const renderEmptyList = useCallback(() => {
    return (
      <View style={{ flex: 1, justifyContent: `center`, alignItems: `center`, padding: 16 }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }, []);

  const handleOnScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (e.nativeEvent.contentOffset.y > standarHeroAssetHeight) dispatch(toggleMutedByScroll(true));
    else dispatch(toggleMutedByScroll(false));
  };

  return (
    <ErrorBoundary header="A problem occurred displaying this screen.">
      <FlatList<CategoryItem>
        ref={flatlistRef}
        contentContainerStyle={{ paddingTop: 12 }}
        ListHeaderComponent={<View>{children}</View>}
        nestedScrollEnabled={true}
        data={categories}
        renderItem={renderItem}
        ListEmptyComponent={renderEmptyList}
        numColumns={windowWidth > 1000 ? 4 : 2}
        refreshing={loading || loading_categories}
        onRefresh={refresh}
        onScroll={handleOnScroll}
        showsVerticalScrollIndicator={false}
      />
    </ErrorBoundary>
  );
};

interface CategoryCardProps {
  category: CategoryItem;
  windowWidth: number;
  toggleFollow?: (category: CategoryItem) => void;
  goToCategory: (category: CategoryItem) => void;
}

export const CategoryCard: React.FC<CategoryCardProps> = ({ category, windowWidth, toggleFollow = () => null, goToCategory }) => {
  const { isGuestMode } = useContext(AppContext);

  const CategoryImage = category_images[category.value];
  const cardWidth = (windowWidth > 500 ? windowWidth / 2 : windowWidth) - 12;

  const cardheight = (105 / 180) * cardWidth;

  const displayTiles = !isGuestMode || true;
  if (!CategoryImage) return null;

  return (
    <TouchableOpacity
      key={category.id}
      onPress={() => goToCategory(category)}
      style={{ margin: 6, height: cardheight, width: cardWidth }}
    >
      <View style={{ justifyContent: `flex-end`, flexDirection: `column-reverse`, height: cardheight, width: cardWidth }}>
        {displayTiles ? (
          //@ts-ignore RNSvg has no typing definition
          <CategoryImage height={cardheight} width={cardWidth} style={{ top: cardheight / 10 }} />
        ) : (
          <View
            style={[
              localStyles.CategoryContainer,
              {
                width: cardWidth,
                height: cardheight,
              },
            ]}
          >
            <Text>{category.label}</Text>
          </View>
        )}

        {!isGuestMode && !!toggleFollow && (
          <TouchableOpacity hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }} onPress={() => toggleFollow(category)}>
            <MeshIcon
              style={{ position: `absolute`, right: cardWidth / 15 }}
              name="heart"
              color={category.following ? Colors.focusedIconColor : Colors.white}
              size={23}
              focused={category.following}
            />
          </TouchableOpacity>
        )}
      </View>
    </TouchableOpacity>
  );
};

const localStyles = StyleSheet.create({
  CategoryContainer: {
    borderWidth: 1,
    borderColor: Colors.brandGreen,
    elevation: 0,
    backgroundColor: `${Colors.brandGreen}66`,
    borderRadius: 12,
    flexDirection: `column-reverse`,
    padding: 8,
  },
});
