import React, { useState, useCallback } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { BETA_CHANNEL } from '../../../constants';
import { appVersionIsAtLeast, getEnvVariables } from '../../../environment';
import { EditableSettings, Setting, UserAndGroup } from '../../common-types/types';
import {
  SETTING_QUERY,
  UPDATE_SETTING_MUTATION,
  UPDATE_CATEGORY_SETTING_MUTATION,
  client,
  refetchQueriesFor,
} from '../../graphql';

type SettingQuery = {
  settingQuery: {
    user_id?: string | undefined;
    group_id?: string | undefined;
    query: string;
  };
};

type SettingQueryResults = {
  getSettings: Setting[];
};

const exclude_settings = getEnvVariables().envName === `production` ? [`user.setting.reactions`, `user.setting.customer_id`] : [];

export const useSettings = (settingPath: string, userAndGroup: UserAndGroup, from: string = ``) => {
  const [settings, setSettings] = useState<EditableSettings>({});
  const [fullSettings, setFullSettings] = useState<EditableSettings>({});
  const [verified, setVerified] = useState(false);
  const { loading, error, networkStatus, data, refetch } = useQuery<SettingQueryResults, SettingQuery>(SETTING_QUERY, {
    fetchPolicy: `cache-and-network`,
    variables: {
      settingQuery: {
        query: settingPath,
        ...userAndGroup,
      },
    },
    onError: (_error) => {
      console.log(`🚀 ~ onError ~ ${from}}`, {
        settingQuery: {
          query: settingPath,
          ...userAndGroup,
        },
        userAndGroup,
        skip_user: settingPath.startsWith(`user`) && !userAndGroup.user_id,
        skip_group: settingPath.startsWith(`group`) && !userAndGroup.group_id,
      });
    },
    skip:
      (settingPath.startsWith(`user`) && !userAndGroup.user_id) || (settingPath.startsWith(`group`) && !userAndGroup.group_id),
  });

  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries:
      settingPath === `user` ? refetchQueriesFor(`Persona`, `Setting`) : refetchQueriesFor(`Group`, `GroupMember`, `Setting`),
  });

  const [updateCategorySetting] = useMutation(UPDATE_CATEGORY_SETTING_MUTATION, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries: refetchQueriesFor(`Setting`, `Group`),
  });

  const saveSetting = useCallback(
    async (key: string, value: any) => {
      const oldSetting = settings[key] || {};
      if (key === `group.setting.type`) {
        await updateCategorySetting({
          variables: {
            categories: value,
            group_id: oldSetting.group_id,
          },
        });
        return;
      }
      const input = {
        key,
        value,
        id: oldSetting.id,
        user_id: oldSetting.user_id,
        group_id: oldSetting.group_id,
      };
      await updateSetting({ variables: { input } });
    },
    [settings, updateSetting, updateCategorySetting],
  );

  React.useEffect(() => {
    if (error) console.warn(`[GetSettingsError] path=${settingPath}:`, error.message);
    if (data) {
      const visibleSettings: EditableSettings = {};
      const allSettings: EditableSettings = {};
      data.getSettings.forEach((setting: Setting) => {
        allSettings[setting.key] = setting;
        // filter only the ones visible or displayable in settings list
        if ((setting.visibility && !exclude_settings.includes(setting.key)) || setting.key === `group.setting.email_verified`)
          visibleSettings[setting.key] = setting;
        if (setting.key.endsWith(`email_verified`)) {
          setVerified(setting.value === `true`);
        }
        if (!setting.visibility && setting.key.endsWith(`reactions`) && appVersionIsAtLeast(BETA_CHANNEL)) {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          setting.visibility = true;
          visibleSettings[setting.key] = setting;
        }
      });
      setFullSettings(allSettings);
      setSettings(visibleSettings);
    }
  }, [data, error, settingPath]);

  return { settings, fullSettings, saveSetting, error, loading, refetch, networkStatus, verified };
};
