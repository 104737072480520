import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  AccountVerification,
  CreatePassword,
  RegisterEmail,
  RegisterUsername,
  VerifyEmail,
  WelcomeScreen,
  ForgotPassword,
  AccountRecovery,
} from '../auth';
import { EarlyAccess } from '../auth/EarlyAccess';
import { LoginScreen } from '../auth/LoginScreen';
import { TermsScreen } from '../auth/TermsScreen';
import { VerificationScreen } from '../auth/VerificationScreen';
import { Colors } from '../common-styles';
import { AgreeToMeshCoC } from '../profile-create';
import { ProfileAvatar } from '../profile-create/ProfileAvatar';
import { ProfileName } from '../profile-create/ProfileName';
import { ProfileUsername } from '../profile-create/ProfileUsername';
import { ProfileDOB } from '../profile-create/ProfileDOB';
import { ProfilePronouns } from '../profile-create/ProfilePronouns';
import { ProfileInterests } from '../profile-create/ProfileInterests';

import { BackButton } from '../common-ui';
import { headerStyle } from '../common-styles/globalStylesheet';
import MeshHorizontalSVG from '../../assets/images/mesh-horizontal.svg';
import MeshLogo from '../../assets/images/mesh-icon-transparent.svg';
import { ReferralScreen } from '../auth/ReferralScreen';
import { ViewLocalStorage } from '../debug/ViewLocalStorage';

//TODO: move AuthStack to typescript

export const authScreenOptions = ({ _route }) => ({
  contentStyle: { backgroundColor: Colors.white },
  headerBackVisible: false,
  headerTitleAlign: `center`,
  headerTitleStyle: { color: `white` },
  headerBackTitle: null,
  gestureEnabled: false,
  headerShown: true,
  headerLeft: () => <BackButton />,
  headerTitle: () => <MeshHorizontalSVG width={64} height={27} />,
  headerRight: () => null,
  headerStyle,
});

export const AuthNavigator = () => {
  const AuthStack = createNativeStackNavigator();
  return (
    <AuthStack.Navigator screenOptions={authScreenOptions} initialRouteName="Welcome">
      <AuthStack.Screen name="Welcome" component={WelcomeScreen} options={{ headerShown: false }} />
      <AuthStack.Screen name="Login" component={LoginScreen} />
      <AuthStack.Screen name="EarlyAccess" component={EarlyAccess} />

      {/* Recovery flow */}
      <AuthStack.Screen name="ForgotPassword" component={ForgotPassword} />
      <AuthStack.Screen name="RecoverAccount" component={AccountRecovery} path="recovery" />
      <AuthStack.Screen name="Verify" component={VerificationScreen} />

      {/* Create persona flow */}
      <AuthStack.Screen name="RegisterUsername" component={RegisterUsername} />
      <AuthStack.Screen name="AccountVerification" component={AccountVerification} />
      <AuthStack.Screen name="CreatePassword" component={CreatePassword} />
      <AuthStack.Screen
        name="RegisterEmail"
        component={RegisterEmail}
        options={{
          headerLeft: () => undefined,
        }}
      />
      <AuthStack.Screen name="VerifyEmail" component={VerifyEmail} />

      {/*<AuthStack.Screen name="CreateProfile" component={CreateProfile}/>*/}
      <AuthStack.Screen name="ProfileAvatar" component={ProfileAvatar} />
      <AuthStack.Screen name="ProfileName" component={ProfileName} />
      <AuthStack.Screen name="ProfileUsername" component={ProfileUsername} />
      <AuthStack.Screen name="ProfileDOB" component={ProfileDOB} />
      <AuthStack.Screen name="ProfilePronouns" component={ProfilePronouns} />
      <AuthStack.Screen name="ProfileInterests" component={ProfileInterests} />

      <AuthStack.Screen name="AgreeToMeshCoC" component={AgreeToMeshCoC} />
      <AuthStack.Screen
        name="TermsScreen"
        component={TermsScreen}
        options={{
          headerLeft: () => <MeshLogo width={70} height={40} />,
          headerTitle: null,
        }}
      />
      <AuthStack.Screen
        name="Referral"
        component={ReferralScreen}
        options={{
          headerTitle: `Refer`,
        }}
      />
      <AuthStack.Screen name="LocalStorage" component={ViewLocalStorage} />
    </AuthStack.Navigator>
  );
};
