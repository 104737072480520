import { User } from "@sentry/react-native";
import { useMemo } from "react";
import { useSettings } from "../../common-settings/hooks/useSettings";
import { GroupCoreFields } from "../../common-types/types";

type AgeVerificationProps = (user: User, group: GroupCoreFields) => boolean;

export const useAgeVerification: AgeVerificationProps = (user, group) => {
  const { settings: birthSetting } = useSettings(`user.setting.birthday`, {
    user_id: user?.id,
    group_id: undefined,
  });

  const { settings: ageSetting } = useSettings(`group.setting.age_restriction`, {
    user_id: undefined,
    group_id: group?.id,
  });

  const minAge = Number(ageSetting[`group.setting.age_restriction`]?.value);

  const isAgeInvalid = useMemo(() => {
    const userBirth = birthSetting[`user.setting.birthday`]?.value;
    const userAge = new Date().getFullYear() - new Date(userBirth).getFullYear();

    if (!!user?.id && userAge < minAge) return true;
    return false;
  }, [user?.id, minAge, birthSetting]);

  return isAgeInvalid;
}