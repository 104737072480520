import React from 'react';
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Colors, Typography } from '../common-styles';
import { MeshIcon, NoThemeButton, LoadingIndicator } from '../common-ui';
import { PaymentMethodImage } from './PaymentMethodImage';
import { SET_DEFAULT_PAYMETHOD } from './testLabels';
import { RootState } from '../redux/store';
import { PaymentNavigator } from '../common-types/navigation-types';
import { PaymentMethod } from '../common-types/types';

type PaymentMethodButtonProps = {
  paymentMethods: PaymentMethod[];
};

export const PaymentMethodButton: React.FC<PaymentMethodButtonProps> = ({ paymentMethods }) => {
  const navigation = useNavigation<PaymentNavigator>();
  const { defaultPaymentMethod } = useSelector((state: RootState) => state.contributions);

  const handleSetDefaultPaymentMethod = () => {
    navigation.push(`PaymentMethodList`, { paymentMethods });
  };

  const { card } = (defaultPaymentMethod as any) || {};

  return (
    <View>
      {!defaultPaymentMethod ? (
        <NoThemeButton
          clear
          buttonStyle={styles.defaultButtonStyle}
          color={Colors.brandPurple}
          title="Select payment"
          titleStyle={Typography.text(`bold`, `link`)}
          testID="SET_DEFAULT_PAYMETHOD"
          rightIcon={<MeshIcon size={20} name="chevron-right" />}
          onPress={handleSetDefaultPaymentMethod}
        />
      ) : (
        <View>
          <View style={styles.buttonStyle}>
            <TouchableOpacity style={{ flexDirection: `row`, alignItems: `center` }} onPress={handleSetDefaultPaymentMethod}>
              <PaymentMethodImage cardName={card?.brand} />
              {card?.brand ? (
                <Text style={{ marginLeft: 12 }}>••••</Text>
              ) : (
                <View style={{ marginLeft: 16 }}>
                  <LoadingIndicator />
                </View>
              )}
              <Text style={{ marginLeft: 6 }}>{card?.last4}</Text>
            </TouchableOpacity>

            <NoThemeButton clear title="Edit" onPress={handleSetDefaultPaymentMethod} testID={SET_DEFAULT_PAYMETHOD} />
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  defaultButtonStyle: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.brandPurple,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 12,
    paddingRight: 12,
    justifyContent: `space-between`,
  },
  buttonStyle: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.brandPurple,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 12,
    paddingRight: 12,
    flexDirection: `row`,
    justifyContent: `space-between`,
  },
});
