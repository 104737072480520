import React from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Colors } from '../common-styles';
import { KeyboardPaddingView, WizardProgress } from '../common-ui';

export const WizardLayout = ({ children, total, selector }) => {
  const { currentStep } = useSelector((state) => state[selector]);
  return (
    <SafeAreaView style={styles.root}>
      <KeyboardPaddingView>
        <View style={styles.flex1}>
          <View style={styles.progress}>
            <WizardProgress current={currentStep} total={total} showStepNumbers solid />
          </View>
          {children}
        </View>
      </KeyboardPaddingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  flex1: {
    flex: 1,
    marginTop: 10,
  },
  progress: {
    paddingHorizontal: 16,
  },
});

WizardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  total: PropTypes.number.isRequired,
  selector: PropTypes.string.isRequired,
};
