import { Platform } from 'react-native';
import { PendoSDK } from 'rn-pendo-sdk';
import { getManifest } from '../../environment';
import { Activity, GroupMember } from '../common-types/types';

/**
 * Uses pendo library to send data
 * data fields can't be complex structures
 * usage:
 *
 * import { PendoTrackEvent, Events } from '../pendo/events';
 *
 * PendoTrackEvent(Events.EventName, { user_handle: '...', taps: 1, categories: ['a','b']});
 *
 * @param event string: name of event
 * @param data EventPayload: object with required data
 */

export const PendoTrackEvent = (event: String, data: EventPayload = {}) => {
  const { version, extra } = getManifest() || {};
  const { publishID, releaseChannel } = extra || {};
  const input: EventPayload = {
    ...data,
    publishID,
    version,
    releaseChannel,
  };
  //console.log(`🚀 ~ PendoTrackEvent ~ ${Platform.OS} ${event}`, input);
  PendoSDK.track(`${Platform.OS} ${event}`, input);
};

type EventPayload = {
  [key: string]: string | number | (string | number)[] | boolean | Date | undefined;
};

/**
 * Event names
 */
export enum Events {
  //REGISTER
  START_SIGNUP = `Signup`,
  REGISTER_USERNAME = `Signup - Username`,
  REGISTER_BACK = `Signup - Cancel`,
  PASSWORD_CONTINUE = `Create Password - Continue`,
  PASSWORD_BACK = `Create Password - Back`,
  EMAIL_SEND_CODE = `Signup Email - Send Code`,
  EMAIL_BACK = `Signup Email - Back`,
  VERIFY_EMAIL_CONTINUE = `Signup Verify Email - Continue`,
  VERIFY_EMAIL_RESEND = `Signup Verify Email - Resend`,
  VERIFY_EMAIL_BACK = `Signup Verify Email - Back`,
  VERIFY_EMAIL_SKIP = `Signup Verify Email - Skip`,
  DOB_CONTINUE = `Signup Birthdate - Continue`,
  DOB_BACK = `Signup Birthdate - Back`,
  //GUEST
  START_GUEST = `Continue as guest`,
  GUEST_BACK = `Explore as Guest - back`,
  GUEST_SEARCH = `Explore as Guest - search`,
  EXPLORE_CATEGORY = `Explore Category`,

  //LOGIN
  START_LOGIN = `Login`,
  START_FORGOT = `Login - Forgot password`,
  LOGIN_SUCCESS = `Login - Log in`,
  FORGOT_PASSWORD_RESET = `Forgot Password - Reset code`,
  FORGOT_PASSWORD_BACK = `Forgot Password - Back`,
  VERIFY_CODE_SUBMIT = `Verification Code - Submit`,
  VERIFY_CODE_RESEND = `Verification Code - Resend`,
  VERIFY_CODE_BACK = `Verification Code - Back`,
  RESET_PASWORD_SUBMIT = `Reset Password - Submit`,
  RESET_PASWORD_BACK = `Reset Password - Back`,
  //ADS
  AD_CLICK = `Ad Click`,
  AD_SEEN = `Ad Seen`,
  AD_LIKED = `Ad Liked`,
  //COMMUNITY
  COMMUNITY_ACTIVE = `Community Active`,
  VIEW_COMMUNITY = `View Community`,
  SHARE_COMMUNITY = `Share Community`,
  COMMUNITY_SPONSOR_CLICK = `Community sponsor click`,
  PREVIEW_ACTIVE = `Preview Active`,
  JOIN_COMMUNITY = `Join Community`,
  //REACTIONS
  START_REACTION = `Reaction`,
  CUSTOM_REACTION = `Custom-Reaction`,
  // POSTS
  SUBMIT_POST = `Submit-Post`,
  SUBMIT_PERSONAL_POST = `Submit personal post`,
  POST_SEEN = `Post seen`,
  POST_APPROVAL = `Post Approval`,
  //TOPICS
  CREATE_TOPIC = `Create-Topic`,
  MY_TOPICS = `MY-TOPICS`,
  VIEW_TOPIC = `VIEW-TOPIC`,
  FAVORITE_TOPIC = `FAVORITE-TOPIC`,
  //MEMBERSHIPS
  MEMBER_APPROVAL = `Member approval`,
  //PROFILE
  PROFILE = `View Profile`,
}

export const extractSourceFromRoute = (route: string) => {
  switch (route) {
    case `HomeScreen`:
      return `Home`;
    case `Explore`:
    case `ExploreSearch`:
    case `CategoryListView`:
      return `Explore`;
    case `Profile`:
      return `Profile`;
    case `JoinCommunity`:
      return `Persona Creation`;
    default:
      return route;
  }
};

export const getCommunityRole = (isGuestMode: boolean, group_member?: GroupMember): string => {
  let community_role = isGuestMode ? `guest` : `visitor`;
  if (group_member?.role_name) community_role = group_member.role_name;
  return community_role;
};

enum MediaTypes {
  TEXT = `text`,
  IMAGE = `image`,
  VIDEO = `video`,
  LINK = `link`,
  AUDIO = `audio`, // TODO
}

export const getContentType = (activity: Activity): MediaTypes => {
  if (activity?.video_urls?.length) return MediaTypes.VIDEO;
  if (activity?.imageUrls?.length) return MediaTypes.IMAGE;
  if (activity?.linkPreview) return MediaTypes.LINK;
  return MediaTypes.TEXT;
};
