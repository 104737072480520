import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { sample } from 'lodash';
import { ViewStyle } from 'react-native';
import { Colors } from '../common-styles';

type BlobProps = {
  width: number;
  height: number;
  style?: ViewStyle;
};
type BackgroundBlogProps = {
  blobIndex: number;
} & BlobProps;

export const BackgroundBlob: React.FC<BackgroundBlogProps> = React.memo(({ blobIndex, width, height }) => {
  const backgrounds = getBackgrounds();
  if (blobIndex < 0 || blobIndex > 5) return null;
  const PostBackground: any = backgrounds[blobIndex];
  const blobHeight = height * 0.7;
  const style = { position: `absolute`, left: width * 0.2, bottom: height * 0.1, zIndex: -1 };
  return <PostBackground style={style} width={width} height={blobHeight} />;
});

export const BlobBlue: React.FC<BlobProps> = React.memo(({ width = 137, height = 120, ...rest }) => (
  <Svg width={width} height={height} viewBox="0 0 137 120" {...rest}>
    <Path
      d="M118.132 15.343c10.694 10.024 15.899 24.673 17.697 40.95 1.704 16.191 0 34.01-10.694 46.261-10.6 12.25-30.285 19.019-47.888 17.134-17.603-1.885-33.124-12.422-47.887-24.673C14.596 82.765.495 68.715.4 54.58c0-14.05 13.912-28.185 28.77-38.209C43.934 6.262 59.55.266 75.45.01c15.899-.257 32.082 5.225 42.682 15.334z"
      fill="#CAF0F4"
      fillRule="nonzero"
    />
  </Svg>
));

export const BlobPurple: React.FC<BlobProps> = React.memo(({ width = 136, height = 120, ...rest }) => (
  <Svg width={width} height={height} viewBox="0 0 136 120" {...rest}>
    <Path
      d="M134.275 38.055c7.058 22.206-8.332 50.296-31.857 66.618-23.526 16.417-55.285 20.972-76.36 7.117C4.884 97.935-5.702 65.67 3.12 41.282 11.844 16.798 40.075.192 68.894.002c28.819-.19 58.324 15.942 65.381 38.053z"
      fill="#E1E1EF"
      fillRule="nonzero"
    />
  </Svg>
));

export const BlobGreen: React.FC<BlobProps> = React.memo(({ width = 137, height = 120, ...rest }) => (
  <Svg width={width} height={height} viewBox="0 0 137 120" {...rest}>
    <Path
      d="M132.83 100.457c-17.836 30.19-100.908 24.109-124.608-9.34C-15.478 57.668 19.95-2.931 61.486.11c41.536 3.258 88.936 69.939 71.344 100.347z"
      fill="#E0F0E3"
      fillRule="nonzero"
    />
  </Svg>
));

export const BlobOrange: React.FC<BlobProps> = React.memo(({ width = 137, height = 120, ...rest }) => (
  <Svg width={width} height={height} viewBox="0 0 137 120" {...rest}>
    <Path
      d="M102.632 33.34c13.472 12.58 28.517 22.42 32.45 35.484 4.032 13.146-3.147 29.437-16.618 39.195-13.57 9.678-33.434 12.662-52.412 11.856-18.88-.888-36.777-5.565-47.594-15.243S3.806 80.276 1.545 63.744C-.717 47.29-1.307 28.904 9.51 16.404 20.326 3.821 42.746-2.953 59.857 1.24c17.208 4.275 29.205 19.517 42.775 32.098z"
      fill="#FFE6E2"
      fillRule="nonzero"
    />
  </Svg>
));

export const BlobPink: React.FC<BlobProps> = React.memo(({ width = 137, height = 120, ...rest }) => (
  <Svg width={width} height={height} viewBox="0 0 137 120" {...rest}>
    <Path
      d="M134.892 40.508c7.68 22.204-8.57 47.042-35.28 62.752-26.6 15.798-63.55 22.38-82.581 11.058-19.032-11.234-20.145-40.372-9.46-65.824C18.365 23.042 40.958 1.276 68.225.048c27.156-1.141 58.875 18.343 66.666 40.46z"
      fill="#FCDEE9"
      fillRule="nonzero"
    />
  </Svg>
));

export const BlobYellow: React.FC<BlobProps> = React.memo(({ width = 136, height = 120, ...rest }) => (
  <Svg width={width} height={height} viewBox="0 0 136 120" {...rest}>
    <Path
      d="M80.768.286C44.872 3.718 15.163 28.46 2.146 59.996c-13.017 31.538 35.999 58.406 71.302 59.946 35.303 1.54 62.554-27.966 62.552-61.576-.002-33.61-19.337-61.512-55.232-58.08z"
      fill="#F8F1AC"
      fillRule="nonzero"
    />
  </Svg>
));

export const getBlobs = (): React.FC<BlobProps>[] => {
  return [BlobPurple, BlobOrange, BlobBlue, BlobGreen, BlobPink, BlobYellow];
};

export const getBackgrounds = () => {
  return [
    BlobPurple,
    BlobOrange,
    BlobBlue,
    BlobGreen,
    BlobYellow,
    BlobPink,
    Colors.orangeBackground,
    Colors.purpleBackground,
    Colors.greenBackground,
    Colors.tealBackground,
    Colors.white,
  ];
};

export const getRandomBlob = () => {
  const blobs = getBlobs();
  const picked = sample(blobs);
  // Ensure at least one pick
  if (!picked) return BlobBlue;
  return picked;
};
