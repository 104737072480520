import { formatIncompletePhoneNumber, isValidNumber } from 'libphonenumber-js';

export const cleanPhoneNumber = (contact) => {
  let cleaned;
  if (contact) {
    const cleanedRegex = /[^0-9]/g;
    cleaned = contact.replace(cleanedRegex, ``).trim();
  }
  return cleaned;
};

export const formatPhoneNumber = (contact) => {
  if (contact) {
    let formatted = formatIncompletePhoneNumber(contact, `US`);
    // console.log(`contact.length`, contact.length);
    if (contact.length === 4 && formatted.endsWith(`)`)) {
      console.log(`contact ends with )`);
      // console.log(`formatted`, formatted);
      formatted = formatted.slice(0, -1).trim();
      // slice 0 -2 causes weird flicker issue when trying to add 3rd digit of area code after delete of third
      // formatted = formatted.slice(0, -2).trim();
    }
    return formatted;
  }
  return contact;
};

export const isValidUSPhone = (contact) => {
  const valid = isValidNumber(contact, `US`);
  return valid;
};
