import React, { memo } from 'react';
import { Channel } from 'stream-chat';
import { MessageList as StreamMessageList } from 'stream-chat-react-native';
import { MeshChatGenerics } from '../chatClient';
import { ChatTypingIndicator } from '../ChatTypingIndicator';
import { DateSeparator } from './DateSeparator';

/*
  FIXME: This chat workflow is not working properly.
  There are too many re-renders when you type in the chat.
  This is because they way we are memoizing the component is not the appropriate way to do it.
  Also, we should consider upgrading the version of getstream to make use of the new hooks that they
  have added. This will allow us to have a cleaner codebase.
*/

type MessageListProps = {
  messages: Channel<MeshChatGenerics>[];
};

export const MessageList: React.FC<MessageListProps> = memo(({ messages }) => {
  return (
    <StreamMessageList
      // TypingIndicator={() => <Text>Writing...</Text>} // Hide because we use the header component to render this one
      TypingIndicator={ChatTypingIndicator}
      NetworkDownIndicator={() => null} // Disaple network down indicator
      // Message={(props) => <CustomMessage {...props} />}
      InlineDateSeparator={DateSeparator}
      //DateSeparator={DateSeparator}
      additionalFlatListProps={{
        removeClippedSubviews: messages?.length > 15,
        onEndReachedThreshold: 0.5,
        windowSize: 4,
        style: {
          width: `100%`,
          paddingLeft: 10,
          paddingRight: 15,
          marginBottom: 32,
        },
        contentContainerStyle: {
          paddingBottom: 8,
          flexGrow: 1,
          justifyContent: `flex-end`,
        },
      }}
    />
  );
});
