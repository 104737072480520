import React from 'react';
import { Easing, Platform, TouchableOpacity } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { OverlayProvider } from 'stream-chat-react-native';
import MeshWordmarkSVG from '../../assets/images/mesh-wordmark.svg';
import { getEnvVariables, getReleaseChannel } from '../../environment';
import {
  EditCodeOfConduct,
  HandleEditor,
  EmailEditor,
  BankAccount,
  EmailVerificationScreen,
  PronounSettingEditor,
  TextEditor,
  AffiliatedCommunities,
  IntroVideoSetting,
} from '../common-settings';
import { Colors, headerNoBottomBorderStyle, headerStyle } from '../common-styles';
import { BackButton, MeshIcon, withBadge } from '../common-ui';
import { BannedUsersList, SearchUsersScreen, AboutScreen, AffiliatedCommunitiesHomeList } from '../community';
import { CommunityApplicationCoC, CommunityApplicationForm, CreateCommunityApplication } from '../community-application';
import { EditCommunityApplicationQuestion } from '../community-application/EditCommunityApplicationQuestion';
import { EditCommunityGuideline } from '../community-application/EditCommunityGuideline';
import EditCommunityBanner from '../community/v2/EditCommunityBanner';
import { CreateNodeFlowGroup } from './groups/CreateCommunityFlow';

import { ExploreScreen } from '../explore/ExploreScreen';
import { ExploreSeeAllGroups } from '../explore/ExploreSeeAllGroups';
import { CommunityPreview } from '../community/v2/CommunityPreview';
import { CategoryListView } from '../explore/CategoryListView';
import { ExploreAllRecommendations } from '../explore/ExploreAllRecommendations';
import { ExploreSearch } from '../explore/ExploreSearch';
import { LandingLoaderScreen } from './LandingLoaderScreen';

import { CommunityCodeOfConductScreen } from '../community/CommunityCodeOfConductScreen';
import { CommunitySettings } from '../community/CommunitySettings';
import { DrawerMenuIcon } from '../drawer/Drawer';
import { HomeScreen } from '../community/v2/HomeScreen';
// import { HomeScreen } from '../feed/HomeScreen';
import { VerificationSelfieUpload } from '../mesh-verification';
import { ApplicationReview } from '../moderation/ApplicationReview';
import { DeniedDetail } from '../moderation/DeniedDetail';
import { LeadersAndModerators } from '../moderation/LeadersAndModeratorsList';
import { MeshVerificationReview } from '../moderation/MeshVerificationReview';
import { ModerationPNReceiver } from '../moderation/ModerationPNReceiver';
import { ModerateCommunityDetail } from '../moderation/ModerateCommunityDetail';
import { ModerationCategoryList } from '../moderation/ModerationCategoryList';
import { ModerationTakeAction } from '../moderation/ModerationTakeAction';
import { PostApprovalReview } from '../moderation/PostApprovalReview';
import { ReportDetail } from '../moderation/ReportDetail';
import { ThreeDotCommunityDetails } from '../moderation/ThreeDotCommunityDetails';

import { NotificationsScreen } from '../notification/NotificationsScreen';
import { NotificationsSettings } from '../notification/NotificationsSettings';
import { FollowRequestScreen } from '../notification/FollowRequestScreen';

import { PostDetail, PostReactionUserList } from '../post';
import { PostEditor } from '../post/CreatePost2';

import { PostCommunity } from '../post/PostCommunity';
import { MessagingSettings } from '../drawer/MessagingManage';

import { ChatChannelList, ChatThread, ChatUnderConstruction, ChatUserOverlaySettings } from '../chat';

import { TermsScreen } from '../auth/TermsScreen';
import { AgreeToMeshCoC } from '../profile-create';
import { ProfileAvatar } from '../profile-create/ProfileAvatar';
import { ProfileName } from '../profile-create/ProfileName';
import { ProfileUsername } from '../profile-create/ProfileUsername';
import { ProfileDOB } from '../profile-create/ProfileDOB';
import { ProfilePronouns } from '../profile-create/ProfilePronouns';
import { ProfileInterests } from '../profile-create/ProfileInterests';
import { ProfileScreen } from '../profile/ProfileScreen';
import { ProfileSettings } from '../profile/ProfileSettings';
import { FavouriteCommunitiesSelection } from '../profile/FavouriteCommunitiesSelection';

import { DevTools } from '../debug/DevTools';
import { ContextViewer } from '../debug/ContextViewer';
import { ViewLocalStorage } from '../debug/ViewLocalStorage';
import { ViewReduxSlice } from '../debug/ViewReduxSlice';
import { authScreenOptions } from './AuthNavigator';
import { MemberList } from '../community/v2/MemberList';
import ReportScreen from '../moderation/ReportScreen';
import DeletePostScreen from '../moderation/DeletePostScreen';
import { PhoneVerificationScreen } from '../common-settings/PhoneVerificationScreen';
import { PhoneNumberEditor } from '../common-settings/PhoneNumberEditor';
import { BirthdayEditor } from '../common-settings/BirthdayEditor';
import EditReactions from '../common-settings/EditReactions';
import EditTopics from '../common-settings/EditTopics';
import CreateTopic from '../common-settings/CreateTopics';
import CommunityTopics from '../community/CommunityTopics';
import UploadReaction from '../common-settings/UploadReaction';
import ReactionLibrary from '../common-settings/ReactionLibrary';
import { VerificationSelfieSentScreen } from '../mesh-verification/VerificationSelfieSentScreen';
import { BirthdaysByCommunity } from '../community/BirthdaysByCommunity';
import { AdTool } from '../ad/AdTool';
import { HeroAreaMediaTool } from '../explore/HeroAreaMediaTool';
import TopicFeedScreen from '../community/v2/TopicFeedScreen';
import { ContributionFlowGroup } from './groups/ContributionFlow';
import { PersonalFeedScreen } from '../community/v2/PersonalFeedScreen';
import { PersonalPostEditor } from '../post/CreatePersonalPost';

export const transitionSpecTiming = {
  animation: `timing`,
  config: { duration: 300, easing: Easing.out(Easing.poly(4)) },
};

export const defaultNavigationOptions = () => {
  return {
    headerBackVisible: false,
    headerLeft: () => <BackButton />,
    headerTintColor: Colors.textBlack, // probably doesn't matter since we override headerTitleStyle and the back button
    headerStyle,
    headerTitleAlign: `center`,
    transitionSpec: { open: transitionSpecTiming, close: transitionSpecTiming },
    contentStyle: { backgroundColor: Colors.white },
    // cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    // onTransitionStart: ({ closing }) => console.log('on transition start', closing),
    // onTransitionEnd: ({ closing }) => console.log('on transition end', closing),
  };
};

export const drawerNavigationOptions = () => {
  return {
    contentStyle: { backgroundColor: Colors.white },
    headerLeft: () => <DrawerMenuIcon />,
    headerTitleAlign: `center`,
    headerStyle: {
      ...headerStyle,
      ...headerNoBottomBorderStyle,
    },
  };
};

//FIXME: screens must not be shared this way, it makes everything convoluted
export const screensForAllStacks = {
  //ChatThread: { screen: ChatThread, path: `chat/:channel_id/:channel_type` },
  ChatUserOverlaySettings: { screen: ChatUserOverlaySettings, options: { headerTitle: `Chat settings` } },

  CommunityApplication: {
    screen: CommunityApplicationCoC,
    path: `community/:group_id/application`,
    options: { headerTitle: `Join us` },
  },

  CommunityApplicationForm: {
    screen: CommunityApplicationForm,
    options: { headerTitle: `Join us` },
  },

  CommunityCodeOfConduct: { screen: CommunityCodeOfConductScreen, options: { headerTitle: `Code of Conduct` } },

  //Groups: { screen: CommunityList, options: { headerTitle: `Communities` } },
  CategoryListView: { screen: CategoryListView, options: { headerTitle: `Category` } },
  MemberInfo: { screen: MemberList, options: { headerTitle: `Members and roles` } },
  AboutScreen: { screen: AboutScreen, options: { headerTitle: `About` } },
  AffiliatedCommunitiesHomeList: { screen: AffiliatedCommunitiesHomeList, options: { headerTitle: `Affiliates` } },
  SearchUsersScreen: { screen: SearchUsersScreen, options: { headerTitle: `Members` } },
  BannedUsersList: { screen: BannedUsersList, options: { headerTitle: `Banned users` } },

  PostDetail: { screen: PostDetail, options: { headerTitle: `Post` } },
  PostReactionUserList: { screen: PostReactionUserList, options: { headerTitle: `Reactions` } },
  PostEditor: { screen: PostEditor, options: { headerTitle: `Create post` } },
  PersonalPostEditor: { screen: PersonalPostEditor, options: { headerTitle: `Create post` } },
  // Contribution Routes.
  ...ContributionFlowGroup,
  // #endregion

  //#region Settings routes
  // common settings
  ProfileSettings: { screen: ProfileSettings, options: { headerTitle: `Edit your profile` } },
  CommunitySettings: {
    screen: CommunitySettings,
    mode: `modal`,
    path: `community/:group_id/settings`,
    options: { headerTitle: `Settings` },
  },
  TextSettingEditor: { screen: TextEditor, mode: `modal` },
  BankAccount: { screen: BankAccount, options: { headerTitle: `Payment account` } },
  HandleEditor: { screen: HandleEditor, mode: `modal`, options: { headerTitle: `Edit username` } },
  EmailEditor: { screen: EmailEditor, mode: `modal`, options: { headerTitle: `Email` } },
  AffiliatedCommunities: { screen: AffiliatedCommunities, mode: `modal`, options: { headerTitle: `Affiliates` } },
  IntroVideoSetting: { screen: IntroVideoSetting, mode: `modal`, options: { headerTitle: `Intro video` } },
  BirthdayEditor: { screen: BirthdayEditor, mode: `modal`, options: { headerTitle: `Birthday` } },
  PhoneNumberEditor: { screen: PhoneNumberEditor, mode: `modal`, options: { headerTitle: `Verify phone` } },
  EmailVerificationScreen: { screen: EmailVerificationScreen, options: { headerTitle: `Email verification` } },
  PhoneVerificationScreen: { screen: PhoneVerificationScreen, options: { headerTitle: `Verify phone` } },
  PronounSettingEditor: { screen: PronounSettingEditor, mode: `modal`, options: { headerTitle: `Edit your pronouns` } },
  VerificationSelfieSentScreen: { screen: VerificationSelfieSentScreen, mode: `modal` },
  BirthdaysByCommunity: { screen: BirthdaysByCommunity, mode: `modal` },

  // Group exclusive
  CreateCommunityApplication: { screen: CreateCommunityApplication, options: { headerTitle: `Membership application` } },
  EditCommunityApplicationQuestion: { screen: EditCommunityApplicationQuestion, options: { headerTitle: `Edit question` } },
  EditCommunityGuideline: { screen: EditCommunityGuideline, options: { headerTitle: `Edit guideline` } },
  EditCommunityBanner: { screen: EditCommunityBanner, options: { headerTitle: `Edit Banner` } },
  EditCodeOfConduct: { screen: EditCodeOfConduct, mode: `modal`, options: { headerTitle: `Edit Ground Rules` } },
  EditReactions: { screen: EditReactions, options: { headerTitle: `Reactions` } },
  EditTopics: { screen: EditTopics, options: { headerTitle: `` } },
  CreateTopic: { screen: CreateTopic },
  CommunityTopics: { screen: CommunityTopics },
  UploadReaction: { screen: UploadReaction, options: { headerTitle: `Upload Reaction` } },
  ReactionLibrary: { screen: ReactionLibrary, options: { headerTitle: `Reaction Library` } },

  // Group exclusive
  NotificationsSettings: {
    screen: NotificationsSettings,
    mode: `modal`,
    path: `notifications/settings`,
    options: { headerTitle: `Notification settings` },
  },
  MessagingSettings: { screen: MessagingSettings, options: { headerTitle: `Messaging` } },
  //#endregion

  //#region moderation routes
  // ModerateCommunityDetail: { screen: ModerateCommunityDetail, options: { headerTitle: `Moderate community` } },
  ModerationCategoryList: { screen: ModerationCategoryList, options: (props) => ModerationCategoryList.navigationOptions(props) },
  ModerationTakeAction: { screen: ModerationTakeAction, options: { headerTitle: `Take action` } },
  ApplicationReview: { screen: ApplicationReview, options: { headerTitle: `Application for review` } },
  PostApprovalReview: { screen: PostApprovalReview, options: { headerTitle: `Post approval` } },
  ThreeDotCommunityDetails: { screen: ThreeDotCommunityDetails, options: { headerTitle: `Community details` } },
  ReportDetail: { screen: ReportDetail, options: { headerTitle: `Report` } },
  ReportScreen: { screen: ReportScreen, screenOptions: { headerTitle: `` } },
  DeletePostScreen: { screen: DeletePostScreen, screenOptions: { headerTitle: `` } },
  DeniedDetail: { screen: DeniedDetail, options: { headerTitle: `Post` } },
  LeadersAndModerators: { screen: LeadersAndModerators, options: { headerTitle: `Leaders and moderators` } },
  MeshVerificationReview: { screen: MeshVerificationReview, options: { headerTitle: `Verification for review` } },
  VerificationSelfieUpload: { screen: VerificationSelfieUpload, options: { headerTitle: `Mesh verification` } },
  //#endregion

  //#region Profile
  Profile: { screen: ProfileScreen, options: { headerTitle: `Profile` } },
  ProfileCompat: { screen: ProfileScreen, options: { headerTitle: `Profile` } },
  FavouriteCommunitiesSelection: { screen: FavouriteCommunitiesSelection, options: { headerTitle: `Favorites` } },
  CreateAdditionalProfile: { screen: ProfileAvatar, options: authScreenOptions },
  ProfileName: { screen: ProfileName, options: authScreenOptions },
  ProfileUsername: { screen: ProfileUsername, options: authScreenOptions },
  ProfileDOB: { screen: ProfileDOB, options: authScreenOptions },
  ProfilePronouns: { screen: ProfilePronouns, options: authScreenOptions },
  ProfileInterests: { screen: ProfileInterests, options: authScreenOptions },
  AgreeToMeshCoC: { screen: AgreeToMeshCoC, options: authScreenOptions },
  //#endregion

  //#region follower/following

  //#region topic feed
  TopicFeedScreen: { screen: TopicFeedScreen, options: { headerShown: false } },
  //#end region
};

const hideTabBar = new Set([
  `TermsScreen`,
  `CreateCommunity`,
  `CommunityName`,
  `CommunityEmail`,
  `CommunityEmailVerification`,
  `CommunityImage`,
  `CommunityCategories`,
  `CreateCommunityApplication`,
  `CommunityApplication`,
  `CommunityApplicationForm`,
  `CommunityExtraSettings`,
  `PostDetail`,
  `ChatThread`,
  `PostEditor`,
  `PersonalPostEditor`,
  `PronounSettingEditor`,
  `HandleEditor`,
  `EmailEditor`,
  `BankAccount`,
  // `EmailVerificationScreen`,
  `StripeProccessFinishedScreen`,
  `ThanksForContributing`,
  `TextSettingEditor`,
  `Settings`,
  `ProfileSettings`,
  `SelectAmount`,
  `Contribute`,
  `PaymentMethodList`,
  `AddPaymentMethod`,
  `EditPaymentMethod`,
  `CommunitySettings`,
  `CreateAdditionalProfile`,
  `Profile`,
  `ProfileCompat`,
  `DrawerSettings`,
  `UserCommunityPostNotificationSettings`,
  `UserFollowingNotificationSettings`,
  `UpdatePassword`,
  `ModerateCommunityDetail`,
  `FollowSettings`,
  `WhoCanCommentSettings`,
  `VisibilitySettings`,
  `MessagingSettings`,
  `FollowInfoScreen`,
  `DiscoverabilityAndContactsSetting`,
  `Ads`,
  `HeroAreaMediaTool`,
]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PersonalFeedNavigator = () => {
  const PersonalFeedStack = createNativeStackNavigator();
  // path: `/`,
  return (
    <PersonalFeedStack.Navigator initialRouteName="PersonalFeedScreen" screenOptions={defaultNavigationOptions}>
      <PersonalFeedStack.Screen
        name="PersonalFeedScreen"
        component={PersonalFeedScreen}
        options={() => ({
          header: () => null,
        })}
      />
      <PersonalFeedStack.Screen name="CommunityPreview" component={CommunityPreview} options={{ headerShown: false }} />
      {Object.keys(screensForAllStacks).map((screenKey) => {
        const { screen, options = {} } = screensForAllStacks[screenKey] || {};
        return <PersonalFeedStack.Screen key={screenKey} name={screenKey} component={screen} options={options} />;
      })}
    </PersonalFeedStack.Navigator>
  );
};

PersonalFeedNavigator.options = (props) => {
  const { route } = props;
  const currentRoute = getFocusedRouteNameFromRoute(route) || route.name;
  return {
    tabBarLabel: `Home`,
    tabBarTestID: `HOME_TAB`,
    tabBarStyle: { display: hideTabBar.has(currentRoute) ? `none` : `flex` },
    tabBarIcon: ({ focused }) => <MeshIcon size={24} focused={focused} name="home" />,
  };
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const HomeNavigator = () => {
  const HomeStack = createNativeStackNavigator();
  // path: `/`,
  return (
    <HomeStack.Navigator initialRouteName="HomeScreen" screenOptions={defaultNavigationOptions}>
      <HomeStack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={({ navigation }) => ({
          ...drawerNavigationOptions(navigation),
          headerShadowVisible: false,
          headerTitle: () => <MeshWordmarkSVG width={68} height={25} />,
          headerRight: () => (
            <TouchableOpacity
              style={{ paddingRight: 6, justifyContent: `center`, alignItems: `center` }}
              onPress={() => navigation.getParent(`RightDrawer`).toggleDrawer()}
              hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
              activeOpacity={0.6}
            >
              <MeshIcon name="list" size={24} color={Colors.iconColor} />
            </TouchableOpacity>
          ),
        })}
      />
      <HomeStack.Screen name="PostCommunity" component={PostCommunity} options={{ headerTitle: `Share with Mesh` }} />
      <HomeStack.Screen name="TermsScreen" component={TermsScreen} />
      <HomeStack.Screen name="ModerationPNReceiver" component={ModerationPNReceiver} />
      <HomeStack.Screen name="ModerateCommunityDetail" component={ModerateCommunityDetail} />

      {/* #region Create community routes */}
      {Object.keys(CreateNodeFlowGroup).map((screenKey) => {
        const { screen, options = {} } = CreateNodeFlowGroup[screenKey] || {};
        return <HomeStack.Screen key={screenKey} name={screenKey} component={screen} options={options} />;
      })}
      {/* #endregion */}

      {Object.keys(screensForAllStacks).map((screenKey) => {
        const { screen, options = {} } = screensForAllStacks[screenKey] || {};
        return <HomeStack.Screen key={screenKey} name={screenKey} component={screen} options={options} />;
      })}
    </HomeStack.Navigator>
  );
};

HomeNavigator.options = ({ route, feedActivityCount }) => {
  const currentRoute = getFocusedRouteNameFromRoute(route) || route.name;
  // path: `notifications`,
  const BadgedIcon = withBadge(undefined, {
    hidden: feedActivityCount === 0,
  })(MeshIcon);

  return {
    tabBarLabel: `Nodes`,
    tabBarTestID: `HOME_TAB`,
    tabBarStyle: { display: hideTabBar.has(currentRoute) ? `none` : `flex` },
    tabBarIcon: ({ focused }) => <BadgedIcon size={24} focused={focused} name="node" />,
  };
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const NotificationNavigator = () => {
  const NotificationStack = createNativeStackNavigator();
  return (
    <NotificationStack.Navigator initialRouteName="NotificationsTab" screenOptions={defaultNavigationOptions}>
      <NotificationStack.Screen
        name="NotificationsTab"
        component={NotificationsScreen}
        options={{
          headerLeft: () => null,
        }}
      />
      <NotificationStack.Screen
        name="NotificationFollowRequest"
        component={FollowRequestScreen}
        options={{
          headerTitle: `Follow requests`,
        }}
      />
      <NotificationStack.Screen name="CommunityPreview" component={CommunityPreview} options={{ headerShown: false }} />
      {Object.keys(screensForAllStacks).map((screenKey) => {
        const { screen, options = {} } = screensForAllStacks[screenKey] || {};
        return <NotificationStack.Screen key={screenKey} name={screenKey} component={screen} options={options} />;
      })}
    </NotificationStack.Navigator>
  );
};

NotificationNavigator.options = ({ route, notificationCount }) => {
  const currentRoute = getFocusedRouteNameFromRoute(route) || route.name;

  // path: `notifications`,
  const BadgedIcon = withBadge(notificationCount, {
    hidden: notificationCount === 0,
  })(MeshIcon);

  return {
    tabBarLabel: `Notifications`,
    tabBarTestID: `NOTIFICATIONS_TAB`,
    tabBarStyle: { display: hideTabBar.has(currentRoute) ? `none` : `flex` },
    tabBarIcon: ({ focused }) => <BadgedIcon size={24} focused={focused} name="notifications" />,
  };
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ExploreNavigator = () => {
  const ExploreStack = createNativeStackNavigator();
  return (
    <ExploreStack.Navigator initialRouteName="Explore" screenOptions={defaultNavigationOptions}>
      <ExploreStack.Screen
        name="Explore"
        component={ExploreScreen}
        options={({ navigation }) => drawerNavigationOptions(navigation)}
      />
      <ExploreStack.Screen name="ExploreSearch" component={ExploreSearch} options={{ headerTitle: `Search` }} />
      <ExploreStack.Screen name="ExploreSeeAllGroups" component={ExploreSeeAllGroups} />
      <ExploreStack.Screen name="CommunityPreview" component={CommunityPreview} options={{ headerShown: false }} />
      <ExploreStack.Screen name="LandingLoaderScreen" component={LandingLoaderScreen} options={{ headerShown: false }} />
      {/* // repeated screen to handle different DeepLink */}
      <ExploreStack.Screen name="LandingLoaderScreenCompat" component={LandingLoaderScreen} options={{ headerShown: false }} />
      <ExploreStack.Screen
        name="ExploreAllRecommendations"
        component={ExploreAllRecommendations}
        options={{
          title: `Recommended Communities`,
        }}
      />
      {Object.keys(screensForAllStacks).map((screenKey) => {
        const { screen, options = {} } = screensForAllStacks[screenKey] || {};
        return (
          <ExploreStack.Screen
            name={screenKey}
            key={screenKey}
            component={screen}
            options={(props) => ({
              ...defaultNavigationOptions(props),
              ...options,
            })}
          />
        );
      })}
    </ExploreStack.Navigator>
  );
};

ExploreNavigator.options = ({ route }) => {
  const currentRoute = getFocusedRouteNameFromRoute(route) || route.name;

  return {
    tabBarLabel: `Explore`,
    tabBarTestID: `EXPLORE_TAB`,
    tabBarStyle: { display: hideTabBar.has(currentRoute) ? `none` : `flex` },
    tabBarIcon: ({ focused }) => <MeshIcon size={24} focused={focused} name="magnifyingglass" />,
  };
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ChatNavigator = () => {
  const ChatStack = createNativeStackNavigator();
  const isChatEnabled = getEnvVariables().newChat;
  return (
    <SafeAreaProvider>
      <OverlayProvider>
        <ChatStack.Navigator
          initialRouteName="chat"
          screenOptions={({ navigation, route }) => ({
            ...defaultNavigationOptions({ navigation, route }),
            headerTitle: `Inbox`,
            headerLeft: null,
          })}
        >
          <ChatStack.Screen name="chat" component={isChatEnabled ? ChatChannelList : ChatUnderConstruction} />
          <ChatStack.Screen name="ChatThread" component={ChatThread} options={{ headerTitle: () => null }} />
          {Object.keys(screensForAllStacks).map((screenKey) => {
            const { screen, options = {} } = screensForAllStacks[screenKey] || {};
            return (
              <ChatStack.Screen
                name={screenKey}
                key={screenKey}
                component={screen}
                options={(props) => ({
                  ...defaultNavigationOptions(props),
                  ...options,
                })}
              />
            );
          })}
        </ChatStack.Navigator>
      </OverlayProvider>
    </SafeAreaProvider>
  );
};

ChatNavigator.options = ({ route, chatNotifications }) => {
  const currentRoute = getFocusedRouteNameFromRoute(route) || route.name;

  const BadgedIcon = withBadge(chatNotifications, {
    hidden: chatNotifications === 0,
  })(MeshIcon);

  return {
    tabBarLabel: `Inbox`,
    tabBarTestID: `CHAT_TAB`,
    tabBarStyle: { display: hideTabBar.has(currentRoute) ? `none` : `flex` },
    tabBarIcon: ({ focused }) => <BadgedIcon size={24} focused={focused} name="chat" />,
    unmountOnBlur: true,
  };
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// HACK: for easy access to WIP components
const HackNavigator = () => {
  const HackStack = createNativeStackNavigator();
  return (
    <HackStack.Navigator screenOptions={defaultNavigationOptions} initialRouteName="DevTools">
      <HackStack.Screen name="DevTools" component={DevTools} />
      <HackStack.Screen name="HeroAreaMediaTool" component={HeroAreaMediaTool} options={{ title: `Hero Assets Tool` }} />
      <HackStack.Screen name="Ads" component={AdTool} />
      <HackStack.Screen
        name="LocalStorage"
        component={ViewLocalStorage}
        options={{
          headerLeft: () => null,
        }}
      />
      <HackStack.Screen name="Context" component={ContextViewer} />
      <HackStack.Screen name="ReduxSlice" component={ViewReduxSlice} />
    </HackStack.Navigator>
  );
};
HackNavigator.options = ({ route }) => {
  const currentRoute = getFocusedRouteNameFromRoute(route) || route.name;

  return {
    tabBarLabel: () => null,
    tabBarStyle: { display: hideTabBar.has(currentRoute) ? `none` : `flex` },
    tabBarIcon: ({ focused }) => <MeshIcon size={24} focused={focused} name="pirate" color="orange" />,
    tabBarBadge: 1,
  };
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getTabBarStacks = () => {
  const MainStacks = {
    PersonalFeedNavigator,
    HomeNavigator,
    //ModerationNavigator,
    ExploreNavigator,
    NotificationNavigator,
    ChatNavigator,
    HackNavigator,
  };

  const isProductionChannel = ((getReleaseChannel() || `dev`).indexOf(`prod`) !== -1 || Platform.OS === `web`) && !__DEV__;

  const hackStackEnabled = !isProductionChannel && true;
  if (!hackStackEnabled) {
    delete MainStacks.HackNavigator;
  }

  return MainStacks;
};
