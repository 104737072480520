import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { SectionList, Text, TextInput, View, ScrollView, Alert, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { Colors, Typography } from '../common-styles';
import {
  Avatar,
  Divider,
  KeyboardPaddingView,
  LoadingIndicator,
  MeshIcon,
  ThemedButton,
  ListItem,
  BackButton,
  BottomSheetModal,
} from '../common-ui';
import { getClosedIssueTitle } from './ModerationIssue';
import { commonPropTypes } from '../common-util';
import { client, refetchQueriesFor, APPLY_MODERATION_ACTIONS, loadCachedGroupMember, REPORT_USER } from '../graphql';
import { MODERATION_ACTIONS, MODERATION_TYPE, getUserActions, getContentActions } from './moderationEnums';
import { reportModerationIssue } from './moderationServices';

export const ModerationTakeAction = React.memo(({ navigation, route }) => {
  const { moderationIssue, customType, reportPayload, prev_screen, values, reporteeName } = route?.params || {};
  const { type, updated_at, status, actions, reportee, activity } = moderationIssue || {};

  const [submitting, setSubmitting] = useState(false);
  const [internalNote, setInternalNote] = useState(customType === `user` ? reportPayload?.explanation || `` : ``);

  const [originalActions, setOriginalActions] = useState([MODERATION_ACTIONS.new_issue, MODERATION_ACTIONS.new_issue]);
  const [contentAction, setContentAction] = useState(MODERATION_ACTIONS.content_report_deny);
  const [userAction, setUserAction] = useState(MODERATION_ACTIONS.use_report_deny);
  const [escalateToMesh, setEscalateToMesh] = useState(false);
  const [escalationNote, setEscalationNote] = useState(``);
  const [actionExpiration, setActionExpiration] = useState();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [moderationActions, setModerationActions] = useState([]);

  const groupMember = loadCachedGroupMember(activity?.origin_group_id, reportee?.id);

  const renderActionItem = useCallback(
    ({ item, section: { id } }) => {
      const { title, icon, color, action, subtitle, actionExpiration } = item;
      const currentlySelected = (id !== `User` && action === contentAction) || (id === `User` && action === userAction);
      if (!title) return null;
      return (
        <ListItem
          title={title}
          subtitle={subtitle}
          titleStyle={localStyles.listItemTitle}
          subtitleStyle={localStyles.listItemSubtitle}
          leftIcon={<MeshIcon name={icon} color={color} />}
          rightIcon={
            <MeshIcon
              name="select-circle"
              color={currentlySelected ? Colors.brandPurple : Colors.gray}
              focused={currentlySelected}
            />
          }
          onPress={() => {
            //console.log(`setting action: ${action} suspension days: ${actionExpiration}`);
            Keyboard.dismiss();
            if (id === `User`) {
              setUserAction(action);
              setActionExpiration(actionExpiration);
            } else setContentAction(action);
          }}
        />
      );
    },
    [contentAction, userAction],
  );

  const applyModerationActions = useCallback(async (input, userDeleteDirectly = false) => {
    const { data } = await client.mutate({
      mutation: APPLY_MODERATION_ACTIONS,
      variables: { input },
      refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, userDeleteDirectly ? `Activity` : ``),
    });
    console.log({
      actions: input.moderationActions,
      response: data.applyModerationActions,
    });
    return data;
  }, []);

  const handleDeleteUserDirectly = useCallback(async () => {
    const reportUser = async (input) => {
      console.log(`<== firing report user directly ==>`);
      const { data } = await client.mutate({
        mutation: REPORT_USER,
        variables: { input },
        refetchQueries: refetchQueriesFor(`ModerationIssue`),
      });
      const reportedUserIssue = data.reportUser;
      if (reportedUserIssue && reportedUserIssue.res_status === `200OK`) {
        return reportedUserIssue;
      }
      return null;
    };
    const result = await reportUser(reportPayload);
    const input = {
      issue_id: result.id,
      group_id: result.group_id,
      mod_explanation: internalNote,
      actions: moderationActions,
    };
    try {
      await applyModerationActions(input, true);
      goBack();
    } catch (error) {
      console.log(`Error occured while applying moderation`, error);
    } finally {
      setSubmitting(false);
    }
  }, [applyModerationActions, reportPayload, internalNote, moderationActions, goBack]);

  const onSubmit = useCallback(async () => {
    console.log(`firing onSubmit...`);
    try {
      setSubmitting(true);
      if (!validActions(contentAction, userAction)) {
        console.log(`no action taken with:`, [contentAction, userAction]);
        return;
      }
      const moderationActions = [];
      if (userAction) {
        moderationActions.push({
          type: MODERATION_TYPE.user,
          action: userAction,
          action_expiration: actionExpiration,
        });
      }
      if (moderationIssue.type !== `user` && contentAction !== null) {
        moderationActions.push({
          type: moderationIssue.type,
          action: contentAction,
        });
      }
      if (escalateToMesh) {
        moderationActions.push({
          type: moderationIssue.type,
          action: MODERATION_TYPE.report_escalated,
        });
      }

      let input = {};
      if (customType) {
        if (customType === `user`) {
          if (moderationActions.some((ma) => ma.type === `user` && ma.action.includes(`user_delete`))) {
            setModerationActions(moderationActions);
            setShowDeleteConfirm(true);
            return;
          }
        } else if (customType === `post` || customType === `comment`) {
          const result = await reportModerationIssue(reportPayload);
          if (result) {
            input = {
              issue_id: result.id,
              group_id: result.group_id,
              mod_explanation: internalNote,
              actions: moderationActions,
            };
          } else {
            Alert.alert(`A problem occurred submitting your report`, `Please try again later`, [
              { text: `OK`, onPress: () => navigation.goBack(), style: `default` },
            ]);
          }
        }
      } else {
        input = {
          issue_id: moderationIssue.id,
          group_id: moderationIssue.group_id,
          mod_explanation: internalNote,
          actions: moderationActions,
        };
      }
      if (input && Object.keys(input).length > 0) {
        const data = await applyModerationActions(input);
        if (data) {
          setTimeout(() => {
            goBack();
            setSubmitting(false);
          }, 1200);
        }
      }
    } catch (err) {
      console.error(`error thrown in onSubmit() in ModerationTakeActionV2`, err.message);
      setTimeout(() => {
        goBack();
        setSubmitting(false);
      }, 1200);
    }
  }, [
    reportPayload,
    actionExpiration,
    contentAction,
    escalateToMesh,
    goBack,
    internalNote,
    moderationIssue.group_id,
    moderationIssue.id,
    moderationIssue.type,
    navigation,
    customType,
    userAction,
    applyModerationActions,
  ]);

  const goBack = useCallback(() => {
    if (prev_screen === `ReportDetail`) navigation.navigate(`ModerateCommunityDetail`, { group: moderationIssue?.group });
    else if (prev_screen === `ReportScreen`) navigation.navigate(`HomeScreen`);
    else navigation.goBack();
  }, [moderationIssue?.group, navigation, prev_screen]);

  // Effect 1:
  useEffect(() => {
    const isNewIssue = moderationIssue?.status === `UNDEF`;
    const originalActions = getOriginalActions(moderationIssue);
    setContentAction(isNewIssue ? MODERATION_ACTIONS.content_report_deny : MODERATION_ACTIONS.no_action);
    setUserAction(isNewIssue ? MODERATION_ACTIONS.user_report_deny : MODERATION_ACTIONS.no_action);
    setOriginalActions(originalActions);
  }, [moderationIssue]);
  // Effect 2: set navigation options
  useEffect(() => {
    const renderHeaderLeft = () => {
      const fromReportScreen = prev_screen === `ReportScreen`;
      const { reportType, reportee, role_name, origin_group_id, reporteeName } = values || {};
      return (
        <BackButton
          onPress={() =>
            fromReportScreen
              ? navigation.navigate(`ReportScreen`, {
                  reportType,
                  reportee,
                  role_name,
                  origin_group_id,
                  reporteeName,
                })
              : navigation.goBack()
          }
        />
      );
    };
    navigation.setOptions({
      headerRight: () => (
        <ThemedButton
          clear
          title="Submit"
          titleStyle={{ color: submitting ? Colors.textDisabled : Colors.brandPurple }}
          onPress={onSubmit}
          containerStyle={{ right: 4 }}
          disabled={submitting || !validActions(contentAction, userAction)}
          leftIcon={submitting && <LoadingIndicator />}
        />
      ),
      headerLeft: () => renderHeaderLeft(),
    });
  }, [navigation, submitting, onSubmit, contentAction, userAction, prev_screen, values]);

  const sections = [];
  if (type !== MODERATION_TYPE.user) {
    const content = type === `profile_feed` ? `Personal Post` : type?.charAt(0).toUpperCase() + type?.slice(1);
    sections.push({
      id: content,
      title: `${content} actions`,
      data: getContentActions(type, originalActions[0]),
    });
  }
  let fromDate = new Date();
  if (status === `closed`) {
    if (!!actions && actions.length > 0) {
      const [firstAction] = actions;
      if (firstAction && firstAction.created_at) {
        fromDate = new Date(+firstAction.created_at);
      }
    } else {
      fromDate = new Date(updated_at);
    }
  }
  if (type !== MODERATION_TYPE.community) {
    const originalData = getUserActions(fromDate, moderationIssue.group_id, originalActions[1]);
    const adminData = [];
    adminData.push(originalData[0]);
    sections.push({
      id: `User`,
      title: `User actions`,
      data: reportee.id === groupMember?.persona_id && groupMember?.role_name === `owner` ? adminData : originalData,
    });
  }

  const nonEscalatableToMeshTypes = [MODERATION_TYPE.user, MODERATION_TYPE.community];

  return (
    <KeyboardPaddingView>
      <ScrollView style={{ backgroundColor: Colors.white }} keyboardShouldPersistTaps="always">
        <SectionList
          sections={sections}
          ListHeaderComponent={<ClosedItem navigation={navigation} modIssue={moderationIssue} />}
          renderSectionHeader={({ section: { title } }) => (
            <View style={{ paddingHorizontal: 16, paddingVertical: 6, backgroundColor: Colors.backgroundColor }}>
              <Text style={localStyles.sectionTitle}>{title}</Text>
            </View>
          )}
          keyboardShouldPersistTaps="always"
          renderItem={renderActionItem}
          keyExtractor={(item, index) => item && item.action + index.toString()}
        />
        <View>
          <HideKeyboard>
            <View>
              <View style={{ paddingHorizontal: 16, paddingVertical: 6, backgroundColor: Colors.backgroundColor }}>
                <Text style={localStyles.sectionTitle}>Internal note for admins</Text>
              </View>
              <View style={{ padding: 16 }}>
                <Text style={localStyles.internalNotesTitle}>Note</Text>
                <TextInput
                  style={localStyles.internalNotes}
                  multiline
                  blurOnSubmit={false}
                  value={internalNote}
                  onChangeText={setInternalNote}
                />
              </View>
            </View>
          </HideKeyboard>
          <View style={{ paddingBottom: 90 }}>
            {!nonEscalatableToMeshTypes.includes(type) && (
              <View>
                <View style={{ paddingHorizontal: 16, paddingVertical: 6, backgroundColor: Colors.backgroundColor }}>
                  <Text style={{ ...Typography.text(`base`, `bold`, `gray`) }}>Escalate issue</Text>
                </View>
                <ListItem
                  title="Report to Mesh"
                  titleStyle={localStyles.listItemTitle}
                  rightIcon={
                    <MeshIcon
                      name="select-circle"
                      color={escalateToMesh ? Colors.brandPurple : Colors.gray}
                      focused={escalateToMesh}
                    />
                  }
                  onPress={() => setEscalateToMesh((prev) => !prev)}
                />
                <Divider />
                {escalateToMesh && (
                  <HideKeyboard>
                    <View style={{ padding: 16 }}>
                      <Text style={{ ...Typography.text(`small`, `gray`, `bold`) }}>Tell us more</Text>
                      <TextInput
                        value={escalationNote}
                        onChangeText={setEscalationNote}
                        style={{
                          padding: 10,
                          borderRadius: 6,
                          borderColor: Colors.gray,
                          borderWidth: 1,
                          marginTop: 8,
                          height: 60,
                        }}
                      />
                    </View>
                  </HideKeyboard>
                )}
              </View>
            )}
          </View>
        </View>
      </ScrollView>
      <BottomSheetModal
        showCancelBtn={false}
        showConfirmBtn
        onPressCancel={() => {
          setShowDeleteConfirm(false);
          setSubmitting(false);
        }}
        onPressConfirm={handleDeleteUserDirectly}
        visible={showDeleteConfirm}
        title={`Do you want to delete ${reporteeName ?? `the user`}?`}
      />
    </KeyboardPaddingView>
  );
});

ModerationTakeAction.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

function ClosedItem({ navigation, modIssue }) {
  const { moderator, group, status } = modIssue;
  if (status === `UNDEF`) return null;
  return (
    <View>
      <View style={{ flexDirection: `row`, alignItems: `center`, padding: 16 }}>
        <Avatar navigation={navigation} user={moderator} size={40} style={{ marginRight: 8 }} />
        {getClosedIssueTitle(modIssue, group)}
      </View>
      <Divider />
    </View>
  );
}
ClosedItem.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  modIssue: commonPropTypes.issue().isRequired,
};

const validActions = (contentAction, userAction) => {
  const validActions = Object.keys(MODERATION_ACTIONS);
  const contentValid = validActions.indexOf(contentAction);
  const userValid = validActions.indexOf(userAction);
  if (contentAction !== userAction) {
    console.log(`${contentAction}!==${userAction} is ${contentAction !== userAction}`);
  }
  console.log({ contentValid, userValid });
  return contentValid >= 0 && userValid >= 0 && contentAction !== userAction; // in case both are no_action
};

const getOriginalActions = (modIssue) => {
  const { action_taken, actions, status } = modIssue;
  if (status !== `closed`) return [MODERATION_ACTIONS.new_issue, MODERATION_ACTIONS.new_issue];
  if (isArray(actions) && actions.length) {
    const copy_actions = [...actions];
    const user_actions = copy_actions.filter((action) => action.type === MODERATION_TYPE.user).reverse();
    const last_user_action = user_actions.pop();
    const content_action = copy_actions.filter((action) => action.type !== MODERATION_TYPE.user).reverse();
    const last_content_action = content_action.pop();
    return [last_content_action ? last_content_action.action : null, last_user_action ? last_user_action.action : null];
  }
  return [action_taken, action_taken]; //theres no way to know with old actions
};

const HideKeyboard = ({ children }) => (
  <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>{children}</TouchableWithoutFeedback>
);

HideKeyboard.propTypes = {
  children: PropTypes.any.isRequired,
};

const localStyles = {
  sectionTitle: {
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    color: Colors.textGray,
  },
  listItemTitle: {
    fontFamily: `inter-semibold`,
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
    fontWeight: `500`,
  },
  listItemSubtitle: {
    fontSize: Typography.smallFontSize,
    color: Colors.textGray,
  },
  internalNotesTitle: {
    fontFamily: `inter-regular`,
    fontSize: Typography.smallFontSize,
    color: Colors.textGray,
    marginBottom: 10,
  },
  internalNotes: {
    padding: 10,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.gray,
    height: 69,
    textAlignVertical: `top`,
  },
};
