import { useMutation } from '@apollo/react-hooks';
import { useCallback, useState } from 'react';
import { CREATE_TOPICS, UPDATE_TOPIC, UPDATE_MY_FAVOR_TOPIC, DELETE_TOPIC } from '../../../graphql';

type CreateTopicInput = {
  group_id: string;
  topic: string;
};

type UpdateTopicInput = {
  id: string;
  input: {
    topic: string;
    active: boolean;
    hidden: boolean;
  };
};

type UpdateFavoriteTopicInput = {
  input: {
    topic_id: string;
    is_favorite: boolean;
    following: boolean;
  };
};

export const useTopics = () => {
  const [createTopicsMutation] = useMutation<CreateTopicInput>(CREATE_TOPICS);
  const [updateTopicMutation] = useMutation<UpdateTopicInput>(UPDATE_TOPIC);
  const [deleteTopicMutation] = useMutation<{ id: string }>(DELETE_TOPIC);

  const [updateTopicFavoriteMutation] = useMutation<UpdateFavoriteTopicInput>(UPDATE_MY_FAVOR_TOPIC);
  const [loading, setLoading] = useState(false);

  const createTopic = useCallback(
    async (groupID: string, topic: string) => {
      if (!groupID || !topic) return;
      try {
        setLoading(true);
        await createTopicsMutation({
          variables: { groupID, topic },
        });
      } catch (error) {
        console.error(`Error while creating Topic: ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [createTopicsMutation],
  );

  const updateTopic = useCallback(
    async (id: string, input: any) => {
      if (!id) return;
      try {
        setLoading(true);
        await updateTopicMutation({
          variables: {
            id,
            input,
          },
        });
      } catch (error) {
        console.error(`Error while updating Topic: ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [updateTopicMutation],
  );

  const deleteTopic = useCallback(
    async (id: string) => {
      if (!id) return;
      try {
        await deleteTopicMutation({
          variables: {
            id,
          },
        });
      } catch (error) {
        console.error(`Error while deleting Topic: ${error}`);
      }
    },
    [deleteTopicMutation],
  );

  const updateTopicFavorite = useCallback(
    async (input: { topic_id: string; following: boolean; is_favorite: boolean }) => {
      try {
        await updateTopicFavoriteMutation({
          variables: { input },
        });
      } catch (error) {
        console.error(`Error while updating my Favorite Topics: ${error}`);
      }
    },
    [updateTopicFavoriteMutation],
  );

  return {
    createTopic,
    updateTopic,
    deleteTopic,
    updateTopicFavorite,
    creating: loading,
  };
};
