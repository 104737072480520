export const REGISTER_USERNAME = `REGISTER_USERNAME`;
export const REGISTER_USERNAME_CONTINUE = `REGISTER_USERNAME_CONTINUE`;
export const REGISTERSCREEN = `REGISTERSCREEN`;

export const WELCOMESCREEN = `WELCOMESCREEN`;
export const WELCOMESCREEN_LOGIN = `WELCOMESCREEN_LOGIN`;
export const WELCOMESCREEN_CONTINUE_AS_GUEST = `WELCOMESCREEN_CONTINUE_AS_GUEST`;
export const WELCOMESCREEN_SIGNUP = `WELCOMESCREEN_SIGNUP`;

export const PASSWORDSCREEN = `PASSWORDSCREEN`;
export const PASSWORDSCREEN_PASSWORD = `PASSWORDSCREEN_PASSWORD`;
export const PASSWORDSCREEN_AGREE = `PASSWORDSCREEN_AGREE`;
export const CREATEPASSWORD = `CREATEPASSWORD`;
export const PASSWORDSCREEN_CONTINUE = ` PASSWORDSCREEN_CONTINUE`;

export const EMAILSCREEN_EMAIL = `EMAILSCREEN_EMAIL`;
export const EMAILSCREEN_SCREEN = `EMAILSCREEN_SCREEN`;
export const EMAILSCREEN_SENDCODE = `EMAILSCREEN_SENDCODE`;

export const VERIFICATION_SKIP = `VERIFICATION_SKIP`;

export const DOB_DD = `DOB_DD`;
export const DOB_MM = `DOB_MM`;
export const DOB_YYYY = `DOB_YYYY`;
export const DOB_OK = `DOB_OK`;

export const PROFILEPHOTOSCREEN = `PROFILEPHOTOSCREEN`;
export const PROFILEPHOTOSCREEN_PHOTO = `PROFILEPHOTOSCREEN_PHOTO`;
export const PROFILEPHOTOSCREEN_CONTINUE = `PROFILEPHOTOSCREEN_CONTINUE`;
export const PROFILEPHOTOSCREEN_SKIP = `PROFILEPHOTOSCREEN_SKIP`;
