import { NetworkStatus } from 'apollo-client';
import { useQuery } from 'react-apollo';
import { ActivityIndicator, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { useState, useEffect, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, globalStyles, Typography } from '../common-styles';
// import { EmptyNotifications } from '../images';
import { LoadingIndicator, MeshIcon, QueryError, SafeAreaView } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { client, NOTIFICATION_QUERY, FOLLOWING_NOTIFICATION_QUERY, GET_USER_FOLLOWERS } from '../graphql';
import { NotificationList } from './NotificationList';
import { showNotImplementedOptions } from './notificationsTempConstants';
import { NOTIFICATIONS_PER_FETCH } from '../../constants';
import { AppContext } from '../../AppContext';
import { FollowingNotificationList } from './FollowingNotificationList';
import { refreshFollowRequest, refreshNotification } from '../redux/followSlice';
import { useFollow } from '../community/v2/hooks/useFollow';

// TODO: Simplify component complexity and update to TS
// - separate follow and activity tabs in 2 components (1/2)
// - delegate callback to each tab if necessary
// eslint-disable-next-line complexity
export const NotificationsScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  const { user, notificationCount, followNotificationCount, setNotificationCount, setFollowNotificationCount } =
    React.useContext(AppContext);
  const [cacheExists, setCacheExists] = useState(false);
  const [status, setStatus] = useState(`activity`);
  const { isRefetchFollowRequest, isRefetchNotification } = useSelector((state) => state.follow);
  const { readFollowingNotification, makeSeenFollowingNotification } = useFollow();
  const markSeen = useCallback(() => {
    const markItemsAsSeen = async () => {
      console.log(`[InApp Notifications]: Focused, marking as seen`);
      try {
        setNotificationCount(0);
        await client.query({
          query: NOTIFICATION_QUERY,
          variables: {
            input: { feed_slug: `notification`, actor_id: user?.id },
            limit: NOTIFICATIONS_PER_FETCH,
            mark_seen: true,
          },
        });
      } catch (err) {
        console.error(`Error thrown in markSeen()`, err.message);
      }
    };

    const markFollowItemsAsSeen = async () => {
      try {
        setFollowNotificationCount(0);
        await makeSeenFollowingNotification();
      } catch (error) {
        console.error(`Error thrown in folowing markSeen()`, error.message);
      }
    };
    if (status === `activity`) markItemsAsSeen();
    else if (status === `following`) markFollowItemsAsSeen();
  }, [user?.id, setNotificationCount, setFollowNotificationCount, status, makeSeenFollowingNotification]);

  const markRead = async (id) => {
    try {
      const variables = {
        input: { feed_slug: `notification`, actor_id: user?.id },
        id_lte: id,
        id_gte: id,
        limit: NOTIFICATIONS_PER_FETCH,
        mark_read: [id],
        mark_seen: true,
      };
      const { data } = await client.query({ query: NOTIFICATION_QUERY, variables });
      const { GetNotificationsV2: cachedNotifications = [] } = client.readQuery({ query: NOTIFICATION_QUERY, variables });

      if (notificationCount > 0) setNotificationCount(notificationCount - 1);

      if (cachedNotifications.length) {
        const index = cachedNotifications.findIndex((n) => n.id === id);
        if (index >= 0) {
          const notification = { ...cachedNotifications[index], is_read: true };
          const new_list = [...cachedNotifications.slice(0, index), notification, ...cachedNotifications.slice(index + 1)];

          client.writeQuery({ query: NOTIFICATION_QUERY, variables, data: { GetNotificationsV2: new_list } });
        }
      }
      if (!data?.GetNotificationsV2?.length) console.log(`no data returned from _markRead() query`);
    } catch (err) {
      console.error(`Error thrown in _markRead()`, err.message);
    }
  };

  const markReadFollow = async (id) => {
    try {
      const variables = {
        input: {
          notifcation_id: id,
          isRead: true,
        },
      };
      await readFollowingNotification(id, true);
      const { data } = await client.query({ query: FOLLOWING_NOTIFICATION_QUERY, variables });
      const temp = client.readQuery({ query: FOLLOWING_NOTIFICATION_QUERY, variables });
      const cachedNotifications = temp?.getFollowingNotifications?.data;

      if (cachedNotifications.length) {
        const index = cachedNotifications.findIndex((n) => n.id === id);
        if (index >= 0) {
          const notification = { ...cachedNotifications[index], isRead: true };
          const new_list = [...cachedNotifications.slice(0, index), notification, ...cachedNotifications.slice(index + 1)];

          client.writeQuery({
            query: FOLLOWING_NOTIFICATION_QUERY,
            variables,
            data: {
              getFollowingNotifications: {
                __typename: `getFollowingNotifications`,
                data: new_list,
              },
            },
          });
        }
      }
      if (!data?.getFollowingNotifications?.data?.length) console.log(`no data returned from _markRead() for follow query`);
    } catch (error) {
      console.error(`Error thrown in _markRead() of Following Notification`, error?.message);
    }
  };

  const checkNotificationCache = useCallback(() => {
    try {
      const cachedData = client.readQuery({
        query: NOTIFICATION_QUERY,
        variables: { input: { feed_slug: `notification`, actor_id: user?.id }, limit: NOTIFICATIONS_PER_FETCH },
      });
      if (cachedData) setCacheExists(true);
    } catch (err) {
      console.error(`Error thrown reading cache for GetNotificationsV2`, err.message);
    }
  }, [user?.id]);

  const { loading, error, data, fetchMore, refetch, networkStatus } = useQuery(NOTIFICATION_QUERY, {
    variables: { input: { feed_slug: `notification`, actor_id: user?.id }, limit: NOTIFICATIONS_PER_FETCH },
    fetchPolicy: `cache-and-network`,
    notifyOnNetworkStatusChange: true,
    skip: !user?.id,
  });

  const {
    loading: followLoading,
    data: followData,
    error: followError,
    fetchMore: fetchMoreFollowing,
    refetch: refetchFollowing,
    networkStatus: followingNetworkStatus,
  } = useQuery(FOLLOWING_NOTIFICATION_QUERY, {
    fetchPolicy: `cache-and-network`,
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        limit: 15,
      },
    },
  });

  const {
    data: requestedFollowers,
    loading: requestedFollowerLoading,
    refetch: refetchFollowRequest,
  } = useQuery(GET_USER_FOLLOWERS, {
    fetchPolicy: `cache-and-network`,
    variables: {
      filter: {
        accepted: false,
      },
    },
  });

  useEffect(() => {
    const _refreshFollowRequest = async () => {
      try {
        await refetchFollowRequest();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(refreshFollowRequest(false));
      }
    };
    const _refreshNotification = async () => {
      try {
        await refetch();
        await refetchFollowRequest();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(refreshNotification(false));
      }
    };
    if (isRefetchFollowRequest) {
      _refreshFollowRequest();
    }
    if (isRefetchNotification) {
      _refreshNotification();
    }
  }, [dispatch, refetch, isRefetchFollowRequest, isRefetchNotification, refetchFollowRequest]);

  useEffect(() => {
    const notificationSettingBtn = (navigation) => (
      <TouchableOpacity onPress={() => navigation.navigate(`NotificationsSettings`)} style={localStyles.headerRightIcon}>
        <MeshIcon name="settings" size={20} color={Colors.iconColor} />
      </TouchableOpacity>
    );
    navigation.setOptions({
      title: `Notifications`,
      headerRight: () => !!showNotImplementedOptions && notificationSettingBtn(navigation),
    });
  }, [navigation]);

  useEffect(() => checkNotificationCache(), [checkNotificationCache]);

  useFocusEffect(markSeen);

  if (!cacheExists && (networkStatus === NetworkStatus.loading || followingNetworkStatus === NetworkStatus.loading)) {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
        <View style={globalStyles.centeredContainer}>
          <LoadingIndicator size="small" />
          <Text style={globalStyles.loadingText}>Loading</Text>
        </View>
      </SafeAreaView>
    );
  }

  if (error || followError)
    return (
      <QueryError
        error={error}
        networkStatus={networkStatus || followingNetworkStatus}
        refetch={status === `following` ? refetchFollowing : refetch}
      />
    );

  if (!user?.id || (!data?.GetNotificationsV2?.length && !followData?.getFollowingNotifications?.data?.length)) {
    return (
      //Could possibly use emptyList component instead but svg needs to be added to assets
      <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
        <View style={{ flex: 1, alignItems: `center`, paddingHorizontal: 43, paddingVertical: 48 }}>
          {/* <EmptyNotifications style={{ marginBottom: 32, marginTop: 100 }} width="288" height="220" /> */}
          <Image source={require(`../../assets/images/no_notifications.png`)} style={{ marginBottom: 32, marginTop: 100 }} />
          <View style={{ justifyContent: `center` }}>
            <Text style={{ ...Typography.text(`center`) }}>
              Welcome to Mesh! Your notifications will appear here when you recieve them.
            </Text>
          </View>
        </View>
      </SafeAreaView>
    );
    //<EmptyNotifications style={{ marginBottom: 32 }} width="250" height="250" />
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <View style={localStyles.butttonContent}>
        <TouchableOpacity onPress={() => setStatus(`activity`)} style={localStyles.buttonContainer} activeOpacity={0.8}>
          <View style={{ ...localStyles.buttonStyle, borderBottomWidth: status === `activity` ? 1 : 0 }}>
            <Text style={{ ...localStyles.buttonText, color: status === `activity` ? Colors.brandPurple : Colors.iconColor }}>
              Activity
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setStatus(`following`)} style={localStyles.buttonContainer} activeOpacity={0.8}>
          <View style={{ ...localStyles.buttonStyle, ...localStyles.row, borderBottomWidth: status === `following` ? 1 : 0 }}>
            <Text style={{ ...localStyles.buttonText, color: status === `following` ? Colors.brandPurple : Colors.iconColor }}>
              Following
            </Text>
            {followNotificationCount > 0 && <View style={localStyles.redDot} />}
          </View>
        </TouchableOpacity>
      </View>
      <View style={{ flex: 1, alignItems: `center`, backgroundColor: Colors.white }}>
        {status === `following` ? (
          <FollowingNotificationList
            data={followData}
            refetch={refetchFollowing}
            fetchMore={fetchMoreFollowing}
            networkStatus={followingNetworkStatus}
            loading={followLoading}
            markRead={markReadFollow}
          />
        ) : (
          <>
            {requestedFollowerLoading && <ActivityIndicator />}
            {!requestedFollowerLoading && requestedFollowers && requestedFollowers.getUserFollowers?.data?.length > 0 && (
              <TouchableOpacity
                style={{ width: `100%`, borderBottomWidth: 1, borderBottomColor: Colors.dividerColor }}
                onPress={() =>
                  navigation.navigate(`NotificationFollowRequest`, { data: requestedFollowers.getUserFollowers?.data })
                }
              >
                <View
                  style={{
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                    flexDirection: `row`,
                    justifyContent: `space-between`,
                    alignItems: `center`,
                  }}
                >
                  <Text style={{ fontSize: 14 }}>
                    {requestedFollowers.getUserFollowers?.data?.length} follow{` `}
                    {requestedFollowers.getUserFollowers?.data?.length > 1 ? `requests` : `request`}
                  </Text>
                  <MeshIcon name="chevron-right" color={Colors.iconColor} size={20} />
                </View>
              </TouchableOpacity>
            )}
            <NotificationList
              data={data}
              refetch={refetch}
              fetchMore={fetchMore}
              networkStatus={networkStatus}
              loading={loading}
              markRead={markRead}
            />
          </>
        )}
      </View>
    </SafeAreaView>
  );
};

NotificationsScreen.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

const localStyles = StyleSheet.create({
  headerRightIcon: {
    marginRight: 12,
    marginTop: 5,
    marginBottom: 5,
  },
  butttonContent: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 0.2,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: `center`,
  },
  buttonStyle: {
    borderBottomColor: Colors.brandPurple,
    padding: 10,
  },
  buttonText: {
    fontSize: Typography.baseFontSize,
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    textAlign: `center`,
  },
  row: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  redDot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginLeft: 5,
    marginBottom: 10,
    backgroundColor: Colors.badgeColor,
  },
});
