import React, { useMemo } from 'react';
import { StyleSheet, FlatList, Text, View, ListRenderItem } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useMutation } from 'react-apollo';
import ParsedText from 'react-native-parsed-text';
import { Colors, Typography } from '../common-styles';
import { Divider, MeshIcon, NoThemeButton, ThemedButton, Avatar, ListItem } from '../common-ui';
import { formatRelativeDate } from '../common-util';
import { APPROVE_APPLICATION, refetchQueriesFor, REJECT_APPLICATION } from '../graphql';
import { MeshVerifiedCheck } from '../images';
import { MODERATION_ACTIONS, MODERATION_TYPE } from './moderationEnums';
import { HomeNavigatorScreens } from '../common-types/navigation-types';
import { PendoTrackEvent, Events } from '../pendo/events';

type ApplicationReviewRoute = RouteProp<HomeNavigatorScreens, `ApplicationReview`>;

const actions: Record<string, string> = {
  reject_application: `rejected`,
  approve_application: `approved`,
  //new actions
  application_approve: `approved`,
  application_reject: `rejected`,
  application_ban: `rejected`,
};

type Application = {
  questions: Question[];
};
type Question = {
  key: string;
  value: string;
};

export const ApplicationReview: React.FC = () => {
  const route = useRoute<ApplicationReviewRoute>();
  const { application } = route.params || {};

  const renderQuestionsAndAnswers: ListRenderItem<Question> = ({ item, index }) => {
    if (item.value.startsWith(`[`) && item.value.endsWith(`]`)) {
      const response = JSON.parse(item.value);
      if (Array.isArray(JSON.parse(item.value)))
        return (
          <View key={item.key + index.toString()} style={localStyles.questionResponseContainer}>
            <Text style={localStyles.questionText}>{item.key}</Text>
            {response?.map((res: any, i: number) => (
              <View style={localStyles.multiResponseContainer} key={res + i.toString()}>
                <MeshIcon name="select-circle" focused color={Colors.brandPurple} size={20} />
                <Text style={[localStyles.responseText, { marginLeft: 6 }]}>{res}</Text>
              </View>
            ))}
          </View>
        );
    }
    return (
      <View key={item.key + index.toString()} style={localStyles.questionResponseContainer}>
        <Text style={localStyles.questionText}>{item.key}</Text>
        <View style={localStyles.textResponseContainer}>
          <Text style={localStyles.responseText}>{item.value}</Text>
        </View>
      </View>
    );
  };

  const keyExtractor = (item: Question) => item.key;

  return (
    <View style={{ flex: 1 }}>
      <FlatList<Question>
        ListHeaderComponent={ApplicationReviewHeader}
        ListFooterComponent={ApplicationReviewFooter}
        data={(application as Application)?.questions || []}
        keyExtractor={keyExtractor}
        renderItem={renderQuestionsAndAnswers}
      />
    </View>
  );
};

const ApplicationReviewFooter: React.FC = () => {
  const route = useRoute<ApplicationReviewRoute>();
  const navigation = useNavigation();
  const { status = `open`, user, application, group, moderationIssue } = route.params || {};
  const input = { issue_id: moderationIssue?.id, group_id: group?.id };

  const applicationLog = application
    ? JSON.stringify({
        photo: application?.photo ? `yes` : `no`,
        identity_id: application?.identity_id,
        full_name: application?.full_name,
        short_name: application?.short_name,
        questions: application?.questions?.length,
      })
    : ``;

  const [approveApplication] = useMutation(APPROVE_APPLICATION, {
    variables: { input },
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, `Group`, `GroupMember`),
    onCompleted: (_data) => {
      PendoTrackEvent(Events.MEMBER_APPROVAL, {
        community_name: group?.name,
        action: `approved`,
        has_application: application ? `yes` : `no`,
        Application: applicationLog,
        username: user?.handle,
      });
      navigation.goBack();
    },
    onError: (error) => console.error(`Error thrown in approveApplication()`, error.message),
  });
  const [rejectApplication] = useMutation(REJECT_APPLICATION, {
    variables: { input, ban_user: false },
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, `Group`, `GroupMember`),
    onCompleted: (data) => {
      const rejected = data.rejectApplication;
      PendoTrackEvent(Events.MEMBER_APPROVAL, {
        community_name: group?.name,
        action: rejected.action_taken === MODERATION_ACTIONS.application_ban ? `rejected and banned` : `rejected`,
        has_application: application ? `yes` : `no`,
        Application: applicationLog,
        username: user?.handle,
      });
      navigation.goBack();
    },
    onError: (error) => console.error(`Error thrown in rejectApplication()`, error.message),
  });

  if (status === `open`) {
    return (
      <View style={{ justifyContent: `center`, padding: 16 }}>
        <ThemedButton
          rounded
          title="Approve"
          titleStyle={localStyles.buttonTitle}
          leftIcon={<MeshIcon name="like" color={Colors.white} size={16} />}
          buttonStyle={{ paddingVertical: 12 }}
          onPress={approveApplication}
        />
        <NoThemeButton
          rounded
          color="rgba(234,35,35,0.15)"
          title="Deny"
          titleStyle={[localStyles.buttonTitle, localStyles.denyButton]}
          leftIcon={<MeshIcon name="dislike" color={Colors.brightRed} size={16} />}
          containerStyle={{ marginVertical: 10 }}
          buttonStyle={{ paddingVertical: 12 }}
          onPress={rejectApplication}
        />
        <NoThemeButton
          rounded
          color="rgba(234,35,35,0.15)"
          title="Deny and ban user"
          titleStyle={[localStyles.buttonTitle, localStyles.denyButton]}
          leftIcon={<MeshIcon name="dislike" color={Colors.brightRed} size={16} />}
          buttonStyle={{ paddingVertical: 12 }}
          onPress={() => rejectApplication({ variables: { input, ban_user: true } })}
        />
      </View>
    );
  }

  const { handle: modHandle } = moderationIssue?.moderator || {};
  const selected_action = actions[moderationIssue?.action_taken || ``];
  let message = `This application was ${selected_action} by ${modHandle || `Deleted User`}`;
  if (moderationIssue?.action_taken === MODERATION_TYPE.application_ban) {
    message += `and the user was banned`;
  }
  const customMatchers = [
    {
      pattern: new RegExp(moderationIssue?.moderator?.handle || `Deleted User`),
      style: Typography.text(`bold`, `warning`),
    },
    {
      pattern: new RegExp(selected_action || `undefined`),
      style: Typography.text(`bold`, `warning`),
    },
  ];
  return (
    <View style={localStyles.closedActions}>
      <ParsedText style={Typography.text(`warning`)} parse={customMatchers}>
        {message}
      </ParsedText>
    </View>
  );
};

const ApplicationReviewHeader: React.FC = () => {
  const route = useRoute<ApplicationReviewRoute>();
  const { user, moderationIssue, group } = route.params || {};
  const renderAvatar = useMemo(() => <Avatar border user={user} size={50} />, [user]);
  const renderUserHandle = useMemo(() => {
    if (!user.identity_verified) return user.handle.trim();
    return (
      <View style={{ flexDirection: `row` }}>
        <Text style={[localStyles.usernameText, { marginRight: 4 }]}>{user.handle.trim()}</Text>
        <MeshVerifiedCheck height={16} width={18} />
      </View>
    );
  }, [user?.identity_verified, user?.handle]);

  return (
    <View style={{ flex: 1 }}>
      <View style={localStyles.groupNameContainer}>
        <Text style={localStyles.groupName}>{group?.name?.trim()}</Text>
      </View>
      <Divider />
      <ListItem
        title={renderUserHandle}
        subtitle={formatRelativeDate(moderationIssue?.created_at)}
        titleStyle={localStyles.usernameText}
        subtitleStyle={{ ...Typography.smallText, color: Colors.textGray }}
        leftAvatar={renderAvatar}
      />
      <Divider />
    </View>
  );
};

const localStyles = StyleSheet.create({
  groupNameContainer: {
    padding: 16,
  },
  groupName: {
    fontFamily: `inter-semibold`,
    fontSize: 14,
    color: Colors.textBlack,
    fontWeight: `500`,
  },
  usernameText: {
    fontSize: 14,
    fontFamily: `inter-semibold`,
    color: Colors.brandPurple,
    fontWeight: `500`,
  },
  questionResponseContainer: {
    marginVertical: 8,
    marginHorizontal: 16,
  },
  questionText: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: `inter-semibold`,
    color: Colors.textGray,
    marginBottom: 10,
  },
  multiResponseContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    paddingVertical: 6,
  },
  textResponseContainer: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: Colors.backgroundColor,
  },
  responseText: {
    fontFamily: `inter-semibold`,
    fontSize: 14,
    color: Colors.textBlack,
    lineHeight: 24,
  },
  buttonTitle: {
    marginLeft: 9,
    fontSize: 15,
    fontWeight: `500`,
    fontFamily: `inter-semibold`,
  },
  denyButton: {
    color: Colors.brightRed,
  },
  closedActions: {
    flexDirection: `row`,
    padding: 16,
    backgroundColor: Colors.warningBackground,
    borderTopColor: Colors.dividerColor,
    borderTopWidth: 1,
  },
});
