import React, { useState, useEffect } from 'react';
import { Text, View, TextInput, Platform } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { commonPropTypes } from '../common-util';
import { APIHelper } from './helpers';
import { Colors, Typography } from '../common-styles';
import { ThemedButton, LoadingIndicator, MeshIcon } from '../common-ui';
import { setting_customer_id } from './creditCardsHelpers';
import { UPDATE_SETTING_MUTATION } from '../graphql';
import { logJsonObj } from '../common-util/logJsonObj';
import { setCustomer, setPaymentMethods, setDefaultPaymentMethod } from '../redux/contributionSlice';
import { AppContext } from '../../AppContext';
import { CC_NUMBER, CC_ZIP, CC_EXPIRATION, CC_SAVE } from './testLabels';

export const AddPaymentMethod = ({ navigation }) => {
  const { customer, group, email, paymentMethods: storedPaymentMethods } = useSelector((state) => state.contributions);
  const dispatch = useDispatch();
  const { user } = React.useContext(AppContext);

  const [idmUpdateKey, setidmUpdateKey] = useState(null);
  const [cardNumber, setCardNumber] = useState(``);
  const [expirationDate, setExpirationDate] = useState(``);
  const [formatedExpirationDate, setFormatedExpirationDate] = useState(``);
  const [zipcode, setZipcode] = useState(``);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idmCreatePMethod, setIdmCreatePMethod] = useState(null);
  const [idmCreateSetupIntent, setIdmCreateSetupIntent] = useState(null);

  const [errorObj, setErrorObj] = useState({
    field: null,
    message: ``,
  });

  const [updateCustomer] = useMutation(UPDATE_SETTING_MUTATION);
  // awaitRefetchQueries: [
  //   {
  //     query: SETTING_QUERY,
  //     variables: {
  //       settingQuery: {
  //         query: `user`,
  //         ...userOrGroupId,
  //       },
  //     },
  //   },
  // ],

  useEffect(() => {
    setIdmCreatePMethod(uuidv4());
    setIdmCreateSetupIntent(uuidv4());
    setidmUpdateKey(uuidv4());
  }, [cardNumber, expirationDate, zipcode]);

  useEffect(() => {
    if (cardNumber && cardNumber.length >= 14 && expirationDate && expirationDate.length > 3 && zipcode && zipcode.length) {
      setButtonEnabled(true);
    } else setButtonEnabled(false);
  }, [cardNumber, expirationDate, zipcode]);

  const clearNumber = (value = ``) => value.replace(/\D+/g, ``);

  const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
    if (clearValue.length >= 4) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
  };

  const onChangeTextHandler = (text, fieldIndex) => {
    if (fieldIndex === 1) setCardNumber(text);
    else if (fieldIndex === 2) {
      setExpirationDate(text);
      const formated = formatExpirationDate(text);
      setFormatedExpirationDate(formated);
    } else setZipcode(text);
  };

  const savePaymentMethod = async () => {
    setLoading(true);
    setErrorObj({ field: null, message: `` });
    try {
      const exp_month = expirationDate.slice(0, 2);
      const exp_year = expirationDate.slice(2, 4);

      const cardDetails = {
        number: cardNumber,

        exp_month,
        exp_year,
        postal_code: zipcode,
      };

      const paymentMethodResult = await APIHelper(`payments/create/`, `POST`, idmCreatePMethod, cardDetails);

      if (paymentMethodResult.status === `error`) {
        const { error } = paymentMethodResult;
        const { raw } = error;
        const { code, message } = raw;
        switch (code) {
          case `incorrect_number`:
            setErrorObj({ field: 1, message });
            break;

          case `invalid_expiry_year`:
          case `invalid_expiry_month`:
            setErrorObj({ field: 2, message });
            break;

          case `invalid_cvc`:
          case `incorrect_cvc`:
            setErrorObj({ field: 3, message });
            break;

          case `card_declined`:
            setErrorObj({ field: 1, message: `Invalid Card` });
            break;

          default:
            break;
        }
      } else if (paymentMethodResult.status === `ok`) {
        setErrorObj({ field: null, message: `` });

        //Creating the setup intent
        //No await needed because the createPaymentMethodResult declaration already has the await indication
        const { paymentMethod } = paymentMethodResult;
        const setupIntentPayload = {
          customer_id: customer?.id || ``,
          payment_method: paymentMethod?.id,
          user: {
            id: user?.id,
            name: user?.name,
            description: user?.description,
            email,
          },
        };

        if (group && group.id) setupIntentPayload.group_id = group.id;

        // logJsonObj(setupIntentPayload, `setupIntentPayload ---> `);

        // logJsonObj(paymentMethod, `paymentMethod ---> `);

        const setupIntentResponse = await APIHelper(
          `payments/create-setup-intent/`,
          `POST`,
          idmCreateSetupIntent,
          setupIntentPayload,
        );

        if (setupIntentResponse.error) {
          setErrorObj({ message: setupIntentResponse.error?.raw?.message });
        } else {
          const { paymentMethods, customer: customerResponse } = setupIntentResponse;

          // logJsonObj(paymentMethodResult, `paymentMethodResult ===> `);
          // logJsonObj(customerResponse, `customerresponse  ===> `);

          if (paymentMethodResult.status === `ok`) {
            //Uncomment to clear the value of the customer_id in the user settings
            // const input = {
            //   id: setting_customer_id,
            //   key: `user.setting.customer_id`,
            //   value: ``,
            //   user_id: user?.id,
            // }

            if (!customer) {
              console.log(`== creating a new customer ==`);
              const input = {
                id: setting_customer_id,
                key: `user.setting.customer_id`,
                value: customerResponse?.id,
                user_id: user?.id,
              };

              const { data } = await updateCustomer({
                variables: { input },
              });

              // setCustomer(customerResponse);
              // setCustomerId(customerResponse?.id);
              dispatch(setCustomer(customerResponse));

              logJsonObj(data);
            }
            // const { data } = await updateCustomer({
            //   variables: { input },
            // });

            // setPaymentMethods(paymentMethods?.data);

            if (storedPaymentMethods?.length === 0) {
              const customerBody = {
                customer: {
                  invoice_settings: {
                    custom_fields: ``,
                    default_payment_method: paymentMethods?.data[0].id,
                  },
                },
              };
              const _updatedResult = await APIHelper(`customers/update/${customer?.id}`, `POST`, idmUpdateKey, customerBody);
              dispatch(setDefaultPaymentMethod(paymentMethods?.data[0]));
            }

            dispatch(setPaymentMethods(paymentMethods?.data));
            navigation.goBack();
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <View style={{ marginHorizontal: 16 }}>
      <Text style={{ ...Typography.text(`bold`), marginTop: 21 }}>Card Number</Text>
      <View style={errorObj && errorObj.field === 1 ? localStyles.InputContainerStyleInvalid : localStyles.InputContainerStyle}>
        <TextInput
          autoFocus
          style={localStyles.cardInputStyle}
          keyboardType="numeric"
          placeholder="0000 0000 0000 0000"
          placeholderTextColor={Colors.textPlaceholder}
          onChangeText={(text) => onChangeTextHandler(text, 1)}
          value={cardNumber}
          maxLength={16}
          testID={CC_NUMBER}
        />
        {errorObj && errorObj.field === 1 && (
          <MeshIcon name="info-circle" color={Colors.brightRed} style={{ marginLeft: 12, padding: 0 }} size={18} />
        )}
      </View>

      <View style={{ flexDirection: `row`, justifyContent: `space-between` }}>
        <View style={{ flexDirection: `column` }}>
          <Text style={{ ...Typography.text(`bold`), marginTop: 17 }}>Expiration</Text>
          <View
            style={[
              errorObj && errorObj.field === 2 ? localStyles.InputContainerStyleInvalid : localStyles.InputContainerStyle,
              { width: 150 },
            ]}
          >
            <TextInput
              style={localStyles.cardInputStyle}
              keyboardType="numeric"
              placeholder="MM/YY"
              placeholderTextColor={Colors.textPlaceholder}
              testID={CC_EXPIRATION}
              onChangeText={(text) => onChangeTextHandler(text, 2)}
              value={formatedExpirationDate}
              maxLength={Platform.OS === `android` ? 5 : 4}
            />
            {errorObj && errorObj.field === 2 && (
              <MeshIcon name="info-circle" color={Colors.brightRed} style={{ marginLeft: 12, padding: 0 }} size={18} />
            )}
          </View>
        </View>
        <View style={{ flexDirection: `column` }}>
          <Text style={{ ...Typography.text(`bold`), marginTop: 17 }}>Zip Code</Text>
          <View style={[localStyles.InputContainerStyle, { width: 150 }]}>
            <TextInput
              style={localStyles.cardInputStyle}
              onChangeText={(text) => onChangeTextHandler(text, 4)}
              value={zipcode}
              keyboardType="numeric"
              maxLength={10}
              testID={CC_ZIP}
            />
          </View>
        </View>
      </View>

      {errorObj && errorObj.message.trim().length > 0 && (
        <Text style={{ textAlign: `center`, marginTop: 20, ...Typography.text(`bold`, `base`, `alert`) }}>
          {errorObj.message}
        </Text>
      )}

      <ThemedButton
        rounded
        title="Save"
        testID={CC_SAVE}
        onPress={savePaymentMethod}
        buttonStyle={{ padding: 15 }}
        containerStyle={{ marginTop: 40 }}
        titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`) }}
        leftIcon={loading && <LoadingIndicator size={18} style={{ marginHorizontal: 12 }} />}
        disabled={loading || !buttonEnabled}
      />
    </View>
  );
};

AddPaymentMethod.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

const localStyles = {
  cardInputStyle: { flex: 1, padding: 0, paddingLeft: 12 },
  InputContainerStyle: {
    flex: 1,
    marginTop: 7,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.taupe,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  InputContainerStyleInvalid: {
    flex: 1,
    marginTop: 7,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.brightRed,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};
