import { TextInput, View, ScrollView, Text, Linking } from 'react-native';
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ParsedText from 'react-native-parsed-text';
import { v4 as uuidv4 } from 'uuid';
import { Colors, Typography } from '../common-styles';
import { KeyboardPaddingView, SafeAreaView, NoThemeButton, ThemedButton, ThemedDeleteButton } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { addGuideline, editGuideline, removeGuideline } from '../redux/guidelinesSlice';

export const EditCommunityGuideline = React.memo(({ navigation, route }) => {
  const { guideline: original_guideline } = route?.params || {};
  const { id = uuidv4(), title = ``, body = `` } = original_guideline || {};
  const dispatch = useDispatch();
  const [guideline, setGuideline] = useState({
    id,
    title,
    body,
  });

  const openMeshGroundRules = () => {
    Linking.openURL(`https://www.meshcommunities.us/ground-rules`);
  };

  const deleteGuideline = () => {
    console.log(`deleteGuideline -> guideline`, guideline.id);
    dispatch(removeGuideline(guideline));
    navigation.goBack();
  };

  const save = useCallback(() => {
    if (original_guideline) dispatch(editGuideline(guideline));
    else dispatch(addGuideline(guideline));
    // TODO: network save
    navigation.goBack();
  }, [original_guideline, dispatch, guideline, navigation]);

  React.useEffect(() => {
    const disabled =
      !guideline ||
      !guideline?.title.trim().length ||
      (guideline?.title.trim() === original_guideline?.title.trim() &&
        guideline?.body.trim() === original_guideline?.body.trim());
    navigation.setOptions({
      headerLeft: () => (
        <NoThemeButton
          clear
          title="Cancel"
          color={Colors.iconColor}
          onPress={() => navigation.goBack()}
          containerStyle={{ left: 10 }}
        />
      ),
      title: `${original_guideline ? `Edit guideline` : `Add guideline`}`,
      headerRight: () => (
        <ThemedButton
          clear
          title="Done"
          testID="SAVE_GUIDELINE"
          disabled={disabled}
          onPress={() => save()}
          containerStyle={{ right: 10 }}
        />
      ),
    });
  }, [guideline, navigation, original_guideline, save]);

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView alwaysBounceVertical={false}>
          <View style={{ flex: 1, marginHorizontal: 16 }}>
            <View style={localStyles.greyInfoContainer}>
              <ParsedText
                style={{ fontSize: 16, color: Colors.darkGray }}
                parse={[
                  {
                    pattern: /Read more/,
                    style: { color: Colors.brandPurple, fontWeight: `bold`, fontSize: 16 },
                    onPress: openMeshGroundRules,
                  },
                ]}
                // eslint-disable-next-line react-native/no-raw-text
              >
                Create a guideline for your node. In addition, your node must adhere to Mesh’s Ground Rules. Read more
              </ParsedText>
            </View>
            <View style={{ marginTop: 20 }}>
              <Text style={{ ...Typography.text(`gray`) }}>Subject</Text>
              <TextInput
                autoFocus
                multiline
                value={guideline.title}
                testID="GUIDELINE_TITLE_INPUT"
                placeholder="Ex: Guideline for post approval"
                placeholderTextColor={Colors.textPlaceholder}
                style={[localStyles.inputStyle, { marginBottom: 16 }]}
                onChangeText={(text) => setGuideline((prev) => ({ ...prev, title: text }))}
              />
              <Text style={{ ...Typography.text(`gray`), marginBottom: 6 }}>Code description</Text>
              <TextInput
                multiline
                value={guideline.body}
                testID="GUIDELINE_DESC_INPUT"
                placeholder="Describe guideline"
                placeholderTextColor={Colors.textPlaceholder}
                autoCompleteType="off"
                scrollEnabled={false}
                style={[localStyles.textInput, { flex: 0, minHeight: 100, textAlignVertical: `top` }]}
                onChangeText={(text) => setGuideline((prev) => ({ ...prev, body: text }))}
              />
              <Text style={{ marginTop: 12, color: Colors.darkGray, marginBottom: 20 }}>
                Example: If your node requires posts to be approved by admins, or if you only allow content on a specific topic,
                you can let people know here.
              </Text>
              {original_guideline && (
                <ThemedDeleteButton
                  rounded
                  containerStyle={{ marginHorizontal: 16, marginBottom: 50 }}
                  buttonStyle={{ flex: 1, height: 40 }}
                  title="Delete guideline"
                  testID="DELETE_GUIDELINE"
                  onPress={deleteGuideline}
                  titleStyle={Typography.text(`bold`, `plusone`)}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
});

EditCommunityGuideline.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

/*export class _EditCommunityGuideline extends React.PureComponent {
  static propTypes = {
    navigation: commonPropTypes.navigation().isRequired,
    route: commonPropTypes.route().isRequired,
  };

  static navigationOptions = ({ navigation, route }) => {
    const { isEditingGuideline, saveEnabled = false } = route.params;
    const editCommunityGuideline = navigation.getParam(`EditCommunityGuideline`); //<-- that

    return {
      cardStyle: { backgroundColor: `white` },
      headerLeft: () => (
        <NoThemeButton
          clear
          title="Cancel"
          color={Colors.iconColor}
          onPress={() => navigation.goBack()}
          containerStyle={{ left: 10 }}
        />
      ),
      title: `${isEditingGuideline ? `Edit guideline` : `Add guideline`}`,
      headerRight: () => (
        <ThemedButton
          clear
          testID="SAVE_GUIDELINE"
          title="Done"
          onPress={() => editCommunityGuideline.onSave()}
          containerStyle={{ right: 10 }}
          disabled={!saveEnabled}
        />
      ),
    };
  };

  constructor(props) {
    super(props);
    const { navigation, route } = this.props;
    const { isEditingGuideline, guideline } = route.params;
    const isEdit = isEditingGuideline && guideline;
    this.state = {
      customGuideline: {
        title: isEdit ? guideline.title : ``,
        body: isEdit ? guideline.body : ``,
        id: isEdit ? guideline.id : ``,
      },
    };
    navigation.setParams({ saveEnabled: false, EditCommunityGuideline: this });
  }

  updateSaveEnabled = () => {
    const { navigation } = this.props;
    const { customGuideline } = this.state;
    let saveEnabled = true;

    if (customGuideline.title.trim().length === 0) {
      saveEnabled = false;
    }
    navigation.setParams({ saveEnabled });
  };

  onSave = () => {
    const { navigation, route } = this.props;
    const { customGuideline } = this.state;
    const { isEditingGuideline, guidelineIndex } = route.params;
    const addGuideline = navigation.getParam(`addGuideline`, undefined);
    const editGuideline = navigation.getParam(`editGuideline`, undefined);

    if (customGuideline.title.trim() === ``) {
      console.log(`subject is required`);
      return;
    }

    if (isEditingGuideline) {
      editGuideline(customGuideline, guidelineIndex);
    } else {
      addGuideline(customGuideline);
    }
    navigation.goBack();
  };

  changeTitle = (title) => {
    this.setState((prev) => {
      return {
        customGuideline: { ...prev.customGuideline, title },
      };
    });
    setTimeout(() => {
      this.updateSaveEnabled();
    });
  };

  changeBody = (body) => {
    this.setState((prev) => {
      return {
        customGuideline: { ...prev.customGuideline, body },
      };
    });
    setTimeout(() => {
      this.updateSaveEnabled();
    }, 500);
  };

  openMeshCodeOfConduct = () => {
    Linking.openURL(`https://www.meshcommunities.us/code-of-conduct`);
  };

  removeGuideline = () => {
    const { navigation, route } = this.props;
    const { isEditingGuideline, guidelineIndex } = route.params;
    const removeGuideline = navigation.getParam(`removeGuideline`);
    if (isEditingGuideline) {
      removeGuideline(guidelineIndex);
      navigation.goBack();
    } else {
      navigation.goBack();
    }
  };

  render = () => {
    const { customGuideline } = this.state;

    return (
      <KeyboardPaddingView>
        <SafeAreaView style={{ flex: 1 }}>
          <ScrollView alwaysBounceVertical={false}>
            <View style={{ flex: 1, marginHorizontal: 16 }}>
              <View style={{ marginTop: 20 }}>
                <Text style={{ fontSize: 16, color: Colors.darkGray }}>
                  Create a guideline for your community. In addition, your community must adhere to Mesh’s Code of Conduct.{` `}
                  <Text
                    onPress={this.openMeshCodeOfConduct}
                    style={{ color: Colors.brandPurple, fontWeight: `bold`, fontSize: 16 }}
                  >
                    Read more
                  </Text>
                </Text>
              </View>
              <View style={{ marginTop: 20 }}>
                <Text style={{ ...Typography.text(`gray`) }}>Subject</Text>
                <TextInput
                  autoFocus
                  testID="GUIDELINE_TITLE_INPUT"
                  placeholder="Ex: Guideline for post approval"
                  style={[localStyles.inputStyle, { marginBottom: 16 }]}
                  value={customGuideline.title}
                  onChangeText={this.changeTitle}
                  multiline
                />
                <Text style={{ ...Typography.text(`gray`), marginBottom: 6 }}>Code description</Text>
                <TextInput
                  multiline
                  testID="GUIDELINE_DESC_INPUT"
                  autoCompleteType="off"
                  scrollEnabled={false}
                  placeholder="Describe guideline"
                  style={[localStyles.textInput, { flex: 0, minHeight: 100, textAlignVertical: `top` }]}
                  value={customGuideline.body}
                  onChangeText={this.changeBody}
                />
                <Text style={{ marginTop: 12, color: Colors.darkGray, marginBottom: 20 }}>
                  Examples: If your community requires posts to be approved by community leaders or mods, you can let people know
                  here.
                </Text>
                <ThemedDeleteButton
                  rounded
                  containerStyle={{ marginHorizontal: 16, marginBottom: 50 }}
                  buttonStyle={{ height: 40, width: `100%` }}
                  title="Delete guideline"
                  testID="DELETE_GUIDELINE"
                  onPress={this.removeGuideline}
                  titleStyle={{ ...Typography.text(`bold`, `plusone`) }}
                />
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      </KeyboardPaddingView>
    );
  };
}*/

//#region constants and styles
const localStyles = {
  inputStyle: {
    borderRadius: 4,
    borderColor: Colors.dividerColor,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  textInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: `#D8D8D8`,
    padding: 10,
    borderRadius: 4,
    minHeight: 80,
    ...Typography.text(),
  },
  greyInfoContainer: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: Colors.lightGray,
    margin: 10,
  },
};
//#endregion
