import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { GroupSponsor } from '../../common-types/types';
import { Colors } from '../../common-styles';
import { formatDate } from '../../common-util/dateUtil';
import { PendoTrackEvent, Events } from '../../pendo/events';
import { openUrlWithUtm } from '../../ad/Ad';

interface Props {
  sponsor?: GroupSponsor;
  community_name?: string;
}

const CommunitySponsor: React.FC<Props> = ({ sponsor, community_name = `` }) => {
  if (!sponsor?.id) return null;

  const onPressLink = () => {
    if (!sponsor?.link_url) return;
    const now = new Date();
    PendoTrackEvent(Events.COMMUNITY_SPONSOR_CLICK, {
      sponsor: sponsor.name,
      node: community_name,
      link: sponsor.link_url,
      time: formatDate(now, `MMM dd yyyy, h:mmTT`),
    });
    openUrlWithUtm(sponsor.link_url, community_name, sponsor.name);
  };

  return (
    <View style={styles.sponsorWrapper}>
      <View style={styles.sponsorContent}>
        <Text style={styles.sponsorLabel}>Sponsored by</Text>
        <Text onPress={onPressLink} style={styles.sponsorName}>
          {sponsor?.name}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sponsorWrapper: {
    display: `flex`,
    alignItems: `center`,
    flexDirection: `row`,
    justifyContent: `center`,
  },
  sponsorContent: {
    marginLeft: 10,
    display: `flex`,
    alignItems: `center`,
    flexDirection: `row`,
    borderRadius: 40,
    paddingVertical: 2,
  },
  sponsorLabel: {
    fontSize: 10,
    marginRight: 4,
    color: Colors.brandBlack,
    fontWeight: `600`,
  },
  sponsorName: {
    fontSize: 10,
    color: Colors.brandPurple,
    fontWeight: `600`,
  },
});

export default CommunitySponsor;
