/* eslint-disable */
import gql from 'graphql-tag';
import {
  //GROUP_CAROUSEL_FRAGMENT,
  ACTIVITY_DETAILS_FRAGMENT,
  AD_DETAILS_FRAGMENT,
  CUSTOM_QUESTION_DETAILS_FRAGMENT,
  GROUP_DETAILS_FRAGMENT,
  GROUP_INVITATION_DETAILS_FRAGMENT,
  GROUP_MEMBER_DETAILS_FRAGMENT,
  //MODERATED_GROUP_DETAILS_FRAGMENT,
  GROUP_CORE_DETAILS_FRAGMENT,
  MODERATED_GROUP_DETAILS_2_FRAGMENT,
  MODERATED_GROUP_MEMBERS_FRAGMENT,
  MODERATION_ISSUE_DETAILS_FRAGMENT,
  MODERATION_NOTIFICATION_DETAILS_FRAGMENT,
  NOTIFICATION_DETAILS_FRAGMENT,
  NOTIFICATION_REACTION_DETAILS_FRAGMENT,
  PERSONA_RELATIONSHIP_FRAGMENT,
  REACTION_DETAILS_FRAGMENT,
  SETTING_DETAILS_FRAGMENT,
  USER_DETAILS_FRAGMENT,
  USER_MENTION_FRAGMENT,
  TOPIC_GROUP_CORE_DETAILS_FRAGMENT,
  PROFILE_ACTIVITY_DETAILS_FRAGMENT,
} from './Fragments.gql';

//#region auth

export const VERSION_CHECK = gql`
  query versionCheck($input: VersionCheckInput!) {
    versionCheck(input: $input) {
      latest_version
      latest_publish
      latest_hash
    }
  }
`;

export const CHECK_USER = gql`
  query checkUser($contact: String!) {
    checkUser(contact: $contact)
  }
`;

export const CHECK_ALPHA = gql`
  query checkAlpha($input: CheckAlphaInput) {
    CheckAlphaV2(input: $input) {
      salts
      beta_type
    }
  }
`;

export const CHECK_BETA = gql`
  query checkBeta($input: [CheckInput], $supportsV2: Boolean) {
    CheckBeta(input: $input, supportsV2: $supportsV2) {
      id
      has_password # false if account creation has not been completed, true otherwise
      has_personas # false if persona creation has not been completed, true otherwise
    }
  }
`;

export const CHECK_MATCHING_PASSWORD = gql`
  query checkMatchingPassword($identity_id: ID!, $current_password: String!) {
    checkMatchingPassword(identity_id: $identity_id, current_password: $current_password) {
      passwordMatch
    }
  }
`;

export const GET_PERSONA = gql`
  query getPersona($id: ID!) {
    getPersona(id: $id) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const CHECK_VALID_MENTIONS = gql`
  query checkValidMentions3($input: CheckValidMentionInput2!) {
    checkValidMentions3(input: $input) {
      validated_users {
        id
        name
        handle
      }
    }
  }
`;

export const CHECK_AGREED_TERMS = gql`
  query checkAgreedTerms($identity_id: ID!) {
    checkAgreedTerms(identity_id: $identity_id) {
      id
      agreed_terms
    }
  }
`;

export const GET_SECURE_STORE = gql`
  query getSecureStore($id: ID!) {
    GetSecureStore(id: $id) {
      secure_store
    }
  }
`;

export const VERIFY_RECOVERY_TOKEN = gql`
  query verifyRecoveryToken($id: ID, $token: String) {
    verifyRecoveryToken(id: $id, token: $token) {
      valid_token
      expired
    }
  }
`;

//#endregion

//#region notifications
export const NOTIFICATION_QUERY = gql`
  query GetNotificationsV2(
    $input: FeedInput!
    $foreign_id: String
    $limit: Int
    $id_gte: String
    $id_gt: String
    $id_lt: String
    $id_lte: String
    $offset: Int
    $enrich: Boolean
    $reactions: ReactionActivityInput
    $mark_seen: Boolean
    $mark_read: [String]
  ) {
    GetNotificationsV2(
      input: $input
      foreign_id: $foreign_id
      limit: $limit
      id_gte: $id_gte
      id_gt: $id_gt
      id_lte: $id_lte
      id_lt: $id_lt
      offset: $offset
      enrich: $enrich
      reactions: $reactions
      mark_seen: $mark_seen
      mark_read: $mark_read
    ) {
      ...NotificationDetails
      activities {
        __typename
        ... on NotificationReaction {
          ...NotificationReactionDetails
        }
        __typename
        ... on Activity {
          ...ActivityDetails
        }
        __typename
        ... on ModerationNotification {
          ...ModerationNotificationDetails
        }
      }
    }
  }
  ${NOTIFICATION_DETAILS_FRAGMENT}
  ${NOTIFICATION_REACTION_DETAILS_FRAGMENT}
  ${ACTIVITY_DETAILS_FRAGMENT}
  ${MODERATION_NOTIFICATION_DETAILS_FRAGMENT}
`;

export const FOLLOWING_NOTIFICATION_QUERY = gql`
  query getFollowingNotifications($filter: GetFollowingNotificationsFilterInput) {
    getFollowingNotifications(filter: $filter) {
      data {
        id
        actor {
          id
          name
          handle
          avatar_url
          pronouns
          isVerified
        }
        activity_type
        activity_at
        group {
          id
          name
        }
        isRead
        activity_id
        unseen
        as_community
      }
    }
  }
`;
//#endregion

//#region settings

export const SETTING_QUERY = gql`
  query getSettings($settingQuery: SettingQueryInput) {
    getSettings(settingQuery: $settingQuery) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const GET_TIP_JAR = gql`
  query getTipJar($group_id: ID) {
    getTipJar(group_id: $group_id) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const SETTING_OPTIONS = gql`
  query getSettingOptions($settingQuery: SettingQueryInput) {
    getSettingOptions(settingQuery: $settingQuery) {
      key
      options {
        id
        label
        value
        detail
      }
    }
  }
`;

export const GROUP_CREATE_SETTINGS = gql`
  query getGroupCreationSettings {
    getGroupCreationSettings {
      id
      key
      label
      value
      value_label
      index
      section
      type
      visibility
    }
  }
`;

export const USER_NOTIFY_POST_SETTINGS = gql`
  query getUserCommunityPostNotificationSettings($group_id: ID) {
    getUserCommunityPostNotificationSettings(group_id: $group_id) {
      ...SettingDetails
      group {
        ...GroupDetails
      }
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
  ${GROUP_DETAILS_FRAGMENT}
`;

export const CHECK_HANDLE = gql`
  query checkHandle($handle: String!) {
    checkHandleAvailability(handle: $handle) {
      is_valid
    }
  }
`;

export const GET_OWN_BLOCKED_USERS_V2 = gql`
  query getOwnBlockedUsersV2 {
    getOwnBlockedUsersV2 {
      ...PersonaRelationshipFragment
    }
  }
  ${PERSONA_RELATIONSHIP_FRAGMENT}
`;

export const GET_OWN_HIDDEN_COMMUNITIES = gql`
  query getHiddenCommunities($user_id: ID!) {
    getHiddenCommunities(user_id: $user_id) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const GET_OWN_ARCHIVED_COMMUNITIES = gql`
  query getArchivedCommunities($user_id: ID!) {
    getArchivedCommunities(user_id: $user_id) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const COMMUNITY_SETTING = gql`
  query getCommunitySetting($input: CommunitySettingInput) {
    getCommunitySetting(input: $input) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const GET_CUSTOM_REACTIONS = gql`
  query getUploadedReactions($group_id: ID!) {
    getUploadedReactions(group_id: $group_id) {
      name
      url
    }
  }
`;

//#endregion

//#region groups

export const CHECK_COMMUNITY_NAME_AVAILABLE = gql`
  query checkCommunityNameAvailability($name: String!) {
    checkCommunityNameAvailability(name: $name) {
      available
    }
  }
`;

export const GET_GROUP = gql`
  query getGroup($group_id: ID!) {
    getGroup(group_id: $group_id) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const GET_INVITATION = gql`
  query getInvitation($invite_token: String!) {
    getInvitation(invite_token: $invite_token) {
      group_invitation {
        ...GroupInvitationDetails
      }
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
  ${GROUP_INVITATION_DETAILS_FRAGMENT}
`;

/**
 * version 1: url links without named source
 * version 2: url links with named source
 */
export const GET_PROFILE_INVITE = gql`
  query getProfileInvite($user_id: ID!, $version: Int) {
    getProfileInvite(user_id: $user_id, version: $version) {
      invite_url
    }
  }
`;

export const LIST_POSTABLE_GROUPS = gql`
  query listPostableGroups {
    listJoinedGroups {
      group {
        ...GroupDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const LIST_GROUPS = gql`
  query listGroups {
    listInvitedGroups {
      group {
        ...GroupCoreDetails
      }
    }
    listJoinedGroups {
      group {
        ...GroupCoreDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_CORE_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const LIST_OWNED_GROUPS = gql`
  query listOwnedGroups($user_id: ID!) {
    listOwnedGroups(user_id: $user_id) {
      group {
        ...GroupDetails
      }
      owner_count
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const PENDING_COMMUNITY_LIST = gql`
  query listPendingCommunities($user_id: ID!) {
    listPendingCommunities(user_id: $user_id) {
      ...GroupCoreDetails
    }
  }
  ${GROUP_CORE_DETAILS_FRAGMENT}
`;
// export const LIST_MY_COMMUNITIES = gql`
//   query listGroups {
//     listJoinedGroups {
//       group {
//         ...GroupCarousel
//       }
//     }
//   }
//   ${GROUP_CAROUSEL_FRAGMENT}
// `;

export const GET_COMMUNITY_APPLICATION = gql`
  query getCommunityApplication($group_id: ID!) {
    getCommunityApplication(group_id: $group_id) {
      community_application {
        questions {
          ...CustomQuestionDetails
        }
      }
    }
  }
  ${CUSTOM_QUESTION_DETAILS_FRAGMENT}
`;

export const LIST_GROUP_MEMBERS = gql`
  query listGroupMembers($group_id: ID!, $offset: Int, $limit: Int) {
    listGroupMembers(group_id: $group_id, offset: $offset, limit: $limit) {
      ...GroupMemberDetails
    }
  }
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const LIST_MEMBERS_AND_COUNTS = gql`
  query listGroupMembersWithMembersCount($group_id: ID!, $offset: Int, $limit: Int) {
    listGroupMembers(group_id: $group_id, offset: $offset, limit: $limit) {
      ...GroupMemberDetails
    }
    getGroupMembersCount(group_id: $group_id) {
      statusCode
      message
      data {
        group_id
        role_id
        role_name
        counts
      }
    }
  }
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const LIST_BANNED_MEMBERS = gql`
  query listBannedMembers($group_id: ID!, $offset: Int, $limit: Int) {
    listBannedMembers(group_id: $group_id, offset: $offset, limit: $limit) {
      ...GroupMemberDetails
    }
  }
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const GET_USERS_BY_NAME = gql`
  query getUsersByName($user_name: String!, $user_id: ID!, $group_id: ID!, $offset: Int, $limit: Int) {
    getUsersByName(user_name: $user_name, user_id: $user_id, group_id: $group_id, offset: $offset, limit: $limit) {
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

//#endregion

//#region feeds and posts

export const COMMUNITY_FEED_QUERY = gql`
  query getActivities(
    $input: FeedInput!
    $limit: Int
    $offset: Int
    $enrich: Boolean
    $reactions: ReactionActivityInput
    $id_lt: String
    $topic_ids: [ID]
  ) {
    getActivities(
      input: $input
      limit: $limit
      offset: $offset
      enrich: $enrich
      reactions: $reactions
      id_lt: $id_lt
      topic_ids: $topic_ids
    ) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const COMMUNITY_FEED_BY_TOPICS = gql`
  query getActivitiesByTopics($group_id: ID!, $topic_ids: [ID]!, $get_pinned: Boolean, $limit: Int, $offset: Int) {
    getActivitiesByTopics(group_id: $group_id, topic_ids: $topic_ids, get_pinned: $get_pinned, limit: $limit, offset: $offset) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const COMMUNITY_EXTRAS = gql`
  query getCommunityExtras($group_id: ID!) {
    getCommunityAds(group_id: $group_id) {
      ...AdDetails
      group_id
    }
    getAffiliatedGroups2(origin_group_id: $group_id) {
      statusCode
      message
      data {
        group {
          ...GroupDetails
        }
        group_member {
          ...GroupMemberDetails
        }
      }
    }
  }
  ${AD_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
  ${GROUP_DETAILS_FRAGMENT}
`;

export const PROFILE_FEED_QUERY = gql`
  query getProfileFeedV2(
    $input: FeedInput!
    $limit: Int
    $offset: Int
    $reactions: ReactionActivityInput
    $enrich: Boolean
    $id_lt: String
  ) {
    getProfileFeedV2(input: $input, limit: $limit, offset: $offset, reactions: $reactions, enrich: $enrich, id_lt: $id_lt) {
      user_is_blocked
      activities {
        __typename
        ... on ProfileActivity {
          ...ProfileActivityDetails
        }
        ... on Activity {
          ...ActivityDetails
        }
      }
      lastActivity
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
  ${PROFILE_ACTIVITY_DETAILS_FRAGMENT}
`;

export const GET_ACTIVITY = gql`
  query getActivity($id: ID, $input: FeedInput!, $reactions: ReactionActivityInput) {
    getActivity(id: $id, input: $input, reactions: $reactions) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const GET_ACTIVITY_V2 = gql`
  query getActivityV2($activity_id: ID!) {
    getActivityV2(activity_id: $activity_id) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const GET_REACTIONS = gql`
  query getReactions(
    $activity_id: ID
    $limit: Int
    $with_activity_data: Boolean
    $id_gte: String
    $id_gt: String
    $id_lte: String
    $id_lt: String
    $kind: String
  ) {
    getReactions(
      activity_id: $activity_id
      limit: $limit
      with_activity_data: $with_activity_data
      id_gte: $id_gte
      id_gt: $id_gt
      id_lte: $id_lte
      id_lt: $id_lt
      kind: $kind
    ) {
      ...ReactionDetails
    }
  }
  ${REACTION_DETAILS_FRAGMENT}
`;

export const GET_COMMENTS_AND_ONE_LIKE = gql`
  query getCommentsAndOneLike($activity_id: ID, $limit: Int, $id_gt: String) {
    comments: getReactions(activity_id: $activity_id, limit: $limit, id_gt: $id_gt, kind: "comment") {
      ...ReactionDetails
    }
    likes: getReactions(activity_id: $activity_id, limit: 1, kind: "like") {
      ...ReactionDetails
    }
  }
  ${REACTION_DETAILS_FRAGMENT}
`;

export const GET_ADS = gql`
  query getAvailableAds($limit: Int, $offset: Int) {
    getAvailableAds(limit: $limit, offset: $offset) {
      id
      type
      advertiser_id
      image_urls
      link_url
      copy
      button
      button_url
    }
  }
`;
export const GET_ADVERTISERS = gql`
  query getAdvertisers {
    getAdvertisers {
      id
      name
      handle
      avatar_url
    }
  }
`;
//#endregion

//#region moderation
export const GET_MODERATION_NOTIFICATION_COUNT = gql`
  query getModerationNotificationCount {
    getModerationNotificationCount {
      count
    }
  }
`;

//TODO: remove getModerationCategories.group_details, get them from props
//TODO: extract getModeratedGroupMembers2 in another query
export const GET_MODERATION_GROUP_STATE = gql`
  query getModerationGroupState($group_id: ID!) {
    getModerationCategories(group_id: $group_id) {
      open_categories {
        category
        count
      }
      closed_categories {
        category
        count
      }
      group_details {
        ...ModeratedGroupDetails2
      }
    }
  }
  ${MODERATED_GROUP_DETAILS_2_FRAGMENT}
`;
export const GET_MODERATED_GROUP_MEMBERS = gql`
  query getModeratedGroupMembers($group_id: ID!) {
    getModeratedGroupMembers2(group_id: $group_id) {
      ...ModeratedGroupMembersDetails
    }
  }
  ${MODERATED_GROUP_MEMBERS_FRAGMENT}
`;
export const GET_MODERATION_ISSUES_BY_CATEGORY = gql`
  query getModerationIssuesByCategory($input: ModerationQueryInput3!, $offset: Int!, $limit: Int!) {
    getModerationIssuesByGroup4(input: $input, offset: $offset, limit: $limit) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;
export const GET_MODERATION_ISSUES_BY_GROUP = gql`
  query getModerationIssuesByGroup($input: ModerationQueryInputV2!) {
    getModerationIssuesByGroup5(input: $input) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const GET_USER_MODERATION_HISTORY = gql`
  query getUserModerationHistory($persona_id: ID!, $group_id: ID, $as_reporter: Boolean) {
    getUserModerationHistory(persona_id: $persona_id, group_id: $group_id, as_reporter: $as_reporter) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

// deprecated - not in use
// export const GET_MODERATION_ISSUE_BY_ID = gql`
//   query getModerationIssueById($id: ID!) {
//     getModerationIssueById(id: $id) {
//       ...ModerationIssueDetails
//     }
//   }
//   ${MODERATION_ISSUE_DETAILS_FRAGMENT}
// `;

export const GET_MODERATED_GROUPS = gql`
  query getModeratedGroups2 {
    getModeratedGroups2 {
      ...ModeratedGroupDetails2
    }
  }
  ${MODERATED_GROUP_DETAILS_2_FRAGMENT}
`;

export const GET_MODERATION_AGREEMENTS = gql`
  query getModerationAgreements($group_id: ID, $flat: Boolean, $isUser: Boolean, $isCommunityReport: Boolean) {
    getModerationAgreements(group_id: $group_id, flat: $flat, isUser: $isUser, isCommunityReport: $isCommunityReport) {
      category
      agreements {
        id
        label
        body
      }
    }
  }
`;

export const GET_USER_GROUP_HISTORY = gql`
  query getUserGroupHistory($persona_id: ID!, $group_id: ID!) {
    getUserGroupHistory(persona_id: $persona_id, group_id: $group_id) {
      activities {
        ...ActivityDetails
      }
      moderation_issues {
        ...ModerationIssueDetails
      }
      comments {
        ...ReactionDetails
      }
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
  ${REACTION_DETAILS_FRAGMENT}
`;

//#endregion

//#region chat

export const SEARCH_CHAT_USERS = gql`
  query searchChatUsers($query: String) {
    searchForChatUsers(query: $query) {
      id
      name
      handle
      image
      shared_group_id
      shared_group_name
      photo_sharing
    }
  }
`;

export const CHECK_PERSONA_RELATIONSHIP = gql`
  query checkPersonaRelationship($other_persona_ids: [ID]!, $check_own: Boolean) {
    checkPersonaRelationship(other_persona_ids: $other_persona_ids, check_own: $check_own) {
      ...PersonaRelationshipFragment
      shared_group_ids
      shared_group_names
    }
  }
  ${PERSONA_RELATIONSHIP_FRAGMENT}
`;

export const SEARCH_PERSONAS = gql`
  query search($query: String!, $limit: Int, $offset: Int, $isVerfied: Boolean, $isFollowing: Boolean) {
    searchForUsers(query: $query, limit: $limit, offset: $offset, isVerified: $isVerfied, isFollowing: $isFollowing) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const SEARCH_FRIENDS = gql`
  query searchForFriends($filter: SearchFriendsInput) {
    searchForFriends(filter: $filter) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const SEARCH_GLOBAL_USERS = gql`
  query searchUsersToInvite($group_id: ID!, $keyword: String, $limit: Int, $offset: Int) {
    searchUsersToInvite(group_id: $group_id, keyword: $keyword, limit: $limit, offset: $offset) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const SEARCH_USERS_TO_PROFILE = gql`
  query searchUsersToProfileInvite($persona_id: ID!, $keyword: String, $limit: Int, $offset: Int) {
    searchUsersToProfileInvite(persona_id: $persona_id, keyword: $keyword, limit: $limit, offset: $offset) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const GET_ATTRIBUTION = gql`
  query getAttribution($input: AttributionInput!) {
    getAttribution(input: $input)
  }
`;

//#endregion

//#region explore
export const GET_HERO_BANNER = gql`
  query getHeroBanner($orderBy: String, $limit: Int, $offset: Int) {
    getHeroBanner(orderBy: $orderBy, limit: $limit, offset: $offset) {
      id
      group_id
      url
      media_type
      end_date
      enabled
    }
  }
`;

export const GET_INTRO_VIDEO = gql`
  query getIntroVideo2($group_id: ID!) {
    getIntroVideo2(group_id: $group_id) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const GET_RANDOM_INTRO_VIDEO = gql`
  query getRandomIntroVideo($user_id: ID!) {
    getRandomIntroVideo(user_id: $user_id) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const GET_NEW_COMMUNITIES = gql`
  query getNewCommunities($user_id: ID!, $limit: Int, $offset: Int) {
    getNewCommunities(user_id: $user_id, limit: $limit, offset: $offset) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const GET_FEATURED_COMMUNITIES = gql`
  query getFeaturedCommunities($user_id: ID!, $limit: Int, $offset: Int) {
    getFeaturedCommunities(user_id: $user_id, limit: $limit, offset: $offset) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const GET_EXPLORE_FEATURED = gql`
  query getExploreFeatured($user_id: ID!, $limit: Int, $offset: Int) {
    getFeaturedCommunities(user_id: $user_id, limit: $limit, offset: $offset) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const GET_EXPLORE_NEW = gql`
  query getExploreNew($user_id: ID!, $limit: Int, $offset: Int) {
    getNewCommunities(user_id: $user_id, limit: $limit, offset: $offset) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const GET_EXPLORE_EXTRAS = gql`
  query getExploreExtras($user_id: ID!, $limit: Int, $offset: Int) {
    getFeaturedCommunities(user_id: $user_id, limit: $limit, offset: $offset) {
      ...GroupDetails
    }
    getNewCommunities(user_id: $user_id, limit: $limit, offset: $offset) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const GET_GROUPS_BY_CATEGORY_NAME = gql`
  query getGroupsByCategoryName($categoryQuery: CategoryInput2!) {
    getGroupsByCategoryName3(input: $categoryQuery) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const GET_COMMUNITIES_OF_THE_WEEK = gql`
  query getCommunitiesOfTheWeek($user_id: ID!) {
    getCommunitiesOfTheWeek2(user_id: $user_id) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const GET_EXPLORE_FEED = gql`
  query getExploreFeed($user_id: ID!) {
    getExploreFeed(user_id: $user_id) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const GET_EXPLORE_COMMUNITIES = gql`
  query getExploreData($recommendQuery: RecommendationInput!, $interestQuery: SettingQueryInput!) {
    getRecommendedCommunities3(input: $recommendQuery) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
    getSettings(settingQuery: $interestQuery) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const GET_FOLLOWING = gql`
  query getFollowing($interestQuery: SettingQueryInput!) {
    getSettings(settingQuery: $interestQuery) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;
export const GET_BLACKLIST = gql`
  query getBlacklist($blacklistQuery: SettingQueryInput!) {
    getSettings(settingQuery: $blacklistQuery) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;
export const GET_COMMUNITY_CATEGORIES = gql`
  query getCommunityCategories($settingQuery: SettingQueryInput!) {
    getSettingOptions(settingQuery: $settingQuery) {
      options {
        id
        label
        value
      }
    }
  }
`;

export const GET_EMAIL_VERIFICATION_CODE = gql`
  query getEmailVerificationCode4(
    $id: ID!
    $key: String!
    $value: String!
    $identity_id: ID!
    $user_id: ID!
    $user_name: String!
    $from_community: Boolean
    $group_id: ID
  ) {
    getEmailVerificationCode4(
      id: $id
      key: $key
      value: $value
      identity_id: $identity_id
      user_id: $user_id
      user_name: $user_name
      from_community: $from_community
      group_id: $group_id
    ) {
      # id
      # key
      # user_name
      user_id
      group_id
      value
      from_community
    }
  }
`;

export const GET_GROUPS_BY_NAME = gql`
  query getGroupsByName($group_name: String!, $user_id: ID!, $offset: Int, $limit: Int) {
    getGroupsByName(group_name: $group_name, user_id: $user_id, offset: $offset, limit: $limit) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const GET_FAVORITE_COMMUNITIES = gql`
  query getFavoriteGroup2($filter: FavoriteGroupListFilterInput!) {
    getFavoriteGroup2(filter: $filter) {
      statusCode
      message
      data {
        group {
          ...GroupDetails
        }
        group_member {
          ...GroupMemberDetails
        }
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const GET_AFFILIATED_COMMUNITIES = gql`
  query getAffiliatedGroups2($origin_group_id: ID!) {
    getAffiliatedGroups2(origin_group_id: $origin_group_id) {
      statusCode
      message
      data {
        group {
          ...GroupDetails
        }
        group_member {
          ...GroupMemberDetails
        }
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

//#endregion

//#region misc
// check if current user has pending mesh verification mod issue
export const CHECK_PENDING_MESH_IDENTITY = gql`
  query checkPendingIdentityVerification {
    checkPendingIdentityVerification {
      pending
    }
  }
`;

//#endregion

//#region follow
export const GET_FOLLOW_COUNT = gql`
  query getFollowCounts {
    getFollowCounts {
      following
      follwers
    }
  }
`;

export const GET_USER_FOLLOWINGS = gql`
  query getUserFollowings($filter: UserFollowListFilterInput) {
    getUserFollowings(filter: $filter) {
      data {
        id
        avatar_url
        persona_id
        pronouns
        name
        handle
        send_notification
        accepted
        isVerified
      }
    }
  }
`;

export const GET_USER_FOLLOWERS = gql`
  query getUserFollowers($filter: UserFollowListFilterInput) {
    getUserFollowers(filter: $filter) {
      data {
        id
        avatar_url
        persona_id
        pronouns
        name
        handle
        send_notification
        accepted
        isFollowing
        requested_at
        isVerified
      }
    }
  }
`;
//#end region

// Start Message Request
export const GET_COMMON_GROUP = gql`
  query getCommonGroups($persona_ids: [ID!]) {
    getCommonGroups(persona_ids: $persona_ids)
  }
`;

export const CHECK_CHANNEL_ID = gql`
  query checkChannelForUser($persona_id: ID!) {
    checkChannelForUser(persona_id: $persona_id) {
      channel_id
    }
  }
`;

export const GET_MESSAGE_REQUEST_PENDING_LIST = gql`
  query getMessageRequestList($filter: MessageRequestListFilterInput) {
    getMessageRequestList(filter: $filter) {
      id
      channelId
      requestedFrom {
        avatar_url
        handle
        id
        pronouns
      }
      requestedTo {
        avatar_url
        handle
        id
        pronouns
      }
      accepted
      created_at
      lastMessage {
        created_at
        text
      }
      commonGroupCount
    }
  }
`;
// End Message Request

// Start Topics
export const GET_TOPICS_BY_GROUP_ID = gql`
  query getTopicsById($id: ID!) {
    getGroupTopicsById(id: $id) {
      ...TopicGroupCoreDetails
    }
  }
  ${TOPIC_GROUP_CORE_DETAILS_FRAGMENT}
`;

export const GET_TOPICS_LIST = gql`
  query getTopicsList($filter: GroupTopicsFilterInput!) {
    getGroupTopicsList_V2(filter: $filter) {
      ...TopicGroupCoreDetails
    }
  }
  ${TOPIC_GROUP_CORE_DETAILS_FRAGMENT}
`;

export const GET_POPULAR_TOPICS = gql`
  query getPopularTopicsList($group_id: ID!, $limit: Int) {
    getPopularTopicsList(group_id: $group_id, limit: $limit) {
      ...TopicGroupCoreDetails
    }
  }
  ${TOPIC_GROUP_CORE_DETAILS_FRAGMENT}
`;
// End Topics

export const GET_CUSTOM_REACTION_COUNTS = gql`
  query getCustomReactionCount_($input: GetReactionsInput) {
    getCustomReactionCount_(input: $input) {
      kind
      count
      url
      own_like
    }
  }
`;

export const GET_REACTORS_LIST = gql`
  query getReactorsList($input: GetReactionsInput) {
    getReactorsList(input: $input) {
      user_id
      user {
        ...UserDetails
      }
      kind
      url
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

//Start Personal Feed
export const GET_PERSONAL_FEED = gql`
  query getProfileNodeFeed($user_id: ID!, $limit: Int, $offset: Int, $id_lt: String) {
    getProfileNodeFeed(user_id: $user_id, limit: $limit, offset: $offset, id_lt: $id_lt) {
      lastActivity
      activities {
        __typename
        ... on ProfileActivity {
          ...ProfileActivityDetails
        }
        ... on Activity {
          ...ActivityDetails
        }
      }
    }
  }

  ${ACTIVITY_DETAILS_FRAGMENT}
  ${PROFILE_ACTIVITY_DETAILS_FRAGMENT}
`;
//End Personal Feed

export const GET_PERSONAL_FEED_BY_ID = gql`
  query getProfileNodeActivity($activity_id: ID!) {
    getProfileNodeActivity(activity_id: $activity_id) {
      __typename
      ... on ProfileActivity {
        ...ProfileActivityDetails
      }
      ... on Activity {
        ...ActivityDetails
      }
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
  ${PROFILE_ACTIVITY_DETAILS_FRAGMENT}
`;
