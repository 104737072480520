import { useMutation } from 'react-apollo';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '../common-styles';
import { LoadingIndicator, BlobBlue, ThemedButton, getRandomBlob } from '../common-ui';
import { refetchQueriesFor, VERIFY_EMAIL_MUTATION } from '../graphql';
import { MeshVerifiedCheck } from '../images/MeshVerifiedCheck';

type EmailVerificationProps = {
  email_token?: string;
};

export const EmailVerification: React.FC<EmailVerificationProps> = ({ email_token }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string | undefined>(!email_token ? `error` : undefined);
  const { width } = useWindowDimensions();

  const [saveSettingMutation] = useMutation(VERIFY_EMAIL_MUTATION, {
    variables: { token: email_token },
    refetchQueries: refetchQueriesFor(`Setting`, `Group`, `GroupMember`),
  });

  const saveSetting = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await saveSettingMutation();
      console.log(`data -> saveNewGroupEmail -> ${JSON.stringify(data, null, 2)}`);
      const response = data.verifyEmailSetting ? `success` : `error`;
      setStatus(response);
    } catch (error) {
      setStatus(`error`);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [saveSettingMutation]);

  useEffect(() => {
    setTimeout(() => {
      if (!status && !loading) saveSetting();
    }, 500);
  }, [status, loading, saveSetting]);

  return (
    <View style={{ flex: 1, justifyContent: `center`, alignItems: `center` }}>
      {/* background */}
      <View style={styles.blobContainer}>
        <BlobBlue
          width={width * 1.29}
          height={width * 0.9}
          style={{ position: `absolute`, left: `38%`, top: `13%`, transform: [{ scaleY: 0.3 }] }}
        />
      </View>
      {loading && (
        <View>
          <Text style={[styles.header, { marginBottom: 20 }]}>We are verifying your email.</Text>
          <LoadingIndicator size={32} style={{ margin: 32 }} />
        </View>
      )}

      {!loading && status !== `success` && (
        <>
          <Text style={[styles.header, { marginBottom: 20, marginHorizontal: 20, textAlign: `center` }]}>
            Please press the button to get your email verified.
          </Text>
          <ThemedButton
            rounded
            title="Verify email"
            onPress={() => saveSetting()}
            containerStyle={{ alignItems: `center`, marginHorizontal: 16, marginBottom: 14 }}
            titleStyle={Typography.text(`white`, `bold`, `plustwo`, `center`)}
            buttonStyle={{ flex: 1, justifyContent: `center`, width: `100%`, paddingVertical: 15, paddingHorizontal: 25 }}
          />
        </>
      )}
      <Message status={status} />
    </View>
  );
};

type MessageProps = {
  status?: string;
};

const Message: React.FC<MessageProps> = ({ status }) => {
  const Blob = getRandomBlob();
  if (!status) return null;
  return (
    <View
      style={{
        flex: 1,
        justifyContent: `flex-start`,
        alignItems: `center`,
        marginVertical: 32,
        marginHorizontal: 16,
      }}
    >
      {status === `success` ? <MeshVerifiedCheck height={75} width={75} /> : null}
      <View>
        <Blob width={24} height={32} style={{ position: `absolute`, left: -8, top: -2, zIndex: -1 }} />
        <Text style={{ flexShrink: 1, ...Typography.text(`large`) }}>
          {status === `success` ? `We have verified your email` : `We could not verify your email. Please try again`}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: { ...Typography.text(`huge`), fontFamily: `sans-serif` },
  blobContainer: {
    position: `absolute`,
    width: `100%`,
    height: `100%`,
    zIndex: -1,
    overflow: `hidden`,
  },
});
