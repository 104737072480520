/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { Typography } from '../common-styles';

const localStyles = StyleSheet.create({
  closeBtnContainer: {
    alignSelf: `flex-end`,
    alignItems: `center`,
    justifyContent: `center`,
    flexDirection: `row`,
    borderWidth: 1,
    padding: 4,
    borderRadius: 4,
  },
});

export const CloseButton = ({ promptClose, propStyles }) => (
  <TouchableOpacity
    style={{
      ...StyleSheet.flatten(localStyles.closeBtnContainer),
      ...StyleSheet.flatten(propStyles),
    }}
    onPress={promptClose}
  >
    <View>
      <Text style={{ fontSize: Typography.buttonFontSize }}>Close</Text>
    </View>
  </TouchableOpacity>
);
CloseButton.propTypes = {
  promptClose: PropTypes.func.isRequired,
  propStyles: PropTypes.object,
};

CloseButton.defaultProps = {
  propStyles: {},
};
