import { Text, View } from 'react-native';
import React from 'react';
import { Typography } from '../common-styles';
import { Construction } from '../images';

export const ChatUnderConstruction = () => {
  return (
    <View style={localStyles.container}>
      <View style={localStyles.innerContainer}>
        {/* <Image source={require(`../../assets/images/construction.png`)} style={{ height: 178, width: 231 }} /> */}
        <Construction height={178} width={231} />
        <View style={localStyles.textContainer}>
          <Text style={localStyles.headerText}>Under construction</Text>
          <Text style={localStyles.subtext}>Chat is coming soon!</Text>
        </View>
      </View>
    </View>
  );
};

const localStyles = {
  container: {
    justifyContent: `center`,
    alignItems: `center`,
  },
  innerContainer: {
    paddingTop: 80,
    paddingHorizontal: 72,
  },
  textContainer: {
    marginTop: 54,
  },
  headerText: {
    ...Typography.text(`large`, `black`, `bold`, `center`),
  },
  subtext: {
    ...Typography.text(`base`, `black`, `center`),
    marginTop: 16,
  },
};
