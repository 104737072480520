import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import { Tooltip } from 'react-native-elements'; // FIXME: stop using react-native-elements
import React from 'react';
import { Colors, globalStyles } from '../common-styles';
import { LoadingIndicator, MeshIcon, SelectMultiple, ListItem } from '../common-ui';
import { GET_MODERATION_AGREEMENTS } from '../graphql';

export const AgreementsView = ({ origin_group_id, isUser, isCommunityReport, reasons, setReasons }) => {
  const { loading, error, data } = useQuery(GET_MODERATION_AGREEMENTS, {
    variables: {
      group_id: origin_group_id,
      flat: true,
      isUser,
      isCommunityReport,
    },
  });

  const renderLabel = (label, _style, _selected, item) => {
    let popWidth;
    let popHeight;
    // eslint-disable-next-line func-names
    (function (body) {
      if (body && body.length > 80) {
        popWidth = 400;
        popHeight = 200;
      } else {
        popWidth = 200;
        popHeight = 100;
      }
    })(item && item.body);
    // TODO: get width/height based of content

    return (
      <View style={{ flex: 1 }} testID="OPTION_WRAPPER">
        <ListItem
          title={label}
          titleStyle={{ fontWeight: `500`, color: Colors.darkestGray }}
          testID="REPORT_OPTION"
          onLongPress={null}
          onPress={null}
          rightElement={
            item && item.body ? (
              <Tooltip
                backgroundColor={Colors.brandPurple}
                popover={<Text style={{ flex: 1, color: Colors.white, maxWidth: 200 }}>{item.body}</Text>}
                height={popHeight}
                width={popWidth}
              >
                <MeshIcon size={19} name="info-circle" focused style={{ padding: 4 }} color={Colors.iconColor} />
              </Tooltip>
            ) : (
              <Tooltip
                backgroundColor={Colors.brandPurple}
                popover={<Text style={{ flex: 1, color: Colors.white }}>This agreement does not have any further content</Text>}
                height={45}
                width={350}
              >
                <MeshIcon size={19} name="info-circle" focused style={{ padding: 4 }} color={Colors.iconColor} />
              </Tooltip>
            )
          }
        />
      </View>
    );
  };

  if (loading)
    return (
      <View style={globalStyles.centeredContainer}>
        <LoadingIndicator size="small" />
        <Text style={globalStyles.loadingText}>Loading</Text>
      </View>
    );
  if (error) return <Text>{`Error: ${error.message}`}</Text>;

  const [agreements] = data?.getModerationAgreements || [];
  return (
    <View style={{ flex: 1, width: `100%` }}>
      <AgreementSet agrSet={agreements} selectedItems={reasons} setReasons={setReasons} renderLabel={renderLabel} />
    </View>
  );
};

AgreementsView.propTypes = {
  origin_group_id: PropTypes.string,
  isUser: PropTypes.bool,
  isCommunityReport: PropTypes.bool,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      body: PropTypes.string,
    }),
  ).isRequired,
  setReasons: PropTypes.func.isRequired,
};

AgreementsView.defaultProps = {
  origin_group_id: undefined,
  isUser: undefined,
  isCommunityReport: undefined,
};

const AgreementSet = ({ agrSet, selectedItems, setReasons, renderLabel }) => {
  return (
    <View style={{ flex: 1, width: `100%` }}>
      <View style={{ flex: 1 }}>
        <SelectMultiple
          onSelectionsChange={(selectedItems) => setReasons(selectedItems)}
          items={agrSet.agreements}
          selectedItems={selectedItems}
          renderLabel={renderLabel}
          style={{ flexShrink: 1 }}
        />
      </View>
    </View>
  );
};
AgreementSet.propTypes = {
  agrSet: PropTypes.shape({
    category: PropTypes.string,
    agreements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        body: PropTypes.string,
      }),
    ),
  }).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      agreements: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          body: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  setReasons: PropTypes.func.isRequired,
  renderLabel: PropTypes.func.isRequired,
};
