import React, { forwardRef, useState } from 'react';
import { Platform, TextInput, TextStyle, TouchableOpacity, View, ViewStyle, TextInputProps } from 'react-native';
import { Colors, Typography } from '../common-styles';
import { MeshIcon } from './MeshIcon';

type PasswordInputProps = {
  style?: TextStyle;
  containerStyle?: ViewStyle;
  autocomplete?: string;
} & TextInputProps;

export const PasswordInput = forwardRef<TextInput, PasswordInputProps>((props, ref) => {
  const { style = {}, containerStyle = {}, onChangeText, editable = true, autoFocus = false, ...otherProps } = props;
  const [text, setText] = useState(``);
  const [focused, setFocused] = useState(false);
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const textWasChanged = (newText: string) => {
    setText(newText);
    if (onChangeText) onChangeText(newText);
  };

  const noFocusRing = Platform.select({ default: {}, web: { outlineWidth: 0 } });
  const focusRing = Platform.select({
    default: {},
    web: { outlineWidth: focused ? `thick` : `none`, outlineColor: Colors.brandPurple },
  });

  return (
    <View
      style={[
        containerStyle,
        {
          flexDirection: `row`,
          alignItems: `center`,
          borderColor: focused ? Colors.brandPurple : Colors.mediumGray,
          borderRadius: 4,
          borderWidth: 1,
          ...focusRing,
        },
      ]}
    >
      <TextInput
        autoFocus={autoFocus}
        autoCapitalize="none"
        autoCompleteType="password"
        autoCorrect={false}
        clearButtonMode="while-editing"
        disableFullscreenUI
        editable={editable}
        importantForAutofill="yes"
        onLayout={() => setRefresh(refresh + 1)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChangeText={textWasChanged}
        placeholder="Enter password"
        placeholderTextColor={Colors.textPlaceholder}
        returnKeyType="go"
        secureTextEntry={!visible}
        selectTextOnFocus
        textContentType="password"
        value={text}
        ref={ref}
        style={[
          {
            ...Typography.text(),
            ...noFocusRing,
            flex: 1,
            padding: 10,
          },
          style || {},
        ]}
        {...otherProps}
      />
      <TouchableOpacity onPress={() => setVisible(!visible)}>
        <MeshIcon size={20} name={visible ? `visibility-on` : `visibility-off`} style={{ padding: 4, marginRight: 6 }} />
      </TouchableOpacity>
    </View>
  );
});

PasswordInput.displayName = `PasswordInput`;
