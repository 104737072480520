import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { COLOR_ARRAY, THEMES } from './themes';

const STORAGE_KEY = `THEME_ID`;
export const ThemeContext = React.createContext();

export const ThemeContextProvider = ({ children }) => {
  const [themeID, setThemeID] = useState(`MESH_PURPLE`);

  useEffect(() => {
    (async () => {
      const storedThemeID = await AsyncStorage.getItem(STORAGE_KEY);
      if (storedThemeID) setThemeID(storedThemeID);
      else setThemeID(THEMES[0].key); // default to mesh purple
    })();
  }, []);

  return <ThemeContext.Provider value={{ themeID, setThemeID }}>{themeID ? children : null}</ThemeContext.Provider>;
};
ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const withTheme = (Comp) => {
  return (props) => {
    const { themeID, setThemeID } = useContext(ThemeContext);

    const getTheme = (themeID) => THEMES.find((theme) => theme.key === themeID);
    const setTheme = (themeID) => {
      AsyncStorage.setItem(STORAGE_KEY, themeID);
      setThemeID(themeID);
    };

    return <Comp {...props} themes={THEMES} theme={getTheme(themeID)} setTheme={setTheme} />;
  };
};

export const themes = THEMES;
export const colorArray = COLOR_ARRAY;
