import { useQuery } from '@apollo/react-hooks';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useContext } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ParsedText from 'react-native-parsed-text';
import { DELETED_USER, DUMMY_UUID } from '../../constants';
import { Colors, Typography } from '../common-styles';
import { Activity, Comment as IComment, LinkPreview as ILinkPreview, UserMention, User } from '../common-types/types';
import { Avatar, CenterColumn, MeshIcon, ThreeDotsButton } from '../common-ui';
import { formatRelativeDate } from '../common-util';
import { client, GET_CUSTOM_REACTION_COUNTS, GET_PERSONA } from '../graphql';
import { MeshVerifiedCheck } from '../images';
import { setReplyTo } from '../redux/postSlice';
import { useAppDispatch } from '../redux/store';
import { buildComment, isAMeshLink, openLink } from './helpers';
import { inviteRegex } from './hooks/useLinkPreview';
import { toggleJoinMeshModal } from '../redux/guestSlice';
import { ImageView } from './ImageView';
import { LinkPreview } from './LinkPreview';
import * as PostStyles from './PostStyles';
import { AppContext } from '../../AppContext';
import { Events, extractSourceFromRoute, PendoTrackEvent } from '../pendo/events';
import { useCommentReaction } from './hooks/useReaction';

// eslint-disable-next-line quotes
// type CommentEvents = Pick<CommentActionsProps, 'onReplyToComment' | 'onLikeCommentPress' | 'onThreeDotPress'>;

type CommentProps = {
  comment: IComment;
  activity: Activity;
  isTargeted: boolean;
  parent: IComment | null;
  replyingTo: IComment | null;
  onThreeDotPress: (comment: IComment) => void;
  onReplyToComment: (comment: IComment) => void;
};

type CommentHeaderProps = {
  author: User;
  isEdited: boolean;
  createdAt: string;
  onPress: () => void;
};

type CommentBodyProps = {
  body: string | null;
  image: string | null;
  isTargeted: boolean;
  linkPreview?: ILinkPreview;
  replyingTo: IComment | null;
  commentId: string;
  validMentionTargets: UserMention[];
};

const RemovedComment = () => (
  <View style={styles.comment}>
    <Text style={Typography.text(`plusone`, `gray`, `plain`)}>comment deleted</Text>
  </View>
);

const LoadingComment = () => (
  <CenterColumn>
    <View style={{ margin: 20 }}>
      <Text>Loading comments...</Text>
    </View>
  </CenterColumn>
);

const ErrorComment = () => (
  <CenterColumn>
    <View style={{ margin: 20 }}>
      <Text style={{ color: Colors.baseText }}>There was an error loading the comment. Please try again later.</Text>
    </View>
  </CenterColumn>
);

export const Comment: React.FC<CommentProps> = ({
  comment,
  activity,
  parent,
  onReplyToComment,
  onThreeDotPress,
  isTargeted,
  replyingTo,
}) => {
  const dispatch = useAppDispatch();
  const { isGuestMode } = useContext(AppContext);
  const navigation = useNavigation<any>();
  const route = useRoute();
  const indent = parent ? 1 : 0;
  const { author, body, image, id, ownLike, childrenCounts, isEdited, isRemoved, updatedAt, linkPreview } = buildComment(comment);
  const { addReaction, removeReaction } = useCommentReaction(id, comment);

  const { data } = useQuery(GET_CUSTOM_REACTION_COUNTS, {
    variables: { input: { parent_id: id, parent_kind: `comment` } },
    fetchPolicy: `network-only`,
  });

  if (id === `error`) return <ErrorComment />;
  if (id === `loading`) return <LoadingComment />;
  if (isRemoved && childrenCounts?.comment_reply) return <RemovedComment />;

  const handlePressReply = () => {
    console.log(`handlePressReply triggered`);
    onReplyToComment(comment);
    dispatch(setReplyTo(parent || comment));
  };

  // const handleClickReactedList = () => {
  //   navigation.push(`PostReactionUserList`, { id, kind: `comment` });
  // };

  const handleEmojiClick = async () => {
    const { origin_group_id } = activity || {};

    console.log(`🚀 ~ handleEmojiClick ~ `, {
      comment_id: comment.id,
      comment,
    });

    if (ownLike) await removeReaction({ variables: { reaction_id: id } });
    else
      await addReaction({
        variables: {
          reaction_id: id,
          group_id: origin_group_id,
        },
      });

    // Tract Event in Pendo
    const source = extractSourceFromRoute(route.name);
    PendoTrackEvent(Events.START_REACTION, {
      community_name: activity?.origin_group?.name,
      content: comment?.kind,
      location: source,
      reaction_name: `heart`,
    });
  };

  const onAuthorPress = () => {
    if (isGuestMode) {
      dispatch(toggleJoinMeshModal({ open: true }));
    } else if (route.name !== `Profile` && author?.handle !== DELETED_USER.handle) {
      PendoTrackEvent(Events.PROFILE, {
        username: author?.handle,
        source: route.name,
        element: `Avatar`,
      });
      navigation.navigate(`Profile`, { user: author, isSameStack: true });
    }
  };

  const likeCount = (data?.getCustomReactionCount_ || []).reduce((total: number, item: any) => total + item.count, 0);

  return (
    <CenterColumn>
      <View testID="COMMENT_ITEM" style={[styles.commentContainer, { paddingLeft: 8 + indent * 40 }]}>
        {/* Fix this types for Avatar */}
        <Avatar user={author} size={32} style={{ flex: 0, marginLeft: 6, marginRight: 8 }} />

        <View style={{ flex: 1, flexDirection: `column` }}>
          {/* comment header: username •15m */}
          <CommentHeader author={author} createdAt={updatedAt} isEdited={isEdited} onPress={onAuthorPress} />
          <CommentBody
            body={body}
            isTargeted={isTargeted}
            commentId={id}
            linkPreview={linkPreview}
            image={image}
            replyingTo={replyingTo}
            validMentionTargets={comment?.data?.validMentionTargets || []}
          />

          <View style={{ flexDirection: `row`, alignItems: `center`, marginTop: 2.5 }}>
            <TouchableOpacity
              testID="REPLY_COMMENT"
              style={{ flex: 0, paddingVertical: 3, marginRight: 20 }}
              hitSlop={{ top: 10, bottom: 10, left: 10 }}
              onPress={handlePressReply}
            >
              <Text style={PostStyles.postCommentReplyButtonText}>Reply</Text>
            </TouchableOpacity>

            {!isGuestMode && <ThreeDotsButton horizontal onPress={() => onThreeDotPress(comment)} />}
            <View style={{ flex: 1 }} />

            <TouchableOpacity
              onPress={handleEmojiClick}
              style={{ flex: 0, display: `flex`, flexDirection: `row`, alignItems: `center` }}
            >
              <View style={styles.reactedEmojiWrapper}>
                <MeshIcon name="heart" size={18} focused={ownLike} color={Colors.heartColor} />
              </View>

              <Text style={{ marginLeft: 4, color: Colors.heartColor, width: 24 }}>
                {likeCount > 0 ? likeCount : ''}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </CenterColumn>
  );
};

const CommentHeader = React.memo(({ author, isEdited, createdAt, onPress }: CommentHeaderProps) => (
  <TouchableOpacity
    key={author?.id}
    //hitSlop={hitSlop}
    disabled={!author?.id || author?.id === DUMMY_UUID}
    onPress={onPress}
    activeOpacity={0.8}
  >
    <View style={styles.commentHeader}>
      <Text style={{ ...PostStyles.postCommentUserHandleText, marginRight: 2 }}>{author.handle}</Text>
      {author.isVerified && <MeshVerifiedCheck width={14} height={14} style={{ marginRight: 4 }} />}
      <Text style={PostStyles.postCommentTimestampText}>
        {isEdited ? `Edited ` : ``}
        {formatRelativeDate(createdAt)}
      </Text>
    </View>
  </TouchableOpacity>
));

const CommentBody: React.FC<CommentBodyProps> = React.memo(({ body, image, linkPreview, validMentionTargets }) => {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const mentionPattern = /@[a-z0-9_-]+/gim;
  const urlRegex =
    /\b((?:(?:http|https)+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/gi;
  let cleanedBody = body;
  if (body && linkPreview) {
    cleanedBody = body.replace(urlRegex, ``).trim();
  }

  const handleOpenUrl = () => {
    if (linkPreview) {
      const { url } = linkPreview;
      openLink(url, () => {
        if (isAMeshLink(url)) {
          const validInvite = url.match(inviteRegex);
          if (validInvite) {
            const [, , invite_token] = validInvite;
            navigation.navigate(`CommunityPreview`, { invite_token });
          } else {
            navigation.navigate(`CommunityPreview`);
          }
        }
      });
    }
  };

  const handleGoToProfile = async (id: String) => {
    try {
      if (id) {
        const { data } = await client.query({
          query: GET_PERSONA,
          variables: { id },
          fetchPolicy: `no-cache`,
        });
        PendoTrackEvent(Events.PROFILE, {
          username: data?.getPersona?.handle,
          source: route?.name,
          element: `comment`,
        });
        navigation.navigate(`ProfileStack`, { screen: `Profile`, params: { user: data?.getPersona } });
      }
    } catch (err) {
      console.error(`error in handleGoToProfile`, err);
    }
  };

  const renderTextComment = (matchingString: String) => {
    const isContained = validMentionTargets?.find(
      (elem: { handle: string }) => elem.handle.toLowerCase() === matchingString.substring(1).toLowerCase(),
    );
    const match = matchingString.match(mentionPattern) && !!isContained;

    if (match)
      return (
        <Text onPress={() => handleGoToProfile(isContained?.id)} style={{ ...Typography.text(`bold`), color: Colors.deepPurple }}>
          {matchingString}
        </Text>
      );
    return <Text style={{ ...Typography.text() }}>{matchingString}</Text>;
  };

  const [coverImage] = linkPreview?.imageUrls || [];

  return (
    <>
      <View style={styles.commentBody}>
        {!!cleanedBody && cleanedBody?.length > 0 && (
          <Text selectable style={{ ...styles.commentBodyText, marginVertical: body ? 8 : 0 }}>
            <ParsedText
              parse={[
                {
                  //We need this prop because it's a must have for the ParseText lib
                  //but the real job is done in renderText
                  pattern: mentionPattern,
                  style: { ...Typography.text(`bold`), color: Colors.deepPurple },
                  //FIXME: it should not be processing the rendering here
                  // @ts-ignore
                  renderText: renderTextComment,
                },
              ]}
            >
              {cleanedBody}
            </ParsedText>
          </Text>
        )}
        {image && <ImageView image={image} />}
      </View>
      {linkPreview && (
        <View style={{ marginTop: 4 }}>
          <LinkPreview
            containerStyle={{
              backgroundColor: Colors.commentGray,
              paddingHorizontal: 0,
            }}
            title={linkPreview.title || `Mesh link`}
            url={linkPreview?.url}
            handlePress={handleOpenUrl}
            coverImageStyle={{ borderBottomLeftRadius: 15 }}
            coverImage={coverImage}
          />
        </View>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  commentContainer: {
    flexDirection: `row`,
    paddingTop: 8,
    paddingRight: 8,
    width: `100%`,
  },
  comment: {
    borderRadius: 15,
    borderTopLeftRadius: 0,
    borderWidth: 1,
    paddingVertical: 8,
    paddingLeft: 11,
    marginTop: 16,
    borderColor: Colors.deletedBorder,
    marginHorizontal: 16,
    backgroundColor: Colors.deletedBackground,
  },
  commentHeader: {
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 6,
    marginBottom: 3,
  },
  commentBody: {
    borderRadius: 15,
    borderTopLeftRadius: 0,
    paddingHorizontal: 10,
    flex: 1,
    paddingBottom: 4,
  },
  commentBodyText: {
    fontFamily: `inter-regular`,
    fontSize: 14,
    lineHeight: 18,
    letterSpacing: -0.5,
    color: Colors.textBlack,
    marginHorizontal: 6,
  },
  reactedEmojiWrapper: {
    width: 18,
    height: 18,
    overflow: `hidden`,
    alignItems: `center`,
    justifyContent: `center`,
    paddingTop: 1,
  },
});
