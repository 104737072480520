import { useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import Modal from 'react-native-modal';
import { ThemedButton } from './ThemedButton';
import { Colors, Typography } from '../common-styles';
import { UserDefaultAvatar } from '../images';
import { AuthNavigationContainer } from '../common-types/navigation-types';
import { AppContext } from '../../AppContext';

interface IGuestPromptModal {
  isVisible: boolean;
  setHide: () => void;
}

type PromptNavigation = StackNavigationProp<AuthNavigationContainer, `Guest`>;
type ScreenOptions = `Login` | `RegisterUsername`;

const { width } = Dimensions.get(`window`);

export const GuestPromptModal: React.FC<IGuestPromptModal> = ({ isVisible, setHide }) => {
  const navigation = useNavigation<PromptNavigation>();
  const { isGuestMode } = useContext(AppContext);
  const handleNavigate = (nextScreen: ScreenOptions) => {
    setHide();
    setTimeout(() => {
      navigation.navigate(`Auth`, { screen: nextScreen });
    }, 200);
  };

  if (!isGuestMode) return null;
  return (
    <Modal
      isVisible={isVisible}
      animationIn="slideInLeft"
      animationOut="slideOutLeft"
      swipeDirection="left"
      animationInTiming={500}
      animationOutTiming={500}
      hideModalContentWhileAnimating
      useNativeDriver
      propagateSwipe
      onBackdropPress={setHide}
      style={localStyles.modalContainer}
    >
      <View style={localStyles.container}>
        <View style={localStyles.avatarContent}>
          <UserDefaultAvatar width={70} height={70} />
          <Text style={{ ...Typography.text(`plusone`, `bold`), fontSize: 16, marginTop: 10, marginLeft: 10 }}>Guest</Text>
        </View>
        <View style={localStyles.centerContent}>
          <Text style={{ textAlign: `center` }}>
            <Text>
              <Text style={{ ...Typography.text(`plusone`), fontSize: 16 }}>Please login to</Text>
              <Text style={{ ...Typography.text(`plusone`, `bold`), fontSize: 16 }}> mesh </Text>
              <Text style={{ ...Typography.text(`plusone`), fontSize: 16 }}>or create an </Text>
            </Text>
            <Text style={{ ...Typography.text(`plusone`), fontSize: 16, lineHeight: 5 }}>account to interact with content </Text>
            <Text style={{ ...Typography.text(`plusone`), fontSize: 16 }}>and access member features.</Text>
          </Text>
          <ThemedButton
            rounded
            title="Sign up"
            onPress={() => handleNavigate(`RegisterUsername`)}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginVertical: 20, marginHorizontal: 14, width: 150 }}
            titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`), fontSize: 14 }}
          />
          <ThemedButton
            outline={true}
            rounded
            clear
            title="Log in"
            onPress={() => handleNavigate(`Login`)}
            buttonStyle={{ padding: 15 }}
            titleStyle={{ ...Typography.text(`plusone`, `bold`), fontSize: 14, color: Colors.brandPurple }}
            containerStyle={{ marginBottom: 15, marginHorizontal: 16, width: 150 }}
          />
        </View>
        <View style={localStyles.descContent} />
      </View>
    </Modal>
  );
};

const localStyles = StyleSheet.create({
  modalContainer: {
    margin: 0,
    width: width * 0.75,
  },
  container: {
    flex: 1,
    backgroundColor: `#fff`,
    display: `flex`,
    justifyContent: `space-between`,
    padding: 20,
  },
  avatarContent: {
    marginTop: 30,
  },
  centerContent: {
    display: `flex`,
    alignItems: `center`,
  },
  descContent: {
    height: 10,
  },
});
