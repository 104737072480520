import AsyncStorage from '@react-native-async-storage/async-storage';
import { useContext, useEffect } from 'react';
import { PendoSDK, NavigationLibraryType } from 'rn-pendo-sdk';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../AppContext';
import { getEnvVariables } from '../../environment';

export const initPendo = () => {
  const navigationOptions = {
    library: NavigationLibraryType.ReactNavigation,
    navigation: null,
  };
  const { pendo: pendoKey } = getEnvVariables();
  PendoSDK.setup(pendoKey, navigationOptions);
};

const getAge = (dob?: string | Date) => {
  if (!dob) return null;
  const dateOfBirth = new Date(dob);
  if (!dateOfBirth || Number.isNaN(dateOfBirth)) return null;
  return Math.floor((Date.now() - dateOfBirth.getTime()) / 1000 / 60 / 60 / 24 / 365.25);
};

const getGuestID = async () => {
  try {
    const guestID = await AsyncStorage.getItem(`GuestID`);
    if (guestID) return guestID;
    // generate Id if not found on device
    const newGuestID = uuidv4();
    await AsyncStorage.setItem(`GuestID`, newGuestID);
    return newGuestID;
  } catch (err) {
    console.error(`setGuestID error: `, err);
    return null;
  }
};

export const usePendoAccount = () => {
  const { user, identity, isGuestMode } = useContext(AppContext);

  useEffect(() => {
    const { envName } = getEnvVariables();
    const switchVisitor = async () => {
      const guestID = await getGuestID();
      // supported pendo values: Text, Number, Boolean, and Dates (no Arrays)
      if (identity?.id && user) {
        PendoSDK.startSession(
          // Visitor ID
          (isGuestMode ? guestID : identity?.id) || ``,
          // Account ID
          `Mesh Communities`,
          // Visitor information
          {
            //Device
            env: envName, // environment: Development | Production | local
            //Identity
            visitor_type: isGuestMode ? `unregistered` : `registered`, // Consumer | enterprise | unregistered
            mesh_user_handle: user?.handle, // Authenticated account handle
            //Persona
            mesh_profile_ids: [user?.id], // current personas in account
            age: getAge(user?.dob),
            pronouns: user.pronouns?.map((p) => p.pronoun),
          },
          // Account information
          {
            // Business: owners communities
            account_type: `Consumer`,
            account_name: `Mesh Communities`,
            account_number: 1,
          },
        );
      } else {
        console.log(`usePendoAccount -> Ended visitor session`);
        PendoSDK.endSession();
      }
    };
    switchVisitor();
  }, [user, identity?.id, isGuestMode]);
};

/*{
  id: '0d7c0264-5567-4180-92f6-46e555473ea5',
  account_name: 'Mesh Communities',
  account_type: 'Mesh Consumer',
  account_number: 1,
}*/
