import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

export const MeshIconImage = (props) => {
  return (
    <Svg width="249px" height="249px" viewBox="0 0 249 249" {...props}>
      <G transform="translate(-86 -86) translate(86 86)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path d="M0 0H249V249H0z" />
        <G fillRule="nonzero">
          <Path
            d="M117.09 133.318c-2.003-31.643-7.165-64.293-1.367-95.463 1.744-9.405 1.723-18.652 7.521-24.641C115.402 2.947 102.61 1.352 92.4 4.35 82.232 7.998 75.244 13.61 70 20.996a71.89 71.89 0 011.436 9.822 875.214 875.214 0 013.419 117.407c-.308 8.015-.985 16.578-3.747 23.957 3.98 7.666 9.497 14.442 17.422 17.378 13.45 4.983 28.916-3.614 36.383-15.88a43.67 43.67 0 001.087-1.895 49.872 49.872 0 01-3.576-7.44c-3.733-9.857-4.636-20.535-5.306-31.041"
            fill="#4FCEDD"
            transform="translate(24 29)"
          />
          <Path
            d="M199.81 68.197c-2.734-17.579-11.705-33.858-23.772-46.93-6.321-6.84-13.755-12.996-22.624-15.862-8.87-2.866-16.94-1.095-24.264 3.03a37.351 37.351 0 00-6.15 4.528c.369.486.745.978 1.1 1.491 7.872 11.348 9.703 25.72 10.639 39.495 1.763 25.924 1.182 51.985-.39 77.902-.82 13.591-2.09 27.71-8.595 39.59 3.983 6.772 9.668 12.511 16.877 15.446 15.429 6.279 33.837-2.169 43.615-15.664 9.778-13.496 12.641-30.746 13.748-47.375.683-9.911.8-19.836.936-29.782.123-8.639.24-17.332-1.1-25.869"
            fill="#F2E35A"
            transform="translate(24 29)"
          />
          <Path
            d="M134.915 54.12c-.94-13.82-2.776-28.239-10.666-39.624-.364-.515-.74-1.009-1.11-1.496-5.814 6.005-7.596 15.928-8.508 25.124-3.167 31.63-1.09 59.122.912 90.847.685 10.541 3.03 25.687 6.78 35.562A48.748 48.748 0 00125.9 172c6.526-11.92 7.8-26.078 8.623-39.72 1.577-26.024 2.16-52.157.39-78.16"
            fill="#2C9A44"
            transform="translate(24 29)"
          />
          <Path
            d="M55.22 102.225c-1.533-19.825-1.8-40.128 4.28-59.05 2.747-8.628 5.963-16.072 10.387-22.266a29.51 29.51 0 00-1.608-4.908C63.56 5.158 50.864-1.151 39.061.175c-7.654.854-14.723 4.573-20.281 9.912l.213-.287C5.795 21.292 2.58 40.228 1.247 57.653a429.557 429.557 0 00.433 70.666c1.518 17.09 4.486 35.083 15.588 48.216 11.103 13.132 32.881 18.69 45.804 7.328A29.18 29.18 0 0071 171.955a77.301 77.301 0 01-3.834-8.62C59.788 143.857 56.82 123 55.22 102.252"
            fill="#FD5793"
            transform="translate(24 29)"
          />
          <Path
            d="M70.95 30.832A71.654 71.654 0 0069.516 21c-4.403 6.19-7.582 13.631-10.337 22.254-6.05 18.912-5.777 39.226-4.26 59.02 1.594 20.743 4.547 41.583 11.89 61.049A79.992 79.992 0 0070.616 172c2.768-7.386 3.418-15.934 3.753-23.914a873.37 873.37 0 00-3.418-117.2"
            fill="#333895"
            transform="translate(24 29)"
          />
        </G>
      </G>
    </Svg>
  );
};
