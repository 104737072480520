import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '../common-ui';
import { AccountNotificationItem, allowed_types as allowedAccountNotif } from './AccountNotificationItem';
import { CommunityNotificationItem, allowed_types as allowedCommunityNotif } from './CommunityNotificationItem';
import { ActivityNotificationItem, allowed_types as allowedActivityNotif } from './ActivityNotificationItem';
import { FollowNotificationItem, allowed_types as allowedFollowNotif } from './FollowActivityNotificationItem';
import { RoleUpdateNotificationItem, allowed_types as allowedRoleUpdateNotif } from './RoleUpdateNotificationItem';
import { FollowingNotificationItem } from './FollowingNotificationItem';

export const NotificationItem = ({ notification, markRead, onPressOptionsButton, status }) => {
  if (status === `following`) {
    return (
      <ErrorBoundary header="Could not display notification">
        <FollowingNotificationItem notification={notification} markRead={markRead} />
      </ErrorBoundary>
    );
  }

  const { verb, activities = [] } = notification || {};
  const [activity] = activities;

  if (verb !== `follow_request` && !activity?.origin_group) return null;

  if (allowedCommunityNotif.includes(verb)) {
    return (
      <ErrorBoundary header="Could not display notification">
        <CommunityNotificationItem
          notification={notification}
          activity={activity}
          type={verb}
          markRead={markRead}
          onPressOptionsButton={onPressOptionsButton}
        />
      </ErrorBoundary>
    );
  }
  if (allowedAccountNotif.includes(verb)) {
    return (
      <ErrorBoundary header="Could not display notification">
        <AccountNotificationItem
          notification={notification}
          activity={activity}
          type={verb}
          markRead={markRead}
          onPressOptionsButton={onPressOptionsButton}
        />
      </ErrorBoundary>
    );
  }
  if (allowedActivityNotif.includes(verb)) {
    return (
      <ErrorBoundary header="Could not display notification">
        <ActivityNotificationItem
          notification={notification}
          activity={activity}
          type={verb}
          markRead={markRead}
          onPressOptionsButton={onPressOptionsButton}
        />
      </ErrorBoundary>
    );
  }

  if (allowedFollowNotif.includes(verb)) {
    return (
      <ErrorBoundary header="Could not display notification">
        <FollowNotificationItem notification={notification} markRead={markRead} />
      </ErrorBoundary>
    );
  }

  if (allowedRoleUpdateNotif.includes(verb)) {
    return (
      <ErrorBoundary header="Could not display notification">
        <RoleUpdateNotificationItem notification={notification} markRead={markRead} />
      </ErrorBoundary>
    );
  }

  console.log(`[Notifications]: Notification type ${verb} is not allowed`);
  return null;
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  markRead: PropTypes.func.isRequired,
  onPressOptionsButton: PropTypes.func,
  status: PropTypes.string.isRequired,
};

NotificationItem.defaultProps = {
  onPressOptionsButton: () => {},
};
