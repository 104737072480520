import { ScrollView, StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { isPossibleNumber } from 'libphonenumber-js';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Colors, Typography, Spacing } from '../common-styles';
import { getRandomBlob, KeyboardPaddingView, LoadingIndicator, ThemedButton, SafeAreaView, BackButton } from '../common-ui';
import { cleanAndFormatContact, resetAccountHelper, validateAccount } from './helpers';
import { ContactInput } from './ContactInput';
import { PendoTrackEvent, Events } from '../pendo/events';
import { AuthNavigatorScreens } from '../common-types/navigation-types';

type ForgotPasswordNavigator = StackNavigationProp<AuthNavigatorScreens, `ForgotPassword`>;

type RequestRecoveyData = {
  requestRecoveryToken2: {
    res_status: string;
    contactToShowInTheClient: string;
  };
};

export const ForgotPassword: React.FC = () => {
  const navigation = useNavigation<ForgotPasswordNavigator>();
  const [contact, setContact] = useState(``);
  const [errorMessage, setErrorMessage] = useState(``);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const Blob = getRandomBlob();
  const requestResetCode = async (contact: string) => {
    try {
      setLoading(true);
      PendoTrackEvent(Events.FORGOT_PASSWORD_RESET, { contact });
      // clean contact to only include digits
      const cleaned = cleanAndFormatContact(contact);
      const betaChecked = await validateAccount(cleaned);
      if (!betaChecked) {
        throw new Error(`WARNING: null result returned from checkBeta for contact ${cleaned}`);
      }

      const { id } = betaChecked;
      if (id) {
        // user provided contact info exists in table -- set the ID and request recovery token
        const response = await resetAccountHelper(id, cleaned);
        const { requestRecoveryToken2 } = response?.data as RequestRecoveyData;
        const { res_status: status, contactToShowInTheClient } = requestRecoveryToken2;
        if (status === `200OK`) {
          // success - a reset token was sent to the user. push to recover account screen to enter in the recovery code
          navigation.push(`RecoverAccount`, {
            identity: id,
            contact: cleaned,
            contactToShowInTheClient,
          });
          return;
        }
      }
      throw new Error(`WARNING: no id returned from checkBeta for contact ${cleaned}`);
    } catch (err: any) {
      console.error(`[ForgotPassword] Error thrown in requestResetCode`, err.message);
      setErrorMessage(`Invalid contact. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  const onPhoneNumberChange = useDebouncedCallback((text) => {
    const hasPermittedChars = /^[0-9+\-. ()+]+$/.test(text);
    try {
      const c = cleanAndFormatContact(text);
      setContact(c);
      if (hasPermittedChars) {
        if (isPossibleNumber(c)) {
          setNextDisabled(false);
        } else {
          setNextDisabled(true);
        }
      } else {
        setNextDisabled(false);
      }
    } catch (e) {
      if (isPossibleNumber(text)) {
        setErrorMessage(`Invalid phone number. Please try again.`);
      } else {
        setErrorMessage(``);
      }
      setNextDisabled(true);
    }
  }, 500);

  React.useEffect(() => {
    const goBack = () => {
      PendoTrackEvent(Events.FORGOT_PASSWORD_BACK);
      navigation.goBack();
    };
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
  }, [navigation]);

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ flex: 1, alignItems: `center`, width: `100%` }}>
          <ScrollView
            style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
            contentContainerStyle={{ alignItems: `center` }}
            alwaysBounceVertical={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={{ width: `100%`, maxWidth: Spacing.standardWidth }}>
              <View style={{ margin: 16 }}>
                <View style={localStyles.titleContainer}>
                  <Blob width={32} height={32} style={{ position: `absolute`, left: -8, top: -2, zIndex: -1 }} />
                  <Text style={localStyles.title}>Forgot your password?</Text>
                </View>
                <Text style={{ ...Typography.text(`gray`), paddingTop: 20, paddingBottom: 30 }}>
                  Enter your username or verified phone number.
                </Text>
                <ContactInput
                  type="both"
                  placeholder="Username or phone number"
                  testID="ACCOUNTRECOVERY_CONTACT"
                  onChangeText={onPhoneNumberChange}
                  style={{ marginBottom: 5 }}
                  autoFocus
                />
                {!!errorMessage && (
                  <View style={localStyles.errorContainer}>
                    <Text style={localStyles.errorText}>{errorMessage}</Text>
                  </View>
                )}
                <ThemedButton
                  rounded
                  title="Send my reset code"
                  testID="ACCOUNTRECOVERY_REQUEST_RESET"
                  onPress={() => requestResetCode(contact)}
                  buttonStyle={{ marginTop: 20, paddingHorizontal: 30, paddingVertical: 12 }}
                  leftIcon={loading && <LoadingIndicator />}
                  disabled={loading || nextDisabled}
                />
              </View>
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

const localStyles = StyleSheet.create({
  title: {
    flex: 1,
    ...Typography.text(`large`, `bold`),
    flexWrap: `wrap`,
    flexShrink: 2,
  },
  titleContainer: {
    alignItems: `center`,
    flexDirection: `row`,
    marginTop: 16,
  },
  errorContainer: {
    color: Colors.brightRed,
    borderRadius: 4,
  },
  errorText: {
    fontSize: 12,
    color: Colors.brightRed,
  },
});
