import React, { useCallback } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { Colors } from '../common-styles';
import { TopicItem } from '../common-types/types';
import { Events, PendoTrackEvent } from '../pendo/events';

type ITopicPill = {
  topic: TopicItem;
  onPress: (t: TopicItem) => void;
  brick?: boolean;
  isPreview?: boolean;
  groupName?: string;
};

const TopicPill: React.FC<ITopicPill> = ({ topic, onPress, brick, isPreview, groupName }) => {
  const handleClickPill = useCallback(() => {
    PendoTrackEvent(Events.VIEW_TOPIC, {
      source: brick ? `brick` : `hashtag`,
      screen: isPreview ? `explore community preview` : `community feed (homescreen)`,
      community_name: groupName,
      topic_name: topic.topic,
    });
    onPress(topic);
  }, [isPreview, groupName, onPress, topic, brick]);

  return (
    <TouchableOpacity
      style={{
        backgroundColor: Colors.badgePlusBackground,
        padding: 6,
        borderRadius: 6,
        marginLeft: brick ? 8 : 0,
        marginRight: brick ? 0 : 8,
        marginBottom: brick ? 5 : 0,
      }}
      onPress={handleClickPill}
    >
      <Text style={{ color: Colors.deepPurple, fontWeight: `600`, fontSize: 10 }}>{topic.topic}</Text>
    </TouchableOpacity>
  );
};

export default TopicPill;
