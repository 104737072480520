import { View } from 'react-native';
import React, { useEffect } from 'react';
import LottieView from 'lottie-react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { loadCachedGroup } from '../graphql';
import { GroupCoreFields } from '../common-types/types';

type HomeNavigatorScreens = {
  HomeScreen: undefined;
  ModerationPNReceiver: {
    group_id: string;
  };
  ModerateCommunityDetail: {
    group: GroupCoreFields;
  };
};

type PNReceiverNavigator = StackNavigationProp<HomeNavigatorScreens, `ModerationPNReceiver`>;
type PNReceiverRoute = RouteProp<HomeNavigatorScreens, `ModerationPNReceiver`>;

export const ModerationPNReceiver: React.FC = () => {
  const navigation = useNavigation<PNReceiverNavigator>();
  const route = useRoute<PNReceiverRoute>();
  const { group_id } = route.params || {};

  useEffect(() => {
    if (!group_id) return;
    const group = loadCachedGroup(group_id);
    if (group) navigation.replace(`ModerateCommunityDetail`, { group });
  }, [group_id, navigation]);

  return (
    <View style={{ flex: 1, justifyContent: `center`, alignItems: `center` }}>
      <LottieView
        resizeMode="cover"
        style={{ width: 200, height: 200 }}
        source={require(`../images/animated/animatedLoading.json`)}
        autoPlay
        loop
      />
    </View>
  );
};
