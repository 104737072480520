import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Constants from 'expo-constants';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../common-styles';
import { BottomSheetModal, FloatingButton, MeshIcon } from '../../common-ui';
import { setTopic } from '../../redux/feedSlice';
import { RootState } from '../../redux/store';
import { useCommunities } from './hooks/useCommunities';
import { useHideHeader } from './hooks/useHideHeader';
import InviteFriends from './InviteFriends';
import { useCommunityHeader } from './hooks/useCommunityHeader';
import { BottomSheetOptions, Maybe, TopicItem } from '../../common-types/types';
import AgreeToCoCBottomSheet from '../../common-ui/AgreeToCoCBottomSheet';
import { CommunityFeedForTopic } from './CommunityFeedForTopic';
import { HomeNavigator, HomeNavigatorScreens } from '../../common-types/navigation-types';
import { AppContext } from '../../../AppContext';

type TopicFeedScreenProps = RouteProp<HomeNavigatorScreens, `TopicFeedScreen`>;

const TopicFeedScreen: React.FC = () => {
  const { user } = useContext(AppContext);
  const route = useRoute<TopicFeedScreenProps>();
  const { groupMember, group, sponsorName } = route?.params;
  const navigation = useNavigation<HomeNavigator>();
  const { selectedTopic } = useSelector((state: RootState) => state.feed);
  const dispatch = useDispatch();
  const { refetch } = useCommunities();
  const { handleScroll } = useHideHeader(group);

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [bottomSheetOptions, setBottomSheetOptions] = useState<BottomSheetOptions[]>([]);
  const [showAgreeToCoCModal, setShowAgreeToCoCModal] = useState(false);
  const [showFloatingButton, setShowFloatingButton] = React.useState(false);

  const { getBottomSheetOptions, onToggleBottomSheet, isBottomSheetOpen, onCloseBottomSheet } = useCommunityHeader({
    mode: `home`,
    groupMember,
    onChangeMember: () => {},
    onChangeGroup: () => {},
  });

  const handleGoBack = useCallback(() => {
    dispatch(setTopic(null));
    navigation.goBack();
  }, [navigation, dispatch]);

  const handleThreeDotMenuPress = useCallback(() => {
    const options = getBottomSheetOptions();
    setBottomSheetOptions(options);
    onToggleBottomSheet();
  }, []);

  useEffect(() => {
    const needsToAgree = !groupMember?.has_agreed && groupMember?.role_name === `member`;
    const displayPostBtn = !!groupMember?.can_post || needsToAgree;
    setShowFloatingButton(displayPostBtn);
  }, [groupMember]);

  const handleGoToCreatePost = useCallback(() => {
    if (group?.id) {
      if (!groupMember?.has_agreed && groupMember?.role_name === `member`) {
        setShowAgreeToCoCModal(true);
      } else {
        navigation.navigate(`PostEditor`, { group, mode: `create` });
      }
    }
  }, [navigation, group, groupMember]);

  return (
    <View style={{ flex: 1 }}>
      <Header
        sponsorName={sponsorName}
        handleGoBack={handleGoBack}
        selectedTopic={selectedTopic}
        setShowInviteModal={setShowInviteModal}
        handleThreeDotMenuPress={handleThreeDotMenuPress}
        groupMember={groupMember}
      />
      <CommunityFeedForTopic
        onPullRefresh={refetch}
        onScroll={handleScroll}
        groupMember={groupMember}
        group={group}
        feedContainerStyle={{ paddingTop: 10 }}
        selectedTopic={selectedTopic}
      />
      {showFloatingButton && !user?.isBanned ? <FloatingButton onPress={handleGoToCreatePost} /> : null}
      {group ? (
        <BottomSheetModal
          visible={showInviteModal}
          title={`Invite your friends to ${group?.name}`}
          showCancelBtn={false}
          confirmTitle="Done"
          onPressConfirm={() => setShowInviteModal(false)}
          onPressCancel={() => setShowInviteModal(false)}
        >
          <InviteFriends groupName={group?.name} groupId={group?.id} />
        </BottomSheetModal>
      ) : null}
      <BottomSheetModal
        onPressCancel={onCloseBottomSheet}
        onPressConfirm={onCloseBottomSheet}
        showCancelBtn={false}
        confirmTitle="Close"
        title="Options"
        options={bottomSheetOptions}
        visible={isBottomSheetOpen}
      />

      <AgreeToCoCBottomSheet
        visible={showAgreeToCoCModal}
        onPressCancel={() => setShowAgreeToCoCModal(false)}
        navigation={navigation}
        activeCommunity={group}
        groupMember={groupMember}
      />
    </View>
  );
};

export default TopicFeedScreen;

type HeaderProps = {
  sponsorName?: string;
  selectedTopic: Maybe<TopicItem>;
  handleGoBack: () => void;
  handleThreeDotMenuPress: () => void;
  setShowInviteModal: (b: boolean) => void;
  groupMember: any;
};
const Header: React.FC<HeaderProps> = ({
  sponsorName,
  handleGoBack,
  selectedTopic,
  handleThreeDotMenuPress,
  setShowInviteModal,
  groupMember,
}) => (
  <View style={styles.headerContainer}>
    <View style={styles.headerBack}>
      <MeshIcon name="chevron-left" onPress={handleGoBack} />
      <Text style={{ marginLeft: 10, fontSize: 16, fontWeight: `bold` }}>{selectedTopic?.topic}</Text>
    </View>
    <View style={{ alignItems: `flex-end` }}>
      <View style={{ flexDirection: `row` }}>
        {groupMember?.can_invite && (
          <TouchableOpacity style={[styles.headerIconContent, { marginRight: 10 }]} onPress={() => setShowInviteModal(true)}>
            <MeshIcon name="invite" size={15} color={Colors.deepPurple} />
          </TouchableOpacity>
        )}
        {groupMember && (
          <TouchableOpacity style={styles.headerIconContent} onPress={handleThreeDotMenuPress}>
            <MeshIcon name="dots-three-horizontal" size={15} color={Colors.deepPurple} />
          </TouchableOpacity>
        )}
      </View>
      {sponsorName && (
        <View style={{ marginTop: 10, flexDirection: `row` }}>
          <Text style={{ fontSize: 11 }}>Community sponsored by </Text>
          <Text style={{ fontSize: 11, color: Colors.deepPurple, fontWeight: `bold` }}>{sponsorName}</Text>
        </View>
      )}
    </View>
  </View>
);

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    backgroundColor: Colors.badgePlusBackground,
    paddingHorizontal: 10,
    paddingVertical: 20,
    marginTop: Constants.statusBarHeight,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  headerBack: {
    flexDirection: `row`,
    alignItems: `center`,
  },
  headerIconContent: {
    paddingHorizontal: 12,
    paddingVertical: 3,
    backgroundColor: Colors.white,
    borderRadius: 6,
  },
});
