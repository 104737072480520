/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */
import { createSlice } from '@reduxjs/toolkit';
import { AppWizardState } from '../common-types/types';

const initialState: AppWizardState = {
  currentStep: 0,
};

const applicationWizardSlice = createSlice({
  name: `applicationWizard`,
  initialState,
  reducers: {
    moveNext: (state) => {
      state.currentStep = 1;
    },
    movePrev: (state) => {
      state.currentStep = 0;
    },
    resetApplication: (state) => {
      state.currentStep = 0;
    },
  },
});

const applicationWizardReducer = applicationWizardSlice.reducer;

export const { movePrev, moveNext, resetApplication } = applicationWizardSlice.actions;
export { applicationWizardReducer };
