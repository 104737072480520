import Constants from 'expo-constants';
import { AppManifest } from 'expo-constants/build/Constants.types';
import * as Updates from 'expo-updates';
// @ts-ignore
import app_json from './app.json';

type VersionNumber = number | number[];
type MeshManifest = {
  expo: ExpoManifest;
};
interface ExpoManifest extends AppManifest {
  extra?: {
    releaseChannel?: string;
    publishID?: string;
  };
}
export type Environment = {
  ssl: boolean;
  apiUrl: string;
  envName: `production` | `development` | `local`;
  sentryDsn: string;
  paymentUrl: string;
  streamApiKey: string;
  anyoneCanRegister: boolean;
  newChat: boolean;
  pendo: string;
  stripe_pub_key: string;
};
type Environments = {
  prod: Environment;
  dev: Environment;
  local: Environment;
};

export const getManifest = (): ExpoManifest => {
  // expo-updates now returns a useless empty manifest for embedded updates; avoid it if possible.
  if (Updates.manifest && Object.keys(Updates.manifest).length > 0) return Updates.manifest as AppManifest;
  if (Constants.manifest && Object.keys(Constants.manifest).length > 0) return Constants.manifest;
  // @ts-ignore reason: no bundleUrl for ejected apps
  const { expo } = app_json as MeshManifest;
  return expo;
};

export const getReleaseChannel = () => {
  return Updates.releaseChannel;
};

export const getAppVersion = () => {
  const releaseChannel = getReleaseChannel();
  if (!releaseChannel || releaseChannel === `default`) return ``;
  const version = releaseChannel.split(`-`).pop();
  return version;
};

const compareArrays = (a: VersionNumber, b: VersionNumber): VersionNumber => {
  if (a instanceof Array && b instanceof Array) {
    for (let r, i = 0, l = Math.min(a.length, b.length); i < l; i += 1) {
      r = compareArrays(a[i], b[i]);
      if (r) return r;
    }
    return a.length - b.length;
  }
  return (a > b ? 1 : 0) - (a < b ? 1 : 0);
};

export const compareVersions = (a: string, b: string) => {
  const aParts = a.split(`.`).map((x) => parseInt(x, 10));
  const bParts = b.split(`.`).map((x) => parseInt(x, 10));
  while (aParts.length < bParts.length) aParts.push(0);
  while (bParts.length < aParts.length) bParts.push(0);
  return compareArrays(aParts, bParts);
};

export const appVersionIsAtLeast = (minVersion: string) => {
  if (!minVersion) throw new Error(`bad usage of appVersionIsAtLeast`);
  const version = getAppVersion();
  if (!version) return true;
  return compareVersions(version, minVersion) >= 0;
};

export const getAndroidPackageName = () => {
  const manifest = getManifest();
  if (manifest?.android?.package) return manifest.android.package;
  return `us.meshconnect.mesh`;
};

const ENV: Environments = {
  local: {
    ssl: false,
    apiUrl: `localhost:5000`,
    envName: `local`,
    //sentryDsn: `https://96fb5a678a73402ab8407efb274cbf90@sentry.io/1523518`,
    sentryDsn: `https://b4cccd2d61d34c81a32bd6fb8975272c@sentry.io/1520470`,
    paymentUrl: `https://mesh-payment-prod.herokuapp.com/api/v1/`, // `http://localhost:4001/api/v1/`,
    streamApiKey: `jysy5ses9m6q`,
    anyoneCanRegister: true,
    newChat: true,
    pendo: `eyJhbGciOiJSUzI1NiIsImtpZCI6IiIsInR5cCI6IkpXVCJ9.eyJkYXRhY2VudGVyIjoidXMiLCJrZXkiOiIwNmVhY2M1M2U0YWQ2MTVkZmYzMjdhMDY4MWQwNDQzNzI3ZDhhOGM1YWE5NDZmMGQxMTQ1N2Y0YzIzZWU0YjcxYjFkYmRlZjUwNTlmZDNlOWYwZDE3YmQ2MzVhM2EwNTdlNTM3Y2IzZjc1MThlMDgwZGJiNTViMGUxMTU0NjEyYTIyYThlMWM1NjcyNGYwNGU0MTFlNzBjMDQ4Nzk0Mjk2MmE1ZDBiZWEyMjZhOWUzNWNlZDc1MjUxZThjMzcwOGUxZDZkODM4YzY5ZDI5MzBkOWY5MjI0ZTNmYjNiNGM1Yi4wNTNiNmVlN2QxNDA5MGQ4OWUwNmRkMDQ2YjNmZjNlNS4wMTkzNjZjNTk3ODYyMWYwZGI0Y2VmZmVkZjQ1YjM1NmRmZmFlN2Y1MmQxMzVlN2NmZTFiZWUzOTg3MWNiNDhiIn0.VTl-MskCzWfMuZzBYRuU2qCvMkjMuDxa_uTR2Kwp8Fa3If2zlrMBX7B6azAoR4Xt3V9G5fxcODI2Cr-XwAsKC7Evg_-K-zxNEiYCgEf6iJDsBhy1KHvdez3CeDqO8ZwQqmCGSqxzsaBdrFIb_4Yas-SNWLpkYEgh9ZeTYfNDQpc`,
    stripe_pub_key: `pk_test_51IipZbKFDNFXy2Wl3Z9P94wY5zEHq7tgLUdp0yzC2Y80ceLzaYhcNfunFAv2E59q29Q1yPC1PaQWzqkVQeBBQSty00x9ffpMmt`,
  },
  dev: {
    ssl: true,
    envName: `development`,
    apiUrl: `meshconnect-development.herokuapp.com`,
    //sentryDsn: `https://96fb5a678a73402ab8407efb274cbf90@sentry.io/1523518`,
    sentryDsn: `https://b4cccd2d61d34c81a32bd6fb8975272c@sentry.io/1520470`,
    streamApiKey: `jysy5ses9m6q`,
    paymentUrl: `https://mesh-payment-dev.herokuapp.com/api/v1/`,
    anyoneCanRegister: true,
    newChat: appVersionIsAtLeast(`1.0.15`),
    pendo: `eyJhbGciOiJSUzI1NiIsImtpZCI6IiIsInR5cCI6IkpXVCJ9.eyJkYXRhY2VudGVyIjoidXMiLCJrZXkiOiIwNmVhY2M1M2U0YWQ2MTVkZmYzMjdhMDY4MWQwNDQzNzI3ZDhhOGM1YWE5NDZmMGQxMTQ1N2Y0YzIzZWU0YjcxYjFkYmRlZjUwNTlmZDNlOWYwZDE3YmQ2MzVhM2EwNTdlNTM3Y2IzZjc1MThlMDgwZGJiNTViMGUxMTU0NjEyYTIyYThlMWM1NjcyNGYwNGU0MTFlNzBjMDQ4Nzk0Mjk2MmE1ZDBiZWEyMjZhOWUzNWNlZDc1MjUxZThjMzcwOGUxZDZkODM4YzY5ZDI5MzBkOWY5MjI0ZTNmYjNiNGM1Yi4wNTNiNmVlN2QxNDA5MGQ4OWUwNmRkMDQ2YjNmZjNlNS4wMTkzNjZjNTk3ODYyMWYwZGI0Y2VmZmVkZjQ1YjM1NmRmZmFlN2Y1MmQxMzVlN2NmZTFiZWUzOTg3MWNiNDhiIn0.VTl-MskCzWfMuZzBYRuU2qCvMkjMuDxa_uTR2Kwp8Fa3If2zlrMBX7B6azAoR4Xt3V9G5fxcODI2Cr-XwAsKC7Evg_-K-zxNEiYCgEf6iJDsBhy1KHvdez3CeDqO8ZwQqmCGSqxzsaBdrFIb_4Yas-SNWLpkYEgh9ZeTYfNDQpc`,
    stripe_pub_key: `pk_test_51IipZbKFDNFXy2Wl3Z9P94wY5zEHq7tgLUdp0yzC2Y80ceLzaYhcNfunFAv2E59q29Q1yPC1PaQWzqkVQeBBQSty00x9ffpMmt`,
  },
  prod: {
    ssl: true,
    envName: `production`,
    apiUrl: `meshconnect-production.herokuapp.com`,
    sentryDsn: `https://b4cccd2d61d34c81a32bd6fb8975272c@sentry.io/1520470`,
    streamApiKey: `2ane7m7wwuk8`,
    anyoneCanRegister: true,
    paymentUrl: `https://mesh-payment-prod.herokuapp.com/api/v1/`,
    newChat: appVersionIsAtLeast(`1.0.15`),
    pendo: `eyJhbGciOiJSUzI1NiIsImtpZCI6IiIsInR5cCI6IkpXVCJ9.eyJkYXRhY2VudGVyIjoidXMiLCJrZXkiOiI1ZjQ4MDAxMGM5OThiNGU3YzFiZDk4YWMxOTkzMjc2YmYyOGYwZDZiOTZkNzAxZjliOGUxMjRjNTM0YTYxNTA0ZTI1MThhOTYzODhiYWJiNjMwNDQ2YTMwZjdiYmJiMzkxZDNkYWY3MmQwM2VhMmNkMzUwNTk4ODQyYzM3ODA1Mzc2NmJkMzA4ZWVlOWM5MTNkODRhOTJiZWE1ODkwYzViMTEzNmJjYjBiMWE2YTUyM2Y0MmFjNThkZGFkYjlhOTEzMzlmYmZiOTJkMzNjNjBmYzIyOWY0NTI5ZjZhZjA3ZC5jZGE2YTRiOGI3ZjVjNGU3YmVhOTJiOGRiYjk3OGExMC45NTZkMGM4MmQ5OWEwODNhNjg2N2FmNGEyMGViZWE1OGJhMGIzMGExNzVkOTQ0MDRiMTI5MzBmMzg3MWQxNDkzIn0.kvfgwzDazxxgpyYVXz-U0NZDka_vre4HlooAxhAupez3RclhEnC3mz_JohAlRH4Jp9Cu5K31P_iqi1Jv79plg7ZEz9hGAFwPz0yLNXMq-HwtLZQuOIG7K13W4OjAJW26a46LVvPUn7CMMBYbhidcfzpp23u8WqSfDtuDB46rcGo`,
    stripe_pub_key: `pk_live_51IipZbKFDNFXy2Wldp0Ml0vUEB4SmmBWHpsYK8GiCSiTio42Le6kQ6dgFZYSOO8Oq70P09IcwdhictMWB48389LM00WXSr1OjN`,
  },
};

export const getEnvVariables = () => {
  const manifest = getManifest();
  const { releaseChannel } = manifest?.extra || {};
  let environment = __DEV__ ? ENV.dev : ENV.prod;
  if (!__DEV__ && typeof releaseChannel === `string`) {
    const isDev = releaseChannel.indexOf(`dev`) !== -1;
    environment = isDev ? ENV.dev : ENV.prod;
    //old release channel default -> prod
  }

  // uncomment to force to localhost. nb: this line intentionally produces an eslint error so you don't check it in
  // if (__DEV__) return false, (environment = ENV.local);

  // uncomment to force to dev. nb: this line intentionally produces an eslint error so you don't check it in
  // if (__DEV__) return false, (environment = ENV.dev);

  // uncomment to force to prod. nb: this line intentionally produces an eslint error so you don't check it in
  // if (__DEV__) return false, (environment = ENV.prod);

  return environment;
};
