import React from 'react';
import { View, Text, StyleProp, TextStyle, ViewStyle, TouchableOpacityProps, TouchableOpacity } from 'react-native';
import { TouchableOpacity as ScrollableTouchableOpacity } from 'react-native-gesture-handler';

import { MeshIcon } from './MeshIcon';
import { Colors } from '../common-styles';
import { ButtonProps } from '../common-types/types';

export const Button: React.FC<ButtonProps> = React.memo(
  ({ title, containerStyle, textStyle, onPress, leftIcon, rightIcon, disabled, onlyIconRender, fullWidth }) => {
    const buttonContent = (
      <>
        <View style={buttonStyles.leftIconContainer}>{leftIcon}</View>
        <Text style={[buttonStyles.textStyles(disabled), textStyle]}>{title}</Text>
        <View style={buttonStyles.rightIconContainer}>{rightIcon}</View>
      </>
    );
    return (
      <ScrollableTouchableOpacity
        style={{ width: fullWidth ? `100%` : `auto` }}
        disabled={disabled}
        activeOpacity={0.5}
        onPress={onPress}
      >
        <View style={[buttonStyles.container(disabled), containerStyle]}>
          {onlyIconRender ? leftIcon || rightIcon || buttonContent : buttonContent}
        </View>
      </ScrollableTouchableOpacity>
    );
  },
);

// eslint-disable-next-line quotes
type FloatingProps = TouchableOpacityProps & {
  //Omit<ButtonProps, 'leftIcon' | 'rightIcon' | 'onlyIconRender' | 'textStyle' | 'title'> & {
  /**
   * Icon to render inside the button
   * */
  containerStyle?: StyleProp<ViewStyle>;
  icon?: React.ReactNode;
};

export const FloatingButton: React.FC<FloatingProps> = React.memo(({ onPress, containerStyle, disabled, icon }) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      activeOpacity={0.8}
      onPress={onPress}
      style={[buttonStyles.floatingButtonContainer, containerStyle]}
    >
      {icon || <MeshIcon name="plus-3" focused color={Colors.white} size={25} style={{ alignSelf: `center` }} />}
    </TouchableOpacity>
  );
});

const buttonStyles = {
  container: (disabled?: boolean): ViewStyle => ({
    backgroundColor: disabled ? Colors.lightGray : `hsl(237, 100%, 95.5%)`,
    padding: 10,
    borderRadius: 15,
    flexDirection: `row`,
    alignItems: `center`,
  }),
  floatingButtonContainer: {
    position: `absolute`,
    alignItems: `center`,
    justifyContent: `center`,
    right: 14,
    bottom: 20,
    backgroundColor: Colors.deepPurple,
    width: 60,
    height: 60,
    borderRadius: 999,
    zIndex: 2,
  } as ViewStyle,
  leftIconContainer: {
    marginRight: 6,
  },
  rightIconContainer: {
    marginLeft: 6,
  },
  textStyles: (disabled?: boolean): TextStyle => ({
    fontSize: 14,
    fontWeight: `500`,
    textAlign: `center`,
    color: disabled ? Colors.textDisabled : Colors.brandPurple,
  }),
};
