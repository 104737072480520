/* eslint-disable no-bitwise */
import * as sjcl from 'sjcl';

if (typeof Buffer === `undefined`) global.Buffer = require(`buffer`).Buffer;

// ------------------------------------------------------------------------------------- #
// Hashing
//
// Collision attacks are extremely important to prevent, since hashes are used to convert
// data in various ways that deliberately loses information. For example, a hash is used to convert
// an email address to a list of identities, *deliberately* losing information about the source email
// in the process.
//
// If a collision were possible to generate, for example by length-extension, you could generate
// an email address that could serve to authenticate any other target email address.

// ---------------
// Alpha is a weak hash, used for initial unsalted lookups.
//
// Note that this hash is DELIBERATELY weak and both designed AND expected to generate hash collisions with
// some frequency. This is because we don't rely on it for real security. The purpose of Alpha is simply to slim
// down the pool of salted Beta hashes that we need to try.
//
// Result is a 8-char string, corresponding to a 4-byte int as hex. (The first byte is cleared to zero, yielding only
// 24 bits of information, to increase collisions.)

const adler32_buf = (buf, seed) => {
  let a = 1;
  let b = 0;
  const L = buf.length;
  let M = 0;
  if (typeof seed === `number`) {
    a = seed & 0xffff;
    b = (seed >>> 16) & 0xffff;
  }
  for (let i = 0; i < L; ) {
    M = Math.min(L - i, 3850) + i;
    for (; i < M; i += 1) {
      a += buf[i] & 0xff;
      b += a;
    }
    a = 15 * (a >>> 16) + (a & 65535);
    b = 15 * (b >>> 16) + (b & 65535);
  }
  return (b % 65521 << 16) | a % 65521;
};

export const hash_alpha = (data) => {
  const input = Buffer.from(data);
  const output = Buffer.alloc(4);
  output.writeUInt32BE(adler32_buf(input) & 0x00ffffff, 0);
  return output.toString(`hex`);
};

// ---------------
// Beta is a strong hash, used with a salt to actually look up identities.
//
// This is presently 250,000 rounds of PBKDF2_HMAC with SHA256 (SHA-2).
//
// Result is a 128-byte string, corresponding to a 64-byte hash as hex.

export const hash_beta = (hexsalt, data, rounds = 10) => {
  //const input = Buffer.from(data);
  const salt = sjcl.codec.hex.toBits(hexsalt);

  class hmacSHA256 {
    constructor(key) {
      const hasher = new sjcl.misc.hmac(key, sjcl.hash.sha256); // eslint-disable-line new-cap
      this.encrypt = (...args) => hasher.encrypt(...args);
    }
  }
  const output = sjcl.misc.pbkdf2(
    data,
    salt,
    rounds * 1000, // modern articles such as OWASP recommend 10,000 iterations.
    256,
    hmacSHA256,
  );
  return sjcl.codec.hex.fromBits(output);
};

// import { hash_alpha, hash_beta } from '../common-auth/Identity';
// console.log('hash_alpha', hash_alpha('drewthaler@gmail.com'));
// console.log('hash_beta', hash_beta('0123456789ABCDEF', 'drewthaler@gmail.com'));
