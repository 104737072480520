import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import React, { useCallback, useState } from 'react';
import { Colors, Typography } from '../common-styles';
import { MeshIcon, BottomSheetModal } from '../common-ui';
import { MainCategoryIcon } from '../images/MainCategoryIcon';
import { Setting, SettingOption } from '../common-types/types';

type SettingValue = string | number | string[] | undefined;

type OptionsList = {
  key: string;
  options: SettingOption[];
};

interface SelectEditorProps {
  setting: Setting;
  optionsData: OptionsList[];
  onCancel: () => void;
  onSave: (key: string, value: SettingValue) => void;
}

const GROUP_SETTING_KEY = `group.setting.type`;
const GROUP_VERIFICATION_KEY = `group.setting.member_verified`;

export const SelectEditor: React.FC<SelectEditorProps> = ({ setting, optionsData, onCancel, onSave }) => {
  const defaultSelected =
    `${setting?.value}`
      ?.trim()
      ?.split(`,`)
      ?.filter((item: any) => !!item) || [];
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultSelected);

  const isMultiSelect = setting?.key === GROUP_SETTING_KEY || setting?.key === GROUP_VERIFICATION_KEY;
  let opts: OptionsList | undefined;
  if (setting?.key && optionsData?.length) {
    opts = optionsData.find((opts) => opts.key === setting.key);
  }

  const onPressItem = (option: SettingOption, isSelected: boolean) => {
    if (isSelected) {
      setSelectedItems((prev) => (prev as string[])?.filter((it) => it !== option.value));
    } else if (isMultiSelect && selectedItems.length < 3) {
      setSelectedItems((prev) => [...prev, `${option.value}`]);
    } else {
      setSelectedItems([`${option.value}`]);
    }
  };

  const handleSave = useCallback(() => {
    if (setting.key === GROUP_SETTING_KEY) {
      onSave(setting.key, selectedItems);
    } else {
      onSave(setting.key, selectedItems.filter((item) => !!item).join(`,`));
    }
    onCancel();
  }, [setting, selectedItems, onSave, onCancel]);

  return (
    <BottomSheetModal
      visible={!!setting}
      title={setting?.label || ``}
      onPressCancel={onCancel}
      confirmTitle="Save"
      onPressConfirm={handleSave}
    >
      <View testID="MODAL_SCROLL" style={{ margin: 10, height: setting?.label === `Category` ? `75%` : undefined }}>
        {setting?.label === `Category` && (
          <Text style={{ ...Typography.text(`small`), alignSelf: `center` }}>
            Select up to 3 categories that describe your node
          </Text>
        )}
        <ScrollView alwaysBounceVertical={false} testID="MODAL_SCROLL">
          {opts?.options?.map((opt) => {
            const isSelected = selectedItems?.includes(opt.value) || false;
            const optionIcon = isSelected ? `circle-check` : `radio-off`;
            const [first] = selectedItems;
            const isPrimaryCategory = isSelected && opt.value === first && setting.key === GROUP_SETTING_KEY;

            return (
              <TouchableOpacity
                key={opt.value}
                style={{ flexDirection: `row`, alignItems: `center` }}
                onPress={() => onPressItem(opt, isSelected)}
                testID="MODAL_OPTION"
              >
                <View style={{ flex: 1, padding: 8 }}>
                  <Text style={Typography.text(isSelected ? `bold` : `base`)}>{opt.label}</Text>
                  {opt.detail && <Text style={Typography.text(`small`, isSelected ? `black` : `gray`)}>{opt.detail}</Text>}
                </View>
                {isPrimaryCategory ? (
                  <MainCategoryIcon style={{ marginRight: 16 }} />
                ) : (
                  <MeshIcon name={optionIcon} size={24} color={Colors.brandPurple} style={{ marginRight: 16 }} />
                )}
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    </BottomSheetModal>
  );
};
