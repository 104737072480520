import { client, SEND_TELEMETRY } from '../graphql';

// Valid objects for the event.
const EventObjects = new Set([`activity`, `ad`]);

// Valid verbs for the event.
const EventVerbs = new Set([`view`, `hide`, `report`, `detail`, `click`]);

let pending_events = [];

// - - - - - - - - -
// API for subsystems to add telemetry events

export const addTelemetryEvent = (type, info = {}) => {
  console.log(`TELEMETRY EVENT: ${type}, ${JSON.stringify(info)}`);
  const created_at = new Date().toISOString();

  // validate incoming event type against known nouns and verbs
  const [object, verb] = type.split(`.`, 2);
  const validObject = EventObjects.has(object);
  const validVerb = EventVerbs.has(verb);
  if (!validObject)
    console.error(`addTelemetryEvent: bad event ${type}, object ${object} must be one of ${[...EventObjects.keys()]}`);
  if (!validVerb) console.error(`addTelemetryEvent: bad event ${type}, verb ${verb} must be one of ${[...EventVerbs.keys()]}`);
  if (!validObject || !validVerb) return;

  // stringify the info
  let data;
  try {
    data = JSON.stringify(info);
  } catch (err) {
    console.error(`addTelemetryEvent: invalid info ${info}, cannot represent as JSON`);
    return;
  }

  // enqueue the event
  pending_events.push({ type, created_at, data });
};

// - - - - - - - - -
// Timer to send batched events
const sendTelemetry = async () => {
  const events_to_send = pending_events;
  pending_events = [];

  if (events_to_send.length === 0) return;

  try {
    await client.mutate({
      mutation: SEND_TELEMETRY,
      variables: { events: events_to_send, client_time: new Date().toISOString() },
      fetchPolicy: `no-cache`,
    });

    console.log(`sendTelemetry: sent ${events_to_send.length} events`);
  } catch (err) {
    console.error(`sendTelemetry: error sending ${events_to_send.length} events: ${err}`);
    pending_events.push(...events_to_send);
  }
};

// eslint-disable-next-line no-unused-vars
const sendIntervalTimer = client && setInterval(sendTelemetry, 5000);
