import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { FlatList, Text, TouchableOpacity, View, Alert } from 'react-native';
import React, { useCallback, useEffect, useState, memo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { CommunityListItem } from '../feed/CommunityListItem';
import { commonPropTypes } from '../common-util';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { Divider, ErrorBoundary, MeshIcon } from '../common-ui';
import { GET_OWN_ARCHIVED_COMMUNITIES, UNARCHIVE_COMMUNITY, client } from '../graphql';

export const ArchivedCommunitiesItem = () => {
  const navigation = useNavigation();
  const navigateToArchivedCommunitiesList = useCallback(() => navigation.push(`ArchivedCommunitiesList`), [navigation]);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <TouchableOpacity
        testID="ARCHIVED_COMMUNITIES_LIST"
        activeOpacity={0.8}
        onPress={navigateToArchivedCommunitiesList}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, padding: 16 }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Archived Nodes</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const ArchivedCommunitiesList = memo(({ navigation }) => {
  const [archivedCommunities, setArchivedCommunities] = useState([]);
  const { user } = React.useContext(AppContext);

  const { data, error } = useQuery(GET_OWN_ARCHIVED_COMMUNITIES, {
    variables: {
      user_id: user.id,
    },
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });

  // Effect #1: set data from query
  useEffect(() => {
    if (data?.getArchivedCommunities) {
      const communities = data.getArchivedCommunities;
      setArchivedCommunities(communities);
    }
  }, [data?.getArchivedCommunities]);

  const _keyExtractor = (item, index) => item.id + index.toString();

  const renderItem = useCallback(
    ({ item: group }) => {
      if (group.archived === false) return null;
      return <GroupCard navigation={navigation} group={group} promptUnArchiveCommunity={promptUnArchiveCommunity} />;
    },
    [navigation, promptUnArchiveCommunity],
  );

  const unArchiveCommunity = useCallback(
    async (group_id) => {
      try {
        const { data } = await client.mutate({
          mutation: UNARCHIVE_COMMUNITY,
          variables: { group_id },
          refetchQueries: [
            {
              query: GET_OWN_ARCHIVED_COMMUNITIES,
              variables: {
                user_id: user.id,
              },
            },
          ],
        });
        const unarchived = data.archiveCommunity;
        return unarchived;
      } catch (err) {
        console.error(`Error thrown in archiveCommunity in CommunitySettings.jsx`, err.message);
        return null;
      }
    },
    [user.id],
  );

  const promptUnArchiveCommunity = useCallback(
    (group_id) => {
      console.log(`🚀 ~ ArchivedCommunitiesList ~ group_id`, group_id);
      Alert.alert(
        `Are you sure?`,
        `This community will become active again with all the settings it had previously. Any past members will be able to see it`,
        [
          {
            text: `Cancel`,
            style: `cancel`,
          },
          {
            text: `OK`,
            style: `default`,
            onPress: () => {
              unArchiveCommunity(group_id);
            },
          },
        ],
        { cancelable: true },
      );
    },
    [unArchiveCommunity],
  );

  return (
    <View>
      {!error ? (
        <FlatList
          testID="ARCHIVED_COMMUNITY_LIST"
          data={archivedCommunities || []}
          keyExtractor={_keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={Divider}
          ListEmptyComponent={() => (
            <View style={{ flex: 1, justifyContent: `center`, marginTop: 20 }}>
              <Text testID="EMPTY_LIST" style={Typography.text(`gray`, `center`)}>
                You have no archived communities.
              </Text>
            </View>
          )}
        />
      ) : (
        <ErrorBoundary header="Error loading hidden communities">
          <View style={{ justifyContent: `center` }}>
            <Text style={Typography.text(`plustwo`, `gray`, `bold`, `center`)}>Error loading archived communities</Text>
          </View>
        </ErrorBoundary>
      )}
    </View>
  );
});

ArchivedCommunitiesList.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

const GroupCard = memo(({ navigation, group, promptUnArchiveCommunity }) => {
  return (
    <View style={{ flexDirection: `row`, justifyContent: `center`, alignContent: `center`, marginLeft: -11 }}>
      <View style={{ flex: 1 }}>
        <CommunityListItem navigation={navigation} group={group} hide_category showMemberPill onPress={() => null} />
      </View>
      <View style={{ justifyContent: `center` }}>
        <TouchableOpacity activeOpacity={0.8} onPress={() => promptUnArchiveCommunity(group.id)} testID="UNARCHIVE_COMMUNITY">
          <View style={{ marginRight: 16 }}>
            <Text style={Typography.text(`bold`, `gray`)}>Restore</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
});

GroupCard.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  group: commonPropTypes.group().isRequired,
  promptUnArchiveCommunity: PropTypes.func.isRequired,
};
