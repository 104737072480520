import { Camera, getCameraPermissionsAsync, requestCameraPermissionsAsync } from 'expo-camera';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Image, ScrollView, Text, TouchableOpacity, View, Platform } from 'react-native';
import { Colors, Typography, _bad_do_not_use_HEIGHT, _bad_do_not_use_WIDTH } from '../common-styles';
import {
  BlobBlue,
  BlobGreen,
  BlobPink,
  CameraCapture,
  LoadingIndicator,
  MeshIcon,
  ThemedButton,
  SafeAreaView,
} from '../common-ui';
import { SelfieHand } from '../images';
import { REQUEST_MESH_VERIFICATION, refetchQueriesFor } from '../graphql';

export const VerificationSelfieUpload = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { rightDrawer } = route?.params || {};

  const [showCamera, setShowCamera] = useState(false);
  const [type, setType] = useState(Camera.Constants.Type.front);
  const [photo, setPhoto] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [device, setDevice] = useState(``);
  const [canAskAgain, setCanAskAgain] = useState(true);
  const [status, setStatus] = useState(``);

  const [requestMeshVerificationMutation] = useMutation(REQUEST_MESH_VERIFICATION, {
    refetchQueries: refetchQueriesFor(`Setting`, `Persona`),
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <ThemedButton
          clear
          title="Submit"
          containerStyle={{ right: 8 }}
          onPress={() => requestMeshVerification()}
          disabled={submitting || !photo}
          leftIcon={submitting && <LoadingIndicator />}
          disabledStyle={{ color: Colors.textDisabled }}
        />
      ),
    });
  }, [navigation, photo, submitting, requestMeshVerification]);

  useEffect(() => {
    setPermissions();
  }, []);

  const setPermissions = async () => {
    const { status, canAskAgain } = await getCameraPermissionsAsync();
    const device = Platform.OS;
    console.log(`Status:`, status, `canAskAgain:`, canAskAgain, `device:`, device);
    setDevice(device);
    setCanAskAgain(canAskAgain);
    setStatus(status);
  };

  const requestMeshVerification = useCallback(async () => {
    try {
      setSubmitting(true);
      if (!photo) throw new Error(`requestMeshVerification() - no photo found`);
      const { data } = await requestMeshVerificationMutation({
        variables: { input: { photo } },
      });
      const verificationRequest = data?.requestMeshVerification;
      console.log(`=== identity verification request sent ===`, verificationRequest);

      if (rightDrawer) {
        navigation.navigate(`HomeScreen`);
        navigation.getParent(`RightDrawer`).openDrawer();
      } else {
        // navigation.goBack();
        navigation.navigate(`VerificationSelfieSentScreen`);
      }

      return;
    } catch (err) {
      console.error(`Error thrown in requestMeshVerification() in VerificationSelfieUpload.jsx`, err.message);
      return;
    } finally {
      setSubmitting(false);
    }
  }, [navigation, photo, requestMeshVerificationMutation, rightDrawer]);

  const checkPermission = async () => {
    if (status === `granted`) {
      console.log(`has permission`);
      setHasPermission(true);
      setShowCamera(true);
    } else if (status !== `granted`) {
      console.log(`does not have permission`);
      const askResponse = await requestCameraPermissionsAsync();
      const respStatus = askResponse.status;
      const respCanAskAgain = askResponse.canAskAgain;
      if (respStatus === `granted`) {
        setHasPermission(true);
        setShowCamera(true);
      }
      if (!respCanAskAgain) setCanAskAgain(respCanAskAgain);
    }
  };

  const renderBlobNumber = (text, Blob, color, size) => {
    const height = size;
    const width = size;
    return (
      <View style={{ alignSelf: `center`, alignItems: `center`, justifyContent: `center` }}>
        <Blob width={width} height={height} style={{ position: `absolute`, width, height }} />
        <Text style={{ ...Typography.text(`large`, `bold`, `center`), width, marginVertical: 12, color }}>{text}</Text>
      </View>
    );
  };

  const handleToggleCamera = () => {
    setShowCamera(!showCamera);
  };

  const handleSetType = (type) => {
    setType(type);
  };

  const handleSetPhoto = (photo) => {
    setPhoto(photo);
  };

  const handleSetShowCamera = (bool) => {
    setShowCamera(bool);
  };

  const renderPhoto = () => {
    if (photo && photo.uri) {
      return (
        <TouchableOpacity onPress={handleToggleCamera} activeOpacity={0.8}>
          <Image source={{ uri: photo.uri }} style={localStyles.photoStyle} />
          <View style={localStyles.cameraIconContainer}>
            <View style={localStyles.cameraIconButton}>
              <MeshIcon focused name="camera" color={Colors.white} size={34} />
            </View>
          </View>
        </TouchableOpacity>
      );
    }

    return (
      <TouchableOpacity activeOpacity={0.8} onPress={() => checkPermission()}>
        <View style={localStyles.roundedSquare}>
          <MeshIcon name="user-verification" color={Colors.disabledIconColor} size={122} />
        </View>
        <View style={localStyles.cameraIconContainer}>
          <View style={localStyles.cameraIconButton}>
            <MeshIcon focused name="camera" color={Colors.white} size={34} />
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderAllowAccess = () => {
    if (status === `granted` || canAskAgain) return null;
    if (!canAskAgain && device === `ios`) {
      return (
        <View>
          <Text style={{ ...Typography.text(`theme`, `bold`, `plusone`, `center`), marginTop: 10, marginHorizontal: 12 }}>
            {` `}
            You must allow camera access to submit a verification photo. To allow camera access go to your device's settings, tap
            privacy, select camera, then tap the slider next to Mesh
          </Text>
        </View>
      );
    }
    if (!canAskAgain && device === `android`) {
      return (
        <View>
          <Text style={{ ...Typography.text(`theme`, `bold`, `plusone`, `center`), marginTop: 10, marginHorizontal: 12 }}>
            {` `}
            Please allow camera access to submit a verification photo. To allow camera access go to your device's settings, tap
            Apps & Notifications, then tap Mesh. Next, tap permissions then tap Camera and select allow.
          </Text>
        </View>
      );
    }
    return null;
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <ScrollView alwaysBounceVertical={false} style={{ flex: 1, marginVertical: 20, marginHorizontal: 16 }}>
        <View style={{ flexDirection: `row` }}>
          <View style={localStyles.blob} />
          <Text style={localStyles.claimVerificationBadge}>Claim your verification{`\n`}badge.</Text>
          <SelfieHand height={90} width={150} style={{ marginLeft: -40, alignSelf: `flex-end` }} />
        </View>

        {renderAllowAccess()}

        <View style={{ justifyContent: `center`, alignItems: `center` }}>
          {renderPhoto()}
          <Text style={localStyles.headerText}>How it works</Text>
          {renderBlobNumber(`1`, BlobBlue, `#2A98A5`, 44)}
          <Text style={localStyles.stepText}>Tap the camera icon.</Text>
          {renderBlobNumber(`2`, BlobGreen, `#2C9A44`, 44)}
          <Text style={localStyles.stepText}>Hold up a piece of paper{`\n`}with your user name on it.</Text>
          {renderBlobNumber(`3`, BlobPink, `#EA236C`, 44)}
          <Text style={localStyles.stepText}>Take a selfie.</Text>
          <View style={localStyles.infoContainer}>
            <Text style={{ ...Typography.text(`small`, `black`) }}>
              We take your privacy seriously. This is only shared with Mesh to ensure that you are not a robot.
            </Text>
          </View>
          {showCamera && hasPermission && (
            <CameraCapture
              type={type}
              setType={handleSetType}
              setPhoto={handleSetPhoto}
              setShowCamera={handleSetShowCamera}
              toggleCamera={handleToggleCamera}
            />
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const localStyles = {
  imageStyle: {
    height: 197,
    width: 287,
    alignSelf: `center`,
  },
  stepText: {
    ...Typography.text(`center`),
    marginVertical: 6,
  },
  headerText: {
    ...Typography.text(`large`, `bold`),
    fontSize: 18,
    marginTop: 16,
    marginBottom: 10,
  },
  infoContainer: {
    padding: 10,
    backgroundColor: Colors.lightGray,
    borderRadius: 8,
    marginTop: 28,
  },
  blob: {
    height: 25,
    width: 26,
    marginLeft: 8,
    borderTopEndRadius: 20,
    borderTopStartRadius: 40,
    borderBottomEndRadius: 20,
    borderBottomStartRadius: 20,
    top: 0,
    bottom: 10,
    left: -6,
    position: `absolute`,
    backgroundColor: `rgba(253,87,148,0.5)`,
  },
  roundedSquare: {
    height: 210,
    width: 210,
    borderRadius: 30,
    borderStyle: `dashed`,
    borderWidth: 2,
    borderColor: Colors.iconColor,
    alignSelf: `center`,
    marginTop: 20,
    justifyContent: `center`,
    alignItems: `center`,
    overflow: `hidden`,
  },
  photoStyle: {
    height: 227,
    width: 227,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Colors.iconColor,
    alignSelf: `center`,
    marginTop: 20,
    justifyContent: `center`,
    alignItems: `center`,
    overflow: `hidden`,
  },
  cameraIconButton: {
    backgroundColor: Colors.brandPurple,
    height: 53,
    width: 53,
    borderRadius: 99,
    justifyContent: `center`,
    alignItems: `center`,
  },
  cameraIconContainer: {
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: -48,
    paddingLeft: 180,
  },
  claimVerificationBadge: {
    ...Typography.text(`large`, `bold`, `black`),
    marginBottom: 10,
    marginLeft: 8,
  },
};
