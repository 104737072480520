import { Platform, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';

const hitSlop = { top: 10, bottom: 10, left: 10, right: 10 };

type ThemedButtonProps = {
  title?: string;
  onPress: () => void;
  clear?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  disabledStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  rounded?: boolean;
  buttonStyle?: StyleProp<ViewStyle>;
  outline?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  testID?: string;
};
export const ThemedButton: React.FC<ThemedButtonProps> = React.memo(
  ({
    onPress,
    title,
    clear = false,
    containerStyle,
    disabledStyle,
    disabled = false,
    rounded = false,
    buttonStyle,
    titleStyle,
    outline = false,
    leftIcon,
    rightIcon,
    testID,
  }) => {
    const borderColor = disabled ? Colors.gray : Colors.brandPurple;
    const backgroundColor = disabled ? Colors.taupe : Colors.brandPurple;
    const TextColor = disabled ? Colors.textDisabled : Colors.brandPurple;
    const conditionalStyles: ViewStyle = {
      borderWidth: !outline ? undefined : 1,
      borderColor: !outline ? undefined : borderColor,
      borderRadius: rounded ? 99 : 4,
      backgroundColor: !clear ? backgroundColor : `transparent`,
    };
    return (
      <TouchableOpacity
        testID={testID}
        onPress={onPress}
        disabled={disabled}
        activeOpacity={0.8}
        style={containerStyle}
        hitSlop={Platform.select({ web: undefined, default: hitSlop })}
      >
        <View style={[styles.baseStyle, conditionalStyles, buttonStyle, disabled ? disabledStyle : undefined]}>
          {leftIcon}
          <Text
            maxFontSizeMultiplier={2}
            style={[
              styles.btnText,
              titleStyle,
              // overwrite default white when clear
              clear ? { color: TextColor } : undefined,
            ]}
          >
            {title}
          </Text>
          {rightIcon}
        </View>
      </TouchableOpacity>
    );
  },
);

const styles = StyleSheet.create({
  baseStyle: {
    padding: 8,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
  },
  btnText: Typography.text(`plusone`, `bold`, `center`, `white`),
});
