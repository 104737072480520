import {
  CHECK_PERSONA_RELATIONSHIP,
  client,
  CREATE_CHAT_USER_TOKEN,
  refetchQueriesFor,
  UPDATE_PERSONA_RELATIONSHIP,
  CHECK_CHANNEL_ID,
  REQUEST_MESSAGE,
} from '../graphql';
import { chatClient } from './chatClient';
import { getEnvVariables } from '../../environment';

const setDevChatUser = async (user) => {
  const setUser = await chatClient.setUser(user, chatClient.devToken(user.id));
  return setUser;
};

const initChannel = (channelType, channelId) => {
  const channel = chatClient.channel(channelType, channelId);
  return channel;
};

const watchChannel = async (channel) => {
  const state = await channel.watch();
  console.log(`state in watchChannel`, state);
  return state;
};

/**
 * returns back userToken to verify stream-chat
 */
const getChatToken = async () => {
  try {
    const { data } = await client.mutate({ mutation: CREATE_CHAT_USER_TOKEN });
    if (data && data.createChatUserToken) {
      const { token } = data.createChatUserToken;
      return token;
    }
    return null;
  } catch (err) {
    console.error(`err thrown in getChatToken(): `, err);
    return null;
  }
};

/**
 * check if message request feature is allowed
 *  @param {string} persona_id
 */
const checkChannelID = async (persona_id) => {
  try {
    const { data } = await client.query({ query: CHECK_CHANNEL_ID, variables: { persona_id }, fetchPolicy: `network-only` });
    const { channel_id } = data.checkChannelForUser;
    return channel_id;
  } catch (error) {
    console.error(`Error while checking channel ID:`, error);
    return undefined;
  }
};

/**
 *  Request Message, Get ChannelID
 * @param {string} persona_id
 * @return {string|null} channelId
 */
const requestMessage = async (persona_id) => {
  if (!persona_id) return null;
  try {
    const { data } = await client.mutate({ mutation: REQUEST_MESSAGE, variables: { persona_id } });
    if (data.requestMessage) {
      const { channelId } = data.requestMessage;
      return channelId;
    }
    return null;
  } catch (error) {
    console.error(`Error while requesting message:`, error);
    return null;
  }
};

/**
 * create a new converastion (channel)
 * @param {string} channelType
 * @param {string} [channelId ]
 * @param {object} channelData
 * @param {string[]} channelData.members
 * @param {string} channelData.name
 * @param {string} channelData.image // this is the image used for the channel conversation -- pass it an image url
 */
const createConversation = async (channelType, channelId, channelData) => {
  try {
    const conversation = chatClient.channel(channelType, channelId, channelData);
    await conversation.create();
    return conversation;
  } catch (err) {
    console.error(`err in create channel`, err);
    throw err;
  }
};

/**
 * add members to selected conversation (channel)
 * @param {string} channelType - channelType of conversation
 * @param {string} channelId - cid of conversation
 * @param {string[]} memberIds - array of members you want to add
 * @param {string} [message] - optional message to include with add
 * @returns {Promise} Promise object of stream result
 */
const addMembersToConversation = async (channelType, channelId, memberIds, message) => {
  try {
    const conversation = chatClient.channel(channelType, channelId);
    const added = await conversation.addMembers(memberIds, message);
    return added;
  } catch (err) {
    console.error(`err in addMembersToConversation(): `, err);
    return null;
  }
};

/**
 * invite members to group
 * @param {string} channelType - channeltype of conversation
 * @param {string} channelId - cid of conversation
 * @param {string[]} invites - array of member_ids to send invites to
 * @returns {Promise} - returns stream promise object of invite
 */
const inviteMembersToChannel = async (channelType, channelId, invites) => {
  try {
    const channelOptions = { invites };
    const invite = chatClient.channel(channelType, channelId, channelOptions);
    const invited = await invite.create();
    return invited;
  } catch (err) {
    console.error(`error thrown in inviteMembersToChannel(): `, err);
    return null;
  }
};

const deleteChannel = async (channelType, channelId) => {
  try {
    const channel = chatClient.channel(channelType, channelId);
    const deleted = await channel.delete();
    return deleted;
  } catch (err) {
    console.error(`error thrown in deleteChannel(): `, err);
    return null;
  }
};

/**
 * hide channel
 * @param {string} channelType - channeltype of conversation
 * @param {string} channelId - cid of conversation
 * @param {boolean} clearChannel - array of member_ids to send invites to
 * @returns {Promise} - returns Promise object of stream result
 */
const hideChannel = async (channelType, channelId, clearChannel = true) => {
  try {
    const channel = chatClient.channel(channelType, channelId);
    if (clearChannel) {
      return await channel.hide(null, true);
    }
    return await channel.hide();
  } catch (err) {
    console.error(`error thrown in hideChannel(): `, err);
    return null;
  }
};

/**
 * search channels of current user for messages that match query
 * @param {string} current_user_id
 * @param {string} query
 * @returns {Promise} returns promise containing array of search result
 */
const searchChatMessages = async (current_user_id, query) => {
  try {
    const filters = { members: { $in: [current_user_id] } };
    if (query && query.length > 0) {
      const search = await chatClient.search(filters, query, {
        limit: 10,
        offset: 0,
      });
      const { results } = search;
      return results;
    }
    return [];
  } catch (err) {
    console.error(`err thrown in searchChatMessages(): `, err);
    return [];
  }
};

/**
 *
 * @param {string[]} other_persona_ids - array of persona_ids to check persona_relationship table with
 * @param {boolean} check_own - if current user should check own persona_relationship row (context.user.id === owner_id) to other_persona_ids
 */
const checkPersonaRelationship = async (other_persona_ids, check_own) => {
  // check persona relationship between current user and otherMembers
  try {
    const { data } = await client.query({
      query: CHECK_PERSONA_RELATIONSHIP,
      variables: { other_persona_ids, check_own },
      fetchPolicy: `network-only`,
    });
    return data.checkPersonaRelationship;
  } catch (err) {
    console.error(`err thrown in checkPersonaRelationship(): `, err);
    return null;
  }
};

/**
 * update persona_relationship table for owner_id === current user id and other_persona_id === other_persona_id
 * @param {string} user_id - string id of current persona
 * @param {string} other_persona_id - string id of target persona you wish to change relationship between current and target
 * @param {string[]} [permissions] - (optional) array of permissions set between current user and other persona, pass null if not changing value in table
 * @param {boolean} [blocked] - (optional) bool if this update on persona_relationship table is to block the other user
 * @returns {Promise} - returns back promise object server response
 */
const updatePersonaRelationship = async (user_id, other_persona_id, permissions, blocked) => {
  const input = {
    other_persona_id,
    permissions,
    blocked,
  };
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PERSONA_RELATIONSHIP,
      variables: { input },
      refetchQueries: refetchQueriesFor(`Persona`, `PersonaRelationship`),
    });

    if (getEnvVariables().newChat) {
      const filter = {
        type: `messaging`,
        members: [other_persona_id, user_id],
      };
      const sort = { last_message_at: -1 };

      const channels = await chatClient.queryChannels(filter, sort, {});
      if (channels.length > 0) {
        const channel = channels[0];
        await updateChannelBanStatus(user_id, other_persona_id, channel.type, channel.id, blocked);
      }
    }

    const result = data.updatePersonaRelationship;
    return result;
  } catch (err) {
    console.error(`err thrown in updatePersonaRelationship`, err);
    return null;
  }
};

/**
 * mute or unmute given channel for current user
 * @param {string} channelType
 * @param {string} channelId
 * @param {boolean} mute - is this to mute a channel or to unmute the channel?
 */
const updateChannelMuteStatus = async (channelType, channelId, mute) => {
  try {
    const channel = chatClient.channel(channelType, channelId);
    if (mute === true) {
      // mute the channel for current user
      const muted = await channel.mute();
      return muted;
    }
    const unmuted = await channel.unmute();
    return unmuted;
  } catch (err) {
    console.error(`error thrown in updateChannelMuteStatus(): `, err);
    return null;
  }
};

/**
 * fetch current logged in stream-chat user
 * returns back "OwnUserResponse" object as defined by Stream
 */
const getCurrentChatUserInfo = () => {
  try {
    const { user } = chatClient;
    return user;
  } catch (err) {
    console.error(`error thrown in getCurrentChatUserInfo`, err);
    return null;
  }
};

const removeChannelMember = async (channelType, channelId, memberId) => {
  try {
    const channel = chatClient.channel(channelType, channelId);
    const removed = await channel.removeMembers([memberId]);
    console.log(`removed`, removed);
    return removed;
  } catch (err) {
    console.error(`error thrown in removeChannelMember(): `, err);
    return null;
  }
};

const updateChannel = async (channelType, channelId, channelData, message) => {
  try {
    const channel = chatClient.channel(channelType, channelId);
    const updated = await channel.update(channelData, message);
    return updated;
  } catch (err) {
    console.error(`error thrown in updateChannel():`, err);
    return null;
  }
};

/**
 * ban or unban user
 * @param {string} user_id - string id of current persona
 * @param {string} other_persona_id - string id of target persona you wish to change ban status
 * @param {string} channelType
 * @param {string} channelId
 * @param {boolean} ban - is this to ban a channel or to unban the channel?
 */
const updateChannelBanStatus = async (user_id, other_persona_id, channelType, channelId, ban = true) => {
  try {
    if (!other_persona_id || !channelType || !channelId) return null;
    const channel = chatClient.channel(channelType, channelId);

    if (ban) {
      return await channel.shadowBan(other_persona_id, { banned_by_id: user_id, reason: `Blocked by user` });
    }

    return await channel.removeShadowBan(other_persona_id);
  } catch (err) {
    console.error(`error thrown in updateChannelBanStatus(): `, err);
    return null;
  }
};

/**
 * Message Request Accept(Invite Members)
 */
const acceptMessageRequest = async (channelType, channelId) => {
  try {
    const channel = chatClient.channel(channelType, channelId);
    await channel.acceptInvite();
  } catch (error) {
    console.log(`Error occured while accepting invite members/message request`);
  }
};

const rejectMessageRequest = async (channelType, channelId) => {
  try {
    const channel = chatClient.channel(channelType, channelId);
    await channel.rejectInvite();
  } catch (error) {
    console.log(`Error occured while rejecting invite members/message request`);
  }
};

export {
  setDevChatUser,
  initChannel,
  watchChannel,
  getChatToken,
  createConversation,
  addMembersToConversation,
  searchChatMessages,
  inviteMembersToChannel,
  deleteChannel,
  hideChannel,
  updatePersonaRelationship,
  checkPersonaRelationship,
  updateChannelMuteStatus,
  getCurrentChatUserInfo,
  removeChannelMember,
  updateChannel,
  updateChannelBanStatus,
  checkChannelID,
  requestMessage,
  acceptMessageRequest,
  rejectMessageRequest,
};
