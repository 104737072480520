import React, { useCallback, useEffect, useState } from 'react';
import { View, Image, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from 'react-apollo';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Colors } from '../../common-styles';
import { GroupCoreFields, GroupCoreFields as ICarouselItem, GroupMember } from '../../common-types/types';
// @ts-ignore reason: svg import type definition does not exist
import CrownIcon from '../../../assets/images/crown.svg';
// @ts-ignore
import BellIcon from '../../../assets/images/bell.svg';
// @ts-ignore
import BellOffIcon from '../../../assets/images/bell_off.svg';
// @ts-ignore
import DollarIcon from '../../../assets/images/dollar.svg';
// @ts-ignore
import UploadIcon from '../../../assets/images/upload.svg';
import { useSettings } from '../../common-util/hooks/useSettings';
import { useSettings as useCommonSettings } from '../../common-settings/hooks/useSettings';
import { useAppDispatch } from '../../redux/store';
import { setGroup } from '../../redux/contributionSlice';
import { BottomSheetModal } from '../../common-ui';
import InviteFriends from './InviteFriends';
import { GET_MODERATION_GROUP_STATE } from '../../graphql';
import { useAppStateListener } from '../../drawer/hooks/useAppStateListener';

const bannerImg = require(`../../../assets/images/node.png`);
const GROUP_BANK_KEY = `group.setting.bank_account`;

type BannerType = {
  group?: ICarouselItem;
  groupMember?: GroupMember;
  mode?: string | null;
};

const mod_roles = [`owner`, `manager`, `moderator`];

const Banner: React.FC<BannerType> = ({ group, groupMember, mode }) => {
  const navigation = useNavigation<any>();
  const { userNotifySetting, loading, updateNotifyPostSetting } = useSettings();
  const dispatch = useAppDispatch();
  const { id } = group || {};
  const { settings: bankSettings } = useCommonSettings(GROUP_BANK_KEY, { group_id: id });
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { data } = useQuery(GET_MODERATION_GROUP_STATE, { variables: { group_id: id } });
  const countOpen = data?.getModerationCategories?.group_details?.count_open || 0;
  const bankAccount = bankSettings[GROUP_BANK_KEY]?.value;
  const { top } = useSafeAreaInsets();
  const { appState } = useAppStateListener();

  const handleClickNotify = useCallback(() => {
    updateNotifyPostSetting(userNotifySetting === `all` ? `none` : `all`, id);
  }, [userNotifySetting, id, updateNotifyPostSetting]);

  const handleGoToContribute = useCallback(() => {
    dispatch(setGroup(group));
    navigation.navigate(`SelectAmount`);
  }, [group, dispatch, navigation]);

  const closeModal = () => setShowInviteModal(false);

  // useEffect to prevent the invite modal to be pointing to the wrong community after the user re-open the app
  useEffect(() => {
    if (appState === `inactive`) closeModal();
  }, [appState]);

  return (
    <View
      style={{
        ...styles.bannerContainer,
        marginTop: Platform.select({ ios: mode === `preview` ? top - 10 : 0, android: mode === `preview` ? top + 28 : 0 }),
      }}
    >
      <BannerImage group={group} mode={mode}>
        <View style={styles.actionsContainer}>
          {mod_roles.includes(groupMember?.role_name || '') ? (
            <View style={styles.crownWrapper}>
              <IconButton
                icon={<CrownIcon width={18} height={18} color={Colors.brandPurple} />}
                style={{ marginLeft: 8 }}
                onPress={() => navigation.navigate(`ModerateCommunityDetail`, { group })}
              />
              {countOpen > 0 && <View style={styles.pinkDot} />}
            </View>
          ) : null}

          {groupMember?.role_id ? (
            <IconButton
              disabled={loading}
              icon={
                userNotifySetting === `all` ? (
                  <BellIcon width={16} height={16} color={Colors.brandPurple} />
                ) : (
                  <BellOffIcon width={16} height={16} color={Colors.brandPurple} />
                )
              }
              style={{ marginLeft: 8 }}
              onPress={handleClickNotify}
            />
          ) : null}

          {bankAccount ? (
            <IconButton
              icon={<DollarIcon width={18} height={18} color={Colors.brandPurple} />}
              style={{ marginLeft: 8 }}
              onPress={handleGoToContribute}
            />
          ) : null}

          {groupMember?.can_invite ? (
            <IconButton
              icon={<UploadIcon width={14} height={14} color={Colors.brandPurple} />}
              style={{ marginLeft: 8 }}
              onPress={() => setShowInviteModal(true)}
            />
          ) : null}
        </View>
      </BannerImage>
      <BottomSheetModal
        visible={showInviteModal && !!group}
        title={`Invite your friends to ${group?.name}`}
        showCancelBtn={false}
        confirmTitle="Done"
        onPressConfirm={closeModal}
        onPressCancel={closeModal}
      >
        {!!group && <InviteFriends groupName={group?.name} groupId={group.id} />}
      </BottomSheetModal>
    </View>
  );
};

type BannerImageProps = {
  group?: Pick<GroupCoreFields, `splash_url` | `avatar_url`>;
  mode?: string | null;
};

const BannerImage: React.FC<BannerImageProps> = ({ group, mode, children }) => {
  const { avatar_url, splash_url } = group || {};
  const avatar_source = avatar_url ? { uri: avatar_url } : bannerImg;
  let splash_source;

  if (splash_url) splash_source = { uri: splash_url };
  else if (!splash_url && avatar_url) splash_source = { uri: avatar_url };
  else splash_source = bannerImg;

  if (mode === `preview`) {
    return (
      <View>
        <View style={styles.previewContainer}>
          <View style={styles.groupImageContainer}>
            <Image source={avatar_source} resizeMode="cover" style={{ height: 56, width: 56 }} />
          </View>
        </View>
        <View>
          <Image source={splash_source} resizeMode="cover" style={{ height: 100, width: `100%` }} />
        </View>
        {children}
      </View>
    );
  }
  return (
    <View>
      {!splash_url && !avatar_url ? (
        <Image source={bannerImg} resizeMode="contain" style={{ height: 100, width: `100%` }} />
      ) : (
        <Image source={{ uri: splash_url || avatar_url }} resizeMode="cover" style={{ height: 100, width: `100%` }} />
      )}
      {children}
    </View>
  );
};

const IconButton: React.FC<any> = ({ icon, disabled, style, onPress = () => {} }) => {
  const showBadge = false;

  if (disabled) {
    return (
      <View style={{ ...styles.iconButtonWrapper, ...style }}>
        {showBadge && <View style={styles.btnNoteBadge} />}
        <View style={{ ...styles.iconButton, opacity: 0.5 }}>{icon}</View>  
      </View>
    );
  };

  return (
    <TouchableOpacity onPress={() => onPress()} style={{ ...styles.iconButtonWrapper, ...style }}>
      {showBadge && <View style={styles.btnNoteBadge} />}
      <View style={styles.iconButton}>{icon}</View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    backgroundColor: Colors.white,
  },
  actionsContainer: {
    position: `absolute`,
    right: 12,
    bottom: 8,
    flexDirection: `row`,
  },
  iconButtonWrapper: {
    position: `relative`,
  },
  iconButton: {
    width: 30,
    height: 30,
    borderRadius: 22,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    color: Colors.brandPurple,
    backgroundColor: Colors.purpleGrey,
  },
  btnNoteBadge: {
    position: `absolute`,
    backgroundColor: Colors.hotPink,
    width: 8,
    height: 8,
    borderRadius: 50,
    alignSelf: `flex-end`,
    top: 0,
    right: 0,
    zIndex: 5,
  },
  crownWrapper: {
    position: `relative`,
  },
  pinkDot: {
    position: `absolute`,
    backgroundColor: Colors.hotPink,
    width: 8,
    height: 8,
    borderRadius: 8,
    top: 0,
    right: 0,
  },
  previewContainer: {
    borderRadius: 100,
    overflow: `hidden`,
    width: 64,
    height: 64,
    alignSelf: `center`,
    position: `absolute`,
    top: -28,
    zIndex: 99,
    backgroundColor: Colors.white,
    justifyContent: `center`,
    alignItems: `center`,
  },
  groupImageContainer: {
    borderRadius: 100,
    overflow: `hidden`,
    width: 56,
    height: 56,
    zIndex: 99,
  },
});

export default Banner;
