import { Dimensions, Linking, Platform, TouchableWithoutFeedback, Text, View } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { getManifest, getAppVersion } from '../../environment';
import MeshLogoSVG from '../../assets/images/mesh-icon-transparent.svg';

export const AppUpdateScreen = React.memo(() => {
  const version = getAppVersion();
  const { extra } = getManifest() || {};
  const { publishID } = extra || {};
  const { width, height } = Dimensions.get(`window`);

  const storeUrl = Platform.select({
    ios: `itms-apps://apps.apple.com/us/app/mesh-communities/id1473534230`,
    android: `https://play.google.com/store/apps/details?id=us.meshconnect.mesh`,
  });

  return (
    <TouchableWithoutFeedback onPress={() => storeUrl && Linking.openURL(storeUrl)}>
      <View>
        <View
          style={{
            backgroundColor: Colors.white,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
            width,
            height,
          }}
        >
          <MeshLogoSVG width={210} height={120} />
          <View style={{ margin: 32 }}>
            <View style={{ height: 20 }} />
            <Text style={Typography.text(`center`, `bold`, `large`)}>Update Required</Text>
            <View style={{ height: 20 }} />
            <Text style={Typography.text(`center`, `plusone`)}>
              A newer version of the app is available. Please download it
              {Platform.select({ ios: ` from the App Store.`, android: ` from the Play Store.`, default: `.` })}
            </Text>
            <View style={{ height: 20 }} />
          </View>
        </View>

        <View
          style={{
            position: `absolute`,
            left: 0,
            bottom: 0,
            width: `100%`,
            marginBottom: height / 16,
            justifyContent: `center`,
            alignItems: `center`,
          }}
        >
          <Text style={Typography.text(`gray`)}>
            {version || `dev build`} {!!publishID && `(${publishID})`}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
});
