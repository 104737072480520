import { NetworkStatus } from 'apollo-client';
import { unionBy } from 'lodash';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useQuery, useMutation } from 'react-apollo';
import { StyleSheet, TouchableHighlight, View, Alert, FlatList, Text } from 'react-native';
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { Colors, globalStyles, Typography } from '../common-styles';
import { Avatar, BottomSheetModal, LoadingIndicator, ThreeDotsButton, ListItem } from '../common-ui';
import { LIFT_USER_BAN, LIST_BANNED_MEMBERS, loadCachedGroupMember, refetchQueriesFor } from '../graphql';
import { createConversation } from '../chat/chatHelpers';
import { AppContext } from '../../AppContext';
import { owner, manager, moderator, member } from './memberInfoHelpers';
import {
  ListGroupMembersVars,
  ListBannedMembersData,
  GroupMember,
  TargetLiftBan,
  LiftBanData,
  User,
} from '../common-types/types';
import { MemberListParamList } from './v2/MemberList';
import { useAppDispatch } from '../redux/store';
import { setMemberCount } from '../redux/feedSlice';
import { PersonaSubtitle } from './PersonaSubtitle';
import { Events, PendoTrackEvent } from '../pendo/events';

// eslint-disable-next-line quotes
type MembersNavigationProp = StackNavigationProp<MemberListParamList, 'BannedUsersList'>;

// eslint-disable-next-line quotes
type MemberListRoute = RouteProp<MemberListParamList, 'BannedUsersList'>;

export const BannedUsersList = () => {
  const route = useRoute<MemberListRoute>();
  const dispatch = useAppDispatch();

  const navigation = useNavigation<MembersNavigationProp>();
  const { group } = route.params || {};
  const { user, setChatNotifications, setChannel, chatNotifications = 0 } = useContext(AppContext);

  const [modal, setModal] = useState<string | null>(null); // It can be Actions || Unban modal
  const [affectedPersona, setAffectedPersona] = useState<User | null>(null);
  const [actions, setActions] = useState<any[]>([]);
  const [nameFilter, _setNameFilter] = useState(``);
  const [bannedList, setBannedList] = useState<GroupMember[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(true);

  const group_member = loadCachedGroupMember(group.id, user?.id);

  const {
    data: bannedMembersData,
    networkStatus,
    refetch,
    fetchMore,
    loading: loadingBannedMembers,
    error,
  } = useQuery<ListBannedMembersData, ListGroupMembersVars>(LIST_BANNED_MEMBERS, {
    variables: { group_id: group?.id, limit: 20, offset: 0 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: `cache-and-network`,
  });

  const [liftUserBanMutation] = useMutation<LiftBanData, TargetLiftBan>(LIFT_USER_BAN, {
    onCompleted: () => refetch(),
    update: (_cache, { data }) => {
      try {
        if (data?.liftUserBanForGroup) dispatch(setMemberCount(data?.liftUserBanForGroup?.group));
      } catch (error) {
        console.log(`cache error -> ${error}`);
      }
    },
    refetchQueries: refetchQueriesFor(`GroupMember`),
  });

  // Effect #1 - We want to track any erros and even more if it is related to the query
  useEffect(() => {
    if (error) console.warn(`[bannedMembersData]`, error);
  }, [error]);

  // Effect #2 - if the query successfuly brings the data, store it
  useEffect(() => {
    if (bannedMembersData?.listBannedMembers) setBannedList(bannedMembersData?.listBannedMembers);
  }, [bannedMembersData]);

  const hideActionModal = () => {
    setModal(null);
    setActions([]);
    setAffectedPersona(null);
  };

  const handleCreateConversation = useCallback(
    async (selectedUser) => {
      try {
        if (!selectedUser || !user?.id) return;
        const ownId = user.id;
        const { conversation } = await createConversation(ownId, selectedUser?.id);
        setChannel(conversation);
        const { unreadCount = 0 } = conversation?.state || {};
        const tentativeNewCount = Math.max(chatNotifications - unreadCount, 0);
        if (tentativeNewCount !== chatNotifications) setChatNotifications(tentativeNewCount);

        if (conversation?.id && conversation.type) {
          navigation.navigate(`ChatNavigator`, {
            screen: `ChatThread`,
            params: {
              channel_type: conversation.type,
              channel_id: conversation.id,
            },
          });
        }
      } catch (error: any) {
        console.error(`Error thrown in handleCreateConversation() in MemberInfo.jsx`, error.message);
        Alert.alert(`Error creating conversation`, undefined, undefined, { cancelable: true });
      }
    },
    [navigation, chatNotifications, setChatNotifications, setChannel, user],
  );

  const liftUserBan = async () => {
    let result;
    if (affectedPersona?.id && group?.id) {
      const { data } = await liftUserBanMutation({
        variables: { persona_id: affectedPersona?.id, group_id: group?.id },
      });
      result = data?.liftUserBanForGroup;
    }
    hideActionModal();
    return result;
  };

  const createBanned = (group_members: GroupMember[], nameFilter: string) => {
    const matchName = (persona: User, filter: string) => {
      return (
        filter === `` ||
        persona.name?.toLowerCase()?.includes(nameFilter.toLowerCase()) ||
        persona.handle.toLowerCase().includes(nameFilter.toLowerCase())
      );
    };
    const banned = group_members.filter((gm) => gm.banned && matchName(gm.persona, nameFilter));
    return banned;
  };

  const onEndReached = () => {
    const total_members = bannedList?.length;

    if (!loadingBannedMembers && canFetchMore) {
      fetchMore({
        variables: { offset: total_members },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult.listBannedMembers?.length ||
            fetchMoreResult.listBannedMembers?.slice(-1)[0].id === prev.listBannedMembers?.slice(-1)[0].id
          ) {
            console.log(`is the same, return prev`);
            setCanFetchMore(false);
            return prev;
          }
          const newBannedList = createBanned(
            unionBy(prev.listBannedMembers, fetchMoreResult.listBannedMembers, (gm) => gm.persona_id),
            nameFilter,
          );
          setBannedList(newBannedList);
          return {
            ...prev,
            listGroupMembers: unionBy(prev.listBannedMembers, fetchMoreResult.listBannedMembers, (gm) => gm.persona_id),
          };
        },
      });
    }
  };

  const showActionModal = useCallback(
    (target_member) => {
      // This is just a sanity check
      // If you're not a group member (admin) you shouldn't be able to get here
      if (!group_member) return null;
      const { persona, banned: target_banned } = target_member;
      const me = user?.id === persona.id;
      const { role_id: myRole } = group_member;
      const outrank = (otherRole: string) =>
        (myRole === owner && (otherRole === manager || otherRole === moderator || otherRole === member)) ||
        (myRole === manager && (otherRole === moderator || otherRole === member)) ||
        (myRole === moderator && otherRole === member);

      const actions = [
        {
          visible: () => !me,
          title: `Message`,
          iconName: `email`,
          testID: `BANNED_LIST_TO_MESSAGES`,
          onPress: () => {
            hideActionModal();
            handleCreateConversation(persona);
          },
        },
        {
          title: `View profile`,
          iconName: `profile`,
          onPress: () => {
            hideActionModal();
            PendoTrackEvent(Events.PROFILE, {
              username: persona?.handle,
              source: `Search user list`,
              element: `Search list`,
            });
            navigation.navigate(`Profile`, { user: persona });
          },
        },
        {
          visible: () => !me && outrank(moderator) && target_banned,
          title: `Unban user`,
          iconName: `circle-plus`,
          onPress: () => {
            setModal(`unban`);
            setActions([]);
            setAffectedPersona(persona);
          },
        },
      ];

      setModal(`actions`);
      setAffectedPersona(target_member?.persona);
      setActions(actions.filter((action) => action.visible === undefined || action.visible()));

      return null;
    },
    [navigation, handleCreateConversation, group_member, user],
  );

  const renderUnbanModal = () => {
    return (
      <BottomSheetModal
        visible={!!affectedPersona}
        title="Unban?"
        onPressCancel={hideActionModal}
        confirmTitle="Unban user"
        onPressConfirm={() => liftUserBan()}
      >
        <View style={{ paddingHorizontal: 16, paddingTop: 16 }}>
          <Text style={{ ...Typography.text(), marginBottom: 32 }}>
            <Text>Are you sure you want to lift the ban on </Text>
            <Text style={Typography.add(`bold`)}>{affectedPersona?.handle}</Text>
            <Text>? They will be reinstated as a member of your community.</Text>
          </Text>
        </View>
      </BottomSheetModal>
    );
  };

  const renderActionsModal = () => {
    if (!affectedPersona || !affectedPersona.handle || !actions || !actions.length) {
      console.log({
        persona: !affectedPersona,
        handle: !affectedPersona?.handle,
        actions: !actions,
        length: !actions.length,
      });
      return null;
    }
    return (
      <BottomSheetModal
        visible={!!actions}
        title={`Manage ${affectedPersona?.handle}`}
        options={actions}
        onPressCancel={hideActionModal}
        onPressConfirm={hideActionModal}
      />
    );
  };

  const renderGroupMember = ({ item: group_member }: { item: GroupMember }) => {
    return <Member group_member={group_member} showActionModal={showActionModal} />;
  };

  if (loadingBannedMembers) {
    return (
      <View style={globalStyles.centeredContainer}>
        <LoadingIndicator size="small" />
        <Text style={globalStyles.loadingText}>Loading</Text>
      </View>
    );
  }

  if (!bannedList.length) {
    return (
      <View style={{ flex: 1, marginTop: 56 }}>
        <Text style={Typography.text(`gray`, `center`)}>You have no banned users.</Text>
      </View>
    );
  }

  return (
    <View style={localStyles.container}>
      {modal === `actions` && renderActionsModal()}
      {modal === `unban` && renderUnbanModal()}

      <View style={localStyles.container}>
        <FlatList
          testID="BANNED_USERS_LIST"
          keyExtractor={(group_member) => group_member?.persona_id}
          renderItem={renderGroupMember}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          onEndReachedThreshold={0.5}
          onEndReached={onEndReached}
          data={bannedList}
        />
      </View>
    </View>
  );
};

type MemberProps = {
  group_member: GroupMember;
  showActionModal: (group_member: GroupMember) => void;
};

const Member: React.FC<MemberProps> = ({ group_member, showActionModal }) => {
  const { persona } = group_member || {};
  if (!persona) return null;

  const personaTitle = <Text style={{ ...Typography.text(`bold`) }}>{persona?.name}</Text>;

  return (
    <View style={{ flex: 1, flexDirection: `row`, justifyContent: `flex-start`, alignItems: `center` }}>
      <TouchableHighlight
        style={{ flex: 1 }}
        onPress={() => showActionModal(group_member)}
        underlayColor={Colors.themeTranslucentHighlight}
      >
        <View>
          <ListItem
            testID="BANNED_MEMBER"
            title={personaTitle}
            subtitle={<PersonaSubtitle persona={persona} />}
            leftAvatar={<Avatar user={persona} size={48} />}
            rightElement={<ThreeDotsButton onPress={() => showActionModal(group_member)} size={24} />}
          />
        </View>
      </TouchableHighlight>
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: { flex: 1, flexDirection: `column`, backgroundColor: Colors.white },
});
