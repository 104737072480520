import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { AppState } from 'react-native';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';

import { AppContext } from '../../../AppContext';
import { usePushToken } from '../../notification/helpers';

// import { NOTIFICATIONS_PER_FETCH } from '../../../constants';
// import { client, NOTIFICATION_QUERY } from '../../graphql';
import { getVersionInfo, useUpdateHandler } from '../../update/useUpdateHandler';

const TEN_MUNITES = 10 * 60 * 1000;

export const useAppStateListener = () => {
  usePushToken();
  const lastActive = useRef<Date>(new Date());
  const { invite_token } = useContext(AppContext);
  const [appState, setAppState] = useState(AppState.currentState);
  const [jsUpdateAvailable, setJsUpdateAvailable] = useState(false);
  const [appUpdateAvailable, setAppUpdateAvailable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { checkForUpdate, downloadUpdateAndRelaunch } = useUpdateHandler();

  const updateToLatestPublish = useCallback(async () => {
    console.log(`updateToLatestPublish`);
    try {
      if (!downloading) {
        setDownloading(true);
        await downloadUpdateAndRelaunch();
        console.log(`updateToLatestPublish still here, either download failed or there was no update`);
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        }); // delay 1s for cosmetic purposes
      }
    } catch (err) {
      console.error(`updateToLatestPublish exception:`, err);
    } finally {
      console.log(`updateToLatestPublish done`);
      setDownloading(false);
    }
  }, [downloading, downloadUpdateAndRelaunch]);

  const refreshUpdateState = useCallback(
    async (forceUpdate = false) => {
      const { appUpdate, jsUpdate, versionInfo } = await checkForUpdate();
      const { version, publishID } = getVersionInfo();
      const { latestVersion, latestPublish } = versionInfo;

      if (appUpdate) console.log(`app update is available: have ${version}, latest ${latestVersion}`);
      if (jsUpdate) console.log(`js update is available: have ${publishID}, latest ${latestPublish}`);
      if (Constants.isDevice) {
        // all requirements met and we have not yet set / fetched an expo push token for this account. Let's register
        await Notifications.setBadgeCountAsync(0);
        //if (identity && !pushToken) await registerForPushNotifications();
      }

      setChecked(true);
      setJsUpdateAvailable(jsUpdate);
      setAppUpdateAvailable(appUpdate);

      // force update if requested
      if (jsUpdate && forceUpdate) {
        updateToLatestPublish();
      }
      return versionInfo;
    },
    [checkForUpdate, updateToLatestPublish], //identity, pushToken,
  );

  const appStateListener = useCallback(
    async (nextAppState) => {
      console.log(`appStateChange(${appState} => ${nextAppState})`);
      // entering active state
      if (appState?.match(/inactive|background/) && nextAppState === `active`) {
        const now = new Date().getTime();
        const timeDiff = now > (TEN_MUNITES + lastActive?.current?.getTime() ?? 0);
        const forceUpdate = !invite_token && timeDiff;
        /* EXTRACT */
        await refreshUpdateState(forceUpdate);
      }
      // leaving active state
      if (appState === `active` && nextAppState?.match(/inactive|background/)) {
        setChecked(false);
      }
      // eslint-disable-next-line require-atomic-updates
      lastActive.current = new Date();
      setAppState(nextAppState);
    },
    [appState, invite_token, refreshUpdateState],
  );

  // useEffect(() => {
  //   if (user?.id && appState === `active`) fetchNotifications();
  // }, [appState, user?.id, fetchNotifications]);

  useEffect(() => {
    AppState.addEventListener(`change`, appStateListener);
    return () => AppState.removeEventListener(`change`, appStateListener);
  }, [appStateListener]);

  return { jsUpdateAvailable, appUpdateAvailable, checked, downloading, refreshUpdateState, updateToLatestPublish, appState };
};
