import React from 'react';
import { Alert, View, Text } from 'react-native';
import { Colors, Typography } from '../common-styles';
import { Divider, ListItem, MeshIcon, SafeAreaView } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { SettingManager } from '../common-settings/SettingManager';
import { ARCHIVE_COMMUNITY, client, refetchQueriesFor } from '../graphql';
import { useCommunities } from './v2/hooks/useCommunities';

export const CommunitySettings = ({ navigation, route }) => {
  const { setActiveCommunityAfterArchive } = useCommunities();
  const { group } = route?.params || {};
  const promptAlert = () => {
    Alert.alert(
      `Are you sure?`,
      `Archiving means admins and members will no longer be able to access this node. Once archived, this node can only be accessed again if it is restored from the Archived section in your profile settings.`,
      [
        { text: `Cancel`, style: `cancel`, onPress: () => console.log(`cancel pressed`) },
        { text: `Archive`, style: `default`, onPress: archiveCommunity },
      ],
      { cancelable: true },
    );
  };

  //TODO: make this a list somewhere else to put important communities
  const archivable = group.name !== `Mesh Moderation`;

  const archiveCommunity = async () => {
    try {
      const { data } = await client.mutate({
        mutation: ARCHIVE_COMMUNITY,
        variables: { group_id: group?.id },
        refetchQueries: refetchQueriesFor(`Group`),
      });
      const archived = data.archiveCommunity;
      await setActiveCommunityAfterArchive();
      navigation.navigate(`HomeScreen`);
      // navigation.popToTop();
      return archived;
    } catch (err) {
      console.error(`Error thrown in archiveCommunity in CommunitySettings.jsx`, err.message);
      return null;
    }
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <SettingManager
        mode="group"
        navigation={navigation}
        route={route}
        ListFooterComponent={
          <View style={{ flex: 1, flexDirection: `column`, paddingBottom: 80 }}>
            {archivable && (
              <View>
                <View style={{ backgroundColor: Colors.lightGray }}>
                  <Text style={{ marginHorizontal: 12, marginVertical: 8, ...Typography.text(`gray`, `bold`) }}>
                    {` `}
                    Permissions
                  </Text>
                </View>
                <Divider style={{ borderColor: Colors.mediumLighterGray }} />
                <ListItem
                  testID="ARCHIVE_COMMUNITY"
                  title="Archive node"
                  subtitle="Remove node from Mesh"
                  onPress={promptAlert}
                  titleStyle={Typography.text(`small`, `red`)}
                  subtitleStyle={Typography.text(`bold`, `red`)}
                  rightIcon={<MeshIcon name="chevron-right" color={Colors.iconColor} size={20} />}
                />
              </View>
            )}
          </View>
        }
      />
    </SafeAreaView>
  );
};

CommunitySettings.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};
