import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useRoute, useNavigation } from '@react-navigation/native';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import _ from 'lodash';
import { useAppDispatch } from '../../redux/store';
import { DELETED_USER } from '../../../constants';
import { formatShortRelativeDate } from '../../common-util';
import { Colors, Typography } from '../../common-styles';
import { Avatar, ErrorBoundary, MeshIcon, ThreeDotsButton } from '../../common-ui';
import { setActiveCommunity, setPreviewCommunity, setTopic } from '../../redux/feedSlice';
import { AppContext } from '../../../AppContext';
import { POST_3DOTS } from './testLabels';
import { GuestPromptModal } from '../../common-ui/GuestPromptModal';
import { Events, extractSourceFromRoute, PendoTrackEvent } from '../../pendo/events';
import { TopicItem, TopicStatus } from '../../common-types/types';
import TopicPill from '../../common-ui/TopicPill';
import { MeshVerifiedCheck } from '../../images';

const postStyles = StyleSheet.create({
  postHeaderContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    backgroundColor: Colors.white,
    paddingLeft: 11,
    paddingRight: 16,
  },
  postAsCommunityWrapper: {
    marginTop: 2,
    flex: 1,
    flexDirection: `row`,
  },
  postAsCommunity: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    marginLeft: -12,
    paddingHorizontal: 12,
    backgroundColor: Colors.purpleGrey,
    paddingVertical: 8,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
  },
});

type PostHeaderProps = {
  activity: any;
  showGroup?: boolean;
  showOptionsMenu: () => void;
  index?: number;
  fromPersonalFeed?: boolean;
};

const areEqualHeader = (prev: any, next: any) => {
  const isEqualActivity = prev.activity?.id === next.activity?.id;
  const areEqualUpdated = prev?.activity.updated_at === next?.activity?.updated_at;
  const areEqualPinned = prev?.activity.pinned === next?.activity?.pinned;
  const areEqualFollowRequest = prev?.activity?.user?.follow_request === next?.activity?.user?.follow_request;
  const areEqualTopics = _.isEqual(prev?.activity?.topics, next?.activity?.topics);
  return isEqualActivity && areEqualUpdated && areEqualPinned && areEqualFollowRequest && areEqualTopics;
};

// eslint-disable-next-line complexity
const PostHeader: React.FC<PostHeaderProps> = React.memo(
  ({ activity, showGroup, showOptionsMenu, index, fromPersonalFeed = false }) => {
    const { isGuestMode } = useContext(AppContext);

    const filteredTopics = useMemo(() => {
      if (activity?.topics?.length)
        return activity?.topics?.filter(
          (tp: TopicItem) => tp.topic.toLocaleLowerCase() !== `general` && tp.status === TopicStatus.ACTIVE,
        );
      return [];
    }, [activity]);

    const route = useRoute();
    const navigation = useNavigation<any>();
    const [isVisible, setVisible] = useState(false);

    const {
      origin_group: group,
      origin_group_member: group_member,
      pinned,
      time,
      as_community,
      created_at,
      updated_at,
    } = activity || {}; // id, origin_group_id: group_id,
    const user = activity?.user || DELETED_USER; // This could trigger an unwanted [deleteduser] tag when activity.user is undefined (because of the delay in the query)
    const handle = user?.handle || DELETED_USER.handle;
    const dispatch = useAppDispatch();
    const formatedRelativeDate = useMemo(() => {
      let text = ``;
      if (updated_at !== created_at) text = `Edited `;
      return `${text}${formatShortRelativeDate(updated_at || time)}`;
    }, [updated_at, created_at, time]);

    // const appropriateUser = useMemo(() => {
    //   if (currentUser?.id === user?.id) return currentUser;
    //   return user;
    // }, [currentUser, user]);

    const onPressUserHandle = useCallback(() => {
      if (isGuestMode) {
        setVisible(true);
      } else if (user?.handle !== DELETED_USER.handle && route.name !== `Profile`) {
        PendoTrackEvent(Events.PROFILE, {
          username: user?.handle,
          source: route?.name,
          element: `post`,
        });
        navigation.navigate(`Profile`, { user, isSameStack: true });
      }
    }, [isGuestMode, navigation, user, route.name]);

    const goToCommunity = useCallback(() => {
      const source = extractSourceFromRoute(route?.name);
      PendoTrackEvent(Events.VIEW_COMMUNITY, {
        community_name: group.name,
        source,
        association: source === `Profile` ? `Profile Favorite` : undefined,
      });
      if (group_member) {
        dispatch(setActiveCommunity({ user_id: group_member?.persona_id, group }));
        navigation.navigate(`HomeNavigator`, { screen: `HomeScreen` });
      } else {
        dispatch(setPreviewCommunity(group));
        navigation.navigate(`CommunityPreview`);
      }
    }, [route, group, group_member, dispatch, navigation]);

    const handleSelectTopic = useCallback(
      (topic: TopicItem) => {
        dispatch(setTopic(topic));
        navigation.navigate(`TopicFeedScreen`, { groupMember: group_member, group });
      },
      [dispatch, group_member, navigation, index, group],
    );

    const isAdmin =
      group_member?.role_name === `owner` || group_member?.role_name === `manager` || group_member?.role_name === `moderator`;

    const showPostedBy = useMemo(() => {
      if (as_community && isAdmin) return true;
      return false;
    }, [as_community, isAdmin]);

    // empty post header if no activity. this contains a Text element with whitespace so that it resizes
    // correctly with system font size changes
    if (!activity) {
      return (
        <View style={postStyles.postHeaderContainer}>
          <View style={{ height: 32, width: 32 }} />
          <View style={{ flex: 1 }}>
            <View style={{ margin: 8 }}>
              <Text maxFontSizeMultiplier={1.5} numberOfLines={1} style={Typography.text(`small`, `gray`)}>
                {` `}
              </Text>
              {showGroup && (
                <Text maxFontSizeMultiplier={1.5} numberOfLines={1} style={{ marginTop: 4, ...Typography.text(`small`) }}>
                  {` `}
                </Text>
              )}
            </View>
          </View>
        </View>
      );
    }

    return (
      <>
        <TouchableWithoutFeedback>
          <>
            <View style={postStyles.postHeaderContainer}>
              <ErrorBoundary header="Error displaying header">
                <>
                  <Avatar
                    user={as_community ? group : user}
                    size={24}
                    onPress={as_community ? goToCommunity : onPressUserHandle}
                    isSameStack
                  />
                  <View style={{ flex: 1 }}>
                    <View
                      style={{
                        margin: 8,
                      }}
                    >
                      <TouchableOpacity
                        onPress={as_community ? goToCommunity : onPressUserHandle}
                        style={{ flexGrow: 0, flexDirection: `row`, alignItems: `center` }}
                      >
                        <Text style={Typography.text(`small`, `bold`, `link`)}>{`${as_community ? group?.name : handle} `}</Text>
                        {user.isVerified && <MeshVerifiedCheck width={14} height={14} style={{ marginRight: 4 }} />}

                        {pinned ? (
                          <MeshIcon style={{ marginTop: 4 }} size={18} name="pin" />
                        ) : (
                          <Text style={{ ...Typography.text(`small`, `gray`), lineHeight: 22 }}>{formatedRelativeDate}</Text>
                        )}
                      </TouchableOpacity>

                      {!!showGroup && (
                        <TouchableOpacity onPress={goToCommunity} style={{ flexDirection: `row`, alignItems: `center` }}>
                          <Text style={Typography.text(`small`)}>{`in `}</Text>
                          <Text style={Typography.text(`small`, `bold`, `link`)}>{group?.name}</Text>
                        </TouchableOpacity>
                      )}

                      {!showGroup && showPostedBy && (
                        <TouchableOpacity onPress={onPressUserHandle} style={{ flexDirection: `row`, alignItems: `center` }}>
                          <Text style={Typography.text(`small`)}>{`Posted by `}</Text>
                          <Text style={Typography.text(`small`, `bold`, `link`)}>{handle}</Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                </>

                {!!showOptionsMenu && !isGuestMode ? (
                  <ThreeDotsButton horizontal onPress={showOptionsMenu} testID={POST_3DOTS} />
                ) : undefined}
              </ErrorBoundary>
            </View>
            {activity?.title ? (
              <View style={{ ...postStyles.postHeaderContainer }}>
                <Text
                  onPress={() =>
                    navigation.navigate('PostDetail', {
                      id: activity?.id,
                      group_id: activity?.origin_group_id,
                      isPersonalFeed: activity.__typename === 'ProfileActivity',
                      fromPersonalFeed,
                    })
                  }
                  style={{ ...Typography.text(`base`, `plustwo`, `black`), marginVertical: 4 }}
                >
                  {activity?.title}
                </Text>
              </View>
            ) : undefined}
          </>
        </TouchableWithoutFeedback>
        {filteredTopics.length > 0 && (
          <View style={{ flexDirection: `row`, paddingHorizontal: 12, paddingVertical: 4 }}>
            {filteredTopics.map((topic: TopicItem) => (
              <TopicPill
                key={topic.id}
                topic={topic}
                onPress={handleSelectTopic}
                groupName={group?.name}
                isPreview={route.name !== `HomeScreen`}
              />
            ))}
          </View>
        )}
        <GuestPromptModal isVisible={isVisible} setHide={() => setVisible(false)} />
      </>
    );
  },
  areEqualHeader,
);

export default PostHeader;
