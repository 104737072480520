/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GuestState, GroupCoreFields } from '../common-types/types';

const initialState: GuestState = {
  joinMeshModalOpen: false,
  groupToJoin: undefined,
};

const guestSlice = createSlice({
  name: `guest`,
  initialState,
  reducers: {
    resetJoinMeshModal: (state) => {
      state.joinMeshModalOpen = false;
      state.groupToJoin = undefined;
    },
    toggleJoinMeshModal: (state, action: PayloadAction<{ open: boolean; group?: GroupCoreFields }>) => {
      state.joinMeshModalOpen = action.payload.open;
      if (action.payload.group) {
        state.groupToJoin = action.payload.group;
      }
    },
  },
});

export const guestReducer = guestSlice.reducer;

export const { resetJoinMeshModal, toggleJoinMeshModal } = guestSlice.actions;
