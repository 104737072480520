/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExploreState } from '../common-types/types';

const initialState: ExploreState = {
  isSoundMuted: true,
  mutedByScroll: false,
};

const exploreSlice = createSlice({
  name: `explore`,
  initialState,
  reducers: {
    toggleHeroSound: (state, action: PayloadAction<boolean>) => {
      state.isSoundMuted = action.payload;
    },
    toggleMutedByScroll: (state, action: PayloadAction<boolean>) => {
      state.mutedByScroll = action.payload;
    },
  },
});

export const { toggleHeroSound, toggleMutedByScroll } = exploreSlice.actions;

const exploreReducer = exploreSlice.reducer;

export { exploreReducer };
