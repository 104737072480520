import React, { useState, useCallback } from 'react';
import { View, Switch, TouchableOpacity, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { MeshIcon, Divider } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { DrawerSettingNavigatorScreens } from '../common-types/navigation-types';

type DrawerNavigation = StackNavigationProp<DrawerSettingNavigatorScreens, `DrawerSettingsMenu`>;

export const AccessManage = () => {
  const navigation = useNavigation<DrawerNavigation>();
  const navigateToAccessScreen = useCallback(() => navigation.push(`AccessSettings`), [navigation]);

  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={navigateToAccessScreen}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, backgroundColor: Colors.white, padding: 16 }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Access</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const AccessSettings = () => {
  const [isApproveFollower, setIsApproveFollower] = useState(true);
  const [isAllowMessageRequest, setIsAllowMessageRequest] = useState(true);

  return (
    <View style={{ flex: 1, marginTop: 10, flexDirection: `column`, backgroundColor: Colors.white }}>
      <View style={{ flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, padding: 16 }}>
        <Text style={Typography.text(`plusone`, `bold`, `flex1`)}>Approve follower requests</Text>
        <Switch
          value={isApproveFollower}
          onValueChange={() => setIsApproveFollower((prev) => !prev)}
          trackColor={{ true: `#2C9A44` }}
        />
      </View>
      <Divider />

      <View style={{ flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, padding: 16 }}>
        <Text style={Typography.text(`plusone`, `bold`, `flex1`)}>Allow message requests</Text>
        <Switch
          value={isAllowMessageRequest}
          onValueChange={() => setIsAllowMessageRequest((prev) => !prev)}
          trackColor={{ true: `#2C9A44` }}
        />
      </View>
      <Divider />
    </View>
  );
};
