import { StyleSheet, Text, View, Image, TouchableWithoutFeedback, Linking } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';

type PageTemplateProps = {
  title: string;
  storeUrl: string;
  storeImage: any;
  avatar_url?: string;
};

export const NativePageTemplate: React.FC<PageTemplateProps> = ({ children, storeUrl, storeImage, title, avatar_url }) => {
  return (
    <View style={{ flex: 1, justifyContent: `center`, alignItems: `center` }}>
      {avatar_url ? (
        <Image source={{ uri: avatar_url }} style={styles.avatar} />
      ) : (
        <Image
          source={require(`../../assets/images/icon.png`)}
          width={128}
          height={128}
          style={{ width: 128, height: 128, margin: 32 }}
        />
      )}
      <Text style={[styles.header, { textAlign: `center`, marginHorizontal: 32 }]}>{title}</Text>
      <Text style={[styles.subheader, { textAlign: `center`, marginHorizontal: 32, marginVertical: 19 }]}>{children}</Text>
      <TouchableWithoutFeedback
        onPress={() => Linking.openURL(storeUrl)}
        style={{ margin: 25 }}
        hitSlop={{ top: 20, left: 20, right: 20, bottom: 20 }}
      >
        <Image source={storeImage} width={200} height={67} style={{ width: 200, height: 67, margin: 25 }} />
      </TouchableWithoutFeedback>
      <View style={{ height: 64 }} />
    </View>
  );
};

const styles = StyleSheet.create({
  header: { ...Typography.text(`huge`), fontFamily: `sans-serif` },
  subheader: { ...Typography.text(`large`), fontSize: 18, lineHeight: 28, fontFamily: `sans-serif` },
  avatar: {
    width: 300,
    height: 192,
    borderRadius: 7,
    borderWidth: 1,
    borderColor: Colors.imageBorderColor,
    marginVertical: 16,
    marginHorizontal: 11,
  },
});
