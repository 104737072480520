import React from 'react';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';

import { SelectAmount } from '../../contribution/SelectAmount';
import { Contribute } from '../../contribution/Contribute';
import { PaymentMethodList } from '../../contribution/PaymentMethodList';
import { AddPaymentMethod } from '../../contribution/AddPaymentMethod';
import { EditPaymentMethod } from '../../contribution/EditPaymentMethod';
import { StripeProccessFinishedScreen } from '../../contribution/StripeProccessFinishedScreen';
import { ThanksForContributing } from '../../contribution/ThanksForContributing';
import { PaymentMethod } from '../../common-types/types';

export type ContributionFlowGroupScreens = {
  SelectAmount: undefined;
  Contribute: undefined;
  PaymentMethodList: {
    paymentMethods: PaymentMethod[];
  };
  AddPaymentMethod: undefined;
  EditPaymentMethod: {
    paymentMethod: PaymentMethod;
  };
  StripeProccessFinishedScreen: undefined;
  ThanksForContributing: undefined;
};

export type ContributionFlowGroupProps = {
  [K in keyof ContributionFlowGroupScreens]: {
    //TODO: update components that still receive nav/route props instead of hooks
    screen: React.FC<undefined | { navigation: any; route: any }>;
    options: NativeStackNavigationOptions;
    mode?: `modal` | ``;
  };
};

export const ContributionFlowGroup: ContributionFlowGroupProps = {
  SelectAmount: { screen: SelectAmount, mode: `modal`, options: { headerTitle: `Tip Jar` } },
  Contribute: { screen: Contribute, options: { headerTitle: `Contribution` } },
  PaymentMethodList: { screen: PaymentMethodList, options: { headerTitle: `Payment Method` } },
  AddPaymentMethod: { screen: AddPaymentMethod, options: { headerTitle: `Add payment method` } },
  EditPaymentMethod: { screen: EditPaymentMethod, options: { headerTitle: `Edit payment method` } },
  StripeProccessFinishedScreen: {
    screen: StripeProccessFinishedScreen,
    options: { headerTitle: `Stripe registration finished`, headerLeft: () => null },
  },
  ThanksForContributing: { screen: ThanksForContributing, options: { headerTitle: ``, headerLeft: () => null } },
};
