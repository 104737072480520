import { useMutation } from 'react-apollo';
import { useCallback } from 'react';
import { ReactNativeFile } from 'apollo-upload-client';
import { Activity, LinkPreview, Maybe, Reaction, UserMention } from '../../common-types/types';
import {
  ADD_COMMENT,
  GET_COMMENTS_AND_ONE_LIKE,
  GET_ACTIVITY_V2,
  EDIT_COMMENT,
  COMMENT_PERSONAL_FEED,
  GET_PERSONAL_FEED_BY_ID,
} from '../../graphql';

type AddCommentInput = {
  activity_id: string;
  reaction_id?: string;
  object?: string;
  origin_actor_id: string;
  user_id?: string;
  group_id: string;
  comment_image_to_upload?: ReactNativeFile;
  valid_mentions?: UserMention[];
  link_preview?: LinkPreview;
};

type EditCommentInput = {
  input: {
    activity_id: string;
    reaction_id?: string;
    object?: string;
    origin_actor_id: string;
    user_id?: string;
    group_id: string;
    comment_image_to_upload?: ReactNativeFile;
    valid_mentions?: UserMention[];
    link_preview?: LinkPreview;
    removed?: boolean;
    comment_image: string | null;
    new_mentions: UserMention[];
  };
};

type AddCommentResults = Maybe<{
  addComment: Reaction;
}>;
type EditCommentResults = Maybe<{
  updateReaction2: Reaction;
}>;

type AddComment = {
  input: AddCommentInput;
};

export const useAddComment = (isPersonalFeed = false) => {
  const [internalAddComment, { error, loading }] = useMutation<AddCommentResults, AddComment>(
    isPersonalFeed ? COMMENT_PERSONAL_FEED : ADD_COMMENT,
    {
      fetchPolicy: `no-cache`,
    },
  );

  const [editComment, { error: editError, loading: editLoading }] = useMutation<EditCommentResults, EditCommentInput>(
    EDIT_COMMENT,
  );

  const addComment = useCallback(
    async (input: AddCommentInput, activity: Activity) => {
      await internalAddComment({
        variables: { input },
        refetchQueries: [
          isPersonalFeed
            ? { query: GET_PERSONAL_FEED_BY_ID, variables: { activity_id: activity?.id } }
            : { query: GET_ACTIVITY_V2, variables: { activity_id: activity?.id } },
          { query: GET_COMMENTS_AND_ONE_LIKE, variables: { activity_id: activity?.id, limit: 15 } },
        ],
        fetchPolicy: `no-cache`,
      });
    },
    [internalAddComment, isPersonalFeed],
  );

  return {
    addComment,
    editComment,
    loading: loading || editLoading,
    error: error || editError,
  };
};
