import { Platform, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { useQuery } from 'react-apollo';
import { GET_PERSONA } from '../graphql';
import { Typography } from '../common-styles';
import { LoadingIndicator, QueryError } from '../common-ui';
import { PageTemplate, QRTemplate } from './PageTemplate';
import { NativePageTemplate } from './NativePageTemplate';
import { GetPersonaData, GetPersonaVars, WebInviteLandingPageProps, MobileInviteLandingPageProps } from '../common-types/types';

export type ProfileLandingPageProps = (WebInviteLandingPageProps | MobileInviteLandingPageProps) & {
  invite_token: string;
  variant: typeof Platform.OS;
};

export const ProfileLandingPage: React.FC<ProfileLandingPageProps> = ({ variant, invite_token, ...rest }) => {
  const { loading, error, data, networkStatus, refetch } = useQuery<GetPersonaData, GetPersonaVars>(GET_PERSONA, {
    variables: { id: invite_token },
  });
  if (loading)
    return (
      <View style={{ width: `100%`, height: `100%`, flex: 1, justifyContent: `center`, alignItems: `center` }}>
        <LoadingIndicator size={32} style={{ margin: 32 }} />
      </View>
    );
  if (error && variant === `web`) {
    //return <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />;
    return (
      <PageTemplate>
        <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />
      </PageTemplate>
    );
  }
  if (error && variant !== `web`) {
    //return <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />;
    const { storeUrl, storeImage } = rest as MobileInviteLandingPageProps;
    return (
      <NativePageTemplate storeUrl={storeUrl} storeImage={storeImage} title="Get the app">
        <View style={{ flexDirection: `column` }}>
          <Text style={{ ...Typography.boldHeaderStyle, margin: 20 }}>Server error</Text>
          <Text style={{ ...Typography.text, margin: 20 }}>{error.message}</Text>
        </View>
      </NativePageTemplate>
    );
  }

  const inviter = data?.getPersona;

  if (variant === `web`) {
    const { url } = rest as WebInviteLandingPageProps;
    return (
      <QRTemplate url={url}>
        <Text>
          <Text style={styles.headerBold}>
            {inviter?.handle}
            {` `}
          </Text>
          <Text>has invited you to join Mesh</Text>
        </Text>
      </QRTemplate>
    );
  }

  const { storeUrl, storeImage } = rest as MobileInviteLandingPageProps;
  return (
    <NativePageTemplate storeUrl={storeUrl} storeImage={storeImage} title="Get the app" avatar_url={inviter?.avatar_url}>
      <Text>
        <Text style={styles.headerBold}>
          {inviter?.handle}
          {` `}
        </Text>
        <Text>has invited you to join Mesh</Text>
      </Text>
    </NativePageTemplate>
  );
};

const styles = StyleSheet.create({
  headerBold: { ...Typography.text(`huge`, `bold`), fontFamily: `sans-serif`, fontWeight: `600` },
});
