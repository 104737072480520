import React, { useMemo } from 'react';
import ParsedText from 'react-native-parsed-text';
import { Text } from 'react-native';
import { UseMentionInput } from '../../common-types/types';
import { useValidMention } from './useValidMention';
import { Colors } from '../../common-styles';

// Expermiental version, I'm trying to create a re-usable way separating the component and returning

/**
 * Contains the logic of handling the mentions in the input.
 * @param resolvedMentions Array of mentions that you already validated, so is not necessary to validate again
 * @param value Value of the input
 * @param set Setter of the input that add values to the resolvedMentions
 * @param style current textStyle, defines normal font color
 * @param backgroundColor current content background color, user to determine mention font color
 * @returns
 */
const useMentionInput = ({ resolvedMentions, set, value, groupId, style, backgroundColor }: UseMentionInput) => {
  const { validateMention, error: validationError } = useValidMention(groupId, resolvedMentions);
  /**
   * This is the algorithm that will be used to determine the mentions that are valid or not.
   */
  const resolveMentionsInText = async (text: string) => {
    const mentionsToValidate = text.match(/(@[a-zA-Z0-9_-]+)/gim);
    if (mentionsToValidate?.length) {
      const validatedMentions = await validateMention(mentionsToValidate);
      set(validatedMentions || []);
    }
  };

  const handleKeyPress = (e: any) => {
    const { text } = e?.nativeEvent;
    resolveMentionsInText(text);
  };

  /**
   * Create an array of Text tags that will be used to render the mentions.
   */
  const renderParsedText = useMemo(() => {
    const sortedMentions = resolvedMentions?.sort((a, b) => b.handle.length - a.handle.length);
    const isResolvedMention = new RegExp(`(${sortedMentions.map((m) => `@${m.handle}`).join(`|`)})`, `gmi`);
    const underline = backgroundColor !== Colors.white;
    if (value && resolvedMentions?.length) {
      return (
        <ParsedText
          style={style}
          parse={[
            // Pattern of mentions
            {
              pattern: isResolvedMention,
              style: {
                ...style,
                fontFamily: `inter-semibold`,
                color: underline ? style?.color : Colors.deepPurple,
                textDecorationLine: underline ? `underline` : `none`,
              },
            },
          ]}
        >
          {value}
        </ParsedText>
      );
    }
    return <Text style={{ ...style, color: style?.color ?? Colors.baseText }}>{value}</Text>;
  }, [value, resolvedMentions, style, backgroundColor]);

  return {
    validationError,
    parsedText: renderParsedText,
    handleKeyPress,
    resolveMentionsInText,
  };
};

export { useMentionInput };
