import React from 'react';
import { StyleProp, ViewStyle, StyleSheet, View } from 'react-native';
import { Colors } from '../common-styles';

type ScrollDotsProps = {
  selected: number;
  total: number;
  containerStyle?: StyleProp<ViewStyle>;
};

const _ScrollDots = (props: ScrollDotsProps) => {
  const { selected, total, containerStyle } = props;
  const dots = new Array(total).fill(0);
  if (selected >= 0 && selected < total) dots[selected] = 1;
  return (
    <View style={[styles.scrollDots, containerStyle]}>
      {dots.map((dot, i) => (
        <View
          key={`${i}-${dot}` /* eslint-disable-line react/no-array-index-key */}
          style={{
            height: 8,
            width: 8,
            borderRadius: 999,
            backgroundColor: dot ? Colors.focusedScrollDotColor : Colors.scrollDotColor,
            marginHorizontal: 4,
          }}
        />
      ))}
    </View>
  );
};

_ScrollDots.defaultProps = {
  containerStyle: {},
};

const styles = StyleSheet.create({
  scrollDots: {
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: -20,
  },
});

export const ScrollDots = React.memo(_ScrollDots);
