import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const MasterCardImage = (props) => {
  return (
    <Svg width={66} height={46} viewBox="0 0 66 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0h1300v7847H0z" />
        <Path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill="#262626"
        />
        <Path fill="#FF5F00" d="M27.658 14.525h10.503v16.918H27.658z" />
        <Path
          d="M28.74 22.985a10.687 10.687 0 014.166-8.457c-4.473-3.467-10.896-2.962-14.755 1.16a10.65 10.65 0 000 14.598c3.86 4.121 10.282 4.626 14.755 1.16a10.687 10.687 0 01-4.167-8.46z"
          fill="#EB001B"
        />
        <Path
          d="M49.536 30.083v-.704h.167v-.145h-.397v.145h.157v.704h.073zm.77 0v-.849h-.12l-.14.606-.14-.606h-.12v.849h.087v-.643l.13.552h.09l.13-.552v.643h.083z"
          fill="#F79E1B"
          fillRule="nonzero"
        />
        <Path
          d="M50.558 22.985c0 4.12-2.385 7.877-6.142 9.677a11.034 11.034 0 01-11.51-1.22 10.705 10.705 0 004.168-8.458c0-3.301-1.536-6.42-4.168-8.46a11.034 11.034 0 0111.51-1.219c3.757 1.8 6.142 5.558 6.142 9.677v.003z"
          fill="#F79E1B"
        />
      </G>
    </Svg>
  );
};
