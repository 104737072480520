import React, { useState, useEffect, useCallback } from 'react';
import { Text, TouchableOpacity, View, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { LinearGradient } from 'expo-linear-gradient';
import { NetworkStatus } from 'apollo-client';
import { useQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import { Colors, Typography, _bad_do_not_use_HEIGHT } from '../common-styles';
import { LoadingIndicator, MeshIcon, QueryError, ThemedButton, getRandomBlob, BackButton } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { WizardLayout } from '../community-create/WizardLayout';
import { SETTING_OPTIONS } from '../graphql';
import { setProfileInterests, movePrev, finishWizard } from '../redux/createProfileSlice';
import { InterestsTestIds } from './testLabels';
import { useCreatePersona } from './useCreatePersona';

export const ProfileInterests = ({ navigation }) => {
  const { profileInterests } = useSelector((state) => state.createProfile);
  const [options, setOptions] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState(profileInterests);
  //const [submitting, setSubmitting] = useState(false);
  const { data, loading, error, networkStatus, refetch } = useQuery(SETTING_OPTIONS, {
    variables: { settingQuery: { query: `group` } },
  });
  const { loading: submitting, error: createError, createPersona } = useCreatePersona();

  const dispatch = useDispatch();

  const onSubmit = async () => {
    dispatch(setProfileInterests(selectedInterests));

    const persona = await createPersona(selectedInterests);
    if (persona && !createError) {
      dispatch(finishWizard());
    }

    navigation.popToTop();
  };

  const setInterests = useCallback(
    (interest, found) => {
      if (found) {
        setSelectedInterests(selectedInterests.filter((selected) => selected.value !== interest.value));
      } else {
        setSelectedInterests([...selectedInterests, interest]);
      }
    },
    [selectedInterests],
  );

  const renderItem = useCallback(
    ({ item, _index }) => {
      const found = selectedInterests.find((selected) => {
        return selected.value === item.value;
      });
      return <InterestItem interest={item} found={!!found} setInterests={setInterests} />;
    },
    [setInterests, selectedInterests],
  );

  const goBack = useCallback(() => {
    dispatch(movePrev());
    navigation.popToTop();
  }, [dispatch, navigation]);

  // Effect #1: set Redux aware back function (manages current step)
  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
  }, [navigation, goBack]);

  // Effect #2: sets category options from server
  useEffect(() => {
    const settings = data?.getSettingOptions.find((setting) => setting.key === `group.setting.type`) || [];
    setOptions(settings.options);
  }, [setOptions, data]);

  const loadingComp = () => {
    if (networkStatus === NetworkStatus.loading || loading) {
      return (
        <View style={{ justifyContent: `center`, alignItems: `center` }}>
          <LoadingIndicator />
        </View>
      );
    }
    return null;
  };
  const nextDisabled = selectedInterests && selectedInterests.length < 3;
  const Blob = getRandomBlob();
  return (
    <WizardLayout total={6} selector="createProfile">
      <FlatList
        data={options}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ListHeaderComponent={() => (
          <View style={{ marginBottom: 10 }}>
            <Blob width={32} height={32} style={{ position: `absolute`, left: -8, top: -2, zIndex: -1 }} />
            <Text style={localStyles.headerText}>Pick at least three or more categories to follow</Text>
          </View>
        )}
        ListFooterComponent={() => loadingComp()}
        ListEmptyComponent={() => {
          if (!loading && (error || !data)) {
            return <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />;
          }
          return null;
        }}
        style={[localStyles.listContainer, localStyles.scrollview]}
        overScrollMode="always"
        testID={InterestsTestIds.screen}
      />
      <LinearGradient
        colors={[`rgba(255,255,255,0.1)`, `rgba(255,255,255,1)`]}
        locations={[0, 0.35]}
        style={localStyles.linearGradientStyle}
      >
        <ThemedButton
          rounded
          title="Continue"
          testID={InterestsTestIds.continue}
          onPress={onSubmit}
          buttonStyle={{ padding: 15 }}
          titleStyle={localStyles.buttonTitle}
          containerStyle={{ marginHorizontal: 16 }}
          disabled={submitting || nextDisabled}
          leftIcon={submitting && <LoadingIndicator />}
        />
      </LinearGradient>
    </WizardLayout>
  );
};
ProfileInterests.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

const InterestItem = ({ interest, found, setInterests }) => {
  const iconName = !found ? `plus` : `check`;
  const borderWidth = !found ? 1 : 2;
  const fillBg = !found ? Colors.white : `${Colors.brandPurple}20`;
  return (
    <TouchableOpacity
      key={interest.id}
      activeOpacity={0.8}
      style={{ marginVertical: 12 }}
      onPress={() => setInterests(interest, found)}
      testID={InterestsTestIds.option}
    >
      <View style={{ ...localStyles.interestContainer, borderWidth, borderColor: Colors.brandPurple, backgroundColor: fillBg }}>
        <Text style={{ ...Typography.text(`base`, `bold`, `theme`), flexShrink: 2 }} ellipsizeMode="tail" numberOfLines={1}>
          {interest.label}
        </Text>
        <MeshIcon name={iconName} color={Colors.brandPurple} focused={!!found} />
      </View>
    </TouchableOpacity>
  );
};
InterestItem.propTypes = {
  interest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  found: PropTypes.bool.isRequired,
  setInterests: PropTypes.func.isRequired,
};

// const FeedbackModal = ({ isVisible, toggleModal, feedbackResponse, handleChange }) => {
//   return (
//     <Modal isVisible={isVisible} onBackdropPress={toggleModal} avoidKeyboard>
//       <View style={localStyles.modal}>
//         <View>
//           <Image source={require(`../../assets/images/pencil.png`)} style={{ alignSelf: `center` }} />
//         </View>
//         <Text style={{ ...localStyles.headerText, marginVertical: 10 }}>Let us know which interests are important to you</Text>
//         <TextInput
//           blurOnSubmit
//           multiline
//           autoFocus
//           placeholder="Share your thoughts"
//           value={feedbackResponse}
//           onChangeText={handleChange}
//           style={localStyles.modalTextInput}
//         />
//         <ThemedButton
//           rounded
//           label="Done"
//           onPress={toggleModal}
//           buttonStyle={{ padding: 15 }}
//           labelStyle={{ fontSize: 15, fontWeight: `bold` }}
//           containerStyle={{ marginVertical: 15 }}
//         />
//       </View>
//     </Modal>
//   );
// };
// FeedbackModal.propTypes = {
//   isVisible: PropTypes.bool.isRequired,
//   toggleModal: PropTypes.func.isRequired,
//   feedbackResponse: PropTypes.string.isRequired,
//   handleChange: PropTypes.func.isRequired,
// };

const localStyles = {
  scrollview: {
    padding: 16,
  },
  listContainer: {
    paddingBottom: 250,
  },
  headerText: {
    ...Typography.text(`bold`, `large`, `black`),
  },
  headerSubtext: {
    ...Typography.text(`small`, `gray`, `bold`),
  },
  modal: {
    backgroundColor: Colors.white,
    padding: 10,
    borderRadius: 20,
  },
  modalTextInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.gray,
    height: 60,
    maxHeight: 80,
    marginVertical: 15,
    borderRadius: 4,
  },
  linearGradientStyle: {
    position: `absolute`,
    left: 0,
    right: 0,
    top: _bad_do_not_use_HEIGHT * 0.77,
    height: 200,
  },
  buttonTitle: {
    ...Typography.text(`plustwo`, `white`, `bold`),
    marginLeft: 4,
  },
  interestContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    padding: 8,
    borderRadius: 4,
    alignItems: `center`,
  },
};
