import React from 'react';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { useSelector } from 'react-redux';
import { Colors, headerStyle } from '../common-styles';
import { Avatar, BackButton } from '../common-ui';
import { ExploreScreen } from '../explore/ExploreScreen';
import { ExploreSearch } from '../explore/ExploreSearch';
import { ExploreSeeAllGroups } from '../explore/ExploreSeeAllGroups';
import { ExploreAllRecommendations } from '../explore/ExploreAllRecommendations';
import { CategoryListView } from '../explore/CategoryListView';
import { CommunityPreview } from '../community/v2/CommunityPreview';
import { AboutScreen, AffiliatedCommunitiesHomeList } from '../community';
import { PostDetail } from '../post';
import GuestPromptBottomSheet from '../common-ui/GuestPromptBottomSheet';
import { GuestState } from '../common-types/types';
import { AppContext } from '../../AppContext';
import { useAppDispatch } from '../redux/store';
import { toggleJoinMeshModal } from '../redux/guestSlice';
import { ExploreNavigatorScreens } from '../common-types/navigation-types';

const GuestStackProps: NativeStackNavigationOptions = {
  headerLeft: () => <BackButton />,
  headerStyle,
  headerTitleAlign: `center`,
  contentStyle: { backgroundColor: Colors.white },
};

export const GuestStackNavigator = () => {
  const GuestStack = createNativeStackNavigator<ExploreNavigatorScreens>();
  const joinMeshModalOpen: boolean = useSelector((state: { guest: GuestState }) => state.guest.joinMeshModalOpen);

  return (
    <>
      <GuestStack.Navigator screenOptions={GuestStackProps} initialRouteName="Explore">
        <GuestStack.Screen
          name="Explore"
          component={ExploreScreen}
          options={{ headerTitle: `Explore`, headerLeft: () => <GuestDrawerMenuIcon /> }}
        />
        <GuestStack.Screen name="ExploreSearch" component={ExploreSearch} options={{ headerTitle: `Search` }} />
        <GuestStack.Screen name="ExploreSeeAllGroups" component={ExploreSeeAllGroups} />
        <GuestStack.Screen
          name="ExploreAllRecommendations"
          component={ExploreAllRecommendations}
          options={{ title: `Recommended Communities` }}
        />
        <GuestStack.Screen name="CategoryListView" component={CategoryListView} options={{ title: `Category` }} />
        <GuestStack.Screen name="CommunityPreview" component={CommunityPreview} options={{ headerShown: false }} />
        <GuestStack.Screen name="PostDetail" component={PostDetail} />
        <GuestStack.Screen name="AboutScreen" component={AboutScreen} options={{ title: `About` }} />
        <GuestStack.Screen
          name="AffiliatedCommunitiesHomeList"
          component={AffiliatedCommunitiesHomeList}
          options={{ title: `Affiliated communinties` }}
        />
      </GuestStack.Navigator>
      <GuestPromptBottomSheet isVisible={joinMeshModalOpen} />
    </>
  );
};

const GuestDrawerMenuIcon = () => {
  const { user } = React.useContext(AppContext);
  const dispatch = useAppDispatch();
  if (!user) return <BackButton />;
  return (
    <Avatar
      testID="DRAWER_BUTTON"
      hitSlop={{ top: 15, bottom: 15, left: 15, right: 15 }}
      style={{ marginHorizontal: 14 }}
      user={user}
      size={24}
      border
      onPress={() => dispatch(toggleJoinMeshModal({ open: true }))}
    />
  );
};
