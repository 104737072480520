import Svg, { G, Path } from 'react-native-svg';
import React from 'react';

export const ChatBubbles = (props) => {
  return (
    <Svg width="194px" height="112px" viewBox="0 0 194 112" {...props}>
      <G transform="translate(-91 -117)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H375V812H0z" />
        <Path
          d="M100.657 14.242c9.122 9.304 13.562 22.902 15.095 38.01 1.453 15.03 0 31.57-9.121 42.942-9.041 11.371-25.831 17.653-40.846 15.904-15.014-1.75-28.252-11.53-40.845-22.902C12.348 76.825.32 63.783.24 50.662c0-13.04 11.865-26.162 24.539-35.466C37.37 5.813 50.69.246 64.252.008c13.561-.239 27.364 4.85 36.405 14.234z"
          fill="#CAF0F4"
          fillRule="nonzero"
          transform="translate(91 117)"
        />
        <G transform="translate(91 117) translate(62 17) translate(.818 .579) translate(0 .263)">
          <Path
            d="M118.006 40.755c0-15.683-14.982-28.396-33.464-28.396S51.078 25.072 51.078 40.755c0 15.683 14.982 28.396 33.464 28.396 2.543 0 5.015-.249 7.395-.704 1.28 2.614 5.1 8.123 14.439 7.472-2.731-1.247-3.449-6.518-3.495-11.416 9.107-5.074 15.125-13.812 15.125-23.748z"
            stroke="#373232"
            strokeWidth={1.4}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M74.154 41.494a3.59 3.59 0 01-3.584 3.598 3.59 3.59 0 01-3.584-3.598 3.59 3.59 0 013.584-3.597 3.59 3.59 0 013.584 3.597M88.219 41.494a3.59 3.59 0 01-3.584 3.598 3.59 3.59 0 01-3.584-3.598 3.59 3.59 0 013.584-3.597 3.59 3.59 0 013.584 3.597M102.283 41.494a3.59 3.59 0 01-3.584 3.598 3.59 3.59 0 01-3.584-3.598 3.59 3.59 0 013.584-3.597 3.59 3.59 0 013.584 3.597"
            fill="#373232"
            fillRule="nonzero"
          />
          <Path
            d="M0 29.983C0 14.3 14.982 1.587 33.464 1.587S66.928 14.3 66.928 29.983c0 15.683-14.982 28.396-33.464 28.396-2.542 0-5.015-.249-7.395-.704-1.279 2.614-5.1 8.123-14.438 7.472 2.73-1.247 3.448-6.518 3.494-11.416C6.018 48.657 0 39.92 0 29.983"
            fill="#333895"
            fillRule="nonzero"
          />
          <Path
            d="M22.68 31.3a3.59 3.59 0 01-3.584 3.598 3.59 3.59 0 01-3.584-3.598 3.59 3.59 0 013.584-3.598A3.59 3.59 0 0122.68 31.3M36.745 31.3a3.59 3.59 0 01-3.584 3.598 3.59 3.59 0 01-3.584-3.598 3.59 3.59 0 013.584-3.598 3.59 3.59 0 013.584 3.598M50.81 31.3a3.59 3.59 0 01-3.584 3.598 3.59 3.59 0 01-3.584-3.598 3.59 3.59 0 013.584-3.598A3.59 3.59 0 0150.81 31.3"
            fill="#FFF"
            fillRule="nonzero"
          />
          <Path
            stroke="#373232"
            strokeWidth={1.4}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M113.907245 0L110.483762 9.1462"
          />
          <Path
            stroke="#373232"
            strokeWidth={1.4}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M124.562264 8.5659L117.220203 14.61145"
          />
          <Path
            stroke="#373232"
            strokeWidth={1.4}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M129.598992 21.0966L121.162142 22.3055"
          />
        </G>
      </G>
    </Svg>
  );
};
