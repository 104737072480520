import { ReactNativeFile } from 'apollo-upload-client';
import { debounce } from 'lodash';
import React, { forwardRef, MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Alert, Image, Keyboard, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../AppContext';
import { Colors, MeshIconsNames, Typography } from '../common-styles';
import { Activity, Comment, UserMention } from '../common-types/types';
import { LoadingIndicator, MeshIcon } from '../common-ui';
import { pickOrderedImages } from '../common-util/FilePicker';
import * as Sentry from '../constants/Sentry';
import useMediaPermissons from '../hooks/useMediaPermissions';
import { Modals, setModal, setReplyTo } from '../redux/postSlice';
import { RootState } from '../redux/store';
import { cleanTypenameFromLink, guaranteeGroup } from './helpers';
import { useLinkPreview } from './hooks/useLinkPreview';
import { MentionTextInput } from './MentionTextInput';
import PostContentLinkPreview from './components/PostContentLinkPreview';
import { useAddComment } from './hooks/useAddComment';

type CommentInputProp = {
  activity: Activity;
  commentId: string;
  visible: boolean;
  onCommentInputEnd: () => void;
};

type Media = {
  uri: string;
};

const hasDiffContent = (isEdit: boolean, targetComment?: Comment, inputText?: string, imageToUpload?: Media) => {
  if (!isEdit) return true;
  if (!targetComment) return false;
  // checks link previews, text content and mentions
  const hasSameText = targetComment.data?.object === inputText;
  // checks new image or video prescense
  const hasSameMedia = !imageToUpload;

  return !(hasSameMedia && hasSameText);
};

// eslint-disable-next-line complexity
export const CommentInput = forwardRef(({ activity, commentId, visible, onCommentInputEnd }: CommentInputProp, ref) => {
  const { user } = React.useContext(AppContext);
  const tabBarHeight = useBottomTabBarHeight();
  const urlRegex =
    /\b((?:(?:http|https)+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/gi;
  const { isEdit, targetComment, is_member, replyingTo, post_frozen, user_frozen, banned, has_agreed } = useSelector(
    (state: RootState) => state.post,
  );
  const dispatch = useDispatch();
  const { checkPermission } = useMediaPermissons();
  const [inputText, setInputText] = useState(``);
  const [imageToUpload, setImageToUpload] = useState<Media>();
  const [submitting, setSubmitting] = useState(false);
  const [resolvedMentions, setResolvedMentions] = useState<UserMention[]>([]);
  const [replyingId, setReplyingId] = useState(targetComment?.id);
  const { considerLinkFetch, linkPreview, setLinkPreview, getAsyncLinkPreview } = useLinkPreview();
  const [dontFetchLink, setDontFetchLink] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [originalMentions, setOriginalMentions] = useState<UserMention[]>([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchLinkPreview = useCallback(
    debounce((text: string) => {
      considerLinkFetch(text);
    }, 1000),
    [],
  );

  // Add comment or subcomment
  //const [addComment] = useMutation<AddCommentResults, AddComment>(ADD_COMMENT);
  const isPersonalFeed = useMemo(() => {
    return activity?.['__typename'] === 'ProfileActivity';
  }, [activity]);

  const { addComment, editComment } = useAddComment(isPersonalFeed);
  React.useEffect(() => {
    if (inputText.length === 0) setDontFetchLink(false);
  }, [inputText]);

  React.useEffect(() => {
    if (targetComment?.data && isEdit) {
      setInputText(targetComment?.data?.object || ``);
      setOriginalMentions(targetComment.data?.validMentionTargets?.map(({ id, handle, name }) => ({ id, handle, name })) || []);
    }

    if (targetComment && commentId && commentId !== replyingId) {
      if (!!user?.id && user.id !== targetComment.user_id) {
        setInputText(`@${targetComment?.user?.handle} `);
      }
      setReplyingId(targetComment?.id);
    }
  }, [targetComment, replyingId, isEdit, user?.id, commentId]);

  const addPhotoToComment = async () => {
    try {
      const isGranted = await checkPermission();
      if (!isGranted) return;

      // Devs: An empty array is being sent because we don't want to be caching the previous selection, it becomes annoying
      const { assets } = await pickOrderedImages([], 1);
      const [asset] = assets || {};

      const uploadFile = new ReactNativeFile({
        uri: asset?.uri || ``,
        type: asset.type,
        name: uuidv4(),
      });

      setImageToUpload(uploadFile);
      setLinkPreview(undefined);
      setDontFetchLink(true);
    } catch (error) {
      Alert.alert(`Error trying to load the image`);
      console.error(`Error while adding photo in comment`);
    }
  };

  const hasContent = inputText.trim().length > 0 || imageToUpload;
  const hasValidReply = !replyingTo || !!(replyingTo && targetComment);
  const hasValidEdit = hasDiffContent(isEdit, targetComment, inputText, imageToUpload);
  const submitButtonEnabled = hasContent && hasValidEdit && hasValidReply && !user_frozen && !banned;

  const clearCommentBox = () => {
    setInputText(``);
    setImageToUpload(undefined);
    dispatch(setReplyTo(undefined));
    setSubmitting(false);
    setReplyingId(undefined);
    Keyboard.dismiss();
    onCommentInputEnd();
  };

  const submit = async () => {
    try {
      setSubmitting(true);
      const { id: activity_id, actor, origin_group } = activity;
      const group = guaranteeGroup(origin_group);
      const canSubmit = isAbleToSubmit(activity);
      // Priotize image if available over linkPreview
      const commentImage = linkPreview ? undefined : imageToUpload;
      //Check if there is a valid url before tring to fetch a link preview (this is also done in useLinkPreview)
      const matches = inputText.match(urlRegex) || null;
      const asyncPreview = linkPreview ?? (matches && (await getAsyncLinkPreview()));

      if (canSubmit) {
        const input = {
          activity_id,
          reaction_id: replyingTo ? targetComment?.id : undefined,
          object: inputText,
          origin_actor_id: actor,
          user_id: user?.id,
          group_id: group.id,
          comment_image_to_upload: commentImage,
          valid_mentions: resolvedMentions.map(({ id, handle, name }) => ({ id, handle, name })),
          link_preview: asyncPreview ? cleanTypenameFromLink(asyncPreview) : undefined,
        };
        await addComment(input, activity);
      }
    } catch (error) {
      console.error(`exception in submitComment:`, error);
      Sentry.withScope((scope: any) => {
        scope.setExtras({ error, activity, inputText });
        Sentry.captureException(error as Error);
      });
    } finally {
      clearCommentBox();
      setLinkPreview(undefined);
    }
  };

  const submitEdit = async () => {
    try {
      const { id: activity_id, actor } = activity;
      const canSubmit = isAbleToSubmit(activity);
      // Priotize image if available over linkPreview
      if (!isEdit || !targetComment || !canSubmit) return;
      const matches = inputText.match(urlRegex) || [];
      const asyncPreview = linkPreview ?? (matches && (await getAsyncLinkPreview()));
      const commentImage = linkPreview ? undefined : imageToUpload;
      setSubmitting(true);
      const newMentions = resolvedMentions.filter(({ id: id1 }) => !originalMentions.some(({ id: id2 }) => id2 === id1));
      const { id, data } = targetComment;

      //const formattedResolvedMentions = resolvedMentions?.map(({ id, handle, name }) => ({ id, handle, name })) || [];

      const input = {
        activity_id,
        reaction_id: id,
        object: inputText,
        origin_actor_id: actor,
        group_id: data?.group_id,
        removed: data?.removed,
        comment_image_to_upload: commentImage,
        comment_image: imageToUpload ? data.comment_image : null,
        valid_mentions: resolvedMentions.map(({ id, handle, name }) => ({ id, handle, name })),
        new_mentions: newMentions.map(({ id, handle, name }) => ({ id, handle, name })),
        link_preview: asyncPreview && cleanTypenameFromLink(asyncPreview),
      };
      await editComment({ variables: { input } });
      setLinkPreview(undefined);
    } catch (error) {
      console.error(`exception in submitComment:`, error);
      Sentry.withScope((scope: any) => {
        scope.setExtras({ error, activity, inputText });
        Sentry.captureException(error as Error);
      });
    } finally {
      clearCommentBox();
      setLinkPreview(undefined);
    }
  };

  const isAbleToSubmit = (activity: Activity) => {
    if (!activity.can_participate) {
      if (banned) {
        dispatch(setModal(Modals.BANNED));
      } else if (activity.origin_group_member && !banned) {
        dispatch(setModal(Modals.AGREE));
      } else if (user_frozen) {
        dispatch(setModal(Modals.USER_FROZEN));
      } else if (post_frozen) {
        dispatch(setModal(Modals.POST_FROZEN)); //input wouldn't have rendered
      } else dispatch(setModal(Modals.JOIN));
      return false;
    }
    return true;
  };

  const onInputFocus = () => {
    const { guidelines } = activity?.origin_group || undefined;
    if (post_frozen) dispatch(setModal(Modals.POST_FROZEN));
    else if (is_member && banned) dispatch(setModal(Modals.BANNED));
    else if (is_member && !banned && !has_agreed && guidelines !== `[]`) dispatch(setModal(Modals.AGREE));
    else if (!is_member) dispatch(setModal(Modals.JOIN));
    setShowKeyboard(true);
  };

  const onInputBlur = () => {
    setShowKeyboard(false);
  };

  // editing comment
  const cancelEdit = () => {
    (ref as MutableRefObject<TextInput>)?.current?.clear();
    clearCommentBox();
    setLinkPreview(undefined);
    Keyboard.dismiss();
  };

  const addToResolvedMentions = (validatedMentions: UserMention[]) => {
    setResolvedMentions(validatedMentions);
  };

  const handleInputChange = (text: string) => {
    setInputText(text);
    if (!linkPreview && !dontFetchLink) {
      debounceFetchLinkPreview(text);
    }
  };

  const handleCloseLinKPreview = () => {
    setLinkPreview(undefined);
    setDontFetchLink(true);
  };

  if (!visible) return null;

  return (
    <View style={{ marginBottom: showKeyboard && Platform.OS === `ios` ? -tabBarHeight : 0 }}>
      {linkPreview && !dontFetchLink && (
        <View style={{ width: `100%`, borderColor: Colors.chatTextInputDefaultBorderColor, borderWidth: 1 }}>
          <PostContentLinkPreview linkPreview={linkPreview} onClose={handleCloseLinKPreview} />
        </View>
      )}
      <View testID="CommentBar" style={localStyles.commentBar}>
        <ImagePreview image={imageToUpload?.uri} onClose={() => setImageToUpload(undefined)} />
        <ReplyTo isReplying={!!replyingTo} onClose={clearCommentBox} />
        <EditPreview isEdit={isEdit} onClose={cancelEdit} />
        {!post_frozen && (
          <View testID="BaseCommentBar" style={{ flex: 0, flexDirection: `row` }}>
            <View testID="InputAndIconWrapper" style={localStyles.inputIconWrapper}>
              <InputIcon name="image" onPress={() => addPhotoToComment()} />
              <MentionTextInput
                testID="POST_CONTENT"
                ref={ref}
                style={localStyles.commentInput}
                value={inputText}
                groupId={activity?.origin_group?.id}
                backgroundColor={Colors.white}
                addToResolvedMentions={addToResolvedMentions}
                resolvedMentions={resolvedMentions}
                mentionsCount={resolvedMentions?.length}
                placeholder="Say something"
                onChangeText={handleInputChange}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                multiline
                scrollEnabled
              />
              {submitting ? (
                <LoadingIndicator size="small" style={localStyles.indicatorMargins} />
              ) : (
                <InputIcon
                  name="arrow-right"
                  testID="SUBMIT_COMMENT"
                  onPress={isEdit ? submitEdit : submit}
                  color={Colors.focusedIconColor}
                  disabled={!submitButtonEnabled}
                />
              )}
            </View>
          </View>
        )}
      </View>
    </View>
  );
});

type ReplyToProps = {
  isReplying: boolean;
  onClose: () => void;
};

const ReplyTo: React.FC<ReplyToProps> = ({ isReplying, onClose }) => {
  if (!isReplying) return null;
  //{replyingTo && (
  return (
    <View style={localStyles.replyTo}>
      <Text style={Typography.text(`small`, `gray`, `bold`)}>Replying</Text>
      <InputIcon name="circle-x" size={16} onPress={onClose} style={{ marginLeft: 8 }} activeOpacity={0.8} />
    </View>
  );
};

type ImagePreviewProps = {
  image?: string;
  onClose: () => void;
};

const ImagePreview: React.FC<ImagePreviewProps> = ({ image, onClose }) => {
  if (!image) return null;
  return (
    <View style={{ marginBottom: 4 }}>
      <Image source={{ uri: image }} resizeMode="cover" style={localStyles.image} />
      <InputIcon focused name="circle-x" color={Colors.translucentCloseIcon} onPress={onClose} style={localStyles.closeIcon} />
    </View>
  );
};

type EditPreviewProps = {
  isEdit: boolean;
  onClose: () => void;
};

const EditPreview: React.FC<EditPreviewProps> = ({ isEdit, onClose }) => {
  if (!isEdit) return null;
  return (
    <View style={{ flexDirection: `row`, alignItems: `center`, paddingBottom: 8 }}>
      <Text style={Typography.text(`small`, `gray`, `bold`)}>Editing comment</Text>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={onClose}
        style={{ marginLeft: 4 }}
        hitSlop={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <View>
          <MeshIcon name="circle-x" size={16} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const InputIcon = ({
  onPress,
  testID,
  name,
  focused = false,
  color = Colors.iconColor,
  disabled = false,
  activeOpacity,
  style,
  size = 24,
}: InputIconProps) => {
  return (
    <TouchableOpacity testID={testID} onPress={disabled ? () => null : onPress} activeOpacity={activeOpacity} style={style}>
      <MeshIcon
        focused={focused}
        name={name}
        size={size}
        style={size > 20 ? localStyles.iconMargins : localStyles.smallIconMargins}
        color={disabled ? Colors.disabledIconColor : color}
      />
    </TouchableOpacity>
  );
};

type InputIconProps = {
  name: MeshIconsNames;
  onPress: () => void;
  testID?: string;
  color?: string;
  focused?: boolean;
  disabled?: boolean;
  activeOpacity?: number;
  size?: number;
  style?: any;
};

const localStyles = StyleSheet.create({
  commentBar: {
    flex: 0,
    flexDirection: `column`,
    borderTopColor: `#D8D8D8`,
    borderTopWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  image: { width: 100, height: 100, borderWidth: 1, borderColor: Colors.mediumLightGray, borderRadius: 5 },
  closeIcon: { position: `absolute`, top: -10, left: 65 },
  inputIconWrapper: {
    maxHeight: 200,
    flex: 1,
    flexDirection: `row`,
    alignItems: `flex-end`,
    borderWidth: 1,
    borderColor: `#D8D8D8`,
    borderRadius: 4,
    margin: 2,
  },
  replyTo: {
    flex: 0,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    paddingLeft: 5,
    paddingBottom: 8,
    alignItems: `center`,
  },
  iconMargins: { marginLeft: 8, marginVertical: 12, marginRight: 11 },
  smallIconMargins: { margin: 4, marginLeft: 2 },
  indicatorMargins: { marginLeft: 10, marginVertical: 14, marginRight: 13 },
  commentInput: {
    flex: 1,
    fontFamily: `inter-regular`,
    fontSize: 15,
    lineHeight: 22,
    margin: 4,
    marginBottom: 9,
    paddingLeft: 12,
    paddingVertical: 5,
  },
});
