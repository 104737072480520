import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useSelector } from 'react-redux';
import { Alert } from 'react-native';
import { JOIN_GROUP, refetchQueriesFor } from '../../graphql';
import { AppContext } from '../../../AppContext';
import { Group, GroupCoreFields } from '../../common-types/types';
import { RootState, useAppDispatch } from '../../redux/store';
import { setActiveCommunity, setForceExplorerRefresh, setMemberCount, setPreviewCommunity } from '../../redux/feedSlice';
import { toggleJoinMeshModal } from '../../redux/guestSlice';
import { resetApplication } from '../../redux/applicationWizardSlice';
import { useRefreshCommunities } from '../../common-util/hooks/useRefreshCommunities';
import { Events, PendoTrackEvent } from '../../pendo/events';

type JoinNavigationRoutes = {
  CommunityApplication: {
    group: GroupCoreFields;
  };
  CommunityCodeOfConduct: {
    group: GroupCoreFields;
  };
  CommunityPreview: {
    group?: GroupCoreFields | null;
    invite_token?: string;
    triggerDecideJoinMethod?: boolean;
    previous?: `Explore` | string;
  };
  HomeNavigator: {
    screen: `HomeScreen`;
  };
  Explore: undefined;
};

type JoinNavigation = StackNavigationProp<JoinNavigationRoutes, `CommunityPreview`>;

enum JoinableType {
  ANYONE = `anyone`,
  BY_APPROVAL = `by_approval`,
}

export const validGuidelines = (guidelines?: string) => {
  try {
    if (!guidelines) return false;
    const parsedGuidelines = guidelines && JSON.parse(guidelines || ``);
    return Array.isArray(parsedGuidelines) && parsedGuidelines.length > 0;
  } catch (error) {
    return false;
  }
};

type JoiningFrom = `CommunityPreview` | `CommunityApplication` | `CommunityCodeOfConduct` | undefined;

export const useJoinCommunity = (group?: (GroupCoreFields & Partial<Group>) | null) => {
  const { user, invite_token, isGuestMode, setInviteToken } = React.useContext(AppContext);
  const navigation = useNavigation<JoinNavigation>();
  const { joinedCommunities } = useSelector((state: RootState) => state.feed);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { fetchJoinedCommunities } = useRefreshCommunities();
  const [joinCommunityMutation, response] = useMutation(JOIN_GROUP, {
    refetchQueries: refetchQueriesFor(`Group`, `GroupMember`),
  });
  const attemptJoinCommunity = React.useCallback(
    // eslint-disable-next-line complexity
    async (from: JoiningFrom) => {
      if (!group) return;
      if (isGuestMode) {
        dispatch(toggleJoinMeshModal({ open: true, group }));
        return;
      }
      console.log(`attemptJoinCommunity -> from`, from);
      const { id, application, guidelines } = group || {};
      if (from !== `CommunityApplication` && application && application.questions.length !== 0) {
        console.log(`The group has an application, guidelines managed be it `);
        dispatch(resetApplication());
        navigation.navigate(`CommunityApplication`, { group });
        return;
      }
      if (from !== `CommunityCodeOfConduct` && !application && validGuidelines(guidelines)) {
        console.log(`The group has no application, but it has custom guidelines - showing guidelines`);
        navigation.navigate(`CommunityCodeOfConduct`, { group });
        return;
      }
      if (group?.id && user?.id) {
        try {
          setLoading(true);
          const { data, errors } = await joinCommunityMutation({
            variables: { group_id: id, invite_token },
          });
          const { application_pending, join_request_pending, group } = data?.joinGroup || {};
          if (errors) throw errors[1];
          if (!joinedCommunities || joinedCommunities.length < 1) {
            await fetchJoinedCommunities();
            if (!application_pending && !join_request_pending) {
              dispatch(setActiveCommunity({ user_id: user.id, group }));
            }
            setLoading(false);
            return;
          }
          if (!application_pending && !join_request_pending) {
            console.log(`[JoinCommunity] setActiveCommunity:`, data);
            if (group?.joinable === JoinableType.BY_APPROVAL) {
              dispatch(setPreviewCommunity(group));
              dispatch(setForceExplorerRefresh(true));
              setTimeout(() => {
                navigation.navigate(`CommunityPreview`, { previous: `Explore` });
              }, 200);
            } else {
              console.log(`setting active community to`, group);
              if (from === `CommunityPreview`)
                PendoTrackEvent(Events.JOIN_COMMUNITY, {
                  community_name: group.name,
                  source: `community preview`,
                  application_required: false,
                  admin_approval_required: false,
                });
              setTimeout(() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: `Explore` }],
                });
                navigation.navigate(`HomeNavigator`, { screen: `HomeScreen` });
                if (user?.id) {
                  dispatch(setActiveCommunity({ user_id: user?.id, group }));
                  dispatch(setMemberCount(group));
                }
              }, 500);
            }
          }
        } catch (error) {
          console.warn(`[JoinCommunity Error]: ${error}`);
          Alert.alert((error as Error)?.message);
          setLoading(false);
        } finally {
          if (invite_token) {
            setInviteToken(undefined);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [group, invite_token, isGuestMode, navigation, user?.id, dispatch, joinCommunityMutation, setInviteToken],
  );

  return { response, attemptJoinCommunity, loading };
};
