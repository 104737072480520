import { useEffect } from 'react';
import { Audio } from 'expo-av';

const soundObject = new Audio.Sound();

/**
 * Issue: media muted if iOS silent switch is on, even with "playsInSilentModeIOS: true"
 * Expo-AV has a bug where Audio Mode isn't applied until an audio file is loaded at least once
 * by loading an empty mp3 file we trick Expo-AV to loade the new Audio profile and ignore iOS silent switch
 *
 * ref: https://stackoverflow.com/a/62331403/8079868
 */

export const useSoundProfile = () => {
  useEffect(() => {
    const enableSound = async () => {
      try {
        await Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
        await soundObject.loadAsync(require(`../../../assets/silence.mp3`));
        await soundObject.playAsync();
        await soundObject.unloadAsync();
      } catch (error: any) {
        console.warn(error.message);
      }
    };
    enableSound();
  }, []);
};
