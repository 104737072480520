import { useMutation } from 'react-apollo';
import { Alert, StyleSheet, Text, View, TextInput } from 'react-native';
import React, { useState, useCallback } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { addPersona, fetchPersona } from '../auth/helpers';
import { Colors, Typography } from '../common-styles';
import { MeshIcon, ThemedButton, ListItem, BackButton } from '../common-ui';
import { refetchQueriesFor, UPDATE_SETTING_MUTATION } from '../graphql';
import { AppContext } from '../../AppContext';
import { useAppDispatch } from '../redux/store';
import { setActiveCommunity } from '../redux/feedSlice';
import { GroupCoreFields, SettingItem } from '../common-types/types';
import { SettingScreensParamList } from '../common-types/navigation-types';

type TextEditorRoute = RouteProp<SettingScreensParamList, `TextSettingEditor`>;

type TextEditorNavigation = StackNavigationProp<SettingScreensParamList, `TextSettingEditor`>;

interface TextSettingItemProps extends SettingItem {
  group: GroupCoreFields;
}

export const TextSettingItem: React.FC<TextSettingItemProps> = React.memo(({ setting, listItemProps, group }) => {
  const navigation = useNavigation<TextEditorNavigation>();
  const { title, titleStyle, rightElement, subtitle, subtitleStyle } = listItemProps;
  const navigateToEditor = () => navigation.navigate(`TextSettingEditor`, { setting, group });
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle={subtitle}
      subtitleStyle={subtitleStyle}
      subtitleProps={{ numberOfLines: 1 }}
      rightElement={rightElement}
      onPress={navigateToEditor}
    />
  );
});

export const TextEditor = () => {
  const navigation = useNavigation();
  const route = useRoute<TextEditorRoute>();
  const dispatch = useAppDispatch();
  const { user, setUser } = React.useContext(AppContext);
  const { setting, group } = route.params || {};
  const [value, setValue] = useState<string>(setting?.value as string);
  const [length, setLength] = useState(`${setting?.value}`.length);
  const [settingPath] = setting?.key?.split(`.`); //.includes(`group`) ? `group` : `user`;
  const maxLength = setting?.label === `Community name` ? 80 : setting?.max_length || 40;
  const nearMax = length > maxLength * 0.9;

  const getBorderColor = (length: number, maxLength: number) => {
    if (length > maxLength) return Colors.invalidText;
    if (length >= maxLength * 0.9) return Colors.deepPurple; //Looks a little weird, maybe change color or make gray
    return Colors.taupe;
  };

  const [updateSettingMutation] = useMutation(UPDATE_SETTING_MUTATION, {
    refetchQueries:
      settingPath === `group` ? refetchQueriesFor(`Group`, `GroupMember`, `Setting`) : refetchQueriesFor(`Persona`, `Setting`),
  });

  const alertDiscard = useCallback(() => {
    if (value !== setting?.value) {
      Alert.alert(`Discard Changes?`, `Are you sure you want to discard your changes?`, [
        {
          text: `Discard`,
          onPress: () => navigation.goBack(),
        },
        {
          text: `Stay Here`,
          onPress: () => console.log(`Cancel Pressed`),
          style: `cancel`,
        },
      ]);
    } else {
      navigation.goBack();
    }
  }, [navigation, setting?.value, value]);

  const saveChanges = useCallback(async () => {
    if (!user || !user.id) return;
    try {
      const { id, key, group_id } = setting || {};
      const input = {
        id,
        key,
        group_id: key.startsWith(`user`) ? undefined : group_id,
        user_id: key.startsWith(`group`) ? undefined : user?.id,
        value,
      };
      await updateSettingMutation({ variables: { input } });

      if (setting?.key === `group.setting.name`) {
        dispatch(setActiveCommunity({ user_id: user.id, group: { ...group, name: value } }));
      }

      if (setting?.key === `user.setting.handle`) {
        setUser({ ...user, handle: value });
      }
      if (setting?.key === `user.setting.name`) {
        setUser({ ...user, name: value });
        const persona = await fetchPersona(user?.id);
        const updatedPersona = { ...persona, name: value };
        await addPersona(updatedPersona);
      }
      if (setting?.key === `user.setting.bio`) {
        setUser({ ...user, description: value });
        const persona = await fetchPersona(user?.id);
        const updatedPersona = { ...persona, description: value };
        await addPersona(updatedPersona);
      }
      navigation.goBack();
    } catch (error) {
      console.error(error);
    }
  }, [updateSettingMutation, setting, navigation, setUser, user, value, dispatch, group]);

  React.useEffect(() => {
    let headerTitle = setting?.label;
    if (setting?.key === `user.setting.bio`) headerTitle = `Edit bio`;
    if (setting?.key === `user.setting.name`) headerTitle = `Full name`;
    navigation.setOptions({
      headerTitle,
      headerLeft: () => <BackButton onPress={alertDiscard} />,
      headerRight: () => <ThemedButton clear title="Save" containerStyle={{ right: 10 }} onPress={saveChanges} />,
    });
  }, [alertDiscard, navigation, saveChanges, setting?.key, setting?.label]);

  const onChangeText = (value: string) => {
    setValue(value);
    setLength(value.length);
  };

  return (
    <View style={{ flex: 1, paddingHorizontal: 20, backgroundColor: Colors.white }}>
      <View style={localStyles.formItemContainer}>
        <Text style={{ ...StyleSheet.flatten(Typography.smallBoldText), color: Colors.gray, marginLeft: 10 }}>
          {setting.label}
        </Text>
      </View>
      <View
        style={{
          ...StyleSheet.flatten(localStyles.inputContainer),
          borderColor: getBorderColor(length, maxLength),
        }}
      >
        <TextInput
          style={{
            height: maxLength >= 500 ? 160 : 41,
            marginHorizontal: 6,
          }}
          multiline={maxLength >= 500}
          textAlignVertical="top"
          blurOnSubmit={maxLength < 500}
          placeholder={setting?.label}
          maxLength={maxLength || 16000}
          autoCorrect={false}
          autoCapitalize="none"
          placeholderTextColor={Colors.textPlaceholder}
          onChangeText={onChangeText}
          value={value}
        />
      </View>
      {nearMax && (
        <View style={{ paddingTop: 6, flexDirection: `row`, alignItems: `center` }}>
          {length > maxLength ? <MeshIcon name="wrong-input" size={19} color={Colors.invalidText} /> : null}
          <Text
            style={{
              ...StyleSheet.flatten(Typography.boldHeaderStyle),
              fontSize: 14,
              color: length <= maxLength ? Colors.validText : Colors.invalidText,
              paddingLeft: 4,
            }}
          >
            {length}/{maxLength}
            {` `}characters
          </Text>
        </View>
      )}
      {setting?.key === `user.setting.name` && (
        <Text style={{ marginTop: 12, marginLeft: 10 }}>
          Enter your name to help your friends discover your account. Your friends and contacts can also use your name to search
          for you in Explore and invite you to their communities.
        </Text>
      )}
      <View style={localStyles.formItemContainer} />
    </View>
  );
};

const localStyles = StyleSheet.create({
  formItemContainer: {
    marginTop: 20,
    marginBottom: 6,
  },
  inputContainer: {
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 0.5,
  },
});
