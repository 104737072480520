import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Animated,
  RefreshControl,
  View,
  FlatList,
  StyleProp,
  ViewStyle,
  StyleSheet,
  ViewToken,
  Text,
  TouchableOpacity,
  ListRenderItem,
} from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { ScrollView } from 'react-native-gesture-handler';
import { NetworkStatus } from 'apollo-client';
import { unionBy } from 'lodash';
import { Colors } from '../../common-styles';
import {
  Activity as IActivity,
  CommunityAd,
  FeedItem,
  GroupCoreFields,
  GroupMember,
  RemoveActivityData,
  RemoveActivityVars,
  BottomSheetOptions,
  AffiliatedGroupsActivity,
  TopicItem,
  TopicStatus,
} from '../../common-types/types';
import { BottomSheetModal, EmptyList, LoadingModal } from '../../common-ui';
import { useDisclosure } from '../../common-util/hooks/useDisclosure';
import { ReportModal } from '../../moderation/ReportModal';
import { Activity, ActivityAd, ActivityContainer } from './Activity';
import { useReportModal } from './hooks/useReportModal';
import { COMMUNITY_FEED_BY_TOPICS, loadCachedGroupMember, refetchQueriesFor, REMOVE_ACTIVITY } from '../../graphql';
import { useBottomSheetOptions } from '../../common-util/hooks/useBottomSheetOptions';
import { usePin } from './hooks/usePin';
import { useFollow } from './hooks/useFollow';
import { useConfirmModal } from '../../common-util/hooks/useConfirmModal';
import { useSoundProfile } from '../../common-util/hooks/useSoundProfile';
import { COMMUNITY_FEED_SCROLLVIEW } from '../testLabels';
import { useAppDispatch } from '../../redux/store';
import { refreshFollowInfo } from '../../redux/followSlice';
import { Events, getCommunityRole, getContentType, PendoTrackEvent } from '../../pendo/events';
import { AppContext } from '../../../AppContext';
import { useFeed } from './hooks/useFeed';
import { MainTabsNavigator } from '../../common-types/navigation-types';
import { AffiliatedCommunityList } from './AffiliatedCommunitiesSection';
import TopicPill from '../../common-ui/TopicPill';
import { setTopic } from '../../redux/feedSlice';

type CommunityFeedProps = {
  groupMember?: GroupMember;
  group?: GroupCoreFields | null;
  onPullRefresh?: () => void;
  onScroll: (...args: any[]) => void;
  onScrollEndDrag?: () => void;
  contentOffset?: any;
  feedContainerStyle?: StyleProp<ViewStyle>;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null | undefined;
  selectedTopic?: any;
};

type ViewableDiff = {
  viewableItems: ViewToken[];
  changed: ViewToken[];
};

const ItemSeparatorComponent = () => <View style={{ backgroundColor: Colors.dividerColor, height: 2 }} />;
const ListFooterComponent = () => <View style={{ backgroundColor: Colors.white, flex: 1, height: 100 }} />;

export const CommunityFeedForTopic = ({
  groupMember,
  group,
  contentOffset,
  onScroll,
  onScrollEndDrag = () => {},
  onPullRefresh,
  feedContainerStyle,
  ListHeaderComponent = () => null,
  selectedTopic,
}: CommunityFeedProps) => {
  useSoundProfile();
  const { user, isGuestMode } = useContext(AppContext);
  const navigation = useNavigation();
  const route = useRoute();
  const dispatch = useAppDispatch();

  const feedListRef = useRef<FlatList<FeedItem>>(null);
  const [tabPressed, setTabPressed] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState<IActivity | null>(null);
  const [activityOptions, setActivityOptions] = useState<BottomSheetOptions[]>([]);

  const [viewableItems, setViewableItems] = useState<number[]>([0]);

  const [shouldFetchMore, setShouldFetchMore] = useState(false);

  const [fetchFeed, { data: feedData, loading, networkStatus, fetchMore }] = useLazyQuery(COMMUNITY_FEED_BY_TOPICS, {
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });

  const { popularTopics } = useFeed({ groupId: group?.id || `` });

  useEffect(() => {
    if (selectedTopic) setShouldFetchMore(true);
  }, [selectedTopic]);

  useEffect(() => {
    const announcementTopicID = popularTopics?.find((p: TopicItem) => p?.topic === `Announcements`)?.id;
    if (group && (selectedTopic || announcementTopicID)) {
      fetchFeed({
        variables: {
          group_id: group?.id,
          limit: 5,
          topic_ids: selectedTopic ? new Array(selectedTopic.id) : new Array(announcementTopicID),
          offset: 0,
        },
      });
    }
  }, [selectedTopic, group, popularTopics, fetchFeed]);

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: {
        itemVisiblePercentThreshold: 50,
        minimumViewTime: 500, //half a second
      },
      onViewableItemsChanged: ({ viewableItems: currentViewable }: ViewableDiff) => {
        const current = currentViewable.map((item) => item.index).filter((index) => index || index === 0) as number[];
        // update only if viewables changed
        if (!current.length) current.push(0);
        setViewableItems(current);
      },
    },
    {
      viewabilityConfig: {
        itemVisiblePercentThreshold: 50,
        minimumViewTime: 5000, //5 seconds
      },
      onViewableItemsChanged: ({ changed }: ViewableDiff) => {
        const group_member = loadCachedGroupMember(group?.id, user?.id);
        changed.forEach(({ index, item, isViewable }) => {
          const community_role = getCommunityRole(isGuestMode, group_member);
          if (isViewable) {
            const input: any = {
              post_id: item.id,
              community_role,
              location: index,
              source: route.name,
            };
            if (item.id?.startsWith(`ad:`)) {
              input.advertiser = (item as CommunityAd).advertiser?.handle;
            } else if (item.id?.startsWith(`affiliated:`)) {
              input.type = `Affiliations`;
            } else if (item.id?.startsWith(`topic_carousel:`)) {
              input.type = `Topic Carousel`;
            } else {
              input.community_name = (item as IActivity).origin_group?.name;
              input.content_type = getContentType(item as IActivity);
              input.post_author = (item as IActivity)?.user?.handle;
              input.post_author_role = (item as IActivity)?.actor_role;
              input.created_at = (item as IActivity)?.created_at;
              PendoTrackEvent(Events.POST_SEEN, input);
            }
          }
        });
      },
    },
  ]);

  const [deletePost, { loading: deletingPost }] = useMutation<RemoveActivityData, RemoveActivityVars>(REMOVE_ACTIVITY, {
    refetchQueries: refetchQueriesFor(`Activity`),
  });
  const { isOpen: isOpenActivityBModal, onClose: onCloseActivityBModal, onToggle: onToggleActivityBModal } = useDisclosure();
  const { isOpen: isOpenReportModal, hideReportModal, reportType, reportee, postId } = useReportModal();
  const { buildPostModalOptions } = useBottomSheetOptions(onCloseActivityBModal);
  const { pinPost, unpinPost } = usePin();
  const promptDeletePost = useConfirmModal();

  const { followUser, unfollowUser } = useFollow();

  const isAdmin = [`manager`, `owner`, `moderator`].includes(groupMember?.role_name || ``);

  const filteredPopularTopics = useMemo(() => {
    const results =
      (popularTopics &&
        popularTopics.length &&
        popularTopics.filter((t: TopicItem) => t?.post_count > 0 && t?.status === TopicStatus.ACTIVE)) ||
      [];

    if (results && results.length && results?.[0].topic !== `Announcements` && isAdmin) {
      const firstItem = popularTopics[popularTopics.findIndex((p: TopicItem) => p?.topic === `Announcements`)];
      const newResult = [firstItem].concat(results);
      return newResult;
    }
    return results;
  }, [popularTopics, isAdmin]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setTabPressed(false);
      };
    }, [setTabPressed]),
  );

  useEffect(() => {
    const parent = navigation.getParent<MainTabsNavigator>();
    const unsubscribe = parent?.addListener(`tabPress`, (_e) => {
      if (tabPressed && feedListRef?.current) feedListRef?.current?.scrollToOffset({ offset: 0, animated: true });
      if (!tabPressed) setTabPressed(true);
    });

    return unsubscribe;
  }, [navigation, setTabPressed, tabPressed]);

  useEffect(() => {
    if (feedListRef?.current) {
      feedListRef?.current?.scrollToOffset({ offset: 0, animated: false });
      //reset viewable items state for event tracking
      setViewableItems([0]);
    }
  }, [feedListRef, group?.id]);

  useEffect(() => {
    if (group?.id) {
      let role = groupMember ? groupMember.role_name : `visitor`;
      if (isGuestMode) role = `guest`;
      PendoTrackEvent(Events.COMMUNITY_ACTIVE, {
        community_name: group.name,
        community_role: role,
        visitor_type: isGuestMode ? `unregistered` : `registered`,
        sponsor: group.sponsor?.name,
      });
    }
  }, [group?.id, group?.name, group?.sponsor?.name, groupMember, isGuestMode]);

  /**
   * This refresh function:
   * - Get the newest carousel items (Ordered by most recent activities)
   * - Refresh the current feed.
   */
  const handlePullToRefresh = useCallback(() => {
    if (onPullRefresh) onPullRefresh();
  }, [onPullRefresh]);

  const handlePinPost = useCallback(
    async (activity: IActivity) => {
      try {
        if (activity) {
          const { origin_group_id: groupId, pinned, post_id } = activity;
          if (pinned) {
            await unpinPost(groupId);
          } else {
            await pinPost(groupId, post_id);
          }
        }
        handlePullToRefresh();
      } catch (error) {
        console.error(`an error ocurred while trying to pin/unpin a post`);
      }
    },
    [handlePullToRefresh, pinPost, unpinPost],
  );

  const handleFollowUser = useCallback(
    async (isFollowed, activity: IActivity) => {
      try {
        if (activity) {
          const { actor } = activity;
          if (isFollowed) {
            await unfollowUser(actor, true);
          } else {
            await followUser(actor, true);
          }
          dispatch(refreshFollowInfo(true));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, followUser, unfollowUser],
  );

  const handleDeletePost = useCallback(
    (activity: IActivity) => {
      const { id } = activity;
      promptDeletePost({
        title: `Are you sure you want to delete this post?`,
        body: `Confirm by pressing Ok`,
        textConfirm: `Ok`,
        onConfirm: () => deletePost({ variables: { id }, refetchQueries: refetchQueriesFor(`Activity`) }),
      });
      onCloseActivityBModal();
    },
    [promptDeletePost, deletePost, onCloseActivityBModal],
  );

  const handleSelectActivity = useCallback(
    (activity: IActivity) => {
      const options = buildPostModalOptions({
        activity,
        groupMember,
        deletePost: () => handleDeletePost(activity),
        handlePinPost: () => handlePinPost(activity),
        handleFollowUser: (isFollowed) => handleFollowUser(isFollowed, activity),
      });
      setActivityOptions(options);
      setSelectedActivity(activity);
      onToggleActivityBModal(); // Open the bottom sheet  modal
    },
    [
      onToggleActivityBModal,
      setActivityOptions,
      buildPostModalOptions,
      handleDeletePost,
      handlePinPost,
      handleFollowUser,
      groupMember,
    ],
  );

  const handleCloseBottomSheet = () => {
    setSelectedActivity(null);
    setActivityOptions([]);
    onCloseActivityBModal();
  };

  const loadMoreActivities = () => {
    const totalTopics = feedData?.getActivitiesByTopics?.length;
    if (!loading && shouldFetchMore) {
      fetchMore({
        variables: {
          offset: totalTopics,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.getActivitiesByTopics?.length) {
            setShouldFetchMore(false);
            return prev;
          }
          const prevActivities = prev?.getActivitiesByTopics || [];
          // filter pinned post that breaks the order of posts
          const moreActivities = fetchMoreResult.getActivitiesByTopics.filter((activity: any) => !activity.pinned);
          // if returned less than 1, end of feed has been reached
          // yet this should not be done this way.
          if (moreActivities.length < 1) setShouldFetchMore(false);
          return {
            ...prev,
            getActivitiesByTopics: unionBy(prevActivities, moreActivities, (activity: any) => activity.id),
          };
        },
      });
    }
  };

  const renderItem: ListRenderItem<FeedItem> = useCallback(
    ({ item: activity, index }) => {
      const { id } = activity;
      if ((activity as IActivity)?.removed || !group) return null;
      const onScreen = viewableItems.reduce((prev, current) => {
        if (index === current) return true;
        return prev;
      }, false);
      if (id?.startsWith(`ad:`)) {
        return (
          <ActivityAd
            activity={activity as CommunityAd}
            group={group}
            groupMember={groupMember}
            onScreen={onScreen}
            index={index}
            showAdOptionsModal={() => {}}
          />
        );
      }
      if (id?.startsWith(`affiliated:`)) {
        return (
          <ActivityContainer containerStyle={{ marginBottom: 25 }}>
            <AffiliatedCommunityList
              affiliatedCommunities={(activity as AffiliatedGroupsActivity).affiliations}
              group_member={groupMember}
              source_group={group}
              source={route.name === `HomeScreen` ? route.name : `Community preview`}
            />
          </ActivityContainer>
        );
      }
      if (id?.startsWith(`topic_carousel:`)) {
        return (
          <TopicsCarousel
            group={group}
            groupMember={groupMember}
            isPreview={route.name !== `HomeScreen`}
            popularTopics={filteredPopularTopics}
          />
        );
      }

      return (
        <Activity
          onScreen={onScreen}
          activity={activity as IActivity}
          onSelectActivity={handleSelectActivity}
          feedIndex={index}
        />
      );
    },
    [group, handleSelectActivity, viewableItems, groupMember, route.name, filteredPopularTopics],
  );

  const keyExtractor = useCallback((item: FeedItem) => item.id, []);

  const renderEmptyList = useCallback(
    () => (
      <View style={{ minHeight: 400 }}>
        {!loading && !feedData?.getActivitiesByTopics?.length && (
          <EmptyList
            isLoading={false}
            message={
              selectedTopic.topic === `Announcements` ? `We don't have any announcements just yet!` : `No Content to display`
            }
            image={require(`../../../assets/images/non-accouncements.png`)}
          />
        )}
      </View>
    ),
    [feedData?.getActivitiesByTopics?.length, loading, selectedTopic.topic],
  );

  if (loading && !feedData?.getActivitiesByTopics?.length)
    return (
      <View style={{ minHeight: 400 }}>
        <EmptyList isLoading={true} message="Loading..." />
      </View>
    );

  return (
    <>
      <ReportModal
        origin_group_id={selectedActivity?.origin_group_id || ``}
        id={postId}
        reportee={reportee}
        isVisible={isOpenReportModal}
        onClose={hideReportModal}
        reportType={reportType}
      />
      <LoadingModal isVisible={deletingPost} content="Deleting the post" />
      <BottomSheetModal
        onPressCancel={handleCloseBottomSheet}
        onPressConfirm={handleCloseBottomSheet}
        showCancelBtn={false}
        confirmTitle="Close"
        visible={isOpenActivityBModal}
        title="Post options"
        options={activityOptions}
      />
      <Animated.FlatList<FeedItem>
        testID={COMMUNITY_FEED_SCROLLVIEW}
        ListHeaderComponent={ListHeaderComponent}
        ListEmptyComponent={renderEmptyList}
        ListFooterComponent={loading ? undefined : ListFooterComponent}
        ItemSeparatorComponent={ItemSeparatorComponent}
        renderItem={renderItem}
        ref={feedListRef}
        contentContainerStyle={[styles.feedContainer, feedContainerStyle]}
        contentOffset={contentOffset}
        refreshControl={<RefreshControl onRefresh={handlePullToRefresh} refreshing={networkStatus === NetworkStatus.refetch} />}
        onEndReached={loadMoreActivities}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        onScroll={onScroll}
        onScrollEndDrag={onScrollEndDrag}
        scrollEventThrottle={16}
        refreshing={networkStatus === NetworkStatus.refetch}
        keyExtractor={keyExtractor}
        onEndReachedThreshold={2.5}
        maxToRenderPerBatch={5}
        data={feedData?.getActivitiesByTopics || []}
        extraData={feedData?.getActivitiesByTopics || []}
      />
    </>
  );
};

const styles = StyleSheet.create({
  feedContainer: {
    // paddingTop: Platform.select({ ios: 196, default: 196 }),
    paddingTop: 90,
    justifyContent: `center`,
  },
});

const TopicsCarousel: React.FC<{ group: any; groupMember: any; isPreview: boolean; popularTopics: TopicItem[] }> = ({
  group,
  groupMember,
  isPreview,
  popularTopics,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();

  const renderItem = useCallback(
    (item: TopicItem) => {
      return (
        <TopicPill
          topic={item}
          onPress={() => {
            dispatch(setTopic(item));
            navigation.navigate(`TopicFeed`, { groupMember });
          }}
          brick
          groupName={group?.name}
          isPreview={isPreview}
        />
      );
    },
    [dispatch, navigation, group, isPreview, groupMember],
  );
  if (
    !popularTopics.length ||
    popularTopics.every((t) => t?.post_count === 0) ||
    (popularTopics.length === 1 && popularTopics[0].topic === `Announcements`)
  ) {
    return null;
  }
  return (
    <View style={{ paddingVertical: 15 }}>
      <View
        style={{
          flexDirection: `row`,
          alignItems: `center`,
          justifyContent: `space-between`,
          marginBottom: 10,
          paddingHorizontal: 12,
        }}
      >
        <Text style={{ fontSize: 16, fontWeight: `600` }}>Popular Topics</Text>

        <TouchableOpacity onPress={() => navigation.navigate(`CommunityTopics`, { group, group_member: groupMember })}>
          <Text style={{ fontSize: 11, color: Colors.deepPurple, fontWeight: `600` }}>See all</Text>
        </TouchableOpacity>
      </View>
      {popularTopics.length > 6 ? (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingHorizontal: 4 }}
        >
          <FlatList
            scrollEnabled={false}
            contentContainerStyle={{ alignSelf: `flex-start` }}
            numColumns={Math.ceil(popularTopics.length / 2)}
            data={popularTopics}
            extraData={popularTopics}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item }) => renderItem(item)}
            key={Math.ceil(popularTopics.length / 2).toString()}
            keyExtractor={(item) => Math.ceil(popularTopics.length / 2).toString() + item.id}
          />
        </ScrollView>
      ) : (
        <FlatList
          horizontal
          data={popularTopics}
          extraData={popularTopics}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => renderItem(item)}
          keyExtractor={(item) => `#${item?.id}`}
          key="#"
        />
      )}
    </View>
  );
};
