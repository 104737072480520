import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ListedJoinedGroups } from '../../common-types/types';
import { getAsyncStorageObject } from '../../common-util';
import { LIST_GROUPS, client } from '../../graphql';
import { setActiveCommunity, setJoinedCommunities } from '../../redux/feedSlice';
import { RootState, useAppDispatch } from '../../redux/store';

export const useActiveCommunity = (from?: string) => {
  const { activeCommunity } = useSelector((state: RootState) => state.feed);
  const dispatch = useAppDispatch();

  const fetchLatestCommunities = useCallback(
    async (user_id: string) => {
      // fetch Joined Communities
      const { data, errors } = await client.query<ListedJoinedGroups>({
        query: LIST_GROUPS,
        variables: { persona_id: user_id },
      });
      if (errors && errors.length) {
        console.error(`[AuthErrror]`, errors[0].message);
      }
      const mappedCommunities = data?.listJoinedGroups?.map(({ group }) => group) || [];
      dispatch(setJoinedCommunities(mappedCommunities));

      // set Active from local or from the first of the response
      const latestStored = await getAsyncStorageObject(`${user_id}_Latest`);
      if (latestStored && activeCommunity?.id === latestStored.id) return;
      if (latestStored && latestStored?.id) {
        dispatch(setActiveCommunity({ user_id, group: latestStored, from: from || `fetchLatest` }));
      } else if (mappedCommunities.length) {
        const [latestFromMappedCommunities] = mappedCommunities;
        dispatch(setActiveCommunity({ user_id, group: latestFromMappedCommunities, from: from || `fetchLatest` }));
      }
    },
    // from's purpose is for debugging and will be constant.
    [dispatch, from, activeCommunity],
  );

  //@deprecated
  const setLatestFromLocalOrFetch = useCallback(
    async (user_id: string) => {
      const latestStored = await getAsyncStorageObject(`${user_id}_Latest`);
      // skip if lastest = active
      if (latestStored && activeCommunity?.id === latestStored.id) return;
      if (latestStored && latestStored?.id) {
        // backup found in local storage
        dispatch(setActiveCommunity({ user_id, group: latestStored, from: `FetchfromLocal` }));
      } else {
        // fetch latest from server
        await fetchLatestCommunities(user_id);
      }
    },
    [activeCommunity?.id, dispatch, fetchLatestCommunities],
  );

  return {
    activeCommunity,
    fetchLatestCommunities,
    setLatestFromLocalOrFetch,
  };
};
