import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import { getEnvVariables, getManifest } from '../../environment';

type SentryLib = typeof Sentry.Native | typeof Sentry.Browser;

const getEnvSentry = () => {
  return Platform.select<SentryLib>({
    native: Sentry.Native,
    default: Sentry.Browser,
  });
};

const _Sentry = getEnvSentry();

export const init = () => {
  const { extra } = getManifest() || {};
  const { publishID } = extra || {};
  _Sentry?.init({
    dsn: getEnvVariables().sentryDsn,
    //enableOutOfMemoryTracking: true,
    //enableAutoPerformanceTracking: true,
    enableNative: true,
    debug: true,
    release: publishID,
  });
};

export const configureScope = (scopeConfig: any) => {
  _Sentry?.configureScope(scopeConfig);
};

export const withScope = (scope: any) => {
  _Sentry?.withScope(scope);
};

export const captureException = (error: Error) => {
  _Sentry?.captureException(error);
};
export const captureMessage = (message: string) => {
  _Sentry?.captureMessage(message);
};
