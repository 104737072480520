import { Linking, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Colors, Typography } from '../common-styles';
import { BlobPink, CenterColumn, MeshIcon, SafeAreaView, ThemedButton, BottomSheetModal } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { client, UPDATE_AGREED_COC } from '../graphql';
import { WelcomeImage } from '../images';
import { AgreementTestIds } from './testLabels';

export const AgreeToMeshCoC = ({ navigation, route }) => {
  const [agreed, setAgreed] = useState(false);
  const [showInviteClickAgainHack, setShowInviteClickAgainHack] = useState(false);

  const goToMeshGroundRules = () => {
    Linking.openURL(`https://www.meshcommunities.us/ground-rules`);
  };

  const agreeToCoC = async () => {
    const identity_id = route.params?.identity_id;
    try {
      if (!identity_id) throw new Error(`No identity_id in agreeToCoC()`);
      const { data } = await client.mutate({ mutation: UPDATE_AGREED_COC, variables: { identity_id } });
      const updatedIdentity = data.updateAgreedCoC;
      console.log(`updated identity`, updatedIdentity);

      //navigateAfterLogin(navigation);
      //setShowInviteClickAgainHack(true);
    } catch (err) {
      console.error(`Error thrown in agreeToCoC() in AgreeToMeshCoC.jsx`);
    }
  };

  const hideModalAndGoToMainScreen = () => {
    setShowInviteClickAgainHack(false);
    //navigateAfterLogin(navigation);
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => null,
    });
  }, [navigation]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <ScrollView
        testID={AgreementTestIds.screen}
        style={{ flex: 1 }}
        contentContainerStyle={{ justifyContent: `center`, paddingVertical: 35, paddingHorizontal: 16 }}
      >
        <CenterColumn style={{ flex: 1 }}>
          <View>
            <BlobPink height={25} width={25} style={{ position: `absolute`, left: -11 }} />
            <Text style={Typography.text(`large`, `bold`)}>Lastly, review and agree to our Ground Rules</Text>
          </View>
          <WelcomeImage height={214} width={324} style={{ alignSelf: `center` }} />
          <View style={{ top: -61 }}>
            <View style={{ backgroundColor: Colors.valuesOfMeshBoxColor, padding: 16, borderRadius: 20 }}>
              <Text style={Typography.text(`plustwo`, `bold`, `center`)}>Our values at Mesh</Text>
              <Text style={{ ...Typography.text(`center`), marginTop: 13 }}>
                <Text>We ask you to embody the following values: </Text>
                <Text style={Typography.text(`bold`)}> respect, inclusion, openness, support, </Text>
                <Text>{`and `}</Text>
                <Text style={Typography.text(`bold`)}>community</Text>
                <Text>.</Text>
              </Text>
            </View>
            <View style={{ backgroundColor: Colors.unacceptableBehaviorsBoxColor, padding: 16, borderRadius: 20, marginTop: 16 }}>
              <Text style={Typography.text(`plustwo`, `bold`, `center`)}>Unacceptable behaviors</Text>
              <Text style={{ ...Typography.text(`center`), marginTop: 13 }}>
                Intimidation, harrassment, abuse, discrimination, intentional misgendering, derogatory or demeaning speech or
                actions.
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => setAgreed(!agreed)}
              activeOpacity={0.8}
              style={{ marginTop: 16 }}
              testID={AgreementTestIds.agree}
            >
              <View style={{ flexDirection: `row`, alignItems: `center` }}>
                <MeshIcon name="select-circle" focused={agreed} color={agreed ? Colors.brandPurple : Colors.iconColor} />
                <Text style={{ ...Typography.text(`plustwo`, `bold`), marginLeft: 16 }}>I agree to be kind and respectful</Text>
              </View>
            </TouchableOpacity>
            <View style={{ marginTop: 16.5, paddingBottom: 68 }}>
              <ThemedButton
                title="Got it!"
                testID={AgreementTestIds.got_it}
                rounded
                buttonStyle={{ padding: 12 }}
                disabled={!agreed}
                onPress={agreeToCoC}
              />
              <ThemedButton
                clear
                title="Read full Ground Rules"
                titleStyle={Typography.text(`base`, `theme`, `bold`)}
                containerStyle={{ marginTop: 24.5 }}
                onPress={goToMeshGroundRules}
              />
            </View>
          </View>
          <BottomSheetModal
            visible={showInviteClickAgainHack}
            onPressCancel={hideModalAndGoToMainScreen}
            title="Hold on"
            showCancelBtn={false}
            confirmTitle="Got it!"
            onPressConfirm={hideModalAndGoToMainScreen}
          >
            <View style={{ marginHorizontal: 16 }}>
              <Text style={{ ...Typography.text(`center`, `plusone`), marginTop: 12, marginBottom: 20 }}>
                If you’ve signed up with a community invite, please tap the invite link again to go to your community{` `}
              </Text>
            </View>
          </BottomSheetModal>
        </CenterColumn>
      </ScrollView>
    </SafeAreaView>
  );
};

AgreeToMeshCoC.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};
