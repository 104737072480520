import React, { useCallback, useState } from 'react';
import { View, Text, SectionList } from 'react-native';
import { useQuery } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { commonPropTypes } from '../common-util';
import { Divider, getRandomBlob, LoadingIndicator, ThreeDotsButton, Avatar, BottomSheetModal } from '../common-ui'; //eslint-disable-line
import { Typography, Colors } from '../common-styles';
import { ListItem } from '../common-ui/ListItem';
import { GET_USERS_BY_NAME, loadCachedGroupMember } from '../graphql';
import {
  handleCreateConversation,
  updateGroupMemberRole,
  banUser,
  removeGroupMember,
  owner,
  manager,
  moderator,
  creator,
  member,
} from './memberInfoHelpers';
import { AppContext } from '../../AppContext';
import { PersonaSubtitle } from './PersonaSubtitle';
import { PendoTrackEvent, Events } from '../pendo/events';

export const SearchUsersScreen = ({ navigation, route }) => {
  const { user, chatNotifications, setChatNotifications } = React.useContext(AppContext);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { group_id, searchText } = route.params;
  const limit = 20;

  const {
    data: users,
    loading,
    fetchMore,
  } = useQuery(GET_USERS_BY_NAME, {
    variables: { user_name: searchText, user_id: user.id, group_id, limit, offset: 0 },
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });

  // useEffect(() => {
  //   if (error) console.warn(`[MembersSearchError]`, error.message);
  //   if (users) {
  //     setMatchingUsers(users)
  //     const sections = createSections();
  //     setSections(sections);
  //   }
  // }, [users, error, createSections, setSections, setMatchingUsers]);

  const _keyExtractor = (item, index) => item.id + index.toString();
  const Blob = getRandomBlob();

  const MainHeader = useCallback(() => {
    return (
      <View style={{ margin: 16 }}>
        <Blob width={24} height={24} style={{ position: `absolute`, left: -8, top: -2, zIndex: -1 }} />
        <Text style={{ flexShrink: 1, ...Typography.text(`bold`, `plusone`) }}>
          <Text>{` `}Your search:</Text>
          <Text style={{ flexShrink: 1, ...Typography.text(`bold`, `plusone`) }}> "{searchText}"</Text>
        </Text>
      </View>
    );
  }, [searchText]);

  const EmptyComponent = useCallback(() => {
    return (
      <View style={{ flex: 1, justifyContent: `center`, marginTop: 20 }}>
        {loading ? (
          <View style={{ alignItems: `center`, justifyContent: `center` }}>
            <LoadingIndicator size={42} />
          </View>
        ) : (
          <Text style={Typography.text(`gray`, `center`)}>No results found</Text>
        )}
      </View>
    );
  }, [loading]);

  const SectionHeader = useCallback(({ section: { title } }) => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: `row`,
          justifyContent: `space-between`,
          alignItems: `center`,
          backgroundColor: Colors.commentGray,
          marginBottom: 0,
        }}
      >
        <Text maxFontSizeMultiplier={2} style={{ marginHorizontal: 16, marginVertical: 8, ...Typography.text(`bold`, `gray`) }}>
          {title}
        </Text>
      </View>
    );
  }, []);

  const createSections = useCallback(() => {
    const usersArray = users?.getUsersByName || [];
    const nonBanned = usersArray.filter((gm) => !gm.group_member.banned);
    const owners = nonBanned.filter((gm) => gm.group_member.role_id === owner);
    const managers = nonBanned.filter((gm) => gm.group_member.role_id === manager);
    const moderators = nonBanned.filter((gm) => gm.group_member.role_id === moderator);
    const creators = nonBanned.filter((gm) => gm.group_member.role_id === creator);
    const members = nonBanned.filter((gm) => gm.group_member.role_id === member);
    // const banned = group_members.filter((gm) => gm.banned);

    const sections = [
      owners.length && {
        title: owners.length > 1 ? `${owners.length} Owners` : `Owner`,
        data: owners,
      },
      managers.length && {
        title: managers.length > 1 ? `${managers.length} Community leaders` : `Community leader`,
        data: managers,
      },
      moderators.length && {
        title: moderators.length > 1 ? `${moderators.length} Moderators` : `Moderator`,
        data: moderators,
      },
      creators.length && {
        title: creators.length > 1 ? `${moderators.length} Creators` : `Creator`,
        data: creators,
      },
      // banned.length && {
      //   title: banned.length > 1 ? `${banned.length} Banned Users` : `Banned`,
      //   data: banned,
      // },
      members.length && {
        title: members.length > 1 ? `Members` : `Member`,
        data: members,
      },
    ].filter((x) => x);
    return sections;
  }, [users?.getUsersByName]);

  const buildModalOptions = useCallback(() => {
    const user_group_member = loadCachedGroupMember(group_id, user?.id);
    if (!user_group_member) return null;

    const { persona, banned: target_banned, role_id: theirRole } = selectedUser;
    const me = user.id === persona.id;
    const { role_id: myRole } = user_group_member;
    const outrank = (otherRole) =>
      (myRole === owner && (otherRole === manager || otherRole === moderator || otherRole === creator || otherRole === member)) ||
      (myRole === manager && (otherRole === moderator || otherRole === creator || otherRole === member)) ||
      (myRole === moderator && (otherRole === creator || otherRole === member)) ||
      (myRole === creator && otherRole === member);

    console.log(`could be promoted to mod?`, !me && outrank(moderator) && outrank(theirRole));
    const actions = [
      {
        predicate: () => !me,
        title: `Message`,
        iconName: `email`,
        testID: `TO_MESSAGES`,
        onPress: () => {
          setShowModal(false);
          handleCreateConversation({ me: user, chatNotifications, setChatNotifications }, persona, navigation);
        },
      },
      {
        predicate: () => !me && outrank(theirRole) && theirRole !== manager && outrank(moderator),
        title: `Promote to Manager`,
        iconName: `moderation`,
        onPress: () => {
          setShowModal(false);
          updateGroupMemberRole(persona.id, manager, group_id);
        },
      },
      {
        predicate: () => !me && outrank(moderator) && outrank(theirRole) && (theirRole === creator || theirRole === member),
        title: `Promote to Moderator`,
        iconName: `star`,
        onPress: () => {
          setShowModal(false);
          updateGroupMemberRole(persona.id, moderator, group_id);
        },
      },
      {
        predicate: () => !me && outrank(moderator) && outrank(theirRole) && theirRole === member,
        title: `Promote to Contributor`,
        iconName: `star`,
        onPress: () => {
          setShowModal(false);
          updateGroupMemberRole(persona.id, creator, group_id);
        },
      },
      {
        title: `View profile`,
        iconName: `profile`,
        onPress: () => {
          setShowModal(false);
          PendoTrackEvent(Events.PROFILE, {
            username: persona?.handle,
            source: `Search user list`,
            element: `Search list`,
          });
          navigation.push(`Profile`, { user: persona });
        },
      },
      {
        predicate: () => !me && outrank(moderator) && !target_banned,
        title: `Ban from community (can’t rejoin)`,
        titleStyle: { color: Colors.alertColor },
        iconName: `alert-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          setShowModal(false);
          banUser(persona.id, navigation);
        },
      },
      {
        predicate: () => !me && outrank(moderator) && outrank(theirRole) && theirRole === manager,
        title: `Demote to Moderator`,
        titleStyle: { color: Colors.alertColor },
        iconName: `minus-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          setShowModal(false);
          updateGroupMemberRole(persona.id, moderator, group_id);
        },
      },
      {
        predicate: () => !me && outrank(creator) && outrank(theirRole) && (theirRole === moderator || theirRole === manager),
        title: `Demote to Contributor`,
        titleStyle: { color: Colors.alertColor },
        iconName: `minus-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          setShowModal(false);
          updateGroupMemberRole(persona.id, creator, group_id);
        },
      },
      {
        predicate: () => !me && outrank(member) && outrank(theirRole) && theirRole !== member && !target_banned,
        title: `Demote to Member`,
        titleStyle: { color: Colors.alertColor },
        iconName: `minus-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          setShowModal(false);
          updateGroupMemberRole(persona.id, member, group_id);
        },
      },
      {
        predicate: () => !me && outrank(theirRole) && !target_banned,
        title: `Remove from community (can rejoin)`,
        titleStyle: { color: Colors.alertColor },
        iconName: `alert-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          setShowModal(false);
          removeGroupMember(persona.id, navigation);
        },
      },
    ];

    const filteredActions = actions.filter((option) => option.predicate === undefined || option.predicate());
    return filteredActions;
  }, [navigation, group_id, selectedUser, user, chatNotifications, setChatNotifications]);

  const fetchNextPage = () => {
    const offset = users?.getUsersByName?.length;
    if (canFetchMore)
      fetchMore({
        variables: { user_name: searchText, user_id: user.id, group_id, limit, offset },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.getUsersByName?.length) {
            console.log(`[searchByUserName] no next value, return prev`);
            setCanFetchMore(false);
            return prev;
          }
          const [oldLast] = prev.getUsersByName?.slice(-1);
          const [newLast] = fetchMoreResult.getUsersByName?.slice(-1);
          if (oldLast.group_member.persona_id === newLast.group_member.persona_id) {
            console.log(`[searchByUserName] is the same last result, return prev`);
            setCanFetchMore(false);
            return prev;
          }
          return {
            ...prev,
            getUsersByName: unionBy(prev.getUsersByName, fetchMoreResult.getUsersByName, (gm) => gm.group_member.persona_id),
          };
        },
      });
  };

  const renderItem = useCallback(
    ({ item: member }) => {
      const { group_member } = member;
      if (!group_member) return null;
      const { persona } = group_member;

      const personaTitle = <Text style={{ ...Typography.text(`bold`) }}>{persona.name}</Text>;

      const renderIsBannedIcon = (banned) => {
        if (!banned) return null;
        return <Text style={{ fontSize: Typography.baseFontSize, color: Colors.mediumGray }}>BANNED</Text>;
      };

      return (
        <ListItem
          title={personaTitle}
          subtitle={<PersonaSubtitle persona={persona} />}
          leftAvatar={<Avatar navigation={navigation} user={persona} size={48} />}
          rightIcon={renderIsBannedIcon(group_member.banned)}
          rightElement={
            <ThreeDotsButton
              size={24}
              onPress={() => {
                setSelectedUser(group_member);
                setShowModal(true);
              }}
            />
          }
          onPress={() => {
            setSelectedUser(group_member);
            setShowModal(true);
          }}
        />
      );
    },
    [navigation],
  );

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <SectionList
        sections={createSections()}
        keyExtractor={_keyExtractor}
        renderSectionHeader={SectionHeader}
        renderItem={renderItem}
        onEndReachedThreshold={1.5}
        onEndReached={fetchNextPage}
        ItemSeparatorComponent={Divider}
        ListHeaderComponent={MainHeader}
        ListEmptyComponent={EmptyComponent}
      />
      {showModal && (
        <BottomSheetModal
          title="Manage"
          options={buildModalOptions()}
          visible={!!showModal}
          onPressCancel={() => setShowModal(false)}
          showCancelBtn={false}
          onPressConfirm={() => setShowModal(false)}
          confirmTitle="Close"
        />
      )}
    </View>
  );
};

SearchUsersScreen.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};
