import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Alert, TextInput, ScrollView, Platform, Text, View, TouchableOpacity, Appearance } from 'react-native';
import { useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';

import { Colors, Typography } from '../common-styles';
import { BottomSheetModal, LoadingIndicator, BackButton, ThemedButton, RadioButtons, SelectMultiple } from '../common-ui';
import { shortDateOptions } from '../common-util';
import { APPLY_TO_COMMUNITY, GET_COMMUNITY_APPLICATION, GET_GROUP, client, refetchQueriesFor } from '../graphql';
import { movePrev } from '../redux/applicationWizardSlice';
import { useAppDispatch } from '../redux/store';
import { VerificationSelfieUpload } from '../mesh-verification';
import { questionTypes } from './helpers';
import { RequireMeshIdentity } from './MeshIdentity';
import { AppContext } from '../../AppContext';
import { WizardLayout } from '../community-create/WizardLayout';
import { setForceExplorerRefresh, setPreviewCommunity } from '../redux/feedSlice';
import { useRefreshCommunities } from '../common-util/hooks/useRefreshCommunities';
import { Events, PendoTrackEvent } from '../pendo/events';

const DateTimePicker = Platform.OS !== `web` ? require(`@react-native-community/datetimepicker`).default : undefined;
const TextBasedAnswers = [questionTypes.shortAnswer.name, questionTypes.paragraph.name, questionTypes.writtenAnswer.name];
const TimeBasedAnswers = [questionTypes.date.name, questionTypes.time.name];
// const TEST_GROUP = `a6cfcc1f-18d7-48b8-9eb7-2cdd50b150e2`; // pollo's group

export const CommunityApplicationForm = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { identity, user, setUser } = React.useContext(AppContext);
  const { joinedCommunities } = useSelector((state) => state.feed);
  const dispatch = useAppDispatch();
  const [photo, setPhoto] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState();
  const [submitGroupId, setSubmitId] = useState(``);
  const [loading, setLoading] = useState(false);
  const [updatedGroup, setUpdatedGroup] = useState(null);
  const { fetchJoinedCommunities } = useRefreshCommunities();

  const [nextDisabled, setNextDisabled] = useState(true);
  const { group } = route?.params || {};

  const { data, error, _loading } = useQuery(GET_COMMUNITY_APPLICATION, {
    variables: { group_id: group.id },
    fetchPolicy: `no-cache`,
  });

  useEffect(() => {
    // Effect #1: Fetch questions
    if (error) return;
    const { questions } = data?.getCommunityApplication?.community_application || {};
    setQuestions(questions || []);
  }, [data, error]);

  useEffect(() => {
    // Effect #2: Set header
    const iconColor = isSubmitting || loading || nextDisabled ? Colors.textDisabled : Colors.brandPurple;
    const handlePrev = () => {
      dispatch(movePrev());
      navigation.goBack();
    };
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={handlePrev} />,
      headerTitle: `Join us`,
      headerRight: () => (
        <ThemedButton
          clear
          testID="APPL_CONTINUE"
          title="Submit"
          titleStyle={{ ...Typography.text(`base`, `bold`), color: iconColor }}
          containerStyle={{ right: 12 }}
          onPress={submitApplication}
          disabled={isSubmitting || nextDisabled || loading}
          leftIcon={(isSubmitting || loading) && <LoadingIndicator />}
        />
      ),
    });
  }, [navigation, nextDisabled, isSubmitting, loading, dispatch, submitApplication, questions]);

  useEffect(() => {
    // Effect #3: Set next disabled
    if (userResponses?.length && questions.length) {
      const formFilled = userResponses.length === questions.length;
      if (formFilled && nextDisabled) setNextDisabled(false);
      if (!formFilled && !nextDisabled) setNextDisabled(true);
    }
  }, [nextDisabled, userResponses?.length, questions?.length]);

  const submitApplication = React.useCallback(async () => {
    dispatch(setForceExplorerRefresh(false));
    const { id: group_id, name, application } = route?.params?.group || {};
    const cleaned_questions = questions.map(({ key, value }) => {
      return { key, value };
    });

    const input = { group_id, questions: cleaned_questions };
    if (application?.require_mesh_identity) {
      input.identity_id = identity?.id;
      input.photo = photo;
    }
    try {
      const { data, errors } = await client.mutate({
        mutation: APPLY_TO_COMMUNITY,
        variables: { input },
        refetchQueries: refetchQueriesFor(`Group`, `GroupMember`),
      });
      if (errors?.length) throw new Error(errors[0].message);
      const applicationResult = data.applyToCommunity;
      setIsSubmitting(false);
      setShowModal(`Sent`);
      setSubmitId(group_id);

      PendoTrackEvent(Events.JOIN_COMMUNITY, {
        community_name: name,
        source: user?.pending_completition ? `create account flow` : `community preview`,
        application_required: true,
        admin_approval_required: false,
      });

      return applicationResult;
    } catch (err) {
      console.error(`Error thrown in submit community application`, err);
      setTimeout(
        () =>
          Alert.alert(`There was an error submitting your application to ${name?.trim()}`, err.message, [
            { text: `OK`, style: `default`, onPress: () => null },
          ]),
        500,
      );
      setIsSubmitting(false);
      return null;
    }
  }, [dispatch, route?.params?.group, questions, identity?.id, photo, user?.pending_completition]);

  const hideApplicationSentModal = async () => {
    if (user?.pending_completition) {
      user.pending_completition = false;
      setUser(user);
    }
    if (!joinedCommunities || joinedCommunities.length < 1) {
      setShowModal();
      await fetchJoinedCommunities();
      return;
    }
    if (updatedGroup && (updatedGroup.application_pending || updatedGroup.join_request_pending)) {
      dispatch(setPreviewCommunity(updatedGroup));
      dispatch(setForceExplorerRefresh(true));
      navigation.navigate(`CommunityPreview`);
    } else {
      dispatch(movePrev()); //to reset step counter
      navigation.pop(2);
    }
    setShowModal();
    setUpdatedGroup(null);
    setSubmitId(``);
  };

  useEffect(() => {
    if (submitGroupId) {
      getGroupById(submitGroupId);
    }
  }, [submitGroupId]);

  const getGroupById = async (id) => {
    setLoading(true);
    try {
      const { data } = await client.query({ query: GET_GROUP, variables: { group_id: id }, fetchPolicy: `network-only` });
      if (!data?.getGroup) {
        console.log(`refreshGroup - unexpectedly no result from getGroup`);
        return;
      }
      setUpdatedGroup(data?.getGroup?.group);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WizardLayout total={2} selector="applicationWizard">
      <ScrollView style={{ paddingHorizontal: 8, paddingTop: 8 }}>
        <View style={localStyles.groupInfoContainer}>
          <Text testID="JOIN_TITLE" style={localStyles.groupInfo}>
            Join {group?.name?.trim()}
          </Text>
          <Text style={localStyles.groupAppPrompt}>
            Answer the following questions from this community's organizers. Only they can see the answers.
          </Text>
        </View>
        <RequireMeshIdentity
          required={group?.require_mesh_identity}
          hasSubmitted={!!photo}
          setShowModal={() => setShowModal(`Verification`)}
        />
        <Formik
          enableReinitialize
          initialValues={{ questions }}
          onSubmit={(values) => {
            const { questions } = values;
            const responses = values?.questions?.map((item) => item.value)?.filter((item) => item);
            setUserResponses(responses);
            setQuestions(questions);
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, values, setFieldValue }) => {
            const _setAnswer = (index, answer) => {
              const parsed_answer = Array.isArray(answer) ? JSON.stringify(answer) : answer;
              setFieldValue(`questions[${index}].value`, parsed_answer);
              handleSubmit();
            };
            return (
              <View>
                <View style={{ marginHorizontal: 10 }}>
                  {values?.questions?.map((question, index) => {
                    return (
                      <QuestionItem
                        // eslint-disable-next-line
                        key={index.toString()}
                        questionIndex={index}
                        question={question}
                        onChangeText={(text) => {
                          handleChange(`questions[${index}].value`)(text);
                          handleSubmit();
                        }}
                        onBlur={handleBlur(`questions[${index}].value`)}
                        value={values.questions[index].value}
                        setAnswer={_setAnswer}
                      />
                    );
                  })}
                </View>
              </View>
            );
          }}
        </Formik>
      </ScrollView>
      {showModal === `Verification` && (
        <VerificationSelfieUpload
          useModal
          isApplication
          photo={photo}
          setPropsPhoto={setPhoto}
          toggleModal={() => setShowModal()}
        />
      )}
      <BottomSheetModal
        title="Application received"
        visible={showModal === `Sent`}
        showCancelBtn={false}
        onPressCancel={hideApplicationSentModal}
        confirmTitle="Got it"
        onPressConfirm={hideApplicationSentModal}
      >
        <View style={{ marginHorizontal: 16 }}>
          <Text style={{ ...Typography.text(`center`, `plusone`), marginTop: 12, marginBottom: 20 }}>
            You have applied to join {group?.name?.trim()}. You will be notified once your membership has been approved.
          </Text>
        </View>
      </BottomSheetModal>
    </WizardLayout>
  );
};

const QuestionItem = ({ questionIndex, question, onChangeText, onBlur, value, setAnswer }) => {
  const [date, setDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(Platform.OS === `ios`);
  const [checks, setChecks] = useState([]);

  useEffect(() => {
    if (question.type.name === questionTypes.date.name || question.type.name === questionTypes.time.name) {
      setAnswer(questionIndex, date.toISOString());
    }
  }, [date, question.type.name, questionIndex, setAnswer]);

  const setMCAnswer = (value) => {
    setAnswer(questionIndex, value);
  };

  const setCheckAnswers = (selections) => {
    setChecks(selections);
    const answers = selections.map((item) => item.label);
    setAnswer(questionIndex, answers);
  };

  const renderInputMethod = (typeName) => {
    if (TextBasedAnswers.includes(typeName)) {
      return (
        <TextInput
          testID="QUESTION_TEXT"
          multiline
          blurOnSubmit
          scrollEnabled={false}
          returnKeyType="done"
          onChangeText={onChangeText}
          onBlur={onBlur}
          value={value}
          style={localStyles.textAnswerInput}
        />
      );
    }
    if (TimeBasedAnswers.includes(typeName)) {
      const colorScheme = Appearance.getColorScheme();
      const mode = typeName === questionTypes.time.name ? `time` : `date`;
      const _setDate = (_evt, date) => {
        setDate(date);
        setAnswer(questionIndex, date.toISOString());
      };
      return (
        <View>
          <TouchableOpacity testID="QUESTION_DATETIME" activeOpacity={0.8} onPress={() => setShowDatePicker(!showDatePicker)}>
            <View style={{ padding: 10, borderRadius: 8, borderColor: Colors.lightGray, borderWidth: 1 }}>
              <Text>
                {new Date(date).toLocaleString(
                  `en-us`,
                  typeName === questionTypes.date.name ? shortDateOptions : { hour: `numeric`, minute: `numeric` },
                )}
              </Text>
            </View>
          </TouchableOpacity>
          {showDatePicker && (
            <View style={{ backgroundColor: colorScheme === `dark` ? Colors.black : Colors.white }}>
              <DateTimePicker
                mode={mode}
                value={date}
                display="spinner"
                is24Hour={false}
                onChange={(evt, date) => {
                  if (Platform.OS === `android`) setShowDatePicker(false);
                  _setDate(evt, date);
                }}
              />
            </View>
          )}
        </View>
      );
    }
    if (typeName === questionTypes.checkbox.name) {
      const renderLabel = (label, _style, _selected, _item) => {
        return (
          <View style={{ flex: 1, marginHorizontal: 10, marginVertical: 5 }}>
            <Text style={{ fontSize: Typography.baseFontSize }}>{label}</Text>
          </View>
        );
      };
      return (
        <View>
          <SelectMultiple
            items={question.options}
            renderLabel={renderLabel}
            selectedItems={checks}
            onSelectionsChange={setCheckAnswers}
            keyExtractor={(item, index) => item.id + index.toString()}
          />
        </View>
      );
    }
    if (typeName === questionTypes.multiple_choice.name) {
      return (
        <View style={{ flex: 1 }} testID="QUESTION_MULTIPLE_CHOICE">
          <RadioButtons questionKey={question.key} options={question.options} onSelectChange={setMCAnswer} />
        </View>
      );
    }
    return null;
  };
  return (
    <View>
      <Text style={localStyles.questionText}>
        {question.key}
        <Text style={{ color: Colors.textRed }}>*</Text>
      </Text>
      {renderInputMethod(question.type.name)}
    </View>
  );
};
QuestionItem.propTypes = {
  question: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setAnswer: PropTypes.func,
};

QuestionItem.defaultProps = {
  value: undefined,
  setAnswer: undefined,
};

const localStyles = {
  groupInfoContainer: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: Colors.lightGray,
    margin: 10,
  },
  groupInfo: {
    fontSize: Typography.baseFontSize,
    fontWeight: `500`,
    color: Colors.textBlack,
    lineHeight: 18,
  },
  groupAppPrompt: {
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
    lineHeight: 18,
  },
  questionText: {
    fontSize: Typography.smallFontSize,
    marginVertical: 8,
    color: Colors.gray,
  },
  textAnswerInput: {
    ...Typography.text(),
    backgroundColor: Colors.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.gray,
    marginVertical: 10,
    marginHorizontal: 8,
    padding: 10,
    //Keep in mind: minHeight = (lineHeight + marginVertical + padding )* 2
    minHeight: (Typography.text().lineHeight + 20) * 2,
  },
};
