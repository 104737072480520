import React, { useState, useEffect } from 'react';
import { Text, View, ScrollView, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Colors, Typography } from '../common-styles';
import { LoadingIndicator, SafeAreaView, KeyboardPaddingView, ThemedButton, BottomSheetModal, BackButton } from '../common-ui';
import { MeshVerifiedCheck } from '../images';
import { client, refetchQueriesFor, VERIFY_EMAIL_MUTATION3, GET_EMAIL_VERIFICATION_CODE } from '../graphql';
import { setUserEmailVerified, setCommunityEmailVerified } from '../redux/contributionSlice';
import { AppContext } from '../../AppContext';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';

type EmailVerificationRoute = RouteProp<HomeNavigatorScreens, `EmailVerificationScreen`>;

export const EmailVerificationScreen = () => {
  const navigation = useNavigation<HomeNavigator>();
  const { params } = useRoute<EmailVerificationRoute>();
  const dispatch = useDispatch();
  const { identity, user } = React.useContext(AppContext);
  const { type, group, sendVerificationCodeInput, emailToDisplay } = params;

  const isCommunity = type === `group`;

  const [verificationTokenText, setVerificationTokenText] = useState(``);
  const [emailVerificationSuccess, setEmailVerificationSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmailResendMessage, setShowEmailResendMessage] = useState(false);

  const [verifyEmail, _response2] = useMutation(VERIFY_EMAIL_MUTATION3, {
    refetchQueries: refetchQueriesFor(`Setting`),
  });

  useEffect(() => {
    const goBackAction = () => {
      console.log(`== goBackAction ==`);
      if (params?.type === `group`) {
        console.log(`navigation go back`);
        navigation.goBack();
      } else {
        console.log(`navigation go to profile screen`);
        navigation.navigate(`ProfileSettings`);
      }
    };
    navigation.setOptions({
      headerTitle: `Verify email`,
      headerLeft: () => <BackButton onPress={goBackAction} />,
    });
  }, [navigation, params?.type]);

  const onChangeVerificationText = (text: string) => {
    setVerificationTokenText(text);
    setErrorMessage(undefined);
  };

  const handleVerifyEmail = async () => {
    try {
      setLoading(true);
      if (isCommunity) dispatch(setCommunityEmailVerified(true));
      else dispatch(setUserEmailVerified(true));

      const input = {
        token: verificationTokenText,
        group_id: group?.id,
        user_id: user?.id,
        from_community: isCommunity,
        identity_id: identity?.id,
      };

      const { data, errors } = await verifyEmail({
        variables: input,
        refetchQueries: refetchQueriesFor(`Setting`, `Group`, `GroupMember`),
      });

      if (!data?.verifyEmailSetting3 || errors) setErrorMessage(`The verification code is wrong or expired`);
      else {
        const [verificationStatus] = data?.verifyEmailSetting3;

        if (verificationStatus?.value === `true`) {
          setEmailVerificationSuccess(true);
          setErrorMessage(undefined);
          navigation.pop(2);
        } else setErrorMessage(`The verification code is wrong or expired`);
      }
    } catch (error) {
      console.error(`handleVerifyEmail`, (error as Error).message);
    }
    setLoading(false);
  };

  const resendEmailVerificationCode = async () => {
    try {
      setShowEmailResendMessage(true);
      setTimeout(() => {
        setShowEmailResendMessage(false);
      }, 6000);
      setLoadingResendEmail(true);
      setVerificationTokenText(``);
      setErrorMessage(undefined);
      await client.query({
        query: GET_EMAIL_VERIFICATION_CODE,
        variables: sendVerificationCodeInput,
        fetchPolicy: `no-cache`,
      });
    } catch (error) {
      console.error(`resendEmailVerificationCode`, error);
    }
    setLoadingResendEmail(false);
  };

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={localStyles.container}>
            <View>
              <View style={localStyles.blob} />
              <Text allowFontScaling={false} style={localStyles.headerText}>
                Enter your verification code
              </Text>
            </View>
            <Text style={localStyles.subtext}>
              We have sent a confirmation code to{`\n`}
              {emailToDisplay}. This code is valid for 10 minutes.
            </Text>
            <TextInput
              blurOnSubmit
              autoFocus
              value={verificationTokenText}
              onChangeText={onChangeVerificationText}
              placeholder="______"
              placeholderTextColor={Colors.textPlaceholder}
              keyboardType="number-pad"
              style={localStyles.inputStyle}
              maxLength={6}
              returnKeyType="done"
              clearButtonMode="always"
            />

            <RenderError errorMessage={errorMessage} />

            {showEmailResendMessage ? (
              <Text style={localStyles.subtext}>We have sent a new verification code to {emailToDisplay}</Text>
            ) : null}

            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => resendEmailVerificationCode()}
              style={{ marginTop: 24 }}
              disabled={loadingResendEmail}
            >
              <Text style={localStyles.requestNewCodeText}>Request a new verification code</Text>
            </TouchableOpacity>

            <ThemedButton
              rounded
              title="Verify my email"
              onPress={() => handleVerifyEmail()}
              buttonStyle={{ padding: 15 }}
              containerStyle={{ marginTop: 30 }}
              leftIcon={loading && <LoadingIndicator />}
              disabled={loading || verificationTokenText.length < 6}
            />
            <Text style={localStyles.subtext}>
              If you do not receive a verification email, check your email to make sure it is correct, or check your spam folder.
            </Text>
          </View>

          <BottomSheetModal
            title=""
            showCancelBtn={false}
            visible={showModal}
            onPressCancel={() => setShowModal(false)}
            confirmTitle={emailVerificationSuccess ? `Ok` : `Go back`}
            onPressConfirm={() => (emailVerificationSuccess ? navigation.pop(2) : navigation.goBack())}
          >
            <View>
              <View style={{ justifyContent: `center`, flexDirection: `row` }}>
                {emailVerificationSuccess && <MeshVerifiedCheck height={75} width={75} />}
              </View>
              <Text style={{ marginTop: 26, marginHorizontal: 18, ...Typography.text(`center`, `bold`, `large`, `black`) }}>
                {emailVerificationSuccess ? `We have verified your email` : `We could not verify your email. Please try again`}
              </Text>
            </View>
          </BottomSheetModal>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

type RenderErrorProps = { errorMessage?: string };
const RenderError: React.FC<RenderErrorProps> = ({ errorMessage }) => {
  if (errorMessage)
    return (
      <View style={localStyles.errorContainer}>
        <Text style={localStyles.errorText}>{errorMessage}</Text>
      </View>
    );
  return null;
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 15,
    marginTop: 40,
  },
  headerText: {
    ...Typography.text(`large`, `black`, `bold`),
    marginBottom: 20,
  },
  subtext: {
    ...Typography.text(`base`, `gray`),
    marginTop: 15,
    marginBottom: 24,
  },
  inputStyle: {
    padding: 10,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    borderRadius: 4,
    letterSpacing: 3,
  },
  errorContainer: {
    padding: 10,
    borderRadius: 4,
    marginTop: 10,
  },
  errorText: {
    ...Typography.text(`small`, `red`, `bold`),
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(79,206,221,0.7)`,
    borderTopStartRadius: 50,
    borderTopEndRadius: 18,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 25,
    left: -10,
    top: 2,
  },
  requestNewCodeText: {
    ...Typography.text(`base`, `theme`, `bold`),
  },
});
