import React, { useContext, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View, ViewStyle, ImageStyle, Insets } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { toggleJoinMeshModal } from '../redux/guestSlice';
import { AppContext } from '../../AppContext';
import { DELETED_USER, DUMMY_UUID, GUEST_ACCOUNT } from '../../constants';
import { Colors } from '../common-styles';
import { User } from '../common-types/types';
import { UserDefaultAvatar } from '../images';
import { GuestPromptModal } from './GuestPromptModal';
import { Events, PendoTrackEvent } from '../pendo/events';

interface AvatarProps {
  user?: User;
  localImage?: string;
  size: number;
  style?: ViewStyle;
  imageStyle?: ImageStyle;
  border?: boolean;
  disabled?: boolean;
  testID?: string;
  onPress?: () => void;
  hitSlop?: Insets;
  isSameStack?: boolean;
  isGroup?: boolean;
}

export const Avatar: React.FC<AvatarProps> = React.memo(
  ({
    user,
    localImage,
    size,
    style,
    imageStyle,
    border = false,
    disabled = false,
    onPress,
    testID = `AVATAR_ICON`,
    hitSlop,
    isSameStack,
    isGroup,
  }) => {
    const dispatch = useDispatch();
    const navigation = useNavigation<any>();
    const route = useRoute();
    const { user: currentUser } = useContext(AppContext);

    const isGuestMode = currentUser?.id === GUEST_ACCOUNT;

    const [isVisible, setVisible] = useState(false);

    const { avatar_url } = user || {};
    const avatar = { uri: localImage || avatar_url };

    const localStyle = border
      ? {
          borderWidth: route.name === `Profile` ? 2 : 1,
          borderColor: Colors.avatarBorderColor,
        }
      : undefined;

    const sizeWithBorder = size + (localStyle?.borderWidth ?? 0) * 2;

    const defaultPress = () => {
      if (isGuestMode) {
        setVisible(true);
      } else if (route.name !== `Profile` && user?.handle !== DELETED_USER.handle) {
        PendoTrackEvent(Events.PROFILE, {
          username: user?.handle,
          source: route.name,
          element: `Avatar`,
        });
        if (isSameStack) {
          navigation.navigate(`Profile`, { user, isSameStack });
        } else {
          navigation.navigate(`ProfileStack`, { screen: `Profile`, params: { user, isSameStack } });
        }
      }
    };

    return (
      <>
        <TouchableOpacity
          testID={testID}
          key={user?.id}
          hitSlop={hitSlop}
          disabled={!user?.id || user?.id === DUMMY_UUID || disabled}
          onPress={
            onPress
              ? () => {
                  if (isGuestMode) {
                    dispatch(toggleJoinMeshModal({ open: true }));
                  } else onPress();
                }
              : defaultPress
          }
          style={style}
          activeOpacity={0.8}
        >
          {avatar.uri ? (
            <Image
              source={avatar}
              resizeMode="cover"
              style={StyleSheet.flatten([
                { width: sizeWithBorder, height: sizeWithBorder, borderRadius: 999 },
                localStyle,
                imageStyle,
              ])}
            />
          ) : (
            <View style={StyleSheet.flatten([{ width: size, height: size, borderRadius: 999, overflow: `hidden` }, localStyle])}>
              {isGroup ? (
                <Image style={{ width: 24, height: 24 }} source={require(`../../assets/images/icon.png`)} />
              ) : (
                <UserDefaultAvatar width={size} height={size} style={{ width: size, height: size }} />
              )}
            </View>
          )}
        </TouchableOpacity>
        {isGuestMode ? <GuestPromptModal isVisible={isVisible} setHide={() => setVisible(false)} /> : null}
      </>
    );
  },
);
