/* eslint-disable react/no-unused-prop-types */
import { ReactNativeFile } from 'apollo-upload-client';
import { Camera } from 'expo-camera';
import PropTypes from 'prop-types';
import { Dimensions, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import React from 'react';
import { Colors, _bad_do_not_use_HEIGHT } from '../common-styles';
import { MeshIcon } from './MeshIcon';
import { NoThemeButton } from './NoThemeButton';

export class CameraCapture extends React.PureComponent {
  static propTypes = {
    type: PropTypes.number.isRequired,
    setType: PropTypes.func.isRequired,
    setPhoto: PropTypes.func.isRequired,
    setShowCamera: PropTypes.func.isRequired,
    toggleCamera: PropTypes.func.isRequired,
  };

  snapPhoto = async () => {
    const { setPhoto, setShowCamera } = this.props;
    if (this.camera) {
      const photo = await this.camera.takePictureAsync();
      const type = `image/jpeg`;
      const name = await photo.uri.slice(photo.uri.indexOf(`/Camera/`)).slice(8);
      const image = new ReactNativeFile({
        uri: photo.uri,
        type,
        name,
      });
      setPhoto(image);
      setShowCamera(false);
    }
  };

  flipCamera = () => {
    const { type, setType } = this.props;
    if (type === Camera.Constants.Type.back) return setType(Camera.Constants.Type.front);
    return setType(Camera.Constants.Type.back);
  };

  render = () => {
    const { type, toggleCamera } = this.props;
    //TODO: This screen needs to be converted into a functional component in order to use the useHeaderHeight hook instead HeaderHeightContext (unsupported) -> paddintTop: useHeaderHeight();
    return (
      <Modal isVisible style={{ ...cameraStyles.modal, paddingTop: 60 }}>
        <View style={{ flexGrow: 1 }}>
          <NoThemeButton
            clear
            title="Cancel"
            color={Colors.white}
            onPress={toggleCamera}
            containerStyle={{ alignSelf: `flex-start`, left: 10, bottom: 20 }}
          />
          <Camera
            ratio="4:3" // required in android
            style={cameraStyles.camera}
            type={type}
            ref={(ref) => (this.camera = ref)}
          >
            <View style={cameraStyles.innerContainer}>
              <View
                style={{
                  height: 365,
                  width: Dimensions.get(`window`).width,
                  bottom: 150,
                }}
              />
              <View style={cameraStyles.bottomRowContainer}>
                <View style={{ flex: 0.1 }} />
                <TouchableOpacity style={{ paddingBottom: 40 }} onPress={this.snapPhoto} activeOpacity={0.8}>
                  <View style={cameraStyles.captureButtonRing}>
                    <View style={cameraStyles.captureButton} />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.flipCamera} style={{ right: 25, bottom: 20 }}>
                  <View>
                    <MeshIcon name="flip-camera" color={Colors.white} size={45} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </Camera>
        </View>
      </Modal>
    );
  };
}

const cameraStyles = {
  modal: {
    margin: 0,
    backgroundColor: Colors.black,
  },
  camera: {
    flexGrow: 1,
    height: _bad_do_not_use_HEIGHT * 0.8, // FIXME: don't use random percentages for height
  },
  innerContainer: {
    flex: 1,
    justifyContent: `flex-end`,
  },
  bottomRowContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
  },
  captureButtonRing: {
    justifyContent: `center`,
    alignItems: `center`,
    borderColor: Colors.white,
    borderWidth: 8,
    height: 60,
    width: 60,
    borderRadius: 99,
    alignSelf: `center`,
  },
  captureButton: {
    justifyContent: `center`,
    borderColor: Colors.black,
    borderWidth: 3,
    backgroundColor: Colors.white,
    height: 50,
    width: 50,
    borderRadius: 99,
  },
};
