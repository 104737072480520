import { createContext } from 'react';
import { GUEST_ACCOUNT } from './constants';
import { getReleaseChannel } from './environment';
import { TokenRes, Identity } from './packages/common-types/types';

export type AuthStateType = {
  identity?: Identity;
  user?: TokenRes;
  prevUser?: TokenRes;
  feedActivityCount: number;
  notificationCount: number;
  followNotificationCount: number;
  chatNotifications: number;
  moderationNotificationCount: number;
  invite_token?: string;
  recoveryCode?: string;
  chatPushToken?: string;
  pushToken?: string;
  launchUrl?: string | null;
  subscribed: boolean;
  chatStatus: string;
  versionInfo: VersionInfo;
  channel: any;
  thread: any;
  isGuestMode: boolean;
  //group?
  setUser: (user: TokenRes) => void;
  setIdentity: (identity: Identity) => void;
  setLaunchUrl: (launchUrl?: string | null) => void;
  setInviteToken: (invite_token?: string) => void;
  setFeedActivityCount: (activityCount: number) => void;
  setNotificationCount: (notificationCount: number) => void;
  setFollowNotificationCount: (notificationCount: number) => void;
  setModerationCount: (setModerationCount: number) => void;
  updateModerationCount: (difference: number) => void;
  setChatStatus: (chatStatus: ChatStatus) => void;
  setChatNotifications: (chatNotifications: number) => void;
  setChatPushToken: (chatPushToken: string) => void;
  setPushToken: (pushToken: string) => void;
  setLatestVersionInfo: (info: VersionInfo) => void;
  setIsGuestMode: (isGuestMode: boolean) => void;
  logout: () => void;
  setChannel: any;
  setThread: any;
};

type ChatStatus = `disconnected` | `initialized`;
type AuthAction = {
  type: string;
  payload?: any;
};
type VersionInfo = {
  latestVersion?: string;
  latestPublish?: string;
  latestHash?: string;
};

export const AppContextActions = {
  SET_IDENTITY: `SET_IDENTITY`,
  SET_INVITE: `SET_INVITE`,
  SET_LAUNCH_URL: `SET_LAUNCH_URL`,
  SET_FEED_ACTIVITY_COUNT: `SET_FEED_ACTIVITY_COUNT`,
  SET_NOTIFICATION_COUNT: `SET_NOTIFICATION_COUNT`,
  SET_FOLLOW_NOTIFICATION_COUNT: `SET_FOLLOW_NOTIFICATION_COUNT`,
  SET_MODERATION_COUNT: `SET_MODERATION_COUNT`,
  UPD_MODERATION_COUNT: `UPD_MODERATION_COUNT`,
  DEC_MODERATION_COUNT: `DEC_MODERATION_COUNT`,
  SET_CHAT_STATUS: `SET_CHAT_STATUS`,
  SET_CHAT_NOTIFICATION: `SET_CHAT_NOTIFICATION`,
  SET_CHAT_PUSHTOKEN: `SET_CHAT_PUSHTOKEN`,
  SET_PUSHTOKEN: `SET_PUSHTOKEN`,
  SET_LATEST_VERSION: ``,
  SET_IS_GUEST_MODE: `SET_IS_GUEST_MODE`,
  LOGIN_USER: `LOGIN_USER`,
  LOGOUT: `LOGOUT`,
  SET_CHANNEL: `SET_CHANNELT`,
  SET_THREAD: `SET_THREAD`,
};

export const initialAppState: AuthStateType = {
  identity: undefined,
  user: undefined,
  prevUser: undefined,
  feedActivityCount: 1,
  notificationCount: 0,
  followNotificationCount: 0,
  chatNotifications: 0,
  moderationNotificationCount: 0,
  invite_token: undefined,
  recoveryCode: undefined,
  chatPushToken: undefined,
  pushToken: undefined,
  launchUrl: undefined,
  subscribed: false,
  chatStatus: `disconnected`,
  isGuestMode: false,
  versionInfo: {
    latestVersion: undefined,
    latestPublish: undefined,
    latestHash: undefined,
  },
  channel: undefined,
  thread: undefined,
  setUser: (_user: TokenRes) => undefined,
  setIdentity: (_identity: Identity) => undefined, // Also set to async storage
  setLaunchUrl: (_url?: string | null) => undefined,
  setInviteToken: (_invite_token?: string) => undefined,
  setFeedActivityCount: (_activityCount: number) => undefined,
  setNotificationCount: (_notificationCount: number) => undefined,
  setFollowNotificationCount: (_followNotificationCount: number) => undefined,
  setModerationCount: (_moderationCount: number) => undefined,
  updateModerationCount: (_difference: number) => undefined,
  setChatStatus: (_chatStatus: ChatStatus) => undefined,
  setChatNotifications: (_chatNotifications: number) => undefined,
  setChatPushToken: (_chatPushToken: string) => undefined,
  setPushToken: (_pushToken: string) => undefined,
  setLatestVersionInfo: (_info: VersionInfo) => undefined,
  setIsGuestMode: (_isGuestMode: boolean) => undefined,
  logout: () => undefined,
  setChannel: (_channel: any) => undefined,
  setThread: (_thread: any) => undefined,
};

const ENV = getReleaseChannel() || `dev`;

// eslint-disable-next-line complexity
export const appContextReducer = (prevState: AuthStateType, action: AuthAction): AuthStateType => {
  switch (action.type) {
    case AppContextActions.SET_IDENTITY:
      return {
        ...prevState,
        identity: action.payload,
      };
    case AppContextActions.SET_INVITE:
      return {
        ...prevState,
        invite_token: action.payload,
      };
    case AppContextActions.SET_LAUNCH_URL:
      return {
        ...prevState,
        launchUrl: action.payload,
      };
    case AppContextActions.SET_FEED_ACTIVITY_COUNT:
      return {
        ...prevState,
        feedActivityCount: action.payload,
      };
    case AppContextActions.SET_NOTIFICATION_COUNT:
      return {
        ...prevState,
        notificationCount: action.payload,
      };
    case AppContextActions.SET_FOLLOW_NOTIFICATION_COUNT:
      return {
        ...prevState,
        followNotificationCount: action.payload,
      };
    case AppContextActions.SET_MODERATION_COUNT:
      return {
        ...prevState,
        moderationNotificationCount: action.payload,
      };
    case AppContextActions.UPD_MODERATION_COUNT: {
      const { moderationNotificationCount: prevCount } = prevState;
      if (prevCount + action.payload < 0) return prevState;
      return {
        ...prevState,
        moderationNotificationCount: prevCount + action.payload,
      };
    }
    case AppContextActions.SET_CHAT_STATUS:
      return {
        ...prevState,
        chatStatus: action.payload,
      };
    case AppContextActions.SET_CHAT_NOTIFICATION:
      return {
        ...prevState,
        chatNotifications: action.payload,
      };
    case AppContextActions.SET_CHAT_PUSHTOKEN:
      return {
        ...prevState,
        chatPushToken: action.payload,
      };
    case AppContextActions.SET_PUSHTOKEN:
      return {
        ...prevState,
        pushToken: action.payload,
      };
    case AppContextActions.SET_LATEST_VERSION:
      return {
        ...prevState,
        versionInfo: action.payload,
      };
    case AppContextActions.SET_IS_GUEST_MODE:
      return {
        ...prevState,
        isGuestMode: action.payload,
      };
    case AppContextActions.LOGIN_USER:
      return {
        ...prevState,
        isGuestMode: action.payload.id === GUEST_ACCOUNT,
        user: {
          ...action.payload,
          loginTime: new Date(),
          env: ENV,
        },
      };
    case AppContextActions.LOGOUT:
      return initialAppState;
    case `SET_CHAT_NOTIFICATIONS`:
      return {
        ...prevState,
        chatNotifications: action.payload,
      };
    case AppContextActions.SET_CHANNEL:
      return {
        ...prevState,
        channel: action.payload,
      };
    case AppContextActions.SET_THREAD:
      return {
        ...prevState,
        channel: action.payload,
      };
    default:
      return prevState;
  }
};

console.log('🚀 ~ initialAppState', initialAppState.feedActivityCount);
export const AppContext = createContext<AuthStateType>(initialAppState);
