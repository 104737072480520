import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const EmptyNotifications = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="360px" height="275px" viewBox="0 0 360 275" {...props}>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path fill="#FFF" d="M0 0H360V275H0z" />
        <G transform="translate(-260 6)">
          <Path fill="#FCE8F0" d="M551 260.31H0h551z" />
          <Path
            fill="#EA236C"
            fillRule="nonzero"
            d="M497.022 175.262c-31.476 52.673-178.078 42.062-219.902-16.294C235.295 100.61 297.817-5.112 371.117.192c73.301 5.684 156.95 122.019 125.905 175.07z"
          />
        </G>
        <G fillRule="nonzero" transform="translate(0 28)">
          <G>
            <Path
              fill="#B0B1DD"
              d="M163.92963 -28.3296976L235.934443 267.571017 92.4715777 268.148867z"
              transform="rotate(-69 164.203 119.908)"
            />
            <Path
              fill="#CBCCF1"
              d="M204.312505 -52.4357001L266.098078 275.587911 204.901001 256.076434 119.630849 299.795165z"
              transform="rotate(-75 192.864 123.68)"
            />
            <Path fill="#EEEEFE" d="M25.7900243 67.0128438L323.895207 146.286004 344.051543 239.957366z" />
          </G>
        </G>
      </G>
    </Svg>
  );
};
