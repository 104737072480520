import { useMutation, useQuery } from '@apollo/react-hooks';
import { useNavigation } from '@react-navigation/native';
import { FlatList, Switch, Text, TouchableOpacity, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { Divider, LoadingIndicator, MeshIcon } from '../common-ui';
import { UPDATE_SETTING_MUTATION, USER_NOTIFY_POST_SETTINGS } from '../graphql';
import { MeshVerifiedCheck } from '../images';

export const UserCommunityPostNotificationListItem = () => {
  const navigation = useNavigation();
  const navigateToSettingPage = useCallback(() => navigation.navigate(`UserCommunityPostNotificationSettings`), [navigation]);

  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={navigateToSettingPage}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, padding: 16, backgroundColor: Colors.white }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Nodes</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const UserCommunityPostNotificationSettings = () => {
  const { user } = React.useContext(AppContext);
  const { data, loading, error } = useQuery(USER_NOTIFY_POST_SETTINGS, { fetchPolicy: `cache-and-network` });
  const [groupSettings, setGroupSettings] = useState([]);
  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION);

  useEffect(() => {
    setGroupSettings(data && data.getUserCommunityPostNotificationSettings);
  }, [data]);

  //#region api calls
  const updateSettingCallback = useCallback(
    async (group_id, value) => {
      try {
        if (!group_id) throw new Error(`no group_id param passed to updateSetting()`);
        if (!value) throw new Error(`no value param passed to updateSetting()`);

        // call the mutation
        const input = {
          key: `user.setting.notify_post`,
          user_id: user.id,
          group_id,
          value,
        };
        await updateSetting({ variables: { input } });
      } catch (err) {
        console.error(`Error thrown in updateSetting() in UserCommunityPostNotificationSetttings.jsx`, err.message);
      }
    },
    [updateSetting, user.id],
  );
  //#endregion

  //#region render functions
  const renderItem = useCallback(
    ({ item, _index }) => {
      const { group } = item;

      const changeSetting = () => {
        // find index of setting we want to change
        const foundIndex = groupSettings.findIndex((groupSetting) => groupSetting.id === item.id);
        console.log(`found index`, foundIndex);
        if (foundIndex > -1) {
          // found
          const cloned = groupSettings;
          const newSettingItem = groupSettings[foundIndex];
          // toggle 'all' or 'none' based on previous item.value (setting.value)
          if (newSettingItem.value === `all`) newSettingItem.value = `none`;
          else newSettingItem.value = `all`;
          // set previous item in cloned groupSettings array at found index to newSettingItem
          cloned[foundIndex] = newSettingItem;
          // set in state for UI update
          setGroupSettings(cloned);
          // update setting api call
          updateSettingCallback(group.id, newSettingItem.value);
        }
      };

      return (
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, padding: 16 }}>
            <View style={{ flexDirection: `row`, alignItems: `center` }}>
              <Text style={Typography.text(`plusone`, `bold`)}>{group.name}</Text>
              {group?.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
            </View>
            <Switch
              value={item.value === `all`}
              onValueChange={changeSetting}
              trackColor={{ true: `#2C9A44`, false: `#BCBCBC` }}
            />
          </View>
          <Divider />
        </View>
      );
    },
    [groupSettings, updateSettingCallback],
  );

  const keyExtractor = useCallback((item, index) => item.id + index.toString(), []);

  //#endregion

  if (loading)
    return (
      <View style={{ flex: 1, justifyContent: `center`, alignItems: `center`, backgroundColor: Colors.white }}>
        <LoadingIndicator />
      </View>
    );

  if (error)
    return (
      <View style={{ flex: 1, backgroundColor: Colors.white }}>
        <Text style={Typography.text(`red`, `bold`)}>Error thrown retrieving user notification settings for communities</Text>
        <Text>{JSON.stringify(error)}</Text>
      </View>
    );

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <FlatList
        style={{ backgroundColor: Colors.white }}
        data={groupSettings || []}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </View>
  );
};
