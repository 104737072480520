import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import React from 'react';
import { Spacing } from '../common-styles';

export const CenterColumn = ({ children, style, testID }) => (
  <View style={StyleSheet.flatten([{ width: `100%`, flexDirection: `row`, justifyContent: `center` }, style])}>
    <View style={{ flexBasis: Spacing.standardWidth, flexShrink: 1 }} testID={testID}>
      {children}
    </View>
  </View>
);
CenterColumn.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.any,
  testID: PropTypes.string,
};

CenterColumn.defaultProps = {
  style: undefined,
  testID: `COLUMN`,
};
