import { useMutation } from '@apollo/react-hooks';
import { useRoute } from '@react-navigation/native';
import { DataProxy } from 'apollo-cache';
import { Asset } from 'react-native-image-picker';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  GroupCoreFields,
  GroupInvitation,
  GroupMember,
  LeaveGroupData,
  LeaveGroupVars,
  BottomSheetOptions,
} from '../../../common-types/types';
import { useBottomSheetOptions } from '../../../common-util/hooks/useBottomSheetOptions';
import { useConfirmModal } from '../../../common-util/hooks/useConfirmModal';
import { useDisclosure } from '../../../common-util/hooks/useDisclosure';
import * as Sentry from '../../../constants/Sentry';
import { client, LEAVE_GROUP, refetchQueriesFor, SINGLE_IMG_UPLOAD, UPDATE_GROUP_PHOTO } from '../../../graphql';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useJoinCommunity } from '../../hooks/useJoinCommunity';
import { useCommunities } from './useCommunities';
import { CommunityPreviewRoute } from '../CommunityPreview';
import { grantMedia } from '../helpers';
import { setMemberCount } from '../../../redux/feedSlice';
import useMediaPermissons from '../../../hooks/useMediaPermissions';
import { AppContext } from '../../../../AppContext';

/**
 * //FIXME: better name for this type may be required
 * Determine which community object will use based on the screen is invoked.
 */
export type HeaderScreenMode = `preview` | `home`;

interface UseCommunityHeaderConfig {
  /**
   * Whether the header should render as a preview or in the home screen.
   */
  mode: HeaderScreenMode;
  /**
   * Change handler for the current community in the HomeScreen header.
   */
  onChangeGroup: (community?: GroupCoreFields) => void;
  /**
   * OnChange handler for the current groupMember in the CommunityPreview header.
   */
  onChangeMember: (member?: GroupMember) => void;
  /**
   * Handler for the setInvite token
   */
  onChangeInvite?: (invitation?: GroupInvitation) => void;
  /**
   * Event handler triggered to refresh the carousel items
   */
  refreshCarouselItems?: () => void;
  /**
   *
   */
  groupMember?: GroupMember;
}

export const useCommunityHeader = ({
  mode = `home`,
  onChangeGroup,
  onChangeMember,
  groupMember,
  refreshCarouselItems,
}: UseCommunityHeaderConfig) => {
  const { isGuestMode } = React.useContext(AppContext);
  const dispatch = useAppDispatch();
  const { checkPermission } = useMediaPermissons();
  const { params } = useRoute<CommunityPreviewRoute>();
  const { onToggle: onToggleBottomSheet, onClose: onCloseBottomSheet, isOpen: isBottomSheetOpen } = useDisclosure();
  const { activeCommunity, previewCommunity } = useSelector((state: RootState) => state.feed);
  const currentCommunity = mode === `home` ? activeCommunity : params?.group || previewCommunity; // Load from params first if available
  const [leaveGroup, { data: leavedGroup, loading: leavingGroup }] = useMutation<LeaveGroupData, LeaveGroupVars>(LEAVE_GROUP, {
    update: (cache: DataProxy, { data }) => {
      try {
        // eslint-disable-next-line no-param-reassign
        delete (cache as any).data.data[`GroupMember:${groupMember?.id}`];
        if (data?.leaveGroup) dispatch(setMemberCount(data?.leaveGroup));
      } catch (error) {
        console.error(`[CacheError] failed to delete cacherd record: GroupMember:${groupMember?.id}`, error);
      }
    },
  });
  const [justLeftGroup, setjustLeftGroup] = React.useState(false);
  const { attemptJoinCommunity, loading } = useJoinCommunity(currentCommunity);
  const promptLeaveCommunity = useConfirmModal();
  const { buildCommunityModalOptions, buildPreviewModalOptions } = useBottomSheetOptions(onToggleBottomSheet);
  const { setActiveCommunityAfterArchive } = useCommunities();

  const handleJoinCommunity = () => {
    attemptJoinCommunity(mode === `preview` ? `CommunityPreview` : `Home`);
  };

  /**
   * Determines if the user is a member of the community to prompt them to leave. If not, it will attemp to join the community.
   */
  const handleJoinOrLeaveCommunity = React.useCallback(() => {
    onCloseBottomSheet();
    if (groupMember?.role_id && currentCommunity) {
      promptLeaveCommunity({
        title: `Leave Community`,
        body: `Are you sure you want to leave ${currentCommunity?.name}?`,
        onConfirm: () => {
          leaveGroup({
            variables: { group_id: currentCommunity.id },
            refetchQueries: refetchQueriesFor(`GroupMember`, `Group`),
          });
          setjustLeftGroup(true);
        },
      });
    } else {
      attemptJoinCommunity(mode === `preview` ? `CommunityPreview` : `Home`);
    }
  }, [currentCommunity, groupMember?.role_id, leaveGroup, promptLeaveCommunity, attemptJoinCommunity, mode, onCloseBottomSheet]);

  /**
   * Update the group photo of the community and update the cache.
   * @param newImageToUpload Image to upload to the community
   * @returns updated group
   */
  const uploadGroupPhoto = React.useCallback(
    async (newImageToUpload: Asset) => {
      let update = null;
      try {
        const { data } = await client.mutate({
          mutation: SINGLE_IMG_UPLOAD,
          variables: { file: newImageToUpload },
        });
        const { url } = data.singleImgUpload;
        update = await client.mutate({
          mutation: UPDATE_GROUP_PHOTO,
          variables: {
            group_id: activeCommunity?.id,
            avatar_url: url,
          },
          refetchQueries: refetchQueriesFor(`Group`),
          fetchPolicy: `no-cache`,
        });
        client.reFetchObservableQueries();
        // setShouldUpdateCommunityAvatar(true);
        if (refreshCarouselItems) refreshCarouselItems();
      } catch (error) {
        console.error(error);
        Sentry.captureException(error as Error);
      }
      if (refreshCarouselItems) refreshCarouselItems();
      onToggleBottomSheet();
      return update;
    },
    [activeCommunity, onToggleBottomSheet, refreshCarouselItems],
  );

  /**
   * Get the bottom sheet options to display when the user clicks on the three dot menu.
   * @param toggleReport Toggle function for the report modal
   * @returns
   */
  const getBottomSheetOptions = useCallback(() => {
    let options = [] as BottomSheetOptions[];
    if (currentCommunity && mode === `home`) {
      options = buildCommunityModalOptions({
        leaveCommunity: handleJoinOrLeaveCommunity, // LEGACY Support: A refactor is needed in order to just require this function once
        joinCommunity: handleJoinOrLeaveCommunity,
        changeCommunityAvi: async () => {
          await checkPermission();
          grantMedia(uploadGroupPhoto);
        },
        currentCommunity,
        groupMember,
        isGuestMode,
      });
    }
    if (mode === `preview`) {
      options = buildPreviewModalOptions({
        currentCommunity,
        leaveCommunity: handleJoinOrLeaveCommunity,
        joinCommunity: handleJoinOrLeaveCommunity,
        groupMember,
        isGuestMode,
      });
    }

    return options;
  }, [
    currentCommunity,
    mode,
    handleJoinOrLeaveCommunity,
    uploadGroupPhoto,
    buildCommunityModalOptions,
    groupMember,
    buildPreviewModalOptions,
    isGuestMode,
    checkPermission,
  ]);

  useEffect(() => {
    if (leavedGroup && justLeftGroup) {
      //
      onChangeMember(undefined);
      onChangeGroup(undefined);
      setActiveCommunityAfterArchive();
      // prevent further triggering as we can't exactly clear leavedGroup
      // else it will keep triggering every onChangeGroup
      setjustLeftGroup(false);
    }
  }, [leavedGroup, justLeftGroup, onChangeMember, onChangeGroup]);

  return {
    handleJoinOrLeaveCommunity,
    getBottomSheetOptions,
    isBottomSheetOpen,
    groupMember,
    currentCommunity,
    leavingGroup,
    justLeftGroup,
    onCloseBottomSheet,
    handleJoinCommunity,
    onToggleBottomSheet,
    loading,
  };
};

export type HeaderProps = ReturnType<typeof useCommunityHeader>;
