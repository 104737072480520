import { NetworkStatus } from 'apollo-client';
import { PropTypes } from 'prop-types';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import { Text, TouchableOpacity, View, SectionList } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { groupBy } from 'lodash';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ModerationIssue } from './ModerationIssue';
import { Colors, Typography } from '../common-styles';
import { CommunityAvatar, MeshIcon, ThemedButton, ThreeDotsButton, LoadingIndicator, SafeAreaView } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { useAppDispatch } from '../redux/store';
import { setActiveCommunity, setMemberCount } from '../redux/feedSlice';

import {
  APPROVE_JOIN_REQUEST,
  APPROVE_POST,
  GET_MODERATION_ISSUES_BY_GROUP,
  GET_MODERATION_GROUP_STATE,
  refetchQueriesFor,
  REJECT_JOIN_REQUEST,
} from '../graphql';
import { MODERATION_CATEGORY } from './moderationEnums';
import { AppContext } from '../../AppContext';
import { Events, getContentType, PendoTrackEvent } from '../pendo/events';

const sectionTitles = {
  abuse: `Reports`,
  post_approve: `Post Approvals`,
  user_report: `User Reports`,
  community_report: `Community Reports`,
  community_join_request: `Community Applications`,
  community_application: `Community Applications`,
  mesh_verification: `Mesh Verification`,
};

export const PAGESIZE = 25;

export const ModerateCommunityDetail = ({ navigation, route }) => {
  // TODO:
  // From mod drawer get group, open issues, and membership
  // Delegate admin/member count to another screen
  const { isGuestMode, user } = useContext(AppContext);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(`open`);
  const [openCollapsed, setOpenCollapsed] = useState([]);
  const [closeCollapsed, setCloseCollapsed] = useState([]);
  // const [categories, setCategories] = useState({ open: [], closed: [] });
  const [sections, setSections] = useState([]);
  const [count_open, setCountOpen] = useState(0);
  const [queryInFlight, setQueryInFlight] = useState(false);

  const { group, admin_mod_count = 1 } = route.params;
  const buildAdminModString =
    admin_mod_count && admin_mod_count === 1 ? `${admin_mod_count} Leader` : `${admin_mod_count} Leaders and Moderators`;

  const mod_category_input = {
    group_id: group?.id,
    status,
  };

  const {
    data: data2,
    error: _errorGroup,
    loading: loadingGroup,
    refetch: refetchGroupInfo,
  } = useQuery(GET_MODERATION_GROUP_STATE, {
    variables: {
      group_id: group?.id,
    },
    fetchPolicy: `cache-and-network`,
  });

  const [loadIssues, { called, data, error, loading: loadingIssues, refetch: refetchIssues }] = useLazyQuery(
    GET_MODERATION_ISSUES_BY_GROUP,
    {
      variables: { input: mod_category_input },
      fetchPolicy: `cache-and-network`,
    },
  );

  const [rejectJoin, responseReject] = useMutation(REJECT_JOIN_REQUEST, {
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, `Group`, `GroupMember`),
    onCompleted: (_data) => {
      PendoTrackEvent(Events.MEMBER_APPROVAL, {
        community_name: group?.name,
        action: `rejected`,
        has_application: `no`,
        username: user?.handle,
      });
    },
  });
  const [approveJoin, responseApprove] = useMutation(APPROVE_JOIN_REQUEST, {
    update: (cache, { data }) => {
      const currentGroup = data?.approveJoinRequest?.group;
      if (data?.approveJoinRequest)
        dispatch(setMemberCount({ ...currentGroup, group_member_count: currentGroup?.group_member_count }));
    },
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, `Group`, `GroupMember`),
    onCompleted: (_data) => {
      PendoTrackEvent(Events.MEMBER_APPROVAL, {
        community_name: group?.name,
        action: `approved`,
        has_application: `no`,
        username: user?.handle,
      });
    },
  });
  const [approvePost, responseApprovePost] = useMutation(APPROVE_POST, {
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, `Activity`),
    onCompleted: (data) => {
      const { activity } = data?.approvePost;
      const input = {
        community_name: group?.name,
        topics: activity?.topics?.map((t) => t.topic),
        //suggested topic(s) assigned to post (not approved topics);
        content_type: getContentType(activity),
        post_author: activity?.user?.handle,
        source: `ModerateCommunity`,
        action: status,
      };
      PendoTrackEvent(Events.POST_APPROVAL, input);
    },
  });

  const fullRefetch = useCallback(() => {
    if (!loadingIssues && !loadingGroup && refetchIssues && refetchGroupInfo) {
      refetchGroupInfo();
      refetchIssues();
    }
  }, [refetchGroupInfo, refetchIssues, loadingGroup, loadingIssues]);
  useEffect(() => {
    console.log(`====> Effect 0: Navigation bar controls`);
    const { group, group_member } = route.params;
    navigation.setOptions({
      headerTitle: `Moderation`,
      headerRight: isGuestMode
        ? undefined
        : () => (
            <ThreeDotsButton
              size={24}
              onPress={() => {
                console.log(`go to community details`);
                if (user?.id) dispatch(setActiveCommunity({ user_id: user.id, group }));
                navigation.push(`ThreeDotCommunityDetails`, { group, group_member });
              }}
            />
          ),
    });
  }, [navigation, isGuestMode, route.params, user, dispatch]);

  useEffect(() => {
    setSections([]);

    //TODO: optimize (4 calls on load)
    const group_info = data2?.getModerationCategories;
    if (group_info) {
      console.log(`====> Effect 1: Moderated Group State`);
      setCountOpen(group_info.group_details?.count_open || 0);

      const categories = {
        open: group_info.open_categories,
        closed: group_info.closed_categories,
      };
      const issues = data?.getModerationIssuesByGroup5 || [];
      const groupedCategories = groupBy(issues, (issue) => issue.category);

      const mapCategoriesWithIssues = ({ category, count }) => {
        return {
          id: category,
          title: category,
          count,
          data: groupedCategories[category] || [],
        };
      };
      const { open, closed } = categories || {};
      const newSections = status === `open` ? open?.map(mapCategoriesWithIssues) : closed?.map(mapCategoriesWithIssues);
      setSections(newSections || []);
    }
  }, [data2, data, status]);

  useEffect(() => {
    console.log(`====> Effect 2: fetch Moderated Group Issues`);
    if (!called) loadIssues();
    else refetchIssues();
  }, [refetchIssues, status, called, loadIssues, group]);

  useEffect(() => {
    console.log(`====> Effect 4: queryInFlight state change`);
    const queryInFlight = responseApprovePost?.loading || responseApprove?.loading || responseReject?.loading;
    setQueryInFlight(queryInFlight);
  }, [responseApprovePost?.loading, responseApprove?.loading, responseReject?.loading]);

  const toggleCategory = useCallback(
    (category) => {
      const collapsed = status === `open` ? openCollapsed : closeCollapsed;
      const setCollapsed = status === `open` ? setOpenCollapsed : setCloseCollapsed;
      const hasCategory = collapsed && collapsed.includes(category);
      if (hasCategory) {
        setCollapsed(collapsed.filter((item) => item !== category));
      } else {
        setCollapsed([...collapsed, category]);
      }
    },
    [openCollapsed, closeCollapsed, setOpenCollapsed, setCloseCollapsed, status],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => {
      const { title, count } = section;
      const collapsed = status === `open` ? openCollapsed : closeCollapsed;
      const isCollapsed = collapsed && collapsed.includes(title);
      return <SectionHeader title={title} count={count} collapsed={isCollapsed} toggleCategory={toggleCategory} />;
    },
    [openCollapsed, closeCollapsed, status, toggleCategory],
  );

  const renderModerationIssues = useCallback(
    ({ item, index, section: { title } }) => {
      if (item) {
        let actions = [];
        if (item.category === MODERATION_CATEGORY.community_join_request) {
          actions = [approveJoin, rejectJoin];
        } else if (item.category === MODERATION_CATEGORY.post_approve) {
          actions = [approvePost, null];
        }
        const collapsed = status === `open` ? openCollapsed : closeCollapsed;
        const isCollapsed = collapsed && collapsed.includes(title);
        return (
          <Collapsible collapsed={status === `open` ? isCollapsed : !isCollapsed}>
            <ModerationIssue
              navigation={navigation}
              group={group}
              modIssue={item}
              actions={actions}
              queryInFlight={queryInFlight}
              approvalIndex={index}
            />
          </Collapsible>
        );
      }
      console.warn(`issue undefined for ${title}`);
      return null;
    },
    [navigation, group, openCollapsed, closeCollapsed, approvePost, approveJoin, rejectJoin, queryInFlight, status],
  );

  const renderSectionFooter = useCallback(
    ({ section }) => {
      const { title, count } = section;
      const collapsed = status === `open` ? openCollapsed : closeCollapsed;
      const isCollapsed = collapsed && collapsed.includes(title);
      if (isCollapsed) return null;

      if (loadingIssues) {
        return (
          <View style={{ flex: 1, justifyContent: `center`, padding: 16 }}>
            <LoadingIndicator />
          </View>
        );
      }
      if (PAGESIZE < count && status === `open`) {
        const gotToModerationCategoryList = () => {
          navigation.push(`ModerationCategoryList`, { group, category: title, status });
        };
        return (
          <View style={{ flex: 1, padding: 16 }}>
            <ThemedButton rounded title="See more" onPress={gotToModerationCategoryList} />
          </View>
        );
      }
      return null;
    },
    [navigation, group, status, loadingIssues, openCollapsed, closeCollapsed],
  );

  const renderEmptyList = useCallback(() => {
    if (loadingIssues || loadingGroup) return <LoadingIndicator size={28} style={{ padding: 32 }} />;
    return (
      <View style={{ flex: 1, marginHorizontal: 33, marginTop: 110 }}>
        <Text style={localStyles.noIssuesTextHeader}>Nothing to moderate</Text>
        <Text style={localStyles.noIssuesSubtext}>You have no {status} moderation reports at this time.</Text>
      </View>
    );
  }, [loadingIssues, loadingGroup, status]);

  return (
    <SafeAreaView style={localStyles.container}>
      <View style={{ flexDirection: `row`, margin: 16 }}>
        <CommunityAvatar community={group} size={36} border />
        <View style={{ marginLeft: 8, marginRight: `35%` }}>
          <Text style={localStyles.groupNameText}>{group?.name?.trim() || `[deleted group]`}</Text>
          <Text style={{ ...Typography.text(`small`, `gray`) }}>{buildAdminModString}</Text>
        </View>
        <View
          style={{
            ...localStyles.openIssuesTagContainer,
            backgroundColor: count_open > 0 ? `#E9F4EC` : Colors.lightWarmGray,
          }}
        >
          <Text style={{ ...Typography.text(`small`, `bold`, `center`), color: count_open > 0 ? `#2C9A44` : Colors.textGray }}>
            {count_open} open item{count_open !== 1 ? `s` : ``}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: `row`, justifyContent: `space-evenly` }}>
        <TouchableOpacity onPress={() => setStatus(`open`)} style={localStyles.buttonContainer} activeOpacity={0.8}>
          <View style={{ ...localStyles.buttonStyle, borderBottomWidth: status === `open` ? 1 : 0 }}>
            <Text style={{ ...localStyles.buttonText, color: status === `open` ? Colors.brandPurple : Colors.iconColor }}>
              Open
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setStatus(`closed`)} style={localStyles.buttonContainer} activeOpacity={0.8}>
          <View style={{ ...localStyles.buttonStyle, borderBottomWidth: status === `closed` ? 1 : 0 }}>
            <Text style={{ ...localStyles.buttonText, color: status === `closed` ? Colors.brandPurple : Colors.iconColor }}>
              Closed
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      {sections && !error && (
        <SectionList
          sections={sections}
          renderSectionHeader={renderSectionHeader}
          renderItem={renderModerationIssues}
          keyExtractor={(item) => item.id}
          renderSectionFooter={renderSectionFooter}
          ListEmptyComponent={renderEmptyList}
          refreshing={loadingIssues === NetworkStatus.refetch}
          onRefresh={fullRefetch}
          style={{ paddingBottom: 80 }}
        />
      )}
    </SafeAreaView>
  );
};

ModerateCommunityDetail.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const SectionHeader = React.memo(({ title, count, collapsed, toggleCategory }) => {
  return (
    <View style={{ backgroundColor: Colors.white }}>
      <TouchableOpacity
        hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
        activeOpacity={0.8}
        onPress={() => toggleCategory(title)}
      >
        <View
          style={{
            width: `100%`,
            backgroundColor: Colors.backgroundColor,
            paddingVertical: 6,
            justifyContent: `space-between`,
            flexDirection: `row`,
            paddingHorizontal: 10,
          }}
        >
          <Text style={{ ...Typography.base, color: Colors.textGray, fontWeight: `500` }}>
            {sectionTitles[title]} ({count})
          </Text>

          <MeshIcon name={!collapsed ? `chevron-down` : `chevron-up`} color="#373232" size={15} />
        </View>
      </TouchableOpacity>
    </View>
  );
});

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  collapsed: PropTypes.bool,
  toggleCategory: PropTypes.func.isRequired,
};
SectionHeader.defaultProps = {
  collapsed: false,
};

const localStyles = {
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  groupNameText: {
    ...Typography.text(`base`, `black`, `bold`),
    flexWrap: `wrap`,
    flexShrink: 1,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: `center`,
  },
  buttonStyle: {
    borderBottomColor: Colors.brandPurple,
    padding: 10,
  },
  buttonText: {
    fontSize: Typography.baseFontSize,
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    textAlign: `center`,
  },
  noIssuesTextHeader: {
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    fontSize: 15,
    color: Colors.textBlack,
    textAlign: `center`,
  },
  noIssuesSubtext: {
    fontFamily: `inter-regular`,
    fontSize: 15,
    color: Colors.textBlack,
    lineHeight: 19,
    textAlign: `center`,
    marginTop: 16,
  },
  openIssuesTagContainer: {
    position: `absolute`,
    right: 0,
    padding: 6,
    borderRadius: 99,
    alignItems: `center`,
    justifyContent: `center`,
  },
};
