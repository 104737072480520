import { View, Text, StyleSheet, FlatList, ListRenderItem } from 'react-native';
import React, { useContext } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useQuery } from '@apollo/react-hooks';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';
import { GroupCardRounded } from '../common-ui/GroupCardRounded';
import { Group, ListedPostableGroups } from '../common-types/types';
import { LIST_POSTABLE_GROUPS } from '../graphql/Queries.gql';
import { AppContext } from '../../AppContext';
import { EmptyList } from '../common-ui';
import { Colors } from '../common-styles';

type PostEditorRoute = RouteProp<HomeNavigatorScreens, `PostCommunity`>;

export const PostCommunity: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigation = useNavigation<HomeNavigator>();
  const route = useRoute<PostEditorRoute>();

  //TODO: server side order by recency and filter by postable
  const { data, loading, error } = useQuery<ListedPostableGroups>(LIST_POSTABLE_GROUPS, {
    //HACK: send current user id to invalidate app-side query cache between persona switches
    variables: { persona_id: user?.id },
    onError: (error) => console.error(`[CommunitiesError]`, error.message),
    skip: !user?.id,
  });

  const selectCommunty = (group: Group) => {
    navigation.push(`PostEditor`, {
      mode: `create`,
      group,
      data: route?.params,
    });
  };

  const renderItem: ListRenderItem<Group> = ({ item }) => {
    return <GroupCardRounded group={item} handleArrowPress={selectCommunty} handleCardPress={selectCommunty} />;
  };

  const keyExtactor = (item: Group) => item.id;

  return (
    <FlatList<Group>
      style={styles.container}
      ListHeaderComponent={
        <View style={styles.optionContent}>
          <Text style={styles.optionText}>Choose a community where to post:</Text>
        </View>
      }
      stickyHeaderIndices={[0]}
      data={data?.listJoinedGroups.map((g) => g.group) || []}
      renderItem={renderItem}
      keyExtractor={keyExtactor}
      ListEmptyComponent={<EmptyList isLoading={loading} message={error ? error.message : `No communities found to share`} />}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  optionContent: {
    backgroundColor: Colors.white,
    borderBottomWidth: 1,
    borderBottomColor: Colors.dividerColor,
    padding: 20,
  },
  optionText: {
    fontSize: 15,
    fontWeight: `600`,
    textAlign: `center`,
  },
});
