import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Colors } from '../../common-styles';

const MemeActionButtonContainer: React.FC<{ onPress: any; children: any; selected?: boolean; isBlob?: boolean }> = ({
  onPress,
  children,
  selected,
  isBlob,
}) => {
  return (
    <TouchableOpacity
      style={{
        width: 36,
        height: 36,
        borderRadius: 18,
        borderWidth: 1,
        borderColor: selected ? Colors.deepPurple : `#e8e9ff`,
        backgroundColor: isBlob ? `#fff` : `#e8e9ff`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        padding: 2,
        margin: 5,
      }}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  );
};

export default MemeActionButtonContainer;
