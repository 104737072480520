import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { SectionList, Text, View } from 'react-native';
import { useLazyQuery } from 'react-apollo';
import { LoadingIndicator, QueryError } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { SelectEditor } from './SelectEditor';
import { AppContext } from '../../AppContext';
import { useSettings } from './hooks/useSettings';
import { SETTING_OPTIONS } from '../graphql';

import { Colors, Typography, globalStyles } from '../common-styles';
import { SettingRender } from './SettingsList';

const sectionTitles = [`User`, `Profile`, `Monetization`, `Privacy`, `Moderation`, `others`];

export const SettingManager = ({ navigation, route, mode, ListHeaderComponent, ListFooterComponent }) => {
  // Gets group_id if deeplinked, group obj otherwise
  const { group, group_id, group_member, openCodeOfConduct } = route?.params || {};
  const { user } = useContext(AppContext);
  const { settings, verified, saveSetting, loading, error, networkStatus, refetch } = useSettings(mode, {
    user_id: mode === `user` ? user?.id : undefined,
    group_id: mode === `group` ? group?.id || group_id : undefined,
  });

  const [selectedSetting, setSelectedSetting] = useState();

  const [loadOptions, { data, loading: _loadingOptions }] = useLazyQuery(SETTING_OPTIONS, {
    variables: { settingQuery: { query: `group` } },
  });

  useEffect(() => {
    if (mode === `group`) loadOptions();
  }, [mode, loadOptions]);

  const renderSectionHeader = ({ section }) => {
    const { id, title } = section || {};
    if (id === `User`) return null;
    return (
      <View style={{ flex: 1, flexDirection: `column`, backgroundColor: Colors.lightGray }}>
        <Text style={{ marginHorizontal: 16, marginVertical: 8, ...Typography.text(`gray`, `bold`) }}>{title}</Text>
      </View>
    );
  };

  const sections = useMemo(() => {
    if (mode === `user`)
      return [
        {
          id: `User`,
          title: `User`,
          data: Object.values(settings),
        },
      ];
    const baseSections = sectionTitles.map((section) => ({
      id: section,
      title: section,
      data: [],
    }));
    if (Object.keys(settings || {}).length) {
      Object.values(settings)
        .filter((s) => s.key !== `group.setting.notify_new_node`)
        .sort((s1, s2) => s1.index - s2.index)
        .forEach((setting) => {
          if (setting.section) {
            let safeIndex = 0;
            if (setting.section < 5) {
              if (setting.section === 1 && setting.type === `bank_account` && group_member?.role_name === `owner`) {
                safeIndex = 2;
              } else if (setting.section > 1 && setting.section < 4) safeIndex = setting.section + 1;
              else safeIndex = setting.section;
            } else safeIndex = 4;
            baseSections[safeIndex].data.push(setting);
          } else {
            baseSections[0].data.push(setting);
          }
        });
      return baseSections.filter((s) => !!s.data.length);
    }
    return [];
  }, [settings, mode, group_member]);

  const renderSettingItem = ({ item: setting }) => {
    return (
      <SettingRender
        navigation={navigation}
        setting={setting}
        verified={verified}
        setSelectedSetting={setSelectedSetting}
        saveSetting={saveSetting}
        openCodeOfConduct={openCodeOfConduct}
        group_member={group_member}
        group={group}
      />
    );
  };

  const renderEmpty = () => (
    <View style={{ flex: 1, justifyContent: `center`, alignItems: `center`, padding: 16 }}>
      <Text>No editable setttings</Text>
    </View>
  );

  if (error)
    return (
      <View style={{ flex: 1 }}>
        <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />
      </View>
    );

  return (
    <View style={globalStyles.settingWrapper}>
      {(loading || !sections.length) && (
        <View style={globalStyles.absoluteContainer}>
          <LoadingIndicator size="small" />
          <Text style={globalStyles.loadingText}>Loading</Text>
        </View>
      )}

      <SectionList
        sections={sections}
        testID="SETTINGS_LIST"
        keyExtractor={(item) => item.key}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={renderSectionHeader}
        renderItem={renderSettingItem}
        ListHeaderComponent={ListHeaderComponent}
        ListEmptyComponent={renderEmpty}
        ListFooterComponent={ListFooterComponent}
        refreshing={loading}
        onRefresh={() => refetch()}
        extraData={loading}
      />

      {selectedSetting && (
        <SelectEditor
          setting={selectedSetting}
          optionsData={data?.getSettingOptions}
          onSave={saveSetting}
          onCancel={() => setSelectedSetting()}
        />
      )}
    </View>
  );
};

SettingManager.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
  mode: PropTypes.string.isRequired,
  ListHeaderComponent: PropTypes.element,
  ListFooterComponent: PropTypes.element,
};

SettingManager.defaultProps = {
  ListHeaderComponent: undefined,
  ListFooterComponent: undefined,
};
