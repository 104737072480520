import React from 'react';
import { View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheetModal, NoThemeButton } from '../common-ui';
import { setModal, PostState } from '../redux/postSlice';
import { formatDate } from '../common-util';
import * as PostStyles from './PostStyles';
import { Group, GroupMember } from '../common-types/types';
import { setPreviewCommunity } from '../redux/feedSlice';

type ModalTemplates = {
  header: string;
  body?: string;
  button?: string;
};

type PostModalProps = {
  options?: any;
  // eslint-disable-next-line no-unused-vars
  setOptions: (options?: any[]) => void;
  group: Group;
  group_member?: GroupMember;
};

export const PostModal = ({ options, setOptions, group, group_member }: PostModalProps) => {
  const { modal, is_member, banned, ban_expiration } = useSelector<any, PostState>((state) => state.post);

  const navigation = useNavigation<any>();
  const dispatch = useDispatch();

  const handleOnPress = () => {
    dispatch(setModal(undefined));
    const needsToNavigate = [`needsToAgree`, `join`, `codeofConduct`];
    if (modal && needsToNavigate.includes(modal)) {
      if (is_member && !banned) {
        // The user is already a member and just needs to re-agree the code of conduct`
        navigation.navigate(`CommunityCodeOfConduct`, { group, group_member, toAgree: false });
      } else if (group?.application) {
        // The group has an application, guidelines managed by it
        navigation.navigate(`CommunityApplication`, { group });
      } else if (group?.guidelines && group.guidelines !== `[]`) {
        // The user is not a group member -- the group does not have application questions but it has guidelines
        navigation.navigate(`CommunityCodeOfConduct`, { group });
      } else if (modal === `codeOfConduct`) {
        navigation.navigate(`CommunityCodeOfConduct`, { group });
      } else {
        // Join inmediately
        dispatch(setPreviewCommunity(group));
        navigation.navigate(`CommunityPreview`, { triggerDecideJoinMethod: true });
      }
    }
  };

  const closeBottomModal = () => {
    dispatch(setModal(undefined));
    if (options) setOptions(undefined);
  };

  const parseModalTitle = (text?: string): string => {
    if (!text) return ``;
    if (banned) {
      const ban_date = ban_expiration || Date.now();
      const isPermanent = new Date(ban_date).getFullYear() > 9000;
      return text.replace(`:title`, isPermanent ? `Permanently` : `Temporarily`);
    }
    return ``;
  };

  const parseModalBody = (text?: string): string => {
    if (!text) return ``;
    const { name = `` } = group || {};
    if (banned) {
      const ban_date = ban_expiration || Date.now();
      const isPermanent = new Date(ban_date).getFullYear() > 9000;
      const expire_date = formatDate(new Date(ban_date), `MMM dd`);
      const expire_time = formatDate(new Date(ban_date), `h:mm TT`);
      return text
        .replace(`:period`, isPermanent ? `permanently` : `temporarily`)
        .replace(`:name`, name)
        .replace(`:expire_date`, isPermanent ? `` : ` until ${expire_date}`)
        .replace(`:expire_time`, isPermanent ? `` : `at ${expire_time}`);
    }
    return text.replace(`:name`, name);
  };

  if (!modal) return null;
  const template: ModalTemplates = templates[modal];

  return (
    <BottomSheetModal
      visible
      title={options?.length ? 'Post options' : parseModalTitle(template.header)}
      onPressCancel={closeBottomModal}
      options={options}
      showCancelBtn={!template.button}
      showConfirmBtn={options?.length ? true : false}
      confirmTitle={'Close'}
      onPressConfirm={closeBottomModal}
    >
      <View>
        {!!template.body && <Text style={PostStyles.postModalBodyText}>{parseModalBody(template.body)}</Text>}
        {!!template.button && (
          <NoThemeButton
            testID="ALERT_ACTION_BTN"
            title={template?.button}
            titleStyle={PostStyles.postModalButtonText}
            onPress={handleOnPress}
            buttonStyle={PostStyles.postModalButton}
            containerStyle={PostStyles.postModalButtonContainer}
          />
        )}
      </View>
    </BottomSheetModal>
  );
};

PostModal.defaultProps = {
  options: [],
  group_member: undefined,
};

const templates: { [key: string]: ModalTemplates } = {
  banned: {
    header: `:title Restricted`,
    body: `You've been :period banned from interacting in :name:expire_date :expire_time. You can still see all the posts, but will not be able to like or comment on them.`,
    button: `Got it`,
  },
  pending: {
    header: `Your membership request is pending`,
    body: `Sit tight. You can only participate in this node after your membership is approved.`,
    button: `Got it`,
  },
  needsToAgree: {
    header: `Updated Code of Conduct`,
    body: `:name has updated their Code of Conduct. You must review and accept the updated Code of Conduct before you can interact in the node.`,
    button: `Review the Code of Conduct`,
  },
  join: {
    header: `Not so fast`,
    body: `On Mesh, you need to be a member of a node to post or interact. Ready to join the conversation?`,
    button: `Join`,
  },
  codeOfConduct: {
    header: `Not so fast`,
    body: `:name has updated their code of conduct. You must agree to the new terms before participating.`,
    button: `View code of conduct`,
  },
  closedComments: {
    header: `Comments closed`,
    body: `You cannot comment on this post because comments were closed by moderators.`,
    button: `Got it`,
  },
  frozen: {
    header: `Not so fast`,
    body: `Your account has been frozen for this node and you cannot interact with content for 24 hours.`,
    button: `Got it`,
  },
  managePost: {
    header: `Manage Post`,
  },
  manageComment: {
    header: `Manage`,
  },
  default: {
    header: ``,
  },
};
