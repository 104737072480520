import { StyleSheet, TextStyle } from 'react-native';
import * as Colors from './colors';

type TextAlign = `left` | `right` | `center` | `auto` | undefined;
const textStyles = {
  default: { fontFamily: `inter-regular`, fontSize: 14, lineHeight: 18, letterSpacing: -0.14, color: Colors.textBlack },

  miniscule: { fontSize: 9, lineHeight: 11 },
  smallest: { fontSize: 10, lineHeight: 13 },
  smaller: { fontSize: 11, lineHeight: 14 },
  small: { fontSize: 12, lineHeight: 15 },
  base: { fontSize: 14, lineHeight: 18 },
  plusone: { fontSize: 15, lineHeight: 19 },
  plustwo: { fontSize: 16, lineHeight: 20 },
  large: { fontSize: 22, lineHeight: 26 },
  larger: { fontSize: 28, lineHeight: 32 },
  largest: { fontSize: 32, lineHeight: 36 },
  huge: { fontSize: 44, lineHeight: 54 },

  button: { fontSize: 18, lineHeight: 18 },
  header: { fontFamily: `inter-semibold`, fontSize: 16, lineHeight: 20 },
  reaction: { fontFamily: `inter-semibold`, fontSize: 12, lineHeight: 15, color: Colors.iconColor },

  bold: { fontFamily: `inter-semibold` },
  plain: { fontFamily: `inter-regular` },

  gray: { color: Colors.textGray },
  darkGray: { color: Colors.darkGray },
  white: { color: Colors.textWhite },
  black: { color: Colors.textBlack },
  red: { color: Colors.textRed },
  orange: { color: Colors.orange },
  placeholder: { color: Colors.textPlaceholder },
  disabled: { color: Colors.textDisabled },
  theme: { color: Colors.brandPurple },
  link: { color: Colors.linkColor },
  focused: { color: Colors.focusedIconColor },
  icon: { color: Colors.iconColor },
  warning: { color: Colors.warningText },
  alert: { color: Colors.alertColor },
  paymentTransparentGray: { color: Colors.paymentTransparentGray },
  uncolored: { color: Colors.transparent },

  flex0: { flex: 0 },
  flex1: { flex: 1 },
  left: { textAlign: `left` as TextAlign },
  center: { textAlign: `center` as TextAlign },
  right: { textAlign: `right` as TextAlign },
};

type MeshTextStyleKey = keyof typeof textStyles;

const compose = (base: TextStyle, ...args: MeshTextStyleKey[]) => {
  let style = base;
  args.forEach((arg) => {
    if (arg) {
      const add = textStyles[arg];
      if (!add) throw new TypeError(`unexpected argument to Typography.text: ${arg}`);
      style = StyleSheet.flatten([style, add]);
    }
  });
  // filter out any explicitly null values
  return Object.entries(style).reduce((result, [k, v]) => (v === null ? result : { ...result, [k]: v }), {});
};
export const text = (...args: MeshTextStyleKey[]): TextStyle => {
  return compose(textStyles.default, ...args);
};

export const add = (...args: MeshTextStyleKey[]) => {
  return compose({}, ...args);
};

/** @deprecated */
export const extraLargeFontSize = text(`largest`).fontSize;
/** @deprecated */
export const largerFontSize = text(`larger`).fontSize;
/** @deprecated */
export const largeFontSize = text(`large`).fontSize;
/** @deprecated */
export const headerFontSize = text(`header`).fontSize;
/** @deprecated */
export const buttonFontSize = text(`button`).fontSize;
/** @deprecated */
export const baseFontSize = text(`base`).fontSize;
/** @deprecated */
export const smallFontSize = text(`small`).fontSize;
/** @deprecated */
export const extraSmallFontSize = text(`smaller`).fontSize;
/** @deprecated */
export const extraXSmallFontSize = text(`smallest`).fontSize;

/** @deprecated */
export const sixteen = 16;
/** @deprecated */
export const largeHeaderFontSize = 20;

/** @deprecated */
export const lightBold = `600`;
/** @deprecated */
export const mediumBoldFontWeight = `700`;

/** @deprecated */
export const buttonStyle = { fontSize: text(`button`).fontSize };

/** @deprecated */
export const h1Style = {
  color: Colors.textBlack,
  fontSize: extraLargeFontSize,
};

/** @deprecated */
export const h2StyleNoSpace = {
  ...text(`large`, `bold`),
  color: Colors.darkGray,
};

/** @deprecated */
export const h2Style = {
  ...text(`large`, `bold`),
  marginHorizontal: 10,
  marginBottom: largeFontSize ? largeFontSize / 8 : 0,
};

/** @deprecated */
export const boldHeaderStyle = {
  fontFamily: `inter-semibold`,
  fontSize: headerFontSize,
  lineHeight: 20,
  letterSpacing: -0.13,
  color: `#373232`,
};

/** @deprecated */
export const boldText = {
  fontFamily: `inter-semibold`,
  fontSize: baseFontSize,
  lineHeight: 18,
  color: Colors.textBlack,
};

/** @deprecated */
export const plainText = {
  fontFamily: `inter-regular`,
  fontSize: baseFontSize,
  lineHeight: 18,
  color: Colors.textBlack,
};

/** @deprecated */
export const smallBoldText = {
  fontFamily: `inter-regular`,
  fontSize: smallFontSize,
  lineHeight: 16,
  color: Colors.textBlack,
};

/** @deprecated */
export const smallText = {
  fontFamily: `inter-regular`,
  fontSize: smallFontSize,
  lineHeight: 16,
  color: Colors.textBlack,
};

/** @deprecated */
export const disabledBoldHeaderStyle = {
  ...boldHeaderStyle,
  color: Colors.lightGray,
};

/** @deprecated */
export const lightHeaderStyle = {
  color: Colors.mediumGray,
  fontSize: headerFontSize,
};
