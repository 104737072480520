import Constants from 'expo-constants';
import { client, DELETE_PUSH_TOKEN, refetchQueriesFor } from '../graphql';

export const disablePush = async (pushToken, _turnOffInSettings = false) => {
  try {
    if (!Constants.isDevice) return false;
    const { data } = await client.mutate({
      mutation: DELETE_PUSH_TOKEN,
      variables: { push_token: pushToken },
      refetchQueries: refetchQueriesFor(`Persona`),
    });
    console.log(`---- disablePush mutation result `, data);
  } catch (err) {
    console.log(`disabled push failed `, err);
  }
  return null;
};
