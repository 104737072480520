/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */

// see https://github.com/tableflip/react-native-select-multiple for prop values

import PropTypes from 'prop-types';
import React from 'react';
import { FlatList, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { Colors } from '../common-styles';
import { Divider } from './Divider';
import { MeshIcon } from './MeshIcon';
import { mergeStyles } from './mergeStyles';
import { SCROLL_MULTIPLE_OPTIONS } from './testLabels';

const localStyles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: `row`,
    alignItems: `center`,
    paddingRight: 12,
    backgroundColor: Colors.white,
  },
  label: {},
});

const itemType = PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ label: PropTypes.any, id: PropTypes.any })]);

const styleType = PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.array]);

// A customiseable FlatList that allows you to select multiple rows
export class SelectMultiple extends React.Component {
  items = undefined;

  selectedItems = undefined;

  rowData = [];

  static propTypes = {
    items: PropTypes.arrayOf(itemType).isRequired,
    selectedItems: PropTypes.arrayOf(itemType),
    maxSelect: PropTypes.number,
    onSelectionsChange: PropTypes.func.isRequired,
    keyExtractor: PropTypes.func,
    checkboxSource: PropTypes.element,
    selectedCheckboxSource: PropTypes.element,
    renderLabel: PropTypes.func,
    flatListProps: PropTypes.any,
    style: styleType,
    rowStyle: styleType,
    checkboxStyle: styleType,
    labelStyle: styleType,
    selectedRowStyle: styleType,
    selectedCheckboxStyle: styleType,
    selectedLabelStyle: styleType,
    testID: PropTypes.string,
  };

  static defaultProps = {
    selectedItems: [],
    style: {},
    rowStyle: {},
    checkboxStyle: {},
    labelStyle: {},
    maxSelect: null,
    checkboxSource: <MeshIcon name="checkbox-off" color={Colors.iconColor} />,
    selectedCheckboxSource: <MeshIcon name="checkbox-on" color={Colors.iconColor} />,
    renderLabel: null,
    keyExtractor: undefined,
    testID: SCROLL_MULTIPLE_OPTIONS,
  };

  getRowData(items, selectedItems) {
    if (items !== this.items || selectedItems !== this.selectedItems) {
      this.items = items;
      this.selectedItems = selectedItems;
      this.rowData = items.map(this.toLabelValueObject);
      selectedItems = (selectedItems || []).map(this.toLabelValueObject);

      this.rowData.forEach((item) => {
        item.selected = selectedItems.some((i) => i.id === item.id);
      });
    }

    return this.rowData;
  }

  onRowPress = (row) => {
    const { label, id } = row;
    let { selectedItems } = this.props;
    const { maxSelect, onSelectionsChange } = this.props;

    selectedItems = (selectedItems || []).map(this.toLabelValueObject);

    const index = selectedItems.findIndex((selectedItem) => selectedItem.id === id);

    if (index > -1) {
      selectedItems = selectedItems.filter((selectedItem) => selectedItem.id !== id);
    } else {
      if (maxSelect !== null && selectedItems.length >= maxSelect) {
        return;
      }
      selectedItems = selectedItems.concat({ label, id });
    }
    onSelectionsChange(selectedItems, { label, id });
  };

  toLabelValueObject = (obj) => {
    if (Object.prototype.toString.call(obj) === `[object String]`) {
      return { label: obj, id: obj, body: obj.body };
    }
    return {
      label: obj.label ? obj.label : obj.value,
      id: obj.id,
      body: obj.body ? obj.body : obj.value,
    };
  };

  keyExtractor = (item, index) => index.toString();

  renderLabel = (label, style, selected, item) => {
    const { renderLabel } = this.props;
    if (renderLabel) {
      return renderLabel(label, style, selected, item);
    }
    return <Text style={style}>{label}</Text>;
  };

  renderItemRow = (row) => {
    let { checkboxSource, labelStyle } = this.props;
    const { selectedCheckboxSource, selectedLabelStyle } = this.props;

    if (row.item.selected) {
      checkboxSource = selectedCheckboxSource;
      labelStyle = mergeStyles([localStyles.label, labelStyle, selectedLabelStyle]);
    } else {
      labelStyle = mergeStyles([localStyles.label, labelStyle]);
    }

    //FIXME: pass checkbox (or onpress) through renderlabel
    //       get rid of touchableWithoutFeedback for our ListItem
    return (
      <TouchableWithoutFeedback onPress={() => this.onRowPress(row.item)} style={{ flex: 1 }}>
        <View style={localStyles.row}>
          {this.renderLabel(row.item.label, labelStyle, row.item.selected, row.item)}
          {checkboxSource}
        </View>
      </TouchableWithoutFeedback>
    );
  };

  render = () => {
    const { style, flatListProps, keyExtractor, items, selectedItems, testID } = this.props;
    const dataSource = this.getRowData(items, selectedItems);
    return (
      <FlatList
        testID={testID}
        keyboardShouldPersistTaps="handled"
        style={style}
        keyExtractor={keyExtractor || this.keyExtractor}
        data={dataSource}
        renderItem={this.renderItemRow}
        ItemSeparatorComponent={Divider}
        {...flatListProps}
      />
    );
  };
}
