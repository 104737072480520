import PropTypes from 'prop-types';
import { Dimensions, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-elements'; // FIXME: stop using react-native-elements
import React from 'react';
import { withTheme } from '../themes/ThemeProvider';
import { Colors, Typography } from '../common-styles';
import { Divider } from './Divider';
import { CloseButton } from './CloseButton';
import { KeyboardPaddingView } from './KeyboardPaddingView';
import { MeshIcon } from './MeshIcon';
import { MenuModal } from './MenuModal';

/*
const testGuidelines = [
  {
    id: `1`,
    title: `Example One`,
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip err ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    id: `2`,
    title: `Example Two`,
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip err ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    id: `3`,
    title: `Example Three`,
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip err ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    id: `4`,
    title: `Example Four`,
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip err ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    id: `5`,
    title: `Example Five`,
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip err ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    id: `6`,
    title: `Example Six`,
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip err ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
];
export class TestGuidlines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guidelines: testGuidelines,
      guideItem: {},
      title: ``,
      body: ``,
    };
  }

  _editItem = ({ id, title, body }) => {
    this.setState((prev) => {
      return {
        guideItem: { id, title, body },
        title,
        body,
      };
    });
  };

  _removeItem = (id) => {
    Alert.alert(
      `Are you sure you want to delete this item?`,
      ``,
      [
        {
          text: `Cancel`,
          style: `cancel`,
          onPress: () =>
            this.setState({
              createModal: false,
              guideItem: {},
              title: ``,
              body: ``,
            }),
        },
        {
          text: `OK`,
          onPress: () => {
            this.setState((prev) => {
              return {
                guidelines: [...prev.guidelines.filter((item) => item.id !== id)],
                createModal: false,
                guideItem: {},
                title: ``,
                body: ``,
              };
            });
          },
        },
      ],
      { cancelable: true },
    );
  };

  _renderItem = ({ item, index, move, moveEnd, isActive }) => {
    return (
      <GuideItem
        guideline={item}
        guidelines={this.state.guidelines}
        removeItem={this._removeItem}
        editItem={this._editItem}
        move={move}
        moveEnd={moveEnd}
      />
    );
  };

  render = () => {
    return (
      <View style={{ flex: 1 }}>
        <Text>Testing guidelines</Text>
        <DraggableFlatList
          data={this.state.guidelines.length > 0 ? this.state.guidelines : []}
          keyExtractor={(item, index) => item + index.toString()}
          renderItem={this._renderItem}
          scrollPercent={5}
          onMoveEnd={({ data }) => this.setState({ guidelines: data })}
        />
      </View>
    );
  };
}
*/

const hitSlop = { left: 10, right: 10, top: 10, bottom: 10 };

class _GuideItem extends React.PureComponent {
  _renderGuideItems = (rearrange, editMode) => {
    const { guideline, guidelines, editItem, move, moveEnd, theme } = this.props;

    const guideItemStyles = StyleSheet.create({
      editDeleteText: {
        fontSize: 14,
        color: theme.primary,
        alignSelf: `flex-end`,
        marginHorizontal: 2,
        marginVertical: 8,
      },
    });

    if (rearrange === true) {
      // rearrange using draggableFlatlist
      return (
        <TouchableOpacity delayLongPress={500} onLongPress={move} onPressOut={moveEnd} activeOpacity={0.8} hitSlop={hitSlop}>
          <View style={guidelineStyles.guideItemTopContainer}>
            <MeshIcon name="three-bars" size={26} color={theme.primary} style={{ marginTop: 6 }} />
            <View style={guidelineStyles.guidelinesContainer}>
              <Text style={guidelineStyles.itemContainer}>
                <Text style={guidelineStyles.itemNumber}>
                  {guidelines.indexOf(guideline) + 1}.{` `}
                </Text>
                <Text style={guidelineStyles.titleText}>
                  {guideline.title}
                  {`\n`}
                </Text>
                <Text style={guidelineStyles.itemText}> {guideline.body}</Text>
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    }
    return (
      <View style={guidelineStyles.guideItemTopContainer}>
        <View style={guidelineStyles.guidelinesContainer}>
          <Text style={guidelineStyles.itemContainer}>
            <Text style={guidelineStyles.itemNumber}>
              {guidelines.indexOf(guideline) + 1}.{` `}
            </Text>
            <Text style={guidelineStyles.titleText}>
              {guideline.title}
              {`\n`}
            </Text>
            <Text style={guidelineStyles.itemText}> {guideline.body}</Text>
          </Text>
          {editMode && (
            <View style={guidelineStyles.editDeleteContainer}>
              <TouchableOpacity onPress={() => editItem(guideline)} hitSlop={hitSlop}>
                <View>
                  <Text style={guideItemStyles.editDeleteText}>EDIT</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    );
  };

  render = () => {
    const { rearrange, editMode } = this.props;
    return this._renderGuideItems(rearrange, editMode);
  };
}
_GuideItem.propTypes = {
  guidelines: PropTypes.arrayOf(PropTypes.object).isRequired,
  guideline: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
  editItem: PropTypes.func.isRequired,
  rearrange: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  move: PropTypes.func.isRequired,
  moveEnd: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};
export const GuideItem = withTheme(_GuideItem);

const _AddGuidelineButton = ({ toggle, ...props }) => {
  const { theme } = props;
  const style = StyleSheet.create({
    addGuidelineTouchable: {
      flex: 1,
      alignItems: `center`,
      alignSelf: `flex-start`,
      marginHorizontal: 20,
      backgroundColor: theme.primary,
      paddingHorizontal: 10,
      borderRadius: 25,
      marginVertical: 20,
    },
  });
  return (
    <TouchableOpacity style={style.addGuidelineTouchable} onPress={toggle} hitSlop={hitSlop}>
      <View style={guidelineStyles.addGuidelineBtnContainer}>
        <MeshIcon name="plus" color="white" size={24} style={{ marginTop: 2 }} />
        <Text style={guidelineStyles.addBtnText}>Create a new guideline</Text>
      </View>
    </TouchableOpacity>
  );
};
_AddGuidelineButton.propTypes = {
  toggle: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};
export const AddGuidelineButton = withTheme(_AddGuidelineButton);

export class _AddGuidelineModal extends React.PureComponent {
  render = () => {
    const { isVisible, toggleModal, addItem, setTitle, setBody, guideItem, body, title, theme } = this.props;

    const addStyles = StyleSheet.create({
      addText: {
        color: theme.primary,
        fontWeight: `bold`,
        fontSize: Typography.baseFontSize,
        alignSelf: `flex-end`,
      },
    });
    return (
      <MenuModal isVisible={isVisible} toggleModal={toggleModal}>
        <View style={guidelineStyles.modalContent}>
          <View style={guidelineStyles.modalHeaderContainer}>
            <Text style={{ ...Typography.h2StyleNoSpace }}>Create a guideline:</Text>
            <CloseButton promptClose={toggleModal} />
          </View>
          <KeyboardPaddingView>
            <View style={{ flex: 1 }}>
              <View style={guidelineStyles.titleContainer}>
                <TextInput
                  onChangeText={(title) => setTitle(title)}
                  ref={(input) => (this.title = input)}
                  placeholder="Guideline title"
                  placeholderTextColor={Colors.textPlaceholder}
                  autoCapitalize="words"
                  value={title}
                  multiline
                  scrollEnabled
                  style={guidelineStyles.titleInputText}
                  autoFocus
                />
                {!!body && !!title ? (
                  <TouchableOpacity
                    style={{ marginLeft: 10 }}
                    onPress={() => {
                      if (!guideItem.id) {
                        // console.log(`!guideItem.id`);
                        const guideline = {
                          id: undefined,
                          title,
                          body,
                        };
                        addItem(guideline);
                        this.title.clear();
                        this.body.clear();
                      } else {
                        // console.log(`guideItem exists`, guideItem);
                        const guideline = {
                          id: guideItem.id,
                          title,
                          body,
                        };
                        addItem(guideline);
                        this.title.clear();
                        this.body.clear();
                      }
                    }}
                    hitSlop={hitSlop}
                  >
                    <View style={guidelineStyles.addTextContainer}>
                      <Text style={addStyles.addText}>{guideItem && guideItem.id ? `SAVE` : `ADD`}</Text>
                    </View>
                  </TouchableOpacity>
                ) : null}
              </View>
              <Divider />
              <View style={{ flex: 1, marginRight: 15 }}>
                <TextInput
                  onChangeText={(body) => setBody(body)}
                  ref={(input) => (this.body = input)}
                  value={body}
                  placeholder="Guideline description"
                  placeholderTextColor={Colors.textPlaceholder}
                  multiline
                  scrollable
                  style={{
                    fontSize: body.length > 120 ? Typography.baseFontSize : Typography.buttonFontSize,
                    minHeight: Dimensions.get(`window`).height / 3,
                  }}
                />
              </View>
            </View>
          </KeyboardPaddingView>
        </View>
      </MenuModal>
    );
  };
}
_AddGuidelineModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setBody: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  guideItem: PropTypes.object.isRequired,
};
export const AddGuidelineModal = withTheme(_AddGuidelineModal);

export const guidelineStyles = StyleSheet.create({
  guideItemTopContainer: {
    flex: 1,
    flexDirection: `row`,
    marginHorizontal: 4,
  },
  guidelinesContainer: {
    flex: 1,
    flexDirection: `row`,
    marginVertical: 5,
    padding: 5,
    borderRadius: 8,
    backgroundColor: Colors.lightWarmGray,
    borderColor: Colors.lightGray,
    alignItems: `center`,
    marginHorizontal: 3,
  },
  itemContainer: {
    flex: 1,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginHorizontal: 10,
    alignItems: `center`,
  },
  itemNumber: {
    fontSize: Typography.baseFontSize,
    fontWeight: `bold`,
  },
  itemText: {
    fontSize: Typography.baseFontSize,
  },
  addTextContainer: {
    flex: 1,
    justifyContent: `center`,
  },

  editDeleteContainer: {
    flex: 0,
    alignSelf: `flex-start`,
  },
  addGuidelineBtnContainer: {
    flex: 1,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
  },
  addBtnText: {
    fontSize: Typography.buttonFontSize,
    color: `white`,
    marginLeft: 2,
    fontWeight: Typography.lightBold,
  },
  titleContainer: {
    maxHeight: 100,
    flexDirection: `row`,
    justifyContent: `space-between`,
  },
  titleInputText: {
    flex: 1,
    fontSize: Typography.largeFontSize,
    marginBottom: 5,
  },
  titleText: {
    fontSize: Typography.buttonFontSize,
    fontWeight: `600`,
  },
  modalContent: {
    backgroundColor: `white`,
    height: Dimensions.get(`window`).height / 2,
    margin: 10,
    paddingBottom: 15,
  },
  modalHeaderContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    paddingBottom: 10,
  },
});
