import { Alert } from 'react-native';
import { checkChannelID, createConversation as createConversationService, requestMessage } from './chatServices';
import { formatDate } from '../common-util';

const PERSONA_RELATIONSHIP_PERMISSIONS = {
  photo_sharing_enabled: `photo_sharing_enabled`,
};

export const CHAT_CHANNEL_TYPES = {
  commerce: `commerce`,
  gaming: `gaming`,
  livestream: `livestream`,
  messaging: `messaging`,
  team: `team`,
};

export { PERSONA_RELATIONSHIP_PERMISSIONS };

/**
 * Creates a getStream conversation between 2 personas
 * @param {string} current_user_id uuid
 * @param {string} other_user_id uuid
 */
export const createConversation = async (current_user_id, other_user_id) => {
  if (!current_user_id) throw new Error(`currentUser is empty`);
  if (!other_user_id) throw new Error(`otherUser is empty`);
  const channelData = {
    members: [other_user_id, current_user_id],
  };
  let conversation;
  const channel_id = await checkChannelID(other_user_id);
  if (channel_id) {
    conversation = await createConversationService(CHAT_CHANNEL_TYPES.messaging, channel_id, channelData);
  } else if (channel_id === null) {
    const channelID = await requestMessage(other_user_id);
    if (channelID) conversation = await createConversationService(CHAT_CHANNEL_TYPES.messaging, channelID, channelData);
    else Alert.alert(`Error occured while creating message request`);
  } else {
    Alert.alert(`Error occured while checking channel ID`);
  }
  const members = conversation.state ? Object.values(conversation.state.members) : [];
  const otherMembers = members.filter((m) => m?.user_id !== current_user_id);

  return {
    conversation,
    otherMembers,
  };
};

export const isDateSeparator = (message) => message.type === `message.date`;

export const insertDates = (messages) => {
  const newMessages = [];
  if (messages.length === 0) {
    return newMessages;
  }

  for (const [i, message] of messages.entries()) {
    /**
     * If message read or deleted don't consider for date labels
     */
    if (message.type === `message.read` || message.deleted_at) {
      newMessages.push(message);
    } else {
      /**
       * Get the date of the current message and create
       * variable for previous date (day)
       */
      const messageDate = formatDate(message.created_at, `MMM dd yyyy`);

      /**
       * If this is not the last entry in the messages array
       * set the previous message date (day) to the date of the next
       * message in the array
       */
      const prevMessageDate = i < messages.length - 1 ? formatDate(messages[i + 1].created_at, `MMM dd yyyy`) : messageDate;

      /**
       * Before the first message insert a date object
       */
      if (i === 0) {
        newMessages.push({ date: message.created_at, type: `message.date` }, message);

        /**
         * If the date (day) has changed between two messages
         * insert a date object
         */
      } else if (messageDate !== prevMessageDate) {
        newMessages.push(message, { date: messages[i + 1].created_at, type: `message.date` });

        /**
         * Otherwise just add the message
         */
      } else {
        const index = newMessages.findIndex((msg) => msg.id === message.id);
        if (index >= 0) newMessages.splice(index);
        newMessages.push(message);
      }
    }
  }

  return newMessages;
};

export const getLastReceivedMessage = (messages) => {
  /**
   * There are no status on dates so they will be skipped
   */
  for (const message of messages) {
    if (message && !isDateSeparator(message) && message.status === `received`) {
      return message;
    }
  }

  return null;
};
