/* eslint-disable complexity */
import React from 'react';
import { Text, View, TouchableOpacity, TouchableHighlight } from 'react-native';
import PropTypes from 'prop-types';
import { Colors, Typography } from '../common-styles';
import { Avatar, CommunityAvatar, MeshIcon, Divider } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { notificationStyles } from './notificationItemStyles';
import MeshLogo from '../../assets/images/mesh-icon-white.svg';
import { showNotImplementedOptions } from './notificationsTempConstants';

const allowedBadges = [`check`, `warning`, `like`, `comment`, `plus`, `moderation`, `sparkle`];

const badgeProperties = {
  check: {
    icon: `check`,
    color: Colors.badgeCheck,
    backgroundColor: Colors.badgeCheckBackground,
    size: 18,
  },
  warning: {
    icon: `warning`,
    color: Colors.badgeWarning,
    backgroundColor: Colors.badgeWarningBackground,
    size: 18,
  },
  like: {
    icon: `heart`,
    color: Colors.badgeLike,
    backgroundColor: Colors.badgeLikeBackground,
    size: 15,
    focused: true,
  },
  comment: {
    icon: `join`,
    color: Colors.badgeComment,
    backgroundColor: Colors.badgeCommentBackground,
    size: 17,
  },
  plus: {
    icon: `plus`,
    color: Colors.badgePlus,
    backgroundColor: Colors.badgePlusBackground,
    size: 14,
  },
  moderation: {
    icon: `moderation`,
    color: Colors.badgeModeration,
    backgroundColor: Colors.badgeModerationBackground,
    size: 18,
  },
  sparkle: {
    icon: `sparkle`,
    color: Colors.badgeModeration,
    backgroundColor: Colors.badgeModerationBackground,
    size: 20,
    focused: true,
  },
};

export const BaseNotificationItem = ({
  avatarData,
  avatarType,
  avatarSize,
  navigation,
  badge,
  badgeSize,
  title,
  description,
  date,
  RightActions,
  showMoreOptionsButton,
  onPressOptionsButton,
  FooterComponent,
  onPress,
  containerStyle,
  follow_status,
  follow_verb,
  handleFollowRequest,
  can_follow_request_disable,
  hideActions,
  isSameStack,
  as_community = false,
}) => {
  const handlePress = () => {
    onPress();
  };
  return (
    <TouchableHighlight underlayColor={Colors.underlayColorNotification} onPress={handlePress}>
      <>
        <View
          style={{
            ...notificationStyles.containerStyle,
            ...containerStyle,
          }}
        >
          {!!avatarData && (
            <View style={notificationStyles.avatarContainer}>
              {avatarType === `persona` && !as_community && (
                <Avatar border navigation={navigation} user={avatarData} size={avatarSize} isSameStack={isSameStack} />
              )}
              {(avatarType === `community` || as_community) && (
                <CommunityAvatar border community={avatarData} size={avatarSize} onPress={onPress} />
              )}
              {avatarType === `mesh` && <MeshLogoAvatar border size={avatarSize} />}

              {!!badge && <Badge type={badge} badgeSize={badgeSize} />}
            </View>
          )}
          <View style={{ flex: 1 }}>
            {typeof title === `string` && title.length > 0 ? (
              <View>
                <Text maxFontSizeMultiplier={1.5} style={Typography.text()}>
                  {title}
                </Text>
              </View>
            ) : (
              title
            )}

            {(!!description || !!date || !!RightActions || !!FooterComponent) && (
              <View style={notificationStyles.description}>
                {typeof description === `string` && description.length > 0 ? (
                  <Text maxFontSizeMultiplier={1.5} style={Typography.text()}>
                    {description}
                  </Text>
                ) : (
                  description
                )}
                {(!!date || !!RightActions) && (
                  <View style={notificationStyles.dateContainer}>
                    {!!date && (
                      <Text maxFontSizeMultiplier={1.8} style={Typography.text(`gray`, `small`)}>
                        {date}
                      </Text>
                    )}
                    {!!RightActions && RightActions}
                  </View>
                )}
                {!!FooterComponent && FooterComponent}
              </View>
            )}
          </View>
          {!hideActions && follow_verb === `follow_request` && !follow_status && (
            <View>
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 2,
                  borderRadius: 20,
                  backgroundColor: Colors.deepPurple,
                  alignItems: `center`,
                  justifyContent: `center`,
                  maxHeight: 26,
                }}
                onPress={() => handleFollowRequest(true)}
              >
                <Text style={{ color: Colors.white, fontWeight: `bold` }}>Accept</Text>
              </TouchableOpacity>
              {can_follow_request_disable && (
                <TouchableOpacity
                  style={{
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    backgroundColor: Colors.white,
                    alignItems: `center`,
                    justifyContent: `center`,
                    maxHeight: 26,
                    borderWidth: 1,
                    borderColor: Colors.deepPurple,
                    marginTop: 8,
                  }}
                  onPress={() => handleFollowRequest(false)}
                >
                  <Text style={{ color: Colors.deepPurple, fontWeight: `bold` }}>Delete</Text>
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
        {!!showMoreOptionsButton && !!showNotImplementedOptions && (
          <TouchableOpacity style={notificationStyles.listItemRight} onPress={onPressOptionsButton}>
            <MeshIcon name="dots-three-vertical" size={20} color={Colors.iconColor} />
          </TouchableOpacity>
        )}

        <Divider />
      </>
    </TouchableHighlight>
  );
};

BaseNotificationItem.propTypes = {
  avatarData: PropTypes.oneOfType([commonPropTypes.persona(), commonPropTypes.group()]).isRequired,
  avatarType: PropTypes.oneOf([`persona`, `community`, `mesh`]),
  avatarSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  navigation: commonPropTypes.navigation().isRequired,
  badge: PropTypes.oneOf(allowedBadges),
  badgeSize: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  date: PropTypes.string,
  RightActions: PropTypes.node,
  showMoreOptionsButton: PropTypes.bool,
  onPressOptionsButton: PropTypes.func,
  FooterComponent: PropTypes.node,
  onPress: PropTypes.func,
  containerStyle: PropTypes.object,
  follow_status: PropTypes.bool,
  follow_verb: PropTypes.string,
  handleFollowRequest: PropTypes.func,
  can_follow_request_disable: PropTypes.bool,
  hideActions: PropTypes.bool,
  isSameStack: PropTypes.bool,
  as_community: PropTypes.bool,
};

BaseNotificationItem.defaultProps = {
  avatarType: `persona`,
  avatarSize: 50,
  badgeSize: 22,
  title: null,
  description: null,
  date: ``,
  RightActions: null,
  showMoreOptionsButton: false,
  onPressOptionsButton: () => {},
  FooterComponent: null,
  onPress: () => {},
  containerStyle: {},
  badge: undefined,
  follow_status: true,
  follow_verb: ``,
  // eslint-disable-next-line
  handleFollowRequest: (accepted) => {},
  can_follow_request_disable: false,
  hideActions: false,
  isSameStack: false,
  as_community: false,
};

const Badge = ({ type, badgeSize }) => {
  const properties = badgeProperties[type];
  if (!properties) {
    return null;
  }

  return (
    <View
      style={{
        ...notificationStyles.badge,
        backgroundColor: properties.backgroundColor,
        width: badgeSize,
        height: badgeSize,
      }}
    >
      <MeshIcon name={properties.icon} size={properties.size} color={properties.color} focused={properties.focused} />
    </View>
  );
};

Badge.propTypes = {
  type: PropTypes.oneOf(allowedBadges).isRequired,
  badgeSize: PropTypes.number,
};

Badge.defaultProps = {
  badgeSize: 27,
};

const MeshLogoAvatar = ({ size, containerStyle, border }) => {
  const logoSize = size * 0.6;
  const localStyle = border && {
    borderWidth: 1,
    borderColor: Colors.avatarBorderColor,
  };
  return (
    <View
      style={{
        justifyContent: `center`,
        alignItems: `center`,
        width: size,
        height: size,
        borderRadius: 999,
        backgroundColor: Colors.logoBackground,
        ...localStyle,
        ...containerStyle,
      }}
    >
      <MeshLogo width={logoSize} height={logoSize} />
    </View>
  );
};

MeshLogoAvatar.propTypes = {
  size: PropTypes.number,
  containerStyle: PropTypes.object,
  border: PropTypes.bool,
};

MeshLogoAvatar.defaultProps = {
  size: 56,
  containerStyle: {},
  border: false,
};
