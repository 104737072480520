import React, { memo, useCallback, useEffect, useContext, useState } from 'react';
import { View, Text, FlatList, ListRenderItem } from 'react-native';
import { unionBy } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { getRandomBlob, LoadingIndicator } from '../common-ui';
import { Typography, Colors } from '../common-styles';
import { client, COMMUNITY_SETTING, GET_GROUPS_BY_NAME } from '../graphql';
import { AppContext } from '../../AppContext';
import { setPreviewCommunity, setForceExplorerRefresh, setActiveCommunity } from '../redux/feedSlice';
import { RootState, useAppDispatch } from '../redux/store';
import { GroupCardRounded } from '../common-ui/GroupCardRounded';
import { ExploreNavigator, ExploreNavigatorScreens } from '../common-types/navigation-types';
import { GroupQuery } from '../common-types/types';

type ExploreSearchRoute = RouteProp<ExploreNavigatorScreens, `ExploreSearch`>;

type GetGroupsResults = {
  getGroupsByName: GroupQuery[];
};

const limit = 15;

export const ExploreSearch = () => {
  const route = useRoute<ExploreSearchRoute>();
  const { user } = React.useContext(AppContext);
  const { searchText = `` } = route.params;
  const dispatch = useAppDispatch();
  const { forceExplorerRefresh } = useSelector((state: RootState) => state.feed);

  const {
    data: groups,
    loading,
    fetchMore,
    refetch,
  } = useQuery<GetGroupsResults>(GET_GROUPS_BY_NAME, {
    variables: { group_name: searchText, user_id: user?.id, limit, offset: 0 },
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    dispatch(setForceExplorerRefresh(false));
  }, [dispatch]);

  useEffect(() => {
    if (forceExplorerRefresh) refetch();
  }, [forceExplorerRefresh, refetch]);

  const Blob = getRandomBlob();
  const keyExtractor = (item: GroupQuery) => item.group.id;

  const renderItem: ListRenderItem<GroupQuery> = useCallback(({ item: { group, group_member } }) => {
    return <GroupCard group={group} group_member={group_member} />;
  }, []);

  const onEndReached = useCallback(() => {
    if (!loading && groups?.getGroupsByName?.length && groups?.getGroupsByName?.length >= limit) {
      fetchMore({
        variables: { offset: groups.getGroupsByName.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          const [prevLast] = prev?.getGroupsByName?.slice(-1) || [];
          const [last] = fetchMoreResult?.getGroupsByName?.slice(-1) || [];
          if (!fetchMoreResult || !last || last?.group?.id === prevLast?.group?.id) {
            console.log(`[search]is the same, return prev`);
            return prev;
          }
          if (!prev) return fetchMoreResult;
          const prevGroups = prev.getGroupsByName;
          const newGroups = fetchMoreResult.getGroupsByName;
          return { ...prev, getGroupsByName: unionBy(prevGroups, newGroups, (g) => g.group.id) };
        },
      });
    }
  }, [loading, groups, fetchMore]);

  return (
    <FlatList
      style={{ flex: 1, backgroundColor: Colors.white }}
      data={groups?.getGroupsByName || []}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ListEmptyComponent={() => (
        <View style={{ flex: 1, justifyContent: `center`, marginTop: 20 }}>
          {loading ? (
            <View style={{ alignItems: `center`, justifyContent: `center` }}>
              <LoadingIndicator size={42} />
            </View>
          ) : (
            <Text style={Typography.text(`gray`, `center`)}>No results found</Text>
          )}
        </View>
      )}
      ListHeaderComponent={
        <View style={{ margin: 16 }}>
          <Blob width={24} height={24} style={{ position: `absolute`, left: -8, top: -2, zIndex: -1 }} />
          <Text style={{ flexShrink: 1, ...Typography.text(`bold`, `plusone`) }}>
            <Text>{` `}Your search: </Text>
            <Text style={{ flexShrink: 1, ...Typography.text(`bold`, `plusone`) }}> "{searchText}"</Text>
          </Text>
        </View>
      }
      onEndReachedThreshold={0.5}
      onEndReached={onEndReached}
    />
  );
};

const GroupCard: React.FC<GroupQuery> = memo(({ group, group_member }) => {
  const navigation = useNavigation<ExploreNavigator>();
  const dispatch = useDispatch();
  const { user } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const handleNavigateToCommunity = async () => {
    try {
      setLoading(true);
      const { data } = await client.query({
        query: COMMUNITY_SETTING,
        variables: { input: { group_id: group?.id, key: `group.setting.visible` } },
      });

      const privacyContent = data?.getCommunitySetting;

      if (user?.id && group_member) {
        await dispatch(setActiveCommunity({ user_id: user.id, group }));
        // @ts-ignore
        navigation.navigate(`HomeScreen`);
        return;
      }

      if (privacyContent?.value === `public`) {
        await dispatch(setPreviewCommunity(group));
        navigation.navigate(`CommunityPreview`);
      } else {
        navigation.navigate(`AboutScreen`, { group, group_member });
      }
    } catch (error) {
      console.error(`An error ocurred in handleNavigateToCommunity() - ExploreSearch.tsx`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ flexDirection: `row`, justifyContent: `center`, alignContent: `center` }}>
      <GroupCardRounded
        group={group}
        group_member={group_member}
        handleArrowPress={handleNavigateToCommunity}
        handleCardPress={handleNavigateToCommunity}
        showMembershipPill
        loading={loading}
      />
    </View>
  );
});
