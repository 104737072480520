import React, { useState, useCallback, useEffect } from 'react';
import { Alert, BackHandler, Text, View } from 'react-native';
import { useQuery } from 'react-apollo';
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { updatePersonaRelationship } from '../chat/chatServices';
import { Colors, MeshIconsNames, Typography } from '../common-styles';
import { BottomSheetModal, ThreeDotsButton, BackButton, LoadingIndicator } from '../common-ui';
import { REMOVE_ACTIVITY, refetchQueriesFor, client, GET_OWN_BLOCKED_USERS_V2, GET_PERSONA } from '../graphql';
import { MODERATION_TYPE } from '../moderation/moderationEnums';
import { MODAL_SWITCH_TIME } from '../../constants';
import { AppContext } from '../../AppContext';
import ProfileFeed from './ProfileFeed';
import { useSoundProfile } from '../common-util/hooks/useSoundProfile';
import { refreshCounts } from '../redux/followSlice';
import { LeftDrawerNavigator, ProfileNavigator, ProfileScreensParamList } from '../common-types/navigation-types';
import { RootState } from '../redux/store';
import { Activity, BottomSheetOptions, GetBlockedUsers, User } from '../common-types/types';

type ProfileRoute = RouteProp<ProfileScreensParamList, `Profile`>;

type IdAndHandle = Pick<User, `id` | `handle`>;
const MESH_MODERATION_GROUP = `6285531e-7ff2-4e55-ba98-86d133227f6f`;

export const ProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  useSoundProfile();

  const navigation = useNavigation<ProfileNavigator>();
  const route = useRoute<ProfileRoute>();

  const { user: currentUser, setLaunchUrl } = React.useContext(AppContext);
  const { user: initialUser = currentUser, user_id } = route?.params || {};

  const [options, setOptions] = useState<BottomSheetOptions[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showPostOptions, setShowPostOptions] = useState(false);
  const [user, setUser] = useState<IdAndHandle>(initialUser as IdAndHandle);
  const [loading, setLoading] = useState(false);

  const { isCountRefresh: isRefresh } = useSelector((state: RootState) => state.follow);

  const {
    data,
    error,
    refetch: RefetchPersona,
  } = useQuery(GET_PERSONA, {
    variables: { id: user_id || initialUser?.id },
  });

  useEffect(() => {
    const refresh = async () => {
      try {
        await RefetchPersona();
      } catch (error: any) {
        console.log(`Error Refetch Persona: `, error);
      } finally {
        dispatch(refreshCounts(false));
      }
    };
    if (isRefresh) refresh();
  }, [isRefresh, dispatch, RefetchPersona]);

  useEffect(() => {
    //clear cached invite deeplink when successfully displaying this screen
    //else it will trigger it again if app restarts after returning from background
    setLaunchUrl(null);
  }, [setLaunchUrl]);

  const blockUser = useCallback(
    async (user_id, block = true) => {
      try {
        // user_id means other_persona_id
        if (!currentUser?.id) throw new Error(`No current user to validate blocks`);
        const blocked = await updatePersonaRelationship(currentUser.id, user_id, [], block);
        console.log(`blocked user`, blocked);
        client.reFetchObservableQueries(); // temp hack to refetch groups that both users may share with each other to hide blocked user's posts
        return blocked;
      } catch (err: any) {
        console.error(`Error thrown in blockUser() in ProfileScreen.jsx`, err.message);
        return null;
      }
    },
    [currentUser],
  );

  const promptBlockUser = useCallback(
    (user) => {
      Alert.alert(
        `Are you sure you want to block ${user && user.handle}?`,
        `You won't see any of their content in groups and you will not be able to message each other.`,
        [
          { text: `Cancel`, onPress: () => console.log(`canceled block`), style: `cancel` },
          {
            text: `Yes, block user`,
            onPress: () => {
              blockUser(user?.id, true);
              setShowOptions(false);
              setTimeout(() => {
                const parent = navigation.getParent<LeftDrawerNavigator>();
                parent.jumpTo(`RightDrawer`, {
                  screen: `MainTabs`,
                  // @ts-ignore - issues with types in nested navigation events
                  params: {
                    screen: `HomeNavigator`,
                    params: { screen: `HomeScreen` },
                  },
                });
              }, 500);
            },
            style: `default`,
          },
        ],
        { cancelable: true },
      );
    },
    [blockUser, navigation],
  );

  const buildProfileOptionsMenu = useCallback(async (): Promise<BottomSheetOptions[]> => {
    try {
      const routeUser = route?.params?.user;
      const {
        data: { getOwnBlockedUsersV2: blockedUsers },
      } = await client.query<GetBlockedUsers>({ query: GET_OWN_BLOCKED_USERS_V2, fetchPolicy: `network-only` });
      const isUserBlocked =
        blockedUsers.findIndex((blockedUser: any) => {
          return blockedUser?.blocked_user?.id === routeUser?.id;
        }) >= 0;
      const options = [
        {
          title: isUserBlocked ? `Unblock user` : `Block user`,
          titleStyle: { color: Colors.alertColor },
          iconName: `block` as MeshIconsNames,
          iconColor: Colors.alertColor,
          testID: `TOGGLE_BLOCK`,
          onPress: () => {
            setShowOptions(false);
            setTimeout(() => {
              if (isUserBlocked) return blockUser(routeUser?.id, false);
              return promptBlockUser({
                id: routeUser?.id,
                handle: routeUser?.handle,
              });
            }, MODAL_SWITCH_TIME);
          },
        },
        {
          title: `Report user`,
          titleStyle: { color: Colors.alertColor },
          iconName: `flag` as MeshIconsNames,
          iconColor: Colors.alertColor,
          onPress: () => {
            setShowOptions(false);
            setTimeout(() => {
              navigation.navigate(`ReportScreen`, {
                reportType: MODERATION_TYPE.user,
                reportee: routeUser?.id,
                origin_group_id: MESH_MODERATION_GROUP,
                reporteeName: routeUser?.handle,
              });
            }, MODAL_SWITCH_TIME);
          },
        },
      ];
      return options;
    } catch (error: any) {
      console.error(`Error thrown in buildProfileOptionsMenu() in ProfileScreen.jsx`, error.message);
      return [];
    }
  }, [navigation, blockUser, promptBlockUser, route.params?.user]);

  const buildPostOptionsMenu = (activity: Activity): BottomSheetOptions[] => {
    const { origin_group, origin_group_id, actor, verb, id } = activity || {};
    const options = [
      {
        predicate: () => currentUser?.id === actor,
        title: `Edit post`,
        iconName: `create` as MeshIconsNames,
        testID: `POST_EDIT`,
        onPress: () => {
          setShowOptions(false);
          setTimeout(() => {
            const parent = navigation.getParent<LeftDrawerNavigator>();
            parent.jumpTo(`RightDrawer`, {
              screen: `MainTabs`,
              // @ts-ignore - issues with types in nested navigation events
              params: {
                screen: `HomeNavigator`,
                params: {
                  screen: `PostEditor`,
                  params: { group: origin_group, activity, mode: `edit` },
                },
              },
            });
          }, 500);
        },
      },
      {
        predicate: () => currentUser?.id === actor,
        title: `Delete post`,
        iconName: `trash` as MeshIconsNames,
        titleStyle: { color: Colors.alertColor },
        iconColor: Colors.alertColor,
        testID: `POST_DELETE`,
        onPress: () => showDeletePostAlert(activity),
      },
      {
        predicate: () => currentUser?.id !== actor,
        title: `Report post`,
        iconName: `flag` as MeshIconsNames,
        titleStyle: { color: Colors.alertColor },
        iconColor: Colors.alertColor,
        testID: `POST_REPORT`,
        onPress: () => {
          setShowOptions(false);
          setTimeout(() => {
            navigation.navigate(`ReportScreen`, {
              origin_group_id,
              id,
              reportType: verb,
            });
          }, MODAL_SWITCH_TIME);
        },
      },
    ];
    return options.filter((option) => option.predicate === undefined || option.predicate());
  };

  const showDeletePostAlert = (activity: Activity) => {
    const { id } = activity;
    Alert.alert(
      `Are you sure you want to delete this post?`,
      `Confirm by pressing OK`,
      [
        { text: `Cancel`, onPress: () => setShowOptions(false), style: `cancel` },
        {
          text: `OK`,
          onPress: () => {
            removeActivity(id);
            setShowOptions(false);
          },
        },
      ],
      { cancelable: true },
    );
  };

  const removeActivity = async (id: string) => {
    console.log(`=== firing removeActivity ===`);
    try {
      await client.mutate({
        mutation: REMOVE_ACTIVITY,
        variables: { id },
        refetchQueries: refetchQueriesFor(`Activity`),
      });
    } catch (err: any) {
      console.error(`Error thrown in removeActivity()`, err.message);
    }
  };

  const onPressPostOptions = (activity: Activity) => {
    setShowPostOptions(true);
    const options = buildPostOptionsMenu(activity) || [];
    if (options) setOptions(options);
  };

  const onPressOptions = useCallback(async () => {
    setShowOptions(true);
    const options = (await buildProfileOptionsMenu()) || [];
    if (options) setOptions(options);
  }, [buildProfileOptionsMenu]);

  useEffect(() => {
    if (error) console.warn(`[ProfileSettingsError]:`, error);
    if (data?.getPersona) setUser(data?.getPersona);
  }, [data, error]);

  const onBackPressHandler = useCallback(() => {
    const canGoBack = navigation.canGoBack();
    if (!canGoBack) {
      const parent = navigation.getParent<LeftDrawerNavigator>();
      // @ts-ignore reason: not specified params so navigation jumps to last screen within MainTabs
      parent?.jumpTo(`RightDrawer`, { screen: `MainTabs` });
    } else {
      navigation.goBack(); // should go back if can go back
    }
    return !canGoBack;
  }, [navigation]);

  useEffect(() => {
    const isCurrentUser = user?.id === currentUser?.id;
    navigation.setOptions({
      headerTitle: `@${user?.handle}`,
      headerRight: !isCurrentUser
        ? () => <ThreeDotsButton onPress={onPressOptions} size={24} style={{ marginRight: 6 }} testID="PROFILE_3DOTS" />
        : () => null,
      headerLeft: () => <BackButton onPress={() => onBackPressHandler()} />,
    });
  }, [user?.id, user?.handle, currentUser?.id, currentUser?.handle, navigation, onPressOptions, onBackPressHandler]);

  useFocusEffect(
    useCallback(() => {
      //otherwise android devices will exit app completely
      BackHandler.addEventListener(`hardwareBackPress`, onBackPressHandler);
      return () => BackHandler.removeEventListener(`hardwareBackPress`, onBackPressHandler);
    }, [onBackPressHandler]),
  );

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [initialUser]);

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: `center`,
          alignItems: `center`,
          backgroundColor: Colors.white,
          height: `100%`,
          width: `100%`,
        }}
      >
        <LoadingIndicator size="small" />
        <Text style={{ ...Typography.text(`center`, `gray`, `bold`), marginTop: 10 }}>Loading</Text>
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      {!!user?.id && <ProfileFeed user={user} showPostOptionsModal={onPressPostOptions} />}

      {options?.length > 0 && (
        <BottomSheetModal
          title="Manage"
          visible={showOptions || showPostOptions}
          onPressCancel={() => {
            setShowPostOptions(false);
            setShowOptions(false);
            setOptions([]);
          }}
          confirmTitle="Close"
          options={options}
          onPressConfirm={() => {
            setShowPostOptions(false);
            setShowOptions(false);
            setOptions([]);
          }}
          showCancelBtn={false}
        />
      )}

      {/* This component needs to be toggled so it could change reportType*/}
    </View>
  );
};
