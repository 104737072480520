import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { View, Text, StyleSheet, TextInput, ScrollView, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import { ThemedButton, SafeAreaView, KeyboardPaddingView, NoThemeButton, MeshIcon } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { consecutiveDaysOfActivityOptions, membershipFilterOptions } from './exploreHelpers';

export const ExploreFilters = ({
  isVisible,
  onBackdropPress,
  setFilters,
  membersFilter,
  postsFilter,
  membershipStatus,
  hideMembershipStatus,
}) => {
  console.log(`===> filters ===> `, {
    members: membersFilter,
    posts: postsFilter,
    membershipStatus,
  });

  const [communitySizeFilter, setCommunitySizeFilter] = useState(+membersFilter);
  const [communitySizeFilterString, setCommunitySizeFilterString] = useState(JSON.stringify(membersFilter)); //avoiding TextInput weird behavior
  const [activityFilter, setActivityFilter] = useState(+postsFilter);
  const [membershipFilter, setMembershipFilter] = useState(membershipStatus || `Any`);

  const safeSetCommunitySize = useCallback(
    (input) => {
      if (!Number.isNaN(input)) {
        setCommunitySizeFilter(+input);
        setCommunitySizeFilterString(input);
      } else {
        console.warn(`communitySize expected a Number, recieved String (NaN)`);
      }
    },
    [setCommunitySizeFilter],
  );

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <Modal isVisible={isVisible} style={localStyles.modalStyle} onBackdropPress={() => onBackdropPress()}>
        <KeyboardPaddingView>
          <SafeAreaView style={{ flex: 1 }}>
            <View style={localStyles.headerContainer}>
              <NoThemeButton
                clear
                title="Cancel"
                onPress={() => onBackdropPress()}
                color={Colors.iconColor}
                titleStyle={localStyles.headerText}
                containerStyle={{ position: `absolute`, left: 8 }}
              />
              <Text style={{ ...StyleSheet.flatten(localStyles.headerText), color: Colors.darkestGray }} testID="REPORT_TITLE">
                Filters
              </Text>
              <ThemedButton
                clear
                title="Save"
                onPress={() => setFilters(communitySizeFilter, activityFilter, membershipFilter)}
                color={Colors.brandPurple}
                titleStyle={localStyles.headerText}
                containerStyle={{ position: `absolute`, right: 8 }}
              />
            </View>

            <ScrollView alwaysBounceVertical={false} keyboardShouldPersistTaps="handled">
              <View style={{ marginTop: 32, marginHorizontal: 16 }}>
                <Text style={{ marginBottom: 12, ...Typography.text() }}>Minimum community size (Members)</Text>
                <TextInput
                  placeholder="Type the minimum community size"
                  placeholderTextColor={Colors.textPlaceholder}
                  value={communitySizeFilterString}
                  keyboardType="numeric"
                  autoFocus
                  onChangeText={safeSetCommunitySize}
                  style={localStyles.inputStyle}
                  textAlignVertical="center"
                />
              </View>
              <View style={{ marginTop: 32, marginHorizontal: 16 }}>
                <Text style={{ marginBottom: 12, ...Typography.text() }}>Consecutive days of activity (Posts)</Text>
                <View>
                  {consecutiveDaysOfActivityOptions.map((elem) => {
                    const isSelected = elem.value === activityFilter;
                    return (
                      <TouchableOpacity
                        key={elem.key}
                        onPress={() => setActivityFilter(elem.value)}
                        style={{ flexDirection: `row`, alignItems: `center` }}
                      >
                        <View style={{ flex: 1, padding: 16 }}>
                          <Text style={Typography.text(isSelected ? `bold` : `base`)}>{elem.label}</Text>
                        </View>
                        <MeshIcon
                          name={isSelected ? `circle-check` : `radio-off`}
                          size={24}
                          color={Colors.brandPurple}
                          style={{ marginRight: 16 }}
                        />
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>

              {!hideMembershipStatus && (
                <View style={{ marginTop: 32, marginHorizontal: 16 }}>
                  <Text style={{ marginBottom: 12, ...Typography.text() }}>Membership status</Text>
                  <View>
                    {membershipFilterOptions.map((elem) => {
                      const isSelected = elem.value === membershipFilter;
                      return (
                        <TouchableOpacity
                          key={elem.key}
                          onPress={() => setMembershipFilter(elem.value)}
                          style={{ flexDirection: `row`, alignItems: `center` }}
                        >
                          <View style={{ flex: 1, padding: 16 }}>
                            <Text style={Typography.text(isSelected ? `bold` : `base`)}>{elem.label}</Text>
                          </View>
                          <MeshIcon
                            name={isSelected ? `circle-check` : `radio-off`}
                            size={24}
                            color={Colors.brandPurple}
                            style={{ marginRight: 16 }}
                          />
                        </TouchableOpacity>
                      );
                    })}
                  </View>
                </View>
              )}
            </ScrollView>
          </SafeAreaView>
        </KeyboardPaddingView>
      </Modal>
    </View>
  );
};

ExploreFilters.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onBackdropPress: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  membersFilter: PropTypes.number.isRequired,
  postsFilter: PropTypes.number.isRequired,
  membershipStatus: PropTypes.string.isRequired,
  hideMembershipStatus: PropTypes.bool,
};

ExploreFilters.defaultProps = {
  hideMembershipStatus: false,
};

const localStyles = StyleSheet.create({
  modalStyle: {
    flex: 1,
    backgroundColor: Colors.white,
    margin: 0,
  },
  headerContainer: {
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    paddingHorizontal: 10,
    paddingTop: 10,
    shadowColor: Colors.gray,
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    borderColor: Colors.lightWarmGray,
    elevation: 5,
    backgroundColor: Colors.white,
    height: 48,
    borderTopWidth: 0,
  },
  headerText: {
    fontSize: Typography.baseFontSize,
    fontWeight: `600`,
  },
  inputStyle: {
    borderRadius: 4,
    borderColor: Colors.dividerColor,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
});
