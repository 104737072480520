import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/react-hooks';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { CodeOfConduct } from '../community';
import { Colors, Typography } from '../common-styles';
import { ThemedButton } from '../common-ui';
import { WizardLayout } from '../community-create/WizardLayout';
import { useAppDispatch } from '../redux/store';
import { moveNext } from '../redux/applicationWizardSlice';
import { COMMUNITY_SETTING } from '../graphql';
import { CommunitySettingsData, CommunitySettingsInput, Guideline } from '../common-types/types';
import { ExploreNavigator, ExploreNavigatorScreens } from '../common-types/navigation-types';

type ApplicationCoCRoute = RouteProp<ExploreNavigatorScreens, `CommunityCodeOfConduct`>;

export const CommunityApplicationCoC: React.FC = () => {
  const navigation = useNavigation<ExploreNavigator>();
  const route = useRoute<ApplicationCoCRoute>();
  const dispatch = useAppDispatch();
  const [fetchedGuidelines, setFetchedGuidelines] = useState<Guideline[]>([]);
  const [agree, setAgree] = useState(false);
  const { group } = route?.params || {};

  const { data, error } = useQuery<CommunitySettingsData, CommunitySettingsInput>(COMMUNITY_SETTING, {
    variables: {
      input: {
        group_id: group?.id,
        key: `group.setting.guidelines`,
      },
    },
    fetchPolicy: `cache-and-network`,
  });

  if (error) console.error(`An error occured trying to fetch the community guidelines ${error?.message}`);

  useEffect(() => {
    const iconColor = agree ? Colors.brandPurple : Colors.textDisabled;
    const handleNext = () => {
      dispatch(moveNext());
      navigation.navigate(`CommunityApplicationForm`, { group });
    };
    navigation.setOptions({
      headerRight: () => (
        <ThemedButton
          clear
          testID="APPL_CONTINUE"
          title="Continue"
          titleStyle={{ ...Typography.text(`base`, `bold`), color: iconColor }}
          containerStyle={{ right: 12 }}
          onPress={handleNext}
          disabled={!agree}
        />
      ),
    });
  }, [navigation, agree, dispatch, group]);

  useEffect(() => {
    if (!error) {
      const setting = data?.getCommunitySetting;
      const parsedGuidelines = JSON.parse((setting?.value as string) || `[]`);
      setFetchedGuidelines(parsedGuidelines);
    }
  }, [data, error]);

  return (
    <View style={{ flex: 1 }}>
      <WizardLayout total={2} selector="applicationWizard">
        <CodeOfConduct
          fetchedGuidelines={fetchedGuidelines}
          showAgreeAndJoin
          group={group}
          agree={agree}
          toggleAgree={() => setAgree((prev) => !prev)}
        />
      </WizardLayout>
    </View>
  );
};
