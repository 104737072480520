import { useState, useCallback } from 'react';
import { useDisclosure } from '../../../common-util/hooks/useDisclosure';
import { MODERATION_TYPE } from '../../../moderation/moderationEnums';

export type ReportType = keyof typeof MODERATION_TYPE;

export const useReportModal = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [reportType, setReportType] = useState(`community`);
  const [postId, setPostId] = useState<string | null>();
  const [reportee, setReportee] = useState<string | null>(null);

  const showReportModal = useCallback(
    (type: string, id: string | null) => {
      onToggle();
      setReportType(type);
      if (type === `user`) {
        setReportee(id);
      } else {
        setPostId(id);
      }
    },
    [onToggle],
  );

  const hideReportModal = useCallback(() => {
    onToggle();
    setReportType(`community`);
    setPostId(null);
  }, [onToggle]);

  return {
    isOpen,
    reportType,
    postId,
    reportee,
    showReportModal,
    hideReportModal,
  };
};
