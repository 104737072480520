/* eslint-disable complexity */
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { formatShortRelativeDate } from '../common-util';
import { NOTIFICATION_TYPE } from '../constants/notifications';
import { MODERATION_ACTIONS, MODERATION_CATEGORY } from '../moderation/moderationEnums';
import { BaseNotificationItem } from './BaseNotificationItem';
import { useAppDispatch } from '../redux/store';
import { setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';
import { MeshVerifiedCheck } from '../images';

const allowedNotifications = {
  invite: `invite`,
  approveApplication: MODERATION_ACTIONS.application_approve,
  inviteAccepted: `invite_accepted`, // TBD - We need to review this
  becomeModerator: NOTIFICATION_TYPE.become_moderator, // TBD - We need to review this
  becomeLeader: NOTIFICATION_TYPE.become_leader, // TBD - We need to review this
  communityOpenItems: `open_items_community`, // TBD - We need to review this
  reportWaitingModeration: NOTIFICATION_TYPE.report_waiting_moderation, // TBD - We need to review this
  postWaitingApproval: NOTIFICATION_TYPE.post_waiting_approval, // TBD - We need to review this
  membershipApplication: MODERATION_CATEGORY.community_application, // TBD - We need to review this
  tempRestrictedCommunity: `temporal_restricted_community`, // TBD - We need to review this
  removedPost: `removed_post`, // TBD - We need to review this
  removedComment: `removed_comment`, // TBD - We need to review this
  postReinstated: `post_reinstated`, // TBD - We need to review this
  userReinstated: `user_reinstated`, // TBD - We need to review this
  archivedCommunity: `archived_community`, // TBD - We need to review this
  unarchivedCommunity: `unarchived_community`, // TBD - We need to review this
  changedCommunityName: NOTIFICATION_TYPE.changed_community_name, // TBD - We need to review this
  // TODO: approve/reject join request backward compatibility for old actions
  approveJoinRequest: MODERATION_ACTIONS.application_approve,
  rejectJoinRequest: MODERATION_ACTIONS.application_reject,
};

export const allowed_types = Object.values(allowedNotifications);

export const CommunityNotificationItem = ({ notification, activity, type, markRead, onPressOptionsButton }) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const onPress = () => {
    const activity = notification.activities[0];
    if (!notification.is_read) {
      markRead(notification.id);
    }
    // const type = allowedNotifications.inviteAccepted;
    switch (type) {
      case allowedNotifications.postWaitingApproval:
        return navigation.push(`ModerateCommunityDetail`, { group: activity.origin_group });

      case allowedNotifications.membershipApplication:
        return navigation.push(`MemberInfo`, { group: activity.origin_group }); // TBD - We need to review this(need group_member)

      case allowedNotifications.tempRestrictedCommunity:
      case allowedNotifications.removedPost:
      case allowedNotifications.removedComment:
        return null;

      case allowedNotifications.postReinstated:
        return goToPost(activity.origin_activity_id); // TBD - We need to review this
      case allowedNotifications.approveApplication:
        dispatch(setActiveCommunity({ user_id: activity?.origin_group_member?.persona_id, group: activity.origin_group }));
        return navigation.navigate(`HomeScreen`);
      default: {
        dispatch(setPreviewCommunity(activity.origin_group));
        return navigation.push(`CommunityPreview`, { invite_token: notification?.invite_token });
      }
    }
  };

  const goToPost = (id) => {
    return navigation.push(`PostDetail`, { id, group_id: activity.origin_group.id, addComment: true });
  };

  const handlePressOptionsButton = () => {
    onPressOptionsButton(notification);
  };

  const getBadge = () => {
    // const type = allowedNotifications.inviteAccepted;
    switch (type) {
      case allowedNotifications.invite:
      case allowedNotifications.inviteAccepted:
        return `plus`;

      case allowedNotifications.approveApplication:
      case allowedNotifications.postReinstated:
      case allowedNotifications.userReinstated:
      case allowedNotifications.approveJoinRequest:
        return `check`;

      case allowedNotifications.becomeModerator:
      case allowedNotifications.becomeLeader:
      case allowedNotifications.communityOpenItems:
      case allowedNotifications.reportWaitingModeration:
      case allowedNotifications.postWaitingApproval:
      case allowedNotifications.membershipApplication:
      case allowedNotifications.archivedCommunity:
      case allowedNotifications.unarchivedCommunity:
      case allowedNotifications.changedCommunityName:
        return `moderation`;

      case allowedNotifications.tempRestrictedCommunity:
      case allowedNotifications.removedPost:
      case allowedNotifications.removedComment:
      case allowedNotifications.rejectJoinRequest:
        return `warning`;

      default:
        return null;
    }
  };

  const getAvatarInfo = () => {
    const avatarInfo = {
      data: null,
      type: null,
    };
    switch (type) {
      case allowedNotifications.approveApplication:
      case allowedNotifications.becomeModerator:
      case allowedNotifications.becomeLeader:
      case allowedNotifications.reportWaitingModeration:
      case allowedNotifications.postWaitingApproval:
      case allowedNotifications.membershipApplication:
      case allowedNotifications.tempRestrictedCommunity:
      case allowedNotifications.removedPost:
      case allowedNotifications.removedComment:
      case allowedNotifications.postReinstated:
      case allowedNotifications.userReinstated:
      case allowedNotifications.changedCommunityName:
      case allowedNotifications.approveJoinRequest:
      case allowedNotifications.rejectJoinRequest:
        avatarInfo.data = activity.origin_group;
        avatarInfo.type = `community`;
        return avatarInfo;

      case allowedNotifications.communityOpenItems:
        avatarInfo.data = activity.user || activity.origin_group;
        avatarInfo.type = `mesh`;
        return avatarInfo;

      default:
        avatarInfo.data = activity.user;
        avatarInfo.type = `persona`;
        return avatarInfo;
    }
  };

  const renderNotificationText = (notification) => {
    // const type = allowedNotifications.inviteAccepted;
    const activity = notification.activities[0];
    const group = activity.origin_group || {};
    const group_name = !!group.name && group.name.trim();
    const user = activity.user || {};
    const content = activity.content || ``;
    const user_name = (!!user.handle && user.handle.trim()) || (!!user.name && user.name.trim());

    const userNameText = (
      <>
        <Text onPress={() => navigation.navigate(`Profile`, { user })} maxFontSizeMultiplier={1.8} style={Typography.add(`bold`)}>
          {user_name}
        </Text>
        {user.isVerified && <MeshVerifiedCheck height={12} width={12} style={{ marginRight: 5, marginLeft: 2 }} />}
      </>
    );
    const groupNameText = (
      <Text maxFontSizeMultiplier={1.8} style={Typography.add(`bold`)}>
        {group_name}
      </Text>
    );
    const others = notification.actor_count === 1 ? `other` : `others`;
    const andOthers = notification.actor_count > 1 ? ` and ${notification.actor_count - 1} ${others}` : ``;

    switch (type) {
      case allowedNotifications.invite:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            {userNameText}
            {andOthers} invited you to join {groupNameText}
          </Text>
        );

      case allowedNotifications.approveApplication:
      case allowedNotifications.approveJoinRequest:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            Your request to join {groupNameText} has been approved!
          </Text>
        );

      case allowedNotifications.rejectJoinRequest:
        return <Text style={Typography.text()}>Your request to join {groupNameText} has been denied.</Text>;

      case allowedNotifications.inviteAccepted:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            {userNameText} has accepted your invite to {groupNameText}.
          </Text>
        );

      case allowedNotifications.becomeModerator:
      case allowedNotifications.becomeLeader: {
        const what = type === allowedNotifications.becomeModerator ? `Moderator` : `Community Leader`;
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            You are now a {what} of {groupNameText}.
          </Text>
        );
      }

      // TBD - needs to be implemented
      case allowedNotifications.communityOpenItems:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            <Text>You have{` `}</Text>
            <Text maxFontSizeMultiplier={1.8} style={Typography.text(`bold`)}>
              3 open items
            </Text>
            <Text>{` `}in your communities.</Text>
          </Text>
        );

      case allowedNotifications.reportWaitingModeration:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            You have a new report in {groupNameText} waiting for moderation.
          </Text>
        );

      case allowedNotifications.postWaitingApproval:
      case allowedNotifications.membershipApplication: {
        const what =
          type === allowedNotifications.postWaitingApproval
            ? `There is a new post waiting for approval in`
            : `You have a new membership application in`;
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            {what} {groupNameText}.
          </Text>
        );
      }

      case allowedNotifications.tempRestrictedCommunity:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            You’re temporarily restricted from joining and posting to {groupNameText} until Sep 8 at 6:55 PM.
          </Text>
        );

      case allowedNotifications.removedPost:
      case allowedNotifications.removedComment: {
        const what = type === allowedNotifications.removedPost ? `post` : `comment`;
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            Your {what} in {groupNameText} was removed citing Spam.
          </Text>
        );
      }

      case allowedNotifications.postReinstated:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            Your post in {groupNameText} has been reinstated.
          </Text>
        );

      case allowedNotifications.userReinstated:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            You have been reinstated as a member in {groupNameText}.
          </Text>
        );

      case allowedNotifications.archivedCommunity:
      case allowedNotifications.unarchivedCommunity: {
        const what = type === allowedNotifications.archivedCommunity ? `archived` : `unarchived`;
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            {userNameText} has {what} {groupNameText}.
          </Text>
        );
      }

      case allowedNotifications.changedCommunityName:
        return (
          <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
            <Text maxFontSizeMultiplier={1.8} style={Typography.text(`bold`)}>
              {content}
            </Text>
            <Text>
              {` `}has changed its name to {groupNameText}.
            </Text>
          </Text>
        );
      default:
        return null;
    }
  };

  const avatarInfo = getAvatarInfo();

  let notificationTime = ``;
  if (notification.updated_at) notificationTime = notification.updated_at;
  else if (activity.time) notificationTime = activity.time;
  else if (activity.reaction.created_at) notificationTime = activity.reaction.created_at;
  else notificationTime = notification.created_at;
  if (!notificationTime.endsWith(`Z`)) notificationTime = `${notificationTime}Z`;

  return (
    <BaseNotificationItem
      containerStyle={{
        backgroundColor: notification.is_read ? Colors.seenNotificationBackground : Colors.unseenNotificationBackground,
      }}
      title={<View style={{ marginBottom: 0 }}>{renderNotificationText(notification)}</View>}
      date={formatShortRelativeDate(notificationTime)}
      avatarData={avatarInfo.data}
      avatarType={avatarInfo.type}
      avatarSize={50}
      navigation={navigation}
      badge={getBadge()}
      showMoreOptionsButton
      onPressOptionsButton={handlePressOptionsButton}
      onPress={onPress}
      isSameStack={true}
    />
  );
};

CommunityNotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  //navigation: commonPropTypes.navigation().isRequired,
  markRead: PropTypes.func.isRequired,
  onPressOptionsButton: PropTypes.func,
};

CommunityNotificationItem.defaultProps = {
  onPressOptionsButton: () => {},
};
