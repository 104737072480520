import { Platform, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import React, { useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Colors, Typography, Spacing } from '../common-styles';
import {
  getRandomBlob,
  KeyboardPaddingView,
  MeshIcon,
  NoThemeButton,
  PasswordInput,
  SafeAreaView,
  WarningBox,
} from '../common-ui';
import { client, RESET_PASSWORD, VERIFY_RECOVERY_TOKEN } from '../graphql';
import { AuthButton } from './AuthButton';
import { cleanAndFormatContact, resetAccountHelper } from './helpers';
import { PendoTrackEvent, Events } from '../pendo/events';
import { AuthNavigatorScreens } from '../common-types/navigation-types';

type RecoveryCodeNavigator = StackNavigationProp<AuthNavigatorScreens, `RecoverAccount`>;
type RecoveryCodeRoute = RouteProp<AuthNavigatorScreens, `RecoverAccount`>;

const Blob = getRandomBlob();

export const AccountRecovery: React.FC = () => {
  const [validToken, setValidToken] = useState<boolean>();
  const [code, setCode] = useState(``);
  const [successMessage, setSuccessMessage] = useState<string>();

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ flex: 1, alignItems: `center`, width: `100%` }}>
          <ScrollView
            style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
            contentContainerStyle={{ alignItems: `center` }}
            alwaysBounceVertical={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={{ width: `100%`, maxWidth: Spacing.standardWidth }}>
              {validToken ? (
                <NewPassword code={code} setSuccessMessage={setSuccessMessage} />
              ) : (
                <VerificationCode validToken={validToken} code={code} setCode={setCode} setValidToken={setValidToken} />
              )}
              {!!successMessage && (
                <Text testID="ACCOUNTRECOVERY_SUCCESS" style={Typography.text(`small`, `gray`, `center`)}>
                  {successMessage}
                </Text>
              )}
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

type NewPasswordProps = {
  code: string;
  setSuccessMessage: (msg?: string) => void;
};

const NewPassword: React.FC<NewPasswordProps> = ({ code, setSuccessMessage }) => {
  //const { identity, password, code, is8chars, containsSpecialSymbol, validPassword, loading, Blob } = this.state;
  const navigation = useNavigation<RecoveryCodeNavigator>();
  const route = useRoute<RecoveryCodeRoute>();
  const { contact, identity } = route.params;

  const [password, setPassword] = useState(``);
  const [is8Chars, setIs8Chars] = useState(false);
  const [containsSpecialSymbol, setContainsSpecialSymbol] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const passwordTextChanged = (text: string) => {
    const has8Chars = text.length >= 8;
    const hasSpecialSymbols = /[^A-Za-z0-9]/.test(text);
    setIs8Chars(has8Chars);
    setContainsSpecialSymbol(hasSpecialSymbols);
    setPassword(text);
    setValidPassword(has8Chars && hasSpecialSymbols); //FIXME: not necessary
  };

  const resetPassword = async (id: string, password: string) => {
    setLoading(true);
    PendoTrackEvent(Events.RESET_PASWORD_SUBMIT, { contact });
    try {
      const input = {
        id,
        new_password: password,
        recovery_token: code,
      };
      const { data } = await client.mutate({
        mutation: RESET_PASSWORD,
        variables: input,
        fetchPolicy: `no-cache`,
      });
      // console.log(`res`, res);
      const response = data.recoverAccount.res_status;
      if (response === `200OK`) {
        setSuccessMessage(`Your password was reset! Taking you back to Login...`);
        setTimeout(() => navigation.replace(`Login`), 3000);
      }
    } catch (error: any) {
      console.error(`[resetPassword] Error:`, error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ margin: 16 }}>
      <View style={localStyles.titleContainer}>
        <Blob width={36} height={36} style={{ position: `absolute`, left: -8, top: -2, zIndex: -1 }} />
        <Text style={localStyles.title}>Enter a new password</Text>
      </View>
      <View style={{ flexDirection: `row`, alignContent: `center` }}>
        <Text style={{ ...Typography.text(`gray`), paddingTop: 5, paddingBottom: 44 }}>
          Enter a new password to access your account
        </Text>
      </View>
      <View style={{ marginBottom: 10 }}>
        <PasswordInput
          autoFocus
          autocomplete={Platform.select({ web: `new-password` })}
          textContentType="newPassword"
          testID="ACCOUNTRECOVERY_NEWPASSWORD"
          onChangeText={passwordTextChanged}
          onSubmitEditing={() => resetPassword(identity, password)}
          placeholder="Enter new password"
        />
      </View>
      <View style={{ ...StyleSheet.flatten(localStyles.passwordValidationContainer) }}>
        {password.trim().length > 0 && (
          <MeshIcon name={is8Chars ? `check` : `info-circle`} color={is8Chars ? Colors.brandGreen : Colors.brightRed} size={18} />
        )}
        <Text style={localStyles.passwordValidationText}>Must contain at least 8 characters</Text>
      </View>
      <View style={localStyles.passwordValidationContainer}>
        {password.trim().length > 0 && (
          <MeshIcon
            name={containsSpecialSymbol ? `check` : `info-circle`}
            color={containsSpecialSymbol ? Colors.brandGreen : Colors.brightRed}
            size={18}
          />
        )}
        <Text style={localStyles.passwordValidationText}>Must contain a special character (e.g. $@!%)</Text>
      </View>
      <View style={{ width: `100%`, paddingTop: 5 }}>
        <AuthButton
          title="Submit"
          testID="ACCOUNTRECOVERY_NEWPASSWORD_SUBMIT"
          onPress={() => resetPassword(identity, password)}
          containerStyle={{ paddingVertical: 10 }}
          disabled={!validPassword || loading}
        />
      </View>
    </View>
  );
};

type RecoveryTokenData = {
  verifyRecoveryToken: {
    valid_token: boolean;
    expired: boolean;
  };
};
type VerificationCodeProps = {
  validToken?: boolean;
  code: string;
  setCode: (code: string) => void;
  setValidToken: (valid: boolean) => void;
};

const VerificationCode: React.FC<VerificationCodeProps> = ({ validToken, code, setCode, setValidToken }) => {
  const route = useRoute<RecoveryCodeRoute>();
  const { contact, identity, contactToShowInTheClient } = route.params;
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(false);

  const verifyToken = async (id: string, token?: string) => {
    try {
      if (!token) throw new Error();
      setLoading(true);
      PendoTrackEvent(Events.VERIFY_CODE_SUBMIT, { verification_code: token });
      const { data } = await client.query<RecoveryTokenData>({
        query: VERIFY_RECOVERY_TOKEN,
        variables: { id, token },
        fetchPolicy: `no-cache`,
      });
      if (data) {
        const { valid_token, expired } = data.verifyRecoveryToken;
        setExpired(expired);
        setLoading(false);
        setValidToken(valid_token);
      }
    } catch (err: any) {
      console.error(`Error thrown in verifyToken():`, err.message);
      setLoading(false);
    }
  };

  const requestNewCode = async () => {
    setLoading(true);
    try {
      PendoTrackEvent(Events.VERIFY_CODE_RESEND, { contact });
      const cleaned = cleanAndFormatContact(contact);
      await resetAccountHelper(identity, cleaned);
    } finally {
      setLoading(false);
    }
  };

  return (
    <KeyboardPaddingView>
      <View style={{ margin: 16 }}>
        <View style={localStyles.titleContainer}>
          <Blob width={36} height={36} style={{ position: `absolute`, left: -8, top: -2, zIndex: -1 }} />
          <Text style={localStyles.title}>Enter your verification code</Text>
        </View>
        <Text style={{ ...Typography.text(`gray`), paddingTop: 20, paddingBottom: 30 }}>
          <Text>{`Sent to `}</Text>
          <Text style={Typography.text(`darkGray`)}>{contactToShowInTheClient}</Text>
          <Text>. This code is valid for 5 minutes.</Text>
        </Text>
        <View style={{ width: `100%` }}>
          <TextInput
            testID="ACCOUNTRECOVERY_ONE_TIME_CODE"
            autoFocus
            value={code}
            placeholder="_ _ _ _ _ _"
            onChangeText={setCode}
            autoCorrect={false}
            textContentType="oneTimeCode"
            keyboardType="number-pad"
            maxLength={6}
            style={localStyles.input}
            placeholderTextColor={Colors.gray}
          />
        </View>
        {expired ? (
          <WarningBox
            text="The code you entered has expired."
            style={localStyles.invalidContainer}
            textStyle={localStyles.invalidText}
          />
        ) : null}
        {validToken === false && !expired ? (
          <WarningBox
            text="The code you entered is incorrect."
            style={localStyles.invalidContainer}
            textStyle={localStyles.invalidText}
          />
        ) : null}
        <View style={{ width: `100%`, paddingTop: 5 }}>
          <AuthButton
            title="Continue"
            testID="ACCOUNTRECOVERY_ONE_TIME_CODE_SUBMIT"
            onPress={() => verifyToken(identity, code)}
            containerStyle={{ paddingVertical: 10 }}
            loading={loading}
            disabled={loading}
          />
        </View>
        <View style={{ width: `100%`, paddingTop: 5 }}>
          <NoThemeButton
            clear
            buttonStyle={{ backgroundColor: Colors.white }}
            title="Request a new code"
            titleStyle={Typography.text(`bold`, `link`)}
            testID="ACCOUNTRECOVERY_ONE_TIME_CODE_SUBMIT"
            onPress={requestNewCode}
            disabled={loading}
          />
        </View>
      </View>
    </KeyboardPaddingView>
  );
};

const localStyles = StyleSheet.create({
  title: {
    flex: 1,
    ...Typography.text(`large`, `bold`),
    flexWrap: `wrap`,
    flexShrink: 2,
  },
  titleContainer: {
    alignItems: `center`,
    flexDirection: `row`,
    marginTop: 16,
  },
  invalidText: {
    ...Typography.text(`white`, `small`),
    padding: 2,
  },
  invalidContainer: {
    backgroundColor: Colors.brightRed,
    justifyContent: `center`,
    alignItems: `flex-start`,
    paddingHorizontal: 16,
    paddingTop: 6,
    paddingBottom: 5,
    marginTop: 15,
    marginBottom: -5,
    borderRadius: 5,
  },
  input: {
    ...Typography.text(),
    borderRadius: 5,
    padding: 10,
    borderColor: Colors.deepPurple,
    borderWidth: 1,
  },
  passwordValidationContainer: {
    paddingTop: 10,
    flexDirection: `row`,
  },
  passwordValidationText: {
    ...Typography.text(),
    marginLeft: 4,
  },
});
