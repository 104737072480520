import React from 'react';
import { Text, View } from 'react-native';
import * as Sentry from '../constants/Sentry';
import { Typography } from '../common-styles';

type ErrorBoundaryProps = {
  header: string;
};
type ErrorBoundaryState = {
  hasError: boolean;
  errorMessage?: string;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };
  }

  componentDidCatch(error: any) {
    // You can also log the error to an error reporting service
    return Sentry.captureMessage(`ErrorBoundary: ${error.message}`);
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    const message = error.message.split(`\n`)[0]; // RN gives you the entire callstack, just show me the error
    return { hasError: true, errorMessage: message };
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { header, children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <View style={{ flex: 1, flexDirection: `column`, justifyContent: `center`, marginHorizontal: 16, marginVertical: 10 }}>
          <Text style={{ ...Typography.text(`header`, `bold`), color: `#FF8888` }}>{header || `Something went wrong`}</Text>
          <Text style={Typography.text()}>{errorMessage}</Text>
        </View>
      );
    }

    return children;
  }
}
