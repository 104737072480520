/* eslint-disable no-return-assign */
import { ScrollView, StyleSheet, Text, View, TextInput, TouchableOpacity } from 'react-native';
import React from 'react';
import { AppContext } from '../../AppContext';
import { getReleaseChannel } from '../../environment';
import { Colors, Typography } from '../common-styles';
import { BackButton, KeyboardPaddingView, SafeAreaView } from '../common-ui';
import { commonPropTypes, setAsyncStorageObject } from '../common-util';
import { client, REQUEST_VERIFY_TOKEN, VERIFY } from '../graphql';
import { AuthButton } from './AuthButton';
import { loginIdentity } from './helpers';

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 20,
    backgroundColor: Colors.themeColor,
  },
  titleContainer: {
    marginTop: 10,
    alignItems: `center`,
    justifyContent: `center`,
  },
  infoContainer: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: Colors.themeColor,
  },
  inputStyle: {
    textAlign: `center`,
    letterSpacing: 8,
    color: `white`,
    fontSize: 40,
    marginHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
  },
  titleStyle: {
    color: `white`,
    fontSize: 18,
  },
  clearButton: {
    marginTop: 12,
    flexDirection: `row`,
    justifyContent: `center`,
  },
  greetingText: {
    color: `white`,
    textAlign: `center`,
    alignSelf: `center`,
    fontSize: Typography.largeHeaderFontSize,
    marginHorizontal: 30,
    marginTop: 10,
  },
  boldText: {
    color: `white`,
    marginTop: 10,
    marginBottom: 10,
    fontSize: Typography.largeFontSize,
    fontWeight: `bold`,
  },
});

export class VerificationScreen extends React.Component {
  static propTypes = {
    navigation: commonPropTypes.navigation().isRequired,
    route: commonPropTypes.route().isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      token: ``,
      showErr: false,
      errMsg: ``,
    };
  }

  renderGreeting = (contact) => {
    return (
      <View style={localStyles.infoContainer}>
        <Text style={localStyles.greetingText}>Please enter the verification code we sent to:{` `}</Text>
        <Text style={localStyles.boldText}>{contact}</Text>
      </View>
    );
  };

  verify = async () => {
    const { token } = this.state;
    const { navigation, route } = this.props;
    const id = route.params?.id;
    const password = route.params?.password;
    const input = { id, verification_token: token };
    const { setIdentity } = this.context;
    const { data } = await client.mutate({
      mutation: VERIFY,
      variables: {
        input,
      },
      fetchPolicy: `no-cache`,
    });

    const { verified } = data.VerifyAccount;
    const { res_status } = data.VerifyAccount;

    if (verified) {
      const loginRes = await loginIdentity(id, password);
      const identity = {
        ...loginRes,
        loginTime: new Date(),
        env: getReleaseChannel() || `dev`,
      };
      setIdentity(identity);
      await setAsyncStorageObject(`identity`, identity);
      if (loginRes.agreedTerms === true) {
        // user has agreed to terms of service. redirect to create profile
        return navigation.push(`CreateProfile`);
      }
      return navigation.navigate(`TermsScreen`, {
        identity_id: loginRes.id,
        prev_screen: `Verification`,
      });
    }

    if (res_status.includes(`expired`)) {
      this.setState({
        showErr: true,
        errMsg: `We're sorry, that the code has expired. Please request a new one and try again.`,
      });
    } else if (!verified) {
      this.setState({
        showErr: true,
        errMsg: `We're sorry, that code does not match our records. Please request a new one and try again.`,
      });
    }
    return null;
  };

  requestNewToken = async () => {
    const { route } = this.props;
    const id = route.params?.id;
    const contact = route.params?.contact;
    await client.mutate({
      mutation: REQUEST_VERIFY_TOKEN,
      variables: {
        id,
        contact,
      },
      fetchPolicy: `no-cache`,
    });
  };

  render() {
    const { route } = this.props;
    const contact = route.params?.contact;
    const { token, showErr, errMsg } = this.state;
    return (
      <KeyboardPaddingView>
        <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white, justifyContent: `flex-end` }}>
          <ScrollView alwaysBounceVertical={false} keyboardShouldPersistTaps="handled">
            <BackButton />
            <View style={localStyles.container}>
              <View style={localStyles.titleContainer}>
                <Text
                  style={{
                    color: `white`,
                    fontSize: Typography.largeHeaderFontSize,
                  }}
                >
                  Let's verify that it's you:
                </Text>
                {this.renderGreeting(contact)}
                <Text style={localStyles.greetingText}>Enter code below: </Text>
              </View>

              <View style={{ flex: 1, marginTop: 20 }}>
                <TextInput
                  ref={(input) => (this.codeInput = input)}
                  value={token}
                  onChangeText={(token) => this.setState({ token })}
                  style={localStyles.inputStyle}
                  autoCapitalize="none"
                  autoFocus
                  autoCorrect={false}
                  textContentType="oneTimeCode"
                  keyboardType="number-pad"
                  maxLength={6}
                  returnKeyLabel="Done"
                  returnKeyType="done"
                />

                <AuthButton
                  title="Verify"
                  onPress={async () => {
                    this.codeInput.clear();
                    await this.verify();
                  }}
                />
                <TouchableOpacity style={localStyles.clearButton} onPress={this.requestNewToken}>
                  <Text style={localStyles.titleStyle}>Resend Code</Text>
                </TouchableOpacity>
              </View>

              {showErr && <Text style={localStyles.titleStyle}>{errMsg}error</Text>}
            </View>
          </ScrollView>
        </SafeAreaView>
      </KeyboardPaddingView>
    );
  };
}

VerificationScreen.contextType = AppContext;
