import React, { useState, useEffect, useCallback } from 'react';
import { ScrollView, Text, TextInput, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Colors, Typography } from '../common-styles';
import { MeshIcon, BackButton, ThemedButton } from '../common-ui';
import { PronounTestIds } from './testLabels';
import { WizardLayout } from '../community-create/WizardLayout';
import { setProfilePronouns, movePrev, moveNext } from '../redux/createProfileSlice';
import { commonPropTypes } from '../common-util';

export const ProfilePronouns = ({ navigation }) => {
  const { profilePronouns } = useSelector((state) => state.createProfile);
  const [selectedPronouns, setSelectedPronouns] = useState(profilePronouns);
  const [ownPronoun, setOwnPronoun] = useState(``);
  const dispatch = useDispatch();
  const pronouns = [
    { id: 0, pronoun: `She/Her` },
    { id: 1, pronoun: `They/Them` },
    { id: 2, pronoun: `He/Him` },
    { id: 3, pronoun: `Enter your own` },
  ];

  const onSubmit = (skip = false) => {
    if (!skip) {
      dispatch(setProfilePronouns(selectedPronouns));
    } else {
      dispatch(moveNext());
    }
    navigation.push(`ProfileInterests`);
  };
  const goBack = useCallback(() => {
    dispatch(movePrev());
    navigation.goBack();
  }, [dispatch, navigation]);

  const renderPronounsList = () => {
    return pronouns.map((item) => {
      const found = selectedPronouns.find((selected) => selected.id === item.id);
      return (
        <ThemedButton
          key={item.id}
          clear
          outline
          testID={PronounTestIds.option}
          title={item.pronoun}
          titleStyle={localStyles.buttonText}
          buttonStyle={localStyles.buttonStyle}
          containerStyle={{ marginTop: 16 }}
          rightIcon={<MeshIcon name="select-circle" color={found ? Colors.brandPurple : Colors.iconColor} focused={!!found} />}
          onPress={() => selectOption(item)}
        />
      );
    });
  };

  const selectOption = (option) => {
    if (!selectedPronouns.find((selected) => selected.id === option.id)) {
      setSelectedPronouns([...selectedPronouns, option]);
    } else {
      const newPronouns = selectedPronouns.filter((selected) => selected.id !== option.id);
      setSelectedPronouns(newPronouns);
    }
  };

  const enterOwnPronoun = (text) => {
    setOwnPronoun(text);
    setSelectedPronouns([...selectedPronouns.filter((p) => p.id !== 3), { id: 3, pronoun: text }]);
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
  }, [navigation, goBack]);

  const hasCustomPronoun = selectedPronouns.find((item) => item.id === 3);
  return (
    <WizardLayout total={6} selector="createProfile">
      <ScrollView
        keyboardShouldPersistTaps="handled"
        style={{ paddingHorizontal: 16, paddingTop: 40 }}
        testID={PronounTestIds.screen}
        alwaysBounceVertical={false}
      >
        <View style={{ paddingBottom: 100 }}>
          <View style={{ paddingVertical: 10 }}>
            <View style={localStyles.blob} />
            <Text style={localStyles.headerText}>What are your pronouns?</Text>
          </View>
          <View>
            <Text style={localStyles.headerSubtext}>Optional. Select all that apply.</Text>
            {renderPronounsList(pronouns)}
          </View>
          {hasCustomPronoun && (
            <View style={{ marginTop: 16 }}>
              <TextInput
                autoFocus
                blurOnSubmit
                autoCorrect={false}
                testID={PronounTestIds.input}
                value={ownPronoun.pronoun}
                onChangeText={enterOwnPronoun}
                style={{ padding: 10, borderColor: Colors.lightGray, borderWidth: 1, borderRadius: 4 }}
              />
            </View>
          )}
          <View style={localStyles.visibleTagContainer}>
            <View style={localStyles.visibleTag}>
              <Text style={localStyles.visibleTagText}>Visible</Text>
              <MeshIcon name="eye" color={Colors.brandGreen} size={16} />
            </View>
            <Text style={localStyles.infoText}>
              <Text style={localStyles.infoText}>Pronouns are visible on your profile and can be changed later.</Text>
              {/* <Text
              onPress={this.toggleFeedbackModal}
              style={{ ...localStyles.infoText, color: Colors.brandPurple, fontWeight: `500` }}
            >
              {` `}
              Tell us more.
            </Text> */}
            </Text>
          </View>
          <ThemedButton
            rounded
            title="Continue"
            testID={PronounTestIds.continue}
            onPress={() => onSubmit()}
            disabled={!selectedPronouns.length}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginTop: 24, margiinHorizontal: 16 }}
            titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
          />
          <ThemedButton
            clear
            title="Skip for now"
            testID={PronounTestIds.skip}
            onPress={() => onSubmit(true)}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginTop: 20, margiinHorizontal: 16 }}
            titleStyle={{ ...Typography.text(`base`, `theme`, `bold`), marginLeft: 4 }}
          />
          {/* {showFeedbackModal && (
          <TellUsMoreModal
            isVisible={showFeedbackModal}
            toggleModal={this.toggleFeedbackModal}
            feedbackResponse={feedbackResponse}
            handleChange={this.handleTellUsMoreChange}
          />
        )} */}
        </View>
      </ScrollView>
    </WizardLayout>
  );
};

ProfilePronouns.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

// const TellUsMoreModal = ({ isVisible, toggleModal, feedbackResponse, handleChange }) => {
//   return (
//     <Modal isVisible={isVisible} onBackdropPress={toggleModal} avoidKeyboard>
//       <View style={localStyles.modal}>
//         <View style={{ marginBottom: 10 }}>
//           <Image source={require(`../../assets/images/pencil.png`)} style={{ alignSelf: `center` }} />
//         </View>
//         <View style={{ marginVertical: 10 }}>
//           <Text style={localStyles.headerText}>Tell us more</Text>
//         </View>
//         <View style={{ marginVertical: 10 }}>
//           <TextInput
//             blurOnSubmit
//             multiline
//             autoFocus
//             placeholder="Let us know your thoughts"
//             value={feedbackResponse}
//             onChangeText={handleChange}
//             style={localStyles.modalTextInput}
//           />
//         </View>
//         <ThemedButton
//           rounded
//           title="Done"
//           buttonStyle={{ padding: 15 }}
//           onPress={toggleModal}
//           containerStyle={{ marginTop: 10 }}
//         />
//       </View>
//     </Modal>
//   );
// };
// TellUsMoreModal.propTypes = {
//   isVisible: PropTypes.bool.isRequired,
//   toggleModal: PropTypes.func.isRequired,
//   feedbackResponse: PropTypes.string.isRequired,
//   handleChange: PropTypes.func.isRequired,
// };

const localStyles = {
  headerText: {
    fontFamily: `inter-semibold`,
    fontSize: Typography.largeFontSize,
    color: Colors.textBlack,
    fontWeight: `bold`,
  },
  headerSubtext: {
    fontSize: Typography.baseFontSize,
    color: Colors.gray,
    fontWeight: `500`,
    marginTop: 24,
  },
  buttonText: {
    fontWeight: `500`,
    color: Colors.brandPurple,
  },
  buttonStyle: {
    justifyContent: `space-between`,
    borderRadius: 4,
  },
  visibleTagContainer: {
    flexDirection: `row`,
    marginVertical: 10,
    alignItems: `center`,
    marginTop: 16,
  },
  visibleTag: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderRadius: 99,
    elevation: 5,
    shadowOffset: { height: 2, width: 2 },
    alignItems: `center`,
    shadowColor: Colors.darkGray,
    shadowOpacity: 0.1,
    backgroundColor: Colors.white,
    padding: 6,
    paddingHorizontal: 12,
  },
  visibleTagText: {
    fontSize: Typography.smallFontSize,
    color: Colors.brandGreen,
    marginRight: 8,
  },
  infoText: {
    fontSize: Typography.smallFontSize,
    color: Colors.gray,
    flexWrap: `wrap`,
    flexShrink: 2,
    marginLeft: 10,
  },
  modal: {
    backgroundColor: Colors.white,
    padding: 15,
    borderRadius: 20,
  },
  modalTextInput: {
    borderColor: Colors.lightGray,
    borderWidth: 1,
    padding: 10,
    borderRadius: 4,
    height: 65,
    maxHeight: 80,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(79,206,221,0.5)`,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
};
