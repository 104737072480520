import React, { useState } from 'react';
import { SectionList, View, Text, Switch, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView, ThemedButton, LoadingIndicator } from '../common-ui';
import { globalStyles, Colors, Typography } from '../common-styles';

export const NotificationsSettings = () => {
  const [settings, setSettings] = useState({
    replies: false,
    post_reactions: false,
    invitations: false,
  });

  const sections = [
    {
      title: `Push notifications`,
      data: [
        { title: `Replies`, valueKey: `replies`, type: `checkbox` },
        { title: `Post reactions`, valueKey: `post_reactions`, type: `checkbox` },
        { title: `Invitations`, valueKey: `invitations`, type: `checkbox` },
      ],
    },
    {
      title: `Cleanup notifications`,
      data: [{ title: `Quick manage`, type: `listItem` }],
    },
  ];

  const handleValueChange = (key, value) => {
    setSettings((previousState) => ({
      ...previousState,
      [key]: value,
    }));
  };

  const markAllRead = () => {};

  const isLastItem = (list, index) => {
    return index >= list.length - 1;
  };

  return (
    <SafeAreaView style={globalStyles.safeArea}>
      <SectionList
        sections={sections}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item, section, index }) =>
          item.type === `checkbox` ? (
            <CheckBoxItem
              title={item.title}
              valueKey={item.valueKey}
              type={item.type}
              value={settings[item.valueKey]}
              showDivider={!isLastItem(section.data, index)}
              onValueChange={handleValueChange}
            />
          ) : (
            <ListItem title={item.title} type={item.type} showDivider onPressAction={markAllRead} />
          )
        }
        renderSectionHeader={({ section: { title } }) => <SectionHeader title={title} />}
      />
    </SafeAreaView>
  );
};

const SectionHeader = ({ title }) => {
  return (
    <View style={localStyles.sectionHeaderContainer}>
      <Text style={localStyles.sectionHeaderTitle}>{title}</Text>
    </View>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

const CheckBoxItem = ({ title, valueKey, type, value, showDivider, onValueChange }) => {
  const handleValueChange = () => {
    onValueChange(valueKey, !value);
  };

  if (type !== `checkbox`) {
    return null;
  }

  return (
    <View>
      <View style={localStyles.itemContainer}>
        <Text style={localStyles.itemText}>{title}</Text>
        <Switch
          name={valueKey}
          ios_backgroundColor={Colors.switchBackground}
          thumbColor={Colors.switchThumbColor}
          trackColor={{ false: Colors.switchInactiveTrackColor, true: Colors.switchActiveTrackColor }}
          onValueChange={handleValueChange}
          value={value}
        />
      </View>
      {!!showDivider && <View style={localStyles.itemDivider} />}
    </View>
  );
};

CheckBoxItem.propTypes = {
  title: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.bool,
  showDivider: PropTypes.bool,
  onValueChange: PropTypes.func,
};

CheckBoxItem.defaultProps = {
  value: false,
  showDivider: false,
  onValueChange: () => {},
};

const ListItem = ({ title, type, loading, showDivider, onPressAction }) => {
  const handlePressAction = () => {
    onPressAction();
  };

  if (type !== `listItem`) {
    return null;
  }

  return (
    <View>
      <View style={localStyles.itemContainer}>
        <Text style={localStyles.itemText}>{title}</Text>
        <ThemedButton
          testID="NOTIFICATIONS_SETTINGS_BUTTON"
          rounded
          outline
          clear
          title="Mark all as read"
          color={Colors.deepPurple}
          buttonStyle={localStyles.itemButton}
          leftIcon={!!loading && <LoadingIndicator style={localStyles.itemLoadingIndicator} />}
          disabled={!!loading}
          onPress={handlePressAction}
        />
      </View>
      {!!showDivider && <View style={localStyles.itemDivider} />}
    </View>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  showDivider: PropTypes.bool,
  onPressAction: PropTypes.func,
};

ListItem.defaultProps = {
  loading: false,
  showDivider: false,
  onPressAction: () => {},
};

const localStyles = StyleSheet.create({
  sectionHeaderContainer: {
    backgroundColor: Colors.lightGray,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  sectionHeaderTitle: {
    ...Typography.text(`gray`, `bold`),
  },
  itemContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    paddingHorizontal: 16,
    paddingVertical: 15,
  },
  itemText: {
    flex: 1,
    marginRight: 10,
    ...Typography.text(`plusone`, `bold`),
  },
  itemButton: {
    paddingHorizontal: 20,
    paddingVertical: 7,
  },
  itemLoadingIndicator: {
    marginRight: 5,
  },
  itemDivider: {
    height: 1,
    backgroundColor: Colors.headerBottomBorderColor,
  },
});
