import { useCallback, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Activity, User } from '../../common-types/types';
import { impactAsync } from '../../common-ui';
import * as Sentry from '../../constants/Sentry';
import { setActionToTake } from '../../redux/feedSlice';
import { client, UPDATE_LIKE, UPDATE_ACTIVITY_LIKE_FRAGMENT } from '../../graphql';
import { AppContext } from '../../../AppContext';

/**
 * List of the actions that the user can perform is the like
 * is denied.
 * */
export const ACTIONS_TO_TAKE = {
  UPDATED_AGREEMENT: `updated_agreement`,
  JOIN: `join`,
  FROZEN_USER: `frozen_user`,
  DELETED_USER: [],
};

export const useLike = (activity: Activity) => {
  const { id, origin_group_id: group_id, own_like, latest_reactions, reaction_counts, is_frozen } = activity;
  const { user } = useContext(AppContext);
  const is_liked = !own_like;

  const likes = latest_reactions?.like?.filter((item: any) => item?.user?.handle !== `[deleted user]`) || [];
  const [firstLiker] = likes;
  const [currentLikeCount, setCurrentLikeCount] = useState(reaction_counts?.like || 0);

  const [displayedLiker, setDisplayedLiker] = useState<User | undefined>(firstLiker?.user);
  const dispatch = useDispatch();

  // eslint-disable-next-line require-await
  const likePost = useCallback(async () => {
    if (is_frozen) {
      dispatch(setActionToTake(ACTIONS_TO_TAKE.FROZEN_USER));
      return;
    }

    try {
      const input = {
        activity_id: id,
        is_liked,
        origin_actor_id: activity.actor, // FIXME: why? server should not trust client on this
        group_id, // FIXME: probably not necessary, id should be enough
        feed_slug: `group`, // FIXME: is this needed?
      };

      // send the like off to the server asynchronously, this will eventually return a
      // result that updates our cache
      client.mutate({ mutation: UPDATE_LIKE, variables: { input } });

      // ... but in the meantime, manually update the important parts of the cache to the expected values
      const { reaction_counts } = activity;

      const oldLikeCount = (reaction_counts && reaction_counts.like) || 0;
      const newLikeCount = oldLikeCount + (is_liked ? 1 : -1);
      setCurrentLikeCount(newLikeCount);

      client.writeFragment({
        id: `Activity:${activity.id}`,
        fragment: UPDATE_ACTIVITY_LIKE_FRAGMENT,
        data: {
          __typename: `Activity`,
          id: activity.id,
          own_like: is_liked,
          reaction_counts: { __typename: `ReactionCounts`, like: newLikeCount },
        },
      });
      if (newLikeCount === 1 && oldLikeCount === 0) setDisplayedLiker(user);
      if (newLikeCount === 0 && displayedLiker && displayedLiker?.id === user?.id) setDisplayedLiker(undefined);
      impactAsync(`like`);
    } catch (err) {
      Sentry.withScope((scope: any) => {
        scope.setExtras({ err, activity });
        Sentry.captureException(err as Error);
      });
    }
  }, [is_frozen, dispatch, id, is_liked, activity, group_id, user, displayedLiker]);

  return { likePost, currentLikeCount, likeHighlight: displayedLiker?.handle };
};
