import React, { useContext } from 'react';
import { useLazyQuery } from 'react-apollo';
import { AppContext } from '../../../AppContext';
import { ListedJoinedGroups } from '../../common-types/types';
import { LIST_GROUPS } from '../../graphql';
import { setJoinedCommunities } from '../../redux/feedSlice';
import { useAppDispatch } from '../../redux/store';

export const useRefreshCommunities = () => {
  const { user } = useContext(AppContext);
  const dispatch = useAppDispatch();

  const [fetchJoinedCommunities, { data, error }] = useLazyQuery<ListedJoinedGroups>(LIST_GROUPS, {
    fetchPolicy: `network-only`,
  });

  React.useEffect(() => {
    if (error) console.error('[RefreshError]', error.message);
    if (data?.listJoinedGroups) {
      const mappedCommunities = data?.listJoinedGroups.map(({ group }) => group) || [];
      dispatch(setJoinedCommunities(mappedCommunities));
    }
  }, [dispatch, data, error]);

  return {
    fetchJoinedCommunities: async () => {
      // ensure its only called with user still in context
      if (user?.id)
        await fetchJoinedCommunities({
          variables: { persona_id: user?.id },
        });
    },
    error,
  };
};
