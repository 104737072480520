// This Step had been removed from community-creation.
import React, { useState, useLayoutEffect, useCallback } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { Tooltip } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { WizardLayout } from './WizardLayout';
import { Colors, Typography } from '../common-styles';
import { BackButton, ThemedButton, MeshIcon } from '../common-ui';
import { movePrev, moveNext, setCommunityVerificationType } from '../redux/createCommunitySlice';
import { HomeNavigator } from '../common-types/navigation-types';

const verificationOptions = [
  {
    id: `email`,
    label: `Members must have \na verified email`,
    tooltip: (
      <Text style={Typography.text(`white`)}>
        <Text>Any member of</Text>
        <Text style={{ ...Typography.text(`bold`), color: Colors.white }}> mesh </Text>
        <Text>with a verified email is eligible to join the community and contribute</Text>
      </Text>
    ),
  },
  {
    id: `phone`,
    label: `Members must have \na verified phone number`,
    tooltip: (
      <Text style={Typography.text(`white`)}>
        <Text>Any member of</Text>
        <Text style={{ ...Typography.text(`bold`), color: Colors.white }}> mesh </Text>
        <Text>with a verified phone number is eligible to join the community and contribute</Text>
      </Text>
    ),
  },
  {
    id: `human`,
    label: `Members must be \na verified human`,
    tooltip: (
      <Text style={Typography.text(`white`)}>
        <Text>Any member of</Text>
        <Text style={{ ...Typography.text(`bold`), color: Colors.white }}> mesh </Text>
        <Text>that has verified that they are human is eligible to join the community and contribute</Text>
      </Text>
    ),
  },
];

const CommunityMemberVerification = () => {
  const navigation = useNavigation<HomeNavigator>();
  const dispatch = useDispatch();
  const { communityVerificationType } = useSelector((state: any) => state.createCommunity);
  const [verifyTypes, setVerifyTypes] = useState<string[]>(communityVerificationType);

  const handleMoveNext = useCallback(() => {
    dispatch(moveNext());
    dispatch(setCommunityVerificationType(verifyTypes));
    navigation.navigate(`CommunityEmail`);
  }, [verifyTypes, navigation, dispatch]);

  const handlePrev = () => {
    navigation.goBack();
    dispatch(movePrev());
  };

  const handlePressItem = useCallback(
    (item: any) => {
      if (verifyTypes.includes(item.id)) {
        setVerifyTypes(verifyTypes.filter((it: any) => it !== item.id));
      } else {
        setVerifyTypes([...verifyTypes, item.id]);
      }
    },
    [verifyTypes],
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `Member verification`,
      headerLeft: () => <BackButton onPress={handlePrev} />,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  return (
    <WizardLayout total={8} selector="createCommunity">
      <View style={styles.container}>
        <View style={{ padding: 10 }}>
          {verificationOptions.map((item, _index) => (
            <VerificationItem
              key={item.id}
              {...item}
              checked={verifyTypes.includes(item.id)}
              onPress={() => handlePressItem(item)}
            />
          ))}
        </View>

        <ThemedButton
          rounded
          title="Continue"
          titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
          onPress={handleMoveNext}
        />
      </View>
    </WizardLayout>
  );
};

type VerificationItemType = {
  label: string;
  tooltip: any;
  checked: boolean;
  onPress: () => void;
};

const VerificationItem: React.FC<VerificationItemType> = ({ label, tooltip, checked, onPress }) => {
  return (
    <View style={styles.verificationItemContainer}>
      <Text style={Typography.text(`bold`)}>{label}</Text>

      <View style={styles.verificationItemRight}>
        <Tooltip backgroundColor={Colors.brandPurple} popover={tooltip} height="auto">
          <MeshIcon focused name="info-circle" color={Colors.brandPurple} size={20} style={{ marginHorizontal: 20 }} />
        </Tooltip>

        <TouchableOpacity onPress={onPress}>
          <MeshIcon name={checked ? `checkbox-on` : `checkbox-off`} color={Colors.brandPurple} size={24} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  verificationItemContainer: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    marginBottom: 24,
  },
  verificationItemRight: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
  },
});

export default CommunityMemberVerification;
