import React, { useLayoutEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { ReactNativeFile } from 'apollo-upload-client';
import { View, Text, StyleSheet, TouchableOpacity, Image, Platform } from 'react-native';
import ImagePicker from 'react-native-image-crop-picker';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { BackButton, ThemedButton, MeshIcon } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { movePrev, moveNext, setCommunityImage } from '../redux/createCommunitySlice';
import { WizardLayout } from './WizardLayout';
import { CommunityImageTestIds } from './testLabels';
import * as Sentry from '../constants/Sentry';
import useMediaPermissons from '../hooks/useMediaPermissions';
import { HomeNavigator } from '../common-types/navigation-types';

const CommunityImage = () => {
  const navigation = useNavigation<HomeNavigator>();
  const { communityImage } = useSelector((state: any) => state.createCommunity);
  const dispatch = useDispatch();
  const { checkPermission } = useMediaPermissons();

  const handleMoveNext = (skip = false) => {
    if (skip) {
      dispatch(moveNext());
      navigation.navigate(`CommunityCategories`);
      return;
    }
    dispatch(setCommunityImage(communityImage));
    dispatch(moveNext());
    navigation.navigate(`CommunityCategories`);
  };

  const handleOpenImagePicker = async () => {
    try {
      const valid = await checkPermission();
      if (!valid) return;

      const image = await ImagePicker.openPicker({
        cropperCircleOverlay: true,
        cropping: true,
        compressImageQuality: 0.9,
        sortOrder: `asc`,
        smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
      });
      const imageFile = new ReactNativeFile({
        uri: image.path,
        type: Platform.OS === `android` ? `image/jpeg` : image.mime,
        name: uuidv4(),
      });

      dispatch(setCommunityImage(imageFile));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error as Error);
    }
  };

  useLayoutEffect(() => {
    const handlePrev = () => {
      navigation.goBack();
      dispatch(movePrev());
    };
    navigation.setOptions({
      title: `Community Details`,
      headerLeft: () => <BackButton onPress={handlePrev} />,
    });
  }, [dispatch, navigation]);

  return (
    <WizardLayout total={5} selector="createCommunity">
      <View style={styles.container}>
        <View style={styles.headerContainer} testID={CommunityImageTestIds.screen}>
          <Text style={styles.headerText}>Upload an avatar</Text>
        </View>

        <View style={styles.uploadContainer}>
          <TouchableOpacity
            testID="UPLOAD_PHOTO"
            style={!communityImage ? styles.uploadIconContainer : styles.imageContainer}
            onPress={handleOpenImagePicker}
          >
            <PreviewImage image={communityImage} />
          </TouchableOpacity>

          {communityImage && (
            <TouchableOpacity onPress={handleOpenImagePicker}>
              <View style={styles.editButton}>
                <MeshIcon name="camera" focused color="white" size={20} />
                <Text style={{ marginLeft: 4, color: `white` }}>Edit</Text>
              </View>
            </TouchableOpacity>
          )}
        </View>

        <ThemedButton
          testID={CommunityImageTestIds.continue}
          title="Continue"
          disabled={!communityImage}
          rounded
          onPress={handleMoveNext}
          titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
        />

        <View style={{ marginVertical: 8 }}>
          <ThemedButton
            clear
            testID={CommunityImageTestIds.skip}
            title="Skip for now &#8594;"
            onPress={() => handleMoveNext(true)}
            titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600` }}
          />
        </View>
      </View>
    </WizardLayout>
  );
};

type ImageType = {
  uri: string;
  mime: string;
  type: string;
};

type PreviewImageProps = {
  image: ImageType;
};

const PreviewImage: React.FC<PreviewImageProps> = ({ image }) => {
  const nativeImage = image && new ReactNativeFile(image);
  if (!nativeImage) {
    return (
      <View style={styles.uploadIconContainer}>
        <MeshIcon name="camera" focused size={45} color={Colors.brandPurple} style={styles.uploadIconStyle} />
        <Text style={styles.uploadTextHeading}>Upload</Text>
      </View>
    );
  }

  return <Image source={{ uri: nativeImage.uri }} style={styles.imageStyle} resizeMode="cover" />;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  headerContainer: {
    marginTop: 25,
    justifyContent: `center`,
    alignItems: `center`,
  },
  headerText: {
    fontSize: 18,
    fontWeight: `600`,
  },
  uploadContainer: {
    justifyContent: `center`,
    alignItems: `center`,
    height: 250,
  },
  editButton: {
    alignItems: `center`,
    flexDirection: `row`,
    justifyContent: `center`,
    backgroundColor: Colors.translucentBlack,
    paddingHorizontal: 18,
    marginTop: -40,
    marginBottom: 16,
  },
  uploadTextHeading: {
    color: Colors.brandPurple,
    fontWeight: `600`,
    marginTop: 8,
  },
  uploadIconContainer: {
    justifyContent: `center`,
    alignItems: `center`,
    borderWidth: 1,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderStyle: `dashed`,
    borderColor: Colors.imageBorderColor,
    width: 200,
    height: 150,
  },
  uploadIconStyle: {
    alignSelf: `center`,
  },
  imageContainer: {
    flex: 1,
    width: 180,
    height: 180,
    maxHeight: 180,
    maxWidth: 180,
    borderRadius: 100,
    justifyContent: `center`,
    overflow: `hidden`,
  },
  imageStyle: {
    flex: 1,
  },
});

export default CommunityImage;
