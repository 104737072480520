import { useMutation, useQuery } from '@apollo/react-hooks';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';
import {
  UpdateSettingInput,
  UpdateSettingsData,
  UpdateSettingsVars,
  UserNotifyPostData,
  UserNotifyPostVars,
} from '../../common-types/types';
import { refetchQueriesFor, UPDATE_SETTING_MUTATION, USER_NOTIFY_POST_SETTINGS } from '../../graphql';
import { RootState } from '../../redux/store';

type UserSettings = {
  fetchNotifyPostSettings?: boolean;
};

/**
 * Hook to get the user settings and update the settings
 * The motivation to create this hook comes from the fact that is been used in multiple places
 * so it is better to have a single hook to handle the settings
 *
 * Should use this hook:
 *
 * ```
 * | - BankAccount.jsx                     |
 * | - EmailEditor.jsx                     |
 * | - TextEditor.jsx                      |
 * | - UserCommunityPostNotifications.jsx  |
 * | - useBottomSheet.tsx                  |
 * | - CommunitySettings                   |
 * | - AddPaymentMethod.jsx                |
 * | - CategoryListView.jsx                |
 * | - ProfileSettings2.jsx                |
 * ```
 * Note: If you don't want to fetch the notifyPostSettings pass a false value to the fetchNotifyPostSettings prop
 *  */
export const useSettings = (props?: UserSettings) => {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { fetchNotifyPostSettings } = props || {};
  const { activeCommunity } = useSelector((state: RootState) => state.feed);
  const [userNotifySetting, setUserNotifySetting] = useState(``);
  const [updateSettings, { ...rest }] = useMutation<UpdateSettingsData, UpdateSettingsVars>(UPDATE_SETTING_MUTATION, {
    refetchQueries: refetchQueriesFor(`Setting`),
  });

  /** Hold this query if you're not going to require the notify settings  */
  const { data: notifyPostSettings } = useQuery<UserNotifyPostData, UserNotifyPostVars>(USER_NOTIFY_POST_SETTINGS, {
    variables: { group_id: activeCommunity?.id },
    fetchPolicy: `cache-and-network`,
    skip: fetchNotifyPostSettings,
  });

  useEffect(() => {
    if (notifyPostSettings) {
      const [setting] = notifyPostSettings.getUserCommunityPostNotificationSettings || [];
      const { value } = setting || {};
      setUserNotifySetting(value);
      setLoading(false);
    }
  }, [JSON.stringify(notifyPostSettings)]);

  /**
   * Update a setting given the updateInput
   * @param {UpdateSettingInput} updateInput  - The input to update the setting
   */
  const update = (updateInput: UpdateSettingInput) => {
    setLoading(true);
    updateSettings({
      variables: {
        input: updateInput,
      },
    });
  };

  /**
   * Update the settings for the key: user.setting.notify_post
   * @param value Value of setting notify_post
   * @param groupId group id related to this setting
   */
  const updateNotifyPostSetting = (value: `all` | `none`, groupId?: string) => {
    update({
      key: `user.setting.notify_post`,
      value,
      group_id: groupId,
      user_id: user?.id,
    });
  };

  return {
    userNotifySetting,
    update,
    updateNotifyPostSetting,
    ...rest,
    loading
  };
};
