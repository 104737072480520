import React from 'react';
import Modal from 'react-native-modal';
import { StyleSheet, useWindowDimensions, View, ViewStyle } from 'react-native';
import { Colors } from '../common-styles';

type OverlayModalProps = {
  toggle: () => void;
  isVisible: boolean;
  containerStyle?: ViewStyle;
  swipeToDismiss?: boolean;
};

export const OverlayModal: React.FC<OverlayModalProps> = ({
  isVisible,
  toggle,
  containerStyle = {},
  children,
  swipeToDismiss = false,
}) => {
  const { height, width } = useWindowDimensions();
  return (
    <Modal
      propagateSwipe
      isVisible={isVisible}
      onBackdropPress={toggle}
      onSwipeStart={swipeToDismiss ? toggle : () => null}
      style={{ margin: 0, justifyContent: `flex-end` }}
    >
      <View style={[localStyles.modalOuterContainer, { width }]}>
        <View style={[localStyles.modalInnerContainer, { minHeight: height * 0.85, maxHeight: height * 0.9 }, containerStyle]}>
          {children}
        </View>
      </View>
    </Modal>
  );
};

const localStyles = StyleSheet.create({
  modalOuterContainer: {
    backgroundColor: Colors.white,
    justifyContent: `flex-start`,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  modalInnerContainer: {
    marginVertical: 10,
  },
});
