import PropTypes from 'prop-types';
import React, { useRef, useCallback, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { RectButton, Swipeable } from 'react-native-gesture-handler';
import { Avatar, MeshIcon, Divider } from '../common-ui';
import { formatRelativeDate } from '../common-util';
import { updateChannelMuteStatus } from './chatServices';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { DUMMY_UUID } from '../../constants';
import { MeshVerifiedCheck } from '../images';
// import { MeshVerifiedCheck } from '../images';

export const ChannelPreview = React.memo(
  ({ channel, setActiveChannel, latestMessagePreview, onDelete, onAccept, onReject, status }) => {
    const { user } = React.useContext(AppContext);
    const navigation = useNavigation();
    const members = channel?.state?.members ? Object.values(channel.state?.members) : [];
    const chatMembers = members.filter((m) => m.user.id !== user?.id);
    const swipeableRef = useRef();
    const { shadowed, user: messageUser, text, created_at } = latestMessagePreview?.messageObject || {};
    const timeDate = created_at || channel.data.created_at;
    const unreadCount = channel.countUnread();

    const [isMuted, setIsMuted] = useState(channel?.muteStatus().muted);

    const handleMuteChannel = useCallback(async () => {
      const isMuted = channel?.muteStatus()?.muted;
      swipeableRef.current.close();
      if (channel?.id) {
        const res = await updateChannelMuteStatus(channel.type, channel.id, !isMuted);
        if (res !== null) {
          const muted = res.channel_mute;
          setIsMuted(muted);
        }
      }
    }, [channel, setIsMuted]);

    const handleDeleteChannel = useCallback(() => {
      swipeableRef.current.close();
      if (channel?.id) onDelete(channel?.id);
    }, [channel?.id, onDelete]);

    const handleMessageRequestAccept = useCallback(() => {
      swipeableRef.current.close();
      if (channel?.id) onAccept(channel?.id);
    }, [channel?.id, onAccept]);

    const handleMessageRequestReject = useCallback(() => {
      swipeableRef.current.close();
      if (channel?.id) onReject(channel?.id);
    }, [channel?.id, onReject]);

    const titleWithVerifyMark = (member) => (
      <>
        <Text>{member?.handle}</Text>
        {member?.identity_verified && <MeshVerifiedCheck height={12} width={12} style={{ marginLeft: 4 }} />}
      </>
    );

    const getTitle = useCallback(() => {
      if (channel?.data && typeof channel?.data === `string`) return channel?.data;
      //map members to handle only for simplicity
      const memberHandles = chatMembers.map((member) => member?.user);
      const [nextMember, ...moreMembers] = memberHandles;
      if (!nextMember) return null;
      if (!moreMembers.length) return titleWithVerifyMark(nextMember);
      if (moreMembers.length === 1) {
        return (
          <Text>
            {titleWithVerifyMark(nextMember)} & {titleWithVerifyMark(moreMembers[0])}
          </Text>
        );
      }
      return (
        <Text>
          {titleWithVerifyMark(nextMember)} & {moreMembers.length} other {moreMembers.length > 1 ? `people` : `person`}
        </Text>
      );
    }, [channel, chatMembers]);

    const renderAvatar = (chatMembers) => {
      //console.log("renderAvatar -> channel.data", channel.data)
      const [nextMember, ...moreMembers] = chatMembers;
      const { image: channel_image } = channel?.data || {};

      const avatar_user = channel_image ? { id: DUMMY_UUID, avatar_url: channel_image } : nextMember?.user;
      if (moreMembers.length) {
        const [otherMember] = moreMembers;
        return (
          <View style={{ marginRight: 8, top: -8, right: 8 }}>
            <Avatar navigation={navigation} user={nextMember?.user} size={40} />
            <View style={{ position: `absolute`, top: 15, left: 20 }}>
              <Avatar navigation={navigation} user={otherMember?.user} size={40} />
            </View>
          </View>
        );
      }
      return <Avatar border navigation={navigation} size={54} user={avatar_user} />;
    };

    const handleMessageRequestDelete = () => {
      handleDeleteChannel();
    };

    return (
      <Swipeable
        ref={swipeableRef}
        friction={2}
        leftThreshold={80}
        rightThreshold={41}
        renderRightActions={() => (
          <View style={{ flexDirection: `row` }}>
            {status === `chat` ? (
              <>
                <RectButton style={[styles.baseButton, styles.muteAction]} disabled onPress={handleMuteChannel}>
                  <Text style={Typography.text(`theme`, `bold`)}>{isMuted ? `Unmute` : `Mute`}</Text>
                </RectButton>
                <RectButton style={[styles.baseButton, styles.deleteAction]} disabled onPress={handleDeleteChannel}>
                  <Text style={Typography.text(`white`, `bold`)}>Delete</Text>
                </RectButton>
              </>
            ) : (
              <View style={{ flexDirection: `row` }}>
                <RectButton style={[styles.baseButton, styles.muteAction]} disabled onPress={handleMessageRequestAccept}>
                  <Text style={Typography.text(`theme`, `bold`)}>Accept</Text>
                </RectButton>
                <RectButton style={[styles.baseButton, styles.blockAction]} disabled onPress={handleMessageRequestReject}>
                  <Text style={Typography.text(`red`, `bold`)}>Block</Text>
                </RectButton>
                <RectButton
                  style={[styles.baseButton, styles.deleteAction]}
                  disabled
                  onPress={() => handleMessageRequestDelete()}
                >
                  <Text style={Typography.text(`white`, `bold`)}>Delete</Text>
                </RectButton>
              </View>
            )}
          </View>
        )}
      >
        <RectButton
          activeOpacity={0.9}
          underlayColor={Colors.chatPreviewUnderlayColor}
          style={{
            flexDirection: `row`,
            alignItems: `center`,
            paddingVertical: 8,
            paddingLeft: 24,
            paddingRight: 27,
            backgroundColor: Colors.chatPreviewBackground,
          }}
          onPress={() => setActiveChannel(channel)}
        >
          {unreadCount > 0 && (
            <View
              style={{
                position: `absolute`,
                left: 8,
                width: 8,
                height: 8,
                borderRadius: 999,
                backgroundColor: Colors.chatBadgeColor,
              }}
            />
          )}
          <View style={{ marginRight: 8 }}>{renderAvatar(chatMembers)}</View>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: `row`, alignItems: `center` }}>
              <View style={{ flexDirection: `row`, alignItems: `center`, flex: 1 }}>
                <Text style={{ ...Typography.text(`bold`), marginBottom: 2 }}>{getTitle()}</Text>
                {/* TODO-Temporary ignore as it's supported by Stream Chat */}
                {/* <MeshVerifiedCheck height={12} width={12} style={{ marginLeft: 4 }} /> */}
              </View>
              <Text style={{ ...Typography.text(`small`, `gray`), lineHeight: 20, marginRight: 4 }}>
                {formatRelativeDate(timeDate)}
              </Text>
              <View style={{ position: `absolute`, right: -13 }}>
                <MeshIcon name="chevron-right" size={16} color={Colors.iconColor} />
              </View>
            </View>
            <Text style={Typography.text(`small`, `gray`)} numberOfLines={2}>
              {shadowed && user?.id !== messageUser.id && `You blocked this user`}
              {!shadowed && (text ?? `nothing yet...`)}
            </Text>
          </View>
        </RectButton>
        <Divider />
      </Swipeable>
    );
  },
);
ChannelPreview.propTypes = {
  channel: PropTypes.object.isRequired,
  setActiveChannel: PropTypes.func.isRequired,
  //navigation: commonPropTypes.navigation().isRequired,
  latestMessagePreview: PropTypes.shape({
    messageObject: PropTypes.shape({
      text: PropTypes.string,
      created_at: PropTypes.instanceOf(Date),
      messageObject: PropTypes.object,
      shadowed: PropTypes.bool,
      user: PropTypes.object,
    }),
  }).isRequired,
  onDelete: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  status: PropTypes.string.isRequired,
};

ChannelPreview.defaultProps = {
  onDelete: () => {},
  onAccept: () => {},
  onReject: () => {},
};

const styles = StyleSheet.create({
  baseButton: {
    alignItems: `center`,
    justifyContent: `center`,
    paddingVertical: 16,
  },
  muteAction: {
    backgroundColor: Colors.chatPreviewMuteButton,
    paddingHorizontal: 21,
    minWidth: 95,
  },
  deleteAction: {
    backgroundColor: Colors.chatPreviewDeleteButton,
    paddingHorizontal: 17,
    minWidth: 78,
  },
  blockAction: {
    backgroundColor: Colors.lightGray,
    paddingHorizontal: 17,
    minWidth: 78,
  },
});
