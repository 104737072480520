import React from 'react';
import { ActivityIndicator, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Colors, Typography } from '../common-styles';
import { MeshIcon } from './MeshIcon';
import { Group, GroupMember } from '../common-types/types';
import { formatShortRelativeDate } from '../common-util';
import { MeshVerifiedCheck } from '../images';

type GroupCardRoundedType = {
  group: Group;
  group_member?: GroupMember;
  showMembershipPill?: boolean;
  handleCardPress: (group: Group) => void;
  handleArrowPress: (group: Group) => Promise<void> | void;
  loading?: boolean;
};

export const GroupCardRounded: React.FC<GroupCardRoundedType> = ({
  group,
  handleArrowPress,
  handleCardPress,
  showMembershipPill,
  group_member,
  loading = false,
}) => {
  const {
    name,
    avatar_url,
    group_member_count,
    description,
    latest_post,
    join_request_pending,
    application_pending,
    isVerified,
  } = group;

  let memberCount = `${group_member_count} member${group_member_count <= 1 ? `` : `s`}`;

  if (group_member_count > 1000) {
    memberCount = `${Math.floor(group_member_count / 100) / 10}k members`;
  }

  const shortDates = showMembershipPill && (application_pending || join_request_pending || group_member);
  return (
    <View key={group?.id} style={styles.communityItemContainer}>
      <TouchableOpacity style={styles.communityItem} activeOpacity={0.8} onPress={() => handleCardPress(group)}>
        {/* Avatar container */}
        <View>
          {avatar_url ? (
            <View style={styles.groupItemShadow}>
              <Image style={styles.communityLogo} resizeMode="cover" source={{ uri: avatar_url }} />
            </View>
          ) : (
            <View style={styles.groupItemShadow}>
              <Image style={styles.communityLogo} resizeMode="cover" source={require(`../../assets/images/node.png`)} />
            </View>
          )}
          {loading ? (
            <ActivityIndicator size={22} color={Colors.gray} style={{ position: `absolute`, top: 25, left: 35 }} />
          ) : undefined}
        </View>
        {/* Inner Container */}
        <View style={styles.innerContainer}>
          <View style={{ flexDirection: `row`, alignItems: `center` }}>
            <Text ellipsizeMode="tail" numberOfLines={1} style={styles.communityName}>
              {name}
            </Text>
            {isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
          </View>
          <View style={styles.informationContainer}>
            {/* member/pending pill */}
            {showMembershipPill ? (
              <MembershipPill pending={application_pending || join_request_pending} isMember={!!group_member} />
            ) : undefined}
            <View style={{ flex: 1, flexDirection: `row` }}>
              {/* member count */}
              <View style={{ flexDirection: `row`, marginRight: 10 }}>
                <MeshIcon name="people" color={Colors.black} size={14} />
                <Text style={styles.communityMemberCount}>{memberCount}</Text>
              </View>
              {/* last updated */}
              <View style={{ flexDirection: `row` }}>
                <MeshIcon name="clock" color={Colors.gray} size={14} />
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  style={[styles.lastUpdatedText, shortDates ? { maxWidth: 50 } : undefined]}
                >
                  {formatShortRelativeDate(latest_post)}
                </Text>
              </View>
            </View>
          </View>
          {/* description */}
          <View style={{ marginTop: 2 }}>
            <Text style={{ ...Typography.text(`smallest`) }} ellipsizeMode="tail" numberOfLines={3}>
              {description}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
      {/* right arrow */}
      <TouchableOpacity style={styles.communityJoinBtn} onPress={() => handleArrowPress(group)}>
        <MeshIcon name="chevron-right" color={Colors.brandPurple} size={24} />
      </TouchableOpacity>
    </View>
  );
};

type MembershipPillProps = {
  pending: boolean;
  isMember: boolean;
};
const MembershipPill: React.FC<MembershipPillProps> = ({ pending, isMember }) => {
  if (!pending && !isMember) return null;
  if (pending && !isMember)
    return (
      <View style={styles.membershipPill}>
        <Text style={styles.membershipPillText} testID="MEMBER_BADGE">
          Pending
        </Text>
      </View>
    );
  return (
    <View style={[styles.membershipPill, { backgroundColor: `#E5F8FA` }]}>
      <Text style={[styles.membershipPillText, { color: `#2A98A5` }]} testID="MEMBER_BADGE">
        Member
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  communityItemContainer: {
    height: 90,
    width: `90%`,
    marginVertical: 7,
    marginHorizontal: 16,
    borderRadius: 25,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.purpleGrey,
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
  },
  communityLogo: {
    width: 70,
    height: 70,
    marginLeft: 10,
    borderRadius: 35,
    backgroundColor: Colors.white,
  },
  communityName: {
    ...Typography.text(`base`, `black`),
    maxWidth: 190,
  },
  communityJoinBtn: {
    height: `100%`,
    width: 50,
    borderLeftWidth: 1,
    borderLeftColor: Colors.purpleGrey,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  communityMemberCount: {
    ...Typography.text(`smaller`, `plain`),
    color: Colors.gray,
    marginLeft: 4,
  },
  lastUpdatedText: {
    ...Typography.text(`smaller`, `plain`),
    color: Colors.gray,
    marginLeft: 4,
  },
  communityItem: {
    height: `100%`,
    flex: 1,
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
  },
  groupItemShadow: {
    shadowColor: Colors.avatarShadowColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  innerContainer: {
    height: `100%`,
    flex: 1,
    marginLeft: 9,
    paddingVertical: 5,
  },
  membershipPill: {
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 10,
    backgroundColor: Colors.cancelButton,
    justifyContent: `center`,
    alignItems: `center`,
    width: 55,
    marginRight: 4,
  },
  membershipPillText: Typography.text(`bold`, `center`, `smallest`, `gray`),
  informationContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 4,
  },
});
