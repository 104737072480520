import React, { useState, useEffect } from 'react';
import { Text, View, ScrollView, StyleProp, TextStyle, StyleSheet } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { ThemedButton, MeshIcon, BlobBlue, LoadingIndicator, BottomSheetModal, ListItem } from '../common-ui';
import Input from '../common-ui/inputs/Input';
import { Colors, Typography } from '../common-styles';
import { GET_EMAIL_VERIFICATION_CODE, client, UPDATE_SETTING_MUTATION, refetchQueriesFor } from '../graphql';
import { userEmailDescription, communityEmailDescription } from './helpers';
import { SETTING_DETAILS_FRAGMENT } from '../graphql/Fragments.gql';
import { setUserEmailVerified, setCommunityEmailVerified } from '../redux/contributionSlice';
import { AppContext } from '../../AppContext';
import { useSettings } from './hooks/useSettings';
import { Group, Setting } from '../common-types/types';
import { HomeNavigator, HomeNavigatorScreens, EditGroupEmail, RightDrawerNavigator } from '../common-types/navigation-types';

const USER_EMAIL_KEY = `user.setting.email`;

type ListItemProps = {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  rightElement?: React.ReactElement;
};
type EmailSettingItemProps = {
  setting: Setting;
  listItemProps: ListItemProps;
  group?: Pick<Group, `id`>;
  verified: boolean;
};

type EmailEditorRoute = RouteProp<HomeNavigatorScreens, `EmailEditor`>;

export const EmailSettingItem: React.FC<EmailSettingItemProps> = ({ setting, listItemProps, group, verified }) => {
  const navigation = useNavigation<HomeNavigator>();
  const { title, titleStyle, subtitleStyle, rightElement } = listItemProps;
  const { user } = React.useContext(AppContext);
  const navigateToEditor = () => {
    if (setting.key.startsWith(`user`) && user) {
      navigation.push(`EmailEditor`, {
        type: `user`,
        setting,
        verified,
      });
    } else if (setting.key.startsWith(`group`) && group) {
      navigation.push(`EmailEditor`, {
        type: `group`,
        group,
        setting,
        verified,
      } as EditGroupEmail);
    }
  };
  return (
    <ListItem
      title={title}
      verified={verified}
      titleStyle={titleStyle}
      subtitle={setting.value as string}
      subtitleStyle={subtitleStyle}
      rightElement={rightElement}
      onPress={navigateToEditor}
    />
  );
};

export const EmailEditor = () => {
  const navigation = useNavigation<HomeNavigator>();
  const { params } = useRoute<EmailEditorRoute>();

  const dispatch = useDispatch();
  const { type, setting, rightDrawer, verified } = params || {};
  const { group } = (params as EditGroupEmail) ?? {};
  const isCommunity = type === `group`;
  const prevValue = setting?.value as string;
  const { identity, user } = React.useContext(AppContext);
  const { verified: userEmailVerified, settings: user_settings } = useSettings(USER_EMAIL_KEY, { user_id: user?.id });
  const personalEmail = user_settings[USER_EMAIL_KEY]?.value as string;
  const [emailAddress, setEmailAddress] = useState<string>(prevValue);
  const [loading, setLoading] = useState(false);
  const [wrongEmailStructure, setWrongEmailStructure] = useState(false);
  const [personalEmailFlag, setPersonalEmailFlag] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const isSameEmail = emailAddress === prevValue;

  useEffect(() => {
    navigation.setOptions({
      headerTitle: isCommunity ? `Node Email` : `Email`,
    });
  }, [navigation, isCommunity]);

  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    refetchQueries: refetchQueriesFor(`Setting`),
  });

  const sendVerificationCodeInput = {
    id: setting?.id,
    key: setting?.key,
    value: emailAddress,
    user_id: user?.id,
    user_name: user?.name,
    from_community: isCommunity, // Flag to know to whether update the group email or the user email
    group_id: group?.id,
    identity_id: identity?.id,
  };

  const sendVerificationCode = async () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) {
      setWrongEmailStructure(true);
      return;
    }
    try {
      setLoading(true);
      const { data } = await client.query({
        query: GET_EMAIL_VERIFICATION_CODE,
        variables: sendVerificationCodeInput,
        fetchPolicy: `no-cache`,
      });

      const { getEmailVerificationCode4: verificationSetting } = data || {};

      if (verificationSetting) {
        const target = verificationSetting?.from_community ? `group` : `user`;
        // Also removes verification mark
        await saveNewEmail(target);
        setLoading(false);

        navigation.push(`EmailVerificationScreen`, {
          type,
          group,
          sendVerificationCodeInput,
          emailToDisplay: emailAddress,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(`ERROR sendVerificationCode:`, error);
    }
  };

  const verifyDirectly = () => {
    navigation.push(`EmailVerificationScreen`, {
      type,
      group,
      sendVerificationCodeInput,
      emailToDisplay: emailAddress,
    });
  };

  const saveNewEmail = async (type: `user` | `group`) => {
    try {
      const input = {
        id: setting?.id,
        key: setting?.key,
        value: emailAddress,
        user_id: type === `user` ? user?.id : undefined,
        group_id: type === `group` ? group?.id : undefined,
        identity_id: identity?.id,
      };
      const { errors } = await updateSetting({
        variables: { input },
        refetchQueries:
          type === `user` ? refetchQueriesFor(`Persona`, `Setting`) : refetchQueriesFor(`Group`, `GroupMember`, `Setting`),
      });
      if (errors?.length) throw errors[0];
      client.writeFragment({
        id: `Setting:${setting?.id}`,
        fragment: SETTING_DETAILS_FRAGMENT,
        data: { ...setting, value: emailAddress },
      });
      dispatch(type === `group` ? setCommunityEmailVerified(false) : setUserEmailVerified(false));
    } catch (error) {
      console.error(`[UpdateSettingError]: ${(error as Error).message}`);
    }
  };

  const savePersonalEmailAsGroupEmail = async () => {
    setLoading(true);
    await saveNewEmail(`group`);

    if (rightDrawer) {
      navigation.navigate(`HomeScreen`);
      // @ts-expect-error
      navigation.getParent<RightDrawerNavigator>(`RightDrawer`).openDrawer();
    } else {
      navigation.goBack();
    }
    setLoading(false);
  };

  const toggleEmailCheckbox = (value: boolean) => {
    setPersonalEmailFlag(value);
    if (value && (!personalEmail || personalEmail.trim().length === 0)) {
      setShowRedirectModal(true);
      return;
    }
    setEmailAddress(value ? personalEmail : ``);
  };

  const onChangeEmail = (value: string) => {
    setWrongEmailStructure(false);
    setEmailAddress(value);
    setPersonalEmailFlag(false);
  };

  return (
    <ScrollView keyboardShouldPersistTaps="handled" style={{ backgroundColor: Colors.white }}>
      <View style={{ flex: 1, backgroundColor: Colors.white }}>
        <View style={localStyles.mainContainer}>
          <BlobBlue width={32} height={35} style={{ position: `absolute`, left: 8, top: -3, zIndex: -1 }} />
          <Text style={{ ...Typography.text(`bold`, `large`), lineHeight: 32 }}>Update your email address</Text>
          <ToggleInput
            isCommunity={isCommunity}
            personalEmail={personalEmail}
            checked={personalEmailFlag}
            toggleEmailCheckbox={toggleEmailCheckbox}
          />
          <EmailInput
            isCommunity={isCommunity}
            emailAddress={emailAddress}
            onChangeEmail={onChangeEmail}
            error={wrongEmailStructure}
          />
          {type === `user` ? (
            <VerificationBox verified={!!verified && isSameEmail} verify={verifyDirectly} />
          ) : (
            <VerificationBox verified={verified || (personalEmailFlag && userEmailVerified)} verify={verifyDirectly} />
          )}

          {/* 2 */}
          {personalEmailFlag ? (
            <ThemedButton
              rounded
              title="Save"
              onPress={savePersonalEmailAsGroupEmail}
              buttonStyle={{ padding: 15 }}
              containerStyle={{ marginTop: 32 }}
              titleStyle={Typography.text(`plustwo`, `bold`, `white`)}
              leftIcon={loading && <LoadingIndicator style={{ marginRight: 10 }} />}
            />
          ) : (
            <ThemedButton
              rounded
              title={isSameEmail && emailAddress?.trim()?.length > 0 ? `Resend verification email` : `Send verification email`}
              onPress={sendVerificationCode}
              buttonStyle={{ padding: 15 }}
              containerStyle={{ marginTop: 32 }}
              titleStyle={Typography.text(`plustwo`, `bold`, `white`)}
              leftIcon={loading && <LoadingIndicator style={{ marginRight: 10 }} />}
              disabled={(isSameEmail && verified) || loading || !emailAddress || wrongEmailStructure} //only disabled if the email address is already verified and the same as we have already
            />
          )}
        </View>
      </View>
      <BottomSheetModal
        title="No personal email found"
        visible={!!showRedirectModal}
        onPressCancel={() => setShowRedirectModal(false)}
        onPressConfirm={() => navigation.navigate(`ProfileSettings`)}
        confirmTitle="Profile Settings"
      >
        <View style={{ marginHorizontal: 16 }}>
          <Text style={{ marginTop: 16, marginBottom: 32 }}>
            It seems you don't have a personal email set up, go to profile settings to set a personal email
          </Text>
        </View>
      </BottomSheetModal>
    </ScrollView>
  );
};

type EmailInputProps = {
  isCommunity: boolean;
  emailAddress: string;
  error: boolean;
  onChangeEmail: (text: string) => void;
};

const EmailInput: React.FC<EmailInputProps> = ({ isCommunity, emailAddress, onChangeEmail, error }) => {
  return (
    <>
      <Input
        autoCapitalize="none"
        isError={error}
        tooltip={isCommunity ? communityEmailDescription : userEmailDescription}
        tooltipWidth={isCommunity ? 200 : 300}
        tooltipHeight={isCommunity ? 200 : 300}
        value={emailAddress}
        onChangeText={onChangeEmail}
        placeholder="example: someone@email.com"
      />
      {error && emailAddress?.length ? <Text style={localStyles.emailError}>Enter a valid email</Text> : null}
    </>
  );
};

type VerificationBoxProps = {
  verified: boolean;
  verify: () => void;
};
const VerificationBox: React.FC<VerificationBoxProps> = ({ verified, verify }) => {
  return (
    <View style={{ flexDirection: `row` }}>
      {verified ? (
        <Text style={{ ...Typography.text(), color: Colors.materialGreen, marginTop: 8 }}>Verified</Text>
      ) : (
        <Text style={{ ...Typography.text(`theme`, `bold`), marginTop: 24 }} onPress={verify}>
          Enter verification code.
        </Text>
      )}
    </View>
  );
};

type ToggleInputProps = {
  isCommunity: boolean;
  personalEmail: string;
  checked: boolean;
  toggleEmailCheckbox: (next_value: boolean) => void;
};

const ToggleInput: React.FC<ToggleInputProps> = ({ isCommunity, personalEmail, checked, toggleEmailCheckbox }) => {
  if (!isCommunity) return null;
  return (
    <View style={{ marginTop: 12, marginBottom: 12, flexDirection: `row`, alignItems: `center` }}>
      {personalEmail ? (
        <MeshIcon
          onPress={() => toggleEmailCheckbox(!checked)}
          name={checked ? `checkbox-on` : `checkbox-off`}
          color={Colors.brandPurple}
          style={{ marginRight: 8 }}
        />
      ) : (
        <MeshIcon name="checkbox-off" color={Colors.gray} style={{ marginRight: 8 }} />
      )}

      <Text style={{ ...Typography.text(`plusone`), color: personalEmail ? Colors.textBlack : Colors.gray }}>
        Use personal email address
      </Text>
    </View>
  );
};

const localStyles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 16,
    justifyContent: `center`,
    marginTop: 22,
  },
  emailError: {
    ...Typography.text(`red`, `bold`),
    backgroundColor: Colors.blockUserBackgroundColor,
    marginTop: 8,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
