import React, { useContext } from 'react';
import { ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { ListItem, SafeAreaView, ThemedButton } from '../common-ui';
import { AppContext } from '../../AppContext';
import { Typography } from '../common-styles';
import { HackNavigator } from '../common-types/navigation-types';

export const ContextViewer = () => {
  const navigation = useNavigation<HackNavigator>();
  const context = useContext(AppContext);
  const entries = Object.entries(context);

  return (
    <SafeAreaView>
      <ScrollView>
        <View style={{ flexDirection: `row` }}>
          <ThemedButton
            clear
            title="See ReduxSlice"
            onPress={() => navigation.navigate(`ReduxSlice`)}
            containerStyle={{ marginBottom: 10, marginHorizontal: 16 }}
            titleStyle={Typography.text(`bold`, `link`)}
          />
        </View>
        {entries.map((entry) => {
          let showValue = `function () => void`;
          if (typeof entry[1] !== `function`) showValue = JSON.stringify(entry[1], null, 2);
          return <ListItem key={entry[0]} title={entry[0]} subtitle={showValue} />;
        })}
      </ScrollView>
    </SafeAreaView>
  );
};
