import React, { useContext, useState, useCallback } from 'react';
import { Alert, Linking, ScrollView, View, Text, TouchableHighlight, StyleSheet } from 'react-native';
import { HTML } from 'react-native-render-html';
import { useMutation } from 'react-apollo';
import { html } from '../../assets/terms';
import { Colors, Typography } from '../common-styles';
import { LoadingModal, SafeAreaView } from '../common-ui';
import { commonPropTypes, getAsyncStorageObject, removeAsyncStorageKeys } from '../common-util';
import { UPDATE_AGREED_TERMS } from '../graphql';
import { disablePush } from '../push-notification/helpers';
import { withTheme } from '../themes/ThemeProvider';
import { ContributionContext } from '../contribution/context/ContributionContext';
import { decodeSecureStore, fetchPersona, loginUser, removeAllPersonas, signTokens } from './helpers';
import { AppContext } from '../../AppContext';

const _TermsScreen = React.memo(({ navigation, route }) => {
  const { identity_id, prev_screen } = route?.params || {};
  const { clearContext: clearContributionContext } = useContext(ContributionContext);
  const { invite_token, logout: clearAppContext, setUser } = useContext(AppContext);

  const [loggingIn, setLoggingIn] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const [updateAgreedTerms, { data, error }] = useMutation(UPDATE_AGREED_TERMS, {
    fetchPolicy: `no-cache`,
  });

  const handleLogin = useCallback(
    async (loginResult) => {
      setLoggingIn(true);
      try {
        const pushPermissionStatus = await getAsyncStorageObject(`pushPermissionStatus`);
        const push_token = pushPermissionStatus && pushPermissionStatus.token;

        // Yes - log into primary persona
        const stored_persona_credentials = await decodeSecureStore(loginResult.secure_store);
        const personas = await Promise.all(stored_persona_credentials.map((persona) => fetchPersona(persona.id)));
        const password_for = stored_persona_credentials.reduce((result, persona) => {
          result[persona.id] = persona.password; // eslint-disable-line no-param-reassign
          return result;
        }, {});

        // Update with the appropriate credentials and call signTokens
        const inputs = personas.map((persona) => {
          const { __typename, ...cleaned_persona } = persona; // eslint-disable-line no-unused-vars
          const credentialed_persona = { ...cleaned_persona, password: password_for[persona.id] };
          return {
            persona: credentialed_persona,
            push_token,
            invite_token,
          };
        });

        //console.log(`logUserIn: inputs = ${JSON.stringify(inputs)}`);
        const signed_users = await signTokens(inputs);
        // console.log(`logUserIn: signed_users = ${JSON.stringify(signed_users)}`);
        if (!signed_users) {
          // this.setState({ errorMessage: `Persona login failed` });
          throw new Error(`Persona login failed`);
        }
        await loginUser(signed_users[0]);
        setUser(signed_users[0]);
        //navigateAfterLogin(navigation);
      } catch (err) {
        console.error(`Error thrown in TermsScreen handleLogin()`, err.message);
      } finally {
        setLoggingIn(false);
      }
    },
    [invite_token, setUser],
  );

  const handleAgreement = useCallback(
    async (updateAgreedTerms) => {
      if (updateAgreedTerms?.is_verified) {
        // this account is verified
        if (prev_screen === `Login` && updateAgreedTerms.secure_store) {
          // if secure_store exists then this user already has persona accounts
          // let's gunzip the secure_store and navigate to home
          await handleLogin(updateAgreedTerms);
        }
        if (!updateAgreedTerms.secure_store) {
          // no secure store? redirect to CreateProfile
          navigation.replace(`ProfileAvatar`);
        } else if (prev_screen === `AppChild`) {
          navigation.navigate(`HomeScreen`);
        }
      }
    },
    [handleLogin, prev_screen, navigation],
  );

  React.useEffect(() => {
    if (error) {
      console.error(`error thrown in updateAgreedTerms()`);
      return;
    }
    if (selectedIndex === 0) return;
    // update identity table
    const { updateAgreedTerms } = data || {};
    handleAgreement(updateAgreedTerms);
  }, [selectedIndex, data, error, handleAgreement]);

  const updateIndex = (selectedIndex) => {
    // 0 - decline terms and conditions
    // 1 - accept terms and conditions
    setSelectedIndex(selectedIndex);
    updateAgreedTerms({
      variables: {
        identity_id,
        agreed_terms: selectedIndex !== 0,
      },
    });
    if (selectedIndex === 0) {
      // inform user that they need to agree in order to use the app
      // log user out
      Alert.alert(
        `You must agree to our Terms and Conditions in order to proceed with account creation`,
        `You will be redirected back to the login screen`,
        [
          {
            text: `Cancel`,
            style: `cancel`,
          },
          {
            text: `OK`,
            style: `default`,
            onPress: logoutAll,
          },
        ],
        { cancelable: true },
      );
    }
  };

  const logoutAll = async () => {
    await disablePush();
    await removeAllPersonas();
    await removeAsyncStorageKeys([`identity`]);
    clearContributionContext();
    clearAppContext();
    //return navigation.replace(`Login`);
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        invertStickyHeaders
        stickyHeaderIndices={[1]}
        keyboardShouldPersistTaps="never"
        keyboardDismissMode="on-drag"
        style={{ flex: 1, backgroundColor: Colors.white }}
      >
        <View
          style={{
            justifyContent: `center`,
            zIndex: 100,
            backgroundColor: Colors.white,
          }}
        >
          <View style={styles.btnGroup}>
            <TouchableHighlight style={[styles.btnBase, styles.btnLeft]} onPress={() => updateIndex(0)}>
              <Text style={styles.btnLabel}>Decline</Text>
            </TouchableHighlight>
            <TouchableHighlight style={[styles.btnBase, styles.btnRight]} onPress={() => updateIndex(1)}>
              <Text style={styles.btnLabel}>Agree</Text>
            </TouchableHighlight>
          </View>
        </View>
        <HTML
          html={html}
          uri={`${meshconnect}/terms`}
          classesStyles={classStyles}
          tagsStyles={tagStyles}
          onLinkPress={(_event, href, _domObject) => {
            Linking.openURL(meshconnect + href);
          }}
        />
        <View style={{ height: 100 }} />
      </ScrollView>
      {loggingIn === true && <LoadingModal isVisible={loggingIn} content="Terms accepted. Logging you in..." />}
    </SafeAreaView>
  );
});

_TermsScreen.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

export const TermsScreen = withTheme(_TermsScreen);

//#region styles objects and const
const styles = StyleSheet.create({
  btnGroup: { flex: 1, flexDirection: `row`, paddingHorizontal: 12 },
  btnBase: { flex: 1, paddingVertical: 10, backgroundColor: `#EF548D`, borderRadius: 20, marginTop: 15, alignItems: `center` },
  btnRight: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
  btnLeft: { borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 1, borderColor: Colors.dividerColor },
  btnLabel: { ...Typography.text(`base`), color: Colors.white },
});
const classStyles = {
  'pure-g': { flex: 1, marginHorizontal: 25, marginVertical: 15 },
  footer: { flex: 1, justifyContent: `center` },
  bullets: { flex: 1, justifyContent: `center`, marginLeft: 100 },
};

const tagStyles = {
  p: { ...Typography.text(), marginTop: 10 },
  a: { ...Typography.text(`link`) },
};

const meshconnect = `https://meshcommunities.us`;
//#endregion
