import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useMutation } from 'react-apollo';
import { isEqual } from 'lodash';
import {
  Text,
  View,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  TextInput,
  Alert,
  NativeSyntheticEvent,
  TextInputSelectionChangeEventData,
  Keyboard,
} from 'react-native';
import { ReactNativeFile } from 'apollo-upload-client';
import { EventProvider } from 'react-native-outside-press';
import { AppContext } from '../../AppContext';
import { MAX_IMAGES_PER_POST } from '../../constants';
import { Colors, Typography } from '../common-styles';
import {
  HeaderCancelButton,
  HeaderSubmitButton,
  KeyboardPaddingView,
  LoadingIndicator,
  LoadingModal,
  BottomSheetModal,
} from '../common-ui';
import { compress, generateApolloFile, ImageMimeTypes, pickExpoVideo, pickOrderedImages } from '../common-util/FilePicker';
import { CREATE_PERSONAL_POST, refetchQueriesFor, UPDATE_PERSONAL_POST } from '../graphql';
import { getTextStyleForPost, defaultMetaStyle, MediaTypes, classifyActivity, adjustMetaStyle } from './helpers';
import PostContentVideo from './components/PostContentVideo';
import PostContentLinkPreview from './components/PostContentLinkPreview';
import { ContentEditor } from './ContentEditor';
import { useLinkPreview, TextSelection } from './hooks/useLinkPreview';
import useMediaPermissons from '../hooks/useMediaPermissions';
// import { Events, PendoTrackEvent } from '../pendo/events';
import { PostImageSelector } from './components/PostImageSelector';
import { ContentToolbar } from './ContentToolbar';
import { HomeNavigator, HomeNavigatorScreens, PostCreateProps, PostEditProps } from '../common-types/navigation-types';
import { SharedMedia } from '../common-types/types';
import PostContentPoll from './components/PostContentPoll';
import PostContentMeme from './components/PostContentMeme';
import { Events, PendoTrackEvent } from '../pendo/events';

type PostEditorRoute = RouteProp<HomeNavigatorScreens, `PersonalPostEditor`>;

export const CreatePostModals = {
  NeedsApproval: `NeedsApproval`,
  MediaUploaded: `MediaUploaded`,
};

//FIXME:
// eslint-disable-next-line complexity
export function PersonalPostEditor() {
  const navigation = useNavigation<HomeNavigator>();
  const route = useRoute<PostEditorRoute>();
  const memeRef = useRef<any>(null);

  const { checkPermission } = useMediaPermissons();

  const { mode = `create` } = route?.params || {};
  const activity = mode === `create` ? undefined : (route?.params as PostEditProps)?.activity;
  const shareData = mode === `create` ? (route?.params as PostCreateProps)?.data : undefined;
  const { user } = React.useContext(AppContext);
  const { width } = useWindowDimensions();
  const MAX_FONT_SIZE = width * (32 / 375);
  const [modal, setModal] = useState<string | null>();
  const [contentMode, setContentMode] = useState<MediaTypes>(classifyActivity(activity));
  const [prevContentMode, setPrevContentMode] = useState(MediaTypes.TEXT);
  const [currentText, setCurrentText] = useState(activity?.content || ``);
  const [postTitle, setPostTitle] = useState(activity?.title || ``);
  const [emojiFontSize, setEmojiFontSize] = useState(MAX_FONT_SIZE);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [shareProcessed, setShareProcessed] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<string>();

  const [existingImageMedia, setExistingImageMedia] = useState([...(activity?.imageUrls || [])]);
  const [existingVideoMedia, setExistingVideoMedia] = useState([...(activity?.video_urls || [])]);
  const [imageMediaToUpload, setImageMediaToUpload] = useState<ReactNativeFile[]>([]);
  const [videoMediaToUpload, setVideoMediaToUpload] = useState<ReactNativeFile[]>([]);

  const { linkPreview, setLinkPreview, considerLinkFetch, fetching } = useLinkPreview(activity?.linkPreview);
  const [resolvedMentions, setResolvedMentions] = useState([]);
  const [meta_style, setMetaStyle] = useState(defaultMetaStyle(width, activity?.meta_style));
  const scrollViewRef = useRef<ScrollView>(null);
  const textInputRef = useRef<TextInput>(null);
  const titleInputRef = useRef<TextInput>(null);
  const videoWidth = React.useRef();
  const videoHeight = React.useRef();
  const textInputSelection = React.useRef<TextSelection>();
  const [orderedImgs, setOrderedImgs] = useState<ReactNativeFile[]>([]);

  const [pollOptions, setPollOptions] = useState<string[]>();

  const [memePostDisabled, setMemePostDisabled] = useState(true);

  const headerTitle = useMemo(() => {
    if (mode === `create`) return `Create Post`;
    return `Edit Post`;
  }, [mode]);

  const submitTitle1 = useMemo(() => {
    return mode === `create` ? `Submit` : `Save`;
  }, [mode]);

  const [createPost] = useMutation(CREATE_PERSONAL_POST, {
    refetchQueries: refetchQueriesFor(`ProfileActivity`),
  });
  const [updatePost] = useMutation(UPDATE_PERSONAL_POST, {
    refetchQueries: refetchQueriesFor(`ProfileActivity`),
  });

  const isPostEqual = useCallback(() => {
    // original state
    const { activity } = route?.params?.mode === `edit` ? (route?.params as PostEditProps) : { activity: undefined };
    if (!activity) {
      console.warn(`[PostEditorError] trying to edit a post but there is no activity to compare`);
      return false;
    }

    return (
      !isEqual(activity?.title, postTitle) ||
      !isEqual(activity?.content, currentText) ||
      !isEqual(activity?.imageUrls, existingImageMedia) ||
      !isEqual(activity?.video_urls, existingVideoMedia) ||
      !isEqual(activity?.linkPreview, linkPreview) ||
      !isEqual(activity?.meta_style?.blobIndex, meta_style?.blobIndex) ||
      !isEqual([], imageMediaToUpload) ||
      !isEqual([], videoMediaToUpload)
    );
  }, [
    route?.params,
    linkPreview,
    currentText,
    postTitle,
    meta_style,
    existingImageMedia,
    existingVideoMedia,
    imageMediaToUpload,
    videoMediaToUpload,
  ]);

  const submitEnabled = useCallback(() => {
    const { frozen } = activity || {};
    const busy = !!loading || !!submitting || !!frozen || !!fetching;
    if (busy) return false;
    if (mode === `edit`) {
      const hasNewContent = isPostEqual();
      console.log(`submitEnabled -> isNew`, hasNewContent);
      return hasNewContent;
    }
    return true;
  }, [mode, activity, loading, submitting, isPostEqual, fetching]);

  const onAfterSubmit = useCallback(() => {
    try {
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
        if (!!videoMediaToUpload?.length && contentMode === MediaTypes.VIDEO) {
          setModal(CreatePostModals.MediaUploaded);
        } else {
          navigation.goBack();
        }
      }, 2000);

      // Track Pendo for Create/Edit Posts in Personal Feed
      PendoTrackEvent(Events.SUBMIT_PERSONAL_POST, {
        community_name: `Personal_Feed ${user?.handle}`,
        content_type: contentMode,
        action: mode,
        account: user?.id,
      });
    } catch (error) {
      console.error(`[PostEditor.SubmitError]:`, error);
      setSubmitting(false);
    }
  }, [videoMediaToUpload?.length, user?.handle, user?.id, contentMode, mode, navigation]);

  const createPayload = useMemo(() => {
    return {
      persona_id: user?.id,
      media_type: pollOptions ? MediaTypes.POLL : contentMode,
      content: contentMode === MediaTypes.POLL ? `` : currentText,
      link_url: linkPreview?.url || linkPreview?.entered,
      video_uploaded: contentMode === MediaTypes.VIDEO,
      meta_style: contentMode === MediaTypes.POLL ? null : adjustMetaStyle(meta_style, width),
      valid_mentions: resolvedMentions,
      poll: { options: pollOptions || [] },
      title: postTitle || ``,
    };
  }, [contentMode, user, pollOptions, currentText, linkPreview, meta_style, width, resolvedMentions, postTitle]);

  const submitCreate = useCallback(
    async (uri?: string) => {
      let memeMedia: any = null;
      if (uri?.length) {
        memeMedia = new ReactNativeFile({
          uri,
          type: `image/png`,
          name: `meme-image-${new Date().getTime()}`,
        });
      }
      let files;
      if (contentMode === MediaTypes.MEME) files = memeMedia;
      else if (contentMode === MediaTypes.IMAGE) files = imageMediaToUpload;
      else if (contentMode === MediaTypes.VIDEO) files = videoMediaToUpload;
      try {
        const response = await createPost({ variables: { input: createPayload, files } });
        console.log(`🚀 🚀  ->  submitCreate  response`, JSON.stringify(response, null, 3));
      } catch (err) {
        console.error(`[SubmitCreateError]:`, err);
      }
    },
    [contentMode, imageMediaToUpload, videoMediaToUpload, createPost, createPayload],
  );

  const submitEdit = useCallback(() => {
    const adjustedMetaStyle = adjustMetaStyle(meta_style, width);
    const { id, post_id, foreign_id } = activity || {};
    let media_to_upload;
    if (contentMode === MediaTypes.IMAGE) media_to_upload = imageMediaToUpload;
    else if (contentMode === MediaTypes.VIDEO) media_to_upload = videoMediaToUpload;
    try {
      updatePost({
        variables: {
          id,
          input: {
            post_id,
            foreign_id,
            set: {
              content: currentText ?? ` `,
              imageUrls: contentMode === MediaTypes.IMAGE ? existingImageMedia : [],
              video_urls: contentMode === MediaTypes.VIDEO ? existingVideoMedia : [],
              meta_style: adjustedMetaStyle,
              link_url: linkPreview?.url || linkPreview?.entered || null,
              title: postTitle || ``,
            },
            media_to_upload,
            media_type: contentMode,
            valid_mentions: resolvedMentions,
          },
        },
      });
    } catch (error) {
      console.error(`Error while editing the post`, error);
    }
  }, [
    activity,
    contentMode,
    currentText,
    linkPreview?.entered,
    linkPreview?.url,
    existingImageMedia,
    existingVideoMedia,
    imageMediaToUpload,
    videoMediaToUpload,
    meta_style,
    updatePost,
    width,
    resolvedMentions,
    postTitle,
  ]);

  // Effect #1: focus content input on mount
  useEffect(() => {
    if (meta_style.blobIndex === 10) {
      setTimeout(() => scrollViewRef.current?.scrollTo({ y: 0, animated: false }), 500);
    } else if (meta_style.blobIndex !== 10 && !hasScrolled) {
      setHasScrolled(true);
      setTimeout(() => scrollViewRef.current?.scrollTo({ y: width / 3, animated: false }), 500);
    }
  }, [meta_style.blobIndex, width, hasScrolled, setHasScrolled]);
  // Effect #2: update content type when linkpreview is setted
  useEffect(() => {
    if (linkPreview && contentMode === MediaTypes.TEXT) {
      setContentMode(MediaTypes.LINK);
    }
  }, [linkPreview, contentMode]);

  // Effect #4: navigation buttons: control submit btn press
  useEffect(() => {
    const hasContent =
      !!postTitle.trim().length ||
      !!currentText.trim().length ||
      !!existingImageMedia?.length ||
      !!imageMediaToUpload?.length ||
      !!existingVideoMedia?.length ||
      !!videoMediaToUpload?.length ||
      !!linkPreview;
    // @ts-ignore
    const enablePoll = !!postTitle && pollOptions?.reduce<boolean>((last: boolean, option: string) => !!option && last, true);
    // if for some reason it resolves to undefined, cast to boolean
    const disabled =
      (!hasContent && contentMode !== MediaTypes.MEME && contentMode !== MediaTypes.POLL) ||
      !submitEnabled() ||
      (contentMode === MediaTypes.POLL && !enablePoll) ||
      (contentMode === MediaTypes.MEME && memePostDisabled && !postTitle.trim().length);

    navigation.setOptions({
      headerLeft: () => (
        <HeaderCancelButton
          onPress={() => {
            removeMedia();
            navigation.goBack();
          }}
        />
      ),
      headerTitle,
      headerRight: () => (
        <HeaderSubmitButton
          title={submitTitle1}
          disabled={disabled}
          onPress={async () => {
            let memeUri = ``;
            if (contentMode === MediaTypes.MEME) {
              memeUri = await memeRef?.current?.onCapture();
              setImageMediaToUpload([
                new ReactNativeFile({
                  uri: memeUri,
                  type: `image/png`,
                  name: `meme-image-${new Date().toDateString()}`,
                }),
              ]);
            }
            if (mode === `create`) submitCreate(memeUri);
            else if (mode === `edit`) submitEdit();
            onAfterSubmit();
          }}
          testID="POST_CONFIRM_BUTTON"
        />
      ),
    });
  }, [
    mode,
    submitEnabled,
    currentText,
    navigation,
    onAfterSubmit,
    submitCreate,
    submitEdit,
    loading,
    meta_style,
    existingImageMedia,
    existingVideoMedia,
    imageMediaToUpload,
    videoMediaToUpload,
    linkPreview,
    resolvedMentions,
    submitTitle1,
    headerTitle,
    postTitle,
    pollOptions,
    contentMode,
    memeRef,
    memePostDisabled,
  ]);

  // Effect #5: Shared Media
  useEffect(() => {
    if (shareData?.mimeType && !shareProcessed) {
      const filename = shareData.data.replace(/(\w|\W)+%2F/, ``).split(`.`)[0];
      if (shareData.mimeType.includes(`text`)) {
        considerLinkFetch(shareData.data, { start: 0, end: shareData.data.length });
      }
      if (shareData.mimeType.includes(`image`)) {
        // fix share lib not mapping GIFs correctly
        if (shareData.mimeType === `image/*`) shareData.mimeType = ImageMimeTypes.GIF;
        setImageMediaToUpload([
          new ReactNativeFile({
            uri: shareData.data,
            type: shareData.mimeType,
            name: filename,
          }),
        ]);
        setContentMode(MediaTypes.IMAGE);
      }
      if (shareData.mimeType.includes(`video`)) {
        addVideoFromShare(shareData);
      }
    }
    setShareProcessed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [considerLinkFetch, route, shareData, shareProcessed]);

  const removeMedia = (index?: number) => {
    if (contentMode === MediaTypes.LINK) setLinkPreview(undefined);
    if (contentMode === MediaTypes.VIDEO) {
      setContentMode(MediaTypes.TEXT);
      setVideoMediaToUpload([]);
    }
    if (contentMode === MediaTypes.IMAGE && (index || index === 0)) {
      if (index < existingImageMedia.length) {
        setExistingImageMedia((prev) => prev.filter((_, i) => i !== index));
        setOrderedImgs((prev) => prev?.filter((_, i) => i !== index));
      } else {
        const uploadIndex = index - existingImageMedia.length;
        setImageMediaToUpload((prev) => prev.filter((_, i) => i !== uploadIndex));
        setOrderedImgs((prev) => prev?.filter((_, i) => i !== uploadIndex));
      }
    }
    // still in the same cycle, media values haven't update yet
    const mediaCount =
      contentMode === MediaTypes.IMAGE
        ? (existingImageMedia?.length ?? 0) + (imageMediaToUpload?.length ?? 0) + (linkPreview ? 1 : 0) - 1
        : (existingVideoMedia?.length ?? 0) + (videoMediaToUpload?.length ?? 0) + (linkPreview ? 1 : 0) - 1;
    if (mediaCount < 1) {
      setContentMode(MediaTypes.TEXT);
    }
  };

  const onChangeText = (newText: string) => {
    let cleanedText = newText;
    // Fix up autocapitalized stuff that we don't want autocapitalized
    cleanedText = cleanedText.replace(/(Http|Https|Www)/g, (m, _o, _s) => m.toLowerCase());
    setCurrentText(newText);
    getStyleForPost(cleanedText);
    // If we don't currently have a link preview, decide if we should add one
    if (
      !linkPreview &&
      !imageMediaToUpload.length &&
      !existingImageMedia.length &&
      !videoMediaToUpload.length &&
      !existingVideoMedia.length
    ) {
      considerLinkFetch(cleanedText, textInputSelection.current);
    }
  };

  const addPhoto = useCallback(async () => {
    Keyboard.dismiss();
    setLoading(true);
    setContentMode(MediaTypes.IMAGE);
    try {
      const isGranted = checkPermission();
      if (!isGranted) return;

      const { assets, rawAssets } = await pickOrderedImages(orderedImgs);
      setOrderedImgs(rawAssets);

      if (assets && assets.length > 0 && assets.length <= 10) {
        setImageMediaToUpload(assets);
        if (contentMode !== MediaTypes.IMAGE) setContentMode(MediaTypes.IMAGE);
      }
    } catch (error) {
      console.error(`Error while adding photo:`, error);
    } finally {
      setLoading(false);
    }
  }, [orderedImgs, checkPermission, contentMode]);

  const addVideo = async () => {
    Keyboard.dismiss();
    setLoading(true);
    setPrevContentMode(contentMode);
    try {
      const isGranted = checkPermission();
      if (!isGranted) return;

      setContentMode(MediaTypes.VIDEO_LOADING);
      await pickExpoVideo(
        ({ assets, didCancel, errorMessage }) => {
          if (didCancel) {
            setContentMode(MediaTypes.VIDEO);
            setProgress(``);
            return;
          }
          if (errorMessage) {
            // eslint-disable-next-line no-alert
            Alert.alert(errorMessage);
            setProgress(``);
            setContentMode(prevContentMode);
            return;
          }
          const [asset]: any[] = assets || [];
          if (asset.duration < 300) {
            const urls: any = [];
            const video = generateApolloFile(asset);
            videoHeight.current = asset?.height;
            videoWidth.current = asset?.width;
            urls.push(video);
            if (asset.thumbnail) {
              const thumbnail = generateApolloFile({ uri: asset.thumbnail });
              urls.push(thumbnail);
            }
            setExistingVideoMedia([]);
            setVideoMediaToUpload(urls);
            setProgress(``);
            setContentMode(MediaTypes.VIDEO);
          } else {
            // eslint-disable-next-line no-alert
            Alert.alert(`Submitted video exceeds 5 minutes.`);
            setProgress(``);
            setContentMode(prevContentMode);
          }
        },
        (current_progress) => {
          const percent = `${current_progress}%`;
          if (percent !== progress) setProgress(percent);
        },
      );
    } catch (error) {
      console.error(`Error while adding video:`, error);
      setContentMode(prevContentMode);
    } finally {
      setLoading(false);
    }
  };

  const addVideoFromShare = async (shareData: SharedMedia) => {
    setLoading(true);
    try {
      const filename = shareData.data.replace(/(\w|\W)+%2F/, ``);
      const urls: ReactNativeFile[] = [];
      setContentMode(MediaTypes.VIDEO_LOADING);
      const asset = await compress(
        {
          uri: shareData.data,
          type: shareData.mimeType,
          fileName: filename.split(`.`)[0],
        },
        (current_progress) => {
          const percent = `${current_progress}%`;
          if (percent !== progress) setProgress(percent);
        },
      );
      const video = generateApolloFile(asset);
      if (!video) throw new Error(`generateApolloFile failed`);
      urls.push(video);
      if (asset.thumbnail) {
        const thumbnail = generateApolloFile({ uri: asset.thumbnail });
        if (thumbnail) urls.push(thumbnail);
      }
      setProgress(``);
      setVideoMediaToUpload(urls);
      setContentMode(MediaTypes.VIDEO);
    } catch (error) {
      console.error(`Error while adding video:`, error);
      setContentMode(prevContentMode);
    } finally {
      setLoading(false);
    }
  };

  const handleIconOptionPress = useCallback((target: string) => {
    // Target can be text image | video | poll | meme
    setContentMode(target as MediaTypes);
  }, []);

  const addToResolvedMentions = (validatedMentions: any) => {
    setResolvedMentions(validatedMentions);
  };

  const onSelectionChange = (e: NativeSyntheticEvent<TextInputSelectionChangeEventData>) => {
    textInputSelection.current = e.nativeEvent.selection;
  };

  const handleContentSizeChange = useCallback(() => {
    if (titleInputRef?.current?.isFocused()) return;
    scrollViewRef?.current?.scrollToEnd({ animated: true });
  }, [titleInputRef]);

  const getStyleForPost = (currentText: any) => {
    const { hasBlob, style } = getTextStyleForPost(currentText, width, `expand`);
    const { fontSize, lineHeight, textAlign: align }: any = style;
    const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
    const hasEmoji = emojiRegex.test(currentText);
    setMetaStyle({
      ...meta_style,
      fontSize,
      lineHeight,
      align,
      hasBlob,
    });
    if (hasEmoji) setEmojiFontSize(fontSize);
  };

  const mediaCount =
    contentMode === MediaTypes.IMAGE
      ? (existingImageMedia?.length ?? 0) + (imageMediaToUpload?.length ?? 0)
      : (existingVideoMedia?.length ?? 0) + (videoMediaToUpload?.length ?? 0);
  const canAddImages =
    contentMode !== MediaTypes.LINK && existingImageMedia.length + imageMediaToUpload.length < MAX_IMAGES_PER_POST;
  const videoUrlsToSend = videoMediaToUpload.length ? videoMediaToUpload : existingVideoMedia;
  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <>
          <ScrollView
            ref={scrollViewRef}
            keyboardShouldPersistTaps="handled"
            keyboardDismissMode={Platform.select({ ios: `interactive`, default: `on-drag` })}
            onContentSizeChange={handleContentSizeChange}
          >
            <TextInput
              autoCorrect={false}
              multiline
              scrollEnabled
              ref={titleInputRef}
              value={postTitle}
              onChangeText={(text: string) => setPostTitle(text)}
              placeholder={contentMode === MediaTypes.POLL ? `What are you asking?` : `Give your post a title`}
              placeholderTextColor={Colors.textPlaceholder}
              maxLength={255}
              returnKeyType="done"
              clearButtonMode="always"
              style={localStyles.titleStyle}
            />

            {/* Content area: square text, or images, or link preview */}
            <>
              {/* removed: PostContentGallery onCloseImage={removeMedia} */}
              {contentMode === MediaTypes.IMAGE && (
                <PostImageSelector
                  imageUrls={existingImageMedia}
                  imagesToUpload={imageMediaToUpload}
                  addPhoto={addPhoto}
                  removePhoto={removeMedia}
                />
              )}

              {contentMode === MediaTypes.VIDEO_LOADING && (
                <View style={[localStyles.loadingContainer, { width, height: width }]}>
                  <LoadingIndicator size="large" />
                  <Text style={localStyles.progressText}>{progress}</Text>
                </View>
              )}

              {contentMode === MediaTypes.VIDEO && (
                <PostContentVideo
                  onScreen
                  width={width}
                  video_urls={videoUrlsToSend}
                  onCloseVideo={removeMedia}
                  videoWidth={videoWidth?.current}
                  videoHeight={videoHeight?.current}
                  from="CreatePost"
                  addVideo={addVideo}
                />
              )}

              {contentMode === MediaTypes.LINK && linkPreview && (
                <PostContentLinkPreview linkPreview={linkPreview} onClose={removeMedia} />
              )}

              {/* TODO: Need to add the poll and meme screens */}
              {contentMode === MediaTypes.POLL && (
                <PostContentPoll setPollOptions={(options) => setPollOptions(options)} pollOptions={pollOptions} />
              )}

              {contentMode === MediaTypes.MEME && (
                <EventProvider>
                  <View style={{ width, height: width }}>
                    <PostContentMeme ref={memeRef} onMemePostDisable={setMemePostDisabled} />
                  </View>
                </EventProvider>
              )}

              {/* Description area: arbitrary length text, unless text is in content */}
              {contentMode !== MediaTypes.POLL && contentMode !== MediaTypes.MEME && (
                <ContentEditor
                  ref={textInputRef}
                  groupId={null}
                  metaStyle={meta_style}
                  currentText={currentText}
                  contentMode={contentMode}
                  mediaCount={mediaCount}
                  emojiFontSize={emojiFontSize}
                  resolvedMentions={resolvedMentions}
                  addToResolvedMentions={addToResolvedMentions}
                  onChangeText={onChangeText}
                  onSelectionChange={onSelectionChange}
                />
              )}
            </>
          </ScrollView>
          {/* add blob, or photo/video to post */}
          <ContentToolbar
            contentMode={contentMode}
            canAddImages={canAddImages}
            handleIconOptionPress={handleIconOptionPress}
            addPhoto={addPhoto}
            addVideo={addVideo}
          />
          {/* Modals */}
          {submitting && !(modal === CreatePostModals.NeedsApproval) && (
            <LoadingModal
              isVisible={submitting}
              content={mode === `create` ? `Posting to Personal Feed` : `Saving updates to your post in Personal Feed`}
            />
          )}

          <BottomSheetModal
            showCancelBtn={false}
            title="Post Submitted"
            visible={modal === CreatePostModals.NeedsApproval || modal === CreatePostModals.MediaUploaded}
            onPressCancel={() => setModal(null)}
            confirmTitle="Got it"
            onPressConfirm={() => navigation.goBack()}
          >
            <View style={{ marginTop: 12, marginHorizontal: 16 }}>
              <Text style={{ marginBottom: 35, marginHorizontal: 16, ...Typography.text(`center`, `plusone`) }}>
                {modal === CreatePostModals.NeedsApproval
                  ? `Your post was submitted for moderator approval. You will receive a notification when your post has been approved.`
                  : `Your post will be published once processing is complete.`}
              </Text>
            </View>
          </BottomSheetModal>
        </>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
}

const localStyles = StyleSheet.create({
  loadingContainer: {
    backgroundColor: Colors.white,
    justifyContent: `center`,
    alignItems: `center`,
  },
  titleStyle: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    marginTop: 12,
    borderBottomColor: Colors.lightWarmGray,
    borderBottomWidth: 1,
    fontSize: 15,
    fontFamily: `inter-regular`,
  },
  progressText: {
    minWidth: 60,
    padding: 10,
    ...Typography.text(`plustwo`, `bold`, `darkGray`, `center`),
  },
});
