import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, View, Dimensions, FlatList } from 'react-native';
import { NetworkStatus } from 'apollo-client';
import { isEqual, unionBy } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from 'react-apollo';
import { Colors, Spacing } from '../common-styles';
import { CenterColumn, Divider, EmptyList, ErrorBoundary, LoadingIndicator, QueryError } from '../common-ui';
import PostContent from '../post/components/PostContent';
import PostFooter from '../post/components/PostFooter';
import PostHeader from '../post/components/PostHeader';
import { ProfileHeader } from './ProfileHeader';
import { PROFILE_FEED_QUERY, PROFILE_FEED_SUBSCRIPTION } from '../graphql';
import { profileStyles } from './profileStyles';
import { ProfileFeedData, ProfileFeedInput } from '../common-types/types';
import PostPolls from '../post/components/PostPolls';

type ProfileFeedType = {
  user: any;
  showPostOptionsModal: any;
};

const ProfileFeed: React.FC<ProfileFeedType> = ({ user, showPostOptionsModal }) => {
  const [viewableItems, setViewableItems] = useState([]);
  const navigation = useNavigation<any>();
  const [lastActivity, setLastActivity] = useState(``);

  const { loading, data, fetchMore, refetch, error, subscribeToMore, networkStatus } = useQuery<
    ProfileFeedData,
    ProfileFeedInput
  >(PROFILE_FEED_QUERY, {
    variables: {
      input: { feed_slug: `user`, actor_id: user.id },
      limit: 15,
    },
    skip: !user?.id,
    fetchPolicy: `network-only`,
    notifyOnNetworkStatusChange: true,
  });

  const onEndReached = useCallback(() => {
    if (!loading) {
      if (lastActivity === null) return;
      fetchMore({
        variables: { id_lt: lastActivity?.length > 0 ? lastActivity : data?.getProfileFeedV2?.lastActivity },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          setLastActivity(fetchMoreResult.getProfileFeedV2.lastActivity);
          if (!fetchMoreResult) {
            return prev;
          }
          const moreActivities = fetchMoreResult.getProfileFeedV2.activities;
          const prevActivities = prev.getProfileFeedV2.activities;
          const newActivities = unionBy(prevActivities, moreActivities, (g: any) => g.id);

          return {
            ...prev,
            getProfileFeedV2: {
              ...prev.getProfileFeedV2,
              activities: newActivities,
              lastActivity,
            },
          };
        },
      });
    }
  }, [setLastActivity, lastActivity, data, fetchMore]);

  const renderActivity = ({ item: activity, index }: any) => {
    const { id, origin_group_id: group_id } = activity;
    const width = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);
    if (activity.removed) return null;
    const onScreen = viewableItems.reduce((prev, current) => {
      if (index === current) return true;
      return prev;
    }, false);

    return (
      <CenterColumn>
        <View style={{ width }} testID="PROFILE_FEED_ACTIVITY">
          <ErrorBoundary header="Sorry, an error occurred displaying this post">
            <PostHeader
              showGroup={activity.__typename === `Activity`}
              activity={activity}
              showOptionsMenu={() => showPostOptionsModal(activity)}
            />

            <PostContent onScreen={onScreen} activity={activity} width={width} textOverflowMode="expand" />
            <PostPolls activity={activity} />
            <Divider />
            <PostFooter
              activity={activity}
              onPressComment={() =>
                navigation.push(`PostDetail`, {
                  id,
                  group_id,
                  addComment: true,
                  isPersonalFeed: activity.__typename === 'ProfileActivity',
                })
              }
            />
          </ErrorBoundary>
        </View>
      </CenterColumn>
    );
  };

  const onViewableItemsChanged = useRef(({ viewableItems: currentViewable }: any) => {
    const current = currentViewable.map((item: any) => item.index);
    if (!current.length) current.push(0);
    if (!isEqual(viewableItems, current)) {
      setViewableItems(current);
    }
  }).current;

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: PROFILE_FEED_SUBSCRIPTION,
      variables: {
        persona_id: user.id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        try {
          const { getProfileFeedV2: newData } = subscriptionData?.data || {};
          const { activities: oldActivities } = prev.getProfileFeedV2 || [];
          let result = oldActivities;
          if (newData?.activities) {
            result = unionBy(newData.activities, oldActivities, (a: any) => a.id);
          }
          return {
            getProfileFeedV2: {
              __typename: `ProfileFeedResponse`,
              user_is_blocked: subscriptionData.data.getProfileFeedV2?.user_is_blocked || false,
              activities: result,
            },
          };
        } catch (err: any) {
          console.error(`Error thrown in subscribeToMore for Profile Feed Subscription`, err.message);
          return prev;
        }
      },
    });
    return unsubscribe;
  }, [user?.id, subscribeToMore]);

  if (networkStatus === NetworkStatus.loading) {
    return (
      <View style={profileStyles.feedContainer}>
        <ProfileHeader user={user} />
      </View>
    );
  }

  if (error) {
    return <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />;
  }

  return (
    <FlatList
      style={{
        height: Platform.select({ web: Dimensions.get(`window`).height - 48 * 2 }),
        backgroundColor: Colors.white,
      }}
      contentContainerStyle={{ justifyContent: `center` }}
      ListEmptyComponent={<EmptyList isLoading={loading} message="No posts to show" />}
      ListHeaderComponent={<ProfileHeader user={user} />}
      ListFooterComponent={
        loading ? <LoadingIndicator /> : <View style={{ flex: 1, backgroundColor: Colors.dividerColor, height: 100 }} />
      }
      ItemSeparatorComponent={() => <View style={{ backgroundColor: Colors.dividerColor, height: 1 }} />}
      data={(data && data.getProfileFeedV2.activities) || []}
      refreshing={networkStatus === NetworkStatus.refetch}
      onRefresh={() => refetch()}
      viewabilityConfig={{ itemVisiblePercentThreshold: 50 }}
      onViewableItemsChanged={onViewableItemsChanged}
      onEndReachedThreshold={0.3}
      onEndReached={onEndReached}
      keyExtractor={(activity) => activity.id}
      renderItem={renderActivity}
      testID="PROFILE_FEED_SCROLLVIEW"
    />
  );
};

export default ProfileFeed;
