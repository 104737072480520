import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import { useQuery } from 'react-apollo';
import { useNavigation } from '@react-navigation/native';
import { LIST_GROUP_MEMBERS } from '../graphql';
import { LoadingIndicator, ListItem, Avatar, ThemedButton } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { BirthdayImage } from '../images/BirthdayImage';
import { handleCreateConversation } from './memberInfoHelpers';
import { AppContext } from '../../AppContext';

export const BirthdaysByCommunity = () => {
  const { user: me, chatNotifications, setChatNotifications } = useContext(AppContext);
  const navigation = useNavigation();
  const [members, setMembers] = useState([]);

  const { data, loading, error } = useQuery(LIST_GROUP_MEMBERS, {
    //TODO: We need to receive the group_id from the notification screen, the 'hand-written' id below is from Cats community (dev)
    variables: { group_id: `eacf6580-083a-4da8-991d-0446ba5382b8`, offset: 0, limit: 40 },
    fetchPolicy: `network-only`,
  });

  if (error) console.error(`error ocurred while trying to get the group members - BirthdaysByCommunity.jsx ${error.message}`);

  useEffect(() => {
    filterUsersByBirthdate();
  }, [data, filterUsersByBirthdate]);

  const isHavingBirthday = (date) => {
    const today = new Date();
    /*
    When we passed the ISO string directly to the Date() constructor,
    the getUTC* methods return the exact same time components that are present in the ISO string.
    */
    const convertedDate = new Date(date);
    return convertedDate.getUTCDate() === today.getDate() && convertedDate.getUTCMonth() === today.getMonth();
  };

  const filterUsersByBirthdate = useCallback(() => {
    const filteredUsers = data?.listGroupMembers.filter((user) => isHavingBirthday(user?.persona?.dob));
    setMembers(filteredUsers);
  }, [data?.listGroupMembers]);

  const handleGoToChat = useCallback(
    async (selectedUser) => {
      try {
        if (!selectedUser) return;
        await handleCreateConversation({ me, chatNotifications, setChatNotifications }, selectedUser, navigation);
      } catch (error) {
        console.error(`Error thrown in handleGoToChat() in BirthdaysByCommunity.jsx`, error.message);
      }
    },
    [me, chatNotifications, setChatNotifications, navigation],
  );

  const keyExtractor = (item) => item.id;

  const listHeader = () => {
    return (
      <View style={{ marginTop: 9, alignItems: `center` }}>
        <BirthdayImage />
        <Text style={{ ...Typography.text(`center`, `small`), marginHorizontal: 63, marginTop: 10 }}>
          Some of your community members have birthdays today! Let's wish them a Happy Birthday
        </Text>
      </View>
    );
  };

  const renderItem = useCallback(
    ({ item }) => {
      const { persona } = item || {};
      const { handle, name, pronouns } = persona || {};

      const personaTitle = <Text style={{ ...Typography.text(`base`) }}>{handle}</Text>;

      const Subtitle = (
        <View style={{ flexDirection: `row`, alignItems: `center`, flex: 1 }}>
          <Text style={{ color: Colors.textGray }}>{name}</Text>
          {pronouns !== null && (
            <View style={{ flexDirection: `row`, justifyContent: `center`, alignItems: `center` }}>
              {pronouns && pronouns.length > 0 && <Text style={{ fontWeight: `bold`, fontSize: 22 }}> · </Text>}
              <Text style={{ color: Colors.textGray }}>{pronouns && pronouns.length > 0 && pronouns[0].pronoun}</Text>
            </View>
          )}
        </View>
      );

      const renderMessageButton = (
        <ThemedButton
          rounded
          clear
          outline
          title="Message"
          onPress={() => handleGoToChat(persona)}
          buttonStyle={{ paddingVertical: 4, height: 25, width: 89 }}
          titleStyle={{ ...Typography.text(`small`), color: Colors.iconColor }}
        />
      );

      return (
        <ListItem
          title={personaTitle}
          subtitle={Subtitle}
          leftAvatar={<Avatar user={persona} size={48} />}
          rightElement={renderMessageButton}
          onPress={() => console.log(`- member pressed -`)}
        />
      );
    },
    [handleGoToChat],
  );

  const emptyBirthdayList = () => {
    return (
      <View style={styles.loadingContainer}>
        <Text style={{ ...Typography.text(), marginTop: 50 }}>No one is having birthday today in this community.</Text>
      </View>
    );
  };

  if (loading)
    return (
      <View style={styles.loadingContainer}>
        <LoadingIndicator />
      </View>
    );

  return (
    <View style={styles.container}>
      <FlatList
        data={members}
        ListHeaderComponent={listHeader}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListEmptyComponent={emptyBirthdayList}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 22,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
  },
});
