import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const PaypalImage = (props) => {
  return (
    <Svg width={66} height={46} viewBox="0 0 66 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0h1300v7847H0z" />
        <Path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill="#1B3D92"
        />
        <G fill="#FFF">
          <Path
            d="M41.745 17.299c.3-1.929-.002-3.24-1.034-4.43C39.572 11.561 37.518 11 34.89 11h-7.628c-.537 0-.995.395-1.078.93l-3.177 20.347a.66.66 0 00.647.765h4.71l-.326 2.082a.577.577 0 00.566.668h3.97c.47 0 .87-.345.943-.814l.039-.204.748-4.79.048-.264a.958.958 0 01.943-.815h.594c3.845 0 6.857-1.578 7.736-6.142.368-1.907.178-3.499-.794-4.618a3.788 3.788 0 00-1.087-.846"
            opacity={0.68}
          />
          <Path
            d="M41.745 17.299c.3-1.929-.002-3.24-1.034-4.43C39.572 11.561 37.518 11 34.89 11h-7.628c-.537 0-.995.395-1.078.93l-3.177 20.347a.66.66 0 00.647.765h4.71l1.182-7.578-.036.238c.083-.536.537-.931 1.074-.931h2.238c4.396 0 7.839-1.804 8.844-7.021.03-.154.055-.304.078-.451"
            opacity={0.7}
          />
          <Path d="M30.819 17.324a.963.963 0 01.943-.814h5.98c.708 0 1.369.047 1.972.146a8.618 8.618 0 01.979.22 5.355 5.355 0 011.053.424c.3-1.93-.002-3.242-1.035-4.43-1.137-1.31-3.191-1.87-5.82-1.87h-7.628c-.537 0-.995.395-1.078.93l-3.177 20.347a.66.66 0 00.647.765h4.71l1.182-7.578 1.271-8.14z" />
        </G>
      </G>
    </Svg>
  );
};
