import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../common-styles';
import { MeshIcon } from '../../common-ui';

type PostContentPollType = {
  pollOptions?: string[];
  setPollOptions: (options: string[]) => void;
};

const PostContentPoll: React.FC<PostContentPollType> = React.memo(({ pollOptions, setPollOptions }) => {
  const [options, setOptions] = useState(pollOptions || ['', '']);
  const firstOptionRef = useRef<any>();

  const handleAddOption = () => {
    setOptions((prev: any) => [...prev, '']);
  };

  const handleChangeText = useCallback(
    (text: string, index: number) => {
      setOptions((prev: any) => {
        return prev.map((it: string, idx: number) => (idx === index ? text : it));
      });
      setPollOptions(options);
    },
    [options],
  );

  useEffect(() => {
    setPollOptions(options);
  }, [options]);

  useEffect(() => {
    firstOptionRef?.current?.focus();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.optionsWrapper}>
        {options.map((option, index) => (
          <Option
            option={option}
            index={index}
            firstOptionRef={firstOptionRef}
            key={index}
            handleChangeText={handleChangeText}
            handleRemoveOption={() => setOptions((prev) => prev.filter((it, idx) => index !== idx))}
          />
        ))}

        <View style={styles.addWrapper}>
          <TouchableOpacity style={styles.addElement} onPress={handleAddOption}>
            <MeshIcon name="circle-plus" color={Colors.iconColor} size={24} />

            <Text style={{ color: Colors.gray, marginLeft: 8 }}>Add Option</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
});

const Option: React.FC<any> = ({ option, index, firstOptionRef, handleChangeText, handleRemoveOption }) => {
  return (
    <View style={styles.optionWrapper}>
      <TextInput
        ref={index == 0 ? firstOptionRef : null}
        placeholder={`Option ${index + 1}`}
        autoCorrect={false}
        placeholderTextColor={Colors.gray}
        style={styles.optionInput}
        defaultValue={option}
        onChange={(e: any) => handleChangeText(e?.nativeEvent?.text, index)}
      />

      {index >= 2 && (
        <TouchableOpacity onPress={handleRemoveOption} style={styles.closeOption}>
          <MeshIcon name="circle-x" color={Colors.iconColor} size={20} />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: `flex`,
    justifyContent: `flex-start`,
  },
  titleWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
  },
  optionsWrapper: {
    padding: 12,
  },
  optionWrapper: {
    marginBottom: 12,
    position: `relative`,
  },
  optionInput: {
    fontSize: 14,
    borderColor: Colors.lightGray,
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 4,
  },
  addWrapper: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 4,
  },
  addElement: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 4,
  },
  closeOption: {
    position: `absolute`,
    top: 8,
    right: 8,
    zIndex: 10,
  },
});

export default PostContentPoll;
