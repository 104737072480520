export const PresetTestIds = {
  screen: `COMMUNITY_PRESET_SCREEN`,
  community: `COMMUNITY_PRESET_COMMUNITY`,
  channel: `COMMUNITY_PRESET_CHANNEL`,
};

export const CommunityNameTestIds = {
  screen: `COMMUNITY_NAME_SCREEN`,
  input: `COMMUNITY_NAME_INPUT`,
  continue: `WIZARD_NEXT`,
};

export const CommunityEmailTestIds = {
  screen: `COMMUNITY_EMAIL_SCREEN`,
  input: `COMMUNITY_EMAIL_INPUT`,
  continue: `WIZARD_NEXT`,
};

export const CommunityImageTestIds = {
  screen: `COMMUNITY_IMAGE_SCREEN`,
  skip: `WIZARD_SKIP`,
  continue: `WIZARD_NEXT`,
};

export const CommunityCategoryTestIds = {
  screen: `COMMUNITY_CATEGORY_SCREEN`,
  option: `CATEGORY_OPTION`,
  continue: `WIZARD_NEXT`,
};

export const CommunityAgeTestIds = {
  any: `ANY_AGE`,
  eighteen: `EIGHTEEN`,
  twentyOne: `TWENTY_ONE`,
};

export const CommunityCreationTestIds = {
  continue: `CONTINUE`,
  create: `CREATE_COMMUNITY`,
};
