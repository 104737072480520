import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Circle } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreEvents = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path d="M146.183 11.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 00153 17.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 00146.471 11l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147l-8.167 8.039-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Rect fill="#FFEEEB" x={0} y={0} width={163} height={98} rx={7} />
          <Path
            d="M142.209 46.52c4.56 4.26 6.78 10.487 7.548 17.405.726 6.88 0 14.454-4.561 19.66-4.52 5.207-12.916 8.083-20.423 7.282-7.507-.8-14.126-5.279-20.423-10.486C98.054 75.175 92.04 69.204 92 63.196c0-5.97 5.933-11.978 12.27-16.238 6.296-4.296 12.956-6.845 19.736-6.954 6.78-.11 13.683 2.22 18.203 6.517z"
            fill="#FF563D"
            fillRule="nonzero"
          />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#FF563D">
            <TSpan x={12} y={22}>
              Current Events
            </TSpan>
          </Text>
          <G transform="translate(65 46)" fillRule="nonzero">
            <Path
              d="M20.09-15.498H50.83a4.58 4.58 0 014.58 4.58v61.761a4.58 4.58 0 01-4.58 4.58H20.09a4.58 4.58 0 01-4.58-4.58v-61.762a4.58 4.58 0 014.58-4.579z"
              fill="#A1D3B1"
              transform="rotate(90 35.46 19.962)"
            />
            <Path
              d="M19.523-13.802h31.875c1.3 0 2.355 1.054 2.355 2.355v62.714c0 1.301-1.054 2.355-2.355 2.355H19.523a2.355 2.355 0 01-2.355-2.355v-62.714c0-1.3 1.054-2.355 2.355-2.355z"
              fill="#5E9A89"
              transform="rotate(90 35.46 19.91)"
            />
            <Circle fill="#A1D3B1" cx={35} cy={20} r={8} />
            <Path
              fill="#FFF"
              transform="rotate(90 36.709 19.91)"
              d="M36.7090329 15.9395504L40.6716127 23.8806881 32.7464531 23.8806881z"
            />
            <Path fill="#A1D3B1" d="M6 34H65V36H6z" />
            <Circle fill="#FFF" cx={6.5} cy={35} r={1.5} />
          </G>
        </G>
      </G>
    </Svg>
  );
};
