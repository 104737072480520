import { AmazonImage } from '../images/creditCardImages/AmazonImage';
import { AmericanExpressImage } from '../images/creditCardImages/AmericanExpressImage';
import { BitcoinImage } from '../images/creditCardImages/BitcoinImage';
import { DinersClubImage } from '../images/creditCardImages/DinersClubImage';
import { DiscoverImage } from '../images/creditCardImages/DiscoverImage';
import { MaestroImage } from '../images/creditCardImages/MaestroImage';
import { MasterCardImage } from '../images/creditCardImages/MasterCardImage';
import { StripeImage } from '../images/creditCardImages/StripeImage';
import { VisaImage } from '../images/creditCardImages/VisaImage';
import { JcbImage } from '../images/creditCardImages/JCBImage';
import { PaypalImage } from '../images/creditCardImages/PaypalImage';
// import { GooglePayImage } from '../images/creditCardImages/GooglePayImage';

export const CREDIT_CARD_IMAGES = {
  amazon: AmazonImage,
  amex: AmericanExpressImage,
  bitcoin: BitcoinImage,
  diners: DinersClubImage,
  discover: DiscoverImage,
  maestro: MaestroImage,
  mastercard: MasterCardImage,
  stripe: StripeImage,
  visa: VisaImage,
  jcb: JcbImage,
  paypal: PaypalImage,
  // google_pay: GooglePayImage,
  // unknown: ?
};

export type CreditCardNames = keyof typeof CREDIT_CARD_IMAGES;

export const setting_customer_id = `77ea7704-8d57-42b5-bf62-ffd5bed0607f`;
