import * as React from 'react';
import Svg, { Defs, Rect, Path, G, Mask, Use, Text, TSpan, Ellipse } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreNature = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Rect id="a" x={0} y={0} width={163} height={98} rx={7} />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Use fill="#FCE8F0" xlinkHref="#a" />
          <G mask="url(#b)">
            <G transform="translate(36 9)">
              <Path
                d="M120.032 66.843c-9.312 15.58-52.68 12.442-65.053-4.82-12.373-17.262 6.123-48.535 27.807-46.966 21.684 1.681 46.43 36.093 37.246 51.786z"
                fill="#EA236C"
                fillRule="nonzero"
                transform="rotate(-65 86.5 45.964)"
              />
              <Path fill="none" d="M0.489583333 33H134.489583333V91H0.489583333z" />
            </G>
          </G>
        </G>
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#EA236C">
          <TSpan x={12} y={22}>
            Nature
          </TSpan>
        </Text>
        <G fillRule="nonzero">
          <G transform="translate(72.427 24.111) translate(42.573 5.889) translate(.24 .03)" fill="#02A837">
            <Path d="M11.1146074 11.6284652H12.42220827V38.3958756H11.1146074z" />
            <Path d="M11.5504743 0L23.1009487 33.5689655 0 33.5689655z" />
          </G>
          <G transform="translate(72.427 24.111) translate(22.573 .889) translate(.338 .047)">
            <Path fill="#02A837" d="M14.5540045 9.85198025H16.58479582V51.298241950000005H14.5540045z" />
            <Path fill="#0CC647" d="M15.2309349 0L30.4618698 43.8243259 0 43.8243259z" />
          </G>
          <G transform="translate(72.427 24.111) translate(53.573 24.889)">
            <Path
              d="M12.462 18.34c5.161 0 12.461-.181 12.461-6.215C24.923 6.09 20.74.074 12.462.074 4.184.074 0 6.09 0 12.124c0 6.035 7.3 6.216 12.462 6.216z"
              fill="#2EE17C"
            />
            <Path fill="#02A837" d="M12.2669604 10.5357143H13.2669604V24.5833333H12.2669604z" />
          </G>
          <G transform="translate(72.427 24.111) translate(13.573 14.889) translate(.55 .854)">
            <Rect fill="#02A837" x={0} y={0} width={12.6863436} height={31.1246377} rx={4.8} />
            <Path fill="#0CC647" d="M6.07887298 11.115942H7.13606828V37.7942029H6.07887298z" />
          </G>
          <G transform="translate(72.427 24.111) translate(38.573 40.889) translate(.981 .962)">
            <Ellipse fill="#00D745" cx={20.8050966} cy={6.67261905} rx={5.11002372} ry={4.91666667} />
            <Path
              d="M11.315 0c2.309 0 4.2 1.719 4.368 3.9a4.506 4.506 0 011.837-.388c2.42 0 4.38 1.887 4.38 4.214 0 .2-.014.396-.042.588a4.484 4.484 0 012.232-.588c2.42 0 4.38 1.887 4.38 4.214 0 .364-.048.718-.138 1.054H.587A5.423 5.423 0 010 10.536c0-3.104 2.615-5.62 5.84-5.62.403 0 .797.04 1.177.115a4.068 4.068 0 01-.082-.817C6.935 1.887 8.896 0 11.315 0z"
              fill="#02A837"
            />
          </G>
          <G transform="translate(72.427 24.111) translate(.573 47.889) translate(.233 .233)">
            <Ellipse fill="#02A837" cx={7.57297042} cy={4.13} rx={4.0777533} ry={4.13} />
            <Path d="M10.486 10.227c0-2.933-2.361-5.114-5.257-5.114C2.334 5.113 0 7.294 0 10.227" fill="#02A837" />
            <Path
              d="M15.206.393c1.209 0 2.347.314 3.341.868a5.47 5.47 0 012.299-.504c2.391 0 4.435 1.536 5.255 3.705.24-.044.486-.066.737-.066 2.337 0 4.23 1.954 4.23 4.365 0 .515-.085 1.008-.244 1.466H8.604a7.469 7.469 0 01-.448-2.557c0-4.019 3.156-7.277 7.05-7.277z"
              fill="#00D745"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
};
