import _ from 'lodash';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAppDispatch } from '../redux/store';
import { AVAILABLE_CATEGORIES, AvailableCategory } from '../common-settings/settings.constants';
import { Typography } from '../common-styles';
import { CenterColumn, CommunityAvatar, MeshIcon } from '../common-ui';
import { formatShortRelativeDate } from '../common-util';
import { setPreviewCommunity, setActiveCommunity } from '../redux/feedSlice';
import { AppContext } from '../../AppContext';
import { GroupCoreFields, GroupMember } from '../common-types/types';
import { Events, extractSourceFromRoute, PendoTrackEvent } from '../pendo/events';
import { ExploreNavigator, HomeNavigator } from '../common-types/navigation-types';
import { MeshVerifiedCheck } from '../images';

type ICategory = {
  label: string;
  value: string;
};

type CommunityListItemProps = {
  group: GroupCoreFields & { category?: ICategory };
  toggleShowHiddenCommunities?: () => void;
  group_member?: GroupMember;
  hide_category?: boolean;
  showMemberPill?: boolean;
  onPress?: () => void;
};

const areEqualCommunities = (prev: CommunityListItemProps, next: CommunityListItemProps) => {
  const prevMainCategory = prev.group?.category?.label;
  const nextMainCategory = next.group?.category?.label;
  // compare only displayable data
  const equalGroup = prev.group.id === next.group.id;
  const equalName = prev.group.name === next.group.name;
  const equalType = prevMainCategory === nextMainCategory;
  const equalLastPost = prev.group.latest_post === next.group.latest_post;
  const groupMemberHasAgreed = prev.group_member?.has_agreed === next.group_member?.has_agreed;
  const equalPillDisplay = prev.showMemberPill === next.showMemberPill;
  // I don't include onScreen since that prop only concern GridView.Row
  return equalGroup && equalName && equalType && equalLastPost && groupMemberHasAgreed && equalPillDisplay;
};

export const CommunityListItem: React.FC<CommunityListItemProps> = React.memo(
  ({ group, group_member, hide_category = false, showMemberPill = false, toggleShowHiddenCommunities, onPress }) => {
    const navigation = useNavigation<HomeNavigator | ExploreNavigator>();
    const route = useRoute();
    // When we select multiple categories, label is null so we get the label from the value prop
    const dispatch = useAppDispatch();

    const multipleCategories = !group?.category?.label;
    const [mainCategoryValue] = group?.category?.value?.split(`,`) || []; // We only want the first category since is consider the main one.

    let type = group?.category?.label;
    if (multipleCategories && mainCategoryValue && Object.keys(AVAILABLE_CATEGORIES).includes(mainCategoryValue)) {
      type = AVAILABLE_CATEGORIES[mainCategoryValue as AvailableCategory];
    }

    const [latestPost, setlatestPost] = useState(formatShortRelativeDate(group.latest_post));
    const goToCommunity = useCallback(async () => {
      if (onPress) {
        onPress();
        return;
      }
      await PendoTrackEvent(Events.VIEW_COMMUNITY, {
        community_name: group.name,
        source: extractSourceFromRoute(route.name),
      });
      if (toggleShowHiddenCommunities) toggleShowHiddenCommunities();
      if (group_member) {
        dispatch(setActiveCommunity({ user_id: group_member.persona_id, group }));
        (navigation as HomeNavigator).navigate(`HomeScreen`);
      } else {
        dispatch(setPreviewCommunity(group));
        (navigation as ExploreNavigator).navigate(`CommunityPreview`, { previous: `Explore` });
      }
    }, [onPress, group, route.name, toggleShowHiddenCommunities, group_member, dispatch, navigation]);

    const goToCategory = useCallback(() => {
      if (!group.category) return;
      (navigation as ExploreNavigator).push(`CategoryListView`, { categoryData: group.category });
    }, [navigation, group]);

    useEffect(() => {
      const interval = setInterval(() => {
        setlatestPost(formatShortRelativeDate(group.latest_post));
      }, 5000);
      return () => clearInterval(interval);
    }, [group.latest_post]);

    return (
      <CenterColumn>
        <View key={group.id}>
          <TouchableOpacity
            activeOpacity={0.8}
            disabled={group_member && group_member.banned}
            onPress={goToCommunity}
            testID={`COMMUNITY_${group.name}`}
          >
            <View style={{ flexDirection: `row`, justifyContent: `flex-start`, marginRight: 16 }}>
              <CommunityAvatar
                onPress={goToCommunity}
                containerStyle={{ marginVertical: 16, marginHorizontal: 11 }}
                community={group}
                size={100}
                border
              />
              <View style={{ flexShrink: 1, justifyContent: `center` }}>
                <View style={{ flexDirection: `row`, alignItems: `center` }}>
                  <Text
                    maxFontSizeMultiplier={2}
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    style={{ flexShrink: 1, ...Typography.text(`bold`, `plusone`) }}
                  >
                    {group.name}
                  </Text>
                  {group.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
                  <MeshIcon name="chevron-right" size={12} style={{ marginLeft: 4 }} />
                </View>
                <View style={{ flexDirection: `row`, marginTop: 5 }}>
                  <Text
                    maxFontSizeMultiplier={2}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={{ ...Typography.text(`small`), letterSpacing: 0 }}
                  >
                    {group.latest_post && <Text>Updated {latestPost}</Text>}
                    {!hide_category && group.latest_post && <Text> • </Text>}
                  </Text>
                  {!hide_category && !!type && (
                    <TouchableOpacity onPress={goToCategory} hitSlop={{ top: 10, bottom: 10 }} style={{ paddingLeft: 2 }}>
                      <Text style={{ ...Typography.add(`link`, `small`), maxWidth: 80 }} ellipsizeMode="tail" numberOfLines={1}>
                        {type}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                <CommunityPills group={group} group_member={group_member} showMemberPill={showMemberPill} />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </CenterColumn>
    );
  },
  areEqualCommunities,
);

type CommunityPillProps = {
  group: GroupCoreFields;
  group_member?: GroupMember;
  showMemberPill: boolean;
};

const CommunityPills: React.FC<CommunityPillProps> = ({ group, group_member, showMemberPill }) => {
  const { isGuestMode } = useContext(AppContext);
  if (group.application_pending || group.join_request_pending) {
    return (
      <View style={[styles.pill, styles.pending_bg]}>
        <Text style={{ ...Typography.text(`bold`, `center`, `small`), color: `#EE9777` }} testID="MEMBER_BADGE">
          Pending
        </Text>
      </View>
    );
  }
  if (group_member && showMemberPill && !isGuestMode) {
    return (
      <View style={[styles.pill, styles.member_bg]}>
        <Text style={{ ...Typography.text(`bold`, `center`, `small`), color: `#2A98A5` }} testID="MEMBER_BADGE">
          Member
        </Text>
      </View>
    );
  }
  return null;
};

const styles = StyleSheet.create({
  pill: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 20,
    justifyContent: `center`,
    alignItems: `center`,
    width: 90,
    marginTop: 4,
    // alignSelf: `flex-end`,
  },
  pending_bg: {
    backgroundColor: `#FEE2B380`,
  },
  member_bg: {
    backgroundColor: `#E5F8FA`,
  },
});
