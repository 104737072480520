import { Dimensions, StyleSheet } from 'react-native';

const MAX_ITEM_HEIGHT = (Dimensions.get(`window`).height * 3) / 8;

export const notificationStyles = StyleSheet.create({
  containerStyle: {
    flexDirection: `row`,
    paddingVertical: 16,
    paddingLeft: 16,
    paddingRight: 34,
  },
  previewContainer: {
    maxHeight: MAX_ITEM_HEIGHT,
    overflow: `hidden`,
    justifyContent: `flex-start`,
  },
  avatarContainer: {
    alignSelf: `flex-start`,
    marginRight: 10,
  },
  badge: {
    position: `absolute`,
    right: 0,
    bottom: -5,
    width: 27,
    height: 27,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: 999,
  },
  description: {
    flex: 1,
    flexDirection: `column`,
  },
  dateContainer: {
    flex: 1,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginTop: 1,
  },
  rightActionsIconHolder: {
    flexDirection: `row`,
    alignItems: `center`,
  },
  rightActionsIcon: {
    marginRight: 6,
    marginBottom: 0,
  },
  listItemRight: {
    position: `absolute`,
    right: 1,
    top: 13,
    padding: 3,
  },
});
