import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Text, TouchableOpacity, View, StyleSheet, ListRenderItem } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { BASE_PAYMENT_API_URL } from '../../constants';
import { Colors, Typography } from '../common-styles';
import { PaymentNavigator } from '../common-types/navigation-types';
import { PaymentMethod } from '../common-types/types';
import { BottomSheetModal, Divider, MeshIcon, ThemedButton } from '../common-ui';
import { logJsonObj } from '../common-util/logJsonObj';
import { setDefaultPaymentMethod, setPaymentMethods } from '../redux/contributionSlice';
import { RootState } from '../redux/store';
import { APIHelper } from './helpers';
import { PaymentMethodImage } from './PaymentMethodImage';
import { DELETE_PAYMETHOD, EDIT_PAYMETHOD, ADD_NEW_PAYMETHOD } from './testLabels';

type PaymentResponse = {
  paymentMethods: PaymentMethod[];
};

const getPaymentPartialUpdate = (pm_id: string) => ({
  customer: {
    invoice_settings: {
      custom_fields: ``,
      default_payment_method: pm_id,
    },
  },
});

export const PaymentMethodList: React.FC = () => {
  const navigation = useNavigation<PaymentNavigator>();
  const { customer, defaultPaymentMethod, paymentMethods } = useSelector((state: RootState) => state.contributions);
  const dispatch = useDispatch();

  const [idmUpdateKey, setidmUpdateKey] = useState<string>();
  const [idmDeleteKey, setIdmDeleteKey] = useState<string>();
  const [selectedCard, setSelectedCard] = useState<PaymentMethod | null | undefined>(defaultPaymentMethod);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [_loading, setLoading] = useState(false);

  const addNewPaymentMethod = () => navigation.push(`AddPaymentMethod`);

  const getPaymentMethods = useCallback(async () => {
    if (customer) {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_PAYMENT_API_URL}payments/list/${customer?.id}`, {
          method: `GET`,
          headers: {
            'Content-Type': `application/json`,
          },
        });
        if (!response.ok) {
          //TODO: handle error
        }

        const data: PaymentResponse = await response.json();
        dispatch(setPaymentMethods(data?.paymentMethods));
        const [defaultMethod, _rest] = data?.paymentMethods || [];

        if (defaultMethod?.id) {
          setSelectedCard(defaultMethod);
          await APIHelper(`customers/update/${customer?.id}`, `POST`, idmUpdateKey, getPaymentPartialUpdate(defaultMethod.id));
          dispatch(setDefaultPaymentMethod(defaultMethod));
        }
      } catch (error) {
        console.error(`Error while getting payment methods`, error);
      }
      setLoading(false);
    }
  }, [customer, dispatch, idmUpdateKey]);

  const handleSelectCard = useCallback(
    async (pm: PaymentMethod) => {
      try {
        setSelectedCard(pm);
        const updatedResult = await APIHelper(
          `customers/update/${customer?.id}`,
          `POST`,
          idmUpdateKey,
          getPaymentPartialUpdate(pm.id),
        );
        dispatch(setDefaultPaymentMethod(pm));
        logJsonObj(updatedResult, `customers/update -> result ->`);
        getPaymentMethods();
        // navigation.goBack();
      } catch (error) {
        console.error(error);
      }
    },
    [customer?.id, idmUpdateKey, dispatch, getPaymentMethods],
  );

  const handleDeleteCard = useCallback(async () => {
    logJsonObj(selectedCard);
    try {
      if (!selectedCard) return;
      const paymentMethod = await APIHelper(`payments/detach/${selectedCard?.id}`, `POST`, idmDeleteKey);
      if (paymentMethod?.id === defaultPaymentMethod?.id) {
        dispatch(setDefaultPaymentMethod(``));
      }
      getPaymentMethods();
      setShowDeleteModal(false);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, selectedCard, getPaymentMethods, idmDeleteKey, defaultPaymentMethod]);

  const renderCardItem: ListRenderItem<PaymentMethod> = useCallback(
    ({ item: pm }) => {
      if (!paymentMethods || !paymentMethods.length) return null;
      // const CardImage = CREDIT_CARD_IMAGES[pm.card.brand];
      const { card, id } = pm;
      const { last4 } = card;

      return (
        <>
          <TouchableOpacity onPress={() => handleSelectCard(pm)} style={styles.cardContainer}>
            <View style={{ flexDirection: `row`, alignItems: `center`, marginLeft: 12 }}>
              <PaymentMethodImage cardName={pm.card.brand} />
              {/* <CardImage width={46} height={32} /> */}
              <Text style={{ marginLeft: 12 }}>••••</Text>
              <Text style={{ marginLeft: 6 }}>{last4}</Text>
            </View>
            <CardStatus paymentMethod={pm} selectedCardId={selectedCard?.id} />
          </TouchableOpacity>
          {id === selectedCard?.id && (
            <View style={{ flexDirection: `row`, justifyContent: `space-evenly`, marginTop: 16 }}>
              <ThemedButton
                rounded
                clear
                outline
                title="Edit"
                testID={EDIT_PAYMETHOD}
                containerStyle={{ width: 100 }}
                buttonStyle={{ padding: 10, alignItems: `center` }}
                onPress={() => navigation.push(`EditPaymentMethod`, { paymentMethod: pm /*group*/ })}
              />
              <ThemedButton
                rounded
                clear
                outline
                title="Delete"
                testID={DELETE_PAYMETHOD}
                containerStyle={{ width: 100 }}
                buttonStyle={{ padding: 10, alignItems: `center` }}
                onPress={() => setShowDeleteModal(true)}
              />
            </View>
          )}
          <Divider style={{ marginTop: 20, borderColor: Colors.headerBottomBorderColor }} />
        </>
      );
    },
    [navigation, paymentMethods, selectedCard, handleSelectCard],
  );

  /**
   * Attemps to generate another idempotencyKey if the selectedCard changes
   * This ensures that the user generate a new key only if a new request is going to be executed.
   */
  useEffect(() => {
    setidmUpdateKey(uuidv4());
    setIdmDeleteKey(uuidv4());
  }, [selectedCard]);

  useEffect(() => {
    setSelectedCard(defaultPaymentMethod);
  }, [paymentMethods, defaultPaymentMethod, handleDeleteCard, handleSelectCard]);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white, paddingHorizontal: 16 }}>
      <FlatList
        data={paymentMethods || []}
        extraData={paymentMethods}
        keyExtractor={(pm) => pm.id}
        renderItem={renderCardItem}
        ListHeaderComponent={<ContributeHeader />}
        ListFooterComponent={
          <>
            <TouchableOpacity onPress={addNewPaymentMethod}>
              <View style={{ marginTop: 16, flexDirection: `row`, justifyContent: `flex-start`, alignItems: `center` }}>
                <MeshIcon name="plus" color={Colors.focusedIconColor} size={20} />
                <Text style={{ ...Typography.text(`theme`, `bold`), marginLeft: 7 }} testID={ADD_NEW_PAYMETHOD}>
                  Add new payment method
                </Text>
              </View>
            </TouchableOpacity>
            <Divider style={{ marginTop: 20, borderColor: Colors.headerBottomBorderColor }} />
            <Text style={{ marginTop: 16, ...Typography.text(`base`) }}>
              Manage your payment methods for contributing to community campaigns.
            </Text>
          </>
        }
        // ListEmptyComponent={EmptyComponent}
      />
      <BottomSheetModal
        title="Delete payment method"
        visible={!!showDeleteModal}
        onPressCancel={() => setShowDeleteModal(false)}
        onPressConfirm={() => handleDeleteCard()}
        confirmTitle="Delete"
      >
        <View style={{ marginHorizontal: 16 }}>
          <Text style={{ marginTop: 16, marginBottom: 32, ...Typography.text(`center`) }}>
            Are you sure you want to delete this payment method?
          </Text>
        </View>
      </BottomSheetModal>
    </View>
  );
};

const ContributeHeader: React.FC = () => <Text style={styles.contributeHeader}>Payment methods</Text>;

type CardStatusProps = {
  selectedCardId?: string;
  paymentMethod: PaymentMethod;
};

const CardStatus: React.FC<CardStatusProps> = ({ paymentMethod, selectedCardId }) => {
  const { card, id } = paymentMethod;
  const { exp_month, exp_year } = card;

  const now = new Date().getTime();
  // Credit cards expires always the last day of the month.
  const expirationDate = new Date(Number(exp_year), Number(exp_month), 0, 23, 59, 59).getTime();
  const hasExpired = now > expirationDate;

  if (!hasExpired) return <MeshIcon name="select-circle" focused={id === selectedCardId} />;
  return <Text style={styles.cardExpired}>Expired</Text>;
};

const styles = StyleSheet.create({
  contributeHeader: {
    ...Typography.text(`bold`, `paymentTransparentGray`, `small`),
    marginTop: 16,
  },
  cardContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    width: `100%`,
    alignItems: `center`,
    marginTop: 16,
  },
  cardExpired: Typography.text(`bold`, `base`, `alert`),
});
