import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Dimensions,
  Text,
  View,
  Image,
  SectionList,
  TouchableOpacity,
  StyleSheet,
  SectionListRenderItem,
  Platform,
} from 'react-native';
import { useQuery } from '@apollo/react-hooks';
import Collapsible from 'react-native-collapsible';
import { useSelector } from 'react-redux';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { PendoSDK } from 'rn-pendo-sdk';
import { useDrawerStatus } from '@react-navigation/drawer';
import FastImage from 'react-native-fast-image';
import { LoadingIndicator, MenuModal, ThemedButton, MeshIcon, Divider, SafeAreaView } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { LIST_GROUPS, PENDING_COMMUNITY_LIST } from '../graphql';
import { MeshVerifiedCheck } from '../images';
import { CircleButton } from '../common-ui/CircleButton';
import { setOpenItems, setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';
import { RootState, useAppDispatch } from '../redux/store';
import { AppContext } from '../../AppContext';
import { useModerationGroups } from './hooks/useModerationGroups';
import { useSettings } from '../common-settings/hooks/useSettings';
import { ListedGroups, PendingGroups, GroupCoreFields, GroupMember } from '../common-types/types';
import { RightDrawerCompositeNavigator } from '../common-types/navigation-types';

const USER_EMAIL_KEY = `user.setting.email`;
const sectionTitles = {
  Manager: `Admin`,
  Creator: `Creator`,
  Member: `Member`,
  MembershipPending: `Membership pending`,
  PendingCommunity: `Pending`,
};
const communityRolesBase: Record<string, string[]> = {
  member: [],
  creator: [],
  moderator: [],
  manager: [],
  owner: [],
};

type ModeratedGroups = {
  group: GroupCoreFields;
  group_member?: GroupMember;
  count_open?: number;
  admin_mod_count?: number;
};

// Do not use 'useNavigation' hook as it will not return the correct navigator(DrawerNavigator) but a StackNavigator
export const ModerationDrawer: React.FC<RightDrawerCompositeNavigator> = ({ navigation }) => {
  //console.log('🚀 ~ navigation state', navigation.getState());
  const { user } = React.useContext(AppContext);
  const isRightDrawerOpen = useDrawerStatus() === `open`;
  const { verified: emailVerified, settings } = useSettings(USER_EMAIL_KEY, { user_id: user?.id });

  const [managedGroups, setmanagedGroups] = useState<ModeratedGroups[]>([]);
  const [creatorGroups, setCreatorGroups] = useState<ModeratedGroups[]>([]);
  const [joinedGroups, setjoinedGroups] = useState<ModeratedGroups[]>([]);
  const [pendingLists, setPendingLists] = useState<ModeratedGroups[]>([]);
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [showVerification, setShowVerification] = useState(false);

  const dispatch = useAppDispatch();
  const { openItems } = useSelector((state: RootState) => state.feed);
  const { moderatedGroups, loadingModeratedGroups, modRefetch } = useModerationGroups();

  const {
    data: listedGroups,
    loading: loadingListedGroups,
    refetch: groupRefetch,
  } = useQuery<ListedGroups>(LIST_GROUPS, {
    skip: !user?.id,
    fetchPolicy: `cache-first`,
    onError: (error) => console.error(`[ModerationError]`, error.message),
  });

  const {
    data: listedPending,
    loading: loaidngListedPending,
    refetch: pendingRefetch,
  } = useQuery<PendingGroups, { user_id?: string }>(PENDING_COMMUNITY_LIST, {
    variables: {
      user_id: user?.id,
    },
    skip: !user?.id,
  });

  const refetch = useCallback(() => {
    modRefetch();
    groupRefetch();
    pendingRefetch();
  }, [modRefetch, groupRefetch, pendingRefetch]);

  useEffect(() => {
    //HACK: used to refetch drawer on user switch
    if (user?.id || isRightDrawerOpen) refetch();
  }, [user?.id, isRightDrawerOpen, refetch]);

  useEffect(() => {
    // useEffect #1 - set the arrays that are going to be displayed
    const managedGroups = moderatedGroups?.getModeratedGroups2 || [];
    const allGroups = listedGroups?.listJoinedGroups || [];

    const communityRoles = listedGroups?.listJoinedGroups?.reduce(
      (prev, joinedGroup) => {
        const { group, group_member } = joinedGroup;
        const { role_name } = group_member || {};
        if (role_name && prev[role_name]) {
          // eslint-disable-next-line no-param-reassign
          prev[role_name] = [...prev[role_name], group.name];
          //prev[role_name].push(group.name); // triggers: Attempted to assign to readonly property
        }
        return prev;
      },
      { ...communityRolesBase },
    );

    if (communityRoles) PendoSDK.setVisitorData(communityRoles);

    const totalIssuesCount = managedGroups.reduce((total, { count_open }) => {
      if (count_open) return total + count_open;
      return total;
    }, 0);

    dispatch(setOpenItems(totalIssuesCount));
    setmanagedGroups(managedGroups);
    setCreatorGroups(allGroups.filter(({ group_member }) => group_member.role_name === `creator`));
    setjoinedGroups(allGroups.filter(({ group_member }) => group_member.role_name === `member`));

    if ((listedPending?.listPendingCommunities?.length || 0) > 0) {
      const result: ModeratedGroups[] = [];
      listedPending?.listPendingCommunities.map((item) => {
        result.push({ group: item });
        return result;
      });
      setPendingLists(result);
    }
  }, [moderatedGroups, listedGroups, dispatch, listedPending]);

  const sections = useMemo(() => {
    const sections = [
      { title: sectionTitles.Manager, data: managedGroups },
      { title: sectionTitles.Creator, data: creatorGroups },
      { title: sectionTitles.Member, data: joinedGroups },
      { title: sectionTitles.MembershipPending, data: [] },
      { title: sectionTitles.PendingCommunity, data: pendingLists },
    ].filter((section) => section.data && section.data.length > 0);

    return sections;
  }, [creatorGroups, managedGroups, joinedGroups, pendingLists]);

  const goToCreateCommunity = useCallback(() => {
    if (!emailVerified) {
      setShowVerification(true);
      return;
    }
    navigation.navigate(`CreateCommunity`);
  }, [navigation, emailVerified]);

  const handleVerify = useCallback(() => {
    if (!emailVerified && user) {
      navigation.navigate(`EmailEditor`, {
        type: `user`,
        setting: settings[USER_EMAIL_KEY],
        rightDrawer: true,
      });
    }
    setShowVerification(false);
  }, [navigation, user, emailVerified, settings]);

  const ListHeaderComponent = useCallback(() => {
    return (
      <View>
        <Text style={DrawerStyles.title}>My nodes</Text>
        <Divider />
        {!user?.isBanned && (
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={goToCreateCommunity}
            style={DrawerStyles.createBtn}
            testID="CREATE_COMMUNITY"
          >
            <CircleButton containerStyle={{ height: 30, width: 30 }} onPress={() => console.log(`circle button pressed`)}>
              {/* <CircleButton.Icon iconProps={{ name: `group` }} /> */}
              <MeshIcon name="plus-3" color={Colors.iconColor} size={18} />
            </CircleButton>
            <Text style={DrawerStyles.createLabel}>Create Node</Text>
          </TouchableOpacity>
        )}

        <Divider />
      </View>
    );
  }, [goToCreateCommunity]);

  const toggleSection = useCallback(
    (section: string) => {
      const hasSection = collapsed?.includes(section);
      if (hasSection) {
        setCollapsed(collapsed.filter((item) => item !== section));
      } else {
        setCollapsed([...collapsed, section]);
      }
    },
    [collapsed, setCollapsed],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => {
      const { title } = section;
      const isCollapsed = collapsed?.includes(title);
      return <SectionHeader title={title} collapsed={isCollapsed} count={openItems} toggleSection={toggleSection} />;
    },
    [collapsed, openItems, toggleSection],
  );

  const handleSelectCommunity = useCallback(
    (selectedGroup) => {
      if (user?.id && selectedGroup?.id) dispatch(setActiveCommunity({ user_id: user?.id, group: selectedGroup }));
      navigation.closeDrawer();
    },
    [dispatch, navigation, user?.id],
  );

  const navigateToPreview = useCallback(
    (group) => {
      dispatch(setPreviewCommunity(group));
      navigation.navigate(`CommunityPreview`);
    },
    [dispatch, navigation],
  );

  const renderItem: SectionListRenderItem<ModeratedGroups> = useCallback(
    ({ item, section: { title } }) => {
      if (!item) return null;
      const { group, count_open, admin_mod_count, group_member } = item || {};
      const isCollapsed = collapsed && collapsed.includes(title);
      const navigateToManage = () => {
        navigation.navigate(`ModerateCommunityDetail`, {
          group,
          group_member,
          admin_mod_count,
        });
      };

      const onSelectCommunity = () => {
        if (group.application_pending) navigateToPreview(group);
        else handleSelectCommunity(group);
      };

      return (
        <Collapsible collapsed={isCollapsed}>
          <TouchableOpacity style={DrawerStyles.groupItemContainer} onPress={onSelectCommunity}>
            {group.avatar_url ? (
              <Image style={DrawerStyles.groupIcon} source={{ uri: group.avatar_url }} />
            ) : (
              <FastImage style={DrawerStyles.nodeImage} source={require(`../../assets/images/node.png`)} />
            )}
            <View style={DrawerStyles.groupItemLabels}>
              <View style={{ flexDirection: `row`, flex: 1 }}>
                <Text maxFontSizeMultiplier={2} numberOfLines={2} ellipsizeMode="tail" style={DrawerStyles.groupLabel}>
                  {group?.name}
                </Text>
                {group?.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4, marginTop: 3 }} />}
              </View>

              {title === sectionTitles.Manager && <ModPill onPress={navigateToManage} count={count_open} />}
            </View>
          </TouchableOpacity>
        </Collapsible>
      );
    },
    [collapsed, navigation, navigateToPreview, handleSelectCommunity],
  );

  const ListEmptyComponent = useCallback(() => {
    if (loadingModeratedGroups || loadingListedGroups || loaidngListedPending)
      return <LoadingIndicator size={28} style={{ alignItems: `center`, justifyContent: `center` }} />;
    return (
      <View style={{ alignItems: `center`, justifyContent: `center`, flex: 1 }}>
        <Text style={{ ...Typography.text(`center`, `bold`), marginTop: 20 }}>Nothing to moderate</Text>
      </View>
    );
  }, [loadingListedGroups, loadingModeratedGroups, loaidngListedPending]);

  const keyExtractor = useCallback((item) => item.group.id, []);
  const { height } = Dimensions.get(`window`);

  // eslint-disable-next-line
  const IOSSafeAreaView: any = Platform.select({ default: SafeAreaView });

  return (
    <IOSSafeAreaView style={{ backgroundColor: Colors.white, flex: 1 }}>
      <SectionList
        sections={sections}
        ListHeaderComponent={ListHeaderComponent}
        renderSectionHeader={renderSectionHeader}
        renderItem={renderItem}
        extraData={sections}
        SectionSeparatorComponent={Divider}
        keyExtractor={keyExtractor}
        ListEmptyComponent={ListEmptyComponent}
        showsVerticalScrollIndicator={false}
        initialNumToRender={15}
        refreshing={loadingModeratedGroups || loadingListedGroups || loaidngListedPending}
      />

      <MenuModal isVisible={showVerification} toggleModal={() => setShowVerification(!showVerification)}>
        <SafeAreaInsetsContext.Consumer>
          {(insets) => (
            <View style={{ marginBottom: insets?.bottom, maxHeight: height * 0.8 }}>
              <View style={{ padding: 25 }}>
                <Text style={Typography.text(`center`)}>
                  <Text>You must have a verified personal email to create a node on </Text>
                  <Text style={Typography.text(`bold`)}>mesh</Text>
                  <Text>.</Text>
                </Text>

                <Text style={Typography.text(`center`)}>Verify now if you wish to continue creating a node.</Text>
              </View>

              <View style={{ paddingHorizontal: 20 }}>
                <ThemedButton
                  title="Verify now"
                  rounded
                  containerStyle={{ marginBottom: 8 }}
                  titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
                  onPress={handleVerify}
                />

                <ThemedButton
                  title="Go back"
                  rounded
                  outline
                  clear
                  titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
                  onPress={() => setShowVerification(false)}
                />
              </View>
            </View>
          )}
        </SafeAreaInsetsContext.Consumer>
      </MenuModal>
    </IOSSafeAreaView>
  );
};

type SectionHeaderProps = {
  title: string;
  collapsed: boolean;
  toggleSection: (section: string) => void;
  count?: number;
};

const SectionHeader: React.FC<SectionHeaderProps> = React.memo(({ title, collapsed, toggleSection, count = 0 }) => {
  return (
    <View style={{ backgroundColor: Colors.white }}>
      <TouchableOpacity
        hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
        activeOpacity={0.8}
        onPress={() => toggleSection(title)}
      >
        <View style={DrawerStyles.sectionHeader}>
          <Text style={DrawerStyles.sectionTitle}>{title}</Text>
          <View style={{ flexDirection: `row`, alignItems: `center` }}>
            {title === sectionTitles.Manager && count > 0 ? (
              <View style={DrawerStyles.openCounterContainer}>
                <Text style={DrawerStyles.openCounter}>
                  {count} item{count !== 1 ? `s` : ``}
                </Text>
              </View>
            ) : null}
            <MeshIcon
              name={!collapsed ? `chevron-up` : `chevron-down`}
              color={Colors.iconColor}
              size={15}
              style={{ marginLeft: 4 }}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
});

type ModPillProps = {
  onPress: () => void;
  count?: number;
};

const ModPill: React.FC<ModPillProps> = ({ onPress, count = 0 }) => {
  return (
    <TouchableOpacity onPress={onPress} style={DrawerStyles.countContainer}>
      <MeshIcon name="moderation" size={28} focused={count > 0} />
      {count > 0 ? <Text style={DrawerStyles.countLabel}>{count}</Text> : null}
    </TouchableOpacity>
  );
};

const DrawerStyles = StyleSheet.create({
  title: { ...Typography.text(`plustwo`, `bold`, `center`), marginTop: 25, marginBottom: 20 },
  createBtn: { marginVertical: 13, marginHorizontal: 16, flexDirection: `row`, alignItems: `center` },
  createLabel: { marginLeft: 10, ...Typography.text(`base`, `theme`, `bold`) },
  sectionHeader: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `space-between`,
    backgroundColor: Colors.lightWarmGray,
    paddingHorizontal: 16,
  },
  sectionTitle: { ...Typography.text(`plusone`, `bold`), marginVertical: 10 },
  groupItemContainer: { flexDirection: `row`, alignItems: `center`, marginVertical: 10, marginHorizontal: 16 },
  groupIcon: { borderRadius: 150 / 2, overflow: `hidden`, marginBottom: 5, width: 30, height: 30 },
  groupItemLabels: { flexDirection: `row`, alignItems: `center`, justifyContent: `space-between`, flex: 1 },
  groupLabel: { ...Typography.text(), marginLeft: 5 },
  openCounterContainer: {
    borderRadius: 12,
    backgroundColor: `#E0F0E3`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: 5,
    marginLeft: 6,
    paddingHorizontal: 10,
  },
  openCounter: {
    ...Typography.text(`smallest`, `bold`, `center`),
    color: Colors.badgeCheck,
  },
  countContainer: {
    top: -2,
    justifyContent: `center`,
    alignItems: `center`,
  },
  countLabel: {
    fontSize: 10,
    fontWeight: `bold`,
    color: Colors.white,
    textShadowColor: Colors.black,
    position: `absolute`,
    top: 11,
  },
  nodeImage: {
    overflow: `hidden`,
    width: 30,
    height: 30,
  },
});
