import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { MeshIcon } from '../../common-ui/MeshIcon';
import { withBadge } from '../../common-ui';
import { HomeNavigator, RightDrawerNavigator } from '../../common-types/navigation-types';
import { Colors } from '../../common-styles';
import { GroupCoreFields } from '../../common-types/types';
import CommunityMenu from './CommunityMenu';
// @ts-ignore
import MeshWordmarkSVG from '../../../assets/images/mesh-wordmark.svg';
import { MeshVerifiedCheck } from '../../images';
import { DrawerMenuIcon } from '../../drawer/Drawer';
import CommunitySponsor from './CommunitySponsor';

type NavigationHeaderProps = {
  collapsed: boolean;
  openItems?: number;
  community?: Pick<GroupCoreFields, `id` | `name` | `isVerified` | `sponsor`>;
  nativeHeader: number;
};

type NavigationRightProps = Pick<NavigationHeaderProps, `collapsed` | `openItems` | `community`>;

export const NavigationHeaderRight: React.FC<NavigationRightProps> = ({ collapsed, openItems, community }) => {
  const navigation = useNavigation<HomeNavigator>();
  // @ts-ignore
  const parent = navigation?.getParent<RightDrawerNavigator>(`RightDrawer`);
  const BadgedIcon = withBadge(undefined, { hidden: !openItems || openItems === 0 })(MeshIcon);

  if (collapsed && community) {
    return <CommunityMenu community={community} />;
  }
  return (
    <TouchableOpacity
      style={styles.rightDrawerIcon}
      onPress={() => parent?.toggleDrawer()}
      hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
      activeOpacity={0.6}
    >
      <BadgedIcon name="list" size={24} color={Colors.iconColor} />
    </TouchableOpacity>
  );
};

type NavigationTitleProps = Pick<NavigationHeaderProps, `collapsed` | `community`> & { displaySponsor?: boolean };

export const NavigationHeaderTitle: React.FC<NavigationTitleProps> = ({ collapsed, community, displaySponsor = true }) => {
  const { width } = useWindowDimensions();
  if (!collapsed) {
    return (
      <View style={{ paddingVertical: 10, alignItems: `center`, justifyContent: `center` }}>
        <MeshWordmarkSVG width={68} height={25} />
      </View>
    );
  }
  const { name, isVerified, sponsor } = community || {};
  return (
    <View style={{ alignItems: `center`, marginTop: Platform.OS === `ios` ? -6 : 0 }}>
      <View style={{ ...styles.navTitleBar, maxWidth: width - 100 }}>
        <Text style={styles.navTitle} ellipsizeMode="tail" numberOfLines={1}>
          {name}
        </Text>
        {isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
      </View>
      {displaySponsor && sponsor?.id ? <CommunitySponsor sponsor={sponsor} community_name={name} /> : null}
    </View>
  );
};

export const NavigationHeader: React.FC<NavigationHeaderProps> = ({ collapsed, openItems, community, nativeHeader }) => {
  if (collapsed && community) {
    return (
      <View style={styles.collapsedWrapper}>
        <View style={[styles.headerWrapper, { height: nativeHeader - 10 }]}>
          <View style={styles.headerInner}>
            <DrawerMenuIcon />
            <NavigationHeaderTitle collapsed={collapsed} community={community} displaySponsor={Platform.OS === `ios`} />
            <NavigationHeaderRight collapsed={collapsed} openItems={openItems} community={community} />
          </View>
        </View>
        {Platform.OS === `android` ? <CommunitySponsor sponsor={community?.sponsor} community_name={community?.name} /> : null}
      </View>
    );
  }
  return (
    <View style={[styles.headerWrapper, { height: nativeHeader }]}>
      <View style={styles.headerInner}>
        <DrawerMenuIcon />
        <NavigationHeaderTitle collapsed={collapsed} community={community} />
        <NavigationHeaderRight collapsed={false} openItems={openItems || 0} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rightDrawerIcon: {
    paddingRight: 6,
    justifyContent: `center`,
    alignItems: `center`,
  },
  navTitleBar: {
    alignItems: `center`,
    justifyContent: `center`,
    flexDirection: `row`,
  },
  navTitle: { fontSize: 18, fontWeight: `700`, color: Colors.darkGray },
  headerWrapper: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `flex-end`,
    paddingLeft: 12,
    paddingRight: 8,
    backgroundColor: Colors.white,
    width: `100%`,
  },
  headerInner: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `space-between`,
    width: `100%`,
  },
  collapsedWrapper: {
    display: `flex`,
    alignItems: `center`,
    backgroundColor: Colors.white,
    paddingVertical: 8,
    paddingRight: 8,
    justifyContent: `flex-end`,
  },
});
