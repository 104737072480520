import * as Device from 'expo-device';
import { Dimensions } from 'react-native';
import { client, SET_ATTRIBUTION, GET_ATTRIBUTION } from '../graphql';

type AttributtionResult = {
  getAttribution: string;
};

type AttributtionVariables = {
  input: AttributionInput;
};

type AttributionInput = {
  osName: string | null;
  osVersion: string | null;
  scale: number;
  fontScale: number;
  width: number;
  height: number;
  manufacturer: string | null;
  modelName: string | null;
  supportedCpuArchitectures: string[] | null;
};

const getAttributionInput = (): AttributionInput => {
  const { osName, osVersion } = Device;
  const { width, height, scale, fontScale } = Dimensions.get(`screen`); // need to Math.ceil() the width/height
  const { manufacturer, modelName } = Device; // iOS only, weak match (iPhone vs iPhone 11 Pro) on modelName
  const { supportedCpuArchitectures } = Device; // Android only, poor match (ia32 vs x86) on supportedCpuArchitectures
  const attributionInput = {
    osName,
    osVersion,
    scale,
    fontScale,
    width,
    height,
    manufacturer,
    modelName,
    supportedCpuArchitectures,
  };
  return attributionInput;
};

export const setAttribution = async (url: string) => {
  try {
    const input = getAttributionInput();
    await client.mutate({ mutation: SET_ATTRIBUTION, variables: { input, url }, fetchPolicy: `no-cache` });
  } catch (err) {} // eslint-disable-line no-empty
};

export const getAttribution = async () => {
  try {
    const input = getAttributionInput();
    const { data } = await client.query<AttributtionResult, AttributtionVariables>({
      query: GET_ATTRIBUTION,
      variables: { input },
      fetchPolicy: `no-cache`,
    });
    return data.getAttribution;
  } catch (err) {
    return null;
  }
};
