import React, { useState, useEffect, useCallback } from 'react';
import { ScrollView, Text, View, TextInput } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import { movePrev } from '../redux/createProfileSlice';
import { WizardLayout } from '../community-create/WizardLayout';
import { Colors, Typography } from '../common-styles';
import { BackButton, MeshIcon, ThemedButton, VisibleTag } from '../common-ui';
import { CHECK_HANDLE, client } from '../graphql';
import Badwords from '../common-util/badwords';

export const ProfileUsername = () => {
  const navigation = useNavigation();

  const badwords = new Badwords();

  const { profileUsername } = useSelector((state) => state.createProfile);
  const [handle, setHandle] = useState(profileUsername);
  const [handleIsValid, setHandleIsValid] = useState();
  const [handleIsAvailable, setHandleIsAvailable] = useState();
  const [handleIsProfane, setHandleIsProfane] = useState();
  const dispatch = useDispatch();

  const onSubmit = () => {
    // dispatch(setProfileUsername(handle));
    // navigation.push(`ProfileDOB`);
    navigation.navigate(`CreatePassword`, { handle });
  };
  const goBack = useCallback(() => {
    dispatch(movePrev());
    navigation.goBack();
  }, [dispatch, navigation]);

  const onHandleChange = useDebouncedCallback((text) => {
    setHandle(text.trim());
    // below - only fire checkHandleValid and checkHandleAvailability once user stops typing

    if (text) {
      checkHandleAvailability(text.trim());
    } else {
      setHandleIsValid();
      setHandleIsAvailable();
      setHandleIsProfane();
    }
  }, 500);

  const checkHandleAvailability = async (handle) => {
    const validHandleRegex = /^[a-zA-Z0-9_]{2,20}$/;
    const valid = validHandleRegex.test(handle);

    if (valid) {
      try {
        setHandleIsValid(true);

        // check the username includes a profane word or not
        if (badwords.isProfane(handle)) {
          setHandleIsProfane(true);
          return;
        }

        const { data } = await client.query({ query: CHECK_HANDLE, variables: { handle } });
        const { is_valid } = data.checkHandleAvailability;
        setHandleIsAvailable(is_valid);
        setHandleIsProfane();
      } catch (err) {
        console.error(`Error thrown in checkHandleAvailability in ProfileUsername.jsx`, err.message);
      }
    } else {
      setHandleIsValid(false);
      setHandleIsAvailable();
      setHandleIsProfane();
    }
  };

  useEffect(() => {
    navigation?.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
  }, [navigation, goBack]);

  const baseInputStyle = {
    padding: 10,
    borderRadius: 4,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    marginHorizontal: 0,
  };
  const validusernameStyleMixin = {
    borderColor: Colors.brandGreen,
  };
  const invalidusernameStyleMixin = {
    borderColor: Colors.brightRed,
  };
  let inputStyle = baseInputStyle;
  if (handleIsAvailable === false || handleIsValid === false || handleIsProfane === true)
    inputStyle = { ...baseInputStyle, ...invalidusernameStyleMixin };
  if (handleIsAvailable === true && handleIsValid === true && !handleIsProfane)
    inputStyle = { ...baseInputStyle, ...validusernameStyleMixin };

  const nextDisabled = !handleIsValid || !handleIsAvailable || handleIsProfane || !handle || !handle.length > 1;

  return (
    <WizardLayout total={6} selector="createProfile">
      <ScrollView
        style={localStyles.container}
        keyboardShouldPersistTaps="handled"
        testID={UsernameTestIds.screen}
        alwaysBounceVertical={false}
      >
        <View>
          <View style={localStyles.blob} />
          <Text allowFontScaling={false} style={localStyles.headerText}>
            Pick a username
          </Text>
          <Text style={localStyles.subtext}>Select a username between 2-20 characters</Text>
        </View>
        <TextInput
          autoFocus
          blurOnSubmit
          autoCapitalize="none"
          textContentType="none"
          autoCorrect={false}
          maxLength={20}
          multiline={false}
          style={inputStyle}
          placeholder="Enter your username"
          placeholderTextColor={Colors.textPlaceholder}
          onChangeText={onHandleChange}
          onSubmitEditing={() => !nextDisabled && onSubmit()}
          rightIcon={<RigthIcon available={!nextDisabled} handle={!!handle} />}
          returnKeyType="done"
          testID={UsernameTestIds.username}
        />
        {!!handle && !!handle.length && (
          <ValidationMessage valid={handleIsValid} available={handleIsAvailable} profane={handleIsProfane} />
        )}
        <View style={{ marginTop: 8 }}>
          <VisibleTag infoText="Your Mesh handle is visible when you post in communities." />
        </View>
        <ThemedButton
          rounded
          title="Continue"
          onPress={onSubmit}
          buttonStyle={{ padding: 15 }}
          containerStyle={{ marginTop: 24 }}
          titleStyle={{ fontSize: Typography.sixteen, marginLeft: 4 }}
          disabled={nextDisabled}
          testID={UsernameTestIds.continue}
        />
      </ScrollView>
    </WizardLayout>
  );
};

const RigthIcon = ({ handle, available }) => {
  //handle  (handleIsAvailable && handleIsValid)
  if (!handle) return null;
  if (available) return <MeshIcon name="check" color={Colors.brandGreen} />;
  return <MeshIcon name="info-circle" color={Colors.brightRed} />;
};
RigthIcon.propTypes = {
  handle: PropTypes.bool.isRequired,
  available: PropTypes.bool.isRequired,
};

const ValidationMessage = ({ valid, available, profane }) => {
  if (valid && available && !profane)
    return (
      <View style={{ backgroundColor: Colors.brandGreen, padding: 10, borderRadius: 4, marginTop: 10 }}>
        <Text style={{ fontSize: Typography.smallFontSize, color: Colors.white }}>Looks good!</Text>
      </View>
    );
  if (valid === false || available === false || profane) {
    let text = ``;
    if (profane) text = `Your username is not available.`;
    if (available === false) text = `The handle you have selected is already in use. Please try another one.`;
    if (valid === false)
      text = `Your username must be 2-20 characters and contain only letters, numbers, underscores, and no spaces.`;

    return (
      <View style={localStyles.errorContainer} testID={UsernameTestIds.error}>
        <Text style={localStyles.errorText}>{text}</Text>
      </View>
    );
  }
  return null;
};
ValidationMessage.propTypes = {
  valid: PropTypes.bool,
  available: PropTypes.bool,
  profane: PropTypes.bool,
};

ValidationMessage.defaultProps = {
  valid: undefined,
  available: undefined,
  profane: undefined,
};

export const UsernameTestIds = {
  screen: `USERNAMESCREEN`,
  username: `USERNAMESCREEN_USERNAME`,
  error: `USERNAMESCREEN_ERROR`,
  continue: `USERNAMESCREEN_CONTINUE`,
};

const localStyles = {
  container: {
    flex: 1,
    padding: 15,
    paddingTop: 45,
  },
  headerText: {
    fontFamily: `inter-semibold`,
    fontSize: Typography.largeFontSize,
    color: Colors.textBlack,
    fontWeight: `bold`,
  },
  subtext: {
    fontSize: Typography.baseFontSize,
    color: Colors.gray,
    marginVertical: 10,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: Colors.chartreuse,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
  errorContainer: {
    marginTop: 10,
  },
  errorText: {
    ...Typography.smallText,
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    color: Colors.brightRed,
  },
};
