import { useQuery } from '@apollo/react-hooks';
import { FlatList, Text, TouchableOpacity, View } from 'react-native';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { chatClient } from '../chat/chatClient';
import { updatePersonaRelationship } from '../chat/chatServices';
import { Colors, Typography } from '../common-styles';
import { Avatar, Divider, ErrorBoundary, MeshIcon } from '../common-ui';
import { GET_OWN_BLOCKED_USERS_V2 } from '../graphql';
import { AppContext } from '../../AppContext';

export const BlockedUsersItem = () => {
  const navigation = useNavigation();
  const navigateToBlockedUsersList = useCallback(() => navigation.push(`BlockedUsersList`), [navigation]);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <TouchableOpacity
        testID="BLOCKED_USERS_LIST"
        activeOpacity={0.8}
        onPress={navigateToBlockedUsersList}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, padding: 16 }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Blocked Users</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const BlockedUsersList = () => {
  const { user } = useContext(AppContext);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const { error, data } = useQuery(GET_OWN_BLOCKED_USERS_V2, { fetchPolicy: `cache-and-network` });

  useEffect(() => {
    const filteredUsers = data?.getOwnBlockedUsersV2.filter((user) => user?.blocked_user?.handle !== `[deleted user]`);
    setBlockedUsers(filteredUsers);
  }, [data]);

  const _keyExtractor = (item, index) => item.id + index.toString();

  const renderItem = useCallback(
    ({ item: { blocked_user }, navigation }) => {
      return (
        <View
          testID="BLOCKED_USER"
          style={{
            flex: 1,
            padding: 16,
            backgroundColor: Colors.white,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <View style={{ flex: 1, flexDirection: `row`, alignItems: `center` }}>
            <Avatar navigation={navigation} user={blocked_user} size={40} />
            <Text style={{ ...Typography.text(`plusone`, `bold`), marginLeft: 16 }}>{blocked_user.handle}</Text>
          </View>
          <TouchableOpacity activeOpacity={0.8} onPress={() => unblockUnmuteUser(blocked_user.id)} testID="UNBLOCK_USER">
            <View>
              <Text style={Typography.text(`bold`, `gray`)}>Unblock</Text>
            </View>
          </TouchableOpacity>
          <Divider />
        </View>
      );
    },
    [unblockUnmuteUser],
  );

  const unblockUnmuteUser = useCallback(
    async (user_id) => {
      try {
        // unblock in our db table persona_relationship
        await unblockUser(user_id);
        // unmute this user for stream chat
        await chatClient.unmuteUser(user_id);
      } catch (err) {
        console.error(`Error thrown in unblockUnmuteUser()`, err.message);
      }
    },
    [unblockUser],
  );

  const unblockUser = useCallback(
    async (user_id) => {
      console.log(`unblock this user`, user_id);
      try {
        const ownId = user?.id;
        const result = await updatePersonaRelationship(ownId, user_id, [], false);
        if (result) {
          // eslint-disable-next-line react/prop-types
          const newArr = blockedUsers.filter((item) => item.id !== user_id);
          setBlockedUsers(newArr);
        }
        return result;
      } catch (err) {
        console.error(`error thrown in unblockUser()`, err);
        return null;
      }
    },
    [blockedUsers, user],
  );

  return (
    <View>
      {!error ? (
        <FlatList
          testID="BLOCKED_LIST"
          data={blockedUsers || []}
          keyExtractor={_keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={Divider}
          ListEmptyComponent={() => (
            <View style={{ flex: 1, justifyContent: `center`, marginTop: 20 }}>
              <Text testID="EMPTY_LIST" style={Typography.text(`gray`, `center`)}>
                You have no blocked users.
              </Text>
            </View>
          )}
        />
      ) : (
        <ErrorBoundary header="Error loading blocked users">
          <View style={{ justifyContent: `center` }}>
            <Text style={Typography.text(`plustwo`, `gray`, `bold`, `center`)}>Error loading blocked users</Text>
          </View>
        </ErrorBoundary>
      )}
    </View>
  );
};
