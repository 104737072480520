import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import React from 'react';
import { AppContext } from '../../AppContext';
import { Colors } from '../common-styles';
import { client, SAVE_PUSH_NOTIFICATION_TOKEN } from '../graphql';

export const usePushToken = () => {
  const { identity, pushToken, setPushToken } = React.useContext(AppContext);

  const registerForExpoPushNotifications = React.useCallback(async () => {
    try {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== `granted`) {
        console.log(`=== PN not granted ===`);
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== `granted`) return console.log(`=== failed to return push token for push notification`);
      const token = await Notifications.getExpoPushTokenAsync({ experienceId: `@drewthaler/mesh` });
      // now save the PN token in push_notification_token table
      const { data } = await client.mutate({ mutation: SAVE_PUSH_NOTIFICATION_TOKEN, variables: { push_token: token.data } });
      const { push_token, user_id } = data?.savePushNotificationToken || {};

      console.log(`[PN Granted] Push Notification Token: ${push_token} for ${user_id}`);
      setPushToken(push_token || token?.data);

      if (Platform.OS === `android`) {
        Notifications.setNotificationChannelAsync(`default`, {
          name: `default`,
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: Colors.brandPurple,
        });
      }
    } catch (error) {
      console.error(`Error thrown in registerForExpoPushNotifications`, error.message);
    }
    return null;
  }, [setPushToken]);

  React.useEffect(() => {
    if (pushToken) return; //console.log(`=== pushToken already in context state ===`);
    if (!Constants.isDevice) {
      console.log(`Must use a physical device for Push Notifications`);
      return;
    }
    if (!identity) {
      console.log(`Must have an identity to match push token`);
      return;
    }
    registerForExpoPushNotifications();
  }, [pushToken, identity, registerForExpoPushNotifications, setPushToken]);
};

export const useBadgeCounter = () => {
  const { chatNotifications, notificationCount, followNotificationCount, moderationNotificationCount } =
    React.useContext(AppContext);
  React.useEffect(() => {
    const totalCount = chatNotifications + notificationCount + followNotificationCount + moderationNotificationCount;
    Notifications.setBadgeCountAsync(totalCount);
  }, [chatNotifications, notificationCount, followNotificationCount, moderationNotificationCount]);
};
