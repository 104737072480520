import { useNavigation } from '@react-navigation/native';
import { ApolloError } from 'apollo-client';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { ActivityIndicator, FlatList, ListRenderItem, StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import FastImage from 'react-native-fast-image';
import { ScrollView, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { Colors } from '../../common-styles';
import { GroupCoreFields as ICarouselItem, GroupMember, WithChildren } from '../../common-types/types';
import { MeshIcon } from '../../common-ui';
import { loadCachedGroupMember } from '../../graphql';
import { Events, PendoTrackEvent } from '../../pendo/events';
import { RootState } from '../../redux/store';
import { trimTextContent } from './helpers';

type CarouselProps = {
  communities: ICarouselItem[];
  isLoading: boolean;
  error?: ApolloError;
  activeItem: ICarouselItem | null;
  onCommunityPress: (community: ICarouselItem) => void;
};

export const Carousel = ({ communities, onCommunityPress, activeItem, isLoading, error }: CarouselProps) => {
  const carouselScroll = useRef<FlatList<ICarouselItem> | null>(null);
  const { user } = useContext(AppContext);
  const { activeCommunity } = useSelector((state: RootState) => state.feed);
  const { width } = useWindowDimensions();
  const navigation = useNavigation<any>();

  useEffect(() => {
    // await for isLoading to be false so scrollToIndex doesn't trigger Array out of bounds
    if (activeCommunity?.id && !isLoading) {
      const index = communities.findIndex((c) => c.id === activeCommunity.id);
      if (index > -1) {
        carouselScroll.current?.scrollToIndex({ animated: true, index, viewPosition: 0.5 });
      }
    }
  }, [activeCommunity?.id, isLoading, communities]);

  const list = useMemo(() => {
    const plusItem: ICarouselItem = {
      id: `plus`,
      name: `Create`,
      avatar_url: ``,
      group_member_count: 0,
      latest_post: ``,
      splash_url: ``,
      application_pending: false,
      join_request_pending: false,
      guidelines_updated_at: ``,
      created_at: ``,
      latest_post_actor_id: ``,
      discoverable: ``,
      privacy: ``,
    };

    const exploreItem: ICarouselItem = {
      id: `explore`,
      name: `Explore`,
      avatar_url: ``,
      group_member_count: 0,
      latest_post: ``,
      splash_url: ``,
      application_pending: false,
      join_request_pending: false,
      guidelines_updated_at: ``,
      created_at: ``,
      latest_post_actor_id: ``,
      discoverable: ``,
      privacy: ``,
    };

    if (communities) return [...communities, plusItem, exploreItem];
    return [plusItem];
  }, [communities]);

  const renderItem: ListRenderItem<ICarouselItem> = useCallback(
    ({ item, index }) => {
      const { avatar_url, id, name, latest_post, latest_post_actor_id } = item;
      const isSelected = id === activeItem?.id;
      const gm: GroupMember | undefined = loadCachedGroupMember(id, user?.id);
      const { last_seen_at } = gm || {};

      const handlePressCommunity = () => {
        PendoTrackEvent(Events.VIEW_COMMUNITY, {
          community_name: item.name,
          source: `HomeScreen`,
        });
        onCommunityPress(item);
      };

      const handlePlusCommunity = () => {
        navigation.navigate(`CreateCommunity`);
      };

      const handleExploreCommunity = () => {
        navigation.navigate(`ExploreNavigator`, { screen: 'Search' });
      };

      const dateMoment = new Date(latest_post);
      const now = new Date();
      const hasRecentActivity = dateMoment.getTime() > now.getTime() - 86400000;
      const seen = dateMoment.getTime() < new Date(last_seen_at || 1).getTime();
      const isLatestPostCreatedByMe = latest_post_actor_id === user?.id;
      const showBadge = !isLatestPostCreatedByMe && hasRecentActivity && !isSelected && !seen;
      const priority = index < 7 ? FastImage.priority.high : FastImage.priority.low;

      if (item.id === `plus`) {
        return (
          <CarouselItem onPress={handlePlusCommunity} communityName="Create">
            <View style={styles.plusIconWrapper}>
              <MeshIcon name="plus-3" focused color={Colors.iconColor} size={25} />
            </View>
          </CarouselItem>
        );
      }

      if (item.id === `explore`) {
        return (
          <CarouselItem onPress={handleExploreCommunity} communityName="Explore">
            <View style={styles.plusIconWrapper}>
              <MeshIcon name="magnifyingglass" size={24} color={Colors.gray} />
            </View>
          </CarouselItem>
        );
      }

      return (
        <CarouselItem onPress={handlePressCommunity} active={isSelected} communityName={name}>
          <View style={styles.carouselImgWrapper}>
            {showBadge && <View style={styles.carouselNoteBadge} />}
            {avatar_url ? (
              <FastImage style={styles.carouselImage} source={{ uri: avatar_url, priority }} />
            ) : (
              <FastImage style={styles.carouselImage} source={require(`../../../assets/images/node.png`)} />
            )}
          </View>
        </CarouselItem>
      );
    },
    [navigation, onCommunityPress, activeItem?.id, user?.id],
  );

  const renderEmpty = useCallback(() => {
    let mock: number[] = [];
    if (isLoading) mock = [1, 2, 3, 4, 5];
    return (
      <View style={[styles.carouselContainer, { flexDirection: `row`, width }]}>
        {mock.map((_, index) => {
          const name = index === 0 ? `Loading...` : ` `;
          return (
            <View key={`${_}`} style={{ width: `20%` }}>
              <CarouselItem active={index === 0} onPress={() => null} communityName={name}>
                {index === 1 ? <ActivityIndicator size={60} /> : <View style={styles.carouselMockAvatar} />}
              </CarouselItem>
            </View>
          );
        })}
      </View>
    );
  }, [isLoading, width]);

  if (error && !communities) {
    return (
      <View style={styles.carouselContainer}>
        <Text>Error loading communities, try again</Text>
      </View>
    );
  }

  return (
    <ScrollView style={styles.carouselContainer} bounces={false}>
      <FlatList<ICarouselItem>
        horizontal
        ref={carouselScroll}
        bounces={false}
        testID="CAROUSEL_CONTAINER"
        onScrollToIndexFailed={() => {
          carouselScroll.current?.scrollToIndex({ index: 0, viewPosition: 0 });
        }}
        getItemLayout={(_, index) => ({ length: 72, offset: 72 * index, index })}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        initialNumToRender={10}
        maxToRenderPerBatch={12}
        data={list}
        contentContainerStyle={{
          alignItems: `center`,
          paddingStart: 5,
          paddingEnd: 5,
        }}
        ListEmptyComponent={renderEmpty}
        renderItem={renderItem}
        keyExtractor={(item: ICarouselItem) => item.id}
      />
    </ScrollView>
  );
};

type CarouselItemProps = {
  onPress: () => void;
  communityName: string;
  active?: boolean;
} & WithChildren;

export const CarouselItem = ({ onPress, communityName, children, active = false }: CarouselItemProps) => {
  return (
    <TouchableWithoutFeedback testID="CAROUSEL_ITEM_BUTTON" onPress={onPress}>
      <View
        style={{
          backgroundColor: Colors.white,
          alignItems: `center`,
          paddingHorizontal: 6,
          height: 104,
          justifyContent: `center`,
          borderTopWidth: 3,
          borderTopColor: active ? Colors.brandPurple : Colors.white,
        }}
      >
        <View style={styles.carouselItemShadow}>{children}</View>
        <View style={{ width: `100%`, height: 28, justifyContent: `flex-start`, alignItems: `center`, marginTop: 2 }}>
          <Text
            maxFontSizeMultiplier={1.5}
            numberOfLines={1}
            style={[styles.carouselText, { color: active ? Colors.textBlack : Colors.gray }]}
          >
            {trimTextContent(communityName, 23)}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  carouselContainer: {
    flex: 1,
    width: `100%`,
    paddingHorizontal: 0,
    backgroundColor: Colors.white,
    zIndex: 4,
    height: 90,
  },
  carouselItemShadow: {
    shadowColor: Colors.avatarShadowColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  carouselImgWrapper: {
    position: `relative`,
    width: 60,
    height: 60,
  },
  plusIconWrapper: {
    position: `relative`,
    width: 60,
    height: 60,
    justifyContent: `center`,
    alignItems: `center`,
    borderRadius: 150 / 2,
    overflow: `hidden`,
    backgroundColor: `white`,
  },
  carouselNoteBadge: {
    position: `absolute`,
    backgroundColor: Colors.hotPink,
    width: 8,
    height: 8,
    borderRadius: 50,
    alignSelf: `flex-end`,
    top: -2,
    right: -2,
  },
  carouselImage: {
    borderRadius: 150 / 2,
    overflow: `hidden`,
    width: 60,
    height: 60,
  },
  carouselMockAvatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: Colors.lightGray,
  },
  carouselText: {
    fontSize: 9.8,
    letterSpacing: -0.1,
    lineHeight: 14,
    justifyContent: `center`,
    maxWidth: 63,
    textAlign: `center`,
    overflow: `hidden`,
  },
});
