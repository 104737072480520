import React from 'react';
import { ExploreAnimals } from '../images/exploreCategoriesImages/ExploreAnimals';
import { ExploreArchitecture } from '../images/exploreCategoriesImages/ExploreArchitecture';
import { ExploreArts } from '../images/exploreCategoriesImages/ExploreArts';
import { ExploreEntertainment } from '../images/exploreCategoriesImages/ExploreEntertainment';
import { ExploreBeauty } from '../images/exploreCategoriesImages/ExploreBeauty';
import { ExploreBusiness } from '../images/exploreCategoriesImages/ExploreBusiness';
import { ExploreCareer } from '../images/exploreCategoriesImages/ExploreCareer';
import { ExploreEvents } from '../images/exploreCategoriesImages/ExploreEvents';
import { ExploreEducation } from '../images/exploreCategoriesImages/ExploreEducation';
// import { ExploreFinance } from '../images/exploreCategoriesImages/ExploreFinance';
import { ExploreFitness } from '../images/exploreCategoriesImages/ExploreFitness';
import { ExploreFood } from '../images/exploreCategoriesImages/ExploreFood';
import { ExploreGaming } from '../images/exploreCategoriesImages/ExploreGaming';
import { ExploreGeneral } from '../images/exploreCategoriesImages/ExploreGeneral';
import { ExploreHealth } from '../images/exploreCategoriesImages/ExploreHealth';
import { ExploreHobbies } from '../images/exploreCategoriesImages/ExploreHobbies';
import { ExploreHome } from '../images/exploreCategoriesImages/ExploreHome';
import { ExploreJustForFun } from '../images/exploreCategoriesImages/ExploreJustForFun';
import { ExploreLGBTQ } from '../images/exploreCategoriesImages/ExploreLGBTQ';
import { ExploreLifestyle } from '../images/exploreCategoriesImages/ExploreLifestyle';
import { ExploreLiterature } from '../images/exploreCategoriesImages/ExploreLiterature';
import { ExploreMotivation } from '../images/exploreCategoriesImages/ExploreMotivation';
import { ExploreMusic } from '../images/exploreCategoriesImages/ExploreMusic';
import { ExploreNature } from '../images/exploreCategoriesImages/ExploreNature';
import { ExploreNetworking } from '../images/exploreCategoriesImages/ExploreNetworking';
import { ExploreParenting } from '../images/exploreCategoriesImages/ExploreParenting';
import { ExplorePolitics } from '../images/exploreCategoriesImages/ExplorePolitics';
import { ExploreRelationship } from '../images/exploreCategoriesImages/ExploreRelationship';
import { ExploreScience } from '../images/exploreCategoriesImages/ExploreScience';
import { ExploreSustainability } from '../images/exploreCategoriesImages/ExploreSustainability';
import { ExploreTechnology } from '../images/exploreCategoriesImages/ExploreTechnology';
import { ExploreTravel } from '../images/exploreCategoriesImages/ExploreTravel';
import { ExploreSports } from '../images/exploreCategoriesImages/ExploreSports';
import { ExploreReligion } from '../images/exploreCategoriesImages/ExploreReligion';
import { ExploreActivism } from '../images/exploreCategoriesImages/ExploreActivism';
import { ExploreCulture } from '../images/exploreCategoriesImages/ExploreCulture';
import { ExploreMoney } from '../images/exploreCategoriesImages/ExploreMoney';
import { ExploreHumor } from '../images/exploreCategoriesImages/ExploreHumor';
import { ExploreVehicles } from '../images/exploreCategoriesImages/ExploreVehicles';
import { ExplorePhotography } from '../images/exploreCategoriesImages/ExplorePhotography';
import { ExploreWomen } from '../images/exploreCategoriesImages/ExploreWomen';

type CategoryImages = { [key: string]: React.ReactNode };

export const category_images: CategoryImages = {
  activism: ExploreActivism,
  animals: ExploreAnimals,
  architecture_and_design: ExploreArchitecture,
  arts_and_entertainment: ExploreEntertainment,
  arts_and_crafts: ExploreArts,
  beauty_and_fashion: ExploreBeauty,
  business: ExploreBusiness,
  career_advice: ExploreCareer,
  current_events: ExploreEvents,
  culture: ExploreCulture,
  education: ExploreEducation,
  money: ExploreMoney,
  fitness: ExploreFitness,
  food_and_drink: ExploreFood,
  gaming: ExploreGaming,
  general: ExploreGeneral,
  health_and_wellness: ExploreHealth,
  hobbies: ExploreHobbies,
  home_and_gardening: ExploreHome,
  humor: ExploreHumor,
  fun: ExploreJustForFun,
  'lgbtq+': ExploreLGBTQ,
  lifestyle: ExploreLifestyle,
  literature: ExploreLiterature,
  motivation_and_inspiration: ExploreMotivation,
  music: ExploreMusic,
  nature: ExploreNature,
  networking: ExploreNetworking,
  parenting: ExploreParenting,
  // pets: ExploreAnimals,
  politics: ExplorePolitics,
  photography: ExplorePhotography,
  relationships_and_dating: ExploreRelationship,
  religion: ExploreReligion,
  science: ExploreScience,
  sports: ExploreSports,
  sustainability: ExploreSustainability,
  vehicles: ExploreVehicles,
  technology: ExploreTechnology,
  travel: ExploreTravel,
  women: ExploreWomen,
};

//it is declared as array so more filters can be added and it can be dynamic
export const availableExploreFilters = [
  {
    type: `community_size_filter`,
    label: `Community size`,
    description: `Short description for the first filter`,
    iconName: `group`,
  },
  {
    type: `community_activity_filter`,
    label: `Consecutive days of activity`,
    description: `Short description for the second filter`,
    iconName: `like`,
  },
];

export const consecutiveDaysOfActivityOptions = [
  { key: `0_days`, label: `0 days`, value: 0 },
  { key: `1_day`, label: `1 day`, value: 1 },
  { key: `3_days`, label: `3 days`, value: 3 },
  { key: `7_days`, label: `7 days`, value: 7 },
  { key: `30_days`, label: `30 days`, value: 30 },
];

export const membershipFilterOptions = [
  { key: `any`, label: `All`, value: `Any` },
  { key: `member`, label: `Member`, value: `Member` },
  { key: `not_a_member`, label: `Not a member`, value: `Not a member` },
];

export const DEFAULT_FILTERS = {
  posts: 0,
  members: 0,
  membership: `Any`,
};
