import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const BitcoinImage = (props) => {
  return (
    <Svg width={66} height={46} viewBox="0 0 66 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0h1300v7847H0z" />
        <Path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill="#F7931A"
        />
        <Path
          d="M41.363 20.778c.343-2.293-1.403-3.525-3.79-4.347l.775-3.106-1.891-.471-.754 3.024a79.085 79.085 0 00-1.515-.357l.76-3.044-1.89-.47-.775 3.104a63.245 63.245 0 01-1.207-.284l.002-.01-2.607-.65-.503 2.019s1.403.321 1.373.341c.766.191.904.698.881 1.1l-.882 3.538c.053.013.121.033.197.063l-.2-.05-1.236 4.957c-.094.232-.332.581-.867.449.02.027-1.374-.343-1.374-.343l-.938 2.164 2.46.613c.457.115.906.235 1.348.348l-.783 3.142 1.889.47.774-3.107c.516.14 1.017.269 1.507.39l-.772 3.094 1.89.471.783-3.135c3.224.61 5.648.364 6.668-2.552.822-2.348-.04-3.702-1.737-4.585 1.235-.285 2.166-1.097 2.414-2.776zm-4.32 6.057c-.584 2.348-4.537 1.079-5.818.76l1.038-4.161c1.281.32 5.391.953 4.78 3.401zm.585-6.09c-.533 2.135-3.823 1.05-4.89.784l.941-3.775c1.067.266 4.504.763 3.95 2.99z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};
