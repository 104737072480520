import React from 'react';
import PropTypes from 'prop-types';
import { Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Lightbox from 'react-native-lightbox';
import FastImage from 'react-native-fast-image';
import { Spacing } from '../common-styles';
import { AndroidImage, LoadingIndicator, MeshIcon } from '../common-ui';
import { postStyles } from './PostStyles';

export class ImageView extends React.PureComponent {
  static propTypes = {
    image: PropTypes.string.isRequired,
    imageCaption: PropTypes.string,
    parentWidth: PropTypes.number,
    onPress: PropTypes.func,
    isOpen: PropTypes.bool,
    swipeToDismiss: PropTypes.bool,
    isMeshBugs: PropTypes.bool,
  };

  static defaultProps = {
    imageCaption: undefined,
    parentWidth: 600,
    onPress: undefined,
    isOpen: undefined,
    swipeToDismiss: undefined,
    isMeshBugs: undefined,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      broken: false,
      aspectRatio: undefined,
      webHeight: 0,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;

    if (this._isMounted === true) {
      const { image } = this.props;

      if (Platform.OS !== `web`) {
        // aspectRatio prop is only available to RN client, does not work on web
        this.getAspectRatio(image);
      } else {
        // we'll grab the parent container width and determine scaling of image height with that
        this.getWebDimensions(image);
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getAspectRatio = (image) => {
    Image.getSize(image, (width, height) => {
      if (!this._isMounted) return;
      if (width && height) this.setState({ aspectRatio: width / height });
      else this.setState({ broken: true });
    });
  };

  getWebDimensions = (image) => {
    const { parentWidth } = this.props;
    Image.getSize(image, (width, height) => {
      if (!this._isMounted) return;
      if (width && height) {
        // const scaledWidth = 600 / width;
        const scaledWidth = parentWidth / width;
        this.setState({ webHeight: height * scaledWidth });
      } else this.setState({ broken: true });
    });
  };

  renderImage = (image, imageCaption, onPress, isOpen, swipeToDismiss) => {
    const { isMeshBugs } = this.props;
    const { aspectRatio, webHeight, broken } = this.state;

    const imageStyles = StyleSheet.create({
      webContainer: {
        maxHeight: (Dimensions.get(`window`).height * 3) / 5,
        maxWidth: `100%`,
        overflow: `hidden`,
      },
      webImage: {
        // width: Dimensions.get('window').width,
        width: `100%`,
        maxWidth: `100%`,
        minWidth: Spacing.standardWidth,
        height: webHeight,
        maxHeight: `100%`,
        alignSelf: `center`,
      },
      renderContentContainer: {
        ...StyleSheet.flatten(postStyles.lightboxContainer),
      },
      iosImageInLightbox: {
        ...StyleSheet.flatten(postStyles.imageInLightBox),
        aspectRatio,
      },
      iosImageContainer: {
        maxHeight: (Dimensions.get(`window`).height * 3) / 5,
        height: isMeshBugs ? 500 : undefined,
        maxWidth: isMeshBugs ? 250 : `100%`,
        overflow: `hidden`,
        justifyContent: `center`,
        alignItems: `center`,
      },
      placeholderContainer: {
        width: Dimensions.get(`window`).width,
        height: 300,
        justifyContent: `center`,
      },
      meshBugsImageStyle: {
        ...StyleSheet.flatten(postStyles.imageInPost),
        flex: 1,
        width: null,
        height: null,
      },
      iosImage: {
        ...StyleSheet.flatten(postStyles.imageInPost),
        width: `100%`,
        maxWidth: `100%`,
        aspectRatio,
      },
      androidImageContainer: {
        maxHeight: (Dimensions.get(`window`).height * 3) / 5,
        maxWidth: `100%`,
        overflow: `hidden`,
      },
    });

    if (Platform.OS === `web`) {
      return (
        // don't use lightbox in web, only show Image
        <View style={imageStyles.webContainer}>
          {/* below: this component is our custom component -- doesn't use native features / components so it is compatible in web */}
          <FastImage source={{ uri: image }} style={imageStyles.webImage} resizeMode="contain" />
        </View>
      );
    }
    if (Platform.OS === `ios`) {
      return (
        <Lightbox
          swipeToDismiss={swipeToDismiss === false ? swipeToDismiss : true}
          isOpen={isOpen}
          springConfig={{ overshootClamping: true }}
          renderHeader={(close) => (
            <TouchableOpacity onPress={onPress || close}>
              <View>
                <Text style={postStyles.lightboxCloseButton}>Close</Text>
              </View>
            </TouchableOpacity>
          )}
          renderContent={() => (
            <View style={imageStyles.renderContentContainer}>
              <FastImage source={{ uri: image || `` }} style={imageStyles.iosImageInLightbox} resizeMode="contain" />
              <Text style={postStyles.lightboxCaption}>{imageCaption}</Text>
            </View>
          )}
        >
          <View style={imageStyles.iosImageContainer}>
            {aspectRatio === undefined ? (
              <View style={imageStyles.placeholderContainer}>
                {!broken && <LoadingIndicator color="white" size="large" />}
                {broken && <MeshIcon name="broken-image" color="white" size="36" />}
              </View>
            ) : (
              <FastImage
                source={{ uri: image || `` }}
                style={isMeshBugs ? imageStyles.meshBugsImageStyle : imageStyles.iosImage}
                resizeMode="contain"
              />
            )}
          </View>
        </Lightbox>
      );
    }
    // resizeMode={'contain'} does not work on Android for .PNG images, need to resize it ourselves
    if (Platform.OS === `android`) {
      return (
        <Lightbox
          swipeToDismiss={swipeToDismiss === false ? swipeToDismiss : true}
          isOpen={isOpen}
          springConfig={{ overshootClamping: true }}
          renderHeader={(close) => (
            <TouchableOpacity onPress={onPress || close}>
              <View>
                <Text style={postStyles.lightboxCloseButton}>Close</Text>
              </View>
            </TouchableOpacity>
          )}
          renderContent={() => (
            <View style={imageStyles.renderContentContainer}>
              <AndroidImage uri={image} />
              <Text style={postStyles.lightboxCaption}>{imageCaption}</Text>
            </View>
          )}
        >
          <View style={imageStyles.androidImageContainer}>
            <AndroidImage uri={image} />
          </View>
        </Lightbox>
      );
    }
    return null;
  };

  render = () => {
    const { image, imageCaption, onPress, isOpen, swipeToDismiss } = this.props;
    return (
      <View style={postStyles.imageContainer}>{this.renderImage(image, imageCaption, onPress, isOpen, swipeToDismiss)}</View>
    );
  };
}
