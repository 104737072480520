import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  Share,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
  Linking,
  Platform,
  Keyboard,
  ListRenderItem,
  NativeSyntheticEvent,
  TextInputChangeEventData,
} from 'react-native';
import { unionBy } from 'lodash';
import SocialShare, { Social, ShareSingleOptions } from 'react-native-share';
import { useLazyQuery, useMutation } from 'react-apollo';
import Clipboard from '@react-native-clipboard/clipboard';
import { User } from '../../common-types/types';
import { Colors, Typography } from '../../common-styles';
import { Avatar, MeshIcon, ThemedButton } from '../../common-ui';
import { CREATE_INVITE_LINK, INVITE_FRIEND_TO_GROUP, SEARCH_FRIENDS, SEARCH_GLOBAL_USERS } from '../../graphql';
import { Events, PendoTrackEvent } from '../../pendo/events';
import { fixMeshInvitelLink } from '../../post/helpers';

// @ts-ignore reason: svg import type definition does not exist
import ShareViaIcon from '../../../assets/images/ShareVia.svg';
// @ts-ignore
import CopyLinkIcon from '../../../assets/images/CopyLink.svg';
// @ts-ignore
import MessageIcon from '../../../assets/images/Messages.svg';
// @ts-ignore
import GoogleMessageIcon from '../../../assets/images/GoogleMessage.svg';
// @ts-ignore
import WhatsAppIcon from '../../../assets/images/WhatsApp.svg';
// @ts-ignore
import EmailIcon from '../../../assets/images/Email.svg';
// @ts-ignore
import MessengerIcon from '../../../assets/images/Messenger.svg';
// @ts-ignore
import InstagramIcon from '../../../assets/images/Instagram.svg';
// @ts-ignore
import TelegramIcon from '../../../assets/images/Telegram.svg';
// @ts-ignore
import TwitterIcon from '../../../assets/images/Twitter.svg';

const role_id = `7c062da3-3ccf-4431-8fc3-3def7bf65b00`;

type InviteFriendsProps = {
  groupId: string;
  groupName: string;
};

type ShareOption = {
  img: React.FC<React.SVGProps<any>>;
  label: string;
  onClick: () => void;
  installed: () => boolean | Promise<boolean>;
  enabled?: boolean;
};

type TrackAction = Social | `Share via...` | `friend` | `copy link`;

const InviteFriends: React.FC<InviteFriendsProps> = ({ groupId, groupName }) => {
  const [searched, setSearched] = useState(false);
  const [searchTxt, setSearchTxt] = useState(``);
  const [options, setOptions] = useState<any>([]);
  const carouselScroll = useRef<any>(null);
  const [invitedList, setInvitedList] = useState<string[]>([]);

  const [createInvite] = useMutation(CREATE_INVITE_LINK, {
    variables: { group_id: groupId, role_id, unlimited: true, version: 2 },
  });
  const [inviteFriendToGroup] = useMutation(INVITE_FRIEND_TO_GROUP);

  const [searchFriends, { data, fetchMore, loading }] = useLazyQuery(SEARCH_FRIENDS, { fetchPolicy: `network-only` });
  const [searchUsers, { data: searchedData, fetchMore: fetchMoreSearched, loading: loadingSearched }] = useLazyQuery(
    SEARCH_GLOBAL_USERS,
    { fetchPolicy: `network-only` },
  );

  const handleKeyPress = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    const txt = e.nativeEvent.text;
    setSearchTxt(txt);
    setSearched(false);
  };

  useEffect(() => {
    searchFriends({ variables: { filter: { keyword: ``, group_id: groupId, limit: 15, offset: 0 } } });
  }, [groupId, searchFriends]);

  const track = (action: TrackAction, target?: string) => {
    const input = {
      community_name: groupName,
      action: `${action}${target ? ` ${target}` : ``}`,
    };
    PendoTrackEvent(Events.SHARE_COMMUNITY, input);
  };

  const handleSearchFriends = useCallback(() => {
    if (!searchTxt) return;
    const variables = { keyword: searchTxt, group_id: groupId, limit: 15, offset: 0 };
    searchUsers({ variables });
    setSearched(true);
    Keyboard.dismiss();
  }, [groupId, searchUsers, searchTxt]);

  const handleCopyLink = async () => {
    try {
      const { data } = await createInvite();
      const url = fixMeshInvitelLink(data.createInviteLink.invite_url);
      Clipboard?.setString(`You’re invited to ${groupName}! ${url}`);
      console.log(`copy invite link to clipboard: `, url);
      track(`copy link`);
    } catch (err) {
      console.error(`copy invite link to clipboard failed: `, err);
    }
  };

  const handleShareVia = async () => {
    try {
      const { data } = await createInvite();
      const url = fixMeshInvitelLink(data.createInviteLink.invite_url);
      await Share.share({ message: `Join ${groupName} on Mesh\n${url}` });
      track(`Share via...`);
    } catch (err) {
      console.error(`share via failed: `, err);
    }
  };

  const handleInvite = async (item: any) => {
    console.log(`frined invite: `, JSON.stringify(item));
    await inviteFriendToGroup({ variables: { group_id: groupId, role_id, persona_ids: [item.id] } });
    setInvitedList((prev) => [...prev, item.id]);
    track(`friend`, item);
  };

  const handleSocialShare = async (SocialType: Social) => {
    try {
      const { data } = await createInvite();
      const url = fixMeshInvitelLink(data.createInviteLink.invite_url);
      if (SocialType === SocialShare.Social.INSTAGRAM) {
        const shareOptions = {
          social: SocialShare.Social.INSTAGRAM,
          url,
          type: `*`,
          forceDialog: true,
        };
        await SocialShare.shareSingle(shareOptions);
      } else if (SocialType === SocialShare.Social.MESSENGER) {
        Linking.openURL(`fb-messenger://share?link=${url}`);
      } else if (SocialType === SocialShare.Social.SMS && Platform.OS === `ios` && url) {
        const text = encodeURIComponent(url);
        Linking.openURL(`sms:${Platform.OS === `ios` ? `&` : `?`}body=${text}`);
      } else if (SocialType === SocialShare.Social.EMAIL) {
        const shareOptions: ShareSingleOptions = {
          subject: `Invitation to join ${groupName} on mesh`,
          message: `I'd like to invite you to join ${groupName} on mesh. Tap the link to download the app and join community`,
          url,
          social: SocialType,
        };
        await SocialShare.shareSingle(shareOptions);
      } else if (SocialType !== SocialShare.Social.FACEBOOK_STORIES) {
        const shareOptions: ShareSingleOptions = {
          title: `Invitation to join ${groupName} on mesh`,
          message: `I'd like to invite you to join ${groupName} on mesh. Tap the link to download the app and join community`,
          url,
          social: SocialType,
        };
        await SocialShare.shareSingle(shareOptions);
      }
      track(SocialType);
    } catch (err) {
      console.error(`share via messages failed: `, err);
    }
  };

  const fetchMoreFriends = useCallback(() => {
    if (loading) return;
    const friendsLength = data?.searchForFriends.length;
    const variables = { filter: { keyword: searchTxt, group_id: groupId, offset: friendsLength, limit: friendsLength + 15 } };

    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          friendsLength === 0 ||
          (fetchMoreResult?.searchForFriends?.length > 0 &&
            fetchMoreResult.searchForFriends.slice(-1)[0].id === prev.searchForFriends.slice(-1)[0].id)
        ) {
          console.log(`same result`);
          return prev;
        }
        return {
          ...prev,
          searchForFriends: unionBy(prev.searchForFriends, fetchMoreResult.searchForFriends),
        };
      },
    });
  }, [data?.searchForFriends, fetchMore, searchTxt, groupId, loading]);

  const fetchMoreUsers = useCallback(() => {
    if (loadingSearched) return;
    const friendsLength = searchedData?.searchUsersToInvite.length;
    const variables = { keyword: searchTxt, group_id: groupId, offset: friendsLength, limit: friendsLength + 15 };

    fetchMoreSearched({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          friendsLength === 0 ||
          (fetchMoreResult?.searchUsersToInvite?.length > 0 &&
            fetchMoreResult.searchUsersToInvite.slice(-1)[0].id === prev.searchUsersToInvite.slice(-1)[0].id)
        ) {
          console.log(`same result`);
          return prev;
        }
        return {
          ...prev,
          searchForFriends: unionBy(prev.searchUsersToInvite, fetchMoreResult.searchUsersToInvite),
        };
      },
    });
  }, [searchedData?.searchUsersToInvite, fetchMoreSearched, searchTxt, groupId, loadingSearched]);

  const inviteOptions: ShareOption[] = [
    {
      img: ShareViaIcon,
      label: `Share via...`,
      onClick: handleShareVia,
      installed: () => true,
    },
    {
      img: CopyLinkIcon,
      label: `Copy link`,
      onClick: handleCopyLink,
      installed: () => true,
    },
    {
      img: Platform.OS === `ios` ? MessageIcon : GoogleMessageIcon,
      label: `Messages`,
      onClick: () => handleSocialShare(SocialShare.Social.SMS),
      installed: () => true,
    },
    {
      img: WhatsAppIcon,
      label: `WhatsApp`,
      onClick: () => handleSocialShare(SocialShare.Social.WHATSAPP),
      installed: () => Linking.canOpenURL(`whatsapp://`),
    },
    {
      img: EmailIcon,
      label: `Email`,
      onClick: () => handleSocialShare(SocialShare.Social.EMAIL),
      installed: () => true,
    },
    {
      img: MessengerIcon,
      label: `Messenger`,
      onClick: () => handleSocialShare(SocialShare.Social.MESSENGER),
      installed: () => Linking.canOpenURL(`fb-messenger://`),
    },
    {
      img: InstagramIcon,
      label: `Instagram`,
      onClick: () => handleSocialShare(SocialShare.Social.INSTAGRAM),
      installed: () => (Platform.OS === `android` ? Linking.canOpenURL(`instagram://`) : false),
    },
    {
      img: TelegramIcon,
      label: `Telegram`,
      onClick: () => handleSocialShare(SocialShare.Social.TELEGRAM),
      installed: () => Linking.canOpenURL(`telegram://`),
    },
    {
      img: TwitterIcon,
      label: `Twitter`,
      onClick: () => handleSocialShare(SocialShare.Social.TWITTER),
      installed: () => Linking.canOpenURL(`twitter://`),
    },
  ];
  useEffect(() => {
    const asyncOptions = async () => {
      const optionPromise = inviteOptions.map(async (op) => {
        const enabled = await op.installed();
        return { ...op, enabled };
      });
      const data = await Promise.all(optionPromise);
      setOptions(data.filter((it) => it.enabled));
    };
    asyncOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem: ListRenderItem<ShareOption> = ({ item }) => {
    const { img: ImageIcon, label, onClick } = item;
    return (
      <TouchableOpacity onPress={onClick}>
        <View style={styles.shareItemWrapper}>
          <View style={styles.manualShare}>
            <ImageIcon width={70} height={70} />
          </View>
          <Text style={styles.shareTxt}>{label}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  const renderFriend: ListRenderItem<User> = ({ item }) => {
    return (
      <View style={styles.friendItem}>
        <Avatar border user={item} size={40} />
        <View style={{ flex: 1, marginLeft: 10 }}>
          <Text style={Typography.text(`bold`)}>{item?.handle}</Text>
          <Text style={styles.friendName as any}>{item?.name}</Text>
        </View>

        <ThemedButton
          rounded
          title={invitedList.includes(item?.id || ``) ? `Invited` : `Invite`}
          titleStyle={{ fontSize: Typography.baseFontSize }}
          onPress={() => handleInvite(item)}
          disabled={invitedList.includes(item?.id || ``)}
          buttonStyle={{ paddingVertical: 6, paddingHorizontal: 12 }}
        />
      </View>
    );
  };

  return (
    <View>
      <View style={styles.shareWrapper}>
        <FlatList<ShareOption>
          horizontal
          ref={carouselScroll}
          bounces={false}
          onScrollToIndexFailed={() => carouselScroll.current?.scrollToIndex({ index: 0, viewPosition: 0 })}
          getItemLayout={(_, index) => ({ length: 72, offset: 72 * index, index })}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          initialNumToRender={10}
          maxToRenderPerBatch={12}
          data={options}
          contentContainerStyle={{ alignItems: `center` }}
          renderItem={renderItem}
          keyExtractor={(item: any) => item.label}
        />
      </View>

      <View style={{ paddingHorizontal: 20 }}>
        <View style={styles.inputWrapper}>
          <TextInput style={styles.textInput} onChange={handleKeyPress} onEndEditing={handleSearchFriends}>
            {searchTxt}
          </TextInput>

          <TouchableOpacity
            hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
            style={{ padding: 10 }}
            onPress={handleSearchFriends}
          >
            <MeshIcon name="magnifyingglass" size={20} color={Colors.mediumGray} />
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.friendsList}>
        <FlatList<User>
          data={searched ? searchedData?.searchUsersToInvite || [] : data?.searchForFriends || []}
          keyExtractor={(item, index) => `${item.id}-${index}`}
          renderItem={renderFriend}
          onEndReachedThreshold={0.3}
          onEndReached={searched ? fetchMoreUsers : fetchMoreFriends}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  shareWrapper: {
    display: `flex`,
    flexDirection: `row`,
    marginBottom: 15,
    paddingLeft: 20,
  },
  shareItemWrapper: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
  },
  manualShare: {
    borderColor: Colors.mediumLightGray,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    overflow: `hidden`,
  },
  shareTxt: {
    fontSize: 12,
    color: Colors.darkGray,
  },
  inputWrapper: {
    borderWidth: 1,
    borderColor: `#D8D8D8`,
    borderRadius: 4,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    width: `100%`,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 10,
  },
  friendsList: {
    height: 260,
    paddingTop: 10,
  },
  friendItem: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    paddingVertical: 4,
    paddingHorizontal: 20,
  },
  friendName: {
    ...Typography.text(`small`, `gray`, `bold`),
    paddingTop: 4,
  },
});

export default InviteFriends;
