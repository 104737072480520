/* eslint-disable react-native/split-platform-components */
import * as ImagePicker from 'expo-image-picker';
import React, { createContext, useCallback, useState, useRef } from 'react';
import { PermissionsAndroid, Platform } from 'react-native';
import { PhotoPermissionModal } from '../common-ui';

// expo-camera: getCameraPermissionsAsync
//    - permission.RECORD_AUDIO
//    - permission.CAMERA
// expo-image-picker: getCameraPermissionsAsync
//    - permission.RECORD_AUDIO
//    - permission.CAMERA

const MediaPermissionContext = createContext({
  // eslint-disable-next-line promise/param-names
  checkPermission: () => new Promise<any>((_resolve, _reject) => {}),
});

export const MediaPermissionProvider = ({ children }: any) => {
  const [opened, setOpened] = useState(false);
  const [mediaLibraryPermission, requestMediaLibraryPermission] = ImagePicker.useMediaLibraryPermissions();

  // TODO: verify requestMediaLibraryPermission includes camera permission
  const isAccessible = useCallback(async () => {
    if (Platform.OS === `android`) {
      const cameraPermission = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA);
      const storageReadPermission = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE);
      const storageWritePermission = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE);

      if (
        cameraPermission === PermissionsAndroid.RESULTS.DENIED ||
        storageReadPermission === PermissionsAndroid.RESULTS.DENIED ||
        storageWritePermission === PermissionsAndroid.RESULTS.DENIED
      ) {
        console.error(`Permissions not granted to access`);
        return false;
      }
    } else if (mediaLibraryPermission?.status) {
      let { status } = mediaLibraryPermission;
      if (status !== `granted`) {
        console.log(`not granted for camera roll, let's ask.`);
        const askResponse = await requestMediaLibraryPermission();
        status = askResponse.status;
        console.log(`permission ${status} for camera roll`);
        if (status !== `granted`) {
          return false;
        }
      }
    }

    return true;
  }, [mediaLibraryPermission]);

  const awaitingPrommiseRef = useRef<{ resolve: any; reject: any }>();
  const checkPermission = useCallback(async () => {
    const isValid = await isAccessible();
    if (!isValid) {
      setOpened(true);
    } else {
      return true;
    }

    return new Promise<any>((resolve, reject) => {
      awaitingPrommiseRef.current = { resolve, reject };
    });
  }, [mediaLibraryPermission, awaitingPrommiseRef.current]);

  const handleToggleModal = useCallback(async () => {
    if (opened && awaitingPrommiseRef.current) {
      const isValid = await isAccessible();
      awaitingPrommiseRef.current.resolve(isValid);
    }
    setOpened(!opened);
  }, [awaitingPrommiseRef.current, opened]);

  return (
    <MediaPermissionContext.Provider value={{ checkPermission }}>
      {children}

      <PhotoPermissionModal isVisible={opened} toggleModal={handleToggleModal} />
    </MediaPermissionContext.Provider>
  );
};

export default MediaPermissionContext;
