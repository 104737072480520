import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import 'react-native-get-random-values';
import { MessageInput, ThemeProvider, useMessageInputContext } from 'stream-chat-react-native';
import { AutoGrowingTextInput } from 'react-native-autogrow-textinput';
import { MeshIcon } from '../common-ui';
import { Colors, Typography } from '../common-styles';

const defaultHitSlop = {
  bottom: 10,
  left: 10,
  right: 10,
  top: 10,
};

export const ChatInput = ({ channel, isAbleToWrite }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const theme = {
    'messageInput.container': `background-color: transparent; border-width: 1px; border-color: ${
      isFocused ? Colors.chatTextInputFocusedBorderColor : Colors.chatTextInputDefaultBorderColor
    }; border-radius: 4px; margin-horizontal: 16px; margin-bottom: 8px; min-height: 48px;`,
    'messageInput.inputBoxContainer': `min-height: 23px; padding-horizontal: 0px; padding-vertical: 4px; margin: 0;`,
  };
  return (
    <ThemeProvider style={theme}>
      <MessageInput
        channel={channel}
        disabled={!isAbleToWrite}
        hasFilePicker={false}
        hasImagePicker={false}
        Input={() => <ChatInputBox isFocused={isFocused} disabled={!isAbleToWrite} setFocused={setIsFocused} />}
      />
    </ThemeProvider>
  );
};

const ChatInputBox = ({ isFocused, setFocused, disabled }) => {
  const { sendMessage, text, onChange } = useMessageInputContext();

  return (
    <View style={{ flexDirection: `row`, alignItems: `flex-end` }}>
      <AutoGrowingTextInput
        testID="CHAT_TEXTINPUT"
        style={styles.textInput}
        autoFocus={isFocused}
        placeholder="Write message..."
        value={text}
        multiline
        maxHeight={Math.max(Typography.text(`plusone`).fontSize, Typography.text(`plusone`).lineHeight) * 5 + 8}
        textAlignVertical="center"
        placeholderTextColor={Colors.textPlaceholder}
        onChangeText={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <TouchableOpacity
        testID="CHAT_SENDMESSAGE"
        hitSlop={defaultHitSlop}
        style={{ padding: 10 }}
        disabled={disabled}
        onPress={sendMessage}
      >
        <MeshIcon name="arrow-right" size={20} color={isFocused ? Colors.chatSendIconFocusedColor : Colors.chatSendIconColor} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    alignSelf: `center`,
    flex: 1,
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 4,
    marginRight: 0,
    marginLeft: 10,
    ...Typography.text(`plusone`),
  },
});

ChatInput.propTypes = {
  channel: PropTypes.object.isRequired,
  isAbleToWrite: PropTypes.bool.isRequired,
};

ChatInputBox.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  setFocused: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
