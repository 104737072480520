import { StyleSheet } from 'react-native';
import { Colors, Typography, _bad_do_not_use_WIDTH } from '../common-styles';

export const chatModalStyles = StyleSheet.create({
  modalContainerStyle: { marginHorizontal: 0, marginTop: 10, flex: 1 },
  headerContainer: { flexDirection: `row`, justifyContent: `space-between` },
  headerText: {
    flex: 1,
    textAlign: `center`,
    alignSelf: `center`,
    fontSize: Typography.buttonFontSize,
    fontWeight: `500`,
    marginLeft: 50,
  },
  image: { height: 300, width: _bad_do_not_use_WIDTH },
  memberNameText: { fontSize: Typography.buttonFontSize, fontWeight: `600` },
  memberDesciptionText: {
    marginTop: 8,
    fontSize: Typography.baseFontSize,
    color: Colors.darkGray,
  },
});
