import { Dimensions, StyleSheet } from 'react-native';
import { Colors, Typography } from '../common-styles';

export const createCommunityStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: `column`,
    backgroundColor: Colors.white,
  },
  headerContainer: {
    justifyContent: `center`,
    marginTop: 15,
    marginBottom: Dimensions.get(`window`).height / 11,
    marginHorizontal: 10,
  },
  headerText: {
    fontSize: Typography.largeFontSize,
    fontWeight: `bold`,
    flexShrink: 2,
    color: Colors.brandBlack,
  },
  headerSubtext: {
    fontSize: 18,
    color: Colors.darkGray,
    paddingBottom: 5,
  },
  textInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: Colors.brandBlack,
    padding: 10,
    borderRadius: 4,
    fontSize: Typography.baseFontSize,
  },
  navButtonContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
  },
  buttonContainer: {
    flexDirection: `column`,
    alignItems: `flex-start`,
    margin: 0,
  },
  buttonStyle: {
    backgroundColor: Colors.brandPurple,
    margin: 5,
    borderRadius: 25,
    paddingHorizontal: 15,
  },
  clearButtonContainer: {
    flexDirection: `row`,
    justifyContent: `space-around`,
  },
  clearButtonStyle: {
    alignSelf: `flex-start`,
  },

  uploadIconStyle: {
    alignSelf: `center`,
  },
  userItemContainer: {
    flex: 1,
    width: `100%`,
    alignSelf: `center`,
    padding: 1,
    margin: 1,
    // maxHeight: 60,
  },
});
