import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  StyleSheet,
  FlatList,
  useWindowDimensions,
  Image,
  Alert,
  Platform,
} from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import Emoji from 'react-native-emoji-v2';
import { useMutation, useQuery } from 'react-apollo';
import { UPDATE_REACTIONS, refetchQueriesFor, GET_CUSTOM_REACTIONS } from '../graphql';
import { Colors } from '../common-styles';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';
import { CustomReaction } from '../common-types/types';
import { LoadingIndicator } from '../common-ui';
import { Events, PendoTrackEvent } from '../pendo/events';
import { useCommunities } from '../community/v2/hooks/useCommunities';

type BaseEmoji = {
  [key: string]: string[];
};

type ReactionLibraryRoute = RouteProp<HomeNavigatorScreens, `ReactionLibrary`>;

type CustomReactionData = {
  getUploadedReactions: CustomReaction[];
};

const emojilist: BaseEmoji = require(`./emojis.json`);

const ReactionLibrary: React.FC = () => {
  const navigation = useNavigation<HomeNavigator>();
  const route = useRoute<ReactionLibraryRoute>();
  const { width } = useWindowDimensions();
  const { selected, setting, groupId } = route?.params;
  const [selectedItem, setSelectedItem] = useState(selected?.name);
  const numColumns = Math.floor((width - 20) / 40);
  const { activeCommunity } = useCommunities();

  const [updateReactions] = useMutation(UPDATE_REACTIONS, {
    refetchQueries: refetchQueriesFor(`Setting`),
  });

  const { data, loading } = useQuery<CustomReactionData>(GET_CUSTOM_REACTIONS, {
    variables: { group_id: groupId },
    fetchPolicy: `network-only`,
  });

  const isDuplicated = useMemo(() => {
    const settingArr = JSON.parse(setting.value as string);
    const duplicationIndex = settingArr.findIndex((item: any) => item.name === selectedItem && item.name !== selected);
    return duplicationIndex >= 0;
  }, [selectedItem, selected, setting]);

  const uploadedReactions = data?.getUploadedReactions || [];

  const handleSave = useCallback(async () => {
    if (isDuplicated) {
      Alert.alert(`Duplicated Emoji`, `This emoji is already selected in the top 6 emojis. Please select the another one`);
      return;
    }

    const uploadedReactions = data?.getUploadedReactions || [];
    const settingArr = JSON.parse(setting.value as string);
    const newList = settingArr.map((item: any) => {
      if (item.name === selected.name) {
        const customSelected = uploadedReactions.find((it: any) => it.name === selectedItem);
        // eslint-disable-next-line
        delete customSelected?.__typename;
        return customSelected ?? { name: selectedItem };
      }
      return item;
    });
    await updateReactions({ variables: { group_id: groupId, reactions: newList } });

    // Track Event in Pendo
    PendoTrackEvent(Events.CUSTOM_REACTION, {
      community_name: activeCommunity?.name,
      reaction_name: selectedItem,
      location: `Reaction Library`,
      community_role: `owner`,
    });

    navigation.goBack();
  }, [
    isDuplicated,
    setting.value,
    updateReactions,
    groupId,
    activeCommunity?.name,
    selectedItem,
    navigation,
    selected,
    data?.getUploadedReactions,
  ]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 5 }} onPress={handleSave}>
          <Text style={{ fontSize: 16 }}>Save</Text>
        </TouchableOpacity>
      ),
    });
  }, [handleSave, navigation, selectedItem]);

  const renderItem = useCallback(
    ({ item }) => (
      <TouchableOpacity key={item} onPress={() => setSelectedItem(item)}>
        <View style={item === selectedItem ? styles.selectedListItem : styles.listItem}>
          <Emoji name={item} style={{ fontSize: Platform.OS === `android` ? 24 : 32 }} />
        </View>
      </TouchableOpacity>
    ),
    [selectedItem],
  );

  const renderSection = ({ item }: any) => (
    <FlatList
      ListHeaderComponent={<Text style={styles.sectionTitle}>{item}</Text>}
      data={emojilist[item]}
      numColumns={numColumns}
      renderItem={renderItem}
      keyExtractor={(item: string) => item}
    />
  );

  const renderCustomItem = useCallback(
    ({ item }) => (
      <TouchableOpacity key={item.name} onPress={() => setSelectedItem(item.name)}>
        <View style={item.name === selectedItem ? styles.selectedListItem : styles.listItem}>
          <Image source={{ uri: item.url }} resizeMode="cover" style={{ width: 32, height: 32 }} />
        </View>
      </TouchableOpacity>
    ),
    [selectedItem],
  );

  const UploadedReactions = (
    <FlatList
      ListHeaderComponent={<Text style={styles.sectionTitle}>Custom Reactions</Text>}
      data={uploadedReactions}
      numColumns={numColumns}
      renderItem={renderCustomItem}
      keyExtractor={(item: any) => item.name}
    />
  );

  if (loading) {
    return (
      <View style={styles.loadingWrapper}>
        <LoadingIndicator size={32} style={{ margin: 32 }} />
      </View>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1, paddingHorizontal: 10 }}>
      <FlatList
        ListHeaderComponent={UploadedReactions}
        data={Object.keys(emojilist)}
        keyExtractor={(key: string) => key}
        renderItem={renderSection}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  loadingWrapper: {
    width: `100%`,
    height: `100%`,
    flex: 1,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  },
  sectionTitle: {
    fontSize: 16,
    paddingHorizontal: 5,
    marginTop: 5,
    paddingVertical: 10,
  },
  listItem: {
    flex: 1,
    alignItems: `center`,
    justifyContent: `center`,
    width: 40,
    height: 42,
    overflow: `hidden`,
    padding: 2,
  },
  selectedListItem: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
    width: 40,
    height: 42,
    overflow: `hidden`,
    padding: 2,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.brandCyan,
    backgroundColor: Colors.warningBackground,
  },
});

export default ReactionLibrary;
