import React from 'react';
import { View, Text } from 'react-native';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { MeshIcon, ThemedButton } from '../common-ui';

type Props = {
  required: boolean;
  hasSubmitted: boolean;
  setShowModal: () => void;
};

export const RequireMeshIdentity = ({ required, hasSubmitted, setShowModal }: Props) => {
  const { user } = React.useContext(AppContext);
  const isVerified = user?.identity_verified;

  let title = `Get Mesh Verified`;

  if (isVerified) title = `You're already Mesh Verified`;
  if (hasSubmitted) title = `You have submitted for Mesh Verification`;

  const renderLeftIcon = () => {
    if (!isVerified && !hasSubmitted) return null;
    const iconColor = isVerified ? Colors.brandGreen : Colors.brandPurple;
    return <MeshIcon name="check" color={iconColor} size={20} />;
  };

  const renderRightIcon = () => {
    if (isVerified || hasSubmitted) return null;
    return <MeshIcon name="arrow-right" color={Colors.brandPurple} size={20} />;
  };

  if (!required) return null;

  const justifyContentType = !isVerified ? `space-between` : `flex-start`;
  const verifiedStatusColor = isVerified ? Colors.brandGreen : Colors.brandPurple;
  return (
    <View style={{ flex: 1, margin: 10 }}>
      <Text style={{ fontSize: Typography.smallFontSize, color: Colors.gray, marginBottom: 10 }}>Mesh Verification Required</Text>
      <ThemedButton
        clear
        outline
        title={title}
        titleStyle={{ color: verifiedStatusColor, marginLeft: isVerified ? 10 : undefined }}
        buttonStyle={{
          justifyContent: justifyContentType,
          borderRadius: 4,
          borderColor: verifiedStatusColor,
        }}
        disabledStyle={{
          justifyContent: justifyContentType,
          borderRadius: 4,
          borderColor: verifiedStatusColor,
        }}
        onPress={setShowModal}
        rightIcon={renderRightIcon()}
        leftIcon={renderLeftIcon()}
        disabled={isVerified || hasSubmitted}
      />
    </View>
  );
};
