import { ReactNode, ReactElement } from 'react';
import { ColorValue, GestureResponderEvent, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { MeshIconsNames } from '../common-styles';
import { CreditCardNames } from '../contribution/creditCardsHelpers';
/**
 * Use interfaces to define the shape of your data and model the application’s
 * If you need to use types declaration it's ok too.
 *
 * Add interfaces/types that are shared between components here.
 * Isolated types like props and states should be defined in their respective files NOT HERE.
 *
 * If you have a big object you can use this to infer its types: https://transform.tools/json-to-typescript
 * Follow these rules: https://github.com/typescript-cheatsheets/react#reacttypescript-cheatsheets
 */

// #region Shared Interfaces --------------------------------------------------- //
// Commmon interfaces.
// ----------------------------------------------------------------------------- //

interface WithTypeName {
  __typename?: string;
}

interface KeyValue {
  key: string;
  value: string;
}

interface Timestamps {
  created_at: string;
  updated_at: string;
}

interface LabelValue {
  label: string;
  value: string;
  value_label?: string;
}

export interface WithChildren {
  children: ReactNode;
}

export type KeyMap<T> = { [key: string]: T };

export type Maybe<T> = T | null | undefined;
// #endregion

// #region miscelaneous types --------------------------------------------------- //
// ----------------------------------------------------------------------------- //

export interface MeshIcon {
  name?: MeshIconsNames;
  focused?: boolean;
  testID?: string;
  color?: string;
  size?: number;
  style?: any;
  onPress?: () => void;
}

export interface PersonaMetadata {
  personaId: string;
  lastCommunity: GroupCoreFields;
}

export interface MetaStyle extends WithTypeName {
  fontSize: number;
  lineHeight: number;
  align: string;
  hasBlob: boolean;
  blobIndex: number;
}

export interface Pronoun {
  id: number;
  pronoun: string;
}

export interface Guideline {
  id: string;
  title: string;
  body: string;
}
interface GroupApplication {
  identity_id: string;
  full_name: string;
  short_name: string;
  photo: string;
  questions: KeyValue[];
}

export interface GroupSponsor {
  id: string;
  name: string;
  group_id: string;
  avatar_url?: string;
  link_url?: string;
  end_date: string;
  start_date: string;
}
export interface Group extends WithTypeName, Timestamps {
  id: string;
  name: string;
  avatar_url: string;
  group_member_count: number;
  latest_post: string;
  splash_url: string;
  privacy: string;
  description: string;
  guidelines: string;
  guidelines_updated_at: string;
  application?: GroupApplication;
  application_pending: boolean;
  join_request_pending: boolean;
  suspended: boolean;
  suspend_expiration: any;
  archived: boolean;
  banned: boolean;
  discoverable: string;
  joinable: string;
  content_visibility: string;
  who_can_invite: string;
  category: Category;
  latest_post_actor_id: string;
  isVerified?: boolean;
  sponsor?: GroupSponsor;
}

export type GroupCoreFields = Pick<
  Group,
  | `id`
  | `name`
  | `avatar_url`
  | `group_member_count`
  | `__typename`
  | `latest_post`
  | `splash_url`
  | `application`
  | `application_pending`
  | `join_request_pending`
  | `guidelines_updated_at`
  | `created_at`
  | `latest_post_actor_id`
  | `discoverable`
  | `privacy`
  | `isVerified`
  | `sponsor`
>;

export interface GroupMember extends WithTypeName {
  id: string;
  group_id: string;
  persona_id: string;
  persona: Persona;
  role_id: string;
  role_name: string;
  has_agreed: boolean;
  agreement_timestamp: string;
  banned: boolean;
  ban_expiration: any;
  restricted: boolean;
  restricted_expiration: any;
  created_at: string;
  can_participate: boolean;
  can_post: boolean;
  can_invite: boolean;
  last_seen_at?: string;
}

export interface OwnedCommunitiesResp {
  group: Group;
  owner_count: number;
}

export interface AffiliatedCommunitiesResp {
  getAffiliatedGroups2: {
    statusCode: number;
    message: string;
    data: [GroupAndGroupMember];
  };
}

export interface JoinedGroup {
  group: GroupCoreFields;
  group_member: GroupMember;
}

export interface InvitedGroup {
  group: GroupCoreFields;
}

export interface Category extends LabelValue {}

export interface CategoryItem extends Category {
  id?: string;
  following?: boolean;
}

export interface Identity extends WithTypeName {
  id: string;
  secure_store: string;
  password?: string;
  accessToken?: string;
  refreshToken?: string;
  invite_group_id?: string;
  res_status: string;
  is_verified: boolean;
  agree_terms: boolean;
  loginTime?: string;
  env?: string;
}
export interface User extends WithTypeName {
  id?: string;
  handle: string;
  name?: string;
  description?: string;
  avatar_url?: string;
  splash_url?: string;
  identity_verified?: boolean;
  pronouns?: Pronoun[];
  dob?: string;
  follow_request?: FollowRequest;
  isVerified?: boolean;
  isBanned?: boolean;
}

export interface TokenRes extends User {
  color_theme?: string;
  feature_flags?: string;
  loginTime?: Date;
  invite_group_id?: string;
  invite_token?: string;
  //app-side only: determines whether is a newly created or an old persona
  pending_completition?: boolean;
}

export interface Reaction extends WithTypeName, Timestamps {
  id: string;
  kind: `comment` | `comment_reply` | `like` | `comment_like`;
  activity_id: string;
  user_id: string;
  user: Pick<User, `id` | `handle` | `name`>;
  data: ReactionData;
  own_like: boolean;
  parent?: string;
  children_counts: ChildrenCounts;
  latest_children?: LatestsReactions;
}

export interface CustomReactionCount extends WithTypeName {
  kind: string;
  url: string;
  count: number;
  own_like: boolean;
}
export interface ReactionItem {
  user: User;
  kind: string;
  url?: string;
}

export interface CustomReaction extends WithTypeName {
  id: string;
  name: string;
  group_id: string;
  url: string;
  created_at: string;
}

export interface Comment extends Reaction {
  kind: `comment`;
  parent: string;
  latest_children: LatestsReactions;
}
export interface Like extends WithTypeName {
  kind: `like`;
  parent: string;
  latest_children: LatestsReactions;
}

export interface ReactionCounts extends WithTypeName {
  /** Number of likes on this post */
  like: number;
  /** Number of coments on this post */
  comment: number;
  comment_reply?: number;
}

export interface ChildrenCounts extends ReactionCounts {
  comment_like: number;
  comment_reply: number;
}
interface LatestsReactions extends WithTypeName {
  like: Reaction[];
  comment: Reaction[];
  comment_like: Reaction[];
  comment_reply: Reaction[];
}

export interface ReactionData {
  object: string | null;
  group_id: string;
  user_id: string;
  comment_image: string | null;
  validMentionTargets?: UserMention[];
  removed: any | null;
  link_preview?: LinkPreview;
}

export interface FollowRequest {
  name?: string;
  avatar_url?: string;
  send_notification?: boolean;
  handle?: string;
  accepted?: boolean;
}
export interface Persona extends WithTypeName {
  id: string;
  handle: string;
  name?: string;
  description?: string;
  avatar_url?: string;
  splash_url?: string;
  identity_verified: boolean;
  pronouns: Pronoun[];
  password: String;
  generation_count: String;
  deleted: Boolean;
  deleted_at: String;
  settings: SettingsDetails[];
  color_theme: String;
  email: String;
  follow_request?: FollowRequest;
  isVerified?: boolean;
}

export interface LinkPreview extends WithTypeName {
  entered: string;
  imageBackgroundColor?: string;
  imageResizeMode?: string;
  imageUrls: string[];
  title?: string;
  url: string;
}

export interface UserAndGroup {
  user_id?: string;
  group_id?: string;
}

export interface Setting extends UserAndGroup {
  id: string;
  key: string;
  label: string;
  value: string | number;
  max_length?: number;
  section?: number;
  type: string;
  value_label?: string;
  visibility: string;
  index?: number;
}

export interface SettingOption {
  id: string;
  label: string;
  value: string;
  detail?: string;
}

export type EditableSettings = {
  [key: string]: Setting;
};

export type ListItemProps = {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  subtitle?: string;
  rightElement?: ReactElement;
};

export interface SettingItem {
  setting: Setting;
  listItemProps: ListItemProps;
}
export interface GroupInvite {
  id: string;
  invite_token: string;
  persona_id: string;
  group_id: string;
  role_id: string;
  role_name: string;
  inviter_id: string;
  inviter: User;
  created_at: string;
}

export interface Ad extends WithTypeName {
  id: string;
  type: string;
  advertiser_id: string;
  advertiser?: {
    id: string;
    name: string;
    handle: string;
    avatar_url: string;
  };
  ad_name?: string;
  image_urls: string[];
  link_url: string;
  copy: string;
  button_url: string;
  button?: string;
}
export interface CommunityAd extends Ad {
  group_id: string;
}

export interface AffiliatedGroups {
  statusCode: number;
  message: string;
  data: GroupAndGroupMember[];
}

export interface AffiliatedGroupsActivity {
  id: string;
  affiliations: GroupAndGroupMember[];
}

export type FeedItem =
  | Activity
  | CommunityAd
  | AffiliatedGroupsActivity
  | {
      id: string;
    };
export type Feed = FeedItem[];

export type TopicItem = {
  __typename: string;
  id: string;
  group_id: string;
  topic: string;
  post_count: number;
  created_at: string;
  updated_at: string;
  favorite: boolean;
  following: boolean;
  status: string;
};

export enum TopicStatus {
  SUGGESTED = `SUGGESTED`,
  ACTIVE = `ACTIVE`,
  DEACTIVATED = `DEACTIVATED`,
  DENIED = `DENIED`,
}

export type PollOption = {
  post_id: string;
  id: string;
  media_type: PollMediaTypes;
  content: string;
  display_order: number;
  vote_count: number;
  voted: boolean;
  removed: boolean;
  end_time: string;
};

export enum PollMediaTypes {
  TEXT = `text`,
  IMAGE = `image`,
  VIDEO = `video`,
  LINK = `link`,
  AUDIO = `audio`, // TODO
}

export type Poll = {
  options: PollOption[];
  end_time: string;
};

export interface Activity extends WithTypeName {
  id: string;
  post_id: string;
  origin_activity_id: string;
  removed: boolean;
  frozen: boolean;
  actor: string;
  actor_role?: string;
  original_verb: any;
  is_admin_or_mod: boolean;
  verb: string;
  object: string;
  content: string;
  foreign_id: string;
  time: string;
  pinned: number;
  created_at: string;
  updated_at: string;
  imageUrls: any[];
  video_urls: any[];
  meta_style: MetaStyle;
  linkPreview: any;
  user: User;
  origin_group_id: string;
  origin_group: Group;
  origin_group_member: GroupMember;
  can_participate: boolean;
  is_frozen: boolean;
  own_like: boolean;
  reaction_counts: ReactionCounts;
  latest_reactions: LatestsReactions;
  pending: boolean;
  scrollIndex: number;
  validMentionTargets?: UserMention[];
  custom_reaction_counts: CustomReactionCount[];
  topics: TopicItem[];
  as_community?: boolean; // for post edition only
  title: string;
  media_type?: string;
  poll?: Poll;
}

export interface SettingsDetails extends Timestamps {
  id: string;
  key: string;
  label: string;
  value: string;
  value_label?: string;
  type: string;
  visibility: boolean;
  section: number;
  index: number;
  max_length: number;
  group_id: string;
  user_id: string;
}

export interface PaymentMethod {
  id: string;
  card: CardInfo;
  billing_details: {
    postal_code: string;
  };
}

export interface CardInfo {
  brand: CreditCardNames;
  exp_month: string;
  exp_year: string;
  country: string;
  last4: string;
}

export interface MenuOption {
  title: string;
  iconName: MeshIconsNames;
  testID?: string;
  titleStyle?: TextStyle;
  iconColor?: ColorValue;
  onPress?: () => void;
  predicate?: () => boolean;
}

export interface WebInviteLandingPageProps {
  url: string;
}
export interface MobileInviteLandingPageProps {
  invite_token: string;
  storeImage: any;
  storeUrl: string;
}

export interface ModIssue {
  id: string;
  status: string;
  action_taken?: string;
  explanation: string;
  type: string;
  activity: Partial<Activity>;
  original_action: string;
  actions?: string[];
  reasons: {
    id: string;
    title: string;
    body: string;
  };
  moderator?: Pick<User, `id` | `handle` | `name` | `avatar_url`>;
  reportee: Pick<User, `id` | `handle` | `name` | `avatar_url`>;
  reporter: Pick<User, `id` | `handle` | `name` | `avatar_url`>;
  created_at: string | Date;
  updated_at: string | Date;
  report_time: string;
  category: string;
}

export interface DirectModIssue {
  type: string;
  updated_at: Date;
  status: `UNDEF`;
  actions: null;
  reportee: Pick<User, `id`>;
  activity: null;
}

export interface SharedMedia {
  data: string;
  mimeType?: string;
}

//#endregion

// #region Mutations, Queries --------------------------------------------------- //
// Commmon Mutations & Queries types.
// ----------------------------------------------------------------------------- //

export interface MarkAsSeenData {
  markGroupAsSeen: GroupMember | null;
}
export interface MarkAsSeenVariables {
  group_id: string;
}

export interface ListedJoinedGroups extends WithTypeName {
  listJoinedGroups: JoinedGroup[];
}
export interface ListedPostableGroups extends WithTypeName {
  listJoinedGroups: {
    group: Group;
  }[];
}
export interface ListedGroups extends ListedJoinedGroups {
  listInvitedGroups: InvitedGroup[];
}
export interface PendingGroups extends WithTypeName {
  listPendingCommunities: GroupCoreFields[];
}

export interface GroupInvitation extends WithTypeName {
  created_at: string | null;
  id: string;
  invite_token: string;
  persona_id: string | null;
  group_id: string;
  role_id: string;
  role_name: string;
  inviter_id: string;
  inviter: User;
}
export interface Invitation {
  group: Group;
  group_member: GroupMember;
  group_invitation: GroupInvitation;
}
export interface GetInvitationData {
  getInvitation: Invitation;
}
export interface GetInvitationVars {
  invite_token: string;
}

export interface LeaveGroupData {
  leaveGroup: Group;
}
export type LeaveGroupVars = Pick<GroupMember, `group_id`>;

export interface UpdateRoleInput {
  persona_id?: string;
  group_id: string;
  role_id: string;
}
export interface ArchiveCommunityInput {
  group_id: string;
}
export interface ArchiveCommunityData {
  archiveCommunity2: {
    group: Group;
  };
}

export interface ArchivePersonaInput {
  persona_id: string;
}
export interface ArchivePersonaData {
  archivePersona: {
    persona_id: string;
    scheduled_at: string;
  };
}

export interface UpdateRoleData {
  updateGroupMemberRole2: {
    group: Group;
    group_member: GroupMember;
  };
}
export interface TargetBanUser {
  group_id: string;
  target_persona_id: string;
}

export type TargetLiftBan = Pick<TargetBanUser, `group_id`> & {
  persona_id: string;
};

export interface BanUserData {
  banUserFromGroupDirect2: {
    bannedUser: GroupMember;
    group: Group;
  };
}
export interface LiftBanData {
  liftUserBanForGroup: {
    lifted_ban_user: GroupMember;
    group: Group;
  };
}
export interface UpdateUserData {
  updatePersona: User;
}
export interface UpdateUserVars {
  input: {
    avatar_image_file: ReactNativeFileOptions;
  };
}
export interface ReactNativeFileOptions {
  uri: string;
  type?: string | undefined;
  name?: string | undefined;
}
export interface UpdateSettingInput {
  id?: string;
  group_id?: string;
  user_id?: string;
  key: string;
  value: string;
}

export interface HeroAssetData {
  id: string;
  group_id: string;
  url: string;
  media_type: string;
  end_date: string;
  enabled: boolean;
}

export interface UpdateSettingsVars {
  input: UpdateSettingInput;
}
export interface UpdateSettingsData {
  updateSettings: SettingsDetails;
}

export interface CommunityPostNotifySettings extends SettingsDetails {
  group: Group;
}
export interface UserNotifyPostData {
  getUserCommunityPostNotificationSettings: CommunityPostNotifySettings[];
}
export type UserNotifyPostVars = {
  group_id?: string;
};

export interface RemoveActivityData {
  removeActivity2: Activity;
}

export interface GetGroupData {
  getGroup: {
    group: Group;
    group_member: GroupMember;
  };
}

export type GetGroupByName = {
  getGroupsByName: JoinedGroup[];
};

export interface GroupAndGroupMember {
  id: String;
  group: Group;
  group_member: GroupMember | null;
}
export interface GroupQuery {
  group: Group;
  group_member?: GroupMember;
}
export interface GetGroupVars {
  group_id: string;
}

export interface ListGroupMembersData {
  listGroupMembers: GroupMember[];
}

export interface ListBannedMembersData {
  listBannedMembers: GroupMember[];
}
export interface ListGroupMembersVars {
  group_id: string;
  offset: number;
  limit: number;
}

export type RemoveActivityVars = Pick<Activity, `id`>;

export interface GetReactionData {
  getReactions: Reaction[];
}
export interface GetReactionVars {
  activity_id: string;
  reaction_id?: string;
  kind: string;
  limit?: number;
  with_activity_data?: boolean;
  id_gte?: string;
  id_gt?: string;
  id_lte?: string;
  id_lt?: string;
}

type CommunitySettingsTargetInput = {
  group_id: string;
};

type Options = { options: SettingOption[] };

export interface CommunitySettingsInput {
  input: { key: string } & CommunitySettingsTargetInput;
}
export interface CommunitySettingsData {
  getCommunitySetting: Setting;
}
export interface SettingOptionInput {
  settingQuery: {
    query: string;
  };
}
export interface SettingOptionData {
  getSettingOptions: Options[];
}

export interface ProfileFeedInput {
  input: {
    feed_slug: `user`;
    actor_id: string;
  };
  limit: number;
}
export interface ProfileFeedData {
  getProfileFeedV2: {
    user_is_blocked: boolean;
    activities: Activity[];
    lastActivity?: string;
  };
}

export interface GroupMemberCountItem {
  group_id: string;
  role_id: string;
  role_name: string;
  counts: number;
}
export interface MembersAndCountData {
  listGroupMembers: GroupMember[];
  getGroupMembersCount: {
    statusCode: number;
    message: string;
    data: GroupMemberCountItem[];
  };
}

export interface GetPersonaData {
  getPersona: User;
}

export interface GetPersonaVars {
  id: string;
}

export interface BlockRelation {
  owner_id: string;
  other_persona_id: string;
  permissions: string[];
  blocked: boolean;
  created_at: Date;
  updated_at: Date;
  blocked_user: User;
}
export interface GetBlockedUsers {
  getOwnBlockedUsersV2: BlockRelation[];
}

//#endregion

// #region Hooks --------------------------------------------------------------- //
// ----------------------------------------------------------------------------- //

export interface Mention {
  id: string;
  mention: string;
}

export interface UserMention {
  id: string;
  handle: string;
  name: string;
}

export interface UseMentionInput {
  value?: string;
  groupId: string;
  resolvedMentions: UserMention[];
  // eslint-disable-next-line no-unused-vars
  set: (mention: UserMention[]) => void;
  style?: TextStyle;
  backgroundColor?: string;
}

export interface BottomSheetOptions {
  title: string;
  iconName: MeshIconsNames;
  testID?: string;
  disabled?: boolean;
  iconColor?: string;
  titleStyle?: StyleProp<TextStyle>;
  visible?: boolean;
  roles?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  onPress?: () => void;
}
//#endregion

// #region Custom Components --------------------------------------------------- //
// ----------------------------------------------------------------------------- //
export interface ButtonProps {
  /**
   * Title of the button
   */
  title?: string;
  /**
   * Styles applied to the text of the button
   */
  textStyle?: StyleProp<TextStyle>;
  /**
   * Styles applied to the button container view
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Determine if the button will take the whole space available.
   */
  fullWidth?: boolean;
  /**
   * handle onPress event of the button
   */
  onPress: (event?: GestureResponderEvent) => void;
  /**
   * If children is passed, left icon will be displayed on the left side of the button.
   */
  leftIcon?: ReactNode;
  /**
   * * If children is passed, right icon will be displayed on the left side of the button.
   */
  rightIcon?: ReactNode;
  /**
   * If true, button will be render as disabled.
   */
  disabled?: boolean;
  /**
   * If true, the button will render as an IconButton (text will not be displayed). By default the component will try to render
   * the leftIcon or the rightIcon that you provide. Otherwise, it will render as a regular button.
   */
  onlyIconRender?: ReactNode;
}

export interface AppWizardState {
  currentStep: number;
}

export interface GuestState {
  joinMeshModalOpen: boolean;
  groupToJoin?: GroupCoreFields;
}

export interface ProfileState {
  currentStep: number;
  profileIdentity?: string;
  profileImage?: string;
  profileName: string;
  profileUsername: string;
  profileDOB?: number;
  profilePronouns: string[];
  profileInterests: string[];
  profileEmail?: string;
}

export interface FollowState {
  isCountRefresh: boolean;
  isFollowingNotiRefresh: boolean;
  isFollowInfoRefresh: boolean;
  isRefetchFollowRequest: boolean;
  isRefetchNotification: boolean;
  counts: {
    following: number;
    followers: number;
  };
}

export interface ExploreState {
  isSoundMuted: boolean;
  mutedByScroll: boolean;
}

export interface parseIntroDataType {
  video_url: string;
  thumbnail_url: string;
  approved_for_explore: boolean;
  pending: boolean;
  date_reviewed: string;
  group_id: string;
}
//#endregion

export interface GetReactionsInput {
  parent_id: string;
  parent_kind: string;
  limit?: number;
  offset?: number;
  id_gt?: string;
  id_lt?: string;
}

export type TopicListData = {
  getGroupTopicsList_V2: TopicItem[];
};

export type MyTopicListData = {
  getMyFavorTopicsList: TopicItem[];
};
