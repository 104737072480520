import React from 'react';
import { Text, View } from 'react-native';
import { Typography } from '../common-styles';
import { GroupMember } from '../common-types/types';
import { BottomSheetModal } from './BottomSheetModal';

const AgreeToCoCBottomSheet: React.FC<{
  visible: boolean;
  navigation: any;
  activeCommunity: any;
  groupMember: GroupMember | undefined;
  onPressCancel: () => void;
}> = ({ visible, navigation, activeCommunity, groupMember, onPressCancel }) => {
  return (
    <BottomSheetModal
      visible={visible}
      title="Updated Ground Rules"
      confirmTitle="Review the Ground Rules"
      showCancelBtn={false}
      onPressCancel={onPressCancel}
      onPressConfirm={() => {
        onPressCancel();
        navigation.navigate(`CommunityCodeOfConduct`, {
          group: activeCommunity || null,
          group_member: groupMember,
          toAgree: false,
        });
      }}
    >
      <View style={{ marginHorizontal: 16, marginVertical: 24 }}>
        <Text style={{ ...Typography.text() }}>
          {activeCommunity?.name} has updated their Ground Rules. You must review and accept the updated Ground Rules before you
          can interact in the community.
        </Text>
      </View>
    </BottomSheetModal>
  );
};

export default AgreeToCoCBottomSheet;
