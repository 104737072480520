import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { View, Text, ScrollView, TextStyle } from 'react-native';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import 'react-native-get-random-values';
import { Channel as ChatChannel } from 'stream-chat';
import { Channel, Chat, CardProps } from 'stream-chat-react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  SafeAreaView,
  BottomSheetModal,
  LoadingIndicator,
  ThreeDotsButton,
  WarningBox,
  ThemedButton,
  MeshIcon,
} from '../common-ui';
import { MessageList } from './custom-chat-components/MessageList';
import { ChatHeader } from './custom-chat-components/ChatHeader';
import { ChatInput } from './ChatInput';
import { chatClient, MeshChatGenerics } from './chatClient';
import { updatePersonaRelationship, updateChannelMuteStatus } from './chatServices';
import { client, GET_COMMON_GROUP, GET_OWN_BLOCKED_USERS_V2 } from '../graphql';
import { Colors, MeshIconsNames, Typography } from '../common-styles';
import { MESH_MODERATION_GROUP, MODAL_SWITCH_TIME } from '../../constants';
import { MODERATION_TYPE } from '../moderation/moderationEnums';
import { AppContext } from '../../AppContext';
import { CustomMessage } from './custom-chat-components/CustomMessage';
import { isAMeshLink } from '../post/helpers';
import { UrlPreview } from './custom-chat-components/UrlPreview';
import { NormalUrlPreview } from './custom-chat-components/NormalUrlPreview';
import { ProfilePreview } from './custom-chat-components/ProfilePreview';
import MessageRequestBottomSheet from './message-request-components/MessageRequestBottomSheet';
import { ChatNavigator, ChatNavigatorScreens, LeftDrawerNavigator } from '../common-types/navigation-types';
import { BlockRelation, GetBlockedUsers, User } from '../common-types/types';
import { CustomMessageText } from './custom-chat-components/CustomMessageText';
import { Events, PendoTrackEvent } from '../pendo/events';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const chatStatusValues = {
  IDLE: `IDLE`,
  LOADING: `LOADING`,
  NORMAL: `NORMAL`,
  BLOCKED: `BLOCKED`,
};

type ChatThreadRoute = RouteProp<ChatNavigatorScreens, `ChatThread`>;

type MessageOptions = {
  title: string;
  iconName: MeshIconsNames;
  onPress: () => void;
  predicate?: () => boolean;
  titleStyle?: TextStyle;
  iconColor?: string;
  testID?: string;
};

//type ModalOptions = `report` | `options` | `messageOptions` | `blockUser`;

export const ChatThread: React.FC = () => {
  const navigation = useNavigation<ChatNavigator>();
  const { nodeMode } = useSelector((state: RootState) => state.feed);
  const route = useRoute<ChatThreadRoute>();
  const { user, channel: context_channel, thread } = React.useContext(AppContext);
  const { channel_id: channelId, channel_type: channelType, isRequested } = route?.params;
  const [loadingChannel, setLoadingChannel] = useState(true);
  const [options, setOptions] = useState<MessageOptions[]>([]);
  //const [messageOptions, setMessageOptions] = useState<MessageOptions[]>([]);
  const [memberToBlock, setMemberToBlock] = useState<User>();
  //const [selectedMessage, setSelectedMessage] = useState<ModalOptions>(null);
  const [channelName, _setChannelName] = useState<string>();
  const [modal, setModal] = useState<string>(); //
  const [channelRef, setChannelRef] = useState<ChatChannel<MeshChatGenerics>>();

  const {
    data,
    loading,
    refetch: refetchBlockedUser,
  } = useQuery<GetBlockedUsers>(GET_OWN_BLOCKED_USERS_V2, { fetchPolicy: `cache-and-network` });
  const blockedUsers = useMemo(() => data?.getOwnBlockedUsersV2 || ([] as BlockRelation[]), [data?.getOwnBlockedUsersV2]);
  const otherMembers: User[] = useMemo(() => {
    return Object.values(channelRef?.state?.members || {})
      .map<User | undefined>((member) => member.user as User | undefined)
      .filter((member) => !member || member?.id !== user?.id) as User[];
  }, [channelRef?.state?.members, user?.id]);
  const [chatStatus, setChatStatus] = useState(chatStatusValues.IDLE);
  //let chatStatus = chatStatusValues.IDLE;
  const isBlocked = useCallback(
    (id: string) => {
      if (!id) return false;
      return blockedUsers.findIndex((blockedUser) => blockedUser?.blocked_user?.id === id) >= 0;
    },
    [blockedUsers],
  );
  const [fetchCommonGroups, { data: commonGroupCount }] = useLazyQuery(GET_COMMON_GROUP);

  useEffect(() => {
    const initialize = async () => {
      try {
        const channel_instance = chatClient.channel(channelType, channelId);
        await channel_instance.watch();
        console.log(`🚀 ~ initialize ~ channel_instance`, channel_instance);
        setChannelRef(channel_instance);
        setLoadingChannel(false);
      } catch (error: any) {
        console.error(error.message);
      }
    };
    const loadDelay = setTimeout(() => {
      if (channelRef?.id !== channelId) initialize();
    }, 500);
    return () => clearTimeout(loadDelay);
  }, [user?.id, channelId, channelType, channelRef]);

  const showOptionsModal = useCallback(async () => {
    const options = await buildOptionsMenu();
    setOptions(options);
    setModal(`options`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherMembers, memberToBlock, blockedUsers, loadingChannel]);

  const hideModal = useCallback(() => setModal(undefined), []);

  const handleMuteChannel = (channel: ChatChannel<MeshChatGenerics>, isMuted: boolean) => {
    if (!channel?.id) return;
    updateChannelMuteStatus(channel.type, channel.id, !isMuted);
  };

  const blockUser = useCallback(
    (memberToBlock, block = true) => {
      if (!memberToBlock) return null;
      return setTimeout(async () => {
        try {
          console.log(`Block/Unblock user from chat action triggered`);
          const ownId = user?.id;
          if (!ownId) return;
          const response = await updatePersonaRelationship(ownId, memberToBlock.user.id, [], block);
          console.log(`🚀 🚀  ->  ~ updatePersonaRelationship ~ response`, response);
          setChatStatus(block ? chatStatusValues.BLOCKED : chatStatusValues.NORMAL);
          refetchBlockedUser();
          client.reFetchObservableQueries(); // temp hack to refetch groups that both users may share with each other to hide blocked user's posts
        } catch (error: any) {
          console.error(`Error thrown in blockUser() in ChatThread.jsx`, error.message);
        } finally {
          hideModal();
        }
      }, MODAL_SWITCH_TIME);
    },
    [hideModal, refetchBlockedUser, user?.id],
  );

  const onPressBlockUser = useCallback(() => blockUser(memberToBlock), [blockUser, memberToBlock]);
  /**
   * return options to display for OptionsModal
   * @returns {object[]} array of objects containing what options to show for the OptionsModal
   */
  const buildOptionsMenu = () => {
    try {
      const [member, ...rest] = otherMembers;
      const hasOnlyOneMember = !rest.length;
      const isMuted = !!channelRef?.muteStatus()?.muted;
      let isUserBlocked = false;
      let localMemberToBlock: typeof member;
      if (hasOnlyOneMember && member?.id) {
        isUserBlocked = isBlocked(member.id);
        setMemberToBlock(member);
        localMemberToBlock = member;
      }

      const options: MessageOptions[] = [
        {
          predicate: () => !!hasOnlyOneMember,
          title: `View ${member?.handle || member?.name} profile`,
          iconName: `profile`,
          onPress: () => {
            hideModal();
            setTimeout(() => {
              const LeftDrawer = navigation.getParent()?.getParent()?.getParent<LeftDrawerNavigator>();
              if (member?.id) {
                PendoTrackEvent(Events.PROFILE, {
                  username: member?.handle,
                  source: `Search user list`,
                  element: `Search list`,
                });
                LeftDrawer?.jumpTo(`ProfileStack`, {
                  screen: `Profile`,
                  params: { user_id: member.id, user: member },
                });
              }
            }, MODAL_SWITCH_TIME);
          },
        },
        {
          title: isMuted ? `Unmute conversation` : `Mute conversation`,
          iconName: isMuted ? `volume-2` : `volume-x`,
          onPress: () => {
            hideModal();
            setTimeout(() => (channelRef ? handleMuteChannel(channelRef, isMuted) : null), MODAL_SWITCH_TIME);
          },
        },
        {
          predicate: () => !!hasOnlyOneMember,
          title: isUserBlocked ? `Unblock user` : `Block user`,
          titleStyle: { color: Colors.alertColor },
          iconName: `alert-circle`,
          iconColor: Colors.alertColor,
          onPress: () => {
            hideModal();
            setTimeout(() => {
              if (isUserBlocked) {
                blockUser(localMemberToBlock, false);
                return;
              }
              setModal(`blockUser`);
            }, MODAL_SWITCH_TIME);
          },
        },
        {
          predicate: () => !!hasOnlyOneMember,
          title: `Report ${member?.handle || member?.name}`,
          iconName: `flag`,
          titleStyle: { color: Colors.alertColor },
          iconColor: Colors.alertColor,
          testID: `USER_REPORT`,
          onPress: () => {
            //hideOptionsModal();
            hideModal();
            setTimeout(() => {
              navigation.navigate(`ReportScreen`, {
                reportType: MODERATION_TYPE.user,
                reportee: first?.id,
                origin_group_id: MESH_MODERATION_GROUP,
              });
            }, 100);
          },
        },
      ];

      return options.filter((option) => option.predicate === undefined || option.predicate());
    } catch (error: any) {
      console.error(`Error thrown in buildOptionsMenu() in ChatThread.jsx`, error.message);
      return [];
    }
  };
  const renderHeaderLeft = useCallback(
    (setLoadingChannel) => {
      return (
        <View style={{ display: `flex`, alignItems: `center`, flexDirection: `row` }}>
          <ThemedButton
            clear
            containerStyle={{ alignItems: `center` }}
            onPress={() => {
              if (navigation.canGoBack()) {
                if (nodeMode === `preview`) {
                  //@ts-ignore
                  navigation.navigate(`ExploreNavigator`);
                } else {
                  navigation.goBack();
                }
              } else {
                navigation.navigate(`chat`);
              }

              setLoadingChannel(true);
            }}
            leftIcon={
              <View style={{ bottom: 1 }}>
                <MeshIcon name="chevron-left" color={Colors.iconColor} />
              </View>
            }
          />

          <ChatHeader channel={context_channel} channelName={channelName} members={otherMembers} />
        </View>
      );
    },
    [channelName, context_channel, navigation, otherMembers, nodeMode],
  );

  const renderHeaderRight = useCallback(() => {
    if (!channelRef) return null;
    return <ThreeDotsButton onPress={showOptionsModal} size={24} style={{ marginRight: 6 }} testID="CHAT_THREAD_3DOTS" />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptionsModal, otherMembers, memberToBlock, blockedUsers, loadingChannel]);

  useEffect(() => {
    navigation.setOptions({
      // headerTitle: () => renderHeaderTitle(),
      // headerTitleAlign: `center`,
      headerRight: () => renderHeaderRight(),
      headerLeft: () => renderHeaderLeft(setLoadingChannel),
    });
  }, [navigation, renderHeaderLeft, setLoadingChannel, renderHeaderRight, otherMembers, blockedUsers, loadingChannel]);

  useEffect(() => {
    const [first] = otherMembers;
    if (loading) setChatStatus(chatStatusValues.LOADING);
    else if (otherMembers.length !== 1) setChatStatus(chatStatusValues.NORMAL);
    else if (first?.id) {
      fetchCommonGroups({
        variables: {
          persona_ids: new Array(user?.id, first?.id),
        },
      });
      const blocked = isBlocked(first.id);
      setChatStatus(blocked ? chatStatusValues.BLOCKED : chatStatusValues.NORMAL);
    }
  }, [isBlocked, loading, otherMembers, user]);

  if (!channelRef || loadingChannel) {
    return (
      <View style={{ flex: 1, justifyContent: `center` }}>
        <LoadingIndicator />
        <Text
          style={{
            ...Typography.text(`gray`, `center`),
            marginTop: 10,
          }}
        >
          Loading channel messages...
        </Text>
      </View>
    );
  }

  const [first] = otherMembers;

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <View testID="CHAT_SCREEN">
        <Chat client={chatClient}>
          <Channel
            channel={channelRef}
            thread={thread}
            MessageSimple={CustomMessage}
            UrlPreview={CustomUrlPreview}
            DateHeader={() => null}
            MessageText={CustomMessageText}
          >
            <View style={{ flex: 1 }}>
              {channelRef && <MessageList messages={channelRef} />}

              {isRequested && (
                <View style={{ paddingLeft: 10, paddingRight: 15 }}>
                  <MessageRequestBottomSheet
                    user={first?.handle}
                    common={commonGroupCount?.getCommonGroups}
                    channelId={channelId}
                    navigation={navigation}
                  />
                </View>
              )}

              {chatStatus !== chatStatusValues.BLOCKED && !isRequested && (
                <ChatInput channel={channelRef} isAbleToWrite={chatStatus === chatStatusValues.NORMAL} />
              )}
              {chatStatus === chatStatusValues.BLOCKED && !isRequested && (
                <View style={{ paddingLeft: 10, paddingRight: 15 }} testID="BLUEBOX_ALERT">
                  <WarningBox text={`You blocked ${first?.handle}`} />
                </View>
              )}
            </View>
          </Channel>
        </Chat>

        {modal === `options` && (
          <BottomSheetModal
            title="Manage"
            visible={true}
            options={options}
            showCancelBtn={false}
            confirmTitle="Close"
            onPressConfirm={hideModal}
            onPressCancel={hideModal}
          />
        )}

        {modal === `blockUser` && (
          <BottomSheetModal
            title="Are you sure?"
            visible={true}
            confirmTitle="Block user"
            onPressCancel={hideModal}
            onPressConfirm={() => onPressBlockUser()}
            showCancelBtn={false}
          >
            <ScrollView alwaysBounceVertical={false} style={{ paddingTop: 12, paddingBottom: 50, paddingHorizontal: 16 }}>
              <Text style={{ ...Typography.text(`center`), lineHeight: 22, marginBottom: 57 }}>
                Are you sure you want to block {memberToBlock && (memberToBlock?.handle || memberToBlock?.name)}? You will no
                longer see each other’s posts or be able to chat. Manage your blocked list in your Settings.
              </Text>
            </ScrollView>
          </BottomSheetModal>
        )}

        {/*modal === `messageOptions` && (
          <BottomSheetModal
            title="Manage"
            visible={true}
            showCancelBtn={false}
            onPressConfirm={hideModal}
            onPressCancel={hideModal}
            options={messageOptions}
          />
        )*/}
      </View>
    </SafeAreaView>
  );
};

//React.ComponentType<CardProps<StreamChatGenerics>>;
type CustomUrlPreviewProps = CardProps<MeshChatGenerics>; /*& {
  text: string;
  thumb_url: string;
  og_scrape_url: string;
  image_url: string;
  title_link?: string;
};*/

const CustomUrlPreview: React.FC<CustomUrlPreviewProps> = ({ text, thumb_url, og_scrape_url, image_url, title_link = `` }) => {
  if (og_scrape_url && isAMeshLink(og_scrape_url)) {
    if (og_scrape_url.includes(`profile/invite`)) {
      return <ProfilePreview inviteUrl={og_scrape_url} />;
    }
    return <UrlPreview inviteUrl={og_scrape_url} />;
  }
  return <NormalUrlPreview link={title_link} image={thumb_url || image_url} title={text} />;
};
