import React, { useEffect, useState, useRef } from 'react';
import { Linking, Animated, TouchableWithoutFeedback, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import ParsedText, { ParseShape } from 'react-native-parsed-text';
import { MeshIcon } from '../common-ui';
import { mentionPattern, urlRegex } from './helpers';
import { Colors, Typography } from '../common-styles';

type MoreTextProps = {
  text: string;
  startingHeight: number;
  startExpanded: boolean;
  onPress: () => void;
  selectable: boolean;
  gradientHeight?: number;
  margin?: number;
  isMediaActivity?: boolean;
  actor?: string;
};

export const MoreText: React.FC<MoreTextProps> = React.memo(
  ({
    text,
    startingHeight,
    startExpanded = false,
    onPress,
    selectable = true,
    gradientHeight = 40,
    margin = 12,
    isMediaActivity = false,
    actor,
  }) => {
    const [expander, setExpander] = useState(false);
    const [expanded, setExpanded] = useState(startExpanded);
    const [fullHeight, setFullHeight] = useState(startingHeight);
    const [opacity, setOpacity] = useState(0.0);
    const animatedHeight = useRef(new Animated.Value(startingHeight)).current;
    const expanderSize = gradientHeight / 2;
    const expanderInset = gradientHeight / 4;

    useEffect(() => {
      // expanded?setText(props.text): setText(props.text.substring(0, 40));
      Animated.spring(animatedHeight, {
        friction: 100,
        toValue: expanded ? fullHeight : startingHeight,
        useNativeDriver: false,
      }).start();
    }, [animatedHeight, expanded, fullHeight, startingHeight]);

    const onViewLayout = (e: any) => {
      let { height } = e.nativeEvent.layout;
      height = Math.floor(height) + gradientHeight;
      if (height > startingHeight) {
        setFullHeight(height);
        setExpander(true);
      }
      setOpacity(1.0);
    };

    const textPatterns: ParseShape[] = [
      {
        //We need this prop because it's a must have for the ParseText lib
        //but the real job is done in renderText
        pattern: mentionPattern,
        style: { ...Typography.text(`bold`), color: Colors.deepPurple },
      },
      {
        pattern: urlRegex,
        style: { ...Typography.text(`bold`), color: Colors.deepPurple },
        onPress: (url: string) => {
          if (!url.includes(`https`)) {
            Linking.openURL(`https://${url}`);
          } else {
            Linking.openURL(url);
          }
        },
      },
      {
        type: `url`,
        style: { ...Typography.text(`bold`), color: Colors.deepPurple },
        onPress: (url: string) => Linking.openURL(url),
      },
    ];

    if (isMediaActivity && actor)
      textPatterns.push({
        pattern: new RegExp(`^${actor}`),
        style: Typography.text(`bold`),
      });

    return (
      <View style={{ flex: 1 }}>
        {/* text is in an animated view that can change height */}
        <Animated.View style={[{ flex: 1, overflow: `hidden`, opacity }, { height: animatedHeight }]}>
          <View style={{ flex: 1, position: `absolute`, top: 0 }} onLayout={onViewLayout}>
            <ParsedText
              selectable={selectable}
              onPress={onPress}
              style={[Typography.text(), { paddingHorizontal: margin }]}
              parse={textPatterns}
            >
              {text}
            </ParsedText>
          </View>
        </Animated.View>

        {/* expander has two parts: a gradient fading out the text, and a button */}
        {expander && (
          <>
            {!expanded && (
              <LinearGradient
                colors={[`rgba(255,255,255,0.0)`, `rgba(255,255,255,0.5)`, `rgba(255,255,255,0.8)`, `rgba(255,255,255,1.0)`]}
                style={{
                  width: `100%`,
                  backgroundColor: `transparent`,
                  position: `absolute`,
                  bottom: 0,
                  height: gradientHeight,
                }}
              />
            )}
            <TouchableWithoutFeedback
              hitSlop={{ top: expanderInset * 3, left: expanderInset * 3, right: expanderInset, bottom: expanderInset }}
              onPress={() => setExpanded((prev) => !prev)}
            >
              <View
                style={{
                  position: `absolute`,
                  right: expanderInset,
                  bottom: expanderInset,
                  padding: 4,
                  backgroundColor: Colors.moreButton,
                  borderRadius: 999,
                }}
              >
                <MeshIcon name={expanded ? `chevron-up-3` : `chevron-down-3`} color={Colors.white} size={expanderSize} />
              </View>
            </TouchableWithoutFeedback>
          </>
        )}
      </View>
    );
  },
);
