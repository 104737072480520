/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import ParsedText from 'react-native-parsed-text';
import { useNavigation } from '@react-navigation/native';
import { ProfileNavigator } from '../common-types/navigation-types';
// @ts-ignore reason: svg import type definition does not exist
import MeshHorizontalSVG from '../../assets/images/mesh-horizontal.svg';
import { Colors, Typography } from '../common-styles';
import { BlobBlue, BottomSheetModal, MeshIcon, PasswordInput, ThemedButton } from '../common-ui';
import { ARCHIVE_PERSONA, CHECK_MATCHING_PASSWORD, client, refetchQueriesFor } from '../graphql';
import { AppContext } from '../../AppContext';
import { formatDate } from '../common-util';
import { ArchivePersonaData, ArchivePersonaInput } from '../common-types/types';
import { useLogoutAll } from '../drawer/hooks/useLogoutAll';

// type EnterPasswordRoute = RouteProp<ProfileScreensParamList, `EnterYourPasswordScreen`>;

export const EnterYourPasswordScreen = () => {
  // const route = useRoute<EnterPasswordRoute>();
  const navigation = useNavigation<ProfileNavigator>();
  const { identity, user } = useContext(AppContext);
  const { logoutAll } = useLogoutAll();

  const [password, setPassword] = useState<string>(``);
  const [errorMessage, setErrorMessage] = useState<string>(``);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deletionDate, setDeletionDate] = useState<string>(``);

  const [archivePersona] = useMutation<ArchivePersonaData, ArchivePersonaInput>(ARCHIVE_PERSONA);

  useEffect(() => {
    navigation.setOptions({
      //@ts-ignore
      headerTitle: () => <MeshHorizontalSVG width={64} height={27} />,
    });
  }, [navigation]);

  useEffect(() => {
    const deletionDate = new Date();
    deletionDate.setDate(deletionDate.getDate() + 30);
    setDeletionDate(formatDate(deletionDate, `MMMM dd, yyyy`));
  }, []);

  const handlePasswordTextChange = (text: string) => {
    setErrorMessage(``);
    setPassword(text);
  };

  const checkPassword = async () => {
    try {
      setLoading(true);
      const has8Chars = password.length >= 8;
      const hasSpecialSymbols = /[^A-Za-z0-9]/.test(password);

      // The error message is the same but with this conditional we don't have to call the server for a password that doesn't even fullfill the minimum requirements
      if (!has8Chars || !hasSpecialSymbols) {
        setErrorMessage(`Incorrect password.`);
        return;
      }

      const { data } = await client.query({
        query: CHECK_MATCHING_PASSWORD,
        variables: { identity_id: identity?.id, current_password: password },
        fetchPolicy: `network-only`,
      });
      console.log(`🚀 🚀  ->  ~ checkPassword ~ data`, data);

      if (data?.checkMatchingPassword?.passwordMatch) setShowModal(true);
      else setErrorMessage(`Incorrect password.`);
    } catch (error) {
      console.error(`An error ocurred trying to fetch the CHECK_MATCHING_PASSWORD query `, error);
    } finally {
      setLoading(false);
    }
  };

  const scheduleAccountForDeletion = useCallback(async () => {
    try {
      await archivePersona({
        variables: { persona_id: user?.id! },
        refetchQueries: refetchQueriesFor(`Persona`),
      });

      await logoutAll();
    } catch (error) {
      console.error(`An error ocurred in scheduleAccountForDeletion ${error}`);
    }
  }, [archivePersona, user?.id, logoutAll]);

  return (
    <View style={styles.container}>
      <BlobBlue width={32} height={35} style={{ position: `absolute`, left: -10, top: -3, zIndex: -1 }} />
      <Text allowFontScaling={false} style={styles.headerText}>
        Enter your password
      </Text>

      <Text style={styles.explanatoryText}>
        Please enter your password to confirm that you want to deactivate your account. Your display name, @{user?.name}, and
        profile will no longer be visible on Mesh.
      </Text>

      <Text style={styles.subtitleText}>What else you should know</Text>

      <Text style={styles.explanatoryText}>
        You can restore you Mesh account if it was accidentally or wrongfully deactivated for up to 30 days after deactivation.
      </Text>

      <Text style={styles.labelText}>Password</Text>

      {errorMessage?.length ? (
        <View
          style={{
            flexDirection: `row`,
            alignItems: `center`,
            borderColor: Colors.textRed,
            borderRadius: 4,
            borderWidth: 1,
            height: 40,
            paddingLeft: 16,
          }}
        >
          <TextInput value={password} secureTextEntry={true} onChangeText={handlePasswordTextChange} style={{ flex: 1 }} />
          <MeshIcon name="info-circle" size={22} color={Colors.textRed} style={{ marginRight: 12 }} />
        </View>
      ) : (
        <PasswordInput
          autoFocus
          textContentType="password"
          onChangeText={handlePasswordTextChange}
          onSubmitEditing={checkPassword}
          placeholder=""
        />
      )}

      {errorMessage?.length ? (
        <View style={styles.errorBox}>
          <Text style={{ ...Typography.text(`red`, `small`) }}>{errorMessage}</Text>
        </View>
      ) : undefined}

      <ThemedButton
        rounded
        title="Confirm"
        onPress={checkPassword}
        buttonStyle={{ padding: 15 }}
        containerStyle={{ marginTop: 12, marginHorizontal: 0 }}
        titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
        disabled={loading || !password.trim().length}
      />

      <BottomSheetModal
        title="Preparing your account for deletion"
        visible={showModal}
        showCancelBtn={false}
        showConfirmBtn={false}
        onPressCancel={() => setShowModal(false)}
        onPressConfirm={() => {}}
      >
        <View style={{ flexDirection: `column` }}>
          <ParsedText
            style={styles.modalText}
            parse={[
              {
                pattern: new RegExp(deletionDate),
                style: Typography.text(`bold`),
              },
            ]}
          >
            To recover your account before it is permanently deleted, log back into mesh before {deletionDate}.
          </ParsedText>
          <ThemedButton
            rounded
            title="Delete my account"
            onPress={() => scheduleAccountForDeletion()}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginTop: 12, marginHorizontal: 16 }}
            titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
          />

          <ThemedButton
            rounded
            title="Cancel"
            onPress={() => setShowModal(false)}
            buttonStyle={{ padding: 15, backgroundColor: Colors.white, borderWidth: 1, borderColor: Colors.brandPurple }}
            containerStyle={{ marginTop: 12, marginBottom: 20, marginHorizontal: 16 }}
            titleStyle={{ ...Typography.text(`plustwo`, `theme`, `bold`), marginLeft: 4 }}
          />
        </View>
      </BottomSheetModal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 19,
    marginTop: 16,
  },
  headerText: {
    ...Typography.text(`large`, `black`, `bold`),
    marginBottom: 8,
  },
  subtitleText: {
    ...Typography.text(`base`, `black`, `bold`),
    marginTop: 12,
    marginHorizontal: 7,
  },
  explanatoryText: {
    marginHorizontal: 7,
    ...Typography.text(`small`, `black`),
  },
  labelText: {
    ...Typography.text(`gray`, `small`),
    marginTop: 50,
    marginBottom: 10,
  },
  modalText: {
    ...Typography.text(`darkGray`),
    marginTop: 10,
    marginBottom: 16,
    marginHorizontal: 16,
  },
  errorBox: {
    borderRadius: 4,
    backgroundColor: Colors.blockUserBackgroundColor,
    padding: 13,
    marginTop: 8,
  },
});
