import { BASE_PAYMENT_API_URL } from '../../constants';

/**
 * Helper to use the fetch API with the payment server
 * @param {String} path Payment server route to make the API Call
 * @param {String} method Only work with post request for now
 * @param {String} idempotencyKey Pass  to make ONLY POST request idempotent.
 * @param {Object} body Body of the request. Send the object not a string
 */

export const APIHelper = async (path = ``, method = `POST`, idempotencyKey = ``, body = {}) => {
  try {
    const response = await fetch(`${BASE_PAYMENT_API_URL}${path}`, {
      method,
      headers: {
        'Content-Type': `application/json`,
        'Idempotency-Key': idempotencyKey,
      },
      body: JSON.stringify(body),
    });
    const data = response.json();
    return data;
  } catch (error) {
    return error as Error;
  }
};

export const calculateFee = (amount: number) => {
  /*
    2.9% plus 30 cents per transaction to accept card payments online
    Ie: 10 dolars are 1000 cents. So the fee will be (1000 * 0.029) + 30 = 59 cents.
   */
  const fee = Math.round(amount * 0.029) + 30;
  const feeInDollars = fee / 100;
  return feeInDollars;
};
