import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, SafeAreaView, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import LottieView from 'lottie-react-native';
import Modal from 'react-native-modal';
import { client, GET_GROUP, JOIN_GROUP } from '../graphql';
import { AuthMainText, BackButton, EmptyList, HeaderSubmitButton, NoThemeButton, OverlayModal } from '../common-ui';
import { Colors } from '../common-styles';
import { AppContext } from '../../AppContext';
import { getEnvVariables } from '../../environment';
import { GetGroupData, Group } from '../common-types/types';
import { AboutFragment } from '../community/AboutScreen';
import { validGuidelines } from '../community/hooks/useJoinCommunity';
import { PersonaNavigationScreens } from '../common-types/navigation-types';
import { useRefreshCommunities } from '../common-util/hooks/useRefreshCommunities';
import { setActiveCommunity } from '../redux/feedSlice';
import { GroupCardRounded } from '../common-ui/GroupCardRounded';
import { Events, PendoTrackEvent } from '../pendo/events';

const INITIAL_COMMUNITIES = {
  development: [
    // DEV
    // `38c940de-8231-4f4b-9e30-147cfcff4b6d`,
    `0eafa898-c935-46ff-a649-e8baca9deaaa`, // E2E tests
    `88ec901d-fac4-4bbf-a861-a97d594df4a9`, // Astrophotography
    `016da035-3608-44eb-9b3a-9d3fa4545cf9`, // A very long long community name
  ],
  production: [
    // PROD
    `9567ac76-bb4d-4586-b66c-2eaecb5e6531`, // Photography Collective
    `942cd5c2-9b14-47b1-83ca-d5391798cb95`, // Art Collective
    `877855d2-a23f-417a-82bf-ef4b3ceedee1`, // House Plant Hobbyist
    `76799b98-66a6-472d-a799-ba516cb836f9`, // Animals of Mesh
    `3f1c7406-bb9b-42df-a95d-b926c59728e9`, // Archeology Enthusiast
    `01f557f3-24b3-435c-bc5f-1ab8ac8fdc15`, // Interior Design
    `f3440e0b-9bf8-46a8-9744-941ec1a4d2ac`, // Collective Gallery
    `7aef8e1f-f12a-48ff-b3f2-b63ed8f452d4`, // Space
    `68edf3ef-647a-4d30-88b7-ef6e8f4f5319`, // Castles around the World
  ],
  local: [] as string[],
};
INITIAL_COMMUNITIES.local = INITIAL_COMMUNITIES.development;

type JoinNavigationScreens = StackNavigationProp<PersonaNavigationScreens, `JoinCommunity`>;

export const JoinCommunity = () => {
  const { envName } = getEnvVariables();
  const dispatch = useDispatch();
  const navigation = useNavigation<JoinNavigationScreens>();
  const { user, setUser } = React.useContext(AppContext);

  const [community, setCommunity] = useState<Group>();
  const [modalOpen, setModalOpen] = useState(false);
  const [communities, setCommunities] = useState<Group[]>([]);
  const [loading, setLoading] = useState(true);
  const [joining, setJoining] = useState(false);
  const { fetchJoinedCommunities } = useRefreshCommunities();

  const attemptJoinInitialCommunity = useCallback(
    async (group: Group) => {
      setModalOpen(false);
      const { id, application, guidelines } = group;
      if (application && application.questions.length !== 0) {
        console.log(`The group has an application, guidelines managed be it `);
        navigation.navigate(`CommunityApplication`, { group });
        setModalOpen(false);
        return;
      }
      if (!application && validGuidelines(guidelines)) {
        console.log(`The group has no application, but it has custom guidelines - showing guidelines`);
        navigation.navigate(`CommunityCodeOfConduct`, { group });
        setModalOpen(false);
        return;
      }
      if (user?.id) {
        setJoining(true);
        const { data } = await client.mutate({
          mutation: JOIN_GROUP,
          variables: { group_id: id },
        });
        const { application_pending, join_request_pending, group: joinedGroup } = data?.joinGroup || {};
        if (!application_pending && !join_request_pending && joinedGroup) {
          PendoTrackEvent(Events.JOIN_COMMUNITY, {
            community_name: joinedGroup.name,
            source: `create account flow`,
            application_required: false,
            admin_approval_required: false,
          });
          dispatch(setActiveCommunity({ user_id: user.id, group: joinedGroup }));
        }
        setJoining(false);
        if (user?.pending_completition) {
          user.pending_completition = false;
          setUser(user);
        }
      }
    },
    // reason: effect should not trigger on every user obj change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, navigation, user?.id],
  );

  const onInfoPress = (c: Group) => {
    setCommunity(c);
    setModalOpen(true);
  };

  const renderItem = useCallback(
    ({ item }) => <GroupCardRounded group={item} handleCardPress={onInfoPress} handleArrowPress={attemptJoinInitialCommunity} />,
    [attemptJoinInitialCommunity],
  );

  useEffect(() => {
    const fetch = async () => {
      const communities: string[] = INITIAL_COMMUNITIES[envName];
      try {
        setLoading(true);
        const availableCommunities = await Promise.all(
          communities.map((group_id) =>
            client.query<GetGroupData>({
              query: GET_GROUP,
              variables: { group_id },
              fetchPolicy: `network-only`,
            }),
          ),
        );
        const _communities = availableCommunities
          .map(({ data, errors }) => {
            if (errors) return undefined;
            return data.getGroup.group;
          })
          .filter((c) => !!c)
          // @ts-ignore
          .sort((a, b) => -a?.latest_post.localeCompare(b?.latest_post));

        // filter should have removed all undefined yet explicit cast was necessary
        setCommunities(_communities as Group[]);
      } catch (e) {
        console.error(e);
        setCommunities([]);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [envName, setUser]);

  useEffect(() => {
    const onSkip = async () => {
      try {
        if (!user?.id) return;
        setJoining(true);
        await fetchJoinedCommunities();
        if (user?.pending_completition) {
          user.pending_completition = false;
          setUser(user);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setJoining(false);
      }
    };
    navigation.setOptions({
      headerLeft: () => <BackButton />,
      headerRight: () => <HeaderSubmitButton disabled={joining} title="Skip for now" onPress={onSkip} />,
    });
  }, [dispatch, navigation, joining, fetchJoinedCommunities, setUser, user]);
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <View style={{ margin: 16 }}>
          <View style={styles.blob} />
          <AuthMainText text="Join a node" />
        </View>
        <FlatList
          data={communities}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          ListFooterComponent={<FooterComponent isEmpty={!communities?.length} loading={loading} />}
        />
        <OverlayModal isVisible={!!community && modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <View style={styles.modalClose}>
            <NoThemeButton
              clear
              title="Close"
              titleStyle={{ fontFamily: `inter-semibold`, color: Colors.deepPurple }}
              buttonStyle={{ alignItems: `center` }}
              onPress={() => setModalOpen(false)}
            />
          </View>
          {community ? (
            <AboutFragment group_fragment={community} setShowAboutModal={setModalOpen} feedContainerStyle={{ flex: 1 }} />
          ) : null}
        </OverlayModal>
        <Modal
          onBackdropPress={() => null}
          onBackButtonPress={() => null}
          style={{ justifyContent: `center` }}
          isVisible={joining}
        >
          <View style={{ justifyContent: `center`, alignItems: `center` }}>
            <LottieView
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
              source={require(`../images/animated/animatedLoading.json`)}
              autoPlay
              loop
            />
          </View>
        </Modal>
      </View>
    </SafeAreaView>
  );
};

type FooterComponentProps = {
  loading: boolean;
  isEmpty: boolean;
};

const FooterComponent: React.FC<FooterComponentProps> = ({ loading, isEmpty }) => {
  if (!isEmpty) return null;
  return <EmptyList isLoading={loading} message="No communities available" />;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  modalClose: { flexDirection: `row`, justifyContent: `flex-end` },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: Colors.translucentBrightRed,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
});
