import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { ScrollView, Text, TextInput, View } from 'react-native';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { KeyboardPaddingView, MeshIcon, ThemedButton, ListItem } from '../common-ui';
import { refetchQueriesFor, UPDATE_SETTING_MUTATION } from '../graphql';
import { commonPropTypes } from '../common-util';

const SafeParseArray = (jsonText) => {
  if (!jsonText) return [];
  try {
    return JSON.parse(jsonText);
  } catch (error) {
    console.warn(`JSON parse error: ${jsonText}`);
    return [];
  }
};

export const PronounSettingItem = ({ setting, listItemProps }) => {
  const navigation = useNavigation();
  const value = SafeParseArray(setting.value);
  const { rightElement, title, titleStyle } = listItemProps;
  const subtitle = (
    <Text style={{ ...Typography.text(`base`, `black`), flexWrap: `wrap` }}>{value.map((p) => p.pronoun).join(`,`)}</Text>
  );
  const navigateToEditor = () => navigation.push(`PronounSettingEditor`, { setting });
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle={subtitle}
      subtitleStyle={{ ...Typography.text(`small`, `darkGray`) }}
      rightElement={rightElement}
      onPress={navigateToEditor}
    />
  );
};

PronounSettingItem.propTypes = {
  setting: PropTypes.object.isRequired,
  listItemProps: PropTypes.object.isRequired,
};

export const PronounSettingEditor = React.memo(({ navigation, route }) => {
  const { setting } = route.params;
  const { user, setUser } = React.useContext(AppContext);
  const [selectedPronouns, setSelectedPronouns] = useState(SafeParseArray(setting.value));
  const [ownPronoun, setOwnPronoun] = useState(selectedPronouns.find((p) => p.id === 3));
  const [saveEnabled, setSaveEnabled] = useState(false);
  const pronouns = [
    { id: 0, pronoun: `She/Her` },
    { id: 1, pronoun: `They/Them` },
    { id: 2, pronoun: `He/Him` },
    { id: 3, pronoun: `Enter your own` },
  ];

  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    refetchQueries: refetchQueriesFor(`Setting`, `Persona`),
  });

  const selectOption = useCallback(
    (pronoun) => {
      const found = selectedPronouns.find((selected) => +selected.id === +pronoun.id);
      if (found) {
        setSelectedPronouns(selectedPronouns.filter((selected) => !(+selected.id === +found.id)));
        if (pronoun.id === 3) setOwnPronoun();
      } else {
        if (pronoun.id === 3) setOwnPronoun({ id: 3, pronoun: `` });
        setSelectedPronouns([...selectedPronouns, pronoun]);
      }
    },
    [selectedPronouns],
  );

  const enterOwnPronoun = (text) => {
    const newOwnPronoun = { id: 3, pronoun: text };
    setOwnPronoun(newOwnPronoun);
    setSelectedPronouns([...selectedPronouns.filter((p) => p.id !== 3), newOwnPronoun]);
  };

  const renderPronounOption = useCallback(
    (item) => {
      const found = selectedPronouns.find((selected) => +selected.id === +item.id);
      return (
        <ThemedButton
          key={item.id}
          clear
          outline
          title={item.pronoun}
          titleStyle={localStyles.buttonText}
          buttonStyle={localStyles.buttonStyle}
          containerStyle={{ marginTop: 16 }}
          rightIcon={<MeshIcon name="select-circle" color={found ? Colors.brandPurple : Colors.iconColor} focused={!!found} />}
          onPress={() => selectOption(item)}
        />
      );
    },
    [selectedPronouns, selectOption],
  );

  const finishAndSave = useCallback(async () => {
    const value = JSON.stringify(selectedPronouns);
    console.log(`new setting`, { ...setting, value });
    await updateSetting({
      variables: {
        input: {
          id: setting.id,
          user_id: setting.user_id,
          key: setting.key,
          value,
        },
      },
    });
    refetchQueriesFor(`Persona`, `Setting`);
    setUser({
      ...user,
      pronouns: selectedPronouns,
    });
    navigation.goBack();
  }, [navigation, setting, user, setUser, updateSetting, selectedPronouns]);

  useEffect(() => {
    const hasAtLeastOneSelected = !!selectedPronouns.length;
    const hasCustomPronounFilled = !!ownPronoun?.pronoun?.length;
    if (!ownPronoun && hasAtLeastOneSelected) {
      setSaveEnabled(true);
    } else if (ownPronoun && hasCustomPronounFilled) {
      setSaveEnabled(true);
    } else {
      setSaveEnabled(false);
    }
  }, [ownPronoun, selectedPronouns.length]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `Edit your pronouns`,
      headerRight: () => {
        return <ThemedButton clear title="Save" disabled={!saveEnabled} onPress={finishAndSave} containerStyle={{ right: 10 }} />;
      },
    });
  }, [navigation, saveEnabled, finishAndSave]);

  return (
    <KeyboardPaddingView>
      <ScrollView style={{ paddingHorizontal: 16, backgroundColor: Colors.white }}>
        <View>
          <View style={{ paddingVertical: 10 }}>
            <View style={localStyles.blob} />
            <Text style={Typography.text(`large`, `black`, `bold`)}>What are your pronouns?</Text>
          </View>
          <View>
            <Text style={{ ...Typography.text(`base`, `gray`, `bold`), marginTop: 24 }}>Optional. Select all that apply.</Text>
            {pronouns.map(renderPronounOption)}
          </View>
          {ownPronoun && (
            <View style={{ marginTop: 16 }}>
              <TextInput
                autoFocus
                blurOnSubmit
                autoCorrect={false}
                placeholder="Enter your own pronoun"
                placeholderTextColor={Colors.textPlaceholder}
                defaultValue={ownPronoun.pronoun}
                onChangeText={enterOwnPronoun}
                style={{ padding: 10, borderColor: Colors.lightGray, borderWidth: 1, borderRadius: 4 }}
              />
            </View>
          )}
        </View>
        <View style={{ flexDirection: `row`, marginTop: 16, marginBottom: 10, alignItems: `center`, paddingHorizontal: 16 }}>
          <View style={localStyles.visibleTag}>
            <Text style={localStyles.visibleTagText}>Visible</Text>
            <MeshIcon name="eye" color={Colors.brandGreen} size={16} />
          </View>
          <Text style={localStyles.infoText}>
            <Text style={localStyles.infoText}>Pronouns are visible on your profile and can be changed later.</Text>
          </Text>
        </View>
      </ScrollView>
    </KeyboardPaddingView>
  );
});

PronounSettingEditor.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const localStyles = {
  headerSubtext: {
    ...Typography.text(`base`, `gray`, `bold`),
    marginTop: 24,
  },
  buttonText: {
    ...Typography.text(`plusone`, `theme`, `bold`),
  },
  buttonStyle: {
    justifyContent: `space-between`,
    borderRadius: 4,
  },

  visibleTag: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderRadius: 99,
    elevation: 5,
    shadowOffset: { height: 2, width: 2 },
    alignItems: `center`,
    shadowColor: Colors.darkGray,
    shadowOpacity: 0.1,
    backgroundColor: Colors.white,
    padding: 6,
    paddingHorizontal: 12,
  },
  visibleTagText: {
    ...Typography.text(`small`),
    color: Colors.brandGreen,
    marginRight: 8,
  },
  infoText: {
    ...Typography.text(`small`, `gray`),
    flexWrap: `wrap`,
    flexShrink: 2,
    marginLeft: 10,
  },
  modal: {
    backgroundColor: Colors.white,
    padding: 15,
    borderRadius: 20,
  },
  modalTextInput: {
    borderColor: Colors.lightGray,
    borderWidth: 1,
    padding: 10,
    borderRadius: 4,
    height: 65,
    maxHeight: 80,
  },
  blob: {
    flex: 1,
    marginTop: 5,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(79,206,221,0.5)`,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
};
