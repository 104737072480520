import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import {
  FlatList,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  ListRenderItem,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import FastImage from 'react-native-fast-image';
import { useQuery } from '@apollo/react-hooks';
import { StackNavigationProp } from '@react-navigation/stack';
import { useSelector } from 'react-redux';
import { Colors, Spacing } from '../common-styles';
import { ErrorBoundary, MeshIcon, ScrollDots } from '../common-ui';
import { VideoPlayer } from '../post/components/VideoPlayer';
import { client, GET_GROUP, GET_HERO_BANNER } from '../graphql';
import { HeroAssetData } from '../common-types/types';
import { useAppDispatch, RootState } from '../redux/store';
import { setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';
import { AppContext } from '../../AppContext';
import { toggleHeroSound } from '../redux/exploreSlice';
import { useResponsiveDimensions } from '../common-util/hooks/useResponsiveDimensions';

type ExploreHeroAreaNavigationScreens = {
  HomeScreen: undefined;
  CommunityPreview: undefined;
};

type HeroAreaNavigation = StackNavigationProp<ExploreHeroAreaNavigationScreens>;

export const ExploreHeroArea = () => {
  const navigation = useNavigation<HeroAreaNavigation>();
  const dispatch = useAppDispatch();
  const { user } = useContext(AppContext);
  const { isSoundMuted } = useSelector((state: RootState) => state.explore);
  const [selected, setSelected] = useState(0);
  const [showVideo, setShowVideo] = useState(true);
  const [heroAssets, setHeroAssets] = useState<HeroAssetData[]>([]);
  const { width } = useResponsiveDimensions();
  const bannerRef = useRef<FlatList<HeroAssetData>>(null);
  const timeoutRef = useRef(null);
  const isMounted: any = React.useRef(null);

  const { data: heroBannerAssetsData, loading } = useQuery(GET_HERO_BANNER, {
    variables: {
      orderBy: `enabled`,
      limit: 15,
      offset: 0,
    },
    fetchPolicy: `cache-and-network`,
  });

  useEffect(() => {
    if (heroBannerAssetsData?.getHeroBanner) setHeroAssets(heroBannerAssetsData?.getHeroBanner || []);
  }, [heroBannerAssetsData]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (showVideo) {
          setShowVideo(false);
        }
      };
    }, [showVideo]),
  );

  useFocusEffect(
    useCallback(() => {
      if (heroAssets[selected]?.media_type.includes(`video`)) {
        setShowVideo(true);
      }
    }, [heroAssets, selected]),
  );

  const handleScroll = useCallback(
    (e) => {
      const newSelected = Math.round(e.nativeEvent.contentOffset.x / Math.min(Spacing.standardWidth, width));
      if (selected !== newSelected) {
        // setShowVideo(false); // if we want the videos to not be auto-played we only comment this line and that's it
        setSelected(newSelected);
        //@ts-ignore
        clearTimeout(timeoutRef.current);
      }
    },
    [setSelected, selected, width],
  );

  const onAssetFinishedDisplaying = useCallback(() => {
    setSelected(selected + 1);
    if (bannerRef?.current) {
      if (selected >= heroAssets?.length - 1) {
        setSelected(0);
        bannerRef?.current?.scrollToIndex({
          index: 0,
          animated: true,
        });
      } else {
        bannerRef?.current?.scrollToIndex({
          index: selected + 1,
          animated: true,
        });
      }
    }
  }, [selected, heroAssets]);

  const handleImageTimer = useCallback(
    (index: number) => {
      //@ts-ignore
      timeoutRef.current = setTimeout(() => {
        if (index === selected && bannerRef?.current) {
          // means that the user haven't made a scroll to any direction
          onAssetFinishedDisplaying();
          //@ts-ignore
        } else clearTimeout(timeoutRef.current);
      }, 15000);
    },
    [selected, onAssetFinishedDisplaying],
  );

  const handleGoToCommunity = useCallback(
    async (group_id: string) => {
      try {
        const { data } = await client.query({ query: GET_GROUP, variables: { group_id } });
        const { group, group_member } = data?.getGroup;
        if (!group_member || group.application_pending) {
          dispatch(setPreviewCommunity(group));
          navigation.navigate(`CommunityPreview`);
        } else if (!group_member.banned && !!user?.id) {
          dispatch(setActiveCommunity({ user_id: user.id, group }));
          navigation.navigate(`HomeScreen`);
        }
      } catch (error) {
        console.error(`An error ocurred trying to fetch a community's data `, error);
      }
    },
    [dispatch, navigation, user],
  );

  const handleToggleSound = useCallback(() => {
    dispatch(toggleHeroSound(!isSoundMuted));
  }, [isSoundMuted, dispatch]);

  const renderItem: ListRenderItem<HeroAssetData> = useCallback(
    ({ item, index }) => {
      if (item?.media_type.includes(`video`)) {
        const videoDim = { width, height: (width * 9) / 16 };
        if (showVideo && selected === index) {
          return (
            <>
              <TouchableWithoutFeedback onPress={() => handleGoToCommunity(item?.group_id)}>
                <View style={{ position: `relative` }}>
                  <VideoPlayer
                    width={width}
                    height={(width * 9) / 16}
                    video_url={item?.url}
                    onScreen={showVideo}
                    onFinish={onAssetFinishedDisplaying}
                    displayNativeControls={false}
                    muted={isSoundMuted}
                  />
                </View>
              </TouchableWithoutFeedback>
              <TouchableOpacity
                activeOpacity={1}
                style={localStyles.volumeIconContainer}
                hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
                onPress={handleToggleSound}
              >
                <MeshIcon name={!isSoundMuted ? `volume-2` : `volume-x`} size={18} color={Colors.white} />
              </TouchableOpacity>
            </>
          );
        }

        return (
          <ErrorBoundary header="Error loading assets">
            <View style={{ ...videoDim, ...localStyles.placeholderImage }} />
          </ErrorBoundary>
        );
      }

      // The following conditional only applies for images
      if (selected === index) {
        handleImageTimer(index);
      }

      return (
        <TouchableWithoutFeedback onPress={() => handleGoToCommunity(item?.group_id)}>
          <FastImage source={{ uri: item?.url }} resizeMode="cover" style={{ width, height: (width * 9) / 16 }} />
        </TouchableWithoutFeedback>
      );
    },
    [
      showVideo,
      width,
      selected,
      onAssetFinishedDisplaying,
      handleGoToCommunity,
      handleToggleSound,
      isSoundMuted,
      handleImageTimer,
    ],
  );

  if (loading)
    return (
      <View style={[localStyles.dataPending, { width: width - 12 * 2, height: (width * 9) / 16 }]}>
        <ActivityIndicator />
      </View>
    );

  return (
    <View>
      <FlatList<HeroAssetData>
        style={{ marginTop: 12 }}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        initialNumToRender={2}
        onScroll={handleScroll}
        maxToRenderPerBatch={3}
        data={heroAssets}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ref={bannerRef}
      />
      <View>{heroAssets?.length > 1 && <ScrollDots selected={selected} total={heroAssets?.length} />}</View>
    </View>
  );
};

const localStyles = StyleSheet.create({
  dataPending: {
    justifyContent: `center`,
    alignItems: `center`,
    marginHorizontal: 12,
    marginTop: 12,
    backgroundColor: `${Colors.white}66`,
    borderRadius: 12,
  },
  volumeIconContainer: {
    position: `absolute`,
    bottom: 10,
    left: 20,
    backgroundColor: Colors.black,
    borderRadius: 99,
    padding: 10,
    zIndex: 99,
  },
  placeholderImage: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    backgroundColor: Colors.transparent,
  },
});
