import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

type AmountBoxProps = {
  amount: number;
  isSelected: boolean;
  onChangeSelection: () => void;
};

export const AmountBox: React.FC<AmountBoxProps> = (props) => {
  const { amount, isSelected, onChangeSelection } = props;
  return (
    <TouchableOpacity onPress={onChangeSelection} style={[styles.container, isSelected && styles.isSelected]}>
      <Text style={styles.amountText}>${amount}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 120,
    height: 64,
    marginRight: 15,
    borderWidth: 1,
    borderColor: `#BEB9B8`,
    borderRadius: 7,
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 20,
    marginLeft: 15,
  },
  amountText: {
    fontWeight: `600`,
  },
  isSelected: {
    backgroundColor: `rgba(44, 154, 68, 0.1)`,
    borderWidth: 2,
    borderColor: `#2C9A44`,
  },
});
