import { useMutation } from 'react-apollo';
import { ScrollView, Text, View, Dimensions } from 'react-native';
import React from 'react';
import { DUMMY_UUID } from '../../constants';
import { Colors, Typography, Spacing } from '../common-styles';
import { Avatar, Divider, MeshIcon, NoThemeButton, ThemedButton, ErrorBoundary } from '../common-ui';
import { commonPropTypes, formatRelativeDate } from '../common-util';
import { APPROVE_POST, refetchQueriesFor, REJECT_POST } from '../graphql';
import PostContent from '../post/components/PostContent';
import PostFooter from '../post/components/PostFooter';
import { classifyActivity } from '../post/helpers';
import { Events, getContentType, PendoTrackEvent } from '../pendo/events';
import PostPolls from '../post/components/PostPolls';

export const PostApprovalReview = React.memo(({ navigation, route }) => {
  const { group, moderationIssue, user, prev } = route.params;
  const input = { issue_id: moderationIssue?.id, group_id: group?.id };
  const activity = {
    ...moderationIssue?.activity,
    id: DUMMY_UUID, // post not created yet in stream
  };
  const width = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);
  let { content: text } = activity || {};
  if (text) text = text.trim();

  const [approvePost, { loading: approveLoading, error: _approveError }] = useMutation(APPROVE_POST, {
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, `Activity`),
    onCompleted: () => handleBack(`approved`),
    onError: (error) => console.error(error),
    variables: { input },
  });

  const [rejectPost, { loading: rejectLoading, error: _rejectError }] = useMutation(REJECT_POST, {
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `ModeratedGroup2`, `Activity`),
    onCompleted: () => handleBack(`rejected`),
    onError: (error) => console.error(error),
    variables: { input },
  });

  const handleBack = (status) => {
    const input = {
      community_name: group?.name,
      topics: moderationIssue.activity.topics?.map((t) => t.topic),
      //suggested topic(s) assigned to post (not approved topics);
      content_type: getContentType(moderationIssue.activity),
      post_author: moderationIssue.activity?.user?.handle,
      source: `PostApprovalReview`,
      action: status,
    };
    PendoTrackEvent(Events.POST_APPROVAL, input);
    if (prev === `Report`) {
      navigation.pop(2);
    } else {
      navigation.goBack();
    }
  };

  return (
    <View style={localStyles.container}>
      <ScrollView style={localStyles.scrollview}>
        <View style={localStyles.innerContainer}>
          <View style={localStyles.groupHeader}>
            <Text style={{ fontFamily: `inter-semibold`, fontSize: 15, fontWeight: `500`, color: Colors.textBlack }}>
              {group?.name?.trim()}
            </Text>
            <View style={localStyles.newTag}>
              <Text style={localStyles.newTagText}>New</Text>
            </View>
          </View>
          <ErrorBoundary header="Sorry, an error occurred displaying this post">
            <View style={localStyles.activityContentContainer}>
              <View style={localStyles.userInfoContainer}>
                <Avatar navigation={navigation} user={user} size={40} />
                <View style={{ marginLeft: 6 }}>
                  <Text>
                    <Text>{user?.handle?.trim() || `No username`}</Text>
                    <Text> posted to </Text>
                    <Text>{group?.name?.trim()}</Text>
                  </Text>
                  <Text style={localStyles.date}>{formatRelativeDate(moderationIssue?.created_at)}</Text>
                </View>
              </View>
            </View>
            <Divider />
            <View style={{ paddingHorizontal: 16, marginTop: 10 }}>
              <Text style={{ fontSize: 16, fontWeight: `600` }}>{activity.title}</Text>
            </View>
            <PostContent activity={activity} width={width} textOverflowMode="expand" startExpanded={false} onScreen />
            <PostPolls activity={activity} action={false} />
            {classifyActivity(activity) !== `text` && !!text && <PostFooter navigation={navigation} activity={activity} />}
          </ErrorBoundary>
        </View>
      </ScrollView>
      <Divider />
      <View style={localStyles.buttonContainer}>
        <NoThemeButton
          rounded
          color="rgba(234,35,35,0.15)"
          title="Deny"
          titleStyle={localStyles.denyButtonTitle}
          leftIcon={<MeshIcon name="dislike" color={Colors.brightRed} size={16} />}
          containerStyle={{ flex: 1, marginLeft: 16, marginRight: 13 }}
          buttonStyle={{ paddingVertical: 12 }}
          disabled={approveLoading || rejectLoading}
          onPress={rejectPost}
        />
        <ThemedButton
          rounded
          title="Approve"
          titleStyle={localStyles.approveButtonTitle}
          leftIcon={<MeshIcon name="like" color={Colors.white} size={16} />}
          containerStyle={{ flex: 1, marginRight: 16 }}
          buttonStyle={{ paddingVertical: 12 }}
          disabled={approveLoading || rejectLoading}
          onPress={approvePost}
        />
      </View>
    </View>
  );
});

PostApprovalReview.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const localStyles = {
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  scrollview: {
    flex: 1,
  },
  innerContainer: {
    paddingBottom: 80,
  },
  groupHeader: {
    padding: 16,
    flexDirection: `row`,
    justifyContent: `space-between`,
  },
  groupName: {
    fontFamily: `inter-semibold`,
    fontSize: 15,
    fontWeight: `500`,
    color: Colors.textBlack,
  },
  newTag: {
    height: 24,
    width: 66,
    borderRadius: 99,
    backgroundColor: `rgba(44,154,68,0.1)`,
    justifyContent: `center`,
    alignItems: `center`,
  },
  newTagText: {
    ...Typography.smallText,
    fontFamily: `inter-semibold`,
    color: `#2C9A44`,
  },
  image: {
    width: `100%`,
    height: 316,
  },
  activityContentContainer: {
    padding: 16,
  },
  userInfoContainer: {
    flexDirection: `row`,
    alignItems: `center`,
  },
  date: {
    ...Typography.smallText,
    color: Colors.textGray,
  },
  contentText: {
    fontFamily: `inter-regular`,
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
    lineHeight: 18,
  },
  buttonContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    backgroundColor: Colors.white,
    paddingVertical: 16,
  },
  denyButtonTitle: {
    color: Colors.brightRed,
    marginLeft: 9,
    fontSize: 15,
    fontWeight: `500`,
    fontFamily: `inter-semibold`,
  },
  approveButtonTitle: {
    marginLeft: 9,
    fontFamily: `inter-semibold`,
    fontSize: 15,
    fontWeight: `500`,
  },
  imageCountOverlay: {
    backgroundColor: Colors.translucentBlack,
    position: `absolute`,
    zIndex: 200,
    width: `100%`,
    height: 170,
    alignItems: `center`,
    justifyContent: `center`,
  },
  imageCountText: {
    ...Typography.text(`large`, `white`, `bold`, `center`),
  },
};
