import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Colors, Typography } from '../common-styles';
import { NotificationNavigator } from '../common-types/navigation-types';
import { formatShortRelativeDate } from '../common-util';
import { useFollow } from '../community/v2/hooks/useFollow';
import { MeshVerifiedCheck } from '../images';
import { refreshNotification } from '../redux/followSlice';
import { BaseNotificationItem } from './BaseNotificationItem';

const allowedNotifications = {
  followRequest: `follow_request`,
};

interface INotificationItem {
  id: string;
  verb: string;
  created_at: string;
  updated_at: string;
  preview: string;
  is_read: boolean;
  is_seen: false;
  activities: any;
}

interface IFollowNotifiItem {
  notification: INotificationItem;
  markRead: (id: string) => void;
}

export const allowed_types = Object.values(allowedNotifications);

export const FollowNotificationItem: React.FC<IFollowNotifiItem> = ({ notification, markRead }) => {
  const dispatch = useDispatch();
  const { verb, preview, is_read, id } = notification;
  const accepted = JSON.parse(preview)?.accepted;
  const navigation = useNavigation<NotificationNavigator>();

  const { acceptFollowRequest } = useFollow();

  const onPress = () => {
    if (!is_read) {
      markRead(id);
    }

    const user: any = JSON.parse(preview);
    // @ts-ignore
    const parent = navigation?.getParent(`LeftDrawer`);
    parent?.navigate(`Profile`, { user_id: user.persona_id });
  };

  const renderTitle = (obj: string) => {
    const preview = JSON.parse(obj);
    const actor = preview?.handle;
    const accepted = preview?.accepted;
    const isVerified = preview?.isVerified;

    return (
      <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
        <Text style={Typography.add(`bold`)}>{actor}</Text>
        {/* TODO: if the checkmark appearing anywhere persists we might have to change the MeshVerifiedCheck SVG and generate another one */}
        <View style={{ marginRight: 5, marginLeft: 2, alignItems: `center` }}>
          {isVerified && <MeshVerifiedCheck height={12} width={12} />}
        </View>
        <Text>
          {` `}
          {accepted ? `is now following you.` : `requested to follow you.`}
        </Text>
      </Text>
    );
  };

  const getBadge = () => {
    switch (notification.verb) {
      case allowedNotifications.followRequest:
        return `check`;
      default:
        return null;
    }
  };

  const handleFollowRequest = async (accepted: boolean) => {
    const prevOjb = JSON.parse(preview);

    try {
      if (accepted) {
        await acceptFollowRequest(prevOjb?.id);
        dispatch(refreshNotification(true));
        markRead(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BaseNotificationItem
      containerStyle={{
        backgroundColor: notification.is_read ? Colors.seenNotificationBackground : Colors.unseenNotificationBackground,
      }}
      title={renderTitle(notification.preview)}
      date={formatShortRelativeDate(notification.updated_at)}
      //avatar data should be updated by actor, not persona
      avatarData={JSON.parse(notification.preview)}
      avatarType="persona"
      navigation={navigation}
      badge={getBadge()}
      onPress={onPress}
      onPressOptionsButton={() => console.log(`press option...`)}
      follow_status={accepted}
      follow_verb={verb}
      handleFollowRequest={handleFollowRequest}
      badgeSize={22}
      isSameStack={true}
    />
  );
};
