import { RouteProp, useRoute, useNavigation } from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { ActivityIndicator, Platform, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AppContext } from '../../../AppContext';
import { Colors, Typography } from '../../common-styles';
import { ExploreNavigator, ExploreNavigatorScreens, MainTabsNavigator } from '../../common-types/navigation-types';
import { GroupInvitation, GroupMember } from '../../common-types/types';
import { BackButton, Divider, ThemedButton } from '../../common-ui';
import { loadCachedGroupMember, COMMUNITY_SETTING } from '../../graphql';
import { RootState, useAppDispatch } from '../../redux/store';
import { CommunityFeed } from './CommunityFeed';
import { CommunityHeader } from './CommunityHeader';
import { useCommunityHeader, HeaderProps } from './hooks/useCommunityHeader';
import { useHideHeader } from './hooks/useHideHeader';
import { AnimatedHeader } from './AnimatedHeader';
import { Events, PendoTrackEvent } from '../../pendo/events';
import { useInvite } from '../hooks/useInvite';
import { setActiveCommunity, setPreviewCommunity } from '../../redux/feedSlice';

export type CommunityPreviewRoute = RouteProp<ExploreNavigatorScreens, `CommunityPreview`>;

export const CommunityPreview = () => {
  const { user, identity, isGuestMode } = useContext(AppContext);
  const navigation = useNavigation<ExploreNavigator>();
  const { params } = useRoute<CommunityPreviewRoute>();
  const { previewCommunity } = useSelector((state: RootState) => state.feed);
  const dispatch = useAppDispatch();
  const [, setInvitation] = useState<GroupInvitation>();
  const currentGroup = previewCommunity || params?.group;

  const { invitation, loading, error } = useInvite();

  const { handleScroll, translateY } = useHideHeader(currentGroup, {
    headerHeight: 150,
    minScroll: 100,
  });
  const [groupMember, setGroupMember] = useState<GroupMember>();
  const { top } = useSafeAreaInsets();

  const { data } = useQuery(COMMUNITY_SETTING, {
    fetchPolicy: `cache-and-network`,
    variables: { input: { group_id: currentGroup?.id, key: `group.setting.visible` } },
  });

  const privacyContent = data?.getCommunitySetting;

  useEffect(() => {
    if (previewCommunity && !groupMember) {
      const gm = loadCachedGroupMember(previewCommunity?.id, user?.id);
      if (gm) setGroupMember(gm);
    }
  }, [previewCommunity, user?.id, groupMember]);

  useEffect(() => {
    if (loading) return;
    if (!error && invitation) {
      const { group, group_member } = invitation;
      if (user?.id && identity?.id && !isGuestMode && group && group_member?.role_name && !group_member?.banned) {
        dispatch(setActiveCommunity({ user_id: user.id, group }));
        const parent = navigation.getParent<MainTabsNavigator>();
        navigation.goBack();
        parent.jumpTo(`HomeNavigator`, { screen: `HomeScreen` });
      } else {
        dispatch(setPreviewCommunity(group));
      }
    }
  }, [navigation, invitation, loading, error, user?.id, identity?.id, dispatch, isGuestMode]);

  // TODO: Possibly this is no longer needed since we'are not rendering an invite pop-up anymore
  // Possible refator: There is something that can be done in order to render something when you have an invitation
  // maybe we can render a top absolute banner at the very top of the screen that says "You have an invitation to join this community from (inviter)"
  // and then you are able to close it or when joined the banner will disappear.
  const handleChangeInvite = React.useCallback((value?: GroupInvitation) => {
    setInvitation(value);
  }, []);

  // params?.invite_token --> Send with the attempJoinCommunity

  const headerProps = useCommunityHeader({
    mode: `preview`,
    groupMember,
    onChangeMember: setGroupMember,
    onChangeGroup: () => null,
    onChangeInvite: handleChangeInvite,
  });

  if (!groupMember && privacyContent?.value === `private`) {
    return (
      <View style={{ ...styles.container, marginTop: top + 5 }}>
        <PreviewNavBar headerProps={headerProps} topOffset={0} />
        <View style={styles.privacyAlertContainer}>
          <ContentPrivacyAlert />
          <View style={{ minHeight: 150 }} />
        </View>
        <PreviewCommunityFooter headerProps={headerProps} />
      </View>
    );
  }
  return (
    <View style={{ paddingTop: top + 5, flex: 1 }}>
      <AnimatedHeader animatedValue={translateY}>
        <PreviewNavBar headerProps={headerProps} topOffset={top} />
      </AnimatedHeader>
      <View style={{ marginTop: Platform.OS === `ios` ? 20 : 10 }}>
        <CommunityFeed
          feedContainerStyle={{ paddingTop: 200 }}
          onScroll={handleScroll}
          groupMember={groupMember}
          group={currentGroup}
        />
      </View>
      <PreviewCommunityFooter headerProps={headerProps} />
    </View>
  );
};

const ContentPrivacyAlert: React.FC = () => {
  return (
    <View style={styles.privacyAlert}>
      <Text style={styles.privacyText}>Content is viewable by members only.</Text>
    </View>
  );
};

type PreviewCommunityFooterProps = {
  headerProps: HeaderProps;
};

const PreviewCommunityFooter: React.FC<PreviewCommunityFooterProps> = ({ headerProps }) => {
  const { handleJoinOrLeaveCommunity, currentCommunity, loading } = headerProps;
  const { user } = useContext(AppContext);
  const [queryInFlight, setQueryInFlight] = useState(false);
  const debouncedHandleJoinOrLeave = useDebouncedCallback(async () => {
    setQueryInFlight(true);
    await handleJoinOrLeaveCommunity();

    setQueryInFlight(false);
  }, 500);

  const isPending = currentCommunity?.join_request_pending || currentCommunity?.application_pending;

  let btnTitle = ``;
  if (!queryInFlight && !isPending) btnTitle = `Join`;
  else if (loading || isPending) btnTitle = `Pending`;
  return (
    <View style={{ position: `absolute`, left: 0, right: 0, bottom: 0, backgroundColor: Colors.white }}>
      <Divider />
      {!user?.isBanned && (
        <ThemedButton
          rounded
          title={btnTitle}
          onPress={() => debouncedHandleJoinOrLeave()}
          buttonStyle={{ marginVertical: 15, marginHorizontal: 37, paddingHorizontal: 30, paddingVertical: 12 }}
          titleStyle={loading || isPending ? { color: Colors.gray } : { color: Colors.white }}
          disabled={loading || isPending}
          rightIcon={queryInFlight ? <ActivityIndicator size={22} style={styles.pendingIndicator} color={Colors.white} /> : null}
        />
      )}
    </View>
  );
};

type PreviewNavBarProps = {
  headerProps: HeaderProps;
  groupMember?: GroupMember;
  topOffset?: number;
};

const PreviewNavBar: React.FC<PreviewNavBarProps> = ({ headerProps, groupMember, topOffset }) => {
  const { isGuestMode } = useContext(AppContext);
  const navigation = useNavigation<ExploreNavigator>();
  const { params } = useRoute<CommunityPreviewRoute>();
  const { forceExplorerRefresh } = useSelector((state: RootState) => state.feed);
  const { top } = useSafeAreaInsets();
  const previous = params?.previous;

  const goBack = () => {
    const { index, routes } = navigation.getState();
    const [rootScreen] = routes;
    const parent = navigation.getParent<MainTabsNavigator>();
    const refreshExplore = previous === `Explore` && forceExplorerRefresh;
    const previewAsRootScreen = index === 0 && rootScreen.name === `CommunityPreview`;
    // reset in case Prevew replaces Explore as root screen of stack
    if (refreshExplore || previewAsRootScreen) {
      navigation.reset({ index: 0, routes: [{ name: `Explore` }] });
      return;
    }
    navigation.goBack();
    if (previous === `FollowNotification`) {
      parent.jumpTo(`NotificationNavigator`, { screen: `NotificationsTab` });
    }
  };

  useEffect(() => {
    if (!headerProps.currentCommunity) return;
    const { name, sponsor } = headerProps.currentCommunity;
    const input = {
      community_name: name,
      community_role: isGuestMode ? `guest` : `visitor`,
      visitor_type: isGuestMode ? `unregistered` : `registered`,
      sponsor: sponsor?.name,
    };
    PendoTrackEvent(Events.PREVIEW_ACTIVE, input);
  }, [headerProps.currentCommunity, isGuestMode]);

  return (
    <View style={[styles.container, { marginTop: topOffset ?? top }]}>
      <View style={styles.navHeader}>
        <BackButton onPress={goBack} />
      </View>
      <CommunityHeader roleId={groupMember?.role_id} headerProps={headerProps} mode="preview" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  navHeader: {
    position: `absolute`,
    width: `100%`,
    backgroundColor: Colors.white,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
  },
  privacyAlertContainer: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: -150,
  },
  privacyAlert: {
    backgroundColor: Colors.badgePlusBackground,
    borderRadius: 7,
    marginHorizontal: 16,
    marginVertical: 16,
    height: 60,
    justifyContent: `center`,
    alignItems: `center`,
  },
  pendingIndicator: { marginHorizontal: 8 },
  privacyText: {
    ...Typography.text(`theme`, `bold`, `center`),
    paddingHorizontal: 20,
  },
});
