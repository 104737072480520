import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, ActivityIndicator, Switch, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useQuery, useMutation } from 'react-apollo';
import { StackNavigationProp } from '@react-navigation/stack';
import { MeshIcon, Divider, ListItem } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { DrawerSettingNavigatorScreens } from '../common-types/navigation-types';
import { SETTING_QUERY, UPDATE_SETTING_MUTATION } from '../graphql';
import { AppContext } from '../../AppContext';

type DrawerNavigation = StackNavigationProp<DrawerSettingNavigatorScreens, `DrawerSettingsMenu`>;

export const DiscoverabilityAndContacts = () => {
  const navigation = useNavigation<DrawerNavigation>();
  const navigateToPaymentMethod = useCallback(() => navigation.navigate(`DiscoverabilityAndContactsSetting`), [navigation]);

  return (
    <View>
      <TouchableOpacity activeOpacity={0.8} onPress={navigateToPaymentMethod} style={localStyles.discoverabilityAndContactsItem}>
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Discoverability and contacts</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const DiscoverabilityAndContactsSetting = () => {
  const { user } = useContext(AppContext);

  const [emailSwitchValue, setEmailSwitchValue] = useState<Boolean>(false);
  const [phoneSwitchValue, setPhoneSwitchValue] = useState<Boolean>(false);

  const { data: findByEmailData, loading: loadingEmailData } = useQuery(SETTING_QUERY, {
    variables: {
      settingQuery: {
        query: `user.setting.find_by_email`,
        user_id: user?.id,
      },
    },
    fetchPolicy: `cache-and-network`,
  });

  const { data: findByPhoneData, loading: loadingPhoneData } = useQuery(SETTING_QUERY, {
    variables: {
      settingQuery: {
        query: `user.setting.find_by_phone`,
        user_id: user?.id,
      },
    },
    fetchPolicy: `cache-and-network`,
  });

  const [updateSettingMutation] = useMutation(UPDATE_SETTING_MUTATION);

  useEffect(() => {
    const [findByEmailSetting] = findByEmailData?.getSettings;
    const [findByPhoneSetting] = findByPhoneData?.getSettings;
    setEmailSwitchValue(!!findByEmailSetting?.value);
    console.log(`🚀 🚀  ->  ~ useEffect ~ findByEmailSetting?.value`, findByEmailSetting?.value);
    setPhoneSwitchValue(!!findByPhoneSetting?.value);
  }, [findByEmailData, findByPhoneData]);

  const handleValueChange = async (key: String) => {
    console.log(`🚀 🚀  ->  ~ handleValueChange ~ emailSwitchValue`, emailSwitchValue);
    console.log(`🚀 🚀  ->  ~ handleValueChange ~ phoneSwitchValue`, phoneSwitchValue);

    if (!key) return;

    try {
      const { data } = await updateSettingMutation({
        variables: {
          input: {
            key: `user.setting.find_by_${key}`,
            value: key === `email` ? emailSwitchValue : phoneSwitchValue,
            user_id: user?.id,
          },
        },
      });
      console.log(`🚀 🚀  ->  ~ handleValueChange ~ data`, data);
    } catch (error) {
      console.error(`An error ocurred trying to trigger the updateSettingMutation - DiscoverabilityAndContacts.tsx `, error);
    }
  };

  return (
    <View>
      <Text style={{ ...Typography.text(`small`), padding: 16 }}>
        Edit your profile discoverability settings and manage contacts you've imported.
      </Text>
      <View style={localStyles.headerContent}>
        <Text style={{ ...Typography.text(`base`, `bold`, `gray`) }}>Discoverability</Text>
      </View>

      {loadingEmailData || loadingPhoneData ? (
        <ActivityIndicator />
      ) : (
        <>
          <ListItem
            title="Let others find you by your email"
            titleStyle={{ ...Typography.text(`small`) }}
            style={{ marginHorizontal: 16 }}
            rightIcon={
              <Switch
                value={!!emailSwitchValue}
                onValueChange={() => {
                  setEmailSwitchValue(!emailSwitchValue);
                  handleValueChange(`email`);
                }}
                trackColor={{ true: Colors.brandPurple }}
              />
            }
          />
          <Divider />
          <ListItem
            title="Let others find you by your phone number"
            titleStyle={{ ...Typography.text(`small`) }}
            style={{ marginHorizontal: 16 }}
            rightIcon={
              <Switch
                value={!!emailSwitchValue}
                onValueChange={() => {
                  setPhoneSwitchValue(!phoneSwitchValue);
                  handleValueChange(`phone`);
                }}
                trackColor={{ true: Colors.brandPurple }}
              />
            }
          />
          <Divider />
        </>
      )}
    </View>
  );
};

const localStyles = StyleSheet.create({
  headerContent: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    backgroundColor: Colors.backgroundColor,
  },
  discoverabilityAndContactsItem: {
    flex: 1,
    flexDirection: `row`,
    justifyContent: `space-between`,
    backgroundColor: Colors.white,
    padding: 16,
  },
});
