import { Platform, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { useQuery } from 'react-apollo';
import { GET_INVITATION } from '../graphql';
import { Typography } from '../common-styles';
import { LoadingIndicator, QueryError } from '../common-ui';
import { PageTemplate, QRTemplate } from './PageTemplate';
import { NativePageTemplate } from './NativePageTemplate';
import { GetInvitationVars, GetInvitationData } from '../common-types/types';

type WebInviteLandingPageProps = {
  url: string;
};
type MobileInviteLandingPageProps = {
  invite_token: string;
  storeImage: any;
  storeUrl: string;
};

type InviteLandingPageProps = (WebInviteLandingPageProps | MobileInviteLandingPageProps) & {
  invite_token: string;
  variant: typeof Platform.OS;
};

export const InviteLandingPage: React.FC<InviteLandingPageProps> = ({ variant, invite_token, ...rest }) => {
  const { loading, error, data, networkStatus, refetch } = useQuery<GetInvitationData, GetInvitationVars>(GET_INVITATION, {
    variables: { invite_token },
  });
  if (loading)
    return (
      <View style={{ width: `100%`, height: `100%`, flex: 1, justifyContent: `center`, alignItems: `center` }}>
        <LoadingIndicator size={32} style={{ margin: 32 }} />
      </View>
    );
  if (error && variant === `web`) {
    //return <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />;
    return (
      <PageTemplate>
        <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />
      </PageTemplate>
    );
  }
  if (error && variant !== `web`) {
    //return <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />;
    const { storeUrl, storeImage } = rest as MobileInviteLandingPageProps;
    return (
      <NativePageTemplate storeUrl={storeUrl} storeImage={storeImage} title="Get the app">
        <View style={{ flexDirection: `column` }}>
          <Text style={{ ...Typography.boldHeaderStyle, margin: 20 }}>Server error</Text>
          <Text style={{ ...Typography.text, margin: 20 }}>{error.message}</Text>
        </View>
      </NativePageTemplate>
    );
  }

  const { group, group_invitation } = data?.getInvitation || {};
  const { handle = `` } = group_invitation?.inviter || {};
  //TODO: display inviter's handle with getInvitation.group_invitation.inviter.handle
  if (variant === `web`) {
    const { url } = rest as WebInviteLandingPageProps;
    return (
      <QRTemplate url={url}>
        <Text>
          <Text>
            {handle} has invited you to{` `}
          </Text>
          <Text style={styles.headerBold}>{group?.name}</Text>
          <Text>{` `}on Mesh</Text>
        </Text>
      </QRTemplate>
    );
  }

  const { storeUrl, storeImage } = rest as MobileInviteLandingPageProps;
  return (
    <NativePageTemplate storeUrl={storeUrl} storeImage={storeImage} title="Get the app" avatar_url={group?.avatar_url}>
      <Text>
        <Text>
          {handle} has invited you to{` `}
        </Text>
        <Text style={styles.headerBold}>{group?.name}</Text>
        <Text>{` `}on Mesh</Text>
      </Text>
    </NativePageTemplate>
  );
};

const styles = StyleSheet.create({
  headerBold: { ...Typography.text(`huge`, `bold`), fontFamily: `sans-serif`, fontWeight: `600` },
});
