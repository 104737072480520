import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { View, Animated, Text, ActivityIndicator, ListRenderItem, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { useQuery } from '@apollo/react-hooks';
import { COMMUNITY_SETTING, GET_AFFILIATED_COMMUNITIES } from '../../graphql';
import { RootState } from '../../redux/store';
import { Typography } from '../../common-styles';
import { GroupCardRounded } from '../../common-ui/GroupCardRounded';
import { GroupQuery } from '../../common-types/types';
import { setActiveCommunity, setPreviewCommunity } from '../../redux/feedSlice';
import { AppContext } from '../../../AppContext';
import { ExploreNavigator, HomeNavigator, HomeNavigatorScreens } from '../../common-types/navigation-types';
import { ThemedButton } from '../../common-ui';

type AffiliatedListRoute = RouteProp<HomeNavigatorScreens, `AffiliatedCommunitiesHomeList`>;

export const AffiliatedCommunitiesHomeList = () => {
  return (
    <AffiliatedCommunitiesHomeListFragment
      feedContainerStyle={style.feedContainerStyle}
      onScroll={undefined}
      contentOffset={undefined}
    />
  );
};

type FragmentProps = {
  contentOffset?: any;
  onScroll?: (...args: any[]) => void;
  feedContainerStyle: StyleProp<ViewStyle>;
};

const AffiliatedCommunitiesHomeFragment: React.FC<FragmentProps> = ({ contentOffset, onScroll, feedContainerStyle }) => {
  const { user } = useContext(AppContext);
  const { activeCommunity } = useSelector((state: RootState) => state.feed);
  const navigation = useNavigation<HomeNavigator | ExploreNavigator>();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { params } = useRoute<AffiliatedListRoute>();
  //@ts-ignore
  const { group_member } = params;

  const { data: affiliatedGroups } = useQuery(GET_AFFILIATED_COMMUNITIES, {
    fetchPolicy: `cache-and-network`,
    variables: { origin_group_id: activeCommunity?.id },
  });

  const { data: affiliatedSetting } = useQuery(COMMUNITY_SETTING, {
    fetchPolicy: `cache-and-network`,
    variables: { input: { group_id: activeCommunity?.id, key: `group.setting.affiliated_communities` } },
  });

  const handleGoToManage = useCallback(() => {
    try {
      setLoading(true);
      if (affiliatedGroups?.getAffiliatedGroups2.data && affiliatedSetting?.getCommunitySetting)
        (navigation as HomeNavigator).navigate(`AffiliatedCommunities`, {
          affiliatedGroupsParam: affiliatedGroups?.getAffiliatedGroups2.data,
          setting: affiliatedSetting?.getCommunitySetting,
        });
    } catch (error) {
      console.error(`An error ocurred trying to go to a community - handleGoToManage()`);
    } finally {
      setLoading(false);
    }
  }, [affiliatedGroups, navigation, affiliatedSetting?.getCommunitySetting]);

  useEffect(() => {
    if (group_member?.role_name === `owner` || group_member?.role_name === `manager`) {
      navigation?.setOptions({
        headerRight: () => (
          <ThemedButton
            title="Manage"
            clear
            onPress={() => handleGoToManage()}
            disabled={loading}
            rightIcon={loading ? <ActivityIndicator /> : undefined}
          />
        ),
      });
    }
  }, [loading, handleGoToManage, navigation, group_member]);

  const renderItem: ListRenderItem<GroupQuery> = useCallback(
    ({ item: { group, group_member } }) => {
      const handleNavigateToCommunity = async () => {
        if (group_member && user?.id) {
          await dispatch(setActiveCommunity({ user_id: user?.id, group }));
          (navigation as HomeNavigator).navigate(`HomeScreen`);
          return;
        }

        if (group?.discoverable === `public`) {
          await dispatch(setPreviewCommunity(group));
          (navigation as ExploreNavigator).navigate(`CommunityPreview`);
        } else {
          (navigation as HomeNavigator).navigate(`AboutScreen`, { group, group_member });
        }
      };

      return (
        <GroupCardRounded
          group={group}
          group_member={group_member}
          handleCardPress={handleNavigateToCommunity}
          handleArrowPress={handleNavigateToCommunity}
          showMembershipPill
        />
      );
    },
    [dispatch, navigation, user?.id],
  );

  const renderEmptyList = useCallback(() => {
    return (
      <View style={{ flex: 1, justifyContent: `center`, alignItems: `center`, marginTop: 50, minHeight: 200 }}>
        <Text style={{ ...Typography.text(`gray`) }}>No affiliated communities to show</Text>
      </View>
    );
  }, []);

  return (
    <Animated.FlatList<GroupQuery>
      data={affiliatedGroups?.getAffiliatedGroups2?.data}
      contentOffset={contentOffset}
      contentContainerStyle={feedContainerStyle}
      renderItem={renderItem}
      keyExtractor={(item, index) => item.group.id + index}
      showsVerticalScrollIndicator={false}
      ListEmptyComponent={renderEmptyList}
      onScroll={onScroll}
    />
  );
};
export const AffiliatedCommunitiesHomeListFragment = React.memo(AffiliatedCommunitiesHomeFragment);

const style = StyleSheet.create({
  feedContainerStyle: {
    flex: 1,
    paddingTop: 16,
  },
});
