import { ApolloProvider } from 'react-apollo';
import { Linking, Platform, Text } from 'react-native';
import React, { useEffect } from 'react';
import URLParse from 'url-parse';
import { client } from '../graphql';
import { getMeshInviteToken, profileInviteRegex } from '../post/hooks/useLinkPreview';
import { EmailVerification } from './EmailVerification';
import { InviteLandingPage } from './InviteLandingPage';
import { NativePageTemplate } from './NativePageTemplate';
import { QRTemplate } from './PageTemplate';
import { setAttribution } from '../attribution';
import { ProfileLandingPage } from './ProfileLandingPage';

type RedirectProps = {
  url: string;
};

type MobileRedirectProps = RedirectProps & {
  variant: `ios` | `android`;
  storeUrl: string;
  storeImage: string;
};

const extractFromURL = (url: string) => {
  const { query } = URLParse(url, true);
  console.log(`extractFromURL -> query`, query);
  const community_invite_token = getMeshInviteToken(url);
  if (community_invite_token) {
    return { query, invitation: true, invite_token: community_invite_token };
  }
  const profile_invite_token = getMeshInviteToken(url, profileInviteRegex);
  if (profile_invite_token) {
    return { query, invitation: true, invite_token: profile_invite_token };
  }

  const { token: invite_token, invitation } = query || {};
  return { query, invite_token, invitation };
};

export const DesktopRedirect: React.FC<RedirectProps> = ({ url }) => {
  return (
    <ApolloProvider client={client}>
      <DesktopRedirectComp url={url} />
    </ApolloProvider>
  );
};

const DesktopRedirectComp: React.FC<RedirectProps> = ({ url }) => {
  const { query, invite_token, invitation } = extractFromURL(url);
  if (url.includes(`confirmation`)) {
    const { token } = query || {};
    return <EmailVerification email_token={token} />;
  }

  if (url.includes(`profile/invite`) && invite_token) {
    return <ProfileLandingPage variant="web" url={url} invite_token={invite_token} />;
  }

  if (invite_token && invitation !== undefined) {
    return <InviteLandingPage variant="web" url={url} invite_token={invite_token} />;
  }

  return (
    <QRTemplate url={url}>
      <Text>Mesh is only on mobile</Text>
    </QRTemplate>
  );
};

const MobileRedirect: React.FC<MobileRedirectProps> = ({ url, variant, storeUrl, storeImage }) => {
  const { query, invite_token, invitation } = extractFromURL(url);
  useEffect(() => {
    const setAttributionAndOpenStore = async () => {
      await setAttribution(url);

      if (Platform.OS === `ios`) {
        const newUrl = url.replace(`https://`, `mesh://`);
        console.log({ newUrl }, `newurl`);
        const canOpenUrl = await Linking.canOpenURL(newUrl);
        if (canOpenUrl) {
          Linking.openURL(newUrl);
          return;
        }
        Linking.openURL(storeUrl);
      }
    };
    setAttributionAndOpenStore();
  }, [storeUrl, url]);

  if (url.includes(`confirmation`)) {
    const { token } = query || {};
    return <EmailVerification email_token={token} />;
  }

  if (url.includes(`profile/invite`) && invite_token) {
    return <ProfileLandingPage variant={variant} invite_token={invite_token} storeUrl={storeUrl} storeImage={storeImage} />;
  }

  if (invite_token && invitation !== undefined) {
    return <InviteLandingPage variant={variant} invite_token={invite_token} storeUrl={storeUrl} storeImage={storeImage} />;
  }

  return (
    <NativePageTemplate storeUrl={storeUrl} storeImage={storeImage} title="Get the app">
      <Text>Mesh is mobile-first.</Text>
    </NativePageTemplate>
  );
};

export const IPhoneRedirect: React.FC<RedirectProps> = ({ url }) => {
  return (
    <ApolloProvider client={client}>
      <MobileRedirect
        url={url}
        variant="ios"
        storeUrl="https://apps.apple.com/us/app/mesh-communities/id1473534230"
        storeImage={require(`../../assets/images/ios-app-store-en-US.png`)}
      />
    </ApolloProvider>
  );
};

export const AndroidRedirect: React.FC<RedirectProps> = ({ url }) => {
  return (
    <ApolloProvider client={client}>
      <MobileRedirect
        url={url}
        variant="android"
        storeUrl="https://play.google.com/store/apps/details?id=us.meshconnect.mesh"
        storeImage={require(`../../assets/images/android-play-store-en-US.png`)}
      />
    </ApolloProvider>
  );
};
