import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import Constants from 'expo-constants';
import Modal from 'react-native-modal';
import { Colors } from '../../common-styles';
import { NoThemeButton } from '../../common-ui';
import ColorPicker from 'react-native-wheel-color-picker';

type MemeColorPickerType = {
  isVisible: boolean;
  selectedColor: string;
  setHide: () => void;
  onSelectColor: (color: string) => void;
};

export const MemeColorPicker: React.FC<MemeColorPickerType> = ({ isVisible, selectedColor, setHide, onSelectColor }) => {
  const [color, setColor] = useState('');
  const [thumbSize, setThumbSize] = useState(0);
  useEffect(() => {
    if (isVisible)
      setTimeout(() => {
        setThumbSize(40);
      }, 100);
  }, [isVisible]);
  return (
    <Modal
      isVisible={isVisible}
      hideModalContentWhileAnimating
      animationInTiming={500}
      animationOutTiming={500}
      useNativeDriver
      propagateSwipe
      hasBackdrop={false}
      style={styles.modalContainer}
    >
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <NoThemeButton
            clear
            title="Cancel"
            color={Colors.iconColor}
            onPress={() => {
              onSelectColor(selectedColor);
              setHide();
            }}
          />
          <Text style={styles.headerText}>Color Picker</Text>
          <NoThemeButton
            clear
            title="Confirm"
            color={Colors.deepPurple}
            onPress={() => {
              onSelectColor(color);
              setHide();
            }}
          />
        </View>
        <View style={{ padding: 40, flex: 1 }}>
          <ColorPicker
            color={selectedColor}
            discrete={false}
            onColorChange={(color) => setColor(color)}
            onColorChangeComplete={(color) => setColor(color)}
            shadeWheelThumb={false}
            shadeSliderThumb
            thumbSize={thumbSize}
            autoResetSlider
            sliderHidden
            // palette={[
            //   '#000000',
            //   '#ffffff',
            //   '#ed1c24',
            //   '#d11cd5',
            //   '#1633e6',
            //   '#00aeef',
            //   '#00c85d',
            //   '#57ff0a',
            //   '#ffde17',
            //   '#f26522',
            // ]}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    margin: 0,
    backgroundColor: `white`,
  },
  container: {
    flex: 1,
    paddingTop: Platform.OS === `ios` ? Constants.statusBarHeight + 10 : 0,
  },
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.dividerColor,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    paddingBottom: 10,
    paddingHorizontal: 18,
  },
  headerText: {
    fontSize: 17,
    fontWeight: `600`,
  },
  cancelText: {
    color: Colors.iconColor,
  },
});
