import { Text, View, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import React, { FC, memo, useMemo } from 'react';
import { Typography, Colors } from '../common-styles';
import { createCommunityStyles } from '../community';
import { CommunityTile } from '../images/CreateCommunityTiles/CommunityTile';
import { ChannelTile } from '../images/CreateCommunityTiles/ChannelTile';
import { PresetTestIds } from './testLabels';
import { CommunityPreset } from '../redux/createCommunitySlice';

type PresetTitleProps = {
  title: CommunityPreset;
  onChangePreset: (title: string) => void;
};

const PresetTile: FC<PresetTitleProps> = memo(({ title, onChangePreset }) => {
  const handlePress = () => {
    onChangePreset(title);
  };

  const tiles: any = useMemo(() => {
    return {
      community: <CommunityTile />,
      channel: <ChannelTile />,
    };
  }, []);

  return (
    <View style={localStyles.tileContainer}>
      <TouchableOpacity testID={PresetTestIds[title]} onPress={handlePress}>
        {tiles[title]}
      </TouchableOpacity>
    </View>
  );
});

const PRESETS: CommunityPreset[] = [`community`, `channel`];
type CommunityTypeProps = {
  onChangePreset: (title: string) => void;
};

export const CommunityType: FC<CommunityTypeProps> = ({ onChangePreset }) => {
  return (
    <ScrollView style={createCommunityStyles.container} testID={PresetTestIds.screen}>
      <View style={{ margin: 10, backgroundColor: Colors.white, paddingHorizontal: 24 }}>
        <View style={localStyles.headerContainer}>
          <Text style={{ ...Typography.text(`header`, `plustwo`) }}>Choose your template to get started</Text>
        </View>
        <View style={{ alignItems: `center` }}>
          <Text style={{ ...Typography.text(`base`), textAlign: `center` }}>
            Nodes are where you post content, invite people to join, and momentize. You can customize your settings at any time.
          </Text>
        </View>
        {PRESETS.map((preset) => {
          return <PresetTile key={preset} onChangePreset={onChangePreset} title={preset} />; //isSelected={isSelected}
        })}
      </View>
    </ScrollView>
  );
};

const localStyles = StyleSheet.create({
  tileContainer: {
    margin: 15,
    justifyContent: `center`,
    alignItems: `center`,
  },
  headerContainer: {
    marginTop: 15,
    marginBottom: 10,
    marginHorizontal: 10,
    alignItems: `center`,
  },
});
