import React, { useContext } from 'react';
import { Text, View, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@apollo/react-hooks';
import ParsedText from 'react-native-parsed-text';
import { Colors, Typography } from '../../common-styles';
import { LoadingIndicator, Divider, Avatar } from '../../common-ui'; //eslint-disable-line
import { LIST_GROUP_MEMBERS } from '../../graphql';
import { owner, manager, moderator, creator } from '../memberInfoHelpers';
import { AppContext } from '../../../AppContext';
import { Group, GroupMember, ListGroupMembersData, ListGroupMembersVars, User } from '../../common-types/types';
import { HomeNavigator } from '../../common-types/navigation-types';

type MemberListSummaryProps = {
  group: Pick<Group, `id`>;
  group_member?: GroupMember;
  access: boolean;
  memberList: string;
};

const memberListEnabledRoles = [`owner`, `manager`, `moderator`, `contributor`];

export const MemberListSummary: React.FC<MemberListSummaryProps> = ({ group_member, access, group, memberList }) => {
  const { isGuestMode } = useContext(AppContext);
  const navigation = useNavigation<HomeNavigator>();
  const { data: members, loading } = useQuery<ListGroupMembersData, ListGroupMembersVars>(LIST_GROUP_MEMBERS, {
    variables: { group_id: group?.id, offset: 0, limit: 40 },
    fetchPolicy: `cache-and-network`,
    skip: isGuestMode,
  });
  if (isGuestMode) return null;
  const memberGroups: Record<string, User[]> = { owner: [], manager: [], moderator: [], contributor: [] };

  members?.listGroupMembers?.forEach((member) => {
    if (member.banned) return;
    if (member.role_id === owner) memberGroups.owner.push(member.persona);
    if (member.role_id === manager) memberGroups.manager.push(member.persona);
    if (member.role_id === moderator) memberGroups.moderator.push(member.persona);
    if (member.role_id === creator) memberGroups.contributor.push(member.persona);
  });

  const goToMemberInfo = () => navigation.push(`MemberInfo`, { groupId: group.id });
  return (
    <>
      <Divider style={{ marginTop: 16, borderColor: Colors.headerBottomBorderColor }} />
      {group_member ? (
        <View style={{ flexDirection: `row`, justifyContent: `space-between`, marginTop: 12 }}>
          <Text style={{ ...Typography.text(`bold`, `black`, `plusone`) }}>Members and roles</Text>
          {memberListEnabledRoles.includes(group_member?.role_name) || (group_member.role_name === `member` && access) ? (
            <TouchableWithoutFeedback onPress={goToMemberInfo}>
              <Text style={Typography.text(`theme`, `bold`)}>View all</Text>
            </TouchableWithoutFeedback>
          ) : null}
        </View>
      ) : null}
      {loading && <LoadingIndicator size={32} />}
      {!loading && (members === null || members === undefined) && (
        <View style={{ marginTop: 16 }}>
          <Text style={{ ...Typography.text(`base`, `black`) }}>You must be a group member to see the members</Text>
        </View>
      )}
      {/* members and roles */}
      {Object.keys(memberGroups).map((key) => {
        if (!memberGroups[key].length) return null;
        return <MemberGroup key={key} roleName={key} members={memberGroups[key]} />;
      })}

      <Divider style={{ marginTop: 16, borderColor: Colors.headerBottomBorderColor }} />

      {!group_member && memberList !== `anyone` ? (
        <View style={localStyles.listPrivacyText}>
          <Text style={Typography.text(`theme`, `bold`, `center`)}>Member list is private for this community.</Text>
        </View>
      ) : undefined}
    </>
  );
};

type MemberGroupProps = {
  roleName: string;
  members: User[];
};

const MemberGroup: React.FC<MemberGroupProps> = ({ roleName, members }) => {
  const names = members.map((u) => u.handle);
  const namesToDisplay = names.filter((_, index) => index < 4).join(`, `);
  const numberOfRemainingUsers = names?.length > 4 ? names?.length - 4 : 0;

  let text = `${namesToDisplay} %replace%- ${names.length > 1 ? `${roleName}s` : roleName}`;
  text = text.replace(
    `%replace%`,
    numberOfRemainingUsers ? `and ${numberOfRemainingUsers} other ${numberOfRemainingUsers > 1 ? `members` : `member`} ` : ``,
  );
  return (
    <View>
      <View style={{ flexDirection: `row`, marginTop: 8 }}>
        {members.map((user) => (
          <Avatar user={user} size={34} style={{ marginRight: 8 }} key={user.id} />
        ))}
      </View>
      <View style={{ marginTop: 8 }}>
        <ParsedText style={Typography.text(`bold`)} parse={[{ pattern: /( and )|-/gim, style: Typography.text() }]}>
          {text}
        </ParsedText>
      </View>
    </View>
  );
};

const localStyles = StyleSheet.create({
  listPrivacyText: {
    backgroundColor: Colors.badgePlusBackground,
    borderRadius: 7,
    flex: 1,
    marginHorizontal: 16,
    marginVertical: 16,
    height: 40,
    justifyContent: `center`,
    alignItems: `center`,
  },
});
