import { useState } from 'react';
import { CHECK_VALID_MENTIONS, client } from '../../graphql';
import { UserMention } from '../../common-types/types';

type CheckValidData = {
  checkValidMentions3: {
    validated_users: UserMention[];
  };
};

type CheckValidVars = {
  input: {
    mentions: string[];
    group_id: string;
  };
};

const useValidMention = (groupId: string, excludeFrom: UserMention[] = []) => {
  const [error, setError] = useState<unknown | null>(null);

  const validateMention = async (mentionsToValidate: string[]): Promise<UserMention[]> => {
    const newHandles = mentionsToValidate.filter((mention) => {
      return !excludeFrom.find((excluded) => excluded.handle === mention.trim()); // Verify is not repeated
    });

    if (newHandles.length === 0) return [];
    let validMentions = [] as UserMention[];

    try {
      const { data } = await client.query<CheckValidData, CheckValidVars>({
        query: CHECK_VALID_MENTIONS,
        variables: {
          input: {
            mentions: newHandles,
            group_id: groupId,
          },
        },
      });
      validMentions = data?.checkValidMentions3?.validated_users.map(({ id, handle, name }) => ({ id, handle, name })); // Remove the __typename field
    } catch (e) {
      setError(e);
    }
    return validMentions;
  };

  return { error, validateMention };
};

export { useValidMention };
