import { Colors } from '../common-styles';
import { dateUtil } from '../common-util';

const DATE_INFINITY = new Date(`January 1, 9999`);

type ActionTuple = [string, string];
type ActionMatrix = ActionTuple[];
type Action = {
  icon: string;
  color: string;
};
export type ActionVerb = keyof typeof ACTION_VERB_MAP;
export type ModerationType = keyof typeof MODERATION_TYPE;
export type ModerationCategory = keyof typeof MODERATION_CATEGORY;
export type ModerationAction =
  | `post_remove`
  | `post_recover`
  | `post_freeze`
  | `post_unfreeze`
  | `post_reject`
  | `post_approve`
  | `comment_remove`
  | `comment_recover`
  | `user_suspend`
  | `user_suspend_1`
  | `user_suspend_3`
  | `user_suspend_5`
  | `user_lift_suspension`
  | `user_ban`
  | `user_lift_ban`
  | `user_delete`
  | `community_warn`
  | `community_suspend`
  | `community_ban`
  | `community_archive`
  | `community_restore`
  | `application_approve`
  | `application_reject`
  | `application_ban`
  | `identity_verify`
  | `identity_reject`
  | `new_issue`
  | `no_action`
  | `content_report_deny`
  | `user_report_deny`
  | `report_reopen`
  | `report_escalated`;

export const MODERATION_ACTIONS: Record<ModerationAction, ModerationAction> = {
  post_remove: `post_remove`,
  post_recover: `post_recover`,
  post_freeze: `post_freeze`,
  post_unfreeze: `post_unfreeze`,
  post_reject: `post_reject`,
  post_approve: `post_approve`,
  comment_remove: `comment_remove`,
  comment_recover: `comment_recover`,
  user_suspend: `user_suspend`,
  user_suspend_1: `user_suspend_1`,
  user_suspend_3: `user_suspend_3`,
  user_suspend_5: `user_suspend_5`,
  user_lift_suspension: `user_lift_suspension`,
  user_ban: `user_ban`,
  user_lift_ban: `user_lift_ban`,
  user_delete: `user_delete`,
  community_warn: `community_warn`,
  community_suspend: `community_suspend`,
  community_ban: `community_ban`,
  community_archive: `community_archive`,
  community_restore: `community_restore`,
  application_approve: `application_approve`,
  application_reject: `application_reject`,
  application_ban: `application_ban`,
  identity_verify: `identity_verify`,
  identity_reject: `identity_reject`,

  // pending to categorize
  new_issue: `new_issue`,
  no_action: `no_action`,
  content_report_deny: `content_report_deny`,
  user_report_deny: `user_report_deny`,
  report_reopen: `report_reopen`,
  report_escalated: `report_escalated`,

  // deprecated
  // mesh_ignore_community: `mesh_ignore`,
  // mesh_warn_community: `mesh_warn`,
  // mesh_suspend_community: `mesh_suspend`,
  // mesh_ban_community: `mesh_ban_community`,
};

export const MODERATION_TYPE = {
  post: `post`,
  comment: `comment`,
  user: `user`,
  community: `community`,
  application: `application`,
  verification: `verification`,
  ad: `ad`,
  /* old types compatibility */
  community_join_request: `community_join_request`,
  application_ban: `application_ban`,
  personal_feed: `profile_feed`,
};

export const MODERATION_CATEGORY = {
  abuse: `abuse`,
  post_approve: `post_approve`,
  user_report: `user_report`,
  community_report: `community_report`,
  community_application: `community_application`,
  community_join_request: `community_join_request`,
  mesh_verification: `mesh_verification`,
};

export const getContentActions = (type: ModerationType, originalAction: ModerationAction = MODERATION_ACTIONS.new_issue) => {
  let matchedAction = -1;
  let contentActionMatrix: ActionMatrix[] = [];
  let actionsTemplate: Action[] = [];
  if (type === MODERATION_TYPE.post || type === MODERATION_TYPE.personal_feed) {
    matchedAction = [
      MODERATION_ACTIONS.new_issue, //post
      MODERATION_ACTIONS.content_report_deny, //post
      MODERATION_ACTIONS.post_freeze,
      MODERATION_ACTIONS.post_unfreeze,
      MODERATION_ACTIONS.post_remove,
      MODERATION_ACTIONS.post_recover,
    ].indexOf(originalAction);

    contentActionMatrix = [
      [
        [`Allow post`, MODERATION_ACTIONS.content_report_deny],
        [`Allow post`, MODERATION_ACTIONS.no_action],
        [`Unfreeze post`, MODERATION_ACTIONS.post_unfreeze], // prev: post_freeze
        [`Allow post`, MODERATION_ACTIONS.no_action], // prev: post_unfreeze
        [`Reinstate post`, MODERATION_ACTIONS.post_recover], // prev: post_remove
        [`Allow post`, MODERATION_ACTIONS.no_action], // prev: post_recover
      ],
      [
        [`Freeze post`, MODERATION_ACTIONS.post_freeze],
        [`Freeze post`, MODERATION_ACTIONS.post_freeze],
        [`Freeze post - No action needed`, MODERATION_ACTIONS.no_action], // prev: post_freeze
        [`Freeze Post`, MODERATION_ACTIONS.post_freeze], // prev: post_unfreeze
        [`Reinstantiate and freeze post`, MODERATION_ACTIONS.post_freeze], // prev: post_remove
        [`Freeze Post`, MODERATION_ACTIONS.post_freeze], // prev: post_recover
      ],
      [
        [`Delete post`, MODERATION_ACTIONS.post_remove],
        [`Delete post`, MODERATION_ACTIONS.post_remove],
        [`Delete post`, MODERATION_ACTIONS.post_remove], // prev: post_freeze
        [`Delete post`, MODERATION_ACTIONS.post_remove], // prev: post_unfreeze
        [`Delete post - No action needed`, MODERATION_ACTIONS.no_action], // prev: post_remove
        [`Delete post`, MODERATION_ACTIONS.post_remove], // prev: post_recover
      ],
    ];
    actionsTemplate = [
      {
        icon: `check`,
        color: Colors.brandGreen,
      },
      {
        icon: `snowflake`,
        color: Colors.iconColor,
      },
      {
        icon: `block`,
        color: Colors.brightRed,
      },
    ];
  } else if (type === MODERATION_TYPE.comment) {
    matchedAction = [
      MODERATION_ACTIONS.new_issue, //comment
      MODERATION_ACTIONS.content_report_deny, //comment
      MODERATION_ACTIONS.comment_remove,
      MODERATION_ACTIONS.comment_recover,
    ].indexOf(originalAction);

    contentActionMatrix = [
      [
        [`Allow comment`, MODERATION_ACTIONS.content_report_deny],
        [`Allow comment`, MODERATION_ACTIONS.no_action],
        [`Reinstate comment`, MODERATION_ACTIONS.comment_recover], // prev: comment_remove
        [`Allow comment`, MODERATION_ACTIONS.no_action], // prev: comment_recover
      ],
      [
        [`Delete comment`, MODERATION_ACTIONS.comment_remove],
        [`Delete comment`, MODERATION_ACTIONS.comment_remove],
        [`Delete comment - No action needed`, MODERATION_ACTIONS.no_action], // prev: comment_remove
        [`Delete comment`, MODERATION_ACTIONS.comment_remove], // prev: comment_recover
      ],
    ];
    actionsTemplate = [
      {
        icon: `check`,
        color: Colors.brandGreen,
      },
      {
        icon: `block`,
        color: Colors.brightRed,
      },
    ];
  } else if (type === MODERATION_TYPE.community) {
    matchedAction = [
      MODERATION_ACTIONS.new_issue,
      MODERATION_ACTIONS.content_report_deny,
      MODERATION_ACTIONS.community_restore,
      MODERATION_ACTIONS.community_suspend,
      MODERATION_ACTIONS.community_archive,
    ].indexOf(originalAction);

    contentActionMatrix = [
      [
        [`Allow community`, MODERATION_ACTIONS.content_report_deny],
        [`Allow community`, MODERATION_ACTIONS.no_action],
        [`Allow community - no action need`, MODERATION_ACTIONS.no_action], // prev: community_restore
        [`Restore community`, MODERATION_ACTIONS.community_restore], // prev: comment_suspend
        [`Restore community`, MODERATION_ACTIONS.community_restore], // prev: community_archive
      ],
      [
        [`Suspend community`, MODERATION_ACTIONS.community_suspend],
        [`Suspend community`, MODERATION_ACTIONS.community_suspend],
        [`Suspend community`, MODERATION_ACTIONS.community_suspend], // prev: community_restore
        [`Suspend community - no action need`, MODERATION_ACTIONS.no_action], // prev: comment_suspend
        [`Restore and suspend community`, MODERATION_ACTIONS.community_suspend], // prev: community_archive
      ],
      [
        [`Archive community`, MODERATION_ACTIONS.community_archive],
        [`Archive community`, MODERATION_ACTIONS.community_archive],
        [`Archive community`, MODERATION_ACTIONS.community_archive], // prev: community_restore
        [`Archive community`, MODERATION_ACTIONS.community_archive], // prev: community_archive
        [`Archive community - no action need`, MODERATION_ACTIONS.community_archive], // prev: community_archive
      ],
    ];

    actionsTemplate = [
      {
        icon: `check`,
        color: Colors.brandGreen,
      },
      {
        icon: `snowflake`,
        color: Colors.iconColor,
      },
      {
        icon: `block`,
        color: Colors.brightRed,
      },
    ];
  }

  if (matchedAction === -1) matchedAction = 0;
  const actionList = actionsTemplate.map((action, index) => {
    const [title, mod_action] = contentActionMatrix[index][matchedAction];
    return {
      ...action,
      title,
      action: mod_action,
    };
  });
  return actionList;
};

export const getUserActions = (
  fromDate: Date,
  group_id: string,
  originalAction: ModerationAction = MODERATION_ACTIONS.new_issue,
) => {
  const getSuspensionDate = (fromDate: Date, days: number) => {
    if (days >= 0) {
      let fromTimestamp = Date.now();
      if (!Number.isNaN(fromDate.getTime())) {
        fromTimestamp = fromDate.getTime();
      }
      const suspendDate = new Date(fromTimestamp + days * 24 * 60 * 60 * 1000);
      //console.log(`TO: ${suspendDate} or ${suspendDate.toISOString()}`);
      return suspendDate;
    }
    return DATE_INFINITY;
  };

  let matchedAction = [
    MODERATION_ACTIONS.new_issue,
    MODERATION_ACTIONS.user_report_deny,
    MODERATION_ACTIONS.user_lift_suspension,
    MODERATION_ACTIONS.user_suspend_1,
    MODERATION_ACTIONS.user_suspend_3,
    MODERATION_ACTIONS.user_suspend_5,
    MODERATION_ACTIONS.user_ban,
    MODERATION_ACTIONS.user_delete,
  ].indexOf(originalAction);
  const userActionMatrix = [
    [
      // titles for first action matching with originalAction position
      [`Take no action`, MODERATION_ACTIONS.user_report_deny],
      [`Take no action`, MODERATION_ACTIONS.no_action],
      [`Take no action`, MODERATION_ACTIONS.no_action],
      [`Unmute user`, MODERATION_ACTIONS.user_lift_suspension],
      [`Unmute user`, MODERATION_ACTIONS.user_lift_suspension],
      [`Unmute user`, MODERATION_ACTIONS.user_lift_suspension],
      [`Unban user`, MODERATION_ACTIONS.user_lift_ban],
    ],
    [
      // titles for second action matching with originalAction position
      [`Mute user for 1 day`, MODERATION_ACTIONS.user_suspend_1],
      [`Mute user for 1 day`, MODERATION_ACTIONS.user_suspend_1],
      [`Mute user for 1 day`, MODERATION_ACTIONS.user_suspend_1],
      [`Mute user for 1 day - no action needed`, MODERATION_ACTIONS.no_action],
      [`Reduce mute period to 1 day`, MODERATION_ACTIONS.user_suspend_1],
      [`Reduce mute period to 1 day`, MODERATION_ACTIONS.user_suspend_1],
      [`Unban user and mute for 1 day`, MODERATION_ACTIONS.user_suspend_1],
    ],
    [
      // titles for third action matching with originalAction position
      [`Mute user for 3 days`, MODERATION_ACTIONS.user_suspend_3],
      [`Mute user for 3 days`, MODERATION_ACTIONS.user_suspend_3],
      [`Mute user for 3 days`, MODERATION_ACTIONS.user_suspend_3],
      [`Extend mute period to 3 days`, MODERATION_ACTIONS.user_suspend_3],
      [`Mute user for 1 days - no action needed`, MODERATION_ACTIONS.no_action],
      [`Reduce mute period to 3 days`, MODERATION_ACTIONS.user_suspend_3],
      [`Unban user and mute for 3 days`, MODERATION_ACTIONS.user_suspend_3],
    ],
    [
      // titles for fourth action matching with originalAction position
      [`Mute user for 5 days`, MODERATION_ACTIONS.user_suspend_5],
      [`Mute user for 5 days`, MODERATION_ACTIONS.user_suspend_5],
      [`Mute user for 5 days`, MODERATION_ACTIONS.user_suspend_5],
      [`Extend mute period to 5 days`, MODERATION_ACTIONS.user_suspend_5],
      [`Extend mute period to 5 days`, MODERATION_ACTIONS.user_suspend_5],
      [`Mute user for 5 days - no action needed`, MODERATION_ACTIONS.no_action],
      [`Unban user and mute for 5 days`, MODERATION_ACTIONS.user_suspend_5],
    ],
    [
      // titles for fifth action matching with originalAction position
      [`Permanently ban user`, MODERATION_ACTIONS.user_ban],
      [`Permanently ban user`, MODERATION_ACTIONS.user_ban],
      [`Permanently ban user`, MODERATION_ACTIONS.user_ban],
      [`Permanently ban user`, MODERATION_ACTIONS.user_ban],
      [`Permanently ban user`, MODERATION_ACTIONS.user_ban],
      [`Permanently ban user`, MODERATION_ACTIONS.user_ban],
      [`Permanently ban user - no action needed`, MODERATION_ACTIONS.no_action],
    ],
    [
      // titles for fifth action matching with originalAction position
      [`Delete the user`, MODERATION_ACTIONS.user_delete],
      [`Delete the user`, MODERATION_ACTIONS.user_delete],
      [`Delete the user`, MODERATION_ACTIONS.user_delete],
      [`Delete the user`, MODERATION_ACTIONS.user_delete],
      [`Delete the user`, MODERATION_ACTIONS.user_delete],
      [`Delete the user`, MODERATION_ACTIONS.user_delete],
      [`Delete the user - no action needed`, MODERATION_ACTIONS.user_delete],
    ],
  ];
  const suspendDate1 = getSuspensionDate(fromDate, 1);
  const suspendDate3 = getSuspensionDate(fromDate, 3);
  const suspendDate5 = getSuspensionDate(fromDate, 5);

  const actionsTemplate = [
    {
      icon: `check`,
      color: Colors.brandGreen,
      actionExpiration: null,
    },
    {
      icon: `snowflake`,
      color: Colors.iconColor,
      subtitle: `Muted until ${dateUtil.formatDate(suspendDate1, `MMM dd h:mm TT`)}`,
      actionExpiration: suspendDate1.toISOString(),
    },
    {
      icon: `snowflake`,
      color: Colors.iconColor,
      subtitle: `Muted until ${dateUtil.formatDate(suspendDate3, `MMM dd h:mm TT`)}`,
      actionExpiration: suspendDate3.toISOString(),
    },
    {
      icon: `snowflake`,
      color: Colors.iconColor,
      subtitle: `Muted until ${dateUtil.formatDate(suspendDate5, `MMM dd h:mm TT`)}`,
      actionExpiration: suspendDate5.toISOString(),
    },
    {
      icon: `block`,
      color: Colors.brightRed,
      actionExpiration: DATE_INFINITY.toISOString(),
    },
    {
      icon: `close`,
      color: Colors.brightRed,
      actionExpiration: DATE_INFINITY.toISOString(),
    },
  ];
  // range checks
  if (matchedAction === -1) matchedAction = 0;
  return actionsTemplate.map((action, index) => {
    //Delete user is only an option for mesh moderation node
    if (index === 5 && group_id !== `6285531e-7ff2-4e55-ba98-86d133227f6f`) return {};
    const [title, mod_action] = userActionMatrix[index][matchedAction];
    console.log(`TITLE AND MATCHED ACTION`, title, `mod_action`, mod_action, `matchedAction`, matchedAction, `index`, index);
    return {
      ...action,
      title,
      action: mod_action,
    };
  });
};

export const ACTION_VERB_MAP = {
  // old verbs
  deny_report: `denied`,
  remove_post: `removed`,
  remove_comment: `removed`,
  freeze_post: `froze`,
  unfreeze_post: `unfroze`,
  reject_post: `rejected`, // this is to be used for post_approvals
  approve_post: `approved`,
  reopen_issue: `reopened`,
  user_full_ban: `permanently banned`,
  user_shadow_ban: `shadow banned`,
  user_temp_ban: `suspended`,
  user_freeze_account: `froze`,
  user_unfreeze_account: `unfroze`,
  mesh_ignore: `ignored`,
  mesh_warn: `warned`,
  mesh_suspend: `suspended`,
  mesh_ban_community: `banned`,
  approve_application: `approved`,
  reject_application: `rejected`,
  verify_identity: `verified`,
  reject_identity: `rejected`,
  approve_join_request: `approved`,
  reject_join_request: `rejected`,
  // new verbs with actions history
  user_report_deny: `denied`,
  content_report_deny: `allowed`,
  post_remove: `removed`,
  post_recover: `reinstated`,
  post_freeze: `froze`,
  post_unfreeze: `unfroze`,
  post_reject: `rejected`,
  post_approve: `approved`,
  comment_remove: `removed`,
  comment_recover: `reinstated`,
  user_suspend: `muted`,
  user_suspend_1: `muted`,
  user_suspend_3: `muted`,
  user_suspend_5: `muted`,
  user_lift_suspension: `lifted mute`,
  user_ban: `banned`,
  user_lift_ban: `lifted ban`,
  community_warn: `warned`,
  community_suspend: `suspended`,
  community_ban: `banned`,
  community_archive: `archived`,
  community_restore: `restored`,
  application_approve: `approved`,
  application_reject: `rejected`,
  identity_verify: `verified`,
  identity_reject: `rejected`,
  user_delete: `deleted`,
};
