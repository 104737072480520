import { combineReducers, createStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { contributionsReducer } from './contributionSlice';
import { communitySliceReducer } from './createCommunitySlice';
import { feedReducer } from './feedSlice';
import { profileReducer } from './createProfileSlice';
import { applicationReducer } from './applicationSlice';
import { applicationWizardReducer } from './applicationWizardSlice';
import { postReducer } from './postSlice';
import { guidelinesReducer } from './guidelinesSlice';
import { guestReducer } from './guestSlice';
import { followReducer } from './followSlice';
import { inboxReducer } from './inboxSlice';
import { exploreReducer } from './exploreSlice';

// export const store = configureStore({
//   reducer: {
//     createProfile: profileReducer,
//     createCommunity: communitySliceReducer,
//     contributions: contributionsReducer,
//     feed: feedReducer,
//     applicationForm: applicationReducer,
//     applicationWizard: applicationWizardReducer,
//     post: postReducer,
//     guidelines: guidelinesReducer,
//     guest: guestReducer,
//   },
//   middleware: [...getDefaultMiddleware({ thunk: false, serializableCheck: false })],
// });

/************ Temporary updates for configuring Redux *********/
// This is updated because catching `LOG_OUT` to initialize the redux store.
// This combined codebase is needed to update if there is a initializing way in `ConfigureStore` directly.
// As long as we dont use middleware, then we can use this way.

const appReducer = combineReducers({
  createProfile: profileReducer,
  createCommunity: communitySliceReducer,
  contributions: contributionsReducer,
  feed: feedReducer,
  applicationForm: applicationReducer,
  applicationWizard: applicationWizardReducer,
  post: postReducer,
  guidelines: guidelinesReducer,
  guest: guestReducer,
  follow: followReducer,
  explore: exploreReducer,
  inbox: inboxReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === `LOG_OUT`) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = createStore(rootReducer);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
