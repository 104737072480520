/* eslint-disable react/function-component-definition */
import * as React from 'react';
import Svg, { G, Rect, Text, TSpan, Path } from 'react-native-svg';

export const ExploreHumor = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-238 -1151) translate(238 491) translate(0 660)">
          <Rect fill="#E5F8FA" x={0} y={0} width={163} height={98} rx={7} />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#2A98A5">
            <TSpan x={12} y={21}>
              Humor
            </TSpan>
          </Text>
          <Path
            d="M129.437 56.447c5.845 5.452 12.372 9.715 14.078 15.377 1.75 5.696-1.365 12.756-7.21 16.984-5.886 4.194-14.504 5.487-22.737 5.138-8.19-.385-15.955-2.412-20.647-6.605-4.693-4.194-6.357-10.555-7.338-17.719-.98-7.13-1.237-15.097 3.456-20.514 4.692-5.452 14.419-8.387 21.841-6.57 7.466 1.852 12.67 8.457 18.557 13.909z"
            fill="#2A98A5"
            fillRule="nonzero"
          />
        </G>
        <G fillRule="nonzero">
          <G transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(0 19.44) translate(16.8 .459)">
            <Path
              d="M1.304 41.187c-.034-4.298-.195-13.03-.23-17.164a1.356 1.356 0 011.35-1.362l3.356-.034c.754-.007 1.371.59 1.379 1.334.05 4.081.21 12.922.26 17.165.002.273-6.112.334-6.115.061z"
              fill="#543629"
            />
            <Path
              d="M.411.103C.181.105-.003.358 0 .667c.058 7.395.24 32.816.294 39.771l8.221-.083C8.428 33.7 8.087 7.307 7.993.178 8.018-.03 5.491-.056.411.103z"
              fill="#051026"
            />
            <Path fill="#FFF" d="M7.44105965 43.2825523L1.32676363 43.3431794 1.30413434 41.1870342 7.41835339 41.1263325z" />
            <Path
              d="M4.038 42.308c.104 1.236-2.533 1.263-2.454.025l-.293.003.028 2.765h8.87l-.009-.983c-.007-.714-2.415-.864-2.762-1.842-1.307.011-2.433.022-3.38.032z"
              fill="#0A1B45"
            />
          </G>
          <Path
            d="M8.899 0c0 4.222 3.571 7.644 7.978 7.644 4.406 0 7.978-3.422 7.978-7.644"
            fill="#0A1B45"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(0 19.44)"
          />
          <Path
            d="M1.02 42.918C.853 41.752.793 41.053.84 40.821c.706-3.698 1.124-5.697 1.903-9.368l1.634-7.59c.16-.74.889-1.21 1.625-1.053l3.28.703a1.36 1.36 0 011.044 1.621 1394.626 1394.626 0 00-3.217 15.137c-.214 1.04-.472 2.337-.668 3.373"
            fill="#694D3D"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(0 19.44)"
          />
          <Path
            d="M8.899 0c-.172.266-.272.512-.3.739C5.729 13.586 2.682 27.082 0 39.985l8.07 1.6c2.6-12.877 5.552-26.302 8.341-39.134.101-.305.177-.918-.21-.982L8.899 0z"
            fill="#0A1B45"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(0 19.44)"
          />
          <Path
            d="M6.395 44.26L.384 43.212c.12-.682.32-1.703.455-2.391l5.988 1.17c-.127.651-.32 1.626-.432 2.27"
            fill="#FFF"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(0 19.44)"
          />
          <Path
            d="M3.688 42.404l-.4-.077c-.148 1.228-2.733.754-2.405-.458l-.288-.055C.25 42.884.077 44.132.077 45.56h8.85c.024-.222.04-.354.048-.398-.205-.856-2.295-1.23-2.391-2.207l-2.896-.551z"
            fill="#0A1B45"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(0 19.44)"
          />
          <Path
            d="M12.81.72l-.15-.032C9.758 13.828 6.699 27.522 3.99 40.714l.15.03C6.85 27.551 9.908 13.862 12.812.721"
            fill="#FFF"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(0 19.44)"
          />
          <G>
            <Path
              d="M26.986 5.667c-1.165.44-2.678.465-3.796 1.046-2.962 1.743-5.993 3.299-9.252 4.564-1.295.517-2.774.986-4.118 1.365a39.004 39.004 0 01-3.221.713c-.958.186-2.088.383-2.861-.365-1.654-1.182-.872-4.135 1.081-4.47A24.88 24.88 0 007.576 8a33.802 33.802 0 004.535-1.429c3.972-1.414 7.611-3.877 11.407-5.726C24.952.092 27.69-.248 28.68.202c.429.079.632.773.204.97-.14.065-.677.183-1.305.375.302.137.528.327.69.505.365.398-.106 1.09-.686.924-.508-.15-1.028-.64-2.065-.472-1.14 1.008-.287 2.269 1.018 1.935.747-.086 1.067.987.449 1.228"
              fill="#694D3D"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(16.963)"
            />
            <Path
              d="M26.986 5.667c-1.165.44-2.678.465-3.796 1.046-2.962 1.743-5.993 3.299-9.252 4.564-1.295.517-2.774.986-4.118 1.365a39.004 39.004 0 01-3.221.713c-.958.186-2.088.383-2.861-.365-1.654-1.182-.872-4.135 1.081-4.47A24.88 24.88 0 007.576 8a33.802 33.802 0 004.535-1.429c3.972-1.414 7.611-3.877 11.407-5.726C24.952.092 27.69-.248 28.68.202c.429.079.632.773.204.97-.14.065-.677.183-1.305.375.302.137.528.327.69.505.365.398-.106 1.09-.686.924-.508-.15-1.028-.64-2.065-.472-1.14 1.008-.287 2.269 1.018 1.935.747-.086 1.067.987.449 1.228"
              fill="#000"
              opacity={0.12}
              transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(16.963)"
            />
            <Path
              d="M20.9 1.67c-.55.32-1.388.825-1.941 1.121C17.203 2.677 5.909 8.356.24 8.356-.702 9.77 1.327 13.04 2.86 13.96c1.075.74 2.381.539 3.62.495 3.731-.29 7.367-1.545 10.648-3.298 1.61-.94 3.478-1.954 4.644-3.365.605-.321 1.483-.857 2.08-1.201l-2.954-4.92z"
              fill="#2DB386"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(16.963)"
            />
            <Path
              d="M19.036 2.88l2.816 5c.586-.316 1.48-.853 2.06-1.19l-2.954-4.92c-.544.316-1.373.815-1.922 1.11"
              fill="#25996F"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(16.963)"
            />
            <G>
              <Path
                d="M21.334 14.7C15.767 15.088.111 13.312.288 12.323c.351-1.96.96-5.025 1.825-9.193C2.113 1.402 3.66 0 5.567 0h10.688c1.907 0 3.453 1.402 3.453 3.13a874.66 874.66 0 011.665 8.892c.123.69.11 1.582-.039 2.678z"
                fill="#34D1A1"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(3.068 8.356)"
              />
              <Path
                d="M0 14.92c7.721 2.726 16.092.148 21.822 0l-.368-2.972c-5.612.2-13.643 2.642-21.172.063L0 14.92z"
                fill="#2DB386"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(3.068 8.356)"
              />
            </G>
            <G>
              <Path
                d="M13.622 17.308c-3.804-.097-7.529-2.905-7.288-6.91.056-1.843.729-3.545 1.743-5.163.677-1.066.331-2.596-.637-3.395-3.883-2.784-6.157 5.685-6.168 8.43-.058 1.932.376 3.932 1.291 5.653 1.82 3.524 5.542 5.736 9.368 6.272.874.181 7.172.676 4.991-1.262a.674.674 0 00.38-.718.697.697 0 00-.403-.514c.47-.226.451-.962.01-1.203.327-.052.702-.125 1.025-.2.473-.04.945-.363.78-.907-.375-1.046-1.63-.155-2.397-.194-.175-1.032-1.42-.425-2.114-.552-.294-.009-.553.287-.581.663"
                fill="#694D3D"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(0 8.356)"
              />
              <Path
                d="M11.862 22.649a14.91 14.91 0 01-2.811-.7c-2.443.048-4.864-1.768-6.457-3.658C.038 15.199-.618 10.745.577 6.967 1.12 5.08 2.284 3.007 3.497 1.6 4.71.193 6.811 0 9.507 0c2.697 0 .262 3.13 0 4.4-.321 1.014-.956 1.94-1.252 2.96-1.412 3.997-.43 6.567 2.732 9.19.549.2 1.135.338 1.74.43l-.865 5.669z"
                fill="#34D1A1"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(0 8.356)"
              />
              <Path
                d="M9.12 21.852l1.923-5.404c.536.194 1.11.328 1.7.42l-.864 5.667a14.83 14.83 0 01-2.76-.683"
                fill="#2DB386"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(2.915) translate(0 8.356)"
              />
            </G>
          </G>
          <G>
            <G fill="#694D3D">
              <Path
                d="M.997 4.74H4.45v3.946c0 1.063-.773 1.925-1.726 1.925S.997 9.75.997 8.686V4.74z"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(14.038 .176)"
              />
              <Path
                d="M4.607 7.186h-.383A3.221 3.221 0 01.997 3.97V2.462A2.466 2.466 0 013.467 0h.43a2.466 2.466 0 012.47 2.462v2.97c0 .968-.788 1.754-1.76 1.754"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(14.038 .176)"
              />
              <Path
                d="M1.918 4.472c0 .528-.43.955-.96.955A.957.957 0 010 4.472c0-.528.43-.956.959-.956.53 0 .959.428.959.956"
                transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(14.038 .176)"
              />
            </G>
            <Path
              d="M.772 4.064c.07 0 .166.016.264.065a.49.49 0 01.276.45v.024h-.23c0-.14-.053-.221-.149-.268a.405.405 0 00-.156-.041H.772v-.23z"
              fill="#000"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(14.038 .176)"
            />
            <Path
              d="M2.666 7.364c-.713-.251-1.069-.886-1.21-1.74a3.227 3.227 0 002.728 1.561h.265c-.609.324-1.203.383-1.783.179z"
              fill="#000"
              opacity={0.12}
              transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(14.038 .176)"
            />
            <Path
              d="M3.898 0c1.067 0 1.976.668 2.327 1.605h-4.28l-.633 1.911H.985V2.462C.985 1.102 2.096 0 3.467 0h.43z"
              fill="#000"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(14.038 .176)"
            />
          </G>
          <G
            transform="translate(-238 -1151) translate(238 491) translate(0 660) matrix(-1 0 0 1 146 24) translate(16.531 2.431)"
            fill="#000"
          >
            <Path d="M1.95394665 0.984776857L2.18023494 0.943023799 2.50904439 2.71261033 1.75165283 2.71261033 1.75165283 2.48328134 2.23237353 2.48328134z" />
            <Path d="M1.95394665 0.984776857L2.18023494 0.943023799 2.50904439 2.71261033 1.75165283 2.71261033 1.75165283 2.48328134 2.23237353 2.48328134z" />
            <Path d="M1.291 0v.23A1.695 1.695 0 00.237.595L.204.623a.775.775 0 00-.037.032L.163.66 0 .497C.065.432.184.34.351.247.618.098.92.006 1.25 0h.041zM2.217.026c.306 0 .584.082.83.218.139.077.24.154.3.211l.01.01-.162.163-.016-.015a1.467 1.467 0 00-.244-.17 1.473 1.473 0 00-.68-.187h-.038v-.23zM.34 1.294c.192-.476 1.02-.482 1.137.002l.004.018-.226.045c-.052-.258-.568-.262-.694.006l-.007.014-.213-.085zM2.259 1.294c.19-.476 1.018-.482 1.136.002l.004.018-.226.045c-.052-.258-.569-.262-.694.006l-.007.014-.213-.085z" />
            <Path d="M1.95394665 0.984776857L2.18023494 0.943023799 2.50904439 2.71261033 1.75165283 2.71261033 1.75165283 2.48328134 2.23237353 2.48324312z" />
            <Path d="M2.595 3.86h-.984a.621.621 0 01-.626-.616V3.17h1.61c-.11.142-.11.444 0 .688z" />
            <Path d="M1.97130742 0.985821581L2.19759382 0.944058325 2.5264873 2.71364996 1.76901266 2.71364996 1.76901266 2.48432096 2.24978706 2.4842751z" />
          </G>
        </G>
        <G fillRule="nonzero">
          <Path
            d="M5.579 45.06l-5.057-.38a.341.341 0 01-.187-.076.556.556 0 01-.142-.179.942.942 0 01-.079-.544c.01-.08.023-.151.036-.224l.04-.217.04-.216.02-.108.024-.102c.062-.27.125-.547.198-.797.036-.128.07-.258.108-.383l.115-.365.059-.183.06-.176.123-.35c.332-.919.696-1.756 1.05-2.545l.504-1.111c.076-.171.144-.318.216-.502l.214-.527.43-1.053c.074-.173.135-.357.201-.536l.196-.54c1.192-3.278 2.13-6.72 2.895-10.219.16-.773.883-1.28 1.62-1.134l3.274.662c.735.147 1.197.931 1.027 1.752-.81 3.707-1.809 7.39-3.123 11.007l-.217.596c-.073.198-.14.398-.223.594l-.481 1.182c-.162.393-.315.786-.499 1.187l-.508 1.12a30.376 30.376 0 00-.875 2.114c-.058.17-.124.343-.174.51l-.08.252c-.025.083-.046.164-.07.247-.05.166-.081.324-.12.483-.022.081-.03.155-.046.232-.012.075-.032.155-.039.226-.01.096-.077.175-.174.23a.544.544 0 01-.163.06.695.695 0 01-.193.013"
            fill="#FFDBB4"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(4.576 20.635)"
          />
          <Path
            d="M21.16 39.092l-1.841-4.713c-.099-.254-.022-.52.185-.607a23.34 23.34 0 001.893-.902 25.051 25.051 0 001.83-1.074 21.021 21.021 0 003.244-2.56c1.183-1.15 2.161-2.418 2.86-3.773a11.723 11.723 0 001.27-4.311 1.27 1.27 0 01.142-.466 1.47 1.47 0 011.275-.756l3.34-.006c.378-.001.713.189.953.515.123.163.21.36.267.578.06.218.076.459.054.708l-.02.211-.026.212-.053.422c-.047.28-.084.563-.143.841a17.429 17.429 0 01-.963 3.274 19.8 19.8 0 01-.71 1.548c-1.042 2.016-2.44 3.8-4.017 5.331a27.058 27.058 0 01-4.179 3.304c-.74.479-1.5.924-2.276 1.337-.778.412-1.57.793-2.396 1.14-.276.116-.59 0-.688-.253"
            fill="#DCB284"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(4.576 20.635)"
          />
          <Path
            d="M16.878 5.04c.725.346 1.454.7 2.14 1.046l.494.253.115.061.108.058.1.054.045.025.033.019.069.039.113.068.227.136c.15.093.302.185.451.282 1.201.762 2.35 1.617 3.399 2.533a21.619 21.619 0 012.794 2.93c.397.516.758 1.042 1.08 1.575l.235.4.213.405.054.101.048.102.095.204.097.203.086.205.085.204c.03.068.059.136.081.206l.15.41c.045.138.083.276.127.414.048.137.077.276.112.414.08.276.12.555.18.832.032.28.085.558.098.84.033.28.028.564.04.847 0 .144-.007.295-.007.442l-.024.44a11.004 11.004 0 01-1.574 4.896 14.329 14.329 0 01-2.33 2.877c-1.172 1.134-2.57 2.153-4.079 3.024l4.113 7.055c2.014-1.179 3.927-2.575 5.651-4.26a22.42 22.42 0 003.616-4.54c.506-.848.95-1.742 1.328-2.667.378-.925.676-1.886.901-2.861.224-.976.37-1.967.439-2.957.012-.247.027-.494.036-.741.003-.248.011-.492.008-.742-.014-.501-.021-1.003-.075-1.504-.034-.502-.117-.998-.189-1.496-.098-.492-.187-.987-.32-1.47-.063-.242-.123-.485-.2-.722-.076-.238-.148-.477-.227-.713l-.257-.698a5.842 5.842 0 00-.138-.345l-.145-.34-.146-.34-.158-.333-.16-.332-.08-.165-.086-.162-.345-.644-.369-.627a24.991 24.991 0 00-1.635-2.357A29.607 29.607 0 0029.43 3.6 35.907 35.907 0 0025.04.358c-.192-.122-.387-.24-.58-.358-5.268 3.258-7.796 4.938-7.582 5.04z"
            fill="#3E36B9"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(4.576 20.635)"
          />
          <Path
            d="M23.69 38.493l-1.508-2.968c-.015.01-.03.02-.046.027-.207.104-.457.074-.687-.06-.23-.135-.441-.374-.583-.678a1.66 1.66 0 01-.16-.877c.031-.258.142-.464.33-.559.015-.007.03-.012.046-.018l-.132-.258a24.015 24.015 0 01-1.57.721l-.248.1-.179.067 2.874 8.291.198-.07.145-.055.27-.104c.173-.07.34-.137.504-.206.395-.167.39-.822.381-1.523-.007-.702-.022-1.45.364-1.83M25.844 38.65l-4.12-7.071c.62-.36 1.221-.748 1.797-1.156l4.761 6.66a31.49 31.49 0 01-2.438 1.568"
            fill="#D7DBE2"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(4.576 20.635)"
          />
          <Path
            d="M8.542.015c-.011.37.007.775-.014 1.145l-.103 2.225-.144 2.223c-.053.74-.12 1.48-.18 2.221-.264 2.96-.62 5.91-1.074 8.835l-.177 1.095-.088.548-.044.273-.05.273-.4 2.176-.447 2.16c-.07.362-.16.715-.242 1.073L5.33 25.33c-.685 2.84-1.492 5.631-2.47 8.292-.316.919-.698 1.782-1.054 2.666-.045.115-.09.207-.133.308l-.147.323-.327.714a51.711 51.711 0 00-.698 1.589l7.567 3.105c.176-.432.371-.875.579-1.337l.322-.714.17-.382c.058-.133.12-.27.17-.396.412-1.03.852-2.056 1.205-3.095 1.1-3.045 1.967-6.122 2.696-9.213l.263-1.16c.086-.387.181-.773.255-1.16l.468-2.327.416-2.33.052-.292.045-.292.092-.584.182-1.167c.467-3.116.827-6.24 1.09-9.371.058-.783.125-1.566.177-2.35l.14-2.353.097-2.356c.02-.392.023-.786.033-1.179l.03-.21L8.542.016z"
            fill="#694FD9"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(4.576 20.635)"
          />
          <Path
            d="M3.299 42.267c-.005.02-.008.039-.014.058a.795.795 0 01-.202.323c-.09.093-.204.169-.334.226-.13.056-.277.093-.432.106a1.426 1.426 0 01-.48-.036 1.306 1.306 0 01-.44-.2 1.156 1.156 0 01-.314-.322 1.063 1.063 0 01-.157-.41.937.937 0 01.034-.428c.007-.022.016-.043.024-.064l-.276-.089-.128.405c-.041.138-.08.282-.12.423l-.058.213-.054.224c-.035.15-.07.298-.104.451A13.24 13.24 0 000 45.331l8.792.034a.244.244 0 00.005-.08c.009-.046.016-.095.019-.143.013-.071-.12-.163-.337-.272l-.79-.41c-.146-.078-.297-.159-.434-.25a3.6 3.6 0 01-.382-.282 1.523 1.523 0 01-.281-.308.723.723 0 01-.12-.33L3.3 42.268zM8.073 42.336l-7.584-3.11c.096-.227.192-.461.288-.678l.143-.33.143-.319.534-1.179 7.483 3.345-.534 1.178c-.17.376-.325.736-.473 1.093"
            fill="#D7DBE2"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(4.576 20.635)"
          />
          <Path
            d="M16.952 7.548h-.763c-4.17 0-7.55-3.373-7.55-7.533h15.863c0 4.16-3.38 7.533-7.55 7.533"
            fill="#694FD9"
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(4.576 20.635)"
          />
          <G>
            <Path
              d="M50.292 6.343c.283.2.516.452.674.74a2.092 2.092 0 01.258.968c.023.7-.305 1.471-.94 2.034-1.39 1.23-2.979 2.148-4.626 2.81a19.575 19.575 0 01-5.18 1.261 20.724 20.724 0 01-5.288-.127 18.158 18.158 0 01-5.151-1.585 15.193 15.193 0 01-2.388-1.453 12.888 12.888 0 01-2.1-1.965 3.044 3.044 0 01-.61-1.12 2.52 2.52 0 01-.071-1.136 2.04 2.04 0 011.208-1.542c.315-.143.645-.215.97-.242.324-.026.631-.002.927.049.288.055.56.14.795.261.238.12.442.277.615.478.375.435.802.843 1.288 1.2.484.361 1.015.682 1.58.96 1.135.55 2.4.932 3.71 1.133 1.31.2 2.663.234 3.99.095 1.327-.14 2.63-.448 3.833-.928 1.18-.475 2.263-1.113 3.157-1.905.099-.093.212-.166.326-.232.112-.067.238-.118.366-.16.126-.045.26-.076.402-.096.138-.023.28-.036.43-.036a3.274 3.274 0 01.92.12c.314.087.62.218.905.418"
              fill="#DCB284"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M52.672 5.946c.17.087.28.246.315.438a.935.935 0 01-.044.465 1.028 1.028 0 01-.079.153 13.06 13.06 0 01-1.872 2.41c-.71.718-1.486 1.36-2.31 1.903a.843.843 0 01-.593.14.627.627 0 01-.45-.294.662.662 0 01-.058-.53.832.832 0 01.34-.455 12.987 12.987 0 002.09-1.722 11.684 11.684 0 001.678-2.159.87.87 0 01.092-.123.812.812 0 01.352-.248.677.677 0 01.539.022"
              fill="#DCB284"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M51.96 4.584a.635.635 0 01.36.39.74.74 0 01-.047.574 11.704 11.704 0 01-1.332 2.09.788.788 0 01-.5.284.648.648 0 01-.509-.163.687.687 0 01-.222-.475.696.696 0 01.157-.5c.456-.572.851-1.191 1.177-1.845a.749.749 0 01.388-.355.701.701 0 01.528 0"
              fill="#DCB284"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M50.89 3.984c.177.07.307.201.373.362a.629.629 0 01-.015.523c-.321.668-.714 1.301-1.168 1.887a.684.684 0 01-.095.103.69.69 0 01-.366.146.662.662 0 01-.493-.17.757.757 0 01-.247-.457.633.633 0 01.011-.243.548.548 0 01.042-.112.539.539 0 01.062-.105 9.16 9.16 0 001.015-1.64.62.62 0 01.36-.309.763.763 0 01.522.015M48.135 5.034a.748.748 0 01.292.417.478.478 0 01-.094.435 9.36 9.36 0 01-1.328 1.287.463.463 0 01-.27.071 1.178 1.178 0 01-.338-.042 2.678 2.678 0 01-.407-.132c.297-.214-.189-.691.286-1.074.371-.307.709-.64 1.009-.994.085-.102.223-.144.373-.141a.87.87 0 01.477.173"
              fill="#DCB284"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M49.556 3.991c.171.08.298.21.362.363a.593.593 0 01.047.238.542.542 0 01-.067.232 9.637 9.637 0 01-2.147 2.62.559.559 0 01-.463.122.716.716 0 01-.437-.275.785.785 0 01-.167-.483c.003-.157.053-.306.169-.4a8.264 8.264 0 001.844-2.247.416.416 0 01.146-.152.554.554 0 01.205-.084.827.827 0 01.508.066"
              fill="#DCB284"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M48.463 11.998c-.804.494-1.64.913-2.493 1.263-.286.422-1.403 1.101-3.08 1.604-.838.25-1.813.455-2.878.573a18.11 18.11 0 01-1.659.105 19.104 19.104 0 01-3.563-.271 19.185 19.185 0 01-1.82-.41 18.355 18.355 0 01-1.787-.603 17.148 17.148 0 01-1.705-.795 15.998 15.998 0 01-1.568-.972 14.416 14.416 0 01-1.38-1.119 12.038 12.038 0 01-2.022-2.426c-.1-.125-.183-.261-.265-.394a5.562 5.562 0 01-.116-.202c-.036-.068-.068-.139-.1-.208a5.096 5.096 0 01-.166-.419 4.93 4.93 0 01-.15-.632 4.275 4.275 0 01-.036-.615c.008-.266.051-.522.114-.757.141-.478-1.209 2.923.709-1.187 1.917-4.11 6.97 2.35 13.24 3.519a21.52 21.52 0 002.102.107c.657 0 1.26-.03 1.788-.066l.737-.056c.224-.023.432-.035.614-.052.091-.007.179-.01.259-.015.08-.005.154-.008.223-.009a2.364 2.364 0 01.336.021c.583-.24 1.14-.52 1.66-.839l3.006 4.855z"
              fill="#CC2935"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M45.997 13.25l-2.225-5.381c.571-.236 1.116-.51 1.625-.823l3.066 4.952c-.795.489-1.622.903-2.466 1.251"
              fill="#B52935"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M6.859 29.409c.912.738 1.73.61 2.227.412.25-.1.35-.347.319-.579a.584.584 0 00-.485-.504c-.286-.054-.636-.014-1.03-.093-.395-.079-.843-.274-1.313-.808-.236-.267-.389-.354-.479-.317-.089.036-.114.196-.077.413.07.436.383 1.106.838 1.476"
              fill="#DCB284"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M6.188 27.01a2.392 2.392 0 01-1.932.032 2.556 2.556 0 01-1.388-1.474l-.098-.259-.092-.262c-.06-.175-.124-.349-.183-.525-.115-.355-.233-.707-.336-1.07a24.56 24.56 0 01-.648-2.719 18.249 18.249 0 01-.279-2.905c-.01-1 .074-2.04.32-3.093.069-.263.14-.526.215-.788l.132-.393.149-.385c.207-.51.453-.999.729-1.462a12.547 12.547 0 011.94-2.463 16.431 16.431 0 012.294-1.885c1.597-1.093 3.278-1.909 4.985-2.571.79-.306 1.608-.28 2.256-.004.651.275 1.13.797 1.314 1.465.185.67.057 1.354-.262 1.928a2.904 2.904 0 01-1.491 1.294c-1.42.549-2.776 1.219-3.948 2.024-1.172.803-2.15 1.748-2.76 2.776a5.84 5.84 0 00-.393.785l-.077.2-.069.204a16.94 16.94 0 01-.114.423 8.012 8.012 0 00-.186 1.894c.007.673.08 1.376.204 2.09.123.715.305 1.441.518 2.167.083.297.184.594.28.89.049.149.103.297.155.445l.078.223.083.222c.22.599.189 1.24-.06 1.82a2.646 2.646 0 01-1.336 1.376"
              fill="#FFDBB4"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M7.51 23.617c-.127-.36-1.074-.096-2.104.35-.515.224-1.05.495-1.513.758-.463.261-.854.514-1.083.69l.182.48c.277.712.573 1.336.886 1.876.308.542.627 1.004.94 1.4.631.792 1.244 1.318 1.782 1.675.54.355 1.005.54 1.36.644.355.104.6.125.705.149.211.048.425-.078.556-.243.13-.169.172-.387.024-.556-.138-.156-.953-.737-1.596-1.495-.645-.752-1.11-1.669-.609-2.31.301-.388.62-.465.922-.408.299.06.587.252.825.424.287.204.638.117.869-.074.23-.192.344-.486.16-.713a7.228 7.228 0 00-.58-.61c-.217-.215-.463-.439-.69-.669-.463-.458-.876-.94-1.036-1.368"
              fill="#FFDBB4"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M2.586 26.116c-.304-.78-.578-1.58-.826-2.4a.873.873 0 01-.152-.149 2.77 2.77 0 01-.17-.239 5.872 5.872 0 01-.363-.695 6.327 6.327 0 01-.186-.452 18.604 18.604 0 01-.187-.519c-.113-.375-.24-.78-.339-1.243A14.519 14.519 0 010 17.145c.008-.634.049-1.307.17-2.017.03-.178.062-.357.096-.536.014-.09.041-.182.064-.273l.07-.276c.05-.184.095-.368.155-.554.062-.185.122-.373.194-.556.552-1.479 1.436-2.827 2.438-3.915A14.437 14.437 0 014.76 7.54c.54-.439 1.09-.827 1.637-1.172a20.018 20.018 0 013.183-1.62 21.268 21.268 0 012.765-.904c1.085-.393 3.158-.793 4.022-.391.867.4 2.655 4.087-1.734 6.686-.792.402-1.599.834-2.373 1.294-.773.462-1.51.955-2.153 1.469-.642.513-1.183 1.044-1.586 1.559-.201.258-.377.51-.516.76-.034.063-.072.125-.102.187l-.048.093-.043.094c-.06.123-.11.246-.154.381-.194.522-.318 1.146-.38 1.782-.06.637-.07 1.277-.058 1.858.017.583.039 1.104.055 1.528.01.212.004.399.003.558-.001.08-.005.152-.01.217a.999.999 0 01-.028.159c.2.66.426 1.322.683 1.981l-5.336 2.056z"
              fill="#FA3239"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M1.768 23.744l5.587-1.677c.198.65.42 1.303.674 1.952l-5.443 2.097a33.29 33.29 0 01-.818-2.372"
              fill="#CC2935"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M29.406 18.555H13.048V6.376a3.237 3.237 0 013.241-3.233h9.876c1.79 0 3.241 1.448 3.241 3.233v12.179z"
              fill="#D7DBE2"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M24.183 3.143c0 1.26-.61 2.858-2.63 2.858-2.403 0-3.395-1.364-3.395-2.858h6.025z"
              fill="#FFDBB4"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M24.311 19.336c2.163-.336 4.212-.726 6.174-.781l-.27-3.067c-1.836.08-3.754.435-5.772.751-.045 1.163-.091 2.22-.132 3.097M12.099 15.575l-.261 2.98c2.037.791 5.428 1.15 7.285 1.253a27.6 27.6 0 00.345-3.062c-1.884-.073-5.303-.4-7.369-1.17"
              fill="#CC2935"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M15.212 3.28a3.235 3.235 0 00-2.306 3.096l-.807 9.2c2.066.77 5.485 1.097 7.37 1.17.232-3.645.52-10.974-1.692-12.115 0 0-1.602-1.674-2.565-1.35M24.443 16.24c2.018-.317 3.936-.672 5.773-.752l-.799-9.112a3.236 3.236 0 00-2.775-3.199c-.204.789-.941 1.036-2 1.53.049 3.379-.068 8.132-.2 11.532"
              fill="#FA3239"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
            <Path
              d="M17.32.066c-3.128.38-3.425 3.026-2.108 3.29 1.04.21 1.707 1.428 2.619 1.896a.295.295 0 00.427-.283l-.014-.193c-.05-.705.056-1.607.765-1.602 1.27.01 3.174.008 4.396-.003.663-.005 1.21.596 1.226 1.258l.007.329a.295.295 0 00.442.25c.825-.471 1.387-1.079 1.562-1.755.037-.146.058-.296.058-.448 0-1.765-6.203-3.125-9.38-2.739"
              fill="#CC2935"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(0 6.391)"
            />
          </G>
          <G>
            <Path
              d="M3.874 8.563H5.34V.573H3.874A3.87 3.87 0 000 4.44v.258a3.87 3.87 0 003.874 3.866"
              fill="#000"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
            <Path
              d="M4.286 6.28h-.701V.574h.701c2.14 0 3.875.97 3.875 3.104v1.02c0 1.582-1.735 1.582-3.875 1.582"
              fill="#000"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
            <Path
              d="M2.135 6.128h3.433v3.928c0 1.058-.769 1.916-1.716 1.916-.948 0-1.717-.858-1.717-1.916V6.128z"
              fill="#FFDBB4"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
            <Path
              d="M5.724 8.563h-.38a3.204 3.204 0 01-3.208-3.201V3.861c0-1.353 1.1-2.45 2.456-2.45h.426a2.453 2.453 0 012.456 2.45v2.956c0 .964-.783 1.746-1.75 1.746"
              fill="#FFDBB4"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
            <Path
              d="M3.794 8.775c-.709-.25-1.062-.882-1.202-1.732.554.92 1.56 1.54 2.712 1.554h.264c-.606.323-1.197.382-1.774.178z"
              fill="#000"
              opacity={0.12}
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
            <Path
              d="M3.05 5.862a.952.952 0 01-.953.95.952.952 0 11.954-.95"
              fill="#FFDBB4"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
            <Path
              d="M1.912 5.456c.069 0 .164.016.262.065a.487.487 0 01.275.447v.024H2.22c0-.14-.053-.22-.148-.267a.402.402 0 00-.156-.04h-.004v-.23zM2.06 4.91h-.118c-.446-.433-.618-1.315-.67-2.172C1.202 1.564 3.245.574 4.423.574h1.372l1.298 1.978H4.095c0 1.293-1.12 2.358-2.036 2.358"
              fill="#000"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
            <Path
              d="M4.367 2.552H7.06c.776 0 1.406-.628 1.406-1.403V.546A.546.546 0 007.919 0H5.512a1.85 1.85 0 00-1.851 1.847c0 .39.316.705.706.705"
              fill="#000"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(17.16)"
            />
          </G>
          <G
            transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(20.783 3.69)"
            fill="#000"
          >
            <Path d="M1.94263559 0.980164708L2.16761394 0.938607198 2.49451997 2.69990597 1.74151282 2.69990597 1.74151282 2.47165102 2.21945071 2.47165102z" />
            <Path d="M1.94263559 0.980164708L2.16761394 0.938607198 2.49451997 2.69990597 1.74151282 2.69990597 1.74151282 2.47165102 2.21945071 2.47165102z" />
            <Path d="M1.284 0v.228A1.684 1.684 0 00.236.593L.202.62a.771.771 0 00-.036.032L.162.656 0 .495C.065.43.183.337.35.245.613.098.913.007 1.242 0h.04zM2.204.026c.304 0 .581.081.825.217.138.076.239.154.298.21l.011.01-.162.162L3.161.61a1.458 1.458 0 00-.243-.168 1.463 1.463 0 00-.677-.187h-.037V.025zM.339 1.288c.19-.474 1.013-.48 1.13.002l.003.018-.224.045c-.051-.257-.565-.262-.69.005l-.007.015-.212-.085zM2.245 1.288c.19-.474 1.013-.48 1.13.002l.004.018-.224.045c-.052-.257-.566-.262-.69.005l-.007.015-.213-.085z" />
            <Path d="M1.94263559 0.980164708L2.16761394 0.938607198 2.49451997 2.69990597 1.74151282 2.69990597 1.74151282 2.47165102 2.21945071 2.47161298z" />
            <Path d="M2.58 3.841h-.978a.618.618 0 01-.623-.613v-.072H2.58c-.11.141-.11.442 0 .685z" />
            <Path d="M1.95989586 0.981204538L2.18487233 0.939636879 2.5118619 2.70094072 1.75877215 2.70094072 1.75877215 2.47268578 2.23676343 2.47264013z" />
          </G>
          <G fill="#000">
            <Path
              d="M4.5 1.293c0 .715-.58 1.294-1.297 1.294a1.295 1.295 0 01-1.296-1.269v-.025H0v-.152h1.916A1.296 1.296 0 013.203 0c.665 0 1.212.499 1.288 1.141h.17A1.296 1.296 0 015.95 0c.716 0 1.296.58 1.296 1.293 0 .715-.58 1.294-1.296 1.294a1.295 1.295 0 01-1.296-1.269v-.025H4.5zM3.203.153c-.632 0-1.144.51-1.144 1.14 0 .63.512 1.142 1.144 1.142a1.143 1.143 0 100-2.283zm2.746 0c-.632 0-1.144.51-1.144 1.14 0 .63.512 1.142 1.144 1.142a1.143 1.143 0 100-2.283z"
              transform="translate(-238 -1151) translate(238 491) translate(0 660) translate(50 23) translate(18.61 3.728)"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
};
