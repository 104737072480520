import React from 'react';
import { Platform, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Colors, Typography } from '../common-styles';

interface NoThemeButtonProps {
  onPress: () => void;
  title?: string;
  buttonStyle?: ViewStyle;
  clear?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  disabledStyle?: ViewStyle;
  titleStyle?: StyleProp<TextStyle>;
  leftIcon?: React.ReactNode;
  rounded?: boolean;
  color?: string;
  horizontalPadding?: number;
  outline?: boolean;
  rightIcon?: React.ReactNode;
  testID?: string;
}

const hitSlop = { top: 10, bottom: 10, left: 10, right: 10 };

export const _NoThemeButton: React.FC<NoThemeButtonProps> = ({
  title = ``,
  onPress,
  buttonStyle = {},
  clear = false,
  containerStyle = {},
  disabled = false,
  disabledStyle = {},
  titleStyle = {},
  leftIcon = undefined,
  rounded = false,
  color = Colors.buttonColor,
  horizontalPadding = 0,
  outline = false,
  rightIcon = undefined,
  testID = undefined,
}) => {
  const baseStyle = StyleSheet.compose(
    {
      flexDirection: `row`,
      backgroundColor: clear || outline ? `transparent` : color,
      borderColor: !outline ? undefined : color,
      borderWidth: outline ? 1 : 0,
      borderRadius: rounded ? 999 : 4,
      paddingVertical: 8,
      paddingHorizontal: 8 + horizontalPadding,
      justifyContent: `center`,
      alignItems: `center`,
    },
    buttonStyle,
  );
  const disabledMixin = StyleSheet.compose(
    {
      backgroundColor: clear ? `transparent` : Colors.lightGray,
      borderWidth: !outline ? undefined : 1,
      borderColor: !outline ? undefined : color || Colors.lightGray,
    },
    disabledStyle,
  );

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      disabled={disabled}
      activeOpacity={0.8}
      style={containerStyle}
      hitSlop={Platform.select({ web: undefined, default: hitSlop })}
    >
      <View style={[baseStyle, disabled ? disabledMixin : {}]}>
        <>
          {leftIcon}
          <Text
            maxFontSizeMultiplier={2}
            style={[
              Typography.text(`bold`, `center`, `plusone`),
              { color: clear ? color || Colors.buttonColor : Colors.white },
              titleStyle,
            ]}
          >
            {title}
          </Text>
          {rightIcon}
        </>
      </View>
    </TouchableOpacity>
  );
};
export const NoThemeButton = React.memo(_NoThemeButton);
