/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { View, Text, StyleSheet, SectionList, Image, ActivityIndicator, SectionListRenderItem } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import ParsedText from 'react-native-parsed-text';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Colors, Typography } from '../common-styles';
import { BottomSheetModal, CenterColumn, Divider, HeaderSubmitButton, ThemedButton } from '../common-ui';
import { LIST_OWNED_GROUPS, refetchQueriesFor, UPDATE_ROLE, ARCHIVE_COMMUNITY } from '../graphql';
import { AppContext } from '../../AppContext';
import { CommunityHeader } from '../images';
import { member } from '../community/memberInfoHelpers';
import {
  UpdateRoleData,
  UpdateRoleInput,
  OwnedCommunitiesResp,
  Group,
  ArchiveCommunityInput,
  ArchiveCommunityData,
} from '../common-types/types';
import { LeftDrawerNavigator, ProfileNavigator } from '../common-types/navigation-types';
import { formatDate } from '../common-util';

type SectionData = {
  title: string;
  data: OwnedCommunitiesResp[];
}[];

export const EditOwnershipScreen = () => {
  const navigation = useNavigation<ProfileNavigator>();
  const { user } = useContext(AppContext);

  const [oneOwnerCommunities, setOneOwnerCommunities] = useState<OwnedCommunitiesResp[]>([]);
  const [multiOwnerCommunities, setMultiOwnerCommunities] = useState<OwnedCommunitiesResp[]>([]);
  const [_communitiesToArchive, _setCommunitiesToArchive] = useState<Group[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deletionDate, setDeletionDate] = useState<string>(``);

  const {
    data: ownedCommunities,
    loading: loadingOwnedGroups,
    refetch: ownedGroupsRefetch,
  } = useQuery(LIST_OWNED_GROUPS, {
    variables: { user_id: user?.id },
    fetchPolicy: `cache-and-network`,
    // partialRefetch: true,
  });

  const [updateRole] = useMutation<UpdateRoleData, UpdateRoleInput>(UPDATE_ROLE);
  const [archiveCommunity] = useMutation<ArchiveCommunityData, ArchiveCommunityInput>(ARCHIVE_COMMUNITY);

  //Effect #1: rendering the continue button once all communities ownership are solved
  useEffect(() => {
    navigation?.setOptions({
      headerRight: () => (
        <HeaderSubmitButton
          title="Continue"
          onPress={() => setShowModal(true)}
          disabled={loadingOwnedGroups || !!oneOwnerCommunities.length || !!multiOwnerCommunities.length}
        />
      ),
    });
  }, [navigation, multiOwnerCommunities, oneOwnerCommunities, loadingOwnedGroups, showModal]);

  //Effect #2: setting the arrays to show the owned and co-owned groups
  useEffect(() => {
    const oneOwnerArray: OwnedCommunitiesResp[] = [];
    const multiOwnerArray: OwnedCommunitiesResp[] = [];
    ownedCommunities?.listOwnedGroups?.forEach((group: OwnedCommunitiesResp) => {
      if (group?.owner_count > 1) multiOwnerArray.push(group);
      else oneOwnerArray.push(group);
    });
    setOneOwnerCommunities(oneOwnerArray);
    setMultiOwnerCommunities(multiOwnerArray);
  }, [ownedCommunities?.listOwnedGroups]);

  //Effect #3: setting the deletion date to be displayed in the modal and to be sent to the server as well
  useEffect(() => {
    const deletionDate = new Date();
    deletionDate.setDate(deletionDate.getDate() + 30);
    setDeletionDate(formatDate(deletionDate, `MMMM dd, yyyy`));
  }, []);

  const handleStepDown = useCallback(
    async (groupId: string) => {
      try {
        const variables = {
          group_id: groupId,
          persona_id: user?.id,
          role_id: member,
        };
        updateRole({
          variables,
          refetchQueries: refetchQueriesFor(`GroupMember`, `Persona`, `Group`),
        });
        await ownedGroupsRefetch();
      } catch (error) {
        console.error(`An error ocurred trying to run updateRole mutation - handleStepDown - EditOwnershipScreen.tsx `, error);
      }
    },
    [updateRole, user?.id, ownedGroupsRefetch],
  );

  const handleArchiveCommunity = useCallback(
    (comm: Group) => {
      // const filteredCommunities = await oneOwnerCommunities.filter((group) => group?.group?.id !== comm.id);
      // setOneOwnerCommunities(filteredCommunities);
      // setCommunitiesToArchive((communitiesToArchive) => [...communitiesToArchive, comm]);

      try {
        archiveCommunity({
          variables: { group_id: comm.id! },
          refetchQueries: [{ query: LIST_OWNED_GROUPS, variables: { user_id: user?.id } }],
        });
      } catch (error) {
        console.error(`An error ocurred trying to archive a community ${comm?.name} - EditOwnershipScreen.tsx`);
      }
    },
    [archiveCommunity, user?.id],
  );

  const sections: SectionData = [
    {
      title: `Owner`,
      data: oneOwnerCommunities || [],
    },
    {
      title: `Co-owner`,
      data: multiOwnerCommunities || [],
    },
  ];

  const renderListHeaderComponent = useCallback(() => {
    return (
      <Text style={styles.headerText}>
        In order to delete your account, you must reassign ownership and step down as owner for any communities you own, or
        archive your communities. Any archived communities you own will be deleted once your account has been permanently deleted.
      </Text>
    );
  }, []);

  const renderSectionHeader = useCallback(({ section: { title } }) => {
    return (
      <CenterColumn>
        <View style={{ flexDirection: `row`, backgroundColor: Colors.commentGray, paddingVertical: 3, paddingHorizontal: 16 }}>
          <Text maxFontSizeMultiplier={2} style={{ ...Typography.text(`bold`, `gray`), paddingVertical: 4 }}>
            {title}
          </Text>
        </View>
      </CenterColumn>
    );
  }, []);

  const goToMemberInfo = (group_id: string) => {
    if (!group_id) return;
    //must declare full nav path to prevent screenForAllStacks to mess things up
    const parent = navigation.getParent<LeftDrawerNavigator>();
    parent?.jumpTo(`RightDrawer`, {
      screen: `MainTabs`,
      params: {
        screen: `HomeNavigator`,
        params: {
          screen: `MemberInfo`,
          params: {
            groupId: group_id,
          },
        },
      },
    });
  };

  const renderItem: SectionListRenderItem<OwnedCommunitiesResp> = useCallback(
    ({ item }) => {
      return (
        <View style={styles.itemContainer}>
          <View style={styles.itemSubContainer}>
            {item?.group?.avatar_url ? (
              <Image style={styles.groupIcon} source={{ uri: item?.group?.avatar_url }} />
            ) : (
              <CommunityHeader width={35} height={35} />
            )}
            <Text style={styles.communityName}>{item?.group?.name}</Text>
          </View>

          {/* buttons region: Edit ownership - Archive - Step down */}
          <View style={styles.buttonsArea}>
            <ThemedButton
              rounded
              title="Edit ownership"
              onPress={() => goToMemberInfo(item?.group?.id)}
              buttonStyle={{ width: 160, height: 40 }}
              titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
            />

            {item?.owner_count > 1 ? (
              <ThemedButton
                rounded
                title="Step down"
                onPress={() => handleStepDown(item?.group?.id)}
                buttonStyle={{
                  backgroundColor: Colors.white,
                  borderWidth: 1,
                  borderColor: Colors.brandPurple,
                  width: 160,
                  height: 40,
                }}
                titleStyle={{ ...Typography.text(`plustwo`, `theme`, `bold`), marginLeft: 4 }}
              />
            ) : (
              <ThemedButton
                rounded
                title="Archive"
                onPress={() => handleArchiveCommunity(item?.group)}
                buttonStyle={{
                  backgroundColor: Colors.white,
                  borderWidth: 1,
                  borderColor: Colors.textRed,
                  width: 160,
                  height: 40,
                }}
                titleStyle={{ ...Typography.text(`plustwo`, `red`, `bold`), marginLeft: 4 }}
              />
            )}
          </View>
        </View>
      );
    },
    [navigation, handleStepDown, handleArchiveCommunity],
  );

  return (
    <View style={styles.container}>
      {loadingOwnedGroups ? (
        <ActivityIndicator style={styles.loading} />
      ) : (
        <View>
          <SectionList
            sections={sections}
            stickySectionHeadersEnabled={true}
            ListHeaderComponent={renderListHeaderComponent}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            ListFooterComponent={<View style={{ marginBottom: 30 }} />}
            keyExtractor={(item) => item?.group?.id}
            ItemSeparatorComponent={Divider}
            showsVerticalScrollIndicator={false}
          />

          <BottomSheetModal
            title="Preparing your account for deletion"
            visible={showModal}
            showCancelBtn={false}
            showConfirmBtn={false}
            onPressCancel={() => setShowModal(false)}
            onPressConfirm={() => {}}
          >
            <View style={{ flexDirection: `column` }}>
              <ParsedText
                style={styles.modalText}
                parse={[
                  {
                    pattern: new RegExp(deletionDate),
                    style: Typography.text(`bold`),
                  },
                ]}
              >
                To recover your account before it is permanently deleted, log back into mesh before {deletionDate}.
              </ParsedText>
              <ThemedButton
                rounded
                title="Delete my account"
                onPress={() => {
                  setShowModal(false);
                  navigation.navigate(`EnterYourPasswordScreen`);
                }}
                buttonStyle={{ padding: 15 }}
                containerStyle={{ marginTop: 12, marginHorizontal: 16 }}
                titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
              />
              <ThemedButton
                rounded
                title="Cancel"
                onPress={() => setShowModal(false)}
                buttonStyle={{ padding: 15, backgroundColor: Colors.white, borderWidth: 1, borderColor: Colors.brandPurple }}
                containerStyle={{ marginTop: 12, marginBottom: 20, marginHorizontal: 16 }}
                titleStyle={{ ...Typography.text(`plustwo`, `theme`, `bold`), marginLeft: 4 }}
              />
            </View>
          </BottomSheetModal>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerText: {
    ...Typography.text(`small`, `center`),
    marginVertical: 16,
    marginHorizontal: 16,
  },
  itemContainer: {
    margin: 16,
  },
  itemSubContainer: {
    flexDirection: `row`,
    alignItems: `center`,
  },
  groupIcon: {
    borderRadius: 150 / 2,
    overflow: `hidden`,
    marginBottom: 5,
    width: 35,
    height: 35,
  },
  communityName: {
    marginLeft: 8,
    ...Typography.text(),
  },
  modalText: {
    ...Typography.text(`darkGray`),
    marginTop: 16,
    marginBottom: 16,
    marginHorizontal: 16,
  },
  buttonsArea: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginTop: 20,
  },
  loading: {
    justifyContent: `center`,
    alignItems: `center`,
    flex: 1,
  },
});
