/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import { useRoute, useNavigation } from '@react-navigation/native';
import { NetworkStatus } from 'apollo-client';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-apollo';
import { Keyboard, Platform, SectionList, Text, useWindowDimensions, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DELETED_USER, DUMMY_UUID, MODAL_SWITCH_TIME } from '../../constants';
import { Colors, Spacing } from '../common-styles';
import {
  CenterColumn,
  Divider,
  ErrorBoundary,
  KeyboardPaddingView,
  QueryError,
  SafeAreaView,
  ThreeDotsButton,
  MeshIcon,
} from '../common-ui';
import { commonPropTypes } from '../common-util';
import {
  GET_ACTIVITY_V2,
  GET_COMMENTS_AND_ONE_LIKE,
  GET_GROUP,
  //ACTIVITY_DETAILS_FRAGMENT,
  refetchQueriesFor,
  REMOVE_OWN_COMMENT,
  REMOVE_ACTIVITY,
  GET_PERSONAL_FEED_BY_ID,
  REMOVE_PERSONAL_POST,
  HIDE_PERSONAL_POST,
} from '../graphql';
import { MODERATION_TYPE } from '../moderation/moderationEnums';
import { ReportModal } from '../moderation/ReportModal';
import { Modals, setCommentToEdit, setGroupRelation, setMembership, setModal, setReplyTo } from '../redux/postSlice';
import { Comment } from './Comment';
import { CommentInput } from './CommentInput';
import { classifyActivity } from './helpers';
import PostContent from './components/PostContent';
import PostFooter from './components/PostFooter';
import PostHeader from './components/PostHeader';
import { PostModal } from './PostModal';
import * as PostStyles from './PostStyles';
import { useCommunities } from '../community/v2/hooks/useCommunities';
import { useConfirmModal } from '../common-util/hooks/useConfirmModal';
import { PostDetailTestIds } from './testLabels';
import { toggleJoinMeshModal } from '../redux/guestSlice';
import { Events, PendoTrackEvent, getCommunityRole, getContentType } from '../pendo/events';
import PostPolls from './components/PostPolls';
import { useFollow } from '../community/v2/hooks/useFollow';

export function PostDetail() {
  const navigation = useNavigation();
  const route = useRoute();
  const { id: activity_id, group_id, reply_id, addComment, isPersonalFeed, fromPersonalFeed, fromReportScreen } = route.params;
  const { modal, reportType, reportee_id, targetComment, target_id, user_frozen, post_frozen, feed } = useSelector((state) => ({
    ...state.post,
    feed: state.feed,
  }));
  const { isGuestMode } = useContext(AppContext);

  const dispatch = useDispatch();
  const { setCommunity } = useCommunities();

  const { user } = React.useContext(AppContext);
  const { height } = useWindowDimensions();
  const [activity, setActivity] = useState();

  const [group_member, setGroupMember] = useState();
  const [options, setOptions] = useState([]);
  const [sections, setSections] = useState();
  const [commentInputVisible, setCommentInputVisible] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const promptDeletePost = useConfirmModal();

  const sectionListRef = React.useRef();
  const commentTextInputRef = React.useRef();

  const query = isPersonalFeed ? GET_PERSONAL_FEED_BY_ID : GET_ACTIVITY_V2;
  const {
    error: activityError,
    data: activityData,
    loading: _activityLoading,
    networkStatus: _activityStatus,
    refetch: _activityRefetch,
  } = useQuery(query, {
    variables: { activity_id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: `network-only`,
  });

  const { error, loading, data, networkStatus, refetch, startPolling, stopPolling } = useQuery(GET_COMMENTS_AND_ONE_LIKE, {
    variables: { activity_id, limit: 15 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: `network-only`,
    onCompleted: () => {
      if (!loaded) setLoaded(true);
    },
  });

  const { data: membershipStatus } = useQuery(GET_GROUP, {
    variables: { group_id },
    fetchPolicy: `network-only`,
  });

  const deleteMutation = isPersonalFeed ? REMOVE_PERSONAL_POST : REMOVE_ACTIVITY;
  const [deletePost] = useMutation(deleteMutation, {
    refetchQueries: refetchQueriesFor(isPersonalFeed ? `ProfileActivity` : `Activity`),
  });

  const [deleteComment] = useMutation(REMOVE_OWN_COMMENT, {
    refetchQueries: refetchQueriesFor(`ActivityReaction`, isPersonalFeed ? `ProfileActivity` : `Activity`),
  });

  const [hidePost] = useMutation(HIDE_PERSONAL_POST, {
    refetchQueries: refetchQueriesFor(`ProfileActivity`),
  });

  const { followUser, unfollowUser } = useFollow();

  /**
   * We currently don't want the keyboard to be displayed every time user tap on comment input. Instead, we want to show
   * the not so fast BottomSheetModal to prompt the user that they can comment only if they match certain conditions.
   */
  const isAllowedToComment = useMemo(() => {
    if (!group_member) return false;
    const { has_agreed, can_participate, banned } = group_member;
    return !user_frozen && !post_frozen && has_agreed && can_participate && !banned;
  }, [user_frozen, post_frozen, group_member]);

  useEffect(() => {
    if (activity_id && reply_id && sectionListRef?.current && sections?.length) {
      setTimeout(() => {
        const commentIndex = sections.findIndex((section) => section?.comment?.activity_id === activity_id);
        if (sections[commentIndex]?.comment?.id === reply_id) {
          sectionListRef?.current?.scrollToLocation({
            sectionIndex: commentIndex,
            itemIndex: 0,
            viewOffset: 0,
            animated: true,
          });
        } else {
          const subIndex = sections[commentIndex]?.data?.findIndex((item) => item?.id === reply_id);
          sectionListRef?.current?.scrollToLocation({
            sectionIndex: commentIndex,
            itemIndex: subIndex > 0 ? subIndex : 0,
            viewOffset: 0,
            animated: true,
          });
        }
      }, 1000);
    }
  }, [activity_id, reply_id, sections]);

  useEffect(() => {
    if (addComment) {
      setCommentInputVisible(true);
    }
  }, [addComment]);

  useEffect(() => {
    if (commentInputVisible) commentTextInputRef.current?.focus();
  }, [commentInputVisible]);

  useEffect(() => {
    if (loaded) startPolling(45000);
    return stopPolling;
  }, [loaded, startPolling, stopPolling]);

  useEffect(() => {
    const results = isPersonalFeed ? activityData?.getProfileNodeActivity : activityData?.getActivityV2;
    if (results && results?.length) {
      const [activity] = results;
      const { application_pending, join_request_pending } = activity?.origin_group || undefined;
      setActivity(activity);
      dispatch(
        setGroupRelation({
          post: activity.frozen,
          user: activity.is_frozen,
          pending: application_pending || join_request_pending,
        }),
      );
    }
  }, [activityData, dispatch, isPersonalFeed]);

  useEffect(() => {
    if (membershipStatus) {
      const status = membershipStatus.getGroup?.group_member;
      const { guidelines } = membershipStatus.getGroup?.group || undefined;
      const { has_agreed = false, can_participate = false, banned = false, ban_expiration } = status || {};
      setGroupMember(status);
      dispatch(
        setMembership({
          is_member: !!status,
          has_agreed,
          can_participate,
          ban_expiration,
          banned,
          guidelines,
        }),
      );
    }
  }, [membershipStatus, dispatch]);

  useEffect(() => {
    const openMenu = activity ? () => showPostOptionsModal(activity) : () => null;
    navigation.setOptions({
      headerTitle: `Post`,
      headerLeft: () => (
        <MeshIcon
          name="chevron-left"
          color={Colors.brandPurple}
          size={24}
          style={{ marginLeft: 6 }}
          onPress={() => redirectToHomeScreen()}
        />
      ),
      headerRight:
        isGuestMode || fromReportScreen
          ? undefined
          : () => <ThreeDotsButton size={24} style={{ marginRight: 6 }} onPress={openMenu} />,
    });
  }, [navigation, activity, isGuestMode, showPostOptionsModal, redirectToHomeScreen, fromReportScreen]);

  useEffect(() => {
    const { comments } = data || {};
    // Return if there is no comments
    if (!comments?.length) return;
    // Sections are just comments mapped into a structure for rendering.
    const sections = comments
      ?.map((comment) => {
        const { comment_reply } = comment.latest_children || {};
        const safelyReversed = [...(comment_reply || [])].reverse();
        const filteredSubcomments = safelyReversed?.filter((subcomment) => !subcomment.data.removed);
        return { comment, key: comment?.id, data: filteredSubcomments || [] };
      })
      ?.filter((section) => {
        return !section.comment.data || !section.comment.data.removed || section.data.length !== 0;
      });
    setSections(sections || []);
  }, [data, error]);

  useEffect(() => {
    dispatch(setReplyTo(undefined));
    dispatch(setCommentToEdit(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (membershipStatus && activity?.id) {
      const { group, group_member } = membershipStatus?.getGroup;
      const community_role = getCommunityRole(isGuestMode, group_member);
      const input = {
        post_id: activity?.id,
        community_name: group?.name,
        community_role,
        location: 0,
        source: `PostDetail`,
        content_type: getContentType(activity),
        post_author: activity?.user?.handle,
        post_author_role: activity?.actor_role,
        created_at: activity?.created_at,
      };

      if (group?.name) PendoTrackEvent(Events.POST_SEEN, input);
      else console.warn(`group not initialized, name=${group?.name}`);
    }
  }, [activity, membershipStatus, isGuestMode]);

  const redirectToHomeScreen = useCallback(() => {
    const results = isPersonalFeed ? activityData?.getProfileNodeActivity : activityData?.getActivityV2;
    const [firstPost] = results || [];
    const { origin_group } = firstPost || {};
    // skip setting community if not member of current post's origin group
    if (group_member && firstPost && origin_group?.id !== feed.activeCommunity?.id) {
      setCommunity(origin_group, `Post`);
    }
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.replace(`HomeScreen`);
  }, [activityData, group_member, setCommunity, navigation, feed.activeCommunity?.id, isPersonalFeed]);

  const showPostOptionsModal = useCallback(
    (activity) => {
      dispatch(setModal(Modals.MANAGE_POST));
      const { actor, origin_group, origin_group_id, user: author } = activity || {};

      const { follow_request } = author || {};
      let followText = `Follow`;
      if (follow_request) followText = follow_request?.accepted ? `Unfollow` : `Remove follow request for`;

      const options = [
        {
          predicate: () => user?.id === actor,
          title: `Edit post`,
          iconName: `create`,
          testID: PostDetailTestIds.edit,
          onPress: () => {
            dispatch(setModal());
            if (isPersonalFeed) {
              navigation.navigate(`PersonalPostEditor`, { activity, mode: `edit` });
            } else {
              navigation.push(`PostEditor`, { group: origin_group, activity, mode: `edit` });
            }
          },
        },
        {
          predicate: () => user?.id === actor,
          title: `Delete post`,
          iconName: `trash`,
          titleStyle: { color: Colors.alertColor },
          iconColor: Colors.alertColor,
          testID: PostDetailTestIds.delete,
          onPress: () => {
            promptDeletePost({
              title: `Are you sure you want to delete this post?`,
              body: `Confirm by pressing Ok`,
              textConfirm: `Ok`,
              onConfirm: () => {
                deletePost({
                  variables: { id: activity_id },
                  refetchQueries: refetchQueriesFor(isPersonalFeed ? `ProfileActivity` : `Activity`),
                });
                dispatch(setModal(undefined));
                navigation.goBack();
              },
            });
          },
        },
        {
          predicate: () => user?.id !== actor,
          title: `${followText} ${author?.handle}`,
          iconName: follow_request === null ? `follow` : `unFollow`,
          testID: PostDetailTestIds.followUser,
          onPress: async () => {
            try {
              if (follow_request) {
                await unfollowUser(actor, true);
              } else {
                await followUser(actor, true);
              }
            } catch (error) {
              console.error(error);
            } finally {
              dispatch(setModal(null));
            }
          },
        },
        {
          predicate: () => user?.id !== actor,
          title: `Report post`,
          iconName: `flag`,
          titleStyle: { color: Colors.alertColor },
          iconColor: Colors.alertColor,
          testID: PostDetailTestIds.report,
          onPress: () => {
            dispatch(setModal(null));
            navigation.navigate(`ReportScreen`, {
              reportType: isPersonalFeed ? MODERATION_TYPE.personal_feed : MODERATION_TYPE.post,
              role_name: group_member?.role_name,
              id: activity_id,
              reportee: activity?.actor,
              origin_group_id: isPersonalFeed ? origin_group_id : group_id,
            });
          },
        },
        {
          predicate: () => user?.id !== actor,
          title: `Report ${activity?.user?.handle}` || `Report post's author`,
          iconName: `flag`,
          titleStyle: { color: Colors.alertColor },
          iconColor: Colors.alertColor,
          testID: PostDetailTestIds.userReport,
          onPress: () => {
            dispatch(setModal(null));
            navigation.navigate(`ReportScreen`, {
              reportType: MODERATION_TYPE.user,
              reportee: activity?.actor,
              role_name: group_member?.role_name,
              origin_group_id: group_id,
              reporteeName: activity?.user?.handle,
            });
          },
        },
        // TODO: Hide Post
        // {
        //   predicate: () => user?.id !== actor && fromPersonalFeed,
        //   title: `Hide post`,
        //   iconName: `visibility-off`,
        //   testID: PostDetailTestIds.hidePost,
        //   onPress: () => {
        //     hidePost({
        //       variables: {
        //         activity_id: id,
        //         hide: true,
        //       },
        //     });
        //     dispatch(setModal(null));
        //   },
        // },

        // TODO
        // {
        //   predicate: () => user?.id === actor && fromPersonalFeed,
        //   title: `Turn off commenting`,
        //   iconName: `volume-x`,
        //   testID: PostDetailTestIds.turnoffComment,
        //   onPress: () => {
        //     dispatch(setModal(null));
        //   },
        // },
      ].filter((option) => option.predicate === undefined || option.predicate());
      setOptions(options);
    },
    [
      dispatch,
      user?.id,
      navigation,
      promptDeletePost,
      deletePost,
      activity_id,
      group_id,
      group_member,
      followUser,
      isPersonalFeed,
      hidePost,
      fromPersonalFeed,
    ],
  );

  const handleDeleteComment = useCallback(
    (comment) => {
      const isAuthor = comment?.user_id === user?.id;
      const role_name = group_member?.role_name;
      const isAdmin = role_name === `owner` || role_name === `manager` || role_name === `moderator`;
      dispatch(setModal());
      if (isAuthor) {
        setTimeout(async () => {
          const { _data, errors } = await deleteComment({
            variables: {
              input: {
                reaction_id: comment.id,
                data: {
                  user_id: comment.data.user_id,
                  object: comment.data.object,
                  group_id: comment.data.group_id,
                  removed: true,
                },
              },
            },
          });
          if (!errors)
            setSections((prev) =>
              prev?.filter((item) => {
                const filteredComment = item?.key !== comment?.id;
                return filteredComment;
              }),
            );
        }, MODAL_SWITCH_TIME);
      } else if (isAdmin) {
        navigation.navigate(`DeletePostScreen`, {
          reportType: MODERATION_TYPE.comment,
          id: activity?.id,
          origin_group_id: group_id,
          reaction_id: comment.id,
        });
      }
    },
    [activity?.id, deleteComment, dispatch, group_id, group_member, user?.id, navigation],
  );

  const showCommentOptionsModal = useCallback(
    (comment) => {
      dispatch(setModal(Modals.MANAGE_COMMENT));
      const isAuthor = comment?.user_id === user?.id;
      const role_name = group_member?.role_name;
      const isAdmin = role_name === `owner` || role_name === `manager` || role_name === `moderator`;

      const options = [
        {
          predicate: () => !isAuthor,
          title: `Report comment`,
          titleStyle: { color: Colors.alertColor },
          iconName: `flag`,
          iconColor: Colors.alertColor,
          testID: PostDetailTestIds.commentReport,
          onPress: () => {
            dispatch(setModal(null));
            navigation.navigate(`ReportScreen`, {
              reportType: MODERATION_TYPE.comment,
              role_name: group_member?.role_name,
              id: activity_id,
              reportee: activity?.actor,
              origin_group_id: group_id,
              reaction_id: comment?.id,
            });
          },
        },
        {
          predicate: () => !isAuthor && comment?.user?.id !== DUMMY_UUID && comment?.user?.handle !== DELETED_USER.handle,
          title: `Report ${comment?.user?.handle}`,
          titleStyle: { color: Colors.alertColor },
          iconName: `flag`,
          iconColor: Colors.alertColor,
          testID: PostDetailTestIds.reportUserOfComment,
          onPress: () => {
            dispatch(setModal(null));
            navigation.navigate(`ReportScreen`, {
              reportType: MODERATION_TYPE.user,
              role_name: group_member?.role_name,
              reportee: activity?.actor,
              origin_group_id: group_id,
              reporteeName: comment?.user?.handle,
            });
          },
        },
        {
          predicate: () => isAuthor && !post_frozen && !user_frozen,
          title: `Edit`,
          iconName: `create`,
          testID: PostDetailTestIds.editComment,
          onPress: () => {
            dispatch(setModal());
            setCommentInputVisible(true);
            setTimeout(() => {
              commentTextInputRef?.current?.focus();
              dispatch(setCommentToEdit(comment));
            }, MODAL_SWITCH_TIME);
          },
        },
        {
          predicate: () => (isAuthor && !post_frozen && !user_frozen) || (!isAuthor && isAdmin),
          title: `Delete`,
          titleStyle: { color: Colors.alertColor },
          iconName: `trash`,
          iconColor: Colors.alertColor,
          testID: PostDetailTestIds.deleteComment,
          onPress: () => handleDeleteComment(comment),
        },
      ].filter((option) => option.predicate === undefined || option.predicate());
      setOptions(options);
    },
    [
      navigation,
      dispatch,
      user?.id,
      post_frozen,
      user_frozen,
      group_member,
      activity,
      group_id,
      activity_id,
      handleDeleteComment,
    ],
  );

  const renderComment = useCallback(
    ({ comment, parent }) => {
      if (!activity) return null;
      if (!comment) return null;
      const isTarget = targetComment?.id === comment?.id;
      return (
        <Comment
          activity={activity || {}}
          comment={comment}
          parent={parent}
          isTargeted={isTarget}
          onThreeDotPress={showCommentOptionsModal}
          onReplyToComment={focusInput}
        />
      );
    },
    [activity, showCommentOptionsModal, targetComment?.id, focusInput],
  );

  const renderFooter = () => {
    return (
      <View style={{ flex: 1, paddingBottom: 40 }}>
        <CenterColumn>
          <View style={{ margin: 20 }}>
            {loading && !loaded && <Text>Loading comments...</Text>}
            {error && (
              <Text style={{ color: Colors.baseText }}>
                <Text>The server returned an error: </Text>
                <Text>{error.message}</Text>
                {data?.graphQLErrors?.map(({ message }) => (
                  <Text style={{ color: Colors.mediumGray }}>{message}</Text>
                ))}
              </Text>
            )}
          </View>
        </CenterColumn>
      </View>
    );
  };

  const onCommentInputEnd = useCallback(() => {
    refetch();
  }, [refetch]);

  const focusInput = useCallback(
    (comment) => {
      if (isGuestMode) {
        dispatch(toggleJoinMeshModal({ open: true, group: activity.origin_group }));
      } else if (isAllowedToComment || isPersonalFeed) {
        setSelectedComment(comment);
        setCommentInputVisible(true);
        commentTextInputRef?.current?.focus();
      }
    },
    [activity, dispatch, isGuestMode, isAllowedToComment, isPersonalFeed],
  );

  if (activityError) return <QueryError error={error || activityError} networkStatus={networkStatus} refetch={refetch} />;

  if (!activityData) return <View />;

  const [currentActivity] = isPersonalFeed ? activityData?.getProfileNodeActivity : activityData?.getActivityV2 || [];
  if (!currentActivity || currentActivity?.removed)
    return (
      <View style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, height: `100%` }}>
        <Text>This post has been removed</Text>
      </View>
    );

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
        <SectionList
          testID={PostDetailTestIds.scrollView}
          contentContainerStyle={{
            flexGrow: 1,
            height: Platform.select({ web: height - 48 * 2 - 42 }),
          }}
          ref={sectionListRef}
          onScrollToIndexFailed={(info) => {
            // eslint-disable-next-line no-promise-executor-return
            const wait = new Promise((resolve) => setTimeout(resolve, 1000));
            wait
              .then(() => {
                sectionListRef?.current?.scrollToLocation({ sectionIndex: info.index, animated: true });
                return null;
              })
              .catch((reason) => {
                console.warn(`reason`, reason);
              });
          }}
          onRefresh={refetch}
          refreshing={networkStatus === NetworkStatus.refetch}
          progressViewOffset={100}
          ListHeaderComponent={
            <ActivityDetails activity={activity} isAllowedToComment={isAllowedToComment} onPressComment={focusInput} />
          }
          ListFooterComponent={renderFooter}
          onEndReachedThreshold={0.5}
          sections={sections}
          onScrollBeginDrag={() => {
            Keyboard.dismiss();
          }}
          extraData={[sections]}
          keyExtractor={(comment) => comment.id}
          renderSectionHeader={({ section: { comment } }) => renderComment({ comment })}
          stickySectionHeadersEnabled={false}
          renderItem={({ item: comment, section }) => renderComment({ comment, parent: section.comment })}
        />
        {isGuestMode ? null : (
          <CommentInput
            activity={activity}
            visible={commentInputVisible}
            commentId={selectedComment?.id}
            onCommentInputEnd={onCommentInputEnd}
            ref={commentTextInputRef}
          />
        )}
        {modal && modal !== `report` && !isGuestMode && (
          <PostModal
            options={options}
            setOptions={setOptions}
            activity_id={activity_id}
            group={activity?.origin_group}
            group_member={group_member}
          />
        )}
        {modal === `report` && (
          <ReportModal
            reportType={reportType}
            isVisible
            onClose={() => dispatch(setModal())}
            id={activity?.id}
            reaction_id={reportType === MODERATION_TYPE.comment ? target_id : null}
            origin_group_id={group_id}
            navigation={navigation}
            reportee={reportee_id}
          />
        )}
      </SafeAreaView>
    </KeyboardPaddingView>
  );
}

// TODO:
// -- We already have an ActivityComponent why using this?

const areEqualDetail = (prev, next) => {
  const areEqualActivities = prev?.activity?.id === next?.activity?.id;
  const areEqualLikeCount = prev?.activity?.reaction_counts?.like === next?.activity?.reaction_counts?.like;
  const areEqualCommentCount = prev?.activity?.reaction_counts?.comment === next.activity?.reaction_counts?.comment;
  const areEqualUpdated = prev?.activity?.updated_at === next?.activity?.updated_at;
  const areEqualMemberAgreed =
    prev?.activity?.origin_group_member?.has_agreed === next?.activity?.origin_group_member?.has_agreed;
  const areEqualCanParticipate =
    prev?.activity?.origin_group_member?.can_participate === next?.activity?.origin_group_member?.can_participate;
  const areEqualCanPost = prev?.activity?.origin_group_member?.can_post === next?.activity?.origin_group_member?.can_post;
  const areEqualCanInvite = prev?.activity?.origin_group_member?.can_invite === next?.activity?.origin_group_member?.can_invite;
  const areEqualBanned = prev?.activity?.origin_group_member?.banned === next?.activity?.origin_group_member?.banned;
  const areEqualPress = prev?.onPressComment === next?.onPressComment;
  const areEqualAllowedComment = prev?.isAllowedToComment === next?.isAllowedToComment;

  const prevVoteCount = prev?.activity?.poll?.options?.reduce((total, option) => total + option.vote_count, 0) || 0;
  const currentVoteCount = next?.activity?.poll?.options?.reduce((total, option) => total + option.vote_count, 0) || 0;
  const isEqualVotes = prevVoteCount === currentVoteCount;

  return (
    areEqualActivities &&
    areEqualLikeCount &&
    areEqualUpdated &&
    areEqualCommentCount &&
    areEqualMemberAgreed &&
    areEqualCanParticipate &&
    areEqualCanPost &&
    areEqualCanInvite &&
    areEqualBanned &&
    areEqualAllowedComment &&
    areEqualPress &&
    isEqualVotes
  );
};

const ActivityDetails = React.memo(({ activity, onPressComment }) => {
  const route = useRoute();
  const { expanded = false } = route.params;
  const { width: windowWidth } = useWindowDimensions();
  const width = Math.min(Spacing.standardWidth, windowWidth);
  const isTextActivity = classifyActivity(activity) !== `text` && !!activity?.content?.trim();

  if (!activity) return null;

  return (
    <CenterColumn testID={PostDetailTestIds.postDetails}>
      <View style={{ flex: 1, maxWidth: Spacing.standardWidth }}>
        <ErrorBoundary header="Sorry, an error occurred displaying this post">
          <PostHeader activity={activity} showGroup={activity.__typename === `Activity`} />
          <PostContent activity={activity} width={width} textOverflowMode="expand" startExpanded={expanded} onScreen={true} />
          <PostPolls activity={activity} />
          <PostFooter activity={activity} containerPress={isTextActivity ? undefined : false} onPressComment={onPressComment} />
          <Divider />
          {/* TODO: !!Move this to the Activity Component!! */}
          {activity?.frozen && (
            <CenterColumn>
              <View style={PostStyles.warningContainer}>
                <Text style={PostStyles.warningText}>Comments and likes are closed on this post</Text>
              </View>
            </CenterColumn>
          )}
        </ErrorBoundary>
      </View>
    </CenterColumn>
  );
}, areEqualDetail);

ActivityDetails.propTypes = {
  activity: commonPropTypes.activity(),
  onPressComment: PropTypes.func,
};

ActivityDetails.defaultProps = {
  activity: null,
  onPressComment: () => null,
};
