import 'react-native-gesture-handler';
import { Asset } from 'expo-asset';
import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Provider } from 'react-redux';
import * as Device from 'expo-device';
import * as Font from 'expo-font';
import * as Notifications from 'expo-notifications';
import 'react-native-get-random-values';
import { Linking, Platform, AppState } from 'react-native';
import { enableScreens } from 'react-native-screens';
import * as Sentry from './packages/constants/Sentry';
import { getEnvVariables, getManifest } from './environment';
import { store } from './packages/redux/store';
import { setJoinedCommunities } from './packages/redux/feedSlice';
import { getAttribution } from './packages/attribution';
import { fetchSecureStore, removeAllPersonas, decodeSecureStoreAndSignIn } from './packages/auth/helpers';
import { Colors, Icons } from './packages/common-styles';
import { getAsyncStorageObject, setAsyncStorageObject } from './packages/common-util';
import { AppUpdateScreen } from './packages/navigation/AppUpdateScreen';
import { LoadingScreen } from './packages/navigation/LoadingScreen';
import { useLinkHandler } from './packages/navigation/helpers';
import { ThemeContextProvider } from './packages/themes/ThemeProvider'; // FIXME: this needs to go away
import { MediaPermissionProvider } from './packages/contexts/MediaPermissionContext';
import { useUpdateHandler } from './packages/update/useUpdateHandler';
import { AndroidRedirect, DesktopRedirect, IPhoneRedirect } from './packages/web/redirects';
import { AppContext, initialAppState, appContextReducer, AppContextActions } from './AppContext';
import { initPendo } from './packages/pendo/setup';
import { inviteRegex } from './packages/post/hooks/useLinkPreview';
import { client, LIST_GROUPS } from './packages/graphql';
import { removeAsyncStorageKeys } from './packages/common-util/asyncStorage';
import { _INVITE_FORMAT } from './constants';
import { PromiseAllSettled } from './packages/common-util/Polyfill';

// LogBox does not exist on react-native-web yet
let LogBox;
try {
  LogBox = require(`react-native`).LogBox;
} catch (err) {} // eslint-disable-line no-empty

Notifications.setNotificationHandler({
  handleNotification: () => ({
    shouldShowAlert: false,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
});
const configInstabug = () => {
  try {
    console.log(`installing Instabug`);
    const Instabug = require(`instabug-reactnative`).default;

    Instabug.startWithToken(`09292d4f18e0256f6d8cb713a0be0170`, [Instabug.invocationEvent.shake]);
    Instabug.setColorTheme(Instabug.colorTheme.light);
    Instabug.setPrimaryColor(Colors.deepPurple);
    Instabug.setWelcomeMessageMode(Instabug.welcomeMessageMode.disabled);

    const { BugReporting } = Instabug;
    const { setShakingThresholdForiPhone, setShakingThresholdForAndroid } = BugReporting || {};
    if (setShakingThresholdForiPhone) setShakingThresholdForiPhone(300);
    if (setShakingThresholdForAndroid) setShakingThresholdForAndroid(200);
    console.log(`Instabug is ready`);
  } catch (err) {
    console.error(`Instabug installation failed: ${err}`);
  }
};
// check some browser properties if we are running in react-native-web
// Checking for a # in url is a hacky but simple way to allow us to test & try the react-native-web build without
// having it default to being visible to everybody.
const defaultUrl = `https://app.mymesh.io/`; // `https://app.meshconnect.us/`;
const url = ((window && window.location && window.location.href) || ``).replace(/http:\/\/localhost:?\d+\//, defaultUrl);
const urlContainsHash = url.indexOf(`#`) !== -1;
const urlIsBeta = url && (url.startsWith(`https://beta.meshconnect.us/`) || url.startsWith(`https://beta.mymesh.io/`));
const userAgent = (navigator && navigator.userAgent) || (window.navigator && window.navigator && window.navigator.userAgent);
const debugging = false;
// const debugging = (false, true); // uncomment to enable debugging in Chrome
const disabled = !debugging && (userAgent || url) && !urlContainsHash && !urlIsBeta;
const isCI = Platform.select({
  native: process.env.DETOX_START_TIMESTAMP !== undefined,
  web: false,
});
if (LogBox && isCI) LogBox.ignoreAllLogs();

// enable react-native-screens
enableScreens(!disabled);
if (!disabled) {
  // Initialize Sentry
  if (Device.isDevice) {
    const { extra } = getManifest() || {};
    const { publishID } = extra || {};
    console.log(`publishID: ${publishID}`);
    Sentry.init();
  }

  // Hide specific Expo warnings that aren't useful
  if (LogBox) LogBox.ignoreLogs([`Error: Native splash screen is already hidden`]);
}

initPendo();

function App() {
  const [state, dispatch] = React.useReducer(appContextReducer, initialAppState);
  const stateReducer = React.useMemo(
    () => ({
      setIdentity: (identity) => dispatch({ type: AppContextActions.SET_IDENTITY, payload: identity }),
      setUser: (user) => dispatch({ type: AppContextActions.LOGIN_USER, payload: user }),
      setLaunchUrl: (launchUrl) => dispatch({ type: AppContextActions.SET_LAUNCH_URL, payload: launchUrl }),
      setInviteToken: (invite_token) => dispatch({ type: AppContextActions.SET_INVITE, payload: invite_token }),
      setFeedActivityCount: (activityCount) =>
        dispatch({ type: AppContextActions.SET_FEED_ACTIVITY_COUNT, payload: activityCount }),
      setNotificationCount: (notificationCount) =>
        dispatch({ type: AppContextActions.SET_NOTIFICATION_COUNT, payload: notificationCount }),
      setFollowNotificationCount: (notificationCount) =>
        dispatch({ type: AppContextActions.SET_FOLLOW_NOTIFICATION_COUNT, payload: notificationCount }),
      setModerationCount: (moderationCount) =>
        dispatch({ type: AppContextActions.SET_MODERATION_COUNT, payload: moderationCount }),
      updateModerationCount: (difference) => dispatch({ type: AppContextActions.UPD_MODERATION_COUNT, payload: difference }),
      setChatStatus: (chatStatus) => dispatch({ type: AppContextActions.SET_CHAT_STATUS, payload: chatStatus }),
      setChatNotifications: (chatNotifications) =>
        dispatch({ type: AppContextActions.SET_CHAT_NOTIFICATION, payload: chatNotifications }),
      setChatPushToken: (chatPushtoken) => dispatch({ type: AppContextActions.SET_CHAT_PUSHTOKEN, payload: chatPushtoken }),
      setPushToken: (pushtoken) => dispatch({ type: AppContextActions.SET_PUSHTOKEN, payload: pushtoken }),
      setLatestVersionInfo: (info) => dispatch({ type: AppContextActions.SET_LATEST_VERSION, payload: info }),
      setIsGuestMode: (isGuestMode) => dispatch({ type: AppContextActions.SET_IS_GUEST_MODE, payload: isGuestMode }),
      logout: () => dispatch({ type: AppContextActions.LOGOUT }),
      //For stream chat
      setChannel: (channel) => dispatch({ type: AppContextActions.SET_CHANNEL, payload: channel }),
      setThread: (thread) => dispatch({ type: AppContextActions.SET_THREAD, payload: thread }),
    }),
    [],
  );
  const [appIsReady, setAppIsReady] = useState(false);
  const [firstScreen, setfirstScreen] = useState();
  const [appState, setAppState] = useState(AppState.currentState);
  const { appUpdate, checkForAndDownloadUpdate } = useUpdateHandler();
  useLinkHandler(appIsReady);

  const getLaunchUrl = async () => {
    // check for initial URL
    const initialUrl = (await Linking.getInitialURL())?.replace(`///`, `//`);
    if (initialUrl) return initialUrl;

    // check for deferred URL from a previous launch
    let launchUrl = await getAsyncStorageObject(`launchUrl`);
    if (launchUrl === `null` || typeof launchUrl !== `string`) {
      if (typeof launchUrl !== `string` && launchUrl === `null`) {
        console.warn(`Type warning: launchUrl expected type 'string' found ${typeof launchUrl}`, launchUrl);
      }
      await setAsyncStorageObject(`launchUrl`, null);
      launchUrl = null;
    }
    if (launchUrl && typeof launchUrl === `string`) {
      const validUrl = launchUrl.match(inviteRegex);
      if (validUrl) {
        const [, , invite_token] = validUrl;
        if (state.invite_token !== invite_token) dispatch({ type: AppContextActions.SET_INVITE, payload: invite_token });
      }
      return launchUrl;
    }
    // check for attribution -- where we did not receive an initialURL, but the server may have our IP
    //  and device fingerprint tagged as following a link that bounced through the App Store
    const attributedUrl = await getAttribution();
    if (attributedUrl) return attributedUrl;

    return null;
  };
  const launch = async () => {
    console.log(`-------------------- App launch flow --------------------`);
    try {
      // STEP 1: get launch URL first, and save it in async storage in case we end up updating and restarting.
      const launchUrl = await getLaunchUrl();
      console.log(`-------> launch url: ${launchUrl}`);
      await setAsyncStorageObject(`launchUrl`, launchUrl);
      dispatch({ type: AppContextActions.SET_LAUNCH_URL, payload: launchUrl });

      // STEP 2: Do several other things in parallel
      const [updateResult, reauthorizeResult, fontResult, assetResult] = await PromiseAllSettled([
        // STEP 2a: check for updates
        checkForAndDownloadUpdate(),

        // STEP 2b: fast reauthorize if we were already logged in
        reauthorizeLogin(),

        // STEP 2c: preload font assets
        Font.loadAsync({
          futura: require(`./assets/fonts/futura.ttf`),
          'inter-regular': require(`./assets/fonts/Inter-Regular.otf`),
          'inter-semibold': require(`./assets/fonts/Inter-SemiBold.otf`),
          ...Icons.getAllIconFonts(),
        }),

        // STEP 2c: preload image assets
        Asset.loadAsync([
          // assets we need all the time
          require(`./assets/images/user_avatar.png`),
          require(`./assets/images/mesh_verified.png`),
          // below are for particular one-off screens, consider not preloading them
          require(`./assets/images/mesh_verification.png`),
          require(`./assets/images/notification.png`),
          require(`./assets/images/pencil.png`),
          require(`./assets/images/nametag.png`),
          require(`./assets/images/magnifying.png`),
          require(`./assets/images/non-accouncements.png`),
        ]),
      ]);

      if (updateResult.status === `rejected`) {
        console.warn(`checkForUpdate threw an exception: ${updateResult.reason}`);
      }
      if (reauthorizeResult.status === `rejected`) {
        console.warn(`reauthorizeLogin threw an exception: ${reauthorizeResult.reason}`);
      }
      if (fontResult.status === `rejected`) {
        console.warn(`Font.loadAsync threw an exception: ${fontResult.reason})`);
      }
      if (assetResult.status === `rejected`) {
        console.warn(`Asset.loadAsync threw an exception: ${assetResult.reason}`);
      }
    } catch (err) {
      console.error(`==> prepareResources threw an exception`, err);
      console.log(err.stack);
    } finally {
      console.log(`-------------------- App is ready --------------------`);
    }
    // We're as ready as we're going to be. Hide our splash screen.
    setAppIsReady(true);
  };
  const redirect = async (firstScreen, why) => {
    console.log(`ℹ️  ~ App.redirect to ${firstScreen} (${why})`);
    if (firstScreen) {
      await removeAllPersonas();
      dispatch({ type: AppContextActions.LOGOUT });
      // due to failed auth (change of env?), clear any persona data
      // but keep any record of latest community
      await removeAsyncStorageKeys([`identity`, `currentUser`]);
      console.log(`setting firstScreen = ${firstScreen}`);
      setfirstScreen(firstScreen);
    }
  };
  const getTokens = async () => {
    const pushPermissionStatus = await getAsyncStorageObject(`pushPermissionStatus`);
    const push_token = pushPermissionStatus && pushPermissionStatus.token;
    return { push_token, invite_token: state.invite_token };
  };
  const getSecureStoreFromIdentity = async () => {
    const stored_identity = await getAsyncStorageObject(`identity`);
    const { anyoneCanRegister } = getEnvVariables();

    if (!stored_identity && anyoneCanRegister) return redirect(`Welcome`);
    if (!stored_identity) return redirect(`Login`, `async storage exists, but no identity`);

    // Get the secure storage for this identity from the server
    const secure_store = await fetchSecureStore(stored_identity.id);
    if (!secure_store) return redirect(`Login`, `invalid identity (did you switch from dev to prod?)`);

    if (state.identity?.id !== stored_identity.id) dispatch({ type: AppContextActions.SET_IDENTITY, payload: stored_identity });
    return secure_store;
  };
  const reauthorizeLogin = async () => {
    const { identity, user } = state;
    const [secure_store, extra_tokens] = await Promise.all([getSecureStoreFromIdentity(), getTokens()]);
    if (!secure_store) return console.log(`reauthorizeLogin -> no secure_store`);
    const [signed_user] = await decodeSecureStoreAndSignIn(secure_store, extra_tokens);
    dispatch({ type: AppContextActions.LOGIN_USER, payload: signed_user });
    await getCurrentUser();

    if (signed_user?.id) {
      const scopeConfig = (scope) => {
        scope.setUser({ username: user?.handle });
        scope.setExtras({ 'global.identity': identity, 'global.user': user });
      };
      Sentry.configureScope(scopeConfig);
    }
    // Prefetch group data, helps identify users who don't finish account creation last step
    const { data, errors } = await client.query({ query: LIST_GROUPS, variables: { persona_id: signed_user.id } });
    if (errors && errors.length) console.error(`[AuthErrror]`, errors[0].message);
    const mappedCommunities = data?.listJoinedGroups.map(({ group }) => group) || [];
    store.dispatch(setJoinedCommunities(mappedCommunities));
    return signed_user;
  };

  const getCurrentUser = async () => {
    const storedKeys = await AsyncStorage.getAllKeys();
    if (!storedKeys.find((key) => key === `currentUser`)) {
      console.log(`App.getCurrentUser: no currentUser, redirect to login`);
    } else {
      const res = (await getAsyncStorageObject(`currentUser`)) || {};
      dispatch({ type: AppContextActions.LOGIN_USER, payload: res });
      //dispatch({type: "SET_PREVUSER", payload: res});
    }
  };

  useEffect(() => {
    console.log(`-------------------- App object created --------------------\ndisabled:${disabled}`);
    if (Platform.OS !== `web` && !__DEV__ && !disabled) configInstabug();
    launch(); // only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _handleAppStateChange = (nextAppState) => {
      if (appState?.match(/inactive|background/) && nextAppState === `active`) console.log(`The app is active now. `);
      setAppState(nextAppState);
    };
    if (!disabled) AppState.addEventListener(`change`, _handleAppStateChange);
    return () => AppState.removeEventListener(`change`, _handleAppStateChange);
  }, [appState]);

  // ========================= Rendering =========================

  // If we are in a browser and don't have a '#' in the URL, show redirect
  if (disabled) {
    const match = userAgent && userAgent.match(/(Windows|Macintosh|iPhone|iPad|Android)/);
    const device = match && match[0];
    console.log(` === device: ${device} === `);
    switch (device) {
      case `iPhone`:
      case `iPad`:
        return <IPhoneRedirect url={url} />;

      case `Android`:
        return <AndroidRedirect url={url} />;

      case `Windows`:
      case `Macintosh`:
      default:
        return <DesktopRedirect url={url} />;
    }
  }

  if (!appIsReady) return <LoadingScreen />;
  if (appUpdate) return <AppUpdateScreen />;

  const { AppChild } = Platform.select({
    native: () => require(`./AppChild.native`),
    default: () => require(`./AppChild`),
  })();
  return (
    <AppContext.Provider value={{ ...state, ...stateReducer }}>
      <ThemeContextProvider>
        <MediaPermissionProvider>
          <Provider store={store}>
            <AppChild firstScreen={firstScreen} />
          </Provider>
        </MediaPermissionProvider>
      </ThemeContextProvider>
    </AppContext.Provider>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
