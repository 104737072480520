/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowState } from '../common-types/types';

const initialState: FollowState = {
  isCountRefresh: false,
  isFollowingNotiRefresh: false,
  isFollowInfoRefresh: false,
  isRefetchFollowRequest: false,
  isRefetchNotification: false,
  counts: {
    following: 0,
    followers: 0,
  },
};

const followSlice = createSlice({
  name: `follow`,
  initialState,
  reducers: {
    refreshCounts: (state, action: PayloadAction<boolean>) => {
      state.isCountRefresh = action.payload;
    },
    refreshFollowingNotification: (state, action: PayloadAction<boolean>) => {
      state.isFollowingNotiRefresh = action.payload;
    },
    refreshFollowInfo: (state, action: PayloadAction<boolean>) => {
      state.isFollowInfoRefresh = action.payload;
    },
    refreshFollowRequest: (state, action: PayloadAction<boolean>) => {
      state.isRefetchFollowRequest = action.payload;
    },
    refreshNotification: (state, action: PayloadAction<boolean>) => {
      state.isRefetchNotification = action.payload;
    },
    setFollowCounts: (state, action: PayloadAction<any>) => {
      state.counts.followers = action.payload.followers;
      state.counts.following = action.payload.following;
    },
  },
});

export const followReducer = followSlice.reducer;

export const {
  refreshCounts,
  refreshFollowingNotification,
  refreshFollowInfo,
  refreshFollowRequest,
  setFollowCounts,
  refreshNotification,
} = followSlice.actions;
