import React from 'react';
import { View, Text, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { Colors } from '../common-styles';
import { WithChildren } from '../common-types/types';

type CircleButtonProps = {
  onPress: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  text?: string;
} & WithChildren;

const TEXT_STYLE = {
  fontSize: 10,
  textAlign: `center`,
} as StyleProp<TextStyle>;

// const buttonWrapper = {
//   backgroundColor: Colors.white,
//   justifyContent: `center`,
//   alignItems: `center`,
//   marginRight: 5,
//   borderRadius: 100,
//   width: 60,
//   height: 60,
// } as StyleProp<ViewStyle>;

export const CircleButton = ({ onPress, children, text, textStyle, containerStyle }: CircleButtonProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          shadowColor: Colors.avatarShadowColor,
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.2,
          shadowRadius: 1.41,
          elevation: 2,
          justifyContent: `flex-start`,
        }}
      >
        <View
          style={[
            {
              backgroundColor: Colors.white,
              borderRadius: 100,
              justifyContent: `center`,
              alignItems: `center`,
              overflow: `hidden`,
              width: 60,
              height: 60,
            },
            containerStyle,
          ]}
        >
          {children}
        </View>
      </View>
      {text && (
        <Text ellipsizeMode="tail" style={[TEXT_STYLE, textStyle]}>
          {text}
        </Text>
      )}
    </TouchableOpacity>
  );
};
