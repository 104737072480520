import React from 'react';
import { CreditCardNames, CREDIT_CARD_IMAGES } from './creditCardsHelpers';

type PaymentMethodImageProps = {
  cardName: CreditCardNames;
  width?: number;
  height?: number;
};

export const PaymentMethodImage: React.FC<PaymentMethodImageProps> = (props) => {
  const { cardName, width = 46, height = 32 } = props;

  const CardImage = CREDIT_CARD_IMAGES[cardName];

  if (!CardImage) return null;

  return <CardImage width={width} height={height} />;
};
