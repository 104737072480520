import * as IntentLauncher from 'expo-intent-launcher';
import { Linking, Platform, Text, View } from 'react-native';
import React from 'react';
import { getAndroidPackageName } from '../../environment';
import { Colors, Typography } from '../common-styles';
import { BottomSheetModal } from './BottomSheetModal';
import { ThemedButton } from './ThemedButton';

type PhotoPermissionModalProps = {
  isVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  toggleModal: (args: any) => any;
};

export const PhotoPermissionModal = ({ isVisible, toggleModal }: PhotoPermissionModalProps) => {
  const goToSettings = () => {
    if (Platform.OS === `ios`) {
      console.log(`platform is ios`);
      Linking.openURL(`app-settings:`);
    }
    if (Platform.OS === `android`) {
      console.log(`platform is android`);
      IntentLauncher.startActivityAsync(IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS, {
        data: `package:${getAndroidPackageName()}`,
      });
    }
  };

  return (
    <BottomSheetModal
      showCancelBtn={false}
      title="Allow Mesh to access to your photos"
      visible={isVisible}
      onPressCancel={() => toggleModal({})}
      showConfirmBtn={false}
      onPressConfirm={() => {}}
    >
      <View style={{ marginTop: 12, marginHorizontal: 16, marginBottom: 0 }}>
        <Text style={{ marginBottom: 35, marginHorizontal: 16, ...Typography.text(`center`, `base`) }}>
          Mesh needs to access your photos so you can share them in your communities.
        </Text>
        <ThemedButton
          rounded
          title="Go to Settings"
          titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.lightGray }}
          containerStyle={{ marginHorizontal: 16, marginBottom: 32 }}
          buttonStyle={{ paddingVertical: 10, alignItems: `center` }}
          onPress={goToSettings}
        />
      </View>
    </BottomSheetModal>
  );
};
