import React from 'react';
import LottieView from 'lottie-react-native';
import { Text, Image, View, ImageSourcePropType } from 'react-native';
import { Typography } from '../common-styles';

type EmptyListProps = {
  isLoading: boolean;
  message: string;
  detail?: string;
  image?: ImageSourcePropType;
  positionSwitch?: boolean;
};

export const EmptyList = React.memo(({ isLoading, image, message, detail, positionSwitch = false }: EmptyListProps) => {
  if (isLoading)
    return (
      <View style={{ flex: 1, justifyContent: `center`, alignItems: `center` }}>
        <LottieView
          resizeMode="cover"
          style={{ width: 200, height: 200 }}
          source={require(`../images/animated/animatedLoading.json`)}
          autoPlay
          loop
        />
      </View>
    );

  // programmers: if you're here because the image you want to put into EmptyList is smaller than 200 x 200,
  // and it's getting scaled/cut off, here's how to convert it to exactly 200 x 200 with ImageMagick:
  //
  //   convert magnifying.png -gravity center -background transparent -extent 200x200 magnifying.png
  //   convert magnifying@2x.png -gravity center -background transparent -extent 400x400 magnifying@2x.png
  //   convert magnifying@3x.png -gravity center -background transparent -extent 600x600 magnifying@3x.png

  return (
    <View style={{ flex: 1, justifyContent: `center`, alignItems: `center` }}>
      <View style={{ flexDirection: positionSwitch ? `column-reverse` : `column`, alignItems: `center` }}>
        {image && <Image source={image} style={{ width: 200, height: 200, marginTop: 46 }} />}
        <Text style={{ marginTop: 30, ...Typography.text(`bold`, `plusone`, `center`) }}>{message}</Text>
      </View>
      {detail && (
        <Text style={{ marginHorizontal: 32, marginVertical: 16, ...Typography.text(`plusone`, `center`) }}>{detail}</Text>
      )}
      <View style={{ minHeight: 150 }} />
    </View>
  );
});
