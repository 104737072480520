import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { setSelectedPreset, moveNext, finishWizard } from '../redux/createCommunitySlice';
import { KeyboardPaddingView, NoThemeButton } from '../common-ui';
import { Colors } from '../common-styles';
import { CommunityType } from './CommunityType';
import { HomeNavigator } from '../common-types/navigation-types';

// Step 1: Community Preset --> Select a preset 1/5
const CreateCommunity = () => {
  const navigation = useNavigation<HomeNavigator>();
  const dispatch = useDispatch();

  const handlePresetChange = useCallback(
    (preset) => {
      dispatch(setSelectedPreset(preset));
      dispatch(moveNext());
      navigation.navigate(`CommunityName`);
    },
    [dispatch, navigation],
  );

  useLayoutEffect(() => {
    const handlePrevPress = () => {
      dispatch(finishWizard());
      navigation.goBack();
    };

    navigation.setOptions({
      title: `Create a node`,
      headerLeft: () => (
        <NoThemeButton
          clear
          testID="WIZARD_BACK"
          color={Colors.iconColor}
          onPress={handlePrevPress}
          title="Cancel"
          titleStyle={{ color: Colors.iconColor, fontSize: 14, fontWeight: `500` }}
          containerStyle={{ left: 8 }}
        />
      ),
    });
  }, [dispatch, navigation]);

  return (
    <View style={{ flex: 1 }} testID="CREATE_COMMUNITY_WIZARD">
      <KeyboardPaddingView>
        <CommunityType onChangePreset={handlePresetChange} />
      </KeyboardPaddingView>
    </View>
  );
};

export default CreateCommunity;
