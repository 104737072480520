import React, { memo, useEffect, useState } from 'react';
import { View, Text, Linking, Alert } from 'react-native';
import { useLazyQuery } from 'react-apollo';
import { MessageTextProps } from 'stream-chat-react-native';
import HyperLink from 'react-native-hyperlink';
import { Colors } from '../../common-styles';
import { GET_INVITATION } from '../../graphql';
import { inviteRegex } from '../../post/hooks/useLinkPreview';
import { MeshChatGenerics } from '../chatClient';

type CustomMessageTextProps = MessageTextProps<MeshChatGenerics>;
export const CustomMessageText: React.FC<CustomMessageTextProps> = memo(({ message }) => {
  const { text } = message || {};

  const [textLink, setTextLink] = useState('');

  const [getInvitation, { data, error }] = useLazyQuery(GET_INVITATION, {
    fetchPolicy: `no-cache`,
  });

  useEffect(() => {
    console.log({ data }, { textLink });
    if (data && textLink) {
      Linking.openURL(textLink);
    } else if (error) {
      Alert.alert(`Invitation is expired.`);
    }
  }, [data, error, textLink]);

  return (
    <View style={{ paddingVertical: 10 }}>
      <HyperLink
        linkStyle={{ color: Colors.deepPurple }}
        onPress={(url) => {
          setTextLink(url);
          if (url.includes(`invitation`)) {
            const valid_url = url.match(inviteRegex);
            if (valid_url) {
              const [, , token] = valid_url;
              getInvitation({ variables: { invite_token: token } });
            }
          } else {
            Linking.openURL(url);
          }
        }}
      >
        <Text>{text}</Text>
      </HyperLink>
    </View>
  );
});
