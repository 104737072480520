import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import { Typography } from '../../common-styles';
import { CategoryItem, GroupCoreFields } from '../../common-types/types';
import { CategoryCard } from '../../explore/InterestList';
import { Events, PendoTrackEvent } from '../../pendo/events';

type CommunityCategoryTilesProps = {
  asFragment: boolean;
  group: Pick<GroupCoreFields, `name`>;
  categoryList: CategoryItem[];
};

type CommunityCategoryScreens = {
  CommunityCategoryTiles: CommunityCategoryTilesProps;
  CategoryListView: {
    categoryData: CategoryItem;
  };
};

type CommunityCategoryNavigation = StackNavigationProp<CommunityCategoryScreens>;

export const CommunityCategoryTiles: React.FC<CommunityCategoryTilesProps> = ({ asFragment, group, categoryList }) => {
  const navigation = useNavigation<CommunityCategoryNavigation>();
  const windowWidth = useWindowDimensions().width;

  const goToCategory = useCallback(
    (category) => {
      if (!asFragment) {
        PendoTrackEvent(Events.EXPLORE_CATEGORY, {
          category_name: category.value,
          community_name: group?.name,
          source: `about`,
        });
        navigation.push(`CategoryListView`, { categoryData: category });
      }
    },
    [navigation, asFragment, group?.name],
  );
  if (!categoryList)
    return (
      <View style={{}}>
        <Text style={{ ...Typography.text(`bold`, `plusone`), marginTop: 25, marginBottom: 10 }}>Categories</Text>
      </View>
    );
  return (
    <View style={{}}>
      <Text style={{ ...Typography.text(`bold`, `plusone`), marginTop: 25, marginBottom: 10 }}>Categories</Text>
      <View style={{ marginLeft: -18 }}>
        <View style={{ flexDirection: `row` }}>
          {categoryList.map((category) => (
            <CategoryCard key={category.value} category={category} windowWidth={windowWidth / 3} goToCategory={goToCategory} />
          ))}
        </View>
      </View>
    </View>
  );
};
