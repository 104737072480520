import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActivityIndicator, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-apollo';
import Modal from 'react-native-modal';
import Constants from 'expo-constants';
import { ReactNativeFile } from 'apollo-upload-client';
import { GroupCoreFields, GroupMember, TopicItem } from '../common-types/types';
import { getAsyncStorageObject, setAsyncStorageObject } from '../common-util';
import { Colors, Typography } from '../common-styles';
import { Avatar, BottomSheetModal, LoadingModal, MeshIcon } from '../common-ui';
import { AppContext } from '../../AppContext';
import TopicEditor from './TopicEditor';
import { ADD_ACTIVITY_3, UPDATE_BY_ID, UPDATE_POST_TOPICS, refetchQueriesFor } from '../graphql';
import { updateFeedItem } from '../redux/feedSlice';

import { CreatePostModals } from './CreatePost2';
import { Events, PendoTrackEvent } from '../pendo/events';
import { MediaTypes } from './helpers';
import { RootState } from '../redux/store';
import { setHideSecondModal, setSelectedTopics } from '../redux/postSlice';
import DateDuration from './DateDuration';

export type PostCreateType = {
  group_id: string;
  content: any;
  link_url: string | undefined;
  video_uploaded: boolean;
  meta_style: any;
  valid_mentions: never[];
  topic_ids?: string[];
  as_community?: boolean;
  title: string;
  poll?: {
    options: string[];
    end_time?: string;
  };
};

type PostEditorSecondType = {
  navigation: any;
  isVisible: boolean;
  setHide: () => void;
  headerTitle: string;
  submitTitle: string;
  group: GroupCoreFields;
  isAdmin: boolean;
  postingAs: string;
  payload: PostCreateType;
  postApproval: string | boolean;
  mediaToUpload: ReactNativeFile[];
  role_name: string;
  linkPreview: any;
  contentMode: any;
  mode: string;
  files: any;
  activity: any;
  media: any;
  resolvedMentions: any;
  directChangeByAdmin: boolean;
  group_member: GroupMember;
};

const PostingOptions = {
  Community: `community`,
  User: `user`,
};

const PostEditorSecond: React.FC<PostEditorSecondType> = ({
  navigation,
  isVisible,
  setHide,
  headerTitle,
  submitTitle,
  group,
  isAdmin,
  postingAs,
  payload,
  postApproval,
  mediaToUpload,
  role_name,
  linkPreview,
  contentMode,
  mode,
  files,
  activity,
  media,
  resolvedMentions,
  directChangeByAdmin,
  group_member,
}) => {
  const { user } = useContext(AppContext);
  const dispatch = useDispatch();
  const [posting, setPostingAs] = useState(postingAs);
  const [modal, setModal] = useState<string | null>();
  const [endTime, setEndTime] = useState<string | undefined>('');

  const { hideSecondModal, selectedTopics: topics } = useSelector((state: RootState) => state.post);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (activity && activity?.topics.length) {
      const filtered = activity.topics.filter((topic: TopicItem) => topic.topic.toLocaleLowerCase() !== `general`);
      dispatch(setSelectedTopics(filtered || []));
    }
  }, [activity]);

  useEffect(() => {
    const getPostingAsSetting = async () => {
      let setting = postingAs;
      if (mode === `create`) {
        const key = `as_community:${user?.id}:${group.id}`;
        setting = await getAsyncStorageObject(key);
      }
      if (setting) setPostingAs(setting);
      else setPostingAs(postingAs);
    };
    getPostingAsSetting();
  }, [group.id, mode, postingAs, user?.id]);

  const [createPost] = useMutation(ADD_ACTIVITY_3, {
    refetchQueries: refetchQueriesFor(`Group`, `Activity`, `GroupTopics`),
    awaitRefetchQueries: true,
    onError: (error) => console.error('[CreateError]', error.message),
  });

  const [updatePost] = useMutation(UPDATE_BY_ID, {
    update: (cache, { data: { updateActivity3 } }) => {
      cache.writeQuery({ query: ADD_ACTIVITY_3, data: { addActivity3: { ...updateActivity3, linkPreview } } });
      dispatch(updateFeedItem({ ...updateActivity3, linkPreview }));
    },
  });

  const [updatePostTopicsByAdmin] = useMutation(UPDATE_POST_TOPICS);

  const onAfterSubmit = useCallback(() => {
    dispatch(setSelectedTopics([]));
    try {
      setSubmitting(true);
      const mediaUploaded = !!mediaToUpload.length;
      let needsApproval = false;
      console.log(`has linkPreview`, !!linkPreview);
      needsApproval = postApproval === `approve` && role_name === `member` && mode === `create`;

      setTimeout(() => {
        setSubmitting(false);
        if (needsApproval) {
          setModal(CreatePostModals.NeedsApproval);
        } else if (mediaUploaded && contentMode === MediaTypes.VIDEO) {
          setModal(CreatePostModals.MediaUploaded);
        } else {
          navigation.goBack();
        }
      }, 2000);

      // Track Pendo for Create/Edit Posts
      PendoTrackEvent(Events.SUBMIT_POST, {
        community_name: group.name,
        content_type: contentMode,
        action: mode,
        account: user?.id,
      });
    } catch (error) {
      console.error(`[PostEditor.SubmitError]:`, error);
      setSubmitting(false);
    }
  }, [
    mediaToUpload.length,
    linkPreview,
    postApproval,
    role_name,
    mode,
    group.name,
    contentMode,
    user?.id,
    navigation,
    dispatch,
    setSelectedTopics,
  ]);

  const handleCreatePost = useCallback(async () => {
    const input = payload;
    if (!!endTime && !!input?.poll) input.poll.end_time = endTime;
    input.topic_ids = topics.map((t) => t.id);
    input.as_community = posting === PostingOptions.Community && isAdmin;
    try {
      await createPost({
        variables: {
          input,
          files,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [payload, topics, posting, isAdmin, createPost, endTime, files]);

  const handlePostTopicsByAdmin = useCallback(() => {
    const { id } = activity || {};
    if (!id) return;
    try {
      updatePostTopicsByAdmin({
        variables: {
          activity_id: id,
          topic_ids: topics.map((t) => t.id),
        },
      });
    } catch (error) {
      console.warn(`[handleEditPostTopics Error]`, error);
    }
  }, [activity, topics, updatePostTopicsByAdmin]);

  const handleEditPost = useCallback(() => {
    const { id, post_id, foreign_id } = activity || {};
    try {
      updatePost({
        variables: {
          id,
          post_id,
          foreign_id,
          set: {
            content: payload.content,
            meta_style: payload.meta_style,
            topic_ids: topics.map((t) => t.id),
            as_community: posting === PostingOptions.Community,
            imageUrls: contentMode === MediaTypes.IMAGE ? media : [],
            video_urls: contentMode === MediaTypes.VIDEO ? media : [],
            link_url: linkPreview?.url || linkPreview?.entered || null,
            title: payload.title || ``,
          },
          media_to_upload: mediaToUpload,
          media_type: contentMode,
          valid_mentions: resolvedMentions,
        },
      });
    } catch (error) {
      console.warn(`[handleEditPost Error]`, (error as Error).message);
    }
  }, [updatePost, activity, topics, posting, payload, contentMode, media, mediaToUpload, resolvedMentions, linkPreview]);

  const handleSubmit = useCallback(() => {
    if (mode === `create`) handleCreatePost();
    else if (mode === `edit`) {
      if (directChangeByAdmin) handlePostTopicsByAdmin();
      else handleEditPost();
    }
    onAfterSubmit();
  }, [handleCreatePost, handleEditPost, mode, directChangeByAdmin, handlePostTopicsByAdmin, onAfterSubmit]);

  const handleChangePollDuration = (duration: number) => {
    console.log(`Poll Duration: `, duration);
    const endTime = new Date().getTime() + 1000 * 60 * 60 * 24 * duration;
    setEndTime(String(endTime));
  };

  if (!isVisible) return null;
  return (
    <Modal
      isVisible={isVisible && !hideSecondModal}
      animationIn="slideInRight"
      animationOut="slideOutRight"
      swipeDirection="left"
      animationInTiming={500}
      animationOutTiming={500}
      hideModalContentWhileAnimating
      useNativeDriver
      propagateSwipe
      hasBackdrop={false}
      style={styles.modalContainer}
    >
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <MeshIcon
            name="chevron-left"
            onPress={() => {
              setHide();
              dispatch(setSelectedTopics([]));
            }}
          />
          <Text style={[styles.headerText, { paddingLeft: mode === `create` ? 20 : 0 }]}>{headerTitle}</Text>
          {submitting ? (
            <ActivityIndicator />
          ) : (
            <TouchableOpacity onPress={handleSubmit} disabled={submitting}>
              <Text style={styles.submitText}>{submitTitle}</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.mainContainer}>
          {isAdmin && !directChangeByAdmin && (
            <View style={{ marginBottom: 10 }}>
              <View style={styles.optionContent}>
                <Text style={styles.optionText}>Post as</Text>
              </View>
              <View style={styles.optionRow}>
                <Image
                  style={styles.optionAvatar}
                  source={group?.avatar_url ? { uri: group?.avatar_url } : require(`../../assets/images/icon.png`)}
                />
                <Text style={styles.optionName}>{group?.name}</Text>
                <MeshIcon
                  onPress={async () => {
                    setPostingAs(PostingOptions.Community);
                    const key = `as_community:${user?.id}:${group.id}`;
                    await setAsyncStorageObject(key, PostingOptions.Community);
                  }}
                  focused={posting === PostingOptions.Community}
                  name="select-circle"
                  color={Colors.brandPurple}
                />
              </View>
              <View style={styles.optionRow}>
                <Avatar user={user} size={30} style={{ marginRight: 8 }} />
                <Text style={styles.optionName}>{user?.handle}</Text>
                <MeshIcon
                  onPress={async () => {
                    setPostingAs(PostingOptions.User);
                    const key = `as_community:${user?.id}:${group.id}`;
                    await setAsyncStorageObject(key, PostingOptions.User);
                  }}
                  focused={posting === PostingOptions.User}
                  name="select-circle"
                  color={Colors.brandPurple}
                />
              </View>
            </View>
          )}

          <TopicEditor
            groupId={group?.id}
            isAdmin={isAdmin}
            group={group}
            role_name={role_name}
            group_member={group_member}
            onHideSecondPostModal={() => dispatch(setHideSecondModal(true))}
          />

          <DateDuration contentMode={contentMode} onChangeDuration={handleChangePollDuration} />
        </View>
      </View>
      {submitting && !(modal === CreatePostModals.NeedsApproval) && (
        <LoadingModal
          isVisible={submitting}
          content={mode === `create` ? `Posting to ${group?.name}` : `Saving updates to your post in ${group?.name}`}
        />
      )}
      <BottomSheetModal
        showCancelBtn={false}
        title="Post Submitted"
        visible={modal === CreatePostModals.NeedsApproval || modal === CreatePostModals.MediaUploaded}
        onPressCancel={() => setModal(null)}
        confirmTitle="Got it"
        onPressConfirm={() => navigation.goBack()}
      >
        <View style={{ marginTop: 12, marginHorizontal: 16 }}>
          <Text style={{ marginBottom: 35, marginHorizontal: 16, ...Typography.text(`center`, `plusone`) }}>
            {modal === CreatePostModals.NeedsApproval
              ? `Your post was submitted for moderator approval. You will receive a notification when your post has been approved.`
              : `Your post will be published once processing is complete.`}
          </Text>
        </View>
      </BottomSheetModal>
    </Modal>
  );
};

export default PostEditorSecond;

const styles = StyleSheet.create({
  modalContainer: {
    margin: 0,
    backgroundColor: `white`,
  },
  container: {
    flex: 1,
    paddingTop: Platform.OS === `ios` ? Constants.statusBarHeight + 10 : 0,
    paddingBottom: 10,
  },
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.dividerColor,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    paddingBottom: 10,
    paddingHorizontal: 18,
    marginTop: -1,
  },
  mainContainer: {},
  headerText: {
    fontSize: 17,
    fontWeight: `600`,
  },
  submitText: {
    color: Colors.deepPurple,
    fontWeight: `bold`,
  },
  optionContent: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.dividerColor,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  optionText: {
    fontSize: 15,
    fontWeight: `600`,
  },
  optionRow: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    paddingHorizontal: 20,
    marginTop: 10,
  },
  optionAvatar: {
    width: 30,
    height: 30,
    borderRadius: 30,
    marginRight: 10,
  },
  optionName: {
    flex: 1,
    paddingHorizontal: 10,
  },
});
