import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import FastImage from 'react-native-fast-image';
import { useDispatch } from 'react-redux';
import { useLazyQuery, useQuery } from 'react-apollo';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Colors, Spacing, Typography } from '../common-styles';
import { parseIntroDataType, GroupAndGroupMember, Setting } from '../common-types/types';
import { MeshIcon } from '../common-ui';
import { VideoPlayer } from '../post/components/VideoPlayer';
import { setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';
import { GET_GROUP, GET_RANDOM_INTRO_VIDEO } from '../graphql';
import { AppContext } from '../../AppContext';

type ExploreIntroVideoNavigationScreens = {
  HomeScreen: undefined;
  CommunityPreview: undefined;
};

type ExploreIntroVideoNavigation = NativeStackNavigationProp<ExploreIntroVideoNavigationScreens>;

type RandomVideoData = {
  getRandomIntroVideo?: Setting;
};
export const ExploreIntroVideo: React.FC = () => {
  const { user } = useContext(AppContext);
  const { width: windowWidth } = useWindowDimensions();
  const width = Math.min(Spacing.standardWidth, windowWidth);
  const videoDim = { width, height: (width * 9) / 16 };
  const [showVideo, setShowVideo] = useState(false);
  const [fetchedGroupData, setFetchedGroupData] = useState<GroupAndGroupMember>({} as GroupAndGroupMember);
  const [introVideo, setIntroVideo] = useState<parseIntroDataType>();

  const { data: randomVideoData } = useQuery<RandomVideoData>(GET_RANDOM_INTRO_VIDEO, {
    variables: { user_id: user?.id },
    fetchPolicy: `cache-and-network`,
  });
  const [getGroup, { data }] = useLazyQuery(GET_GROUP, {
    fetchPolicy: `cache-first`,
  });

  useEffect(() => {
    if (randomVideoData?.getRandomIntroVideo?.value) {
      const { group_id, value } = randomVideoData?.getRandomIntroVideo ?? {};
      const parsedValue = JSON.parse(value as string);
      parsedValue.group_id = group_id;
      setIntroVideo(parsedValue);
      if (group_id) getGroup({ variables: { group_id } });
    }
  }, [randomVideoData, getGroup]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (showVideo) setShowVideo(false);
      };
    }, [showVideo]),
  );

  useEffect(() => {
    if (data?.getGroup) {
      setFetchedGroupData(data?.getGroup);
    }
  }, [data]);

  const setVideoDisplayed = () => setShowVideo(true);

  if (!introVideo)
    return (
      <>
        <View style={[styles.dataPending, { width: width - 12 * 2, height: (width * 9) / 16 }]}>
          <ActivityIndicator />
        </View>
        <NavToCommunityButton fetchedGroupData={fetchedGroupData} />
      </>
    );

  if (showVideo) {
    return (
      <View style={{ marginTop: 16, width: windowWidth }}>
        <VideoPlayer
          width={width}
          height={(width * 9) / 16}
          video_url={introVideo?.video_url}
          onScreen={true}
          onLayout={() => {}}
        />
        <NavToCommunityButton fetchedGroupData={fetchedGroupData} />
      </View>
    );
  }
  return (
    <View style={styles.thumbnailContainer}>
      <FastImage
        style={{ ...videoDim, backgroundColor: Colors.black }}
        source={{ uri: introVideo?.thumbnail_url }}
        resizeMode="cover"
      />
      <View style={{ position: `absolute` }}>
        <MeshIcon
          name="play-circle"
          size={62}
          color={Colors.white}
          onPress={setVideoDisplayed}
          style={{ backgroundColor: Colors.black, borderRadius: 31, overflow: `hidden` }}
        />
      </View>
      <NavToCommunityButton fetchedGroupData={fetchedGroupData} />
    </View>
  );
};

const NavToCommunityButton: React.FC<{ fetchedGroupData: any }> = ({ fetchedGroupData }) => {
  const navigation = useNavigation<ExploreIntroVideoNavigation>();
  const { user } = useContext(AppContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleGoToCommunity = useCallback(() => {
    try {
      setLoading(true);
      const { group, group_member } = fetchedGroupData;
      if (!group_member || group.application_pending) {
        dispatch(setPreviewCommunity(group));
        navigation.navigate(`CommunityPreview`);
      } else if (!group_member.banned && !!user?.id) {
        dispatch(setActiveCommunity({ user_id: user.id, group }));
        navigation.navigate(`HomeScreen`);
      }
    } catch (error) {
      console.error(`An error ocurred trying to fetch a community's data `, error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, navigation, user, fetchedGroupData]);
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={handleGoToCommunity} disabled={loading} style={styles.button}>
      <MeshIcon name="arrow-right" color={Colors.brandPurple} size={15} />
      <Text style={{ ...Typography.text(`small`, `theme`), marginLeft: 5 }}>Check out {fetchedGroupData?.group?.name}</Text>
      {loading ? <ActivityIndicator style={{ marginLeft: 5 }} color={Colors.brandPurple} /> : undefined}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  dataPending: {
    justifyContent: `center`,
    alignItems: `center`,
    marginHorizontal: 12,
    marginTop: 12,
    backgroundColor: `${Colors.white}66`,
    borderRadius: 12,
  },
  thumbnailContainer: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginTop: 16,
  },
  button: {
    backgroundColor: Colors.white,
    alignSelf: `flex-start`,
    marginTop: 12,
    borderRadius: 8,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginLeft: 16,
  },
});
