import { Platform, StyleSheet, Dimensions } from 'react-native';
import * as Colors from './colors';
import * as Typography from './typography';

/** DO NOT USE - any use of Dimensions in a module-level variable only obtains window size once when app initializes */
export const _bad_do_not_use_HEIGHT = Dimensions.get(`window`).height;

/** DO NOT USE - any use of Dimensions in a module-level variable only obtains window size once when app initializes */
export const _bad_do_not_use_WIDTH = Dimensions.get(`window`).width;

export const globalStyles = StyleSheet.create({
  settingWrapper: {
    flex: 1,
    position: `relative`,
  },
  centeredContainer: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
  },
  absoluteContainer: {
    position: `absolute`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: `#fff`,
    opacity: 0.8,
    zIndex: 10,
  },
  loadingText: {
    ...Typography.text(`center`, `gray`, `bold`),
    marginTop: 10,
  },
  tabIcon: { fontSize: 10, color: Colors.iconColor },
  focusedTabIcon: { fontSize: 10, color: Colors.brandPurple },
});

export const headerBottomBorderStyle = {
  borderColor: `#ECECEC`,
  borderWidth: 0,
  borderBottomWidth: StyleSheet.hairlineWidth,
};

export const headerNoBottomBorderStyle = {
  borderBottomWidth: 0,
  shadowColor: `transparent`,
};

export const headerShadowStyle = Platform.select({
  ios: { shadowColor: Colors.gray, shadowOffset: { width: 2, height: 2 }, shadowOpacity: 0.2, shadowRadius: 4 },
  android: { elevation: 5 },
});

// disables header even on Android, where it is the default
export const headerNoShadowStyle = Platform.select({
  ios: { shadowRadius: 0 },
  android: { elevation: 0 },
  web: { boxShadow: `none` },
});

export const headerStyle = {
  backgroundColor: Colors.white,
  ...headerBottomBorderStyle,
  ...headerNoShadowStyle,
};
