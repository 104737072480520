// constants.js
import { getEnvVariables } from './environment';

export const DUMMY_UUID = `00000000-0000-0000-0000-000000000000`;

export const DELETED_USER = {
  __typename: ``,
  id: DUMMY_UUID,
  handle: `[deleted user]`,
  name: `[deleted user]`,
  description: ``,
  avatar_url: ``,
  splash_url: ``,
  identity_verified: false,
  pronouns: [],
};

export const GUEST_ACCOUNT = `5eb12be0-163f-455a-9a3e-b9dd7aaadd7a`;

export const MAX_IMAGES_PER_POST = 10;

export const MAX_IMAGES_PER_COMMENT = 1;

export const MODAL_SWITCH_TIME = 1000;

export const NOTIFICATIONS_PER_FETCH = 20;

export const VERSION_TO_COMPARE = `1.2.1`;

export const BETA_CHANNEL = `1.3.1`;

export const MESH_MODERATION_GROUP = `6285531e-7ff2-4e55-ba98-86d133227f6f`;

export const INVITE_FORMAT =
  /(https:\/\/app\.meshconnect\.us\/|mesh:\/\/)(\?invitation&token=|invitation\/)([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/gim;

const { paymentUrl } = getEnvVariables();
export const BASE_PAYMENT_API_URL = paymentUrl;
