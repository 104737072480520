import { Alert, StyleSheet, Text, TextInput, View, TouchableOpacity, TouchableWithoutFeedback, Keyboard } from 'react-native';
import React, { useEffect, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { MESH_MODERATION_GROUP } from '../../constants';
import { Colors, Typography, _bad_do_not_use_HEIGHT, _bad_do_not_use_WIDTH } from '../common-styles';
import { Divider, KeyboardPaddingView, SafeAreaView, ThemedButton } from '../common-ui';
import { LoadingIndicator } from '../common-ui/LoadingIndicator';
import { client, refetchQueriesFor, REPORT_USER } from '../graphql';
import { AgreementsView } from './AgreementsView';
import { MODERATION_TYPE } from './moderationEnums';
import { reportModerationIssue } from './moderationServices';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';
import { DirectModIssue } from '../common-types/types';

const HideKeyboard: React.FC = ({ children }) => (
  <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>{children}</TouchableWithoutFeedback>
);
/**
 *  Required params per specific reportType
 *
 *  MODERATION_TYPE.user
 *    - reportee
 *  MODERATION_TYPE.post
 *    - id
 *    - origin_group_id
 *  MODERATION_TYPE.comment
 *    - id
 *    - reaction_id
 *    - origin_group_id
 *  MODERATION_TYPE.community
 *    - origin_group_id
 */

type ReportRoute = RouteProp<HomeNavigatorScreens, `ReportScreen`>;

type Reason = {
  id?: string;
  label?: string;
  body?: string;
};

const ReportScreen: React.FC = () => {
  const navigation = useNavigation<HomeNavigator>();
  const route = useRoute<ReportRoute>();
  const { id, reportType, origin_group_id, reaction_id, reportee: reportee_id, role, reporteeName } = route?.params || {};
  const [reasonsSelected, setReasonSelected] = useState<Reason[]>([]);
  const [explanation, setExplanation] = useState(``);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isFocused, setFocus] = useState(false);

  const disabled = isSubmitting || explanation.trim().length === 0 || reasonsSelected.length === 0;

  const cancelReport = () => {
    setReasonSelected([]);
    setExplanation(``);
    onClose();
  };

  const onClose = () => {
    navigation.goBack();
  };

  useEffect(() => {
    const renderHeaderTitle = () => (
      <Text style={{ ...StyleSheet.flatten(localStyles.headerText), color: Colors.darkestGray }} testID="REPORT_TITLE">
        Report {reportType === 'profile_feed' ? 'post' : reportType !== `community` ? reportType : `node`}
      </Text>
    );
    const goToTakeAction = () => {
      const reasonIds = reasonsSelected.map((reason: Reason) => `${reason.id}`);
      let input;

      if (reportType === `user`) {
        input = {
          group_id: MESH_MODERATION_GROUP, // note: this will ALWAYS be Mesh Moderation as per Jessica's instruction
          reportee_id: reportee_id, // id of the user who is being reported
          reasons: reasonIds,
          explanation,
        };
      } else {
        input = {
          group_id: origin_group_id,
          reaction_id: reaction_id || null, // reaction id e.g. comments - report comment
          object_id: id || null, // activity id - report activity
          reasons: reasonIds,
          explanation,
          type: reportType,
        };
      }

      const moderationIssue: DirectModIssue = {
        type: reportType,
        updated_at: new Date(),
        status: `UNDEF`,
        actions: null,
        reportee: { id: reportee_id },
        activity: null,
      };

      navigation.navigate(`ModerationTakeAction`, {
        moderationIssue,
        reportPayload: input,
        customType: reportType,
        prev_screen: `ReportScreen`,
        values: route.params,
        reporteeName,
      });
    };

    navigation.setOptions({
      headerTitle: () => renderHeaderTitle(),
      headerRight: () =>
        (reportType === `user` || reportType === `post` || reportType === `comment`) &&
        (role === `manager` || role === `owner`) && (
          <TouchableOpacity onPress={goToTakeAction} disabled={disabled}>
            <View style={{ right: 16 }}>
              <Text style={{ ...Typography.text(`base`, `bold`), color: disabled ? Colors.iconColor : Colors.brandPurple }}>
                Take Action
              </Text>
            </View>
          </TouchableOpacity>
        ),
    });
  }, [
    navigation,
    disabled,
    reasonsSelected,
    explanation,
    role,
    reportType,
    reportee_id,
    origin_group_id,
    reaction_id,
    id,
    reporteeName,
    route?.params,
  ]);

  const submitModerationIssueReport = async () => {
    console.log(`=== firing submitModerationIssueReport ===`);

    setSubmitting(true);

    const reasonIds = reasonsSelected.map((reason) => `${reason.id}`);

    const input: any = {
      group_id: origin_group_id,
      reaction_id: reaction_id || null, // reaction id e.g. comments - report comment
      object_id: id || null, // activity id - report activity
      reasons: reasonIds,
      explanation,
      type: reportType,
    };
    try {
      const reported: any = await reportModerationIssue(input);
      if (reported && reported.res_status === `200OK`) {
        Alert.alert(`Report sent!`, ``, [{ text: `OK`, onPress: cancelReport, style: `default` }]);
      } else {
        Alert.alert(`A problem occurred submitting your report`, `Please try again later`, [
          { text: `OK`, onPress: cancelReport, style: `default` },
        ]);
      }
    } catch (err) {
      console.error(`exception thrown in submitModerationIssueReport in ReportScreen.jsx`, (err as Error).message);
    }

    return setSubmitting(false);
  };

  const reportUser = async () => {
    console.log(`=== firing report user ===`);
    try {
      const reasonIds = reasonsSelected.map((reason) => reason.id);

      const input = {
        group_id: MESH_MODERATION_GROUP, // note: this will ALWAYS be Mesh Moderation as per Jessica's instruction
        reportee_id: reportee_id, // id of the user who is being reported
        reasons: reasonIds,
        explanation,
      };

      const { data } = await client.mutate({
        mutation: REPORT_USER,
        variables: { input },
        refetchQueries: refetchQueriesFor(`ModerationIssue`),
      });
      const reportedUserIssue = data.reportUser;
      console.log(`reported user issue`, reportedUserIssue);
      if (reportedUserIssue && reportedUserIssue.res_status === `200OK`) {
        Alert.alert(`Report sent!`, ``, [{ text: `OK`, onPress: cancelReport, style: `default` }]);
      } else {
        Alert.alert(`A problem occurred submitting your report`, `Please try again later`, [
          { text: `OK`, onPress: cancelReport, style: `default` },
        ]);
      }
      return reportedUserIssue;
    } catch (err) {
      console.error(`Error thrown in reportUser() in ReportScreen.tsx`, (err as Error).message);
      return null;
    }
  };

  const setReasons = (reasons: Reason[]) => {
    setReasonSelected(reasons);
    Keyboard.dismiss();
  };

  const renderReasonsMessage = (reasonsSelected: Reason[]) => {
    let message = ``;
    if (reasonsSelected.length > 1) message = `You have selected ${reasonsSelected.length} reasons. `;
    if (reasonsSelected.length === 1) message = `You have selected 1 reason. `;
    if (explanation.length === 0) message = message.concat(`Brief explanation (required)`);

    return message;
  };

  const setTextboxFocus = (isFocused: boolean) => {
    setFocus(isFocused);
  };

  const handleChange = (text: string) => {
    setExplanation(text);
  };

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <HideKeyboard>
          <View style={localStyles.innerContainer}>
            <View style={{ margin: 10 }}>
              <Text style={{ color: Colors.darkGray }}>
                This {reportType === 'profile_feed' ? 'post' : reportType} violates the following agreements (select all that
                apply):
              </Text>
            </View>

            <Divider style={{ width: `100%` }} />
            <AgreementsView
              origin_group_id={origin_group_id}
              isUser={reportType === MODERATION_TYPE.user}
              reasons={reasonsSelected}
              setReasons={setReasons}
              isCommunityReport={reportType === MODERATION_TYPE.community}
            />
            <Divider style={{ width: `100%` }} />
            <View style={localStyles.textInputContainer}>
              <Text style={localStyles.briefExplanation}>{renderReasonsMessage(reasonsSelected)}</Text>
              <TextInput
                multiline
                scrollEnabled
                blurOnSubmit
                value={explanation}
                testID="REPORT_REASON"
                onChangeText={handleChange}
                placeholder="Explain why you are reporting this"
                placeholderTextColor={Colors.textPlaceholder}
                style={{
                  ...StyleSheet.flatten(localStyles.textInput),
                  borderColor: isFocused ? Colors.iconColor : Colors.mediumGray,
                  borderWidth: isFocused ? 2 : 1,
                  textAlignVertical: `top`,
                }}
                onFocus={() => setTextboxFocus(true)}
                onBlur={() => setTextboxFocus(false)}
              />
              <ThemedButton
                rounded
                disabled={disabled}
                title="Submit"
                testID={`REPORT_SUBMIT${disabled ? `_DISABLED` : ``}`}
                buttonStyle={{ padding: 10 }}
                onPress={!(reportType === MODERATION_TYPE.user) ? submitModerationIssueReport : reportUser}
                leftIcon={isSubmitting && <LoadingIndicator />}
              />
            </View>
          </View>
        </HideKeyboard>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

export default ReportScreen;

const localStyles = StyleSheet.create({
  headerText: {
    ...Typography.text(`plustwo`, `bold`),
    fontWeight: `600`,
  },
  innerContainer: {
    flex: 1,
    margin: 10,
    justifyContent: `flex-end`,
  },
  briefExplanation: {
    fontSize: Typography.extraSmallFontSize,
    fontWeight: `500`,
    color: Colors.mediumGray,
  },
  textInputContainer: {
    flexShrink: 1,
    margin: 10,
  },
  textInput: {
    flexShrink: 1,
    borderWidth: 1,
    borderColor: Colors.mediumGray,
    padding: 6,
    borderRadius: 4,
    margin: 10,
    minHeight: 100,
    maxHeight: 125,
  },
});
