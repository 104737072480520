import { client } from './Client';
import {
  ACTIVITY_DETAILS_FRAGMENT,
  GROUP_DETAILS_FRAGMENT,
  GROUP_CORE_DETAILS_FRAGMENT,
  GROUP_INVITATION_DETAILS_FRAGMENT,
  GROUP_MEMBER_DETAILS_FRAGMENT,
  REACTION_DETAILS_FRAGMENT,
} from './Fragments.gql';
import * as Queries from './Queries.gql';

//Fix import cycle by exporting queries from within cache
export * from './Queries.gql';
// local stuff

const verbose = false;

const cleanActivity = (activity, overrides = {}) => {
  const cleaned_activity = { ...activity, ...overrides };
  if (!cleaned_activity.reaction_counts) cleaned_activity.reaction_counts = { __typename: `ReactionCounts`, like: 0, comment: 0 };
  if (!cleaned_activity.time) cleaned_activity.time = Date.now();
  return cleaned_activity;
};

const cleanReaction = (reaction, overrides = {}) => {
  const cleaned_reaction = { ...reaction, ...overrides };
  return cleaned_reaction;
};

// exported functions

// eslint-disable-next-line complexity
export const updateCache = ({ persona_id, group, group_member, group_invitation, activity, reaction }) => {
  console.log(`updateCache`);

  // group
  if (group?.__typename === `Group`) {
    if (verbose) console.log(`updating cache with group:`, JSON.stringify(group, null, 2));
    client.writeFragment({
      id: `Group:${group.id}`,
      fragment: GROUP_DETAILS_FRAGMENT,
      fragmentName: `GroupDetails`,
      data: group,
    });
  }

  // group_member
  if (group_member?.__typename === `GroupMember`) {
    if (verbose) console.log(`updating cache with group_member:`, JSON.stringify(group_member, null, 2));
    client.writeFragment({
      id: `GroupMember:${group_member.id}`,
      fragment: GROUP_MEMBER_DETAILS_FRAGMENT,
      fragmentName: `GroupMemberDetails`,
      data: group_member,
    });
  }

  if (group && group_member === null) {
    const gm_id = `persona_id:${persona_id},group_id:${group.id}`;
    if (verbose) console.log(`deleting group_member:`, gm_id);
    client.cache.data.delete(`GroupMember:${gm_id}`);
  }

  // group_invitation
  if (group_invitation?.__typename === `GroupInvitation`) {
    if (verbose) console.log(`updating cache with invitation: ${group_invitation}`);
    client.writeFragment({
      id: `GroupInvitation:${group_invitation.invite_token}`,
      fragment: GROUP_INVITATION_DETAILS_FRAGMENT,
      fragmentName: `GroupInvitationDetails`,
      data: group_invitation,
    });
  }

  // activity
  if (activity?.__typename === `Activity`) {
    if (verbose) console.log(`updating cache with activity: ${activity}`);
    client.writeFragment({
      id: `Activity:${activity.id}`,
      fragment: ACTIVITY_DETAILS_FRAGMENT,
      fragmentName: `ActivityDetails`,
      data: cleanActivity(activity),
    });
  }

  // reaction
  if (reaction?.__typename === `ActivityReaction`) {
    if (verbose) console.log(`updating cache with reaction: ${reaction}`);
    client.writeFragment({
      id: `ActivityReaction:${reaction.id}`,
      fragment: REACTION_DETAILS_FRAGMENT,
      fragmentName: `ActivityReactionDetails`,
      data: cleanReaction(reaction),
    });
  }

  console.log(`updateCache complete`);
};

// List of which queries use which fragments. most useful for refetchQueries param in client.mutate.
// DYNAMICALLY GENERATED at module load time from queries that exist in Queries.gql.js!
export const queriesFor = {};
Object.values(Queries).forEach((query) => {
  // We are iterating the javascript objects exported from this file. Most (if not all) will be
  // gql template literals. These are turned into individual graphql document objects with the following structure:
  // { kind: `Document`, definitions: [...], loc: { start: 0, end: N } }
  const { _kind, definitions = [], _loc } = query || {};

  // Iterate definitions to gather all queries that are called.
  // If the document calls a query, we should find one (or more!) query names.
  const query_names = [];
  definitions.forEach((def) => {
    if (def.kind === `OperationDefinition` && def.operation === `query` && def.name?.kind === `Name`)
      query_names.push(def.name.value);
  });
  if (!query_names.length) return;

  // Iterate definitions to gather all fragments that are used.
  definitions.forEach((def) => {
    if (
      def.kind === `FragmentDefinition` &&
      def.typeCondition &&
      def.typeCondition.kind === `NamedType` &&
      def.typeCondition.name
    ) {
      const typename = def.typeCondition.name.value;
      //query_names.forEach((query_name) => console.log(`${query_name} returns fragment ${typename}`));
      if (!queriesFor[typename]) queriesFor[typename] = [];
      queriesFor[typename].push(...query_names);
    }
  });
});

/**
 * refetches any query that includes any of the fragments imported in its definition
 * @param {string[]} fragments
 * @returns any[]
 */
export const refetchQueriesFor = (...fragments) => {
  const queries = [];
  fragments.forEach((fragment) => {
    const qff = queriesFor[fragment] || [];
    if (!qff.length) console.log(`refetchQueriesFor: unrecognized fragment ${fragment}, no queries use it`);
    queries.push(...qff);
  });
  return [...new Set(queries)].sort();
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Cache accessors

/**
 * load Group from Cached by group id
 * @param {string | undefined} group_id
 * @returns Group | undefined
 */
export const loadCachedGroup = (group_id) => {
  let group;
  if (group_id) {
    try {
      group = client.cache.readFragment(
        {
          id: `Group:${group_id}`,
          fragment: GROUP_CORE_DETAILS_FRAGMENT,
          fragmentName: `GroupCoreDetails`,
          data: { ...GROUP_CORE_DETAILS_FRAGMENT },
        },
        true,
      );
    } catch (err) {} // eslint-disable-line no-empty
  }

  //FIXME: too many calls re-renders?
  //console.log(`loadCachedGroup(${group_id}):`, group?.name);
  return group;
};

/**
 * load GroupMember from Cached by group id and persona id
 * @param {string | undefined} group_id
 * @param {string | undefined} persona_id
 * @return {GroupMember | undefined}
 */
export const loadCachedGroupMember = (group_id, persona_id) => {
  let group_member;
  if (group_id && persona_id) {
    try {
      const group_member_id = `persona_id:${persona_id},group_id:${group_id}`;
      group_member = client.cache.readFragment(
        {
          id: `GroupMember:${group_member_id}`,
          fragment: GROUP_MEMBER_DETAILS_FRAGMENT,
          fragmentName: `GroupMemberDetails`,
          // data: { ...GROUP_MEMBER_DETAILS_FRAGMENT },
        },
        true,
      );
    } catch (err) {
      // console.log(" ~ loadCachedGroupMember ~ err", err);
    }
  }
  // Uncomment to see the cache contents
  // console.log(`loadCachedGroupMember(${group_id}):`, group_member?.role_name);
  return group_member;
};
