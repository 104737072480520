import React from 'react';
import { View, Text, TextInput, TextInputProps, StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { Tooltip } from 'react-native-elements';
import { MeshIcon } from '..';
import { Colors, Typography } from '../../common-styles';

type Props = TextInputProps & {
  isError?: boolean;
  tooltip?: string;
  tooltipWidth?: number;
  tooltipHeight?: number;
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
};

const Input: React.FC<Props> = ({ isError, style, inputStyle, tooltip, tooltipWidth, tooltipHeight, ...rest }) => {
  return (
    <View style={[styles.root, style]}>
      <View style={isError ? { ...styles.container, ...styles.errorContainer } : styles.container}>
        <TextInput style={[styles.input, inputStyle]} placeholderTextColor={Colors.textPlaceholder} {...rest} />
        {isError && (
          <View style={styles.errorIcon}>
            <MeshIcon name="alert-circle" color={Colors.textRed} size={24} />
          </View>
        )}
      </View>

      {tooltip && tooltipHeight && tooltipWidth ? (
        // @ts-ignore
        <Tooltip
          popover={<Text style={Typography.text(`white`)}>{tooltip}</Text>}
          backgroundColor={Colors.brandPurple}
          height={tooltipHeight}
          width={tooltipWidth}
        >
          <MeshIcon name="info-circle" color={Colors.brandPurple} size={24} focused style={{ paddingLeft: 12, paddingTop: 8 }} />
        </Tooltip>
      ) : null}
    </View>
  );
};

export default Input;

const styles = StyleSheet.create({
  root: {
    display: `flex`,
    alignItems: `center`,
    borderWidth: 0,
    flexDirection: `row`,
  },
  container: {
    marginTop: 8,
    position: `relative`,
    borderColor: `#BEB9B8`,
    borderRadius: 4,
    borderWidth: 1,
    zIndex: 1,
    flex: 1,
  },
  input: {
    flexDirection: `row`,
    alignItems: `center`,
    height: 45,
    paddingLeft: 4,
  },
  errorContainer: {
    paddingRight: 44,
    borderColor: Colors.textRed,
  },
  errorIcon: {
    position: `absolute`,
    right: 10,
    top: 10,
    zIndex: 2,
  },
});
