import { createConversation } from '../chat/chatHelpers';
import { BAN_USER_FROM_GROUP_DIRECT, client, refetchQueriesFor, REMOVE_USER_FROM_GROUP, UPDATE_ROLE } from '../graphql';

//#region roles
export const owner = `9647345f-3c08-4cd8-968d-253ba61f6dca`;
export const manager = `9017763d-288e-40a1-9023-f85601a25861`;
export const moderator = `3f948f3f-c763-4aeb-8c2d-c04e5792fc3d`;
export const creator = `885a1200-b1f9-416b-8ecc-60454c9a7d26`;
export const member = `7c062da3-3ccf-4431-8fc3-3def7bf65b00`;
//#endregion

export const handleCreateConversation = async (context, user, navigation) => {
  try {
    const { me, chatNotifications = 0, setChatNotifications, setChannel } = context;
    if (!me || !user) return;
    const { conversation, _otherMembers } = await createConversation(me.id, user.id);
    const { unreadCount = 0 } = conversation?.state;
    const tentativeNewCount = Math.max(chatNotifications - unreadCount, 0);
    if (tentativeNewCount !== chatNotifications) setChatNotifications(tentativeNewCount);

    if (conversation) {
      const otherMembers = Object.values(conversation?.state?.members)
        .map((mb) => mb?.user)
        .filter((mb) => mb?.id !== me?.id);

      const params = {
        channel_type: conversation.type,
        channel_id: conversation.id,
      };

      if (!otherMembers.length) params.isRequested = { user };

      setChannel(conversation);
      navigation.navigate(`ChatNavigator`, {
        screen: `ChatThread`,
        params,
      });
    }
  } catch (error) {
    console.error(`Error thrown in handleCreateConversation()`, error.message);
    // Alert.alert(`Error creating conversation`, { cancelable: true });
  }
};

export const updateGroupMemberRole = async (persona_id, role_id, group_id) => {
  const input = {
    group_id,
    persona_id,
    role_id,
  };
  try {
    await client.mutate({
      mutation: UPDATE_ROLE,
      variables: input,
      refetchQueries: refetchQueriesFor(`GroupMember`, `Persona`, `Group`),
    });
  } catch (error) {
    console.error(`Error thrown in updateGroupMemberRole()`, error.message);
  }
};

export const removeGroupMember = async (persona_id, group_id) => {
  try {
    const { data } = await client.mutate({
      mutation: REMOVE_USER_FROM_GROUP,
      variables: {
        target_persona_id: persona_id,
        group_id,
      },
      refetchQueries: refetchQueriesFor(`GroupMember`, `Persona`, `Group`),
    });
    console.log(`removeGroupMember - data`, data);
  } catch (error) {
    console.error(`Error thrown in removeGroupMember()`, error.message);
  }
};

export const banUser = async (persona_id, group_id) => {
  if (persona_id && group_id) {
    const { data } = await client.mutate({
      mutation: BAN_USER_FROM_GROUP_DIRECT,
      variables: {
        group_id,
        target_persona_id: persona_id,
      },
      refetchQueries: refetchQueriesFor(`GroupMember`, `Persona`),
    });
    console.log(`data`, data);
  }
};
