import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Linking, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import ParsedText from 'react-native-parsed-text';
import { Events, PendoTrackEvent } from '../pendo/events';
import { getReleaseChannel } from '../../environment';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import {
  AuthMainText,
  AuthSubText,
  BackButton,
  HeaderSubmitButton,
  KeyboardPaddingView,
  LoadingIndicator,
  PasswordInput,
  MeshIcon,
  SafeAreaView,
} from '../common-ui';
import { setAsyncStorageObject } from '../common-util';

import { client, UPDATE_IDENTITY_PASSWORD_AND_TERMS } from '../graphql';
import { loginIdentity } from './helpers';
import {
  PASSWORDSCREEN,
  PASSWORDSCREEN_PASSWORD,
  CREATEPASSWORD,
  PASSWORDSCREEN_CONTINUE,
} from './testLabels';
import { AuthNavigationContainer, AuthNavigatorScreens } from '../common-types/navigation-types';

type PasswordNavigator = StackNavigationProp<AuthNavigationContainer, `Auth`>;

type PasswordRoute = RouteProp<AuthNavigatorScreens, `CreatePassword`>;

const meshconnect = `https://meshconnect.us/`;
const passwordRegex = /[^A-Za-z0-9]/;

const openLink = (path: string) => {
  Linking.openURL(`${meshconnect}${path}`);
};

export const CreatePassword = () => {
  const navigation = useNavigation<PasswordNavigator>();
  const route = useRoute<PasswordRoute>();
  const { identity_id, handle } = route?.params || { identity_id: null };

  const { setIdentity } = useContext(AppContext);
  const [password, setPassword] = useState(``);
  const [validPassword, setValidPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const createPassword = useCallback(
    async (password) => {
      try {
        const { data } = await client.mutate({
          mutation: UPDATE_IDENTITY_PASSWORD_AND_TERMS,
          variables: { identity_id, set: { password, agreed_terms: true } },
          fetchPolicy: `no-cache`,
        });
        const updated = data.updateIdentityPasswordAndAgreedTerms;

        const loggedIdentity = await loginIdentity(identity_id, password);
        if (!loggedIdentity?.id) throw new Error(`No identity returned`);

        const identity = {
          ...loggedIdentity,
          loginTime: new Date(),
          env: getReleaseChannel() || `dev`,
        };
        setIdentity(identity);

        await setAsyncStorageObject(`identity`, identity);

        return updated;
      } catch (err: any) {
        console.error(`[createPassword]`, err.message);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [identity_id, setIdentity],
  );

  const onSubmit = useCallback(async () => {
    if (identity_id && validPassword) {
      setLoading(true);
      const response = await createPassword(password);
      if (response) {
        PendoTrackEvent(Events.PASSWORD_CONTINUE, { username: handle });
        navigation.navigate(`Auth`, { screen: `RegisterEmail`, params: { identity_id, handle } });
      }
    }
  }, [password, createPassword, identity_id, navigation, setLoading, validPassword, handle]);

  const onChangePassword = (text: string) => {
    setPassword(text);
    const has8chars = text?.trim().length >= 8;
    const hasSpecialChar = passwordRegex.test(text);
    if (!has8chars) {
      setValidPassword(false);
    } else if (!hasSpecialChar) {
      setValidPassword(false);
    } else {
      setValidPassword(true);
    }
  };

  const customMatchers = [
    {
      pattern: /Ground Rules/,
      onPress: () => Linking.openURL(`https://mymesh.io/ground-rules`),
      style: localStyles.purpleBold,
    },
    {
      pattern: /Privacy Policy/,
      onPress: () => Linking.openURL(`https://mymesh.io/privacy-policy`),
      style: localStyles.purpleBold,
    },
  ];

  const has8chars = password?.trim()?.length >= 8;
  const hasSpecialChar = passwordRegex.test(password);
  const continueDisabled = !validPassword;

  useEffect(() => {
    navigation?.setOptions({
      headerLeft: () => (loading ? undefined : <BackButton />),
      headerRight: () => (
        <HeaderSubmitButton
          title="Continue"
          onPress={onSubmit}
          testID={PASSWORDSCREEN_CONTINUE}
          disabled={continueDisabled || loading}
        />
      ),
    });
  }, [continueDisabled, loading, navigation, onSubmit]);

  return (
    <KeyboardPaddingView>
      <SafeAreaView testID={CREATEPASSWORD} style={{ flex: 1, backgroundColor: Colors.white }}>
        {loading ? (
          <View style={{ width: `100%`, height: `100%`, flex: 1, justifyContent: `center`, alignItems: `center` }}>
            <LoadingIndicator size={32} style={{ margin: 32 }} />
          </View>
        ) : (
          <ScrollView style={localStyles.scroll} alwaysBounceVertical={false} keyboardShouldPersistTaps="handled">
            <View style={localStyles.container} testID={PASSWORDSCREEN}>
              <View>
                <View style={localStyles.blob} />
                <AuthMainText text="Set a password" />
              </View>

              <View style={localStyles.passwordInputContainer}>
                <AuthSubText text="Password" style={{ marginLeft: 10, marginBottom: 5 }} />

                <PasswordInput
                  autoFocus={true}
                  onChangeText={onChangePassword}
                  placeholder="Create a password"
                  onSubmitEditing={onSubmit}
                  textContentType="newPassword"
                  testID={PASSWORDSCREEN_PASSWORD}
                />

                <View style={localStyles.passwordValidationMessagesContainer}>
                  <View style={localStyles.passwordValidationContainerTop}>
                    {password.length > 0 && (
                      <MeshIcon
                        testID={has8chars ? `PASS` : `ERROR`}
                        name={has8chars ? `check` : `info-circle`}
                        color={has8chars ? Colors.brandGreen : Colors.brightRed}
                        size={18}
                      />
                    )}
                    <AuthSubText text="Must contain at least 8 characters" style={localStyles.passwordValidationText} />
                  </View>
                  <View style={{ flexDirection: `row` }}>
                    {password.length > 0 && (
                      <MeshIcon
                        testID={hasSpecialChar ? `PASS` : `ERROR`}
                        name={hasSpecialChar ? `check` : `info-circle`}
                        color={hasSpecialChar ? Colors.brandGreen : Colors.brightRed}
                        size={18}
                      />
                    )}
                    <AuthSubText text="Must contain a special character (e.g. $@!%)" style={localStyles.passwordValidationText} />
                  </View>
                </View>

                <ParsedText
                  style={localStyles.agreementText}
                  parse={customMatchers}
                  // eslint-disable-next-line react-native/no-raw-text
                >
                  By continuing you agree to mesh's Ground Rules and Privacy Policy.
                </ParsedText>
              </View>
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

const localStyles = StyleSheet.create({
  scroll: { flex: 1, backgroundColor: Colors.white, width: `100%` },
  container: {
    flex: 1,
    padding: 16,
  },
  passwordInputContainer: {
    marginTop: 30,
  },
  passwordValidationMessagesContainer: {
    marginTop: 16,
  },
  passwordValidationContainerTop: {
    flexDirection: `row`,
    marginBottom: 10,
  },
  passwordValidationText: {
    color: Colors.textBlack,
    marginLeft: 4,
    marginTop: 2,
  },
  agreementText: {
    fontSize: Typography.smallFontSize,
    color: Colors.gray,
    marginHorizontal: 10,
    marginTop: 23,
  },
  purpleBold: {
    fontWeight: `500`,
    color: Colors.brandPurple,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(255,86,61,0.7)`,
    borderTopStartRadius: 40,
    borderTopEndRadius: 45,
    borderBottomStartRadius: 40,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
});
