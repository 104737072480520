import React, { useEffect, useRef } from 'react';
import { Animated, Keyboard, KeyboardEvent, Platform } from 'react-native';

export const KeyboardPaddingView: React.FC = ({ children }) => {
  // when the keyboard shows or hides, animate paddingBottom to match
  const paddingBottom = useRef(new Animated.Value(0)).current;
  // only add the listeners on ios, because android is currently set to auto-resize

  // FIXME: Do not use this component within a screen with tab bar displayed, it will add tab bar height to the calculation
  // add the screen to MainTabNavigator.hideTabBar list before to fix the offset

  useEffect(() => {
    if (Platform.OS === `ios`) {
      const useNativeDriver = false;
      const keyboardWillShow = (event: KeyboardEvent) => {
        const toValue = event.endCoordinates.height;
        Animated.timing(paddingBottom, { useNativeDriver, toValue, duration: event.duration }).start();
        //console.log(`KPV keyboardWillShow: paddingBottom ${toValue}`);
      };
      const keyboardWillHide = (event: KeyboardEvent) => {
        const toValue = 0;
        Animated.timing(paddingBottom, { useNativeDriver, toValue, duration: event.duration }).start();
        //console.log(`KPV keyboardWillHide: paddingBottom ${toValue}`);
      };
      const subscriptions = [
        Keyboard.addListener(`keyboardWillShow`, keyboardWillShow),
        Keyboard.addListener(`keyboardWillHide`, keyboardWillHide),
      ];
      return () => subscriptions.forEach((sub) => sub.remove());
    }
    return () => null;
  }, [paddingBottom]);

  return (
    //id="KeyboardPaddingView"
    <Animated.View style={{ flex: 1, paddingBottom }}>{children}</Animated.View>
  );
};
