import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Mask, Use, Ellipse } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreTechnology = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path id="a" d="M0 0H34.5413962V20.7881947H0z" />
        <Path id="c" d="M0.0128201054 0.0090831301L3.29136947 0.0090831301 3.29136947 2.1558115 0.0128201054 2.1558115z" />
        <Path
          id="e"
          d="M0.00000324139443 0.0289788746L1.81049512 0.0289788746 1.81049512 0.862831923 0.00000324139443 0.862831923z"
        />
        <Path id="g" d="M0.0123099771 0.016166834L1.2000282 0.016166834 1.2000282 0.620657859 0.0123099771 0.620657859z" />
        <Path id="i" d="M0 0H34.5413962V20.7881947H0z" />
        <Path
          id="k"
          d="M0.00000174910935 0.0120679216L16.4064644 0.0120679216 16.4064644 14.4440497 0.00000174910935 14.4440497z"
        />
        <Path id="m" d="M0 0L5.87466581 0 5.87466581 4.83412144 0 4.83412144z" />
        <Path id="o" d="M0 0H34.5413962V20.7881947H0z" />
        <Path
          id="q"
          d="M0.00000232915686 0.00000306951464L11.2969465 0.00000306951464 11.2969465 12.3441611 0.00000232915686 12.3441611z"
        />
        <Path id="s" d="M0 0H34.5413962V20.7881947H0z" />
        <Path id="u" d="M0 0.00661570249L14.0663259 0.00661570249 14.0663259 14.4440497 0 14.4440497z" />
        <Path id="w" d="M0.00892299992 0.0159657081L6.79370417 0.0159657081 6.79370417 6.24596994 0.00892299992 6.24596994z" />
        <Path id="y" d="M0.00703552318 0.00720570039L3.00405335 0.00720570039 3.00405335 9.25361936 0.00703552318 9.25361936z" />
        <Path
          id="A"
          d="M0.000805662375 0.00154741563L1.57544078 0.00154741563 1.57544078 0.683380945 0.000805662375 0.683380945z"
        />
        <Path id="C" d="M0.00764029048 0.0094811023L5.0290922 0.0094811023 5.0290922 3.58148904 0.00764029048 3.58148904z" />
        <Path id="E" d="M0 0.0165802958L5.83488032 0.0165802958 5.83488032 5.19704867 0 5.19704867z" />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Rect fill="#FFEEEB" x={0} y={0} width={163} height={98} rx={7} />
          <Path
            d="M141.754 46.52c4.56 4.26 6.78 10.487 7.547 17.405.727 6.88 0 14.454-4.56 19.66-4.521 5.207-12.916 8.083-20.423 7.282-7.507-.8-14.127-5.279-20.423-10.486-6.296-5.206-12.31-11.177-12.35-17.185 0-5.97 5.933-11.978 12.27-16.238 6.296-4.296 12.955-6.845 19.736-6.954 6.78-.11 13.682 2.22 18.203 6.517z"
            fill="#FF563D"
            fillRule="nonzero"
          />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#FF563D">
            <TSpan x={11.5446415} y={22}>
              Technology
            </TSpan>
          </Text>
        </G>
        <Path
          d="M0 50.05V2.318C0 1.037.735 0 1.64 0h65.802c.906 0 1.64 1.038 1.64 2.317v47.625c0 .06-.034.109-.077.109H0z"
          fill="#FCEBEC"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792)"
        />
        <Path
          d="M2.865 1.826a.63.63 0 01-.629.632.63.63 0 01-.629-.632.63.63 0 01.629-.632.63.63 0 01.629.632"
          fill="#E93737"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792)"
        />
        <Path
          d="M5.474 1.78a.63.63 0 01-.63.631.63.63 0 01-.628-.632.63.63 0 01.629-.632.63.63 0 01.629.632"
          fill="#F9C818"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792)"
        />
        <Path
          d="M8.175 1.803a.63.63 0 01-.629.632.63.63 0 01-.628-.632.63.63 0 01.628-.632.63.63 0 01.63.632"
          fill="#1BC0D7"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792)"
        />
        <Path
          fill="#E93737"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792) translate(34.541 24.206)"
          d="M0 0H34.5413962V20.7881947H0z"
        />
        <G transform="translate(70 33) translate(.792) translate(34.541 24.206)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <Path
              d="M10.69 5.15s-.086 1.9-1.189 2.57c-1 .61 1.12 4.14 3.4 3.93 1.247-.116 1.54-3.546 1.54-3.546s-1.008-.196-.636-1.74L10.69 5.15z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405)"
            />
            <Path
              d="M12.515 7.082c-1.478-.18-2.544-1.157-2.694-2.777-.43.069-.53-1.056-.411-1.418.12-.362.51-.15.51-.15.022-.058.04-.117.056-.18C10.622-.105 12.43.001 12.43.001c3.323.174 2.544 3.584 2.355 4.488-.19.905-.84 2.766-2.27 2.593z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405)"
            />
            <Path
              d="M2.448 6.789C.78 8.822.206 10.46.726 11.704c.779 1.865 2.694-.08 3.757-2.444.709-1.575 1.582-2.779 2.62-3.611l-4.655 1.14z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014) rotate(-10 3.821 9.049)"
            />
            <Path
              d="M6.542.962c1.93-.6 2.525-.634 2.525-.634l2.039.442s1.117 1.475 1.212 2.79c0 0 .032-1.732.766-2.918 0 0 3.231-.03 4.45 1.248C19.456 3.909 19.42 7.45 19.42 7.45l-3.175.329 1.287-1.801s.082 7.337-.11 9.23C15 17.894 6.29 15.815 6.29 15.815s.189-6.071.22-7.732c.033-1.66-.578-1.437-1.42.622C4.398 10.389 2.21 6.9 2.21 6.9S3.554 1.89 6.542.962z"
              fill="#1BC0D7"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014)"
            />
            <Path
              d="M16.398 10.106s1.568-.564 3.162-.23c0 0-.021-.524-.005-1.214.014-.567.323-1.445-.792-1.35-1.298.11-1.854.12-2.419.377 0 0-.134 1.877.054 2.417"
              fill="#FCEBEC"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014)"
            />
            <Path
              d="M1.49 8.001l.683-2.161s2.53-.532 3.532 1.666l-.751 1.957S3.549 7.381 1.489 8.001"
              fill="#237978"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014)"
            />
            <G transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014) translate(7.873)">
              <Mask id="d" fill="#fff">
                <Use xlinkHref="#c" />
              </Mask>
              <Path
                d="M1.227.011s-1.29.44-1.21.56c.079.119 2.37 1.65 2.542 1.583.171-.068.815-1.295.723-1.418C3.19.612 1.338-.034 1.227.01"
                fill="#237978"
                fillRule="nonzero"
                mask="url(#d)"
              />
            </G>
            <Path
              d="M15.154.545s1.11.793 1 .885c-.11.092-2.742.898-2.887.783-.145-.115-.412-1.476-.289-1.567.124-.092 2.083-.177 2.176-.1"
              fill="#237978"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014)"
            />
            <Path
              d="M2.87 10.413c1.018.493.7.696 1.197 1.004.497.307 1.26 1.107 1.522.925.262-.182.187-.345-.086-.57-.273-.226-1.12-.965-1.027-1.077.093-.112 1.524 1.489 1.943 1.208.42-.281-.485-1.003-.923-1.306-.439-.304-.684-.574-.544-.62.14-.045 1.797 1.715 2.116 1.449.32-.266-.274-.878-.714-1.187-.441-.309-1.12-.805-1.12-.805s.875.361 1.548 1.006c.674.645.836.604.92.456.084-.148-.612-.964-.612-.964s.022-.945-.874-1.26c-.895-.314-1.573-.799-2.515-.726-.941.074-1.23.632-1.23.632s-.62 1.343.398 1.835z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014) rotate(30 4.996 10.154)"
            />
            <Path
              d="M15.442 4.913h-2.115a.156.156 0 01-.156-.156v-.185c0-.086.07-.156.156-.156h2.115c.086 0 .156.07.156.156v.185c0 .086-.07.156-.156.156"
              fill="#237978"
              fillRule="nonzero"
              transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(0 .014)"
            />
            <G fillRule="nonzero">
              <Path
                d="M4.316 8.719L.06 7.533s-.265-.28.514-.355c.78-.073 8.919-.292 8.919-.292L4.316 8.719z"
                fill="#204EB2"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(9.247 4.097)"
              />
              <Path
                d="M16.381 8.706H4.445c-.221 0-.4-.18-.4-.403l1.083-7.9c0-.223.18-.403.4-.403h11.937c.221 0 .4.18.4.403l-1.083 7.9c0 .223-.18.403-.4.403"
                fill="#3267C8"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) translate(9.247 4.097)"
              />
            </G>
            <G fillRule="nonzero">
              <Path
                d="M.81.017S.703.123.595.257C.486.388.582.412.342.666c-.24.252-.672.891.084 1.868.756.977 1.008.893 1.32.724.312-.17.348-.29.936-.35.587-.06 1.151-.64 1.14-.784l-.013-.145s.54-.205.504-.434c-.036-.229-.192-.205-.192-.205s.108-.108.024-.301C4.061.847 3.665.883 3.21.943c-.455.06-1.631.302-1.703.254-.072-.049-.048-.12.228-.157.276-.036 1.392-.048 1.392-.362 0-.313-.336-.374-.984-.362-.648.012-.768-.024-.924.037L.93.386.81.016z"
                fill="#F6CBC3"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) scale(-1 1) rotate(-72 -2.21 22.663)"
              />
              <Path
                d="M4.13 1.34s.035-.025-.222.074a2.45 2.45 0 01-.41.108c-.696.133-1.788.374-2.124.422-.336.049-.06.12.708-.036a54.296 54.296 0 001.723-.38c.07-.024.28-.094.326-.188M2.71 2.323c-.297.075-.792.188-.844.2-.094.023-.276.06-.276.06s.002.068.561-.05c.139-.028.396-.07.559-.116.15-.043.691-.183.763-.244a.237.237 0 00.084-.12s-.568.2-.847.27z"
                fill="#995B5D"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(0 1.405) translate(0 7.491) scale(-1 1) rotate(-72 -2.21 22.663)"
              />
            </G>
            <G>
              <Path
                d="M1.135.992a.14.14 0 01-.14.14.14.14 0 01-.14-.14.14.14 0 01.14-.14.14.14 0 01.14.14M2.987 1.308a.14.14 0 01-.14.14.14.14 0 01-.14-.14.14.14 0 01.14-.14.14.14 0 01.14.14"
                fill="#000"
                fillRule="nonzero"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933)"
              />
              <Path
                d="M.903 2.983s-.006.374.515.44c.522.068.755 0 .743-.239 0 0-.083-.262-.205-.273-.122-.011-.25.056-.25.056s-.121-.162-.249-.145c-.127.017-.382.167-.554.161"
                fill="#CE4129"
                fillRule="nonzero"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933)"
              />
              <G transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933) translate(0 .004)">
                <Mask id="f" fill="#fff">
                  <Use xlinkHref="#e" />
                </Mask>
                <Path
                  d="M1.752.863S1.079.58.904.5C.728.421.69.366.484.396.274.427.038.495.001.433-.035.372.43.063.585.033.74.003 1.656.146 1.773.489c.095.277-.021.374-.021.374"
                  fill="#000"
                  fillRule="nonzero"
                  mask="url(#f)"
                />
              </G>
              <Path
                d="M1.82 3.186c-.38-.051-.835-.119-.978-.19C.738 2.942.7 2.955.7 2.955s.033-.12.184-.044c.147.073.75.154.948.18.346.047.378.03.417.007l.046.08a.273.273 0 01-.156.036c-.067 0-.165-.009-.32-.03z"
                fill="#000"
                fillRule="nonzero"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933)"
              />
              <G transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933) translate(2.539 .426)">
                <Mask id="h" fill="#fff">
                  <Use xlinkHref="#g" />
                </Mask>
                <Path
                  d="M.013.365S.028.145.206.047c.179-.097.801.062.848.115.047.053.131.18.146.459 0 0-.067-.212-.19-.247C.887.338.504.497.262.493.019.488.007.444.013.365"
                  fill="#000"
                  fillRule="nonzero"
                  mask="url(#h)"
                />
              </G>
              <Path
                d="M1.525 4.64c-.323 0-.657-.095-.896-.183A4.001 4.001 0 01.1 4.22l.033-.062c.009.005.873.486 1.563.401l.009.07c-.059.007-.119.01-.18.01"
                fill="#000"
                fillRule="nonzero"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933)"
              />
              <G fillRule="nonzero">
                <Path
                  d="M2.304.012l.09.029-.01.028a5.512 5.512 0 00-.093.34c-.025.102-.046.2-.063.293L2.223.73c-.04.236-.046.417-.01.514l.003.006c.206.514-.074.774-.664.592l-.018-.006.029-.089c.53.17.743-.022.566-.462-.049-.122-.041-.328.008-.6a4.914 4.914 0 01.127-.537L2.272.12a6.02 6.02 0 01.014-.047l.011-.038.007-.023z"
                  fill="#000"
                  transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933) translate(.093 .632)"
                />
                <Path
                  d="M.004 3.594l.046-.081.017.009.015.008.078.04.013.006c.098.048.207.097.323.141.267.104.53.174.775.197.111.01.217.01.315 0l.02-.002.011.093c-.11.014-.23.014-.354.002a3.032 3.032 0 01-.8-.202 4.234 4.234 0 01-.33-.145l-.039-.018a3.198 3.198 0 01-.045-.024l-.027-.014-.018-.01z"
                  fill="#C86A5D"
                  transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933) translate(.093 .632)"
                />
              </G>
              <G fill="#000" fillRule="nonzero">
                <Path
                  d="M.369 1.9c-.095 0-.212-.018-.359-.066l.029-.089c.273.088.474.08.568-.02.079-.086.078-.243-.002-.442C.551 1.148.565.89.645.52.705.25.78.013.781.01l.088.028C.788.295.596 1.01.692 1.248c.12.3.057.46-.017.54-.053.058-.146.112-.306.112"
                  transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.11 3.933) translate(1.607 .632)"
                />
              </G>
            </G>
            <G fill="#66231C" fillRule="nonzero">
              <Path
                d="M6.22 5.08c0-.438-.097-.993.165-1.212.262-.22.83-.593.502-1.053-.327-.46.044-1.36-.524-1.47-.567-.11-.59-.29-.744-.706C5.467.222 4.818.55 4.552.34 4.3.143 4-.199 3.304.151c-.374.19-1.014-.545-1.48.493-.298.665-.614.082-1.046.428-.513.411-.014 1.012-.472 1.319-.458.307-.059.659-.224 1.005-.272.57.193 2.577.499 2.753.305.175.463.47.463.47S.904 6.14.87 5.7c0 0-.236.06-.365-.364-.13-.424-.175-1.146.104-1.228.279-.081.33.147.33.147s.092.35.04.615c-.054.267.119.427.211.027.093-.4.557-.413.464-.959-.093-.546.093-.866.464-1.025.371-.16.477-.973.795-.893.318.08.676-.266 1.166-.026.49.24.45.572.875.652.424.08.649.347.649.573 0 .226.292.306.371.626.08.32.01 1.267-.156 2.063 0 0 .404-.387.404-.826z"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(8.967)"
              />
            </G>
            <G fill="#000" fillRule="nonzero">
              <Path
                d="M3.074 1.228S2.887.934 2.421.606C2.164.425.929.136.58.674c-.35.538-.575.681-.495.676C.872 1.3.948.924 1.303.862c.396-.07.605-.163.605-.163.14.14.28.234.583.398.1.055.567.324.583.13"
                transform="translate(1.024 1.78) matrix(-1 0 0 1 28.44 0) translate(11.063 5.91) rotate(2 -.416 2.235) rotate(11 1.572 .857)"
              />
            </G>
          </G>
        </G>
        <Path
          fill="#204EB2"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792) translate(0 24.183)"
          d="M0 0H34.5413962V20.7881947H0z"
        />
        <G transform="translate(70 33) translate(.792) translate(0 24.183)">
          <Mask id="j" fill="#fff">
            <Use xlinkHref="#i" />
          </Mask>
          <G mask="url(#j)">
            <Path
              d="M6.94 5.15s-.086 1.9-1.189 2.57c-1 .61 1.12 4.14 3.4 3.93 1.247-.116 1.54-3.546 1.54-3.546s-1.008-.196-.636-1.74L6.94 5.15z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007)"
            />
            <Path
              d="M8.765 7.082c-1.478-.18-2.544-1.157-2.694-2.777-.43.069-.53-1.056-.411-1.418.12-.362.51-.15.51-.15.022-.058.04-.117.056-.18C6.872-.105 8.68.001 8.68.001c3.323.174 2.545 3.584 2.355 4.488-.19.905-.84 2.766-2.27 2.593z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007)"
            />
            <Path
              d="M3.366 7.825l-.84 7.892s5.974 1.079 11.141-.607l.302-6.948-10.603-.337z"
              fill="#000"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <G transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608) translate(0 .011)">
              <Mask id="l" fill="#fff">
                <Use xlinkHref="#k" />
              </Mask>
              <Path
                d="M2.567 13.384S-.303 11.586.027 8.67C.354 5.753 1.576 1.771 3.68.952 5.782.134 5.848.012 5.848.012s.842 1.105 2.31 1.176c1.467.07 2.422-.75 2.422-.75s2.881.69 3.711 1.996c.83 1.305 2.95 7.134 1.762 8.984-1.188 1.85-3.485 2.388-3.485 2.388s-7.02 1.685-10-.422"
                fill="#000"
                fillRule="nonzero"
                mask="url(#l)"
              />
            </G>
            <Path
              d="M6.69 8.03l-.021-1.873s-.064-.398.55-.422c.614-.023 2.266-.046 2.266-.046s.402-.094.423.491c.022.585.043 2.599.043 2.599l-2.541.725-.953-.046.233-1.428z"
              fill="#204EB2"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <Path
              d="M6.47 9.447l.938.046L9.94 8.77c-.002-.147-.022-2.03-.042-2.59-.008-.203-.062-.346-.162-.423a.321.321 0 00-.247-.057h-.003c-.016 0-1.658.024-2.265.047-.223.009-.38.067-.469.174a.323.323 0 00-.07.234L6.7 8.03v.002l-.23 1.415zm.941.07H7.41l-.966-.048.235-1.44-.02-1.872a.34.34 0 01.075-.25c.092-.113.256-.175.486-.183.6-.023 2.212-.046 2.265-.047a.34.34 0 01.265.061c.106.082.163.23.171.442.021.578.042 2.578.042 2.599v.009l-.008.002-2.543.726z"
              fill="#000"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <Path
              d="M6.668 6.227l-.001-.323s0-.257.349-.28c.35-.024 2.422-.07 2.422-.07s.28-.024.396.14c.117.164.08.562.08.562s-.013-.558-.477-.549l-2.27.048s-.474-.048-.499.472"
              fill="#56ACF6"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <Path
              d="M9.448 5.775c.317 0 .424.399.46.65 0-.197-.012-.49-.083-.643-.112-.236-.383-.205-.386-.205-.021.001-2.077.072-2.422.107a.345.345 0 00-.297.217.54.54 0 00-.042.19l.001.258a.774.774 0 01.134-.336.392.392 0 01.355-.166l2.268-.072h.012m.477.848h-.023c0-.003-.004-.211-.064-.415-.079-.27-.214-.404-.401-.397l-2.27.07c-.003 0-.197-.026-.339.159-.09.12-.14.3-.148.539l-.024-.001-.001-.487c0-.005.004-.407.36-.443.346-.035 2.402-.105 2.423-.106.011-.001.287-.033.406.22.117.25.083.837.081.861"
              fill="#000"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <Path
              d="M6.695 7.28s-.336.217-.444.35c-.108.132-.012.156-.252.41-.24.253-.672.892.084 1.869.756.976 1.008.892 1.32.723.312-.169.348-.29.936-.35.587-.06 1.151-.639 1.14-.783l-.013-.145s.54-.205.504-.434c-.036-.23-.192-.205-.192-.205s.108-.109.024-.302c-.084-.193-.48-.156-.936-.096-.455.06-1.631.301-1.703.253-.072-.048-.048-.12.228-.157.276-.036 1.391-.048 1.391-.361 0-.314-.336-.374-.983-.362-.648.012-.768-.024-.924.036l-.156.06-.024-.506z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <Path
              d="M9.788 8.714s.034-.025-.223.073a2.45 2.45 0 01-.41.109c-.697.132-1.788.374-2.124.422-.336.048-.06.12.708-.036A54.296 54.296 0 009.462 8.9c.07-.024.28-.094.326-.187M9.214 9.426s-.568.2-.847.27c-.297.075-.52.177-.58.08-.06-.096-.132-.12-.132-.12l-.096.108s.016.12-.036.133c-.094.022-.276.06-.276.06s.002.068.56-.05c.14-.029.397-.07.56-.117.15-.043.691-.183.763-.243a.237.237 0 00.084-.12"
              fill="#995B5D"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <Path
              d="M7.45 9.366s-.008.486.128.478c.137-.008.185-.008.209-.044.024-.036-.036-.157-.036-.29 0-.132 0-.192-.108-.18-.108.012-.18.012-.192.036"
              fill="#F9C818"
              fillRule="nonzero"
              transform="translate(8.85 2.177) translate(0 1.007) translate(0 7.608)"
            />
            <G fillRule="nonzero">
              <Path
                d="M.81 1.049s-.006.373.515.44c.521.068.754.001.743-.239 0 0-.083-.262-.205-.273-.122-.011-.25.056-.25.056S1.491.87 1.363.888c-.127.016-.382.167-.554.16"
                fill="#CE4129"
                transform="translate(8.85 2.177) translate(6.612 2.829) translate(.841 2.645) rotate(8 1.439 1.2)"
              />
              <Path
                d="M1.738 1.248c-.38-.052-.835-.12-.977-.191-.104-.053-.143-.04-.143-.04S.65.898.802.974c.147.074.75.155.948.182.347.046.378.029.418.006l.046.081a.273.273 0 01-.157.036c-.067 0-.164-.01-.319-.03z"
                fill="#000"
                transform="translate(8.85 2.177) translate(6.612 2.829) translate(.841 2.645) rotate(8 1.416 1.113)"
              />
              <Path
                d="M1.432 2.7c-.323 0-.657-.094-.896-.182a4.001 4.001 0 01-.531-.237l.034-.062c.009.005.873.486 1.563.401l.009.07c-.059.007-.12.01-.18.01"
                fill="#000"
                transform="translate(8.85 2.177) translate(6.612 2.829) translate(.841 2.645)"
              />
              <Path
                d="M2.304.012l.09.029-.01.028a5.512 5.512 0 00-.093.34c-.025.102-.046.2-.063.293L2.223.73c-.04.236-.046.417-.01.514l.003.006c.206.514-.074.774-.664.592l-.018-.006.029-.089c.53.17.743-.022.566-.462-.049-.122-.041-.328.008-.6a4.914 4.914 0 01.127-.537L2.272.12a6.02 6.02 0 01.014-.047l.011-.038.007-.023z"
                fill="#000"
                transform="translate(8.85 2.177) translate(6.612 2.829) translate(.841 1.338)"
              />
              <Path
                d="M.004 3.594l.046-.081.017.009.015.008.078.04.013.006c.098.048.207.097.323.141.267.104.53.174.775.197.111.01.217.01.315 0l.02-.002.011.093c-.11.014-.23.014-.354.002a3.032 3.032 0 01-.8-.202 4.234 4.234 0 01-.33-.145l-.039-.018a3.198 3.198 0 01-.045-.024l-.027-.014-.018-.01z"
                fill="#C86A5D"
                transform="translate(8.85 2.177) translate(6.612 2.829) translate(.841 1.338)"
              />
              <Path
                d="M.369 1.9c-.095 0-.212-.018-.359-.066l.029-.089c.273.088.474.08.568-.02.079-.086.078-.243-.002-.442C.551 1.148.565.89.645.52.705.25.78.013.781.01l.088.028C.788.295.596 1.01.692 1.248c.12.3.057.46-.017.54-.053.058-.146.112-.306.112"
                transform="translate(8.85 2.177) translate(6.612 2.829) translate(2.355 1.338)"
                fill="#000"
              />
              <Path
                d="M2.09 1.974a.07.07 0 01-.047-.019s-.116-.104-.282-.097c-.172.006-.287.09-.288.092a.07.07 0 01-.097-.014.07.07 0 01.013-.098.684.684 0 01.367-.12.57.57 0 01.382.134.07.07 0 01-.048.122"
                fill="#000"
                transform="translate(8.85 2.177) translate(6.612 2.829)"
              />
              <Path
                d="M.84 1.351s.356-.216.628-.265c.167-.03 1.092.099 1.062.288-.03.185-.12.16-.485.098-.365-.063-.532-.105-.743-.081-.21.023-.456.069-.461-.04"
                fill="#000"
                transform="translate(8.85 2.177) translate(6.612 2.829) rotate(-19 1.686 1.3)"
              />
              <Path
                d="M4.615 1.585s-.061-.187-.162-.277c-.1-.09-.856-.286-.984-.166-.202.19-.056.259.351.302.328.035.457.046.61.113.207.09.18.063.185.028"
                fill="#000"
                transform="translate(8.85 2.177) translate(6.612 2.829) rotate(28 3.997 1.36)"
              />
              <Path
                d="M4.05 2.188a.07.07 0 01-.048-.019s-.116-.104-.282-.097a.554.554 0 00-.287.091.07.07 0 01-.098-.013.07.07 0 01.013-.098.684.684 0 01.367-.12.571.571 0 01.382.134.07.07 0 01-.047.122"
                fill="#000"
                transform="translate(8.85 2.177) translate(6.612 2.829)"
              />
            </G>
            <G transform="translate(8.85 2.177) translate(5.567)">
              <Mask id="n" fill="#fff">
                <Use xlinkHref="#m" />
              </Mask>
              <Path
                d="M.631 3.998s-.142.698.097.485c.239-.214.235-1.085.734-1.695.759-.926 3.776-.499 4.03.236.207.604.139 1.221.097 1.81 0 0 .269-.031.267-1.683-.002-1.34.254-2.06-1.053-2.516C3.495.18 2.848-.343 1.674.3.501.944-.264 1.682.084 2.465c.38.854-.176 1.294-.006 1.516.138.18.553.017.553.017"
                fill="#221108"
                fillRule="nonzero"
                mask="url(#n)"
              />
            </G>
            <G fillRule="nonzero">
              <G fill="#000">
                <Path
                  d="M2.078.011c-.491 0-.89.4-.89.894s.399.895.89.895c.492 0 .89-.4.89-.895a.892.892 0 00-.89-.894zm0 .211c.376 0 .68.306.68.683a.682.682 0 01-.68.684.682.682 0 01-.68-.684c0-.377.305-.683.68-.683zM4.266.224c-.491 0-.89.4-.89.895 0 .493.399.894.89.894s.89-.4.89-.894a.892.892 0 00-.89-.895zm0 .211c.376 0 .68.306.68.684a.682.682 0 01-.68.683.682.682 0 01-.68-.683c0-.378.304-.684.68-.684z"
                  transform="translate(8.85 2.177) translate(5.8 3.629) translate(.373)"
                />
                <Path
                  d="M.008.265A.105.105 0 01.14.205l.005.001 1.164.468a.106.106 0 01-.073.198L1.231.87.067.402A.106.106 0 01.008.265zM2.768.95a.105.105 0 01.11-.094h.006l.61.069a.105.105 0 01-.018.21h-.005l-.61-.069A.105.105 0 012.768.95z"
                  transform="translate(8.85 2.177) translate(5.8 3.629) translate(.373)"
                />
              </G>
              <Path
                d="M.748.387c0 .204-.163.37-.365.37a.367.367 0 01-.366-.37c0-.204.164-.37.366-.37.202 0 .365.166.365.37"
                transform="translate(8.85 2.177) translate(5.8 3.629) translate(0 1.381)"
                fill="#FFF"
              />
            </G>
            <G fill="#000" fillRule="nonzero">
              <Path
                d="M2.034 1.281l-.396-.046s-.652.561-.932.093c-.338-.566.303-.749.699-.82.396-.07.605-.163.605-.163.14.14.204.236.452.431.247.195.244.677 0 .728-.244.051-.428-.223-.428-.223zM2.997.93C2.812.509 2.731.417 2.462.227c-.257-.18-1.43-.444-1.78.095C.537.547.44.674.339.712c-.14.05-.59.87-.093 1.584.231.332.703.488 1.206.46a2.29 2.29 0 00.87-.21c.192-.09.492-.38.676-.984"
                transform="translate(8.85 2.177) translate(7.244 5.923)"
              />
            </G>
          </G>
        </G>
        <Path
          fill="#FFE89D"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792) translate(34.541 3.418)"
          d="M0 0H34.5413962V20.7881947H0z"
        />
        <G transform="translate(70 33) translate(.792) translate(34.541 3.418)">
          <Mask id="p" fill="#fff">
            <Use xlinkHref="#o" />
          </Mask>
          <G mask="url(#p)">
            <G fillRule="nonzero">
              <Path
                d="M11.016 5.15s-.086 1.9-1.189 2.57c-1 .61 1.12 4.14 3.4 3.93 1.247-.116 1.54-3.546 1.54-3.546s-1.008-.196-.636-1.74L11.016 5.15z"
                fill="#F6CBC3"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(0 .585)"
              />
              <Path
                d="M12.842 7.082c-1.479-.18-2.545-1.157-2.695-2.777-.43.069-.53-1.056-.411-1.418.12-.362.51-.15.51-.15.022-.058.04-.117.056-.18.646-2.662 2.454-2.556 2.454-2.556 3.323.174 2.545 3.584 2.355 4.488-.19.905-.84 2.766-2.27 2.593z"
                fill="#F6CBC3"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(0 .585)"
              />
              <G transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(0 .585) translate(0 7.772)">
                <Path
                  d="M2.893 5.712C.262 9.902-.569 12.445.403 13.34c.97.894 2.824-.194 5.558-3.265-.14-.685-.505-1.434-1.091-2.244a11.35 11.35 0 00-1.977-2.119z"
                  fill="#F6CBC3"
                />
                <Path
                  d="M18.058 8.685l-1.92-.224s.491 6.645.306 6.645c-.099 0-2.406 1.258-5.64 1.258-2.484 0-5.53-.826-5.66-.833-.015-.001-.03-.012-.046-.032.042-.458.075-1.268.108-2.163.023-.628.047-1.298.074-1.916.026-.575.054-1.106.088-1.518.15-1.8.046-3.441.046-3.441L4.68 8.467.016 6.759s1.557-2.28 3.413-4.264C5.271.526 8.225.027 8.271.02c0 0-.166 2.715 2.629 2.924 2.323.173 2.443-2.626 2.443-2.626s2.716.713 3.495 1.38c.78.669 4.217 5.229 4.217 5.344 0 .115-2.997 1.643-2.997 1.643z"
                  transform="translate(1.42 .004)"
                  fill="#F9C818"
                />
                <Path d="M6.734 11.424c-.04.618-.073 1.288-.107 1.916-.007-.426.017-1.038.107-1.916" fill="#F9C818" />
                <Path
                  d="M16.357 9.471l-2.443.2a.268.268 0 01-.288-.247l-.39-4.828a.268.268 0 01.244-.29l2.443-.199a.268.268 0 01.289.246l.39 4.828a.268.268 0 01-.245.29"
                  fill="#204EB2"
                />
                <Path
                  d="M16.606 9.337l-2.452.2a.263.263 0 01-.283-.242l-.377-4.66a.264.264 0 01.24-.286l2.452-.2a.263.263 0 01.284.242l.377 4.661a.264.264 0 01-.241.285"
                  fill="#1F9BEB"
                />
                <Path d="M16.194 4.68a.253.253 0 11-.504.041.253.253 0 11.504-.041" fill="#204EB2" />
                <Path
                  d="M1.805 2.819S.58 3.087.572 2.66c0 0 .832-.347 1.02-.332.19.016 1.061.119 1.061.119L1.577 1.91.51 2.243s-.243.015-.353-.158c-.11-.174.015-.276.015-.276l1.398-.466s.047-.15-.306-.166c-.353-.016-1.13-.07-1.13-.07S-.042.956.037.686C.116.42.556.515.556.515S.313.277.682.017c0 0 1.13.031 1.547.292.416.26 3.265 2.019 3.265 2.019l2.218-1.171c2.806 3.453 3.55 5.499 2.233 6.137-1.317.639-4.03-.853-8.14-4.475z"
                  transform="translate(13.905 6.348)"
                  fill="#F6CBC3"
                />
                <Path
                  d="M16.94 7.615l-1.113-.635-1.387-.149.01-.093 1.396.15c.007.001.013.003.018.006l1.122.64-.046.08z"
                  fill="#C86A5D"
                />
                <Path
                  fill="#C86A5D"
                  d="M21.4092511 9.95557411L18.7480963 8.27484389 18.7976607 8.19555379 21.4588156 9.87628401z"
                />
                <Path
                  fill="#C86A5D"
                  transform="rotate(-64 3.364 8.827)"
                  d="M4.67334459 9.70097492L2.00460062 8.0324582 2.05452711 7.95339308 4.72327107 9.6219098z"
                />
                <Path
                  d="M16.85 8.129c-.007-.005-.705-.48-.887-.556-.139-.058-.416.048-.511.094l-.04-.085c.015-.007.382-.181.587-.095.19.08.874.545.903.564l-.052.078z"
                  fill="#C86A5D"
                />
                <Path fill="#F6CBC3" d="M16.6101515 6.27682215L17.2975789 7.33355538 16.6438544 6.92034319z" />
                <Path
                  fill="#C86A5D"
                  d="M17.2727804 7.37318872L16.6190559 6.95999994 16.6686437 6.88070984 17.3223681 7.29389862z"
                />
                <Path
                  d="M8.145 9.95l-.819-.016a1.565 1.565 0 01-1.53-1.6l.052-2.622 3.722.073-.055 2.84a1.347 1.347 0 01-1.37 1.325"
                  fill="#FFF"
                />
              </G>
              <Path
                d="M1.038 0S.702.217.594.35C.486.482.582.506.342.76c-.24.253-.672.892.084 1.869s1.008.892 1.32.723c.312-.169.348-.29.936-.35.587-.06 1.151-.638 1.14-.783l-.013-.145s.54-.205.504-.434c-.036-.23-.192-.205-.192-.205s.108-.109.024-.301c-.084-.193-.48-.157-.936-.097-.455.06-1.631.302-1.703.253-.072-.048-.048-.12.228-.156.276-.037 1.392-.049 1.392-.362 0-.314-.336-.374-.984-.362-.648.012-.768-.024-.924.036l-.156.06L1.038 0z"
                fill="#F6CBC3"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(0 .585) translate(4.728 14.39)"
              />
              <Path
                d="M4.13 1.434s.035-.025-.222.073a2.45 2.45 0 01-.41.109c-.696.133-1.788.374-2.124.422-.336.048-.06.12.708-.036a54.296 54.296 0 001.723-.381c.07-.023.28-.094.326-.187M3.557 2.146s-.568.2-.847.27c-.297.075-.52.177-.58.08a.282.282 0 00-.132-.12l-.096.108s.016.12-.036.133c-.094.022-.276.06-.276.06s.002.068.561-.05c.139-.028.396-.07.559-.116.15-.044.691-.184.763-.244.072-.06.084-.12.084-.12"
                fill="#995B5D"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(0 .585) translate(4.728 14.39)"
              />
              <Path
                d="M1.794 2.086s-.01.486.127.478c.137-.008.185-.008.209-.044.024-.036-.036-.156-.036-.29 0-.132 0-.192-.108-.18-.108.012-.18.012-.192.036"
                fill="#F9C818"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(0 .585) translate(4.728 14.39)"
              />
            </G>
            <G fillRule="nonzero">
              <Path
                d="M2.304.012l.09.029-.01.028a5.512 5.512 0 00-.093.34c-.025.102-.046.2-.063.293L2.223.73c-.04.236-.046.417-.01.514l.003.006c.206.514-.074.774-.664.592l-.018-.006.029-.089c.53.17.743-.022.566-.462-.049-.122-.041-.328.008-.6a4.914 4.914 0 01.127-.537L2.272.12a6.02 6.02 0 01.014-.047l.011-.038.007-.023z"
                fill="#000"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(11.53 2.856) translate(0 .89)"
              />
              <Path
                d="M.004 3.594l.046-.081.017.009.015.008.078.04.013.006c.098.048.207.097.323.141.267.104.53.174.775.197.111.01.217.01.315 0l.02-.002.011.093c-.11.014-.23.014-.354.002a3.032 3.032 0 01-.8-.202 4.234 4.234 0 01-.33-.145l-.039-.018a3.198 3.198 0 01-.045-.024l-.027-.014-.018-.01z"
                fill="#C86A5D"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(11.53 2.856) translate(0 .89)"
              />
              <G transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(11.53 2.856) translate(.42 1.053)">
                <Path d="M1.5 2.256a.137.137 0 01-.137.137.137.137 0 01-.137-.137.137.137 0 11.274 0" fill="#000" />
                <Path fill="#FFF" d="M0.0293939595 0.408670624L0.588391605 0.0341085581" />
                <Path
                  fill="#000"
                  d="M0.0487608988 0.437893488L0.0100037287 0.379461806 0.569001375 0.00489974003 0.607758545 0.0633314223z"
                />
                <Path fill="#FFF" d="M0.285577922 0.549131399L0.844575568 0.174569333" />
                <Path
                  fill="#000"
                  d="M0.304968153 0.578354263L0.266210983 0.519922581 0.825208629 0.145360515 0.863965799 0.203792197z"
                />
                <Path fill="#FFF" d="M2.21877811 0.595951657L2.77777576 0.221389591" />
                <Path
                  fill="#000"
                  d="M2.23816835 0.625174522L2.19941118 0.566742839 2.75840882 0.192180773 2.79716599 0.250612455z"
                />
                <Path fill="#FFF" d="M2.47498537 0.736412432L3.03400631 0.361850366" />
                <Path
                  fill="#000"
                  d="M2.4943756 0.765635296L2.45561843 0.707203614 3.01461608 0.332641548 3.05337325 0.39107323z"
                />
              </G>
              <Path
                d="M.748.01A.951.951 0 01.946.002c.085.006.197.03.333.065a5.148 5.148 0 01.35.105l.046.016.047.016a.129.129 0 01-.08.244L1.636.446 1.614.44a6.404 6.404 0 00-.4-.123L1.188.31C1.078.28.988.263.927.259a.705.705 0 00-.144.007 1.558 1.558 0 00-.314.077L.453.35c-.01.003-.02.006-.028.01L.408.365a.129.129 0 01-.094-.24L.328.12A1.884 1.884 0 01.503.063C.587.039.67.021.748.01zM3.012.387l.031-.001c.127-.003.23 0 .308.014.043.007.087.019.133.035.069.025.14.058.213.098.046.025.09.052.132.08l.018.01.062.043c.056.042.068.12.027.176a.125.125 0 01-.17.03L3.747.86 3.734.85A1.79 1.79 0 003.4.673.557.557 0 003.31.648a1.655 1.655 0 00-.29-.01 5.306 5.306 0 00-.243.013l-.036.003a6.493 6.493 0 00-.069.006L2.61.666a.126.126 0 01-.032-.25h.018l.029-.004h.007a6.774 6.774 0 01.38-.025z"
                fill="#000"
                transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(11.53 2.856)"
              />
            </G>
            <G transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(6.056)">
              <Mask id="r" fill="#fff">
                <Use xlinkHref="#q" />
              </Mask>
              <Path
                d="M7.666 1.147s-1.303 1.94-2.477 2.764c-1.9 1.334.46 2.36-.212 5.844 0 0-.894 1.771 0 2.417 0 0-1.37-.899-2.237-.477-.866.421-1.062-.619-1.79-.45-.726.169-.95-.337-.95-.337S1.063 9.924 1.063 8.8s-.28-1.77.391-2.614c.671-.843.084-1.574 1.09-2.473 1.007-.9 1.622-5.396 5.397-3.036 0 0 2.181.358 1.79 2.943-.392 2.586.56 3.578.447 5.264-.111 1.686 1.119 2.417 1.119 2.417s-.475.169-.95.618c-.476.45-1.371-.309-1.623.17-.251.477-1.034.14-1.034.14s.503-1.659.335-2.698c-.167-1.04-.56-1.452-.337-2.07 0 0 .817-.45 1.22-1.92.405-1.468.253-4.022-1.242-4.394"
                fill="#8B4E26"
                fillRule="nonzero"
                mask="url(#r)"
              />
            </G>
            <Path
              d="M.748.387c0 .204-.163.37-.365.37a.367.367 0 01-.366-.37c0-.204.164-.37.366-.37.202 0 .365.166.365.37"
              transform="translate(2.585 2.599) matrix(-1 0 0 1 25.808 0) translate(9.876 4.588)"
              fill="#FFF"
              fillRule="nonzero"
            />
          </G>
        </G>
        <Path
          fill="#237978"
          fillRule="nonzero"
          transform="translate(70 33) translate(.792) translate(0 3.418)"
          d="M0 0H34.5413962V20.7881947H0z"
        />
        <G transform="translate(70 33) translate(.792) translate(0 3.418)">
          <Mask id="t" fill="#fff">
            <Use xlinkHref="#s" />
          </Mask>
          <G mask="url(#t)">
            <Path
              d="M2.935 15.427l-.839 7.894s8.16 1.972 11.135-.607l.301-6.95-10.597-.337z"
              fill="#D60000"
              fillRule="nonzero"
              transform="translate(9.293 -1.405) translate(0 4.588)"
            />
            <Path
              d="M6.497 5.15s-.086 1.9-1.188 2.57c-1 .61 1.119 4.14 3.399 3.93 1.248-.116 1.54-3.546 1.54-3.546s-1.007-.196-.635-1.74L6.497 5.15z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(9.293 -1.405) translate(0 4.588)"
            />
            <Path
              d="M8.323 7.082c-1.478-.18-2.545-1.157-2.694-2.777-.43.069-.531-1.056-.412-1.418.12-.362.511-.15.511-.15.022-.058.04-.117.055-.18C6.43-.105 8.238.001 8.238.001c3.323.174 2.544 3.584 2.354 4.488-.19.905-.84 2.766-2.27 2.593z"
              fill="#F6CBC3"
              fillRule="nonzero"
              transform="translate(9.293 -1.405) translate(0 4.588)"
            />
            <G transform="translate(9.293 -1.405) translate(0 4.588) translate(0 6.719)">
              <Path
                d="M13.237 11.24c-.49-.2-1.122-.836-1.878-1.888a20.7 20.7 0 01-.989-1.519l.081-.047c.017.03 1.687 2.903 2.821 3.367l-.035.087z"
                fill="#864A56"
                fillRule="nonzero"
              />
              <G transform="translate(0 .897)">
                <Mask id="v" fill="#fff">
                  <Use xlinkHref="#u" />
                </Mask>
                <Path
                  d="M5.403.007S1.264.4.41 4.722c-.853 4.32-.955 9.418 5.622 9.682 6.577.264 7.137-.848 7.132-1.092-.005-.243.112-4.246.647-7.289.536-3.043.583-4.682-3.517-5.689 0 0-1.816 2.435-4.89-.327"
                  fill="#D60000"
                  fillRule="nonzero"
                  mask="url(#v)"
                />
              </G>
              <Path
                d="M8.547 2.683l.49-2.248s-.024-.374.559-.35C10.178.107 14.3.036 14.3.036s.327-.046.42.258c.093.304-1.118 5.408-1.118 5.408s.023.35-.373.35-5.264.188-5.264.188l.582-3.558"
                fill="#204EB2"
                fillRule="nonzero"
              />
              <G transform="translate(7.942 .007)">
                <Mask id="x" fill="#fff">
                  <Use xlinkHref="#w" />
                </Mask>
                <Path
                  d="M.616 2.678L.036 6.22c.293-.01 4.87-.186 5.25-.186.122 0 .216-.034.278-.1a.352.352 0 00.084-.239v-.003C5.66 5.642 6.858.589 6.766.29 6.677 0 6.373.04 6.36.041c-.043.001-4.131.07-4.707.048-.214-.009-.368.035-.46.13a.301.301 0 00-.087.208V.43l-.49 2.248zM.01 6.246l.585-3.573.488-2.246a.32.32 0 01.093-.225c.097-.1.259-.145.479-.137C2.229.088 6.317.02 6.358.018c.002 0 .335-.044.43.266.093.303-1.062 5.181-1.117 5.412a.37.37 0 01-.09.254c-.067.072-.166.108-.294.108-.392 0-5.215.186-5.264.187l-.014.001z"
                  fill="#000"
                  fillRule="nonzero"
                  mask="url(#x)"
                />
              </G>
              <Path
                d="M8.71 2.847L9.2.599s-.024-.374.559-.35C10.34.271 14.464.2 14.464.2s.326-.046.419.258c.093.304-1.118 5.408-1.118 5.408s.023.35-.373.35-5.264.188-5.264.188l.582-3.558"
                fill="#56ACF6"
                fillRule="nonzero"
              />
              <Path
                d="M6.176 6.37S7.531 4.82 7.9 4.155c.37-.665.37-1.053.339-1.358-.03-.304-.03-.665.216-.72.246-.055.277.083.307.416.031.332 0 .665.555.47.554-.193 1.908-.664 2.062-.276.154.387-.185.443-.185.443s.247.028.308.222c.062.193 0 .332-.184.415-.185.083-.277.11-.277.11s.369-.054.461.223c.093.277-.061.415-.215.47 0 0 .198.033.246.25.03.139-.154.222-.37.305-.29.112-1.17.138-1.908.61-.739.47-2.524 3.546-3.417 4.488-.893.942-1.786-1.496-1.786-1.496S5.16 7.313 6.176 6.37"
                fill="#F6CBC3"
                fillRule="nonzero"
              />
              <Path
                fill="#3F1315"
                fillRule="nonzero"
                d="M9.66423313 3.57836699L9.646089 3.51057126 11.1853589 3.09494783 11.203503 3.16274356z"
              />
              <Path
                fill="#3F1315"
                fillRule="nonzero"
                d="M9.66423313 4.29875519L9.646089 4.23095946 11.1853589 3.81533603 11.203503 3.88313176z"
              />
              <Path
                fill="#3F1315"
                fillRule="nonzero"
                d="M9.84892828 5.01911998L9.83083073 4.95132425 11.3701006 4.53572422 11.3881982 4.60351996z"
              />
              <G transform="translate(12.577 1.997)">
                <Mask id="z" fill="#fff">
                  <Use xlinkHref="#y" />
                </Mask>
                <Path
                  d="M.787 7.933s.215-1.109.307-2.494c.093-1.385.062-1.995-.061-2.106-.123-.11-.985-.083-.985-.526s.615-.305.615-.305-.646-.11-.584-.443c.061-.333.43-.277.43-.277S-.075 1.67.017 1.31c.092-.36.646-.222.646-.222S.14 1.006.356.646C.57.286.94.507 1.37.618c.431.11.462.139.493-.028.03-.166-.03-.61.308-.582.339.028.4.139.4.749s.092 1.163.185 1.717c.092.555.123 1.192.061 2.217-.061 1.025.8 4.045-.738 4.489C.54 9.623.787 7.933.787 7.933"
                  fill="#F6CBC3"
                  fillRule="nonzero"
                  mask="url(#z)"
                />
              </G>
              <Path
                fill="#3F1315"
                fillRule="nonzero"
                d="M14.0150492 4.6895475L13.1096594 4.49409633 13.1243564 4.42543442 14.0297462 4.62086218z"
              />
              <Path
                fill="#3F1315"
                fillRule="nonzero"
                d="M13.9933042 4.00880202L13.0878911 3.81332744 13.1025881 3.74466553 14.0080012 3.9401167z"
              />
              <Path
                fill="#3F1315"
                fillRule="nonzero"
                d="M14.0329488 3.27654956L13.1275589 3.08109839 13.1422559 3.01243649 14.0476458 3.20786424z"
              />
              <Path
                fill="#3F1315"
                fillRule="nonzero"
                d="M14.8663281 2.82972277L14.4199452 2.7327346 14.4347353 2.66411952 14.8811183 2.76108427z"
              />
            </G>
            <G>
              <G fillRule="nonzero">
                <Path
                  d="M2.304.012l.09.029-.01.028a5.512 5.512 0 00-.093.34c-.025.102-.046.2-.063.293L2.223.73c-.04.236-.046.417-.01.514l.003.006c.206.514-.074.774-.664.592l-.018-.006.029-.089c.53.17.743-.022.566-.462-.049-.122-.041-.328.008-.6a4.914 4.914 0 01.127-.537L2.272.12a6.02 6.02 0 01.014-.047l.011-.038.007-.023z"
                  fill="#000"
                  transform="translate(9.293 -1.405) translate(7.01 7.234) translate(0 .515)"
                />
                <Path
                  d="M.004 3.594l.046-.081.017.009.015.008.078.04.013.006c.098.048.207.097.323.141.267.104.53.174.775.197.111.01.217.01.315 0l.02-.002.011.093c-.11.014-.23.014-.354.002a3.032 3.032 0 01-.8-.202 4.234 4.234 0 01-.33-.145l-.039-.018a3.198 3.198 0 01-.045-.024l-.027-.014-.018-.01z"
                  fill="#C86A5D"
                  transform="translate(9.293 -1.405) translate(7.01 7.234) translate(0 .515)"
                />
              </G>
              <Path
                d="M1.655 3.09c-.44 0-.771-.202-.787-.212a.07.07 0 01-.023-.097.07.07 0 01.097-.022c.006.004.395.239.865.181a.07.07 0 01.017.14 1.39 1.39 0 01-.17.01M1.836 2.415c-.094 0-.212-.018-.358-.066l.028-.089c.273.088.475.08.568-.02.08-.086.079-.243-.001-.442-.055-.135-.041-.392.04-.762.059-.271.134-.508.135-.51l.089.028c-.082.256-.273.972-.178 1.209.121.3.058.46-.017.54-.053.058-.145.112-.306.112"
                fill="#000"
                fillRule="nonzero"
                transform="translate(9.293 -1.405) translate(7.01 7.234) translate(.047)"
              />
              <G transform="translate(9.293 -1.405) translate(7.01 7.234) translate(.047) translate(0 .022)">
                <Mask id="B" fill="#fff">
                  <Use xlinkHref="#A" />
                </Mask>
                <Path
                  d="M1.447.683a.09.09 0 01-.042-.01C1.27.6.877.41.695.39.523.371.262.5.175.55.11.589.036.54.01.44-.016.34.016.23.082.19.097.18.45-.025.714.004c.252.029.754.292.776.303.066.035.102.146.078.247-.018.08-.068.13-.12.13"
                  fill="#000"
                  fillRule="nonzero"
                  mask="url(#B)"
                />
              </G>
              <Path
                d="M3.603.917a.124.124 0 01-.074-.025C3.458.84 3.238.698 3.078.672c-.17-.03-.563.003-.7.018A.126.126 0 012.35.44c.021-.002.528-.057.768-.017.252.043.545.258.558.267a.126.126 0 01-.074.227"
                fill="#000"
                fillRule="nonzero"
                transform="translate(9.293 -1.405) translate(7.01 7.234) translate(.047)"
              />
              <G fill="#000" fillRule="nonzero">
                <Path
                  d="M.292.234C.292.348.23.441.155.441.079.441.018.348.018.234.018.12.079.027.155.027.23.027.292.12.292.234M2.044.47c0 .113-.061.206-.137.206-.075 0-.137-.093-.137-.207 0-.114.062-.207.137-.207.076 0 .137.093.137.207"
                  transform="translate(9.293 -1.405) translate(7.01 7.234) translate(.885 .89)"
                />
              </G>
            </G>
            <G>
              <G transform="translate(9.293 -1.405) translate(5.1) translate(1.141 .014)">
                <Mask id="D" fill="#fff">
                  <Use xlinkHref="#C" />
                </Mask>
                <Path
                  d="M4.145 3.536s-.095-.22.421-.245c.516-.024.72-1.085-.024-1.555S4.602.77 3.462.18C2.323-.41 1.76.723 1.76.723s-1.2-.47-1.62.494c-.42.965.3 1.35.3 1.35s-.48.326-.096.724c.384.398 1.42-.044 1.592.171.172.215 2.21.074 2.21.074"
                  fill="#752B00"
                  fillRule="nonzero"
                  mask="url(#D)"
                />
              </G>
              <Path
                d="M1.836 3.771s.364-.672 1.227-.735c.864-.063 1.252.245 1.352.735.1.49-2.579 0-2.579 0"
                fill="#FFF"
                fillRule="nonzero"
                transform="translate(9.293 -1.405) translate(5.1)"
              />
              <G transform="translate(9.293 -1.405) translate(5.1) translate(0 3.479)">
                <Mask id="F" fill="#fff">
                  <Use xlinkHref="#E" />
                </Mask>
                <Path
                  d="M.52 4.674C.496 4.57.56 4.445.712 4.3c.228-.217.144-1.218.684-1.398.54-.181 1.44-1.11 1.86-1.11.42 0 1.14.386 1.427.362.288-.024.672.555.696.856.024.302.417.84.173 2.187 0 0 .283-.45.283-.981 0-.53-.072-.651-.036-1.41.036-.76-.048-1.544-.6-1.906-.552-.362-.9-1.025-1.752-.856C2.596.212 1.936.007 1.432.55c-.37.4-.924.675-1.044 1.495-.112.764-.729 1.118-.13 1.94 0 0 .262.424.262.69z"
                  fill="#752B00"
                  fillRule="nonzero"
                  mask="url(#F)"
                />
              </G>
              <Path
                d="M1.898 3.716s.16-.661 1.207-.732c1.048-.07 1.253.384 1.302.754 0 0-.43-.29-.983-.17-.552.119-1.172.037-1.526.148"
                fill="#56ACF6"
                fillRule="nonzero"
                transform="translate(9.293 -1.405) translate(5.1)"
              />
            </G>
          </G>
        </G>
        <G transform="translate(70 33) translate(.792) translate(0 44.97)" fillRule="nonzero">
          <Path fill="#DDCBD5" d="M0 0H69.0827924V5.96958293H0z" />
          <Rect fill="#FCEBEC" x={61.7226567} y={1.89622046} width={5.93934999} height={2.34101291} rx={1.17050646} />
          <G transform="translate(2.047 1.896)" fill="#FCEBEC">
            <Path d="M0.538687398 1.6855293H1.538687398V2.6855293H0.538687398z" />
            <Rect x={0.259188575} y={2.15373188} width={1} height={1} rx={0.5} />
            <Rect x={0.352354849} y={0} width={1} height={1.45142801} rx={0.5} />
            <Path d="M.085.937a.093.093 0 01.101.085c.036.39.211.57.548.57.338 0 .503-.152.524-.483a.093.093 0 11.186.012c-.028.43-.274.658-.71.658-.438 0-.69-.257-.734-.74A.094.094 0 01.085.937z" />
          </G>
          <G transform="translate(6.568 1.896)" fill="#FCEBEC">
            <Rect x={0} y={0} width={2.53878098} height={2.34101291} rx={1.17050646} />
            <Path d="M2.67853039 0.856141866L3.37727744 0.421382324 3.37727744 1.94304072 2.67853039 1.50828118z" />
          </G>
        </G>
        <G transform="translate(70 33) translate(.792) translate(13.23 46.61)" fill="#FCEBEC" fillRule="nonzero">
          <Path d="M1.153 1.24a1.17 1.17 0 011.153.967 1.557 1.557 0 01-1.153.509C.696 2.716.285 2.52 0 2.207a1.17 1.17 0 011.153-.966z" />
          <Ellipse cx={1.15293264} cy={0.690598809} rx={1} ry={1} />
          <Path d="M2.95802921 0.53843297H3.95802921V1.7557596800000002H2.95802921z" />
          <Path
            transform="rotate(90 3.11 1.147)"
            d="M2.95725857 0.541515544L3.26159024 0.541515544 3.26159024 1.75267711 2.95725857 1.75267711z"
          />
        </G>
        <G transform="translate(70 33) translate(.792) translate(31.746 46.61)" fillRule="nonzero">
          <Rect fill="#FCEBEC" x={0} y={0} width={5.61326803} height={2.71557498} rx={1.35778749} />
          <Path fill="#DDB0C8" d="M2.80663401 0.468202583L3.6334847 1.35778749 1.97978333 1.35778749z" />
          <Path fill="#DDB0C8" d="M2.42232313 1.35778749H3.42232313V2.3577874899999998H2.42232313z" />
        </G>
      </G>
    </Svg>
  );
};
