import * as React from 'react';
import Svg, { Defs, LinearGradient, Stop, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const DinersClubImage = (props) => {
  return (
    <Svg width={66} height={46} viewBox="0 0 66 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Defs>
        <LinearGradient x1="70.752%" y1="81.957%" x2="32.476%" y2="18.043%" id="prefix__a">
          <Stop stopColor="#009BE0" offset="0%" />
          <Stop stopColor="#006BA8" offset="100%" />
        </LinearGradient>
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0h1300v7847H0z" />
        <Path
          d="M62.993.5c.691 0 1.317.281 1.77.734A2.49 2.49 0 0165.5 3h0v40a2.503 2.503 0 01-2.507 2.5h0H3.007a2.501 2.501 0 01-1.77-.734A2.49 2.49 0 01.5 43h0V3A2.503 2.503 0 013.007.5h0z"
          stroke="#E6E7E8"
          fill="#FFF"
        />
        <Path
          d="M17.552 23.952c6.488.032 12.409-5.339 12.409-11.872C29.96 4.936 24.04-.002 17.552 0H11.97C5.404-.002 0 4.937 0 12.08c0 6.535 5.404 11.904 11.969 11.872h5.583z"
          fill="url(#prefix__a)"
          transform="translate(18 11)"
        />
        <Path
          d="M29.992 11.998c-6 .002-10.86 4.909-10.862 10.965.002 6.055 4.863 10.962 10.862 10.963 6-.001 10.863-4.908 10.864-10.963-.001-6.056-4.864-10.963-10.864-10.965zm-6.885 10.965a6.959 6.959 0 014.42-6.486v12.97a6.956 6.956 0 01-4.42-6.484zm9.349 6.487V16.477a6.955 6.955 0 014.423 6.486 6.958 6.958 0 01-4.423 6.487z"
          fill="#F4F6F7"
        />
      </G>
    </Svg>
  );
};
