import React, { useState, useCallback, useContext, useEffect } from 'react';
import { View, TouchableOpacity, Text, ActivityIndicator } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useQuery, useMutation } from 'react-apollo';
import { MeshIcon, Divider, ListItem } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { SETTING_QUERY, UPDATE_SETTING_MUTATION } from '../graphql';

export const FollowManage = () => {
  const navigation = useNavigation();
  const navigateToFollowManageScreen = useCallback(() => navigation.push(`FollowSettings`), [navigation]);

  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={navigateToFollowManageScreen}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, backgroundColor: Colors.white, padding: 16 }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Follow</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const FollowSettings = () => {
  const { user } = useContext(AppContext);
  const { data, loading } = useQuery(SETTING_QUERY, {
    variables: {
      settingQuery: {
        query: `user.setting.follow`,
        user_id: user.id,
      },
    },
    fetchPolicy: `network-only`,
  });
  const [isAllowToAnyOne, setAllowToAnyone] = useState(false);

  const [updateSettingMutation] = useMutation(UPDATE_SETTING_MUTATION);
  useEffect(() => {
    if (data?.getSettings?.[0]?.value === `allow_anyone`) setAllowToAnyone(true);
    else setAllowToAnyone(false);
  }, [data]);

  const updateSetting = useCallback(
    async (_value) => {
      if (isAllowToAnyOne !== _value) {
        const value = _value === false ? `manual` : `allow_anyone`;
        try {
          await updateSettingMutation({
            variables: {
              input: {
                key: `user.setting.follow`,
                value,
                user_id: user?.id,
              },
            },
          });
          setAllowToAnyone(_value);
        } catch (error) {
          console.err(error);
        }
      }
    },
    [updateSettingMutation, setAllowToAnyone, isAllowToAnyOne],
  );

  return (
    <View>
      <Text style={localStyles.headerTitle}>Decide who can see your activity</Text>
      <View style={localStyles.headerContent}>
        <Text style={{ ...Typography.text(`base`, `bold`, `gray`) }}>Followers</Text>
      </View>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <ListItem
            title="Allow anyone to follow"
            titleStyle={localStyles.listItemTitle}
            rightIcon={
              <MeshIcon
                name="select-circle"
                color={isAllowToAnyOne ? Colors.brandPurple : Colors.gray}
                focused={isAllowToAnyOne}
              />
            }
            onPress={() => {
              updateSetting(true);
            }}
          />
          <Divider />
          <ListItem
            title="Manually approve follow requests"
            titleStyle={localStyles.listItemTitle}
            rightIcon={
              <MeshIcon
                name="select-circle"
                color={!isAllowToAnyOne ? Colors.brandPurple : Colors.gray}
                focused={!isAllowToAnyOne}
              />
            }
            onPress={() => {
              updateSetting(false);
            }}
          />
          <Divider />
        </>
      )}
    </View>
  );
};

const localStyles = {
  headerTitle: {
    paddingVertical: 16,
    textAlign: `center`,
    fontSize: 12,
  },
  headerContent: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    backgroundColor: Colors.backgroundColor,
  },
  listItemTitle: {
    fontFamily: `inter-semibold`,
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
    fontWeight: `500`,
  },
};
