import React, { useMemo, useState } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import FastImage from 'react-native-fast-image';
import Modal from 'react-native-modal';
import { ImageView } from '../ImageView';
import { CloseBoxOverlay } from '../../common-ui';
import { Colors } from '../../common-styles';

export const DIVIDER_WIDTH = 3;

type PostContentImagesProps = {
  width: number;
  imageUrls: string[];
  imagesToUpload?: any;
  onCloseImage?: (index: number) => void;
  onLayout?: () => void;
};

const PostContentImages: React.FC<PostContentImagesProps> = React.memo(
  ({ width, imageUrls: existing = [], imagesToUpload = [], onCloseImage = (_index: number) => {}, onLayout }) => {
    const appending = imagesToUpload.map((rnf: any) => rnf.uri);
    const imageUrls = [...existing, ...appending];
    const imageUrlLinks = imageUrls?.map((url) => ({ url }));
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [whichImage, setWhichImage] = useState(0);

    const zoom = (which: number) => {
      setWhichImage(which);
      setShowImageViewer(true);
    };

    const closeImageViewer = () => setShowImageViewer(false);

    const imgStyles = useMemo(
      () => ({
        fullSquare: {
          width,
          height: `100%`,
        },
        rectangle: {
          width,
          height: (width - DIVIDER_WIDTH) / 2,
        },
        halfSquare: {
          width: (width - DIVIDER_WIDTH) / 2,
          height: (width - DIVIDER_WIDTH) / 2,
        },
        row: {
          flexDirection: `row`,
        },
        rowHalf: {
          flexDirection: `row`,
          width,
          height: (width - DIVIDER_WIDTH) / 2,
        },
        noMargin: {
          margin: 0,
        },
        rowHeightDivider: {
          width,
          height: DIVIDER_WIDTH,
          backgroundColor: Colors.white,
        },
        rowWidthDivided: { width: DIVIDER_WIDTH, height: (width - DIVIDER_WIDTH) / 2, backgroundColor: Colors.white },
      }),
      [width],
    );

    let body;
    switch (imageUrls?.length) {
      case 1:
        body = (
          <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(0)}>
            <TouchableWithoutFeedback onPress={() => zoom(0)}>
              <ImageView image={imageUrls[0]} />
            </TouchableWithoutFeedback>
          </CloseBoxOverlay>
        );
        break;

      case 2:
        body = (
          <>
            <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(0)}>
              <TouchableWithoutFeedback onPress={() => zoom(0)}>
                <FastImage source={{ uri: imageUrls[0] }} resizeMode="cover" style={imgStyles.rectangle} />
              </TouchableWithoutFeedback>
            </CloseBoxOverlay>
            <View style={imgStyles.rowHeightDivider} />
            <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(1)}>
              <TouchableWithoutFeedback onPress={() => zoom(1)}>
                <FastImage source={{ uri: imageUrls[1] }} resizeMode="cover" style={imgStyles.rectangle} />
              </TouchableWithoutFeedback>
            </CloseBoxOverlay>
          </>
        );
        break;

      case 3:
        body = (
          <>
            <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(0)}>
              <TouchableWithoutFeedback onPress={() => zoom(0)}>
                <FastImage source={{ uri: imageUrls[0] }} resizeMode="cover" style={imgStyles.rectangle} />
              </TouchableWithoutFeedback>
            </CloseBoxOverlay>
            <View style={imgStyles.rowHeightDivider} />
            <View style={imgStyles.rowHalf as any}>
              <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(1)}>
                <TouchableWithoutFeedback onPress={() => zoom(1)}>
                  <FastImage source={{ uri: imageUrls[1] }} resizeMode="cover" style={imgStyles.halfSquare} />
                </TouchableWithoutFeedback>
              </CloseBoxOverlay>
              <View style={imgStyles.rowWidthDivided} />
              <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(2)}>
                <TouchableWithoutFeedback onPress={() => zoom(2)}>
                  <FastImage source={{ uri: imageUrls[2] }} resizeMode="cover" style={imgStyles.halfSquare} />
                </TouchableWithoutFeedback>
              </CloseBoxOverlay>
            </View>
          </>
        );
        break;

      case 4:
      default:
        body = (
          <>
            <View style={imgStyles.row as any}>
              <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(0)}>
                <TouchableWithoutFeedback onPress={() => zoom(0)}>
                  <FastImage source={{ uri: imageUrls[0] }} resizeMode="cover" style={imgStyles.halfSquare} />
                </TouchableWithoutFeedback>
              </CloseBoxOverlay>
              <View style={imgStyles.rowWidthDivided} />
              <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(1)}>
                <TouchableWithoutFeedback onPress={() => zoom(1)}>
                  <FastImage source={{ uri: imageUrls[1] }} resizeMode="cover" style={imgStyles.halfSquare} />
                </TouchableWithoutFeedback>
              </CloseBoxOverlay>
            </View>
            <View style={{ width, height: DIVIDER_WIDTH, backgroundColor: Colors.white }} />
            <View style={imgStyles.row as any}>
              <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(2)}>
                <TouchableWithoutFeedback onPress={() => zoom(2)}>
                  <FastImage source={{ uri: imageUrls[2] }} resizeMode="cover" style={imgStyles.halfSquare} />
                </TouchableWithoutFeedback>
              </CloseBoxOverlay>
              <View style={imgStyles.rowWidthDivided} />
              <CloseBoxOverlay disabled={!onCloseImage} onPress={() => onCloseImage(3)}>
                <TouchableWithoutFeedback onPress={() => zoom(3)}>
                  <FastImage source={{ uri: imageUrls[3] }} resizeMode="cover" style={imgStyles.halfSquare} />
                </TouchableWithoutFeedback>
              </CloseBoxOverlay>
            </View>
          </>
        );
        break;
    }

    return (
      <View style={imgStyles.fullSquare} onLayout={onLayout}>
        {body}
        <Modal
          isVisible={showImageViewer}
          onBackdropPress={closeImageViewer}
          onBackButtonPress={closeImageViewer}
          style={imgStyles.noMargin}
        >
          <ImageViewer
            enableSwipeDown
            enablePreload
            backgroundColor="#000000E0"
            imageUrls={imageUrlLinks}
            index={whichImage}
            onCancel={closeImageViewer}
            onClick={closeImageViewer}
            onSwipeDown={closeImageViewer}
            swipeDownThreshold={32}
          />
        </Modal>
      </View>
    );
  },
);

export default PostContentImages;
