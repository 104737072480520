import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';

// - - - - - - - - - -
// Wizard progress bar (green bars)

export const WizardProgress = React.memo(({ current, total, showStepNumbers, solid }) => {
  // progress bar
  const renderProgressBar = () => {
    const steps = new Array(total).fill(0).map((_, index) => index <= current);
    return (
      <View
        style={{
          flexDirection: `row`,
          justifyContent: `space-evenly`,
          backgroundColor: Colors.white,
        }}
      >
        {steps.map((value, index) => (
          <View
            key={`${value}-${index}` /* eslint-disable-line react/no-array-index-key */}
            style={{
              flex: 1,
              height: 10,
              marginHorizontal: solid ? 0 : 5,
              borderRadius: solid ? 0 : 20,
              backgroundColor: value ? Colors.brandGreen : Colors.lightWarmGray,
            }}
          />
        ))}
      </View>
    );
  };

  return (
    <View style={{ margin: solid ? 0 : 10 }}>
      {showStepNumbers && (
        <View style={{ flexDirection: `row` }}>
          <View style={{ flex: 1 }} />
          <Text style={{ ...Typography.text(), marginHorizontal: 8, marginBottom: 8 }}>
            <Text style={Typography.add(`bold`)}>{current + 1}</Text>
            <Text>/{total}</Text>
          </Text>
        </View>
      )}
      {renderProgressBar()}
    </View>
  );
});

WizardProgress.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  showStepNumbers: PropTypes.bool,
  solid: PropTypes.bool,
};

WizardProgress.defaultProps = {
  showStepNumbers: true,
  solid: false,
};
