import React, { useEffect, useState } from 'react';
import { Alert, ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { clearAsyncStorage, getAsyncStorageObject } from '../common-util';
import { ListItem, SafeAreaView, ThemedButton } from '../common-ui';
import { HackNavigator } from '../common-types/navigation-types';
import { Typography } from '../common-styles';

export const ViewLocalStorage: React.FC = () => {
  const navigation = useNavigation<HackNavigator>();
  const [data, setData] = useState<any[]>([]);

  const copyLocalStorageToData = async () => {
    // all keys:
    const keys = await AsyncStorage.getAllKeys();
    const data = await Promise.all(
      keys.map(async (item) => {
        if (item === `THEME_ID`) return [item, await AsyncStorage.getItem(item)];
        if (item === `GuestID`) return [item, await AsyncStorage.getItem(item)];
        return [item, await getAsyncStorageObject(item)];
      }),
    );
    setData(data);
  };

  const promptDelete = (key: string) => {
    Alert.alert(`Delete Item`, `Are you sure you want to delete stored value of "${key}"`, [
      {
        text: `Delete`,
        onPress: () => navigation.goBack(),
        style: `destructive`,
      },
      {
        text: `Cancel`,
        onPress: () => console.log(`Cancel Pressed`),
      },
    ]);
  };

  useEffect(() => {
    copyLocalStorageToData();
  }, []);

  return (
    <SafeAreaView>
      <ScrollView>
        <View style={{ flexDirection: `row` }}>
          <ThemedButton
            clear
            title="See context"
            onPress={() => navigation.navigate(`Context`)}
            containerStyle={{ marginBottom: 10, marginHorizontal: 16 }}
            titleStyle={Typography.text(`bold`, `link`)}
          />
          <ThemedButton
            clear
            title="See ReduxSlice"
            onPress={() => navigation.navigate(`ReduxSlice`)}
            containerStyle={{ marginBottom: 10, marginHorizontal: 16 }}
            titleStyle={Typography.text(`bold`, `link`)}
          />
          <ThemedButton
            clear
            title="Reload"
            onPress={copyLocalStorageToData}
            containerStyle={{ marginBottom: 10, marginHorizontal: 16 }}
            titleStyle={Typography.text(`bold`, `link`)}
          />
          <ThemedButton
            clear
            title="Clear storage"
            onPress={clearAsyncStorage}
            containerStyle={{ marginBottom: 10, marginHorizontal: 16 }}
            titleStyle={Typography.text(`bold`, `link`, `red`)}
          />
        </View>
        {data.map((item) => {
          // const showValue = JSON.stringify(Object.keys(item[1]));
          const showValue = JSON.stringify(item[1], null, 2);
          return <ListItem key={`${item[0]}`} title={item[0]} subtitle={showValue} onPress={() => promptDelete(item[0])} />;
        })}
      </ScrollView>
    </SafeAreaView>
  );
};
