import { Platform, Text, TextInput, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Colors, Typography } from '../common-styles';
import { LoadingIndicator, MeshIcon, ThemedButton } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { APIHelper } from './helpers';
import { setPaymentMethods } from '../redux/contributionSlice';
import { BASE_PAYMENT_API_URL } from '../../constants';

export const EditPaymentMethod = ({ navigation, route }) => {
  const { customer } = useSelector((state) => state.contributions);
  const dispatch = useDispatch();

  const { paymentMethod } = route.params;
  const { card, billing_details } = paymentMethod;
  const { exp_month, exp_year } = card;
  const { postal_code } = billing_details.address;
  const expDate = exp_month.toString() + exp_year.toString().slice(2, 4);
  const [expirationDate, setExpirationDate] = useState(expDate || ``);

  const [formatedExpirationDate, setFormatedExpirationDate] = useState(() => {
    const trimExpYear = `${exp_year}`.slice(2, 4);
    if (exp_month <= 9) return `0${exp_month}/${trimExpYear}`;
    return `${exp_month}/${trimExpYear}`;
  });

  //this is going to be improved with a custom hook in order to not make the same code in several components
  const getPaymentMethods = useCallback(async () => {
    if (customer) {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_PAYMENT_API_URL}payments/list/${customer?.id}`, {
          method: `GET`,
          headers: {
            'Content-Type': `application/json`,
          },
        });
        const data = await response.json();
        dispatch(setPaymentMethods(data?.paymentMethods));
        // setPaymentMethods(data?.paymentMethods);
      } catch (error) {
        console.error(`Error while getting payment methods`, error);
      }
      setLoading(false);
    }
  }, [customer, dispatch]);

  const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
    if (clearValue.length >= 4) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
  };

  const clearNumber = (value = ``) => value.replace(/\D+/g, ``);

  const [zipcode, setZipcode] = useState(postal_code || ``);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [idmUpdate, setIdmUpdate] = useState(null);
  const [errorObj, setErrorObj] = useState({
    field: null,
    message: ``,
  });

  useEffect(() => {
    setIdmUpdate(uuidv4());
  }, [expirationDate, zipcode]);

  useEffect(() => {
    if (expirationDate?.length > 3 && zipcode?.length > 0) {
      setButtonEnabled(true);
      return;
    }
    setButtonEnabled(false);
  }, [expirationDate, zipcode]);

  const onChangeTextHandler = (text, fieldIndex) => {
    if (fieldIndex === 1) {
      setExpirationDate(text);
      const formated = formatExpirationDate(text);
      setFormatedExpirationDate(formated);
    }

    if (fieldIndex === 2) {
      setZipcode(text);
    }
  };

  const updatePaymentMethod = async () => {
    setLoading(true);
    try {
      //Updating the payment method
      const exp_month = expirationDate.slice(0, 2);
      const exp_year = expirationDate.slice(2, 4);

      const paymentDetailsBody = {
        paymentDetails: {
          billing_details: {
            address: {
              postal_code: zipcode,
            },
          },
          card: {
            exp_month,
            exp_year,
          },
        },
      };

      const paymentMethodResult = await APIHelper(`payments/update/${paymentMethod?.id}`, `POST`, idmUpdate, paymentDetailsBody);

      if (paymentMethodResult.updated !== true) {
        const { error } = paymentMethodResult;
        const { raw } = error;
        const { code, message } = raw;

        if (code === `invalid_expiry_year`) {
          setErrorObj({ field: 1, message });
        }
      } else if (paymentMethodResult.status === `ok`) setErrorObj({ field: null, message: `` });

      if (paymentMethodResult.updated === true) {
        getPaymentMethods();
        navigation.goBack();
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <View style={{ marginHorizontal: 16 }}>
      <View style={{ flexDirection: `row`, justifyContent: `space-between` }}>
        <View style={{ flexDirection: `column` }}>
          <Text style={{ ...Typography.text(`bold`), marginTop: 17 }}>Expiration</Text>
          <View
            style={[
              errorObj && errorObj.field === 1 ? localStyles.mainInputStyleInvalid : localStyles.mainInputStyle,
              { width: 150 },
            ]}
          >
            <TextInput
              style={localStyles.cardInputStyle}
              keyboardType="numeric"
              placeholder="MM/YY"
              placeholderTextColor={Colors.textPlaceholder}
              onChangeText={(text) => onChangeTextHandler(text, 1)}
              value={formatedExpirationDate}
              maxLength={Platform.OS === `android` ? 5 : 4}
            />

            {errorObj && errorObj.field === 1 && (
              <MeshIcon name="info-circle" color={Colors.brightRed} style={{ marginLeft: 12, padding: 0 }} size={18} />
            )}
          </View>
        </View>

        <View style={{ flexDirection: `column` }}>
          <Text style={{ ...Typography.text(`bold`), marginTop: 17 }}>Zip Code</Text>
          <View style={[localStyles.mainInputStyle, { width: 150 }]}>
            <TextInput
              style={localStyles.cardInputStyle}
              onChangeText={(text) => onChangeTextHandler(text, 2)}
              value={zipcode}
              keyboardType="numeric"
            />
          </View>
        </View>
      </View>

      <Text style={{ ...Typography.text(), marginTop: 32 }}>
        If you want to change the card number, you will need to add a new payment method.
      </Text>

      <ThemedButton
        rounded
        title="Save"
        onPress={updatePaymentMethod}
        buttonStyle={{ padding: 15 }}
        containerStyle={{ marginTop: 40 }}
        titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`) }}
        leftIcon={loading && <LoadingIndicator size={18} style={{ marginHorizontal: 12 }} />}
        disabled={loading || !buttonEnabled}
      />
    </View>
  );
};

EditPaymentMethod.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const localStyles = {
  mainInputStyle: {
    flex: 1,
    marginTop: 7,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.taupe,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  mainInputStyleInvalid: {
    flex: 1,
    marginTop: 7,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.brightRed,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  cardInputStyle: { flex: 1, padding: 0, paddingLeft: 12 },
  InputContainerStyle: {
    flex: 1,
    marginTop: 7,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.taupe,
    borderWidth: 1,
    minHeight: 40,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};
