import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import { hash_alpha, hash_beta } from '../common-auth';
import { Colors, Typography } from '../common-styles';
import {
  KeyboardPaddingView,
  SafeAreaView,
  LoadingIndicator,
  ThemedButton,
  WizardProgress,
  BottomSheetModal,
} from '../common-ui';
import { commonPropTypes, formatPhone } from '../common-util';
import { client, REGISTERV3, REQUEST_VERIFY_TOKEN, VERIFY } from '../graphql';
import { checkAlpha, checkBeta, cleanAndFormatContact } from './helpers';
import { Smiles } from '../images';
import { AppContext } from '../../AppContext';

export class AccountVerification extends React.PureComponent {
  static propTypes = {
    navigation: commonPropTypes.navigation().isRequired,
    route: commonPropTypes.route().isRequired,
  };

  static contextType = AppContext;

  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      verificationToken: ``,
      submitting: false,
      identity: {},
      accountExists: false,
      modal: false,
      creatingAccount: true,
      sendingVerificationToken: false,
      verificationTokenSent: false,
      error: undefined,
      errorMessage: undefined,
      showResendVerificationCodeModal: false,
      contact: route.params?.contact,
    };
  }

  componentDidMount() {
    this.createIdentityAndRequestVerification();
  }

  //#region class methods
  goToNextWizardStep = () => {
    const { verificationToken } = this.state;
    const nextDisabled = verificationToken.trim().length < 6;
    if (!nextDisabled) {
      this.setState({ submitting: true });
      this.verify();
    }
    return console.log(`next disabled`);
  };

  // goToPreviousWizardStep = () => {
  //   const { navigation } = this.props;
  //   navigation.goBack();
  // };

  onChangeVerificationText = (text) => {
    this.setState({ verificationToken: text, error: false, errorMessage: `` });
  };

  renderInfoMessage = (contact) => {
    const { sendingVerificationToken, creatingAccount, verificationTokenSent } = this.state;
    if (creatingAccount) return `Creating your account, this may take a second...`;
    if (sendingVerificationToken && !creatingAccount)
      return `Sending a verification code to ${formatPhone.formatPhoneNumber(contact)}.`;
    if (!sendingVerificationToken && !creatingAccount && verificationTokenSent)
      return `Sent to ${formatPhone.formatPhoneNumber(contact)}. This code is valid for 5 minutes.`;
    return null;
  };

  renderError = () => {
    const { error, errorMessage } = this.state;
    if (error) {
      return (
        <View style={localStyles.errorContainer}>
          <Text style={localStyles.errorText}>{errorMessage}</Text>
        </View>
      );
    }
    return null;
  };

  //#region api calls
  createIdentityAndRequestVerification = async () => {
    const { contact } = this.state;
    // const contactLength = contact?.trim().length;
    // const nextDisabled = contactLength < 14 || contactLength === 0;

    // Disable button if contact is invalid
    // if (nextDisabled) return console.log(`next disabled`);

    console.log(`=== firing createIdentityAndRequestVerification() ===`);

    try {
      let generated_beta;
      let generated_salt;

      const cleaned = contact.startsWith(`+000555000`) ? contact : cleanAndFormatContact(contact);

      const alpha = hash_alpha(cleaned); // alpha hash the contact / sms used to sign up
      const { salts } = await checkAlpha(alpha, cleaned); // check if contact / sms are registered with us -- this is a GraphQL query
      const keysToTest = salts.map((salt) => {
        // What is salts??
        // check gen beta against salts in DB to find if ID exists already in table
        const beta = hash_beta(salt, cleaned, 10);
        generated_beta = beta;
        generated_salt = salt;
        return { beta, salt };
      });

      const betaChecked = await checkBeta(keysToTest);
      if (betaChecked) {
        const { id, has_password } = betaChecked;
        if (id && !has_password) {
          // user has created account but not yet verified / created a password -- continue with regular account setup
          // therefore -- do not create identity account but proceed with account verification and persona creation
          this.setState({ identity: { id } });
          await this.requestVerificationCode(id, contact);
          return null;
        }
        if (id && has_password) {
          // id was returned and account is fully realized with password -- send to login
          console.log(`account already verified -- let's verify and then send to login`);
          this.setState({ identity: { id }, accountExists: true });
          await this.requestVerificationCode(id, contact);
          return null;
        }
      }

      console.log(`no account registered: ${cleaned}`);

      // no account registered with contact number -- let's register them
      const { invite_token } = this.context;
      const registerInput = {
        alpha,
        beta: generated_beta,
        salt: generated_salt,
        cleartext: cleaned,
        invite_token: invite_token || ``,
      };

      // register and insert account into identity table in db
      const { data } = await client.mutate({
        mutation: REGISTERV3,
        variables: { input: registerInput },
        fetchPolicy: `no-cache`,
      });

      const identity = data.registerV3;
      if (!identity?.id) throw new Error(`Account not registered yet`);
      // identity exists
      this.setState({ identity, creatingAccount: false });
      console.log(`created identity: ${JSON.stringify(identity)}`);
      if (identity.expired_invite_token) {
        // invite_count > invite_limit
        this.setState({
          error: true,
          errorMessage: `Sorry, it looks like that invitation link has reached its limit of uses. Please contact the person who invited you for a new link.`,
        });
        return null;
      }
      // request verification token
      await this.requestVerificationCode(identity.id, contact);
      return null;
    } catch (err) {
      let message = `There was a server problem when registering your account. Please try again later.`;
      if (err.message.includes(`reached invite limit`)) message = `Invite token has reached invite limit.`;
      this.setState({ error: true, errorMessage: message });
      return null;
    } finally {
      console.log(`=== finished createIdentityAndRequestVerification ===`);
    }
  };

  requestVerificationCode = async (identity_id, contact) => {
    try {
      console.log(`=== firing requestVerificationCode() ===`);
      this.setState({ sendingVerificationToken: true });
      const { data } = await client.mutate({
        mutation: REQUEST_VERIFY_TOKEN,
        variables: { id: identity_id, contact },
        fetchPolicy: `no-cache`,
      });
      if (!data.RequestVerificationToken) {
        console.log(`!data.RequestVerificationToken`);
        this.setState({
          error: true,
          errorMessage: `Sorry, there was a server error requesting your verification token. Please request a new one.`,
          verificationTokenSent: false,
        });
      }
      this.setState({ verificationTokenSent: true });
    } catch (err) {
      console.error(`Error thrown in requestVerificationCode() in AccountVerification.jsx`, err.message);
    } finally {
      this.setState({ sendingVerificationToken: false });
    }
  };

  verify = async () => {
    try {
      const { navigation } = this.props;
      const { accountExists, verificationToken, identity } = this.state;

      const input = { id: identity.id, verification_token: verificationToken };
      const { data } = await client.mutate({ mutation: VERIFY, variables: { input }, fetchPolicy: `no-cache` });

      if (!data.VerifyAccount) {
        const errorMessage = `The verification code you entered does not match our records. Please try again.`;
        this.setState({ error: true, errorMessage });
        return;
      }

      const { verified, expired } = data.VerifyAccount;

      if (accountExists && verified) {
        console.log(`verified and account exists -- show account exists modal`);
        this.setState({ modal: true });
        return;
      }

      if (verified) {
        // push user to create password screen
        console.log(`user goes to create profile`);
        navigation.navigate(`CreatePassword`, { identity_id: identity.id });
        return;
      }

      if (expired) {
        this.setState({
          error: true,
          errorMessage: `We're sorry, that the code has expired. Please request a new one and try again.`,
        });
      } else if (!verified) {
        this.setState({
          error: true,
          errorMessage: `We're sorry, that code does not match our records. Please request a new one and try again.`,
        });
      }
      return;
    } catch (err) {
      console.error(`Error thrown in verify()`, err.message);
      return;
    } finally {
      this.setState({ submitting: false });
    }
  };

  requestVerificationToken = async () => {
    console.log(`requestVerificationToken`);
    const { route } = this.props;
    let identity = route.params?.identity;
    const contact = route.params?.contact;
    const { identity: stateIdentity } = this.state;
    if (!identity) identity = stateIdentity;
    console.log(`identity`, identity);
    console.log(`contact`, contact);
    if ((identity || stateIdentity) && contact) {
      await client.mutate({
        mutation: REQUEST_VERIFY_TOKEN,
        variables: { id: identity.id, contact },
        fetchPolicy: `no-cache`,
      });
      this.setState({ showResendVerificationCodeModal: true });
    }
  };
  //#endregion

  //#endregion

  render() {
    const { navigation, route } = this.props;
    const contact = route.params?.contact;
    const { verificationToken, submitting, modal, sendingVerificationToken, showResendVerificationCodeModal } = this.state;
    const nextDisabled = verificationToken.trim().length < 6;
    return (
      <KeyboardPaddingView>
        <SafeAreaView testID="VERIFICATIONSCREEN" style={{ flex: 1 }}>
          <ScrollView
            style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
            alwaysBounceVertical={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={localStyles.wizardProgressContainer}>
              <WizardProgress current={1} total={2} showStepNumbers={false} />
            </View>
            <View style={localStyles.container}>
              <View>
                <View style={localStyles.blob} />
                <Text allowFontScaling={false} style={localStyles.headerText}>
                  Enter your verification code
                </Text>
              </View>
              <Text style={localStyles.subtext}>{this.renderInfoMessage(contact)}</Text>
              <TextInput
                blurOnSubmit
                autoFocus
                onSubmitEditing={() => !nextDisabled && this.goToNextWizardStep()}
                value={verificationToken}
                onChangeText={this.onChangeVerificationText}
                placeholder="______"
                placeholderTextColor={Colors.textPlaceholder}
                keyboardType="number-pad"
                style={localStyles.inputStyle}
                maxLength={6}
                returnKeyType="done"
                clearButtonMode="always"
                testID="VERIFICATIONSCREEN_SMSCODE"
              />
              {this.renderError()}
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={this.requestVerificationToken}
                style={{ marginTop: 24 }}
                disabled={sendingVerificationToken}
              >
                <Text style={localStyles.requestNewCodeText}>Request a new verification code</Text>
              </TouchableOpacity>
              <ThemedButton
                rounded
                title="Continue"
                onPress={this.goToNextWizardStep}
                buttonStyle={{ padding: 15 }}
                containerStyle={{ marginTop: 30 }}
                leftIcon={submitting && <LoadingIndicator />}
                disabled={submitting || nextDisabled}
                testID="VERIFICATIONSCREEN_CONTINUE"
              />
            </View>
            {modal && <AccountExistsModal navigation={navigation} contact={contact} />}
            {showResendVerificationCodeModal && (
              <BottomSheetModal
                title="Verification code sent"
                visible={!!showResendVerificationCodeModal}
                onPressCancel={() => this.setState({ showResendVerificationCodeModal: false })}
                confirmTitle="Ok"
                showCancelBtn={false}
                onPressConfirm={() => this.setState({ showResendVerificationCodeModal: false })}
              >
                <View style={{ marginHorizontal: 16 }}>
                  <Text style={{ marginTop: 16, marginBottom: 32, textAlign: `center` }}>
                    We have sent you a new verification code
                  </Text>
                </View>
              </BottomSheetModal>
            )}
          </ScrollView>
        </SafeAreaView>
      </KeyboardPaddingView>
    );
  }
}

const AccountExistsModal = ({ navigation, contact }) => {
  const redirectToLogin = () => {
    navigation.replace(`Login`, { contact });
  };
  return (
    <Modal isVisible>
      <View style={{ backgroundColor: Colors.white, justifyContent: `center`, padding: 16, borderRadius: 20 }}>
        {/* <Image
          source={require(`../../assets/images/groups.png`)}
          style={{ height: 161, width: 206, alignSelf: `center`, marginTop: 33.5 }}
        /> */}
        <Smiles height={161} width={206} style={{ alignSelf: `center`, marginTop: 33.5 }} />
        <View style={{ marginTop: 10, marginBottom: 20 }}>
          <Text style={{ ...Typography.text(`large`, `black`, `bold`) }}>Good news!</Text>
          <Text style={{ ...Typography.text(), marginTop: 4 }}>You already have a registered Mesh account.</Text>
        </View>
        <ThemedButton rounded title="Log in" buttonStyle={{ padding: 15 }} onPress={redirectToLogin} />
      </View>
    </Modal>
  );
};

AccountExistsModal.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  contact: PropTypes.string.isRequired,
};

const localStyles = {
  container: {
    flex: 1,
    margin: 15,
    marginTop: 40,
  },
  headerText: {
    ...Typography.text(`large`, `black`, `bold`),
  },
  subtext: {
    ...Typography.text(`base`, `gray`),
    marginTop: 15,
    marginBottom: 24,
  },
  inputStyle: {
    padding: 10,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    borderRadius: 4,
    letterSpacing: 3,
  },
  errorContainer: {
    padding: 10,
    borderRadius: 4,
    marginTop: 10,
  },
  errorText: {
    ...Typography.text(`small`, `red`, `bold`),
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(79,206,221,0.7)`,
    borderTopStartRadius: 50,
    borderTopEndRadius: 18,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 25,
    left: -10,
    top: 2,
  },
  requestNewCodeText: {
    ...Typography.text(`base`, `theme`, `bold`),
  },
  meshHeaderText: {
    left: -14,
    bottom: 4,
    fontWeight: `bold`,
    color: Colors.darkestGray,
  },
  headerRightButtonText: {
    ...Typography.text(`base`, `bold`),
    marginLeft: 4,
  },
  wizardProgressContainer: {
    marginTop: 15,
  },
};
