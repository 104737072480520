/**
 * Promise.allSettled
 * RN64 ios seems to support allSettled but android is undefined
 * added polyfill in case it isn't defined in any platform
 * @param promises<any>[]
 * @returns any[]
 */

export const PromiseAllSettled = (promises: Promise<any>[]) => {
  if (Promise.allSettled) return Promise.allSettled(promises);
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then(
      (val) => ({ status: `fulfilled`, value: val }),
      (err) => ({ status: `rejected`, reason: err }),
    ),
  );
  return Promise.all(wrappedPromises);
};
