import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactNativeFile } from 'apollo-upload-client';
import { useMutation } from '@apollo/react-hooks';
import { useWindowDimensions } from 'react-native';
import { CREATE_COMMUNITY, refetchQueriesFor, ADD_ACTIVITY_3 } from '../../graphql';
import { defaultMetaStyle } from '../../post/helpers';

/**
 * These are the default settings for a new community.
 * We will override these as needed
 * */
const defaultSettings = [
  {
    key: `group.setting.description`,
    value: ``,
  },
  {
    key: `group.setting.visible`,
    value: `public`,
  },
  {
    key: `group.setting.guidelines`,
    value: JSON.stringify(``),
  },
];

/**
 * Select the settings for the new community using the defaultSettings object
 * and overriding the values depending on the preset selected
 * @param {Object} settings - additional settings
 * @returns {Array} - The settings for the new community
 * */
const settingsByPreset = (settings = {}) => {
  const additionalSettings = Object.values(settings).map((value) => {
    return {
      key: value.key,
      value: value.value,
    };
  });
  return [...defaultSettings, ...additionalSettings];
};

export const useCreateCommunity = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { width } = useWindowDimensions();
  const { communityName, categories, communityImage } = useSelector((state) => state.createCommunity);

  const [commitCreateCommunity] = useMutation(CREATE_COMMUNITY);
  const [commitCreateActivity] = useMutation(ADD_ACTIVITY_3);

  const createCommunity = useCallback(
    async (_settings) => {
      setLoading(true);
      try {
        const settings = settingsByPreset(_settings);
        if (!settings) throw new Error(`Unknown preset`);
        // Variables required for create a community, may change in the future
        const input = {
          name: communityName,
          description: ``,
          avatar_image_file: communityImage ? new ReactNativeFile(communityImage) : null,
          language: `english`,
          privacy: `public`,
          settings: [
            ...settings,
            { key: `group.setting.name`, value: communityName },
            { key: `group.setting.type`, value: categories.join(`,`) },
          ],
          // This field could be removed, server doesn't use it
          type: categories.join(`,`),
        };
        const { data: resCreateCommunity } = await commitCreateCommunity({
          variables: {
            input,
          },
          refetchQueries: refetchQueriesFor(`Group`),
        });

        if (!resCreateCommunity.createCommunity) {
          throw new Error(`Error creating community`);
        }

        // When a community has been created it must create automatic post
        const meta_style = defaultMetaStyle(width);
        const { fontSize, lineHeight } = meta_style;
        const blobIndex = Math.floor(Math.random() * 6);
        const adjustMetaStyle = {
          ...meta_style,
          fontSize: Math.floor((fontSize / width) * 375 * 1000),
          lineHeight: Math.floor((lineHeight / width) * 375 * 1000),
          blobIndex,
        };
        const createActivityInput = {
          group_id: resCreateCommunity.createCommunity?.group.id,
          content: `Welcome to ${resCreateCommunity.createCommunity?.group.name}!`,
          meta_style: adjustMetaStyle,
          as_community: true,
        };
        await commitCreateActivity({
          variables: {
            input: createActivityInput,
            files: [],
          },
          fetchPolicy: `no-cache`,
          refetchQueries: refetchQueriesFor(`Activity`),
        });
        setError(null);
        return resCreateCommunity.createCommunity;
      } catch (error) {
        console.error(`There was an issue creating the community`, error);
        setError(error);
        return error;
      } finally {
        setLoading(false);
      }
    },
    [communityName, communityImage, categories, commitCreateCommunity, width, commitCreateActivity],
  );

  return { loading, error, createCommunity };
};
