import React, { useCallback, useEffect, useState } from 'react';
import { Text, View, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { ReactNativeFile } from 'apollo-upload-client';
import { v4 as uuidv4 } from 'uuid';
import FastImage from 'react-native-fast-image';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import ImagePicker, { Image as PickedImage } from 'react-native-image-crop-picker';
import { useMutation } from '@apollo/react-hooks';
import { Colors } from '../common-styles';
import { MeshIcon } from '../common-ui';
import Input from '../common-ui/inputs/Input';
import { UPLOAD_CUSTOM_REACTION } from '../graphql';
import useMediaPermissons from '../hooks/useMediaPermissions';
import { HomeNavigatorScreens } from '../common-types/navigation-types';

const emojilist: Record<string, string[]> = require(`./emojis.json`);

const defaultImg = require(`../../assets/images/user_avatar.png`);

type UploadReactionRoute = RouteProp<HomeNavigatorScreens, `UploadReaction`>;

const UploadReaction: React.FC = () => {
  const { checkPermission } = useMediaPermissons();
  const [name, setName] = useState(``);
  const [image, setImage] = useState(defaultImg);
  const [error, setError] = useState(``);
  const navigation = useNavigation();
  const route = useRoute<UploadReactionRoute>();
  const { groupId } = route?.params;

  const [uploadCustomReaction] = useMutation(UPLOAD_CUSTOM_REACTION);

  const onChangeText = (value: string) => {
    setName(value);
    const fullEmojiList: string[] = Object.values(emojilist).flat() as string[];
    if (value.length < 2) setError(`Name is too short`);
    else if (value.length > 20) setError(`Name is too long`);
    else if (fullEmojiList.findIndex((it) => it === value) >= 0) setError(`Name is duplicated`);
    else setError(``);
  };

  const handleSave = useCallback(async () => {
    if (!name || error) return;
    const reaction = { name, file: image };
    const variables = { group_id: groupId, reaction };
    await uploadCustomReaction({ variables });
    navigation.goBack();
  }, [name, error, image, groupId, uploadCustomReaction, navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 5 }} onPress={handleSave}>
          <Text style={{ fontSize: 16 }}>Save</Text>
        </TouchableOpacity>
      ),
    });
  }, [name, image, navigation, handleSave]);

  const handleUploadReaction = async () => {
    const valid = await checkPermission();
    if (!valid) return;

    try {
      const image: PickedImage = await ImagePicker.openPicker({
        cropperCircleOverlay: false,
        cropping: true,
        compressImageQuality: 0.9,
        sortOrder: `asc`,
        smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
      });
      const imageFile = new ReactNativeFile({
        uri: image.path,
        type: Platform.OS === `android` ? `image/jpeg` : image.mime,
        name: uuidv4(),
      });
      setImage(imageFile);
    } catch (err) {
      console.log(`Error: picking reaction image`, err);
    }
  };

  return (
    <View style={styles.mainSection}>
      <View style={styles.imageContainer}>
        <View style={styles.imageWrapper}>
          <FastImage source={image} style={styles.mainImage} />

          <TouchableOpacity onPress={handleUploadReaction}>
            <View style={styles.cameraContainer}>
              <MeshIcon name="camera" focused color="white" size={24} />
            </View>
          </TouchableOpacity>
        </View>
      </View>

      <View>
        <Text style={styles.inputTitle}>Create a unique Reaction name between 2 - 20 characters</Text>

        <Input
          value={name}
          onChangeText={onChangeText}
          autoCapitalize="none"
          placeholder="Reaction Name"
          underlineColorAndroid="transparent"
          isError={!!error}
        />
        {!!error && <Text style={styles.errorMsg}>{error}</Text>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainSection: {
    padding: 20,
  },
  imageContainer: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    marginBottom: 40,
  },
  imageWrapper: {
    position: `relative`,
    width: 150,
    height: 150,
    borderColor: Colors.lightGray,
    borderWidth: 1,
  },
  mainImage: {
    width: `100%`,
    height: `100%`,
  },
  inputTitle: {
    fontSize: 13,
    color: Colors.textGray,
  },
  cameraContainer: {
    backgroundColor: Colors.brandPurple,
    padding: 10,
    borderRadius: 50,
    position: `absolute`,
    bottom: -15,
    right: -15,
  },
  errorMsg: {
    fontSize: 12,
    color: Colors.textRed,
    marginTop: 8,
  },
});

export default UploadReaction;
