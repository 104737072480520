import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreFitness = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <Rect x={0} y={0} width={163} height={98} rx={7} fill="#ECE8F6" />
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#4722A7">
          <TSpan x={12} y={22}>
            Fitness
          </TSpan>
        </Text>
        <Path
          d="M108.022 86.857c18.212-1.716 33.285-14.087 39.89-29.855 6.604-15.769-18.265-29.203-36.176-29.973C93.825 26.259 79.999 41.012 80 57.817c0 16.805 9.81 30.756 28.022 29.04z"
          fill="#4722A7"
          style={{
            mixBlendMode: `multiply`,
          }}
          transform="rotate(180 114.5 57)"
        />
        <G fillRule="nonzero">
          <Path
            d="M6.655 1.558c1.01 1.842.89 6.515-.416 6.845-.521.131-1.631-.19-2.76-.856l.708 4.904H0l1.441-6.568C.638 4.96.093 3.83.184 2.563c.262-3.65 5.46-2.847 6.47-1.005z"
            transform="translate(91 16) translate(3 .134) translate(21.46 6.482)"
            fill="#EAC7A8"
          />
          <Path
            d="M25.728 5.916c-.552-.088-2.038-.15-2.312-.15-.175 0-.365.04-.469-.117-.085-.129-.053-.347-.079-.496a7.082 7.082 0 00-.113-.516 5.175 5.175 0 00-.557-1.383 4.315 4.315 0 00-2.061-1.824c-.976-.41-2.08-.504-3.13-.421-1.123.089-2.14.451-3.2.793-1.01.325-2.069.453-3.126.294-1.065-.16-1.87-.724-2.767-1.26-.92-.548-1.97-.907-3.058-.824-1.014.077-1.95.486-2.788 1.033C1.282 1.557.635 2.173.269 3.04c-.393.933-.393 1.956.254 2.78.938 1.195 2.678 1.504 4.127 1.473a8.046 8.046 0 002.451-.44c1.055-.362 1.966-.977 2.953-1.475a7.788 7.788 0 011.444-.565c.486-.135.966-.196 1.407.091.466.305.884.603 1.418.794a4.665 4.665 0 001.599.28c1.06-.007 2.106-.33 3.137-.547.84-.177 1.739-.352 2.596-.183.357.071.88.217 1.008.58-2.173.385-3.026 3.013-2.414 4.941.331 1.041 2.318 2.258 3.091 1.71.49-.346.193-.801 0-1.102-.368-.575-.494-1.314.187-1.715.588-.345 1.206.475 1.218.474.162-.012 1.558-.784 2.52-1.91.949.054 1.093-.557.866-1.058-.226-.5-1.64-1.13-2.403-1.252z"
            fill="#191847"
            transform="translate(91 16) translate(3 .134)"
          />
          <G transform="translate(91 16) translate(0 33.134)">
            <Path
              fill="#DCAE92"
              d="M23.8565138 0L36.73573 21.7224242 46.3914366 40.086903 48.4711902 40.086903 32.2929182 8.90108053e-14z"
            />
            <Path
              d="M.29 1.12C.098 1.641 0 2.031 0 2.291c0 .315.052.775.156 1.38h9.776c.254-.847.107-1.305-.44-1.372a53.691 53.691 0 01-1.364-.183L3.584.073a.173.173 0 00-.23.093L3.06.881c-.506.239-.918.358-1.235.358-.257 0-.613-.105-1.066-.315a.345.345 0 00-.47.195z"
              transform="translate(44.829 38.12)"
              fill="#E4E4E4"
            />
            <Path
              d="M43.71 37.146l4.01-1.224c-1.757-5.3-3.91-11.132-6.46-17.496A258.088 258.088 0 0033.042 0H21.715c3.255 7.488 6.705 14.291 10.351 20.41 3.646 6.12 7.528 11.699 11.644 16.736z"
              fill="#DB2721"
            />
            <Path
              d="M23.01 0c-1.215 7.633-3.56 17.386-3.781 17.687-.148.2-5.458 1.996-15.93 5.385l.449 1.85c12.488-1.817 19.069-3.228 19.741-4.23C24.498 19.186 29.533 7.301 31.483 0H23.01z"
              fill="#EAC7A8"
            />
            <Path
              d="M6.98 21.473l.791 2.98c9.34.826 15.064.23 17.17-1.792 2.106-2.02 4.506-9.574 7.2-22.661H21.34c-1.708 10.716-2.662 16.3-2.863 16.749-.202.45-4.034 2.025-11.497 4.724z"
              fill="#FF4133"
            />
            <Path
              d="M.29 1.12C.098 1.641 0 2.031 0 2.291c0 .315.052.775.156 1.38h9.776c.254-.847.107-1.305-.44-1.372a53.691 53.691 0 01-1.364-.183L3.584.073a.173.173 0 00-.23.093L3.06.881c-.506.239-.918.358-1.235.358-.257 0-.613-.105-1.066-.315a.345.345 0 00-.47.195z"
              transform="rotate(80 -10.592 13.833)"
              fill="#E4E4E4"
            />
          </G>
          <G>
            <Path
              d="M8.572 14.232l3.548.706C8.552 21.603 6.64 25.136 6.38 25.536c-.583.9-.04 2.549.161 3.417-1.26.294-.762-1.606-2.356-1.21-1.455.363-2.69 1.292-4.046.039-.167-.154-.289-.636.28-.883 1.417-.616 3.51-1.768 3.85-2.216.462-.61 1.897-4.094 4.303-10.451zm35.094-5.336l-.064.094c-.535.79-1.035 1.618-.82 1.893.222.286.868-.03 1.123.436.17.31-.918.681-3.265 1.114l-4.7 3.61-2.003-2.572 6.274-2.762c1.1-1.097 2.16-1.932 3.184-2.503.306-.097.83-.127.271.69z"
              fill="#EAC7A8"
              transform="translate(91 16) translate(6 15.134)"
            />
            <Path
              d="M20.998 1.712l1.469-.386c3.525 2.874 5.515 10.347 7.768 11.162 2.13.771 4.951-.402 8.292-1.945l1.263 2.274c-2.837 3.672-9.262 7.128-12.111 5.725-4.556-2.242-6.374-11.514-6.681-16.83z"
              fill="#DB2721"
              transform="translate(91 16) translate(6 15.134) rotate(5 30.394 10.094)"
            />
            <Path
              d="M15.237 19.786h13.248c.606 0 .491-.876.404-1.319-1.01-5.102-4.953-10.603-4.953-17.866L20.116 0c-3.162 5.095-4.255 11.369-4.879 19.786z"
              fill="#DDE3E9"
              transform="translate(91 16) translate(6 15.134)"
            />
            <Path
              d="M21.382 0c3.43 11.722 4.376 19.532 2.837 23.43H12.294c.188-2.852.793-5.87 1.622-8.811-2.7 4.883-4.97 8.399-6.81 10.547l-2.777-.545C6.117 13.501 11.297 5.294 19.869 0h1.513z"
              fill="#FF4133"
              transform="translate(91 16) translate(6 15.134)"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
};
