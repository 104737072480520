import React from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Colors, Typography } from '../common-styles';
import { MeshIcon } from './MeshIcon';

type VisibleTagProps = {
  infoText: string;
  style?: ViewStyle;
};

export const VisibleTag: React.FC<VisibleTagProps> = ({ infoText, style = {} }) => {
  return (
    <View style={[localStyles.visibleTagContainer, style]}>
      <View style={localStyles.visibleTag}>
        <Text style={localStyles.visibleTagText}>Visible</Text>
        <MeshIcon name="eye" color={Colors.brandGreen} size={16} />
      </View>
      <Text style={localStyles.infoText}>{infoText}</Text>
    </View>
  );
};

const localStyles = StyleSheet.create({
  visibleTagContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    paddingLeft: 6,
    paddingBottom: 6,
  },
  visibleTag: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderRadius: 99,
    elevation: 5,
    shadowOffset: { height: 2, width: 2 },
    alignItems: `center`,
    shadowColor: Colors.darkGray,
    shadowOpacity: 0.1,
    backgroundColor: Colors.white,
    padding: 6,
    paddingHorizontal: 12,
  },
  visibleTagText: {
    fontSize: Typography.smallFontSize,
    color: Colors.brandGreen,
    marginRight: 8,
  },
  infoText: {
    fontSize: Typography.smallFontSize,
    color: Colors.gray,
    flexWrap: `wrap`,
    flexShrink: 2,
    marginLeft: 10,
  },
});
