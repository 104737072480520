import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { BETA_CHANNEL } from '../../constants';
import { getManifest, compareVersions } from '../../environment';
import { Colors } from '../common-styles';
import { MeshIcon } from '../common-ui';
import { MediaTypes } from './helpers';

interface ContentToolbarProps {
  canAddImages: boolean;
  contentMode: string;
  handleIconOptionPress: (target: string) => void;
  addPhoto: () => void;
  addVideo: () => void;
}

export const ContentToolbar: React.FC<ContentToolbarProps> = ({
  canAddImages,
  contentMode,
  handleIconOptionPress,
  addPhoto,
  addVideo,
}) => {
  const Options = useMemo(() => {
    const options = [
      {
        title: `Text`,
        icon: `text-post`,
        mode: MediaTypes.TEXT,
        show: true,
        onPress: () => handleIconOptionPress(MediaTypes.TEXT),
        iconSize: 18,
      },
      {
        title: `Image`,
        icon: `image`,
        mode: MediaTypes.IMAGE,
        show: canAddImages,
        // onPress: () => handleIconOptionPress(MediaTypes.IMAGE),
        onPress: () => addPhoto(),
        iconSize: 24,
      },
      {
        title: `Video`,
        icon: `video`,
        mode: MediaTypes.VIDEO,
        show: canAddImages,
        // onPress: () => handleIconOptionPress(MediaTypes.VIDEO),
        onPress: () => addVideo(),
        iconSize: 24,
      },
    ];

    const version = getManifest()?.version ?? `1.3.0`;
    if (compareVersions(version, BETA_CHANNEL) >= 0) {
      options.push(
        {
          title: `Poll`,
          icon: `poll`,
          mode: MediaTypes.POLL,
          show: true,
          onPress: () => handleIconOptionPress(MediaTypes.POLL),
          iconSize: 14,
        },
        {
          title: `Meme`,
          icon: `meme`,
          mode: MediaTypes.MEME,
          show: true,
          onPress: () => handleIconOptionPress(`meme`),
          iconSize: 24,
        },
      );
    }

    return options;
  }, [addPhoto, addVideo, canAddImages, handleIconOptionPress]);

  return (
    <View style={localStyles.mediaSelectorContainer}>
      {Options.map((option) => (
        <TouchableOpacity onPress={option.onPress} disabled={option.mode === contentMode} key={option.title}>
          <View style={localStyles.optionWrapper}>
            <MeshIcon
              name={option.icon as any}
              size={option.iconSize}
              color={option.mode === contentMode ? Colors.brandPurple : Colors.gray}
            />
          </View>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const localStyles = StyleSheet.create({
  mediaSelectorContainer: {
    width: `60%`,
    padding: 12,
    paddingBottom: 14,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
  },
  optionWrapper: {
    width: 50,
    height: 40,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
});
