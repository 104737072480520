import React from 'react';
import { View, Text, StyleSheet, ActivityIndicator, TouchableOpacity, Image } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { Colors, Typography } from '../common-styles';
import { Group } from '../common-types/types';
import { setPreviewCommunity } from '../redux/feedSlice';
import { MeshIcon } from '../common-ui';
import { parseMemberLength } from '../community/v2/helpers';
import { MeshVerifiedCheck } from '../images';

interface Props {
  title: string;
  communities: Group[];
  loading: boolean;
  handleSeeAll: () => void;
}

interface RenderCommunitiesProps {
  communities: Group[];
}

type NewOrFeaturedCommunitiesNavigationScreens = {
  NewOrFeaturedCommunities: undefined;
  CommunityPreview: undefined;
  HomeScreen: undefined;
};

type NewOrFeaturedCommunitiesNavigation = StackNavigationProp<
  NewOrFeaturedCommunitiesNavigationScreens,
  `NewOrFeaturedCommunities`
>;

export const NewOrFeaturedCommunities: React.FC<Props> = ({ title, communities, loading, handleSeeAll }) => {
  return (
    <View style={localStyles.container}>
      <View style={localStyles.titleContainer}>
        <Text style={localStyles.sectionTitle}>{title}</Text>
        <TouchableOpacity onPress={handleSeeAll} activeOpacity={0.8}>
          <Text style={localStyles.seeAllButton}>See all</Text>
        </TouchableOpacity>
      </View>
      {loading ? (
        <View style={localStyles.center}>
          <ActivityIndicator />
        </View>
      ) : (
        <View style={localStyles.center}>
          <RenderCommunities communities={communities} />
        </View>
      )}
    </View>
  );
};

const RenderCommunities = ({ communities }: RenderCommunitiesProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<NewOrFeaturedCommunitiesNavigation>();

  const onSelectCommunity = async (group: Group) => {
    await dispatch(setPreviewCommunity(group));
    navigation.navigate(`CommunityPreview`);
  };

  if (!communities) return null;

  return (
    <View style={localStyles.communitiesContainer}>
      {communities.map((group: Group) => {
        const membersLength = parseMemberLength(group?.group_member_count || 0);
        return (
          <TouchableOpacity
            onPress={() => onSelectCommunity(group)}
            key={group?.id}
            style={localStyles.communityItem}
            activeOpacity={0.8}
          >
            {group.avatar_url ? (
              <View style={localStyles.groupItemShadow}>
                <Image style={localStyles.groupImage} resizeMode="cover" source={{ uri: group.avatar_url }} />
              </View>
            ) : (
              <View style={localStyles.groupItemShadow}>
                <Image style={localStyles.groupImage} resizeMode="cover" source={require(`../../assets/images/node.png`)} />
              </View>
            )}
            <View style={{ flexDirection: `row`, alignItems: `flex-start` }}>
              <Text numberOfLines={1} ellipsizeMode="tail" style={localStyles.groupNameText}>
                {group.name}
              </Text>
              {group.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 2, marginTop: 6 }} />}
            </View>

            <View style={localStyles.membersContainer}>
              <MeshIcon name="group" color="gray" size={12} />
              <Text style={localStyles.membersText}>
                {membersLength} {group?.group_member_count === 1 ? `Member` : `Members`}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingVertical: 16,
    marginTop: 12,
  },
  center: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
    height: 172,
  },
  communityItem: {
    width: 110,
    marginHorizontal: 10,
    alignItems: `center`,
  },
  titleContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    paddingHorizontal: 32,
  },
  sectionTitle: {
    ...Typography.text(`plustwo`, `bold`),
  },
  seeAllButton: {
    ...Typography.text(`plustwo`, `theme`, `bold`),
  },
  groupImage: {
    // borderWidth: 0.5,
    borderRadius: 150 / 2,
    overflow: `hidden`,
    width: 99,
    height: 99,
  },
  groupItemShadow: {
    shadowColor: Colors.avatarShadowColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  groupNameText: {
    ...Typography.text(`center`),
    marginTop: 4,
    marginBottom: 8,
  },
  membersContainer: {
    flexDirection: `row`,
    justifyContent: `center`,
  },
  membersText: {
    ...Typography.text(`smallest`),
    marginLeft: 5,
  },
  communitiesContainer: {
    flex: 1,
    marginTop: 12,
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    alignItems: `center`,
  },
});
