import React from 'react';
import { TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Button as RNEButton, ButtonProps } from 'react-native-elements'; // FIXME: stop using react-native-elements
import { Typography, Colors, MeshIconsNames } from '../common-styles';
import { MeshIcon } from '../common-ui';

interface AuthButtonProps extends ButtonProps {
  title?: string;
  iconName?: MeshIconsNames;
  bare?: boolean;
  buttonStyle?: ViewStyle;
  disabledStyle?: ViewStyle;
  titleStyle?: TextStyle;
  testID?: string;
  onPress: () => void;
  loading?: boolean;
}

export const AuthButton: React.FC<AuthButtonProps> = ({
  title,
  iconName,
  bare = false,
  buttonStyle,
  disabledStyle,
  titleStyle,
  testID = `AUTHBUTTON`,
  onPress,
  loading = false,
  ...rest
}) => {
  const margin = 5;
  if (bare)
    return (
      <TouchableOpacity onPress={onPress}>
        <View>
          <MeshIcon name={iconName} />
        </View>
      </TouchableOpacity>
    );

  return (
    <RNEButton
      title={title}
      testID={testID}
      disabledStyle={disabledStyle}
      buttonStyle={{
        ...buttonStyle,
        marginHorizontal: 10,
        marginTop: 10,
        backgroundColor: Colors.deepPurple,
        borderRadius: 9999,
        margin,
      }}
      titleStyle={{
        marginVertical: 3,
        fontSize: Typography.buttonFontSize,
        alignSelf: `center`,
        color: Colors.white,
        ...titleStyle,
      }}
      onPress={onPress}
      loading={loading}
      {...rest}
    />
  );
};
