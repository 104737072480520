export const MenuTestIds = {
  about: `MENU_ABOUT`,
  topic: `MENU_TOPIC`,
  tip: `MENU_TIP`,
  request: `MENU_REQUEST`,
  members: `MENU_MEMBERS`,
  coc: `MENU_COC`,
  application: `MENU_APPLICATION`,
  settings: `MENU_SETTINGS`,
  groupPostNotifications: `MENU_GROUP_POST_NOTIIFCATIONS`,
  report: `MENU_REPORT_COMMUNITY`,
  leave: `MENU_LEAVE`,
  join: `MENU_JOIN`,
};

export const PostTestIds = {
  edit: `POST_EDIT`,
  pin: `POST_PIN`,
  delete: `POST_DELETE`,
  followUser: `FOLLOW_USER`,
  unfollowerUser: `REMOVE_FOLLOWER`,
  viewProfile: `VIEW_PROFILE`,
  followingNotifiOn: `FOLLOW_NOTIFICATION_ON`,
  followingNotifiOff: `FOLLOW_NOTIFICATION_OFF`,
  postReport: `POST_REPORT`,
  userReport: `USER_REPORT`,
  editTopics: `EDIT_TOPICS`,
  hidePost: `HIDE_POST`,
  turnOffComment: `TURN_OFF_COMMENT`,
};

export const ModerationTestIds = {
  messages: `TO_MESSAGES`,
  promoteOwner: `PROMOTE_TO_OWNER`,
  promoteLeader: `PROMOTE_TO_COMMUNITY_LEADER`,
  promoteMod: `PROMOTE_TO_MOD`,
  promoteCreator: `PROMOTE_CREATOR`,
  viewProfile: `VIEW_PROFILE`,
  unban: `UN_BAN`,
  ban: `BAN_FROM_COMMUNITY`,
  demoteManager: `DEMOTE_TO_MANAGER`,
  demoteModerator: `DEMOTE_TO_MODERATOR`,
  demoteCreator: `DEMOTE_TO_CREATOR`,
  demoteMember: `DEMOTE_TO_MEMBER`,
  kick: `KICK_FROM_COMMUNITY`,
  report: `REPORT_USER`,
  reportCommunity: `MENU_REPORT_COMMUNITY`,
};
