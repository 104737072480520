import { ApolloProvider as ApolloHooksProvider, useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import React, { useContext, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider } from './packages/common-ui';
import { ContributionProvider } from './packages/contribution/context/ContributionContext';
import { CHECK_AGREED_TERMS, client } from './packages/graphql';
import { AppNavigator } from './packages/navigation/AppNavigator';
import { AuthNavigator } from './packages/navigation/AuthNavigator';
import { GuestStackNavigator } from './packages/navigation/GuestNavigator';
import { withTheme } from './packages/themes/ThemeProvider';
import { linking } from './packages/navigation/meshLinking';
import { AppContext } from './AppContext';

console.log(`=== AppChild NATIVE ====`);
const Stack = createStackNavigator();

const _AppChild = ({ firstScreen, setTheme }) => {
  const navigationRef = React.useRef(null);
  const { user, identity } = useContext(AppContext);
  const [checkAgreedTerms, { data }] = useLazyQuery(CHECK_AGREED_TERMS, {
    client,
  });
  useEffect(() => {
    /*const setAppTheme = async () => {
      const theme = await fetchTheme(); 
      if (theme?.value) setTheme(theme.value);
      else setTheme(`MESH PURPLE`);
    }*/
    setTheme(`MESH BLURPLE`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (identity?.id) checkAgreedTerms({ variables: { identity_id: identity?.id } });
  }, [checkAgreedTerms, identity?.id]);

  useEffect(() => {
    if (data?.agreed_terms === false) {
      console.log(`push to terms screen`);
      navigationRef?.current?.navigate(`TermsScreen`, {
        identity_id: identity?.id,
        prev_screen: `AppChild`,
      });
    }
  }, [identity?.id, data, firstScreen]);

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <ContributionProvider>
          <SafeAreaProvider>
            <View style={{ flex: 1, backgroundColor: `#fff` }}>
              <NavigationContainer ref={navigationRef} linking={linking}>
                <Stack.Navigator screenOptions={{ headerShown: false }}>
                  {!user?.id && <Stack.Screen name="Auth" component={AuthNavigator} />}
                  {!user?.id && <Stack.Screen name="Guest" component={GuestStackNavigator} />}
                  {user?.id && <Stack.Screen name="Main" component={AppNavigator} />}
                </Stack.Navigator>
              </NavigationContainer>
              <FlashMessage position="top" animationDuration={750} />
            </View>
          </SafeAreaProvider>
        </ContributionProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

_AppChild.propTypes = {
  firstScreen: PropTypes.string,
  setTheme: PropTypes.func.isRequired,
};

_AppChild.defaultProps = {
  firstScreen: undefined,
};

export const AppChild = withTheme(_AppChild);
