/* eslint-disable react/require-default-props */
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { Colors } from '../common-styles';
import { MeshIcon } from './MeshIcon';

type Props = {
  children: React.ReactNode;
  onPress?: () => void;
  top?: number;
  disabled?: boolean;
  size?: number;
};

export const CloseBoxOverlay = React.memo<Props>(({ children, disabled = false, size = 16, onPress, top = 0 }: Props) => {
  const half = size / 2;

  if (disabled) return <View>{children}</View>;

  return (
    <View>
      {children}
      <TouchableOpacity
        style={[styles.icon, { top, margin: half, minWidth: size, minHeight: size }]}
        hitSlop={{ top: half, left: half, right: half, bottom: half }}
        onPress={onPress}
      >
        <MeshIcon name="close" size={size} color={Colors.white} style={{ margin: 1 }} />
      </TouchableOpacity>
    </View>
  );
});

const styles = StyleSheet.create({
  icon: {
    right: 0,
    position: `absolute`,
    backgroundColor: Colors.translucentCloseIcon,
    borderRadius: 999,
    zIndex: 999,
  },
});
