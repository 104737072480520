import React, { useEffect, useState } from 'react';
import { View, Image, Platform, Linking, TouchableWithoutFeedback, Modal, StyleSheet } from 'react-native';
import { VERSION_TO_COMPARE } from '../../constants';
import { compareVersions, getManifest } from '../../environment';
import { Colors } from '../common-styles';

export const VersionUpdateModal: React.FC = () => {
  const [showUpdateAppModal, setShowUpdateAppModal] = useState(false);

  useEffect(() => {
    const version = getManifest()?.version ?? `1.1.2`;
    if (compareVersions(version, VERSION_TO_COMPARE) < 0) {
      setShowUpdateAppModal(true);
    }
  }, []);

  const redirectToStore = () => {
    const idd = `id1473534230`;
    if (Platform.OS === `ios`) Linking.openURL(`itms-apps://itunes.apple.com/us/app/id${idd}?mt=8`);
    //if (Platform.OS === `ios`) Linking.openURL(`https://apps.apple.com/us/app/mesh-communities/id1473534230`);
    else if (Platform.OS === `android`) Linking.openURL(`https://play.google.com/store/apps/details?id=us.meshconnect.mesh`);
    //setShowUpdateAppModal(false);
  };

  return (
    <Modal visible={showUpdateAppModal}>
      <View style={style.container}>
        <TouchableWithoutFeedback onPress={redirectToStore}>
          <Image
            source={require(`../../assets/images/update_notification.png`)}
            style={style.imageBackground}
            resizeMode="contain"
          />
        </TouchableWithoutFeedback>
      </View>
    </Modal>
  );
};

const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  imageBackground: {
    flex: 1,
    width: `100%`,
    alignSelf: `center`,
  },
});
