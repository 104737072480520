import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, TextInput, View, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation, RouteProp, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Events, PendoTrackEvent } from '../pendo/events';
import { AuthNavigationContainer, AuthNavigatorScreens } from '../common-types/navigation-types';
import { AuthMainText, AuthSubText, HeaderSubmitButton, KeyboardPaddingView, MeshIcon } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { client, REGISTER_EMAIL } from '../graphql';
import { AppContext } from '../../AppContext';
import { EMAILSCREEN_EMAIL, EMAILSCREEN_SCREEN, EMAILSCREEN_SENDCODE, VERIFICATION_SKIP } from './testLabels';
import { setProfileEmail } from '../redux/createProfileSlice';

type RegisterEmailNavigator = StackNavigationProp<AuthNavigationContainer, `Auth`>;

type RegisterEmailRoute = RouteProp<AuthNavigatorScreens, `RegisterEmail`>;

export const RegisterEmail = () => {
  const { identity } = useContext(AppContext);

  const navigation = useNavigation<RegisterEmailNavigator>();
  const route = useRoute<RegisterEmailRoute>();
  const { handle } = route.params;

  const dispatch = useDispatch();

  const [email, setEmail] = useState(``);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const onEmailChange = useDebouncedCallback((text) => {
    setIsValid(regex.test(text));
    setEmail(text);
  }, 500);

  const onSendCodePress = useCallback(async () => {
    try {
      setLoading(true);
      if (!identity?.id || !email) {
        console.error(`No id or email`, { identity_id: identity, email });
        return;
      }
      //@ts-ignore outdated client.mutate type
      const { data, error } = await client.mutate({
        mutation: REGISTER_EMAIL,
        variables: { id: identity.id, contact: email },
        fetchPolicy: `no-cache`,
      });
      if (!data.RegisterEmail || error) {
        console.error(`[RegisterEmailError]`, error?.message);
      }
      PendoTrackEvent(Events.EMAIL_SEND_CODE, { username: handle });
      dispatch(setProfileEmail(email));
      navigation.navigate(`Auth`, { screen: `VerifyEmail`, params: { handle, email } });
    } catch (e: any) {
      console.error(`[RegisterEmailError]`, e.message);
    } finally {
      setLoading(false);
    }
  }, [dispatch, email, identity, handle, navigation]);

  const onSkip = () => {
    dispatch(setProfileEmail(email));
    PendoTrackEvent(Events.VERIFY_EMAIL_SKIP);
    navigation.navigate(`Persona`, { screen: `DOB` });
  };

  useEffect(() => {
    navigation.setOptions({
      //headerLeft: () => undefined,
      headerRight: () => (
        <HeaderSubmitButton
          disabled={!isValid || loading}
          title="Verify now"
          onPress={onSendCodePress}
          testID={EMAILSCREEN_SENDCODE}
        />
      ),
    });
  }, [navigation, isValid, loading, onSendCodePress]);

  return (
    <KeyboardPaddingView>
      <SafeAreaView testID={EMAILSCREEN_SCREEN} style={{ flex: 1 }}>
        <ScrollView keyboardShouldPersistTaps="handled" style={{ flex: 1, backgroundColor: Colors.white }}>
          <View style={styles.container}>
            <AuthMainText text="Add an email address" />
            <AuthSubText
              text="Enter your email address. You can verify it now or later."
              style={{ marginTop: 30, marginLeft: 10, marginBottom: 5 }}
            />

            <View style={{ display: `flex`, flexDirection: `row`, alignItems: `center` }}>
              <View style={email === `` || isValid ? styles.emailContainer : styles.invalidEmailContainer}>
                <TextInput
                  autoFocus
                  autoCapitalize="none"
                  style={{ flex: 1, paddingHorizontal: 8 }}
                  onChangeText={onEmailChange}
                  defaultValue=""
                  keyboardType="email-address"
                  placeholder="example: someone@email.com"
                  placeholderTextColor={Colors.textPlaceholder}
                  testID={EMAILSCREEN_EMAIL}
                />
              </View>
              {email !== `` && (
                <MeshIcon
                  name={isValid ? `check` : `info-circle`}
                  color={isValid ? Colors.brandGreen : Colors.brightRed}
                  size={18}
                  style={{ marginLeft: 5 }}
                />
              )}
            </View>
          </View>
          <View
            style={{
              flex: 1,
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              justifyContent: `center`,
              marginTop: 30,
            }}
          >
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={onSkip}
              disabled={!isValid || loading}
              style={{ marginBottom: 40, display: `flex`, flexDirection: `row`, alignItems: `center` }}
            >
              <AuthSubText
                text="Verify later"
                testID={VERIFICATION_SKIP}
                style={{
                  color: !isValid || loading ? Colors.gray : Colors.brandPurple,
                  fontWeight: Typography.mediumBoldFontWeight,
                }}
              />
              <MeshIcon
                name="arrow-right"
                size={18}
                color={!isValid || loading ? Colors.gray : Colors.brandPurple}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: 16,
    marginTop: 20,
  },
  emailContainer: {
    flex: 1,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    minHeight: 40,
    padding: 3,
    paddingTop: 10,
    paddingBottom: 10,
  },
  invalidEmailContainer: {
    flex: 1,
    flexDirection: `row`,
    borderRadius: 4,
    borderColor: Colors.brightRed,
    borderWidth: 1,
    minHeight: 40,
    padding: 3,
    paddingTop: 10,
    paddingBottom: 10,
  },
});
