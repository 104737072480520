import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const SeeMore = React.memo((props) => {
  return (
    <Svg width="214pt" height="284" viewBox="0 0 214 213" {...props}>
      <G>
        <Path
          fill="#F2E35A"
          d="M94.934 33.547c5.437 14.152-6.067 29.98-24.977 39.992-18.832 10.07-44.992 14.266-58.465 7.05C-1.984 73.43-2.77 54.86 4.793 38.638c7.644-16.219 23.64-30.09 42.945-30.875 19.223-.727 41.68 11.691 47.196 25.785zm0 0"
        />
        <Path
          fill="#4FCEDD"
          d="M147.531 22.375c10.281 9.57 15.285 23.55 17.012 39.086 1.637 15.453 0 32.46-10.277 44.156-10.188 11.692-29.11 18.149-46.028 16.352-16.922-1.801-31.84-11.856-46.027-23.551C48.02 86.727 34.465 73.316 34.375 59.824c0-13.406 13.371-26.898 27.652-36.465 14.192-9.648 29.2-15.375 44.48-15.617 15.282-.246 30.837 4.985 41.024 14.633zm0 0"
        />
        <Path
          fill="#F2E35A"
          d="M199.36 16.086a1.225 1.225 0 00-1.051-.844l-6.645-.601-2.625-6.164a1.227 1.227 0 00-1.125-.747c-.492 0-.934.293-1.125.747l-2.629 6.164-6.64.601a1.235 1.235 0 00-1.055.844c-.153.469-.012.98.36 1.305l5.019 4.414-1.48 6.539a1.225 1.225 0 001.19 1.496c.22 0 .434-.059.63-.172l5.73-3.434 5.727 3.434c.418.25.949.23 1.343-.059.399-.289.586-.789.477-1.265l-1.48-6.54L199 17.392c.371-.325.512-.836.36-1.305zm0 0M164.27 5.89a.857.857 0 00-.743-.593l-4.683-.43-1.852-4.344a.863.863 0 00-1.586 0l-1.851 4.344-4.684.43a.852.852 0 00-.738.594.865.865 0 00.25.918l3.539 3.109-1.043 4.61a.862.862 0 00.84 1.059.859.859 0 00.441-.126l4.04-2.418 4.038 2.418c.297.18.668.164.95-.04a.87.87 0 00.332-.894l-1.043-4.609 3.539-3.11c.261-.23.359-.59.254-.917zm0 0M213.969 33.996a.605.605 0 00-.524-.418l-3.304-.3-1.305-3.067a.616.616 0 00-.563-.371.615.615 0 00-.558.37l-1.305 3.067-3.305.301a.61.61 0 00-.343 1.067l2.496 2.195-.738 3.25a.612.612 0 00.906.66l2.847-1.707 2.852 1.707a.603.603 0 00.668-.031.612.612 0 00.234-.63l-.734-3.25 2.496-2.194a.613.613 0 00.18-.649zm0 0M187.941 44.992a.61.61 0 00-.523-.422l-3.305-.3-1.304-3.063a.608.608 0 00-1.121 0l-1.305 3.063-3.305.3a.609.609 0 00-.344 1.066l2.496 2.196-.734 3.254a.606.606 0 00.902.656l2.852-1.707 2.848 1.707a.603.603 0 00.902-.656l-.734-3.254 2.496-2.191a.617.617 0 00.18-.649zm0 0M169.988 23.004a.614.614 0 00-.523-.422l-3.305-.3-1.305-3.063a.61.61 0 00-1.12 0l-1.305 3.062-3.305.301a.61.61 0 00-.344 1.066l2.496 2.196-.738 3.254c-.05.238.04.484.238.629a.62.62 0 00.668.027l2.848-1.707 2.852 1.707a.61.61 0 00.667-.027.612.612 0 00.235-.63l-.734-3.253 2.496-2.196a.609.609 0 00.18-.644zm0 0"
        />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M123.406 146.09c6.906 10.285 8.903 23.957 5.34 36.558-3.562 12.602-12.621 23.926-24.234 30.286.386-5.891.293-15.582-3.371-26.493-3.649-10.867-9.278-18.289-13.043-22.496l35.308-17.855"
        />
        <Path fill="#EDEDED" fillRule="evenodd" d="M104.512 212.934" />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M104.512 212.934c.386-5.891.293-15.582-3.371-26.493a65.18 65.18 0 00-3.11-7.632 60.226 60.226 0 007.602-3.836 70.162 70.162 0 012.406 5.941c3.922 11.121 4.227 20.95 3.961 26.957a49.412 49.412 0 01-2.957 2.242c-.004.004-.008.004-.008.008-.004 0-.008.004-.012.008a48.475 48.475 0 01-4.511 2.805"
        />
        <Path
          fill="#E8E9FF"
          fillRule="evenodd"
          d="M120.895 190.133a.573.573 0 01-.262-.059c-.332-.172-.461-.683-.445-1.453.015-.77.167-1.797.39-3.023.23-1.223.5-2.649.793-4.227.281-1.57.567-3.289.793-5.101.465-3.625.633-6.946.82-9.438.098-1.242.211-2.277.395-3.023.183-.75.437-1.211.8-1.29a.361.361 0 01.114-.015c.332 0 .727.312 1.133.906.453.664.926 1.676 1.324 2.977.398 1.304.719 2.902.867 4.699a28.442 28.442 0 01-.137 5.863 27.867 27.867 0 01-1.343 5.715c-.602 1.703-1.313 3.168-2.032 4.328-.718 1.16-1.43 2.016-2.039 2.54-.46.398-.863.6-1.171.6"
        />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M82.746 105.324c-10.262-6.926-23.894-8.93-36.46-5.355-12.571 3.57-23.864 12.652-30.208 24.297 5.875-.387 15.543-.293 26.422 3.382 10.84 3.657 18.242 9.297 22.441 13.075l17.805-35.399"
        />
        <Path fill="#EDEDED" fillRule="evenodd" d="M16.078 124.266" />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M50.113 130.762a64.56 64.56 0 00-7.613-3.114c-8.371-2.828-16.023-3.535-21.785-3.535-1.727 0-3.281.063-4.637.153a47.972 47.972 0 012.914-4.684 49.576 49.576 0 012.137-2.824c.976-.043 2.058-.07 3.226-.07 6 0 14.38.75 23.66 4.042a68.279 68.279 0 015.926 2.415 60.371 60.371 0 00-3.828 7.617"
        />
        <Path
          fill="#E8E9FF"
          fillRule="evenodd"
          d="M40.207 108.547c-.7 0-1.168-.133-1.328-.445-.168-.329.02-.829.539-1.438.523-.61 1.379-1.324 2.535-2.043 1.156-.719 2.617-1.437 4.317-2.039a27.478 27.478 0 015.695-1.348 28.866 28.866 0 013.605-.226c.778 0 1.528.031 2.246.09 1.793.152 3.387.472 4.684.87 1.3.4 2.313.872 2.969 1.329.66.453.972.89.894 1.25-.082.363-.543.617-1.289.805-.746.183-1.777.293-3.015.39-2.485.192-5.797.36-9.41.824a96.61 96.61 0 00-5.09.797c-1.575.293-2.996.563-4.215.793-1.223.223-2.25.375-3.02.39h-.117"
        />
        <Path
          fill="#FF563D"
          fillRule="evenodd"
          d="M65.816 174.605c1.942 6.493.114 13.797-4.043 19.145-4.152 5.344-10.406 8.82-16.984 10.383-6.578 1.566-13.473 1.328-20.14.21-1.118-6.683-1.352-13.6.206-20.195 1.563-6.593 5.028-12.867 10.36-17.03 5.332-4.165 12.617-6 19.094-4.052l11.507 11.54"
        />
        <Path
          fill="#F2E35A"
          fillRule="evenodd"
          d="M64.117 172.902c1.328 4.618-.05 9.856-3.066 13.711-3.02 3.86-7.516 6.399-12.23 7.578-4.712 1.18-9.637 1.075-14.387.34-.735-4.761-.836-9.703.34-14.426 1.175-4.726 3.71-9.238 7.554-12.261 3.848-3.028 9.07-4.406 13.68-3.078l8.11 8.136"
        />
        <Path
          fill="#FF563D"
          fillRule="evenodd"
          d="M82.285 175.988l-9.562 3.95a4.613 4.613 0 01-5.028-1.008l-11.28-11.317 4.862 4.88-11.285-11.313a4.644 4.644 0 01-1.004-5.04l3.938-9.585 29.36 29.433"
        />
        <Path
          fill="#EDEDED"
          fillRule="evenodd"
          d="M71.637 180.238a4.68 4.68 0 001.086-.3l5.379-2.22-5.38 2.22a4.68 4.68 0 01-1.085.3"
        />
        <Path
          fill="#EA236C"
          fillRule="evenodd"
          d="M70.965 180.285a4.617 4.617 0 01-3.27-1.355l-17.703-17.75a4.645 4.645 0 01-1.004-5.04l.535-1.3 11.934 11.969-.629 1.257 1.254-.632 7.031 7.05a4.626 4.626 0 005.032 1.008l1.23-.508 2.727 2.735-5.38 2.219a4.68 4.68 0 01-1.085.3 4.814 4.814 0 01-.672.047"
        />
        <Path
          fill="#CCC"
          fillRule="evenodd"
          d="M80.93 180.555a5.632 5.632 0 004.652 1.617c8.676-1.043 16.926-4.836 24.14-9.832 7.993-5.531 47.508-47.113 56.321-63.735 8.812-16.617 13.258-36.304 8.437-54.492-18.14-4.836-37.777-.379-54.351 8.457-16.574 8.836-58.047 48.457-63.563 56.47-4.984 7.233-8.765 15.507-9.808 24.206a5.668 5.668 0 001.617 4.664l32.555 32.645"
        />
        <Path
          fill="#EA236C"
          fillRule="evenodd"
          d="M107.281 121.488c-8.418-8.445-8.418-22.136 0-30.578 8.422-8.445 22.078-8.445 30.5 0 8.422 8.442 8.422 22.133 0 30.578-8.422 8.442-22.078 8.442-30.5 0zm0 0"
        />
        <Path
          fill="#E8E9FF"
          fillRule="evenodd"
          d="M112.34 116.418c-5.63-5.645-5.63-14.793 0-20.438 5.629-5.644 14.754-5.644 20.383 0 5.629 5.645 5.629 14.793 0 20.438-5.63 5.64-14.754 5.64-20.383 0zm0 0"
        />
        <Path
          fill="#EA236C"
          fillRule="evenodd"
          d="M160.738 87.004c3.754 5.23 6.551 10.504 8.258 15.422l.024.004-.028-.004c-1.703-4.918-4.5-10.192-8.254-15.422m8.727 14.469c.035-.078.07-.157.101-.239-.03.082-.066.16-.101.239m-3.422 7.136a99.617 99.617 0 003.23-6.695l.13-.293c.07-.168.144-.336.214-.508.094-.215.184-.43.278-.648a2.59 2.59 0 01.078-.184c-.016.04-.036.078-.051.117 6.117-14.683 8.61-31.011 4.558-46.285-15.937-4.25-33.03-1.324-48.183 5.442a95.792 95.792 0 00-6.168 3.015c-.29.157-.582.317-.887.492 8.824 2.426 19.117 8.692 28.336 17.934 9.277 9.305 15.547 19.692 17.934 28.578.187-.328.363-.652.531-.965"
        />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M169.047 102.43c6.75-15.2 9.672-32.336 5.433-48.317-15.937-4.25-33.03-1.324-48.183 5.442 8.176 2.87 17.332 8.773 25.625 17.086 8.32 8.343 14.223 17.562 17.074 25.785a.223.223 0 00.05.004"
        />
        <Path
          fill="#EDEDED"
          fillRule="evenodd"
          d="M83.969 182.133l-.016-.004.016.004m79.25-68.86a60.834 60.834 0 002.527-4.113 61.052 61.052 0 01-2.527 4.113m2.824-4.664c.055-.105.113-.21.168-.32a105.246 105.246 0 00-.168.32m.766-1.476c.007-.012.011-.024.02-.035-.009.011-.013.023-.02.035"
        />
        <Path
          fill="#078CE4"
          fillRule="evenodd"
          d="M105.633 174.973a68.732 68.732 0 004.09-2.633c1.414-.977 3.808-3.074 6.84-5.965-3.032 2.89-5.426 4.988-6.836 5.965a70.116 70.116 0 01-4.094 2.633"
        />
        <Path fill="#0782D4" fillRule="evenodd" d="M98.031 178.809" />
        <Path
          fill="#BEBEBE"
          fillRule="evenodd"
          d="M84.914 182.215c-.32 0-.633-.028-.945-.082l-.016-.004h-.004a5.918 5.918 0 01-.96-.258s-.005-.004-.009-.004a5.67 5.67 0 01-2.05-1.312l-15.008-15.051 2.398-1.207c3.094-1.559 6.11-3.25 9.043-5.07l6.39 6.406a5.631 5.631 0 003.985 1.66c.223 0 .446-.016.668-.043 8.676-1.043 16.926-4.832 24.145-9.832 6.785-4.695 36.32-35.41 50.46-54.738 1.067 2.367 1.903 4.675 2.5 6.894.063-.113.13-.226.192-.34a60.834 60.834 0 01-2.484 4.04c-10.004 15.07-33.946 41-46.656 53.101-3.032 2.89-5.426 4.988-6.84 5.965a68.732 68.732 0 01-4.09 2.633 60.226 60.226 0 01-7.602 3.836 52.517 52.517 0 01-3.3 1.25c-2.977 1.011-6.036 1.738-9.149 2.113a5.478 5.478 0 01-.668.043m81.297-73.926a99.968 99.968 0 003.73-7.941l.032-.067c-.008.016-.016.031-.024.051 3.278-7.879 5.504-16.227 6.242-24.629-.949 10.84-4.382 21.586-9.34 31.344 0 .004 0 .008-.003.008l-.02.043c-.008.011-.012.023-.02.035l-.003.012c-.196.382-.395.765-.594 1.144m2.809-5.86l-.028-.003a50.633 50.633 0 00-.039-.114h.004c0 .008.004.02.008.028v.004a.056.056 0 01.008.023s0 .004.004.004c0 .008.003.016.007.024v.003c.004.012.008.02.012.028h.012l.012.004"
        />
        <Path
          fill="#EA236C"
          fillRule="evenodd"
          d="M165.512 109.574c-.598-2.219-1.434-4.527-2.5-6.894a106.254 106.254 0 003.804-5.516 50.633 50.633 0 012.176 5.262l.028.004-.012-.004a.133.133 0 00.039.004 99.968 99.968 0 01-2.836 5.86c-.055.108-.113.214-.168.32-.094.18-.195.363-.297.55a.726.726 0 00-.043.074c-.062.114-.129.227-.191.34m3.484-7.148c-.004-.008-.008-.016-.012-.028.004.012.008.02.012.028m-.012-.031c-.004-.008-.007-.016-.007-.024 0 .008.003.016.007.024m.957-2.047c.004-.004.004-.012.008-.016.008-.02.016-.035.024-.05l-.032.066"
        />
        <Path
          fill="#EDEDED"
          fillRule="evenodd"
          d="M46.719 143.809c.004-.188.02-.375.039-.563.488-4.074 1.574-8.05 3.11-11.875-1.532 3.824-2.622 7.8-3.11 11.875-.02.188-.035.375-.04.563"
        />
        <Path
          fill="#BEBEBE"
          fillRule="evenodd"
          d="M63.383 162.96l-15.008-15.05a5.661 5.661 0 01-1.617-4.664c.488-4.074 1.578-8.05 3.11-11.875.03-.082.062-.16.097-.242.277.687.695 1.32 1.234 1.86l18.446 18.495a117.328 117.328 0 00-5.06 9.07l-1.202 2.407"
        />
        <Path
          fill="#E8E9FF"
          fillRule="evenodd"
          d="M169.047 102.43c-.012 0-.027 0-.04-.004h-.01c-.005-.008-.009-.016-.013-.028v-.003c-.004-.008-.007-.016-.007-.024-.004 0-.004-.004-.004-.004a.056.056 0 00-.008-.023v-.004a.072.072 0 01-.008-.028h-.004a50.429 50.429 0 00-2.137-5.148c.801-1.25 1.489-2.414 2.051-3.477 3.02-5.695 5.528-11.753 7.324-17.984-.738 8.402-2.964 16.75-6.242 24.629-.004.004-.004.012-.008.016-.289.699-.586 1.39-.894 2.082"
        />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M60.828 168.066l7.492-3.77a115.672 115.672 0 0029.93-21.663l6.195-6.211a6.677 6.677 0 000-9.422l-1.328-1.336-1.332-1.332a6.63 6.63 0 00-9.394 0l-6.196 6.211a116.07 116.07 0 00-21.61 30.012l-3.757 7.511"
        />
        <Path fill="#E30D2F" fillRule="evenodd" d="M61.457 166.809l1.926-3.848-1.926 3.848" />
        <Path fill="#D30C2C" fillRule="evenodd" d="M60.828 168.066l.629-1.257-.629 1.257" />
        <Path fill="#B1B1B1" fillRule="evenodd" d="M63.383 162.96l1.195-2.39-1.195 2.39" />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M60.828 168.066l2.555-5.105 1.195-2.39 6.277-3.157a115.713 115.713 0 0029.93-21.664l5.453-5.469a6.662 6.662 0 01-1.793 6.14l-6.195 6.212a115.739 115.739 0 01-29.93 21.664l-7.492 3.77"
        />
        <Path
          fill="#E30D2F"
          fillRule="evenodd"
          d="M122.531 120.648a14.344 14.344 0 010 0m14.012-11.054c0-.004.004-.008.004-.012 0 .004-.004.008-.004.012"
        />
        <Path
          fill="#333895"
          fillRule="evenodd"
          d="M122.531 120.648c-3.687 0-7.375-1.41-10.191-4.23-4.25-4.262-5.29-10.52-3.121-15.762a14.403 14.403 0 004.21 9.68 14.339 14.339 0 0010.192 4.234c3.688 0 7.375-1.41 10.192-4.234a14.374 14.374 0 003.12-4.676 14.445 14.445 0 01-.382 3.903v.007c-.004.004-.004.008-.004.012s-.004.008-.004.012v.004a14.416 14.416 0 01-3.82 6.82 14.344 14.344 0 01-10.192 4.23"
        />
        <Path
          fill="#BEBEBE"
          fillRule="evenodd"
          d="M105.375 119.301l-.012-.012.012.012m-.02-.028c-.003-.003-.011-.011-.011-.015 0 .004.008.012.011.015m-.023-.027l-.012-.016.012.016m-.016-.023c-.035-.043-.066-.086-.097-.13.031.044.062.087.097.13m-.101-.13"
        />
        <Path
          fill="#EA236C"
          fillRule="evenodd"
          d="M122.531 127.82a21.51 21.51 0 01-11.047-3.047 21.687 21.687 0 01-5.902-5.207c-.008-.007-.016-.02-.023-.027l-.004-.004c-.004-.008-.012-.015-.02-.027h-.004c-.008-.012-.011-.02-.02-.028 0-.003-.003-.003-.003-.003-.02-.032-.043-.059-.067-.09l-.007-.008c-.004-.008-.008-.012-.016-.02-.004-.004-.008-.007-.008-.011l-.012-.016a.054.054 0 00-.011-.016.054.054 0 01-.012-.015l-.012-.012c-.004-.008-.004-.012-.008-.016-.003-.003-.011-.011-.011-.015l-.012-.012-.012-.016c-.004-.003-.004-.003-.004-.007-.035-.043-.066-.086-.097-.13h-.004a21.659 21.659 0 01-4.145-15.003 21.56 21.56 0 006.176 12.828 21.483 21.483 0 0015.25 6.332 21.48 21.48 0 0015.246-6.332 21.574 21.574 0 006.215-13.18c.719 6.32-1.34 12.899-6.176 17.75a21.483 21.483 0 01-15.25 6.332"
        />
        <Path
          fill="#E8E9FF"
          fillRule="evenodd"
          d="M106.973 80.922c-.235 0-.41-.059-.516-.18-.168-.183-.168-.504-.016-.949a4.84 4.84 0 01.34-.758c.074-.14.16-.289.254-.445.094-.156.203-.317.317-.485.23-.332.496-.695.796-1.078.301-.382.649-.785 1.024-1.21.371-.43.797-.86 1.246-1.32.445-.466.941-.927 1.465-1.415.52-.488 1.09-.969 1.68-1.477.296-.257.609-.5.921-.753.317-.254.641-.512.973-.766.336-.254.676-.508 1.023-.766.176-.129.352-.261.532-.39.18-.125.363-.25.543-.38.37-.253.742-.515 1.125-.773l1.172-.761c.394-.258.804-.5 1.218-.754.414-.25.832-.508 1.266-.746.43-.243.867-.489 1.305-.739.086-.043.171-.09.257-.132 2.622 1.05 5.325 2.402 8.051 4.027a154.694 154.694 0 00-3.894 1.707c-.43.2-.86.394-1.282.59-.421.203-.84.402-1.253.597-.415.192-.817.403-1.22.598-.402.2-.804.387-1.19.59-.391.2-.778.394-1.16.59-.38.195-.75.39-1.122.586-.183.093-.367.191-.55.285-.18.097-.36.191-.54.289l-1.05.562c-.688.375-1.356.743-1.993 1.09-.636.364-1.257.688-1.84 1.024-.585.332-1.152.636-1.68.937-.53.301-1.046.57-1.515.836-.472.262-.926.492-1.344.707-.414.219-.804.406-1.16.57-.18.082-.355.157-.515.223-.164.07-.317.133-.461.188-.293.109-.555.187-.785.234a2.173 2.173 0 01-.422.047"
        />
        <Path
          fill="#EA236C"
          fillRule="evenodd"
          d="M131.95 68.672c-2.727-1.625-5.43-2.977-8.052-4.027.364-.192.727-.387 1.098-.579a70.641 70.641 0 015.79-2.66c.003-.004.007-.004.01-.008 2.673 1.243 5.407 2.774 8.145 4.579a149.068 149.068 0 00-5.488 2.074l-1.504.62"
        />
        <Path
          fill="#E8E9FF"
          fillRule="evenodd"
          d="M138.941 65.977c-2.738-1.805-5.472-3.336-8.144-4.579a70.567 70.567 0 015.996-2.101c.492-.145.984-.285 1.469-.426.488-.129.972-.254 1.449-.379.476-.129.957-.234 1.43-.34.468-.109.937-.218 1.398-.304.465-.09.922-.18 1.371-.27.453-.082.903-.152 1.344-.223l.656-.105c.219-.035.434-.059.649-.09.43-.055.851-.11 1.27-.16.417-.047.827-.086 1.23-.125.402-.035.796-.078 1.183-.098.778-.043 1.52-.097 2.235-.101.418-.008.828-.016 1.218-.016.278 0 .547.004.813.012.644.02 1.246.035 1.812.086.567.047 1.09.094 1.575.16.484.066.925.145 1.32.223.2.043.387.082.562.129.176.046.34.093.493.144.304.098.562.203.77.309.417.215.64.449.648.695.007.246-.2.496-.59.75-.196.125-.438.25-.723.375-.145.062-.3.125-.465.187-.164.063-.34.13-.523.196-.371.133-.782.265-1.235.398-.445.133-.933.281-1.457.422-.52.137-1.078.3-1.664.453-.59.149-1.21.324-1.86.492-.651.164-1.327.364-2.034.543l-2.192.598c-.375.11-.757.219-1.148.328-.192.055-.39.113-.586.168-.195.059-.395.117-.594.176-.398.117-.804.238-1.21.36-.41.124-.825.253-1.243.382-.422.121-.84.266-1.266.402-.43.137-.863.266-1.293.418-.433.145-.875.29-1.316.438l-1.332.469c-.008 0-.012.004-.016.004"
        />
        <Path
          fill="#F85D76"
          fillRule="evenodd"
          d="M137.762 96.73c-.188 0-.473-.195-.856-.523-.46-.398-1.066-.992-1.832-1.68-1.527-1.37-3.75-3.062-6.57-4.164-1.41-.55-2.809-.91-4.11-1.125a24.563 24.563 0 00-3.535-.308c-1.027-.024-1.875 0-2.48-.024-.61-.023-.98-.097-1.04-.289-.058-.187.204-.472.766-.793.563-.32 1.426-.664 2.543-.91a14.01 14.01 0 013.07-.328c.313 0 .63.008.958.027 1.527.094 3.215.43 4.87 1.082a15.836 15.836 0 014.306 2.535c1.183.977 2.101 2.02 2.746 2.965.648.95 1.047 1.79 1.242 2.407.195.617.191 1.007.02 1.105a.2.2 0 01-.098.023"
        />
        <Path
          fill="#BABCDC"
          fillRule="evenodd"
          d="M131.434 101.898c-.532-.156-1.172-.433-1.895-.761-1.445-.664-3.293-1.469-5.371-1.926-2.078-.457-4.094-.504-5.68-.508-.793-.004-1.492-.02-2.039-.101-.547-.086-.953-.258-1.14-.582a.86.86 0 01-.086-.57 1.96 1.96 0 01.304-.727c.332-.528.95-1.114 1.84-1.649.223-.14.465-.258.719-.383.258-.125.531-.234.816-.343.285-.098.586-.207.903-.285.16-.04.32-.083.484-.118.168-.03.336-.066.504-.097a11.991 11.991 0 011.914-.168c.086 0 .176.004.262.004.761.015 1.55.105 2.347.277.797.176 1.551.43 2.25.734.7.301 1.332.676 1.907 1.063.14.101.28.199.418.3.132.102.261.208.386.313.258.203.485.426.703.637.215.219.418.433.598.652.18.223.352.43.492.649.586.859.903 1.652.985 2.273a1.89 1.89 0 01-.032.785.834.834 0 01-.312.48c-.16.118-.356.165-.582.165a2.52 2.52 0 01-.695-.114zm0 0"
        />
        <Path
          fill="#E8E9FF"
          fillRule="evenodd"
          d="M82.441 140.11c-.12 0-.214-.032-.273-.094-.695-.703 2.723-5.22 7.637-10.094.613-.61 1.25-1.219 1.922-1.781.335-.282.683-.555 1.039-.805a11.307 11.307 0 012.223-1.266l.284-.117c.094-.035.196-.07.293-.105.192-.07.391-.125.579-.18a7.714 7.714 0 012.187-.258c.672.02 1.281.14 1.79.317.507.18.917.41 1.214.648.07.059.14.113.2.172.062.058.116.117.163.172.047.058.09.11.125.16.035.055.063.105.086.156.094.195.102.34.035.442-.117.171-.402.191-.812.191h-.317c-.058 0-.117-.004-.175-.004-.559 0-1.27.05-2.086.27-.45.12-.934.296-1.422.535-.117.066-.242.12-.363.195-.06.035-.122.07-.18.106a1.931 1.931 0 00-.192.117c-.25.156-.503.328-.761.523a18.979 18.979 0 00-1.594 1.363c-.547.512-1.117 1.09-1.727 1.7-4.476 4.437-8.648 7.636-9.875 7.636"
        />
      </G>
    </Svg>
  );
});
