import PropTypes from 'prop-types';
import { ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WizardLayout } from '../community-create/WizardLayout';
import { setProfileName, movePrev } from '../redux/createProfileSlice';
import { Colors, Typography } from '../common-styles';
import { ThemedButton, BackButton, VisibleTag } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { Hello } from '../images';
import { NameTestIds } from './testLabels';

export const ProfileName = ({ navigation }) => {
  const { profileName } = useSelector((state) => state.createProfile);
  const [name, setName] = useState(profileName);
  const dispatch = useDispatch();
  const [showInfoModal, setShowInfoModal] = useState(false);

  const onSubmit = () => {
    dispatch(setProfileName(name));
    navigation.push(`ProfileUsername`);
  };
  const goBack = useCallback(() => {
    dispatch(movePrev());
    navigation.goBack();
  }, [dispatch, navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
  }, [navigation, goBack]);
  return (
    <WizardLayout total={6} selector="createProfile">
      <ScrollView
        style={localStyles.container}
        keyboardShouldPersistTaps="handled"
        testID={NameTestIds.screen}
        alwaysBounceVertical={false}
      >
        <View style={{ marginBottom: 20 }}>
          <View style={localStyles.blob} />
          <Text allowFontScaling={false} style={localStyles.headerText}>
            What's your name?
          </Text>
        </View>
        <View>
          <Text style={localStyles.optionalText}>Optional</Text>
          <TextInput
            autoFocus
            blurOnSubmit
            autoCapitalize="words"
            value={name}
            onChangeText={setName}
            returnKeyType="done"
            onSubmitEditing={onSubmit}
            placeholder="Enter your name"
            placeholderTextColor={Colors.textPlaceholder}
            style={localStyles.textInput}
            clearButtonMode="always"
            testID={NameTestIds.name}
          />
        </View>
        <View style={{ marginTop: 15, marginBottom: 5 }}>
          <TouchableOpacity onPress={() => setShowInfoModal(!showInfoModal)}>
            <View>
              <Text style={localStyles.whyAddYourNameText}>Why add your name?</Text>
            </View>
          </TouchableOpacity>
        </View>
        <VisibleTag infoText="Your name is visible on your profile." />
        <ThemedButton
          rounded
          title="Continue"
          testID={NameTestIds.continue}
          onPress={onSubmit}
          buttonStyle={{ padding: 15 }}
          containerStyle={{ marginTop: 15 }}
          titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
        />
        <WhyNameModal isVisible={showInfoModal} toggleModal={() => setShowInfoModal(false)} />
      </ScrollView>
    </WizardLayout>
  );
};
ProfileName.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

const WhyNameModal = ({ isVisible, toggleModal }) => {
  return (
    <Modal isVisible={isVisible} onBackdropPress={toggleModal}>
      <View style={localStyles.modal}>
        <View style={{ bottom: 10, left: 40 }}>
          {/* <Image source={require(`../../assets/images/nametag.png`)} style={{ height: 183, width: 320 }} /> */}
          <Hello height={183} width={320} />
        </View>
        <View style={localStyles.modalInnerContainer}>
          <View style={localStyles.modalTextContainer}>
            <Text style={localStyles.modalHeaderText}>Why add your name?</Text>
            <Text style={localStyles.modalSubText}>
              Build trust with your community by adding your full name. The choice is up to you!
            </Text>
          </View>
          <ThemedButton
            rounded
            title="Sounds good"
            titleStyle={localStyles.modalButtonText}
            onPress={toggleModal}
            buttonStyle={localStyles.modalButtonStyle}
          />
        </View>
      </View>
    </Modal>
  );
};

WhyNameModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const localStyles = {
  container: {
    flex: 1,
    padding: 15,
    paddingTop: 45,
    paddingHorizontal: 16,
  },
  headerText: {
    ...Typography.text(`large`, `bold`, `black`),
  },
  optionalText: {
    ...Typography.text(`base`, `gray`, `bold`),
    marginBottom: 10,
  },
  textInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    borderRadius: 4,
  },
  whyAddYourNameText: {
    ...Typography.text(`base`, `bold`, `theme`),
  },
  modal: {
    backgroundColor: Colors.white,
    padding: 10,
    borderRadius: 20,
    overflow: `hidden`,
  },
  modalInnerContainer: {
    margin: 10,
    overflow: `hidden`,
  },
  modalTextContainer: {
    marginVertical: 20,
  },
  modalHeaderText: {
    ...Typography.text(`large`, `black`, `bold`),
  },
  modalSubText: {
    ...Typography.text(`base`, `black`),
    marginVertical: 15,
  },
  modalButtonText: {
    ...Typography.text(`plusone`, `bold`, `white`),
  },
  modalButtonStyle: {
    padding: 15,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(253,87,148,0.5)`,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
};
