import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreCareer = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path id="a" d="M0 -5.68434189e-14H101V61.67938929999994H0z" />
        <Path
          id="c"
          d="M0.00000205603379 0.0144488732L19.2853839 0.0144488732 19.2853839 17.2938017 0.00000205603379 17.2938017z"
        />
        <Path
          id="e"
          d="M0.00000381017715 0.0299109204L2.12819121 0.0299109204 2.12819121 0.890583136 0.00000381017715 0.890583136z"
        />
        <Path id="g" d="M0.0144700666 0.016686807L1.41060278 0.016686807 1.41060278 0.640620043 0.0144700666 0.640620043z" />
        <Path id="i" d="M0 0.0000191882146L7.02199391 0.0000191882146 7.02199391 5.26535999 0 5.26535999z" />
        <Path id="k" d="M0.0150877962 0.010838454L3.87356503 0.010838454 3.87356503 2.57242421 0.0150877962 2.57242421z" />
        <Path
          id="m"
          d="M0.000948172373 0.00159178918L1.85411343 0.00159178918 1.85411343 0.702977513 0.000948172373 0.702977513z"
        />
        <Path id="o" d="M0 0.0020262606L12.0826888 0.0020262606 12.0826888 11.1889665 0 11.1889665z" />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Rect fillOpacity={0.1} fill="#2C9A44" x={0} y={0} width={163} height={98} rx={7} />
          <Path
            d="M145.102 51.904c3.614 10.362-4.033 21.953-16.603 29.284-12.517 7.372-29.905 10.444-38.861 5.16-8.956-5.242-9.48-18.84-4.452-30.717 5.08-11.878 15.712-22.035 28.544-22.609 12.78-.532 27.706 8.56 31.372 18.882z"
            fill="#2C9A44"
            fillRule="nonzero"
            transform="rotate(-175 114 61)"
          />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#2C9A44">
            <TSpan x={12} y={22}>
              Career Advice
            </TSpan>
          </Text>
          <G transform="translate(54 36)">
            <Mask id="b" fill="#fff">
              <Use xlinkHref="#a" />
            </Mask>
            <G mask="url(#b)">
              <G fillRule="nonzero">
                <Path
                  d="M3.52 1.773C3.19 3.682.022 24.258.022 24.258s.072.52.587.596c.515.077.788-.397.788-.397l4.47-22.184-2.347-.5zM13.918 2.26c.348 1.906 4.456 22.313 4.456 22.313s.245.462.755.353c.51-.108.603-.648.603-.648L16.29 1.909l-2.373.352zM19.127 2.199c.348 1.904 4.455 22.311 4.455 22.311s.246.463.755.354c.51-.108.603-.648.603-.648L21.5 1.847l-2.373.352z"
                  fill="#DDB0C8"
                  transform="translate(0 7.71) translate(45 43.496)"
                />
                <Path
                  d="M21.895 2.645H2.886A1.31 1.31 0 011.583 1.33c0-.726.583-1.314 1.303-1.314h19.01c.72 0 1.303.588 1.303 1.314a1.31 1.31 0 01-1.304 1.315"
                  fill="#EECCDC"
                  transform="translate(0 7.71) translate(45 43.496)"
                />
              </G>
              <G fillRule="nonzero">
                <Path
                  d="M2.083.027c-.037.268.328 5.718.328 5.718l2.769.463L6.288.027H2.083z"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(3.176 28) rotate(-4 247.836 -260.558)"
                  fill="#C26E5E"
                />
                <Path
                  d="M.087.026s-.17 5.257.746 4.946c0 0 4.983.506 6.2.7.612.099 1.784.85 5.449 1.475 3.665.626 7.16 1.271 7.16 1.523 0 .506.798 4.2.988 8.716 0 0 1.142 1.402 4.565.156 0 0 .248-8.832-1.464-12.259C20.463.844 10.103.026 10.103.026H.087z"
                  fill="#56ACF6"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(3.176 28) translate(0 .002)"
                />
                <Path
                  d="M1.187.379c0 .27.327 7.186.327 7.186l2.667.25L5.656 0l-4.47.379z"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(3.176 28) translate(0 .002) translate(8.761 15.61)"
                  fill="#C26E5E"
                />
                <Path
                  d="M2.713 7.147c3.665.626 7.16 1.271 7.16 1.523 0 .506-.297 4.2-.106 8.716 0 0 1.14 1.402 4.564.156 0 0 1.343-8.832-.368-12.259C10.694.844.334.026.334.026-.523 4.356.27 6.73 2.714 7.147z"
                  fill="#56ACF6"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(3.176 28) translate(0 .002)"
                />
                <Path
                  d="M11.058 2.597c2.329.89 3.533 2.536 3.602 4.925l.001.072-.137.003c-.044-2.346-1.19-3.956-3.446-4.842l-.068-.027.048-.13z"
                  fill="#000"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(3.176 28)"
                />
              </G>
              <Path
                d="M8.158 6.166S8.057 8.441 6.76 9.244c-1.176.728 1.316 4.956 3.996 4.704 1.467-.139 1.81-4.246 1.81-4.246s-1.184-.234-.746-2.083L8.158 6.166z"
                fill="#C26E5E"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317)"
              />
              <Path
                d="M10.304 8.48c-1.738-.216-2.992-1.385-3.167-3.326-.505.083-.625-1.265-.484-1.697.14-.434.6-.18.6-.18a1.84 1.84 0 00.065-.216c.76-3.187 2.885-3.06 2.885-3.06 3.906.208 2.991 4.29 2.768 5.374-.223 1.083-.988 3.312-2.667 3.104z"
                fill="#C26E5E"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317)"
              />
              <Path
                d="M3.956 9.368l-.986 9.45s7.021 1.292 13.096-.727l.355-8.319-12.465-.404z"
                fill="#000"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <G transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11) translate(0 .014)">
                <Mask id="d" fill="#fff">
                  <Use xlinkHref="#c" />
                </Mask>
                <Path
                  d="M3.018 16.025S-.355 13.872.03 10.38c.386-3.493 1.824-8.26 4.294-9.24C6.796.16 6.874.014 6.874.014s.99 1.324 2.715 1.408c1.725.084 2.847-.897 2.847-.897s3.388.826 4.363 2.389c.976 1.563 3.467 8.542 2.071 10.756-1.396 2.215-4.097 2.86-4.097 2.86s-8.25 2.017-11.755-.505"
                  fill="#000"
                  fillRule="nonzero"
                  mask="url(#d)"
                />
              </G>
              <Path
                d="M7.864 9.614L7.839 7.37s-.075-.476.647-.504c.722-.028 2.663-.056 2.663-.056s.473-.112.498.589c.025.7.05 3.11.05 3.11l-2.987.87-1.12-.056.274-1.71z"
                fill="#204EB2"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <Path
                d="M7.606 11.31l1.102.056 2.975-.866c-.002-.176-.026-2.431-.05-3.1-.008-.244-.072-.415-.19-.507a.372.372 0 00-.29-.068h-.004c-.019 0-1.95.028-2.662.056-.263.01-.448.08-.55.209a.392.392 0 00-.085.28l.026 2.245v.001l-.272 1.694zm1.106.084h-.003l-1.135-.057.276-1.724-.025-2.241a.412.412 0 01.09-.3c.108-.135.3-.209.57-.219.706-.027 2.6-.055 2.663-.056a.394.394 0 01.312.074c.124.097.192.275.2.528.025.693.05 3.087.05 3.112v.01l-.01.003-2.988.87z"
                fill="#000"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <Path
                d="M7.838 7.455l-.002-.386s0-.308.411-.336c.41-.028 2.847-.084 2.847-.084s.329-.028.466.168.093.673.093.673-.015-.668-.56-.657l-2.669.057s-.557-.058-.586.565"
                fill="#56ACF6"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <Path
                d="M11.106 6.85c.372 0 .498.368.54.6 0-.182-.013-.453-.097-.593-.131-.219-.45-.19-.453-.19-.026.001-2.442.066-2.848.099-.163.013-.284.082-.349.2a.407.407 0 00-.049.175l.001.239a.64.64 0 01.158-.31.53.53 0 01.417-.154l2.666-.066h.014m.561.783h-.027c0-.003-.005-.195-.075-.383-.093-.25-.252-.373-.472-.367l-2.668.066c-.004 0-.232-.026-.398.145-.108.11-.166.278-.175.498h-.028l-.001-.45c0-.004.005-.376.423-.409.406-.032 2.824-.097 2.848-.098.013-.001.337-.03.477.203.138.23.098.772.096.795"
                fill="#000"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <Path
                d="M7.87 8.716s-.395.26-.522.419c-.127.159-.014.188-.296.49-.282.304-.79 1.07.098 2.239.889 1.17 1.185 1.068 1.552.866.366-.202.409-.347 1.1-.419.69-.072 1.354-.765 1.34-.938l-.015-.173s.635-.246.593-.52c-.043-.275-.226-.246-.226-.246s.127-.13.028-.36c-.098-.232-.564-.188-1.1-.116-.536.072-1.918.36-2.002.303-.085-.058-.057-.144.268-.188.324-.043 1.636-.057 1.636-.433 0-.375-.395-.447-1.157-.433-.761.015-.903-.029-1.086.043l-.183.073-.028-.607z"
                fill="#C26E5E"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <Path
                d="M11.505 10.433s.04-.03-.261.088c-.11.043-.265.087-.483.13-.818.159-2.102.447-2.497.505-.394.058-.07.145.833-.043.902-.188 1.977-.44 2.025-.456.082-.028.328-.112.383-.224M10.831 11.286s-.667.24-.996.324c-.349.09-.612.21-.682.095-.07-.116-.155-.145-.155-.145l-.113.13s.02.144-.042.16c-.11.026-.325.071-.325.071s.003.081.66-.059c.163-.035.465-.084.657-.14.176-.052.813-.22.898-.291.084-.073.098-.145.098-.145"
                fill="#995B5D"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <Path
                d="M8.758 11.214s-.01.581.15.572c.16-.009.217-.009.245-.052.028-.044-.042-.188-.042-.347 0-.159 0-.23-.127-.216-.127.014-.212.014-.226.043"
                fill="#F9C818"
                fillRule="nonzero"
                transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(0 1.317) translate(0 9.11)"
              />
              <G>
                <Path
                  d="M1.334 1.188a.166.166 0 01-.164.168.166.166 0 01-.165-.168c0-.093.074-.168.165-.168.09 0 .164.075.164.168M3.51 1.566a.166.166 0 01-.164.168.166.166 0 01-.164-.168c0-.093.074-.168.164-.168.091 0 .165.075.165.168"
                  fill="#000"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344)"
                />
                <Path
                  d="M1.061 3.572s-.006.447.606.527c.613.08.887 0 .874-.287 0 0-.098-.313-.241-.327-.144-.013-.294.067-.294.067s-.143-.194-.293-.173c-.15.02-.45.2-.652.193"
                  fill="#CE4129"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344)"
                />
                <G transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344) translate(0 .005)">
                  <Mask id="f" fill="#fff">
                    <Use xlinkHref="#e" />
                  </Mask>
                  <Path
                    d="M2.06.89S1.267.6 1.061.518C.856.435.812.377.568.41.324.441.046.511.002.447-.04.384.506.066.688.034.87.002 1.947.151 2.084.504c.112.287-.025.387-.025.387"
                    fill="#000"
                    fillRule="nonzero"
                    mask="url(#f)"
                  />
                </G>
                <Path
                  d="M2.139 3.814c-.447-.06-.981-.142-1.149-.228C.868 3.524.823 3.54.822 3.54c0 0 .04-.145.217-.054.173.088.881.185 1.114.217.408.055.444.034.49.007l.055.097a.316.316 0 01-.184.043c-.079 0-.193-.011-.375-.036z"
                  fill="#000"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344)"
                />
                <G transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344) translate(2.984 .51)">
                  <Mask id="h" fill="#fff">
                    <Use xlinkHref="#g" />
                  </Mask>
                  <Path
                    d="M.016.376S.033.15.242.048c.21-.1.942.064.997.119.055.055.154.187.172.474 0 0-.08-.219-.224-.255C1.043.349.593.513.307.509.024.504.01.459.017.376"
                    fill="#000"
                    fillRule="nonzero"
                    mask="url(#h)"
                  />
                </G>
                <Path
                  d="M1.792 5.555c-.38 0-.771-.114-1.052-.218a4.663 4.663 0 01-.625-.285l.04-.073c.01.006 1.026.582 1.838.48l.01.083a1.69 1.69 0 01-.21.013"
                  fill="#000"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344)"
                />
                <G fillRule="nonzero">
                  <Path
                    d="M2.709.014l.104.035-.01.033a6.693 6.693 0 00-.11.408c-.03.122-.054.24-.074.35l-.005.034c-.048.283-.055.5-.012.616l.002.006c.243.616-.087.928-.78.71l-.02-.008.033-.106c.624.204.874-.026.666-.554-.058-.145-.049-.392.009-.717a5.978 5.978 0 01.15-.643L2.67.144c.005-.02.01-.04.016-.057L2.7.042 2.71.014z"
                    fill="#000"
                    transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344) translate(.11 .757)"
                  />
                  <Path
                    d="M.005 4.303l.054-.097.02.01.017.01.093.048.014.008c.116.057.244.115.38.169.314.124.623.208.911.235.131.013.255.013.37 0l.024-.002.013.111a1.9 1.9 0 01-.417.003 3.511 3.511 0 01-.94-.242 4.93 4.93 0 01-.388-.173L.11 4.36a3.734 3.734 0 01-.054-.028l-.03-.017-.022-.012z"
                    fill="#C86A5D"
                    transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344) translate(.11 .757)"
                  />
                </G>
                <G fill="#000" fillRule="nonzero">
                  <Path
                    d="M.433 2.275c-.11 0-.249-.022-.42-.079l.032-.106c.321.105.559.096.668-.025.093-.102.093-.29-.002-.529-.063-.162-.048-.469.047-.912a7.73 7.73 0 01.16-.612l.104.035C.926.353.702 1.21.813 1.494c.142.36.068.551-.02.648-.062.069-.171.133-.36.133"
                    transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.652 4.344) translate(1.89 .757)"
                  />
                </G>
              </G>
              <G transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(7.009)">
                <Mask id="j" fill="#fff">
                  <Use xlinkHref="#i" />
                </Mask>
                <Path
                  d="M.248 4.7s.086.052.055.565c0 0 .228-.233.289-.513.06-.28.471-1.09.82-1.307.35-.218.897-.202 1.216-.7l.32-.498s2.036.14 2.917.81c0 0 .41.186.32 2.006 0 0 .167-.98.197-1.478s.243.296.152.731c0 0 .52-.454.486-1.431-.046-1.328-.676-1.712-1.094-2.023C5.434.497 3.983-.31 3.071.126 2.16.56 2.203.66 1.777.83c-.425.171-.957.094-1.413.436-.456.342-.354 3.25-.354 3.25s.18.074.238.183"
                  fill="#221108"
                  fillRule="nonzero"
                  mask="url(#j)"
                />
              </G>
              <G fillRule="nonzero">
                <G fill="#E93737">
                  <Path
                    d="M2.443.013c-.577 0-1.046.48-1.046 1.071s.469 1.07 1.046 1.07c.578 0 1.046-.479 1.046-1.07 0-.591-.468-1.07-1.046-1.07zm0 .253c.442 0 .8.366.8.818a.809.809 0 01-.8.818.809.809 0 01-.8-.818c0-.452.359-.818.8-.818zM5.014.269c-.577 0-1.045.48-1.045 1.07 0 .592.468 1.07 1.045 1.07.578 0 1.046-.478 1.046-1.07C6.06.75 5.592.27 5.014.27zm0 .252c.442 0 .8.366.8.818a.809.809 0 01-.8.819.809.809 0 01-.799-.819c0-.452.358-.818.8-.818z"
                    transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(6.817 4.457) translate(.438)"
                  />
                  <Path
                    d="M.01.317A.122.122 0 01.164.245L.17.247l1.369.56c.063.026.094.1.069.164a.122.122 0 01-.155.073l-.006-.002L.078.48A.127.127 0 01.01.317zM3.254 1.137a.124.124 0 01.13-.112h.006l.718.082c.067.008.116.07.109.14a.124.124 0 01-.13.112H4.08l-.717-.083a.125.125 0 01-.11-.139z"
                    transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(6.817 4.457) translate(.438)"
                  />
                </G>
                <Path
                  d="M.774.4a.38.38 0 01-.378.38A.38.38 0 01.018.4c0-.211.17-.382.378-.382A.38.38 0 01.774.4"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(6.817 4.457) translate(0 1.654)"
                  fill="#FFF"
                />
              </G>
              <G fill="#000" fillRule="nonzero">
                <Path
                  d="M3.617 1.376s-.222-.352-.771-.747C2.543.412 1.089.061.68.704c-.408.642-.672.812-.578.806.924-.055 1.012-.505 1.429-.579.465-.081.711-.192.711-.192.165.168.33.281.687.479.12.065.668.39.687.158"
                  transform="translate(0 7.71) matrix(-1 0 0 1 70 9.496) translate(8.597 6.811) rotate(2 -.534 2.553) rotate(11 1.85 .923)"
                />
              </G>
              <G fillRule="nonzero">
                <Path
                  d="M3.52 1.773C3.19 3.682.022 24.258.022 24.258s.072.52.587.596c.515.077.788-.397.788-.397l4.47-22.184-2.347-.5zM13.918 2.26c.348 1.906 4.456 22.313 4.456 22.313s.245.462.755.353c.51-.108.603-.648.603-.648L16.29 1.909l-2.373.352zM19.127 2.199c.348 1.904 4.455 22.311 4.455 22.311s.246.463.755.354c.51-.108.603-.648.603-.648L21.5 1.847l-2.373.352z"
                  fill="#DDB0C8"
                  transform="translate(0 7.71) translate(70 43.496)"
                />
                <Path
                  d="M21.895 2.645H2.886A1.31 1.31 0 011.583 1.33c0-.726.583-1.314 1.303-1.314h19.01c.72 0 1.303.588 1.303 1.314a1.31 1.31 0 01-1.304 1.315"
                  fill="#EECCDC"
                  transform="translate(0 7.71) translate(70 43.496)"
                />
              </G>
              <G>
                <G fillRule="nonzero">
                  <Path
                    d="M2.086.027c-.037.267.328 5.698.328 5.698l2.772.462 1.11-6.16h-4.21z"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(8.358 28.018) rotate(-4 247.067 -260.943)"
                    fill="#ECAFA3"
                  />
                  <Path
                    d="M.087.026s-.17 5.24.747 4.93c0 0 4.989.504 6.207.698.614.097 1.786.845 5.456 1.469 3.67.624 7.168 1.267 7.168 1.518 0 .504.8 4.185.99 8.687 0 0 1.143 1.397 4.57.155 0 0 .249-8.802-1.465-12.217C20.488.84 10.115.026 10.115.026H.087z"
                    fill="#165C5D"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(8.358 28.018) translate(0 .002)"
                  />
                  <Path
                    d="M1.188.377c0 .27.327 7.162.327 7.162l2.671.25L5.663 0 1.188.377z"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(8.358 28.018) translate(0 .002) translate(8.772 15.558)"
                    fill="#ECAFA3"
                  />
                  <Path
                    d="M2.716 7.123c3.67.624 7.169 1.267 7.169 1.518 0 .504-.297 4.185-.107 8.687 0 0 1.143 1.397 4.57.155 0 0 1.345-8.802-.369-12.217C10.707.84.335.026.335.026-.524 4.341.27 6.707 2.716 7.123z"
                    fill="#165C5D"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(8.358 28.018) translate(0 .002)"
                  />
                </G>
                <Path
                  d="M11.047 2.654c2.33.887 3.517 2.525 3.563 4.916"
                  stroke="#000"
                  strokeWidth={0.5}
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(8.358 28.018)"
                />
                <Path
                  d="M13.345 6.146s-.1 2.266-1.398 3.067c-1.178.726 1.316 4.939 4 4.687 1.469-.137 1.813-4.23 1.813-4.23s-1.186-.234-.748-2.077l-3.667-1.447z"
                  fill="#ECAFA3"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425)"
                />
                <Path
                  d="M15.494 8.45c-1.74-.214-2.995-1.38-3.17-3.313-.507.082-.626-1.26-.485-1.692.141-.432.601-.18.601-.18.026-.068.047-.14.065-.214.76-3.176 2.888-3.05 2.888-3.05 3.911.207 2.995 4.276 2.772 5.356-.224 1.08-.99 3.3-2.671 3.094z"
                  fill="#ECAFA3"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425)"
                />
                <Path
                  d="M3.652 8.105c-1.97 2.43-2.65 4.386-2.043 5.868.913 2.222 3.173-.104 4.431-2.925.84-1.881 1.87-3.32 3.094-4.315L3.652 8.105z"
                  fill="#ECAFA3"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017) rotate(-10 5.264 10.796)"
                />
                <Path
                  d="M8.463 1.148c2.273-.716 2.973-.756 2.973-.756l2.399.527s1.314 1.76 1.427 3.33c0 0 .038-2.067.902-3.484 0 0 3.802-.035 5.235 1.49 2.264 2.41 2.222 6.636 2.222 6.636l-3.736.392 1.514-2.149s.096 8.755-.13 11.014c-2.851 3.206-13.103.724-13.103.724s.222-7.244.26-9.226c.037-1.982-.682-1.714-1.673.742-.81 2.009-3.387-2.155-3.387-2.155s1.581-5.977 5.097-7.085z"
                  fill="#1BC0D7"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017)"
                />
                <Path
                  d="M20.064 12.06s1.844-.675 3.72-.277c0 0-.024-.624-.005-1.447.016-.677.38-1.724-.933-1.612-1.527.132-2.182.145-2.846.451 0 0-.158 2.24.064 2.885"
                  fill="#FCEBEC"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017)"
                />
                <Path
                  d="M2.517 9.548l.805-2.58s2.977-.634 4.157 1.989l-.884 2.335S4.94 8.808 2.517 9.548"
                  fill="#237978"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017)"
                />
                <G transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017) translate(10.03)">
                  <Mask id="l" fill="#fff">
                    <Use xlinkHref="#k" />
                  </Mask>
                  <Path
                    d="M1.445.014S-.075.537.019.68c.094.143 2.79 1.97 2.992 1.89.202-.081.96-1.545.852-1.692C3.755.73 1.574-.042 1.445.014"
                    fill="#237978"
                    fillRule="nonzero"
                    mask="url(#l)"
                  />
                </G>
                <Path
                  d="M18.599.65s1.307.947 1.177 1.056c-.13.11-3.227 1.071-3.398.935-.17-.137-.484-1.761-.34-1.87.146-.11 2.452-.211 2.56-.12"
                  fill="#237978"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017)"
                />
                <Path
                  d="M4.136 12.41c1.206.593.832.832 1.421 1.201.59.37 1.496 1.326 1.804 1.111.308-.215.219-.408-.105-.678-.325-.27-1.329-1.156-1.22-1.288.108-.132 1.81 1.781 2.303 1.45.493-.331-.58-1.196-1.1-1.56-.52-.364-.812-.687-.647-.74.165-.052 2.135 2.052 2.51 1.738.376-.314-.33-1.046-.852-1.417-.523-.37-1.327-.965-1.327-.965s1.034.436 1.834 1.207c.8.772.991.725 1.09.549.097-.175-.73-1.15-.73-1.15s.019-1.124-1.04-1.505c-1.06-.38-1.865-.961-2.976-.88-1.112.08-1.449.741-1.449.741s-.723 1.593.484 2.186z"
                  fill="#ECAFA3"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017) rotate(30 6.646 12.114)"
                />
                <Path
                  d="M18.938 5.863h-2.49a.185.185 0 01-.183-.187v-.22c0-.103.082-.187.184-.187h2.49c.1 0 .183.084.183.187v.22a.185.185 0 01-.184.187"
                  fill="#237978"
                  fillRule="nonzero"
                  transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(0 .017)"
                />
                <G fillRule="nonzero">
                  <Path
                    d="M5.079 10.403L.071 8.99s-.312-.335.605-.423c.917-.089 10.496-.35 10.496-.35l-6.093 2.187z"
                    fill="#204EB2"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(11.647 4.888)"
                  />
                  <Path
                    d="M19.279 10.388H5.23a.476.476 0 01-.471-.48L6.035.481c0-.266.21-.48.471-.48h14.048c.26 0 .471.214.471.48L19.75 9.908c0 .265-.21.48-.471.48"
                    fill="#3267C8"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) translate(11.647 4.888)"
                  />
                </G>
                <G fillRule="nonzero">
                  <Path
                    d="M.954.02S.826.147.699.306C.572.464.685.493.403.795.12 1.097-.388 1.86.5 3.025c.89 1.166 1.186 1.065 1.554.863.367-.201.41-.345 1.1-.417.693-.072 1.356-.762 1.342-.935l-.014-.173s.636-.244.593-.518c-.042-.273-.226-.244-.226-.244s.127-.13.029-.36c-.1-.23-.565-.187-1.102-.115-.536.072-1.92.36-2.005.302-.085-.058-.056-.144.268-.187.325-.043 1.638-.058 1.638-.432S3.283.363 2.521.378c-.763.014-.904-.03-1.088.043l-.339.04-.14-.44z"
                    fill="#ECAFA3"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) scale(-1 1) rotate(-72 -2.865 27.311)"
                  />
                  <Path
                    d="M4.861 1.599s.041-.03-.261.088c-.11.042-.265.087-.484.13-.819.158-2.104.445-2.5.503-.395.058-.07.144.834-.043a63.15 63.15 0 002.028-.455c.082-.028.329-.111.383-.223M3.19 2.772c-.35.09-.933.224-.994.239-.11.027-.325.072-.325.072s.003.08.66-.06c.164-.034.466-.083.658-.14.177-.05.814-.218.899-.29a.283.283 0 00.099-.143s-.669.238-.998.322z"
                    fill="#995B5D"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(0 1.425) translate(0 8.939) scale(-1 1) rotate(-72 -2.865 27.311)"
                  />
                </G>
                <G>
                  <G fillRule="nonzero">
                    <Path
                      d="M2.712.014l.104.035-.01.033a6.647 6.647 0 00-.11.406c-.029.121-.054.239-.074.35l-.005.033c-.048.282-.055.498-.012.613l.003.007c.243.614-.088.925-.781.707l-.021-.007.033-.106c.625.203.875-.026.667-.552-.058-.145-.049-.39.009-.715a5.934 5.934 0 01.15-.64l.009-.035A7.26 7.26 0 012.69.087l.014-.045.008-.028z"
                      fill="#000"
                      transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(13.95 4.581) translate(0 .615)"
                    />
                    <Path
                      d="M.005 4.289L.06 4.19l.02.011.017.01.093.048.015.007c.115.058.243.115.38.169.314.123.624.207.912.235.131.012.255.012.37 0l.024-.003.013.11a1.91 1.91 0 01-.417.004c-.3-.028-.62-.115-.942-.242a4.948 4.948 0 01-.388-.172L.11 4.345a3.744 3.744 0 01-.054-.028l-.03-.016-.022-.012z"
                      fill="#C86A5D"
                      transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(13.95 4.581) translate(0 .615)"
                    />
                  </G>
                  <Path
                    d="M1.947 3.687c-.517 0-.907-.241-.925-.253a.085.085 0 01-.027-.115.081.081 0 01.113-.027c.008.004.466.285 1.018.217a.083.083 0 01.092.073.083.083 0 01-.072.093 1.613 1.613 0 01-.199.012M2.16 2.882c-.11 0-.248-.022-.42-.078l.032-.107c.322.105.56.096.669-.024.093-.102.093-.29-.002-.528-.064-.16-.048-.467.047-.909.07-.324.159-.606.16-.61l.104.035c-.096.305-.321 1.16-.21 1.443.143.359.069.549-.019.645-.062.069-.171.133-.36.133"
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(13.95 4.581) translate(.055)"
                  />
                  <G transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(13.95 4.581) translate(.055) translate(0 .027)">
                    <Mask id="n" fill="#fff">
                      <Use xlinkHref="#m" />
                    </Mask>
                    <Path
                      d="M1.703.703a.118.118 0 01-.05-.011c-.16-.074-.621-.27-.836-.29C.616.381.308.512.207.565.129.606.042.555.01.453-.019.35.02.235.097.195c.017-.009.43-.22.743-.19.297.029.888.3.913.311.079.036.12.15.093.254-.022.082-.08.133-.143.133"
                      fill="#000"
                      fillRule="nonzero"
                      mask="url(#n)"
                    />
                  </G>
                  <Path
                    d="M4.24 1.094c-.03 0-.06-.01-.087-.029-.084-.063-.343-.232-.53-.264-.202-.034-.664.004-.825.022A.148.148 0 012.636.69a.15.15 0 01.13-.165c.026-.003.623-.068.905-.02.296.05.642.307.656.318.066.05.08.143.032.21a.146.146 0 01-.12.061"
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(13.95 4.581) translate(.055)"
                  />
                  <G fill="#000" fillRule="nonzero">
                    <Path
                      d="M.343.24c0 .118-.072.214-.16.214C.092.454.02.358.02.24.02.123.093.028.182.028c.09 0 .161.095.161.213M2.406.483c0 .117-.072.212-.162.212-.089 0-.16-.095-.16-.212 0-.118.071-.213.16-.213.09 0 .162.095.162.213"
                      transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(13.95 4.581) translate(1.042 1.061)"
                    />
                  </G>
                </G>
                <G fill="#CD382F" fillRule="nonzero">
                  <Path
                    d="M3.257 1.806C1.393 4.742 2.523 6.626.955 9.893c-1.567 3.266-.734 4.604-.734 4.604.49.338 1.078.59 1.766.754.545.131 1.456.206 2.731.225 0 0-.804-2.12.292-5.277.98-2.822-1.546-4.637-.548-5.391C6.052 3.607 7.787 1.7 7.787 1.7s2.313.432 1.807 4.349c0 0-.463 3.168-2.002 3.8 0 0 .676 3.919 1.853 4.85 1.177.933 3.272.804 4.37.777 0 0 .268-2.902-.691-4.271-1.134-1.618-2.544-1.35-2.357-6.09C10.83 3.47 9.569.036 7.648.396c0 0-2.527-1.525-4.391 1.41z"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(8.495)"
                  />
                </G>
                <G fill="#E93737" fillRule="nonzero">
                  <Path
                    d="M2.446.013c-.578 0-1.047.478-1.047 1.067 0 .59.469 1.067 1.047 1.067S3.493 1.67 3.493 1.08c0-.589-.469-1.067-1.047-1.067zm0 .252c.442 0 .8.365.8.815 0 .45-.358.816-.8.816a.808.808 0 01-.8-.816c0-.45.358-.815.8-.815zM5.02.268c-.578 0-1.047.478-1.047 1.067 0 .59.47 1.067 1.048 1.067.578 0 1.047-.478 1.047-1.067 0-.59-.47-1.067-1.047-1.067zm0 .251c.443 0 .8.365.8.816 0 .45-.357.815-.8.815a.808.808 0 01-.8-.815c0-.45.359-.816.8-.816z"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(12.442 4.553)"
                  />
                  <Path
                    d="M.01.316A.123.123 0 01.165.244L.17.246l1.37.559a.127.127 0 01.07.163.123.123 0 01-.155.072l-.006-.002L.08.479a.127.127 0 01-.07-.163zM3.23 1.133a.124.124 0 01.13-.111h.007l.718.082c.068.007.117.07.11.138a.124.124 0 01-.131.112h-.006l-.719-.082a.125.125 0 01-.109-.139z"
                    transform="translate(0 7.71) matrix(-1 0 0 1 101 9.496) translate(12.442 4.553)"
                  />
                </G>
              </G>
              <G>
                <G fillRule="nonzero">
                  <Path
                    d="M12.557 34.179c-.112 0-.442-.372-.922-1.005l-.661 3.783c-4.248.64-6.025-.59-6.025-.59L6.197 25.13c-1.912-2.969-3.64-5.964-4.378-7.04C-.423 14.822.056.016.056.016c3.114.085 12.578 0 12.578 0s.733 15.401.733 16.763c0 1.362-.76 7.162-.76 7.162s3.799 6.354 3.546 6.96c-.254.605-2.938 3.278-3.596 3.278z"
                    transform="translate(0 7.71) translate(0 .496) translate(15.345 26.135) translate(0 .012)"
                    fill="#204EB2"
                  />
                  <Path
                    d="M7.102 5.6h.005a.084.084 0 01.078.089l-.006.087-.006.104-.006.101c-.007.13-.014.275-.02.433-.07 1.701-.054 3.55.111 5.29.191 2.008.563 3.61 1.147 4.65l.69 1.229.787 1.407 1.538 2.753 1.213 2.178a.083.083 0 01-.032.114.084.084 0 01-.112-.028l-1.155-2.073-1.551-2.778-.755-1.35-.769-1.37c-.598-1.066-.975-2.687-1.168-4.717-.166-1.747-.183-3.604-.112-5.312l.004-.093.021-.419.008-.13.006-.088a.084.084 0 01.084-.078zM8.068 27.577a.084.084 0 01.113.022l.002.005 3.479 5.541a.084.084 0 01-.14.093l-.003-.004-3.478-5.542a.083.083 0 01.027-.115z"
                    fill="#000"
                    transform="translate(0 7.71) translate(0 .496) translate(15.345 26.135) translate(0 .012)"
                  />
                </G>
                <Path
                  d="M20.263 6.123s-.103 2.26-1.426 3.056c-1.2.724 1.343 4.922 4.08 4.671 1.497-.137 1.848-4.215 1.848-4.215s-1.21-.233-.763-2.07l-3.739-1.442z"
                  fill="#7D4439"
                  fillRule="nonzero"
                  transform="translate(0 7.71) translate(0 .496) translate(0 1.837)"
                />
                <Path
                  d="M22.454 8.42c-1.773-.214-3.054-1.375-3.233-3.302-.516.082-.637-1.256-.494-1.686.144-.43.613-.178.613-.178a1.79 1.79 0 00.067-.214C20.182-.125 22.352 0 22.352 0c3.988.208 3.053 4.262 2.826 5.337-.228 1.076-1.009 3.29-2.724 3.083z"
                  fill="#7D4439"
                  fillRule="nonzero"
                  transform="translate(0 7.71) translate(0 .496) translate(0 1.837)"
                />
                <G transform="translate(0 7.71) translate(0 .496) translate(0 1.837) matrix(-1 0 0 1 43.826 9.24) translate(3.827 11.631)">
                  <Mask id="p" fill="#fff">
                    <Use xlinkHref="#o" />
                  </Mask>
                  <Path
                    d="M8.466.002s-.21 3.637-.925 4.17c-.715.534-5.608 1.916-5.608 1.916s-.22 2.868.57 2.567c.791-.302 8.77-1.422 8.852-1.907.081-.486.728-6.101.728-6.101L8.466.002z"
                    fill="#7D4439"
                    fillRule="nonzero"
                    mask="url(#p)"
                  />
                </G>
                <Path
                  d="M19.018 2.41c1.193 1.88 1.109 3.735 1.953 4.328.845.594 1.193 1.435 1.441 2.523s0 .504 0 .504l-4.204 2.877s-2.502-5.162-2.055-3.53c.447 1.633 1.502 8.499 1.076 8.893-.426.395-4.426 1.921-8.049 1.757-3.174-.145-6.164-.988-6.164-1.219 0-.494.719-6.067.719-6.067l-3.726-.84s.313-7.402 3.496-9.889c0 0 1.724-1.254 3.845-1.717 0 0 .62 1.343 2.564 1.387 1.945.044 2.576-.648 3.061-1.414 0 0 4.851.528 6.043 2.407z"
                  transform="translate(0 7.71) translate(0 .496) translate(0 1.837) matrix(-1 0 0 1 43.826 9.24) translate(12.017 .024)"
                  fill="#FC7750"
                  fillRule="nonzero"
                />
                <Path
                  d="M5.158 17.907a.443.443 0 01.08.083l.538.724a.454.454 0 01-.682.594.454.454 0 01-.68.563.452.452 0 01-.74.488.454.454 0 01-.772.47l-.538-.723a.454.454 0 01-.075-.155l-1.055-1.792c.043-.033.088-.07.134-.116.383-.379 1.397-1.255 1.769-1.726.924.337 1.616.51 2.077.521.69.017 1.754-.35 1.817.048.071.45-1.196 1-1.847 1.02l-.026.001z"
                  fill="#7D4439"
                  fillRule="nonzero"
                  transform="translate(0 7.71) translate(0 .496) translate(0 1.837) matrix(-1 0 0 1 43.826 9.24) scale(-1 1) rotate(-41 0 29.722)"
                />
                <G fillRule="nonzero">
                  <Path
                    d="M13.808 5.998s2.151 3.652.03 4.41c-2.12.759-4.001.7-8.545.76-1.785.022-6.174.135-6.007-.58.093-.397 3.296-1.017 4.793-1.589.441-.168 5.037-1.039 5.284-1.308.165-.179 1.647-.743 4.445-1.693z"
                    fill="#7D4439"
                    transform="translate(0 7.71) translate(0 .496) translate(0 1.837) translate(0 8.886) rotate(55 7.027 8.593)"
                  />
                  <Path
                    d="M5.24 6.343s.35-.578-.957-1.823c-.69-.657-1.211-2.174-1.594-1.913-.382.261.413 2.048.413 2.048s-.834-.752-1.328-1.58C1.281 2.247.822 1.574.822 1.574s-.391-.25-.418.144c-.026.394.084.519.084.519s-.3-.07-.306.231c-.006.302.05.365.05.365s-.227-.025-.232.24a.674.674 0 00.143.43s-.17.039-.026.357c.145.318.961 1.975 1.536 2.51.575.534 2.074 1.447 2.074 1.447s1.645-1.02 1.512-1.474"
                    fill="#7D4439"
                    transform="translate(0 7.71) translate(0 .496) translate(0 1.837) translate(0 8.886)"
                  />
                  <Path
                    d="M1.74 5.248c-.018-.013-.458-.334-.926-1.086-.42-.674-.579-.63-.58-.63l.004-.002-.046-.07c.007-.004.034-.019.083-.008.103.023.293.157.61.666.46.738.9 1.06.904 1.063l-.049.067zM1.97 4.882a34.747 34.747 0 00-.455-.408c-.236-.21-.527-.677-.74-1.018a6.104 6.104 0 00-.233-.358C.38 2.883.227 2.871.226 2.871l.003-.083c.008 0 .192.01.38.26.057.075.14.21.237.364.21.337.497.798.725 1 .333.294.455.408.456.409l-.057.06zM2.15 4.578a7.332 7.332 0 01-.776-.821c-.292-.38-.53-.893-.658-1.17l-.03-.062C.582 2.3.488 2.272.487 2.272l.019-.081c.013.003.133.035.256.298l.03.064c.127.273.362.78.649 1.153.308.4.76.806.765.81l-.057.062zM3.884 5.283c-.003-.002-.256-.248-.458-.317a.792.792 0 01-.364-.288l.07-.046a.718.718 0 00.322.255c.219.075.478.326.489.336l-.059.06z"
                    fill="#995B5D"
                    transform="translate(0 7.71) translate(0 .496) translate(0 1.837) translate(0 8.886)"
                  />
                </G>
                <G fillRule="nonzero">
                  <G fill="#000">
                    <Path
                      d="M1.121 3.106s.452.695 1.065.293l-1.065-.293zM3.57 1.317a.167.167 0 11-.334 0 .167.167 0 01.335 0"
                      transform="translate(0 7.71) translate(0 .496) translate(20.88 5.26)"
                    />
                    <Path
                      d="M0 .432S.427.1.752.024c.2-.046 1.31.155 1.274.447-.034.285-.144.246-.581.15C1.007.522.807.457.554.493.301.529.007.6 0 .432"
                      transform="translate(0 7.71) translate(0 .496) translate(20.88 5.26) translate(0 .014)"
                    />
                    <Path
                      d="M4.558.772S4.485.55 4.365.442C4.245.337 3.339.106 3.186.248c-.243.227-.068.309.42.36.393.04.548.053.73.132.248.108.216.075.222.033"
                      transform="translate(0 7.71) translate(0 .496) translate(20.88 5.26)"
                    />
                  </G>
                  <Path
                    d="M2.765.014l.107.034-.01.034a6.508 6.508 0 00-.113.404c-.03.121-.055.238-.075.349l-.006.033c-.048.28-.055.496-.012.611l.003.007c.248.611-.089.921-.796.704l-.022-.007.034-.106c.637.203.893-.026.68-.55-.058-.144-.05-.389.01-.712a5.795 5.795 0 01.152-.639l.01-.033.016-.057.014-.044.008-.028z"
                    fill="#000"
                    transform="translate(0 7.71) translate(0 .496) translate(20.88 5.26) translate(0 .334)"
                  />
                  <Path
                    d="M.005 4.273l.055-.097.02.011.018.01.095.048.015.007c.118.057.248.114.387.168.32.123.637.207.93.234.134.012.26.012.379 0l.023-.003.014.11a1.994 1.994 0 01-.426.004 3.666 3.666 0 01-.96-.241 5.106 5.106 0 01-.396-.172L.113 4.33a3.85 3.85 0 01-.054-.028l-.032-.017-.022-.012z"
                    fill="#C86A5D"
                    transform="translate(0 7.71) translate(0 .496) translate(20.88 5.26) translate(0 .334)"
                  />
                  <Path
                    d="M1.5 1.09a.084.084 0 01-.057-.023.522.522 0 00-.34-.116C.898.96.76 1.06.76 1.061a.084.084 0 01-.118-.017.083.083 0 01.016-.116.825.825 0 01.44-.144c.27-.01.451.153.459.16a.083.083 0 01-.057.145"
                    fill="#000"
                    transform="translate(0 7.71) translate(0 .496) translate(20.88 5.26)"
                  />
                </G>
                <G>
                  <Path
                    d="M5.184 2.825s-2.38.052-3.067 1.895c-.687 1.843-.073 2.46-.603 3.593C.984 9.447.003 9.544.003 9.544s1.334-.82 1.354-1.817c.02-.996-.047-2.512-.033-2.565.013-.053-.516-.195-.614.179 0 0-.312-2.223.887-3.507C3.302.008 7.631.794 7.478 4.108c-.11 2.373.092 3.211.092 3.211s-.169-.959-.251-1.87c-.082-.912-.286-2.096-1.783-2.597 0 0-.61-.554-.792-.596-.182-.043.506.339.44.569"
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(0 7.71) translate(0 .496) translate(18)"
                  />
                  <Path
                    d="M5.184 2.825s-2.38.052-3.067 1.895c-.687 1.843-.073 2.46-.603 3.593C.984 9.447.003 9.544.003 9.544s1.334-.82 1.354-1.817c.02-.996-.047-2.512-.033-2.565.013-.053-.516-.195-.614.179 0 0-.312-2.223.887-3.507C3.302.008 7.631.794 7.478 4.108c-.11 2.373.092 3.211.092 3.211s-.169-.959-.251-1.87c-.082-.912-.286-2.096-1.783-2.597 0 0-.61-.554-.792-.596-.182-.043.506.339.44.569z"
                    stroke="#000"
                    strokeWidth={0.5}
                    transform="translate(0 7.71) translate(0 .496) translate(18)"
                  />
                  <Path
                    d="M2.691 2.428s-1.048.626-1.846-.376C.048 1.049 1.265.088 2.314.088c1.049 0 1.426.627 1.342 1.295-.083.669-.503.878-.965 1.045"
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(0 7.71) translate(0 .496) translate(18)"
                  />
                  <Path
                    d="M2.691 2.428s-1.048.626-1.846-.376C.048 1.049 1.265.088 2.314.088c1.049 0 1.426.627 1.342 1.295-.083.669-.503.878-.965 1.045z"
                    stroke="#000"
                    strokeWidth={0.5}
                    transform="translate(0 7.71) translate(0 .496) translate(18)"
                  />
                  <Path
                    d="M6.98.156s1.18.192 1.244 1.402c.063 1.21-1.438 1.155-2.265.512-.827-.642-.771-1.324-.328-1.754.443-.429.891-.322 1.35-.16"
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(0 7.71) translate(0 .496) translate(18)"
                  />
                  <Path
                    d="M6.98.156s1.18.192 1.244 1.402c.063 1.21-1.438 1.155-2.265.512-.827-.642-.771-1.324-.328-1.754.443-.429.891-.322 1.35-.16z"
                    stroke="#000"
                    strokeWidth={0.5}
                    transform="translate(0 7.71) translate(0 .496) translate(18)"
                  />
                </G>
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};
