import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { FlatList, Linking, Text, TouchableOpacity, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import ParsedText from 'react-native-parsed-text';
import { useMutation } from 'react-apollo';
import { MODAL_SWITCH_TIME } from '../../constants';
import { Colors, Typography } from '../common-styles';
import { BottomSheetModal, Divider, ListItem, MeshIcon, ThemedButton } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { refetchQueriesFor, UPDATE_SETTING_MUTATION } from '../graphql';
import { setGuidelines } from '../redux/guidelinesSlice';

export const EditCodeOfConduct = React.memo(({ navigation, route }) => {
  const { setting, group } = route?.params || {};
  console.log(`setting`, setting?.group?.id, group?.id);
  const [modal, setModal] = useState(); // delete | 3dots
  const { guidelines, changes } = useSelector((state) => state.guidelines);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      const parsedGuidelines = JSON.parse(setting?.value) || [];
      console.log(`setGuidelines`, parsedGuidelines);
      dispatch(setGuidelines(parsedGuidelines));
    } catch (error) {
      console.warn(`[EditCodeOfConductError]`, error, setting?.value);
    }
  }, [dispatch, setting?.value]);

  useEffect(() => {
    console.log(`saveChanges`, changes);
    if (guidelines && changes) saveChanges(guidelines);
  }, [guidelines, changes, saveChanges]);

  useEffect(() => {
    const disabled = guidelines.length === 0;
    navigation.setOptions({
      headerRight: () => (
        <ThemedButton
          clear
          testID="SAVE_COC"
          disabled={disabled}
          titleStyle={disabled ? { color: Colors.gray } : { color: Colors.brandPurple }}
          containerStyle={{ right: 10 }}
          leftIcon={<MeshIcon name="dots-three-vertical" color={Colors.iconColor} size={24} />}
          onPress={() => setModal(`3dots`)}
        />
      ),
    });
  }, [guidelines.length, navigation]);

  const [saveSettingMutation, data, error] = useMutation(UPDATE_SETTING_MUTATION);

  useEffect(() => {
    if (error) console.warn(`[UpdateSettingsError]`, error);
  }, [data, error]);

  const addGuideline = () => {
    navigation.navigate(`EditCommunityGuideline`);
  };

  const editGuideline = useCallback(
    (guideline) => {
      navigation.navigate(`EditCommunityGuideline`, { guideline });
    },
    [navigation],
  );

  const openMeshGroundRules = () => {
    Linking.openURL(`https://www.meshcommunities.us/ground-rules`);
  };

  const switchModal = (title) => {
    setModal();
    setTimeout(() => setModal(title), MODAL_SWITCH_TIME);
  };

  const deleteAllGuidelines = () => {
    try {
      dispatch(setGuidelines([]));
      setTimeout(() => saveChanges(), 500);
      setModal();
    } catch (error) {
      console.warn(`[EditCodeOfConductError]`, error);
    }
  };

  const saveChanges = useCallback(
    async (guidelines = []) => {
      const { group, setting } = route.params;
      const input = {
        id: setting.id,
        key: setting.key,
        group_id: setting.group_id || group?.id,
        value: JSON.stringify(guidelines),
      };
      await saveSettingMutation({
        variables: { input },
        refetchQueries: refetchQueriesFor(`Setting`, `Group`),
      });
    },
    [route, saveSettingMutation],
  );

  const renderGuideline = useCallback(
    ({ item: guideline }) => {
      return (
        <TouchableOpacity testID="GO_TO_EDIT" onPress={() => editGuideline(guideline)}>
          <View style={localStyles.customGuideline}>
            <View style={{ flex: 1 }}>
              <Text testID="GUIDELINE_TITLE">{guideline.title}</Text>
              <Text testID="GUIDELINE_BODY" style={{ color: Colors.textGray }}>
                {guideline?.body}
              </Text>
            </View>
            <MeshIcon name="create" color={Colors.iconColor} focused />
          </View>
        </TouchableOpacity>
      );
    },
    [editGuideline],
  );

  const renderFooter = () => (
    <View style={{ margin: 10 }}>
      <ThemedButton
        clear
        outline
        testID="ADD_GUIDELINE"
        title="Add custom guideline"
        titleStyle={localStyles.addGuidelineButtonText}
        buttonStyle={localStyles.addGuidelineButtonStyle}
        leftIcon={<MeshIcon name="circle-plus" color={Colors.brandPurple} size={20} />}
        onPress={addGuideline}
      />
      <View style={localStyles.greyInfoContainer}>
        <ParsedText
          style={localStyles.smallText}
          parse={[{ pattern: /Read more/, style: localStyles.link, onPress: openMeshGroundRules }]}
          // eslint-disable-next-line react-native/no-raw-text
        >
          Members of your node must adhere to Mesh's Ground Rules. You may also add additional guidelines for your node. Read more
        </ParsedText>
      </View>
    </View>
  );

  return (
    <View style={{ flex: 1, marginVertical: 10 }}>
      <FlatList
        data={guidelines}
        testID="GUIDELINES_LIST"
        keyExtractor={(item) => item.id}
        renderItem={renderGuideline}
        ItemSeparatorComponent={() => <Divider />}
        keyboardShouldPersistTaps="handled"
        ListEmptyComponent={() => <Text style={localStyles.noGuidelines}>You have no custom guidelines for your communnity</Text>}
        ListFooterComponent={renderFooter}
      />
      <BottomSheetModal //first modal in the delete guidelines flow - 3 dot menu modal
        showCancelBtn={modal !== `delete`}
        title={modal === `3dots` ? `Manage` : `Are you sure?`}
        visible={modal === `3dots` || modal === `delete`}
        onPressCancel={() => setModal()}
        confirmTitle="Delete"
        onPressConfirm={() => {
          if (modal === `3dots`) {
            switchModal(`delete`);
          } else if (modal === `delete`) {
            deleteAllGuidelines();
          }
        }}
      >
        {modal === `3dots` && (
          <View style={{ marginTop: 20, marginBottom: 32, marginHorizontal: 16 }}>
            <TouchableOpacity style={{ flexDirection: `row` }} onPress={() => switchModal(`delete`)}>
              <MeshIcon name="minus-circle" color={Colors.alertColor} size={24} style={{ marginRight: 14 }} />
              <Text style={{ color: Colors.alertColor, ...Typography.text(`bold`, `alert`, `plusone`), marginTop: 1 }}>
                Delete Ground Rules
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {modal === `delete` && (
          <View style={{ marginTop: 12, marginHorizontal: 16 }}>
            <Text style={{ textAlign: `center`, ...Typography.text(`plustwo`) }}>
              Are you sure you want to delete the Ground Rule?
            </Text>
          </View>
        )}
      </BottomSheetModal>
    </View>
  );
});

EditCodeOfConduct.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.navigation().isRequired,
};

export const GuidelineSettingItem = ({ setting, listItemProps, testID, group }) => {
  const navigation = useNavigation();
  const { title, titleStyle, subtitleStyle } = listItemProps;
  const editGuidelines = () => {
    navigation.push(`EditCodeOfConduct`, { setting, group });
  };
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle="Press to view and edit..."
      testID={testID}
      subtitleStyle={subtitleStyle}
      rightElement={<MeshIcon name="chevron-right" size={20} testID="GUIDELINES_SETTINGS" />}
      onPress={editGuidelines}
    />
  );
};
GuidelineSettingItem.propTypes = {
  setting: commonPropTypes.setting.isRequired,
  listItemProps: PropTypes.object.isRequired,
  group: commonPropTypes.group().isRequired,
  testID: PropTypes.string,
};

GuidelineSettingItem.defaultProps = {
  testID: `GUIDELINES_SETTING_OPTION`,
};

const localStyles = {
  baseText: {
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
  },
  smallText: {
    fontSize: Typography.smallFontSize,
    color: Colors.textBlack,
    fontWeight: `300`,
    lineHeight: 15,
  },
  textInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    borderRadius: 4,
  },
  addGuidelineButtonText: {
    fontSize: Typography.baseFontSize,
    fontWeight: `600`,
    color: Colors.brandPurple,
    marginLeft: 10,
  },
  addGuidelineButtonStyle: {
    justifyContent: `flex-start`,
    borderColor: Colors.lightGray,
  },
  greyInfoContainer: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: Colors.lightGray,
    margin: 10,
  },
  customGuideline: {
    flex: 1,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginHorizontal: 16,
    marginVertical: 16,
  },
  customGuidelineHeaderContainer: {
    width: `100%`,
    backgroundColor: Colors.lightGray,
    padding: 8,
  },
  customGuidelineHeaderText: {
    fontSize: Typography.baseFontSize,
    color: Colors.gray,
    fontWeight: `500`,
    paddingHorizontal: 10,
  },
  noGuidelines: {
    margin: 16,
    fontSize: Typography.baseFontSize,
    color: Colors.gray,
  },
  link: {
    color: Colors.brandPurple,
    fontWeight: `bold`,
  },
};
