/* eslint-disable */
import gql from 'graphql-tag';
import {
  ACTIVITY_DETAILS_FRAGMENT,
  CUSTOM_QUESTION_DETAILS_FRAGMENT,
  GROUP_CORE_DETAILS_FRAGMENT,
  GROUP_DETAILS_FRAGMENT,
  GROUP_INVITATION_DETAILS_FRAGMENT,
  GROUP_MEMBER_DETAILS_FRAGMENT,
  IDENTITY_DETAILS_FRAGMENT,
  MODERATION_ISSUE_DETAILS_FRAGMENT,
  PERSONA_RELATIONSHIP_FRAGMENT,
  REACTION_DETAILS_FRAGMENT,
  SETTING_DETAILS_FRAGMENT,
  USER_DETAILS_FRAGMENT,
  FOLLOW_REQUEST_FRAGMENT,
  TOPIC_GROUP_CORE_DETAILS_FRAGMENT,
  PROFILE_ACTIVITY_DETAILS_FRAGMENT,
  POLL_OPTION_FRAGMENT,
} from './Fragments.gql';

//#region auth

export const SIGN_TOKENS = gql`
  mutation sign($input: [SignTokensInput]) {
    SignTokens(input: $input) {
      accessToken
      refreshToken
      id
      name
      password
      handle
      description
      avatar_url
      splash_url
      color_theme
      generation_count
      invite_token
      invite_group_id
      feature_flags
      identity_verified
      pronouns
      dob
    }
  }
`;

export const LOGINV2 = gql`
  mutation login($input: RegisterLoginInput) {
    LoginV2(input: $input) {
      # almost matches type Identity
      id
      secure_store
      password
      accessToken
      refreshToken
      invite_group_id
      res_status # still used by code
      is_verified
      agreed_terms
    }
  }
`;

export const REGISTERV3 = gql`
  mutation registerV3($input: RegisterLoginInput) {
    registerV3(input: $input) {
      id
      password
      accessToken
      refreshToken
      invite_group_id
      expired_invite_token
    }
  }
`;

export const UPDATE_IDENTITY_PASSWORD_AND_TERMS = gql`
  mutation updateIdentityPasswordAndAgreedTerms($identity_id: ID!, $set: UpdateIdentityPasswordAndAgreedTermsInput) {
    updateIdentityPasswordAndAgreedTerms(identity_id: $identity_id, set: $set) {
      id
      password
    }
  }
`;

export const REGISTER_EMAIL = gql`
  mutation RegisterEmail($id: ID!, $contact: String) {
    RegisterEmail(id: $id, contact: $contact) {
      verified
    }
  }
`;
export const REQUEST_VERIFY_TOKEN = gql`
  mutation requestVerificationToken($id: ID!, $contact: String) {
    RequestVerificationToken(id: $id, contact: $contact) {
      verified
    }
  }
`;

export const VERIFY = gql`
  mutation verify($input: VerifyInput) {
    VerifyAccount(input: $input) {
      verified
      expired
      res_status # still used by code
    }
  }
`;

export const REQUEST_RECOVERY_TOKEN = gql`
  mutation requestRecovery($id: ID!, $contact: String!) {
    requestRecoveryToken2(id: $id, contact: $contact) {
      res_status # still used by code, migrate to Void
      contactToShowInTheClient
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($id: ID, $new_password: String, $recovery_token: String) {
    recoverAccount(id: $id, new_password: $new_password, recovery_token: $recovery_token) {
      res_status # still used by code, migrate to Void
    }
  }
`;

export const CREATE_PERSONA_V3 = gql`
  mutation createPersonaV3($input: CreatePersonaInputV2) {
    createPersonaV3(input: $input) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const UPDATE_REFERRAL = gql`
  mutation updateReferral($input: UpdateReferralInput!) {
    updateReferral(input: $input) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const SET_SECURE_STORE = gql`
  mutation setSecureStore($input: SetSecureStoreInput) {
    SetSecureStore(input: $input) {
      secure_store
    }
  }
`;

export const MAP_PERSONA_TO_IDENTITY = gql`
  mutation mapPersonaToIdentity($id: ID!, $persona_id: ID!) {
    mapPersonaToIdentity(id: $id, persona_id: $persona_id) {
      res_status # not used by code, migrate to Void
    }
  }
`;

export const UPDATE_AGREED_TERMS = gql`
  mutation updateAgreedTerms($identity_id: ID!, $agreed_terms: Boolean) {
    updateAgreedTerms(identity_id: $identity_id, agreed_terms: $agreed_terms) {
      ...IdentityDetails
    }
  }
  ${IDENTITY_DETAILS_FRAGMENT}
`;

export const UPDATE_AGREED_COC = gql`
  mutation updateAgreedCoC($identity_id: ID!) {
    updateAgreedCoC(identity_id: $identity_id) {
      ...IdentityDetails
    }
  }
  ${IDENTITY_DETAILS_FRAGMENT}
`;

export const SAVE_PUSH_NOTIFICATION_TOKEN = gql`
  mutation savePushNotificationToken($push_token: String!) {
    savePushNotificationToken(push_token: $push_token) {
      id
      user_id
      push_token
      details
      created_at
      updated_at
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($identity_id: ID!, $current_password: String!, $new_password: String!) {
    updatePassword(identity_id: $identity_id, current_password: $current_password, new_password: $new_password) {
      ...IdentityDetails
    }
  }
  ${IDENTITY_DETAILS_FRAGMENT}
`;
//#endregion

//#region profile

export const UPDATE_PERSONA = gql`
  mutation updatePersona($input: UpdatePersonaInput!) {
    updatePersona(input: $input) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const DELETE_ACCOUNT = gql`
  mutation deletePersonasAndIdentity($identity_id: ID!) {
    deletePersonasAndIdentity(identity_id: $identity_id) {
      deleted_id
      res_status
    }
  }
`;

//#endregion

//#region settings

export const UPDATE_SETTING_MUTATION = gql`
  mutation updateSettings($input: UpdateSettingsInput) {
    updateSettings(input: $input) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const UPDATE_CATEGORY_SETTING_MUTATION = gql`
  mutation updateCategories($group_id: ID!, $categories: [String]!) {
    updateCategories(group_id: $group_id, categories: $categories) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmailSetting($token: String) {
    verifyEmailSetting(token: $token) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const VERIFY_EMAIL_MUTATION3 = gql`
  mutation verifyEmailSetting3($token: String, $group_id: ID, $user_id: ID, $from_community: Boolean, $identity_id: ID!) {
    verifyEmailSetting3(
      token: $token
      group_id: $group_id
      user_id: $user_id
      from_community: $from_community
      identity_id: $identity_id
    ) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

//#endregion

//#region notifications

export const SAVE_PUSH_TOKEN = gql`
  mutation SavePushNotificationToken($input: SavePushNotificationTokenInput) {
    SavePushNotificationToken(input: $input)
  }
`;

export const DELETE_PUSH_TOKEN = gql`
  mutation DeletePushNotificationToken($push_token: String!) {
    DeletePushNotificationToken(push_token: $push_token)
  }
`;

//#endregion

//#region feed and posts

export const PIN_POST = gql`
  mutation pin_post2($group_id: ID!, $post_id: Int!) {
    pin_post2(group_id: $group_id, post_id: $post_id) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const UNPIN_POST = gql`
  mutation unpin_post2($group_id: ID!) {
    unpin_post2(group_id: $group_id) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const ADD_ACTIVITY_3 = gql`
  mutation addActivity($input: ActivityInput2!, $files: [Upload]) {
    addActivity3(input: $input, files: $files) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const UPDATE_BY_ID = gql`
  mutation updateActivity(
    $id: ID!
    $post_id: String
    $foreign_id: String
    $set: ActivityUpdateInput
    $unset: ActivityUpdateInput
    $media_to_upload: [Upload]
    $media_type: String
    $valid_mentions: [Mention2]
  ) {
    updateActivity3(
      id: $id
      post_id: $post_id
      foreign_id: $foreign_id
      set: $set
      unset: $unset
      media_to_upload: $media_to_upload
      media_type: $media_type
      valid_mentions: $valid_mentions
    ) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const REMOVE_ACTIVITY = gql`
  mutation removeActivity2($id: ID!) {
    removeActivity2(id: $id) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const UPDATE_LIKE = gql`
  mutation updateLike($input: LikeInput!) {
    updateLike(input: $input) {
      ...ActivityDetails
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

export const ADD_COMMENT = gql`
  mutation addComment($input: CommentInput!) {
    addComment(input: $input) {
      ...ReactionDetails
    }
  }
  ${REACTION_DETAILS_FRAGMENT}
`;

export const EDIT_COMMENT = gql`
  mutation updateReaction2($input: ReactionUpdateInput2!) {
    updateReaction2(input: $input) {
      ...ReactionDetails
    }
  }
  ${REACTION_DETAILS_FRAGMENT}
`;

export const _EDIT_COMMENT = gql`
  mutation updateReaction($input: ReactionUpdateInput!) {
    updateReaction(input: $input) {
      ...ReactionDetails
    }
  }
  ${REACTION_DETAILS_FRAGMENT}
`;

export const UPDATE_REACTIONS = gql`
  mutation updateReactions($group_id: ID!, $reactions: [CustomReactionDescriptor]!) {
    updateReactions(group_id: $group_id, reactions: $reactions) {
      id
    }
  }
`;

export const UPLOAD_CUSTOM_REACTION = gql`
  mutation uploadCustomReaction($group_id: ID!, $reaction: CustomReactionDescriptor!) {
    uploadCustomReaction(group_id: $group_id, reaction: $reaction) {
      name
      url
    }
  }
`;

export const REMOVE_OWN_COMMENT = gql`
  mutation removeOwnComment($input: ReactionUpdateInput!) {
    flagReactionAsRemoved(input: $input) {
      ...ReactionDetails
    }
  }
  ${REACTION_DETAILS_FRAGMENT}
`;

export const SINGLE_IMG_UPLOAD = gql`
  mutation singleImgUpload($file: Upload!) {
    singleImgUpload(file: $file) {
      url
    }
  }
`;

export const CREATE_ADVERTISER = gql`
  mutation createAdvertiser($input: AdvertiserInput!, $files: [Upload]) {
    createAdvertiser(input: $input, files: $files) {
      id
      name
      handle
      avatar_url
    }
  }
`;
export const CREATE_AD = gql`
  mutation createAd($input: AdInput!, $files: [Upload]) {
    createAd(input: $input, files: $files) {
      id
      copy
      ad_name
      link_url
      image_urls
    }
  }
`;
export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($input: CampaignInput!) {
    createCampaign(input: $input) {
      ad_id
      group_id
      starts_at
      ends_at
      disabled
    }
  }
`;

//#endregion

//#region group

export const JOIN_GROUP = gql`
  mutation joinGroup($group_id: ID, $invite_token: String) {
    joinGroup(group_id: $group_id, invite_token: $invite_token) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
      group_invitation {
        ...GroupInvitationDetails
      }
      application_pending
      join_request_pending
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
  ${GROUP_INVITATION_DETAILS_FRAGMENT}
`;

export const AGREE_TO_CODE_OF_CONDUCT = gql`
  mutation agreeToCodeOfConduct($group_id: ID!) {
    agreeToCodeOfConduct(group_id: $group_id) {
      ...GroupMemberDetails
    }
  }
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

// export const REMOVE_GROUP_MEMBER = gql`
//   mutation removeGroupMemberExt($input: GroupMemberInput!) {
//     removeGroupMemberExt(input: $input) {
//       group {
//         ...GroupDetails
//       }
//     }
//   }
//   ${GROUP_DETAILS_FRAGMENT}
// `;

export const LEAVE_GROUP = gql`
  mutation leaveGroup($group_id: ID!) {
    leaveGroup(group_id: $group_id) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const CREATE_COMMUNITY = gql`
  mutation createCommunity($input: CreateCommunityInput2!) {
    createCommunity(input: $input) {
      group {
        ...GroupDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const UPDATE_GROUP_PHOTO = gql`
  mutation updateGroupPhoto2($group_id: ID!, $splash_url: String, $avatar_url: String) {
    updateGroupPhoto2(group_id: $group_id, splash_url: $splash_url, avatar_url: $avatar_url) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const ADD_FAVORITE_GROUP = gql`
  mutation addFavoriteGroup($group_ids: [ID!]) {
    addFavoriteGroup(group_ids: $group_ids) {
      statusCode
      message
      data {
        ...GroupDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const REMOVE_FAVORITE_GROUP = gql`
  mutation removeFavoriteGroup($group_ids: [ID!]) {
    removeFavoriteGroup(group_ids: $group_ids) {
      statusCode
      message
      data
    }
  }
`;

export const ADD_AFFILIATED_GROUP = gql`
  mutation addAffiliatedGroup($origin_group_id: ID!, $affiliated_group_id: ID!) {
    addAffiliatedGroup(origin_group_id: $origin_group_id, affiliated_group_id: $affiliated_group_id) {
      id
      origin_group_id
      affiliated_group_id
    }
  }
`;

export const REMOVE_AFFILIATED_GROUP = gql`
  mutation removeAffiliatedGroup($origin_group_id: ID!, $affiliated_group_id: ID!) {
    removeAffiliatedGroup(origin_group_id: $origin_group_id, affiliated_group_id: $affiliated_group_id) {
      id
      origin_group_id
      affiliated_group_id
    }
  }
`;

export const MARK_GROUP_AS_SEEN = gql`
  mutation markGroupAsRead($group_id: ID!) {
    markGroupAsRead(group_id: $group_id) {
      ...GroupMemberDetails
    }
  }
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const APPLY_TO_COMMUNITY = gql`
  mutation applyToCommunity($input: UserApplicationInput!) {
    applyToCommunity(input: $input) {
      id # not used by code, migrate to Void
    }
  }
`;

export const REQUEST_MESH_VERIFICATION = gql`
  mutation requestMeshVerification($input: MeshVerificationInput) {
    requestMeshVerification(input: $input) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const CREATE_COMMUNITY_APPLICATION = gql`
  mutation createCommunityApplication($input: CreateCommunityApplicationInput!) {
    createCommunityApplication(input: $input) {
      community_application {
        questions {
          ...CustomQuestionDetails
        }
      }
    }
  }
  ${CUSTOM_QUESTION_DETAILS_FRAGMENT}
`;

export const DELETE_COMMUNITY_APPLICATION = gql`
  mutation deleteCommunityApplication($group_id: ID!) {
    deleteCommunityApplication(group_id: $group_id) {
      community_application {
        questions {
          ...CustomQuestionDetails
        }
      }
    }
  }
  ${CUSTOM_QUESTION_DETAILS_FRAGMENT}
`;

export const DECLINE_INVITATION = gql`
  mutation declineInvitation($invite_token: String!) {
    declineInvitation(invite_token: $invite_token) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
      group_invitation {
        ...GroupInvitationDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const ARCHIVE_COMMUNITY = gql`
  mutation archiveCommunity2($group_id: ID!) {
    archiveCommunity2(group_id: $group_id) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

export const UNARCHIVE_COMMUNITY = gql`
  mutation unArchiveCommunity($group_id: ID!) {
    unArchiveCommunity(group_id: $group_id) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

// // This mutation is called when the user is trying to delete his account
// // The group will be scheduled to be deleted in 30 days
// export const ARCHIVE_GROUP = gql`
//   mutation archiveGroup($group_id: ID!) {
//     archiveGroup(group_id: $group_id) {
//       ...GroupDetails
//     }
//   }
//   ${GROUP_DETAILS_FRAGMENT}
// `;

// This mutation is called when the user has managed all the owned and co-owned and tries to delete his account
// The account with all personas will be scheduled to be deleted in 30 days
export const ARCHIVE_PERSONA = gql`
  mutation archivePersona($persona_id: ID!) {
    archivePersona(persona_id: $persona_id) {
      persona_id
      scheduled_at
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateGroupMemberRole2($group_id: ID!, $persona_id: ID!, $role_id: ID!) {
    updateGroupMemberRole2(group_id: $group_id, persona_id: $persona_id, role_id: $role_id) {
      group {
        ...GroupDetails
      }
      group_member {
        ...GroupMemberDetails
      }
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

//#endregion

//#region invitations
/**
 * version 1: url links without named source
 * version 2: url links with named source
 */
export const CREATE_INVITE_LINK = gql`
  mutation createInviteLink($group_id: ID!, $role_id: ID!, $unlimited: Boolean, $version: Int) {
    createInviteLink(group_id: $group_id, role_id: $role_id, unlimited: $unlimited, version: $version) {
      invite_url
    }
  }
`;

//#endregion

//#region moderation

export const APPLY_MODERATION_ACTIONS = gql`
  mutation applyModerationActions($input: ModerationActionInputV2!) {
    applyModerationActions(input: $input) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const APPROVE_POST = gql`
  mutation approvePost($input: PostModerationActionInput!) {
    approvePost(input: $input) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const REJECT_POST = gql`
  mutation rejectPost($input: PostModerationActionInput!) {
    rejectPost(input: $input) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const APPROVE_APPLICATION = gql`
  mutation approveApplication($input: PostModerationActionInput!) {
    approveApplication(input: $input) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const REJECT_APPLICATION = gql`
  mutation rejectApplication($input: PostModerationActionInput!, $ban_user: Boolean) {
    rejectApplication(input: $input, ban_user: $ban_user) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const MESH_VERIFY_IDENTITY = gql`
  mutation meshVerifyIdentity($issue_id: ID!) {
    meshVerifyIdentity(issue_id: $issue_id) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const MESH_REJECT_IDENTITY = gql`
  mutation meshRejectIdentity($issue_id: ID!) {
    meshRejectIdentity(issue_id: $issue_id) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

// TODO: not used!
// export const REOPEN_MODERATION_ISSUE = gql`
//   mutation reopenModerationIssue($input: ModerationActionInput!) {
//     reopenModerationIssue(input: $input) {
//       issue {
//         ...ModerationIssueDetails
//       }
//       explanation
//       activity {
//         id
//         object
//         verb
//         actor
//         time
//         foreign_id
//       }
//       group_id
//       moderator {
//         id
//         name
//         handle
//         avatar_url
//       }
//       group {
//         id
//         name
//         avatar_url
//       }
//     }
//   }
//   ${MODERATION_ISSUE_DETAILS_FRAGMENT}
// `;

export const REPORT_MODERATION_ISSUE = gql`
  mutation reportModerationIssue($input: ModerationIssueInput!) {
    reportModerationIssue(input: $input) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const REPORT_USER = gql`
  mutation reportUser($input: ReportUserModerationInput!) {
    reportUser(input: $input) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

/**
 * this is used for direct lifting of user ban i.e. clicking on the user's name in MemberInfo screen and lifting ban directly
 * to lift ban via Moderation Suite -- use performModerationAction mutation and pass action === MODERATION_ACTIONS.user_lift_ban
 */
export const LIFT_USER_BAN = gql`
  mutation liftUserBan($group_id: ID!, $persona_id: ID!) {
    liftUserBanForGroup(group_id: $group_id, persona_id: $persona_id) {
      lifted_ban_user {
        ...GroupMemberDetails
      }
      group {
        ...GroupCoreDetails
      }
    }
  }
  ${GROUP_CORE_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const ESCALATE_REPORT_TO_MESH = gql`
  mutation escalateReportToMesh($issue_id: ID!, $group_id: ID!, $mod_explanation: String) {
    escalateReportToMesh(issue_id: $issue_id, group_id: $group_id, mod_explanation: $mod_explanation) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const BAN_USER_FROM_GROUP_DIRECT = gql`
  mutation banUserDirect($target_persona_id: ID!, $group_id: ID!) {
    banUserFromGroupDirect2(target_persona_id: $target_persona_id, group_id: $group_id) {
      bannedUser {
        ...GroupMemberDetails
      }
      group {
        ...GroupCoreDetails
      }
    }
  }
  ${GROUP_CORE_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const REMOVE_USER_FROM_GROUP = gql`
  mutation removeUserFromGroup2($target_persona_id: ID!, $group_id: ID!) {
    removeUserFromGroup2(target_persona_id: $target_persona_id, group_id: $group_id) {
      count
    }
  }
`;

export const APPROVE_JOIN_REQUEST = gql`
  mutation approveJoinRequest($issue_id: ID!) {
    approveJoinRequest(issue_id: $issue_id) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const REJECT_JOIN_REQUEST = gql`
  mutation rejectJoinRequest($issue_id: ID!) {
    rejectJoinRequest(issue_id: $issue_id) {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;
//#endregion

//#region chat

export const CREATE_CHAT_USER_TOKEN = gql`
  mutation createChatUserToken {
    createChatUserToken {
      token
    }
  }
`;

export const UPDATE_PERSONA_RELATIONSHIP = gql`
  mutation updatePersonaRelationship($input: UpdatePersonaRelationshipInput) {
    updatePersonaRelationship(input: $input) {
      ...PersonaRelationshipFragment
    }
  }
  ${PERSONA_RELATIONSHIP_FRAGMENT}
`;

export const SET_ATTRIBUTION = gql`
  mutation setAttribution($input: AttributionInput!, $url: String!) {
    setAttribution(input: $input, url: $url)
  }
`;

export const CREATE_LINK_PREVIEW = gql`
  mutation createLinkPreview($link: String!) {
    createLinkPreview(link: $link) {
      entered
      url
      title
      imageUrls
      imageResizeMode
      imageBackgroundColor
    }
  }
`;

export const SEND_TELEMETRY = gql`
  mutation sendTelemetry($events: [EventInput]!, $client_time: Timestamp!) {
    sendTelemetry(events: $events, client_time: $client_time)
  }
`;

//#endregion

//#region Follow user
export const FOLLOW_CATEGORY = gql`
  mutation followCategory2($user_id: ID!, $category: String) {
    followCategory2(user_id: $user_id, category: $category) {
      id
      key
      value
    }
  }
`;
export const UNFOLLOW_CATEGORY = gql`
  mutation unfollowCategory2($user_id: ID!, $category: String) {
    unfollowCategory2(user_id: $user_id, category: $category) {
      id
      key
      value
    }
  }
`;

export const FOLLOW_USER = gql`
  mutation followUser($userId: ID!) {
    followUser(userId: $userId) {
      statusCode
      message
      data {
        ...FollowRequestDetails
      }
    }
  }
  ${FOLLOW_REQUEST_FRAGMENT}
`;

export const UNFOLLOW_USER = gql`
  mutation unFollowUser($userId: ID!) {
    unFollowUser(userId: $userId) {
      statusCode
      message
      data {
        ...FollowRequestDetails
      }
    }
  }
  ${FOLLOW_REQUEST_FRAGMENT}
`;

export const UPDATE_FOLLOW_NOTIFICATION = gql`
  mutation updateFollowingNotification($id: ID!, $setting: Boolean!) {
    updateFollowingNotification(id: $id, setting: $setting) {
      statusCode
    }
  }
`;

export const DECLINE_REQUEST_FOLLOW = gql`
  mutation declineFollowRequest($id: ID!) {
    declineFollowRequest(id: $id) {
      statusCode
    }
  }
`;

export const ACCEPT_REQUEST_FOLLOW = gql`
  mutation acceptFollowRequest($id: ID!) {
    acceptFollowRequest(id: $id) {
      statusCode
    }
  }
`;

export const UPDATE_FOLLOW_REQUEST = gql`
  mutation updateFollowRequest($input: UpdateFollowRequestInput!) {
    updateFollowRequest(input: $input) {
      statusCode
    }
  }
`;

export const READ_FOLLOWING_NOTIFICATION = gql`
  mutation readFollowingNotification($input: ReadFollowingNotificationInput) {
    readFollowingNotification(input: $input) {
      statusCode
    }
  }
`;

export const MAKE_SEEN_FOLLOWING_NOTIFICATION = gql`
  mutation clearUnseenNotifications {
    clearUnseenNotifications {
      statusCode
    }
  }
`;
//#endregion

export const INVITE_FRIEND_TO_GROUP = gql`
  mutation inviteByPersona($group_id: ID!, $persona_ids: [ID!]!, $role_id: ID!) {
    inviteByPersona(group_id: $group_id, persona_ids: $persona_ids, role_id: $role_id) {
      ...GroupDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

// Start Message Request
export const REQUEST_MESSAGE = gql`
  mutation requestMessage($persona_id: ID!) {
    requestMessage(persona_id: $persona_id) {
      channelId
    }
  }
`;

export const UPDATE_MESSAGE_REQUEST_STATUS = gql`
  mutation updateMessageRequestStatus($id: ID!, $accepted: Boolean!) {
    updateMessageRequestStatus(id: $id, accepted: $accepted) {
      id
      channelId
      requestedFrom {
        avatar_url
        handle
        id
      }
      requestedTo {
        avatar_url
        handle
        id
      }
      accepted
    }
  }
`;
// End Message Request

// region New explore
export const ADD_HERO_ASSET = gql`
  mutation addHeroAsset($input: HeroAssetDescriptor!) {
    addHeroAsset(input: $input) {
      id
      group_id
      url
      media_type
      end_date
      enabled
    }
  }
`;

export const UPLOAD_INTRO_VIDEO = gql`
  mutation uploadIntroVideo($input: uploadIntroVideoInput) {
    uploadIntroVideo(input: $input) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

export const DELETE_INTRO_VIDEO = gql`
  mutation deleteIntroVideo2($group_id: ID!) {
    deleteIntroVideo2(group_id: $group_id) {
      ...SettingDetails
    }
  }
  ${SETTING_DETAILS_FRAGMENT}
`;

// Start Topics
export const CREATE_TOPICS = gql`
  mutation addGroupTopics($groupID: ID!, $topic: String!) {
    addGroupTopics_V2(group_id: $groupID, topic: $topic) {
      ...TopicGroupCoreDetails
    }
  }
  ${TOPIC_GROUP_CORE_DETAILS_FRAGMENT}
`;

export const UPDATE_TOPIC = gql`
  mutation updateGroupTopics($id: ID!, $input: GroupTopicsUpdateInput!) {
    updateGroupTopics_V2(id: $id, input: $input) {
      ...TopicGroupCoreDetails
    }
  }
  ${TOPIC_GROUP_CORE_DETAILS_FRAGMENT}
`;

export const DELETE_TOPIC = gql`
  mutation deleteGroupTopics($id: ID!) {
    deleteGroupTopics_V2(id: $id) {
      id
    }
  }
`;

export const UPDATE_MY_FAVOR_TOPIC = gql`
  mutation updateMyFavorTopics($input: MyFavoriteTopicInput!) {
    updateMyFavorTopics(input: $input) {
      ...TopicGroupCoreDetails
    }
  }
  ${TOPIC_GROUP_CORE_DETAILS_FRAGMENT}
`;

export const UPDATE_POST_TOPICS = gql`
  mutation updatePostTopics($activity_id: ID!, $topic_ids: [ID]!) {
    updatePostTopics(activity_id: $activity_id, topic_ids: $topic_ids) {
      id
      topics {
        ...TopicGroupCoreDetails
      }
    }
  }
  ${TOPIC_GROUP_CORE_DETAILS_FRAGMENT}
`;

// End Topics

export const REACT_WITH = gql`
  mutation reactWith($input: ReactWithInput!) {
    reactWith(input: $input) {
      id
      kind
      activity_id
      user_id
      created_at
      updated_at
      removed
      data {
        customReaction {
          name
          url
        }
      }
    }
  }
`;

export const ADD_COMMENT_REACTION = gql`
  mutation addCommentReaction($reaction_id: ID!, $group_id: ID!) {
    addCommentReaction(reaction_id: $reaction_id, group_id: $group_id) {
      id
      kind
      activity_id
      user_id
      created_at
      updated_at
      removed
      data {
        customReaction {
          name
          url
        }
      }
    }
  }
`;

export const REMOVE_COMMENT_REACTION = gql`
  mutation removeCommentReaction($reaction_id: ID!) {
    removeCommentReaction(reaction_id: $reaction_id) {
      id
      kind
      activity_id
      user_id
      created_at
      updated_at
      removed
      data {
        customReaction {
          name
          url
        }
      }
    }
  }
`;

export const CREATE_PERSONAL_POST = gql`
  mutation addProfileActivity($input: ProfileActivityInput!, $files: [Upload]) {
    addProfileActivity(input: $input, files: $files) {
      ...ProfileActivityDetails
    }
  }
  ${PROFILE_ACTIVITY_DETAILS_FRAGMENT}
`;

export const REMOVE_PERSONAL_POST = gql`
  mutation removeProfileActivity($id: ID!) {
    removeProfileActivity(id: $id) {
      id
    }
  }
`;

export const UPDATE_PERSONAL_POST = gql`
  mutation updateProfileActivity($id: ID!, $input: ProfileActivityUpdateInput) {
    updateProfileActivity(id: $id, input: $input) {
      ...ProfileActivityDetails
    }
  }
  ${PROFILE_ACTIVITY_DETAILS_FRAGMENT}
`;

export const HIDE_PERSONAL_POST = gql`
  mutation hideProfileFeed($activity_id: ID!, $hide: Boolean!) {
    hideProfileFeed(activity_id: $activity_id, hide: $hide)
  }
`;

export const ADD_VOTE = gql`
  mutation addVote($option_id: ID!, $user_id: ID!, $activity_id: ID!) {
    addVote(option_id: $option_id, user_id: $user_id, activity_id: $activity_id) {
      option_id
      count
      voted
    }
  }
`;

export const COMMENT_PERSONAL_FEED = gql`
  mutation commentOnProfileActivity($input: CommentInput) {
    commentOnProfileActivity(input: $input) {
      ...ReactionDetails
    }
  }
  ${REACTION_DETAILS_FRAGMENT}
`;
