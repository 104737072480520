import React from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';
import { Colors, Typography } from '../common-styles';

type AuthMainTextProps = {
  text: string;
  style?: TextStyle;
};

export const AuthMainText: React.FC<AuthMainTextProps> = ({ text, style = {} }) => {
  return <Text style={[styles.main, style]}>{text}</Text>;
};

type AuthSubTextProps = {
  text: string;
  style?: TextStyle;
  testID?: string;
};

export const AuthSubText: React.FC<AuthSubTextProps> = ({ text, style = {}, testID }) => {
  return (
    <Text style={[styles.sub, style]} testID={testID}>
      {text}
    </Text>
  );
};

const styles = StyleSheet.create({
  main: {
    fontFamily: `inter-semibold`,
    fontSize: Typography.largeFontSize,
    color: Colors.textBlack,
    fontWeight: `bold`,
  },
  sub: {
    fontSize: Typography.smallFontSize,
    color: Colors.gray,
  },
});
