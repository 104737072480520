/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Comment, TopicItem, User } from '../common-types/types';

export type PostState = {
  modal?: Modals;
  isEdit: boolean;
  submitting: boolean;
  reportType?: string;
  target_id?: string;
  replyingTo?: User;
  targetComment?: Comment;
  is_member: boolean;
  can_participate: boolean;
  has_agreed: boolean;
  banned: boolean;
  ban_expiration?: number;
  post_frozen: boolean;
  user_frozen: boolean;
  pending: boolean;
  guidelines?: object[];
  hideSecondModal: boolean;
  selectedTopics: TopicItem[];
  isMemeTexterEditorClicked: boolean;
};

const PostSlice = createSlice<PostState, SliceCaseReducers<PostState>, string>({
  name: `post`,
  initialState: {
    modal: undefined,
    isEdit: false,
    submitting: false,
    reportType: undefined,
    target_id: undefined,
    replyingTo: undefined,
    targetComment: undefined,
    is_member: false,
    can_participate: false,
    has_agreed: false,
    banned: false,
    ban_expiration: undefined,
    post_frozen: false,
    user_frozen: false,
    pending: false,
    guidelines: undefined,
    hideSecondModal: false,
    selectedTopics: [],
    isMemeTexterEditorClicked: false,
  },
  reducers: {
    setReplyTo: (state, action) => {
      state.isEdit = false;
      state.targetComment = action.payload;
      state.replyingTo = action.payload?.user;
    },
    setCommentToEdit: (state, action) => {
      state.replyingTo = undefined;
      state.isEdit = !!action.payload;
      state.targetComment = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setReport: (state, action) => {
      state.modal = Modals.REPORT;
      state.reportType = action.payload.type;
      state.target_id = action.payload.target;
    },
    setMembership: (state, action) => {
      const { is_member, banned, has_agreed, can_participate, ban_expiration, guidelines } = action.payload;
      if (is_member) state.is_member = is_member;
      if (has_agreed) state.has_agreed = has_agreed;
      if (can_participate) state.can_participate = can_participate;
      if (banned) {
        state.banned = banned;
        state.ban_expiration = new Date(ban_expiration)?.getTime();
        state.modal = Modals.BANNED;
      } else if (is_member && !has_agreed && guidelines !== `[]`) {
        state.modal = Modals.AGREE;
      }
    },
    setGroupRelation: (state, action) => {
      const { post, user, pending } = action.payload;
      state.post_frozen = post;
      state.user_frozen = user;
      state.pending = pending;
      if (pending) {
        state.modal = Modals.PENDING;
      } else if (user) state.modal = Modals.USER_FROZEN;
      else if (post) state.modal = Modals.POST_FROZEN;
    },
    setHideSecondModal: (state, action) => {
      state.hideSecondModal = action.payload;
    },
    setSelectedTopics: (state, action) => {
      state.selectedTopics = action.payload;
    },
    setMemeTexterEditorClicked: (state, action) => {
      state.isMemeTexterEditorClicked = action.payload;
    },
  },
});

export enum Modals {
  POST_FROZEN = `closedComments`,
  USER_FROZEN = `frozen`,
  PENDING = `pending`,
  BANNED = `banned`,
  AGREE = `needsToAgree`,
  JOIN = `join`,
  COC = `codeOfConduct`,
  MANAGE_POST = `managePost`,
  MANAGE_COMMENT = `manageComment`,
  REPORT = `report`,
}

export const {
  setReplyTo,
  setCommentToEdit,
  setModal,
  setReport,
  setMembership,
  setGroupRelation,
  setHideSecondModal,
  setSelectedTopics,
  setMemeTexterEditorClicked,
} = PostSlice.actions;

const postReducer = PostSlice.reducer;

export { postReducer };
