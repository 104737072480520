import { useQuery } from '@apollo/react-hooks';
import { ActivityIndicator, FlatList, RefreshControl, Switch, Text, TextInput, TouchableOpacity, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { Divider, MeshIcon } from '../common-ui';

import { GET_USER_FOLLOWINGS } from '../graphql';
import { useFollow } from '../community/v2/hooks/useFollow';
import { MeshVerifiedCheck } from '../images';

export const UserFollowingNotificationListItem = () => {
  const navigation = useNavigation();
  const navigateToSettingPage = useCallback(() => navigation.push(`UserFollowingNotificationSettings`), [navigation]);
  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={navigateToSettingPage}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, padding: 16, backgroundColor: Colors.white }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>People You're Following</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const UserFollowingNotificationSettings = () => {
  const { user } = React.useContext(AppContext);
  const [searchKey, setSearchKey] = useState(``);
  const navigation = useNavigation();
  const { data, loading, error, refetch } = useQuery(GET_USER_FOLLOWINGS, {
    fetchPolicy: `network-only`,
    variables: {
      filter: {
        search: searchKey,
      },
    },
  });
  const [groupSettings, setGroupSettings] = useState([]);
  const { updateFollowNotification } = useFollow();

  useEffect(() => {
    if (data) {
      setGroupSettings(data?.getUserFollowings?.data);
    }
  }, [data]);

  //#region api calls
  const updateNotificationSetting = useCallback(
    async (id, setting) => {
      try {
        await updateFollowNotification(id, setting);
      } catch (err) {
        console.error(`Error thrown in update following notification`, err?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateFollowNotification, user.id],
  );
  //#endregion

  //#region render functions
  const renderItem = useCallback(
    ({ item, _index }) => {
      const { name, handle, id, send_notification, isVerified } = item;
      const findIndex = groupSettings.findIndex((gs) => gs.id === id);
      const changeSetting = async () => {
        try {
          const cloned = groupSettings;
          const newItem = groupSettings[findIndex];
          newItem.send_notification = !send_notification;
          cloned[findIndex] = newItem;
          setGroupSettings(cloned);

          await updateNotificationSetting(id, !send_notification);
        } catch (error) {
          console.error(error);
        }
      };

      return (
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, padding: 16 }}>
            <TouchableOpacity
              style={{ flexDirection: `row`, alignItems: `center` }}
              onPress={() => navigation.navigate(`Profile`, { user_id: item.persona_id })}
            >
              <Text style={Typography.text(`plusone`, `bold`)}>{name || handle}</Text>
              {isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
            </TouchableOpacity>
            <Switch value={send_notification} onValueChange={changeSetting} trackColor={{ true: `#2C9A44` }} />
          </View>
          <Divider />
        </View>
      );
    },
    [groupSettings, navigation, updateNotificationSetting],
  );

  const keyExtractor = useCallback((item, index) => item.id + index.toString(), []);

  //#endregion

  if (error)
    return (
      <View style={{ flex: 1, backgroundColor: Colors.white }}>
        <Text style={Typography.text(`red`, `bold`)}>Error thrown retrieving user notification settings for communities</Text>
        <Text>{JSON.stringify(error)}</Text>
      </View>
    );

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <TextInput
        placeholder="Search"
        placeholderTextColor={Colors.textPlaceholder}
        style={localStyles.textInput}
        onChangeText={setSearchKey}
        value={searchKey}
      />
      <Text style={localStyles.headerTitle}>Toggle notifications for people you're following.</Text>
      <View style={localStyles.headerContent}>
        <Text style={Typography.text(`base`, `bold`, `gray`)}>People I'm following</Text>
      </View>
      {loading && <ActivityIndicator />}
      <FlatList
        style={{ backgroundColor: Colors.white }}
        data={groupSettings}
        extraData={groupSettings}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}
        ListEmptyComponent={() => {
          if (loading) {
            return <View />;
          }
          return (
            <Text style={{ textAlign: `center`, paddingVertical: 10, color: Colors.gray }}>
              {searchKey.length === 0 ? `You are not following anyone yet` : `No results found`}
            </Text>
          );
        }}
      />
    </View>
  );
};

const localStyles = {
  headerTitle: {
    paddingBottom: 16,
    textAlign: `center`,
    fontSize: 12,
  },
  headerContent: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    backgroundColor: Colors.backgroundColor,
  },
  textInput: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    margin: 15,
    borderRadius: 6,
  },
};
