import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';
import { unionBy, unionWith } from 'lodash';
import { useState, useCallback, useEffect, useMemo, useContext, useRef } from 'react';
import { Dimensions } from 'react-native';
import { Spacing } from '../../../common-styles';
import {
  Activity,
  Activity as IActivity,
  CommunityAd,
  GroupMember,
  AffiliatedGroups,
  AffiliatedGroupsActivity,
  TopicItem,
  TopicStatus,
} from '../../../common-types/types';
import {
  COMMUNITY_EXTRAS,
  COMMUNITY_FEED_QUERY,
  COMMUNITY_FEED_SUBSCRIPTION,
  //COMMUNITY_SETTING,
  GET_POPULAR_TOPICS,
} from '../../../graphql';
import { AppContext } from '../../../../AppContext';
import { shuffleArray } from '../helpers';
import { useSettings } from '../../../common-settings/hooks/useSettings';

type FeedInput = {
  feed_slug: `group`;
  actor_id: string;
  get_pinned: boolean;
};

type FeedQueryData = {
  getActivities: IActivity[] | FeedSubscriptionData;
};

type FeedSubscriptionData = {
  new: IActivity[];
  deleted: {
    id: string;
  };
};

type FeedExtrasQueryData = {
  getCommunityAds?: CommunityAd[];
  getAffiliatedGroups2?: AffiliatedGroups;
};

type FeedExtrasQueryVar = { group_id: string };
type FeedQueryVar = {
  input: FeedInput;
  limit?: number;
  offset?: number;
  enrich?: boolean;
  reactions?: {
    own: boolean;
    recent: boolean;
    counts: boolean;
  };
  id_lt?: string;
  group_id: string;
  topic_ids?: string[];
};

type UseFeedProps = {
  groupId: string;
  groupMember?: GroupMember | null;
  popularTopicLimits?: number;
};

type PopularTopicProps = {
  getPopularTopicsList: TopicItem[];
};

type ItemLayout = { length: number; offset: number; index: number };

const CHUNK_SIZE = 15;

export const useFeed = ({ groupId, groupMember, popularTopicLimits }: UseFeedProps) => {
  const { notificationCount } = useContext(AppContext);
  const [privacyLock, setPrivacyLock] = useState(true);
  const [loadingDelay, setLoadingDelay] = useState(true);
  const [firstAdIndex] = useState(Math.floor(Math.random() * 1000)); // random first ad, rotate thereafter
  const notificationCountRef = useRef<any>();
  const [loadingCombination, setLoadingCombination] = useState(false);
  const [ads, setAds] = useState<CommunityAd[]>([]);

  const { settings } = useSettings(`group.setting.visible`, { group_id: groupId, user_id: undefined });
  /*const { data: privacyData } = useQuery(COMMUNITY_SETTING, {
    fetchPolicy: `cache-and-network`,
    variables: { input: { group_id: groupId, key: `group.setting.visible` } },
  });*/

  // FIXME:
  // if server manages to find 15 blocked posts straight,
  // client will interpret it as end of feed, then lock fetchMore to prevent loops
  // TODO: getActivities (v2) should return
  // {
  //   activities: Activity[]!,
  //   cursor: ID (id of last non-filtered post)
  // }
  // so that we can fetchMore until cursor is null (true end of feed)
  const { data, loading, error, refetch, networkStatus, fetchMore, subscribeToMore } = useQuery<FeedQueryData, FeedQueryVar>(
    COMMUNITY_FEED_QUERY,
    {
      skip: !groupId || privacyLock,
      variables: {
        input: {
          feed_slug: `group`,
          actor_id: groupId,
          get_pinned: true,
        },
        group_id: groupId,
        limit: CHUNK_SIZE,
      },
      fetchPolicy: `cache-and-network`,
      partialRefetch: true,
      notifyOnNetworkStatusChange: true,
    },
  );
  const { data: extras } = useQuery<FeedExtrasQueryData, FeedExtrasQueryVar>(COMMUNITY_EXTRAS, {
    skip: !groupId,
    variables: { group_id: groupId },
    onCompleted: (data) => {
      const { getCommunityAds } = data || {};
      if (getCommunityAds?.length) {
        setAds(shuffleArray<CommunityAd>(getCommunityAds));
      }
    },
  });
  const { data: popularData, refetch: refetchPopularTopic } = useQuery<PopularTopicProps>(GET_POPULAR_TOPICS, {
    variables: {
      group_id: groupId,
      limit: popularTopicLimits,
    },
  });

  useEffect(() => {
    if (popularTopicLimits && popularTopicLimits > 0) refetchPopularTopic();
  }, [popularTopicLimits]);

  const showTopicCarousel = useMemo(() => {
    const popularTopics = popularData?.getPopularTopicsList ?? [];
    const results = popularTopics.filter((t) => t?.post_count > 0 && t?.status === TopicStatus.ACTIVE);
    return results.length > 0;
  }, [popularData]);

  useEffect(() => {
    const unSubscribe = subscribeToMore({
      document: COMMUNITY_FEED_SUBSCRIPTION,
      variables: {
        group_id: groupId,
      },
      //@ts-ignore
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data?.getActivities) return prev;
        const res = subscriptionData.data?.getActivities as FeedSubscriptionData;
        const { new: newData = [] } = res || {};
        const oldActivities = (prev?.getActivities as IActivity[]) || [];
        try {
          let result = oldActivities;
          // Remove possible updates from other communities subscribed to
          const newFilteredData = newData?.filter((activity) => activity.origin_group_id === groupId) || [];
          if (!newFilteredData.length) return prev;

          result = unionWith(newFilteredData, oldActivities, (post1, post2) => {
            return post1.id === post2.id;
          });
          return {
            getActivities: result,
            getCommunityAds: [],
          };
        } catch (err) {
          console.error(`Error thrown in subscriptionMore for Community Feed`, err);
          return prev;
        }
      },
    });
    return unSubscribe;
  }, [subscribeToMore, groupId]);

  const [shouldFetchMore, setShouldFetchMore] = useState(true);
  const isRefreshing = networkStatus === NetworkStatus.refetch;

  const loadMoreActivities = () => {
    if (!loading && shouldFetchMore) {
      const activities = data?.getActivities as IActivity[];
      fetchMore({
        variables: {
          id_lt: activities?.slice(-1)[0].id,
        },
        updateQuery: (prev: FeedQueryData, { fetchMoreResult }) => {
          const results = fetchMoreResult?.getActivities as IActivity[];
          if (!results?.length) {
            setShouldFetchMore(false);
            return prev;
          }
          const prevActivities = (prev?.getActivities as IActivity[]) || [];
          // filter pinned post that breaks the order of posts
          const moreActivities = results.filter((activity) => !activity.pinned);
          // if returned less than 1, end of feed has been reached
          // yet this should not be done this way.
          if (moreActivities.length < 1) setShouldFetchMore(false);
          const newCursor = results.slice(-1)[0].id;
          return {
            id_lt: newCursor,
            getActivities: unionBy(prevActivities, moreActivities, (activity: Activity) => activity.id),
          };
        },
      });
    }
  };

  /**
   * This function is used to scroll to an item, right now it calculates the height of each row based on the standard post sizes
   * The postContentHeights list is supposed to be populated with the height of each post as they are rendered so you can
   * calculate the offset by knowing all the heights that come before it.
   *
   * console.log(`Post height from getItemLayout, postContentHeight);
   */
  const getItemLayout = useCallback((_item, index) => {
    const postHeaderHeight = 54;
    const postFooterHeight = 65;
    const dividerheight = 8;
    const communityHeaderHeight = 441;
    //FIXME: we need to manually calculate item's height instead of using width
    const postContentHeight = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);
    const totalRowHeight = postHeaderHeight + postContentHeight + postFooterHeight + dividerheight; //postContentHeights[index]
    const layout: ItemLayout = { length: totalRowHeight, offset: communityHeaderHeight + totalRowHeight * index, index };
    //console.log('🚀 ~ getItemLayout ~ layout', layout);
    return layout;
  }, []);

  useEffect(() => {
    const isPrivate = settings[`group.setting.visible`]?.value === `private`;
    if (groupMember?.id || !isPrivate) {
      if (privacyLock) setPrivacyLock(false);
    } else if (privacyLock !== (isPrivate && !groupMember?.id)) {
      setPrivacyLock(isPrivate && !groupMember?.id);
    }
  }, [settings, groupMember?.id, privacyLock]);

  useEffect(
    () => {
      //Reset fetchmore flag only once when changing active community
      if (!shouldFetchMore) setShouldFetchMore(true);
    },
    // reason: if effect depended on shouldFetchMore, it will loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId],
  );

  useEffect(() => {
    setLoadingDelay(true);
    setTimeout(() => {
      setLoadingDelay(false);
    }, 1000);
  }, [groupId]);

  useEffect(() => {
    if (loadingDelay) setLoadingCombination(true);
    if (!loadingDelay && !loading) setLoadingCombination(false);
  }, [loading, loadingDelay]);

  useEffect(() => {
    if (!!notificationCountRef?.current && notificationCount > notificationCountRef.current) {
      if (refetch) refetch();
      notificationCountRef.current = notificationCount;
    } else {
      notificationCountRef.current = notificationCount;
    }
  }, [notificationCount, refetch]);

  const activities = useMemo(() => {
    const posts = (data?.getActivities as IActivity[] | undefined) || [];
    const { data: affiliatedCommunities = [] } = extras?.getAffiliatedGroups2 || {};

    // no ads && no affiliated_communities
    if (!ads.length && !affiliatedCommunities.length && !showTopicCarousel) return posts;

    const offset = 1; // zero-based. 0 = first in list, 1 = second in list
    const stride = 10;

    const merged: (Activity | CommunityAd | AffiliatedGroupsActivity)[] = [];

    let ad_index = firstAdIndex % ads.length;
    for (let i = 0; i < posts.length; i += 1) {
      if (i === 1 && showTopicCarousel) {
        const topic: any = {
          id: `topic_carousel: ${groupId}`,
        };
        merged.push(topic);
      }

      if (ads.length) {
        // otherwise merge ads in according to parameters
        if (i % stride === offset && posts.length > 4) {
          const ad = ads[ad_index];
          const ad_activity: CommunityAd = {
            ...ad,
            id: `ad:${merged.length}:${ad.id}`,
          };

          //const userHidThisIndex = hiddenAdIndexes.includes(merged.length.toString());
          //if (!userHidThisIndex)
          merged.push(ad_activity);

          ad_index = (ad_index + 1) % ads.length;
        }
      }

      if (affiliatedCommunities?.length && i === (showTopicCarousel ? 3 : 4) && posts.length > 3) {
        const affiliated_section_activity: AffiliatedGroupsActivity = {
          affiliations: affiliatedCommunities,
          id: `affiliated:${affiliatedCommunities[0].group.id}`,
        };
        merged.push(affiliated_section_activity);
      }

      merged.push(posts[i]);
    }
    return merged;
  }, [data, extras, ads, firstAdIndex, showTopicCarousel, groupId]);

  return {
    isRefreshingFeed: isRefreshing,
    isLoadingFeed: loading,
    error,
    loadingCombination,
    feed: loadingCombination ? [] : activities,
    scrollRefresh: refetch,
    loadMoreActivities,
    getItemLayout,
    popularTopics: popularData?.getPopularTopicsList || [],
  };
};
