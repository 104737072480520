import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { WebSocketLink } from 'apollo-link-ws';
import ApolloSentryLink from 'apollo-sentry-link';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import MessageTypes from 'subscriptions-transport-ws/dist/message-types';
import { Platform } from 'react-native';
import { getEnvVariables, getAppVersion, getManifest } from '../../environment';
import introspectionQueryResultData from './fragmentTypes.json';

const { ssl, apiUrl } = getEnvVariables();

const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

// Set the accessToken used for authentication
let authorization;
export const setAccessToken = (newToken) => {
  if (newToken !== authorization) {
    authorization = newToken;
    reconnectWebsocketClient();
  }
};
export const clearAccessToken = () => setAccessToken(undefined);

const authLink = setContext((_operation, { headers }) => {
  return { headers: { ...headers, authorization } };
});

// Send an authorization header with websocket connections (subscriptions)
// When auth changes due to login/logout, we have to close and re-open the websocket
const wsClient = new SubscriptionClient(`${ssl ? `wss` : `ws`}://${apiUrl}/graphql`, {
  reconnect: true,
  connectionParams: () => {
    //console.log(`wsClient connecting with authorization: ${authorization}`);
    return authorization ? { authorization, headers: { authorization } } : {};
  },
});
const wsLink = new WebSocketLink(wsClient);

export const reconnectWebsocketClient = ({ restoreSubscriptions } = {}) => {
  // Copy current operations
  const operations = restoreSubscriptions && { ...wsClient.operations };

  // Close and reopen connection
  wsClient.close(true);
  wsClient.connect();

  // Push all current operations to the new connection
  if (restoreSubscriptions)
    Object.keys(operations).forEach((id) => wsClient.sendMessage(id, MessageTypes.GQL_START, operations[id].options));
};

// Allow uploading of files
const uploadLink = createUploadLink({
  uri: `${ssl ? `https` : `http`}://${apiUrl}/graphql`,
  credentials: `same-origin`,
});

const retryLink = new RetryLink().split(
  (sys) => {
    const { operation } = getMainDefinition(sys.query);
    return operation === `subscription`;
  },
  authLink && authLink.concat(wsLink),
  authLink && authLink.concat(uploadLink),
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, _extensions }) => {
      console.log(`🛑[GraphQL error]: Message: ${message}, Path: ${path}`);
    });
  }
  if (networkError) console.error(`[Network error]:`, networkError);
});

export const client = new ApolloClient({
  link: ApolloLink.from([ApolloSentryLink, errorLink, retryLink]),
  name: Platform.select({ ios: `mesh-ios`, android: `mesh-android`, web: `mesh-web` }),
  version: `${getAppVersion()} (${getManifest()?.extra?.publishID || `dev`})`,
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case `NotificationReaction`: // FIXME: object with different class using ActivityReaction.id
          if (!object.reaction) {
            // console.log(`received NotificationReaction with null reaction:`, JSON.stringify(object));
            return null;
          }
          return `NotificationReaction:${object.reaction.id}`;
        case `GroupInvitation`:
          return `GroupInvitation:${object.invite_token}`;
        default:
          if (object.__typename && object.id) return `${object.__typename}:${object.id}`;
          return object.id;
      }
    },
  }),
});

// summarizes a GQL document for human consumption
export const summarizeGQL = (query) => {
  if (query && Array.isArray(query.definitions)) {
    const queryNames = query.definitions
      .filter((d) => d.operation === `query` || d.operation === `mutation`)
      .map((d) => d.name.value)
      .join();
    return queryNames;
  }

  // unknown
  return JSON.stringify(query);
};

// summarizes a GQL result for human consumption
export const summarizeObject = (obj) => {
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    const N = 3;
    const firstN = obj.slice(0, N);
    return `[${firstN.map(summarizeObject).join()}${obj.length > N ? `, ...` : ``}]`;
  }
  if (obj.__typename) {
    const { handle, name, id } = obj;
    if (handle || name || id) return `${obj.__typename} ${handle || name || id}`;
    return obj;
  }
  return obj;
};

export const summarizeResult = (result) => {
  const { data } = result || {};
  const summary = {};
  if (data) Object.keys(data).forEach((key) => (summary[key] = summarizeObject(data[key])));
  return JSON.stringify(summary); //.substring(0,90);
};

// monkey-patches to print as calls are issued and return
let concurrent = 0;
let highWaterConcurrency = 0;
const networkCallStart = (...args) => {
  concurrent += 1;
  const oldHW = highWaterConcurrency;
  highWaterConcurrency = Math.max(concurrent, highWaterConcurrency);

  if (oldHW !== highWaterConcurrency && highWaterConcurrency % 10 === 0) {
    const { stack } = new Error();
    console.log(`WARNING WARNING WARNING: ${highWaterConcurrency} simultaneous network calls is a lot! Stack dump:\n`, stack);
  }

  const { first, ...rest } = args;
  if (typeof first === `string`) console.log(`${concurrent} (max ${highWaterConcurrency}) ${first}`, ...rest);
  else console.log(`${concurrent} (max ${highWaterConcurrency})`, ...args);
};

const networkCallDone = (...args) => {
  concurrent -= 1;
  const { first, ...rest } = args;
  if (typeof first === `string`) console.log(`${concurrent} (max ${highWaterConcurrency}) ${first}`, ...rest);
  else console.log(`${concurrent} (max ${highWaterConcurrency})`, ...args);
};

client._query = client.query;
client.query = async (...args) => {
  const [options] = args;
  const { query } = options || {};
  const what = summarizeGQL(query);
  networkCallStart(`=> query:`, what);
  try {
    const result = await client._query(...args);
    networkCallDone(`<= query ok: ${summarizeResult(result)}`);
    return result;
  } catch (err) {
    networkCallDone(`<= query exception:`, what, err);
    throw err;
  }
};

client._mutate = client.mutate;
client.mutate = async (...args) => {
  const [options] = args;
  const { mutation } = options || {};
  const what = summarizeGQL(mutation);
  networkCallStart(`=> mutate:`, what);
  try {
    const result = await client._mutate(...args);
    networkCallDone(`<= mutate ok: ${summarizeResult(result)}`);
    return result;
  } catch (err) {
    networkCallDone(`<= mutate exception:`, mutation, err);
    throw err;
  }
};
