import { Text, View, SectionList } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { Avatar, Divider, ListItem } from '../common-ui';
import { commonPropTypes } from '../common-util';

export class LeadersAndModerators extends React.PureComponent {
  static propTypes = {
    navigation: commonPropTypes.navigation().isRequired,
    route: commonPropTypes.route().isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  _renderSectionHeader = ({ section: { title, data } }) => {
    return (
      <View style={{ paddingHorizontal: 16, paddingVertical: 8, backgroundColor: Colors.backgroundColor }}>
        <Text style={{ ...Typography.text(`plusone`, `bold`, `black`) }}>
          {title} ({data.length})
        </Text>
      </View>
    );
  };

  renderItems = ({ item }) => {
    const { navigation } = this.props;
    const { persona } = item;

    const openUserProfile = () => {
      if (persona) navigation.push(`Profile`, { user: persona });
    };

    return (
      <ListItem
        title={persona && persona.handle.trim()}
        leftAvatar={<Avatar navigation={navigation} user={persona} size={40} />}
        onPress={openUserProfile}
      />
    );
  };

  render = () => {
    const { route } = this.props;
    const { admin_mods = [] } = route.params;
    const owners = admin_mods.filter((owner) => owner.role_name === `owner`);
    const managers = admin_mods.filter((manager) => manager.role_name === `manager`);
    const mods = admin_mods.filter((mod) => mod.role_name === `moderator`);
    const sections = [
      { id: 0, title: `Owners`, data: owners },
      { id: 1, title: `Community Leaders`, data: managers },
      { id: 2, title: `Moderators`, data: mods },
    ];

    const filteredSections = sections.filter((section) => section.data && section.data.length > 0);

    return (
      <SectionList
        sections={filteredSections}
        renderSectionHeader={this._renderSectionHeader}
        renderItem={this.renderItems}
        ItemSeparatorComponent={Divider}
      />
    );
  };
}
