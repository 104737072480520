import { ActivityIndicator, Linking, ScrollView, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { Divider, MeshIcon } from '../common-ui';
import { dateUtil } from '../common-util';
import { COC_AGREE } from './testLabels';
import { GroupCoreFields, Guideline } from '../common-types/types';

type AgreeCheckboxProps = {
  agree: boolean;
  showAgreeAndJoin: boolean;
  toggleAgree: () => void;
};
type CodeOfConductProps = {
  group: GroupCoreFields;
  fetchedGuidelines?: Guideline[];
  loadingGuidelines?: boolean;
} & AgreeCheckboxProps;

export const CodeOfConduct: React.FC<CodeOfConductProps> = ({
  group,
  fetchedGuidelines,
  loadingGuidelines,
  agree,
  toggleAgree,
  showAgreeAndJoin,
}) => {
  const { guidelines_updated_at, created_at } = group || {};
  const updated_at = dateUtil.formatShortRelativeDate(guidelines_updated_at || created_at);
  const on = updated_at.toLowerCase().includes(`ago`) ? `` : `on `;

  const openMeshGroundRules = () => {
    Linking.openURL(`https://www.meshcommunities.us/ground-rules`);
  };

  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      alwaysBounceVertical={false}
      style={localStyles.scrollview}
      testID="COC_SCROLL"
    >
      <View style={localStyles.margin15}>
        <Text style={Typography.text(`plusone`, `black`, `bold`)}>{group.name.trim()}</Text>
        <Text style={{ ...Typography.text(`small`, `gray`), marginTop: 4 }}>Last updated {`${on}${updated_at}`}</Text>
      </View>
      <Divider />
      <View style={localStyles.margin15}>
        <Text style={Typography.text()}>In order to join this node, you must agree to:</Text>
        <View style={localStyles.infoContainer}>
          <Text style={Typography.text(`bold`)}>Mesh's Ground Rules</Text>
          <Text style={Typography.text()}>
            <Text>You must agree to our platform policy. </Text>
            <Text style={Typography.text(`bold`, `theme`)} onPress={openMeshGroundRules}>
              Read more
            </Text>
          </Text>
        </View>
        <View style={localStyles.infoContainer}>
          <Text style={Typography.text(`bold`)}>Code of Conduct</Text>
          <GroupGuidelines loadingGuidelines={loadingGuidelines} guidelines={fetchedGuidelines} />
        </View>
        <AgreeCheckbox showAgreeAndJoin={showAgreeAndJoin} agree={agree} toggleAgree={toggleAgree} />
      </View>
    </ScrollView>
  );
};

type GroupGuidelinesProps = {
  guidelines?: Guideline[];
  loadingGuidelines?: boolean;
};

const GroupGuidelines: React.FC<GroupGuidelinesProps> = ({ guidelines, loadingGuidelines = true }) => {
  if (!guidelines?.length) {
    return (
      <View style={{ padding: 10, borderRadius: 8, backgroundColor: Colors.lightGray }}>
        {loadingGuidelines ? (
          <ActivityIndicator size={30} color={Colors.gray} />
        ) : (
          <Text style={Typography.text(`center`)}>This node has no custom guidelines</Text>
        )}
      </View>
    );
  }
  return (
    <>
      {guidelines.map((g, i) => {
        return (
          <View key={g.id.toString()} style={localStyles.guidelineContainer}>
            <Text style={{ ...Typography.text(`bold`) }}>
              {i + 1}. {g.title}
            </Text>
            <Text style={{ ...Typography.text(), fontWeight: `300` }}>{g.body}</Text>
          </View>
        );
      })}
    </>
  );
};

const AgreeCheckbox: React.FC<AgreeCheckboxProps> = ({ showAgreeAndJoin, agree, toggleAgree }) => {
  if (!showAgreeAndJoin) return null;
  return (
    <TouchableOpacity
      testID={COC_AGREE}
      onPress={toggleAgree}
      activeOpacity={0.8}
      style={{ flexDirection: `row`, alignItems: `center`, marginTop: 6, paddingBottom: 60 }}
    >
      <MeshIcon
        style={{ marginRight: 6 }}
        name={agree ? `checkbox-on` : `checkbox-off`}
        color={agree ? Colors.brandPurple : Colors.gray}
      />
      <Text style={Typography.text()}>I agree to follow the Code of Conduct</Text>
    </TouchableOpacity>
  );
};

const localStyles = {
  scrollview: {
    flexGrow: 1,
  },
  margin15: {
    margin: 15,
  },
  infoContainer: {
    padding: 10,
    backgroundColor: Colors.lightGray,
    borderRadius: 8,
    marginVertical: 10,
  },
  guidelineContainer: {
    marginVertical: 10,
  },
};
