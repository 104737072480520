export const PostDetailTestIds = {
  edit: `POST_EDIT`,
  delete: `POST_DELETE`,
  report: `POST_REPORT`,
  followUser: `FOLLOW_USER`,
  userReport: `USER_REPORT`,
  commentReport: `REPORT_COMMENT`,
  reportUserOfComment: `REPORT_USER_OF_COMMENT`,
  editComment: `EDIT_COMMENT`,
  deleteComment: `DELETE_COMMENT`,
  scrollView: `REACTIONS_SCROLLVIEW`,
  postDetails: `POST_DETAILS`,
  hidePost: `HIDE_POSTS`,
  turnoffComment: `TURN_OFF_COMMENT`,
};
