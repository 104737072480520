import { Dimensions, View } from 'react-native';
import React from 'react';
import { Colors, Spacing } from '../common-styles';
import { CenterColumn, Divider, ErrorBoundary } from '../common-ui';
import { commonPropTypes } from '../common-util';

import PostContent from '../post/components/PostContent';
import PostFooter from '../post/components/PostFooter';
import PostHeader from '../post/components/PostHeader';
import { classifyActivity } from '../post/helpers';
//import * as PostStyles from '../post/PostStyles';

export const DeniedDetail = ({ navigation, route }) => {
  const width = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);
  const activity = route.parmas?.activity;
  console.log(`[ACTIVITY_ID]:`, activity.id);
  let { content: text } = activity || {};
  if (text) text = text.trim();
  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <View style={{ flex: 1 }}>
        <CenterColumn testID="POST_DETAILS">
          <View style={{ width, minHeight: width }}>
            <ErrorBoundary header="Sorry, an error occurred displaying this post">
              <PostHeader navigation={navigation} activity={activity} showGroup />
              <Divider />
              <PostContent activity={activity} width={width} textOverflowMode="expand" startExpanded={false} onScreen />
              {classifyActivity(activity) !== `text` && !!text && <PostFooter navigation={navigation} activity={activity} />}
            </ErrorBoundary>
          </View>
        </CenterColumn>
        <View style={{ flex: 1, paddingBottom: 40 }} />
      </View>
    </View>
  );
};

DeniedDetail.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

// fluid transitions
DeniedDetail.sharedElemnts = (_navigation, _otherNavigation, _showing) => {
  return [`activity:-1`];
};
