import React, { useCallback, useEffect, useState } from 'react';
import { View, ScrollView, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation, RouteProp, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { client, VERIFY, REQUEST_VERIFY_TOKEN } from '../graphql';
import { AuthMainText, AuthSubText, HeaderSubmitButton, SafeAreaView, KeyboardPaddingView, BackButton } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { Events, PendoTrackEvent } from '../pendo/events';
import { AuthNavigationContainer, AuthNavigatorScreens } from '../common-types/navigation-types';

type VerifyEmailNavigator = StackNavigationProp<AuthNavigationContainer, `Auth`>;

type VerifyEmailRoute = RouteProp<AuthNavigatorScreens, `VerifyEmail`>;

export const VerifyEmail = () => {
  const { identity } = React.useContext(AppContext);

  const navigation = useNavigation<VerifyEmailNavigator>();
  const route = useRoute<VerifyEmailRoute>();

  const { email } = route.params;

  const [verificationCode, setVerificationCode] = useState(``);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(``);

  const onChangeVerificationText = (text: string) => {
    setVerificationCode(text);
    setError(``);
  };

  const verifyEmail = useCallback(async () => {
    try {
      setLoading(true);

      const input = { id: identity?.id, verification_token: verificationCode };
      const { data } = await client.mutate({ mutation: VERIFY, variables: { input }, fetchPolicy: `no-cache` });

      if (!data.VerifyAccount) {
        setError(`The verification code you entered does not match our records. Please try again.`);
        return;
      }

      const { verified, expired } = data.VerifyAccount;

      if (expired) {
        setError(`We're sorry, that the code has expired. Please request a new one and try again.`);
        return;
      }

      PendoTrackEvent(Events.VERIFY_EMAIL_CONTINUE, { email, verified: !!verified });
      if (verified) {
        navigation.navigate(`Persona`, { screen: `DOB` });
      } else {
        setError(`We're sorry, that code does not match our records. Please request a new one and try again.`);
      }
    } catch (e: any) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [identity?.id, email, verificationCode, navigation]);

  const resendVerificationCode = useCallback(async () => {
    try {
      setLoading(true);
      setVerificationCode(``);
      setError(``);
      PendoTrackEvent(Events.VERIFY_EMAIL_RESEND, { email });
      const { data } = await client.mutate({
        mutation: REQUEST_VERIFY_TOKEN,
        variables: { id: identity?.id, contact: email },
        fetchPolicy: `no-cache`,
      });
      if (!data.RequestVerificationToken) {
        console.error(`Error`);
        return;
      }
    } catch (e: any) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [email, identity?.id]);

  const goBack = useCallback(() => {
    PendoTrackEvent(Events.VERIFY_EMAIL_BACK);
    navigation.goBack();
  }, [navigation]);

  // const onSkip = () => {
  //   PendoTrackEvent(Events.VERIFY_EMAIL_SKIP);
  //   navigation.navigate(`Persona`, { screen: `DOB` });
  // };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
      headerRight: () => <HeaderSubmitButton disabled={!verificationCode || loading} title="Continue" onPress={verifyEmail} />,
    });
  }, [goBack, loading, navigation, verificationCode, verifyEmail]);

  useEffect(() => {
    resendVerificationCode();
  }, []);

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
          contentContainerStyle={{ height: `100%` }}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={localStyles.container}>
            <View>
              <View style={localStyles.blob} />
              <AuthMainText text="Enter your verification code" />
            </View>

            <AuthSubText
              text={`A one-time code has been sent to ${email}. This code is valid for 10 minutes.`}
              style={{ marginTop: 16, marginBottom: 24 }}
            />

            <AuthSubText text="Verification code" style={{ marginLeft: 6, marginBottom: 5 }} />

            <TextInput
              blurOnSubmit
              autoFocus
              onChangeText={!loading ? onChangeVerificationText : () => {}}
              placeholder="______"
              placeholderTextColor={Colors.textPlaceholder}
              keyboardType="number-pad"
              style={localStyles.inputStyle}
              maxLength={6}
              returnKeyType="done"
              clearButtonMode="always"
            />

            {!!error && (
              <View style={localStyles.errorContainer}>
                <AuthSubText
                  text={error}
                  style={{
                    color: Colors.textRed,
                    fontWeight: Typography.mediumBoldFontWeight,
                  }}
                />
              </View>
            )}

            <TouchableOpacity activeOpacity={0.8} onPress={resendVerificationCode} style={{ marginTop: 16 }} disabled={loading}>
              <AuthSubText
                text="Resend verification code"
                style={{
                  color: !loading ? Colors.themeColor : Colors.gray,
                  fontWeight: Typography.mediumBoldFontWeight,
                }}
              />
            </TouchableOpacity>
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 16,
    height: `100%`,
  },
  inputStyle: {
    padding: 10,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    borderRadius: 4,
    letterSpacing: 3,
  },
  errorContainer: {
    padding: 10,
    borderRadius: 4,
    marginTop: 10,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(79,206,221,0.7)`,
    borderTopStartRadius: 50,
    borderTopEndRadius: 18,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 25,
    left: -10,
    top: 2,
  },
});
