import { NetworkStatus } from 'apollo-client';
import { unionBy } from 'lodash';
import PropTypes from 'prop-types';
import { Dimensions, Platform, StyleSheet, View, Text, FlatList } from 'react-native';
import React, { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NOTIFICATIONS_PER_FETCH } from '../../constants';
import { Colors, Typography } from '../common-styles';
import { EmptyNotifications } from '../images';
import { BottomSheetModal, CenterColumn, LoadingIndicator, ThemedButton } from '../common-ui';
import { NotificationItem } from './NotificationItem';
import { showNotImplementedOptions } from './notificationsTempConstants';
import { AppContext } from '../../AppContext';

export const NotificationList = ({ markRead, data, loading, networkStatus, refetch, fetchMore }) => {
  const { user } = React.useContext(AppContext);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreToFetch, setNoMoreToFetch] = useState(false);

  const notifications = data?.GetNotificationsV2 || [];
  // Sort the notifications by most recent at the beginning given the Date of creation
  const sortedNotifications = notifications.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

  const showOptionsModal = useCallback(
    (type) => {
      setOptions(buildOptionsMenu(type));
      setShowOptions(true);
    },
    [buildOptionsMenu],
  );

  /**
   * return options to display for OptionsModal
   * @returns {object[]} array of objects containing what options to show for the OptionsModal
   */
  const buildOptionsMenu = useCallback(
    (notification) => {
      if (!notification) return [];
      const { verb } = notification;
      const allowedTurnOffNotifications = [`like`, `comment`, `comment_like`, `reply_comment`];
      const options = [
        {
          title: `Mark as read`,
          iconName: `eye`,
          onPress: () => {
            setShowOptions(false);
            markRead(notification.id);
          },
        },
        {
          predicate: () => !!showNotImplementedOptions && allowedTurnOffNotifications.indexOf(verb) >= 0,
          title: `Turn off notifications about this post`,
          iconName: `volume-x`,
          onPress: () => {
            setShowOptions(false);
            /** TBD We need to implement this */
          },
        },
        {
          predicate: () => !!showNotImplementedOptions,
          title: `Remove notification`,
          titleStyle: { color: Colors.alertColor },
          iconName: `minus-circle`,
          iconColor: Colors.alertColor,
          onPress: () => {
            setShowOptions(false);
            /** TBD We need to implement this */
          },
        },
      ];
      return options.filter((option) => option.predicate === undefined || option.predicate());
    },
    [markRead],
  );

  const getMoreItems = useCallback(() => {
    if (!loading && !loadingMore && !noMoreToFetch) {
      setLoadingMore(true);
      const [notificationItem] = data?.GetNotificationsV2?.slice(-1);
      fetchMore({
        variables: { id_lt: notificationItem?.id },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.GetNotificationsV2?.length || fetchMoreResult?.GetNotificationsV2?.length === 1) {
            console.log(`Same with Previous one, return prev`);
            setLoadingMore(false);
            setNoMoreToFetch(true);
            return prev;
          }
          setLoadingMore(false);
          setNoMoreToFetch(false);

          const moreNotifications = fetchMoreResult?.GetNotificationsV2;
          const [latestNotification] = moreNotifications?.slice(-1);
          const notifications = unionBy(prev.GetNotificationsV2, moreNotifications, (n) => n.id);

          return { id_lt: latestNotification.id, GetNotificationsV2: notifications };
        },
      });
    }
  }, [data?.GetNotificationsV2, fetchMore, loading, loadingMore, noMoreToFetch, setNoMoreToFetch, setLoadingMore]);

  const onRefresh = () => {
    if (user?.id) {
      refetch({
        input: {
          feed_slug: `notification`,
          actor_id: user.id,
        },
        limit: NOTIFICATIONS_PER_FETCH,
      });
      setNoMoreToFetch(false);
    }
  };

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <CenterColumn>
          <NotificationItem notification={item} markRead={markRead} onPressOptionsButton={showOptionsModal} status="activity" />
        </CenterColumn>
      );
    },
    [markRead, showOptionsModal],
  );

  const renderFooter = () => {
    if (sortedNotifications.length) {
      return (
        <CenterColumn>
          <View style={localStyles.containerListFooter}>
            {loadingMore && <LoadingIndicator size="small" />}
            {!loadingMore && !loading && !noMoreToFetch && (
              <ThemedButton
                rounded
                outline
                clear
                title="Load more"
                testID="LOAD_MORE_NOTIFICATIONS"
                onPress={getMoreItems}
                buttonStyle={{ paddingHorizontal: 30, paddingVertical: 12 }}
                disabled={loading || loadingMore || noMoreToFetch}
              />
            )}
          </View>
        </CenterColumn>
      );
    }
    return null;
  };
  return (
    <View style={localStyles.container}>
      <FlatList
        testID="NOTIFICATION_LIST"
        ListEmptyComponent={EmptyNotificationList}
        data={sortedNotifications}
        key={sortedNotifications.length}
        extraData={sortedNotifications}
        refreshing={networkStatus === NetworkStatus.refetch}
        onRefresh={onRefresh}
        onEndReachedThreshold={0.3}
        onEndReached={getMoreItems}
        keyExtractor={(item, index) => (item && item.id + index.toString()) || uuidv4()}
        renderItem={renderItem}
        ListFooterComponent={renderFooter}
        style={localStyles.containerFlatList}
      />
      <BottomSheetModal
        title="Manage notifications"
        visible={showOptions}
        onPressCancel={() => setShowOptions(false)}
        onPressConfirm={() => setShowOptions(false)}
        options={options}
        confirmTitle="Close"
      />
    </View>
  );
};

NotificationList.propTypes = {
  markRead: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  networkStatus: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
};

const EmptyNotificationList = () => (
  <View style={{ flex: 1, alignItems: `center`, paddingHorizontal: 43, paddingVertical: 48 }}>
    <EmptyNotifications style={{ marginBottom: 32, marginTop: 100 }} width="288" height="220" />
    <View style={{ justifyContent: `center` }}>
      <Text style={{ ...Typography.text(`center`) }}>
        Welcome to Mesh! Your notifications will appear here when you recieve them.
      </Text>
    </View>
  </View>
);

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  containerFlatList: {
    flex: 1,
    height: Platform.select({ web: Dimensions.get(`window`).height - 48 * 2 }),
  },
  containerListFooter: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 10,
  },
});
