import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Colors } from '../common-styles';
import { BackButton, KeyboardPaddingView, SafeAreaView, ThemedButton } from '../common-ui';
import { useTopics } from '../community/v2/hooks/useTopics';
import { GET_TOPICS_LIST } from '../graphql';
import { Events, PendoTrackEvent } from '../pendo/events';
import { setForceRefreshTopicLists } from '../redux/feedSlice';

const CreateTopic: React.FC<{ route: any }> = ({ route }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const { group, role_name, currentTopic } = route?.params;

  const [fetchTopics, { data: topics, loading }] = useLazyQuery(GET_TOPICS_LIST, {
    fetchPolicy: `no-cache`,
  });

  const [topicText, setTopicText] = useState(currentTopic?.topic || ``);
  const [prevTopic, setPrevTopic] = useState(``);

  const { createTopic, creating, updateTopic } = useTopics();

  const [created, setCreated] = useState(``);
  const [error, setError] = useState(``);

  useEffect(() => {
    if (group?.id) {
      fetchTopics({
        variables: {
          filter: {
            keyword: topicText,
            limit: 5,
            offset: 0,
            group_id: group?.id,
          },
        },
      });
    }
  }, [fetchTopics, group, topicText]);

  const isValid = useMemo(() => {
    setError(``);
    if (topicText.length > 30) {
      return false;
    }
    if (/^\s/.test(topicText)) {
      setError(`Topic name can't start with space`);
      return false;
    }
    if (topics?.getGroupTopicsList_V2.length > 0) {
      if (/\S/.test(topicText)) {
        if (
          topics?.getGroupTopicsList_V2?.some((tp: any) => {
            return tp.topic.toLocaleLowerCase() === topicText.trim().toLocaleLowerCase();
          })
        ) {
          setError(`Topic name already exists`);
          return false;
        }

        return true;
      }
    } else {
      return true;
    }
    return false;
  }, [topics, topicText]);

  useEffect(() => {
    if (created.length > 0 && topicText.length > 0) {
      setCreated(``);
    }
  }, [topicText, prevTopic, created]);

  const handleGoBack = useCallback(() => {
    navigation.goBack();
    setCreated(``);
    setError(``);
  }, [navigation, setCreated, setError]);

  const handleCreateTopic = useCallback(async () => {
    if (!group || !topicText) return;
    try {
      PendoTrackEvent(Events.CREATE_TOPIC, {
        source: `CreateTopics`,
        community_name: group?.name,
        community_role: role_name,
        topic_name: topicText,
      });
      await createTopic(group?.id, topicText);
      setPrevTopic(topicText);
      dispatch(setForceRefreshTopicLists(true));
      setTimeout(() => {
        setTopicText(``);
        setCreated(`New Topic is created.`);
      }, 100);
    } catch (error: any) {
      setError(error);
      console.error(error);
    }
  }, [group, topicText, role_name, createTopic, dispatch]);

  const handleUpdateTopic = useCallback(async () => {
    if (!currentTopic?.topic) return;
    try {
      await updateTopic(currentTopic?.id, {
        topic: topicText,
      });
      setPrevTopic(topicText);
      dispatch(setForceRefreshTopicLists(true));
      setTimeout(() => {
        setTopicText(``);
        setCreated(`Topic is updated.`);
      }, 100);
    } catch (error) {
      console.error(error);
    }
  }, [currentTopic?.topic, currentTopic?.id, updateTopic, topicText, dispatch]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `${currentTopic ? `Edit` : `Create`} topic`,
      headerLeft: () => <BackButton onPress={handleGoBack} />,
    });
  }, [navigation, currentTopic, handleGoBack]);

  let err: any;
  if (error && !created.length) err = error;
  if (!error && created.length) err = null;
  return (
    <KeyboardPaddingView>
      <SafeAreaView style={styles.container}>
        <View>
          <TextInput
            autoFocus
            style={[styles.topicInputContainer, { borderColor: error.length ? Colors.alertColor : Colors.mediumLighterGray }]}
            placeholder="Enter topic"
            placeholderTextColor={Colors.textPlaceholder}
            value={topicText}
            onChangeText={setTopicText}
            maxLength={30}
          />
          <Text style={{ color: Colors.textGray, fontSize: 10, marginBottom: 5, fontWeight: `500` }}>
            {topicText.length}/30 characters used
          </Text>
          <Announcement error={err} message={created} />
        </View>
        <ThemedButton
          rounded
          title={currentTopic ? `Update` : `Save`}
          onPress={currentTopic ? handleUpdateTopic : handleCreateTopic}
          disabled={!topicText.length || creating || !isValid || loading}
          disabledStyle={{
            backgroundColor: !topicText.length || creating || !isValid ? Colors.taupe : Colors.brandPurple,
          }}
        />
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

export default CreateTopic;

type AnnouncementProps = {
  error?: string | null;
  message: string;
};

const Announcement: React.FC<AnnouncementProps> = ({ error, message }) => {
  if (typeof error === `string` || error === null)
    return (
      <View
        style={{
          backgroundColor: error?.length ? `${Colors.chatPreviewDeleteButton}50` : `${Colors.greenBackground}50`,
          borderRadius: 6,
          padding: 10,
        }}
      >
        <Text style={{ color: error?.length ? Colors.alertColor : Colors.brandGreen, fontSize: 12, fontWeight: `bold` }}>
          {error?.length ? error : message}
        </Text>
      </View>
    );

  return null;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    justifyContent: `space-between`,
  },
  topicInputContainer: {
    flexDirection: `row`,
    borderRadius: 6,
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 10,

    marginBottom: 8,
  },
});
