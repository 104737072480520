import React, { useCallback, forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { Dimensions, Keyboard, ScrollView, useWindowDimensions, View } from 'react-native';
import { captureRef } from 'react-native-view-shot';
import MemeText, { IMoveStyle } from '../Meme/MemeText';
import useMediaPermissons from '../../hooks/useMediaPermissions';
import { pickOrderedImages } from '../../common-util/FilePicker';
import { BlobPurple, MeshIcon, SafeAreaView } from '../../common-ui';
import { Colors } from '../../common-styles';
import MemeActionButtonContainer from '../Meme/MemeActionButtonContainer';
import MemeLayoutItem from '../Meme/MemeLayoutItem';
import MemeStyleEditor from '../Meme/MemeStyleEditor';

export type ITexterType = `align` | `size` | `color` | `left` | `top`;

export type ITexter = {
  fontSize: number;
  color: string;
  aligned: boolean;
  left: number;
  top: number;
  transform: any;
};

const defaultTexter: ITexter = {
  fontSize: 48,
  color: `black`,
  aligned: true,
  left: 0,
  top: 150,
  transform: [{ rotate: '0deg' }, { scale: 1 }],
};

export type IActionType = `layout` | `image` | `text` | `background`;

export type IBlob = {
  blobIndex: number;
  isBlob: boolean;
  background: any;
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const PostContentMeme = forwardRef((props: any, ref: any) => {
  const viewshotRef = useRef<any>();

  const { checkPermission } = useMediaPermissons();
  const [texter, setTexter] = useState<ITexter[]>([]);
  const [focusedTexterIndex, setFocusTexterIndex] = useState(-1);
  const [orderedImgs, setOrderedImgs] = useState<any>([]);
  const { width, height } = useWindowDimensions();
  const [layout, setLayout] = useState(1);
  const [blobBG, setBlobBG] = useState<IBlob>({
    blobIndex: 0,
    isBlob: true,
    background: BlobPurple,
  });

  useEffect(() => {
    if (!!orderedImgs.length || !!texter.length) {
      props?.onMemePostDisable(false);
    } else {
      props?.onMemePostDisable(true);
    }
  }, [orderedImgs, texter]);

  const [actionType, setActionType] = useState<IActionType>();

  const [showTexterEditor, setShowTexterEditor] = useState(false);

  useImperativeHandle(ref, () => ({
    async onCapture() {
      setFocusTexterIndex(-1);
      setActionType(`layout`);
      return delay(500).then(async () => {
        return await captureRef(viewshotRef, { snapshotContentContainer: true }).then();
      });
    },
  }));

  const handleChangeTexter = useCallback(
    (type: ITexterType, isFontSizeUp?: boolean, color?: string, isTextAligned?: boolean) => {
      if (focusedTexterIndex > -1) {
        const items = [...texter];
        let item = { ...texter[focusedTexterIndex] };
        let defaultSize = texter[focusedTexterIndex].fontSize;
        if (type === `size` && isFontSizeUp !== undefined) {
          if (isFontSizeUp) defaultSize++;
          else defaultSize--;
          item = { ...texter[focusedTexterIndex], fontSize: defaultSize };
        }

        if (type === `color` && color !== undefined) {
          item = { ...texter[focusedTexterIndex], color: color };
        }

        if (type === `align` && isTextAligned !== undefined) {
          item = { ...texter[focusedTexterIndex], aligned: isTextAligned };
        }

        items[focusedTexterIndex] = item;
        setTexter(items);
      }
    },
    [texter, focusedTexterIndex, setTexter],
  );

  const handleChangeTextPosition = useCallback(
    (ele: number, style: IMoveStyle) => {
      const items = [...texter];
      let item = { ...texter[ele] };
      item = { ...texter[ele], left: style.left, top: style.top, transform: style.transform };
      items[ele] = item;
      setTexter(items);
    },
    [setTexter, texter],
  );

  const handleActionLayout = useCallback(() => {
    Keyboard.dismiss();
    setFocusTexterIndex(-1);
    setActionType(`layout`);
    if (layout === 4) setLayout(1);
    else setLayout((lo) => lo + 1);
  }, [layout, setActionType, setFocusTexterIndex]);

  const getPhotos = async () => {
    try {
      const isGranted = checkPermission();
      if (!isGranted) return;

      const { assets, rawAssets }: any = await pickOrderedImages(orderedImgs, 4);
      setOrderedImgs(rawAssets);
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionImage = useCallback(async () => {
    setFocusTexterIndex(-1);
    setActionType(`image`);
    await getPhotos();
  }, [getPhotos, setActionType, setFocusTexterIndex]);

  const handleActionText = useCallback(() => {
    setActionType(`text`);
    setShowTexterEditor(false);
    setTexter([...texter, defaultTexter]);
    setFocusTexterIndex(texter.length);
  }, [setTexter, texter, defaultTexter, setFocusTexterIndex]);

  const handleActionBackground = useCallback(() => {
    Keyboard.dismiss();
    setFocusTexterIndex(-1);
    setActionType(`background`);
  }, [setActionType, setFocusTexterIndex]);

  let BlobWithSizeAndPosition;
  const blobHeight = width;
  const blobWidth = width;

  const style = {
    backgroundColor: Colors.white,
    position: `absolute`,
    flex: 1,
    width,
    height: width,
    zIndex: -1,
    paddingLeft: width - blobWidth * 0.75,
    paddingTop: height * 0.09,
    opacity: actionType === `text` && focusedTexterIndex > -1 ? 0.9 : 1,
  };
  if (blobBG?.isBlob) {
    const Blob = blobBG.background as any;
    BlobWithSizeAndPosition = (
      <View style={style as any}>
        <Blob width={blobWidth * 0.5} height={blobHeight * 0.5} style={{ backgroundColor: Colors.white }} />
      </View>
    );
  } else {
    BlobWithSizeAndPosition = (
      <View
        style={{
          width,
          height: width,
          backgroundColor: `${blobBG?.background}${actionType === `text` && focusedTexterIndex > -1 ? '99' : ''}`,
          position: `absolute`,
          zIndex: -1,
        }}
      />
    );
  }

  const BlobBackGround = blobBG.background as any;
  const isBlob = blobBG?.blobIndex < 6;
  return (
    <SafeAreaView style={{ flex: 1, position: `relative`, display: `flex`, flexDirection: `column` }}>
      <View style={{ position: `absolute`, bottom: `40%`, right: 10, zIndex: 9998 }}>
        <MemeActionButtonContainer onPress={handleActionLayout}>
          <MeshIcon name="grid" size={22} color={Colors.deepPurple} />
        </MemeActionButtonContainer>
        <MemeActionButtonContainer onPress={handleActionImage}>
          <MeshIcon name="camera" size={22} color={Colors.deepPurple} />
        </MemeActionButtonContainer>

        <MemeActionButtonContainer onPress={handleActionText} selected={actionType === `text` && focusedTexterIndex > -1}>
          <MeshIcon name="create2" size={22} color={Colors.deepPurple} />
        </MemeActionButtonContainer>

        <MemeActionButtonContainer onPress={handleActionBackground} isBlob={isBlob} selected={actionType === `background`}>
          {isBlob ? (
            <BlobBackGround width={22} height={22} />
          ) : (
            <View
              style={{
                borderRadius: 6,
                width: 20,
                height: 20,
                backgroundColor: blobBG.background,
                borderColor: blobBG.background,
                borderWidth: 1,
              }}
            />
          )}
        </MemeActionButtonContainer>
      </View>
      <ScrollView contentContainerStyle={{ width, height: width, flex: 1 }} ref={viewshotRef} keyboardShouldPersistTaps="handled">
        <View style={{ flex: 1 }} collapsable={false}>
          {BlobWithSizeAndPosition}

          {texter.length > 0 &&
            texter.map((ele: ITexter, idx: number) => (
              <MemeText
                key={idx}
                index={idx}
                focusedTexterIndex={focusedTexterIndex}
                onSelected={(el) => setFocusTexterIndex(el)}
                onMove={handleChangeTextPosition}
                texter={ele}
                handleEditor={(val) => setShowTexterEditor(val)}
                showEditor={showTexterEditor}
                onSetTypeText={() => setActionType(`text`)}
              />
            ))}
          <MemeLayoutItem
            layout={layout}
            images={orderedImgs}
            isText={actionType === `text` && focusedTexterIndex > -1}
            hidden={orderedImgs?.length > 1}
          />
        </View>
      </ScrollView>
      <View style={{ position: `absolute`, top: width - 50, zIndex: 999 }}>
        <MemeStyleEditor
          type={actionType}
          blobIndex={blobBG?.blobIndex || 0}
          onSetBG={setBlobBG}
          texter={texter}
          onChangeTexter={handleChangeTexter}
          focusedTexterIndex={focusedTexterIndex}
          showTexterEditor={showTexterEditor}
        />
      </View>
    </SafeAreaView>
  );
});

export default PostContentMeme;
