import * as React from 'react';
import Svg, { Defs, Rect, Path, G, Text, TSpan, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreArchitecture = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Rect id="a" x={0} y={0} width={163} height={98} rx={7} />
        <Path d="M146.183 10.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 00153 16.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 00146.471 10l-.288.006z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <Path
          d="M145.647 52.904c3.613 10.362-4.033 21.953-16.603 29.284-12.517 7.372-29.905 10.444-38.861 5.16-8.956-5.242-9.48-18.84-4.452-30.717 5.08-11.878 15.712-22.035 28.544-22.609 12.779-.532 27.705 8.56 31.372 18.882z"
          fill="#2C9A44"
          fillRule="nonzero"
          transform="rotate(170 114.545 62)"
        />
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#2C9A44">
          <TSpan x={12} y={22}>
            Architecture
          </TSpan>
          <TSpan x={12} y={41}>
            and Design
          </TSpan>
        </Text>
        <G>
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Use fillOpacity={0.1} fill="#2C9A44" xlinkHref="#a" />
          <G fillRule="nonzero" mask="url(#b)">
            <G transform="translate(104 34) translate(0 .143) translate(0 .253)">
              <Path fill="#A39FFF" d="M0 27.3552398L4.98741855 23.3520339 4.98741855 64.0512931 0 64.0512931z" />
              <Path fill="#A39FFF" d="M4.71413534 7.0129153L14.5523308 0 14.5523308 64.0512931 4.71413534 64.0512931z" />
              <Path fill="#7364FF" d="M14.5523308 0L23.8439599 0 23.8439599 64.0512931 14.5523308 64.0512931z" />
              <Path fill="#CECCFF" d="M1.43473684 55.6445609H13.459198039999999V64.05129312H1.43473684z" />
              <Path fill="#5628D2" d="M5.80726817 59.180726H9.08666667V64.05129308H5.80726817z" />
              <Path
                fill="#5628D2"
                d="M5.80726817 7.90112245L9.08666667 5.33760776 9.08666667 54.4435991 5.80726817 54.4435991z"
              />
              <Path fill="#5628D2" d="M10.1797995 4.4976828L13.459198 1.93488281 13.459198 54.4435991 10.1797995 54.4435991z" />
              <Path fill="#370EA3" d="M16.3969925 23.8857947H22.40922308V36.6960533H16.3969925z" />
              <Path fill="#370EA3" d="M16.3969925 48.1051899H22.40922308V60.9154485H16.3969925z" />
              <Path fill="#370EA3" d="M16.3969925 15.812663H22.40922308V20.54978989H16.3969925z" />
              <Path fill="#370EA3" d="M16.3969925 1.93488281H22.40922308V12.40993801H16.3969925z" />
              <Path
                fill="#CECCFF"
                d="M1.43473684 28.3560412L4.71413534 25.6872373 4.71413534 54.4435991 1.43473684 54.4435991z"
              />
              <Path fill="#FFC30D" d="M16.3969925 40.0320582H22.40922308V44.76918509H16.3969925z" />
            </G>
            <G transform="translate(104 34) translate(0 .143) translate(23.537 22.955)">
              <Rect fill="#A39FFF" x={2.24086687} y={3.40133551} width={1.35810114} height={4.00157119} rx={0.679050568} />
              <Rect fill="#A39FFF" x={4.14220846} y={1.80070704} width={1.35810114} height={4.00157119} rx={0.679050568} />
              <Path fill="#7364FF" d="M0 7.46959956L14.1242518 0 14.1242518 41.349569 0 41.349569z" />
              <Path fill="#5628D2" d="M14.1242518 0H22.81609907V41.349569H14.1242518z" />
              <Path d="M7.062 36.014c.9 0 1.63.717 1.63 1.6v3.736h-3.26v-3.735c0-.884.73-1.6 1.63-1.6z" fill="#5628D2" />
              <Rect fill="#370EA3" x={1.08648091} y={10.4040851} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#FFC30D" x={1.08648091} y={23.2091129} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={1.08648091} y={16.806599} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={5.43240454} y={10.4040851} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={5.43240454} y={23.2091129} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={5.43240454} y={16.806599} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#FFC30D" x={9.77832817} y={10.4040851} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={9.77832817} y={23.2091129} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={9.77832817} y={16.806599} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#370EA3" x={15.7539732} y={10.4040851} width={5.43240454} height={3.73479978} rx={1.86739989} />
              <Rect fill="#370EA3" x={15.7539732} y={23.2091129} width={5.43240454} height={3.73479978} rx={1.86739989} />
              <Rect fill="#370EA3" x={15.7539732} y={2.66771413} width={5.43240454} height={5.06865684} rx={2.53432842} />
              <Rect fill="#370EA3" x={15.7539732} y={16.806599} width={5.43240454} height={3.73479978} rx={1.86739989} />
              <Rect fill="#5628D2" x={1.08648091} y={29.6116268} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={1.08648091} y={36.0141407} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={5.43240454} y={29.6116268} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#370EA3" x={9.77832817} y={29.6116268} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#5628D2" x={9.77832817} y={36.0141407} width={3.25944272} height={3.73479978} rx={1.62972136} />
              <Rect fill="#370EA3" x={15.7539732} y={29.6116268} width={5.43240454} height={3.73479978} rx={1.86739989} />
              <Rect fill="#370EA3" x={15.7539732} y={36.0141407} width={5.43240454} height={3.73479978} rx={1.86739989} />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};
