import React, { useCallback, useMemo, useState } from 'react';
import { isArray } from 'lodash';
import { PropTypes } from 'prop-types';
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';
import { useFocusEffect } from '@react-navigation/native';
import { MODERATION_ACTIONS, MODERATION_CATEGORY, MODERATION_TYPE, ACTION_VERB_MAP } from './moderationEnums';
import { Colors, Typography } from '../common-styles';
import { Avatar, Divider, ErrorBoundary, NoThemeButton, ListItem, ThemedButton, MeshIcon } from '../common-ui';
import { commonPropTypes, formatRelativeDate } from '../common-util';
import TopicPill from '../common-ui/TopicPill';
import { TopicStatus } from '../common-types/types';
import { VideoPlayer } from '../post/components/VideoPlayer';
import { getReadablePath } from '../common-util/FilePicker';
import PostPolls from '../post/components/PostPolls';
import { MediaTypes } from '../post/helpers';

const OPEN_STATUS = `open`;
const CLOSED_STATUS = `closed`;

const getOriginalActions = (modIssue) => {
  const { action_taken, actions, status } = modIssue;
  if (status !== `closed`) return [MODERATION_ACTIONS.new_issue, MODERATION_ACTIONS.new_issue];
  if (isArray(actions) && actions.length) {
    const copy_actions = [...actions];
    const user_actions = copy_actions.filter((action) => action.type === MODERATION_TYPE.user).reverse();
    const last_user_action = user_actions.pop();
    const content_action = copy_actions.filter((action) => action.type !== MODERATION_TYPE.user).reverse();
    const last_content_action = content_action.pop();
    return [last_content_action ? last_content_action.action : null, last_user_action ? last_user_action.action : null];
  }
  return [action_taken, action_taken]; //theres no way to know with old actions
};

const renderTrailingString = (type, last_actions) => {
  if (type === MODERATION_TYPE.community) return `community.`;
  if (last_actions[0] === MODERATION_ACTIONS.content_report_deny) return `report `;
  if (last_actions[0] === null && last_actions[1] === MODERATION_ACTIONS.user_report_deny) return `report `;
  if (type === MODERATION_TYPE.verification) return `Mesh verification request.`;
  /* old actions compatibility */
  if (type === MODERATION_TYPE.community_join_request) return `request to join.`;
  if (last_actions[0] === MODERATION_ACTIONS.deny_report) return `report `;
  //if (type === MODERATION_TYPE.post && !!last_actions[1] && !last_actions[1].includes(`user`)) return `${type} `;
  if (type === MODERATION_TYPE.comment || type === MODERATION_TYPE.post) {
    // type is not user but no content action taken or is user related
    if (!!last_actions[0] && last_actions[0].includes(`user`)) return ``;
  }
  return `${type} `;
};

const renderReasons = (type, reasons, last_actions) => {
  if (!reasons || reasons.length < 1) return null;
  let prefix = ``;
  if (!!last_actions[1] && last_actions[1].includes(`user`)) {
    prefix = `for `;
  } else if (type !== MODERATION_TYPE.application) {
    prefix = `citing `;
  }
  if (
    type !== MODERATION_TYPE.verification &&
    type !== MODERATION_TYPE.application &&
    type !== MODERATION_TYPE.community &&
    type !== MODERATION_TYPE.community_join_request
  ) {
    let reasonText = reasons[0]?.label;
    if (!reasons[0]?.label) {
      reasonText = `Other`;
    }
    return (
      <>
        <Text>{prefix}</Text>
        <Text style={{ ...Typography.text(`base`, `red`, `bold`) }}>{reasonText}</Text>
      </>
    );
  }
  return null;
};

const renderAffected = (modIssue, group, last_actions) => {
  const { type, reportee, reporter } = modIssue;
  if (type === MODERATION_TYPE.community) return ` ${(group && group.name.trim()) || `[deleted community]`}`;
  if ([MODERATION_ACTIONS.content_report_deny, MODERATION_ACTIONS.user_report_deny].includes(last_actions[0])) {
    return ` ${(reporter && reporter.handle.trim()) || `[deleted user]`}`;
  }
  return ` ${(reportee && reportee.handle.trim()) || `[deleted user]`}`;
};

export const getIssueTitle = (issue) => {
  const { reportee, reporter, category, type, origin_group, reasons } = issue;
  const reporteeText = (reportee && reportee.handle.trim()) || `[deleted user]`;
  const reporterText = (reporter && reporter.handle.trim()) || `[deleted user]`;
  const originGroupText = (origin_group && origin_group.name.trim()) || `[deleted community]`;
  let message = ``;
  let first = null;
  let second = null;
  let third = null;
  switch (category) {
    case MODERATION_CATEGORY.post_approve: {
      message = `New post for approval from `;
      second = reporteeText;
      break;
    }
    case MODERATION_CATEGORY.abuse: {
      first = reporterText;
      message = ` reported `;
      second = reporteeText;
      third = `'s ${type === `profile_feed` ? `personal post` : type} citing `;
      break;
    }
    case MODERATION_CATEGORY.user_report: {
      first = reporterText;
      message = ` reported `;
      second = reporteeText;
      break;
    }
    case MODERATION_CATEGORY.community_application: {
      first = reporteeText;
      message = ` applied to join your community`;
      break;
    }
    case MODERATION_CATEGORY.community_join_request: {
      first = reporteeText;
      message = ` has requested to join your community`;
      break;
    }
    case MODERATION_CATEGORY.community_report: {
      first = reporterText;
      message = ` reported `;
      second = originGroupText;
      third = ` for `;
      break;
    }
    case MODERATION_CATEGORY.mesh_verification: {
      first = reporteeText;
      message = ` applied for `;
      second = `Mesh Verification`;
      break;
    }
    default:
      break;
  }
  return (
    <Text>
      <Text style={Typography.text(`bold`)}>{first}</Text>
      {message}
      <Text style={Typography.text(`bold`)}>{second}</Text>
      {third && (
        <>
          <Text>{third}</Text>
          <Text style={Typography.text(`bold`, `red`)}>{reasons.map((reason) => reason?.label).join(`,`)}</Text>
        </>
      )}
    </Text>
  );
};

const getCurrentActionVerb = (type, last_actions) => {
  const [contentAction, userAction] = last_actions;
  const isUserAction = type === MODERATION_TYPE.user;
  const currentActionVerb = ACTION_VERB_MAP[isUserAction ? userAction : contentAction];
  if (type === MODERATION_TYPE.community && (currentActionVerb === `denied` || currentActionVerb === `ignored`)) {
    return `${currentActionVerb} report on`;
  }
  return currentActionVerb;
};

export const getClosedIssueTitle = (modIssue, group) => {
  const { type, reasons, moderator } = modIssue;
  const moderatorText = (moderator && moderator.handle.trim()) || `[deleted user]`;
  const last_actions = getOriginalActions(modIssue);
  const currentActionVerb = getCurrentActionVerb(type, last_actions);
  const nonPossesiveCategories = [MODERATION_TYPE.community, MODERATION_TYPE.user];
  const possesive = nonPossesiveCategories.includes(type) ? `` : `'s`;
  return (
    <Text style={{ flex: 1, flexWrap: `wrap`, ...Typography.text(`base`, `black`) }}>
      <Text style={{ ...Typography.text(`bold`, `base`, `black`) }}>{moderatorText}</Text>
      <Text> {currentActionVerb ?? `closed`}</Text>
      <Text style={{ ...Typography.text(`bold`, `base`, `black`) }}>
        {renderAffected(modIssue, group, last_actions)}
        {possesive}
      </Text>
      <Text> {renderTrailingString(type, last_actions)}</Text>
      {renderReasons(type, reasons, last_actions)}
    </Text>
  );
};

export const ModerationIssue = React.memo(({ navigation, group, modIssue, queryInFlight, actions, approvalIndex }) => {
  const { id, category, status, activity } = modIssue;
  const isOpen = status === `UNDEF`;
  const [approve, reject] = actions;
  return (
    <ErrorBoundary header="Error showing moderation issue">
      <TouchableOpacity activeOpacity={0.8}>
        <ModerationIssue.Header withDate navigation={navigation} group={group} modIssue={modIssue} />
        {isOpen &&
          (category === MODERATION_CATEGORY.post_approve ||
            (category === MODERATION_CATEGORY.abuse &&
              (activity?.imageUrls?.length > 0 || activity?.video_urls?.length > 0))) && (
            <>
              <ModerationIssue.Activity navigation={navigation} group={group} modIssue={modIssue} />
              {category === MODERATION_CATEGORY.post_approve && (
                <ModerationIssue.Actions
                  navigation={navigation}
                  group={group}
                  modIssue={modIssue}
                  action={approve}
                  queryInFlight={queryInFlight}
                  approvalIndex={approvalIndex}
                />
              )}
            </>
          )}
        {isOpen && category === MODERATION_CATEGORY.community_join_request && (
          <View style={{ flexDirection: `row`, justifyContent: `space-between`, marginTop: 8, padding: 16 }}>
            <NoThemeButton
              rounded
              color="rgba(234,35,35,0.15)"
              titleStyle={Typography.text(`bold`, `plusone`, `red`)}
              containerStyle={{ flex: 1, paddingHorizontal: 4 }}
              title="Deny"
              onPress={() => reject({ variables: { issue_id: id } })}
              disabled={queryInFlight}
            />
            <ThemedButton
              rounded
              clear
              outline
              containerStyle={{ flex: 1, paddingHorizontal: 4 }}
              title="Approve"
              onPress={() => approve({ variables: { issue_id: id } })}
              disabled={queryInFlight}
            />
          </View>
        )}
        <Divider />
      </TouchableOpacity>
    </ErrorBoundary>
  );
});

ModerationIssue.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  group: commonPropTypes.group().isRequired,
  modIssue: commonPropTypes.issue().isRequired,
  queryInFlight: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.func).isRequired,
  approvalIndex: PropTypes.number.isRequired,
};

ModerationIssue.Header = React.memo(({ navigation, group, modIssue }) => {
  const { type, application, moderator, category, reportee, status, created_at, origin_group, reporter } = modIssue;
  const isOpen = status === `UNDEF`;
  // const withDate = new Set([
  //   MODERATION_CATEGORY.community_application,
  //   MODERATION_CATEGORY.mesh_verification,
  //   MODERATION_CATEGORY.post_approve,
  // ]);
  const title = isOpen ? getIssueTitle(modIssue, origin_group || group) : getClosedIssueTitle(modIssue, origin_group || group);
  const goToReportDetail = () => {
    if (type !== MODERATION_TYPE.application && type !== MODERATION_TYPE.community_join_request) {
      if (!type && type !== MODERATION_TYPE.community) console.warn(`Issue with no activity loaded yet`);
      navigation.push(`ReportDetail`, { moderationIssue: modIssue, status: status === `UNDEF` ? `open` : `closed`, group });
      return;
    }
    if (type === MODERATION_TYPE.application || type === MODERATION_TYPE.verification) {
      navigation.push(`ApplicationReview`, {
        moderationIssue: modIssue,
        status: status === `UNDEF` ? `open` : `closed`,
        application,
        user: reportee,
        group,
      });
      return;
    }
    navigation.push(`ReportDetail`, { moderationIssue: modIssue, status: isOpen ? OPEN_STATUS : CLOSED_STATUS });
  };

  const goToMeshVerification = () =>
    navigation.push(`MeshVerificationReview`, { moderationIssue: modIssue, status: isOpen ? OPEN_STATUS : CLOSED_STATUS });

  const pushToApplicationReview = () => {
    console.log(`navigating to application review...`);
    navigation.push(`ApplicationReview`, {
      application: modIssue.application,
      group,
      moderationIssue: modIssue,
      user: reportee,
      status: isOpen ? OPEN_STATUS : CLOSED_STATUS,
    });
  };

  let rightElement = null;
  if (category === MODERATION_CATEGORY.community_application) {
    rightElement = pushToApplicationReview;
  }
  if (category === MODERATION_CATEGORY.mesh_verification) {
    rightElement = goToMeshVerification;
  }

  return (
    <ListItem
      leftAvatar={<Avatar navigation={navigation} user={isOpen ? reporter : moderator} size={40} />}
      title={title}
      // subtitle={isOpen && withDate.has(category) ? formatRelativeDate(created_at) : null}  //comment due to APP-4096
      subtitle={formatRelativeDate(created_at)}
      titleStyle={{ fontFamily: `inter-regular`, fontSize: Typography.baseFontSize, color: Colors.textBlack }}
      subtitleStyle={{ ...Typography.smallText, color: Colors.textGray }}
      onPress={goToReportDetail}
      rightElement={isOpen && rightElement ? <ThemedButton clear outline rounded title="Review" onPress={rightElement} /> : null}
    />
  );
});

ModerationIssue.Header.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  group: commonPropTypes.group().isRequired,
  modIssue: commonPropTypes.issue().isRequired,
};

ModerationIssue.Activity = React.memo(({ navigation, group, modIssue }) => {
  const { activity, reportee } = modIssue;
  //TODO: enable topic edition
  const { imageUrls, _topics, video_urls } = activity;
  const filteredTopics = useMemo(() => {
    if (activity?.topics?.length)
      return activity?.topics?.filter((tp) => tp.topic.toLocaleLowerCase() !== `general` && tp.status === TopicStatus.ACTIVE);
    return [];
  }, [activity]);

  return (
    <View style={localStyles.activityContainer}>
      <View style={localStyles.activityContentContainer}>
        <Avatar navigation={navigation} user={reportee} size={32} />
        <View style={localStyles.contentTextContainer}>
          <Text style={localStyles.activityContentText}>
            <Text style={{ ...Typography.text(`small`, `bold`, `black`), flex: 1, flexWrap: `wrap` }}>
              {(reportee && reportee.handle) || `[deleted user]`}
            </Text>
            <Text style={{ ...Typography.smallText }}> posted to </Text>
            <Text style={{ ...Typography.smallText, fontFamily: `inter-semibold`, fontWeight: `500` }}>{group.name}</Text>
          </Text>
          <Text style={{ ...Typography.smallText, color: Colors.textGray }}>{formatRelativeDate(activity.time)}</Text>
        </View>
      </View>
      <View style={{ marginTop: filteredTopics.length > 0 ? 6 : 10 }}>
        {filteredTopics.length > 0 && (
          <View style={{ flexDirection: `row`, paddingHorizontal: 12, paddingVertical: 4 }}>
            {filteredTopics.map((topic) => (
              <TopicPill key={topic.id} topic={topic} onPress={() => {}} groupName={group?.name} />
            ))}
          </View>
        )}
        {imageUrls?.length > 0 && <ModerationImagePreview images={imageUrls} />}
        {video_urls?.length > 0 && <ModerationVideoPreview videos={video_urls} />}

        <Text style={{ ...Typography.smallText, color: Colors.textGray, marginHorizontal: 10 }}>{activity.content}</Text>

        {activity?.media_type === MediaTypes.POLL && (
          <>
            <Text style={{ paddingHorizontal: 10, fontSize: 16, fontWeight: `600` }}>{activity?.title}</Text>
            <PostPolls activity={activity} action={false} />
          </>
        )}
      </View>
    </View>
  );
});

ModerationIssue.Activity.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  group: commonPropTypes.group().isRequired,
  modIssue: commonPropTypes.issue().isRequired,
};

ModerationIssue.Actions = React.memo(({ navigation, group, modIssue, queryInFlight, action, approvalIndex }) => {
  const [index, setIndex] = useState(-1);
  const { id, status, reportee, activity } = modIssue;
  const isOpen = status === `UNDEF`;
  //console.log(`ModerationIssue.Actions:`, { action, type: typeof action });
  const primaryBtnBackground = queryInFlight && index === approvalIndex ? Colors.taupe : `#E8E9FF`;
  const primaryBtnTextColor = queryInFlight && index === approvalIndex ? Colors.white : Colors.brandPurple;

  const handleApprove = () => {
    setIndex(approvalIndex);
    action({ variables: { input: { issue_id: id, group_id: group.id } } });
    setTimeout(() => {
      setIndex(-1);
    }, 1000);
  };

  const goToPostPreview = () =>
    navigation.push(`PostApprovalReview`, {
      moderationIssue: modIssue,
      status: isOpen ? OPEN_STATUS : CLOSED_STATUS,
      user: reportee,
      group,
    });

  const goToEditTopics = () => {
    setTimeout(() => {
      navigation.navigate(`PostEditor`, { group, activity, mode: `edit`, editTopicByAdmin: true });
    }, 1000);
  };

  return (
    <View style={{ flexDirection: `row`, justifyContent: `flex-end`, alignItems: `center` }}>
      <TouchableOpacity activeOpacity={0.8} style={{ marginTop: 16, marginBottom: 8.5, marginRight: 8 }} onPress={goToEditTopics}>
        <View style={localStyles.takeActionButton}>
          <Text style={localStyles.takeActionButtonText}>Edit topics</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        activeOpacity={0.8}
        style={{ marginTop: 16, marginBottom: 8.5, marginRight: 8 }}
        onPress={goToPostPreview}
      >
        <View style={localStyles.takeActionButton}>
          <Text style={localStyles.takeActionButtonText}>View post</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        activeOpacity={0.8}
        style={{ marginTop: 16, marginBottom: 8.5, marginRight: 8 }}
        onPress={handleApprove}
        disabled={queryInFlight && index === approvalIndex}
      >
        <View style={{ ...localStyles.takeActionButton, backgroundColor: primaryBtnBackground, borderWidth: 0 }}>
          <Text style={{ ...localStyles.takeActionButtonText, color: primaryBtnTextColor }}>Approve</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
});

ModerationIssue.Actions.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  group: commonPropTypes.group().isRequired,
  modIssue: commonPropTypes.issue().isRequired,
  action: PropTypes.func.isRequired,
  queryInFlight: PropTypes.bool,
  approvalIndex: PropTypes.number,
};

ModerationIssue.Actions.defaultProps = {
  queryInFlight: false,
  approvalIndex: -1,
};

const ModerationImagePreview = ({ images }) => {
  if (images && images.length === 1) {
    return <Image source={{ uri: images[0] }} style={{ width: `100%`, height: 172, marginBottom: 10 }} resizeMode="cover" />;
  }

  if (images && images.length === 2) {
    return (
      <View style={{ flexDirection: `row` }}>
        <View style={{ flex: 1, marginRight: 4 }}>
          <Image source={{ uri: images[0] }} style={{ width: `100%`, height: 172, marginBottom: 10 }} resizeMode="cover" />
        </View>
        <View style={{ flex: 1 }}>
          <Image source={{ uri: images[1] }} style={{ width: `100%`, height: 172, marginBottom: 10 }} resizeMode="cover" />
        </View>
      </View>
    );
  }

  if (images && images.length > 2) {
    return (
      <View style={{ flexDirection: `row` }}>
        <View style={{ flex: 1, marginRight: 4 }}>
          <Image source={{ uri: images[0] }} style={{ width: `100%`, height: 172, marginBottom: 10 }} resizeMode="cover" />
        </View>
        <View style={{ flex: 1 }}>
          <View style={localStyles.imageCountOverlay}>
            <Text style={localStyles.imageCountText}>+{images.length - 2}</Text>
          </View>
          <Image source={{ uri: images[1] }} style={{ width: `100%`, height: 172, marginBottom: 10 }} resizeMode="cover" />
        </View>
      </View>
    );
  }

  return null;
};

ModerationImagePreview.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const ModerationVideoPreview = ({ videos }) => {
  const [showVideo, setShowVideo] = useState(false);
  const uri = useMemo(() => {
    if (Platform.OS === `android` && videos[1]?.type?.includes(`video`)) {
      return getReadablePath(videos[1]);
    }
    return videos[1]?.uri || videos[1];
  }, [videos]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (showVideo) setShowVideo(false);
      };
    }, [showVideo]),
  );

  if (showVideo) {
    return <VideoPlayer width="100%" height={172} marginBottom={10} video_url={videos[0]} onScreen={showVideo} />;
  }
  return (
    <View style={localStyles.contentContainer}>
      <FastImage style={{ width: `100%`, height: 172, marginBottom: 10 }} resizeMode="cover" source={{ uri }} />
      <View style={{ position: `absolute` }}>
        <MeshIcon
          name="play-circle"
          size={62}
          color={Colors.white}
          onPress={() => setShowVideo(true)}
          style={{ backgroundColor: Colors.black, borderRadius: 31, overflow: `hidden` }}
        />
      </View>
    </View>
  );
};

ModerationVideoPreview.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const localStyles = {
  contentTextContainer: {
    flex: 1,
    marginLeft: 8,
  },
  activityContainer: {
    marginLeft: 50,
    marginRight: 10,
    marginTop: 8,
    borderColor: Colors.lightGray,
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 10,
    paddingTop: 3,
    marginBottom: 8,
  },
  activityContentContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
  },
  activityContentText: {
    ...Typography.text(`small`),
    flex: 1,
    flexWrap: `wrap`,
  },
  takeActionButton: {
    borderRadius: 99,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    height: 32,
    width: 109,
    alignItems: `center`,
    justifyContent: `center`,
  },
  takeActionButtonText: {
    fontFamily: `inter-semibold`,
    fontSize: Typography.baseFontSize,
    color: Colors.brandPurple,
    fontWeight: `500`,
  },
  imageCountOverlay: {
    backgroundColor: Colors.translucentBlack,
    position: `absolute`,
    zIndex: 200,
    width: `100%`,
    height: 170,
    alignItems: `center`,
    justifyContent: `center`,
  },
  imageCountText: {
    ...Typography.text(`large`, `white`, `bold`, `center`),
  },
  openIssuesTagContainer: {
    position: `absolute`,
    right: 0,
    padding: 6,
    borderRadius: 99,
    alignItems: `center`,
    justifyContent: `center`,
  },
  contentContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
  },
};
