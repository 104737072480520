import Color from 'color';
import { Colors } from '../common-styles';

const themeLighter = (themeColor) => Color(themeColor).lighten(0.5).string();
const themeLight = (themeColor) => Color(themeColor).lighten(0.3).string();
const themeDark = (themeColor) => Color(themeColor).darken(0.25).string();
const themeDarker = (themeColor) => Color(themeColor).darken(0.5).string();
const themeTranslucentHighlight = (color) => Color(color).alpha(0.2).string();
const themeFontColor = (themeColor) => Color(themeColor).isLight(); // WCAG contrast ratio to determine if font should be light or dark

/** sample hexcode colors from colors.js file  */
// export const themeColor = '#F90A3A'; // red
// export const themeColor = '#28B900'; // green
// export const themeColor = '#186BDC'; // blue
// export const themeColor = '#FF489B'; // pink
// export const themeColor = '#FF942F'; // orange

export const COLOR_ARRAY = [
  { key: `MESH PURPLE`, hex: `#5d41c0` },
  { key: `MAASTRICHT BLUE`, hex: `#011627` },
  { key: `MAXIMUM BLUE GREEN`, hex: `#2EC4B6` },
  { key: `ROSE MADDER`, hex: `#CD5C5C` },
  { key: `BRIGHT YELLOW (CRAYOLA)`, hex: `#FFD972` },
  { key: `GRASS GREEN`, hex: `#239B56` },
  { key: `BLUE`, hex: `#377BD7` },
  { key: `DUSKY PINK`, hex: `#D6ABBE` },
  { key: `TANGERINE ORANGE`, hex: `#FFAE61` },
  { key: `ORIGINAL RED`, hex: `#F90A3A` },
  { key: `ORIGINAL GREEN`, hex: `#28B900` },
  { key: `ORIGINAL BLUE`, hex: `#186BDC` },
  { key: `ORIGINAL PINK`, hex: `#FF489B` },
  { key: `ORIGINAL ORANGE`, hex: `#FF942F` },
  { key: `MESH BLURPLE`, hex: Colors.brandPurple },
];

export const createThemes = (colorArray) => {
  let themes = [];
  if (Array.isArray(colorArray)) {
    themes = colorArray.map((color) => {
      const theme = {};
      theme.key = color.key;
      theme.primary = color.hex;
      theme.light = themeLight(color.hex);
      theme.lighter = themeLighter(color.hex);
      theme.dark = themeDark(color.hex);
      theme.darker = themeDarker(color.hex);
      theme.translucentHighlight = themeTranslucentHighlight(theme.lighter);
      theme.fontColor = themeFontColor(color.hex) ? `#1B1A1A` : `#FFFFFF`;

      return theme;
    });
  }
  return themes;
};

export const THEMES = createThemes(COLOR_ARRAY);
