import React, { useCallback, useMemo } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ErrorBoundary } from '../../common-ui';
import { classifyActivity, getTrimmedActivityText, MediaTypes } from '../helpers';
import PostContentVideo from './PostContentVideo';
import PostContentImages from './PostContentImages';
import PostContentGallery from './PostContentGallery';
import PostText from './PostText';
import PostContentLinkPreview from './PostContentLinkPreview';

type PostContentProps = {
  onScreen?: boolean;
  activity: any;
  width: number;
  textOverflowMode?: `fit` | `expand`;
  startExpanded?: boolean;
  selectable?: boolean;
  onLayout?: () => void;
  fromPersonalFeed?: boolean;
};

const areEqualPost = (prev: any, next: any) => {
  // So we check if the content is really new. To satisfy that condition we compare the values of the activity
  const areEqualActivityId = prev?.activity.id === next?.activity.id;
  const areEqualContent = prev?.activity.content === next?.activity.content;
  const areEqualImages = prev?.activity?.imageUrls?.length === next?.activity?.imageUrls?.length;
  const areEqualLinkPreview = prev?.activity?.linkPreview?.entered === next?.activity?.linkPreview?.entered;
  const areEqualUpdated = prev?.activity.updated_at === next?.activity?.updated_at;
  const areEqualStyle = prev?.activity?.meta_style === next?.activity?.meta_style;
  const areEqualFollowRequst = prev?.activity?.user?.follow_request?.accepted === next?.activity?.user?.follow_request?.accepted;
  const areEqualActivity =
    areEqualActivityId &&
    areEqualContent &&
    areEqualImages &&
    areEqualLinkPreview &&
    areEqualUpdated &&
    areEqualStyle &&
    areEqualFollowRequst;
  // onScreen is only for checked for video posts
  const type = classifyActivity(next?.activity);
  let areEqualOnScreenState = true;
  if (type !== MediaTypes.VIDEO) {
    areEqualOnScreenState = prev?.onScreen === next?.onScreen;
  }
  const isEqual = areEqualActivity && areEqualOnScreenState;
  return isEqual;
};

const PostContent: React.FC<PostContentProps> = React.memo(
  ({
    onScreen = true,
    activity,
    width,
    textOverflowMode = `fit`,
    startExpanded = false,
    onLayout = () => {},
    fromPersonalFeed = false,
  }) => {
    const route = useRoute();
    const navigation = useNavigation<any>();
    const { imageUrls, id, origin_group: group, video_urls, meta_style, linkPreview } = activity;
    const type = classifyActivity(activity);
    const excludedGallery = [`Explore`];

    const navigateToPost = useCallback(() => {
      navigation.navigate(`PostDetail`, {
        id,
        group_id: group?.id,
        isPersonalFeed: activity.__typename === 'ProfileActivity',
        fromPersonalFeed,
      });
    }, [navigation, group, id]);

    const textComponentProps = {
      activity,
      width,
      getTrimmedActivityText,
      textOverflowMode,
      startExpanded,
      meta_style,
      onLayout,
      navigateToPost,
    };

    const ContentBody = useMemo(() => {
      switch (type) {
        case `video`:
          return <PostContentVideo onScreen={true} width={width} video_urls={video_urls} onLayout={onLayout} />;
        case `image`:
          if (excludedGallery.includes(route?.name)) {
            return <PostContentImages width={width} imageUrls={imageUrls} onLayout={onLayout} />;
          }
          return <PostContentGallery width={width} imageUrls={imageUrls} onLayout={onLayout} onPress={navigateToPost} />;
        case `link`:
          return <PostContentLinkPreview linkPreview={linkPreview} textComponentProps={textComponentProps} />;
        case `text`:
        default:
          return (
            <PostText
              activity={activity}
              width={width}
              text={getTrimmedActivityText(activity)}
              overflowMode={textOverflowMode}
              startExpanded={startExpanded}
              selectable={false}
              meta_style={meta_style}
              onLayout={onLayout}
              onPress={navigateToPost}
              fromPersonalFeed={fromPersonalFeed}
            />
          );
      }
    }, [type, excludedGallery, route?.name, activity, onScreen]);

    if (type === MediaTypes.POLL) {
      return null;
    }

    if (type === `video`) {
      return (
        <View style={{ flex: 1, width }}>
          <ErrorBoundary header="Could not display post">{ContentBody}</ErrorBoundary>
        </View>
      );
    }

    return (
      <TouchableWithoutFeedback disabled={!navigation} onPress={navigateToPost} style={{ width }}>
        <View style={{ width }}>
          <ErrorBoundary header="Could not display post">{ContentBody}</ErrorBoundary>
        </View>
      </TouchableWithoutFeedback>
    );
  },
  areEqualPost,
);

export default PostContent;
