/* eslint-disable react/require-default-props */
import React from 'react';
import { View } from 'react-native';
// load custom font and icons
import PropTypes from 'prop-types';
import { Colors, Icons } from '../common-styles';
import { MeshIcon as MeshIconProps } from '../common-types/types';

export class MeshIcon extends React.PureComponent<MeshIconProps> {
  _root: any;

  static propTypes = {
    focused: PropTypes.bool,
    testID: PropTypes.string,
  };

  static defaultProps = {
    focused: false,
    testID: `ICON`,
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  setNativeProps(nativeProps: any) {
    this._root.setNativeProps(nativeProps);
  }

  render() {
    const { focused, testID, ...userProps } = this.props;
    const [IconClass, IconName] = Icons.getIconEntry(this);
    const defaultProps = {
      size: 26,
      style: { marginBottom: 0 },
      color: focused ? Colors.focusedIconColor : Colors.iconColor,
    };
    const overrideProps = {
      name: IconName,
    };
    return (
      <View ref={(component) => (this._root = component)} testID={testID}>
        <IconClass {...defaultProps} {...userProps} {...overrideProps} />
      </View>
    );
  }
}
