import React from 'react';
import { View, Text, ViewStyle, StyleProp, StyleSheet, TextStyle } from 'react-native';
import { Typography, Colors } from '../common-styles';

type BadgeProps = {
  value?: string | number;
  backgroundColor: string;
  textStyle: StyleProp<TextStyle>;
  padding?: number;
  badgeContainer: StyleProp<ViewStyle>;
};

export const Badge = React.memo<BadgeProps>(
  ({ value, backgroundColor = Colors.hotPink, textStyle, padding = 0, badgeContainer }) => {
    const badgeTextStyle = StyleSheet.flatten([Typography.text(`miniscule`, `white`, `center`, `bold`), textStyle]);

    badgeTextStyle.minWidth = badgeTextStyle.lineHeight || badgeTextStyle.fontSize;
    const bgColor = backgroundColor || Colors.hotPink;
    return (
      <View
        style={[
          {
            padding: padding || 1,
            borderRadius: 999,
            backgroundColor: bgColor,
            minWidth: 8,
            minHeight: 8,
            justifyContent: `center`,
            alignItems: `center`,
          },
          badgeContainer,
        ]}
      >
        {!!value && (
          <Text style={badgeTextStyle} maxFontSizeMultiplier={1.5}>
            {value}
          </Text>
        )}
      </View>
    );
  },
);

type WithBagdeHOC = {
  bottom?: number;
  left?: number;
  top?: number;
  right?: number;
  hidden?: boolean;
  textStyle?: StyleProp<TextStyle>;
  backgroundColor?: string;
};
export const withBadge =
  (value?: string | number, options: WithBagdeHOC = {}) =>
  (WrappedComponent: any) => {
    const WithBadge: React.FC<any> = (props) => {
      const { bottom, left, hidden = false, textStyle, backgroundColor = Colors.hotPink } = options;
      let { right = -8, top = -3 } = options;
      if (!value) {
        right = -3;
        top = 1;
      }
      return (
        <View>
          <WrappedComponent {...props} />
          {!hidden && (
            <Badge
              value={value}
              badgeContainer={{ position: `absolute`, top, bottom, left, right }}
              textStyle={textStyle}
              backgroundColor={backgroundColor}
            />
          )}
        </View>
      );
    };

    return WithBadge;
  };
