import React, { useContext } from 'react';
import { View } from 'react-native';
import { PendoSDK } from 'rn-pendo-sdk';
import { usePendoAccount } from './setup';
import { useSettings } from '../common-settings/hooks/useSettings';
import { AppContext } from '../../AppContext';
import { EditableSettings } from '../common-types/types';

export const PendoComponent = () => {
  const { user } = useContext(AppContext);
  const { fullSettings, loading } = useSettings(`user`, { user_id: user?.id });
  usePendoAccount();

  React.useEffect(() => {
    if (!!user?.id && !!fullSettings && !loading) {
      PendoSDK.setVisitorData({
        categories: getValue(fullSettings, `user.setting.interests`),
        payment_setup: getValue(fullSettings, `user.setting.customer_id`),
      });
    }
  }, [fullSettings, loading, user?.id]);

  return <View />;
};

const getValue = (settings: EditableSettings, key: string) => {
  const { value } = settings[key] || {};
  switch (key) {
    case `user.setting.interests`: {
      return JSON.parse(value?.toString() || `[]`);
    }
    case `user.setting.customer_id`:
      return !!value;
    default:
      return value;
  }
};
