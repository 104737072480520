import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Alert, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useMutation } from 'react-apollo';
import { Colors, Typography } from '../common-styles';
import { FindFriendsImage } from '../images/FindFriendsImage';
import { MeshIcon, ThemedButton, BackButton, AuthSubText, BlobBlue } from '../common-ui';
import { movePrev } from '../redux/createProfileSlice';
import { UPDATE_SETTING_MUTATION, client, refetchQueriesFor } from '../graphql';
import { AppContext } from '../../AppContext';
import { AuthNavigationContainer } from '../common-types/navigation-types';

type FindFriendsNavigator = StackNavigationProp<AuthNavigationContainer, `Persona`>;

export const FindFriendsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<FindFriendsNavigator>();
  const { user } = useContext(AppContext);

  const [checkbox, setCheckbox] = useState<Boolean>(true);

  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries: refetchQueriesFor(`Persona`, `Setting`),
  });

  const goBack = useCallback(() => {
    dispatch(movePrev());
    navigation.goBack();
  }, [dispatch, navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
  }, [navigation, goBack]);

  const handleSetSettings = useCallback(async () => {
    const emailInput = {
      key: `user.setting.find_by_email`,
      value: checkbox.toString(),
      user_id: user?.id,
    };
    const { data: findByEmailData } = await updateSetting({ variables: { input: emailInput } });
    console.log(`🚀 🚀  ->  ~ findByEmailData `, findByEmailData);

    const phoneInput = {
      key: `user.setting.find_by_phone`,
      value: checkbox.toString(),
      user_id: user?.id,
    };
    const { data: findByPhoneData } = await updateSetting({ variables: { input: phoneInput } });
    console.log(`🚀 🚀  ->  ~ findByPhoneData `, findByPhoneData);
  }, [checkbox, updateSetting, user]);

  const promptAllowContactSyncing = useCallback(() => {
    Alert.alert(
      `"mesh: communities" would like to access your contacts`,
      `Syncing your contacts allows you to easily find friends who are on mesh and invite those who aren't`,
      [
        { text: `Don't allow`, onPress: () => console.log(`canceled - access contacts`) },
        {
          text: `Allow`,
          onPress: async () => {
            await handleSetSettings();
            //TODO: implement Screen
            //navigation.navigate(`InviteFriends`);
          },
          style: `default`,
        },
      ],
      { cancelable: true },
    );
  }, [handleSetSettings]);

  return (
    <View style={styles.container}>
      <BlobBlue width={32} height={35} style={{ position: `absolute`, left: -7, top: -3, zIndex: -1 }} />

      <Text allowFontScaling={false} style={styles.headerText}>
        Find your friends on mesh
      </Text>

      <Text style={{ ...Typography.text(`small`), marginBottom: 32 }}>
        We'll use your contacts to help you find people you know and help them find you. We will never share or sell your data.
      </Text>

      <View style={styles.imageStyle}>
        <FindFriendsImage />
      </View>

      <View style={styles.hiddenTagContainer}>
        <View style={styles.hiddenTag}>
          <MeshIcon name="eye-hide" color={Colors.gray} size={16} />
          <Text style={styles.hiddenTagText1}>Hidden</Text>
        </View>
        <Text style={styles.hiddenTagText2}>Only you can see your contacts.</Text>
      </View>

      <ThemedButton
        rounded
        title="Find my friends"
        onPress={() => promptAllowContactSyncing()}
        buttonStyle={{ padding: 15 }}
        containerStyle={{ marginVertical: 0, marginTop: 32, marginBottom: 20 }}
        titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
      />

      <View style={styles.checkboxContainer}>
        <MeshIcon
          name={checkbox ? `checkbox-on` : `checkbox-off`}
          color={Colors.brandPurple}
          size={28}
          style={{ marginRight: 7 }}
          onPress={() => setCheckbox(!checkbox)}
        />
        <Text style={{ ...Typography.text(`small`) }}>
          Allow contacts to find me if they have my phone number or email address saved
        </Text>
      </View>

      <View style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={async () => {
            await handleSetSettings();
            navigation.navigate(`Persona`, { screen: `JoinCommunity` });
          }}
          style={{ marginTop: 13, display: `flex`, flexDirection: `row`, alignItems: `center` }}
        >
          <AuthSubText
            text="Skip for now"
            style={{
              color: Colors.brandPurple,
              fontWeight: Typography.mediumBoldFontWeight,
            }}
          />
          <MeshIcon name="arrow-right" size={18} color={Colors.themeColor} style={{ marginLeft: 5 }} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 19,
    marginTop: 16,
  },
  headerText: {
    ...Typography.text(`large`, `black`),
    marginBottom: 8,
  },
  hiddenTagContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 24,
  },
  hiddenTag: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderRadius: 99,
    alignItems: `center`,
    shadowColor: Colors.darkGray,
    backgroundColor: Colors.lightGray,
    padding: 6,
    paddingHorizontal: 12,
  },
  hiddenTagText1: {
    ...Typography.text(`small`, `gray`),
    marginLeft: 5,
  },
  hiddenTagText2: {
    ...Typography.text(`small`, `gray`),
    flexShrink: 1,
    flexWrap: `wrap`,
    marginLeft: 8,
  },
  checkboxContainer: {
    flexDirection: `row`,
    marginHorizontal: 28,
    marginBottom: 12,
  },
  imageStyle: {
    justifyContent: `center`,
    alignItems: `center`,
  },
});
