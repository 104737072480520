import { ScaledSize, useWindowDimensions } from 'react-native';
import { Spacing } from '../../common-styles';

type ResponsiveScaledSize = ScaledSize & {
  responsiveWidth: number;
};

/**
 * Extended useWindowDimensions with a responsive width based on iPad wrapper constrains
 * @returns ResponsiveScaledSize
 */

export const useResponsiveDimensions = (): ResponsiveScaledSize => {
  const { width, height, scale, fontScale } = useWindowDimensions();
  return {
    width,
    responsiveWidth: Math.min(Spacing.standardWidth, width),
    height,
    scale,
    fontScale,
  };
};
