import React, { useState, useRef, useContext } from 'react';
import { Platform, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useDebouncedCallback } from 'use-debounce/lib';
import { isPossibleNumber } from 'libphonenumber-js';
import { setProfileIdentity, setProfileUsername } from '../redux/createProfileSlice';
import * as Sentry from '../constants/Sentry';
import { getEnvVariables, getReleaseChannel } from '../../environment';
import { Colors, Spacing, Typography } from '../common-styles';
import { KeyboardPaddingView, LoadingIndicator, MeshIcon, PasswordInput, SafeAreaView, ThemedButton } from '../common-ui';
import { getAsyncStorageObject, setAsyncStorageObject } from '../common-util';
import { withTheme } from '../themes/ThemeProvider';
import { ContactInput } from './ContactInput';
import { cleanAndFormatContact, decodeSecureStoreAndSignIn, loginIdentity, validateAccount } from './helpers';
import { AppContext } from '../../AppContext';
import { SETTING_QUERY, client, UPDATE_SETTING_MUTATION, refetchQueriesFor } from '../graphql';
import { AuthNavigationContainer } from '../common-types/navigation-types';
import { Setting } from '../common-types/types';
import { useActiveCommunity } from '../community/hooks/useActiveCommunity';

type LoginNavigator = StackNavigationProp<AuthNavigationContainer, `Auth`>;

type SettingQuery = {
  data: {
    getSettings: Setting[];
  };
};

const APPLE_APP_STORE_ID = `ddd157c6-147f-4461-a3ed-09c9b47b4462`;
const hitSlop = { left: 10, right: 10, top: 10, bottom: 10 };

const _LoginScreen = () => {
  const navigation = useNavigation<LoginNavigator>();
  const dispatch = useDispatch();

  const [contact, setContact] = useState<string>();
  const [isValidContact, setIsValidContact] = useState(false);
  const [password, setPassword] = useState(``);
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { invite_token, setUser, setIdentity } = useContext(AppContext);
  const { fetchLatestCommunities } = useActiveCommunity(`LoginScreen->fetchLatest`);

  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    refetchQueries: refetchQueriesFor(`Persona`, `Setting`),
  });

  const passwordInput = useRef<TextInput>(null);

  const handleContactTextChange = useDebouncedCallback((text) => {
    try {
      const c = cleanAndFormatContact(text);
      setContact(c);
      setIsValidContact(true);
    } catch (e) {
      if (isPossibleNumber(text)) {
        setErrorMessage(`Invalid username. Please try again.`);
      } else {
        setErrorMessage(``);
      }
      setIsValidContact(false);
    }
  }, 500);

  const handlePasswordTextChange = (password: string) => {
    if (errorMessage) setErrorMessage(undefined);
    setPassword(password);
  };

  const finishLoginWithMessage = (message?: string) => {
    setLoggingIn(false);
    setErrorMessage(message);
  };

  const getTokens = async () => {
    const pushPermissionStatus = await getAsyncStorageObject(`pushPermissionStatus`);
    const push_token = pushPermissionStatus && pushPermissionStatus.token;
    return {
      push_token,
      invite_token,
    };
  };

  const logUserIn = async () => {
    try {
      if (!isValidContact || !contact) return;

      setLoggingIn(true);

      const cleaned = cleanAndFormatContact(contact);

      const checkedBeta = await validateAccount(cleaned);
      if (!checkedBeta) {
        // check beta returned null in this case - we don't have an account registered with this beta
        finishLoginWithMessage(`Username or password may be incorrect`);
        return;
      }

      const { id, has_password, has_personas } = checkedBeta;
      if (id && !has_password) {
        // user has registered with their number but didn't complete account creation / verification -- take to veification and then walk through create password => createprofile.jsx
        navigation.push(`Auth`, {
          screen: `CreatePassword`,
          params: {
            identity_id: id,
            handle: contact,
          },
        });
        return;
      }

      if (!id) {
        finishLoginWithMessage(`Username or password may be incorrect`);
        return;
      }

      const { push_token } = await getTokens();

      // Call LoginV2 to log in the identity.
      const loginResult = await loginIdentity(id, password, push_token, invite_token);
      const identity = {
        ...loginResult,
        loginTime: new Date(),
        env: getReleaseChannel() || `dev`,
      };
      setIdentity(identity);
      dispatch(setProfileIdentity(identity.id));
      dispatch(setProfileUsername(cleaned));
      await setAsyncStorageObject(`identity`, identity);

      if (!loginResult) {
        finishLoginWithMessage(`Username or password may be incorrect`);
        return;
      }

      if (id && has_password && !has_personas) {
        // in case persona creation process has been interrupted
        navigation.push(`Auth`, {
          screen: `RegisterEmail`,
          params: {
            identity_id: id,
            handle: contact,
          },
        });
        return;
      }
      // To proceed, user must verify their account.
      // if (!loginResult.is_verified) {
      //   setLoggingIn(false);
      //   navigation.push(`Verify`, { id, cleaned });
      //   return;
      // }

      // To proceed, user must also agree to terms & conditions
      if (loginResult.agreed_terms !== true || id === APPLE_APP_STORE_ID) {
        setLoggingIn(false);
        navigation.navigate(`Auth`, { screen: `TermsScreen`, params: { identity_id: id, prev_screen: `Login` } });
        return;
      }

      // // Do we have personas in our secure store?
      // if (!loginResult.secure_store) {
      //   // no personas connected with identity yet, let's go create one
      //   navigation.navigate(`Persona`);
      //   return;
      // }

      const signed_users = await decodeSecureStoreAndSignIn(loginResult.secure_store, { push_token, invite_token });

      // console.log(`logUserIn: signed_users = ${JSON.stringify(signed_users)}`);
      if (!signed_users) {
        finishLoginWithMessage(`Persona login failed`);
        throw new Error(`Persona login failed`);
      }

      const newUser = signed_users[0];

      const userAndGroup = {
        user_id: newUser?.id,
        group_id: undefined,
      };

      const { data: userSettings }: SettingQuery = await client.query({
        query: SETTING_QUERY,
        variables: {
          settingQuery: {
            query: `user`,
            ...userAndGroup,
          },
        },
      });

      const phoneNumberSetting = userSettings?.getSettings.find((elem) => elem.key === `user.setting.phone_number`);
      const phoneNumberVerifiedSetting = userSettings?.getSettings.find(
        (elem) => elem.key === `user.setting.phone_number_verified`,
      );

      if (cleaned.startsWith(`+`) && phoneNumberSetting?.value === null) {
        // means that the input that the user just wrote is a number
        const input = {
          key: phoneNumberVerifiedSetting?.key,
          value: `true`,
          id: phoneNumberVerifiedSetting?.id,
          user_id: newUser?.id,
        };

        const { data: updatedSettingPhoneVerifiedData } = await updateSetting({ variables: { input } });
        console.log(`🚀 🚀  ->  ~ logUserIn ~ updatedSettingPhoneVerifiedData`, updatedSettingPhoneVerifiedData);

        const input2 = {
          key: phoneNumberSetting?.key,
          value: cleaned,
          id: phoneNumberSetting?.id,
          user_id: newUser?.id,
        };

        const { data: updatedSettingPhoneData } = await updateSetting({ variables: { input: input2 } });
        console.log(`🚀 🚀  ->  ~ logUserIn ~ updatedSettingPhoneData`, updatedSettingPhoneData);
      }

      await fetchLatestCommunities(newUser.id);

      setUser(newUser);

      // Configure Sentry so that all future calls will include identity & user information
      Sentry.configureScope((scope: any) => {
        scope.setUser({ username: newUser?.handle });
        scope.setExtras({ 'global.identity': identity, 'global.user': newUser });
      });

      finishLoginWithMessage(undefined);

      // navigateAfterLogin(navigation); // automated in nav v5
      // const duration = +Date.now() - startTime;
      // console.log('🚀 ~ file: LoginScreen.jsx ~ line 200 ~ _LoginScreen ~ logUserIn= ~ Total duration', duration);
    } catch (err: unknown) {
      console.error(`login exception:`, err);
      Sentry.withScope((scope: any) => {
        scope.setExtra(`err`, err);
        Sentry.captureException(err as Error);
      });
      finishLoginWithMessage(`An unexpected problem occurred in the app`);
    }
  };

  return (
    <KeyboardPaddingView>
      <SafeAreaView testID="LOGINSCREEN" style={{ flex: 1 }}>
        <View style={{ flex: 1, alignItems: `center`, width: `100%` }}>
          <ScrollView
            style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
            contentContainerStyle={{ alignItems: `center` }}
            alwaysBounceVertical={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={{ marginTop: 16, width: `100%`, maxWidth: Spacing.standardWidth }}>
              <Text style={Typography.text(`bold`, `center`, `large`)}>Welcome back!</Text>
              <Text style={Typography.text(`bold`, `center`, `large`)}>Log in.</Text>

              <View style={{ margin: 10 }}>
                {!!errorMessage && (
                  <View style={localStyles.errorMessageContainer}>
                    <Text style={localStyles.errorMessageText} testID="LOGINSCREEN_ERRORMSG">
                      {errorMessage}
                    </Text>
                  </View>
                )}
                <Text style={localStyles.phoneNumberLabelText}>Username or phone number</Text>
                <ContactInput
                  type="both"
                  testID="LOGINSCREEN_PHONE_NUMBER"
                  editable={!loggingIn}
                  onChangeText={handleContactTextChange}
                  onSubmitEditing={() => passwordInput.current?.focus()}
                  style={{ marginVertical: 10 }}
                  autoFocus
                />

                <Text style={localStyles.passwordLabelText}>Password</Text>
                <PasswordInput
                  testID="LOGINSCREEN_PASSWORD"
                  ref={passwordInput}
                  editable={!loggingIn}
                  onChangeText={handlePasswordTextChange}
                  onSubmitEditing={logUserIn}
                />
              </View>

              <View style={{ margin: 10 }}>
                <ThemedButton
                  rounded
                  title="Log in"
                  titleStyle={localStyles.authButtonText}
                  testID="LOGINSCREEN_LOGIN"
                  onPress={logUserIn}
                  disabled={!isValidContact || !password || loggingIn}
                  buttonStyle={{ padding: 10 }}
                  leftIcon={loggingIn && <LoadingIndicator size={4} />}
                />
                <View style={{ marginVertical: 12, flexDirection: `row`, alignItems: `center` }}>
                  <ForgotPasswordLink />
                  <NotAMemberLink />
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

export const LoginScreen = withTheme(_LoginScreen);

const ForgotPasswordLink = () => {
  const navigation = useNavigation<LoginNavigator>();
  return (
    <View style={{ flex: 1, flexDirection: `row` }}>
      <TouchableOpacity
        style={{ flexShrink: 1, flexDirection: `row` }}
        hitSlop={Platform.select({ web: undefined, default: hitSlop })}
        testID="LOGINSCREEN_FORGOTPASSWORD"
        onPress={() => navigation.navigate(`Auth`, { screen: `ForgotPassword` })}
      >
        <View style={{ flex: 1, flexDirection: `row`, justifyContent: `flex-start`, alignItems: `center` }}>
          <Text style={{ ...Typography.text(`small`, `link`), margin: 4 }}>Forgot password?</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const NotAMemberLink = () => {
  const navigation = useNavigation<LoginNavigator>();
  const { invite_token } = React.useContext(AppContext);
  const { anyoneCanRegister } = getEnvVariables();

  const handleLogin = () => {
    if (anyoneCanRegister || invite_token) navigation.push(`Auth`, { screen: `RegisterUsername` });
    else navigation.push(`Auth`, { screen: `EarlyAccess` });
  };

  return (
    <View style={{ flex: 1, flexDirection: `row`, justifyContent: `flex-end` }}>
      <TouchableOpacity
        style={{ flexShrink: 1, flexDirection: `row` }}
        hitSlop={Platform.select({ web: undefined, default: hitSlop })}
        testID="LOGINSCREEN_EARLYACCESS"
        onPress={handleLogin}
      >
        <View style={{ flex: 1, flexDirection: `row`, justifyContent: `flex-end`, alignItems: `center` }}>
          <Text style={{ ...Typography.text(`small`, `link`), margin: 4 }}>Not a Mesh member yet?</Text>
          <MeshIcon name="arrow-right" color={Colors.deepPurple} size={22} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const localStyles = StyleSheet.create({
  errorMessageContainer: {
    justifyContent: `center`,
    padding: 4,
    marginTop: 6,
    backgroundColor: Colors.paleLightRed,
    paddingHorizontal: 20,
  },
  errorMessageText: {
    ...Typography.text(`smaller`, `red`, `center`),
  },
  phoneNumberLabelText: {
    marginTop: 4,
    ...Typography.text(`smaller`, `gray`),
  },
  passwordLabelText: {
    marginTop: 10,
    marginBottom: 10,
    ...Typography.text(`smaller`, `gray`),
  },
  authButtonText: {
    ...Typography.text(`white`, `bold`),
    marginLeft: 4,
  },
});
