import { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Colors } from '../../common-styles';
import { LinkPreview, GetInvitationVars, GetInvitationData } from '../../common-types/types';
import { CREATE_LINK_PREVIEW, GET_INVITATION } from '../../graphql';
import { isAMeshLink } from '../helpers';

type CreateLinkPreviewResults = {
  createLinkPreview: LinkPreview;
};

type CreateLinkPreviewVars = {
  link: string;
};
export type TextSelection = {
  start: number;
  end: number;
};
// gruber's permissive url matching regex
const urlRegex =
  /\b((?:(?:http|https)+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/gi;

export const inviteRegex =
  // eslint-disable-next-line no-useless-escape
  /(\?invitation&token=|invitation\/)([\w]+)?([\/]?)([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/i;

// eslint-disable-next-line no-useless-escape
export const profileInviteRegex =
  /(profile\/invite\/)([\w]+)?([\/]?)([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/i;

export const getMeshInviteToken = (link: string = ``, regexp: RegExp = inviteRegex) => {
  if (!isAMeshLink(link)) return undefined;
  const validUrl = link.match(regexp) || [];
  const invite_token = validUrl[validUrl.length - 1];
  return invite_token;
};

export const useLinkPreview = (initialPreview?: LinkPreview) => {
  const [failed, setFailed] = useState<string[]>([]);
  const [fetching, setFetching] = useState<string>();
  const [delay, setDelay] = useState(100);
  const [linkPreview, setLinkPreview] = useState<LinkPreview | undefined>(initialPreview);
  const asyncPreviewRef = useRef<LinkPreview | undefined>(linkPreview);
  asyncPreviewRef.current = linkPreview;

  const [requestLinkPreview, { data: link, error: linkError }] = useMutation<CreateLinkPreviewResults, CreateLinkPreviewVars>(
    CREATE_LINK_PREVIEW,
  );
  const [requestInvitePreview, { data: invite, error: inviteError }] = useLazyQuery<GetInvitationData, GetInvitationVars>(
    GET_INVITATION,
    {
      onError: (err) => console.log(`[useLinkPreviewError]`, (err as Error).message),
    },
  );

  const considerLinkFetch = useDebouncedCallback((text: string, selection?: TextSelection) => {
    const matches = text.match(urlRegex) || [];
    const { start = 0 } = selection || {};
    for (const match of matches) {
      const mindex = text.indexOf(match);
      const notAnURL = failed.includes(match); // skip if we know (from a previous failed fetch) that it's not a url
      if (!notAnURL && mindex >= 0 && fetching !== match) {
        const stillTyping = mindex + match.length === start;
        setDelay(stillTyping ? 1000 : 100);
        setFetching(match);
        break;
      }
    }
  }, 100);

  useEffect(() => {
    if (fetching) {
      const isAMeshInvite = isAMeshLink(fetching);
      console.log(`fetchLinkPreviewAfterDelay(${fetching}) starting in ${delay}ms`);
      const timer = setTimeout(() => {
        try {
          const validUrl = fetching.match(inviteRegex); // decomposed into [path, method, token]
          if (isAMeshInvite && validUrl) {
            const token = validUrl[validUrl.length - 1]
            if (token) requestInvitePreview({ variables: { invite_token: token } });
          } else {
            requestLinkPreview({ variables: { link: fetching } });
          }
        } catch (error) {
          console.warn(`[LinkPreviewLocalError]`, error);
        }
      }, delay);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [fetching, delay, requestLinkPreview, requestInvitePreview]);

  useEffect(() => {
    if (linkError && fetching) {
      if (__DEV__) console.warn(`[LinkPreviewServerError]`, linkError);
      setFailed((prev) => [...prev, fetching]);
      return undefined;
    }
    if (link?.createLinkPreview) {
      const newLinkPreview: LinkPreview = link?.createLinkPreview || {};
      if (newLinkPreview?.entered === fetching) {
        setLinkPreview(newLinkPreview);
        setFetching(undefined);
      }
    }
    return undefined;
  }, [link, linkError, fetching]);

  useEffect(() => {
    if (inviteError && fetching) {
      console.warn(`[LinkPreviewServerError]`, inviteError);
      setFailed((prev) => [...prev, fetching]);
      return undefined;
    }
    const { group_invitation: invitePreview, group } = invite?.getInvitation || {};

    const validUrl = fetching?.match(inviteRegex);
    if (invitePreview && group && validUrl) {
      const [, , entered] = validUrl; // decomposed into [path, method, token]
      setLinkPreview({
        entered,
        url: fetching,
        imageUrls: [group?.splash_url],
        title: `You are invited to ${group?.name}`,
        imageResizeMode: `cover`,
        imageBackgroundColor: Colors.white,
      } as LinkPreview);
      setFetching(undefined);
    }
    return undefined;
  }, [fetching, invite, inviteError]);

  const getAsyncLinkPreview = () => {
    return new Promise<LinkPreview | undefined>((resolve) => {
      setTimeout(() => {
        resolve(asyncPreviewRef.current);
      }, 3000);
    });
  };

  return { linkPreview, getAsyncLinkPreview, setLinkPreview, considerLinkFetch, fetching };
};
