import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { formatShortRelativeDate } from '../common-util';
import { MODERATION_ACTIONS } from '../moderation/moderationEnums';
import { BaseNotificationItem } from './BaseNotificationItem';
import { PendoTrackEvent, Events } from '../pendo/events';

const allowedNotifications = {
  verifyIdentity: MODERATION_ACTIONS.identity_verify,
  rejectIdentity: MODERATION_ACTIONS.identity_reject,
  tempRestrictedAccount: `temporal_restricted_account`, // TBD - We need to review this
};

export const allowed_types = Object.values(allowedNotifications);

const AccountNotificationPresets = {
  verifyIdentity: {
    navigate: `Profile`,
    badge: `check`,
    text: `Your account has been verified!`,
  },
  rejectIdentity: {
    navigate: `ProfileGetMeshVerified`,
    badge: `warning`,
    text: `We could not verify your account. Tap to submit again for Mesh Verification.`,
  },
  tempRestrictedAccount: {
    badge: `warning`,
    text: `You’re temporarily restricted from joining and posting to communties until Sep 8 at 6:55 PM.`,
  },
};

export const AccountNotificationItem = ({ notification, activity, type, markRead, onPressOptionsButton }) => {
  const navigation = useNavigation();
  if (!activity?.origin_group) return null;

  const onPress = () => {
    if (!notification.is_read) markRead(notification.id);
    const targetScreen = AccountNotificationPresets[type]?.navigate;
    if (targetScreen) {
      PendoTrackEvent(Events.PROFILE, {
        //TODO: verify not null
        username: notification?.actor?.handle,
        source: `notifications`,
        element: `notification`,
      });
      navigation.navigate(targetScreen);
    }
  };

  const handlePressOptionsButton = () => onPressOptionsButton(notification);

  const getBadge = () => AccountNotificationPresets[type]?.badge;

  const renderNotificationText = () => {
    const content = AccountNotificationPresets[type]?.text;
    if (!content) return null;
    return <Text style={Typography.text()}>{content}</Text>;
  };

  const avatarInfo = {
    data: activity.user || activity.origin_group,
    type: `mesh`,
  };

  let notificationTime = ``;
  if (notification.updated_at) notificationTime = notification.updated_at;
  else if (activity.time) notificationTime = activity.time;
  else if (activity.reaction.created_at) notificationTime = activity.reaction.created_at;
  else notificationTime = notification.created_at;
  if (!notificationTime.endsWith(`Z`)) notificationTime = `${notificationTime}Z`;

  return (
    <BaseNotificationItem
      containerStyle={{
        backgroundColor: notification.is_read ? Colors.seenNotificationBackground : Colors.unseenNotificationBackground,
      }}
      title={<View style={{ marginBottom: 0 }}>{renderNotificationText(notification)}</View>}
      date={formatShortRelativeDate(notificationTime)}
      avatarData={avatarInfo.data}
      avatarType={avatarInfo.type}
      navigation={navigation}
      badge={getBadge()}
      showMoreOptionsButton
      onPressOptionsButton={handlePressOptionsButton}
      onPress={onPress}
      isSameStack={true}
    />
  );
};

AccountNotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  markRead: PropTypes.func.isRequired,
  onPressOptionsButton: PropTypes.func,
};

AccountNotificationItem.defaultProps = {
  onPressOptionsButton: () => {},
};
