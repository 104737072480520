import { Linking, Platform, TextStyle } from 'react-native';
import URLParse from 'url-parse';
import { User, Group, Activity, Invitation, UserMention, MetaStyle, Comment, LinkPreview } from '../common-types/types';
import { Typography } from '../common-styles';
import { DUMMY_UUID, DELETED_USER } from '../../constants';

const MENTIONS_REGEX = /@[a-z0-9_-]+/gim; // Get “@mentions" from the text input
const BLANK_SPACES_REGEX = /(\S+\s+)/;
const PARSE_URL_REGEX = /(http[s]?:\/\/\S*)/;
/**
 * This functions takes an URL as args and returns whether or not
 * is a MESH invite link.
 * @param {String} link URL to verify if is a mesh link
 *
 * @returns {Boolean} Returns true if is a mesh invite link
 */
export const isAMeshLink = (link: string = ``) => {
  const temp = link.replace(`\n`, ` `).match(PARSE_URL_REGEX);
  const data = temp && temp[0] ? temp[0] : link;
  const { host } = URLParse(data, true);
  return host.includes(`app.meshconnect.us`) || host.includes(`app.mymesh.io`);
};

export const fixMeshInvitelLink = (text?: string) => {
  //fix v1.0 links
  if (text?.includes(`app.meshconnect.us/?invitation&token=`)) {
    return text.replace(`app.meshconnect.us/?invitation&token=`, `app.mymesh.io/invitation/`);
  }
  //fix v1.1 links
  if (text?.includes(`app.meshconnect.us/`)) {
    return text.replace(`app.meshconnect.us/`, `app.mymesh.io/`);
  }
  return text;
};
/**
 * This functions transform the title that came from the activity and
 * returns the corresponding invitation message if necessary.
 * @param {Community} community Community name where inivitation came from
 * @param {String} oldTitle Title that came from linkPreview
 * @param {Boolean} replace Determine if replace is needed
 * @returns You're invited to ${newTitle} or default oldTitle
 */
export const replaceHeaderTitle = (community: Invitation, oldTitle: string = ``, replace: boolean = false) => {
  if (!replace) return oldTitle;
  if (community) {
    const {
      group: { name },
    } = community;
    return `You're invited to ${name}`;
  }
  // Fallback is replace but not community sended
  return oldTitle;
};

export const classifyActivity = (activity: Activity | undefined) => {
  const { imageUrls, linkPreview, video_urls, poll } = activity || {};
  if (poll && poll?.options?.length > 0) return MediaTypes.POLL;
  if (video_urls && video_urls.length > 0) return MediaTypes.VIDEO;
  if (imageUrls && imageUrls.length > 0) return MediaTypes.IMAGE;
  if (linkPreview) return MediaTypes.LINK;
  return MediaTypes.TEXT;
};

export const classifyText = (text: string) => {
  const MAX_LINES_IN_SHORT_POST = 6;
  const MAX_CHARS_IN_SHORT_POST = 120;
  const MAX_LINES_IN_MEDIUM_POST = 12;
  const MAX_CHARS_IN_MEDIUM_POST = 250;
  const charCount = text?.length || 0;
  const linebreakCount = text?.split(`\n`).length || 0;
  if (linebreakCount <= MAX_LINES_IN_SHORT_POST && charCount < MAX_CHARS_IN_SHORT_POST) return `short`;
  if (linebreakCount <= MAX_LINES_IN_MEDIUM_POST && charCount < MAX_CHARS_IN_MEDIUM_POST) return `medium`;
  return `long`;
};

export const getTrimmedActivityText = (activity: Activity) => {
  const { linkPreview, content } = activity;
  let text = content || ``;
  text = text?.trim();
  const { entered: link } = linkPreview || {};
  if (link && text?.startsWith(link)) text = text?.slice(link.length).trim();
  else if (link && text?.endsWith(link)) text = text?.slice(0, text.length - link.length).trim();
  return text;
};

export const getTextStyleForPost = (text: string, width: number, overflowMode: string) => {
  // Choose font sizes dynamically.
  // size on this screen = width on this screen * (size on basis screen / width for basis screen)
  const textClassification = classifyText(text);
  const style: TextStyle = Typography.text();
  const margin = width * (16 / 375);
  let height;
  let allowFontScaling;
  let numberOfLines;
  let hasBlob;
  switch (textClassification) {
    case `short`:
      hasBlob = true;
      allowFontScaling = false;
      height = width;
      style.textAlign = `center`;
      style.fontSize = width * (32 / 375);
      style.lineHeight = width * (39 / 375);
      break;
    case `medium`:
      hasBlob = true;
      allowFontScaling = false;
      height = width;
      style.textAlign = `center`;
      style.fontSize = width * (22 / 375);
      style.lineHeight = width * (26 / 375);
      break;
    case `long`:
    default:
      hasBlob = false;
      style.textAlign = `left`;
      style.fontSize = width * (14 / 375);
      style.lineHeight = width * (17 / 375);
      if (overflowMode === `fit`) {
        height = width;
        numberOfLines = Math.floor((height - 2 * margin) / style.lineHeight);
        allowFontScaling = false;
      } else {
        allowFontScaling = true;
      }
      break;
  }
  return { textClassification, height, margin, allowFontScaling, numberOfLines, hasBlob, style };
};

/**
 * Transforms an array into a unique set of mentions.
 * @param arr Array of mentions captured by the regex.
 * @returns Set of unique mentions.
 */
export const uniqueMentions = (arr: UserMention[] = []) => {
  if (arr?.length === 0) return new Set();
  const handles = arr?.filter((e) => e).map((mention) => `@${mention.handle}`);
  return new Set(handles);
};

/**
 * Transforms an array into a unique set of mentions.
 * @param arr Array of mentions captured by the regex.
 * @returns Array of unique mentions.
 */
export const removeDuplicatedMentions = (arr: UserMention[] = []) => {
  const uniquesMentions = arr?.filter((mention, index, self) => {
    return index === self.findIndex((prev) => prev.id === mention.id);
  });
  return uniquesMentions;
};

/** @deprecated
 * Split the value using the blankSpacesRegex which will split the string including the empty spaces of each word.
 * This prevents that when the user types a space, the words are not going to be joined together.
 * @param value Value of the text input that we are receiving
 * @returns Splited value of the text input
 */
export const splitWithBlankSpaces = (value?: string) => {
  const words = value?.split(BLANK_SPACES_REGEX);
  return words?.filter((n) => n.trim().length > 0) || [];
};

/** @deprecated
 * Determine the mention of the current word
 * @param word Word that we are trying to find a match for
 * @returns Whether a word is a match for a mention text
 */
export const checkIfIsMention = (word: string) => {
  return new RegExp(MENTIONS_REGEX).test(word);
};

/**
 * User: guarantees that we have a non-null user object with all the required fields
 */
export const guaranteeUser = (user: User): Pick<User, `id` | `handle`> => {
  const INVALID_VALUES = { id: DUMMY_UUID, handle: `[invalid]` };
  if (!user) return DELETED_USER;
  return { ...INVALID_VALUES, ...user }; // if we are missing any required fields then populate them
};

/**
 * Group: guarantees that we have a non-null group object with all the required fields
 */
export const guaranteeGroup = (group?: Group) => {
  const DELETED_GROUP = { id: DUMMY_UUID, name: `[deleted community]` };
  const INVALID_VALUES = { id: DUMMY_UUID, name: `[invalid community]` };
  if (!group) return DELETED_GROUP;
  return { ...INVALID_VALUES, ...group }; // if we are missing any required fields then populate them
};

export const defaultMetaStyle = (width: number, currentMetaStyle?: MetaStyle): MetaStyle => {
  const MAX_FONT_SIZE = width * (32 / 375);
  const margin = width * (16 / 375);
  const windowWidth = width - 2 * margin;
  if (currentMetaStyle) {
    //If editing a post that already has metaStyle, check if it has blob index or not
    const { fontSize, lineHeight, blobIndex } = currentMetaStyle;
    return {
      ...currentMetaStyle,
      fontSize: Math.max(windowWidth * (fontSize / 1000 / 375), windowWidth * (14 / 375)),
      lineHeight: Math.max(windowWidth * (lineHeight / 1000 / 375), windowWidth * (17 / 375)),
      blobIndex: blobIndex !== undefined ? blobIndex : 10,
    };
  }
  return {
    fontSize: MAX_FONT_SIZE, //use max font size but scale relative to screen width
    lineHeight: width * (39 / 375),
    align: `center`,
    hasBlob: true,
    blobIndex: 10,
  } as MetaStyle;
};

export const adjustMetaStyle = (style: MetaStyle, width: number = 375) => {
  //delete __typename because it is unnecessary in this mutation
  // eslint-disable-next-line no-param-reassign
  if (style.__typename) delete style.__typename;
  const margin = width * (16 / 375);
  const windowWidth = width - 2 * margin;
  return {
    ...style,
    fontSize: Math.floor((style.fontSize / windowWidth) * 375 * 1000),
    lineHeight: Math.floor((style.lineHeight / windowWidth) * 375 * 1000),
  };
};

export enum MediaTypes {
  TEXT = `text`,
  IMAGE = `image`,
  VIDEO = `video`,
  VIDEO_LOADING = `video-loading`,
  LINK = `link`,
  POLL = `poll`,
  MEME = `meme`,
  // TODO
  AUDIO = `audio`,
}

export const buildComment = (comment: Comment) => {
  const {
    id,
    user,
    data,
    children_counts: childrenCounts,
    kind,
    own_like: ownLike,
    created_at: createdAt,
    updated_at: updatedAt,
  } = comment || {};
  const author = guaranteeUser(user);
  const body = data?.object;
  const { comment_image: image, validMentionTargets = [], link_preview: linkPreview } = data || {};
  const isEdited = comment.created_at !== comment.updated_at;
  const isRemoved = comment.data?.removed;

  return {
    id,
    author,
    body,
    image,
    ownLike,
    isEdited,
    isRemoved,
    childrenCounts,
    validMentionTargets,
    kind,
    createdAt,
    updatedAt,
    linkPreview,
  };
};

export const openLink = (url: string, callback: () => void) => {
  try {
    if (isAMeshLink(url) && Platform.OS === `ios`) {
      callback(); // Call the callback to open the link in the app
    } else {
      Linking.openURL(url);
    }
  } catch (error) {
    console.error(error, `[POST]: `, error);
  }
};

export const cleanTypenameFromLink = (link: LinkPreview) => {
  const cleanLink = { ...link };
  if (cleanLink?.__typename) delete cleanLink.__typename;
  return cleanLink;
};

export const hasValidUrl = (text: string) => {
  const temp = text.replace(`\n`, ` `).match(PARSE_URL_REGEX);
  return temp && temp[0];
};

export const mentionPattern = /@[a-z0-9_-]+/gim;

export const urlRegex =
  /\b((?:(?:http|https)+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/gi;

export const firstWordPattern = /^([\w-]+)/;
