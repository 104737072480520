import React, { useState, useCallback } from 'react';
import { View, Text, TouchableOpacity, SectionList } from 'react-native';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';
import { KeyboardPaddingView, ErrorBoundary, MeshIcon, Divider, LoadingIndicator } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { CommunityListItem } from '../feed/CommunityListItem';
import { GET_EXPLORE_COMMUNITIES } from '../graphql';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';

/** @deprecated */
export const ExploreAllRecommendations = ({ navigation }) => {
  const { user } = React.useContext(AppContext);
  const [order, setOrder] = useState(`default`);

  const recommendationVariables = {
    recommendQuery: {
      user_id: user?.id,
      sort: order,
      filters: {
        posts: 0,
        members: 0,
        membership: `Not a member`, //Only recommend communities I'm not member of
      },
      limit: -1,
    },
    interestQuery: {
      user_id: user?.id,
      query: `user.setting.interests`,
    },
  };

  const {
    data: recommendedCategories,
    loading,
    refetch,
    networkStatus,
  } = useQuery(GET_EXPLORE_COMMUNITIES, {
    variables: recommendationVariables,
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    skip: !user?.id,
  });

  const sections = [
    {
      id: `recommendedCommunities`,
      title: `Recommended`,
      data: recommendedCategories?.getRecommendedCommunities3 || [],
    },
  ];

  const cycleOrdering = useCallback(() => {
    if (order === `default`) setOrder(`A-Z`);
    if (order === `A-Z`) setOrder(`Z-A`);
    if (order === `Z-A`) setOrder(`default`);
    refetch();
    console.log(`order changed --> refetch`);
  }, [order, refetch]);

  const keyExtractor = useCallback((item) => item.group.id, []);

  const ListDivider = useCallback(({ section }) => {
    return section.id === `categories` ? null : <Divider />;
  }, []);

  const Header = useCallback(
    ({ section: { id, title } }) => {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
            backgroundColor: Colors.commentGray,
            marginBottom: id === `categories` ? 20 : 0,
          }}
        >
          <Text maxFontSizeMultiplier={2} style={{ marginHorizontal: 16, marginVertical: 8, ...Typography.text(`bold`, `gray`) }}>
            {title}
          </Text>
          <TouchableOpacity onPress={cycleOrdering}>
            {order === `default` ? (
              <MeshIcon
                name="clock"
                size={16}
                color={Colors.focusedIconColor}
                style={{ marginHorizontal: 16, marginVertical: 8 }}
              />
            ) : (
              <Text
                maxFontSizeMultiplier={2}
                style={{ marginHorizontal: 16, marginVertical: 8, ...Typography.text(`theme`, `right`, `small`) }}
              >
                {order}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      );
    },
    [order, cycleOrdering],
  );

  const renderItem = useCallback(
    ({ item }) => {
      return <CommunityListItem navigation={navigation} group={item.group} />;
    },
    [navigation],
  );

  return (
    <KeyboardPaddingView>
      <ErrorBoundary header="A problem occurred displaying this screen.">
        <View style={{ backgroundColor: `white`, flex: 1 }}>
          {loading ? (
            <View style={{ width: `100%`, height: `100%`, flex: 1, justifyContent: `center`, alignItems: `center` }}>
              <LoadingIndicator size={32} style={{ margin: 32 }} />
            </View>
          ) : (
            <SectionList
              sections={sections}
              refreshing={networkStatus === NetworkStatus.refetch}
              onRefresh={refetch}
              keyExtractor={keyExtractor}
              renderSectionHeader={Header}
              renderItem={renderItem}
              ItemSeparatorComponent={ListDivider}
            />
          )}
        </View>
      </ErrorBoundary>
    </KeyboardPaddingView>
  );
};

ExploreAllRecommendations.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};
