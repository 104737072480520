import React, { useCallback, useLayoutEffect, useState, useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { setCommunityEmail, movePrev } from '../redux/createCommunitySlice';
import { BackButton, MeshIcon, ThemedButton } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { WizardLayout } from './WizardLayout';
import { isValidEmail } from '../common-util';
import { CommunityEmailTestIds } from './testLabels';
import { AppContext } from '../../AppContext';
import Input from '../common-ui/inputs/Input';
import { useSettings } from '../common-settings/hooks/useSettings';
import { RootState } from '../redux/store';
import { HomeNavigator } from '../common-types/navigation-types';

const USER_EMAIL_KEY = `user.setting.email`;

// Step 2: Community Details
const CommunityEmail = () => {
  const navigation = useNavigation<HomeNavigator>();
  const dispatch = useDispatch();
  const { user } = useContext(AppContext);

  const { settings } = useSettings(USER_EMAIL_KEY, { user_id: user?.id });
  const personalEmail = `${settings[USER_EMAIL_KEY]?.value}`;

  const { communityEmail } = useSelector((state: RootState) => state.createCommunity);
  const [currentCommunityEmail, setCurrentCommunityEmail] = useState(communityEmail);

  const [error, setError] = useState(``);
  const [usePersonalEmail, setUsePersonalEmail] = useState(false);

  const handleMoveNext = useCallback(() => {
    const trimmedValue = currentCommunityEmail.trim();
    const valid = isValidEmail(trimmedValue);
    if (!valid) {
      setError(`Invalid email, please type a correct one`);
      return;
    }

    dispatch(setCommunityEmail(trimmedValue));
    navigation.navigate(`CommunityEmailVerification`, { groupId: undefined });
  }, [currentCommunityEmail, dispatch, navigation]);

  const handlePrev = () => {
    navigation.goBack();
    dispatch(movePrev());
  };

  const onChangeText = (value: string) => {
    setError(``);
    setCurrentCommunityEmail(value);
    setUsePersonalEmail(false);
  };

  const togglePersonalEmail = useCallback(() => {
    setError(``);
    if (!personalEmail) return;

    if (!usePersonalEmail) {
      setCurrentCommunityEmail(personalEmail);
    } else {
      setCurrentCommunityEmail(communityEmail);
    }
    setUsePersonalEmail(!usePersonalEmail);
  }, [usePersonalEmail, personalEmail, communityEmail]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `Community Details`,
      headerLeft: () => <BackButton onPress={handlePrev} />,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  return (
    <WizardLayout total={5} selector="createCommunity">
      <View style={styles.container}>
        <View style={styles.inputContainer} testID={CommunityEmailTestIds.screen}>
          <Text style={styles.titleStyle}>Add an email address</Text>

          <Input
            testID={CommunityEmailTestIds.input}
            style={styles.communityInput}
            value={currentCommunityEmail}
            autoCapitalize="none"
            isError={!!error}
            tooltip="Email Address"
            placeholder="Enter an email address"
            onChangeText={onChangeText}
            underlineColorAndroid="transparent"
            keyboardType="email-address"
          />

          {error.length > 0 && (
            <View style={{ marginBottom: 8, backgroundColor: Colors.blockUserBackgroundColor, borderRadius: 4 }}>
              <Text style={{ ...Typography.text(`red`, `bold`), paddingHorizontal: 16, paddingVertical: 8 }}>
                Enter a valid email
              </Text>
            </View>
          )}

          <TouchableOpacity
            onPress={togglePersonalEmail}
            activeOpacity={0.8}
            style={{ flexDirection: `row`, alignItems: `center` }}
          >
            <MeshIcon
              style={{ marginRight: 6 }}
              name={usePersonalEmail ? `checkbox-on` : `checkbox-off`}
              color={personalEmail ? Colors.brandPurple : Colors.gray}
            />
            <Text style={Typography.text()}>Use personal email address</Text>
          </TouchableOpacity>
        </View>

        <ThemedButton
          testID={CommunityEmailTestIds.continue}
          title="Continue"
          disabled={currentCommunityEmail?.trim()?.length === 0 || !!error}
          rounded
          onPress={handleMoveNext}
          titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
        />
      </View>
    </WizardLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  inputContainer: {
    paddingVertical: 24,
    marginBottom: 60,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: `600`,
  },
  communityInput: {
    marginTop: 8,
    marginBottom: 8,
  },
});

export default CommunityEmail;
