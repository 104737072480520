import React, { useState, useContext, useEffect } from 'react';
import { FlatList, Linking, Platform, StyleSheet, Text, TouchableOpacity, View, Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import * as Animatable from 'react-native-animatable';
import { useNavigation } from '@react-navigation/native';
import { useDrawerStatus, getDrawerStatusFromState } from '@react-navigation/drawer';

import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { getAllPersonaKeys } from '../auth/helpers';
import { Divider, LoadingModal, MeshIcon, NoThemeButton, SafeAreaView, Avatar, BottomSheetModal } from '../common-ui';
import { getAsyncStorageObject } from '../common-util';
import { getEnvVariables, getManifest } from '../../environment';
//import { NOTIFICATIONS_PER_FETCH } from '../../constants';
import { Colors, Typography } from '../common-styles';
import { MeshVerifiedCheck } from '../images';
import { AppContext } from '../../AppContext';
import { PersonaMenu } from './PersonaMenu';
import { useAppStateListener } from './hooks/useAppStateListener';
import { getVersionInfo } from '../update/useUpdateHandler';
import { GET_FOLLOW_COUNT, GET_PROFILE_INVITE } from '../graphql';
import { refreshCounts, setFollowCounts } from '../redux/followSlice';
import { useLogoutAll } from './hooks/useLogoutAll';
import { PendoTrackEvent, Events } from '../pendo/events';
import InviteModal from '../profile/InviteModal';
import { fixMeshInvitelLink } from '../post/helpers';

export const DrawerMenuIcon = ({ onPress }) => {
  const { user } = useContext(AppContext);
  const navigation = useNavigation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerStatus = (status) => {
    const drawerVisible = getDrawerStatusFromState(status?.data?.state) === `open`;
    setIsDrawerOpen(drawerVisible);
  };

  useEffect(() => {
    const leftDrawer = navigation?.getParent(`LeftDrawer`);
    const unsubscribe = leftDrawer?.addListener(`state`, handleDrawerStatus);
    return unsubscribe;
  }, [navigation]);

  // menu icon disappears while drawer is open so that we don't have two copies of the avatar onscreen
  if (isDrawerOpen) return null;

  return (
    <View style={{ paddingVertical: 8 }}>
      <Avatar
        testID="DRAWER_BUTTON"
        hitSlop={{ top: 15, bottom: 15, left: 15, right: 15 }}
        user={user}
        size={24}
        border
        onPress={() => {
          if (onPress) onPress();
          navigation.getParent(`LeftDrawer`).toggleDrawer();
        }}
      />
    </View>
  );
};

DrawerMenuIcon.propTypes = {
  onPress: PropTypes.func,
};
DrawerMenuIcon.defaultProps = {
  onPress: undefined,
};

const numberWithComma = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);
};
export const Drawer = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { logoutAll, loggingOut } = useLogoutAll();
  const isDrawerOpen = useDrawerStatus() === `open`;
  const { refreshUpdateState, ...currentState } = useAppStateListener();
  const { user } = useContext(AppContext);
  const [personas, setPersonas] = useState([]);
  const { isCountRefresh } = useSelector((state) => state.follow);
  const [showFriends, setShowFriends] = useState(false);
  const [inviteUrl, setInviteUrl] = useState(``);

  const { data: followCounts, loading: followCountsLoading, refetch } = useQuery(GET_FOLLOW_COUNT, { fetchPolicy: `no-cache` });
  const { data: inviteData } = useQuery(GET_PROFILE_INVITE, { variables: { user_id: user?.id } });

  useEffect(() => {
    const link = inviteData?.getProfileInvite?.invite_url;
    if (link) {
      setInviteUrl(fixMeshInvitelLink(link) || ``);
    }
  }, [inviteData?.getProfileInvite?.invite_url]);

  useEffect(() => {
    const requestRefetch = async () => {
      try {
        const results = await refetch();
        if (results) dispatch(refreshCounts(false));
      } catch (error) {
        console.error(`Error occured when refetching counts...`, error.message);
      }
    };
    if (isCountRefresh || isDrawerOpen) requestRefetch();
  }, [isCountRefresh, isDrawerOpen, refetch, dispatch]);

  useEffect(() => {
    if (followCounts?.getFollowCounts) {
      const payload = {
        followers: followCounts?.getFollowCounts?.follwers || 0,
        following: followCounts?.getFollowCounts?.following || 0,
      };
      dispatch(setFollowCounts(payload));
    }
  }, [dispatch, followCounts]);

  const refreshPersonas = async () => {
    const persona_ids = await getAllPersonaKeys();
    const fetchedPersonas = (await Promise.all(persona_ids.map((key) => getAsyncStorageObject(key)))).filter(
      (p) => p.handle !== `guest`,
    );
    fetchedPersonas.sort((p1, p2) => p1.handle.localeCompare(p2.handle));
    setPersonas(fetchedPersonas);
  };

  useEffect(() => {
    // Only once per opening of drawer
    if (isDrawerOpen) {
      Keyboard.dismiss();
      refreshUpdateState();
    }
  }, [isDrawerOpen, refreshUpdateState]);

  useEffect(() => {
    try {
      if (user?.id) {
        const persona_ids = personas.map((persona) => persona.id);
        if (persona_ids.indexOf(user.id) === -1) refreshPersonas();
      } else if (personas.length > 0) setPersonas([]);
    } catch (error) {
      console.warn(`[refreshPersonas]`, error);
    }
  }, [personas, setPersonas, user?.id]);

  const handleInviteFriendsPress = () => {
    setShowFriends(true);
  };

  const marginLeft = 21;
  const IOSSafeAreaView = Platform.select({ ios: SafeAreaView, default: View });
  const menu = [
    {
      icon: `profile`,
      title: `Profile`,
      onPress: () => {
        PendoTrackEvent(Events.PROFILE, {
          username: user?.handle,
          source: `Drawer`,
          element: `Profile item`,
        });
        navigation.navigate(`ProfileStack`, { screen: `Profile`, params: { user: undefined } });
      },
    },
    {
      icon: `invite`,
      title: `Invite Friends`,
      onPress: handleInviteFriendsPress,
    },
    {
      icon: `user-verification`,
      title: `Get Verified`,
      onPress: () => Linking.openURL(`http://mymesh.io/get-verified`),
      predicate: () => !user?.identity_verified,
    },
    {
      icon: `settings`,
      title: `Settings`,
      onPress: () => navigation.navigate(`DrawerSettings`),
    },
    {
      icon: `star`,
      title: `Mesh Ground Rules`,
      onPress: () => Linking.openURL(`https://www.meshcommunities.us/ground-rules`),
    },
    {
      icon: `eye-hide`,
      title: `Privacy Policy`,
      onPress: () => Linking.openURL(`https://www.meshcommunities.us/privacy-policy`),
    },
    {
      dividerBefore: true,
      icon: `logout`,
      title: `Log out`,
      onPress: logoutAll,
    },
  ].filter((item) => item.predicate === undefined || item.predicate());

  return (
    <IOSSafeAreaView style={{ flex: 1, borderRightWidth: StyleSheet.hairlineWidth, borderColor: Colors.mediumLightGray }}>
      <View style={{ flex: 1, marginRight: 1 /* to make room for border */ }}>
        <View>
          <View style={{ flexDirection: `row`, alignItems: `center` }}>
            {/* user avatar and persona menu */}
            <PersonaMenu personas={personas} />
          </View>
          {/* user name and mesh verified icon */}
          {!!user && !!user.name && (
            <View style={{ flexDirection: `row`, marginLeft }}>
              <Text style={Typography.text(`bold`)}>{user.name}</Text>
              {user.identity_verified && <MeshVerifiedCheck height={16} width={16} style={{ marginLeft: 4 }} />}
            </View>
          )}
          {/* user handle */}
          {!!user && !!user.handle && <Text style={{ marginLeft, marginTop: 2, ...Typography.text(`gray`) }}>{user.handle}</Text>}
          <TouchableOpacity
            style={{ flexDirection: `row`, marginLeft, marginTop: 10 }}
            onPress={() =>
              navigation.navigate(`ProfileStack`, {
                screen: `FollowInfoScreen`,
              })
            }
          >
            <Text>
              <Text style={Typography.text(`bold`)}>
                {(!followCountsLoading &&
                  followCounts?.getFollowCounts &&
                  numberWithComma(followCounts?.getFollowCounts?.following)) ||
                  0}
              </Text>
              <Text> following</Text>
            </Text>
            <Text style={{ marginLeft: 8 }}>
              <Text style={Typography.text(`bold`)}>
                {(!followCountsLoading &&
                  followCounts?.getFollowCounts &&
                  numberWithComma(followCounts?.getFollowCounts?.follwers)) ||
                  0}
              </Text>
              <Text> followers</Text>
            </Text>
          </TouchableOpacity>
        </View>
        {/* menu */}
        <FlatList
          style={{ marginTop: 13 }}
          data={menu}
          alwaysBounceVertical={false}
          keyExtractor={(item) => item.icon}
          renderItem={({ item }) => (
            <>
              {item.dividerBefore && <Divider />}
              <TouchableOpacity onPress={item.onPress} style={{ paddingVertical: 15 }} testID={`DRAWER_ITEM_${item.title}`}>
                <View style={{ flexDirection: `row`, alignItems: `center`, marginLeft }}>
                  <MeshIcon name={item.icon} size={24} style={{ marginRight: 13 }} />
                  <Text style={Typography.text(`bold`)}>{item.title}</Text>
                </View>
              </TouchableOpacity>
            </>
          )}
        />
      </View>
      {/* version info and update button */}
      <VersionInfo {...currentState} />
      <LoadingModal isVisible={loggingOut} content="Securely logging you out..." />

      <BottomSheetModal
        visible={showFriends && !!inviteUrl}
        title="Invite Friends"
        showCancelBtn={false}
        confirmTitle="Done"
        onPressConfirm={() => setShowFriends(false)}
        onPressCancel={() => setShowFriends(false)}
      >
        <InviteModal inviteUrl={inviteUrl} user={user} onClose={() => setShowFriends(false)} />
      </BottomSheetModal>
    </IOSSafeAreaView>
  );
};

const VersionInfo = React.memo(({ jsUpdateAvailable, downloading, checked, updateToLatestPublish }) => {
  const { publishID } = getVersionInfo();
  const { versionInfo: currentVersion } = useContext(AppContext);
  const manifest = getManifest();
  let release = ``;
  if (__DEV__) release = `dev`;
  else if (manifest && manifest.extra) {
    release = manifest.extra.publishID;
    if (checked && jsUpdateAvailable) release += ` (latest: ${currentVersion?.latestPublish})`;
    if (checked && !jsUpdateAvailable && publishID) release += ` (up-to-date)`;
  }

  let server = getEnvVariables().apiUrl.replace(/(meshconnect-|\.herokuapp\.com)/g, ``);
  if (!__DEV__) server = server.replace(`production`, ``);

  const versionInfo = [
    { label: `Beta`, value: release },
    { label: `Channel`, value: manifest.releaseChannel },
    { label: `Server`, value: server },
  ].filter((item) => item.value);

  const borderColor = downloading ? Colors.deepPurple : Colors.iconColor;
  const backgroundColor = downloading ? Colors.deepPurple : Colors.white;

  return (
    <View style={styles.VersionInfoContainer}>
      {/* update button, spins icon while updating */}
      {jsUpdateAvailable ||
        (true && (
          <View style={styles.buttonSpacer}>
            <NoThemeButton
              testID="DRAWER_UPDATE"
              title="Update"
              titleStyle={{ ...Typography.text(`small`, downloading ? `white` : `icon`), marginLeft: 4 }}
              containerStyle={[styles.updateButton, { borderColor, backgroundColor }]}
              color={backgroundColor}
              leftIcon={
                downloading ? (
                  <Animatable.View animation="rotate" easing="linear" duration={1000} iterationCount="infinite">
                    <MeshIcon name="reload" color={Colors.white} size={16} />
                  </Animatable.View>
                ) : (
                  <MeshIcon name="reload" color={Colors.iconColor} size={16} />
                )
              }
              onPress={updateToLatestPublish}
            />
          </View>
        ))}
      {/* version info */}
      {versionInfo.map((item) => (
        <View key={item.label} style={{ flexDirection: `row` }}>
          <Text allowFontScaling={false} style={styles.infoLabelLeft}>
            {item.label}:
          </Text>
          <Text allowFontScaling={false} style={styles.infoLabelRight}>
            {item.value}
          </Text>
        </View>
      ))}
    </View>
  );
});

VersionInfo.propTypes = {
  updateToLatestPublish: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  jsUpdateAvailable: PropTypes.bool,
};

VersionInfo.defaultProps = {
  jsUpdateAvailable: false,
};

const styles = StyleSheet.create({
  VersionInfoContainer: { justifyContent: `flex-end`, marginBottom: 5 },
  buttonSpacer: { flexDirection: `row`, justifyContent: `center`, marginBottom: 8 },
  updateButton: {
    borderWidth: 1,
    borderRadius: 22,
    paddingHorizontal: 12,
  },
  infoLabelLeft: { ...Typography.text(`gray`, `right`), width: 70, marginHorizontal: 5 },
  infoLabelRight: { ...Typography.text(`gray`), marginHorizontal: 5 },
});
