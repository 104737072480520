import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Alert, SafeAreaView, ScrollView, Text, View, TextInput, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useDebouncedCallback } from 'use-debounce';

import { hash_alpha, hash_beta } from '../common-auth';
import { Colors, Typography } from '../common-styles';
import {
  AuthMainText,
  AuthSubText,
  BackButton,
  HeaderSubmitButton,
  KeyboardPaddingView,
  LoadingIndicator,
  VisibleTag,
} from '../common-ui';
import Badwords from '../common-util/badwords';

import { client, CHECK_HANDLE, REGISTERV3 } from '../graphql';
import { checkAlpha, checkBeta } from './helpers';
import { AppContext } from '../../AppContext';
import { setProfileIdentity, setProfileUsername } from '../redux/createProfileSlice';
import { REGISTER_USERNAME, REGISTER_USERNAME_CONTINUE, REGISTERSCREEN } from './testLabels';
import { Events, PendoTrackEvent } from '../pendo/events';
import { AuthNavigationContainer } from '../common-types/navigation-types';

type UsernameNavigator = StackNavigationProp<AuthNavigationContainer, `Auth`>;

export const RegisterUsername = () => {
  const dispatch = useDispatch();

  const navigation = useNavigation<UsernameNavigator>();

  const { invite_token, setIdentity } = useContext(AppContext);

  const badwords = new Badwords();

  const [handle, setHandle] = useState<string>();
  const [handleIsValid, setHandleIsValid] = useState<boolean>();
  const [handleIsAvailable, setHandleIsAvailable] = useState<boolean>();
  const [handleIsProfane, setHandleIsProfane] = useState<boolean>();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      if (!handle) {
        setLoading(false);
        setHandleIsValid(false);
        return;
      }
      let generated_beta;
      let generated_salt;

      const alpha = hash_alpha(handle); // alpha hash the contact / sms used to sign up
      const { salts } = await checkAlpha(alpha, handle); // check if contact / sms are registered with us -- this is a GraphQL query
      const keysToTest = salts.map((salt: string) => {
        // What is salts??
        // check gen beta against salts in DB to find if ID exists already in table
        const beta = hash_beta(salt, handle, 10);
        generated_beta = beta;
        generated_salt = salt;
        return { beta, salt };
      });

      const betaChecked = await checkBeta(keysToTest);
      if (betaChecked) {
        const { id, has_password } = betaChecked;

        if (id && !has_password && handle) {
          PendoTrackEvent(Events.REGISTER_USERNAME, {
            usename: handle,
            invite_token,
          });
          // user has created account but not yet verified / created a password -- continue with regular account setup
          // therefore -- do not create identity account but proceed with account verification and persona creation
          navigation.navigate(`Auth`, { screen: `CreatePassword`, params: { identity_id: id, handle } });
          return;
        }
        if (id && has_password) {
          // id was returned and account is fully realized with password -- send to login
          navigation.navigate(`Auth`, { screen: `Login` });
          return;
        }
      }

      // no account registered with contact number -- let's register them
      // register and insert account into identity table in db
      const registerInput = {
        alpha,
        beta: generated_beta,
        salt: generated_salt,
        cleartext: handle,
        invite_token: invite_token || ``,
      };

      const { data } = await client.mutate({
        mutation: REGISTERV3,
        variables: { input: registerInput },
        fetchPolicy: `no-cache`,
      });

      const identity = data.registerV3;
      if (!identity?.id) throw new Error(`Account not registered yet`);
      setIdentity(identity);

      dispatch(setProfileIdentity(identity.id));
      dispatch(setProfileUsername(handle));

      navigation.navigate(`Auth`, { screen: `CreatePassword`, params: { identity_id: identity.id, handle } });
    } catch (err: any) {
      let message = `There was a server problem when registering your account. Please try again later.`;
      if (err.message.includes(`reached invite limit`)) message = `Invite token has reached invite limit.`;

      Alert.alert(message, undefined, undefined, { cancelable: true });
    } finally {
      setLoading(false);
    }
  }, [dispatch, handle, invite_token, navigation, setIdentity]);

  const onHandleChange = useDebouncedCallback((text) => {
    setHandle(text.trim());

    if (text) {
      checkHandleAvailability(text.trim());
    } else {
      setHandleIsValid(undefined);
      setHandleIsAvailable(undefined);
      setHandleIsProfane(undefined);
    }
  }, 500);

  const checkHandleAvailability = async (handle: string) => {
    const validHandleRegex = /^[a-zA-Z][a-zA-Z0-9_]{1,19}$/;
    const valid = validHandleRegex.test(handle);

    if (valid) {
      try {
        setHandleIsValid(true);

        // check the username includes a profane word or not
        if (badwords.isProfane(handle)) {
          setHandleIsProfane(true);
          return;
        }

        const { data } = await client.query({ query: CHECK_HANDLE, variables: { handle } });
        const { is_valid } = data.checkHandleAvailability;
        setHandleIsAvailable(is_valid);
        setHandleIsProfane(false);
      } catch (err) {
        Alert.alert(`Username availability check failed.`, undefined, undefined, { cancelable: true });
      }
    } else {
      setHandleIsValid(false);
      setHandleIsAvailable(undefined);
      setHandleIsProfane(undefined);
    }
  };

  useEffect(() => {
    const goBack = () => {
      PendoTrackEvent(Events.REGISTER_BACK, { invite_token });
      navigation.goBack();
    };
    navigation?.setOptions({
      headerLeft: () => (loading ? undefined : <BackButton onPress={goBack} />),
      headerRight: () => (
        <HeaderSubmitButton
          title="Continue"
          testID={REGISTER_USERNAME_CONTINUE}
          onPress={onSubmit}
          disabled={!(handleIsValid && handleIsAvailable && !handleIsProfane) || loading}
        />
      ),
    });
  }, [navigation, loading, handleIsValid, handleIsAvailable, handleIsProfane, onSubmit, invite_token]);

  const baseInputStyle = {
    padding: 10,
    borderRadius: 4,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    marginHorizontal: 0,
  };
  const validusernameStyleMixin = {
    borderColor: Colors.brandGreen,
  };
  const invalidusernameStyleMixin = {
    borderColor: Colors.brightRed,
  };
  let inputStyle = baseInputStyle;
  if (handleIsAvailable === false || handleIsValid === false || handleIsProfane === true)
    inputStyle = { ...baseInputStyle, ...invalidusernameStyleMixin };
  if (handleIsAvailable === true && handleIsValid === true && !handleIsProfane)
    inputStyle = { ...baseInputStyle, ...validusernameStyleMixin };

  const nextDisabled = !handleIsValid || !handleIsAvailable || handleIsProfane || !handle || (handle?.length || 0) > 1;

  return (
    <KeyboardPaddingView>
      <SafeAreaView testID={REGISTERSCREEN} style={{ flex: 1 }}>
        {loading ? (
          <View style={{ width: `100%`, height: `100%`, flex: 1, justifyContent: `center`, alignItems: `center` }}>
            <LoadingIndicator size={32} style={{ margin: 32 }} />
          </View>
        ) : (
          <ScrollView
            style={localStyles.container}
            keyboardShouldPersistTaps="handled"
            testID={UsernameTestIds.screen}
            alwaysBounceVertical={false}
          >
            <View>
              <View style={localStyles.blob} />
              <AuthMainText text="Welcome to mesh!" />
              <AuthSubText text="Create a unique username between 2-20 characters" style={localStyles.subtext} />
            </View>
            <TextInput
              autoFocus
              blurOnSubmit
              autoCapitalize="none"
              textContentType="none"
              autoCorrect={false}
              maxLength={20}
              multiline={false}
              defaultValue={handle}
              style={inputStyle}
              placeholder="Enter your username"
              placeholderTextColor={Colors.textPlaceholder}
              onChangeText={onHandleChange}
              onSubmitEditing={() => !nextDisabled && onSubmit()}
              returnKeyType="done"
              testID={REGISTER_USERNAME}
            />
            {!!handle && !!handle.length && (
              <ValidationMessage valid={handleIsValid} available={handleIsAvailable} profane={handleIsProfane} />
            )}
            <View
              style={{ width: `100%`, display: `flex`, flexDirection: `row`, alignItems: `center`, justifyContent: `flex-end` }}
            >
              <AuthSubText text={`${!handle ? 0 : handle.length}/20 Characters`} style={{ marginTop: 5 }} />
            </View>
            <View style={{ marginTop: 8 }}>
              <VisibleTag infoText="Your Mesh handle is visible when you post in communities." />
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};
interface ValidationMessageProps {
  valid?: boolean;
  available?: boolean;
  profane?: boolean;
}

const ValidationMessage: React.FC<ValidationMessageProps> = ({ valid, available, profane }) => {
  if (valid && available && !profane)
    return (
      <View style={{ backgroundColor: Colors.brandGreen, padding: 10, borderRadius: 4, marginTop: 10 }}>
        <Text style={{ fontSize: Typography.smallFontSize, color: Colors.white }}>Looks good!</Text>
      </View>
    );
  if (valid === false || available === false || profane) {
    let text = ``;
    if (profane) text = `Your username is not available.`;
    if (available === false) text = `The handle you have selected is already in use. Please try another one.`;
    if (valid === false)
      text = `Your username must be 2-20 characters, starts with a letter and contain only letters, numbers, underscores, and no spaces.`;

    return (
      <View style={localStyles.errorContainer} testID={UsernameTestIds.error}>
        <Text style={localStyles.errorText}>{text}</Text>
      </View>
    );
  }
  return null;
};

export const UsernameTestIds = {
  screen: `USERNAMESCREEN`,
  username: `USERNAMESCREEN_USERNAME`,
  error: `USERNAMESCREEN_ERROR`,
  continue: `USERNAMESCREEN_CONTINUE`,
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  subtext: {
    fontSize: Typography.smallFontSize,
    color: Colors.gray,
    marginTop: 30,
    marginLeft: 10,
    marginBottom: 5,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: Colors.chartreuse,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
  errorContainer: {
    marginTop: 10,
  },
  errorText: {
    ...Typography.smallText,
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    color: Colors.brightRed,
  },
});
