// @ts-nocheck
import React, { useCallback, useState, useContext } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
  Alert,
  Image,
  ActivityIndicator,
  ListRenderItem,
  TextInput,
  useWindowDimensions,
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { ReactNativeFile } from 'apollo-upload-client';
import FastImage from 'react-native-fast-image';
import { useFocusEffect } from '@react-navigation/native';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { pickImages, generateApolloFile, pickExpoVideo } from '../common-util/FilePicker';
import { Colors, Typography } from '../common-styles';
import { Avatar, ListItem, MeshIcon, ThemedButton } from '../common-ui';
import { Select } from '../common-ui/Select';
import useMediaPermissons from '../hooks/useMediaPermissions';
import { VideoPlayer } from '../post/components/VideoPlayer';
import { ADD_HERO_ASSET, GET_GROUPS_BY_NAME } from '../graphql';
import { GroupCoreFields, GetGroupByName } from '../common-types/types';
import { AppContext } from '../../AppContext';
import { MediaTypes } from '../post/helpers';

export const HeroAreaMediaTool = () => {
  const { checkPermission } = useMediaPermissons();
  const { user } = useContext(AppContext);
  const [imageToUpload, setImageToUpload] = useState<ReactNativeFile>();
  const [videoToUpload, setVideoToUpload] = useState<ReactNativeFile[]>();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(``);
  const [showPickGroupModal, setShowPickGroupModal] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState<GroupCoreFields>();
  const [search, setSearch] = useState(``);

  const [searchCommunity, { data: groupData }] = useLazyQuery<GetGroupByName>(GET_GROUPS_BY_NAME);
  const [addHeroAsset, { loading: loadingHeroAsset }] = useMutation(ADD_HERO_ASSET);

  const pickImage = async () => {
    try {
      const isGranted = await checkPermission();
      if (!isGranted) return;

      pickImages(({ assets, didCancel, errorCode, errorMessage }) => {
        if (didCancel) {
          setProgress(``);
          return;
        }
        if (errorCode || errorMessage) {
          Alert.alert(`Error`, errorMessage);
          setProgress(``);
          return;
        }
        const [asset] = assets || [];
        if (asset) {
          const uploadFile = new ReactNativeFile({
            uri: asset?.uri || ``,
            type: asset.type,
            name: uuidv4(),
          });
          setImageToUpload(uploadFile);
          // We only want the user to send one type of media not two at the same time
          setVideoToUpload(undefined);
        }
      });
    } catch (error) {
      console.error(`Error while adding photo:`, error);
    } finally {
      setLoading(false);
    }
  };

  const addVideo = async () => {
    setLoading(true);
    try {
      const isGranted = checkPermission();
      if (!isGranted) return;

      await pickExpoVideo(
        ({ assets, didCancel, errorCode, errorMessage }) => {
          if (didCancel) {
            setProgress(``);
            return;
          }
          if (errorCode || errorMessage) {
            Alert.alert(errorMessage!);
            setProgress(``);
            return;
          }
          const [asset] = assets;
          if (asset.duration < 300) {
            const urls = [];
            const video = generateApolloFile(asset);
            urls.push(video);
            if (asset.thumbnail) {
              const thumbnail = generateApolloFile({ uri: asset.thumbnail });
              urls.push(thumbnail);
            }
            setVideoToUpload(urls);
            setProgress(``);
            // We only want the user to send one type of media not two at the same time
            setImageToUpload(undefined);
          } else {
            Alert.alert(`Submitted video exceeds 5 minutes.`);
            setProgress(``);
          }
        },
        (current_progress) => {
          const percent = `${current_progress}%`;
          if (percent !== progress) setProgress(percent);
        },
      );
    } catch (error) {
      console.error(`Error while adding video:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddHeroAsset = async () => {
    try {
      const input = {
        group_id: selectedCommunity?.id,
        file: imageToUpload || videoToUpload[0],
        media_type: imageToUpload ? MediaTypes.IMAGE : MediaTypes.VIDEO,
        // end_date: undefined,
      };

      const { data } = await addHeroAsset({ variables: { input } });
      if (data?.addHeroAsset) {
        setSelectedCommunity(undefined);
        setImageToUpload(undefined);
        setVideoToUpload(undefined);
      }
    } catch (error) {
      console.error(`Something went wrong while trying to add a hero asset - HeroAreaMediaTool.tsx ${error}`);
    }
  };

  const onTextChanged = (text: string) => {
    setSearch(text);
    if ((text.length || 0) > 2) searchBy(text);
  };
  // Must be debounced to prevent refetching on every typing ~500ms
  const searchBy = useDebouncedCallback((group_name: string) => {
    searchCommunity({
      variables: { group_name, user_id: user?.id, limit: 15, offset: 0 },
    });
  }, 500);

  const renderGroupOptions: ListRenderItem<GroupCoreFields> = ({ item }) => {
    return (
      <View style={{ flex: 1, flexDirection: `row`, alignItems: `center`, margin: 12 }}>
        <Avatar size={32} localImage={item.avatar_url} />
        <Text style={{ marginLeft: 12 }}>{item.name}</Text>
      </View>
    );
  };

  return (
    <View style={localStyles.block}>
      <Text style={localStyles.label}>Media</Text>
      <Text style={localStyles.subLabel}>it is recommended that the file you upload has the image format (16:9)</Text>
      <View style={localStyles.imageInput}>
        <TouchableOpacity onPress={pickImage}>
          <MeshIcon name="image" size={24} color={Colors.iconColor} style={localStyles.iconMargins} />
        </TouchableOpacity>
        <TouchableOpacity onPress={addVideo}>
          <MeshIcon name="video" size={24} color={Colors.iconColor} style={localStyles.iconMargins} />
        </TouchableOpacity>
      </View>

      <Text style={localStyles.label}>Origin group</Text>
      <Text style={localStyles.subLabel}>If someone press the banner he will be redirected to the origin group</Text>
      <ListItem
        title={selectedCommunity?.name}
        containerStyle={localStyles.imageInput}
        onPress={() => setShowPickGroupModal(true)}
      />

      {loading ? (
        <View style={{ justifyContent: `center`, alignItems: `center` }}>
          <ActivityIndicator />
          <Text style={localStyles.progressText}>{progress}</Text>
        </View>
      ) : null}

      {imageToUpload?.uri ? (
        <View>
          <Text style={localStyles.label}>Preview</Text>
          <View style={localStyles.fileContainer}>
            <Text style={localStyles.fileName}>{imageToUpload?.name}</Text>
            <MeshIcon name="circle-x" color={Colors.brandPurple} size={18} onPress={() => setImageToUpload(undefined)} />
          </View>
          <Image source={{ uri: imageToUpload?.uri }} style={localStyles.image} />
        </View>
      ) : null}

      {videoToUpload?.length ? (
        <View>
          <Text style={localStyles.label}>Preview</Text>
          <View style={localStyles.fileContainer}>
            <Text style={localStyles.fileName}>{videoToUpload[0]?.name}</Text>
            <MeshIcon name="circle-x" color={Colors.brandPurple} size={18} onPress={() => setVideoToUpload(undefined)} />
          </View>
          <PreviewVideo videoToUpload={videoToUpload} />
        </View>
      ) : null}

      <ThemedButton
        rounded
        title="Confirm"
        buttonStyle={localStyles.submit}
        titleStyle={{ marginRight: 10 }}
        containerStyle={{ marginTop: 20 }}
        onPress={handleAddHeroAsset}
        rightIcon={loadingHeroAsset ? <ActivityIndicator /> : null}
        disabled={loadingHeroAsset || !selectedCommunity?.id || (!imageToUpload && !videoToUpload)}
      />
      <Select<GroupCoreFields>
        title="Select the origin group"
        ListHeaderComponent={<SearchHeader value={search} setValue={onTextChanged} />}
        options={groupData?.getGroupsByName?.map((g) => g.group) || []}
        visible={showPickGroupModal}
        renderOption={renderGroupOptions}
        onCancel={() => setShowPickGroupModal(false)}
        onSave={(selected) => setSelectedCommunity(selected[0])}
      />
    </View>
  );
};

type SearchHeaderProps = {
  value?: string;
  setValue: (s: string) => void;
};

const SearchHeader: React.FC<SearchHeaderProps> = ({ value, setValue }) => {
  return (
    <View style={{ flex: 1, padding: 12 }}>
      <Text style={{ marginBottom: 12 }}>Redirect to Community</Text>
      <TextInput
        onSubmitEditing={(e) => setValue(e.nativeEvent.text)}
        placeholder="Search communities"
        placeholderTextColor={Colors.textPlaceholder}
        style={localStyles.imageInput}
        testID="SEARCH_BAR"
        onChangeText={(text) => setValue(text)}
        value={value}
      />
    </View>
  );
};

type PreviewVideoProps = {
  videoToUpload?: {
    uri: string;
    name: string;
    type: string;
  }[];
};
const PreviewVideo: React.FC<PreviewVideoProps> = ({ videoToUpload }) => {
  const { width } = useWindowDimensions();
  const [showVideo, setShowVideo] = useState(false);
  const setVideoDisplayed = () => setShowVideo(true);
  useFocusEffect(
    useCallback(() => {
      return () => {
        if (showVideo) setShowVideo(false);
      };
    }, [showVideo]),
  );
  if (!videoToUpload?.length) return null;

  const videoDim = { width, height: (width * 9) / 16 };
  const [videoMedia] = videoToUpload;
  return showVideo ? (
    <VideoPlayer
      width={videoDim.width}
      height={videoDim.height}
      video_url={videoMedia?.uri}
      onScreen={true}
      onLayout={() => {}}
    />
  ) : (
    <View style={{ position: `relative`, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
      <FastImage
        style={{ ...videoDim, backgroundColor: Colors.black }}
        source={{ uri: videoToUpload[1]?.uri }}
        resizeMode="cover"
      />
      <View style={{ position: `absolute` }}>
        <MeshIcon
          name="play-circle"
          size={62}
          color={Colors.white}
          onPress={setVideoDisplayed}
          style={{ backgroundColor: Colors.black, borderRadius: 31, overflow: `hidden` }}
        />
      </View>
    </View>
  );
};

const localStyles = StyleSheet.create({
  block: {
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
    marginHorizontal: 16,
    marginBottom: 20,
  },
  label: { ...Typography.text(`plusone`, `gray`), marginBottom: 6 },
  subLabel: { ...Typography.text(`small`, `gray`), marginBottom: 10 },
  imageInput: {
    flexDirection: `row-reverse`,
    borderRadius: 4,
    borderColor: Colors.mediumGray,
    borderWidth: 1,
    minHeight: 40,
    marginBottom: 20,
    paddingHorizontal: 6,
  },
  iconMargins: {
    marginLeft: 8,
    marginVertical: 12,
    marginRight: 11,
  },
  image: {
    flex: 1,
    resizeMode: `contain`,
    minHeight: 200,
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 200,
  },
  fileContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    marginBottom: 12,
  },
  fileName: {
    ...Typography.text(`small`, `gray`),
    marginRight: 10,
  },
  submit: { paddingVertical: 12, paddingHorizontal: 24, margin: 16 },
  progressText: { minWidth: 60, padding: 10, ...Typography.text(`plustwo`, `bold`, `darkGray`, `center`) },
});
