import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { FlatList, Text, TouchableOpacity, View, Alert } from 'react-native';
import React, { useCallback, useEffect, useState, memo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { CommunityListItem } from '../feed/CommunityListItem';
import { commonPropTypes } from '../common-util';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { Divider, ErrorBoundary, MeshIcon } from '../common-ui';
import { UPDATE_SETTING_MUTATION, GET_OWN_HIDDEN_COMMUNITIES } from '../graphql';

export const HiddenCommunitiesItem = () => {
  const navigation = useNavigation();
  const navigateToHiddenCommunitiesList = useCallback(() => navigation.push(`HiddenCommunitiesList`), [navigation]);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <TouchableOpacity
        testID="HIDDEN_COMMUNITIES_LIST"
        activeOpacity={0.8}
        onPress={navigateToHiddenCommunitiesList}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, padding: 16 }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Hidden Nodes</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const HiddenCommunitiesList = memo(({ navigation }) => {
  const [hiddenCommunities, setHiddenCommunities] = useState([]);
  const { user } = React.useContext(AppContext);

  const { data, error } = useQuery(GET_OWN_HIDDEN_COMMUNITIES, {
    variables: {
      user_id: user.id,
    },
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });

  // Effect #1: set data from query
  useEffect(() => {
    setHiddenCommunities(data && data.getHiddenCommunities);
  }, [data]);

  const [updateSetting, _response1] = useMutation(UPDATE_SETTING_MUTATION, {
    refetchQueries: [
      {
        query: GET_OWN_HIDDEN_COMMUNITIES,
        variables: {
          user_id: user.id,
        },
      },
    ],
  });

  const _keyExtractor = (item, index) => item.id + index.toString();

  const renderItem = useCallback(
    ({ item: group }) => {
      return <GroupCard navigation={navigation} group={group} unHideCommunity={unHideCommunity} />;
    },
    [navigation, unHideCommunity],
  );

  const removeFromNotToRecommend = useCallback(
    (group_id) => {
      let blacklist = hiddenCommunities.map((group) => group.id) || `[]` || [];

      const blackListSet = new Set(blacklist);
      let value = `[]`;
      if (group_id && blackListSet.has(group_id)) {
        blacklist = blacklist.filter((item) => item !== group_id);
        const newCommunitiesList = hiddenCommunities.filter((group) => group.id !== group_id);
        setHiddenCommunities(newCommunitiesList);
      }
      value = JSON.stringify(blacklist);
      const input = {
        value,
        key: `user.setting.blacklist`,
        user_id: user.id,
      };

      updateSetting({ variables: { input } });
    },
    [hiddenCommunities, user.id, updateSetting],
  );

  const unHideCommunity = useCallback(
    (group_id) => {
      Alert.alert(
        `Are you sure?`,
        `This community will appear in your recommendations again`,
        [
          {
            text: `Cancel`,
            style: `cancel`,
          },
          {
            text: `OK`,
            style: `default`,
            onPress: () => {
              removeFromNotToRecommend(group_id);
            },
          },
        ],
        { cancelable: true },
      );
    },
    [removeFromNotToRecommend],
  );

  return (
    <View>
      {!error ? (
        <FlatList
          testID="HIDDEN_COMMUNITY_LIST"
          data={hiddenCommunities || []}
          keyExtractor={_keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={Divider}
          ListEmptyComponent={() => (
            <View style={{ flex: 1, justifyContent: `center`, marginTop: 20 }}>
              <Text testID="EMPTY_LIST" style={Typography.text(`gray`, `center`)}>
                You have no hidden communities.
              </Text>
            </View>
          )}
        />
      ) : (
        <ErrorBoundary header="Error loading hidden communities">
          <View style={{ justifyContent: `center` }}>
            <Text style={Typography.text(`plustwo`, `gray`, `bold`, `center`)}>Error loading hidden communities</Text>
          </View>
        </ErrorBoundary>
      )}
    </View>
  );
});

HiddenCommunitiesList.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

const GroupCard = memo(({ navigation, group, unHideCommunity }) => {
  return (
    <View style={{ flexDirection: `row`, justifyContent: `center`, alignContent: `center`, marginLeft: -11 }}>
      <View style={{ flex: 1 }}>
        <CommunityListItem navigation={navigation} group={group} hide_category showMemberPill />
      </View>
      <View style={{ justifyContent: `center` }}>
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => {
            unHideCommunity(group.id);
          }}
          testID="UNHIDE_COMMUNITY"
        >
          <View style={{ marginRight: 16 }}>
            <Text style={Typography.text(`bold`, `gray`)}>Unhide</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
});

GroupCard.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  group: commonPropTypes.group().isRequired,
  unHideCommunity: PropTypes.func.isRequired,
};
