import { useNavigation } from '@react-navigation/native';
import React from 'react';
import ParsedText from 'react-native-parsed-text';
import { Text } from 'react-native';
import { Colors, Typography } from '../common-styles';
import { formatShortRelativeDate } from '../common-util';
import { BaseNotificationItem } from './BaseNotificationItem';
import { NotificationNavigator } from '../common-types/navigation-types';
import { MeshVerifiedCheck } from '../images';

const allowedNotifications = {
  groupMemberRoleUpdate: `group_member_role_update`,
};

interface INotificationItem {
  id: string;
  verb: string;
  created_at: string;
  preview: string;
  is_read: boolean;
  is_seen: false;
  activities: any;
}

interface IRoleUpdateNotificationItem {
  notification: INotificationItem;
  markRead: (id: string) => void;
}

export const allowed_types = Object.values(allowedNotifications);

export const RoleUpdateNotificationItem: React.FC<IRoleUpdateNotificationItem> = ({ notification, markRead }) => {
  const { is_read, id, created_at } = notification;
  const [activity] = notification.activities || {};
  const { user: actor, origin_group, origin_group_member, object } = activity;

  const navigation = useNavigation<NotificationNavigator | any>();

  const onPress = () => {
    if (!is_read) markRead(id);
    if (origin_group?.id) navigation.navigate(`MemberInfo`, { groupId: origin_group?.id });
  };
  const _subject = object.substring(0, object.indexOf(` `));
  const _object = object.substring(object.indexOf(` `) + 1);
  const isTransitive = _subject.match(actor?.handle);

  const name = isTransitive ? actor?.handle : origin_group_member?.persona?.handle;
  const verified = isTransitive ? actor?.isVerified : origin_group_member?.persona?.isVerified;

  const renderTitle = () => {
    return (
      <Text style={{ flexDirection: `row`, alignItems: `center`, flex: 1 }}>
        <ParsedText
          style={Typography.text()}
          maxFontSizeMultiplier={1.8}
          parse={[
            {
              pattern: new RegExp(`(${name})`, `gm`),
              style: Typography.add(`bold`),
              onPress: () => navigation.navigate(`Profile`, { user: isTransitive ? actor : origin_group?.persona }),
            },
          ]}
        >
          {_subject}
        </ParsedText>
        {` `}
        {verified && (
          <>
            <MeshVerifiedCheck height={14} width={14} />
            {`  `}
          </>
        )}
        <Text>
          <ParsedText
            style={Typography.text()}
            maxFontSizeMultiplier={1.8}
            parse={[
              {
                pattern: new RegExp(`(${origin_group.name}|owner|moderator|manager|contributor)`, `gm`),
                style: Typography.add(`bold`),
              },
            ]}
          >
            {_object}
          </ParsedText>
        </Text>
      </Text>
    );
  };

  const getBadge = () => {
    switch (notification.verb) {
      case allowedNotifications.groupMemberRoleUpdate:
        return `sparkle`;
      default:
        return null;
    }
  };

  return (
    <BaseNotificationItem
      containerStyle={{
        backgroundColor: notification.is_read ? Colors.seenNotificationBackground : Colors.unseenNotificationBackground,
      }}
      title={renderTitle()}
      date={formatShortRelativeDate(created_at)}
      avatarData={actor}
      avatarType="persona"
      navigation={navigation}
      badge={getBadge()}
      onPress={onPress}
      onPressOptionsButton={() => console.log(`press option...`)}
      badgeSize={22}
    />
  );
};
