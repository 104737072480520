import { Linking } from 'react-native';
import * as Notifications from 'expo-notifications';

export const linking = {
  enabled: true,
  prefixes: [`mesh://`, `mesh-dev://`, `https://app.meshconnect.us/`, `https://beta.meshconnect.us/`, `https://app.mymesh.io/`],
  config: {
    screens: {
      Main: {
        path: ``,
        screens: {
          RightDrawer: {
            path: ``,
            screens: {
              MainTabs: {
                path: ``,
                screens: {
                  HomeNavigator: {
                    path: ``,
                    initialRouteName: `HomeScreen`,
                    screens: {
                      HomeScreen: `community/:group_id`,
                      CreateCommunity: `community/create`,
                      CommunityApplication: `community/:group_id/application`, // needs complete group
                      CommunitySettings: `community/:group_id/settings`, // needs complete group and group_member
                      BankAccount: `createbankaccount/:group_id`,
                      StripeProccessFinishedScreen: `finishedstripeprocess/:group_id`,
                      MemberInfo: `community/:group_id/members`,
                      BannedUsersList: `community/:group_id/banned`,
                      ModerationPNReceiver: `moderation/:group_id`,
                      PostDetail: `post/:id/:group_id`,
                      PostReactionUserList: `post/:activity_id/likes`,
                      PostCommunity: `post/create`,
                      DrawerSettings: `DrawerSettings`,
                      UserCommunityPostNotificationSettings: `DrawerSettings/pushnotifications`,
                      UpdatePassword: `DrawerSettings/updatePassword`,

                      NotificationsSettings: `notifications/settings`,
                    },
                  },
                  ExploreNavigator: {
                    initialRouteName: `Explore`,
                    screens: {
                      LandingLoaderScreen: `invitation/:community_name/:invite_token`,
                      LandingLoaderScreenCompat: `invitation/:invite_token`,
                      // CommunityPreview: `invitation/:community_name/:invite_token`,
                      // CommunityPreviewCompat: `invitation/:invite_token`, // repeated screen to handle different DeepLink structures
                    },
                  },
                  ChatNavigator: {
                    path: `chat`,
                    screens: {
                      ChatThread: `:channel_id/:channel_type`,
                    },
                  },
                  HackNavigator: `wip`,
                },
              },
            },
          },
          ProfileStack: {
            path: `profile`,
            screens: {
              ProfileCompat: `invite/:user_id`,
              Profile: `invite/:user_name/:user_id`,
            },
          },
        },
      },
      //NotFound: `*`,
      // 404 - kind of page, should handle whatever it gets example: invite links, broken deep links, etc
    },
  },
  async getInitialURL() {
    const url = await Linking.getInitialURL();
    return `mesh://${url}`;
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }) => {
      console.log(`🚀 [MeshLinking] onReceiveURL -> url`, url);
      return listener(url);
    };
    Linking.addEventListener(`url`, onReceiveURL);
    const unsubscribeNotification = Notifications.addNotificationResponseReceivedListener(async (response) => {
      //console.log(`[MeshLinking] handling PushNotificationTap `, response);
      try {
        // get and set badge number
        const badgeCount = await Notifications.getBadgeCountAsync();
        const setBadgeCount = await Notifications.setBadgeCountAsync(badgeCount);
        console.log(`[MeshLinking] set PN app badge count`, setBadgeCount);
      } catch (err) {
        console.error(`[MeshLinking] Error thrown in setPushNotificationBadge`, err.message);
      }
      if (response.notification) {
        const pushNotificationData = response.notification.request.content.data;
        console.log(`🚀 [MeshLinking] handlePushNotificationTap -> pushNotificationData`, pushNotificationData);
        listener(`mesh://${pushNotificationData.url}`);

        // const onReceiveURL = ({ url }) => {
        //   console.log(`🚀 [MeshLinking] onReceiveURL -> url`, `https:/app.mymesh.io/${url}`);
        //   return listener(`https://app.mymesh.io/${url}`);
        // };
        // Linking.addEventListener(`url`, onReceiveURL);
        // return () => Linking.removeEventListener(`url`, onReceiveURL);
        //handlePushNotificationUrl(pushNotificationData);
      }
    });

    return () => {
      Linking.removeEventListener(`url`, onReceiveURL);
      unsubscribeNotification.remove();
    };
  },
};
// xcrun simctl openurl booted https://app.mymesh.io/ ...
// adb shell am start -W -a android.intent.action.VIEW -d https://app.mymesh.io/... us.meshconnect.mesh.dev

// Profile Invite: https://app.mymesh.io/profile/invite/e035a9cf-bd36-4bc6-9e5e-38faa39b5c67
//                 mesh://profile/invite/e035a9cf-bd36-4bc6-9e5e-38faa39b5c67
// New Profile Invite: https://app.mymesh.io/profile/invite/EstebanE/621a0b70-644a-4c3e-8e38-4041f17b7a52
//                 mesh://profile/invite/EstebanE/621a0b70-644a-4c3e-8e38-4041f17b7a52

// params: {invitation: true, token}
// https://app.mymesh.io/?invitation&token=fe342cf5-1e52-4b6a-8231-b5655e11681c

// xcrun simctl openurl booted https://app.mymesh.io/invitation/fe342cf5-1e52-4b6a-8231-b5655e11681c

/*
[MeshLinking] handlePushNotificationTap -> pushNotificationData {
  "activity_id": "", 
  "group_id": "e65d3e3e-c014-49b9-968f-e5398f1e483d", 
  "origin_actor_id": "58f67c6d-1751-4fb3-b93d-2199cb727e60", 
  "url": "moderation", 
  "verb": "report"
}


post pushNotificationData: {
  "activity_id": "8987e310-49d1-11ed-8080-80005258bce3", 
  "group_id": "5955fc10-5ede-4a8c-83f9-f877e9e67e38", 
  "origin_actor_id": "c4278236-d375-46a6-a839-be5994636eef", 
  "url": "https://app.mymesh.io/post/8987e310-49d1-11ed-8080-80005258bce3/5955fc10-5ede-4a8c-83f9-f877e9e67e38", 
  "verb": "post"
}
*/
