import { useRef, useContext, useCallback } from 'react';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
//import AlertAsync from 'react-native-alert-async';
import { Platform } from 'react-native';
import { AppContext } from '../../../AppContext';
import { getEnvVariables, appVersionIsAtLeast } from '../../../environment';
import { getChatToken } from '../chatServices';
import { chatClient } from '../chatClient';
import { Colors } from '../../common-styles';

export const CHAT_STATUS = {
  disconnected: `disconnected`,
  initialized: `initialized`,
};

const removeReservedFields = (user) => {
  const chatUser = { ...user };
  delete chatUser.password;
  delete chatUser.created_at;
  delete chatUser.updated_at;
  delete chatUser.deleted_at;
  return chatUser;
};

/* 
TODO:
- update chat client channels on new channel created
*/

export const useChatService = () => {
  const pushTokenSubscription = useRef(null);

  const { user, chatPushToken, setChatPushToken, setChatNotifications, setChatStatus } = useContext(AppContext);

  const disconnectChat = useCallback(async () => {
    try {
      if (chatClient) {
        setChatStatus(CHAT_STATUS.disconnected);
        await Promise.all([unregisterDeviceForPushNotifications(), chatClient.disconnect()]);
      }
    } catch (error) {
      console.error(`[Chat] Error in disconnectChat()`, error.message);
    }
  }, [setChatStatus, unregisterDeviceForPushNotifications]);

  const unregisterDeviceForPushNotifications = useCallback(async () => {
    if (!getEnvVariables().newChat || !appVersionIsAtLeast(`1.0.14`) || !Notifications) {
      console.warn(`[Chat] file: chatHelpers.js app version is not >= 1.0.14`);
      return;
    }
    try {
      if (!chatPushToken || !user?.id) return;

      await chatClient.removeDevice(chatPushToken, user?.id);
      // cancel subscription
      pushTokenSubscription.current?.remove();

      // clean token in global state
      setChatPushToken(undefined);
    } catch (error) {
      console.error(`[Chat] file: useChatService.js error thrown in unregisterDeviceForPushNotifications`, error.message);
    }
  }, [chatPushToken, setChatPushToken, user?.id]);

  const registerForChat = useCallback(async () => {
    if (!getEnvVariables().newChat) return;
    try {
      if (user?.id) {
        const chatToken = await getChatToken();
        const chatUser = removeReservedFields(user);
        const streamChatUser = await chatClient.connectUser(chatUser, chatToken);
        if (streamChatUser) {
          // FIXME:
          // Warning: Can't perform a React state update on an unmounted component.
          // This is a no-op, but it indicates a memory leak in your application.
          // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
          setChatStatus(CHAT_STATUS.initialized);
          setChatNotifications(streamChatUser.me.total_unread_count);
          registerDeviceForPushNotifications();
          chatClient.on((e) => {
            if (e.type === `connection.recovered`) {
              console.log(`[Chat] connection recovered`);
            }
            if (e.unread_channels) {
              console.log(`[Chat] new channel alerts available`, e.unread_channels);
            }
          });

          // chatClient.on(`notification.mark_read`, (e) => {
          //   console.log(`[Chat] chat mark as read`, e.total_unread_count);
          //   setChatNotifications(e.total_unread_count);
          // });

          chatClient.on(`notification.channel_deleted`, (e) => {
            console.log(`[Chat] chat deleted`, e.total_unread_count);
            setChatNotifications(e.total_unread_count);
          });
        }
      }
    } catch (err) {
      const error = err.message.startsWith(`{`) ? JSON.parse(err.message) : err;
      console.error(`[Chat] Error in useChatService->registerForChat()`, error);
      //const message = error.code === 43 ? `Connect failed with JWTAuth error` : error.message;
      //FIXME: client.disconnect spam while handling PN
      //await AlertAsync(`error in useChatService->registerForChat()`, `${message}`);
    }
  }, [user, setChatNotifications, setChatStatus, registerDeviceForPushNotifications]);

  const registerDeviceForPushNotifications = useCallback(async () => {
    console.log(`useChatService -> registerDeviceForPushNotifications`);
    if (!getEnvVariables().newChat || !appVersionIsAtLeast(`1.0.14`) || !Notifications) {
      console.log(`file: useChatService.js app version is not >= 1.0.14`);
      return;
    }
    if (chatPushToken) {
      console.log(`=== file: useChatService.js device already registered: return ===`);
      return;
    }

    try {
      if (!Constants.isDevice) return;
      Notifications.getPermissionsAsync();
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== `granted`) {
        console.log(`=== file: useChatService.js PN not granted ===`);
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== `granted`) {
        console.log(`=== file: useChatService.js failed to return push token for push notification`);
        return;
      }

      console.log(`=== file: useChatService.js PN permissions granted: fetch Expo Push Token ===`);

      pushTokenSubscription.current = Notifications.addPushTokenListener((token) => registerDevice(token.data));
    } catch (err) {
      console.error(`file: useChatService.js error thrown in registerForPushNotifications in chatHelpers.js`, err.message);
    }
  }, [chatPushToken, registerDevice]);

  const registerDevice = useCallback(
    async (deviceId) => {
      console.log(`[Register] useChatService -> registerDevice`);
      try {
        // console.log(`[Chat] === file: chatHelpers.js PN token ===`, deviceId);
        await unregisterDeviceForPushNotifications();
        // now we register the PN token in stream-chat
        await chatClient.addDevice(deviceId, Platform.OS === `ios` ? `apn` : `firebase`);

        // set token in global state
        setChatPushToken(deviceId);

        if (Platform.OS === `android`) {
          Notifications.setNotificationChannelAsync(`default`, {
            name: `default`,
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: Colors.brandPurple,
          });
        }
      } catch (error) {
        console.error(`file: useChatService.js -- error thrown in registerDevice`, error.message);
      }
    },
    [setChatPushToken, unregisterDeviceForPushNotifications],
  );

  return {
    disconnectChat,
    registerForChat,
  };
};
