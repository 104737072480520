import AsyncStorage from '@react-native-async-storage/async-storage';
import { promisify } from 'es6-promisify';
import { gunzip, gzip } from 'react-zlib-js';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import { getEnvVariables, getReleaseChannel } from '../../environment';
import { getAsyncStorageObject, removeAsyncStorageKey, setAsyncStorageObject } from '../common-util';
import {
  CHECK_USER,
  CHECK_ALPHA,
  CHECK_BETA,
  clearAccessToken,
  client,
  GET_PERSONA,
  GET_SECURE_STORE,
  LOGINV2,
  REQUEST_RECOVERY_TOKEN,
  setAccessToken,
  SET_SECURE_STORE,
  SIGN_TOKENS,
} from '../graphql';
import { hash_alpha, hash_beta } from '../common-auth';

if (typeof Buffer === `undefined`) global.Buffer = require(`buffer`).Buffer;

const ENV = getReleaseChannel() || `dev`;

const isPhone = /^[+]?[0-9]{10,22}$/;
const isTestPhone = /^[+]000555000[0-9]{1,13}$/;

export const getPersona = (key) => getAsyncStorageObject(`persona-${ENV}-${key}`);
export const addPersona = (user) => setAsyncStorageObject(`persona-${ENV}-${user.id}`, user);
export const removeActivePersona = () => {
  removeAsyncStorageKey(`currentUser`);
  clearAccessToken();
};

export const decodeSecureStoreAndSignIn = async (secure_store, tokens) => {
  const stored_persona_credentials = await decodeSecureStore(secure_store);
  const personas = await Promise.all(stored_persona_credentials.map((persona) => fetchPersona(persona.id)));
  const password_for = stored_persona_credentials.reduce((result, persona) => {
    result[persona.id] = persona.password; // eslint-disable-line no-param-reassign
    return result;
  }, {});

  // Update with the appropriate credentials and call signTokens
  const personas_with_credentials = personas.map((persona) => {
    const { __typename, ...cleaned_persona } = persona;
    const credentialed_persona = { ...cleaned_persona, password: password_for[persona.id] };
    return {
      persona: credentialed_persona,
      ...tokens,
    };
  });
  const signed_personas = await signTokens(personas_with_credentials);
  const _signed_user = await loginUser(signed_personas[0]);
  return signed_personas;
};

export const getAllPersonaKeys = async () => {
  try {
    const keys = await AsyncStorage.getAllKeys();
    const personaKeys = keys.filter((s) => s.startsWith(`persona-${ENV}-`));
    //console.log(`getAllPersonaKeys: ${JSON.stringify(personaKeys)}`);
    return personaKeys;
  } catch (err) {
    console.error(`getAllPersonaKeys error: `, err);
    throw new Error(err);
  }
};

export const getAllPersonas = async () => {
  try {
    const keys = await AsyncStorage.getAllKeys();
    const personaKeys = keys.filter((s) => s.startsWith(`persona-${ENV}-`));
    // console.log(`-- Personas: `, personas);
    const personas = await Promise.all(
      personaKeys.map(async (s) => {
        const id = s.replace(`persona-${ENV}-`, ``);
        const persona = await getPersona(id);
        return [id, persona];
      }),
    );
    console.log(`getAllPersonas: ${JSON.stringify(personas)}`);
    return personas;
  } catch (err) {
    console.error(`getAllPersonas error: `, err);
    throw new Error(err);
  }
};

export const removeAllPersonas = async () => {
  try {
    await AsyncStorage.multiRemove(await getAllPersonaKeys());
    await removeActivePersona();
    //console.log(`removeAllPersonas multiRemove result: ${res}`);
  } catch (err) {
    //console.error(`removeAllPersonas error: `, err);
  }
};

export const loginUser = async (user) => {
  if (!user) throw new Error(`loginUser did not receive a user`);
  console.log(`login user:`, user.handle);

  const userObj = {
    ...user,
    loginTime: new Date(),
    env: ENV,
  };

  await Promise.all([addPersona(userObj), setAsyncStorageObject(`currentUser`, userObj)]);

  setAccessToken(userObj.accessToken);
  return userObj;
};

export const switchPersona = async (personaId) => {
  const user = await getPersona(personaId);
  await setAsyncStorageObject(`currentUser`, user);
  setAccessToken(user.accessToken);
  await client.clearStore();
};

// #region identity stuff

export const SS_STORAGE_TYPE_GZIP = 1;

export const createSecureStoreHeader = (storageType, payload) => {
  const header = Buffer.alloc(4);
  header.writeInt32LE(storageType, 0);
  return Buffer.concat([header, payload]);
};

export const parseSecureStoreHeader = (buffer) => {
  const storageType = buffer.readInt32LE(0);
  if (storageType === SS_STORAGE_TYPE_GZIP) {
    return {
      storageType,
      payload: buffer.slice(4),
    };
  }
  throw new Error(`unknown securestore storageType: ${storageType}`);
};

export const loginIdentity = async (id, password, push_token, invite_token) => {
  let response;
  const loginInput = {
    id,
    password,
    push_token,
    invite_token,
  };
  console.log(`calling client.mutate(LOGINV2)`);
  const { data } = await client.mutate({
    mutation: LOGINV2,
    variables: { input: loginInput },
    fetchPolicy: `no-cache`,
  });
  const loginResult = data.LoginV2;
  //console.log(`loginRes: `, loginResult);
  if (loginResult.id) {
    response = loginResult;
  } else if (loginResult.res_status.includes(`401`)) {
    console.log(`user failed authentication`);
  }
  return response;
};

export const checkUser = async (contact) => {
  const { data } = await client.query({
    query: CHECK_USER,
    variables: { contact: contact.toLowerCase() },
    fetchPolicy: `no-cache`,
  });
  const secret = data.checkUser;
  return Buffer.from(secret, `hex`).toString();
};

export const checkAlpha = async (alpha, contact) => {
  const { data } = await client.query({
    query: CHECK_ALPHA,
    variables: { input: { alpha, contact } },
    fetchPolicy: `no-cache`,
  });
  const { salts, beta_type } = data.CheckAlphaV2;
  return { salts, beta_type };
};

export const checkBeta = async (keys_to_test) => {
  try {
    const { data } = await client.query({
      query: CHECK_BETA,
      variables: { input: keys_to_test, supportsV2: true },
      fetchPolicy: `no-cache`,
    });
    if (!data.CheckBeta) return null;
    const { id, has_password, has_personas } = data.CheckBeta;
    return { id, has_password, has_personas };
  } catch (err) {
    console.error(`Error thrown in checkBeta /auth/helpers.js`, err.message);
    return null;
  }
};

const validateAlphaBeta = async (contact) => {
  /**
   * alpha hash contact / sms, return salts
   * beta hash contact / sms with salts, match in table
   * if match, user exists in table, return secure_store + hashed password (key to de-encrypt secure_store on client)
   */
  const alpha = hash_alpha(contact);
  const { salts, beta_type } = await checkAlpha(alpha, contact);
  const keys_to_test = salts.map((salt) => {
    const beta = hash_beta(salt, contact, beta_type);
    return { beta, salt };
  });
  return checkBeta(keys_to_test);
};

export const validateAccount = async (contact) => {
  if (isPhone.test(contact)) {
    return validateAlphaBeta(contact);
  }
  const input = await checkUser(contact);
  return validateAlphaBeta(input);
};

export const resetAccountHelper = async (id, formattedAndCleanedContact) => {
  try {
    const { data } = await client.mutate({
      mutation: REQUEST_RECOVERY_TOKEN,
      variables: { id, contact: formattedAndCleanedContact },
      fetchPolicy: `no-cache`,
    });
    return { data };
  } catch (err) {
    console.error(`Error thrown in resetAccountHelper /auth/helpers.js`, err.message);
    return null;
  }
};

export const convertObjToBuf = (jsonObj) => {
  const jsonStr = JSON.stringify(jsonObj);
  const buf = Buffer.from(jsonStr);
  return buf;
};

/**
 *
 * @param {Object[]} inputs - array of input objects to sign
 * @returns Promise -- returns Promise containing array of signed personas
 */
const allowed_fields = [
  `id`,
  `name`,
  `handle`,
  `description`,
  `password`,
  `avatar_url`,
  `splash_url`,
  `color_theme`,
  `identity_verified`,
  `pronouns`,
  `dob`,
];
export const signTokens = async (inputs) => {
  const loginInputs = inputs.map((item) => {
    const cleanedPersona = Object.keys(item.persona).reduce((obj, field) => {
      // eslint-disable-next-line no-param-reassign
      if (allowed_fields.includes(field)) obj[field] = item.persona[field];
      return obj;
    }, {});
    return {
      ...item,
      persona: cleanedPersona,
    };
  });

  const { data } = await client.mutate({
    mutation: SIGN_TOKENS,
    variables: { input: loginInputs },
    fetchPolicy: `no-cache`,
  });

  const signed_personas = data.SignTokens;

  // signed_tokens are the signed_tokens personas returned from the server with accessToken && refreshToken / login time
  if (Array.isArray(signed_personas)) {
    const addPersonaPromises = signed_personas.map((persona) =>
      addPersona({
        ...persona,
        loginTime: new Date(),
        env: ENV,
        isBanned: inputs?.find((ip) => ip.persona.id === persona.id)?.persona?.isBanned,
      }),
    );
    await Promise.all(addPersonaPromises);
  }

  //Manually add isBanned to signed_personas
  const updated_signed_personas = signed_personas.map((sp) => ({
    ...sp,
    isBanned: inputs?.find((ip) => ip.persona.id === sp.id)?.persona?.isBanned,
  }));
  return updated_signed_personas;
};

export const fetchPersona = async (id) => {
  try {
    let persona;
    if (id) {
      const { data } = await client.query({
        query: GET_PERSONA,
        variables: { id },
        fetchPolicy: `no-cache`,
      });
      persona = data.getPersona;
    }
    return persona;
  } catch (err) {
    console.error(`error in fetchPersona`, err);
    return null;
  }
};

export const fetchSecureStore = async (identity_id) => {
  const { data } = await client.query({
    query: GET_SECURE_STORE,
    variables: { id: identity_id },
    fetchPolicy: `no-cache`,
  });
  const { secure_store } = data.GetSecureStore;
  return secure_store;
};

export const decodeSecureStore = async (secure_store) => {
  const bufferWithHeader = Buffer.from(secure_store, `base64`);
  const { storageType, payload } = parseSecureStoreHeader(bufferWithHeader);
  if (storageType !== SS_STORAGE_TYPE_GZIP) throw new Error(`unhandled secure store storage type: \${storageType}`);

  const awaitable_gunzip = promisify(gunzip);
  const buffer = await awaitable_gunzip(payload, {});

  let stored_persona_credentials = JSON.parse(buffer.toString(`utf8`));
  if (!Array.isArray(stored_persona_credentials)) stored_persona_credentials = [stored_persona_credentials];
  return stored_persona_credentials;
};

export const encodeSecureStore = async (stored_persona_credentials) => {
  const unencoded = convertObjToBuf(stored_persona_credentials);
  const awaitable_gzip = promisify(gzip);
  const encoded = await awaitable_gzip(unencoded, {});
  const bufferWithHeader = createSecureStoreHeader(SS_STORAGE_TYPE_GZIP, encoded);
  const base64BufferWithHeader = bufferWithHeader.toString(`base64`);
  const secure_store = base64BufferWithHeader;
  return secure_store;
};

export const setSecureStore = async (identity_id, secure_store) => {
  await client.mutate({
    mutation: SET_SECURE_STORE,
    variables: {
      input: { id: identity_id, secure_store },
    },
  });
};

// #endregion

export const multiplePersonasAllowed = (identity) => {
  const { envName } = getEnvVariables();
  if (!identity) return false;
  // hardcoded for now
  const allowed = new Set(
    envName === `production`
      ? [
          `78753d41-04f8-4909-9278-33bc28a1cd52`, // Mesh (prod)
          `888af633-7a33-477c-b058-b123c676c9e2`, // Dorothy (prod)
          `78828468-38eb-4c55-9349-fb14ed4ec21e`, // jess (prod)
          `c732431a-8d2b-4a1b-9d3f-e3a5b05412cc`, // caldwell (prod)
          `73a2a5bd-7b8b-4d3a-88c8-f1cf4b657530`, // herik (prod)
          `cbe0e006-3db8-4f73-b113-8824e9c3e4c3`, // gillian (prod)
          //`2b5148a7-c349-4483-910c-9e6b91aae3ac`, // sock puppet acct (prod)
        ]
      : [
          `55b4574c-0710-4f63-9657-49af1a80812e`, // dorothy (dev)
          `11e05711-2c08-489f-b9ee-e95179374632`, // herik (dev)
          `fb8edb81-68da-4e7f-b093-8cffb7924421`, // esteban (dev)
        ],
  );
  return allowed.has(identity.id);
};

/**
 * cleans and returns back contact -- if email then .toLowerCase().trim() and if phone number parse into only digits
 * assume +1 if no + in contact string
 * @param {string} contact contact to clean and format
 * @returns {string} returns back the cleaned and formatted string (e.g. )
 */
export const cleanAndFormatContact = (contact) => {
  const hasPermittedChars = /^[0-9+\-. ()+]+$/.test(contact);
  // E2E test accounts
  if (isTestPhone.test(contact)) return contact;
  // phone: remove whitespace from input
  if (hasPermittedChars && isPhone.test(contact.replace(/[^+0-9]/g, ``))) {
    const isUSNumber = isValidNumber(contact, `US`);
    const parsed = parsePhoneNumber(contact, isUSNumber ? `US` : undefined);
    return parsed.number;
  }

  // email or username
  return contact.trim();
};
