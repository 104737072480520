import React, { useEffect } from 'react';
import * as Notifications from 'expo-notifications';
//import * as ExpoLinking from 'expo-linking';
import { View, Linking } from 'react-native';

// TODO: this component should be a HOC that wraps the AppChild component.
// Used like this makes it easier to test. Once we know that the component is working we can transform this into a HOC that wraps another componetn

const PushNotificationRegistry = () => {
  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  useEffect(() => {
    const handleNotification = async () => {
      if (
        lastNotificationResponse &&
        lastNotificationResponse.notification.request.content.data.url &&
        lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER
      ) {
        const notifResponse = lastNotificationResponse.notification.request.content.data;
        const canOpenURL = await Linking.canOpenURL(`https://app.mymesh.io/${notifResponse.url}`);
        if (canOpenURL) Linking.openURL(`mesh:/${notifResponse.url}`);
        else {
          const oldSchemaUrl = await Linking.canOpenURL(`https://app.meshconnect.us/${notifResponse.url}`);
          if (oldSchemaUrl) Linking.openURL(`mesh:/${notifResponse.url}`);
        }
      }
    };
    handleNotification();
  }, [lastNotificationResponse]);

  return <View style={{ flex: 0 }} />;
};

export { PushNotificationRegistry };
