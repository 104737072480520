//Node creating 5th step
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { SectionList, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../AppContext';
import { SelectEditor } from '../common-settings';
import { SettingRender } from '../common-settings/SettingsList';
import { Colors, Typography } from '../common-styles';
import { BackButton, BottomSheetModal, LoadingIndicator, ThemedButton } from '../common-ui';
import { GROUP_CREATE_SETTINGS, SETTING_OPTIONS } from '../graphql';
import { setActiveCommunity } from '../redux/feedSlice';
import { RootState } from '../redux/store';
import { useCreateCommunity } from './hooks/useCreateCommunity';
import { WizardLayout } from './WizardLayout';
import { movePrev, finishWizard } from '../redux/createCommunitySlice';

type SettingCore = {
  id: string;
  key: string;
  label: string;
  value: string;
  value_label: string;
  index: number;
  section: number;
  type: string;
  visibility: boolean;
};

type CustomSetting = {
  [key: string]: SettingCore;
};

type SectionType = {
  id: string;
  title: string;
  data: SettingCore[];
};

enum SettingEnum {
  Notify = `group.setting.notify_new_node`,
  Discover = `group.setting.discover`,
  Members = `group.setting.list_members`,
  Joinable = `group.setting.joinable`,
  CanPost = `group.setting.can_post`,
  PostApproval = `group.setting.mod.post_approval`,
  AgeRestrict = `group.setting.age_restriction`,
  Visible = `group.setting.visible`,
}

const sectionTitles = [`Notifications`, `Privacy`, `Moderation`];

const CommunityExtraSettings = () => {
  const { user } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const [selectedSetting, setSelectedSetting] = useState<any>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [settings, setSettings] = useState<CustomSetting>();

  const { selectedPreset } = useSelector((state: RootState) => state.createCommunity);
  const { createCommunity, loading: createLoading } = useCreateCommunity();

  const { data: _settings, loading } = useQuery<{ getGroupCreationSettings: SettingCore[] }>(GROUP_CREATE_SETTINGS);

  const { data, loading: _loadingOptions } = useQuery(SETTING_OPTIONS, {
    variables: { settingQuery: { query: `group` } },
  });

  useEffect(() => {
    if (_settings && _settings?.getGroupCreationSettings && !loading) {
      const postValue =
        selectedPreset === `community`
          ? { value: `anyone`, label: `All members` }
          : { value: `creator`, label: `Admins and Contributors` };

      const temp = _settings?.getGroupCreationSettings;
      temp.map((tp) => {
        if (tp.key === SettingEnum.CanPost) {
          tp.value = postValue.value;
          tp.value_label = postValue.label;
        }
        if (tp.key === SettingEnum.Joinable) {
          tp.value = `anyone`;
          tp.value_label = `Anyone can join`;
        }
      });

      const data = temp.reduce((obj: any, item: any) => ({ ...obj, [item.key]: item }), {});

      setSettings(data);
    }
  }, [_settings, loading, selectedPreset]);

  const handlePrev = () => {
    navigation.goBack();
    dispatch(movePrev());
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={handlePrev} />,
    });
  }, [navigation]);

  const handleCreateCommunity = useCallback(async () => {
    try {
      const { group } = await createCommunity(settings);
      if (group && user?.id) {
        dispatch(setActiveCommunity({ group, user_id: user?.id }));
        dispatch(finishWizard());
        navigation.navigate(`HomeScreen`);
        setShowConfirm(false);
      }
    } catch (error) {}
  }, [dispatch, user?.id, navigation, settings]);

  const sections = useMemo(() => {
    const baseSections: any[] = sectionTitles.map((section) => ({
      id: section,
      title: section,
      data: [],
    }));
    if (settings && Object.keys(settings || {}).length) {
      Object.values(settings).forEach((setting: SettingCore) => {
        if (setting.key === SettingEnum.Notify) {
          baseSections[0]?.data.push(setting);
        } else if (setting.key === SettingEnum.Discover || setting.key === SettingEnum.Members || setting.key === SettingEnum.Visible) {
          baseSections[1]?.data.push(setting);
        } else {
          baseSections[2]?.data.push(setting);
        }
      });
      return baseSections.filter((s) => !!s.data.length);
    }
    return [];
  }, [settings]);

  const renderSectionHeader = ({ section }: { section: SectionType }) => {
    const { id, title } = section || {};
    return (
      <View style={{ flex: 1, flexDirection: `column`, backgroundColor: Colors.lightGray }} key={id}>
        <Text style={{ marginHorizontal: 16, marginVertical: 8, ...Typography.text(`gray`, `bold`) }}>{title}</Text>
      </View>
    );
  };

  const setValueLabel = (key: string, value: string) => {
    return data?.getSettingOptions?.find((el: any) => el.key === key)?.options?.find((opt: any) => opt.value === value)?.label;
  };

  const saveSetting = useCallback(
    (key: string, value: string) => {
      setSettings((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          value,
          value_label: setValueLabel(key, value),
        },
      }));
    },
    [setSettings, data],
  );

  const renderSettingItem = ({ item: setting }: { item: any }) => {
    return (
      <SettingRender
        navigation={navigation}
        setting={setting}
        setSelectedSetting={setSelectedSetting}
        saveSetting={saveSetting}
      />
    );
  };

  if (loading) return <LoadingIndicator />;

  return (
    <WizardLayout total={5} selector="createCommunity">
      <View style={styles.container}>
        <View style={styles.descript}>
          <Text style={styles.description}>These preferences can also be adjusted in "Settings"</Text>
        </View>

        <SectionList
          sections={sections}
          keyExtractor={(item) => item.key}
          stickySectionHeadersEnabled={false}
          renderSectionHeader={renderSectionHeader}
          renderItem={renderSettingItem}
          extraData={sections}
        />
        <View style={{ padding: 10 }}>
          <ThemedButton
            title="Continue"
            rounded
            onPress={() => setShowConfirm(true)}
            titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
          />
        </View>

        <BottomSheetModal
          title="Confirm"
          showCancelBtn={false}
          showConfirmBtn={false}
          visible={showConfirm}
          onPressCancel={() => setShowConfirm(false)}
          onPressConfirm={() => {}}
        >
          <View style={{ paddingHorizontal: 10 }}>
            <ThemedButton
              title="Create node"
              rounded
              disabled={createLoading}
              onPress={handleCreateCommunity}
              titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
            />
            <View style={{ padding: 10 }}>
              <Text style={{ textAlign: `center` }}>By creating a node you agree to</Text>
              <Text style={{ textAlign: `center` }}>
                <Text>abide by the </Text>
                <Text style={{ ...Typography.text(`bold`), color: Colors.brandPurple }}>mesh Ground Rules.</Text>
              </Text>
            </View>
          </View>
        </BottomSheetModal>
        {selectedSetting && (
          <SelectEditor
            setting={selectedSetting}
            optionsData={data?.getSettingOptions}
            onSave={saveSetting}
            onCancel={() => setSelectedSetting(null)}
          />
        )}
      </View>
    </WizardLayout>
  );
};

export default CommunityExtraSettings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  descript: {
    padding: 10,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  description: {
    fontSize: 12,
  },
});
