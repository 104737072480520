import PropTypes from 'prop-types';
import { StyleSheet, Switch, Text, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { Divider } from '../common-ui';
import { commonPropTypes } from '../common-util';

const menuOptionStyles = StyleSheet.create({
  title: {
    marginLeft: 5,
    fontSize: Typography.baseFontSize,
    color: Colors.darkestGray,
    fontWeight: `500`,
  },
  switchViewContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    margin: 10,
    alignItems: `center`,
  },
});

export const ChatMenuOption = ({ divider, title, theme, hasSwitch, value, changeSwitchValue, handleOnPress }) => {
  return !hasSwitch ? (
    <View>
      <TouchableOpacity style={{ marginVertical: 10 }} onPress={handleOnPress}>
        <View style={{ flexDirection: `row`, margin: 10 }}>
          <Text style={menuOptionStyles.title}>{title}</Text>
        </View>
      </TouchableOpacity>
      {divider && <Divider />}
    </View>
  ) : (
    <View>
      <View style={{ marginVertical: 10 }}>
        <View style={menuOptionStyles.switchViewContainer}>
          <Text style={menuOptionStyles.title}>{title}</Text>
          <Switch trackColor={{ true: theme.primary }} onValueChange={(val) => changeSwitchValue(val)} value={value} />
        </View>
      </View>
      {divider && <Divider />}
    </View>
  );
};
ChatMenuOption.propTypes = {
  divider: PropTypes.bool,
  title: PropTypes.string.isRequired,
  theme: commonPropTypes.theme().isRequired,
  handleOnPress: PropTypes.func,
  hasSwitch: PropTypes.bool,
  value: PropTypes.bool,
  changeSwitchValue: PropTypes.func,
};
ChatMenuOption.defaultProps = {
  divider: false,
  handleOnPress: undefined,
  hasSwitch: undefined,
  value: undefined,
  changeSwitchValue: undefined,
};
