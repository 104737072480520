import { StyleSheet, Text, TextInput, View, TouchableOpacity, TouchableWithoutFeedback, Keyboard } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Colors, Typography, _bad_do_not_use_HEIGHT, _bad_do_not_use_WIDTH } from '../common-styles';
import { Divider, KeyboardPaddingView, SafeAreaView } from '../common-ui';
import { LoadingIndicator } from '../common-ui/LoadingIndicator';
import { AgreementsView } from './AgreementsView';
import { MODERATION_TYPE } from './moderationEnums';
import { reportModerationIssue } from './moderationServices';
import { APPLY_MODERATION_ACTIONS, client, refetchQueriesFor } from '../graphql';

const HideKeyboard: React.FC<any> = ({ children }) => (
  <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>{children}</TouchableWithoutFeedback>
);
/**
 *  Required params per specific reportType
 *
 *  MODERATION_TYPE.post
 *    - id
 *    - origin_group_id
 */

const DeletePostCommentScreen: React.FC<any> = ({ route }) => {
  const values = route.params;
  const reportType = values?.reportType;
  const id = values?.id;
  const origin_group_id = values?.origin_group_id;
  const role = values?.role_name;
  const reaction_id = values?.reaction_id;

  const navigation = useNavigation();
  const [reasonsSelected, setReasonSelected] = useState<any[]>([]);
  const [explanation, setExplanation] = useState(``);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isFocused, setFocus] = useState(false);

  const disabled = isSubmitting || explanation.trim().length === 0 || reasonsSelected.length === 0;

  const applyModerationActions = useCallback(
    async (input) => {
      try {
        await client.mutate({
          mutation: APPLY_MODERATION_ACTIONS,
          variables: { input },
          refetchQueries: refetchQueriesFor(
            `ModerationIssue`,
            `ModeratedGroup2`,
            reportType === MODERATION_TYPE.comment ? `ActivityReaction` : ``,
          ),
        });
        navigation.goBack();
      } catch (error) {
        console.log(`Error occured whle applying moderation action - delete post`);
      }
    },
    [navigation, reportType],
  );

  const submitModerationIssueReport = async () => {
    console.log(`=== firing submitModerationIssueReport ===`);

    setSubmitting(true);

    const reasonIds = reasonsSelected.map((reason: any) => `${reason.id}`);

    if (!id) return;

    const reportInput: any = {
      group_id: origin_group_id,
      reaction_id: reaction_id || null, // reaction id e.g. comments - report comment
      object_id: id || null, // activity id - report activity
      reasons: reasonIds,
      explanation,
      type: reportType,
    };

    const moderationActions = [];
    moderationActions.push({
      type: reportType,
      action: reportType === MODERATION_TYPE.post ? `post_remove` : `comment_remove`,
    });
    try {
      const result: any = await reportModerationIssue(reportInput);
      if (result && result.res_status === `200OK`) {
        const input = {
          issue_id: result.id,
          group_id: result.group_id,
          mod_explanation: explanation,
          actions: moderationActions,
        };
        applyModerationActions(input);
      }
    } catch (err: any) {
      console.error(`exception thrown in submitModerationIssueReport in ReportScreen.jsx`, err.message);
    }

    setSubmitting(false);
  };

  useEffect(() => {
    const renderHeaderTitle = () => (
      <Text style={{ ...StyleSheet.flatten(localStyles.headerText), color: Colors.darkestGray }} testID="REPORT_TITLE">
        Delete {reportType}
      </Text>
    );

    navigation.setOptions({
      headerTitle: () => renderHeaderTitle(),
      headerRight: () => (
        <TouchableOpacity onPress={submitModerationIssueReport} disabled={disabled}>
          <View style={{ right: 16 }}>
            {isSubmitting ? (
              <LoadingIndicator />
            ) : (
              <Text style={{ ...Typography.text(`base`, `bold`), color: disabled ? Colors.iconColor : Colors.brandPurple }}>
                Delete
              </Text>
            )}
          </View>
        </TouchableOpacity>
      ),
    });
  }, [navigation, disabled, isSubmitting, reasonsSelected, explanation, role, reportType, origin_group_id, id]);

  const setReasons = (reasons: any) => {
    setReasonSelected(reasons);
    Keyboard.dismiss();
  };

  const renderReasonsMessage = (reasonsSelected: any[]) => {
    let message = ``;
    if (reasonsSelected.length > 1) message = `You have selected ${reasonsSelected.length} reasons. `;
    if (reasonsSelected.length === 1) message = `You have selected 1 reason. `;
    if (explanation.length === 0) message = message.concat(`Brief explanation (required)`);

    return message;
  };

  const setTextboxFocus = (isFocused: boolean) => {
    setFocus(isFocused);
  };

  const handleChange = (text: string) => {
    setExplanation(text);
  };

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <HideKeyboard>
          <View style={localStyles.innerContainer}>
            <View style={{ margin: 10 }}>
              <Text style={{ color: Colors.darkGray }}>
                This {reportType} violates the following agreements (select all that apply):
              </Text>
            </View>

            <Divider style={{ width: `100%` }} />
            <AgreementsView
              origin_group_id={origin_group_id}
              isUser={reportType === MODERATION_TYPE.user}
              reasons={reasonsSelected}
              setReasons={setReasons}
              isCommunityReport={reportType === MODERATION_TYPE.community}
            />
            <Divider style={{ width: `100%` }} />
            <View style={localStyles.textInputContainer}>
              <Text style={localStyles.briefExplanation}>{renderReasonsMessage(reasonsSelected)}</Text>
              <TextInput
                multiline
                scrollEnabled
                blurOnSubmit
                value={explanation}
                testID="REPORT_REASON"
                onChangeText={handleChange}
                placeholder="Explain why you are reporting this"
                placeholderTextColor={Colors.textPlaceholder}
                style={{
                  ...StyleSheet.flatten(localStyles.textInput),
                  borderColor: isFocused ? Colors.iconColor : Colors.mediumGray,
                  borderWidth: isFocused ? 2 : 1,
                  textAlignVertical: `top`,
                }}
                onFocus={() => setTextboxFocus(true)}
                onBlur={() => setTextboxFocus(false)}
              />
            </View>
          </View>
        </HideKeyboard>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

export default DeletePostCommentScreen;

const localStyles = StyleSheet.create({
  headerText: {
    ...Typography.text(`plustwo`, `bold`),
    fontWeight: `600`,
  },
  innerContainer: {
    flex: 1,
    margin: 10,
    justifyContent: `flex-end`,
  },
  briefExplanation: {
    fontSize: Typography.extraSmallFontSize,
    fontWeight: `500`,
    color: Colors.mediumGray,
  },
  textInputContainer: {
    flexShrink: 1,
    margin: 10,
  },
  textInput: {
    flexShrink: 1,
    borderWidth: 1,
    borderColor: Colors.mediumGray,
    padding: 6,
    borderRadius: 4,
    margin: 10,
    minHeight: 100,
    maxHeight: 125,
  },
});
