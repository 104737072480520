/* eslint-disable no-underscore-dangle */
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Colors } from '../common-styles';
import { LoadingIndicator } from './LoadingIndicator';
import { MeshIcon } from './MeshIcon';
import { ThemedButton } from './ThemedButton';

type LoadingModalProps = {
  isVisible: boolean;
  content: string;
  error?: string;
  onAccept?: () => void;
};

const localStyles = StyleSheet.create({
  loadingModal: { flex: 1, justifyContent: `center`, alignItems: `center` },
  loadingContainer: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: `white`,
    maxHeight: Dimensions.get(`window`).height / 3.5,
    width: Dimensions.get(`window`).width / 1.5,
    maxWidth: 600,
    borderRadius: 25,
    marginHorizontal: 10,
  },
  textContainer: {
    flexWrap: `wrap`,
    marginHorizontal: 25,
    marginTop: 16,
  },
  loadingText: {
    fontSize: 20,
    color: Colors.brandPurple,
    textAlign: `center`,
    alignSelf: `center`,
    flexShrink: 1,
  },
  button: {
    marginTop: 16,
    width: 100,
  },
});

export const LoadingModal = ({ isVisible, content, error, onAccept }: LoadingModalProps) => {
  const navigation = useNavigation();
  if (!isVisible) return null;

  const handleError = () => {
    if (onAccept) onAccept();
    else navigation.goBack();
  };

  return (
    <Modal isVisible={isVisible} style={localStyles.loadingModal} animationIn="zoomIn" animationOut="zoomOut">
      <View style={localStyles.loadingContainer}>
        {error ? (
          <MeshIcon name="warning" color={Colors.brandPurple} size={32} />
        ) : (
          <LoadingIndicator size="small" color={Colors.brandPurple} />
        )}
        <View style={localStyles.textContainer}>
          <Text style={localStyles.loadingText}>{content}</Text>
        </View>
        {error ? <ThemedButton containerStyle={localStyles.button} title="OK" onPress={handleError} /> : undefined}
      </View>
    </Modal>
  );
};
