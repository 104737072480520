import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Keyboard,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useQuery } from '@apollo/react-hooks';
import Collapsible from 'react-native-collapsible';
import { NetworkStatus } from 'apollo-client';
import _, { unionBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../common-styles';
import { BottomSheetOptions, TopicItem, TopicListData, TopicStatus } from '../common-types/types';
import { BottomSheetModal, ListItem, MeshIcon, NoThemeButton } from '../common-ui';
import { useDisclosure } from '../common-util/hooks/useDisclosure';
import { GET_TOPICS_LIST } from '../graphql';
import { useTopics } from '../community/v2/hooks/useTopics';
import { RootState } from '../redux/store';
import { setForceRefreshTopicLists, setTopic } from '../redux/feedSlice';
import { Events, PendoTrackEvent } from '../pendo/events';

type SectionData = {
  title: string;
  data: TopicItem[];
};
type SectionRender = ((info: { section: SectionListData<TopicItem, SectionData> }) => React.ReactElement | null) | undefined;

const EditTopics: React.FC<{ route: any }> = ({ route }) => {
  const dispatch = useDispatch();
  const { group, group_member } = route?.params;
  const navigation = useNavigation<any>();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [sections, setSections] = useState<SectionData[]>([]);
  const [searchKey, setSearchKey] = useState(``);
  const [options, setOptions] = useState<BottomSheetOptions[]>([]);
  const [collapsed, setCollapsed] = useState<string[]>([]);

  const { updateTopic, deleteTopic } = useTopics();
  const { isRefreshTopicLists } = useSelector((state: RootState) => state.feed);

  const { data, refetch, fetchMore, loading, networkStatus } = useQuery<TopicListData>(GET_TOPICS_LIST, {
    variables: {
      filter: {
        keyword: searchKey,
        limit: 20,
        offset: 0,
        group_id: group?.id,
        sortBy: `TOPIC`,
        sortOrder: `ASC`,
      },
    },
  });

  const prepareSectionData = (lists: TopicItem[]): SectionData[] => {
    const filtered = lists.filter((list) => list.topic.toLocaleLowerCase() !== `general`);
    return [
      {
        title: `Suggested`,
        data: filtered.filter((list) => list.status === TopicStatus.SUGGESTED),
      },
      {
        title: `Active topics`,
        data: filtered.filter((list) => list.status === TopicStatus.ACTIVE),
      },
      {
        title: `Deactive topics`,
        data: filtered.filter((list) => list.status === TopicStatus.DEACTIVATED),
      },
    ];
  };

  useEffect(() => {
    const forceRefetch = async () => {
      try {
        await refetch();
      } catch (error) {
        console.error(`forceRefetch:`, (error as Error).message);
      } finally {
        dispatch(setForceRefreshTopicLists(false));
      }
    };

    if (isRefreshTopicLists) forceRefetch();
  }, [dispatch, isRefreshTopicLists, refetch]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `Manage topics`,
      headerRight: () => (
        <TouchableOpacity
          style={{ alignItems: `center` }}
          onPress={() =>
            navigation.navigate(`CreateTopic`, { group, topics: data?.getGroupTopicsList_V2, role_name: group_member?.role_name })
          }
        >
          <MeshIcon name="plus" color={Colors.iconColor} size={20} />
          <Text style={{ fontSize: 10, color: Colors.iconColor, fontWeight: `bold` }}>Create</Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation, group, group_member, data]);

  useEffect(() => {
    const topicLists = data?.getGroupTopicsList_V2;
    if (topicLists) {
      setSections(prepareSectionData(topicLists));
    }
  }, [data]);

  const handleActivate = useCallback(
    async (topic: TopicItem, wantedStatus: TopicStatus, pendo?: boolean) => {
      try {
        await updateTopic(topic.id, {
          status: wantedStatus,
        });
        if (pendo) {
          PendoTrackEvent(Events.CREATE_TOPIC, {
            source: `ApproveTopics`,
            community_name: group?.name,
            community_role: group_member?.role_name,
            topic_name: topic?.topic,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [updateTopic, group, group_member],
  );

  const handleUpdate = useCallback(
    (topic: TopicItem) => {
      navigation.navigate(`CreateTopic`, {
        group,
        topics: data?.getGroupTopicsList_V2,
        role_name: group_member?.role_name,
        currentTopic: topic,
      });
    },
    [navigation, data, group_member, group],
  );

  const handleDelete = useCallback(async (topic: TopicItem) => {
    try {
      await deleteTopic(topic.id);
      await refetch();
    } catch (error) {
      console.error((error as Error).message);
    }
  }, []);

  const handleSelect = useCallback(
    (item: TopicItem) => {
      const options: BottomSheetOptions[] = [
        {
          title: item.status === TopicStatus.ACTIVE ? `Deactivate topic` : `Make topic active`,
          titleStyle: { color: item.status === TopicStatus.ACTIVE ? Colors.alertColor : Colors.black },
          iconName: item.status === TopicStatus.ACTIVE ? `eye-hide` : `eye`,
          iconColor: item.status === TopicStatus.ACTIVE ? Colors.alertColor : Colors.iconColor,
          onPress: () => {
            onToggle();
            handleActivate(item, item.status === TopicStatus.ACTIVE ? TopicStatus.DEACTIVATED : TopicStatus.ACTIVE);
          },
        },
        {
          title: `Edit Topic`,
          titleStyle: { color: Colors.black },
          iconName: `edit`,
          iconColor: Colors.iconColor,
          onPress: () => {
            onToggle();
            setTimeout(() => {
              handleUpdate(item);
            }, 500);
          },
        },
        {
          title: `Delete Topic`,
          titleStyle: { color: Colors.alertColor },
          iconName: `close`,
          iconColor: Colors.alertColor,
          onPress: () => {
            onToggle();
            handleDelete(item);
          },
        },
      ];
      setOptions(options);
      onToggle();
    },
    [handleActivate, handleDelete, handleUpdate, onToggle],
  );

  const handleCloseBottomSheet = useCallback(() => {
    setOptions([]);
    onClose();
  }, [setOptions, onClose]);

  const toggleCategory = useCallback(
    (category) => {
      const hasCategory = collapsed?.includes(category);
      if (hasCategory) setCollapsed(collapsed.filter((item) => item !== category));
      else setCollapsed([...collapsed, category]);
    },
    [collapsed, setCollapsed],
  );

  const renderSectionHeader: SectionRender = useCallback(
    ({ section }) => {
      const { title } = section;
      const isCollapsed = collapsed?.includes(title);
      return <SectionHeader title={title} collapsed={isCollapsed} toggleCategory={() => toggleCategory(title)} />;
    },
    [collapsed, toggleCategory],
  );

  const handleGoToTopicFeed = useCallback(
    (topic: TopicItem) => {
      dispatch(setTopic(topic));
      navigation.navigate(`TopicFeedScreen`, { groupMember: group_member, group });
    },
    [dispatch, navigation, group_member, group],
  );

  const renderItem: SectionListRenderItem<TopicItem, SectionData> = useCallback(
    ({ item, section: { title } }) => {
      const { topic, post_count } = item;
      const isCollapsed = collapsed && collapsed.includes(title);
      return (
        <Collapsible collapsed={isCollapsed}>
          <TouchableOpacity
            style={{
              paddingHorizontal: 12,
              paddingVertical: 15,
              flexDirection: `row`,
              justifyContent: `space-between`,
              borderBottomColor: Colors.lightGray,
              borderBottomWidth: 1,
              alignItems: `center`,
            }}
            onPress={() => handleGoToTopicFeed(item)}
          >
            <View>
              <Text style={{ fontWeight: `bold` }}>{topic}</Text>
              <Text style={{ color: Colors.textGray, fontSize: 12 }}>
                {post_count} {post_count > 1 ? `posts` : `post`}
              </Text>
            </View>
            {item.status !== TopicStatus.SUGGESTED &&
              item.status !== TopicStatus.DENIED &&
              item.topic.toLocaleLowerCase() !== `announcements` && (
                <TouchableOpacity onPress={() => handleSelect(item)} hitSlop={{ left: 15, right: 15, bottom: 10, top: 10 }}>
                  <Icon name="ellipsis-vertical" size={18} color={Colors.iconColor} />
                </TouchableOpacity>
              )}
            {item.status === TopicStatus.SUGGESTED && (
              <View style={{ flexDirection: `row` }}>
                <NoThemeButton
                  title="Deny"
                  titleStyle={{ color: Colors.textRed, fontSize: 11 }}
                  buttonStyle={{
                    backgroundColor: Colors.translucentBrightRed,
                    marginLeft: 10,
                    borderRadius: 8,
                    paddingVertical: 3,
                    width: 60,
                  }}
                  onPress={() => handleActivate(item, TopicStatus.DENIED)}
                />
                <NoThemeButton
                  title="Approve"
                  titleStyle={{ color: Colors.white, fontSize: 11 }}
                  buttonStyle={{
                    backgroundColor: Colors.deepPurple,
                    marginLeft: 10,
                    borderRadius: 8,
                    paddingVertical: 3,
                    width: 60,
                  }}
                  onPress={() => handleActivate(item, TopicStatus.ACTIVE, true)}
                />
              </View>
            )}
          </TouchableOpacity>
        </Collapsible>
      );
    },
    [handleSelect, handleActivate, handleGoToTopicFeed, collapsed],
  );

  const handleOnEndReached = () => {
    const totalTopics = sections.reduce((result, section) => result + section.data.length, 0);
    if (!loading) {
      fetchMore({
        variables: {
          filter: { offset: totalTopics, group_id: group?.id, sortBy: `TOPIC`, sortOrder: `ASC`, keyword: searchKey },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult.getGroupTopicsList_V2.length ||
            fetchMoreResult.getGroupTopicsList_V2.slice(-1)[0].id === prev.getGroupTopicsList_V2.slice(-1)[0].id
          ) {
            console.log(`[EditTopic]is the same, return prev`);
            return prev;
          }
          const newSections = unionBy(
            prev.getGroupTopicsList_V2,
            fetchMoreResult.getGroupTopicsList_V2,
            (li: TopicItem) => li.id,
          );
          setSections(prepareSectionData(newSections));
          return {
            ...prev,
            getGroupTopicsList_V2: unionBy(
              prev.getGroupTopicsList_V2,
              fetchMoreResult.getGroupTopicsList_V2,
              (li: TopicItem) => li.id,
            ),
          };
        },
      });
    }
  };

  return (
    <>
      <View style={{ flex: 1 }}>
        <View style={styles.searchContent}>
          <TextInput
            placeholder="Search topics"
            placeholderTextColor={Colors.textPlaceholder}
            style={styles.textInput}
            onChangeText={setSearchKey}
            value={searchKey}
            returnKeyType="done"
          />
          <TouchableOpacity
            disabled={!searchKey}
            onPress={() => setSearchKey(``)}
            hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
            style={{ padding: 10 }}
          >
            <Icon name={searchKey ? `close` : `search`} color={Colors.iconColor} size={20} />
          </TouchableOpacity>
        </View>
        <SectionList
          sections={sections}
          keyboardShouldPersistTaps="handled"
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={refetch}
          onEndReachedThreshold={1.5}
          onEndReached={handleOnEndReached}
          keyExtractor={(item) => item.id}
          renderSectionHeader={renderSectionHeader}
          renderItem={renderItem}
          onMomentumScrollBegin={() => Keyboard.dismiss()}
        />
      </View>
      <BottomSheetModal
        showConfirmBtn={false}
        showCancelBtn
        onPressCancel={handleCloseBottomSheet}
        onPressConfirm={handleCloseBottomSheet}
        visible={isOpen}
        title="Topic options"
        options={options}
      />
    </>
  );
};

export const TopicSettingItem: React.FC<any> = ({ group, listItemProps, group_member }) => {
  const { title, titleStyle, subtitleStyle } = listItemProps;
  const navigation = useNavigation<any>();

  const handlePress = () => {
    navigation.push(`EditTopics`, { group, group_member });
  };

  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle="Press to view and edit..."
      subtitleStyle={subtitleStyle}
      rightElement={<MeshIcon name="chevron-right" size={20} />}
      onPress={handlePress}
    />
  );
};

export default EditTopics;

type SectionHeaderProps = {
  title: string;
  collapsed: boolean;
  toggleCategory: (title: string) => void;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, collapsed, toggleCategory }) => {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={() => toggleCategory(title)}>
      <View
        style={{
          width: `100%`,
          backgroundColor: Colors.backgroundColor,
          paddingVertical: 6,
          justifyContent: `space-between`,
          flexDirection: `row`,
          paddingHorizontal: 12,
        }}
      >
        <Text style={{ color: Colors.textGray, fontWeight: `500` }}>{title}</Text>
        <MeshIcon name={!collapsed ? `chevron-down` : `chevron-up`} color={Colors.iconColor} size={18} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  searchContent: {
    flexDirection: `row`,
    alignItems: `center`,
    borderWidth: 1,
    borderColor: Colors.dividerColor,
    margin: 10,
    borderRadius: 4,
    justifyContent: `space-between`,
  },
  textInput: {
    flex: 1,
    padding: 10,
  },
});
