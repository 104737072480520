import { useState, useCallback } from 'react';

/**
 * This hook handle the logic of showing and hiding a component. Use it for modals, bottomsheet, alerts etc...
 * @param initialState Wheter the first load is open or not
 * @returns State of isOpen, onClose, onOpen, onToggle
 */
export const useDisclosure = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onToggle = useCallback(() => setIsOpen((prev) => !prev), []);
  return { isOpen, onClose, onOpen, onToggle };
};
