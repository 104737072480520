import { NetworkStatus } from 'apollo-client';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect, memo, useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useNavigation, useRoute } from '@react-navigation/native';
import { View, Text, Platform, Dimensions, FlatList, TouchableOpacity, Alert } from 'react-native';
import { ThemedButton, CenterColumn, Divider, MeshIcon, LoadingIndicator, ThreeDotsButton, BottomSheetModal } from '../common-ui';
import { NoCategories } from '../images/exploreCategoriesImages/NoCategories';
import { commonPropTypes } from '../common-util';
import { Colors, Typography } from '../common-styles';
import { CommunityListItem } from '../feed/CommunityListItem';
import {
  client,
  GET_GROUPS_BY_CATEGORY_NAME,
  FOLLOW_CATEGORY,
  UNFOLLOW_CATEGORY,
  GET_EXPLORE_COMMUNITIES,
  GET_FOLLOWING,
  GET_BLACKLIST,
  SETTING_VALUE_FRAGMENT,
  UPDATE_SETTING_MUTATION,
  refetchQueriesFor,
} from '../graphql';
import { ExploreFilters } from './ExploreFilters';
import { MODERATION_TYPE } from '../moderation/moderationEnums';
import { ReportModal } from '../moderation/ReportModal';
import { DEFAULT_FILTERS } from './exploreHelpers';
import { AppContext } from '../../AppContext';
import { setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';
import { useAppDispatch } from '../redux/store';
import { owner as OWNER } from '../community/memberInfoHelpers';
import { toggleJoinMeshModal } from '../redux/guestSlice';
import { GroupCardRounded } from '../common-ui/GroupCardRounded';

//TODO: implement useBottomSheetModal

export const CategoryListView = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { categoryData, filters: receivedFilters = {} } = route.params;
  const { user, isGuestMode } = React.useContext(AppContext);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [order, setOrder] = useState(`default`);
  const [group, setGroup] = useState(undefined);
  const [groupMember, _setGroupMember] = useState(undefined);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const dispatch = useAppDispatch();
  const [report, setReport] = useState(undefined);
  const [filters, setFilters] = useState({
    posts: receivedFilters.posts || DEFAULT_FILTERS.posts,
    members: receivedFilters.members || DEFAULT_FILTERS.members,
    membership: receivedFilters.membership || DEFAULT_FILTERS.membership,
  });
  const [following, setFollowing] = useState(categoryData?.following || false);
  const [filtersCount, setFiltersCount] = useState(0);

  const recommendationVariables = {
    recommendQuery: {
      user_id: user?.id,
      sort: order,
      filters: {
        posts: 0,
        members: 0,
        membership: `Any`, //Hardcoded in this screen - membershipStatus
      },
      // limit: -1
    },
    interestQuery: {
      user_id: user?.id,
      query: `user.setting.interests`,
    },
  };

  const [loadCategoryGroups, lazyResults] = useLazyQuery(GET_GROUPS_BY_CATEGORY_NAME, {
    variables: {
      categoryQuery: {
        user_id: user?.id,
        category_name: categoryData?.value,
        sort: order,
        filters,
      },
    },
    // fetchPolicy: `cache-and-network`,
    fetchPolicy: `network-only`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
  });
  const { called, data: groups, networkStatus, loading, refetch: refetchCategory } = lazyResults;
  const { data: interests, refetch: refetchFollowing } = useQuery(GET_FOLLOWING, {
    variables: {
      interestQuery: {
        user_id: user?.id,
        query: `user.setting.interests`,
      },
    },
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    // skip: isGuestMode,
  });

  const [follow, _response2] = useMutation(FOLLOW_CATEGORY, {
    refetchQueries: [
      {
        query: GET_EXPLORE_COMMUNITIES,
        variables: {
          recommendQuery: {
            user_id: user?.id,
            sort: order,
            filters,
          },
          interestQuery: {
            user_id: user?.id,
            query: `user.setting.interests`,
          },
        },
      },
    ],
  });

  const [unfollow, _response3] = useMutation(UNFOLLOW_CATEGORY, {
    refetchQueries: [
      {
        query: GET_EXPLORE_COMMUNITIES,
        variables: {
          recommendQuery: {
            user_id: user?.id,
            sort: order,
            filters,
          },
          interestQuery: {
            user_id: user?.id,
            query: `user.setting.interests`,
          },
        },
      },
    ],
  });

  const { data: blacklistQuery } = useQuery(GET_BLACKLIST, {
    variables: {
      blacklistQuery: {
        user_id: user?.id,
        query: `user.setting.blacklist`,
      },
    },
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    // skip: isGuestMode,
  });

  const [blackListSetting] = blacklistQuery?.getSettings || [{}];

  const [updateSetting, _response1] = useMutation(UPDATE_SETTING_MUTATION, {
    refetchQueries: [
      {
        query: GET_EXPLORE_COMMUNITIES,
        variables: recommendationVariables,
      },
      {
        query: GET_BLACKLIST,
        variables: {
          blacklistQuery: {
            user_id: user?.id,
            query: `user.setting.blacklist`,
          },
        },
      },
      {
        query: GET_GROUPS_BY_CATEGORY_NAME,
        variables: {
          categoryQuery: {
            user_id: user?.id,
            category_name: categoryData?.value,
            sort: order,
            filters,
          },
        },
      },
    ],
  });

  useEffect(() => {
    console.log(`Effect #1: set FilterBtn interaction`);
    navigation.setOptions(({ route }) => {
      const { filtersCount = 0 } = route.params;
      return {
        title: `Category`,
        headerRight: () => (
          <ThemedButton
            clear
            title={`Filters${filtersCount > 0 ? ` (${filtersCount})` : ``}`}
            onPress={() => setShowFilterModal(true)}
            containerStyle={{ right: 10 }}
          />
        ),
        cardStyle: { backgroundColor: `white` },
      };
    });
  }, [navigation]);

  useEffect(() => {
    console.log(`Effect #2: get follow status`);
    const settings = interests?.getSettings || [];
    if (settings.length) {
      const interestSetting = settings[0];
      const parsedInterests = JSON.parse(interestSetting?.value || `[]`);
      const myInterestSet = new Set(parsedInterests);
      setFollowing(myInterestSet.has(categoryData?.value));
    }
  }, [interests?.getSettings, categoryData?.value]);

  useEffect(() => {
    console.log(`Effect #3: get sotredFilters and then category groups`);
    loadFilterValuesAsync();
    loadCategoryGroups();
    // if (!isGuestMode) loadCategoryGroups();
    // only run this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGuestMode]);

  useEffect(() => {
    console.log(`Effect #4: refetching groups, filters and/or order values changed`, { order, filters });
    if (refetchCategory)
      refetchCategory({
        variables: {
          categoryQuery: {
            category_name: categoryData?.value,
            sort: order,
            filters,
          },
        },
      });
  }, [categoryData?.value, filters, order, refetchCategory]);

  useEffect(() => {
    console.log(`Effect #5: Change the filters count in the filter nav button`);
    navigation.setParams({ filtersCount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCount]);

  const compareAndSetFilters = (filters) => {
    let count = 0;
    if (+filters.posts !== 0) count += 1;
    if (+filters.members !== 0) count += 1;
    if (filters.membership !== `Any`) count += 1;
    setFilters(filters);
    setFiltersCount(count);
    console.log(`compareAndSetFilters -> count`, count);
  };

  const loadFilterValuesAsync = async () => {
    try {
      const storedFilters = {};
      storedFilters.members = +(await AsyncStorage.getItem(`communitySizeFilter`)) || DEFAULT_FILTERS.members;
      storedFilters.posts = +(await AsyncStorage.getItem(`activityFilter`)) || DEFAULT_FILTERS.posts;
      storedFilters.membership = (await AsyncStorage.getItem(`membershipStatus`)) || DEFAULT_FILTERS.membership;
      compareAndSetFilters(storedFilters);
    } catch (error) {
      console.warn(`loadFilterValuesAsync:`, error);
    }
  };

  const saveFilters = async (communitySizeFilter, activityFilter, membershipFilter) => {
    try {
      compareAndSetFilters({
        posts: +activityFilter,
        members: +communitySizeFilter,
        membership: membershipFilter,
      });
      await AsyncStorage.setItem(`communitySizeFilter`, communitySizeFilter.toString());
      await AsyncStorage.setItem(`activityFilter`, activityFilter.toString());
      await AsyncStorage.setItem(`membershipStatus`, membershipFilter);
    } catch (error) {
      console.warn(error);
    }
    setShowFilterModal(false);
  };

  const toggleFollow = useCallback(
    async (category, following) => {
      if (following) {
        setFollowing(false);
        await unfollow({
          variables: { category: category.value, user_id: user?.id },
          refetchQueries: refetchQueriesFor(`Setting`),
        });
      } else {
        setFollowing(true);
        await follow({
          variables: { category: category.value, user_id: user?.id },
          refetchQueries: refetchQueriesFor(`Setting`),
        });
      }
      refetchFollowing();
    },
    [user, follow, unfollow, setFollowing, refetchFollowing],
  );

  const cycleOrdering = useCallback(async () => {
    if (order === `default`) setOrder(`A-Z`);
    if (order === `A-Z`) setOrder(`Z-A`);
    if (order === `Z-A`) setOrder(`default`);
    await refetchCategory();
    console.log(`reordering query results`);
  }, [order, refetchCategory]);

  const ItemSeparatorComponent = useCallback(
    () => (
      <CenterColumn>
        <Divider />
      </CenterColumn>
    ),
    [],
  );

  const reportGroup = useCallback(() => {
    setTimeout(() => {
      console.log(`firing report for`, group.name);
      navigation.navigate(`ReportScreen`, {
        reportType: MODERATION_TYPE.community,
        origin_group_id: group?.id,
      });
    });
    setShowOptionsModal(false);
  }, [group, setShowOptionsModal, navigation]);

  const ListFooterComponent = useCallback(() => <View style={{ minHeight: 64 }} />, []);

  const handleArrowPress = useCallback(
    async (groupParam) => {
      const { group, group_member } = groupParam || {};
      if (group?.discoverable !== `public`) return;
      if (group_member && !group_member?.banned) {
        await dispatch(setActiveCommunity({ user_id: user?.id, group }));
        navigation.navigate(`HomeNavigator`, { screen: `HomeScreen` });
      } else {
        await dispatch(setPreviewCommunity(group));
        navigation.navigate(`CommunityPreview`);
      }
    },
    [dispatch, navigation, user?.id],
  );

  const renderItem = useCallback(
    ({ item: group }) => {
      return (
        <GroupCardRounded
          group={group?.group}
          handleArrowPress={() => handleArrowPress(group)}
          handleCardPress={() => handleArrowPress(group)}
          showMembershipPill
        />
      );
    },
    [handleArrowPress],
  );

  const renderHeader = useCallback(() => {
    const foundGroups = groups?.getGroupsByCategoryName3;
    if (!called || !foundGroups || foundGroups.length === 0) {
      return null;
    }
    return <Header order={order} cycleOrdering={cycleOrdering} />;
  }, [groups, order, called, cycleOrdering]);

  const buildModalOptions = useCallback(() => {
    const options = isGuestMode
      ? [
          {
            title: `About`,
            iconName: `info-circle`,
            testID: `MENU_REPORT_COMMUNITY`,
            onPress: () => {
              setShowOptionsModal(false);
              navigation.push(`AboutScreen`, { group, group_member: groupMember });
            },
          },
          {
            predicate: () => !groupMember,
            title: `Join`,
            iconName: `join`,
            testID: `MENU_REPORT_COMMUNITY`,
            onPress: () => {
              if (isGuestMode) {
                setShowOptionsModal(false);
                dispatch(toggleJoinMeshModal({ open: true, group }));
              } else {
                setShowOptionsModal(false);
                dispatch(setPreviewCommunity(group));
                navigation.push(`CommunityPreview`, { triggerDecideJoinMethod: true });
              }
            },
          },
        ]
      : [
          {
            title: `About`,
            iconName: `info-circle`,
            testID: `MENU_REPORT_COMMUNITY`,
            onPress: () => {
              setShowOptionsModal(false);
              navigation.push(`AboutScreen`, { group, group_member: groupMember });
            },
          },
          {
            predicate: () => !groupMember,
            title: `Join`,
            iconName: `join`,
            testID: `MENU_REPORT_COMMUNITY`,
            onPress: () => {
              setShowOptionsModal(false);
              dispatch(setPreviewCommunity(group));
              navigation.push(`CommunityPreview`, { triggerDecideJoinMethod: true });
            },
          },
          {
            predicate: () => !groupMember,
            title: `Hide node`,
            iconName: `eye-hide`,
            titleStyle: { color: Colors.alertColor },
            iconColor: Colors.alertColor,
            testID: `MENU_REPORT_COMMUNITY`,
            onPress: hideCommunity,
          },
          {
            predicate: () => groupMember && groupMember?.role_name !== OWNER,
            title: `Leave`,
            iconName: `logout`,
            titleStyle: { color: Colors.alertColor },
            iconColor: Colors.alertColor,
            testID: `MENU_REPORT_COMMUNITY`,
            onPress: () => {
              setShowOptionsModal(false);
              dispatch(setPreviewCommunity(group));
              navigation.push(`CommunityPreview`, { triggerLeaveCommunityMethod: true });
            },
          },
          {
            predicate: () => groupMember?.role_id !== OWNER,
            title: `Report`,
            iconName: `flag`,
            titleStyle: { color: Colors.alertColor },
            iconColor: Colors.alertColor,
            testID: `MENU_REPORT_COMMUNITY`,
            onPress: reportGroup,
          },
        ];
    const filteredOptions = options.filter((option) => option.predicate === undefined || option.predicate());
    return filteredOptions;
  }, [isGuestMode, hideCommunity, reportGroup, navigation, group, groupMember, dispatch]);

  const addToNotToRecommend = useCallback(
    (group_id) => {
      setShowOptionsModal(false);
      const blacklist = JSON.parse(blackListSetting?.value || `[]`) || [];
      const blackListSet = new Set(blacklist);
      let value = `[]`;
      if (group_id && !blackListSet.has(group_id)) {
        blacklist.push(group_id);
        console.log(`blacklisting group_id: ${blacklist}`);
      }
      value = JSON.stringify(blacklist);
      const input = {
        value,
        key: `user.setting.blacklist`,
        user_id: user?.id,
      };

      if (blackListSetting) {
        // manually update the setting value in the apollo cache
        client.writeFragment({
          id: `Setting:${blackListSetting?.id}`,
          fragment: SETTING_VALUE_FRAGMENT,
          data: { __typename: `Setting`, id: blackListSetting?.id, value },
        });
      }
      console.log(`done with WriteFragment`);

      // // and send the update off to the server asynchronously - this will cause everything to refresh,
      // // including recommendations
      updateSetting({ variables: { input } });
    },
    [user, blackListSetting, updateSetting],
  );

  const hideCommunity = useCallback(() => {
    Alert.alert(
      `Are you sure?`,
      `You will not see this community again in your recommendations`,
      [
        {
          text: `Cancel`,
          style: `cancel`,
          onPress: () => {
            setGroup(undefined);
            setShowOptionsModal(false);
          },
        },
        {
          text: `OK`,
          style: `default`,
          onPress: () => {
            addToNotToRecommend(group.id);
            setGroup(undefined);
            setShowOptionsModal(false);
          },
        },
      ],
      { cancelable: true },
    );
  }, [group, addToNotToRecommend]);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>
      <View
        style={{
          marginVertical: 16,
          marginHorizontal: 16,
          flexDirection: `row`,
          justifyContent: `space-between`,
          alignItems: `center`,
        }}
      >
        <Text maxFontSizeMultiplier={2} style={Typography.text(`bold`, `gray`)}>
          {categoryData?.label}
        </Text>
        {!isGuestMode && (
          <ThemedButton
            rounded
            clear={!following}
            outline
            title={following ? `Unfollow` : `Follow`}
            titleStyle={following ? { color: Colors.white } : { color: Colors.brandPurple }}
            containerStyle={{ marginLeft: 16, marginRight: 8 }}
            buttonStyle={{ paddingHorizontal: 20, paddingVertical: 7, alignItems: `center` }}
            onPress={() => toggleFollow(categoryData, following)}
          />
        )}
      </View>
      <FlatList
        data={groups ? groups.getGroupsByCategoryName3 : []}
        keyExtractor={(groupAndMembership) => groupAndMembership.group.id}
        contentContainerStyle={{
          flexGrow: 1,
          height: Platform.select({ web: Dimensions.get(`window`).height - 48 * 2 - 42 }),
        }}
        ListHeaderComponent={renderHeader}
        renderItem={renderItem}
        ListEmptyComponent={<EmptyListComponent navigation={navigation} loading={loading} />}
        ItemSeparatorComponent={ItemSeparatorComponent}
        ListFooterComponent={ListFooterComponent}
        refreshing={networkStatus === NetworkStatus.refetch}
      />
      {showOptionsModal && (
        <BottomSheetModal
          title="Manage"
          options={buildModalOptions()}
          visible={showOptionsModal}
          onPressCancel={() => setShowOptionsModal(false)}
          showCancelBtn={false}
          onPressConfirm={() => setShowOptionsModal(false)}
          confirmTitle="Close"
        />
      )}
      {showFilterModal && (
        <ExploreFilters
          isVisible={showFilterModal}
          onBackdropPress={() => setShowFilterModal(false)}
          setFilters={saveFilters}
          membersFilter={filters.members}
          postsFilter={filters.posts}
          membershipStatus={filters.membership}
        />
      )}
      {report && (
        <View style={{ flex: 1 }}>
          <ReportModal
            reportType={MODERATION_TYPE.community}
            isCommunityReport
            isVisible
            origin_group_id={report?.id}
            onClose={() => setReport(undefined)}
            navigation={navigation}
          />
        </View>
      )}
    </View>
  );
};

const Header = memo(({ order, cycleOrdering }) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: `row`,
        justifyContent: `space-between`,
        alignItems: `center`,
        backgroundColor: Colors.lightGray,
      }}
    >
      <Text
        maxFontSizeMultiplier={2}
        style={{ marginHorizontal: 16, marginVertical: 8, ...Typography.text(`bold`, `base`, `gray`) }}
      >
        Related
      </Text>
      <TouchableOpacity onPress={cycleOrdering}>
        {order === `default` ? (
          <MeshIcon name="clock" size={16} color={Colors.focusedIconColor} style={{ marginHorizontal: 16, marginVertical: 8 }} />
        ) : (
          <Text
            maxFontSizeMultiplier={2}
            style={{ marginHorizontal: 16, marginVertical: 8, ...Typography.text(`bold`, `theme`, `small`) }}
          >
            {order}
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );
});

Header.propTypes = {
  order: PropTypes.string.isRequired,
  cycleOrdering: PropTypes.func.isRequired,
};

const GroupCard = memo(({ group, setGroup, setGroupMember, setShowOptionsModal }) => {
  const navigation = useNavigation();
  return (
    <View style={{ flexDirection: `row`, justifyContent: `center`, alignContent: `center` }}>
      <View style={{ flex: 1 }}>
        <CommunityListItem
          navigation={navigation}
          group={group.group}
          group_member={group.group_member}
          hide_category
          showMemberPill
        />
      </View>
      <View style={{ justifyContent: `center` }}>
        <ThreeDotsButton
          onPress={() => {
            setGroup(group.group);
            setGroupMember(group.group_member);
            setShowOptionsModal(true);
          }}
          size={24}
          style={{ marginRight: 12 }}
        />
      </View>
    </View>
  );
});

GroupCard.propTypes = {
  group: commonPropTypes.group().isRequired,
  setGroup: PropTypes.func.isRequired,
  setGroupMember: PropTypes.func.isRequired,
  setShowOptionsModal: PropTypes.func.isRequired,
};

const EmptyListComponent = ({ loading }) => {
  const { isGuestMode } = useContext(AppContext);
  const navigation = useNavigation();

  if (loading) {
    return <LoadingIndicator size={32} style={{ margin: 32 }} />;
  }
  return (
    <View style={{ justifyContent: `center`, flexDirection: `column`, marginTop: isGuestMode ? 0 : 47, height: `100%` }}>
      <View style={{ display: `flex`, justifyContent: `center`, flexDirection: `row` }}>
        <NoCategories />
      </View>
      <View style={{ marginHorizontal: 32, marginTop: 31 }}>
        <Text style={{ ...Typography.text(`bold`, `plustwo`), textAlign: `center` }}>No communities yet</Text>
        {!isGuestMode && (
          <Text style={{ ...Typography.text(`plain`, `plustwo`), marginTop: 10, textAlign: `center` }}>
            Be the first and start a community based on your interests.
          </Text>
        )}
      </View>
      {!isGuestMode && (
        <ThemedButton
          rounded
          title="Create a node"
          titleStyle={{ color: Colors.white }}
          containerStyle={{ flex: 1, marginTop: 24 }}
          buttonStyle={{ alignItems: `center`, marginHorizontal: 16 }}
          onPress={() => navigation.push(`CreateCommunity`)}
        />
      )}
    </View>
  );
};

EmptyListComponent.propTypes = {
  loading: PropTypes.bool,
};
EmptyListComponent.defaultProps = {
  loading: true,
};
