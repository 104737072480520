import React, { useCallback } from 'react';
import { ImageBackground, View } from 'react-native';

type IMemeLayoutItem = {
  layout: number;
  images: any[];
  isText: boolean;
  hidden?: boolean;
};

const MemeLayoutItem: React.FC<IMemeLayoutItem> = ({ layout, images, isText, hidden }) => {
  const flexDirection = layout === 3 ? `column` : `row`;
  const loop = layout === 3 ? 2 : layout;
  const width = layout === 2 || layout === 4 ? `50%` : `100%`;
  const height = layout === 3 || layout === 4 ? `50%` : `100%`;

  const borderLeftWidth = useCallback(
    (index: number) => {
      if (hidden) return 0;
      if ((layout === 2 && index % 2 !== 0) || (layout === 4 && index % 2 !== 0)) return 1;
      return 0;
    },
    [layout, hidden],
  );

  const borderRightWidth = useCallback(
    (index: number) => {
      if (hidden) return 0;
      if ((layout === 2 && index % 2 === 0) || (layout === 4 && index % 2 === 0)) return 1;
      return 0;
    },
    [layout, hidden],
  );

  const borderTopWidth = useCallback(
    (index: number) => {
      if (hidden) return 0;
      if ((layout === 4 && (index === 2 || index === 3)) || (layout === 3 && index === 1)) return 1;
      return 0;
    },
    [layout, hidden],
  );

  const borderBottomWidth = useCallback(
    (index: number) => {
      if (hidden) return 0;
      if ((layout === 4 && (index === 0 || index === 1)) || (layout === 3 && index === 0)) return 1;
      return 0;
    },
    [layout, hidden],
  );

  return (
    <View style={{ flex: 1, display: `flex`, flexDirection, flexWrap: `wrap` }} key={images?.length}>
      {Array.from(Array(loop)).map((val: any, idx: number) => (
        <View
          key={idx}
          style={{
            width,
            height,
            borderLeftWidth: borderLeftWidth(idx),
            borderRightWidth: borderRightWidth(idx),
            borderTopWidth: borderTopWidth(idx),
            borderBottomWidth: borderBottomWidth(idx),
            borderColor: `white`,
          }}
        >
          <ImageBackground
            style={{ width: `100%`, height: `100%`, opacity: isText ? 0.8 : 1 }}
            source={{ uri: images?.[idx]?.path }}
            resizeMode="cover"
          />
        </View>
      ))}
    </View>
  );
};

export default MemeLayoutItem;
