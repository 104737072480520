import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Text, View } from 'react-native';
import { Typography } from '../common-styles';
import { ThemedButton } from '../common-ui';
import { SelfieVerificationSentImage } from '../images/SelfieVerificationSentImage';
import MeshWordmarkSVG from '../../assets/images/mesh-wordmark.svg';

export const VerificationSelfieSentScreen = () => {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => <MeshWordmarkSVG width={68} height={25} />,
      headerRight: () => <ThemedButton clear title="Done" containerStyle={{ right: 8 }} onPress={() => navigation.popToTop()} />,
    });
  }, [navigation]);

  return (
    <View style={localStyles.container}>
      <SelfieVerificationSentImage style={{ marginTop: -100 }} />
      <Text style={localStyles.text}>
        Thanks for taking the steps to complete your profile! Your application is currently being reviewed by the mesh admins. You
        will recieve a notification once our review is complete.
      </Text>
    </View>
  );
};

const localStyles = {
  container: {
    flex: 1,
    justifyContent: `center`,
    alignItems: `center`,
  },
  text: {
    ...Typography.text(`center`),
    marginTop: 35,
    marginHorizontal: 55,
  },
};
