import React, { useCallback, useState, useEffect } from 'react';
import { FlatList, SafeAreaView, StyleSheet, Text, TextInput, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Colors, Typography } from '../common-styles';
import { MeshIcon, ThemedButton } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { AmountBox } from './AmountBox';
import { CONTRIBUTION_AMOUNTS } from './constants';
import { setAmount, setCustomer, setEmail, setPaymentMethods } from '../redux/contributionSlice';
import { client } from '../graphql';
import { SETTING_QUERY } from '../graphql/Queries.gql';
import { BASE_PAYMENT_API_URL } from '../../constants';
import { AppContext } from '../../AppContext';
import { _AMOUNT_LIST, _AMOUNT_BOX, _CONTRIBUTION_AMOUNT_INPUT, _CONTINUE_CONTRIBUTION_BUTTON } from './testLabels';

const SelectAmount = ({ navigation }) => {
  const { amount, email, customer } = useSelector((state) => state.contributions);
  const dispatch = useDispatch();

  const [selectedAmount, setSelectedAmount] = useState(amount || 0);
  const [typedAmount, setTypedAmount] = useState(``);
  const { user } = React.useContext(AppContext);

  useEffect(() => {
    const getUserSettings = async () => {
      try {
        const { data } = await client.query({
          query: SETTING_QUERY,
          variables: {
            settingQuery: {
              query: `user`,
              user_id: user?.id,
            },
          },
        });
        const settings = data?.getSettings || [];
        const [userEmail] = settings.filter((setting) => setting.key === `user.setting.email`);
        const [customerSetting] = settings.filter((setting) => setting.key === `user.setting.customer_id`);

        if (customerSetting?.value) {
          const response = await fetch(`${BASE_PAYMENT_API_URL}customers/retrieve/${customerSetting.value}`, {
            method: `GET`,
            headers: {
              'Content-Type': `application/json`,
            },
          });
          const { customer, paymentMethods } = await response.json();

          // setCustomer(customer);
          dispatch(setCustomer(customer));
          // setDefaultPaymentMethod(customer?.invoice_settings.default_payment_method);

          // setPaymentMethods(paymentMethods?.data);
          dispatch(setPaymentMethods(paymentMethods?.data));
        }

        // setEmail(userEmail?.value);
        dispatch(setEmail(userEmail?.value));
        // setCustomerId(customerSetting?.value);
      } catch (error) {
        // TODO: Should Handle this error better
        console.error(`Error while getting the user email ===> `, error);
      }
    };
    if (!user?.id) return;
    if (!email || !customer) {
      getUserSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const handleTypedAmount = (value) => {
    if (selectedAmount) setSelectedAmount(``);
    setTypedAmount(value);
  };

  const handleContinue = () => {
    if (!typedAmount && !selectedAmount) return;

    const amount = Number(typedAmount || selectedAmount.price);
    dispatch(setAmount(amount));
    navigation.push(`Contribute`);
  };

  const isValidTypedAmount = (str) => {
    if (typeof str !== `string`) return false;
    return !Number.isNaN(str) && !Number.isNaN(parseFloat(str)) && parseFloat(str) >= 0.5;
  };

  const renderItem = useCallback(
    ({ item }) => (
      <AmountBox
        testID="AMOUNT_BOX"
        isSelected={selectedAmount.id === item.id}
        onChangeSelection={() => {
          setSelectedAmount(item);
          setTypedAmount(item.price.toString());
        }}
        amount={item.price}
      />
    ),
    [selectedAmount],
  );

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.ammountsContainer}>
        <FlatList
          testID="AMOUNT_LIST"
          data={CONTRIBUTION_AMOUNTS}
          numColumns={2}
          renderItem={renderItem}
          keyExtractor={({ id }) => id}
        />
      </View>
      <View style={styles.labelAndTextContainer}>
        <Text style={{ fontWeight: `600` }}>Amount</Text>
        <View style={styles.typeAmountContainer}>
          <MeshIcon name="dollar" size={18} />
          <TextInput
            testID="CONTRIBUTION_AMOUNT_INPUT"
            keyboardType="numeric"
            returnKeyType="done"
            style={styles.typeAmountTextField}
            value={typedAmount}
            placeholder="Enter amount"
            placeholderTextColor={Colors.textPlaceholder}
            onChangeText={handleTypedAmount}
            underlineColorAndroid="transparent"
          />
        </View>
      </View>

      {typedAmount.trim().length !== 0 && parseFloat(typedAmount) < 0.5 && (
        <Text style={{ ...Typography.text(), marginTop: 16 }}>The minimum amount to contribute is 0.50$.</Text>
      )}

      <ThemedButton
        rounded
        title="Continue"
        testID="CONTINUE_CONTRIBUTION_BUTTON"
        onPress={handleContinue}
        color={Colors.deepPurple}
        buttonStyle={{ paddingHorizontal: 30, paddingVertical: 12, marginTop: 20 }}
        // leftIcon={loading && <LoadingIndicator />}
        disabled={!isValidTypedAmount(typedAmount) && !selectedAmount.price}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 20,
  },
  labelAndTextContainer: {
    marginTop: 40,
  },
  ammountsContainer: {
    marginTop: 40,
    alignItems: `center`,
    width: `100%`,
    height: 232,
  },
  typeAmountContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 8,
    borderWidth: 1,
    paddingLeft: 4,
    borderColor: `#BEB9B8`,
    borderRadius: 4,
  },
  typeAmountTextField: {
    height: 40,
    paddingTop: 11,
    width: `100%`,
    paddingBottom: 12,
    paddingLeft: 13,
    paddingRight: 13,
  },
});

SelectAmount.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

export { SelectAmount };
