/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isListRefresh: false,
};

const inboxSlice = createSlice({
  name: `inbox`,
  initialState,
  reducers: {
    refreshList: (state, action: PayloadAction<boolean>) => {
      state.isListRefresh = action.payload;
    },
  },
});

export const inboxReducer = inboxSlice.reducer;

export const { refreshList } = inboxSlice.actions;
