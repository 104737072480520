import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import React from 'react';
import QRCode from 'react-native-qrcode-svg';
import { Colors, Typography } from '../common-styles';
import { BlobBlue, BlobGreen, BlobYellow } from '../common-ui/Blobs';

type PageTemplateProps = {
  url: string;
};

export const PageTemplate: React.FC = ({ children }) => {
  const { width } = useWindowDimensions();
  return (
    <View style={{ flex: 1, justifyContent: `center`, alignItems: `center` }}>
      {/* background */}
      <View style={styles.blobContainer}>
        <BlobBlue
          width={width * 1.29}
          height={width * 0.9}
          style={{ position: `absolute`, left: `38%`, top: `13%`, transform: [{ scaleY: 0.3 }] }}
        />
      </View>
      <View style={{ flex: 0.5 }} />
      <View style={{ flexGrow: 1, flexBasis: 300, flexDirection: `row`, justifyContent: `center`, flexWrap: `wrap` }}>
        {children}
      </View>
      <View style={{ flex: 1 }} />
    </View>
  );
};

export const QRTemplate: React.FC<PageTemplateProps> = ({ children, url }) => {
  return (
    <PageTemplate>
      <View style={{ flex: 1 }} />
      {/* left card */}
      <View style={{ flexGrow: 3, flexBasis: 300, marginHorizontal: 60, marginBottom: 60 }}>
        <Text style={styles.header}>{children}</Text>
        <Text style={[styles.subheader, { marginVertical: 19, marginRight: 60 }]}>
          Open this link on your mobile device or scan this QR code with your mobile phone.
        </Text>
      </View>
      {/* right card */}
      <View style={{ flexGrow: 3, flexBasis: 300, marginHorizontal: 60, marginBottom: 60 }}>
        <View style={styles.QRContainer}>
          <View style={{ flexDirection: `row`, justifyContent: `center`, margin: 40 }}>
            <QRCode
              value={url}
              size={200}
              enableLinearGradient
              gradientDirection={[`20%`, `20%`, `85%`, `85%`]}
              linearGradient={[Colors.brandPink, Colors.brandPurple, Colors.brandCyan]}
            />
          </View>
          <View style={{ borderBottomWidth: 1, borderColor: Colors.dividerColor }} />
          <View style={{ margin: 40, alignItems: `center` }}>
            <View style={styles.blobMessage}>
              <BlobGreen width={45} height={40} style={{ position: `absolute`, left: 0, top: 0, zIndex: -1 }} />
              <Text style={styles.instructions_1}>1</Text>
            </View>
            <Text style={styles.instructions_text}>
              Open the camera app on your mobile phone. Your device recognizes the QR code and will show a notification.
            </Text>
            <View style={{ height: 4 }} />
            <View style={styles.blobMessage}>
              <BlobYellow width={45} height={40} style={{ position: `absolute`, left: 0, top: 0, zIndex: -1 }} />
              <Text style={styles.instructions_2}>2</Text>
            </View>
            <Text style={styles.instructions_text}>Tap the notification to open the link.</Text>
          </View>
        </View>
      </View>
      <View style={{ flex: 1 }} />
    </PageTemplate>
  );
};

const styles = StyleSheet.create({
  header: { ...Typography.text(`huge`), fontFamily: `sans-serif` },
  subheader: { ...Typography.text(`large`), fontSize: 18, lineHeight: 28, fontFamily: `sans-serif` },
  blobContainer: {
    position: `absolute`,
    width: `100%`,
    height: `100%`,
    zIndex: -1,
    overflow: `hidden`,
  },
  blobMessage: { width: 45, height: 40, alignItems: `center`, justifyContent: `center`, margin: 14 },
  QRContainer: {
    borderRadius: 7,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.dividerColor,
    // ios shadow
    shadowColor: `rgba(0,0,0,0.5)`,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.25,
    // android shadow
    elevation: 4,
  },
  instructions_1: {
    ...Typography.text(`large`, `bold`, `center`),
    fontSize: 24,
    fontWeight: `600`,
    lineHeight: 29,
    fontFamily: `sans-serif`,
    color: `#2C9A44`,
    textAlign: `center`,
    margin: 14,
  },
  instructions_2: {
    ...Typography.text(`large`, `bold`, `center`),
    fontSize: 24,
    fontWeight: `600`,
    lineHeight: 29,
    fontFamily: `sans-serif`,
    color: `#887E21`,
    margin: 14,
  },
  instructions_text: { ...Typography.text(`large`, `center`), fontSize: 18, lineHeight: 24, fontFamily: `sans-serif` },
});
