/* eslint-disable no-param-reassign */
/* 
	NOTE: 
	We are tracking the actionToTake value which is a string that triggers a modal in PostDetail when certain conditions are met in useLike.
	Ideally we should put all the shared logic of feed reactions and likes here, but in order to make as less changes as possible we just leave
	the slice with this simple action. 

	Todo: 
	PostDetail2 have an internal state that is used to trigger different kind of modals. In the near future
	we will be able to move all the logic to this slice.
*/

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupCoreFields, PersonaMetadata, Feed, TopicItem } from '../common-types/types';
import { setAsyncStorageObject } from '../common-util';

export type FeedState = {
  previewCommunity: GroupCoreFields | null;
  activeCommunity: GroupCoreFields | null;
  actionToTake: string | null;
  joinedCommunities: GroupCoreFields[];
  personasMetadata: PersonaMetadata[];
  openItems: number;
  feeds: Feed;
  forceExplorerRefresh: boolean;
  selectedTopic: TopicItem | null;
  isRefreshTopicLists: boolean;
  nodeMode: `preview` | `home` | undefined;
  forcePersonalFeedRefresh: boolean;
};

const initialState: FeedState = {
  previewCommunity: null,
  actionToTake: null,
  activeCommunity: null,
  personasMetadata: [],
  joinedCommunities: [],
  openItems: 0,
  feeds: [],
  forceExplorerRefresh: false,
  selectedTopic: null,
  isRefreshTopicLists: false,
  nodeMode: undefined,
  forcePersonalFeedRefresh: false,
};

const feedSlice = createSlice({
  name: `feed`,
  initialState,
  reducers: {
    setActionToTake: (state, action) => {
      state.actionToTake = action.payload;
    },
    setActiveCommunity: (state, action: PayloadAction<{ user_id: string; group: GroupCoreFields; from?: string }>) => {
      const { user_id, group, from } = action.payload;
      // if (group.id !== state.activeCommunity?.id || group.name !== state.activeCommunity?.name) {
      /*try {
          if (!from) throw new Error("[Redux] illegal call to ActiveCommunity");
        } catch (error: any) {
          console.warn(error.message);
          console.log("keys: ", Object.keys(error));
        }*/
      console.log(`🚀 ~[Redux] ActiveCommunity`, group?.name, user_id, `${from || ``}`);
      state.activeCommunity = group;
      setAsyncStorageObject(`${user_id}_Latest`, group);
      // }
    },
    setMemberCount: (state, action: PayloadAction<GroupCoreFields>) => {
      const group = action.payload;
      if (group.id === state.activeCommunity?.id) state.activeCommunity.group_member_count = group.group_member_count;
    },
    setPreviewCommunity: (state, action: PayloadAction<GroupCoreFields>) => {
      console.log(`🚀 ~[Redux] PreviewCommunity`);
      state.previewCommunity = action.payload;
    },
    setJoinedCommunities: (state, action: PayloadAction<GroupCoreFields[]>) => {
      state.joinedCommunities = action.payload;
    },
    setOpenItems: (state, action: PayloadAction<number>) => {
      state.openItems = action.payload;
    },
    setFeeds: (state, action) => {
      state.feeds = action.payload;
    },
    setTopic: (state, action) => {
      // const idx = state.selectedTopics.findIndex((st) => st.id === action.payload?.id);
      // if (idx !== -1) {
      //   state.selectedTopics.splice(idx, 1);
      // } else {
      //   state.selectedTopics = [...state.selectedTopics, action.payload];
      // }
      state.selectedTopic = action.payload;
    },
    setForceRefreshTopicLists: (state, action) => {
      state.isRefreshTopicLists = action.payload;
    },
    updateFeedItem: (state, action) => {
      const item = action.payload;
      state.feeds = state.feeds.map((it: any) => (it.id === item.id ? item : it));
    },
    setForceExplorerRefresh: (state, action) => {
      state.forceExplorerRefresh = action.payload;
    },
    setNodeMode: (state, action) => {
      state.nodeMode = action.payload;
    },
    setPersonalFeedRefresh: (state, action) => {
      state.forcePersonalFeedRefresh = action.payload;
    },
    cleanState: () => {
      return initialState;
    },
  },
});

export const {
  setActionToTake,
  setActiveCommunity,
  setMemberCount,
  setJoinedCommunities,
  setOpenItems,
  setPreviewCommunity,
  setFeeds,
  setTopic,
  updateFeedItem,
  setForceExplorerRefresh,
  cleanState,
  setForceRefreshTopicLists,
  setNodeMode,
  setPersonalFeedRefresh,
} = feedSlice.actions;

const feedReducer = feedSlice.reducer;

export { feedReducer };
