import React from 'react';
import { StyleSheet, View } from 'react-native';
import { EmptyList } from './EmptyList';

type LoadingBGProps = {
  loading: boolean;
};

export const LoadingBG: React.FC<LoadingBGProps> = ({ loading }) => {
  if (loading) {
    return (
      <View style={Styles.container}>
        <EmptyList isLoading={true} message="Loading..." />
      </View>
    );
  }
  return null;
};

const Styles = StyleSheet.create({
  container: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    position: `absolute`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 11,
  },
});
