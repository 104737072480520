import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import { RouteProp, useRoute, useNavigation } from '@react-navigation/native';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';
import { useDispatch } from 'react-redux';
import { unionBy } from 'lodash';
import LottieView from 'lottie-react-native';
import { setPreviewCommunity } from '../redux/feedSlice';
import { ExploreNavigator, ExploreNavigatorScreens } from '../common-types/navigation-types';
import { Colors, Typography } from '../common-styles';
import { GET_EXPLORE_FEATURED, GET_EXPLORE_NEW } from '../graphql';
import { AppContext } from '../../AppContext';
import { Group } from '../common-types/types';
import { CenterColumn } from '../common-ui';
import { GroupCardRounded } from '../common-ui/GroupCardRounded';

export type ExploreSeeAllGroupsRoute = RouteProp<ExploreNavigatorScreens, `ExploreSeeAllGroups`>;

type SearchResults = {
  getFeaturedCommunities: Group[];
  getNewCommunities: Group[];
};

export const ExploreSeeAllGroups = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<ExploreNavigator>();
  const { user } = useContext(AppContext);
  const { params } = useRoute<ExploreSeeAllGroupsRoute>();
  const { title = `` } = params;
  const isFeatured = title.toLowerCase().includes(`featured`);

  const [communities, setCommunities] = useState<Group[]>([]);

  const {
    data: exploreExtraData,
    loading: loadingExploreExtras,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery<SearchResults>(isFeatured ? GET_EXPLORE_FEATURED : GET_EXPLORE_NEW, {
    variables: { user_id: user?.id, limit: 15, offset: 0 },
    fetchPolicy: `cache-and-network`,
    onError: (error) => console.error(`[GET_EXPLORE_EXTRAS error]`, error.message),
  });

  useEffect(() => {
    navigation.setOptions({
      title,
    });
  }, [navigation, title]);

  useEffect(() => {
    if (exploreExtraData) {
      const isFeatured = title.toLowerCase().includes(`featured`);
      setCommunities(isFeatured ? exploreExtraData.getFeaturedCommunities : exploreExtraData.getNewCommunities);
    }
  }, [exploreExtraData, title, setCommunities]);

  const handleArrowPress = useCallback(
    async (group: Group) => {
      if (group?.discoverable !== `public`) return;
      await dispatch(setPreviewCommunity(group));
      navigation.navigate(`CommunityPreview`);
    },
    [dispatch, navigation],
  );

  const _renderSectionTitle = useCallback(() => {
    return (
      <CenterColumn>
        <View
          style={{
            flexDirection: `row`,
            backgroundColor: Colors.commentGray,
            paddingVertical: 3,
            paddingHorizontal: 16,
            marginBottom: 16,
          }}
        >
          <Text maxFontSizeMultiplier={2} style={{ ...Typography.text(`bold`, `gray`), paddingVertical: 4 }}>
            Related
          </Text>
        </View>
      </CenterColumn>
    );
  }, []);

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <GroupCardRounded
          group={item}
          handleArrowPress={handleArrowPress}
          handleCardPress={handleArrowPress}
          showMembershipPill
        />
      );
    },
    [handleArrowPress],
  );

  const onEndReached = useCallback(() => {
    const filterKey = isFeatured ? `getFeaturedCommunities` : `getNewCommunities`;
    if (!exploreExtraData || !exploreExtraData[filterKey].length) return;
    const totalCounts = exploreExtraData[filterKey].length;
    if (!loadingExploreExtras) {
      fetchMore({
        variables: { user_id: user?.id, offset: totalCounts },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult[filterKey]?.length ||
            fetchMoreResult[filterKey].slice(-1)[0].id === prev[filterKey].slice(-1)[0].id
          ) {
            console.log(`[explore]is the same, return prev`);
            return prev;
          }
          const newLists = unionBy(prev[filterKey], fetchMoreResult[filterKey], (li: any) => li.id);
          setCommunities(newLists);
          return {
            ...prev,
            [filterKey]: newLists,
          };
        },
      });
    }
  }, [isFeatured, fetchMore, setCommunities, exploreExtraData, user?.id, loadingExploreExtras]);

  const renderEmptyList = useCallback(() => {
    if (loadingExploreExtras) return null;
    return (
      <View style={{ flex: 1, justifyContent: `center`, alignItems: `center`, marginTop: 50 }}>
        <Text style={{ ...Typography.text(`gray`) }}>No nodes to display</Text>
      </View>
    );
  }, []);

  return (
    <View style={{ flex: 1 }}>
      {loadingExploreExtras && !communities.length ? (
        <View style={{ flex: 1, justifyContent: `center`, alignItems: `center`, marginTop: 50 }}>
          <LottieView
            resizeMode="cover"
            style={{ width: 200, height: 200 }}
            source={require(`../images/animated/animatedLoading.json`)}
            autoPlay
            loop
          />
        </View>
      ) : (
        <FlatList
          data={communities || []}
          // ListHeaderComponent={renderSectionTitle}
          renderItem={renderItem}
          keyExtractor={({ id }) => id}
          showsVerticalScrollIndicator={false}
          ListEmptyComponent={renderEmptyList}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={refetch}
          onEndReachedThreshold={1.5}
          onEndReached={onEndReached}
        />
      )}
    </View>
  );
};
