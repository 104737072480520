import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'react-native';
import { useChatContext, useChannelContext } from 'stream-chat-react-native';
import LottieView from 'lottie-react-native';
import { Avatar } from '../common-ui';
import { Colors } from '../common-styles';

export const ChatTypingIndicator = () => {
  const [typingUsers, setTypingUsers] = useState([]);
  const { channel, typing } = useChannelContext();
  const { client } = useChatContext();

  const getUsersWithStatus = useCallback(async () => {
    if (typing)
      try {
        const promises = Object.values(typing).map(async ({ user }) => {
          const blockedUsers = await channel.queryMembers({ id: user?.id, banned: true }, {}, { limit: 1 });
          return { ...user, blocked: !!blockedUsers?.members?.length };
        });

        const members = await Promise.all(promises);
        const otherMembers = members.filter((user) => !!user && user?.id !== client?.user?.id && !user?.blocked);
        setTypingUsers(otherMembers);
      } catch (error) {
        console.error(`🚀 ~ file: ChatTypingIndicator.jsx ~ line 23 ~ getUsersWithStatus ~ error`, error.message);
      }
  }, [channel, typing, client]);

  useEffect(() => {
    getUsersWithStatus();
  }, [getUsersWithStatus]);

  const renderAvatar = (typingUsers) => {
    if (Array.isArray(typingUsers) && typingUsers.length > 1) {
      return <Avatar style={{ marginRight: 7 }} user={typingUsers[0]} size={30} border />;
    }

    return null;
  };

  if (!typingUsers.length) {
    return null;
  }

  return (
    <View style={{ flexDirection: `row`, alignItems: `center`, marginBottom: 10, marginTop: -8 }}>
      {renderAvatar(typingUsers)}
      <View
        style={{
          width: 57,
          height: 30,
          paddingVertical: 10,
          paddingHorizontal: 21,
          backgroundColor: Colors.chatTypingIndicatorContainer,
          borderRadius: 15,
          borderTopLeftRadius: 0,
        }}
      >
        <LottieView resizeMode="cover" source={require(`./typing.json`)} autoPlay loop />
      </View>
    </View>
  );
};
