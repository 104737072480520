import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { SettingManager } from '../common-settings/SettingManager';
import { ProfileSettingsHeader } from './ProfileSettingsHeader';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { ListItem, MeshIcon, SafeAreaView, BottomSheetModal, ThemedButton } from '../common-ui';
import { loadCachedGroupMember } from '../graphql';
import { useModerationGroups } from '../moderation/hooks/useModerationGroups';
import { ProfileNavigator } from '../common-types/navigation-types';

export const ProfileSettings = React.memo(() => {
  const navigation = useNavigation<ProfileNavigator>();
  const route = useRoute();
  const { user } = React.useContext(AppContext);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const { moderatedGroups } = useModerationGroups();
  const isOwner = moderatedGroups?.getModeratedGroups2.reduce((res, { group }) => {
    const gm = loadCachedGroupMember(group.id, user?.id);
    return gm?.role_name === `owner` || res;
  }, false);

  const handleConfirmPress = () => {
    setShowDeleteAccountModal(false);
    if (isOwner) navigation.navigate(`EditOwnershipScreen`);
    else navigation.navigate(`EnterYourPasswordScreen`);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <SettingManager
        mode="user"
        navigation={navigation}
        route={route}
        ListHeaderComponent={user ? <ProfileSettingsHeader user={user} /> : null}
        ListFooterComponent={
          <ListItem
            testID="DELETE_ACCOUNT"
            title="Delete Account"
            subtitle="Permenantly remove account"
            onPress={() => setShowDeleteAccountModal(true)}
            titleStyle={Typography.text(`small`, `red`)}
            subtitleStyle={Typography.text(`bold`, `red`)}
            rightIcon={<MeshIcon name="chevron-right" color={Colors.iconColor} size={20} />}
          />
        }
      />

      <BottomSheetModal
        title={isOwner ? `Delete account` : `Are you sure?`}
        visible={showDeleteAccountModal}
        showCancelBtn={false}
        showConfirmBtn={false}
        onPressCancel={() => setShowDeleteAccountModal(false)}
        onPressConfirm={() => {}}
      >
        <View style={{ flexDirection: `column` }}>
          <Text style={{ ...Typography.text(`darkGray`, `center`), marginTop: 16, marginBottom: 16, marginHorizontal: 16 }}>
            {isOwner
              ? `Since you are a community owner, you must assign a new owner to your communities or archive your communities before your account can be deleted.`
              : `Deleting your account cannot be undone.`}
          </Text>
          <ThemedButton
            rounded
            title={isOwner ? `Manage my communities` : `Delete my account`}
            onPress={handleConfirmPress}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginTop: 12, marginHorizontal: 16 }}
            titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
          />

          <ThemedButton
            rounded
            title="Cancel"
            onPress={() => setShowDeleteAccountModal(false)}
            buttonStyle={{ padding: 15, backgroundColor: Colors.white, borderWidth: 1, borderColor: Colors.brandPurple }}
            containerStyle={{ marginTop: 12, marginBottom: 20, marginHorizontal: 16 }}
            titleStyle={{ ...Typography.text(`plustwo`, `theme`, `bold`), marginLeft: 4 }}
          />
        </View>
      </BottomSheetModal>
    </SafeAreaView>
  );
});
