import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { Colors, Typography } from '../common-styles';
import { Divider, MeshIcon, ListItem, LoadingIndicator } from '../common-ui';
import { useAppDispatch, RootState } from '../redux/store';
import { setActiveCommunity } from '../redux/feedSlice';
import { GroupMember } from '../common-types/types';
import { AppContext } from '../../AppContext';
import { GET_MODERATED_GROUP_MEMBERS } from '../graphql';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';

// eslint-disable-next-line quotes
type ThreeDotsCommunityRoute = RouteProp<HomeNavigatorScreens, 'ThreeDotCommunityDetails'>;

const buildLeadersAndModString = (length = 0) => {
  if (length === 1) return `View leader`;
  return `View ${length} leaders and moderators`;
};

const buildMembersString = (length = 0) => {
  if (length === 1) return `View member`;
  return `View ${length} members`;
};

//TODO: require or fetch admin_mods, group_member, group_members
//      currently gets undefined, undefined, []

export const ThreeDotCommunityDetails = () => {
  const { user } = useContext(AppContext);
  const navigation = useNavigation<HomeNavigator>();
  const route = useRoute<ThreeDotsCommunityRoute>();
  const dispatch = useAppDispatch();
  const { activeCommunity } = useSelector((state: RootState) => state.feed);
  const { group, group_member } = route.params;
  const [adminMods, setAdminMods] = React.useState<GroupMember[]>();
  // TODO: We need to keep using the group_member_count that comes from the group (params) and not the activeCommunity because it can be missunderstandings when using the moderation drawer
  const { avatar_url, name } = group || {};

  const { data, loading, error } = useQuery(GET_MODERATED_GROUP_MEMBERS, {
    variables: {
      group_id: group?.id,
    },
  });

  const goToCommmunity = () => {
    if (activeCommunity?.id !== group?.id && group?.id && user?.id) {
      dispatch(setActiveCommunity({ user_id: user.id, group }));
    }
    navigation.navigate(`HomeScreen`);
  };

  React.useEffect(() => {
    if (error || loading) return;
    const { admin_mods } = data?.getModeratedGroupMembers2 || {};
    if (admin_mods) setAdminMods(admin_mods);
  }, [data, error, loading]);

  const goToBanList = () => {
    navigation.navigate(`BannedUsersList`, { group, group_member });
  };

  return (
    <View>
      <View>
        <TouchableOpacity activeOpacity={0.8} onPress={goToCommmunity}>
          <View style={{ height: 96, backgroundColor: Colors.white }}>
            <View style={{ flexDirection: `row`, justifyContent: `flex-start`, marginRight: 16 }}>
              <Image source={{ uri: avatar_url }} style={localStyles.groupAvatar} />
              <View style={{ flexShrink: 1, justifyContent: `center` }}>
                <View style={{ flexDirection: `row`, alignItems: `center` }}>
                  <Text style={{ flexShrink: 1, ...Typography.text(`bold`, `plusone`) }}>{name}</Text>
                  <MeshIcon name="chevron-right" style={{ marginLeft: 4 }} />
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
        <Divider />
        <ListItem
          title="Custom Code of Conduct"
          subtitle="View all"
          rightIcon={<MeshIcon name="chevron-right" color={Colors.iconColor} />}
          titleStyle={localStyles.listItemTitle}
          subtitleStyle={localStyles.listItemSubtitle}
          onPress={() => navigation.navigate(`CommunityCodeOfConduct`, { group, group_member, agreeHidden: true })}
        />
        <View style={{ height: 10, width: `100%`, backgroundColor: Colors.backgroundColor }} />

        {loading ? (
          <LoadingIndicator size={36} style={{ padding: 18 }} />
        ) : (
          <>
            <ListItem
              title="Leaders and Moderators"
              subtitle={buildLeadersAndModString(adminMods?.length || 1)}
              rightIcon={<MeshIcon name="chevron-right" color={Colors.iconColor} />}
              titleStyle={localStyles.listItemTitle}
              subtitleStyle={localStyles.listItemSubtitle}
              onPress={() => navigation.navigate(`LeadersAndModerators`, { admin_mods: adminMods })}
            />
            <Divider />
            <ListItem
              title="Member List"
              subtitle={buildMembersString(activeCommunity?.group_member_count || 0)}
              rightIcon={<MeshIcon name="chevron-right" color={Colors.iconColor} />}
              titleStyle={localStyles.listItemTitle}
              subtitleStyle={localStyles.listItemSubtitle}
              onPress={() => {
                console.log(`view all group members`);
                navigation.navigate(`MemberInfo`, {
                  groupId: group?.id,
                });
              }}
            />
            <Divider />
            <ListItem
              title="Banned members"
              subtitle="View list"
              rightIcon={<MeshIcon name="chevron-right" color={Colors.iconColor} />}
              titleStyle={localStyles.listItemTitle}
              subtitleStyle={localStyles.listItemSubtitle}
              onPress={goToBanList}
            />
            <Divider />
          </>
        )}
      </View>
    </View>
  );
};

const localStyles = {
  groupAvatar: {
    width: 100,
    height: 64,
    borderRadius: 7,
    borderWidth: 1,
    borderColor: Colors.imageBorderColor,
    marginVertical: 16,
    marginHorizontal: 11,
  },
  listItemTitle: {
    ...Typography.text(`small`, `gray`, `bold`),
  },
  listItemSubtitle: {
    ...Typography.text(`base`, `black`, `bold`),
  },
};
