import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Platform, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { ReactNativeFile } from 'apollo-upload-client';
import { StackNavigationProp } from '@react-navigation/stack';
import ImagePicker from 'react-native-image-crop-picker';
import { v4 as uuidv4 } from 'uuid';
import { setProfileImage } from '../redux/createProfileSlice';
import { AuthMainText, AuthSubText, Avatar, MeshIcon, HeaderSubmitButton, VisibleTag } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import useMediaPermissons from '../hooks/useMediaPermissions';
import { PROFILEPHOTOSCREEN, PROFILEPHOTOSCREEN_CONTINUE, PROFILEPHOTOSCREEN_PHOTO, PROFILEPHOTOSCREEN_SKIP } from './testLabels';
import { client, refetchQueriesFor, UPDATE_PERSONA } from '../graphql';
import { AuthNavigationContainer } from '../common-types/navigation-types';
import { RootState } from '../redux/store';
import { AppContext } from '../../AppContext';

type PhotoNavigation = StackNavigationProp<AuthNavigationContainer, `Persona`>;
type SelectedAvatar = ReactNativeFile | string | undefined;

export const ProfilePhoto: React.FC = () => {
  const { user, setUser } = useContext(AppContext);
  const navigation = useNavigation<PhotoNavigation>();

  const { profileImage } = useSelector((state: RootState) => state.createProfile);
  const [avatarFile, setAvatarFile] = useState<SelectedAvatar>(profileImage);
  const dispatch = useDispatch();
  const { checkPermission } = useMediaPermissons();

  const onSubmit = useCallback(async () => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PERSONA,
        refetchQueries: refetchQueriesFor(`Persona`),
        variables: { input: { avatar_image_file: avatarFile } },
      });

      const result: any = data?.updatePersona;

      dispatch(setProfileImage(avatarFile));

      const newUser: any = {
        ...user,
        avatar_url: result?.avatar_url,
      };
      setUser(newUser);
      // navigation.navigate(`Persona`, { screen: `FindFriendsScreen` });
      navigation.navigate(`Persona`, { screen: `JoinCommunity` });
    } catch (error: any) {
      console.error(`error thrown in updateLocalUser()`, error.message);
    }

    // navigation.push(`ProfileName`);
  }, [avatarFile, dispatch, navigation, setUser, user]);

  const pickImage = async () => {
    const isValid = checkPermission();
    if (!isValid) return;

    const image = await ImagePicker.openPicker({
      cropperCircleOverlay: true,
      cropping: true,
      compressImageQuality: 0.95,
      sortOrder: `asc`,
      smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
    });

    const heic = image.filename && image.filename.toLowerCase().endsWith(`.heic`);
    if (image.filename && heic) image.filename = `${image.filename.split(`.`)[0]}.JPG`;

    const loadedFile = new ReactNativeFile({
      uri: image?.path,
      type: Platform.select({ android: `image/jpeg`, default: image.mime }),
      name: (image.filename && image.filename.split(`.`)[0]) || uuidv4(),
    });
    setAvatarFile(loadedFile);
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => undefined,
      headerRight: () => (
        <HeaderSubmitButton title="Continue" testID={PROFILEPHOTOSCREEN_CONTINUE} onPress={onSubmit} disabled={!avatarFile} />
      ),
    });
  }, [avatarFile, navigation, onSubmit]);

  const avatar = typeof avatarFile === `string` ? avatarFile : avatarFile?.uri;
  return (
    <SafeAreaView testID={PROFILEPHOTOSCREEN} style={styles.safeArea}>
      <View style={styles.container}>
        <AuthMainText text="Your account has been created!" />
        <AuthSubText text="Now let's build your profile. Want to add a profile pic?" style={styles.description} />

        <View style={styles.photo}>
          <TouchableOpacity
            testID={PROFILEPHOTOSCREEN_PHOTO}
            activeOpacity={0.8}
            style={{ marginTop: 24, marginBottom: 32 }}
            onPress={pickImage}
          >
            <Avatar localImage={avatar} size={97} border disabled />
            <View style={styles.camera}>
              <MeshIcon name="camera" color={Colors.white} focused size={24} />
            </View>
          </TouchableOpacity>
        </View>

        <VisibleTag infoText="Your avatar is visible on your profile and will show up by any posts or comments you make." />

        {!avatarFile ? (
          <View style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
            <TouchableOpacity
              testID={PROFILEPHOTOSCREEN_SKIP}
              activeOpacity={0.8}
              // onPress={() => navigation.navigate(`Persona`, { screen: `FindFriendsScreen` })}
              onPress={() => navigation.navigate(`Persona`, { screen: `JoinCommunity` })}
              style={{ marginTop: 40, display: `flex`, flexDirection: `row`, alignItems: `center` }}
            >
              <AuthSubText
                text="Skip for now"
                style={{
                  color: Colors.themeColor,
                  fontWeight: Typography.mediumBoldFontWeight,
                }}
              />
              <MeshIcon name="arrow-right" size={18} color={Colors.themeColor} style={{ marginLeft: 5 }} />
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    margin: 16,
  },
  description: {
    color: Colors.black,
    marginTop: 10,
  },
  photo: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginTop: 40,
  },
  camera: {
    padding: 8,
    height: 40,
    width: 40,
    borderRadius: 99,
    position: `absolute`,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.brandPurple,
    alignItems: `center`,
    justifyContent: `center`,
    alignContent: `center`,
  },
});
