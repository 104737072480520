import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ActivityIndicator, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Colors } from '../common-styles';
import { GroupCoreFields, GroupMember, TopicItem, TopicStatus } from '../common-types/types';
import { MeshIcon } from '../common-ui';
import { GET_TOPICS_LIST, client, CREATE_TOPICS } from '../graphql';
import { Events, PendoTrackEvent } from '../pendo/events';
import { useTopic } from '../community/v2/hooks/useTopic';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { setSelectedTopics } from '../redux/postSlice';

type TopicEditorProps = {
  groupId: string;
  isAdmin: boolean;
  group: GroupCoreFields;
  role_name: string;
  group_member: GroupMember;
  onHideSecondPostModal: () => void;
};

// eslint-disable-next-line complexity
const TopicEditor: React.FC<TopicEditorProps> = ({ groupId, isAdmin, group, role_name, group_member, onHideSecondPostModal }) => {
  const navigation = useNavigation<any>();
  const dispatch = useDispatch<any>();

  const [searchKey, setSearchKey] = useState(``);
  // const [topics, setTopics] = useState<TopicItem[]>(originalTopics || []);

  const { selectedTopics: topics } = useSelector((state: RootState) => state.post);
  const [isExisting, setIsExisting] = useState(``);
  const [isSuggestCreated, setSuggestCreated] = useState(false);

  const isDisabled = (topic: TopicItem) => {
    return topics.findIndex((tp) => tp.id === topic.id) > -1;
  };

  const [fetchTopics, { data, loading }] = useLazyQuery(GET_TOPICS_LIST, {
    fetchPolicy: `no-cache`,
  });

  const { topics: popularData } = useTopic({ groupId, popularTopicLimits: 15 });

  const topicLists = useMemo(() => {
    return popularData?.filter((p: TopicItem) => p.status === TopicStatus.ACTIVE) || [];
  }, [popularData]);

  const startWithSpace = useMemo(() => {
    if (/^\s/.test(searchKey)) return true;
    return false;
  }, [searchKey]);

  useEffect(() => {
    setIsExisting(``);
    if (searchKey.length > 30) return;
    const topicListData = data?.getGroupTopicsList_V2;
    const sugggestedList = topicListData?.filter((tp: TopicItem) => tp.status === TopicStatus.SUGGESTED) || [];
    if (topicListData?.length > 0 && sugggestedList?.length > 0) {
      if (!/\S/.test(searchKey)) return;
      if (
        sugggestedList.some((tp: TopicItem) => {
          return tp.topic.toLocaleLowerCase() === searchKey.trim().toLocaleLowerCase();
        })
      ) {
        setIsExisting(`Topic name already exists in the Suggested Topics`);
      }
    }
    if (searchKey.length) setSuggestCreated(false);
  }, [data, searchKey]);

  useEffect(() => {
    if (!searchKey.length) return;
    fetchTopics({
      variables: {
        filter: {
          keyword: searchKey,
          group_id: groupId,
          limit: 50,
          offset: 0,
          sortBy: `TOPIC`,
          sortOrder: `ASC`,
        },
      },
    });
  }, [fetchTopics, groupId, searchKey]);

  const handleCreateNewTopic = useCallback(async () => {
    if (!groupId || !searchKey) return;
    if (isExisting.length > 0) {
      setSuggestCreated(true);
      return;
    }
    try {
      const { data } = await client.mutate({
        mutation: CREATE_TOPICS,
        variables: {
          groupID: groupId,
          topic: searchKey,
        },
      });
      if (data?.addGroupTopics_V2) {
        PendoTrackEvent(Events.CREATE_TOPIC, {
          source: `PostTopics`,
          community_name: group?.name,
          community_role: role_name,
          topic_name: searchKey,
        });
        if (isAdmin) dispatch(setSelectedTopics([...topics, data?.addGroupTopics_V2]));
        else setSuggestCreated(true);
        setSearchKey(``);
      }
    } catch (error) {
      console.error(typeof error === `string` ? error : `Error occured while creating new topic`);
    }
  }, [searchKey, groupId, group, role_name, topics, isAdmin, isExisting, dispatch, setSelectedTopics]);

  const handleTopics = useCallback(() => {
    navigation.navigate(`CommunityTopics`, { group, group_member: group_member, status: `creating` });
    onHideSecondPostModal();
  }, [navigation, group, group_member, onHideSecondPostModal]);

  return (
    <View>
      <View style={styles.borderTopContent}>
        <Text style={styles.optionText}>Assign up to three topics to post</Text>
        <View style={styles.container}>
          {topics.length > 0 &&
            topics.map((tp) => (
              <TouchableOpacity
                key={tp?.id}
                style={styles.topicPill}
                onPress={() => {
                  dispatch(setSelectedTopics(topics.filter((t) => t?.topic !== tp?.topic)));
                }}
              >
                <Text style={{ fontSize: 12, color: Colors.deepPurple, marginRight: 3 }}>{tp?.topic}</Text>
                <MeshIcon name="close" size={12} />
              </TouchableOpacity>
            ))}
          <TextInput
            style={{ flex: 1, fontSize: 12, marginBottom: 5 }}
            placeholder={topics.length > 0 ? `` : `Add up to 3 topics`}
            placeholderTextColor={Colors.textPlaceholder}
            value={searchKey}
            onChangeText={setSearchKey}
          />
        </View>
        {isExisting.length > 0 && isSuggestCreated && (
          <View style={{ backgroundColor: `${Colors.chatPreviewDeleteButton}40`, borderRadius: 6, padding: 10, marginTop: 3 }}>
            <Text style={{ fontSize: 12, color: Colors.alertColor }}>{isExisting}</Text>
          </View>
        )}
        {startWithSpace && (
          <View style={{ backgroundColor: `${Colors.chatPreviewDeleteButton}40`, borderRadius: 6, padding: 10, marginTop: 3 }}>
            <Text style={{ fontSize: 12, color: Colors.alertColor }}>Topic name can't start with space</Text>
          </View>
        )}
        {isExisting.length == 0 && isSuggestCreated && (
          <View style={{ backgroundColor: `${Colors.greenBackground}50`, borderRadius: 6, padding: 10, marginTop: 3 }}>
            <Text style={{ fontSize: 12, color: Colors.brandGreen }}>New topic is suggested</Text>
          </View>
        )}
        {!loading &&
          !startWithSpace &&
          searchKey.length > 0 &&
          ((topics.length < 3 && isAdmin) || !isAdmin) &&
          (!data?.getGroupTopicsList_V2?.length ||
            (data?.getGroupTopicsList_V2?.length > 0 &&
              data?.getGroupTopicsList_V2?.some((t: TopicItem) => t.topic !== searchKey))) && (
            <TouchableOpacity style={styles.addTopic} onPress={handleCreateNewTopic}>
              <ActionStringItem searchKey={searchKey} isAdmin={isAdmin} />
              <MeshIcon name="circle-plus" color={Colors.iconColor} size={24} />
            </TouchableOpacity>
          )}
        {data?.getGroupTopicsList_V2?.length > 0 &&
          searchKey.length > 0 &&
          data?.getGroupTopicsList_V2.filter((tp: TopicItem) => tp.status === TopicStatus.ACTIVE).length > 0 && (
            <ScrollView style={[styles.candidatesContainer, { maxHeight: data?.getGroupTopicsList_V2?.length > 1 ? 150 : 50 }]}>
              {data?.getGroupTopicsList_V2
                .filter((tp: TopicItem) => tp.status === TopicStatus.ACTIVE)
                .map((t: TopicItem) => (
                  <TouchableOpacity
                    style={[
                      styles.candidateItem,
                      {
                        backgroundColor: isDisabled(t) ? Colors.disabledIconColor : Colors.badgePlusBackground,
                      },
                    ]}
                    key={t.id}
                    onPress={() => {
                      if (topics.length < 3) {
                        setSearchKey(``);
                        dispatch(setSelectedTopics([...topics, t]));
                      }
                    }}
                    disabled={isDisabled(t)}
                  >
                    <HighlightTextItem text={t.topic} searchKey={searchKey} disabled={isDisabled(t)} />
                  </TouchableOpacity>
                ))}
            </ScrollView>
          )}
        {loading && (
          <View style={{ padding: 10 }}>
            <ActivityIndicator />
          </View>
        )}
      </View>
      {topicLists?.length > 0 && (
        <View style={styles.borderTopContent}>
          <View style={{ flexDirection: `row`, alignItems: `center`, justifyContent: `space-between` }}>
            <Text style={styles.optionText}>Popular topics</Text>
            {topicLists?.length > 20 && (
              <TouchableOpacity onPress={handleTopics}>
                <Text style={{ fontSize: 11, color: Colors.deepPurple, fontWeight: `600` }}>See all</Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={{ flexDirection: `row`, flexWrap: `wrap`, paddingTop: 10 }}>
            {topicLists
              ?.filter((p: TopicItem) => (isAdmin ? true : p.topic !== `Announcements`))
              .map((pd: TopicItem) => {
                const disabled = isDisabled(pd);
                return (
                  <TouchableOpacity
                    key={pd.id}
                    style={{
                      backgroundColor: disabled ? Colors.disabledIconColor : Colors.badgePlusBackground,
                      marginRight: 5,
                      paddingVertical: 3,
                      paddingHorizontal: 6,
                      borderRadius: 6,
                      marginBottom: 5,
                    }}
                    disabled={disabled}
                    onPress={() => {
                      if (topics.length < 3) dispatch(setSelectedTopics([...topics, pd]));
                    }}
                  >
                    <Text style={{ fontSize: 12, color: disabled ? Colors.textDisabled : Colors.deepPurple }}>{pd.topic}</Text>
                  </TouchableOpacity>
                );
              })}
          </View>
        </View>
      )}
    </View>
  );
};

export default TopicEditor;

const styles = StyleSheet.create({
  container: {
    flexDirection: `row`,
    flexWrap: `wrap`,
    borderWidth: 1,
    borderColor: Colors.dividerColor,
    padding: 8,
    paddingTop: 12,
    borderRadius: 8,
    marginTop: 10,
  },
  topicPill: {
    backgroundColor: Colors.badgePlusBackground,
    marginRight: 5,
    marginBottom: 5,
    paddingVertical: 3,
    paddingHorizontal: 6,
    borderRadius: 6,
    flexDirection: `row`,
    alignItems: `center`,
  },
  borderTopContent: {
    borderTopWidth: 1,
    borderTopColor: Colors.dividerColor,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  optionText: {
    fontSize: 15,
    fontWeight: `600`,
  },
  candidatesContainer: {
    paddingVertical: 5,
  },
  candidateItem: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: Colors.badgePlusBackground,
    marginBottom: 3,
  },
  addTopic: {
    padding: 10,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
  },
  grayText: {
    color: Colors.textGray,
  },
});

const ActionStringItem: React.FC<{ searchKey: string; isAdmin: boolean }> = ({ searchKey, isAdmin }) => (
  <Text style={{ fontSize: 12 }}>
    <Text style={styles.grayText}>{isAdmin ? `Add` : `Suggest`} </Text>
    <Text style={{ fontWeight: `bold` }}>{searchKey} </Text>
    <Text style={styles.grayText}>{isAdmin ? `to Topics` : `as a topic`}</Text>
  </Text>
);

const HighlightTextItem: React.FC<{ searchKey: string; text: string; disabled: boolean }> = ({ searchKey, text, disabled }) => {
  const parts = text.length > 0 && text.split(new RegExp(`(${searchKey})`, `gi`));

  return (
    <Text style={{ fontSize: 12, color: disabled ? Colors.textDisabled : Colors.black }}>
      {parts &&
        parts.map((part, idx) => {
          if (part.toLowerCase() === searchKey.toLowerCase()) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={idx} style={{ fontWeight: `bold` }}>
                {part}
              </Text>
            );
          }
          // eslint-disable-next-line react/no-array-index-key
          return <Text key={idx}>{part}</Text>;
        })}
    </Text>
  );
};
