import Svg, { Circle, Defs, G, Mask, Path, Use } from 'react-native-svg';
import React from 'react';

export const UserDefaultAvatar = (props) => {
  return (
    <Svg width="96px" height="96px" viewBox="0 0 96 96" {...props}>
      <Defs>
        <Circle id="a" cx={48} cy={48} r={48} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <Use fill="#E8E9FF" xlinkHref="#a" />
        <Path
          d="M33.103 33.766c-3.571 0-6.956 2.247-10.185 6.62a2.09 2.09 0 00.407 2.897c.9.685 2.177.5 2.852-.414 2.474-3.35 4.804-4.966 6.926-4.966 2.123 0 4.453 1.616 6.927 4.966a2.017 2.017 0 002.852.414 2.09 2.09 0 00.407-2.897c-3.23-4.373-6.614-6.62-10.186-6.62zM62.234 33.766c-3.571 0-6.956 2.247-10.185 6.62a2.09 2.09 0 00.407 2.897c.9.685 2.177.5 2.852-.414 2.474-3.35 4.804-4.966 6.926-4.966 2.123 0 4.453 1.616 6.927 4.966a2.017 2.017 0 002.852.414 2.09 2.09 0 00.407-2.897c-3.23-4.373-6.614-6.62-10.186-6.62zM56.435 53.628H38.24c-2.471 0-4.474 2.018-4.474 4.508 0 .754.187 1.496.545 2.158 3.77 6.968 8.097 10.547 13.027 10.547s9.256-3.58 13.026-10.547c1.183-2.187.382-4.925-1.788-6.117a4.448 4.448 0 00-2.141-.55z"
          fill="#333895"
          fillRule="nonzero"
          mask="url(#b)"
        />
      </G>
    </Svg>
  );
};
