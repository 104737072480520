import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, Platform, Image, TouchableOpacity, StyleSheet } from 'react-native';
import FastImage from 'react-native-fast-image';
import { useFocusEffect } from '@react-navigation/native';
import { VideoPlayer } from './VideoPlayer';
import { getReadablePath } from '../../common-util/FilePicker';
import { Divider, MeshIcon } from '../../common-ui';
import { Colors } from '../../common-styles';
import { AddMediaButton } from './AddMediaButton';

type PostContentVideoType = {
  width: number;
  video_urls: any[];
  onScreen: boolean;
  onCloseVideo?: () => void;
  onLayout?: () => void;
  videoWidth?: number;
  videoHeight?: number;
  from?: string;
  addVideo: () => void;
};

const areEqualVideo = (prev: any, next: any) => {
  const areEqualUrls = prev?.video_urls?.length === next?.video_urls?.length;
  const areEqualOnScreenState = prev?.onScreen === next?.onScreen;
  return areEqualUrls && areEqualOnScreenState;
};

const PostContentVideo: React.FC<PostContentVideoType> = React.memo(
  ({ width, video_urls, onCloseVideo, onScreen, onLayout, videoWidth, videoHeight, from, addVideo }) => {
    const [showVideo, setShowVideo] = useState(false);
    const [imageRatio, setImageRatio] = useState(1);
    const [videoDim, setVideoDim] = useState({
      width: 0,
      height: 0,
    });

    const setVideoDisplayed = () => setShowVideo(true);

    const thumbnailUrl = useMemo(() => {
      if (Platform.OS === `android` && video_urls[1]?.type?.includes(`video`)) {
        return getReadablePath(video_urls[1]);
      }
      return video_urls[1]?.uri || video_urls[1]; // localFile || streamed file
    }, [video_urls]);

    useEffect(() => {
      if (from === `CreatePost`) {
        if (videoHeight && videoWidth && videoHeight > videoWidth) setVideoDim({ width: 200, height: 400 });
        else setVideoDim({ width, height: 300 });
      } else {
        setVideoDim({ width, height: width * imageRatio });
      }
    }, [videoHeight, videoWidth, width, from, imageRatio]);

    useEffect(() => {
      if (thumbnailUrl)
        Image.getSize(thumbnailUrl.url || thumbnailUrl.uri || thumbnailUrl, (width, height) => {
          setImageRatio(Math.min(height / width, 5 / 4));
        });
    }, [thumbnailUrl]);

    useFocusEffect(
      useCallback(() => {
        return () => {
          if (showVideo) setShowVideo(false);
        };
      }, [showVideo]),
    );

    if (showVideo) {
      return (
        <VideoPlayer
          width={videoDim?.width}
          height={videoDim?.height}
          video_url={video_urls[0]}
          onCloseVideo={onCloseVideo}
          onScreen={onScreen && showVideo}
          onLayout={onLayout}
        />
      );
    }

    return (
      <>
        <View style={styles.container}>
          {video_urls && video_urls?.length ? (
            <View style={styles.contentContainer}>
              <FastImage
                style={{ ...videoDim, backgroundColor: Colors.black, borderRadius: from === `CreatePost` ? 8 : 0 }}
                source={{ uri: thumbnailUrl }}
                resizeMode="cover"
              />

              {from === `CreatePost` ? (
                <TouchableOpacity
                  activeOpacity={0.8}
                  onPress={onCloseVideo}
                  style={styles.closeIconContainer}
                  hitSlop={{ top: 15, bottom: 15, left: 15, right: 15 }}
                >
                  <MeshIcon name="circle-x" size={20} color={Colors.brandPurple} />
                </TouchableOpacity>
              ) : undefined}

              <View style={{ position: `absolute` }}>
                <MeshIcon
                  name="play-circle"
                  size={62}
                  color={Colors.white}
                  onPress={setVideoDisplayed}
                  style={{ backgroundColor: Colors.black, borderRadius: 31, overflow: `hidden` }}
                />
              </View>
            </View>
          ) : (
            <View style={{ margin: 16, flex: 1, alignSelf: `flex-start` }}>
              <AddMediaButton onPress={addVideo} />
            </View>
          )}
        </View>
        <Divider />
      </>
    );
  },
  areEqualVideo,
);

const styles = StyleSheet.create({
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  closeIconContainer: {
    position: `absolute`,
    top: 10,
    right: 10,
    backgroundColor: Colors.white,
    borderRadius: 100,
  },
  contentContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
  },
});
export default PostContentVideo;
