import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { AVPlaybackStatus, Video, VideoFullscreenUpdateEvent, VideoReadyForDisplayEvent } from 'expo-av';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getReadablePath } from '../../common-util/FilePicker';
import { CloseBoxOverlay, LoadingIndicator } from '../../common-ui';

type VideoPlayerType = {
  width: number;
  height: number;
  video_url: any;
  onScreen: boolean;
  onCloseVideo?: () => void;
  onLayout?: () => void;
  onFinish?: () => void;
  displayNativeControls?: boolean;
  muted?: boolean;
};

export const VideoPlayer: React.FC<VideoPlayerType> = ({
  width,
  height,
  video_url,
  onScreen,
  onCloseVideo,
  onLayout,
  onFinish,
  displayNativeControls = true,
  muted = false,
}) => {
  const videoPlayer = useRef<Video>(null);
  const [resumeMilles, setResumeMillis] = useState(0);
  const top = Platform.OS === `ios` ? 32 : 0;
  const [loading, setLoading] = useState(false);

  const { mutedByScroll } = useSelector((state: RootState) => state.explore);

  const videoUrl: string = useMemo(() => {
    if (Platform.OS === `android` && video_url.type?.includes(`video`)) {
      return getReadablePath(video_url);
    }
    return video_url?.uri || video_url;
  }, [video_url]);

  useEffect(() => {
    const manageVideoScreenAsync = async () => {
      if (!videoPlayer.current) return;
      const status: AVPlaybackStatus = await videoPlayer?.current?.getStatusAsync();
      if (status.isLoaded) {
        const { positionMillis = 0 } = status;
        // safe time for next time it loads
        await videoPlayer.current?.pauseAsync();
        await videoPlayer.current?.unloadAsync();
        if (videoPlayer.current) {
          setResumeMillis(positionMillis);
        }
      } else if (onScreen && videoUrl) {
        const loaded = await videoPlayer.current
          ?.loadAsync(
            { uri: videoUrl },
            {
              isMuted: muted,
              volume: muted ? 0 : 1,
              positionMillis: resumeMilles,
            },
            false,
          )
          .catch((reason) => console.warn(reason));
        if (loaded) videoPlayer.current?.playAsync();
      }
    };
    manageVideoScreenAsync();
  }, [onScreen, videoUrl, resumeMilles, muted]);

  // This is only for the Hero Area Videos - when they are not in the scroll view port we want to pause them (or eventually have another muting condition)
  useEffect(() => {
    if (mutedByScroll) videoPlayer.current?.pauseAsync();
    else videoPlayer.current?.playAsync();
  }, [mutedByScroll]);

  const handlePlaybackStatusUpdate = useCallback(
    (status: AVPlaybackStatus) => {
      const { isLoaded } = status;
      if (isLoaded && status.didJustFinish) {
        if (onFinish) onFinish();
        setResumeMillis(0);
        videoPlayer.current?.setPositionAsync(0);
        videoPlayer.current?.pauseAsync();
      }
    },
    [onFinish],
  );

  const onReady = async ({ status }: VideoReadyForDisplayEvent) => {
    setLoading(false);
    if (status?.isLoaded) {
      await videoPlayer.current?.setStatusAsync({
        positionMillis: Math.max(resumeMilles, status.positionMillis),
        isMuted: muted,
        volume: muted ? 0 : 1,
      });
    }
  };

  const onToggleFullScreen = ({ fullscreenUpdate, status }: VideoFullscreenUpdateEvent) => {
    if (status?.isLoaded) {
      const shouldResume = !status?.didJustFinish && status?.isPlaying;
      if (shouldResume && fullscreenUpdate % 2 === 1) {
        //resume after in/out of fullscreen
        setTimeout(() => videoPlayer.current?.playAsync(), 1000);
      }
    }
  };

  return (
    <View style={{ width, height, position: `relative` }} onLayout={onLayout}>
      <CloseBoxOverlay disabled={!onCloseVideo} onPress={onCloseVideo} size={18} top={top}>
        <Video
          isMuted={muted}
          volume={muted ? 0 : 1.0}
          ref={videoPlayer}
          useNativeControls={displayNativeControls}
          resizeMode="cover"
          onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
          style={{ width, height, backgroundColor: `black` }}
          onError={(error) => console.log(`[video-load] Error:`, { videoUrl }, error)}
          onLoadStart={() => setLoading(true)}
          onReadyForDisplay={onReady}
          onFullscreenUpdate={onToggleFullScreen}
        />
      </CloseBoxOverlay>
      {loading ? (
        <View style={styles.loadingContainer}>
          <LoadingIndicator size={32} />
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  },
});
