import React, { useCallback, useMemo } from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { FlatList, Text, View, StyleSheet, Image, Platform, ListRenderItem } from 'react-native';
import Emoji from 'react-native-emoji-v2';

import { useSettings } from './hooks/useSettings';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';
import { NoThemeButton, Button, ListItem, MeshIcon, LoadingIndicator } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { Setting } from '../common-types/types';

type EditReactionRoute = RouteProp<HomeNavigatorScreens, `EditReactions`>;

type ReactionSetting = {
  name: string;
  url?: string;
};

const EditReactions: React.FC = () => {
  const route = useRoute<EditReactionRoute>();
  const navigation = useNavigation<HomeNavigator>();
  const { groupId } = route?.params;
  const { settings } = useSettings(`group`, { user_id: undefined, group_id: groupId });

  const setting = useMemo(() => {
    if (settings) {
      return Object.values(settings).find((item: Setting) => item.type === `reactions`);
    }
    return undefined;
  }, [settings]);

  const handleUploadReaction = () => {
    navigation.push(`UploadReaction`, { groupId });
  };

  const handleClickEdit = useCallback(
    (item: ReactionSetting) => {
      if (setting) {
        navigation.push(`ReactionLibrary`, { selected: item, setting, groupId });
      }
    },
    [groupId, navigation, setting],
  );

  const renderItem: ListRenderItem<ReactionSetting> = ({ item }) => {
    return (
      <View style={styles.reactionItem}>
        <View style={styles.reactionImgWrapper}>
          {item.url ? (
            <View style={styles.emojiWrapper}>
              <Image source={{ uri: item.url }} style={{ width: 32, height: 32 }} />
            </View>
          ) : (
            <View style={styles.emojiWrapper}>
              <View style={styles.selectWrapper}>
                <Emoji name={item.name} style={{ fontSize: Platform.OS === `android` ? 28 : 32 }} />
              </View>
            </View>
          )}
        </View>

        <Text style={styles.reactionName}>{item.name}</Text>

        <Button
          title="Edit"
          textStyle={{ fontSize: Typography.baseFontSize }}
          containerStyle={{ paddingVertical: 8, paddingHorizontal: 30 }}
          onPress={() => handleClickEdit(item)}
        />
      </View>
    );
  };

  if (!setting) {
    return (
      <View style={{ flex: 1, alignItems: `center`, justifyContent: `center` }}>
        <LoadingIndicator size={42} />
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.mainSection}>
        <View style={{ marginBottom: 15 }}>
          <Text style={styles.sectionTitle}>Add Custom Reactions</Text>
          <Text style={styles.sectionDescription}>Upload custom Reactions for your node to use.</Text>
        </View>

        <View style={{ marginBottom: 20 }}>
          <Text style={styles.sectionTitle}>Upload Requirements</Text>
          <Text style={styles.sectionDescription}>File Type: JPEG, GIF or PNG</Text>
          <Text style={styles.sectionDescription}>Recommended size: 256KB (we'll compress it for you!)</Text>
          <Text style={styles.sectionDescription}>Recommended dimentions: 108 x 108</Text>
        </View>

        <NoThemeButton
          rounded
          title="Upload Reaction"
          titleStyle={{ fontSize: 16, paddingVertical: 2 }}
          onPress={handleUploadReaction}
        />
      </View>

      <View style={styles.listSection}>
        <FlatList<ReactionSetting>
          style={{ backgroundColor: Colors.white }}
          data={JSON.parse(setting?.value as string) || []}
          keyExtractor={(item: ReactionSetting) => item.name}
          renderItem={renderItem}
          ListHeaderComponent={ListHeaderComponent}
        />
      </View>
    </View>
  );
};

export const ReactionSettingItem: React.FC<any> = ({ group, listItemProps }) => {
  const { title, titleStyle, subtitleStyle } = listItemProps;
  const navigation = useNavigation<any>();

  const handlePress = () => {
    navigation.push(`EditReactions`, { groupId: group.id });
  };

  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle="Press to view and edit..."
      subtitleStyle={subtitleStyle}
      rightElement={<MeshIcon name="chevron-right" size={20} />}
      onPress={handlePress}
    />
  );
};

const ListHeaderComponent: React.FC = () => (
  <View style={styles.reactionHeader}>
    <View style={styles.imgHeader}>
      <Text style={{ fontWeight: `600`, fontSize: 16 }}>Image</Text>
    </View>
    <View style={styles.nameHeader}>
      <Text style={{ fontWeight: `600`, fontSize: 16 }}>Name</Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  mainSection: {
    padding: 20,
    borderBottomColor: Colors.lightGray,
    borderBottomWidth: 1,
  },
  sectionTitle: {
    fontSize: 16,
    color: Colors.textBlack,
    fontWeight: `600`,
    marginBottom: 5,
  },
  sectionDescription: {
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
  },
  listSection: {
    padding: 20,
    flex: 1,
  },
  emojiWrapper: {
    width: 40,
    height: 40,
    overflow: `hidden`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  selectWrapper: {
    overflow: `hidden`,
    width: `100%`,
    height: `100%`,
    display: `flex`,
    alignItems: `center`,
  },
  reactionItem: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-end`,
    marginBottom: 4,
  },
  reactionImgWrapper: {
    marginRight: 20,
  },
  reactionName: {
    fontSize: Typography.baseFontSize,
    flex: 1,
  },
  reactionHeader: {
    display: `flex`,
    flexDirection: `row`,
    marginBottom: 10,
  },
  imgHeader: {
    width: 55,
  },
  nameHeader: {
    flex: 1,
  },
});

export default EditReactions;
