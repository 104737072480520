import React, { memo } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { useQuery } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { useMessageContext } from 'stream-chat-react-native';
import { GET_INVITATION } from '../../graphql';
import { getMeshInviteToken } from '../../post/hooks/useLinkPreview';
import { setActiveCommunity, setPreviewCommunity } from '../../redux/feedSlice';
// @ts-ignore
import MeshIcon from '../../../assets/images/icon.png';
import { Colors } from '../../common-styles';
import { JoinedGroup } from '../../common-types/types';
import { ChatNavigator, MainTabsNavigator } from '../../common-types/navigation-types';

const truncate = (input: string, length: number, end = `...`) => {
  if (input.length > length) {
    return `${input.substring(0, length - end.length)}${end}`;
  }
  return input;
};

export const UrlPreview: React.FC<{ inviteUrl: string }> = memo(({ inviteUrl }) => {
  const { isMyMessage } = useMessageContext();
  const dispatch = useDispatch();
  const navigation = useNavigation<ChatNavigator>();
  const invite_token = getMeshInviteToken(inviteUrl);

  const { data, error } = useQuery(GET_INVITATION, {
    variables: { invite_token },
    fetchPolicy: `cache-and-network`,
    skip: !invite_token,
  });

  const handleNavigate = (groupData: JoinedGroup) => {
    const { group_member } = groupData;

    if (group_member?.role_id) {
      dispatch(setActiveCommunity({ user_id: group_member.persona_id, group: groupData.group }));
      const TabNavigator = navigation.getParent<MainTabsNavigator>();
      TabNavigator.jumpTo(`HomeNavigator`, { screen: `HomeScreen` });
    } else {
      dispatch(setPreviewCommunity(groupData.group));
      const TabNavigator = navigation.getParent<MainTabsNavigator>();
      TabNavigator.jumpTo(`ExploreNavigator`, {
        screen: `CommunityPreview`,
        params: { invite_token },
      });
    }
  };
  if (data) {
    const { avatar_url, name } = data.getInvitation.group;
    return (
      <TouchableOpacity
        onPress={() => handleNavigate(data.getInvitation)}
        style={[
          styles.container,
          { backgroundColor: isMyMessage ? Colors.chatMessageBackground : Colors.otherchatMessageBackground },
        ]}
      >
        <View style={styles.thumbnailContainer}>
          {avatar_url ? (
            <Image
              resizeMode="cover"
              source={{
                uri: avatar_url,
              }}
              style={styles.thumbnail}
            />
          ) : (
            <Image resizeMode="cover" source={MeshIcon} style={styles.thumbnail} />
          )}
        </View>
        <View style={styles.detailsContainer}>
          <Text style={styles.description}>{truncate(name, 100)}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  if (error) {
    return (
      <View>
        <Text style={{ paddingHorizontal: 15, paddingVertical: 5, color: Colors.alertColor }}>Expired invitation</Text>
      </View>
    );
  }
  return <View />;
});

const styles = StyleSheet.create({
  container: {
    flexDirection: `column`,
    marginBottom: 8,
    borderRadius: 0,
    paddingHorizontal: 10,
  },
  description: {
    padding: 2,
    fontSize: 12.5,
  },
  detailsContainer: {
    marginTop: 10,
    flexDirection: `column`,
    width: 200,
  },
  thumbnail: {
    height: 150,
    width: `100%`,
  },
  thumbnailContainer: {},
  title: {
    color: `#1E75BE`,
    fontWeight: `bold`,
    padding: 2,
  },
  titleUrl: {
    fontWeight: `bold`,
    padding: 2,
  },
});
