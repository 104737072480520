import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const AmazonImage = (props) => {
  return (
    <Svg width={66} height={46} viewBox="0 0 66 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0h1300v7847H0z" />
        <Path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill="#FF9201"
        />
        <G fill="#FFF">
          <Path
            d="M40.437 25.984c-.581-.802-1.205-1.454-1.205-2.947v-4.955c0-2.1.15-4.027-1.401-5.472-1.222-1.171-3.252-1.585-4.804-1.585-3.036 0-6.423 1.13-7.133 4.87-.075.398.214.606.477.664l3.09.334c.29-.015.5-.298.555-.584.266-1.29 1.348-1.909 2.565-1.909.656 0 1.401.24 1.79.826.447.654.387 1.551.387 2.306v.415c-1.85.206-4.268.343-6 1.1-1.997.862-3.4 2.617-3.4 5.197 0 3.306 2.088 4.958 4.775 4.958 2.267 0 3.508-.533 5.258-2.311.58.838.77 1.242 1.828 2.12a.662.662 0 00.754-.076l.008.008c.637-.565 1.794-1.567 2.446-2.112.26-.214.214-.558.01-.847zm-6.276-1.428c-.506.897-1.313 1.445-2.21 1.445-1.221 0-1.938-.93-1.938-2.306 0-2.71 2.438-3.201 4.744-3.201v.686c0 1.24.03 2.272-.596 3.376z"
            fillRule="nonzero"
          />
          <Path d="M44.25 31.39c-3.054 2.271-7.482 3.48-11.296 3.48-5.343 0-10.155-1.991-13.797-5.304-.286-.26-.03-.617.312-.414 3.93 2.304 8.789 3.691 13.807 3.691 3.385 0 7.106-.707 10.53-2.172.517-.22.95.344.444.72z" />
          <Path d="M45.52 29.928c-.39-.503-2.582-.238-3.565-.119-.3.035-.345-.226-.077-.418 1.749-1.237 4.613-.88 4.946-.466.336.42-.087 3.313-1.726 4.694-.252.213-.491.1-.38-.18.369-.93 1.194-3.007.802-3.51z" />
        </G>
      </G>
    </Svg>
  );
};
