import React, { memo } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Linking } from 'react-native';

type NormalUrlPreviewProps = {
  link?: string;
  image?: string;
  title?: string;
};

const truncate = (input: string, length: number, end = `...`) => {
  if (input.length > length) {
    return `${input.substring(0, length - end.length)}${end}`;
  }
  return input;
};

export const NormalUrlPreview: React.FC<NormalUrlPreviewProps> = memo(({ link, image, title }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={() => (link ? Linking.openURL(link) : null)}>
      <View>
        <Image
          resizeMode="cover"
          source={{
            uri: image,
          }}
          style={styles.thumbnail}
        />
      </View>
      <View style={styles.detailsContainer}>
        <Text style={styles.description}>{truncate(title || ``, 100)}</Text>
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: `column`,
    marginBottom: 8,
    paddingTop: 5,
    paddingHorizontal: 10,
  },
  description: {
    padding: 2,
    fontSize: 12.5,
  },
  detailsContainer: {
    marginTop: 10,
    flexDirection: `column`,
    width: 200,
  },
  thumbnail: {
    height: 150,
    width: 200,
    borderRadius: 8,
  },
  title: {
    color: `#1E75BE`,
    fontWeight: `bold`,
    padding: 2,
  },
  titleUrl: {
    fontWeight: `bold`,
    padding: 2,
  },
});
