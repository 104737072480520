import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { StyleSheet, Text, View, TextInput } from 'react-native';
import React, { useCallback, useState } from 'react';
import { AppContext } from '../../AppContext';
import { addPersona, fetchPersona } from '../auth/helpers';
import { Colors, Typography } from '../common-styles';
import { HeaderCancelButton, HeaderSubmitButton, MeshIcon, ListItem } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { client, refetchQueriesFor, UPDATE_SETTING_MUTATION, CHECK_HANDLE } from '../graphql';
import Badwords from '../common-util/badwords';

export const HandleSettingItem = ({ setting, listItemProps, navigation }) => {
  const { title, titleStyle, subtitleStyle, rightElement } = listItemProps;
  const navigateToEditor = () => navigation.navigate(`HandleEditor`, { setting });
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle={setting.value}
      subtitleStyle={subtitleStyle}
      rightElement={rightElement}
      onPress={navigateToEditor}
    />
  );
};

HandleSettingItem.propTypes = {
  setting: commonPropTypes.setting.isRequired,
  listItemProps: PropTypes.object.isRequired,
  navigation: commonPropTypes.navigation().isRequired,
};

export const HandleEditor = ({ navigation, route }) => {
  const { setting } = route.params;
  const prevValue = setting?.value;

  const badwords = new Badwords();

  const [username, setUsername] = useState(prevValue);
  const { user, setUser } = React.useContext(AppContext);
  const [takenUsername, setTakenUsername] = useState(undefined);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries: refetchQueriesFor(`Setting`, `Persona`),
  });

  const isSameName = useCallback(() => username === user?.handle, [user?.handle, username]);
  const validUsername = useCallback(
    () => !isSameName() && takenUsername === false && (username.length >= 2 || username.length <= 20),
    [isSameName, takenUsername, username?.length],
  );

  const saveSetting = useCallback(
    async (value) => {
      const input = {
        value,
        id: setting.id,
        key: setting.key,
        user_id: setting.user_id,
        group_id: setting.group_id,
      };
      await updateSetting({ variables: { input } });
    },
    [setting, updateSetting],
  );

  // Callback to check availability with server
  const checkUsernameAvailability = useCallback(
    async (candidate) => {
      try {
        if (badwords.isProfane(candidate)) return false;

        const { data } = await client.query({
          query: CHECK_HANDLE,
          variables: { handle: candidate },
        });
        const available = data.checkHandleAvailability.is_valid;
        setTakenUsername(!available);
        return available;
      } catch (err) {
        console.error(`err thrown in checkHandleAvailability in HandleEditor.jsx`, err);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const debouncedCheckUsernameAvailability = React.useMemo(
    () => debounce(checkUsernameAvailability, 500, { leading: true, trailing: true, maxWait: 500 }),
    [checkUsernameAvailability],
  );

  // Callback for onSubmit
  const saveNewUsername = useCallback(async () => {
    // check for availability before continuing
    const available = await checkUsernameAvailability(username);
    if (!available || !validUsername()) return;

    await saveSetting(username);

    const prevUser = user;
    setUser({ ...prevUser, handle: username });
    let persona = await fetchPersona(user.id);
    persona = { ...persona, handle: username };

    await addPersona(persona);
    navigation.goBack();
  }, [checkUsernameAvailability, saveSetting, setUser, setting, user, username, validUsername]); // eslint-disable-line react-hooks/exhaustive-deps

  // Effect #1: enable/disable submit button as our state changes
  React.useEffect(() => {
    const isValid = validUsername();
    if (submitDisabled === isValid) {
      setSubmitDisabled(!isValid);
    }
    navigation.setOptions({
      headerTitle: `Edit Username`,
      headerLeft: () => <HeaderCancelButton onPress={() => navigation.goBack()} />,
      headerRight: () => <HeaderSubmitButton onPress={saveNewUsername} disabled={!isValid} title="Save" />,
    });
  }, [
    navigation,
    submitDisabled,
    checkUsernameAvailability,
    saveNewUsername,
    saveSetting,
    setting,
    user,
    username,
    validUsername,
  ]);

  // Callbacks for Input
  const onChangeText = useCallback(
    (newText) => {
      const cleanedText = newText.replace(/[^A-Za-z0-9_]/g, ``);
      setUsername(cleanedText);
      setTakenUsername(undefined);
      debouncedCheckUsernameAvailability(cleanedText);
    },
    [debouncedCheckUsernameAvailability],
  );

  const rightIcon = useCallback(() => {
    if (isSameName()) return null;
    if (takenUsername === undefined) return null;
    const valid = validUsername();
    return (
      <View style={{ alignItems: `center`, padding: 10 }}>
        <MeshIcon name={valid ? `check` : `wrong-input`} size={28} color={valid ? `#2c9a44` : Colors.brickRed} />
      </View>
    );
  }, [isSameName, validUsername, takenUsername]);

  // Subview for describing what's wrong
  const renderMessageBox = (valid, msg) => (
    <View style={valid ? localStyles.validContainer : localStyles.invalidContainer}>
      <Text style={localStyles.textStyle}>{msg}</Text>
    </View>
  );

  const renderFeedback = () => {
    if (isSameName()) return null;
    if (username?.length > 20) return renderMessageBox(false, `Your username must be no more than 20 characters.`);
    if (!username || username?.length < 2) return renderMessageBox(false, `Your username must be at least 2 characters.`);
    if (takenUsername) return renderMessageBox(false, `That username is taken. Please choose another.`);
    if (takenUsername === undefined) return null;
    return renderMessageBox(true, `Looks good!`);
  };

  return (
    <View style={localStyles.container}>
      <View>
        <Text style={Typography.text(`plusone`, `bold`)}>Current username</Text>
        <Text style={{ ...Typography.text(`small`), lineHeight: 20 }}>{user?.handle}</Text>
      </View>
      <View style={{ marginTop: 16, marginBottom: 4 }}>
        <Text style={Typography.text(`small`, `gray`)}>Username</Text>
      </View>
      <View style={localStyles.inputContainer}>
        <TextInput
          style={localStyles.input}
          maxLength={40}
          placeholder="Username"
          autoCorrect={false}
          autoCapitalize="none"
          placeholderTextColor={Colors.textPlaceholder}
          onChangeText={onChangeText}
          value={username}
        />
        {rightIcon()}
      </View>
      {renderFeedback()}
    </View>
  );
};

HandleEditor.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: Colors.white,
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: Colors.taupe,
    borderRadius: 5,
    paddingLeft: 0.5,
    flexDirection: `row`,
    alignItems: `center`,
  },
  input: {
    borderBottomWidth: 0,
    marginTop: 3.5,
    flex: 1,
    minHeight: 40,
    fontSize: 18,
    padding: 10,
  },
  invalidContainer: {
    backgroundColor: Colors.brightRed,
    justifyContent: `center`,
    alignItems: `flex-start`,
    paddingLeft: 16,
    paddingTop: 6,
    paddingBottom: 5,
    width: `100%`,
    marginTop: 16,
    borderRadius: 5,
  },
  textStyle: {
    fontSize: 12,
    color: Colors.white,
    lineHeight: 21,
  },
  validContainer: {
    backgroundColor: `#2c9a44`,
    justifyContent: `center`,
    alignItems: `flex-start`,
    paddingLeft: 16,
    marginTop: 16,
    paddingTop: 6,
    paddingBottom: 5,
    width: `100%`,
    borderRadius: 5,
  },
});
