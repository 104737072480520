/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNativeFile } from 'apollo-upload-client';

export type CreateCommunityState = {
  currentStep: number;
  communityName: string;
  communityImage: string | ReactNativeFile | null;
  communityEmail: string;
  selectedPreset: CommunityPreset | ``;
  categories: string[];
  communityAge: string | number;
  communityVerificationType: string[];
};

export type CommunityPreset = `community` | `channel`;

const initialState: CreateCommunityState = {
  currentStep: 0,
  communityName: ``,
  communityImage: null,
  communityEmail: ``,
  selectedPreset: `community`,
  categories: [],
  communityAge: `0`,
  communityVerificationType: [],
};

const createCommunitySlice = createSlice({
  name: `community`,
  initialState,
  reducers: {
    moveNext: (state) => {
      state.currentStep += 1;
    },
    movePrev: (state) => {
      state.currentStep -= 1;
    },
    addCategory: (state, action: PayloadAction<string>) => {
      // Max 3 categories per community
      if (state.categories.length > 2) return;
      state.categories.push(action.payload);
    },
    removeCategory: (state, action: PayloadAction<string>) => {
      if (state.categories.length === 0) return;
      state.categories = state.categories.filter((cat) => cat !== action.payload);
    },
    setCommunityName: (state, action: PayloadAction<string>) => {
      state.communityName = action.payload;
    },
    setCommunityEmail: (state, action: PayloadAction<string>) => {
      state.communityEmail = action.payload;
    },
    setSelectedPreset: (state, action: PayloadAction<CommunityPreset>) => {
      state.selectedPreset = action.payload;
    },
    setCommunityImage: (state, action: PayloadAction<string | ReactNativeFile>) => {
      state.communityImage = action.payload;
    },
    setCommunityAge: (state, action: PayloadAction<string>) => {
      state.communityAge = action.payload;
    },
    setCommunityVerificationType: (state, action: PayloadAction<string[]>) => {
      state.communityVerificationType = action.payload;
    },
    finishWizard: (state) => {
      state.categories = [];
      state.communityEmail = ``;
      state.communityImage = null;
      state.communityName = ``;
      state.currentStep = 0;
      state.selectedPreset = ``;
      state.communityAge = `0`;
      state.communityVerificationType = [];
    },
  },
});

export const {
  addCategory,
  moveNext,
  movePrev,
  removeCategory,
  setCommunityName,
  finishWizard,
  setSelectedPreset,
  setCommunityImage,
  setCommunityEmail,
  setCommunityAge,
  setCommunityVerificationType,
} = createCommunitySlice.actions;

const communitySliceReducer = createCommunitySlice.reducer;

export { communitySliceReducer };
