import React, { useState, useEffect, useCallback } from 'react';
import { Image, View, FlatList, TouchableWithoutFeedback, TouchableOpacity, StyleSheet } from 'react-native';
import FastImage from 'react-native-fast-image';
import { MeshIcon, ScrollDots } from '../../common-ui';
import { Colors, Spacing } from '../../common-styles';

type PostContentGalleryType = {
  width: number;
  imageUrls?: string[];
  imagesToUpload?: any[];
  onLayout?: () => void;
  onPress?: () => void;
  removeMedia?: (index: number) => void;
  showCloseButton?: boolean;
};

const HIT_SLOP = { top: 10, bottom: 10, left: 10, right: 10 };

const PostContentGallery: React.FC<PostContentGalleryType> = React.memo(
  ({ width, imageUrls: existing = [], imagesToUpload = [], onLayout, onPress, removeMedia, showCloseButton = false }) => {
    const appending = imagesToUpload.map((rnf) => rnf.uri || rnf);
    const imageUrls = [...existing, ...appending];
    const imageUrlLinks = imageUrls?.map((image) => {
      // normalize local and server data
      return { url: image.url || image.uri || image };
    });

    const [selected, setSelected] = useState(0);
    const isMounted: any = React.useRef(null);
    const [imgRatio, setImgRatio] = useState(1);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      const [referenceFrame] = imageUrlLinks;
      if (referenceFrame && isMounted?.current) {
        Image.getSize(referenceFrame.url || referenceFrame, (width, height) => {
          const ratio = Math.min(height / width, 2);
          setImgRatio(ratio);
        });
      }
    }, [existing, imageUrlLinks, isMounted?.current]);

    // FlatList callbacks
    const keyExtractor = useCallback((image) => image.url, []);

    const handleScroll = useCallback(
      (e) => {
        const newSelected = Math.round(e.nativeEvent.contentOffset.x / Math.min(Spacing.standardWidth, width));
        if (selected !== newSelected) setSelected(newSelected);
      },
      [setSelected, selected, width],
    );

    const renderImage = useCallback(
      ({ item: imageSource, index }) => {
        const onClosePress = () => {
          if (!removeMedia) return;
          removeMedia(index);
        };

        return (
          <>
            <TouchableWithoutFeedback key={index} onPress={onPress}>
              <FastImage source={{ uri: imageSource.url }} resizeMode="cover" style={{ width, height: width * imgRatio }} />
            </TouchableWithoutFeedback>
            {showCloseButton ? (
              // <View style={{ position: `relative` }}>
              <TouchableOpacity activeOpacity={0.8} onPress={onClosePress} style={styles.closeIconContainer} hitSlop={HIT_SLOP}>
                <MeshIcon name="cross" size={20} color={Colors.white} />
              </TouchableOpacity>
            ) : // </View>
            undefined}
          </>
        );
      },
      [width, imgRatio, onPress, showCloseButton, removeMedia],
    );

    return (
      <View onLayout={onLayout}>
        <FlatList
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          initialNumToRender={2}
          onScroll={handleScroll}
          maxToRenderPerBatch={3}
          data={imageUrlLinks}
          keyExtractor={keyExtractor}
          renderItem={renderImage}
        />
        {imageUrlLinks?.length > 1 && <ScrollDots selected={selected} total={imageUrlLinks?.length} />}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  closeIconContainer: {
    position: `absolute`,
    top: 15,
    right: 15,
    backgroundColor: Colors.black,
    borderRadius: 100,
    padding: 3,
  },
});

export default PostContentGallery;
