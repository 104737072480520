import { useCallback, useEffect, useContext } from 'react';
import * as Updates from 'expo-updates';
import { Platform, Linking } from 'react-native';
import { setAsyncStorageObject } from '../common-util';
import { AppContext } from '../../AppContext';

// - - - - - - - - - -
// useLinkHandler
// - - - - - - - - - -
// Are you testing links? Here's how to do it the least painful way on iOS:
//
// - xcrun simctl openurl booted <link>
//
export const useLinkHandler = (appIsReady) => {
  const { user, setLaunchUrl } = useContext(AppContext);

  const handleLink = useCallback(
    async (url) => {
      if (!url) return false;
      if (!user?.id) {
        // Save this URL to async storage so that we can pick up handling it when we
        // are done with login.
        console.log(`----> useLinkHandler deferring url: ${url}`);
        setLaunchUrl(url);
        setAsyncStorageObject(`launchUrl`, url);
        return false;
      }
      await Linking.openURL(url);
      return true;
    },
    [setLaunchUrl, user?.id],
  );

  const expoUpdatesEventHandler = useCallback(
    (event) => {
      if (!appIsReady && event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
        console.log(`update available, and the app isn't ready yet -- let's relaunch!!!`);
        Updates.reloadAsync();
      }
    },
    [appIsReady],
  );

  useEffect(() => {
    const expoUpdatesSubscription = Updates.addListener(expoUpdatesEventHandler);
    if (Platform.OS !== `web`) Linking.addEventListener(`url`, handleLink);
    return () => {
      expoUpdatesSubscription.remove();
      if (Platform.OS !== `web`) Linking.removeEventListener(`url`, handleLink);
    };
  }, [expoUpdatesEventHandler, handleLink]);
};

// - - - - - - - - - -
// matchTemplateUrl
// - - - - - - - - - -
// Try to match an URL against a template. Returns a regex match with decoded URL params in groups, or null
//
//  template = `/chat/:channel_id/:channel_type`
//  urlpath = `/chat/1234-5678/foobar`
//  returns => regex match object with match.groups = { channel_id: `1234-5678`, channel_type: `foobar` }
//
//  template = `/chat/:channel_id/channel_type`
//  urlpath = `/community/1234-5678`
//  returns => null
//
export const matchTemplateUrl = (template, urlpath) => {
  if (!template) return null;
  if (!urlpath) return null;

  // console.log(`-`);
  // console.log(`template: ${template}`);
  // console.log(`urlpath: ${urlpath}`);

  let regex = template;
  regex = regex.replace(/([.\\(){}[\]?*])/g, /\\$1/); // escape any regex chars in the template
  regex = regex.replace(/:([a-z_]+)/g, `(?<$1>[^/]+)`); // convert :named_var to a regex group capture

  // console.log(`regex: ${regex}`);

  return urlpath.match(`^${regex}$`);
};
