import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, FlatList, ListRenderItem, RefreshControl, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useMutation, useQuery } from 'react-apollo';
import { unionBy } from 'lodash';
import { NetworkStatus } from 'apollo-client';
import { useHeaderHeight } from '@react-navigation/elements';
import { useSelector } from 'react-redux';
import { Colors } from '../../common-styles';
import { BottomSheetModal, FloatingButton, LoadingIndicator, LoadingModal, MeshIcon, ThemedButton } from '../../common-ui';
//@ts-ignore
import WelcomeImage2 from '../../images/WelcomeImage2';
import { AppContext } from '../../../AppContext';
import { GET_PERSONAL_FEED, HIDE_PERSONAL_POST, refetchQueriesFor, REMOVE_ACTIVITY, REMOVE_PERSONAL_POST } from '../../graphql';
import {
  Activity as IActivity,
  BottomSheetOptions,
  FeedItem,
  RemoveActivityData,
  RemoveActivityVars,
} from '../../common-types/types';
import { MainTabsNavigator } from '../../common-types/navigation-types';
import { Activity } from './Activity';
import { useBottomSheetOptions } from '../../common-util/hooks/useBottomSheetOptions';
import { useDisclosure } from '../../common-util/hooks/useDisclosure';
import { useConfirmModal } from '../../common-util/hooks/useConfirmModal';
import { RootState, useAppDispatch } from '../../redux/store';
import { refreshFollowInfo } from '../../redux/followSlice';
import { useFollow } from './hooks/useFollow';
import { DrawerMenuIcon } from '../../drawer/Drawer';
// @ts-ignore
import MeshWordmarkSVG from '../../../assets/images/mesh-wordmark.svg';
import { setPersonalFeedRefresh } from '../../redux/feedSlice';
import InviteToProfile from './InviteToProfile';

const ItemSeparatorComponent = () => <View style={{ backgroundColor: Colors.dividerColor, height: 1 }} />;
const ListFooterComponent = () => <View style={{ backgroundColor: Colors.white, flex: 1, height: 100 }} />;

export const PersonalFeedScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useContext(AppContext);
  const navigation = useNavigation<any>();
  const { forcePersonalFeedRefresh } = useSelector((state: RootState) => state.feed);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [lastActivity, setLastActivity] = useState(``);
  const [offset, setOffset] = useState(5);

  const feedListRef = useRef<FlatList<FeedItem>>(null);
  const [tabPressed, setTabPressed] = useState(false);

  const headerHeight = useHeaderHeight();
  const [nativeHeaderHight] = useState(headerHeight);

  const [activityOptions, setActivityOptions] = useState<BottomSheetOptions[]>([]);

  const { followUser, unfollowUser } = useFollow();
  const { isOpen: isOpenActivityBModal, onClose: onCloseActivityBModal, onToggle: onToggleActivityBModal } = useDisclosure();
  const { buildPersonalFeedModalOptions } = useBottomSheetOptions(onCloseActivityBModal);
  const promptDeletePost = useConfirmModal();

  useFocusEffect(
    useCallback(() => {
      return () => {
        setTabPressed(false);
        setLastActivity(``);
      };
    }, [setTabPressed, setLastActivity]),
  );

  useEffect(() => {
    //@ts-ignore
    const unsubscribe = navigation.getParent<MainTabsNavigator>().addListener(`tabPress`, () => {
      if (tabPressed && feedListRef?.current) {
        feedListRef?.current?.scrollToOffset({ offset: 0, animated: true });
      }
      if (!tabPressed) setTabPressed(true);
    });

    return unsubscribe;
  }, [navigation, setTabPressed, tabPressed]);

  useEffect(() => {
    navigation.setOptions({
      header: () => (
        <View style={[styles.headerWrapper, { height: nativeHeaderHight }]}>
          <View style={styles.headerInner}>
            <DrawerMenuIcon />
            <View style={{ paddingVertical: 10, alignItems: `center`, justifyContent: `center` }}>
              <MeshWordmarkSVG width={68} height={25} />
            </View>
            <HeaderRight setShowInviteModal={setShowInviteModal} />
          </View>
        </View>
      ),
    });
  }, [nativeHeaderHight, navigation, setShowInviteModal]);

  const keyExtractor = useCallback((item: FeedItem) => item.id, []);

  const {
    data: feedData,
    loading,
    networkStatus,
    refetch,
    fetchMore,
    // subscribeToMore,
  } = useQuery(GET_PERSONAL_FEED, {
    variables: {
      user_id: user?.id,
      limit: 5,
    },
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => dispatch(setPersonalFeedRefresh(false)),
  });

  const personalFeeds = useMemo(() => {
    if (feedData?.getProfileNodeFeed?.activities?.length > 0) return feedData?.getProfileNodeFeed?.activities;
    return [];
  }, [feedData]);

  // useEffect(() => {
  //   const unSubscribe = subscribeToMore({
  //     document: PERSONAL_FEED_SUBSCRIPTION,
  //     variables: {
  //       persona_id: user?.id,
  //     },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data?.getProfileNodeFeed) return prev;
  //       const res = subscriptionData.data?.getProfileNodeFeed;
  //       const { new: newData = [] } = res || {};
  //       const oldActivities = prev?.getProfileNodeFeed || [];

  //       try {
  //         let result = oldActivities;

  //         result = unionWith(newData, oldActivities, (post1: any, post2: any) => {
  //           return post1.id === post2.id;
  //         });

  //         return {
  //           getProfileNodeFeed: result,
  //         };
  //       } catch (error) {
  //         console.error(`Error thrown in subscriptionMore for Personal Feed`, error);
  //         return prev;
  //       }
  //     },
  //   });
  //   return unSubscribe;
  // }, [user?.id, subscribeToMore]);

  useEffect(() => {
    if (forcePersonalFeedRefresh) {
      refetch();
      setOffset(0);
      setTimeout(() => {
        if (feedListRef?.current) feedListRef?.current?.scrollToOffset({ offset: 0, animated: false });
      }, 500);
    }
  }, [forcePersonalFeedRefresh, feedListRef]);

  const [deletePost, { loading: deletingPost }] = useMutation<RemoveActivityData, RemoveActivityVars>(REMOVE_ACTIVITY, {
    refetchQueries: refetchQueriesFor(`Activity`),
  });

  const [deleteProfileFeed, { loading: deletingProfilePost }] = useMutation<RemoveActivityData, RemoveActivityVars>(
    REMOVE_PERSONAL_POST,
    {
      refetchQueries: refetchQueriesFor(`ProfileActivity`),
    },
  );

  const [hidePost] = useMutation(HIDE_PERSONAL_POST, {
    refetchQueries: refetchQueriesFor(`ProfileActivity`),
  });
  const loadMoreActivities = useCallback(() => {
    if (!loading) {
      if (lastActivity === null) return;
      fetchMore({
        variables: {
          id_lt: lastActivity?.length > 0 ? lastActivity : feedData?.getProfileNodeFeed?.lastActivity,
          offset,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          setLastActivity(fetchMoreResult?.getProfileNodeFeed?.lastActivity);
          setOffset((idx) => idx + 5);
          if (!fetchMoreResult) {
            console.log(`same as before, return prev..`);
            return prev;
          }
          const prevActivities = prev?.getProfileNodeFeed?.activities;
          const moreActivities = fetchMoreResult?.getProfileNodeFeed?.activities;
          const newActivities = unionBy(prevActivities, moreActivities, (g: any) => g.id);
          return {
            ...prev,
            getProfileNodeFeed: {
              ...prev.getProfileNodeFeed,
              activities: newActivities,
              lastActivity,
            },
          };
        },
      });
    }
  }, [feedData, setLastActivity, lastActivity, offset, setOffset]);

  const handleDeletePost = useCallback(
    (activity: IActivity) => {
      const { id } = activity;
      promptDeletePost({
        title: `Are you sure you want to delete this post?`,
        body: `Confirm by pressing Ok`,
        textConfirm: `Ok`,
        onConfirm: () => {
          if (activity.__typename === `ProfileActivity`) {
            deleteProfileFeed({ variables: { id }, refetchQueries: refetchQueriesFor(`ProfileActivity`) });
          } else {
            deletePost({ variables: { id }, refetchQueries: refetchQueriesFor(`Activity`) });
          }
        },
      });
    },
    [promptDeletePost],
  );

  const hidePersonalFeed = useCallback(
    (activity: IActivity) => {
      const { id } = activity;
      hidePost({
        variables: {
          activity_id: id,
          hide: true,
        },
      });
    },
    [hidePost],
  );

  const handleFollowUser = useCallback(
    async (isFollowed, activity: IActivity) => {
      try {
        if (activity) {
          const { actor } = activity;
          if (isFollowed) {
            await unfollowUser(actor, true);
          } else {
            await followUser(actor, true);
          }
          dispatch(refreshFollowInfo(true));
        }
      } catch (error) {
        console.error(error);
      }
    },

    [dispatch, followUser, unfollowUser],
  );

  const handleSelectActivity = useCallback(
    (activity: IActivity) => {
      const options = buildPersonalFeedModalOptions({
        activity,
        deletePost: () => handleDeletePost(activity),
        handleFollowUser: (isFollowed) => handleFollowUser(isFollowed, activity),
        handleTurnoffComment: () => {},
        hidePost: () => hidePersonalFeed(activity),
      });
      setActivityOptions(options);
      onToggleActivityBModal(); // Open the bottom sheet  modal
    },
    [onToggleActivityBModal, setActivityOptions, buildPersonalFeedModalOptions, handleDeletePost, handleFollowUser],
  );

  const handleCloseBottomSheet = () => {
    setActivityOptions([]);
    onCloseActivityBModal();
  };

  const renderItem: ListRenderItem<FeedItem> = ({ item: activity, index }) => {
    //@ts-ignore
    return (
      <Activity
        activity={activity as IActivity}
        onSelectActivity={handleSelectActivity}
        feedIndex={index}
        //@ts-ignore
        showGroup={activity.__typename === `Activity`}
        fromPersonalFeed={true}
      />
    );
  };
  if (!loading && !personalFeeds?.length) {
    return (
      <>
        <View style={styles.container}>
          <LinearGradient
            colors={[`rgba(218, 251, 255, 1)`, `rgba(252, 222, 233, 1)`]}
            style={styles.background}
            start={[0, 0]}
            end={[1, 0]}
          />
          <Welcome setShowInviteModal={setShowInviteModal} />
          <FloatingButton onPress={() => navigation.navigate(`PersonalPostEditor`, { group: null, mode: `create` })} />
        </View>
        <BottomSheetModal
          visible={showInviteModal}
          title="Invite to profile"
          showCancelBtn={false}
          confirmTitle="Done"
          onPressConfirm={() => setShowInviteModal(false)}
          onPressCancel={() => setShowInviteModal(false)}
        >
          <InviteToProfile toggleSheet={() => setShowInviteModal(!showInviteModal)} persona={user} />
        </BottomSheetModal>
      </>
    );
  }
  return (
    <View style={{ flex: 1 }}>
      <Animated.FlatList
        ref={feedListRef}
        ListFooterComponent={loading ? <LoadingIndicator /> : <ListFooterComponent />}
        ItemSeparatorComponent={ItemSeparatorComponent}
        renderItem={renderItem}
        refreshControl={
          <RefreshControl
            onRefresh={() => {
              refetch();
              setOffset(0);
            }}
            refreshing={networkStatus === NetworkStatus.refetch}
          />
        }
        refreshing={networkStatus === NetworkStatus.refetch}
        onEndReachedThreshold={1.5}
        data={personalFeeds}
        keyExtractor={keyExtractor}
        onEndReached={loadMoreActivities}
        onRefresh={() => {
          refetch();
          setOffset(0);
        }}
        onScrollBeginDrag={() => setTabPressed(true)}
      />
      <LoadingModal isVisible={deletingPost || deletingProfilePost} content="Deleting the post" />
      <FloatingButton onPress={() => navigation.navigate(`PersonalPostEditor`, { group: null, mode: `create` })} />
      <BottomSheetModal
        onPressCancel={handleCloseBottomSheet}
        onPressConfirm={handleCloseBottomSheet}
        showCancelBtn={false}
        confirmTitle="Close"
        visible={isOpenActivityBModal}
        title="Post options"
        options={activityOptions}
      />
      <BottomSheetModal
        visible={showInviteModal}
        title="Invite to profile"
        showCancelBtn={false}
        confirmTitle="Done"
        onPressConfirm={() => setShowInviteModal(false)}
        onPressCancel={() => setShowInviteModal(false)}
      >
        <InviteToProfile toggleSheet={() => setShowInviteModal(!showInviteModal)} persona={user} />
      </BottomSheetModal>
    </View>
  );
};

type HeaderRightProps = {
  setShowInviteModal: (b: boolean) => void;
};
const HeaderRight: React.FC<HeaderRightProps> = ({ setShowInviteModal }) => (
  <TouchableOpacity
    style={{ paddingRight: 6, justifyContent: `center`, alignItems: `center` }}
    onPress={() => setShowInviteModal(true)}
    hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
    activeOpacity={0.6}
  >
    <MeshIcon name="follow" size={24} color={Colors.iconColor} />
  </TouchableOpacity>
);

const Welcome = ({ setShowInviteModal }: { setShowInviteModal: (visible: boolean) => void }) => {
  const { user } = useContext(AppContext);
  return (
    <View style={{ marginTop: 30 }}>
      <View style={styles.titleContent}>
        <WelcomeImage2 onPress={() => console.log(`clicking avatar`)} uri={user?.avatar_url || ''} />

        <Text style={styles.description}>
          This is your home feed. When you follow people, {`\n`}you'll see their content here, along with your own.{`\n`}Invite
          and follow friends to get started!
        </Text>
      </View>
      <ThemedButton rounded title="Get Started" onPress={() => setShowInviteModal(true)} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: 15,
    justifyContent: `space-around`,
  },
  background: {
    position: `absolute`,
    left: 0,
    right: 0,
    top: 0,
    height: `100%`,
  },
  titleContent: {
    alignItems: `center`,
    marginTop: -100,
  },
  description: {
    fontSize: 15,
    textAlign: `center`,
    marginBottom: 40,
  },
  headerWrapper: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `flex-end`,
    paddingLeft: 12,
    paddingRight: 8,
    backgroundColor: Colors.white,
    width: `100%`,
  },
  headerInner: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `space-between`,
    width: `100%`,
  },
});
