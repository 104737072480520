import * as React from 'react';
import Svg, { Defs, Circle, G, Mask, Use, Path } from 'react-native-svg';

export const BirthdayImage = (props) => {
  return (
    <Svg
      width="69px"
      height="84px"
      viewBox="0 0 69 84"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Circle id="a" cx={24} cy={24} r={24} />
        <Circle id="c" cx={24} cy={24} r={24} />
        <Circle id="e" cx={24} cy={24} r={24} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          cx={24}
          cy={24}
          r={24}
          fill="#E8E9FF"
          fillRule="nonzero"
          transform="translate(-164 -103) translate(153.292 98.791) translate(10.708 36.209)"
        />
        <G transform="translate(-164 -103) translate(153.292 98.791) translate(10.708 36.209)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Path
            d="M16.552 16.883c-1.786 0-3.478 1.124-5.093 3.31a1.045 1.045 0 00.204 1.448c.45.343 1.088.25 1.426-.207 1.237-1.675 2.401-2.482 3.463-2.482 1.061 0 2.226.807 3.463 2.482.337.457.976.55 1.426.207.45-.342.541-.99.204-1.448-1.615-2.186-3.307-3.31-5.093-3.31z"
            fill="#333895"
            fillRule="nonzero"
            mask="url(#b)"
          />
        </G>
        <G transform="translate(-164 -103) translate(153.292 98.791) translate(10.708 36.209)">
          <Mask id="d" fill="#fff">
            <Use xlinkHref="#c" />
          </Mask>
          <Path
            d="M31.117 16.883c-1.786 0-3.478 1.124-5.093 3.31a1.045 1.045 0 00.204 1.448c.45.343 1.089.25 1.426-.207 1.237-1.675 2.402-2.482 3.463-2.482 1.062 0 2.226.807 3.463 2.482.338.457.976.55 1.426.207.45-.342.542-.99.204-1.448-1.615-2.186-3.307-3.31-5.093-3.31z"
            fill="#333895"
            fillRule="nonzero"
            mask="url(#d)"
          />
        </G>
        <G transform="translate(-164 -103) translate(153.292 98.791) translate(10.708 36.209)">
          <Mask id="f" fill="#fff">
            <Use xlinkHref="#e" />
          </Mask>
          <Path
            d="M28.217 26.814H19.12a2.246 2.246 0 00-2.237 2.254c0 .377.094.748.273 1.079 1.885 3.484 4.048 5.274 6.513 5.274s4.628-1.79 6.513-5.274a2.263 2.263 0 00-.894-3.058 2.224 2.224 0 00-1.07-.275z"
            fill="#333895"
            fillRule="nonzero"
            mask="url(#f)"
          />
        </G>
        <Path
          d="M22.673 4.209c1.781 0 3.449.705 4.68 1.936a6.577 6.577 0 011.946 4.69 6.58 6.58 0 01-1.37 4.018l-.278.341-.035.036 11.748 27.774c.093.16.165.33.213.51l.055.272.018.28a2.174 2.174 0 01-1.949 2.161l-.237.013-18.142-.002-.67.29-.193-.29-10.563.002c-.154 0-.304-.015-.445-.043l-.206-.053-.192-.074a2.167 2.167 0 01-1.25-2.636l.082-.224 11.923-27.908-.1-.105a6.564 6.564 0 01-1.59-3.483l-.043-.437-.015-.442c0-1.473.481-2.87 1.37-4.031l.279-.341L18 6.15a6.591 6.591 0 014.672-1.941zm6.616 26.154l-.648.462c-2.042 1.402-4.326 2.668-6.83 3.815-2.51 1.153-5.172 2.155-7.987 3.046l-.969.296-1.66 3.897h7.25l.823-.355c1.98-.88 3.797-1.827 5.449-2.855l.812-.52c1.994-1.305 3.702-2.73 5.089-4.272l.11-.126-1.439-3.388zm3.288 7.752l-.18.176a31.723 31.723 0 01-3.656 2.952l-.819.555-.13.081h6.375l-1.59-3.764zm-6.53-15.399l-.66.422a47.311 47.311 0 01-2.795 1.591 55.362 55.362 0 01-4.308 2.012l-.763.31-2.376 5.584.312-.11a62.489 62.489 0 003.442-1.369l1.097-.49c2.765-1.261 5.244-2.692 7.359-4.287l.183-.145-1.49-3.518zm-2.28-5.365l-.093.02a6.59 6.59 0 01-1.645.046l-.367-.048-1.607 3.765.52-.264a39.313 39.313 0 003.565-2.105l.177-.124-.55-1.29zm-1.094-8.77c-.527 0-1.02.174-1.408.482l-.064.056-.122.123a2.223 2.223 0 00-.645 1.334l-.015.26c0 .592.243 1.161.66 1.579.427.426.981.66 1.594.66.615 0 1.182-.236 1.585-.638.43-.44.668-1 .668-1.602 0-.605-.241-1.175-.668-1.601a2.196 2.196 0 00-1.585-.652z"
          fill="#9B88AF"
          transform="translate(-164 -103) translate(153.292 98.791) rotate(-18 22.68 25.368)"
        />
        <Path
          d="M64.191 55.962c1.415-1.895 3.823-2.287 5.75-1.014l.228.16.23.184c1.404 1.193 1.883 2.962 1.303 4.59l-.1.256-.018.037h4.215c.794 0 1.458.558 1.622 1.304l.028.175.01.181v5.38a1.66 1.66 0 01-1.48 1.65l-.18.01-.052-.001v14.052c0 .795-.558 1.459-1.303 1.622l-.175.029-.181.01H52.079a1.66 1.66 0 01-1.65-1.48l-.01-.18-.001-14.053-.05.001a1.661 1.661 0 01-1.622-1.304l-.028-.175-.01-.18v-5.381c0-.855.648-1.56 1.48-1.65l.18-.01h4.213l-.014-.032c-.692-1.603-.33-3.389.96-4.632l.223-.202.217-.173c1.866-1.42 4.307-1.163 5.819.594l.177.218 1.132 1.483 1.096-1.469zm8.236 12.912h-7.684v12.391h7.684V68.874zm-11.004 0h-7.685v12.391h7.685V68.874zm12.715-5.38h-9.395v2.06h9.395v-2.06zm-12.715 0h-9.397v2.06h9.397v-2.06zm5.529-5.668l-.101.121-1.661 2.223 2.4.005.803-1.077c.324-.435.289-.88-.079-1.225l-.12-.1c-.407-.298-.798-.316-1.123-.057l-.12.11zm-8.853-.135l-.133.09c-.433.319-.533.752-.28 1.186l.086.13.804 1.078h2.443l-1.691-2.214c-.298-.39-.686-.507-1.081-.344l-.148.074z"
          fill="#9B88AF"
          transform="translate(-164 -103) translate(153.292 98.791) rotate(20 63.083 69.398)"
        />
      </G>
    </Svg>
  );
};
