import * as React from 'react';
import Svg, { Defs, Rect, Path, G, Mask, Use, Text, TSpan } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreBusiness = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Rect id="a" x={0} y={0} width={163} height={98} rx={7} />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Use fill="#ECE8F6" xlinkHref="#a" />
          <G mask="url(#b)">
            <Path
              d="M38.992 70.866C55.884 69.265 69.865 57.72 75.99 43.002c6.126-14.718-16.94-27.256-33.554-27.975C25.823 14.308 13 28.077 13 43.763c0 15.684 9.1 28.705 25.992 27.103z"
              fill="#4722A7"
              style={{
                mixBlendMode: `multiply`,
              }}
              transform="translate(73 18) rotate(-126 45 43)"
            />
            <G strokeWidth={1} fill="none">
              <Path
                d="M65 43.77c0 .83-.577 1.503-1.29 1.503H44.32v5.534c1.435.106 2.57 1.495 2.57 3.193H24.58c0-.884.307-1.684.804-2.263.473-.552 1.118-.903 1.834-.934v-5.53H7.289C6.577 45.273 6 44.6 6 43.77V36h58.94c.033 0 .06.032.06.07v7.7z"
                fill="#B0CEB7"
                fillRule="nonzero"
                transform="translate(73 18) translate(0 11)"
              />
              <Path
                d="M67.924 45H2.076A2.082 2.082 0 010 42.912V2.088C0 .935.93 0 2.076 0h65.848C69.07 0 70 .935 70 2.088v40.824C70 44.065 69.07 45 67.924 45"
                fill="#CADECC"
                fillRule="nonzero"
                transform="translate(73 18) translate(0 11)"
              />
              <Path
                d="M37 40.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0M3 36V4.528C3 3.684 3.691 3 4.544 3h61.912C67.31 3 68 3.684 68 4.528v31.4c0 .04-.033.072-.073.072H3z"
                fill="#EAF0EB"
                fillRule="nonzero"
                transform="translate(73 18) translate(0 11)"
              />
              <G fillRule="nonzero">
                <Path
                  d="M12.322-11.216h9.689a1.08 1.08 0 011.081 1.078v32.152a1.08 1.08 0 01-1.081 1.078h-9.689a1.08 1.08 0 01-1.082-1.078v-32.152a1.08 1.08 0 011.082-1.078z"
                  fill="#FFF"
                  transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 14.3) rotate(90 17.166 5.938)"
                />
                <G fill="#B0CEB7">
                  <Path
                    d="M0 0h27.025a.68.68 0 01.678.68.68.68 0 01-.678.681H0V0zM0 3.452h12.322a.68.68 0 01.678.68.68.68 0 01-.678.68H0v-1.36zM0 6.884h19.009a.68.68 0 01.678.68.68.68 0 01-.678.681H0V6.884z"
                    transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 14.3) translate(3.033 1.806)"
                  />
                </G>
              </G>
              <G fillRule="nonzero">
                <Path
                  d="M2.873-1.95h10.713c.496 0 .897.4.897.894v14.67a.895.895 0 01-.897.894H2.873a.895.895 0 01-.897-.894v-14.67c0-.493.402-.894.897-.894z"
                  fill="#FFF"
                  transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) rotate(90 8.23 6.279)"
                />
                <Path
                  d="M20.75-1.95h10.713c.496 0 .897.4.897.894v14.67a.895.895 0 01-.897.894H20.75a.895.895 0 01-.897-.894v-14.67c0-.493.402-.894.897-.894z"
                  fill="#FFF"
                  transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) rotate(90 26.107 6.279)"
                />
                <Path
                  d="M5.058 0A5.057 5.057 0 110 5.057 5.057 5.057 0 015.058 0zm0 2.317a2.742 2.742 0 00-2.745 2.74 2.742 2.742 0 002.745 2.739 2.742 2.742 0 002.745-2.74 2.742 2.742 0 00-2.745-2.739z"
                  fill="#B0CEB7"
                  transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) translate(3.181 .923)"
                />
                <Path
                  d="M9.88 3.553l-2.212.685a2.755 2.755 0 00-1.587-1.73L6.955.355A5.082 5.082 0 019.88 3.553z"
                  fill="#E93737"
                  transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) translate(3.181 .923)"
                />
                <Path
                  d="M3.05 6.93L1.36 8.516A5.072 5.072 0 01.177 6.4l2.23-.623c.118.438.343.832.644 1.154z"
                  fill="#1BC0D7"
                  transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) translate(3.181 .923)"
                />
                <G>
                  <Path
                    d="M5.058 0A5.057 5.057 0 110 5.057 5.057 5.057 0 015.058 0zm0 2.317a2.742 2.742 0 00-2.745 2.74 2.742 2.742 0 002.745 2.739 2.742 2.742 0 002.745-2.74 2.742 2.742 0 00-2.745-2.739z"
                    fill="#B0CEB7"
                    transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) translate(21.058 .923)"
                  />
                  <Path
                    d="M9.88 3.553l-2.212.685a2.755 2.755 0 00-1.587-1.73L6.955.355A5.082 5.082 0 019.88 3.553z"
                    fill="#E93737"
                    transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) translate(21.058 .923)"
                  />
                  <Path
                    d="M3.05 6.93L1.36 8.516A5.072 5.072 0 01.177 6.4l2.23-.623c.118.438.343.832.644 1.154z"
                    fill="#1BC0D7"
                    transform="translate(73 18) translate(0 11) translate(7 6) translate(21.452 .053) translate(21.058 .923)"
                  />
                </G>
              </G>
              <G transform="translate(73 18) translate(0 11) translate(7 6)">
                <Path
                  d="M-2.67 3.963h23.585a1.29 1.29 0 011.293 1.288V20.92a1.29 1.29 0 01-1.293 1.288H-2.67a1.29 1.29 0 01-1.293-1.288V5.251A1.29 1.29 0 01-2.67 3.963z"
                  transform="rotate(90 9.123 13.086)"
                  fill="#FFF"
                  fillRule="nonzero"
                />
                <Rect
                  fill="#EAF0EB"
                  fillRule="nonzero"
                  x={2.57638974}
                  y={6.01517226}
                  width={13.1031502}
                  height={11.0169727}
                  rx={1}
                />
                <Path
                  d="M3.181 16.354c1.346-2.956 2.422-4.435 3.23-4.435 1.211 0 .89 2.053 2.21 2.218 1.32.165 2.887-4.29 3.784-4.552.897-.262.385 1.3.833 1.3.3 0 .903-1.272 1.812-3.815"
                  stroke="#56ACF6"
                  strokeWidth={0.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path
                  d="M3.181 16.706c1.108-1.242 1.975-1.755 2.602-1.538.939.326 2.417.961 3.47-.917 1.051-1.878 2.39.137 3.255.474.577.224 1.424-.56 2.542-2.352"
                  stroke="#E93737"
                  strokeWidth={0.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <Path fill="#B0CEB7" fillRule="nonzero" d="M3.917164 2.24249843H14.305929899999999V4.59443078H3.917164z" />
                <Path fill="#B0CEB7" fillRule="nonzero" d="M2.57638974 19.3118688H15.67953994V20.3118688H2.57638974z" />
                <Path fill="#B0CEB7" fillRule="nonzero" d="M2.57638974 22.4249843H15.67953994V23.4249843H2.57638974z" />
              </G>
            </G>
          </G>
        </G>
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#4722A7">
          <TSpan x={12} y={22}>
            Business
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
};
