import { createIconSetFromIcoMoon, Entypo, MaterialCommunityIcons, MaterialIcons, Feather } from '@expo/vector-icons';
import icoMoonConfig from '../../assets/meshicon-v4.1/selection.json';

const expoAssetId = require(`../../assets/meshicon-v4.1/fonts/meshicon.ttf`);
const MeshIcons = createIconSetFromIcoMoon(icoMoonConfig, `MeshIcons`, expoAssetId);

export const IconTable = {
  // bottom tab bar
  home: {
    default: [MeshIcons, `home`],
    focused: [MeshIcons, `home-filled`],
  },
  node: {
    default: [MeshIcons, `node`],
    focused: [MeshIcons, `node-filled`],
  },
  group: {
    default: [MeshIcons, `users`],
    focused: [MeshIcons, `users-filled`],
  },
  notifications: {
    default: [MeshIcons, `bell`],
    focused: [MeshIcons, `bell-filled`],
  },
  profile: {
    default: [MeshIcons, `user`],
    focused: [MeshIcons, `user-filled`],
  },
  chat: {
    default: [MeshIcons, `chat`],
    focused: [MeshIcons, `chat-filled`],
  },

  // general icons
  'list-alt': {
    default: [MeshIcons, `list-alt`],
  },
  'check-box-selected': {
    default: [MeshIcons, `checkbox-hover`],
  },
  'wrong-input': {
    default: [MeshIcons, `info`],
    focused: [MeshIcons, `info-filled`],
  },
  'chevron-right': {
    default: [MeshIcons, `chevron-right`],
  },
  'chevron-left': {
    default: [MeshIcons, `chevron-left`],
  },
  'chevron-down': {
    default: [MeshIcons, `chevron-down`],
  },
  'chevron-down-3': {
    default: [MeshIcons, `chevron-down-3`],
  },
  'chevron-up': {
    default: [MeshIcons, `chevron-up`],
  },
  'chevron-up-3': {
    default: [MeshIcons, `chevron-up-3`],
  },
  settings: {
    default: [MeshIcons, `settings`],
    focused: [MeshIcons, `settings-filled`],
  },
  reload: {
    default: [MaterialIcons, `refresh`],
  },
  'arrow-right': {
    default: [MeshIcons, `arrow-right`],
  },
  'arrow-up': {
    default: [MeshIcons, `arrow-up`],
  },
  'arrow-up-3': {
    default: [MeshIcons, `arrow-up-3`],
  },
  grid: {
    default: [MeshIcons, `grid`],
  },
  'grid-3': {
    default: [MeshIcons, `grid-3`],
  },
  'grid-filled': {
    default: [MeshIcons, `grid-filled`],
  },
  list: {
    default: [MeshIcons, `list`],
  },
  'list-3': {
    default: [MeshIcons, `list-3`],
  },
  flag: {
    default: [MeshIcons, `flag`],
    focused: [MeshIcons, `flag-filled`],
  },
  pin: {
    default: [MeshIcons, `pin`],
    focused: [MeshIcons, `pin-filled`],
  },
  dollar: {
    default: [MeshIcons, `dollar`],
  },
  'dollar-w-border': {
    default: [MeshIcons, `Dollar-w-border`],
  },
  invite: {
    default: [MeshIcons, `share-2-3`],
  },
  'checkbox-on': {
    default: [MeshIcons, `checkbox-on`],
  },
  'checkbox-off': {
    default: [MeshIcons, `checkbox-off`],
  },
  'radio-on': {
    default: [MeshIcons, `radio-on`],
  },
  'radio-off': {
    default: [MeshIcons, `radio-off`],
  },
  snowflake: {
    default: [MeshIcons, `snowflake`],
  },
  block: {
    default: [MeshIcons, `block`],
  },

  // posts
  like: {
    default: [MeshIcons, `thumbs-up`],
    focused: [MeshIcons, `thumbs-up-filled`],
  },
  dislike: {
    default: [MeshIcons, `thumbs-down`],
    focused: [MeshIcons, `thumbs-down-filled`],
  },
  comment: {
    default: [MeshIcons, `chat`],
  },
  create: {
    default: [MeshIcons, `edit-2`],
    focused: [MeshIcons, `edit-2-filled`],
  },
  heart: {
    default: [MeshIcons, `heart-empty`],
    focused: [MeshIcons, `heart-full`],
  },
  meme: {
    default: [MeshIcons, `meme`],
  },
  'text-post': {
    default: [MeshIcons, `text-post`],
  },
  poll: {
    default: [MeshIcons, `poll`],
  },

  // uncategorized
  edit: {
    default: [MeshIcons, `edit`],
    focused: [MeshIcons, `edit-filled`],
  },
  star: {
    default: [MeshIcons, `star`],
    focused: [MeshIcons, `star-filled`],
  },
  'user-verification': {
    default: [MeshIcons, `user-verification`],
  },
  freeze: {
    default: [MeshIcons, `snowflake`],
  },
  image: {
    default: [MeshIcons, `image`],
  },
  camera: {
    default: [MeshIcons, `camera`],
    focused: [MeshIcons, `camera-filled`],
  },
  'flip-camera': {
    default: [MeshIcons, `reverse-camera`],
  },
  video: {
    default: [MeshIcons, `video`],
    focused: [MeshIcons, `video-filled`],
  },
  copy: {
    default: [MeshIcons, `copy`],
  },
  email: {
    default: [MeshIcons, `mail`],
    focused: [MeshIcons, `mail-filled`],
  },
  trash: {
    default: [MeshIcons, `trash`],
  },
  magnifyingglass: {
    default: [MeshIcons, `search`],
    focused: [MeshIcons, `search-filled`],
  },
  'select-circle': {
    default: [MeshIcons, `select-off`],
    focused: [MeshIcons, `select-on`],
  },
  closecircle: {
    default: [MeshIcons, `x-circle`],
  },
  'circle-check': {
    default: [MeshIcons, `select-on`],
  },
  'circle-plus': {
    default: [MeshIcons, `plus-circle`],
    focused: [MeshIcons, `plus-circle-filled`],
  },
  'circle-x': {
    default: [MeshIcons, `x-circle`],
    focused: [MeshIcons, `x-circle-filled`],
  },
  lock: {
    default: [MeshIcons, `lock`],
  },
  unlock: {
    default: [MeshIcons, `unlock`],
  },
  cross: {
    default: [MeshIcons, `x`],
  },
  plus: {
    default: [MeshIcons, `plus`],
  },
  'plus-3': {
    default: [MeshIcons, `plus-3`],
  },
  minus: {
    default: [Entypo, `minus`],
  },
  'alert-circle': {
    default: [MeshIcons, `alert-circle`],
  },
  check: {
    default: [MeshIcons, `check`],
  },
  'dots-three-horizontal': {
    default: [MeshIcons, `more-horizontal`],
  },
  'dots-three-vertical': {
    default: [MeshIcons, `more-vertical`],
  },
  upload: {
    default: [Entypo, `upload`],
  },
  'three-bars': {
    default: [MeshIcons, `menu`],
  },
  'info-circle': {
    default: [MeshIcons, `info`],
    focused: [MeshIcons, `info-filled`],
  },
  'help-circle': {
    default: [MeshIcons, `help-circle`],
    focused: [MeshIcons, `help-circle-filled`],
  },
  'visibility-on': {
    default: [MeshIcons, `view`],
  },
  'visibility-off': {
    default: [MeshIcons, `hide`],
  },
  close: {
    default: [MeshIcons, `x`],
  },
  logout: {
    default: [MeshIcons, `logout`],
  },
  info: {
    default: [Entypo, `info`],
  },
  moderation: {
    default: [MeshIcons, `crown`],
    focused: [MeshIcons, `crown-filled`],
  },
  pirate: {
    default: [MaterialCommunityIcons, `pirate`],
  },
  'mobile-phone': {
    default: [Entypo, `mobile`],
  },
  eye: {
    default: [MeshIcons, `view`],
    focused: [MeshIcons, `view-filled`],
  },
  'eye-hide': {
    default: [MeshIcons, `hide`],
    focused: [MeshIcons, `hide-filled`],
  },
  sparkle: {
    default: [MeshIcons, `sparkle`],
    focused: [MeshIcons, `sparkle-filled`],
  },
  clock: {
    default: [MeshIcons, `clock`],
    focused: [MeshIcons, `clock-filled`],
  },
  join: {
    default: [MeshIcons, `arrow-corner-down-right`],
    focused: [MeshIcons, `arrow-corner-down-right-3`],
  },
  'volume-x': {
    default: [MeshIcons, `volume-x`],
    focused: [MeshIcons, `volume-x-filled`],
  },
  'volume-2': {
    default: [MeshIcons, `volume-2`],
    focused: [MeshIcons, `volume-2-filled`],
  },
  'minus-circle': {
    default: [MeshIcons, `minus-circle`],
    focused: [MeshIcons, `minus-circle-filled`],
  },
  play: {
    default: [MeshIcons, `play`],
    focused: [MeshIcons, `play-circle`],
  },
  'play-circle': {
    default: [MeshIcons, `play-circle-filled`],
    focused: [MeshIcons, `play-circle`],
  },
  warning: {
    default: [MeshIcons, `report-problem`],
  },
  refresh: {
    default: [MeshIcons, `refresh`],
  },
  gift: {
    default: [MeshIcons, `gift`],
  },
  follow: {
    default: [Feather, `user-plus`],
  },
  unFollow: {
    default: [Feather, `user-minus`],
  },
  people: {
    default: [MaterialIcons, `people-outline`],
  },
  create2: {
    default: [Feather, `edit-3`],
  },
  'align-center': {
    default: [Feather, `align-center`],
  },
  'align-left': {
    default: [Feather, `align-left`],
  },
};

// returns the right form for Font.loadAsync

export const getAllIconFonts = () => {
  // collect a set of all elements referenced by IconTable
  const classSet = new Set(
    Array.prototype.concat.apply(
      [],
      Object.keys(IconTable).map((k1) => Object.keys(IconTable[k1]).map((k2) => IconTable[k1][k2][0])),
    ),
  );
  // create an object that contains all the ...element.font elements merged
  const classes = Array.from(classSet);
  const fonts = classes.reduce((prev, curr) => Object.assign(prev, curr.font), {});
  return fonts;
};

// entry[0] is the element class, entry[1] is the name property
// may return a different value if focused vs not focused
// if no such entry, returns 'broken-image'
export const getIconEntry = (icon) => {
  const entry = IconTable[icon.props.name];
  if (!entry) {
    console.log(`icons.js: no entry for "${icon.props.name}"`);
    return [MaterialIcons, `broken-image`];
  }
  if (icon.props.focused && entry.focused) return entry.focused;
  return entry.default;
};
