import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Channel, DefaultGenerics } from 'stream-chat';
import { Typography } from '../../common-styles';
import { ChatNavigator, LeftDrawerNavigator } from '../../common-types/navigation-types';
import { User } from '../../common-types/types';
import { Avatar } from '../../common-ui/Avatar';
import { MeshVerifiedCheck } from '../../images';
// import { MeshVerifiedCheck } from '../../images';
import { Events, PendoTrackEvent } from '../../pendo/events';

type HeaderProps = {
  channel: Channel<DefaultGenerics>;
  members?: Pick<User, `id` | `handle` | `avatar_url` | `pronouns` | `identity_verified`>[];
  channelName?: string;
};

export const ChatHeader: React.FC<HeaderProps> = React.memo(({ channel, channelName, members = [] }) => {
  const [first] = members;
  return (
    <View style={styles.chatHeader}>
      {members?.length ? (
        <>
          <View style={{ marginRight: 8, marginLeft: 8 }}>
            <ChatAvatar members={members} channel={channel} />
          </View>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: `row`, alignItems: `center` }}>
              <ChatTitle members={members} channel={channel} channelName={channelName} />
              {first?.identity_verified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
            </View>
            <PronounLabel members={members} />
          </View>
        </>
      ) : null}
    </View>
  );
});

const ChatTitle: React.FC<HeaderProps> = ({ members = [], channel, channelName }) => {
  const navigation = useNavigation<ChatNavigator>();
  const memberHandles = members.map((user) => user.handle || `Unnamed user`);
  let generatedChannelName = memberHandles.join(`, `);
  if (memberHandles.length < 3 && generatedChannelName.length > 35) {
    generatedChannelName = `${generatedChannelName.substring(0, 35)}...`;
  }
  if (memberHandles.length >= 3) {
    const [other] = members;
    generatedChannelName = `${other.handle} and ${members.length - 1} people`;
  }
  const goToProfile = () => {
    if (!Array.isArray(members) || members.length !== 1) return;
    const [other] = members;
    const LeftDrawer = navigation.getParent()?.getParent()?.getParent<LeftDrawerNavigator>();
    if (other?.id) {
      PendoTrackEvent(Events.PROFILE, {
        username: other?.handle,
        source: `Search user list`,
        element: `Search list`,
      });
      LeftDrawer?.jumpTo(`ProfileStack`, { screen: `Profile`, params: { user_id: other.id, user: other } });
    }
  };

  return (
    <Text style={styles.chatTitle} ellipsizeMode="tail" numberOfLines={1} onPress={goToProfile}>
      {channelName || generatedChannelName || channel?.data?.name}
    </Text>
  );
};

const ChatAvatar: React.FC<Omit<HeaderProps, `channelName`>> = ({ members = [], channel }) => {
  const [nextMember, ...moreMembers] = members;
  const { image } = channel?.data || {};

  const channel_image = nextMember.avatar_url || (image as string);
  if (moreMembers.length >= 2) {
    const [otherMember] = moreMembers;
    return (
      <View style={{ marginRight: 8, top: -8, right: 8 }}>
        <Avatar user={nextMember} size={35} />
        <View style={{ position: `absolute`, top: 15, left: 20 }}>
          <Avatar user={otherMember} size={35} />
        </View>
      </View>
    );
  }
  return <Avatar border size={40} localImage={channel_image} />;
};

const PronounLabel: React.FC<Pick<HeaderProps, `members`>> = ({ members }) => {
  if (!Array.isArray(members) || members.length !== 1) return null;
  // so far chat doesn't support 3+ users
  const [user] = members;
  if (!user) return null;
  const { pronouns } = user;
  if (!pronouns?.length) return null;
  return (
    <Text style={{ ...Typography.text(`small`, `gray`, `bold`), lineHeight: 20 }} numberOfLines={1}>
      {pronouns
        ?.map((p) => p.pronoun)
        .filter((p) => p !== `Enter your own`)
        .join(`,`)}
    </Text>
  );
};

const styles = StyleSheet.create({
  chatHeader: {
    flex: 1,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    paddingBottom: 6,
    marginLeft: 0,
  },
  chatTitle: {
    ...Typography.text(`plusone`, `bold`),
    marginBottom: 1,
  },
});
