import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreAnimals = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path d="M146.183 10.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 00153 16.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 00146.471 10l-.288.006z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <Rect fill="#ECE8F6" x={0} y={0} width={163} height={98} rx={7} />
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#4722A7">
          <TSpan x={12} y={22}>
            Animals
          </TSpan>
        </Text>
        <Path
          d="M99.022 91.857c18.212-1.716 33.285-14.087 39.89-29.855 6.604-15.769-18.265-29.203-36.176-29.973C84.825 31.259 70.999 46.012 71 62.817c0 16.805 9.81 30.756 28.022 29.04z"
          fill="#4722A7"
          style={{
            mixBlendMode: `multiply`,
          }}
          transform="rotate(180 105.5 62)"
        />
        <G fillRule="nonzero">
          <Path
            d="M10.641.776C15.258-.708 24.878-1.656 29.506 13.63c0 0 13.112-.06 20.537.948 8.667 1.175 8.889 5.064 12.45 6.92 8.35 4.35 14.477 4.83 14.507 5.78.09 2.949-12.342-.626-13.865-1.126 0 0 2.333 6.355-.834 20.8a1.078 1.078 0 01-1.128.84l-1.537-.104a1.07 1.07 0 01-1.004-1.06v-8.055a.714.714 0 00-.758-.707l-.024.001a.713.713 0 00-.677.663l-.526 8.172c-.036.56-.507.996-1.075.996h-1.183c-.579 0-1.054-.45-1.077-1.021l-.51-12.818s-18.123 4.276-25.45 4.043l-1.086 9.997a1.071 1.071 0 01-1 .947l-.556.037a1.076 1.076 0 01-1.119-.809l-2.164-8.743a.717.717 0 00-.74-.54l-.107.007a.714.714 0 00-.675.663l-.533 8.429a1.07 1.07 0 01-.963.99l-1.09.114a1.075 1.075 0 01-1.174-.871L11.2 20.642S.091 12.27 5.471 4.522c1.24-1.786 3.084-3.075 5.17-3.746"
            fill="#9B7368"
            transform="matrix(-1 0 0 1 155 42)"
          />
          <Path
            d="M37.965 28.351c-.207-1.919-2.332-2.957-3.974-1.975-2.197 1.316-4.85 3.65-1.14 5.883 3.744 2.253 5.418-1.09 5.114-3.908"
            fill="#F7D3BB"
            transform="matrix(-1 0 0 1 155 42)"
          />
          <Path
            d="M14.944 2.718c-.182.82-.752 1.386-1.273 1.266-.522-.121-.797-.883-.615-1.702.182-.82.752-1.386 1.273-1.266.522.12.797.883.615 1.702M10.964 7.477c-.078-.094-.258-.129-.402-.078-.01.004-1.034.361-2.006.143a2.07 2.07 0 01-1.312-.887l-.054-.08 1.436-1.04c.175-.125.065-.328-.193-.353l-1.781-.18c-.239-.023-.433.124-.363.274l.575 1.225-.131.118c-.014.012-1.392 1.22-3.33.733-.151-.038-.324.012-.383.111-.059.1.017.213.17.251 1.763.443 3.13-.254 3.713-.639.366.419.856.699 1.46.834.294.066.589.091.866.091.87 0 1.577-.247 1.616-.26.144-.051.197-.17.119-.263"
            fill="#010101"
            transform="matrix(-1 0 0 1 155 42)"
          />
          <Path
            d="M19.914 18.485C22.36 21.353 25.908 11.451 27 8.146 25.436 5.753 23.663 4.106 21.826 3c-.88 2.328-4.493 12.46-1.912 15.485"
            fill="#6D4038"
            transform="matrix(-1 0 0 1 155 42)"
          />
          <Path
            d="M41.522 21.647c4.514 6.564 12.104-2.297 11.437-6.051-1.22-.453-2.73-.829-4.666-1.076-1.769-.226-3.863-.394-6.024-.52-1.977 1.248-3.559 3.557-.747 7.647"
            fill="#F7D3BB"
            transform="matrix(-1 0 0 1 155 42)"
          />
          <Path
            d="M3.002 11c-1.236 1.56-4.215 5.925-2.464 9.863C2.185 24.569 4.144 18.307 5 15.04 4.195 13.846 3.455 12.473 3.002 11"
            fill="#6D4038"
            transform="matrix(-1 0 0 1 155 42)"
          />
        </G>
      </G>
    </Svg>
  );
};
