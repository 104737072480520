import * as React from 'react';
import Svg, { G, Path, Circle } from 'react-native-svg';

export const SelfieHand = (props) => {
  return (
    <Svg width="90px" height="90px" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M36.55 89.785c23.755-2.574 43.416-21.13 52.03-44.782C97.194 21.35 64.757 1.199 41.395.043 18.032-1.112-.001 21.018 0 46.226c.001 25.207 12.796 46.133 36.55 43.56z"
          fill="#4FCEDD"
          style={{
            mixBlendMode: `multiply`,
          }}
          transform="rotate(180 45 45)"
        />
        <G transform="translate(10.035 12.583)" fillRule="nonzero">
          <Path
            d="M17.542 57.338l-.57 11.153s24.115-23.387 27.12-30.23c3.004-6.843-2.651-23.77-2.651-23.77l-12.548 7.023-11.351 35.824zM55.295 10.491l3.401.357s1.782 4.286-3.725 4.643l.324-5zM55.295 17.491l3.401.357s1.782 4.286-3.725 4.643l.324-5zM55.295 24.491l3.401.357s1.782 4.286-3.725 4.643l.324-5zM54.295 32.491l3.401.357s1.782 4.286-3.725 4.643l.324-5z"
            fill="#9F616A"
          />
          <Path
            d="M55.971 11.848h-.297V4.021A4.44 4.44 0 0054.292.818a4.818 4.818 0 00-3.335-1.327H33.689A4.818 4.818 0 0030.353.818a4.44 4.44 0 00-1.382 3.203v42.94a4.44 4.44 0 001.382 3.204 4.818 4.818 0 003.336 1.326h17.268c2.605 0 4.717-2.028 4.717-4.53V17.42h.297v-5.572z"
            fill="#3F3D56"
          />
          <Path
            d="M53.971 3.898v43.186c0 .904-.35 1.77-.972 2.41a3.279 3.279 0 01-2.349.997H34.292c-.88 0-1.725-.359-2.348-.998a3.453 3.453 0 01-.973-2.409V3.898c0-.903.35-1.77.973-2.409a3.279 3.279 0 012.348-.998h1.986c-.199.5-.142 1.067.15 1.515.294.447.785.716 1.31.716h9.327c.525 0 1.016-.27 1.309-.717.293-.447.35-1.015.152-1.514h2.124c.881 0 1.726.36 2.349.998.623.64.972 1.506.972 2.41z"
            fill="#F1F1F1"
          />
          <Path
            fill="#000"
            opacity={0.2}
            transform="rotate(-20.222 57.128 11.408)"
            d="M55.9452481 11.3502271L58.3114798 11.3502271 58.3114798 11.4660615 55.9452481 11.4660615z"
          />
          <Path
            fill="#000"
            opacity={0.2}
            transform="rotate(-20.222 57.128 18.126)"
            d="M55.9452481 18.0678171L58.3114798 18.0678171 58.3114798 18.1836515 55.9452481 18.1836515z"
          />
          <Path
            fill="#000"
            opacity={0.2}
            transform="rotate(-20.222 57.128 25.307)"
            d="M55.9452469 25.2486891L58.3114786 25.2486891 58.3114786 25.3645235 55.9452469 25.3645235z"
          />
          <Path
            fill="#000"
            opacity={0.2}
            transform="rotate(-25.639 56.54 32.338)"
            d="M55.9402651 32.2804389L57.139335 32.2804389 57.139335 32.3953605 55.9402651 32.3953605z"
          />
          <Path fill="#9F616A" d="M34.97138 30.620271L33.910682 33.4030731 31.97138 38.4913473 31.97138 29.4913473z" />
          <Path
            d="M49.971 17.95c0 4.133-3.581 3.508-8 3.508-4.418 0-8 .625-8-3.508s1.75-11.459 8-11.459c6.466 0 8 7.326 8 11.459z"
            fill="#2F2E41"
          />
          <Path
            d="M53.659 32.298l.312 1.962v13.525a3.45 3.45 0 01-.447 1.706l-3.14-5.529-1.13-11.579-.283-2.892 4.255.09.433 2.717z"
            fill="#9F616A"
          />
          <Path
            d="M53.971 31.793a253.27 253.27 0 00-.674-2.17c-.498-1.575-1.106-3.439-1.468-4.327-.689-1.696-4.202-1.016-4.202-1.016l-12.035-.453-2.467-.328c-.846-.113-1.583 1.077-2.154 2.5v4.475l2.805.214c-.226.79-.355 1.604-.385 2.425-.032 1.123.165 2.379 1.018 3.157 1.84 1.683 2.43 9.26 2.43 9.26l-1.293 4.961H50.65c.881 0 1.726-.35 2.349-.976a3.34 3.34 0 00.972-2.358V45.32c-.953-1.548-1.735-2.327-1.735-2.327l-2.702-10.669 4.136-.072.301-.006v-.452z"
            fill="#2F2E41"
          />
          <Circle fill="#9F616A" transform="rotate(-45 42.356 15.437)" cx={42.3560228} cy={15.4366707} r={5.66651781} />
          <Path
            d="M35.971 14.491h2.216l.977-2.769.196 2.77h1.059l.57-1.616.114 1.615h7.868c0-3.313-2.654-6-5.93-6h-1.14c-3.275 0-5.93 2.687-5.93 6z"
            fill="#2F2E41"
          />
          <Path
            d="M-.029 63.21l20.075-22.974.717-14.753 7.533-12.594 2.152-5.398s5.022.54.897 10.795l-1.704 5.487c1.536 3.319 1.174 6.933 1.174 10.592V46.94c0 2.961-12.908 19.089-14.545 21.552L-.029 63.21z"
            fill="#9F616A"
          />
          <Path
            fill="#000"
            opacity={0.2}
            d="M30.208586 13.4913473L28.97138 12.8384235 29.0135379 12.746234 30.1565817 13.3495725 31.8820931 8.49134733 31.97138 8.5279973z"
          />
          <Circle fill="#EA236C" cx={43.97138} cy={46.4913473} r={3} />
          <Circle fill="#FFF" cx={36.47138} cy={45.9913473} r={1.5} />
          <Path
            d="M35.971 48.491a2 2 0 110-4 2 2 0 010 4zm0-3.833a1.833 1.833 0 100 3.667 1.833 1.833 0 000-3.667z"
            fill="#CBCBCB"
          />
          <G fill="#E5E5E5">
            <Path
              d="M1.93 2H0L.91.04C.92.015.941 0 .964 0s.044.015.055.04l.61 1.315.03.063.27.582z"
              transform="translate(34.971 44.491)"
            />
            <Path
              d="M3 2H1.337l.322-.582.024-.042.42-.759a.081.081 0 01.06-.04.076.076 0 01.072.04L3 2z"
              transform="translate(34.971 44.491)"
            />
          </G>
          <Circle fill="#FFF" cx={51.47138} cy={45.9913473} r={1.5} />
          <Path
            d="M50.971 48.491a2 2 0 110-4 2 2 0 010 4zm0-3.833a1.833 1.833 0 100 3.667 1.833 1.833 0 000-3.667z"
            fill="#CBCBCB"
          />
          <Path
            d="M50.795 45.05l-.07.073v-.525a.105.105 0 00-.103-.107h-1.547a.103.103 0 00-.09.053.109.109 0 000 .107.103.103 0 00.09.053h1.444v.42l-.07-.073a.1.1 0 00-.113-.023.106.106 0 00-.064.098v.005c0 .028.01.055.03.075l.247.254a.102.102 0 00.146 0l.246-.254c.02-.02.03-.047.03-.075v-.005a.106.106 0 00-.063-.098.1.1 0 00-.113.023zM50.868 46.279h-1.444v-.42l.07.073a.1.1 0 00.113.023.106.106 0 00.064-.098v-.005a.108.108 0 00-.03-.076l-.247-.254a.102.102 0 00-.146 0l-.246.254a.108.108 0 00-.03.076v.005c0 .043.025.082.063.098a.1.1 0 00.112-.023l.07-.073v.526c0 .059.047.106.104.106h1.547c.037 0 .071-.02.09-.053a.109.109 0 000-.106.103.103 0 00-.09-.053z"
            fill="#E5E5E5"
          />
          <Path fill="#FFF" d="M32.97138 5.44134733H37.97138V6.44134733H32.97138z" />
          <Path fill="#FFF" d="M40.97138 5.44134733H45.97138V6.44134733H40.97138z" />
          <Path fill="#FFF" d="M48.97138 5.44134733H53.97138V6.44134733H48.97138z" />
          <Path fill="#FFF" d="M32.97138 40.4413473H37.97138V41.4413473H32.97138z" />
          <Path fill="#FFF" d="M40.97138 40.4413473H45.97138V41.4413473H40.97138z" />
          <Path fill="#FFF" d="M48.97138 40.4413473H53.97138V41.4413473H48.97138z" />
        </G>
      </G>
    </Svg>
  );
};
