import Svg, { Ellipse, G, Path } from 'react-native-svg';
import React from 'react';

export const MeshVerifiedCheck = (props) => {
  return (
    <Svg width="16px" height="14px" viewBox="0 0 16 14" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Ellipse fill="#57C7D7" cx={6.85714286} cy={7} rx={6.85714286} ry={7} />
        <Path
          d="M14.229 1.478a1.02 1.02 0 011.467 0c.38.389.404 1.004.071 1.422l-.07.08-7.61 7.792a1.02 1.02 0 01-1.389.073l-.078-.073L3.16 7.23a1.08 1.08 0 010-1.502 1.02 1.02 0 011.389-.073l.078.073 2.725 2.79 6.876-7.04z"
          fill="#EA236C"
          style={{
            mixBlendMode: `multiply`,
          }}
        />
      </G>
    </Svg>
  );
};
