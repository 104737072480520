//import { NetworkStatus } from 'apollo-client';
import { useQuery, useMutation } from 'react-apollo';
import { View, FlatList } from 'react-native';
import React, { useState, useEffect, useCallback } from 'react';
import { unionBy } from 'lodash';
import { Colors } from '../common-styles';
import { LoadingIndicator } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { ModerationIssue } from './ModerationIssue';
import { PAGESIZE } from './ModerateCommunityDetail';
import {
  refetchQueriesFor,
  APPROVE_POST,
  APPROVE_JOIN_REQUEST,
  REJECT_JOIN_REQUEST,
  GET_MODERATION_ISSUES_BY_CATEGORY,
} from '../graphql';
import { MODERATION_CATEGORY } from './moderationEnums';

const sectionTitles = {
  abuse: `Reports`,
  post_approve: `Post Approvals`,
  user_report: `User Reports`,
  community_report: `Community Reports`,
  community_application: `Community Applications`,
  mesh_verification: `Mesh Verification`,
};

export const ModerationCategoryList = ({ navigation, route }) => {
  const { group, category, status = `open` } = route.params;
  const [queryInFlight, setQueryInFlight] = useState(0);
  const [issues, setIssues] = useState([]);

  const getQueryInput = () => {
    const mod_category_input = {
      group_id: group.id,
      status,
      category,
    };
    return {
      input: mod_category_input,
      limit: PAGESIZE,
      offset: 0,
    };
  };

  const { data, _error, loading, fetchMore, networkStatus } = useQuery(GET_MODERATION_ISSUES_BY_CATEGORY, {
    variables: getQueryInput(),
    notifyOnNetworkStatusChange: true,
  });
  const [rejectJoin, responseReject] = useMutation(REJECT_JOIN_REQUEST, {
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `Group`, `GroupMember`),
  });
  const [approveJoin, responseApprove] = useMutation(APPROVE_JOIN_REQUEST, {
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `Group`, `GroupMember`),
  });
  const [approvePost, responseApprovePost] = useMutation(APPROVE_POST, {
    refetchQueries: refetchQueriesFor(`ModerationIssue`, `Activity`),
  });

  useEffect(() => {
    console.log(`====> Effect #1: Update current list of issues`);
    const issues = data?.getModerationIssuesByGroup4;
    console.log(`found:`, issues?.length || 0);
    if (issues) setIssues(issues);
  }, [data]);

  useEffect(() => {
    console.log(`====> Effect #2: queryInFlight state change`);
    const queryInFlight = responseApprovePost?.loading && responseApprove?.loading && responseReject?.loading;
    setQueryInFlight(queryInFlight);
  }, [responseApprovePost?.loading, responseApprove?.loading, responseReject?.loading]);

  const onEndReached = useCallback(() => {
    if (!loading) {
      console.log(`fetching more from ${issues.length}`);
      fetchMore({
        variables: { offset: issues.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.getModerationIssuesByGroup4?.length) return prev;
          const prevIssues = prev.getModerationIssuesByGroup4;
          const moreIssues = fetchMoreResult.getModerationIssuesByGroup4;
          //const newCursor = fetchMoreResult?.getModerationIssuesByGroup4?.slice(-1)[0].id;
          return {
            getModerationIssuesByGroup4: unionBy(prevIssues, moreIssues, (issue) => issue.id),
          };
        },
      });
    }
  }, [loading, fetchMore, issues]);

  const renderModerationIssues = useCallback(
    ({ item }) => {
      if (item) {
        let actions = [];
        if (item.category === MODERATION_CATEGORY.community_join_request) {
          actions = [approveJoin, rejectJoin];
        } else if (item.category === MODERATION_CATEGORY.approvePost) {
          actions = [approvePost, null];
        }
        return (
          <ModerationIssue
            navigation={navigation}
            group={group}
            modIssue={item}
            actions={actions}
            queryInFlight={queryInFlight}
          />
        );
      }
      return null;
    },
    [navigation, group, approvePost, approveJoin, rejectJoin, queryInFlight],
  );

  const renderFooter = useCallback(() => {
    if (!loading) return null;
    return (
      <View style={{ flex: 1, justifyContent: `center`, padding: 16 }}>
        <LoadingIndicator />
      </View>
    );
  }, [loading]);

  console.log({ loading, networkStatus });
  return (
    <View style={localStyles.container}>
      <FlatList
        data={issues}
        renderItem={renderModerationIssues}
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        ListFooterComponent={renderFooter}
      />
    </View>
  );
};

ModerationCategoryList.navigationOptions = ({ route }) => {
  const { category } = route.params;
  return {
    headerTitle: category && sectionTitles[category],
  };
};

ModerationCategoryList.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const localStyles = {
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
};
