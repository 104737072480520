export const AvatarTestIds = {
  screen: `AVATARSCREEN`,
  skip: `AVATARSCREEN_SKIP`,
  continue: `AVATARSCREEN_CONTINUE`,
};

export const DOBTestIds = {
  screen: `BIRTHSCREEN`,
  date: `BIRTHSCREEN_DATE`,
  done: `BIRTHSCREEN_DONE`,
  modal: `BIRTHSCREEN_MODAL`,
  error: `BIRTHSCREEN_ERROR`,
  continue: `BIRTHSCREEN_CONTINUE`,
};

export const InterestsTestIds = {
  screen: `INTERESTSCREEN`,
  option: `INTERESTSCREEN_OPTION`,
  continue: `INTERESTSCREEN_CONTINUE`,
};

export const ReferralsTestIds = {
  screen: `REFERRALSCREEN`,
  skip: `REFERRALSCREEN_SKIP`,
  input: `REFERRALSCREEN_INPUT`,
  warning: `REFERRALSCREEN_WARNING`,
  continue: `REFERRALSCREEN_CONTINUE`,
};

export const NameTestIds = {
  screen: `NAMESCREEN`,
  name: `NAMESCREEN_NAME`,
  continue: `NAMESCREEN_CONTINUE`,
};

export const PronounTestIds = {
  screen: `PRONOUNSCREEN`,
  option: `PRONOUNSCREEN_OPTION`,
  input: `PRONOUNSCREEN_INPUT`,
  skip: `PRONOUNSCREEN_SKIP`,
  continue: `PRONOUNSCREEN_CONTINUE`,
};

export const UsernameTestIds = {
  screen: `USERNAMESCREEN`,
  username: `USERNAMESCREEN_USERNAME`,
  error: `USERNAMESCREEN_ERROR`,
  continue: `USERNAMESCREEN_CONTINUE`,
};
export const AgreementTestIds = {
  screen: `AGREEMENTSCREEN`,
  agree: `AGREEMENTSCREEN_AGREE`,
  continue: `AGREEMENTSCREEN_CONTINUE`,
  got_it: `AGREEMENT_GOT_IT`,
};
