import React, { useEffect, useState } from 'react';
import { Slider, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
// TODO: Replace RN-slider after testflight build
//import Slider from '@react-native-community/slider';
import { Colors } from '../common-styles';
import { MediaTypes } from './helpers';

type DateDurationType = {
  contentMode: string;
  onChangeDuration: (duration: number) => void;
};

const DateDuration: React.FC<DateDurationType> = ({ contentMode, onChangeDuration }) => {
  const [duration, setDuration] = useState(2);

  useEffect(() => {
    onChangeDuration(duration);
  }, [duration]);

  if (contentMode !== MediaTypes.POLL) return null;

  return (
    <View style={styles.sliderWrapper}>
      <View>
        <Text style={styles.title}>Poll ends in {duration} days</Text>
      </View>

      <Slider
        minimumValue={1}
        maximumValue={7}
        minimumTrackTintColor={Colors.brandPurple}
        step={1}
        value={duration}
        onValueChange={(value) => setDuration(value)}
        style={{ marginHorizontal: 20 }}
      />
      <View style={styles.stepNumbers}>
        {[1, 2, 3, 4, 5, 6, 7].map((item: number) => (
          <TouchableOpacity key={item} onPress={() => setDuration(item)} hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}>
            <Text style={styles.stepNumber}>{item}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sliderWrapper: {
    paddingLeft: 20,
    paddingRight: 20,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: Colors.lightGray,
    borderBottomColor: Colors.lightGray,
    paddingVertical: 10,
  },
  title: {
    fontSize: 15,
    fontWeight: `600`,
  },
  stepNumbers: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `space-between`,
    paddingHorizontal: 25,
  },
  stepNumber: {
    color: Colors.brandPurple,
    fontSize: 12,
  },
});

export default DateDuration;
