import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Text, ListRenderItem } from 'react-native';
import { useDispatch } from 'react-redux';
import { Typography } from '../common-styles';
import { LeftDrawerNavigator } from '../common-types/navigation-types';
import { CenterColumn } from '../common-ui';
import { formatShortRelativeDate } from '../common-util';
import { useFollow } from '../community/v2/hooks/useFollow';
import { refreshCounts, refreshFollowInfo, refreshFollowRequest } from '../redux/followSlice';
import { BaseNotificationItem } from './BaseNotificationItem';
import { PendoTrackEvent, Events } from '../pendo/events';
import { MeshVerifiedCheck } from '../images';

interface RequestedUser {
  accepted: number;
  avatar_url: string;
  handle: string;
  id: string;
  persona_id: string;
  requested_at: Date;
  isVerified?: boolean;
}

export const FollowRequestScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const routes = useRoute<any>();
  const { acceptFollowRequest, declineFollowRequest } = useFollow();

  const [lists, setLists] = useState<RequestedUser[]>([]);
  const [hideActionIndex, setHideActionIndex] = useState<number[]>([]);

  useEffect(() => {
    //TODO: what is map supposed todo here?
    routes.params?.data.map((item: RequestedUser) => ({
      ...item,
      accepted: -1,
    }));
    setLists(routes.params?.data);
  }, [routes]);

  const renderItem: ListRenderItem<RequestedUser> = useCallback(
    ({ item, index }) => {
      const { accepted, isVerified } = item;
      const renderTitle = () => {
        const actorNameText = (
          <>
            <Text maxFontSizeMultiplier={1.8} style={Typography.add(`bold`)}>
              {item.handle}
            </Text>
            {!!isVerified && <MeshVerifiedCheck height={12} width={12} style={{ marginRight: 5, marginLeft: 2 }} />}
          </>
        );

        if ((typeof accepted === `boolean` && !accepted) || (typeof accepted === `number` && accepted === -1)) {
          return (
            <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
              {actorNameText} requested to follow you
            </Text>
          );
        }

        if (typeof accepted === `number` && accepted === 1) {
          return (
            <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
              You accepted {actorNameText}'s request
            </Text>
          );
        }

        if (typeof accepted === `number` && accepted === 0) {
          return (
            <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
              You declined {actorNameText}'s request
            </Text>
          );
        }
        return null;
      };

      const handlePress = () => {
        // @ts-ignore reason: navigation id is typed as undefined for some reason
        const parentNav = navigation.getParent<LeftDrawerNavigator>(`LeftDrawer`);
        PendoTrackEvent(Events.PROFILE, {
          username: item.handle,
          source: `notifications`,
          element: `follow request`,
        });
        parentNav.jumpTo(`ProfileStack`, { screen: `Profile`, params: { user_id: item.id } });
      };

      const handleFollowRequest = async (accepted: boolean) => {
        try {
          const cloned = lists;
          const newItem = lists[index];
          if (accepted) {
            await acceptFollowRequest(item.id);
            newItem.accepted = 1;
          } else {
            await declineFollowRequest(item.id);
            newItem.accepted = 0;
          }
          setHideActionIndex((prev) => [...prev, index]);

          newItem.requested_at = new Date();

          cloned[index] = newItem;
          setLists([...cloned]);
          dispatch(refreshFollowRequest(true));
          dispatch(refreshCounts(true));
          dispatch(refreshFollowInfo(true));
        } catch (error) {
          console.error(`Error while accept/decline follow request:`, error);
        }
      };
      return (
        <CenterColumn>
          <BaseNotificationItem
            title={renderTitle()}
            date={formatShortRelativeDate(item.requested_at)}
            avatarData={item}
            navigation={navigation}
            badge="check"
            avatarSize={50}
            badgeSize={22}
            onPress={handlePress}
            follow_verb="follow_request"
            follow_status={false}
            can_follow_request_disable={true}
            handleFollowRequest={handleFollowRequest}
            hideActions={hideActionIndex.includes(index)}
          />
        </CenterColumn>
      );
    },
    [acceptFollowRequest, declineFollowRequest, setLists, lists, navigation],
  );

  return (
    <View style={styles.container}>
      <FlatList data={lists} extraData={lists} renderItem={renderItem} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
