import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  FOLLOW_USER,
  UNFOLLOW_USER,
  UPDATE_FOLLOW_NOTIFICATION,
  DECLINE_REQUEST_FOLLOW,
  ACCEPT_REQUEST_FOLLOW,
  READ_FOLLOWING_NOTIFICATION,
  UPDATE_FOLLOW_REQUEST,
  MAKE_SEEN_FOLLOWING_NOTIFICATION,
  refetchQueriesFor,
} from '../../../graphql';
import { useDispatch } from 'react-redux';
import { setPersonalFeedRefresh } from '../../../redux/feedSlice';

type FollowData = {
  userId: string;
};

type FollowNotificationData = {
  id: string;
  setting: boolean;
};

type FollowRequestData = {
  id: string;
};

type UpdateSeenNotificationData = {
  notification_id: string;
  isRead: boolean;
};

export const useFollow = () => {
  const dispatch = useDispatch();
  const [followUserMutation] = useMutation<FollowData>(FOLLOW_USER);
  const [unfollowUserMutation] = useMutation<FollowData>(UNFOLLOW_USER);
  const [updateFollowNotificationMutation] = useMutation<FollowNotificationData>(UPDATE_FOLLOW_NOTIFICATION);
  const [declineFollowRequestMutation] = useMutation<FollowRequestData>(DECLINE_REQUEST_FOLLOW);
  const [acceptFollowRequestMutation] = useMutation<FollowRequestData>(ACCEPT_REQUEST_FOLLOW);
  const [updateFollowRequestMutation] = useMutation<{ request_id: string; accepted: boolean }>(UPDATE_FOLLOW_REQUEST);
  const [readFollowingNotificationMutation] = useMutation<UpdateSeenNotificationData>(READ_FOLLOWING_NOTIFICATION);
  const [makeSeenFollowingNotificationMutation] = useMutation(MAKE_SEEN_FOLLOWING_NOTIFICATION);

  const [loading, setLoading] = useState(false);

  const followUser = useCallback(
    async (userId: string, refetchQueries = false) => {
      try {
        setLoading(true);
        await followUserMutation({
          variables: { userId },
          refetchQueries: refetchQueries && refetchQueriesFor(`Persona`, `Activity`),
        });
        dispatch(setPersonalFeedRefresh(true));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [followUserMutation, setLoading, dispatch],
  );

  const unfollowUser = useCallback(
    async (userId: string, refetchQueries = false) => {
      try {
        setLoading(true);
        await unfollowUserMutation({
          variables: { userId },
          refetchQueries: refetchQueries && refetchQueriesFor(`Persona`, `Activity`),
        });
        dispatch(setPersonalFeedRefresh(true));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [unfollowUserMutation, setLoading, dispatch],
  );

  const updateFollowNotification = useCallback(
    async (id: string, setting: boolean) => {
      try {
        await updateFollowNotificationMutation({
          variables: {
            id,
            setting,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [updateFollowNotificationMutation],
  );

  const declineFollowRequest = useCallback(
    async (id: string) => {
      try {
        await declineFollowRequestMutation({
          variables: {
            id,
          },
        });
      } catch (error: any) {
        console.error(`[Error]: ${error?.messag}`);
      }
    },
    [declineFollowRequestMutation],
  );

  const acceptFollowRequest = useCallback(
    async (id: string) => {
      try {
        await acceptFollowRequestMutation({
          variables: {
            id,
          },
        });
      } catch (error: any) {
        console.error(`[Error]: ${error?.message}`);
      }
    },
    [acceptFollowRequestMutation],
  );

  const updateFollowRequest = useCallback(
    async (request_id: string, accepted: boolean) => {
      try {
        await updateFollowRequestMutation({
          variables: {
            input: {
              request_id,
              accepted,
            },
          },
        });
      } catch (error: any) {
        console.log(`[Error]: ${error?.message}`);
      }
    },
    [updateFollowRequestMutation],
  );

  const readFollowingNotification = useCallback(
    async (notifcation_id: string, isRead: boolean) => {
      try {
        await readFollowingNotificationMutation({
          variables: {
            input: {
              notifcation_id,
              isRead,
            },
          },
        });
      } catch (error: any) {
        console.error(`[Error when updating seen following notification]: ${error?.message}`);
      }
    },
    [readFollowingNotificationMutation],
  );

  const makeSeenFollowingNotification = useCallback(async () => {
    try {
      await makeSeenFollowingNotificationMutation();
    } catch (error: any) {
      console.error(`[Error when making seen following notification]: ${error?.message}`);
    }
  }, [makeSeenFollowingNotificationMutation]);

  return {
    followUser,
    unfollowUser,
    updateFollowNotification,
    declineFollowRequest,
    acceptFollowRequest,
    readFollowingNotification,
    updateFollowRequest,
    makeSeenFollowingNotification,
    loading,
  };
};
