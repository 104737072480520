import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { Typography, Colors } from '../common-styles';

export const Tooltip = ({ message, pointer, backgroundColor }) => {
  return (
    <View>
      <View style={{ backgroundColor, padding: 8, margin: 16, borderRadius: 4 }}>
        <Text style={{ ...Typography.text(`small`, `white`, `bold`, `center`) }}>{message}</Text>
      </View>
      {pointer && (
        <View
          style={{
            ...triangleStyle.down,
            ...triangleStyle.triangle,
            position: `absolute`,
            bottom: 2,
            alignSelf: `center`,
            borderBottomColor: backgroundColor,
          }}
        />
      )}
    </View>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  pointer: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

Tooltip.defaultProps = {
  pointer: true,
  backgroundColor: Colors.darkGray,
};

const triangleStyle = {
  down: {
    transform: [{ rotate: `180deg` }],
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: `transparent`,
    borderStyle: `solid`,
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderBottomWidth: 15,
    borderLeftColor: `transparent`,
    borderRightColor: `transparent`,
    borderBottomColor: Colors.darkGray,
  },
};
