import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { WizardLayout } from './WizardLayout';
import Input from '../common-ui/inputs/Input';
import { Colors, Typography } from '../common-styles';
import { ThemedButton } from '../common-ui';
import { setCommunityEmailVerified } from '../redux/contributionSlice';
import { finishWizard } from '../redux/createCommunitySlice';
import { AppContext } from '../../AppContext';
import { useSettings } from '../common-settings/hooks/useSettings';
import { client, refetchQueriesFor, GET_EMAIL_VERIFICATION_CODE, VERIFY_EMAIL_MUTATION3 } from '../graphql';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';
import { RootState } from '../redux/store';

const GROUP_EMAIL_KEY = `group.setting.email`;

type VerificationRoute = RouteProp<HomeNavigatorScreens, `CommunityEmailVerification`>;

const CommunityEmailVerification = () => {
  const route = useRoute<VerificationRoute>();
  const { communityEmail } = useSelector((state: RootState) => state.createCommunity);
  const { groupId } = route.params || ({} as any);
  const dispatch = useDispatch();
  const navigation = useNavigation<HomeNavigator>();
  const { identity, user } = useContext(AppContext);
  const { settings } = useSettings(GROUP_EMAIL_KEY, { group_id: groupId });

  const [sentEmail, setSentEmail] = useState(false);
  const [verificationCode, setVerificationCode] = useState(``);
  const [error, setError] = useState(``);
  const [loading, setLoading] = useState(false);

  const handleSendVerificationCode = async (input: any) => {
    try {
      await client.query({
        query: GET_EMAIL_VERIFICATION_CODE,
        variables: input,
        fetchPolicy: `no-cache`,
      });
    } catch (error) {
      console.log(`Resending verification code error: `, error);
    }
  };

  const handleResentVerification = useCallback(() => {
    const verificationInput = {
      id: settings[GROUP_EMAIL_KEY]?.id,
      key: settings[GROUP_EMAIL_KEY]?.key,
      value: communityEmail,
      user_id: user?.id,
      user_name: user?.name,
      from_community: true,
      group_id: groupId,
    };
    handleSendVerificationCode(verificationInput);
  }, [settings, user, communityEmail, groupId]);

  useEffect(() => {
    if (!!settings[GROUP_EMAIL_KEY] && !!user && !!communityEmail && !sentEmail) {
      const verificationInput = {
        id: settings[GROUP_EMAIL_KEY]?.id,
        key: settings[GROUP_EMAIL_KEY]?.key,
        value: communityEmail,
        user_id: user?.id,
        user_name: user?.name,
        from_community: true,
        group_id: groupId,
      };
      handleSendVerificationCode(verificationInput);
      setSentEmail(true);
    }
  }, [settings, user, communityEmail, groupId, sentEmail]);

  const [verifyEmail] = useMutation(VERIFY_EMAIL_MUTATION3, {
    refetchQueries: refetchQueriesFor(`Setting`),
  });

  const onChangeVerificationCode = (text: string) => {
    setVerificationCode(text);
    setError(``);
  };

  const handleMoveNext = useCallback(async () => {
    if (!user?.id) return;

    setLoading(true);
    const input = {
      token: verificationCode,
      group_id: groupId,
      user_id: user.id,
      from_community: true,
      identity_id: identity?.id,
    };

    const { data } = await verifyEmail({
      variables: input,
      refetchQueries: refetchQueriesFor(`Setting`, `Group`, `GroupMember`),
    });
    const [verificationStatus] = data?.verifyEmailSetting3 || [];
    setLoading(false);

    if (verificationStatus?.value === `true`) {
      dispatch(setCommunityEmailVerified(true));
      dispatch(finishWizard());
      navigation.navigate(`HomeScreen`);
    } else {
      setError(`The verification code is wrong or expired`);
    }
  }, [user?.id, verificationCode, groupId, identity?.id, verifyEmail, dispatch, navigation]);

  const handleSkipThisStep = () => {
    dispatch(finishWizard());
    navigation.navigate(`HomeScreen`);
  };

  const isEnableSubmit = verificationCode.length === 6 && !error;

  return (
    <WizardLayout total={8} selector="createCommunity">
      <View style={styles.container}>
        <View style={styles.inputContainer}>
          <Text style={styles.title}>Enter your verification code</Text>
          <Text style={styles.description}>
            We have sent a confirmation code to {`\n`}
            {communityEmail}. This code is valid for 10 minutes.
          </Text>
          <Input
            isError={!!error}
            value={verificationCode}
            inputStyle={{ letterSpacing: 3 }}
            onChangeText={onChangeVerificationCode}
            placeholder="______"
            keyboardType="number-pad"
            maxLength={6}
          />

          {!!error && (
            <View style={{ marginBottom: 8, backgroundColor: Colors.blockUserBackgroundColor, borderRadius: 4 }}>
              <Text style={{ ...Typography.text(`red`, `bold`), paddingHorizontal: 16, paddingVertical: 8 }}>{error}</Text>
            </View>
          )}
        </View>

        <View>
          <TouchableOpacity activeOpacity={0.8} onPress={handleResentVerification} style={{ marginBottom: 12 }}>
            <Text style={styles.resendText}>Resend Verification code</Text>
          </TouchableOpacity>

          <ThemedButton
            title="Continue"
            disabled={!isEnableSubmit || loading}
            rounded
            onPress={handleMoveNext}
            titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
          />

          <TouchableOpacity activeOpacity={0.8} onPress={handleSkipThisStep} style={{ marginTop: 12 }}>
            <Text style={styles.skipText}>Skip for now &#8594;</Text>
          </TouchableOpacity>
        </View>
      </View>
    </WizardLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  inputContainer: {
    paddingVertical: 24,
    marginBottom: 60,
  },
  title: {
    fontSize: 16,
    fontWeight: `600`,
    height: 20,
  },
  description: {
    ...Typography.text(`base`, `gray`),
    marginVertical: 4,
  },
  resendText: {
    ...Typography.text(`base`, `theme`, `bold`),
  },
  skipText: {
    ...Typography.text(`base`, `theme`, `bold`),
    textAlign: `center`,
  },
});

export default CommunityEmailVerification;
