import { useCallback } from 'react';
import { Alert } from 'react-native';

interface ConfirmModalProps {
  title: string;
  body: string;
  textCancel?: string;
  textConfirm?: string;
  onConfirm: () => void;
  onDismiss?: () => void;
}

export const useConfirmModal = () => {
  const createConfirmationModal = useCallback((props: ConfirmModalProps) => {
    const { title, body = `Press OK to confirm`, onConfirm, textCancel = `Cancel`, textConfirm = `Confirm` } = props;
    Alert.alert(title, body, [
      { text: textCancel, style: `cancel` },
      {
        text: textConfirm,
        style: `default`,
        onPress: onConfirm,
      },
    ]);
  }, []);
  return createConfirmationModal;
};
