import { ScrollView, Text, TouchableOpacity, View, Image } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { Avatar, Divider, MeshIcon, NoThemeButton, ThemedButton } from '../common-ui';
import { commonPropTypes, formatRelativeDate } from '../common-util';
import { client, MESH_REJECT_IDENTITY, MESH_VERIFY_IDENTITY, refetchQueriesFor } from '../graphql';

export class MeshVerificationReview extends React.PureComponent {
  static propTypes = {
    navigation: commonPropTypes.navigation().isRequired,
    route: commonPropTypes.route().isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  //#region api calls
  verifyIdentity = async (modIssue) => {
    const { navigation } = this.props;
    try {
      const issue_id = modIssue.id;
      const { data } = await client.mutate({
        mutation: MESH_VERIFY_IDENTITY,
        variables: { issue_id },
        refetchQueries: refetchQueriesFor(`ModerationIssue`),
      });
      const verified = data.meshVerifyIdentity;
      console.log(`verified mesh identity`, verified);
      navigation.goBack();
      return verified;
    } catch (err) {
      console.error(`Error thrown in verifyIdentity()`, err.message);
      return null;
    }
  };

  rejectIdentity = async (modIssue) => {
    const { navigation } = this.props;
    try {
      const issue_id = modIssue.id;
      const { data } = await client.mutate({
        mutation: MESH_REJECT_IDENTITY,
        variables: { issue_id },
        refetchQueries: refetchQueriesFor(`ModerationIssue`),
      });
      const rejected = data.meshRejectIdentity;
      console.log(`rejected mesh identity`, rejected);
      navigation.goBack();
      return rejected;
    } catch (err) {
      console.error(`Error thrown in rejectIdentity()`, err.message);
      return null;
    }
  };
  //#endregion

  //#region render functions
  renderVerificationApplication = (modIssue) => {
    console.log(`=== rendering verification application ===`);
    const { application, reportee } = modIssue;

    return (
      <View style={{ padding: 16, backgroundColor: Colors.white }}>
        <View>
          <Text style={{ ...Typography.text(`base`, `black`), marginBottom: 8 }}>
            <Text>Name:{` `}</Text>
            <Text style={{ ...Typography.text(`base`, `bold`, `black`) }}>{reportee && reportee.name.trim()}</Text>
          </Text>
          {reportee && reportee.pronouns && reportee.pronouns.length > 0 && this.renderPronouns(reportee.pronouns)}
        </View>
        <View style={{ alignItems: `center` }}>
          {application && application.photo ? (
            <Image source={{ uri: application && application.photo }} style={{ height: 343, width: 344, borderRadius: 20 }} />
          ) : (
            <View
              style={{
                height: 343,
                width: 344,
                borderRadius: 20,
                backgroundColor: Colors.backgroundColor,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <Text style={{ ...Typography.text(`small`, `gray`) }}>No image</Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  renderPronouns = (pronouns) => {
    return (
      <View style={{ flexDirection: `row`, alignItems: `center` }}>
        {pronouns.map((p, i) => {
          return (
            <Text style={{ ...Typography.text(`base`, `gray`, `bold`), marginBottom: 16 }}>
              {i ? `, ` : null}
              {p.pronoun}
            </Text>
          );
        })}
      </View>
    );
  };
  //#endregion

  render = () => {
    const { navigation, route } = this.props;
    const { moderationIssue = {} } = route.params;
    const { reportee, created_at } = moderationIssue;
    return (
      <View style={{ backgroundColor: Colors.white }}>
        <ScrollView contentContainerStyle={{ justifyContent: `center` }}>
          <View style={{ justifyContent: `center` }}>
            <View style={{ flexDirection: `row`, justifyContent: `space-between`, padding: 16 }}>
              <Text style={{ ...Typography.text(`base`, `black`, `bold`) }}>Mesh Verification</Text>
              <View style={{ padding: 4, paddingHorizontal: 12, borderRadius: 99, backgroundColor: `rgba(44,154,68,0.1)` }}>
                <Text style={{ ...Typography.text(`small`, `bold`, `center`), color: `#2C9A44` }}>New</Text>
              </View>
            </View>
            <Divider style={{ width: `100%` }} />
            <View style={{ flexDirection: `row`, alignItems: `center`, padding: 16, backgroundColor: Colors.lightWarmGray }}>
              <Avatar navigation={navigation} user={reportee} size={40} />
              <View style={{ marginLeft: 8 }}>
                <Text style={{ ...Typography.text(`base`, `bold`), color: Colors.brandPurple }}>
                  {reportee && reportee.handle.trim()}
                </Text>
                <Text style={{ ...Typography.text(`small`, `gray`) }}>{formatRelativeDate(created_at)}</Text>
              </View>
              <TouchableOpacity activeOpacity={0.8} style={{ position: `absolute`, right: 16 }}>
                <View
                  style={{
                    padding: 8,
                    borderColor: Colors.brandPurple,
                    borderRadius: 99,
                    borderWidth: 1,
                    alignItems: `center`,
                    justifyContent: `center`,
                  }}
                >
                  <Text style={{ ...Typography.text(`base`, `bold`, `center`), color: Colors.brandPurple }}>Message</Text>
                </View>
              </TouchableOpacity>
            </View>
            <Divider style={{ width: `100%` }} />
            {this.renderVerificationApplication(moderationIssue)}
          </View>
        </ScrollView>
        <View style={localStyles.buttonContainer}>
          <NoThemeButton
            rounded
            color="rgba(234,35,35,0.15)"
            title="Deny"
            titleStyle={localStyles.denyButtonTitle}
            leftIcon={<MeshIcon name="dislike" color={Colors.brightRed} size={16} />}
            containerStyle={{ flex: 1, marginLeft: 16, marginRight: 13 }}
            buttonStyle={{ paddingVertical: 12 }}
            onPress={() => this.rejectIdentity(moderationIssue)}
          />
          <ThemedButton
            rounded
            title="Approve"
            titleStyle={localStyles.approveButtonTitle}
            leftIcon={<MeshIcon name="like" color={Colors.white} size={16} />}
            containerStyle={{ flex: 1, marginRight: 16 }}
            buttonStyle={{ paddingVertical: 12 }}
            onPress={() => this.verifyIdentity(moderationIssue)}
          />
        </View>
      </View>
    );
  };
}

const localStyles = {
  buttonContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    backgroundColor: Colors.white,
    paddingVertical: 16,
  },
  denyButtonTitle: {
    color: Colors.brightRed,
    marginLeft: 9,
    fontSize: 15,
    fontWeight: `500`,
    fontFamily: `inter-semibold`,
  },
  approveButtonTitle: {
    marginLeft: 9,
    fontFamily: `inter-semibold`,
    fontSize: 15,
    fontWeight: `500`,
  },
};
