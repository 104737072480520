import { useState, useEffect, useContext } from 'react';
import { useLazyQuery, useSubscription } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { NOTIFICATIONS_PER_FETCH } from '../../constants';
import { AppContext } from '../../AppContext';
import {
  client,
  GET_MODERATION_NOTIFICATION_COUNT,
  NOTIFICATION_QUERY,
  FOLLOWING_NOTIFICATION_QUERY,
  NOTIFICATION_SUBSCRIPTION,
  FOLLOW_NOTIFICATION_SUBSCRIPTION,
  PERSONA_SUBSCRIPTION,
  GET_PERSONA,
} from '../graphql';
import { useChatService } from '../chat/hooks/useChatService';
import { refreshNotification } from '../redux/followSlice';

export const useMeshSubscription = () => {
  const dispatch = useDispatch();
  const { user, _updateModerationCount, setModerationCount, setNotificationCount, setFollowNotificationCount, setUser } =
    useContext(AppContext);

  const { resubscribe, currentSubscriber, setCurrentSubscriber } = useResubscribe();

  const { registerForChat, disconnectChat } = useChatService();

  //#region  Common Notification Query+subscription
  const [getNotifications] = useLazyQuery(NOTIFICATION_QUERY, {
    onCompleted: (notificationsData) => {
      try {
        const [notifications] = notificationsData?.GetNotificationsV2 || [];
        console.log(`useMeshSubscription->notifications.unseen`, notifications?.unseen);
        console.log(`useMeshSubscription->notifications.unread`, notifications?.unread);

        setNotificationCount(notifications?.unseen || 0);
      } catch (error) {
        console.error(`[NotificationError]: ${error.message}`);
      }
    },
    onError: (error) => console.warn(`Error fetching to notifications:`, error.message),
    client,
  });

  const [getFollowNotifications] = useLazyQuery(FOLLOWING_NOTIFICATION_QUERY, {
    onCompleted: (notificationsData) => {
      try {
        const [notifications] = notificationsData?.getFollowingNotifications?.data || [];
        console.log(`useMeshSubscription->follow notifications.unseen`, notifications?.unseen);

        const unSeenLength = notifications?.unseen;
        setFollowNotificationCount(unSeenLength || 0);
      } catch (error) {
        console.error(`[Follow Notification Error]: ${error.message}`);
      }
    },
    onError: (error) => console.warn(`Error fetching to Following Notifications:`, error.message),
    client,
  });

  const [getPersona] = useLazyQuery(GET_PERSONA, {
    onCompleted: (data) => {
      try {
        setUser(data?.getPersona);
      } catch (error) {
        console.error(`Error>>>`);
      }
    },
    onError: (error) => console.warn(`error>>>>>>>>>>`, error?.message),
    client,
  });

  const { data: subscriptionData } = useSubscription(NOTIFICATION_SUBSCRIPTION, {
    variables: { persona_id: currentSubscriber },
    shouldResubscribe: resubscribe,
    skip: !currentSubscriber,
    client,
    onError: (error) => console.warn(`Error subscribing to notifications: ${error.message}`),
  });

  const { data: followSubscriptionData } = useSubscription(FOLLOW_NOTIFICATION_SUBSCRIPTION, {
    variables: { persona_id: currentSubscriber },
    client,
  });
  //#endregion

  //#region Get Persona Subscription : It is needed when user is banned from Mesh Moderation
  const { data: personaData } = useSubscription(PERSONA_SUBSCRIPTION, {
    variables: {
      id: currentSubscriber,
    },
    client,
    onSubscriptionComplete: () => {
      console.log(`persona subscription...`);
    },
  });

  //#endregion

  //#region  Moderation Notification Query+subscription
  const [getModNotifications] = useLazyQuery(GET_MODERATION_NOTIFICATION_COUNT, {
    client,
    fetchPolicy: `no-cache`,
    onCompleted: (moderationData) => {
      const { count = 0 } = moderationData?.getModerationNotificationCount || {};
      setModerationCount(count);
    },
    onError: (error) => console.warn(`Error fetching to moderation:`, error.message),
  });

  //#endregion

  // Effect #1: onChange user: fetch ands subscribe
  useEffect(() => {
    //subscribe user to notifications, pass null for unsubscribe
    if (currentSubscriber !== user?.id) setCurrentSubscriber(user?.id);
    else if (currentSubscriber && resubscribe) {
      getModNotifications();
      getNotifications({
        variables: {
          input: {
            feed_slug: `notification`,
            actor_id: currentSubscriber,
          },
          limit: NOTIFICATIONS_PER_FETCH,
        },
      });
      getFollowNotifications({
        variables: {
          filter: {
            limit: NOTIFICATIONS_PER_FETCH,
          },
        },
      });
      getPersona({
        variables: {
          id: user?.id,
        },
      });
    }
  }, [
    user?.id,
    currentSubscriber,
    resubscribe,
    setCurrentSubscriber,
    getModNotifications,
    getNotifications,
    getFollowNotifications,
    getPersona,
  ]);

  // Effect #2: load user Notifications
  useEffect(() => {
    const loadNotifications = async () => {
      if (!subscriptionData?.StreamNotificationSubscription) return;
      let notificationsV2Data = [];
      const variables = { input: { feed_slug: `notification`, actor_id: currentSubscriber }, limit: NOTIFICATIONS_PER_FETCH };
      const cachedNotifications = client.readQuery({ query: NOTIFICATION_QUERY, variables });
      if (!cachedNotifications) {
        // we haven't queried notifications yet, let's fetch them and hydrate the apollo cache
        console.log(`=== no cached notifications data yet -- fetching... ===`);
        const { data } = await client.query({ query: NOTIFICATION_QUERY, variables });
        notificationsV2Data = data?.GetNotificationsV2;
      } else {
        console.log(`=== cached notifications exist ===`);
        notificationsV2Data = cachedNotifications?.GetNotificationsV2;
      }
      if (followSubscriptionData?.getNewFollowingNotificationCount?.count) {
        setFollowNotificationCount(followSubscriptionData?.getNewFollowingNotificationCount?.count);
      }
      if (personaData?.getPersona) {
        setUser(personaData?.getPersona);
      }
      if (subscriptionData.StreamNotificationSubscription?.verb !== null) {
        const newNotificationItem = subscriptionData.StreamNotificationSubscription;
        /**
         * stream seems to aggregate notifications based on the last 107 characters of id,
         * the first 9 of the id are unique to that individual notification item, it is then
         * put into the activity_group (notification item) to aggregate.
         * We need to substring and match the last 107 characters to properly filter && display notifications
         */
        const filtered = notificationsV2Data.filter((n) => n.id.substring(9, 116) !== newNotificationItem.id.substring(9, 116));
        const new_list = [newNotificationItem, ...filtered];

        client.writeQuery({ query: NOTIFICATION_QUERY, variables, data: { GetNotificationsV2: new_list } });

        /*
        The Above client.writeQuery works fine. But Flatlist(NotificationList.jsx-> FlatList) does not update even extraData is changed.
        It should be resolved without using force refreshing by Dispatch.
        */
        dispatch(refreshNotification(true));
        setNotificationCount(newNotificationItem.unseen);
      } else {
        client.writeQuery({
          NOTIFICATION_QUERY,
          variables,
          data: {
            GetNotificationsV2:
              notificationsV2Data?.filter((n) => n.id !== subscriptionData.StreamNotificationSubscription.id) || [],
          },
        });
      }
    };
    if (currentSubscriber) loadNotifications();
  }, [subscriptionData, currentSubscriber, personaData, setNotificationCount, setFollowNotificationCount, setUser]);

  // Effect #4: subscribe to chat (Channels and PushNotifications)
  useEffect(() => {
    // note: !resubscribe means user has changed
    if (user?.id && !resubscribe) {
      disconnectChat();
      registerForChat();
    } else if (!user?.id) {
      disconnectChat();
    }
  }, [user?.id, resubscribe, currentSubscriber?.id, registerForChat, disconnectChat]);

  return { subscribe: setCurrentSubscriber };
};

const useResubscribe = () => {
  const [currentSubscriber, setCurrentSubscriber] = useState();
  const [prev, setPrev] = useState();
  const [resubscribe, setResubscribe] = useState(false);

  useEffect(() => {
    if (currentSubscriber && !resubscribe) {
      // user logged in
      setResubscribe(true);
      if (!prev) setPrev(currentSubscriber);
      console.log(`useMeshSubscription -> subscribing user ${currentSubscriber}`);
    } else if (resubscribe && (!currentSubscriber || currentSubscriber !== prev)) {
      // user switched out or logout
      setResubscribe(false);
      setPrev(currentSubscriber);
      console.log(`useMeshSubscription -> stopping subscription of ${prev}`);
    }
  }, [currentSubscriber, prev, resubscribe]);
  return { currentSubscriber, resubscribe, setCurrentSubscriber };
};

/*

const { data: modSubscriptionData } = useSubscription(MODERATOR_SUBSCRIPTION, {
  variables: { persona_id: currentSubscriber },
  shouldResubscribe: resubscribe,
  fetchPolicy: `no-cache`,
  skip: !currentSubscriber,
  client,
  onError: (error) => console.warn(`Error subscribing to moderation:`, error.message),
});

Effect #3: load user moderation notifications
useEffect(() => {
  // check to see if this was an added report or action was taken and we need to decrement the count
  const { action_taken = MODERATION_ACTIONS.no_action } = modSubscriptionData?.moderationSubscription || {};
  let sign = 1;
  // if action_taken exists then that means this report was closed / acted upon so decrement from moderation count
  if (action_taken === MODERATION_ACTIONS.no_action) return;
  if (action_taken !== MODERATION_ACTIONS.report_reopen) sign = -1;
  updateModerationCount(sign);
}, [modSubscriptionData, updateModerationCount]);

*/
