import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Colors, Typography } from '../common-styles';
import { BottomSheetModal } from '../common-ui/BottomSheetModal';
// import { StarImage } from '../images/StarImage';
import { StarImage } from '../images/StarImage';

export const ThanksForContributing = () => {
  const navigation = useNavigation();
  const [showModal, setShowModal] = useState(true);

  const onCancel = () => setShowModal(false);
  const onConfirm = () => {
    setShowModal(false);
    navigation.goBack();
  };
  return (
    <View style={{ flex: 1, backgroundColor: Colors.white, paddingHorizontal: 16 }}>
      <BottomSheetModal title="" visible={showModal} onPressCancel={onCancel} confirmTitle="Close" onPressConfirm={onConfirm}>
        <View style={{ justifyContent: `center`, flexDirection: `row` }}>
          <StarImage />
        </View>
        <Text style={{ marginTop: 26, marginHorizontal: 18, ...Typography.text(`center`, `bold`, `large`, `black`) }}>
          Thank you for tipping!
        </Text>
        <Text style={{ marginTop: 9, marginHorizontal: 54, ...Typography.text(`center`, `base`) }}>
          We just texted you a receipt. Your tip helps your community grow.
        </Text>
      </BottomSheetModal>
    </View>
  );
};
