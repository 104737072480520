import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { View, TouchableWithoutFeedback, Dimensions } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import PostReactions from './PostReactions';
import PostText from './PostText';
import { Spacing } from '../../common-styles';
import { setActionToTake } from '../../redux/feedSlice';
import { ACTIONS_TO_TAKE } from '../hooks/useLike';
import { classifyActivity } from '../helpers';
import { Activity } from '../../common-types/types';
import { PostReactedUsers } from './PostReactedUsers';

const areEqualFooter = (prev: any, next: any) => {
  const areEqualActivities = prev?.activity?.id === next?.activity?.id;
  const areEqualLikeCount = prev?.activity?.reaction_counts?.like === next?.activity?.reaction_counts?.like;
  const areEqualCommentCount = prev?.activity?.reaction_counts?.comment === next?.activity?.reaction_counts?.comment;
  const areEqualUpdated = prev?.activity?.updated_at === next?.activity?.updated_at;
  const areEqualMemberAgreed =
    prev?.activity?.origin_group_member?.has_agreed === next?.activity?.origin_group_member?.has_agreed;
  const areEqualCanParticipate =
    prev?.activity?.origin_group_member?.can_participate === next?.activity?.origin_group_member?.can_participate;
  const areEqualCanPost = prev?.activity?.origin_group_member?.can_post === next?.activity?.origin_group_member?.can_post;
  const areEqualCanInvite = prev?.activity?.origin_group_member?.can_invite === next?.activity?.origin_group_member?.can_invite;
  const areEqualBanned = prev?.activity?.origin_group_member?.banned === next?.activity?.origin_group_member?.banned;
  const areEqualClick = prev?.onPressComment === next?.onPressComment;

  const prevReactionCounts = prev?.activity?.custom_reaction_counts?.reduce((total: number, item: any) => total + item.count, 0);
  const nextReactionCounts = next?.activity?.custom_reaction_counts?.reduce((total: number, item: any) => total + item.count, 0);
  const areEqualReactionCounts = prevReactionCounts === nextReactionCounts;
  const areEqualLiked = prev?.activity?.latest_reactions?.like === next?.activity?.latest_reactions?.like;

  return (
    areEqualActivities &&
    areEqualLikeCount &&
    areEqualUpdated &&
    areEqualCommentCount &&
    areEqualMemberAgreed &&
    areEqualCanParticipate &&
    areEqualCanPost &&
    areEqualCanInvite &&
    areEqualBanned &&
    areEqualReactionCounts &&
    areEqualClick &&
    areEqualLiked
  );
};

type PostFooterProps = {
  activity: Activity;
  selectable?: boolean;
  onPressComment: any;
  fromPersonalFeed?: boolean;
};

const PostFooter: React.FC<PostFooterProps> = React.memo(({ activity, onPressComment, fromPersonalFeed = false }) => {
  const navigation = useNavigation<any>();
  const route = useRoute();
  const { id, origin_group_id: group_id, content } = activity || {};
  const isMediaActivity =
    classifyActivity(activity) !== `text` && classifyActivity(activity) !== `link` && !!activity.content?.trim();
  const isPostDetails = route?.name === `PostDetail`;
  const dispatch = useDispatch();

  // Right can be PostReactions or blank
  const onContainerPress = useCallback(() => {
    if (isPostDetails) return;
    if (id && group_id) {
      navigation.navigate(`PostDetail`, {
        id,
        group_id,
        isPersonalFeed: activity.__typename === 'ProfileActivity',
        fromPersonalFeed,
      });
    }
  }, [navigation, isPostDetails, id, group_id]);

  const onCantInteract = useCallback(() => {
    dispatch(setActionToTake(ACTIONS_TO_TAKE.JOIN));
    if (route?.name !== `PostDetail`) {
      navigation.navigate(`PostDetail`, {
        id,
        group_id,
        addComment: true,
        isPersonalFeed: activity.__typename === 'ProfileActivity',
        fromPersonalFeed,
      });
    }
  }, [dispatch, navigation, route?.name, id, group_id]);

  const textContent = useMemo(() => {
    if (!!content && content.startsWith(`http`)) {
      return content.replace(`https://`, ``).replace(`http://`, ``);
    }
    return content;
  }, [content]);

  return (
    <TouchableWithoutFeedback onPress={onContainerPress}>
      <View style={{ flexDirection: `column`, paddingBottom: 3 }}>
        <PostReactions activity={activity} onCantInteract={onCantInteract} onPressComment={onPressComment} />
        <PostReactedUsers activity={activity} />

        {!!content && !!isMediaActivity && (
          <PostText
            activity={activity}
            isMediaActivity={isMediaActivity}
            width={Math.min(Spacing.standardWidth, Dimensions.get(`window`).width)}
            text={textContent}
            overflowMode="fit"
            startExpanded={false}
            meta_style={{}}
            onLayout={() => {}}
            onPress={onContainerPress}
            selectable={false}
          />
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}, areEqualFooter);

export default PostFooter;
