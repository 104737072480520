import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text, TextInput, useWindowDimensions, View } from 'react-native';
import Gestures from 'react-native-easy-gestures-new';
import { ITexter } from '../components/PostContentMeme';
import OutsidePressHandler from 'react-native-outside-press';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Colors } from '../../common-styles';

export type IMoveStyle = {
  left: number;
  top: number;
  transform: any;
};

type IMemeText = {
  index: number;
  focusedTexterIndex: number;
  onSelected: (index: number) => void;
  onMove: (index: number, style: IMoveStyle) => void;
  texter: ITexter;
  handleEditor: (show: boolean) => void;
  onSetTypeText: () => void;
  showEditor: boolean;
};

const TextIndicator: React.FC<{ children: any; zIndex: number }> = ({ children, zIndex }) => {
  return (
    <View>
      {Array.from(Array(4)).map((_, idx) => {
        return (
          <View
            key={idx}
            style={{
              position: `absolute`,
              width: 8,
              height: 8,
              borderRadius: 4,
              backgroundColor: Colors.deepPurple,
              left: idx % 4 === 0 || idx % 4 === 2 ? 0 : 'auto',
              top: idx % 4 === 0 || idx % 4 === 1 ? 0 : 'auto',
              right: idx % 4 === 1 || idx % 4 === 3 ? 0 : 'auto',
              bottom: idx % 4 === 2 || idx % 4 === 3 ? 0 : 'auto',
              margin: -3.5,
              zIndex,
            }}
          />
        );
      })}
      {children}
    </View>
  );
};

const MemeText: React.FC<IMemeText> = ({
  index,
  onSelected,
  onMove,
  texter,
  focusedTexterIndex,
  handleEditor,
  onSetTypeText,
  showEditor,
}) => {
  const inputRef = useRef<TextInput>(null);
  const textRef = useRef<any>(null);
  const { width } = useWindowDimensions();
  const [text, setText] = useState(``);
  const { isMemeTexterEditorClicked } = useSelector((state: RootState) => state.post);

  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentLeft, setCurrentLeft] = useState(0);

  const customTransform = useMemo(() => {
    const rotate = texter.transform[0]?.rotate || texter.transform[1]?.rotate;
    const scale = texter.transform[1]?.scale || texter.transform[0]?.scale;
    return { rotate, scale };
  }, [texter]);

  useEffect(() => {
    textRef.current = isMemeTexterEditorClicked;
  }, [isMemeTexterEditorClicked]);

  if (focusedTexterIndex === index) {
    if (showEditor)
      return (
        <Gestures
          scalable
          draggable
          rotatable
          rotate={customTransform.rotate || `0deg`}
          scale={customTransform.scale || 1}
          onChange={(e, s: IMoveStyle) => {
            onMove(index, s);
          }}
          style={{
            position: `absolute`,
            left: texter.left,
            top: texter.top,
            zIndex: 9 + index,
          }}
        >
          <OutsidePressHandler
            onOutsidePress={() => {
              setTimeout(() => {
                if (!textRef.current) {
                  onSelected(-1);
                }
              }, 100);
            }}
            disabled={false}
          >
            <TextIndicator zIndex={10 + index}>
              <Text
                style={{
                  fontSize: texter.fontSize,
                  color: texter.color,
                  textAlign: texter.aligned ? `center` : `left`,
                  fontFamily: `futura`,
                  borderWidth: 0.5,
                  borderStyle: `dotted`,
                }}
                onPress={() => {
                  onSelected(index);
                  handleEditor(false);
                }}
              >
                {text}
              </Text>
            </TextIndicator>
          </OutsidePressHandler>
        </Gestures>
      );
    else {
      return (
        <TextInput
          autoFocus
          style={{
            fontSize: texter.fontSize,
            color: texter.color,
            fontFamily: `futura`,
            zIndex: 9,
            left: currentLeft,
            top: texter.top,
            transform: texter.transform,
          }}
          value={text}
          onChangeText={setText}
          onBlur={() => {
            onSelected(-1);
            handleEditor(false);
            onMove(focusedTexterIndex, {
              left: width - currentWidth > 20 ? (width - currentWidth) / 2 : 0,
              top: texter.top,
              transform: texter.transform,
            });
          }}
          multiline
          textAlignVertical="top"
          ref={inputRef}
          selectionColor={texter.color}
          textAlign={texter.aligned ? `center` : `left`}
          maxLength={250}
          onContentSizeChange={(event) => {
            setCurrentWidth(event.nativeEvent.contentSize.width);
          }}
          onTouchStart={() => {
            onSelected(index);
          }}
        />
      );
    }
  }

  return (
    <View
      style={{
        position: `absolute`,
        left: texter.left,
        top: texter.top,
        transform: texter.transform,
        zIndex: 9 + index,
      }}
      onTouchStart={() => {
        if (focusedTexterIndex < 0) onSelected(index);
        handleEditor(true);
        onSetTypeText();
        setCurrentLeft(0);
      }}
    >
      <Text
        style={{
          fontSize: texter.fontSize,
          color: texter.color,
          textAlign: texter.aligned ? `center` : `left`,
          fontFamily: `futura`,
        }}
      >
        {text}
      </Text>
    </View>
  );
};

export default MemeText;
