import { useNavigation } from '@react-navigation/native';
import { Linking } from 'react-native';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { Colors } from '../../common-styles';
import { Activity, GroupCoreFields, GroupMember, BottomSheetOptions } from '../../common-types/types';
//import { BottomSheetOptions } from '../../common-ui';
import { withPlayerRole } from '../../community/v2/helpers';
import { MODERATION_TYPE } from '../../moderation/moderationEnums';
import { RootState } from '../../redux/store';
import { owner, manager, creator, member, moderator } from '../../community/memberInfoHelpers';
import { useSettings } from './useSettings';
import { loadCachedGroupMember } from '../../graphql';
import { DELETED_USER } from '../../../constants';
import { MenuTestIds, PostTestIds, ModerationTestIds } from './testLabels';

const OWNER = `owner`;
const CL = `manager`;
const MOD = `moderator`;
const CR = `creator`;
const MEM = `member`;
const NONE = `none`;
const ALL = [OWNER, CL, MOD, CR, MEM];

type CommunityBottomSheet = {
  /** Callback to generate another modal  */
  leaveCommunity: () => void;
  changeCommunityAvi?: () => void;
  joinCommunity?: () => void;
  groupMember: GroupMember | null | undefined;
  currentCommunity: GroupCoreFields | null;
  isGuestMode?: boolean;
};

type ActivityPostBottomSheet = {
  activity: Activity;
  groupMember: GroupMember | null | undefined;
  handlePinPost: () => void;
  deletePost: () => void;
  handleFollowUser: (isFollowed: boolean) => void;
};

type MemberListBottomSheet = {
  groupMember: GroupMember;
  selectedMember: GroupMember;
  multiOwners?: boolean;
  handleCreateConversation: () => void;
  handleFollow: (isFollowed: boolean, actor: string) => void;
  handleViewProfile: () => void;
  handleBan: () => void;
  handleUnban: () => void;
  handleDemoteOrPromote: (role: string) => void;
  handleRemoveGroupMember: () => void;
  handleReport: (type: string, id: string | null) => void;
  handleReportCommunity: () => void;
  handleLeaveCommunity: () => void;
};

type PersonalFeedBottomSheet = {
  activity: Activity;
  handleFollowUser: (isFollwed: boolean) => void;
  deletePost: () => void;
  hidePost?: () => void;
  handleTurnoffComment?: () => void;
};
/**
 * This hook attemps to build the options for the bottom sheet. If you want to build more options for different bottom sheets.
 * You can use this hook and add another build<YourComponent>Options function.
 * @param navigation
 * @returns
 */
export const useBottomSheetOptions = (toggle: () => void) => {
  const navigation = useNavigation<any>();
  const { user } = useContext(AppContext);
  const { activeCommunity, previewCommunity } = useSelector((state: RootState) => state.feed);
  const { userNotifySetting, updateNotifyPostSetting } = useSettings();

  /**
   * Create the options for the bottom sheet displayed when the user press
   * the three dot menu in the CommunityHeader.
   * @param BuildOptions Object with the params to use in the BottomSheetOptions.
   * @returns Array of options
   */
  const buildCommunityModalOptions = useCallback(
    ({ leaveCommunity, changeCommunityAvi, currentCommunity, groupMember, isGuestMode }: CommunityBottomSheet) => {
      const { can_participate } = groupMember || {};
      const { role_name: roleName } = groupMember || {};
      if (!roleName || !currentCommunity) return []; // We need the role in order to show the correct options.
      const { id } = currentCommunity;
      const options: BottomSheetOptions[] = isGuestMode
        ? [
            {
              // disabled: !banned,
              roles: new Set(ALL),
              title: `About`,
              iconName: `info-circle`,
              testID: MenuTestIds.about,
              onPress: () => {
                toggle();
                navigation.navigate(`AboutScreen`, { group: activeCommunity, group_member: groupMember });
              },
            },
          ]
        : [
            {
              // disabled: !banned,
              roles: new Set(ALL),
              title: `About`,
              iconName: `info-circle`,
              testID: MenuTestIds.about,
              onPress: () => {
                toggle();
                navigation.navigate(`AboutScreen`, { group: activeCommunity, group_member: groupMember });
              },
            },
            {
              // disabled: !banned,
              roles: new Set(ALL),
              title: `Topics`,
              iconName: `star`,
              testID: MenuTestIds.about,
              onPress: () => {
                toggle();
                navigation.navigate(`CommunityTopics`, { group: activeCommunity, group_member: groupMember });
              },
            },
            {
              roles: new Set(ALL),
              title: `View affiliates`,
              iconName: `eye`,
              testID: MenuTestIds.members,
              onPress: () => {
                toggle();
                navigation.navigate(`AffiliatedCommunitiesHomeList`, { group_member: groupMember });
              },
            },
            {
              visible: !!can_participate,
              roles: new Set(ALL),
              title: `Members and roles`,
              iconName: `group`,
              testID: MenuTestIds.members,
              onPress: () => {
                navigation.navigate(`MemberInfo`, { groupId: currentCommunity?.id });
                toggle();
              },
            },
            {
              roles: new Set([OWNER, CL]),
              title: `Edit avatar`,
              iconName: `profile`,
              testID: MenuTestIds.members,
              onPress: () => {
                if (changeCommunityAvi) changeCommunityAvi();
              },
            },
            {
              roles: new Set([OWNER, CL]),
              title: `Edit banner`,
              iconName: `image`,
              onPress: () => {
                toggle();
                navigation.push(`EditCommunityBanner`, { group: activeCommunity });
              },
            },
            {
              roles: new Set(ALL),
              title: `Code of Conduct`,
              iconName: `sparkle`,
              testID: MenuTestIds.coc,
              onPress: () => {
                toggle();
                navigation.navigate(`CommunityCodeOfConduct`, {
                  group: activeCommunity,
                  group_member: groupMember,
                  toAgree: true,
                });
              },
            },
            {
              roles: new Set([OWNER, CL]),
              visible: !!can_participate,
              title: `Membership application`,
              iconName: `list`,
              testID: MenuTestIds.application,
              onPress: () => {
                toggle();
                navigation.navigate(`CreateCommunityApplication`, { group: activeCommunity });
              },
            },
            {
              roles: new Set([OWNER, CL, MOD]),
              title: `Settings`,
              iconName: `settings`,
              testID: MenuTestIds.settings,
              onPress: () => {
                toggle();
                navigation.navigate(`CommunitySettings`, { group: activeCommunity, group_member: groupMember });
              },
            },
            {
              roles: new Set([OWNER, MOD, CR, CL]),
              title: `Get Verified`,
              iconName: `user-verification`,
              testID: MenuTestIds.settings,
              onPress: () => {
                toggle();
                Linking.openURL(`https://forms.gle/W3TkJwKvp4SKbdDx5`);
              },
            },
            {
              roles: new Set([CL, MOD, CR, MEM]),
              title: `Report`,
              iconName: `flag`,
              testID: MenuTestIds.report,
              onPress: () => {
                toggle();
                setTimeout(() => {
                  navigation.navigate(`ReportScreen`, {
                    reportType: MODERATION_TYPE.community,
                    origin_group_id: id,
                  });
                }, 500);
              },
              iconColor: Colors.alertColor,
              titleStyle: { color: Colors.alertColor },
            },
            {
              roles: new Set([MEM]),
              title: `Leave`,
              iconName: `logout`,
              testID: MenuTestIds.leave,
              onPress: leaveCommunity,
              iconColor: Colors.alertColor,
              titleStyle: { color: Colors.alertColor },
            },
          ];
      return options.filter(({ roles }) => roles?.has(roleName)) as BottomSheetOptions[];
    },
    [activeCommunity, navigation, toggle],
  );
  /**
   * Create the options for the bottom sheet displayed when the user press
   * the three dot menu in the CommunityPreview Header.
   * @param BuildOptions Object with the params to use in the BottomSheetOptions.
   * @returns Array of options
   */
  const buildPreviewModalOptions = useCallback(
    ({ leaveCommunity, joinCommunity, isGuestMode }: CommunityBottomSheet) => {
      const groupMember = loadCachedGroupMember(previewCommunity?.id, user?.id) as GroupMember | undefined;
      const { role_name: roleName = NONE, can_participate = false } = groupMember || {};
      if (!previewCommunity) return []; // We need the Community to show the correct options.
      const { id } = previewCommunity;
      const options: BottomSheetOptions[] = isGuestMode
        ? [
            {
              roles: new Set([...ALL, NONE]),
              title: `About`,
              iconName: `info-circle`,
              testID: MenuTestIds.about,
              onPress: () => {
                toggle();
                navigation.navigate(`AboutScreen`, { group: previewCommunity, group_member: groupMember });
              },
            },
            {
              roles: new Set(ALL),
              title: `View affiliates`,
              iconName: `eye`,
              testID: MenuTestIds.members,
              onPress: () => {
                toggle();
                navigation.navigate(`AffiliatedCommunitiesHomeList`);
              },
            },
            {
              roles: new Set([NONE]),
              title: `Join`,
              iconName: `logout`,
              testID: MenuTestIds.join,
              onPress: joinCommunity,
            },
          ]
        : [
            {
              roles: new Set([...ALL, NONE]),
              title: `About`,
              iconName: `info-circle`,
              testID: MenuTestIds.about,
              onPress: () => {
                toggle();
                navigation.push(`AboutScreen`, { group: previewCommunity, group_member: groupMember });
              },
            },
            {
              roles: new Set(ALL),
              title: `Code of Conduct`,
              iconName: `sparkle`,
              testID: MenuTestIds.coc,
              onPress: () => {
                toggle();
                navigation.navigate(`CommunityCodeOfConduct`, {
                  group: previewCommunity,
                  group_member: groupMember,
                  toAgree: true,
                });
              },
            },
            {
              roles: new Set([OWNER, CL]),
              visible: !!can_participate,
              title: `Membership application`,
              iconName: `list`,
              testID: MenuTestIds.application,
              onPress: () => {
                toggle();
                navigation.navigate(`CreateCommunityApplication`, { group: previewCommunity });
              },
            },
            {
              roles: new Set([OWNER, CL]),
              title: `Settings`,
              iconName: `settings`,
              testID: MenuTestIds.settings,
              onPress: () => {
                toggle();
                navigation.navigate(`CommunitySettings`, { group: previewCommunity, group_member: groupMember });
              },
            },
            {
              disabled: user?.isBanned,
              roles: new Set([NONE]),
              title: `Join`,
              iconName: `logout`,
              testID: MenuTestIds.join,
              onPress: joinCommunity,
            },
            {
              roles: new Set([OWNER, MOD, CR, CL]),
              title: `Get Verified`,
              iconName: `user-verification`,
              testID: MenuTestIds.settings,
              onPress: () => {
                toggle();
                Linking.openURL(`https://forms.gle/W3TkJwKvp4SKbdDx5`);
              },
            },
            {
              roles: new Set([CL, MOD, CR, MEM, NONE]),
              title: `Report`,
              iconName: `flag`,
              testID: MenuTestIds.report,
              onPress: () => {
                toggle();
                setTimeout(() => {
                  navigation.navigate(`ReportScreen`, { reportType: MODERATION_TYPE.community, origin_group_id: id });
                }, 200);
              },
              iconColor: Colors.alertColor,
              titleStyle: { color: Colors.alertColor },
            },
            {
              roles: new Set([MEM]),
              title: `Leave`,
              iconName: `logout`,
              testID: MenuTestIds.leave,
              onPress: leaveCommunity,
              iconColor: Colors.alertColor,
              titleStyle: { color: Colors.alertColor },
            },
          ];

      return options.filter(({ roles }) => roles?.has(roleName)) as BottomSheetOptions[];
    },
    [previewCommunity, navigation, userNotifySetting, updateNotifyPostSetting, toggle, user?.id],
  );

  /**
   * Create the options for the bottom sheet displayed when the user press
   * the three dot menu in the Activity - Post
   * @param BuildOptions Object with the params to use in the BottomSheetOptions.
   * @returns Array of options
   */
  const buildPostModalOptions = useCallback(
    // eslint-disable-next-line complexity
    ({ activity, handlePinPost, handleFollowUser, deletePost, groupMember }: ActivityPostBottomSheet) => {
      const { frozen, origin_group, pinned, user: author, actor, as_community, poll } = activity;
      const { can_participate } = groupMember || {};
      const { role_name: roleName } = groupMember || {};
      const { follow_request } = author || {};
      const isVoted = poll?.options?.reduce((result, item) => result || item?.voted, false);

      let followText = `Follow`;
      if (follow_request) followText = follow_request?.accepted ? `Unfollow` : `Remove follow request for`;
      // if (!roleName) return []; // in the community preview this will be null
      const options: BottomSheetOptions[] =
        !groupMember || !can_participate
          ? [
              {
                visible: !as_community && user?.id !== actor && author?.handle !== DELETED_USER.handle,
                roles: new Set([...ALL, NONE]),
                title: `${followText} ${author?.handle}`,
                iconName: follow_request === null ? `follow` : `unFollow`,
                disabled: false,
                testID: PostTestIds.followUser,
                onPress: () => {
                  toggle();
                  handleFollowUser(!!follow_request);
                },
              },
              {
                visible: user?.id !== actor,
                roles: new Set([...ALL, NONE]),
                title: `Report post`,
                iconName: `flag`,
                disabled: false,
                titleStyle: { color: Colors.alertColor },
                iconColor: Colors.alertColor,
                testID: PostTestIds.postReport,
                onPress: () => {
                  toggle();
                  navigation.navigate(`ReportScreen`, {
                    reportType: MODERATION_TYPE.post,
                    role_name: roleName,
                    id: activity?.id,
                    origin_group_id: origin_group?.id,
                    reportee: actor,
                  });
                },
              },
              {
                visible: !as_community && user?.id !== actor && author?.handle !== DELETED_USER.handle,
                roles: new Set([...ALL, NONE]),
                title: `Report ${author?.handle}`,
                iconName: `flag`,
                disabled: false,
                titleStyle: { color: Colors.alertColor },
                iconColor: Colors.alertColor,
                testID: PostTestIds.userReport,
                onPress: () => {
                  toggle();
                  navigation.navigate(`ReportScreen`, {
                    reportType: MODERATION_TYPE.user,
                    reportee: actor,
                    role_name: roleName,
                    origin_group_id: origin_group?.id,
                    reporteeName: author?.handle,
                  });
                },
              },
            ]
          : [
              {
                visible: actor === user?.id && !isVoted,
                roles: new Set(ALL),
                title: `Edit post`,
                iconName: `create`,
                testID: PostTestIds.edit,
                disabled: frozen,
                onPress: () => {
                  toggle();
                  navigation.navigate(`PostEditor`, { group: origin_group, activity, mode: `edit` });
                },
              },
              {
                visible: true,
                roles: new Set([OWNER, CL, MOD]),
                title: pinned ? `Unpin post` : `Pin post`,
                iconName: `pin`,
                testID: PostTestIds.pin,
                disabled: frozen,
                onPress: () => {
                  toggle();
                  handlePinPost();
                },
              },
              {
                visible: true,
                roles: new Set([OWNER, CL, MOD]),
                title: `Edit topics`,
                iconName: `create`,
                testID: PostTestIds.editTopics,
                disabled: frozen,
                onPress: () => {
                  toggle();
                  setTimeout(() => {
                    navigation.navigate(`PostEditor`, { group: origin_group, activity, mode: `edit`, editTopicByAdmin: true });
                  }, 1000);
                },
              },
              {
                visible: !as_community && user?.id !== actor && author?.handle !== DELETED_USER.handle,
                roles: new Set(ALL),
                title: `${followText} ${author?.handle}`,
                iconName: follow_request === null ? `follow` : `unFollow`,
                disabled: false,
                testID: PostTestIds.followUser,
                onPress: () => {
                  toggle();
                  handleFollowUser(!!follow_request);
                },
              },
              {
                visible: actor === user?.id,
                title: `Delete post`,
                iconName: `trash`,
                roles: new Set(ALL),
                disabled: false,
                titleStyle: { color: Colors.alertColor },
                iconColor: Colors.alertColor,
                testID: PostTestIds.delete,
                onPress: deletePost,
              },
              {
                visible: actor !== user?.id && (roleName === `owner` || roleName === `manager` || roleName === `moderator`),
                title: `Delete post`,
                iconName: `trash`,
                roles: new Set(ALL),
                titleStyle: { color: Colors.alertColor },
                iconColor: Colors.alertColor,
                testID: PostTestIds.delete,
                onPress: () => {
                  toggle();
                  navigation.navigate(`DeletePostScreen`, {
                    reportType: MODERATION_TYPE.post,
                    id: activity?.id,
                    origin_group_id: origin_group?.id,
                  });
                },
              },

              {
                visible: user?.id !== actor,
                roles: new Set(ALL),
                title: `Report post`,
                iconName: `flag`,
                disabled: false,
                titleStyle: { color: Colors.alertColor },
                iconColor: Colors.alertColor,
                testID: PostTestIds.postReport,
                onPress: () => {
                  toggle();
                  navigation.navigate(`ReportScreen`, {
                    reportType: MODERATION_TYPE.post,
                    role_name: roleName,
                    id: activity?.id,
                    origin_group_id: origin_group?.id,
                    reportee: actor,
                  });
                },
              },
              {
                visible: !as_community && user?.id !== actor && author?.handle !== DELETED_USER.handle,
                roles: new Set(ALL),
                title: `Report ${author?.handle}`,
                iconName: `flag`,
                disabled: false,
                titleStyle: { color: Colors.alertColor },
                iconColor: Colors.alertColor,
                testID: PostTestIds.userReport,
                onPress: () => {
                  toggle();
                  navigation.navigate(`ReportScreen`, {
                    reportType: MODERATION_TYPE.user,
                    reportee: actor,
                    role_name: roleName,
                    origin_group_id: origin_group?.id,
                    reporteeName: author?.handle,
                  });
                },
              },
            ];
      return options.filter(({ roles, visible }) => roles?.has(roleName || NONE) && visible) as BottomSheetOptions[];
    },
    [navigation, toggle, user?.id, activeCommunity?.id],
  );

  // eslint-disable-next-line complexity
  const buildMemberListOptions = ({
    groupMember,
    selectedMember,
    multiOwners,
    handleBan,
    handleCreateConversation,
    handleFollow,
    handleDemoteOrPromote,
    handleRemoveGroupMember,
    handleReport,
    handleUnban,
    handleViewProfile,
    handleReportCommunity,
    handleLeaveCommunity,
  }: MemberListBottomSheet) => {
    const { persona, banned: target_banned, role_id: theirRole } = selectedMember;
    const me = user?.id === persona.id;
    const follow_request = persona?.follow_request;
    const { role_id: myRole } = groupMember;
    const outRankRole = withPlayerRole(myRole);
    let followText = `Follow`;
    if (follow_request) followText = follow_request?.accepted ? `Unfollow` : `Remove follow request for`;
    const options: BottomSheetOptions[] = [
      {
        visible: !me,
        title: `${followText} ${persona.handle}`,
        iconName: !follow_request ? `plus` : `minus`,
        testID: follow_request && follow_request.accepted ? PostTestIds.unfollowerUser : PostTestIds.followUser,
        onPress: () => {
          toggle();
          handleFollow(!!follow_request, persona.id);
        },
      },
      {
        visible: true,
        testID: ModerationTestIds.viewProfile,
        title: `View profile`,
        iconName: `profile`,
        onPress: handleViewProfile,
      },
      {
        visible: !me,
        title: `Message`,
        iconName: `email`,
        testID: ModerationTestIds.messages,
        onPress: () => {
          toggle();
          handleCreateConversation();
        },
      },
      {
        testID: ModerationTestIds.promoteOwner,
        visible: !me && outRankRole(theirRole) && theirRole !== owner && outRankRole(manager),
        title: `Promote to Owner`,
        iconName: `moderation`,
        onPress: () => {
          handleDemoteOrPromote(owner);
        },
      },
      {
        visible: !me && outRankRole(theirRole) && theirRole !== manager && outRankRole(moderator),
        testID: ModerationTestIds.promoteLeader,
        title: `Promote to Manager`,
        iconName: `moderation`,
        onPress: () => {
          handleDemoteOrPromote(manager);
        },
      },
      {
        testID: ModerationTestIds.promoteMod,
        visible: !me && outRankRole(moderator) && outRankRole(theirRole) && (theirRole === creator || theirRole === member),
        title: `Promote to Moderator`,
        iconName: `star`,
        onPress: () => {
          handleDemoteOrPromote(moderator);
        },
      },
      {
        testID: ModerationTestIds.promoteCreator,
        visible: !me && outRankRole(moderator) && outRankRole(theirRole) && theirRole === member,
        title: `Promote to Contributor`,
        iconName: `star`,
        onPress: () => {
          handleDemoteOrPromote(creator);
        },
      },
      {
        visible: me && myRole !== owner,
        testID: ModerationTestIds.reportCommunity,
        title: `Report`,
        iconName: `flag`,
        iconColor: Colors.alertColor,
        titleStyle: { color: Colors.alertColor },
        onPress: handleReportCommunity,
      },
      {
        testID: ModerationTestIds.unban,
        visible: !me && target_banned,
        title: `Unban user`,
        iconName: `circle-plus`,
        onPress: handleUnban,
      },
      {
        testID: ModerationTestIds.demoteManager,
        visible: multiOwners && me && theirRole === owner && myRole === owner,
        title: `Demote to Manager`,
        titleStyle: { color: Colors.alertColor },
        iconName: `minus-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          handleDemoteOrPromote(manager);
        },
      },
      {
        testID: ModerationTestIds.demoteModerator,
        visible:
          (!me && outRankRole(moderator) && outRankRole(theirRole) && theirRole === manager) || (me && outRankRole(moderator)),
        title: `Demote to Moderator`,
        titleStyle: { color: Colors.alertColor },
        iconName: `minus-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          handleDemoteOrPromote(moderator);
        },
      },
      {
        testID: ModerationTestIds.demoteCreator,
        visible:
          (!me && outRankRole(creator) && outRankRole(theirRole) && (theirRole === moderator || theirRole === manager)) ||
          (me && outRankRole(creator)),
        title: `Demote to Contributor`,
        titleStyle: { color: Colors.alertColor },
        iconName: `minus-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          handleDemoteOrPromote(creator);
        },
      },
      {
        visible:
          (!me && outRankRole(member) && outRankRole(theirRole) && theirRole !== member && !target_banned) ||
          (me && outRankRole(member)),
        testID: ModerationTestIds.demoteMember,
        title: `Demote to Member`,
        titleStyle: { color: Colors.alertColor },
        iconName: `minus-circle`,
        iconColor: Colors.alertColor,
        onPress: () => {
          handleDemoteOrPromote(member);
        },
      },
      {
        visible: !me && outRankRole(theirRole) && !target_banned,
        testID: ModerationTestIds.kick,
        title: `Remove from community (can rejoin)`,
        titleStyle: { color: Colors.alertColor },
        iconName: `alert-circle`,
        iconColor: Colors.alertColor,
        onPress: handleRemoveGroupMember,
      },
      {
        testID: ModerationTestIds.ban,
        visible: !me && outRankRole(moderator) && !target_banned && theirRole !== owner,
        title: `Ban from community (can’t rejoin)`,
        titleStyle: { color: Colors.alertColor },
        iconName: `alert-circle`,
        iconColor: Colors.alertColor,
        onPress: handleBan,
      },
      {
        visible: !me && theirRole !== owner,
        testID: ModerationTestIds.report,
        title: `Report ${persona.handle}`,
        titleStyle: { color: Colors.alertColor },
        iconName: `flag`,
        iconColor: Colors.alertColor,
        onPress: () => {
          handleReport(MODERATION_TYPE.user, persona.id);
        },
      },
      {
        visible: me && ((myRole !== owner && !multiOwners) || multiOwners),
        testID: MenuTestIds.leave,
        title: `Leave Community`,
        iconName: `logout`,
        iconColor: Colors.alertColor,
        titleStyle: { color: Colors.alertColor },
        onPress: handleLeaveCommunity,
      },
    ];
    return options.filter((action) => action.visible === undefined || action.visible);
  };

  const buildPersonalFeedModalOptions = useCallback(
    ({ activity, handleFollowUser, deletePost, handleTurnoffComment }: PersonalFeedBottomSheet) => {
      const { user: author, actor, frozen, origin_group, origin_group_id } = activity;
      const isPersonalFeed = activity['__typename'] === 'ProfileActivity';
      const { follow_request } = author || {};
      let followText = `Follow`;
      if (follow_request) followText = follow_request?.accepted ? `Unfollow` : `Remove follow request for`;

      const options: BottomSheetOptions[] = [
        {
          visible: user?.id !== actor && author?.handle !== DELETED_USER.handle,
          title: `${followText} ${author?.handle}`,
          iconName: follow_request === null ? `follow` : `unFollow`,
          disabled: false,
          testID: PostTestIds.followUser,
          onPress: () => {
            toggle();
            handleFollowUser(!!follow_request);
          },
        },

        {
          visible: user?.id !== actor,
          title: `Report post`,
          iconName: `flag`,
          disabled: false,
          titleStyle: { color: Colors.alertColor },
          iconColor: Colors.alertColor,
          testID: PostTestIds.postReport,
          onPress: () => {
            toggle();
            navigation.navigate(`ReportScreen`, {
              reportType: isPersonalFeed ? MODERATION_TYPE.personal_feed : MODERATION_TYPE.post,
              id: activity?.id,
              origin_group_id: isPersonalFeed ? origin_group_id : origin_group?.id,
              reportee: actor,
            });
          },
        },
        {
          visible: user?.id !== actor && author?.handle !== DELETED_USER.handle,
          title: `Report ${author?.handle}`,
          iconName: `flag`,
          disabled: false,
          titleStyle: { color: Colors.alertColor },
          iconColor: Colors.alertColor,
          testID: PostTestIds.userReport,
          onPress: () => {
            toggle();
            navigation.navigate(`ReportScreen`, {
              reportType: MODERATION_TYPE.user,
              reportee: actor,
              origin_group_id: actor,
              reporteeName: author?.handle,
            });
          },
        },
        // TODO: Hide Post
        // {
        //   visible: user?.id !== actor && author?.handle !== DELETED_USER.handle,
        //   title: `Hide post`,
        //   iconName: `visibility-off`,
        //   disabled: false,
        //   testID: PostTestIds.hidePost,
        //   onPress: () => {
        //     toggle();
        //     hidePost();
        //   },
        // },
        {
          visible: actor === user?.id,
          title: `Edit post`,
          iconName: `create`,
          testID: PostTestIds.edit,
          disabled: frozen,
          onPress: () => {
            toggle();
            if (activity.__typename === `ProfileActivity`) {
              navigation.navigate(`PersonalPostEditor`, { activity, mode: `edit` });
            } else {
              navigation.navigate(`PostEditor`, { group: origin_group, activity, mode: `edit` });
            }
          },
        },
        {
          visible: actor === user?.id,
          title: `Delete post`,
          iconName: `trash`,
          disabled: false,
          titleStyle: { color: Colors.alertColor },
          iconColor: Colors.alertColor,
          testID: PostTestIds.delete,
          onPress: () => {
            toggle();
            deletePost();
          },
        },
        // TODO
        // {
        //   visible: actor === user?.id,
        //   title: `Turn off commenting`,
        //   iconName: `volume-x`,
        //   testID: PostTestIds.turnOffComment,
        //   disabled: frozen,
        //   onPress: () => {
        //     toggle();
        //     handleTurnoffComment();
        //   },
        // },
      ];
      return options.filter(({ visible }) => visible) as BottomSheetOptions[];
    },
    [navigation, toggle, user?.id],
  );

  return {
    buildCommunityModalOptions,
    buildPreviewModalOptions,
    buildPostModalOptions,
    buildMemberListOptions,
    buildPersonalFeedModalOptions,
  };
};
