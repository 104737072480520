import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_MODERATED_GROUPS, MODERATOR_SUBSCRIPTION } from '../../graphql';
import { MODERATION_ACTIONS } from '../moderationEnums';
import { AppContext } from '../../../AppContext';
import { Group } from '../../common-types/types';

type ModIssue = {
  id: number;
  action_taken: keyof typeof MODERATION_ACTIONS;
  group_id: string;
};

type ModGroup = {
  group: Group;
  count_open: number;
  admin_mod_count: number;
};

type ModGroupsQueryType = {
  getModeratedGroups2: ModGroup[];
  // only available on subscribeToMore->updateQuery
  moderationSubscription?: ModIssue;
};

export const useModerationGroups = () => {
  const { user } = React.useContext(AppContext);

  const { data, loading, refetch, subscribeToMore } = useQuery<ModGroupsQueryType>(GET_MODERATED_GROUPS, {
    fetchPolicy: `cache-and-network`,
  });

  React.useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: MODERATOR_SUBSCRIPTION,
      variables: { persona_id: user?.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const { moderationSubscription: newestIssue } = subscriptionData.data || {};
        const { action_taken = MODERATION_ACTIONS.no_action, group_id } = newestIssue || {};
        let sign = 1;
        // if action_taken exists then that means this report was closed / acted upon so decrement from moderation count
        if (action_taken === MODERATION_ACTIONS.no_action) return prev;
        if (action_taken !== MODERATION_ACTIONS.report_reopen) sign = -1;

        return {
          getModeratedGroups2: (prev.getModeratedGroups2 as ModGroup[])
            .map((modGroup) => {
              if (modGroup.group.id !== group_id) return modGroup;
              return {
                ...modGroup,
                count_open: modGroup.count_open + sign,
              };
              //Re-sort groups descending order
            })
            .sort((g1, g2) => g2.count_open - g1.count_open),
        };
      },
    });
    return unsubscribe;
  }, [subscribeToMore, user?.id]);

  return { moderatedGroups: data, loadingModeratedGroups: loading, modRefetch: refetch };
};
