import React, { forwardRef, useEffect } from 'react';
import { TextInput, TextInputProps, TextStyle } from 'react-native';
import { useSelector } from 'react-redux';
import { UserMention } from '../common-types/types';
import * as Colors from '../common-styles/colors';
import { useMentionInput } from './hooks/useMentionInput';
import { RootState } from '../redux/store';

type Props = TextInputProps & {
  groupId: string;
  resolvedMentions: UserMention[];
  mentionsCount?: number;
  backgroundColor: string;
  // eslint-disable-next-line no-unused-vars
  addToResolvedMentions: (mention: UserMention[]) => void;
};

const MentionTextInput = forwardRef<any, Props>((props: Props, ref) => {
  const { value, resolvedMentions, groupId, addToResolvedMentions, style, backgroundColor = Colors.white, ...rest } = props;
  const { replyingTo } = useSelector((state: RootState) => state.post);
  const { handleKeyPress, parsedText, validationError, resolveMentionsInText } = useMentionInput({
    resolvedMentions,
    value,
    set: addToResolvedMentions, // Pass a setter to the state of the parent that is controlling the TextInput.
    groupId,
    style: style as TextStyle,
    backgroundColor,
  });

  useEffect(() => {
    if (value && value.length) resolveMentionsInText(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyingTo?.id, value]);

  if (validationError) console.error(validationError);

  return (
    <TextInput ref={ref} onChange={handleKeyPress} style={style} placeholderTextColor={Colors.textPlaceholder} {...rest}>
      {parsedText}
    </TextInput>
  );
});

export { MentionTextInput };
