import React from 'react';
import { SafeAreaProvider as SACSafeAreaProvider } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import { appVersionIsAtLeast } from '../../environment';

export const SafeAreaProvider = (props) => {
  const { children } = props;
  if (!appVersionIsAtLeast(`1.0.13`)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <SACSafeAreaProvider>{children}</SACSafeAreaProvider>;
};

SafeAreaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
