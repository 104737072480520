/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */

import { createSlice } from '@reduxjs/toolkit';
import { ProfileState } from '../common-types/types';

const initialState: ProfileState = {
  currentStep: 0,
  profileIdentity: undefined,
  profileImage: undefined,
  profileName: ``,
  profileUsername: ``,
  profileDOB: undefined,
  profilePronouns: [],
  profileInterests: [],
  profileEmail: undefined,
};

const createProfileSlice = createSlice({
  name: `profile`,
  initialState,
  reducers: {
    setProfileIdentity: (state, action) => {
      state.profileIdentity = action.payload;
    },
    moveNext: (state) => {
      state.currentStep += 1;
    },
    movePrev: (state) => {
      state.currentStep -= 1;
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload;
      state.currentStep += 1;
    },
    setProfileName: (state, action) => {
      state.profileName = action.payload;
      state.currentStep += 1;
    },
    setProfileUsername: (state, action) => {
      state.profileUsername = action.payload;
      state.currentStep += 1;
    },
    setProfileDOB: (state, action) => {
      state.profileDOB = action.payload;
      state.currentStep += 1;
    },
    setProfilePronouns: (state, action) => {
      state.profilePronouns = action.payload;
      state.currentStep += 1;
    },
    setProfileEmail: (state, action) => {
      state.profileEmail = action.payload;
    },
    addPronoun: (state, action) => {
      state.profilePronouns.push(action.payload);
    },
    removePronoun: (state, action) => {
      if (state.profilePronouns.length === 0) return;
      state.profilePronouns = state.profilePronouns.filter((pro) => pro !== action.payload);
    },
    setProfileInterests: (state, action) => {
      state.profileInterests = action.payload;
    },
    addInterest: (state, action) => {
      // Max 3 categories per community
      state.profileInterests.push(action.payload as string);
    },
    removeInterest: (state, action) => {
      if (state.profileInterests.length === 0) return;
      state.profileInterests = state.profileInterests.filter((cat) => cat !== action.payload);
    },
    finishWizard: (state) => {
      state.profileImage = undefined;
      state.profileName = ``;
      state.profileUsername = ``;
      state.profileDOB = undefined;
      state.profilePronouns = [];
      state.profileInterests = [];
      state.currentStep = 0;
      state.profileIdentity = undefined;
      state.profileEmail = undefined;
    },
  },
});

export const {
  moveNext,
  movePrev,
  setProfileIdentity,
  setProfileImage,
  setProfileName,
  setProfileUsername,
  setProfileDOB,
  setProfilePronouns,
  setProfileInterests,
  setProfileEmail,
  finishWizard,
} = createProfileSlice.actions;

const profileReducer = createProfileSlice.reducer;

export { profileReducer };
