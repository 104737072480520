import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  TextInputProps,
  View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { AuthMainText, AuthSubText, BottomSheetModal, HeaderSubmitButton, KeyboardPaddingView, VisibleTag } from '../common-ui';
import { setProfileDOB } from '../redux/createProfileSlice';
import { Colors, Typography } from '../common-styles';
import { DOB_DD, DOB_MM, DOB_YYYY } from './testLabels';
import { Events, PendoTrackEvent } from '../pendo/events';
import { AuthNavigationContainer } from '../common-types/navigation-types';

type DOBNavigator = StackNavigationProp<AuthNavigationContainer, `Persona`>;

export const DOB = () => {
  const navigation = useNavigation<DOBNavigator>();

  const [mm, setMM] = useState(``);
  const [dd, setDD] = useState(``);
  const [yyyy, setYYYY] = useState(``);
  const [ageModalVisible, setAgeModalVisible] = useState(false);

  const mmRef = useRef<TextInput>(null);
  const ddRef = useRef<TextInput>(null);
  const yyyyRef = useRef<TextInput>(null);
  const ScrollRef = useRef<ScrollView>(null);

  const dispatch = useDispatch();

  const onMMChange = useCallback((t) => {
    setMM(t);
  }, []);

  const onMMKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (mm.length === 1 && e.nativeEvent.key !== `Backspace`) ddRef.current?.focus();
    },
    [mm],
  );

  const onDDChange = useCallback((t) => {
    setDD(t);
  }, []);

  const onDDKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (dd.length === 1) {
        if (e.nativeEvent.key === `Backspace`) {
          mmRef.current?.focus();
        } else {
          yyyyRef.current?.focus();
        }
      }
    },
    [dd],
  );

  const onYYYYChange = useCallback((t) => {
    setYYYY(t);
  }, []);

  const onYYYYKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (yyyy.length <= 1 && e.nativeEvent.key === `Backspace`) {
        ddRef.current?.focus();
      }
    },
    [yyyy],
  );

  const isValidDate =
    /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/.test(`${mm}/${dd}/${yyyy}`) &&
    dayjs(`${mm}/${dd}/${yyyy}`).isValid();

  const isOver13 = dayjs().diff(dayjs(`${mm}/${dd}/${yyyy}`), `months`) >= 156;

  useEffect(() => {
    mmRef.current?.focus();
  }, []);

  useEffect(() => {
    if (isValidDate && isOver13) {
      dispatch(setProfileDOB(dayjs(`${mm}/${dd}/${yyyy}`).toDate().getTime()));
    }
  }, [isValidDate, isOver13, mm, dd, yyyy, dispatch]);

  const goToNextStep = useCallback(() => {
    setAgeModalVisible(!isOver13);
    if (isOver13) {
      PendoTrackEvent(Events.DOB_CONTINUE, { birthdate: `${mm}/${dd}/${yyyy}` });
      navigation.navigate(`Persona`, { screen: `CreatingAccount` });
    }
  }, [navigation, mm, dd, yyyy, isOver13]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => null,
      headerRight: () => <HeaderSubmitButton disabled={!isValidDate} title="Continue" onPress={goToNextStep} />,
    });
  }, [navigation, isOver13, isValidDate, mm, dd, yyyy, goToNextStep]);

  return (
    <KeyboardPaddingView>
      <SafeAreaView testID="DOB" style={{ flex: 1 }}>
        <ScrollView
          testID="DOB_SCROLL"
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
          ref={ScrollRef}
          style={{
            flex: 1,
            width: `100%`,
          }}
          contentContainerStyle={{
            flex: 1,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `space-between`,
          }}
        >
          <View style={{ paddingHorizontal: 16, marginTop: 20 }}>
            <View style={localStyles.blob} />
            <AuthMainText text="What's your date of birth?" />
            <AuthSubText text="Date of birth" style={{ marginTop: 30, marginLeft: 10, marginBottom: 5 }} />

            <View
              style={{
                ...localStyles.dobContainer,
                borderColor:
                  mm.length === 2 && dd.length === 2 && yyyy.length === 4 && !isValidDate ? Colors.brightRed : Colors.brandPurple,
              }}
            >
              <DateTextInput
                ref={mmRef}
                autoFocus
                onChangeText={onMMChange}
                onKeyPress={onMMKeyPress}
                onSubmitEditing={(ev) => {
                  ev.preventDefault();
                  ddRef.current?.focus();
                }}
                placeholder="MM"
                testID={DOB_MM}
                maxLength={2}
              />
              <Text style={localStyles.delimiter}>/</Text>
              <DateTextInput
                ref={ddRef}
                onChangeText={onDDChange}
                onKeyPress={onDDKeyPress}
                onSubmitEditing={(ev) => {
                  ev.preventDefault();
                  yyyyRef.current?.focus();
                }}
                placeholder="DD"
                testID={DOB_DD}
                maxLength={2}
              />
              <Text style={localStyles.delimiter}>/</Text>
              <DateTextInput
                ref={yyyyRef}
                onChangeText={onYYYYChange}
                onKeyPress={onYYYYKeyPress}
                onSubmitEditing={(ev) => {
                  ev.preventDefault();
                  goToNextStep();
                }}
                placeholder="YYYY"
                testID={DOB_YYYY}
                maxLength={4}
              />
            </View>

            <View style={{ marginTop: 8 }}>
              <VisibleTag infoText="Your birthday cannot be changed. You can choose whether to display the month and date on your profile." />
            </View>

            {mm.length === 2 && dd.length === 2 && yyyy.length === 4 && !isValidDate && (
              <View style={localStyles.errorContainer} testID="DOB_ERROR">
                <AuthSubText
                  text="Please enter your birth date in MM/DD/YYYY format."
                  style={{ color: Colors.brightRed, fontWeight: `bold` }}
                />
              </View>
            )}

            <BottomSheetModal //first modal in the delete guidelines flow - 3 dot menu modal
              title=""
              visible={ageModalVisible}
              showCancelBtn={false}
              confirmTitle="Ok"
              onPressConfirm={() => {}}
              onPressCancel={() => setAgeModalVisible(false)}
            >
              <View style={{ padding: 20 }}>
                <AuthSubText
                  text="We're sorry, you must be at least 13 years old to become a member of mesh. Please try again once you've reached the minimum age."
                  style={{ lineHeight: 20, textAlign: `center`, color: Colors.textBlack }}
                />
              </View>
            </BottomSheetModal>
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

type DateTextInputProps = Pick<
  TextInputProps,
  `onChangeText` | `onKeyPress` | `placeholder` | `testID` | `maxLength` | `autoFocus` | `onSubmitEditing`
>;

const DateTextInput = React.forwardRef<TextInput, DateTextInputProps>(
  ({ onChangeText, onKeyPress, placeholder, testID, maxLength, autoFocus, onSubmitEditing }, ref) => {
    return (
      <TextInput
        ref={ref}
        autoFocus={autoFocus}
        style={[localStyles.input, { width: 34 }]}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmitEditing}
        onKeyPress={onKeyPress}
        placeholderTextColor={Colors.textPlaceholder}
        defaultValue=""
        keyboardType="number-pad"
        placeholder={placeholder}
        maxLength={maxLength}
        testID={testID}
      />
    );
  },
);

const localStyles = StyleSheet.create({
  dobContainer: {
    flex: 1,
    flexDirection: `row`,
    alignItems: `center`,
    borderRadius: 4,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    minHeight: 40,
    padding: 10,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(44,154,68,0.5)`,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: 10,
    top: 2,
  },
  errorContainer: {
    marginTop: 10,
    backgroundColor: `#F15B5755`,
    borderRadius: 6,
    padding: 10,
  },
  input: {
    flex: 1,
    minHeight: 50,
    fontFamily: Platform.OS === `ios` ? `Courier` : `monospace`,
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
  },
  delimiter: {
    fontFamily: Platform.OS === `ios` ? `Courier` : `monospace`,
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
    marginHorizontal: 2,
  },
});
