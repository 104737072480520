import React from 'react';
import { View, Text } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { toggleJoinMeshModal } from '../redux/guestSlice';
import { BottomSheetModal, NoThemeButton } from '.';
import { Colors, Typography } from '../common-styles';
import { AuthNavigationContainer } from '../common-types/navigation-types';

interface IGuestPromptBottomSheet {
  isVisible: boolean;
}

type PromptNavigation = StackNavigationProp<AuthNavigationContainer, `Guest`>;
type ScreenOptions = `Login` | `RegisterUsername`;

const GuestPromptBottomSheet: React.FC<IGuestPromptBottomSheet> = ({ isVisible }) => {
  const dispatch = useDispatch();
  const navigation: any = useNavigation<PromptNavigation>();

  const onToggle = () => {
    dispatch(toggleJoinMeshModal({ open: false }));
  };

  const handleNavigate = (nextScreen: ScreenOptions) => {
    dispatch(toggleJoinMeshModal({ open: false }));
    navigation.navigate(`Auth`, { screen: nextScreen });
  };

  return (
    <BottomSheetModal
      visible={isVisible}
      title=""
      showCancelBtn={false}
      showConfirmBtn={false}
      onPressCancel={onToggle}
      onPressConfirm={() => {}}
    >
      <View style={{ padding: 16 }}>
        <View style={{ marginBottom: 32 }}>
          <Text style={{ display: `flex`, flexDirection: `row`, alignItems: `center`, textAlign: `center` }}>
            <Text>Please log in to</Text>
            <Text style={{ fontWeight: `bold` }}> mesh </Text>
            <Text>or create an account to </Text>
          </Text>
          <Text style={{ textAlign: `center` }}>interact with content and access member</Text>
          <Text style={{ textAlign: `center` }}> features.</Text>
        </View>

        <NoThemeButton
          rounded
          title="Create an account"
          titleStyle={{
            ...Typography.text(`bold`, `plusone`),
            color: Colors.white,
          }}
          containerStyle={{ marginHorizontal: 16 }}
          buttonStyle={{
            paddingVertical: 10,
            alignItems: `center`,
            backgroundColor: Colors.deepPurple,
            borderColor: Colors.white,
            borderWidth: 1,
            marginBottom: 10,
          }}
          onPress={() => handleNavigate(`RegisterUsername`)}
        />
        <NoThemeButton
          rounded
          title="Log in"
          titleStyle={{
            ...Typography.text(`bold`, `plusone`),
            color: Colors.deepPurple,
          }}
          containerStyle={{ marginHorizontal: 16 }}
          buttonStyle={{
            paddingVertical: 10,
            alignItems: `center`,
            backgroundColor: Colors.white,
            borderColor: Colors.deepPurple,
            borderWidth: 1,
          }}
          onPress={() => handleNavigate(`Login`)}
        />
      </View>
    </BottomSheetModal>
  );
};

export default GuestPromptBottomSheet;
