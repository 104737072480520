import React from 'react';

import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { CreatingAccount, DOB, JoinCommunity, ProfilePhoto } from '../auth';

import { Colors } from '../common-styles';
import { headerStyle } from '../common-styles/globalStylesheet';
// @ts-ignore reason: svg import type definition does not exist
import MeshHorizontalSVG from '../../assets/images/mesh-horizontal.svg';
import { PersonaNavigationScreens } from '../common-types/navigation-types';
import { CommunityApplicationCoC, CommunityApplicationForm } from '../community-application';
import { CommunityCodeOfConductScreen } from '../community/CommunityCodeOfConductScreen';
import { BackButton } from '../common-ui';

export const personaScreenOptions = (): NativeStackNavigationOptions => ({
  contentStyle: { backgroundColor: Colors.white },
  headerStyle,
  headerTitleAlign: `center`,
  headerTitleStyle: { color: `white` },
  gestureEnabled: false,
  headerShown: true,
  headerBackVisible: false,
  headerLeft: () => null,
  headerRight: () => null,
  //@ts-ignore
  headerTitle: () => <MeshHorizontalSVG width={64} height={27} />,
});

export const PersonaNavigator = () => {
  const PersonaStack = createNativeStackNavigator<PersonaNavigationScreens>();
  return (
    //@ts-ignore
    <PersonaStack.Navigator screenOptions={personaScreenOptions} initialRouteName="DOB">
      <PersonaStack.Screen name="DOB" component={DOB} />
      <PersonaStack.Screen name="CreatingAccount" component={CreatingAccount} />
      <PersonaStack.Screen name="ProfilePhoto" component={ProfilePhoto} />
      {/*<PersonaStack.Screen name="FindFriendsScreen" component={FindFriendsScreen} />*/}
      <PersonaStack.Screen name="JoinCommunity" component={JoinCommunity} />
      <PersonaStack.Screen
        name="CommunityCodeOfConduct"
        component={CommunityCodeOfConductScreen}
        options={{
          headerLeft: () => <BackButton />,
        }}
      />
      <PersonaStack.Screen
        name="CommunityApplication"
        component={CommunityApplicationCoC}
        options={{
          headerLeft: () => <BackButton />,
        }}
      />
      <PersonaStack.Screen name="CommunityApplicationForm" component={CommunityApplicationForm} />
    </PersonaStack.Navigator>
  );
};
