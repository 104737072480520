import React from 'react';
import { View, Text, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Typography, Colors } from '../common-styles';

type WarningBoxProps = {
  text: string;
  style?: ViewStyle;
  textStyle?: TextStyle;
};

export const WarningBox: React.FC<WarningBoxProps> = ({
  text,
  style = localStyles.containerStyle,
  textStyle = localStyles.textStyle,
}) => {
  return (
    <View style={style}>
      <Text style={textStyle}>{text}</Text>
    </View>
  );
};

const localStyles = StyleSheet.create({
  containerStyle: {
    width: `100%`,
    minHeight: 32,
    padding: 9,
    borderRadius: 5,
    backgroundColor: Colors.warningBoxBackground,
  },
  textStyle: {
    ...Typography.text(`small`, `bold`, `center`),
    color: Colors.warningBoxTextColor,
  },
});
