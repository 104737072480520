import React, { useCallback, useLayoutEffect } from 'react';
import { NetworkStatus } from 'apollo-client';
import { useNavigation } from '@react-navigation/native';
import { View, Text, StyleSheet, FlatList, SafeAreaView, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { Colors, Typography } from '../common-styles';
import { Divider, NoThemeButton, BackButton, MeshIcon, ThemedButton, LoadingIndicator } from '../common-ui';
import { WizardLayout } from './WizardLayout';
import { GET_COMMUNITY_CATEGORIES } from '../graphql';
import { removeCategory, addCategory, movePrev, moveNext } from '../redux/createCommunitySlice';
import { CommunityCategoryTestIds } from './testLabels';
import { HomeNavigator } from '../common-types/navigation-types';
import { RootState } from '../redux/store';

type CategoryType = {
  label: string;
  value: string;
};

type CategoryListItemType = {
  item: CategoryType;
  onPress: (value: string) => void;
  icon: string;
  isSelected: boolean;
};

type CategoryListType = {
  categories: CategoryListItemType[];
};

// Last step of the create commmunity wizard
const CommunityCategories: React.FC = () => {
  const navigation = useNavigation<HomeNavigator>();
  const { categories } = useSelector((state: RootState) => state.createCommunity);
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(moveNext());
    navigation.navigate(`CommunityExtraSettings`);
  };

  const handlePrev = () => {
    navigation.goBack();
    dispatch(movePrev());
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `Category`,
      headerLeft: () => <BackButton onPress={handlePrev} />,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  return (
    <SafeAreaView style={styles.root}>
      <WizardLayout total={5} selector="createCommunity">
        <View style={styles.container}>
          <View style={styles.headerContainer} testID={CommunityCategoryTestIds.screen}>
            <Text style={styles.titleStyle}>Select a category</Text>
            <Text style={styles.description}>
              Select 1-3 categories that describe your node. This allows potential node members to find your page based on common
              interests.
            </Text>
          </View>
          <CategoryList categories={categories} />
          <View>
            <ThemedButton
              testID={CommunityCategoryTestIds.continue}
              title="Continue"
              disabled={categories.length < 1}
              rounded
              onPress={handleNext}
              titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
            />
          </View>
        </View>
      </WizardLayout>
    </SafeAreaView>
  );
};

const CategoryList: React.FC<CategoryListType> = ({ categories = [] }) => {
  const dispatch = useDispatch();
  const { data, loading, error, refetch, networkStatus } = useQuery(GET_COMMUNITY_CATEGORIES, {
    variables: {
      settingQuery: {
        query: `group.setting.type`,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const keyExtractor = (item: CategoryType) => item.value;

  const handlePress = useCallback(
    (value) => {
      const category = categories.find((category) => category === value);
      if (category) dispatch(removeCategory(value));
      else dispatch(addCategory(value));
    },
    [dispatch, categories],
  );

  const renderItem = useCallback(
    ({ item }) => {
      const isSelected = categories.includes(item.value);
      const isSelectedIconName = isSelected ? `circle-check` : `radio-off`;
      return <CategoryListItem item={item} icon={isSelectedIconName} isSelected={isSelected} onPress={handlePress} />;
    },
    [categories, handlePress],
  );

  if (loading || networkStatus === NetworkStatus.refetch) return <LoadingIndicator />;

  if (error) {
    return (
      <View style={styles.container}>
        <Text>{error.message}</Text>
        <NoThemeButton onPress={refetch} title="Retry" />
      </View>
    );
  }

  return (
    <View style={styles.categoryListContainer}>
      <FlatList
        data={data?.getSettingOptions[0]?.options}
        ItemSeparatorComponent={Divider}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </View>
  );
};

const areEqual = (prev: any, next: any) => {
  const equalItem = prev.item.value === next.item.value;
  const equalIcon = prev.item.icon === next.item.icon;
  const equalIsSelected = prev.isSelected === next.isSelected;
  return equalItem && equalIcon && equalIsSelected;
};

const CategoryListItem: React.FC<CategoryListItemType> = React.memo(({ item, onPress, icon, isSelected }) => {
  const handlePress = () => onPress(item.value);
  return (
    <TouchableOpacity style={styles.categoryListItem} onPress={handlePress} testID={CommunityCategoryTestIds.option}>
      <View style={styles.categoryListItemContainer}>
        <Text style={{ ...Typography.text(isSelected ? `bold` : `base`) }}>{item.label}</Text>
      </View>
      <MeshIcon name={icon as any} size={24} color={Colors.brandPurple} style={styles.marginR16} />
    </TouchableOpacity>
  );
}, areEqual);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  container: {
    flex: 1,
    padding: 16,
  },
  marginR16: {
    marginRight: 16,
  },
  categoryListContainer: {
    flex: 1,
    paddingTop: 24,
  },
  headerContainer: {
    marginTop: 32,
    marginBottom: 20,
    justifyContent: `center`,
    alignItems: `center`,
  },
  categoryListItem: {
    flexDirection: `row`,
    alignItems: `center`,
  },
  categoryListItemContainer: {
    flex: 1,
    padding: 16,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: `600`,
  },
  description: {
    paddingTop: 10,
    fontWeight: `400`,
    color: Colors.baseText,
  },
});

export default CommunityCategories;
