import PropTypes from 'prop-types';
import React from 'react';
import { Colors } from '../common-styles';
import { NoThemeButton } from './NoThemeButton';
import { ThemedButton } from './ThemedButton';

export const HeaderCancelButton = ({ onPress, testID }) => {
  return (
    <NoThemeButton testID={testID} clear title="Cancel" color={Colors.iconColor} onPress={onPress} containerStyle={{ left: 0 }} />
  );
};
HeaderCancelButton.propTypes = {
  onPress: PropTypes.func,
  testID: PropTypes.string,
};
HeaderCancelButton.defaultProps = {
  onPress: undefined,
  testID: `CANCEL`,
};

//

export const HeaderSubmitButton = ({ title, disabled, onPress, testID }) => {
  return <ThemedButton clear testID={testID} title={title} disabled={disabled} onPress={onPress} containerStyle={{ right: 0 }} />;
};
HeaderSubmitButton.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  testID: PropTypes.string,
};
HeaderSubmitButton.defaultProps = {
  testID: `SUBMIT`,
};
