import bcrypt from 'bcryptjs';
import isaac from 'isaac';
import { useContext, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import * as Sentry from '../constants/Sentry';
import { decodeSecureStore, encodeSecureStore, fetchSecureStore, setSecureStore, loginUser, signTokens } from '../auth/helpers';
import { getAsyncStorageObject, setAsyncStorageObject } from '../common-util';
import { CREATE_PERSONA_V3, MAP_PERSONA_TO_IDENTITY, refetchQueriesFor } from '../graphql';
import { AppContext } from '../../AppContext';
//import { client, CREATE_PERSONA_V3, MAP_PERSONA_TO_IDENTITY, refetchQueriesFor } from '../graphql';

bcrypt.setRandomFallback((len) => {
  const buf = new Uint8Array(len);
  return buf.map(() => Math.floor(isaac.random() * 256));
});

export const useCreatePersona = () => {
  const { identity, setUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    profileIdentity: identity_id,
    profileAvatar: avatarFile,
    profileName: name,
    profileUsername: handle,
    profileDOB,
    profilePronouns: pronouns,
  } = useSelector((state) => state.createProfile);

  const [commitCreatePersona] = useMutation(CREATE_PERSONA_V3, {
    refetchQueries: refetchQueriesFor(`Persona`),
  });
  const [mapPersonaToID] = useMutation(MAP_PERSONA_TO_IDENTITY);

  const createPersona = useCallback(
    async (interests = []) => {
      setLoading(true);
      try {
        if (!identity_id) throw new Error(`Unknown identity`);
        const genPw = uuidv4();
        const hashedPw = await bcrypt.hash(genPw, 10);

        // Fetch existing secure store (if any)
        let secureStore = await fetchSecureStore(identity_id);
        let storedPersonaCredentials = [];

        if (secureStore) {
          try {
            storedPersonaCredentials = await decodeSecureStore(secureStore);
          } catch (err) {
            console.error(`_createPersona error decoding old secure store ${err}`);
          }
        }

        // Create persona and attach it to the identity
        const dob = new Date(profileDOB); //Can't pass Date Obj from store
        const input = {
          avatarFile,
          name,
          description: ``,
          handle,
          dob,
          pronouns,
          interests: interests.map((i) => i.value),
          password: hashedPw,
        };

        const { data } = await commitCreatePersona({
          variables: { input },
          refetchQueries: refetchQueriesFor(`Persona`),
        });
        if (!data?.createPersonaV3) {
          throw new Error(`Error creating persona`);
        }
        const persona = data.createPersonaV3;
        await mapPersonaToID({
          variables: { id: identity_id, persona_id: persona.id },
        });

        // Add persona id + password
        storedPersonaCredentials = [...storedPersonaCredentials, { id: persona.id, password: hashedPw }];

        // Set new secure store on the identity
        secureStore = await encodeSecureStore(storedPersonaCredentials);
        await setSecureStore(identity_id, secureStore);

        // Set the global identity object now that we have a valid persona
        //setIdentity(identity);
        await setAsyncStorageObject(`identity`, identity);
        // Do other junk related to login
        const pushPermissionStatus = await getAsyncStorageObject(`pushPermissionStatus`);
        const push_token = pushPermissionStatus && pushPermissionStatus.token;
        const { __typename, ...persona_input } = persona; // eslint-disable-line no-unused-vars
        // sign personas before firing loginUser
        const inputs = [{ persona: { ...persona_input, password: hashedPw }, push_token }];
        const signed = await signTokens(inputs);

        const user = await loginUser(signed[0]);
        setUser(user);
        setError(null);
        return data.createPersonaV3;
      } catch (error) {
        console.error(`There was an issue creating the persona`, error);
        Sentry.withScope((scope) => {
          scope.setExtra(`where`, `ProfileInterests.createPersona`);
          scope.setExtra(`err`, error);
          Sentry.captureException(error);
        });
        setError(error);
        return error;
      } finally {
        setLoading(false);
      }
    },
    [identity_id, profileDOB, avatarFile, name, handle, pronouns, commitCreatePersona, mapPersonaToID, identity, setUser],
  );

  return { loading, error, createPersona };
};
