import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreHealth = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path id="a" d="M0.0234536386 0.0159585909L28.1751465 0.0159585909 28.1751465 38.5280825 0.0234536386 38.5280825z" />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="c"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="e"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="g"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="i"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="k"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="m"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="o"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="q"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="s"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="u"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="w"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="y"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="A"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="C"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="E"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="G"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="I"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="K"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="M"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="O"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="Q"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="S"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="U"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="W"
        />
        <Path
          d="M2.755 2.892C1.785 3.879 0 10.56 0 10.56s3.164.728 5.307.234l.178-.702L4.43 20.163s7.52 1.377 14.025-.775l.38-8.866s2.492.216 4.182-.129c0 0-1.647-6.456-3.253-7.747-1.605-1.29-5.582-2.354-5.582-2.354s-.26 1.354-2.816 1.234C9.5 1.438 8.76.377 8.478.017c0 0-4.753 1.887-5.723 2.875"
          id="Y"
        />
        <Path id="aa" d="M0 0.0000204512527L7.52023553 0.0000204512527 7.52023553 5.61194515 0 5.61194515z" />
        <Path d="M146.183 11.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 00153 17.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 00146.471 11l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147l-8.167 8.039-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Rect fill="#E5F8FA" x={0} y={0} width={163} height={98} rx={7} />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#2A98A5">
            <TSpan x={12} y={22}>
              Health and
            </TSpan>
            <TSpan x={12} y={41}>
              Wellness
            </TSpan>
          </Text>
          <Path
            d="M132.152 33.669c4.755 4.403 10.065 7.847 11.453 12.42 1.423 4.6-1.11 10.302-5.865 13.718-4.79 3.387-11.8 4.431-18.498 4.15-6.664-.311-12.98-1.949-16.798-5.336-3.818-3.387-5.171-8.524-5.97-14.31-.798-5.759-1.006-12.195 2.812-16.57 3.817-4.403 11.73-6.774 17.77-5.307 6.073 1.496 10.307 6.831 15.096 11.235z"
            fill="#2A98A5"
            fillRule="nonzero"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(1.202 27.514)">
          <Path
            stroke="#979797"
            strokeWidth={0.5}
            fill="#FC7750"
            fillRule="nonzero"
            d="M16.8744002 0.0149369065L3.65065284 0.0149369065 3.70899156 5.75070901 16.8744002 5.75070901z"
          />
          <Path
            d="M16.235 21.911s-1.324.372-2.923.466c-1.599.093-1.827.605-1.37 3.816.456 3.211.41 8.424 1.324 7.586.914-.838.457-5.352 2.558-7.726 2.102-2.373 1.827-1.489 1.827-1.489l-1.416-2.653zM7.42 37.395s.318 1.357-.58 1.828c-.897.472-2.489 1.593-4.747 2.419-2.257.825-2.75 1.622-1.1 1.828 1.65.207 3.445.03 5.008-.413 1.563-.442 4.313.915 5.963-.472 1.65-1.386-.666-3.597-.579-4.128.087-.531-3.966-1.062-3.966-1.062"
            fill="#ECAFA3"
            fillRule="nonzero"
            transform="translate(0 2.958)"
          />
          <G transform="translate(0 2.958) translate(3.636)">
            <Mask id="b" fill="#fff">
              <Use xlinkHref="#a" />
            </Mask>
            <Path
              d="M.024.74s-.053 8.487.832 18.516c.885 10.029 1.991 18.03 1.991 18.03s3.982 2.028 6.857.901c0 0-2.625-30.87-.745-30.533 1.88.338 10.804 3.914 10.564 4.159-3.315 3.377-7.496 9.584-7.496 9.584l1.327 6.198S28.175 12.72 28.175 9.79c0-2.93-14.94-9.774-14.94-9.774S8 2.054.026.74"
              fill="#FC7750"
              fillRule="nonzero"
              mask="url(#b)"
            />
          </G>
          <Path
            d="M26.795 9.684l.029.116c-.69.175-1.41.511-2.134.96-.387.24-.75.498-1.078.755l-.046.036c-.082.065-.157.126-.224.182l-.036.03a6.819 6.819 0 00-.072.063l-.036.031-.078-.089.023-.02.024-.021.041-.036.023-.02a12.26 12.26 0 011.398-1.014c.72-.446 1.44-.783 2.132-.964l.034-.01zM17.379.21l.019.118-.014.002-.017.004a1.43 1.43 0 00-.018.004l-.01.002a2.54 2.54 0 00-.178.05c-.194.061-.416.15-.66.273-.692.346-1.426.884-2.17 1.653l-.027.028-.083-.083c.762-.794 1.516-1.349 2.228-1.705.25-.126.477-.217.677-.28.07-.022.133-.04.186-.052l.022-.005.03-.006.015-.003zM12.153 4.712c0 .802.093 1.521.248 2.148l.015.06c.048.184.098.348.148.491l.008.022c.02.057.039.106.055.148l.013.03.008.018-.106.05-.007-.014-.009-.022-.007-.017a6.188 6.188 0 01-.232-.737 9.041 9.041 0 01-.252-2.134v-.043h.118z"
            fill="#000"
            fillRule="nonzero"
            transform="translate(0 2.958)"
          />
        </G>
        <Path
          d="M9.994 6.585s-.108 2.426-1.498 3.282c-1.26.776 1.41 5.284 4.284 5.015 1.573-.148 1.941-4.526 1.941-4.526s-1.27-.25-.8-2.222L9.994 6.585z"
          fill="#ECAFA3"
          fillRule="nonzero"
          transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404)"
        />
        <Path
          d="M12.286 9.038c-1.864-.23-3.209-1.475-3.397-3.542-.542.088-.67-1.347-.519-1.808.151-.462.644-.192.644-.192.028-.073.05-.149.07-.229.814-3.395 3.094-3.26 3.094-3.26 4.19.222 3.208 4.57 2.97 5.724-.24 1.154-1.06 3.528-2.862 3.307z"
          fill="#ECAFA3"
          fillRule="nonzero"
          transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404)"
        />
        <G
          transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .075)"
          fillRule="nonzero"
        >
          <Path
            d="M8.49.015S3.738 1.9 2.769 2.889c-.97.988-2.756 7.668-2.756 7.668s3.164.728 5.307.234l.179-.702L4.442 20.16s7.519 1.378 14.024-.774l.38-8.866s2.493.215 4.182-.13c0 0-1.647-6.455-3.252-7.746C18.17 1.353 14.193.289 14.193.289s-.26 1.354-2.815 1.234C9.51 1.436 8.772.375 8.49.015"
            fill="#E4F9FF"
          />
          <Path fill="#204EB2" d="M5.01709198 10.6797567L4.84324608 10.6523326 5.28634936 7.73515471 5.46019526 7.76257887z" />
          <Path fill="#204EB2" d="M18.4559611 10.4966631L18.0128285 7.57948526 18.1867037 7.5520611 18.629807 10.4692389z" />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="d" fill="#fff">
            <Use xlinkHref="#c" />
          </Mask>
          <Path
            d="M11.558 20.762a.778.778 0 01-.678-.385.716.716 0 01.077-.863.135.135 0 01.193-.004.141.141 0 01.004.197.43.43 0 00-.042.522c.18.294.48.254.492.252a.138.138 0 01.155.117.138.138 0 01-.113.159.589.589 0 01-.088.005"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#d)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="f" fill="#fff">
            <Use xlinkHref="#e" />
          </Mask>
          <Path
            d="M10.34 15.199a.137.137 0 01-.132-.103.42.42 0 00-.416-.306.516.516 0 00-.467.299.136.136 0 01-.178.075.14.14 0 01-.075-.18.789.789 0 01.715-.473.696.696 0 01.685.512.14.14 0 01-.132.176"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#f)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="h" fill="#fff">
            <Use xlinkHref="#g" />
          </Mask>
          <Path
            d="M13.487 11.902a.137.137 0 01-.132-.103c-.008-.03-.096-.312-.416-.306a.516.516 0 00-.466.3.136.136 0 01-.179.074.14.14 0 01-.074-.18.789.789 0 01.714-.472.697.697 0 01.685.512.14.14 0 01-.132.175"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#h)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="j" fill="#fff">
            <Use xlinkHref="#i" />
          </Mask>
          <Path
            d="M14.962 18.405a.137.137 0 01-.132-.103c-.009-.03-.097-.312-.416-.306a.516.516 0 00-.467.3.136.136 0 01-.178.074.14.14 0 01-.075-.18.789.789 0 01.715-.472.696.696 0 01.685.512.14.14 0 01-.132.175"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#j)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="l" fill="#fff">
            <Use xlinkHref="#k" />
          </Mask>
          <Path
            d="M8.25 18.697a.137.137 0 01-.133-.103.42.42 0 00-.416-.306.516.516 0 00-.467.299.136.136 0 01-.178.075.14.14 0 01-.075-.18.789.789 0 01.715-.472.696.696 0 01.685.511.14.14 0 01-.132.176"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#l)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="n" fill="#fff">
            <Use xlinkHref="#m" />
          </Mask>
          <Path
            d="M4.221 7.126a.137.137 0 01-.131-.103c-.01-.03-.097-.312-.417-.306a.516.516 0 00-.466.299.136.136 0 01-.179.075.14.14 0 01-.074-.18.789.789 0 01.714-.472.696.696 0 01.685.512.14.14 0 01-.132.175"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#n)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="p" fill="#fff">
            <Use xlinkHref="#o" />
          </Mask>
          <Path
            d="M17.053 8.046a.137.137 0 01-.132-.104c-.009-.03-.097-.311-.417-.305a.516.516 0 00-.466.298.136.136 0 01-.178.075.14.14 0 01-.075-.18.789.789 0 01.715-.472.696.696 0 01.685.512.14.14 0 01-.132.176"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#p)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="r" fill="#fff">
            <Use xlinkHref="#q" />
          </Mask>
          <Path
            d="M19.782 4.861a.137.137 0 01-.132-.103.42.42 0 00-.416-.306.516.516 0 00-.467.3.136.136 0 01-.178.074.14.14 0 01-.075-.18.789.789 0 01.715-.472.696.696 0 01.685.512.14.14 0 01-.132.175"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#r)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="t" fill="#fff">
            <Use xlinkHref="#s" />
          </Mask>
          <Path
            d="M14.375 3.272a.778.778 0 01-.678-.386.716.716 0 01.077-.862.135.135 0 01.194-.004.141.141 0 01.003.197.43.43 0 00-.042.521.51.51 0 00.49.253.137.137 0 01.157.116.139.139 0 01-.113.159.588.588 0 01-.088.006"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#t)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="v" fill="#fff">
            <Use xlinkHref="#u" />
          </Mask>
          <Path
            d="M8.323 6.052a.778.778 0 01-.678-.386.716.716 0 01.077-.862.135.135 0 01.193-.004.141.141 0 01.004.197.43.43 0 00-.042.522c.18.294.479.254.491.252a.138.138 0 01.155.117.138.138 0 01-.113.159.588.588 0 01-.087.005"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#v)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="x" fill="#fff">
            <Use xlinkHref="#w" />
          </Mask>
          <Path
            d="M1.302 10.2a.778.778 0 01-.678-.385.716.716 0 01.077-.862.135.135 0 01.193-.004.141.141 0 01.004.197.43.43 0 00-.042.521c.18.294.479.255.491.253a.138.138 0 01.155.117.138.138 0 01-.113.158.589.589 0 01-.087.006"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#x)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="z" fill="#fff">
            <Use xlinkHref="#y" />
          </Mask>
          <Path
            d="M16.884 14.955a.778.778 0 01-.677-.386.716.716 0 01.076-.862.135.135 0 01.194-.004.141.141 0 01.003.197.43.43 0 00-.042.52c.181.295.48.255.492.254a.138.138 0 01.155.117.138.138 0 01-.113.158.588.588 0 01-.088.006"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#z)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="B" fill="#fff">
            <Use xlinkHref="#A" />
          </Mask>
          <Path
            d="M6.914 15.784a.778.778 0 01-.678-.386.716.716 0 01.077-.862.135.135 0 01.193-.004.141.141 0 01.004.197.43.43 0 00-.042.522c.181.294.48.254.492.252a.138.138 0 01.155.117.138.138 0 01-.113.159.588.588 0 01-.088.005"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#B)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="D" fill="#fff">
            <Use xlinkHref="#C" />
          </Mask>
          <Path
            d="M11.486 17.788a.637.637 0 01-.164-.02.14.14 0 01-.097-.17.136.136 0 01.167-.1c.031.008.314.071.469-.214a.533.533 0 00-.021-.56.142.142 0 01.025-.196.134.134 0 01.191.025.814.814 0 01.044.866.68.68 0 01-.614.369"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#D)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="F" fill="#fff">
            <Use xlinkHref="#E" />
          </Mask>
          <Path
            d="M9.46 12.63a.637.637 0 01-.163-.02.14.14 0 01-.097-.17.136.136 0 01.168-.099c.03.008.313.07.468-.214a.532.532 0 00-.02-.56.141.141 0 01.023-.196.135.135 0 01.192.024.814.814 0 01.044.866.68.68 0 01-.614.37"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#F)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="H" fill="#fff">
            <Use xlinkHref="#G" />
          </Mask>
          <Path
            d="M14.985 5.612a.637.637 0 01-.164-.02.14.14 0 01-.096-.171.136.136 0 01.167-.099c.03.008.313.07.468-.214a.533.533 0 00-.02-.561.142.142 0 01.025-.195.134.134 0 01.19.024.814.814 0 01.044.867.68.68 0 01-.614.369"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#H)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="J" fill="#fff">
            <Use xlinkHref="#I" />
          </Mask>
          <Path
            d="M18.969 10.612a.637.637 0 01-.164-.02.14.14 0 01-.097-.17.136.136 0 01.168-.1c.03.009.313.071.468-.214a.533.533 0 00-.021-.56.142.142 0 01.026-.195.134.134 0 01.19.024.814.814 0 01.044.866.68.68 0 01-.614.369"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#J)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="L" fill="#fff">
            <Use xlinkHref="#K" />
          </Mask>
          <Path
            d="M4.267 4.064a.637.637 0 01-.164-.02.14.14 0 01-.097-.17.136.136 0 01.167-.099c.031.008.314.07.469-.214A.533.533 0 004.62 3a.142.142 0 01.025-.195.134.134 0 01.191.024.814.814 0 01.044.867.68.68 0 01-.614.368"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#L)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="N" fill="#fff">
            <Use xlinkHref="#M" />
          </Mask>
          <Path
            d="M4.09 10.491a.134.134 0 01-.072-.02.14.14 0 01-.044-.193.431.431 0 00-.063-.519.507.507 0 00-.531-.145.136.136 0 01-.177-.08.14.14 0 01.08-.18.776.776 0 01.825.212c.3.318.195.702.098.86a.136.136 0 01-.116.065"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#N)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="P" fill="#fff">
            <Use xlinkHref="#O" />
          </Mask>
          <Path
            d="M8.162 2.576a.134.134 0 01-.073-.021.14.14 0 01-.043-.192.431.431 0 00-.063-.52.507.507 0 00-.532-.144.136.136 0 01-.176-.08.14.14 0 01.079-.18.776.776 0 01.826.212c.3.317.195.701.098.86a.136.136 0 01-.116.065"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#P)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="R" fill="#fff">
            <Use xlinkHref="#Q" />
          </Mask>
          <Path
            d="M20.993 8.922a.134.134 0 01-.072-.021.14.14 0 01-.044-.192.431.431 0 00-.063-.52.508.508 0 00-.532-.144.136.136 0 01-.175-.082.14.14 0 01.078-.179.776.776 0 01.826.213c.3.317.195.701.098.86a.136.136 0 01-.116.065"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#R)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="T" fill="#fff">
            <Use xlinkHref="#S" />
          </Mask>
          <Path
            d="M16.965 3.136a.134.134 0 01-.072-.02.14.14 0 01-.043-.193.431.431 0 00-.064-.519.508.508 0 00-.532-.144.136.136 0 01-.175-.083.14.14 0 01.079-.178.776.776 0 01.825.212c.3.318.196.702.098.86a.136.136 0 01-.116.065"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#T)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="V" fill="#fff">
            <Use xlinkHref="#U" />
          </Mask>
          <Path
            d="M13.73 14.909a.134.134 0 01-.072-.021.14.14 0 01-.044-.192.431.431 0 00-.063-.52.509.509 0 00-.532-.144.136.136 0 01-.176-.082.14.14 0 01.08-.179.776.776 0 01.825.213c.3.318.195.701.098.86a.136.136 0 01-.116.065"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#V)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015) translate(0 .09)">
          <Mask id="X" fill="#fff">
            <Use xlinkHref="#W" />
          </Mask>
          <Path
            d="M18.242 18.609a.134.134 0 01-.072-.021.14.14 0 01-.044-.192.431.431 0 00-.063-.52.509.509 0 00-.532-.144.136.136 0 01-.176-.082.14.14 0 01.08-.179.776.776 0 01.825.213c.3.318.195.701.098.86a.136.136 0 01-.116.065"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#X)"
          />
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(0 .015)">
          <Mask id="Z" fill="#fff">
            <Use xlinkHref="#Y" />
          </Mask>
          <Path
            d="M5.08 20.358a.134.134 0 01-.072-.021.14.14 0 01-.043-.192.431.431 0 00-.064-.52.509.509 0 00-.532-.144.136.136 0 01-.175-.082.14.14 0 01.079-.179.776.776 0 01.825.213c.3.318.196.701.098.86a.136.136 0 01-.115.065"
            fill="#204EB2"
            fillRule="nonzero"
            mask="url(#Z)"
          />
        </G>
        <G
          transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(0 1.404) translate(0 9.53) translate(.235 .643)"
          fillRule="nonzero"
        >
          <Path
            d="M22.373 9.913s.399 5.277-.752 6.248c-.969.816-2.339 1.435-5.203-2.305-2.863-3.74-4.874-5.858-4.883-7.34-.011-1.745-.348-6.346.096-6.387.444-.042.915 4.728 2.61 6.413 1.693 1.684 4.281 3.356 4.281 3.356s1.27.201 3.85.015"
            fill="#ECAFA3"
          />
          <Path
            d="M.235 10.11s-.847 5.297.813 6.425c1.002.68 2.385 1.259 5.349-2.48 2.963-3.74 5.008-5.918 5.091-7.398.084-1.48.042-6.616-.417-6.657-.46-.041-.668 5.055-2.42 6.74-1.754 1.684-4.55 3.616-4.55 3.616s-1.125.166-3.866-.245"
            fill="#ECAFA3"
          />
          <Path fill="#C86A5D" d="M11.4761139 6.5179462L11.3309149 1.31129907 11.4481707 1.30792333 11.5933404 6.51457046z" />
        </G>
        <G fillRule="nonzero">
          <Path
            d="M2.901.015l.112.037-.011.036a7.107 7.107 0 00-.118.434c-.031.13-.058.255-.079.374L2.8.931c-.05.302-.058.533-.013.657l.003.007c.26.656-.093.988-.835.755l-.023-.007.036-.114c.669.218.937-.027.713-.59-.061-.154-.052-.417.01-.764.021-.121.049-.25.08-.381.026-.106.053-.208.08-.305l.01-.036.018-.06.014-.048.009-.03z"
            fill="#000"
            transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(10.644 4.78) translate(0 .657)"
          />
          <Path
            d="M.005 4.587l.058-.105.02.012.02.01.099.052.016.008c.124.061.26.123.407.18.335.132.667.222.975.251.14.013.273.014.397 0l.025-.003.014.119c-.14.017-.29.018-.446.003a3.775 3.775 0 01-1.008-.259 5.293 5.293 0 01-.415-.184l-.048-.024-.058-.03L.028 4.6l-.023-.012z"
            fill="#C86A5D"
            transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(10.644 4.78) translate(0 .657)"
          />
          <G fill="#000">
            <Path
              d="M2.055 3.945a2.04 2.04 0 01-.992-.275.09.09 0 01-.03-.124.087.087 0 01.121-.028c.009.005.5.307 1.092.237a.09.09 0 01.098.078.088.088 0 01-.076.1 1.729 1.729 0 01-.213.012"
              transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(10.644 4.78) translate(.059) rotate(15 1.683 3.725)"
            />
            <Path
              d="M2.311 3.082c-.118 0-.266-.024-.45-.084l.035-.114c.344.112.598.103.715-.025.1-.11.1-.31-.002-.565-.068-.172-.051-.5.05-.972.075-.346.17-.649.17-.652l.112.037c-.102.326-.343 1.24-.223 1.543.152.384.073.587-.021.69-.067.073-.184.142-.386.142"
              transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(10.644 4.78) translate(.059)"
            />
            <Path
              d="M1.822.882a.111.111 0 01-.053-.014C1.598.776 1.103.53.874.504.66.479.33.644.221.71.138.76.045.697.012.568-.02.44.021.295.103.245c.02-.012.462-.278.795-.24.319.037.95.377.977.392.084.045.129.188.1.318-.024.103-.086.167-.153.167"
              transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(10.644 4.78) translate(.059) translate(0 .029)"
            />
            <Path
              d="M4.535 1.17a.155.155 0 01-.093-.031c-.09-.067-.366-.248-.568-.283-.214-.036-.708.005-.88.024A.158.158 0 012.82.738a.16.16 0 01.14-.177c.026-.003.665-.072.966-.02.317.053.687.328.703.34.07.052.085.152.033.224a.156.156 0 01-.127.065M1.514 1.468a.087.087 0 01-.06-.024.54.54 0 00-.355-.124c-.217.008-.36.116-.362.117a.087.087 0 01-.123-.017.09.09 0 01.017-.126.854.854 0 01.462-.153.714.714 0 01.48.171.09.09 0 01.006.127.087.087 0 01-.065.029M3.98 1.74a.087.087 0 01-.059-.023.54.54 0 00-.356-.124.691.691 0 00-.361.117.087.087 0 01-.123-.017.09.09 0 01.016-.125.854.854 0 01.462-.154.714.714 0 01.481.171.09.09 0 01.005.127.087.087 0 01-.065.029"
              transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(10.644 4.78) translate(.059)"
            />
          </G>
        </G>
        <G transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(8.767)">
          <Mask id="ab" fill="#fff">
            <Use xlinkHref="#aa" />
          </Mask>
          <Path
            d="M.266 5.01s.091.055.059.602c0 0 .244-.249.309-.547.065-.299.505-1.161.879-1.393.374-.232.96-.216 1.302-.746l.342-.531s2.18.15 3.125.862c0 0 .439.2.341 2.14 0 0 .18-1.045.212-1.576.033-.53.26.315.163.78 0 0 .557-.485.52-1.526C7.47 1.659 6.795 1.25 6.348.919 5.82.53 4.266-.33 3.289.134c-.976.464-.93.57-1.386.752-.455.182-1.025.1-1.513.464-.488.365-.38 3.463-.38 3.463s.193.08.256.196"
            fill="#221108"
            fillRule="nonzero"
            mask="url(#ab)"
          />
        </G>
        <G fill="#000" fillRule="nonzero">
          <Path
            d="M3.873 1.465S3.636 1.09 3.047.67C2.723.438 1.167.066.73.75c-.438.685-.721.867-.62.86.989-.06 1.084-.54 1.53-.618.499-.088.762-.206.762-.206.177.18.354.3.736.51.127.07.715.414.735.168"
            transform="translate(102.546 16.06) matrix(-1 0 0 1 35.454 .94) translate(10.468 7.26) rotate(2 -.562 2.725) rotate(11 1.981 .985)"
          />
        </G>
      </G>
    </Svg>
  );
};
