import React, { useCallback, useContext, useMemo, useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import Emoji from 'react-native-emoji-v2';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { Modals, setModal } from '../../redux/postSlice';
import { BottomSheetModal, MeshIcon } from '../../common-ui';
import { Colors, Typography } from '../../common-styles';
import { AppContext } from '../../../AppContext';
import { TO_POST_DETAILS } from './testLabels';
import { toggleJoinMeshModal } from '../../redux/guestSlice';
import { useSettings } from '../../common-settings/hooks/useSettings';
// @ts-ignore reason: svg import type definition does not exist
// import SmilingIcon from '../../../assets/images/smiling.svg';
// @ts-ignore
// import BubbleBottomIcon from '../../../assets/images/bubble_bottom.svg';
// @ts-ignore
// import BubbleTopIcon from '../../../assets/images/bubble_top.svg';
import { ACTIVITY_DETAILS_FRAGMENT, client, PROFILE_ACTIVITY_DETAILS_FRAGMENT, REACT_WITH } from '../../graphql';
import { Events, extractSourceFromRoute, PendoTrackEvent } from '../../pendo/events';
import { Activity } from '../../common-types/types';
import { HomeNavigatorScreens } from '../../common-types/navigation-types';

type PostReactionsNav = NativeStackNavigationProp<Pick<HomeNavigatorScreens, `PostDetail`>>;

type PostReactionsProps = {
  activity: Activity;
  onCantInteract: () => void;
  //onPressLike: () => void;
  onPressComment: () => void;
};

type EmojiReaction = {
  activity_id: string;
  group_id: string;
  kind: string;
  name: string;
  origin_actor_id: string;
  reaction: {
    name: string;
    url?: string;
  };
  reaction_id: string;
};

const PostReactions: React.FC<PostReactionsProps> = React.memo(({ activity, onCantInteract, onPressComment }) => {
  const { isGuestMode, user } = useContext(AppContext);
  const navigation = useNavigation<PostReactionsNav>();
  const { name: routeName } = useRoute();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const {
    id,
    reaction_counts,
    custom_reaction_counts,
    latest_reactions,
    can_participate,
    frozen: post_frozen,
    origin_group_member: group_member,
    origin_group,
    origin_group_id,
    actor,
    __typename,
  } = activity || {};

  const [addReaction] = useMutation(REACT_WITH, {
    onCompleted: (data) => {
      const { removed, data: reactionData } = data?.reactWith || {};
      let customReactionCounts = custom_reaction_counts;
      const latestReactions: any = latest_reactions;
      const { name: kind = null, url = null } = reactionData?.customReaction || {};
      const reactionTypes = new Set(custom_reaction_counts.map((i) => i.kind));

      // FIXME:
      // until multiple reactions are completed, this validation will fail whenever
      // reacted with "heart" and then reacted with "surprise" due to not been aware
      // what has been deleted.
      // stream delete only returns reaction id (no reaction data)
      if (reactionTypes.has(kind)) {
        customReactionCounts = custom_reaction_counts.map((item) => {
          if (item.kind === kind) {
            return {
              ...item,
              count: item.count + (removed ? -1 : 1),
              own_like: !removed,
            };
          }
          return item;
        });
      } else if (!removed) {
        customReactionCounts = [
          ...custom_reaction_counts,
          { __typename: `CustomReactionCounts`, count: 1, kind, own_like: true, url },
        ];
      }

      if (removed) {
        latestReactions.like = latestReactions?.like?.filter((item: any) => item?.user?.handle !== user?.handle);
      } else {
        latestReactions.like = [
          {
            __typename: `ActivityReaction`,
            activity_id: activity?.id,
            children_counts: {
              __typename: `ReactionCounts`,
              comment_like: null,
              comment_reply: null,
            },
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            data: {
              __typename: `ReactionData`,
              comment_image: null,
              customReaction: null,
              group_id: ``,
              link_preview: null,
              object: null,
              removed: null,
              user_id: user?.id,
              validMentionTargets: null,
            },
            kind: `like`,
            id: new Date().getTime(),
            parent: ``,
            own_like: true,
            latest_children: {
              __typename: `Reactions`,
              comment_like: null,
              comment_reply: null,
            },
            user_id: user?.id,
            user,
          },
          ...(latestReactions?.like || []),
        ];
      }

      client.writeFragment({
        id: __typename === `ProfileActivity` ? `ProfileActivity:${id}` : `Activity:${id}`,
        fragment: __typename === `ProfileActivity` ? PROFILE_ACTIVITY_DETAILS_FRAGMENT : ACTIVITY_DETAILS_FRAGMENT,
        fragmentName: __typename === `ProfileActivity` ? `ProfileActivityDetails` : `ActivityDetails`,
        data: {
          ...activity,
          own_like: !removed,
          custom_reaction_counts: customReactionCounts.filter((r) => r.count > 0),
          latest_reactions: latestReactions,
        },
      });
    },
  });

  const { settings } = useSettings(`group`, { user_id: undefined, group_id: origin_group_id });
  const { comment: comment_count = 0, comment_reply: reply_count = 0 } = reaction_counts || {};
  const {
    restricted: user_frozen,
    restricted_expiration,
    can_participate: can_user_participate,
    has_agreed,
  } = group_member || {};

  const frozen = post_frozen || user_frozen;

  const onComment = () => {
    if (isGuestMode) {
      dispatch(toggleJoinMeshModal({ open: true, group: origin_group }));
    } else if (__typename === `ProfileActivity`) {
      onPressComment();
    } else {
      if (frozen) return;
      if (routeName === `CommunityPreview`) {
        //if the user is in the community preview screen means it's not a group member
        navigation.push(`PostDetail`, { id: activity?.id, group_id: origin_group_id, addComment: true });
      }
      if (!group_member) {
        if (routeName !== `PostDetail`)
          navigation.push(`PostDetail`, { id: activity?.id, group_id: origin_group_id, addComment: false });
        dispatch(setModal(Modals.JOIN));
        return;
      }
      if (group_member && !can_user_participate && !has_agreed) {
        // We want to give the user a feedback when the comment icon is pressed
        if (routeName === `HomeScreen`) {
          navigation.push(`PostDetail`, { id: activity?.id, group_id: origin_group_id, addComment: true });
        }
        dispatch(setModal(Modals.AGREE));
        return;
      }
      if (can_participate && !frozen) {
        onPressComment();
        return;
      }
      onCantInteract();
    }
  };

  // TODO: if possible move up in hierarchy so it is shared through all the feed
  const top6Emojis: EmojiReaction[] = useMemo(() => {
    if (!settings) return [];
    const reactions = settings[`group.setting.reactions`];
    try {
      const value = JSON.parse((reactions?.value as string) ?? []);
      return value;
    } catch (error) {
      return [];
    }
  }, [settings]);

  const handleEmojiClick = useCallback(
    async (item: EmojiReaction) => {
      const input = {
        activity_id: id,
        origin_actor_id: actor,
        kind: `post`,
        group_id: origin_group_id,
        reaction: item,
      };

      const myReaction = custom_reaction_counts?.find((item) => item.own_like);
      await addReaction({ variables: { input } });
      // We need to remove the first Reaction and add new Reaction. That's we need to call addReaction to times like below
      if (!!myReaction && myReaction.kind !== item.name) {
        await addReaction({ variables: { input } });
      }

      // Track Event in Pendo
      const source = extractSourceFromRoute(routeName);
      PendoTrackEvent(Events.START_REACTION, {
        community_name: origin_group?.name,
        content: `post`,
        location: source,
        reaction_name: item.name,
      });
    },
    [actor, addReaction, custom_reaction_counts, id, origin_group?.name, origin_group_id, routeName],
  );

  const myReactions = custom_reaction_counts?.filter((item) => item.own_like).map((item) => item.kind) || [];

  return (
    <View style={styles.reactionsWrapper}>
      {top6Emojis.map((emoji: any, index: number) => (
        <TouchableOpacity
          hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
          onPress={() => handleEmojiClick(emoji)}
          // eslint-disable-next-line react/no-array-index-key
          key={`${emoji.name}-${index}`}
          disabled={user?.isBanned}
          style={{ marginRight: 8 }}
        >
          <View
            style={{
              ...styles.reactedList,
              backgroundColor: myReactions.includes(emoji.name) ? `#1AD4E544` : Colors.white,
              borderColor: myReactions.includes(emoji.name) ? Colors.brandCyan : Colors.lightGray,
            }}
          >
            {emoji.url ? (
              <Image source={{ uri: emoji.url }} style={{ width: 20, height: 20 }} />
            ) : (
              <View style={styles.reactedEmojiWrapper}>
                <View style={styles.reactedSelectWraper}>
                  <Emoji name={emoji.name} style={{ fontSize: 18 }} />
                </View>
              </View>
            )}
          </View>
        </TouchableOpacity>
      ))}

      <View style={{ flex: 1 }} />
      {!user?.isBanned && (
        <View style={{ flexDirection: `row`, alignItems: `center` }}>
          <TouchableOpacity
            onPress={onComment}
            disabled={frozen}
            hitSlop={{ right: 10, top: 10, bottom: 10 }}
            style={{ flexDirection: `row`, alignItems: `center` }}
            testID={TO_POST_DETAILS}
          >
            <MeshIcon
              size={20}
              name="comment"
              style={{ marginLeft: 7, marginRight: 4 }}
              color={frozen ? Colors.commentGray : Colors.iconColor}
            />

            <Text maxFontSizeMultiplier={1.2} numberOfLines={1} style={Typography.text(`reaction`)}>
              {comment_count + reply_count || null}
            </Text>
          </TouchableOpacity>
        </View>
      )}

      <BottomSheetModal
        visible={isVisible}
        onPressConfirm={() => setIsVisible(false)}
        onPressCancel={() => setIsVisible(false)}
        title="Confirm"
      >
        <Text style={{ textAlign: `center`, marginVertical: 30 }}>{`You are muted and can not participate until ${moment(
          restricted_expiration,
        ).format(`MMM DD HH:mm`)}.`}</Text>
      </BottomSheetModal>
    </View>
  );
});

const styles = StyleSheet.create({
  reactionsWrapper: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
  reactedList: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    borderWidth: 1,
    width: 32,
    height: 32,
    borderRadius: 6,
  },
  emojiContainer: {
    padding: 0,
    margin: 0,
    overflow: `visible`,
    borderRadius: 18,
  },
  bubbleBottom: {
    right: 30,
    bottom: -18,
    position: `absolute`,
  },
  bubbleTop: {
    right: 30,
    top: -18,
    position: `absolute`,
  },
  emojiTooltip: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    flexDirection: `row`,
  },
  emojiWrapper: {
    width: 20,
    height: 20,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: `#fff`,
    borderRadius: 10,
  },
  reactionImgWrapper: {
    padding: 4,
  },
  bubbleEmojiWrapper: {
    width: 40,
    height: 40,
    overflow: `hidden`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  bubbleSelectWrapper: {
    overflow: `hidden`,
    width: `100%`,
    height: `100%`,
    display: `flex`,
    alignItems: `center`,
  },
  reactedEmojiWrapper: {
    width: 24,
    height: 24,
    overflow: `hidden`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  reactedSelectWraper: {
    overflow: `hidden`,
    width: `100%`,
    height: `100%`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
});

export default PostReactions;
