import { Dimensions, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { Colors } from '../common-styles';
import { profileStyles } from '../profile/profileStyles';
import { LoadingIndicator } from './LoadingIndicator';
import { SafeAreaView } from './SafeAreaView';

const localStyles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    justifyContent: `center`,
    backgroundColor: `white`,
    marginHorizontal: 20,
    minHeight: 150,
    maxWidth: Dimensions.get(`window`).width,
    flexWrap: `wrap`,
    flexShrink: 2,
    flexDirection: `column`,
  },
  headerText: {
    fontSize: 32,
    fontWeight: `bold`,
    textAlign: `center`,
    alignSelf: `center`,
    paddingTop: 5,
    flexShrink: 2,
    marginTop: 20,
  },
});

export class LoadingHeader extends React.PureComponent {
  render = () => {
    return (
      <View
        style={{
          flex: 1,
          minHeight: Dimensions.get(`window`).height / 2.5,
        }}
      >
        <SafeAreaView style={profileStyles.profileHeaderContainer}>
          <View
            style={{
              ...StyleSheet.flatten(profileStyles.splashImgContainer),
              position: `relative`,
            }}
          >
            <View style={profileStyles.splashImg}>
              <LoadingIndicator
                size="large"
                color="white"
                style={{
                  alignSelf: `center`,
                  marginTop: Dimensions.get(`window`).height / 8,
                }}
              />
            </View>
          </View>
          <View style={localStyles.headerContainer}>
            <View>
              <Text
                style={{
                  ...StyleSheet.flatten(localStyles.headerText),
                  color: Colors.mediumGray,
                }}
              >
                Loading
              </Text>
            </View>
          </View>
        </SafeAreaView>
      </View>
    );
  };
}
