import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Mask, Use, Ellipse } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreSustainability = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path d="M.187 0h149v57a7 7 0 01-7 7h-142V0z" id="a" />
        <Path d="M146.183 11.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 00153 17.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 00146.471 11l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147l-8.167 8.039-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G fill="#2C9A44">
          <Rect fillOpacity={0.1} x={0} y={0} width={163} height={98} rx={7} />
          <Path
            d="M141.931 30.787c.904 2.591-1.008 5.489-4.15 7.322-3.13 1.843-7.477 2.61-9.716 1.29-2.239-1.31-2.37-4.71-1.113-7.68 1.27-2.97 3.928-5.508 7.136-5.652 3.195-.133 6.927 2.14 7.843 4.72z"
            fillRule="nonzero"
            transform="rotate(170 134.156 33.062)"
          />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14}>
            <TSpan x={12} y={22}>
              Sustainability
            </TSpan>
          </Text>
        </G>
        <G transform="translate(13.81 34)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <G fillRule="nonzero" mask="url(#b)">
            <G transform="translate(-11) translate(40.187) translate(33.863) translate(.02 7.275)">
              <Path fill="#7364FF" d="M8.11429412 0.268174769H12.84763236V20.515369769000003H8.11429412z" />
              <Path fill="#CECCFF" d="M0.676191176 20.5153698H11.044455875999999V42.818571399999996H0.676191176z" />
              <Path
                fill="#CECCFF"
                d="M0.676191176 0.268174769L8.11429412 0.268174769 8.11429412 20.5153698 1.86449314 20.5153698 0.676191176 20.5153698z"
              />
              <Path fill="#7364FF" d="M11.0444559 20.5153698H15.73271472V42.818571399999996H11.0444559z" />
              <Path fill="#370EA3" d="M12.3066794 21.3198941H14.47049116V36.6505517H12.3066794z" />
              <Path fill="#FFC30D" d="M9.42159706 10.9951655H11.94604412V19.5767581H9.42159706z" />
              <Path fill="#5628D2" d="M4.77841765 10.9951655H6.89715V19.5767581H4.77841765z" />
              <Path fill="#FFC30D" d="M1.44254118 10.9951655H3.5612735300000002V19.5767581H1.44254118z" />
              <Path fill="#370EA3" d="M9.42159706 0.893915896H11.94604412V9.475508496H9.42159706z" />
              <Path fill="#5628D2" d="M1.44254118 21.3198941H3.5612735300000002V36.516464299999996H1.44254118z" />
              <Path fill="#5628D2" d="M1.44254118 0.893915896H3.5612735300000002V9.117942145999999H1.44254118z" />
              <Path fill="#5628D2" d="M4.77841765 0.893915896H6.89715V9.117942145999999H4.77841765z" />
              <Path fill="#5628D2" d="M4.77841765 21.3198941H6.89715V36.6505517H4.77841765z" />
              <Path fill="#5628D2" d="M8.11429412 21.3198941H10.23302647V36.6505517H8.11429412z" />
              <Path fill="#5628D2" d="M4.77841765 38.3042962H6.89715V42.81857148H4.77841765z" />
              <Path fill="#E8E7FF" d="M0 0H7.97905588V1H0z" />
              <Path fill="#A39FFF" d="M7.97905588 0H13.38858529V1H7.97905588z" />
            </G>
            <G transform="translate(-11) translate(40.187) translate(33.863) translate(14.58)">
              <Path fill="#A39FFF" d="M0.390431373 31.6917052H10.541647073V46.352142900000004H0.390431373z" />
              <Path fill="#A39FFF" d="M1.41531373 20.5633202H10.20001961V31.6917052H1.41531373z" />
              <Path fill="#A39FFF" d="M2.29378431 9.77362511H9.90719607V20.61170441H2.29378431z" />
              <Path fill="#A39FFF" d="M3.02584314 0.290305696H9.66317647V9.676856546000002H3.02584314z" />
              <Path fill="#5628D2" d="M10.5416471 31.6917052H15.61725494V46.352142900000004H10.5416471z" />
              <Path fill="#5628D2" d="M10.2000196 20.5633202H14.59237254V31.6917052H10.2000196z" />
              <Path fill="#5628D2" d="M9.90719608 9.77362511H13.713901960000001V20.61170441H9.90719608z" />
              <Path fill="#5628D2" d="M9.66317647 0.290305696H12.981843139999999V9.676856546000002H9.66317647z" />
              <Path fill="#7364FF" d="M1.17129412 42.8200902H9.76078432V46.35214284H1.17129412z" />
              <Path fill="#FFC30D" d="M11.1272941 21.385853H14.34835292V23.32122431H11.1272941z" />
              <Path fill="#FFC30D" d="M10.6880588 11.1767693H13.51868625V12.82183491H10.6880588z" />
              <Path fill="#370EA3" d="M11.1272941 25.0630585H14.34835292V26.99842981H11.1272941z" />
              <Path fill="#370EA3" d="M10.6880588 14.370132H13.51868625V16.01519761H10.6880588z" />
              <Path fill="#370EA3" d="M10.3464314 4.30620116H12.78662748V5.757729639999999H10.3464314z" />
              <Path fill="#370EA3" d="M11.1272941 28.7402639H14.34835292V30.67563521H11.1272941z" />
              <Path fill="#370EA3" d="M10.6880588 17.5634946H13.51868625V19.208560209999998H10.6880588z" />
              <Path fill="#370EA3" d="M10.3464314 7.06410528H12.78662748V8.51563376H10.3464314z" />
              <Path fill="#370EA3" d="M11.6153333 32.5626223H15.27562742V34.78829931H11.6153333z" />
              <Path fill="#370EA3" d="M11.6153333 35.9011378H15.27562742V38.12681481H11.6153333z" />
              <Path fill="#370EA3" d="M11.6153333 39.191269H15.27562742V41.41694601H11.6153333z" />
              <Path fill="#370EA3" d="M11.6153333 42.8200902H15.27562742V46.35214284H11.6153333z" />
              <Path fill="#5628D2" d="M2.09856863 21.385853H9.51676471V23.32122431H2.09856863z" />
              <Path fill="#FFC30D" d="M2.87943137 11.1767693H9.321549019999999V12.82183491H2.87943137z" />
              <Path fill="#5628D2" d="M2.09856863 25.0630585H9.51676471V26.99842981H2.09856863z" />
              <Path fill="#5628D2" d="M2.87943137 14.370132H9.321549019999999V16.01519761H2.87943137z" />
              <Path fill="#5628D2" d="M3.56268627 4.30620116H9.12633333V5.757729639999999H3.56268627z" />
              <Path fill="#5628D2" d="M2.09856863 28.7402639H9.51676471V30.67563521H2.09856863z" />
              <Path fill="#5628D2" d="M2.87943137 17.5634946H9.321549019999999V19.208560209999998H2.87943137z" />
              <Path fill="#5628D2" d="M3.56268627 7.06410528H9.12633333V8.51563376H3.56268627z" />
              <Path fill="#5628D2" d="M1.17129412 32.5626223H9.76078432V34.78829931H1.17129412z" />
              <Path fill="#5628D2" d="M1.17129412 35.9011378H9.76078432V38.12681481H1.17129412z" />
              <Path fill="#5628D2" d="M1.17129412 39.191269H9.76078432V41.41694601H1.17129412z" />
              <Path fill="#FFC30D" d="M7.41819608 42.8200902H9.76078432V46.35214284H7.41819608z" />
              <Path fill="#7364FF" d="M1.07368627 20.2730145H10.54164705V21.2730145H1.07368627z" />
              <Path fill="#7364FF" d="M2.00096078 9.48331942H10.2000196V10.48331942H2.00096078z" />
              <Path fill="#7364FF" d="M0 31.3046309H10.9320784V32.3046309H0z" />
              <Path fill="#370EA3" d="M10.5416471 20.2730145H14.93400004V21.2730145H10.5416471z" />
              <Path fill="#370EA3" d="M10.2000196 9.48331942H14.00672548V10.48331942H10.2000196z" />
              <Path fill="#370EA3" d="M10.9320784 31.3046309H16.007686239999998V32.3046309H10.9320784z" />
              <Path fill="#FFC30D" d="M10.3464314 1.49991276H12.78662748V2.95144124H10.3464314z" />
              <Path fill="#FFC30D" d="M3.56268627 1.49991276H9.12633333V2.95144124H3.56268627z" />
              <Path fill="#7364FF" d="M2.78182353 0H9.90719608V1H2.78182353z" />
              <Path fill="#370EA3" d="M9.90719608 0H13.225862750000001V1H9.90719608z" />
            </G>
            <G transform="translate(-11) translate(40.187) translate(33.863) translate(31.613 15.693)">
              <Rect fill="#A39FFF" x={1.48762059} y={2.28257316} width={1} height={2.68538018} rx={0.5} />
              <Rect fill="#A39FFF" x={2.74984412} y={1.20842108} width={1} height={2.68538018} rx={0.5} />
              <Path fill="#7364FF" d="M0 5.01270968L9.37651765 0 9.37651765 27.7489286 0 27.7489286z" />
              <Path fill="#5628D2" d="M9.37651765 0H15.14668236V27.7489286H9.37651765z" />
              <Path
                d="M4.688 24.168c.598 0 1.082.481 1.082 1.075v2.506H3.606v-2.506c0-.594.485-1.075 1.082-1.075z"
                fill="#5628D2"
              />
              <Rect fill="#370EA3" x={0.721270588} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#FFC30D" x={0.721270588} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={0.721270588} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={3.60635294} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={3.60635294} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={3.60635294} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#FFC30D" x={6.49143529} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={6.49143529} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={6.49143529} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#370EA3" x={10.4584235} y={6.98198848} width={3.60635294} height={2.50635484} rx={1.25317742} />
              <Rect fill="#370EA3" x={10.4584235} y={15.5752051} width={3.60635294} height={2.50635484} rx={1.25317742} />
              <Rect fill="#370EA3" x={10.4584235} y={1.79025346} width={3.60635294} height={3.40148157} rx={1.70074078} />
              <Rect fill="#370EA3" x={10.4584235} y={11.2785968} width={3.60635294} height={2.50635484} rx={1.25317742} />
              <Rect fill="#5628D2" x={0.721270588} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={0.721270588} y={24.1684217} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={3.60635294} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#370EA3" x={6.49143529} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#5628D2" x={6.49143529} y={24.1684217} width={2.16381176} height={2.50635484} rx={1.08190588} />
              <Rect fill="#370EA3" x={10.4584235} y={19.8718134} width={3.60635294} height={2.50635484} rx={1.25317742} />
              <Rect fill="#370EA3" x={10.4584235} y={24.1684217} width={3.60635294} height={2.50635484} rx={1.25317742} />
            </G>
            <G>
              <G transform="translate(-11) translate(40.187) translate(14.108 26.398) translate(.015 2.442)">
                <Rect fill="#A39FFF" x={1.48762059} y={2.28257316} width={1} height={2.68538018} rx={0.5} />
                <Rect fill="#A39FFF" x={2.74984412} y={1.20842108} width={1} height={2.68538018} rx={0.5} />
                <Path fill="#7364FF" d="M0 5.01270968L9.37651765 0 9.37651765 27.7489286 0 27.7489286z" />
                <Path fill="#5628D2" d="M9.37651765 0H15.14668236V27.7489286H9.37651765z" />
                <Path
                  d="M4.688 24.168c.598 0 1.082.481 1.082 1.075v2.506H3.606v-2.506c0-.594.485-1.075 1.082-1.075z"
                  fill="#5628D2"
                />
                <Rect fill="#370EA3" x={0.721270588} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#FFC30D" x={0.721270588} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={0.721270588} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#FFC30D" x={6.49143529} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={6.49143529} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={6.49143529} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#370EA3" x={10.4584235} y={6.98198848} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#370EA3" x={10.4584235} y={15.5752051} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#370EA3" x={10.4584235} y={1.79025346} width={3.60635294} height={3.40148157} rx={1.70074078} />
                <Rect fill="#370EA3" x={10.4584235} y={11.2785968} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#5628D2" x={0.721270588} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={0.721270588} y={24.1684217} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#370EA3" x={6.49143529} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={6.49143529} y={24.1684217} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#370EA3" x={10.4584235} y={19.8718134} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#370EA3" x={10.4584235} y={24.1684217} width={3.60635294} height={2.50635484} rx={1.25317742} />
              </G>
              <G transform="translate(-11) translate(40.187) translate(14.108 26.398) translate(12.72 2.442)">
                <Path d="M6.13 0c.499 0 .902.4.902.895v.448H5.23V.895c0-.169-.262-.76-.18-.895H6.13z" fill="#7364FF" />
                <Path fill="#A39FFF" d="M0 1.25317742H10.0977882V2.25317742H0z" />
                <Path fill="#7364FF" d="M10.0977882 1.25317742H16.94985879V2.25317742H10.0977882z" />
                <Path d="M5.049 0c.498 0 .901.4.901.895v.448H4.147V.895c0-.494.404-.895.902-.895z" fill="#A39FFF" />
                <Ellipse fill="#7364FF" cx={5.04889412} cy={0.716101382} rx={1} ry={1} />
                <Path fill="#E8E7FF" d="M0.360635294 1.61122811H9.737152944V27.74892861H0.360635294z" />
                <Ellipse fill="#A39FFF" cx={1.80317647} cy={3.49099424} rx={1} ry={1} />
                <Ellipse fill="#A39FFF" cx={3.96698824} cy={3.49099424} rx={1} ry={1} />
                <Ellipse fill="#A39FFF" cx={6.1308} cy={3.49099424} rx={1} ry={1} />
                <Ellipse fill="#A39FFF" cx={8.29461176} cy={3.49099424} rx={1} ry={1} />
                <Path fill="#CECCFF" d="M9.73715294 1.61122811H16.589223529999998V27.74892861H9.73715294z" />
                <G transform="translate(0 15.307)">
                  <Path
                    d="M1.803 0c.399 0 .721.32.721.716v1.432H1.082V.716c0-.395.323-.716.721-.716zM6.13 0c.4 0 .722.32.722.716v1.432H5.41V.716c0-.395.322-.716.72-.716z"
                    fill="#7364FF"
                  />
                  <Path d="M11 0c.398 0 .72.32.72.716v1.432h-1.442V.716c0-.395.323-.716.721-.716z" fill="#5628D2" />
                  <Path
                    d="M3.967 0c.398 0 .721.32.721.716v1.432H3.246V.716c0-.395.323-.716.721-.716zM8.295 0c.398 0 .72.32.72.716v1.432H7.574V.716c0-.395.323-.716.722-.716z"
                    fill="#7364FF"
                  />
                  <Path
                    d="M13.163 0c.399 0 .721.32.721.716v1.432h-1.442V.716c0-.395.323-.716.721-.716zM15.327 0c.398 0 .721.32.721.716v1.432h-1.442V.716c0-.395.323-.716.721-.716z"
                    fill="#5628D2"
                  />
                  <Path fill="#A39FFF" d="M0 2.05879147H10.0977882V3.05879147H0z" />
                  <Path fill="#7364FF" d="M10.0977882 2.05879147H16.94985879V3.05879147H10.0977882z" />
                </G>
                <G transform="translate(0 10.742)">
                  <Path
                    d="M1.803 0c.399 0 .721.32.721.716v1.432H1.082V.716c0-.395.323-.716.721-.716zM6.13 0c.4 0 .722.32.722.716v1.432H5.41V.716c0-.395.322-.716.72-.716z"
                    fill="#7364FF"
                  />
                  <Path d="M11 0c.398 0 .72.32.72.716v1.432h-1.442V.716c0-.395.323-.716.721-.716z" fill="#5628D2" />
                  <Path d="M3.967 0c.398 0 .721.32.721.716v1.432H3.246V.716c0-.395.323-.716.721-.716z" fill="#FFC30D" />
                  <Path
                    d="M8.295 0c.398 0 .72.32.72.716v1.432H7.574V.716c0-.395.323-.716.722-.716zM13.163 0c.399 0 .721.32.721.716v1.432h-1.442V.716c0-.395.323-.716.721-.716zM15.327 0c.398 0 .721.32.721.716v1.432h-1.442V.716c0-.395.323-.716.721-.716z"
                    fill="#7364FF"
                  />
                  <Path fill="#A39FFF" d="M0 2.05879147H10.0977882V3.05879147H0z" />
                  <Path fill="#7364FF" d="M10.0977882 2.05879147H16.94985879V3.05879147H10.0977882z" />
                </G>
                <G transform="translate(0 6.176)">
                  <Path
                    d="M1.803 0c.399 0 .721.32.721.716v1.432H1.082V.716c0-.395.323-.716.721-.716zM6.13 0c.4 0 .722.32.722.716v1.432H5.41V.716c0-.395.322-.716.72-.716z"
                    fill="#7364FF"
                  />
                  <Path d="M11 0c.398 0 .72.32.72.716v1.432h-1.442V.716c0-.395.323-.716.721-.716z" fill="#5628D2" />
                  <Path d="M3.967 0c.398 0 .721.32.721.716v1.432H3.246V.716c0-.395.323-.716.721-.716z" fill="#7364FF" />
                  <Path
                    d="M8.295 0c.398 0 .72.32.72.716v1.432H7.574V.716c0-.395.323-.716.722-.716zM13.163 0c.399 0 .721.32.721.716v1.432h-1.442V.716c0-.395.323-.716.721-.716zM15.327 0c.398 0 .721.32.721.716v1.432h-1.442V.716c0-.395.323-.716.721-.716z"
                    fill="#5628D2"
                  />
                  <Path fill="#A39FFF" d="M0 2.05879147H10.0977882V3.05879147H0z" />
                  <Path fill="#7364FF" d="M10.0977882 2.05879147H16.94985879V3.05879147H10.0977882z" />
                </G>
                <Path d="M1.803 19.872c.399 0 .721.32.721.716v1.432H1.082v-1.432c0-.396.323-.716.721-.716z" fill="#5628D2" />
                <Path
                  d="M11 19.872c.398 0 .72.32.72.716v1.432h-1.442v-1.432c0-.396.323-.716.721-.716zM6.13 19.872c.4 0 .722.32.722.716v1.432H5.41v-1.432c0-.396.322-.716.72-.716zM3.967 19.872c.398 0 .721.32.721.716v1.432H3.246v-1.432c0-.396.323-.716.721-.716z"
                  fill="#7364FF"
                />
                <Path d="M13.163 19.872c.399 0 .721.32.721.716v1.432h-1.442v-1.432c0-.396.323-.716.721-.716z" fill="#5628D2" />
                <Path d="M8.295 19.872c.398 0 .72.32.72.716v1.432H7.574v-1.432c0-.396.323-.716.722-.716z" fill="#7364FF" />
                <Path fill="#7364FF" d="M10.0977882 22.0201175H16.94985879V23.0201175H10.0977882z" />
                <Path fill="#A39FFF" d="M0 22.0201175H10.0977882V23.0201175H0z" />
                <Path d="M5.026 24.526c.486 0 .88.391.88.873v2.35H4.146v-2.35c0-.482.394-.873.88-.873z" fill="#7364FF" />
                <Path fill="#7364FF" d="M1.08190588 24.5264724H3.5161941199999998V27.03282724H1.08190588z" />
                <Path fill="#7364FF" d="M6.58159412 24.5264724H9.015882359999999V27.03282724H6.58159412z" />
                <Path fill="#5628D2" d="M10.8190588 24.5264724H15.50731762V27.03282724H10.8190588z" />
                <Ellipse fill="#7364FF" cx={10.9993765} cy={3.49099424} rx={1} ry={1} />
                <Ellipse fill="#7364FF" cx={13.1631882} cy={3.49099424} rx={1} ry={1} />
                <Path d="M15.327 19.872c.398 0 .721.32.721.716v1.432h-1.442v-1.432c0-.396.323-.716.721-.716z" fill="#5628D2" />
                <Ellipse fill="#7364FF" cx={15.327} cy={3.49099424} rx={1} ry={1} />
              </G>
              <G transform="translate(-11) translate(40.187) translate(14.108 26.398) translate(25.825 1.726) translate(.27)">
                <Path fill="#7364FF" d="M0.360635294 0.716101382H13.343505894V28.465029982H0.360635294z" />
                <Path fill="#5628D2" d="M13.3435059 0.716101382H18.031764719999998V28.465029982H13.3435059z" />
                <Path fill="#CECCFF" d="M1.08190588 25.2425737H3.2457176400000005V28.465029920000003H1.08190588z" />
                <Path fill="#CECCFF" d="M1.08190588 7.69808986H3.2457176400000005V10.2044447H1.08190588z" />
                <Path fill="#5628D2" d="M1.08190588 16.2913065H3.2457176400000005V18.79766134H1.08190588z" />
                <Path fill="#CECCFF" d="M1.08190588 2.50635484H3.2457176400000005V5.90783641H1.08190588z" />
                <Path fill="#CECCFF" d="M1.08190588 11.9946982H3.2457176400000005V14.50105304H1.08190588z" />
                <Path fill="#CECCFF" d="M3.96698824 7.69808986H6.130800000000001V10.2044447H3.96698824z" />
                <Path fill="#CECCFF" d="M3.96698824 16.2913065H6.130800000000001V18.79766134H3.96698824z" />
                <Path fill="#CECCFF" d="M3.96698824 2.50635484H6.130800000000001V5.90783641H3.96698824z" />
                <Path fill="#CECCFF" d="M3.96698824 11.9946982H6.130800000000001V14.50105304H3.96698824z" />
                <Path fill="#CECCFF" d="M6.85207059 7.69808986H9.01588235V10.2044447H6.85207059z" />
                <Path fill="#CECCFF" d="M9.73715294 7.69808986H11.9009647V10.2044447H9.73715294z" />
                <Path fill="#CECCFF" d="M6.85207059 16.2913065H9.01588235V18.79766134H6.85207059z" />
                <Path fill="#CECCFF" d="M9.73715294 16.2913065H11.9009647V18.79766134H9.73715294z" />
                <Path fill="#CECCFF" d="M6.85207059 2.50635484H9.01588235V5.90783641H6.85207059z" />
                <Path fill="#5628D2" d="M9.73715294 2.50635484H11.9009647V5.90783641H9.73715294z" />
                <Path fill="#CECCFF" d="M6.85207059 11.9946982H9.01588235V14.50105304H6.85207059z" />
                <Path fill="#CECCFF" d="M9.73715294 11.9946982H11.9009647V14.50105304H9.73715294z" />
                <Path fill="#FFC30D" d="M14.56065 7.69808986H16.72446176V10.2044447H14.56065z" />
                <Path fill="#A39FFF" d="M14.56065 16.2913065H16.72446176V18.79766134H14.56065z" />
                <Path fill="#A39FFF" d="M14.56065 2.50635484H16.72446176V5.90783641H14.56065z" />
                <Path fill="#A39FFF" d="M14.56065 11.9946982H16.72446176V14.50105304H14.56065z" />
                <Path fill="#CECCFF" d="M1.08190588 20.5879147H3.2457176400000005V23.09426954H1.08190588z" />
                <Path fill="#CECCFF" d="M3.96698824 20.5879147H6.130800000000001V23.09426954H3.96698824z" />
                <Path fill="#CECCFF" d="M6.85207059 20.5879147H9.01588235V23.09426954H6.85207059z" />
                <Path fill="#CECCFF" d="M9.73715294 20.5879147H11.9009647V23.09426954H9.73715294z" />
                <Path fill="#A39FFF" d="M14.56065 20.5879147H16.72446176V23.09426954H14.56065z" />
                <Path fill="#CECCFF" d="M3.96698824 25.2425737H11.90096471V27.74892854H3.96698824z" />
                <Path fill="#A39FFF" d="M14.6057294 25.2425737H16.76954116V27.74892854H14.6057294z" />
                <Path fill="#E8E7FF" d="M0 0H13.7041412V1.43220276H0z" />
                <Path fill="#A39FFF" d="M13.7041412 0H18.39240002V1.43220276H13.7041412z" />
              </G>
              <G transform="translate(-11) translate(40.187) translate(14.108 26.398) translate(25.825 1.726) translate(0 23.99)">
                <Path fill="#E8E7FF" d="M1.57777941 0H12.17144121V1.4769591H1.57777941z" />
                <Path d="M11.777 0h.394v1.477h-1.893c0-.816.671-1.477 1.5-1.477z" fill="#A39FFF" />
                <Path d="M1.499 0h.394v1.477H0C0 .661.671 0 1.499 0z" fill="#E8E7FF" />
              </G>
              <G transform="translate(-11) translate(40.187) translate(14.108 26.398) translate(40.296 1.726)">
                <Path fill="#7364FF" d="M0.360635294 0.716101382H13.343505894V28.465029982H0.360635294z" />
                <Path fill="#A39FFF" d="M1.08190588 23.4970766H11.900964680000001V28.46502994H1.08190588z" />
                <Path d="M3.2 21.394h6.582c1.17 0 2.119.941 2.119 2.103H1.081c0-1.162.95-2.103 2.12-2.103z" fill="#5628D2" />
                <Path fill="#5628D2" d="M13.3435059 0.716101382H18.031764719999998V28.465029982H13.3435059z" />
                <Path
                  d="M6.491 24.168c.598 0 1.082.481 1.082 1.075v3.222H5.41v-3.222c0-.594.484-1.075 1.081-1.075zM3.606 24.168c.598 0 1.082.481 1.082 1.075v3.222H2.524v-3.222c0-.594.485-1.075 1.082-1.075zM9.377 24.168c.597 0 1.081.481 1.081 1.075v3.222H8.295v-3.222c0-.594.484-1.075 1.082-1.075z"
                  fill="#5628D2"
                />
                <Path
                  d="M15.643 24.168c.597 0 1.081.481 1.081 1.075v3.222h-2.163v-3.222c0-.594.484-1.075 1.082-1.075z"
                  fill="#370EA3"
                />
                <Path
                  d="M2.164 2.506c.597 0 1.082.481 1.082 1.075v17.007H1.082V3.58c0-.594.484-1.075 1.082-1.075zM5.049 2.506c.597 0 1.082.481 1.082 1.075v17.007H3.967V3.58c0-.594.484-1.075 1.082-1.075zM7.934 2.506c.597 0 1.082.481 1.082 1.075v17.007H6.852V3.58c0-.594.484-1.075 1.082-1.075z"
                  fill="#CECCFF"
                />
                <Path
                  d="M10.82 2.506c.597 0 1.081.481 1.081 1.075v17.007H9.737V3.58c0-.594.485-1.075 1.082-1.075z"
                  fill="#FFC30D"
                />
                <Path
                  d="M15.643 2.506c.597 0 1.081.481 1.081 1.075v17.007h-2.163V3.58c0-.594.484-1.075 1.082-1.075z"
                  fill="#A39FFF"
                />
                <Path fill="#5628D2" d="M0 0H13.7041412V1.43220276H0z" />
                <Path fill="#370EA3" d="M13.7041412 0H18.39240002V1.43220276H13.7041412z" />
              </G>
              <G transform="translate(-11) translate(40.187) translate(14.108 26.398) translate(56.555 2.442)">
                <Rect fill="#A39FFF" x={1.48762059} y={2.28257316} width={1} height={2.68538018} rx={0.5} />
                <Rect fill="#A39FFF" x={2.74984412} y={1.20842108} width={1} height={2.68538018} rx={0.5} />
                <Path fill="#7364FF" d="M0 5.01270968L9.37651765 0 9.37651765 27.7489286 0 27.7489286z" />
                <Path fill="#5628D2" d="M9.37651765 0H15.14668236V27.7489286H9.37651765z" />
                <Path
                  d="M4.688 24.168c.598 0 1.082.481 1.082 1.075v2.506H3.606v-2.506c0-.594.485-1.075 1.082-1.075z"
                  fill="#5628D2"
                />
                <Rect fill="#370EA3" x={0.721270588} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#FFC30D" x={0.721270588} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={0.721270588} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#FFC30D" x={6.49143529} y={6.98198848} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={6.49143529} y={15.5752051} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={6.49143529} y={11.2785968} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#370EA3" x={10.4584235} y={6.98198848} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#370EA3" x={10.4584235} y={15.5752051} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#370EA3" x={10.4584235} y={1.79025346} width={3.60635294} height={3.40148157} rx={1.70074078} />
                <Rect fill="#370EA3" x={10.4584235} y={11.2785968} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#5628D2" x={0.721270588} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={0.721270588} y={24.1684217} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={3.60635294} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#370EA3" x={6.49143529} y={19.8718134} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#5628D2" x={6.49143529} y={24.1684217} width={2.16381176} height={2.50635484} rx={1.08190588} />
                <Rect fill="#370EA3" x={10.4584235} y={19.8718134} width={3.60635294} height={2.50635484} rx={1.25317742} />
                <Rect fill="#370EA3" x={10.4584235} y={24.1684217} width={3.60635294} height={2.50635484} rx={1.25317742} />
              </G>
              <G transform="translate(-11) translate(40.187) translate(14.108 26.398) translate(68.54 .025)">
                <Rect fill="#A39FFF" x={10.8190588} y={0} width={1} height={3.75953226} rx={0.5} />
                <Rect fill="#A39FFF" x={12.0812824} y={0} width={1} height={3.75953226} rx={0.5} />
                <Path fill="#CECCFF" d="M0.360635294 2.41684217H13.343505894V30.165770769999998H0.360635294z" />
                <Path fill="#A39FFF" d="M13.3435059 2.41684217H18.031764719999998V30.165770769999998H13.3435059z" />
                <Path fill="#5628D2" d="M5.40952941 26.9433145H7.573341170000001V30.16577072H5.40952941z" />
                <Path fill="#7364FF" d="M1.08190588 9.39883065H11.900964680000001V11.905185490000001H1.08190588z" />
                <Path fill="#7364FF" d="M1.08190588 5.10222235H11.900964680000001V7.60857719H1.08190588z" />
                <Path fill="#7364FF" d="M1.08190588 17.9920472H11.900964680000001V20.49840204H1.08190588z" />
                <Path fill="#7364FF" d="M1.08190588 13.6954389H11.900964680000001V16.20179374H1.08190588z" />
                <Path fill="#FFC30D" d="M14.56065 9.39883065H16.72446176V11.905185490000001H14.56065z" />
                <Path fill="#5628D2" d="M14.56065 17.9920472H16.72446176V20.49840204H14.56065z" />
                <Path fill="#5628D2" d="M14.56065 4.923197H16.72446176V7.60857718H14.56065z" />
                <Path fill="#5628D2" d="M14.56065 13.6954389H16.72446176V16.20179374H14.56065z" />
                <Path fill="#7364FF" d="M1.08190588 22.2886555H11.900964680000001V24.79501034H1.08190588z" />
                <Path fill="#5628D2" d="M14.56065 22.2886555H16.72446176V24.79501034H14.56065z" />
                <Path fill="#7364FF" d="M9.01588235 26.9433145H11.9009647V29.44966934H9.01588235z" />
                <Path fill="#7364FF" d="M1.08190588 26.9433145H3.96698823V29.44966934H1.08190588z" />
                <Path fill="#E8E7FF" d="M0 1.70074078H13.7041412V3.1329435400000003H0z" />
                <Path fill="#CECCFF" d="M13.7041412 1.70074078H18.39240002V3.1329435400000003H13.7041412z" />
              </G>
            </G>
            <G>
              <G transform="translate(-11) translate(40.187) translate(.17 51.86) translate(7.388)">
                <Path
                  d="M.524 0h12.733c.29 0 .524.233.524.52v5.352c0 .086-.07.156-.157.156H.157A.157.157 0 010 5.872V.52C0 .233.235 0 .524 0z"
                  fill="#CECCFF"
                />
                <Path
                  d="M3.72 4.053h1.048c.55 0 .996.442.996.988v.831c0 .086-.07.156-.157.156H2.882a.157.157 0 01-.157-.156v-.831c0-.546.446-.988.995-.988z"
                  fill="#A39FFF"
                />
                <Path d="M3.93 4.729h.629c.434 0 .786.349.786.78v.519H3.144v-.52c0-.43.352-.78.786-.78z" fill="#370EA3" />
                <Ellipse fill="#5628D2" cx={4.2706} cy={6.001875} rx={1} ry={1} />
                <Path d="M13.362 2.481l2.593 1.322c.21.107.341.32.341.554v1.67h-2.934V2.482z" fill="#CECCFF" />
                <Path
                  d="M13.781 4.053h1.52c.55 0 .995.442.995.988v.831c0 .086-.07.156-.157.156h-2.725a.157.157 0 01-.157-.156v-1.3c0-.286.235-.519.524-.519z"
                  fill="#A39FFF"
                />
                <Path d="M14.462 4.729h.63c.433 0 .785.349.785.78v.519h-2.2v-.52c0-.43.351-.78.785-.78z" fill="#370EA3" />
                <Ellipse fill="#E8E7FF" cx={4.2706} cy={6.001875} rx={1} ry={1} />
                <Ellipse fill="#5628D2" cx={14.803} cy={6.001875} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={14.803} cy={6.001875} rx={1} ry={1} />
                <Path fill="#5628D2" d="M11.1612 1.14321429H12.995199999999999V5.7160714299999995H11.1612z" />
                <Path fill="#5628D2" d="M8.9604 1.14321429H10.6896V2.4942857199999997H8.9604z" />
                <Path fill="#5628D2" d="M13.362 1.14321429H14.362V2.70214286H13.362z" />
                <Path fill="#5628D2" d="M7.074 1.14321429H8.8032V2.4942857199999997H7.074z" />
                <Path fill="#5628D2" d="M5.1876 1.14321429H6.9168V2.4942857199999997H5.1876z" />
                <Path fill="#5628D2" d="M3.3012 1.14321429H5.0304V2.4942857199999997H3.3012z" />
                <Path fill="#5628D2" d="M1.4148 1.14321429H3.144V2.4942857199999997H1.4148z" />
                <Path fill="#5628D2" d="M0 1.14321429H1.2576V2.4942857199999997H0z" />
                <Path fill="#5628D2" d="M0 3.27375H11.1612V4.27375H0z" />
              </G>
              <G transform="translate(-11) translate(40.187) translate(.17 51.86) translate(102.285 2.079)">
                <Path
                  d="M.55 1.507H11.11c.231 0 .419.186.419.416v.675c0 .603-.493 1.091-1.1 1.091H.243a.209.209 0 01-.208-.238L.291 1.73a.261.261 0 01.26-.222z"
                  fill="#7364FF"
                  transform="matrix(1 0 0 -1 0 5.196)"
                />
                <Path
                  d="M5.232 0h3.16l-.326 1.31a.262.262 0 01-.254.197H5.588a.261.261 0 01-.261-.24L5.232 0z"
                  fill="#5628D2"
                  transform="matrix(1 0 0 -1 0 1.507)"
                />
                <Path
                  d="M1.494 2.13h1.65c.55 0 .996.443.996.988v.727a.26.26 0 01-.262.26H.75a.157.157 0 01-.156-.172l.117-1.105a.784.784 0 01.782-.697zM8.84 2.13h1.954c.55 0 .996.443.996.988v.831c0 .086-.07.156-.157.156H8.116a.157.157 0 01-.152-.195l.37-1.392a.523.523 0 01.506-.387z"
                  fill="#A39FFF"
                />
                <Path
                  d="M2.007 2.806h.718c.434 0 .786.349.786.78v.52H1.153l.074-.613a.784.784 0 01.78-.687zM9.851 2.806h.629c.434 0 .786.349.786.78v.52h-2.2v-.52c0-.431.351-.78.785-.78z"
                  fill="#370EA3"
                />
                <Path
                  d="M7.621.312l.298 1.195h.473L8.363.384a.095.095 0 00-.036-.052.107.107 0 00-.063-.02H7.62z"
                  fill="#A39FFF"
                  transform="rotate(180 8.007 .91)"
                />
                <Ellipse fill="#5628D2" cx={2.358} cy={4.05321429} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={2.358} cy={4.05321429} rx={1} ry={1} />
                <Ellipse fill="#5628D2" cx={10.218} cy={4.05321429} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={10.218} cy={4.05321429} rx={1} ry={1} />
                <Path
                  d="M5.755.312h1.633c.058 0 .105.046.105.104v.987a.104.104 0 01-.105.104h-1.72a.104.104 0 01-.104-.113l.087-.987a.104.104 0 01.104-.095z"
                  fill="#A39FFF"
                />
              </G>
              <G transform="translate(-11) translate(40.187) translate(.17 51.86) translate(0 2.962)">
                <Path
                  d="M.42 1.61h5.606c.232 0 .42.187.42.417v.987a.209.209 0 01-.21.208H.226a.209.209 0 01-.2-.266l.393-1.345z"
                  fill="#CECCFF"
                />
                <Path d="M1.156 0H3.99c.187 0 .351.123.403.302l.376 1.309H.42L.75.314A.419.419 0 011.156 0z" fill="#A39FFF" />
                <Path
                  d="M4.024.312a.105.105 0 01.102.078l.289 1.22H2.568V.313h1.456zM2.358 1.61H.918a.156.156 0 01-.153-.192L1.01.39a.104.104 0 01.102-.077h1.247V1.61z"
                  fill="#5628D2"
                />
                <Ellipse fill="#5628D2" cx={1.2052} cy={3.16982143} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={1.2052} cy={3.16982143} rx={1} ry={1} />
                <Ellipse fill="#5628D2" cx={5.2924} cy={3.16982143} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={5.2924} cy={3.16982143} rx={1} ry={1} />
              </G>
              <G transform="translate(-11) translate(40.187) translate(.17 51.86) translate(87.718)">
                <Path
                  d="M.524 0h12.733c.29 0 .524.233.524.52v5.352c0 .086-.07.156-.157.156H.157A.157.157 0 010 5.872V.52C0 .233.235 0 .524 0z"
                  fill="#CECCFF"
                />
                <Path
                  d="M3.72 4.053h1.048c.55 0 .996.442.996.988v.831c0 .086-.07.156-.157.156H2.882a.157.157 0 01-.157-.156v-.831c0-.546.446-.988.995-.988z"
                  fill="#A39FFF"
                />
                <Path d="M3.93 4.729h.629c.434 0 .786.349.786.78v.519H3.144v-.52c0-.43.352-.78.786-.78z" fill="#370EA3" />
                <Ellipse fill="#5628D2" cx={4.2706} cy={6.001875} rx={1} ry={1} />
                <Path d="M13.362 2.481l2.593 1.322c.21.107.341.32.341.554v1.67h-2.934V2.482z" fill="#CECCFF" />
                <Path
                  d="M13.781 4.053h1.52c.55 0 .995.442.995.988v.831c0 .086-.07.156-.157.156h-2.725a.157.157 0 01-.157-.156v-1.3c0-.286.235-.519.524-.519z"
                  fill="#A39FFF"
                />
                <Path d="M14.462 4.729h.63c.433 0 .785.349.785.78v.519h-2.2v-.52c0-.43.351-.78.785-.78z" fill="#370EA3" />
                <Ellipse fill="#E8E7FF" cx={4.2706} cy={6.001875} rx={1} ry={1} />
                <Ellipse fill="#5628D2" cx={14.803} cy={6.001875} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={14.803} cy={6.001875} rx={1} ry={1} />
                <Path fill="#5628D2" d="M11.1612 1.14321429H12.995199999999999V5.7160714299999995H11.1612z" />
                <Path fill="#5628D2" d="M8.9604 1.14321429H10.6896V2.4942857199999997H8.9604z" />
                <Path fill="#5628D2" d="M13.362 1.14321429H14.362V2.70214286H13.362z" />
                <Path fill="#5628D2" d="M7.074 1.14321429H8.8032V2.4942857199999997H7.074z" />
                <Path fill="#5628D2" d="M5.1876 1.14321429H6.9168V2.4942857199999997H5.1876z" />
                <Path fill="#5628D2" d="M3.3012 1.14321429H5.0304V2.4942857199999997H3.3012z" />
                <Path fill="#5628D2" d="M1.4148 1.14321429H3.144V2.4942857199999997H1.4148z" />
                <Path fill="#5628D2" d="M0 1.14321429H1.2576V2.4942857199999997H0z" />
                <Path fill="#5628D2" d="M0 3.27375H11.1612V4.27375H0z" />
              </G>
              <G transform="translate(-11) translate(40.187) translate(.17 51.86) translate(22.637 2.806)">
                <Path
                  d="M0 1.455h9.484l2.601.242c.373.035.67.326.708.696l.074.704a.208.208 0 01-.209.229H.22a.209.209 0 01-.21-.207L0 1.455z"
                  fill="#7364FF"
                />
                <Path d="M.474 0h7.757c.032 0 .062.014.081.038l1.172 1.417H0L.269.165A.21.21 0 01.474 0z" fill="#A39FFF" />
                <Path
                  d="M8.159.208a.16.16 0 01.08.02c.015.01.36.418 1.036 1.227H6.76V.208h1.399zM6.498 1.455H4.509a.156.156 0 01-.15-.202l.3-.97a.102.102 0 01.046-.054.16.16 0 01.08-.021h1.713v1.247z"
                  fill="#5628D2"
                />
                <Path
                  d="M.42.208a.115.115 0 00-.067.02.1.1 0 00-.039.055L.53 1.33c.015.073.08.125.154.125h3.648a.052.052 0 00.05-.067L4.04.283A.1.1 0 004 .229a.115.115 0 00-.066-.021H.42z"
                  fill="#5628D2"
                  transform="matrix(1 0 0 -1 0 1.663)"
                />
                <Ellipse fill="#370EA3" cx={2.882} cy={3.32571429} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={2.882} cy={3.32571429} rx={1} ry={1} />
                <Ellipse fill="#370EA3" cx={10.3752} cy={3.32571429} rx={1} ry={1} />
                <Ellipse fill="#E8E7FF" cx={10.3752} cy={3.32571429} rx={1} ry={1} />
              </G>
            </G>
            <G>
              <G transform="translate(-11) translate(.187 7) translate(32)">
                <Path
                  d="M9.066 11.458c1.64 0 3.106.758 4.07 1.946a3.09 3.09 0 011.828-.597c1.373 0 2.54.894 2.957 2.136.289-.089.595-.136.912-.136 1.725 0 3.123 1.41 3.123 3.148 0 .056-.002.11-.005.165H0a2.736 2.736 0 012.723-2.614c.422 0 .823.097 1.18.27.455-2.458 2.594-4.318 5.163-4.318z"
                  fill="#E8E7FF"
                  transform="translate(.987) matrix(-1 0 0 1 21.956 0)"
                />
                <Path
                  d="M20.284 12.612a3.683 3.683 0 013.681 3.685 3.68 3.68 0 013.178 1.823H13.572a3.686 3.686 0 013.153-2.77 3.684 3.684 0 013.559-2.738z"
                  fill="#CECCFF"
                  transform="translate(.987)"
                />
                <Path
                  d="M114.101 3.59c1.586 0 2.914 1.013 3.253 2.372a3.574 3.574 0 011.461-.308c1.318 0 2.457.7 3 1.716.173-.047.357-.071.547-.071.966 0 1.77.64 1.93 1.484H103.91c.162-.844.965-1.484 1.931-1.484.3 0 .583.06.836.17.5-1.11 1.694-1.891 3.086-1.891.402 0 .787.065 1.144.184.414-1.257 1.688-2.173 3.195-2.173z"
                  fill="#E8E7FF"
                  transform="translate(.987)"
                />
                <Path
                  d="M86.776 2.278c1.64 0 3.105.758 4.068 1.946a3.09 3.09 0 011.83-.596c1.372 0 2.538.893 2.957 2.135.288-.088.594-.136.911-.136 1.725 0 3.123 1.41 3.123 3.149 0 .055-.002.11-.004.164H77.709a2.736 2.736 0 012.723-2.613c.423 0 .823.097 1.18.27.455-2.458 2.594-4.319 5.164-4.319z"
                  fill="#E8E7FF"
                  transform="translate(.987) matrix(-1 0 0 1 177.374 0)"
                />
                <Path
                  d="M101.599.704c2.028 0 3.839.936 5.03 2.403a3.824 3.824 0 012.261-.737 3.864 3.864 0 013.656 2.637c.356-.11.735-.169 1.127-.169 2.132 0 3.86 1.74 3.86 3.887 0 .072-.002.144-.006.215H90.39c.08-1.802 1.557-3.238 3.366-3.238.523 0 1.018.12 1.46.334C95.777 3 98.421.704 101.598.704z"
                  fill="#CECCFF"
                  transform="translate(.987) matrix(-1 0 0 1 207.923 0)"
                />
                <Path fill="#0CC647" d="M10 48.5442621H129V57.09459458H10z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(33.37 41.305)" fill="#02A837">
                <Path d="M2.4628 2.20315315H3.4628V7.2389317900000005H2.4628z" />
                <Path d="M2.5152 1.88841699L5.0304 6.13735521 0 6.13735521z" />
                <Path d="M2.5676 0.786840412L4.6636 4.30139425 0.4716 4.30139425z" />
                <Path d="M2.5152 0L3.9824 2.46543329 1.048 2.46543329z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(35.414 40.623)">
                <Path fill="#02A837" d="M2.2532 1.5212248H3.2532V7.920860149999999H2.2532z" />
                <Path fill="#0CC647" d="M2.358 0L4.716 6.76682754 0 6.76682754z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(36.566 46.603)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(52.024 41.41)">
                <Path
                  d="M2.515 5.875c1.39 0 2.515-.468 2.515-2.09C5.03 2.161 3.904 0 2.515 0 1.126 0 0 2.162 0 3.784c0 1.623 1.126 2.091 2.515 2.091z"
                  fill="#00D745"
                />
                <Path fill="#02A837" d="M2.4628 2.0982411H3.4628V7.13401974H2.4628z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(46.784 41.41)">
                <Rect fill="#02A837" x={0} y={0} width={2.5152} height={5.87507508} rx={1.2576} />
                <Path fill="#0CC647" d="M1.2052 2.0982411H2.2052V7.13401974H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(42.645 40.623)">
                <Path fill="#02A837" d="M2.2532 1.5212248H3.2532V7.920860149999999H2.2532z" />
                <Path fill="#0CC647" d="M2.358 0L4.716 6.76682754 0 6.76682754z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(58.941 39.784)">
                <Rect fill="#00D745" x={0} y={0} width={2.5676} height={7.50121193} rx={1.2838} />
                <Path fill="#02A837" d="M1.2052 3.72437795H2.2052V8.760156590000001H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(41.544 41.41)">
                <Rect fill="#02A837" x={0} y={0} width={2.5152} height={5.87507508} rx={1.2576} />
                <Path fill="#0CC647" d="M1.2052 2.0982411H2.2052V7.13401974H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(50.033 42.25)">
                <Path
                  d="M1.886 5.036c1.042 0 1.887-.402 1.887-1.792C3.773 1.854 2.928 0 1.886 0 .845 0 0 1.853 0 3.244c0 1.39.845 1.792 1.886 1.792z"
                  fill="#0CC647"
                />
                <Path fill="#02A837" d="M1.834 2.0982411H2.834V6.2947233H1.834z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(61.98 46.603)">
                <Ellipse fill="#02A837" cx={2.6462} cy={1.07534856} rx={1} ry={1} />
                <Path
                  d="M1.677 0A1.049 1.049 0 012.72 1.154h.005a.63.63 0 01.609.787H.084A.84.84 0 01.671.75C.8.317 1.202 0 1.678 0z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(54.644 47.18)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(38.662 46.603)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(48.88 47.18)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(58.26 11.72)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(67.168 1.02) translate(.175)">
                <Ellipse fill="#02A837" cx={0.8302125} cy={0.45899024} rx={1} ry={1} />
                <Path d="M1.15 1.137A.565.565 0 00.573.568.563.563 0 000 1.137" fill="#02A837" />
                <Path
                  d="M1.667.044c.133 0 .257.035.366.096a.593.593 0 01.252-.056.62.62 0 01.576.412.475.475 0 01.545.478c0 .057-.01.112-.027.163H.943A.84.84 0 01.894.852c0-.446.346-.808.773-.808z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(62.452 11.72)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(76.862 19.589)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(55.43 46.603)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(59.675 46.603)">
                <Ellipse fill="#02A837" cx={2.6462} cy={1.07534856} rx={1} ry={1} />
                <Path
                  d="M1.677 0A1.049 1.049 0 012.72 1.154h.005a.63.63 0 01.609.787H.084A.84.84 0 01.671.75C.8.317 1.202 0 1.678 0z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(45.422 46.603)">
                <Ellipse fill="#02A837" cx={2.6462} cy={1.07534856} rx={1} ry={1} />
                <Path
                  d="M1.677 0A1.049 1.049 0 012.72 1.154h.005a.63.63 0 01.609.787H.084A.84.84 0 01.671.75C.8.317 1.202 0 1.678 0z"
                  fill="#00D745"
                />
              </G>
              <Path
                d="M1.284 2.99c.709 0 1.284-.029 1.284-.854C2.568 1.31 1.993 0 1.284 0 .574 0 0 1.31 0 2.136c0 .825.575.854 1.284.854z"
                transform="translate(-11) translate(.187 7) translate(32) translate(42.592 45.554)"
                fill="#00D745"
              />
              <G transform="translate(-11) translate(.187 7) translate(32) translate(33.37 47.18)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(71.622 41.305)" fill="#02A837">
                <Path d="M2.4628 2.20315315H3.4628V7.2389317900000005H2.4628z" />
                <Path d="M2.5152 1.88841699L5.0304 6.13735521 0 6.13735521z" />
                <Path d="M2.5676 0.786840412L4.6636 4.30139425 0.4716 4.30139425z" />
                <Path d="M2.5152 0L3.9824 2.46543329 1.048 2.46543329z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(73.666 40.623)">
                <Path fill="#02A837" d="M2.2532 1.5212248H3.2532V7.920860149999999H2.2532z" />
                <Path fill="#0CC647" d="M2.358 0L4.716 6.76682754 0 6.76682754z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(74.818 46.603)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(70.312 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(66.644 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(63.5 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(90.276 41.41)">
                <Path
                  d="M2.515 5.875c1.39 0 2.515-.468 2.515-2.09C5.03 2.161 3.904 0 2.515 0 1.126 0 0 2.162 0 3.784c0 1.623 1.126 2.091 2.515 2.091z"
                  fill="#00D745"
                />
                <Path fill="#02A837" d="M2.4628 2.0982411H3.4628V7.13401974H2.4628z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(85.036 41.41)">
                <Rect fill="#02A837" x={0} y={0} width={2.5152} height={5.87507508} rx={1.2576} />
                <Path fill="#0CC647" d="M1.2052 2.0982411H2.2052V7.13401974H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(80.897 40.623)">
                <Path fill="#02A837" d="M2.2532 1.5212248H3.2532V7.920860149999999H2.2532z" />
                <Path fill="#0CC647" d="M2.358 0L4.716 6.76682754 0 6.76682754z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(97.193 39.784)">
                <Rect fill="#00D745" x={0} y={0} width={2.5676} height={7.50121193} rx={1.2838} />
                <Path fill="#02A837" d="M1.2052 3.72437795H2.2052V8.760156590000001H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(79.796 41.41)">
                <Rect fill="#02A837" x={0} y={0} width={2.5152} height={5.87507508} rx={1.2576} />
                <Path fill="#0CC647" d="M1.2052 2.0982411H2.2052V7.13401974H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(88.285 42.25)">
                <Path
                  d="M1.886 5.036c1.042 0 1.887-.402 1.887-1.792C3.773 1.854 2.928 0 1.886 0 .845 0 0 1.853 0 3.244c0 1.39.845 1.792 1.886 1.792z"
                  fill="#0CC647"
                />
                <Path fill="#02A837" d="M1.834 2.0982411H2.834V6.2947233H1.834z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(100.232 46.603)">
                <Ellipse fill="#02A837" cx={2.6462} cy={1.07534856} rx={1} ry={1} />
                <Path
                  d="M1.677 0A1.049 1.049 0 012.72 1.154h.005a.63.63 0 01.609.787H.084A.84.84 0 01.671.75C.8.317 1.202 0 1.678 0z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(92.896 47.18)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(76.914 46.603)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(72.408 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(87.132 47.18)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(93.682 46.603)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(97.927 46.603)">
                <Ellipse fill="#02A837" cx={2.6462} cy={1.07534856} rx={1} ry={1} />
                <Path
                  d="M1.677 0A1.049 1.049 0 012.72 1.154h.005a.63.63 0 01.609.787H.084A.84.84 0 01.671.75C.8.317 1.202 0 1.678 0z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(83.674 46.603)">
                <Ellipse fill="#02A837" cx={2.6462} cy={1.07534856} rx={1} ry={1} />
                <Path
                  d="M1.677 0A1.049 1.049 0 012.72 1.154h.005a.63.63 0 01.609.787H.084A.84.84 0 01.671.75C.8.317 1.202 0 1.678 0z"
                  fill="#00D745"
                />
              </G>
              <Path
                d="M1.284 2.99c.709 0 1.284-.029 1.284-.854C2.568 1.31 1.993 0 1.284 0 .574 0 0 1.31 0 2.136c0 .825.575.854 1.284.854z"
                transform="translate(-11) translate(.187 7) translate(32) translate(80.844 45.554)"
                fill="#00D745"
              />
              <G transform="translate(-11) translate(.187 7) translate(32) translate(71.622 47.18)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(62.452 .836)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(59.832 .836)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(93.84 14.92) translate(.052 .21)">
                <Path fill="#02A837" d="M2.2532 1.12865066H3.2532V5.87676721H2.2532z" />
                <Path fill="#0CC647" d="M2.358 0L4.716 5.02054947 0 5.02054947z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(94.992 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(104.11 15.654)">
                <Rect fill="#02A837" x={0} y={0} width={2.5152} height={4.40630631} rx={1.2576} />
                <Path fill="#0CC647" d="M1.2052 1.57368082H2.2052V5.3505148H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(99.499 15.707) translate(.157 .21)">
                <Path fill="#02A837" d="M2.2532 0.981435352H3.2532V5.110232352H2.2532z" />
                <Path fill="#0CC647" d="M2.358 0L4.716 4.36569519 0 4.36569519z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(97.298 14.92) translate(0 .21)">
                <Rect fill="#02A837" x={0} y={0} width={2.5152} height={4.82595453} rx={1.2576} />
                <Path fill="#0CC647" d="M1.2052 1.72355519H2.2052V5.86008764H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(97.088 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(103.848 19.064)">
                <Ellipse fill="#02A837" cx={2.6462} cy={1.07534856} rx={1} ry={1} />
                <Path
                  d="M1.677 0A1.049 1.049 0 012.72 1.154h.005a.63.63 0 01.609.787H.084A.84.84 0 01.671.75C.8.317 1.202 0 1.678 0z"
                  fill="#00D745"
                />
              </G>
              <Path
                d="M1.284 2.99c.709 0 1.284-.029 1.284-.854C2.568 1.31 1.993 0 1.284 0 .574 0 0 1.31 0 2.136c0 .825.575.854 1.284.854z"
                transform="translate(-11) translate(.187 7) translate(32) translate(101.018 18.015)"
                fill="#00D745"
              />
              <Path
                d="M1.284 2.99c.709 0 1.284-.029 1.284-.854C2.568 1.31 1.993 0 1.284 0 .574 0 0 1.31 0 2.136c0 .825.575.854 1.284.854z"
                transform="translate(-11) translate(.187 7) translate(32) translate(59.57 18.015)"
                fill="#00D745"
              />
              <Path
                d="M1.284 2.99c.709 0 1.284-.029 1.284-.854C2.568 1.31 1.993 0 1.284 0 .574 0 0 1.31 0 2.136c0 .825.575.854 1.284.854z"
                transform="translate(-11) translate(.187 7) translate(32) translate(55.64 18.015)"
                fill="#00D745"
              />
              <Path
                d="M1.284 2.99c.709 0 1.284-.029 1.284-.854C2.568 1.31 1.993 0 1.284 0 .574 0 0 1.31 0 2.136c0 .825.575.854 1.284.854z"
                transform="translate(-11) translate(.187 7) translate(32) translate(51.448 18.015)"
                fill="#00D745"
              />
              <G transform="translate(-11) translate(.187 7) translate(32) translate(91.796 19.641)">
                <Ellipse fill="#02A837" cx={1.0218} cy={0.550788288} rx={1} ry={1} />
                <Path d="M1.415 1.364c0-.391-.319-.682-.71-.682-.39 0-.705.29-.705.682" fill="#02A837" />
                <Path
                  d="M2.052.052c.163 0 .316.042.45.116a.745.745 0 01.31-.067c.323 0 .6.205.71.494a.577.577 0 01.67.573.592.592 0 01-.033.196H1.161a.986.986 0 01-.06-.341c0-.536.425-.97.95-.97z"
                  fill="#00D745"
                />
              </G>
              <G
                transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(.303 18.112)"
                fill="#02A837"
              >
                <Path d="M2.78375 2.90282399H3.78375V9.584796189999999H2.78375z" />
                <Path d="M2.89291667 0L5.78583333 8.37985039 0 8.37985039z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(18.078 20.563)">
                <Rect fill="#02A837" x={0} y={0} width={2.5152} height={5.87507508} rx={1.2576} />
                <Path fill="#0CC647" d="M1.2052 2.0982411H2.2052V7.13401974H1.2052z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(0 21.77)">
                <Rect fill="#02A837" x={0} y={0} width={2.096} height={4.8814962} rx={1.048} />
                <Path fill="#0CC647" d="M1.00433333 1.7433915H2.0043333299999997V5.9275310999999995H1.00433333z" />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(14.462 25.756)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(24.209 .21)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(32.33 .21)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(16.873 25.756)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(26.62 .21)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(12.043 20.9) translate(2.777 25.756)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(56.688 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G transform="translate(-11) translate(.187 7) translate(32) translate(52.758 19.064)">
                <Ellipse fill="#00D745" cx={2.9868} cy={0.996664522} rx={1} ry={1} />
                <Path
                  d="M1.624 0a.63.63 0 01.627.582.63.63 0 01.886.66.63.63 0 01.93.699H.084A.839.839 0 011.007.75.631.631 0 011.624 0z"
                  fill="#02A837"
                />
              </G>
              <G fill="#0CC647">
                <Path
                  d="M42.13.098v16.576H0l.05-.038C1.107 15.82 19.106 2.172 42.13.098z"
                  transform="translate(-11) translate(.187 7) translate(0 48.5)"
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};
