import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { SafeAreaView, StyleSheet, Text, View, BackHandler } from 'react-native';
import LottieView from 'lottie-react-native';
import { Typography } from '../common-styles';
import { ProfileNavigator } from '../common-types/navigation-types';

export const PreparingAccountForDeletion = () => {
  const navigation = useNavigation<ProfileNavigator>();

  useFocusEffect(
    useCallback(() => {
      //otherwise android devices will exit app completely
      const onBackPress = () => null;
      BackHandler.addEventListener(`hardwareBackPress`, onBackPress);
      return () => BackHandler.removeEventListener(`hardwareBackPress`, onBackPress);
    }, []),
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => null,
      headerRight: () => null,
    });
    let timer: any;
    const create = () => {
      try {
        // await scheduleAccountForDeletion();
        timer = setTimeout(() => {
          navigation.goBack();
        }, 10000);
      } catch (e) {
        console.error(e);
      }
    };
    create();
    return () => clearTimeout(timer);
  }, [navigation]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <LottieView
          resizeMode="cover"
          style={{ width: 200, height: 200 }}
          source={require(`../images/animated/animatedLoading.json`)}
          autoPlay
          loop
        />
        <Text style={styles.headerText}>Preparing your account for deletion</Text>
        <Text style={styles.headerText}>
          To recover your account before it is permanently deleted, log back into mesh before DATE
        </Text>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 100,
  },
  headerText: {
    marginRight: 10,
    marginTop: 20,
    ...Typography.text(`bold`, `black`, `plusone`),
  },
});
