/* eslint-disable react/jsx-no-useless-fragment */
import { Platform, View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { useMutation } from 'react-apollo';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Colors, Typography } from '../common-styles';
import { Divider, HeaderCancelButton, HeaderSubmitButton, MeshIcon, PasswordInput } from '../common-ui';
import { UPDATE_PASSWORD } from '../graphql';
import { AppContext } from '../../AppContext';
import { DrawerSettingNavigatorScreens } from '../common-types/navigation-types';

type DrawerNavigation = StackNavigationProp<DrawerSettingNavigatorScreens, `DrawerSettingsMenu`>;

export const UpdatePasswordItem = () => {
  const navigation = useNavigation<DrawerNavigation>();
  const navigateToUpdatePassword = useCallback(() => navigation.push(`UpdatePassword`), [navigation]);

  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={navigateToUpdatePassword}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, padding: 16, backgroundColor: Colors.white }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Password</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};

export const UpdatePassword = () => {
  const navigation = useNavigation<DrawerNavigation>();
  const { identity } = React.useContext(AppContext);
  const identity_id = identity?.id;
  const [currentPassword, setCurrentPassword] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const [confirmNewPassword, setConfirmNewPassword] = useState(``);
  const [correctPassword, setCorrectPassword] = useState(true);
  const [is8Chars, setIs8Chars] = useState(false);
  const [containsSpecialSymbol, setContainsSpecialSymbol] = useState(false);
  const [saveSettingMutation] = useMutation(UPDATE_PASSWORD);

  const onSubmit = useCallback(
    async (navigation) => {
      try {
        await saveSettingMutation({ variables: { identity_id, current_password: currentPassword, new_password: newPassword } });
        console.log(`------ save password done --------`);
        setCorrectPassword(true);
        navigation.goBack();
      } catch (err) {
        setCorrectPassword(false);
        console.error(`Error thrown in Update Password`, err);
      }
    },
    [currentPassword, identity_id, newPassword, saveSettingMutation],
  );

  //check if all requirements are fulfilled for submit button
  const submitDisabled = React.useMemo(() => {
    const passwordRegex = /[^A-Za-z0-9]/;
    setIs8Chars(newPassword.length >= 8);
    setContainsSpecialSymbol(passwordRegex.test(newPassword));
    const submitEnabled =
      !!currentPassword && !!newPassword && newPassword === confirmNewPassword && is8Chars && containsSpecialSymbol;
    return !submitEnabled;
  }, [currentPassword, newPassword, confirmNewPassword, is8Chars, containsSpecialSymbol]);

  // Effect #1: update onSubmit as our state changes
  useEffect(() => {
    navigation.setOptions({
      title: `Update password`,
      headerLeft: () => <HeaderCancelButton onPress={() => navigation.goBack()} />,
      headerRight: () => <HeaderSubmitButton onPress={() => onSubmit(navigation)} disabled={submitDisabled} title="Done" />,
    });
  }, [navigation, onSubmit, submitDisabled]);

  const passwordsMatch = newPassword === confirmNewPassword;
  return (
    <View style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 16, backgroundColor: Colors.white }}>
      <FormInput
        label="Current password"
        placeholder="Old password"
        onChangeText={setCurrentPassword}
        testID="UPDATEPASSWORD_PASSWORD"
      >
        {!correctPassword && <RequirementTip display text="Current password is incorrect" />}
      </FormInput>
      <FormInput
        label="New password"
        placeholder="New password"
        onChangeText={setNewPassword}
        testID="UPDATEPASSWORD_NEWPASSWORD"
      >
        <>
          <RequirementTip
            condition={is8Chars}
            display={newPassword.trim().length > 0}
            text="Must contain at least 8 characters"
          />
          <RequirementTip
            condition={containsSpecialSymbol}
            display={newPassword.trim().length > 0}
            text="Must contain a special character (e.g. $@!%)"
          />
        </>
      </FormInput>
      <FormInput
        label="Confirm new password"
        placeholder="Confirm password"
        onChangeText={setConfirmNewPassword}
        testID="UPDATEPASSWORD_CONFIRMPASSWORD"
      >
        {confirmNewPassword !== `` && (
          <RequirementTip
            condition={passwordsMatch}
            display={newPassword.trim().length > 0}
            text={passwordsMatch ? `Hit "Done" to change your password` : `Passwords do not match`}
          />
        )}
      </FormInput>
    </View>
  );
};

type FormInputProps = {
  label: string;
  testID: string;
  onChangeText: (text: string) => void;
  placeholder: string;
};

const FormInput: React.FC<FormInputProps> = ({ label, testID, onChangeText, placeholder, children }) => {
  return (
    <View style={{ paddingTop: 10 }}>
      <Text style={localStyles.passwordLabelText}>{label}</Text>
      <PasswordInput
        autoFocus
        autocomplete={Platform.select({ web: `new-password` })}
        textContentType="newPassword"
        testID={testID}
        onChangeText={onChangeText}
        placeholder={placeholder}
      />
      <>{children}</>
    </View>
  );
};

type RequirementTipProps = {
  display?: boolean;
  condition?: boolean;
  text: string;
};

const RequirementTip: React.FC<RequirementTipProps> = ({ display = false, condition = false, text }) => {
  return (
    <View style={localStyles.passwordValidationContainer}>
      {display && (
        <MeshIcon name={condition ? `check` : `info-circle`} color={condition ? Colors.brandGreen : Colors.brightRed} size={18} />
      )}
      <Text style={localStyles.passwordValidationText}>{text}</Text>
    </View>
  );
};

const localStyles = StyleSheet.create({
  passwordValidationText: {
    fontWeight: `400`,
    color: Colors.textBlack,
    marginLeft: 4,
  },
  passwordLabelText: {
    marginTop: 4,
    paddingBottom: 5,
    ...Typography.text(`smaller`, `gray`),
  },
  passwordValidationContainer: {
    paddingTop: 8,
    flexDirection: `row`,
  },
});
