import React from 'react';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';

import CreateCommunity from '../../community-create/CreateCommunity';
import CommunityImage from '../../community-create/CommunityImage';
import CommunityName from '../../community-create/CommunityName';
import CommunityCategories from '../../community-create/CommunityCategories';
import CommunityEmail from '../../community-create/CommunityEmail';
import CommunityEmailVerification from '../../community-create/CommunityEmailVerification';
import CommunityExtraSettings from '../../community-create/CommunityExtraSettings';
import CommunityMemberVerification from '../../community-create/CommunityMemberVerification';

export type CreateNodeFlowScreens = {
  CreateCommunity: undefined;
  CommunityName: undefined;
  CommunityEmail: undefined;
  CommunityEmailVerification: {
    groupId?: string;
  };
  CommunityImage: undefined;
  CommunityCategories: undefined;
  CommunityExtraSettings: undefined;
  CommunityMemberVerification: undefined;
};

export type CreateNodeFlowGroupProps = {
  [K in keyof CreateNodeFlowScreens]: {
    screen: React.FC;
    options: NativeStackNavigationOptions;
  };
};

export const CreateNodeFlowGroup: CreateNodeFlowGroupProps = {
  CreateCommunity: { screen: CreateCommunity, options: { headerTitle: `Create a node` } },
  CommunityName: { screen: CommunityName, options: { headerTitle: `Node details` } },
  CommunityEmail: { screen: CommunityEmail, options: { headerTitle: `Node details` } },
  CommunityEmailVerification: { screen: CommunityEmailVerification, options: { headerTitle: `Node details` } },
  CommunityImage: { screen: CommunityImage, options: { headerTitle: `Node details` } },
  CommunityCategories: { screen: CommunityCategories, options: { headerTitle: `Node details` } },
  CommunityExtraSettings: { screen: CommunityExtraSettings, options: { headerTitle: `Additional node settings` } },
  CommunityMemberVerification: { screen: CommunityMemberVerification, options: { headerTitle: `Member verification` } },
};
