import { ReactNativeFile } from 'apollo-upload-client';
import ImagePicker from 'react-native-image-crop-picker';
import { Asset } from 'react-native-image-picker';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Platform } from 'react-native';
import { GroupCoreFields, GroupMember } from '../../common-types/types';
import * as Sentry from '../../constants/Sentry';
import { owner, manager, moderator, member, creator } from '../memberInfoHelpers';

// Fisher–Yates Shuffle - O(n)
export const shuffleArray = <T>(array: T[]) => {
  const results = [...array];
  let m = array.length - 1;
  let i;
  let t;

  // While there remain elements to shuffle
  while (m > 0) {
    // Pick a remaining element
    i = Math.floor(Math.random() * m);
    // And swap it with the current element.
    t = results[m];
    results[m] = results[i];
    results[i] = t;
    m -= 1;
  }
  return results;
};

export const trimTextContent = (text: string, maxLength: number) => {
  if (!text) return ``;
  if (text.length <= maxLength) return text;
  return `${text.substr(0, maxLength)}...`;
};

/**
 * Sort by most recent post
 * @param d1 Timestamp of the latest post
 * @param d2 Timestamp of the latest post to compare
 * @returns Comparision of the two timestamps
 */
// zz for null values to go last
const sortNewestFirst = (d1: string = `zz`, d2: string = `zz`) => {
  if (!d2) return -1;
  if (!d1) return 1;
  if (!d1 && !d2) return 0;
  return d2.localeCompare(d1);
};
export const sortByLatestPost = ({ latest_post: d1 }: GroupCoreFields, { latest_post: d2 }: GroupCoreFields) =>
  sortNewestFirst(d1, d2);

export interface SectionListMembers {
  title: string;
  data: GroupMember[];
}
/**
 * Create user sections and return sections array for SectionList
 * @param {GroupMember[]} groupMembers Array of group members
 */
export const createSectionListByRole = (groupMembers: GroupMember[]): SectionListMembers[] => {
  // const nonBanned = groupMembers.filter((gm) => !gm.banned); // We need to render the banned users too.
  const groupByRole = (gm: GroupMember) => gm.role_name;
  const translateRole = (role: string) => {
    if (role === `admin`) return `Manager`;
    if (role === `creator`) return `Contributor`;
    return role[0].toUpperCase() + role.substr(1);
  };
  const sections = _.chain(groupMembers)
    .groupBy(groupByRole)
    .map((members, role) => {
      return {
        title: members.length > 1 ? `${members.length} ${translateRole(role)}s` : translateRole(role),
        data: members,
      };
    })
    .value();

  return sections;
};

/**
 * Determines whether the action is allowed or not (depending the range) for the current user.
 * @param otherRole Role of the selected member
 * @param myRole Role of the current user
 * @returns True if the action performed can outrank the member
 */
export const outrank = (otherRole: string, myRole: string) =>
  (myRole === owner && (otherRole === manager || otherRole === moderator || otherRole === creator || otherRole === member)) ||
  (myRole === manager && (otherRole === moderator || otherRole === creator || otherRole === member)) ||
  (myRole === moderator && (otherRole === creator || otherRole === member)) ||
  (myRole === creator && otherRole === member);

export const withPlayerRole = (myRole: string) => {
  return (role: string) => outrank(role, myRole);
};

export const parseMemberLength = (memberCount: number) => {
  if (memberCount && memberCount > 1000) {
    return `${Math.floor(memberCount / 1000)}K`;
  }
  return `${memberCount}`;
};

export const grantMedia = async (callback: (file: Asset) => void) => {
  try {
    const image = await ImagePicker.openPicker({
      cropperCircleOverlay: true,
      cropping: true,
      // compressImageQuality: 0.9,
      sortOrder: `asc`,
      smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
    });
    const imageFile = new ReactNativeFile({
      uri: image?.path,
      type: Platform.OS === `android` ? `image/jpeg` : image.mime,
      name: uuidv4(),
    });
    callback(imageFile);
  } catch (error: any) {
    console.error(`An error ocurred while trying to update the community avatar ${error.message}`);
    Sentry.captureException(error);
  }
};

export const grantBannerMedia = async (width: number, callback: (file: Asset) => void) => {
  try {
    // HACK: library height/width is in pixels, RN dimensions are not in pixels
    // upscaling crop x2/x4 while maintaining aspect ratio does the job
    const upscaledHeight = 400;
    const image = await ImagePicker.openPicker({
      width: upscaledHeight * (width / 100),
      height: upscaledHeight,
      cropping: true,
      sortOrder: `asc`,
      smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
    });
    const imageFile = new ReactNativeFile({
      uri: image?.path,
      type: Platform.OS === `android` ? `image/jpeg` : image.mime,
      name: uuidv4(),
    });
    callback(imageFile);
  } catch (error: any) {
    console.error(`An error ocurred while trying to update the community avatar ${error.message}`);
    Sentry.captureException(error);
  }
};
