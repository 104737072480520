import React, { useState, useEffect, useContext } from 'react';
import { Text, View, ScrollView, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import 'react-native-get-random-values';
import { useNavigation } from '@react-navigation/native';
import { ChannelList, Chat } from 'stream-chat-react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { LoadingIndicator, SafeAreaView, BottomSheetModal, NoThemeButton } from '../common-ui';
import { ChannelPreview } from './ChannelPreview';
import { ChatEmptyImage } from '../images/ChatEmptyImage';
import { chatClient } from './chatClient';
import { hideChannel, acceptMessageRequest, rejectMessageRequest } from './chatServices';
import { client, GET_OWN_BLOCKED_USERS_V2 } from '../graphql';
import { CHAT_CHANNEL_TYPES } from './chatHelpers';
import { CHAT_STATUS } from './hooks/useChatService';
import { Typography, Colors } from '../common-styles';
import { AppContext } from '../../AppContext';
import { refreshList } from '../redux/inboxSlice';

const styles = StyleSheet.create({
  flexCenter: {
    flex: 1,
    justifyContent: `center`,
  },
  loadingText: {
    ...Typography.text(`gray`, `center`),
    marginTop: 10,
  },
  root: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  butttonContent: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 0.2,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: `center`,
  },
  buttonStyle: {
    borderBottomColor: Colors.brandPurple,
    padding: 10,
  },
  buttonText: {
    fontSize: Typography.baseFontSize,
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    textAlign: `center`,
  },
  searchContent: {
    flexDirection: `row`,
    alignItems: `center`,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    margin: 15,
    borderRadius: 6,
    justifyContent: `space-between`,
  },
  textInput: {
    width: `90%`,
    paddingVertical: 5,
  },
  redDot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginLeft: 5,
    marginBottom: 10,
    backgroundColor: Colors.badgeColor,
  },
  row: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
  },
});
export const ChatChannelList = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { chatNotifications = 0, setChatNotifications, user, chatStatus, setChannel } = useContext(AppContext);
  const [refreshing, setRefreshing] = useState(false);
  const [deleteChannelModalVisible, setDeleteChannelModalVisible] = useState(false);
  const [deleteChannelId, setdeleteChannelId] = useState(null);
  const [status, setStatus] = useState(`chat`);
  const [searchKey, setSearchKey] = useState(``);
  const [key, setKey] = useState(0);
  const { isListRefresh } = useSelector((state) => state.inbox);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);

  const fetchPendingCounts = () => {
    chatClient
      .queryChannels({
        type: `messaging`,
        members: { $in: [user?.id] },
        last_message_at: { $lt: new Date() },
        member_count: { $eq: 2 },
        joined: { $eq: false },
        invite: { $eq: `pending` },
      })
      .then((res) => {
        // eslint-disable-next-line promise/always-return
        if (res) {
          setPendingRequestCount(res.length);
        }
      })
      .catch((error) => console.error(`an error acurred trying to fetchPendingCounts ${error}`));
  };

  useEffect(() => {
    client.query({ query: GET_OWN_BLOCKED_USERS_V2, fetchPolicy: `network-only` }); // Fills cache
    fetchPendingCounts();
  }, []);

  useEffect(() => {
    if (isListRefresh) {
      fetchPendingCounts();
      setKey((prev) => prev + 1);
      dispatch(refreshList(false));
    }
  }, [isListRefresh, dispatch, setKey]);

  const deleteChannel = async () => {
    if (!deleteChannelId) return;
    hideDeleteChannelModal();
    await hideChannel(CHAT_CHANNEL_TYPES.messaging, deleteChannelId);
    setKey((prev) => prev + 1);
  };

  const handleOpenDeleteChannelModal = (id) => {
    if (!id) return;
    setdeleteChannelId(id);
    setDeleteChannelModalVisible(true);
  };

  const handleMessageRequestAccept = async (id) => {
    if (!id) return;
    await acceptMessageRequest(CHAT_CHANNEL_TYPES.messaging, id);
    fetchPendingCounts();
    setKey((prev) => prev + 1);
  };

  const handleMessageRequestReject = async (id) => {
    if (!id) return;
    await rejectMessageRequest(CHAT_CHANNEL_TYPES.messaging, id);
    fetchPendingCounts();
    setKey((prev) => prev + 1);
  };

  const hideDeleteChannelModal = () => {
    setdeleteChannelId(null);
    setDeleteChannelModalVisible(false);
  };

  const renderDeleteChannelModal = () => (
    <BottomSheetModal
      title="Delete conversation?"
      visible={deleteChannelModalVisible}
      hideOptionsModal={hideDeleteChannelModal}
      showConfirmBtn={false}
      onPressCancel={hideDeleteChannelModal}
    >
      <ScrollView alwaysBounceVertical={false} style={{ paddingTop: 12, paddingBottom: 50, paddingHorizontal: 16 }}>
        <Text style={{ ...Typography.text(`center`), lineHeight: 22, marginBottom: 57 }}>
          Deleting removes the conversation from your inbox, but not a recipient’s inbox.
        </Text>
        <NoThemeButton
          testID="DELETE_CHANNEL"
          rounded
          title="Delete"
          color={Colors.deleteChannelBackgroundColor}
          buttonStyle={{ paddingVertical: 13, paddingHorizontal: 10 }}
          titleStyle={{ color: Colors.deleteChannelTextColor }}
          onPress={deleteChannel}
        />
      </ScrollView>
    </BottomSheetModal>
  );

  const handleSelectChannel = (channel) => {
    setChannel(channel);
    const { unreadCount = 0 } = channel?.state || {};
    const tentativeNewCount = Math.max(chatNotifications - unreadCount, 0);
    if (tentativeNewCount !== chatNotifications) setChatNotifications(tentativeNewCount);
    navigation.navigate(`ChatThread`, {
      channel_type: channel.type,
      channel_id: channel.id,
      isRequested:
        status === `chat`
          ? null
          : {
              id: `test`,
            },
    });
  };

  // Side effect, should we move it to the componentDidMount?
  // log human-readable navigation prop values
  const chatFilters = {
    type: `messaging`,
    members: { $in: [user?.id] },
    last_message_at: { $lt: new Date() },
    member_count: { $eq: 2 },
    joined: { $eq: true },
  };

  const requestFilters = {
    type: `messaging`,
    members: { $in: [user?.id] },
    last_message_at: { $lt: new Date() },
    member_count: { $eq: 2 },
    joined: { $eq: false },
    invite: { $eq: `pending` },
  };

  if (chatStatus === CHAT_STATUS.disconnected) {
    return null;
  }

  return (
    <SafeAreaView style={styles.root}>
      <View style={{ flex: 1 }}>
        <View style={styles.searchContent}>
          <TextInput
            placeholder="Search"
            placeholderTextColor={Colors.textPlaceholder}
            style={styles.textInput}
            onChangeText={setSearchKey}
            value={searchKey}
          />
          <TouchableOpacity disabled={!searchKey} onPress={() => setSearchKey(``)}>
            <Icon name={searchKey ? `close` : `search`} color={Colors.iconColor} size={24} />
          </TouchableOpacity>
        </View>

        <View style={styles.butttonContent}>
          <TouchableOpacity onPress={() => setStatus(`chat`)} style={styles.buttonContainer}>
            <View style={{ ...styles.buttonStyle, borderBottomWidth: status === `chat` ? 1 : 0 }}>
              <Text style={{ ...styles.buttonText, color: status === `chat` ? Colors.brandPurple : Colors.iconColor }}>
                Messages
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setStatus(`request`)} style={styles.buttonContainer}>
            <View style={{ ...styles.buttonStyle, ...styles.row, borderBottomWidth: status === `request` ? 1 : 0 }}>
              <Text style={{ ...styles.buttonText, color: status === `request` ? Colors.brandPurple : Colors.iconColor }}>
                Requests
              </Text>
              {pendingRequestCount > 0 && <View style={styles.redDot} />}
            </View>
          </TouchableOpacity>
        </View>
        {!refreshing && (
          <Chat client={chatClient}>
            <ChannelList
              key={key}
              filters={status === `chat` ? chatFilters : requestFilters}
              sort={{ last_message_at: -1 }}
              LoadingIndicator={LoadingChatIndicator}
              EmptyStateIndicator={() => <EmptyList status={status} />}
              Preview={(props) => (
                <ChannelPreview
                  {...props}
                  setActiveChannel={handleSelectChannel}
                  onDelete={handleOpenDeleteChannelModal}
                  onAccept={handleMessageRequestAccept}
                  onReject={handleMessageRequestReject}
                  status={status}
                />
              )}
              additionalFlatListProps={{
                extraData: user?.id,
                refreshing,
                onRefresh: () => setRefreshing(!refreshing),
              }}
            />
          </Chat>
        )}
        {refreshing && <LoadingChatIndicator />}
        {renderDeleteChannelModal()}
      </View>
    </SafeAreaView>
  );
};

const EmptyList = ({ status }) => {
  return (
    <View style={{ flex: 1, alignItems: `center`, paddingHorizontal: 43, paddingVertical: 24 }}>
      <ChatEmptyImage style={{ marginBottom: 32 }} width="250" height="250" />

      <View style={{ justifyContent: `center` }}>
        <Text style={{ ...Typography.text(`larger`, `bold`, `center`), marginBottom: 15 }}>
          {status === `chat` ? `No messages yet` : `You have no requests`}
        </Text>
        {status === `chat` && (
          <Text style={{ ...Typography.text(`center`) }}>
            There are no messages in your inbox yet. Tap the chat button on someone’s profile to start chatting.
          </Text>
        )}
      </View>
    </View>
  );
};

EmptyList.propTypes = {
  status: PropTypes.string.isRequired,
};

const LoadingChatIndicator = () => {
  return (
    <View style={styles.flexCenter}>
      <LoadingIndicator />
      <Text style={styles.loadingText}>Loading channels</Text>
    </View>
  );
};
