import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

export const StripeImage = (props) => {
  return (
    <Svg width="66px" height="46px" viewBox="0 0 66 46" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <Path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill="#6772E5"
          transform="translate(0 158)"
        />
        <G transform="translate(38 5604) translate(0 158) translate(13 15)" fill="#FFF">
          <Path d="M40 8.772c0-2.901-1.378-5.19-4.011-5.19-2.645 0-4.245 2.289-4.245 5.167 0 3.412 1.89 5.134 4.6 5.134 1.323 0 2.323-.306 3.078-.736V10.88c-.755.385-1.622.623-2.722.623-1.078 0-2.033-.385-2.156-1.722h5.434c0-.148.022-.737.022-1.009zm-5.489-1.077c0-1.28.767-1.813 1.467-1.813.678 0 1.4.533 1.4 1.813H34.51zM27.456 3.581c-1.09 0-1.79.522-2.178.884l-.145-.702H22.69v13.214l2.778-.6.01-3.208c.4.295.99.714 1.967.714 1.99 0 3.8-1.632 3.8-5.224-.01-3.287-1.844-5.078-3.788-5.078zm-.667 7.809c-.656 0-1.045-.238-1.311-.533l-.011-4.204c.289-.329.689-.556 1.322-.556 1.011 0 1.711 1.156 1.711 2.641 0 1.519-.689 2.652-1.711 2.652z" />
          <Path d="M18.8666667 2.91266667L21.6555556 2.30066667 21.6555556 0 18.8666667 0.600666667z" />
          <Path d="M18.8666667 3.774H21.65555559V13.690666669999999H18.8666667z" />
          <Path d="M15.878 4.613l-.178-.839h-2.4v9.917h2.778V6.97c.655-.873 1.766-.714 2.11-.59V3.775c-.355-.136-1.655-.385-2.31.839zM10.322 1.315l-2.71.589-.012 9.078c0 1.677 1.233 2.913 2.878 2.913.91 0 1.578-.17 1.944-.374V11.22c-.355.147-2.11.669-2.11-1.009V6.188h2.11V3.774h-2.11l.01-2.46zM2.811 6.653c0-.442.356-.612.945-.612.844 0 1.91.26 2.755.725V4.103a7.2 7.2 0 00-2.755-.522C1.5 3.581 0 4.783 0 6.79c0 3.128 4.222 2.629 4.222 3.978 0 .521-.444.691-1.066.691-.923 0-2.1-.385-3.034-.907v2.698a7.573 7.573 0 003.034.646c2.31 0 3.9-1.168 3.9-3.196C7.044 7.32 2.81 7.922 2.81 6.653z" />
        </G>
      </G>
    </Svg>
  );
};
