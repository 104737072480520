import React from 'react';
import { TouchableOpacity } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { MeshIcon } from './MeshIcon';
import { Colors } from '../common-styles';
import { _BACK } from './testLabels';

type BackButtonProps = {
  color?: string;
  onPress?: () => void;
};

type screens = {
  BackButton: {
    rightDrawer?: boolean;
  };
  HomeScreen: undefined;
  RightDrawer: undefined;
  MainTabs: undefined;
};

type BackButtonRoute = RouteProp<screens, `BackButton`>;

export const BackButton: React.FC<BackButtonProps> = ({ color, onPress }) => {
  const navigation = useNavigation<any>();
  const route = useRoute<BackButtonRoute>();
  const { rightDrawer } = route?.params || {};

  const goBack = () => {
    if (rightDrawer) {
      navigation.navigate(`HomeScreen`);
      navigation.getParent(`RightDrawer`)?.openDrawer();
    } else if (route.key.startsWith(`Profile-`)) {
      navigation.navigate(`MainTabs`);
    } else navigation.goBack();
  };

  return (
    <TouchableOpacity
      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
      testID="BACK"
      onPress={onPress || goBack}
      style={{ paddingTop: 0, paddingBottom: 4, paddingLeft: 6, paddingRight: 10 }}
    >
      <MeshIcon size={24} name="chevron-left" color={color || Colors.iconColor} />
    </TouchableOpacity>
  );
};
