import React, { memo, useCallback, useContext, useEffect } from 'react';
import { Image, Linking, Text, View, TouchableHighlight, StyleSheet } from 'react-native';
import { random } from 'lodash';
import { ErrorBoundary, ThemedButton, ThreeDotsButton } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { addTelemetryEvent } from '../telemetry/Events';
import { CommunityAd, GroupCoreFields, GroupMember } from '../common-types/types';
import { Events, getCommunityRole, PendoTrackEvent } from '../pendo/events';
import { AppContext } from '../../AppContext';

// - - - - - - - - - - - - - - - - - - -
// AdHeader is the header above the content block. It contains the advertiser who posted, and
//  a three-dot menu to hide/report the ad

interface AdHeaderProps {
  showOptionsMenu?: (ad: CommunityAd) => void;
  ad: CommunityAd;
  index: number;
  onScreen: boolean;
  group: GroupCoreFields;
  group_member?: GroupMember;
}

interface AdContentProps {
  width: number;
  ad: CommunityAd;
  index: number;
  group: GroupCoreFields;
  group_member?: GroupMember;
}

export const AdHeader: React.FC<AdHeaderProps> = memo(({ showOptionsMenu, ad, index, group, group_member, onScreen }) => {
  const { id, advertiser, link_url, group_id, ad_name } = ad;
  const { avatar_url, name } = advertiser || {};
  const { user, isGuestMode } = useContext(AppContext);
  const community_role = getCommunityRole(isGuestMode, group_member);
  const onPress = () => (showOptionsMenu ? showOptionsMenu(ad) : null);
  useEffect(() => {
    if (!onScreen) return;
    if (group_id !== group_member?.group_id) return;
    const [, , ad_id] = id.split(`:`);
    const input = {
      ad_id,
      ad_position: index,
      username: user?.handle,
      community_name: group?.name,
      community_role,
      //advertiser_id: advertiser?.id,
      advertiser_name: advertiser?.name,
      ad_name,
      ad_url: link_url,
    };
    PendoTrackEvent(Events.AD_SEEN, input);
  }, [
    onScreen,
    id,
    ad_name,
    user?.handle,
    advertiser?.name,
    link_url,
    isGuestMode,
    group_member?.group_id,
    group_id,
    index,
    group?.name,
    community_role,
  ]);

  const openLinkUrl = useCallback(async () => {
    addTelemetryEvent(`ad.click`, { id, group_id: group?.id, index, member: !!group_member, url: link_url });
    const [, , ad_id] = id.split(`:`);
    PendoTrackEvent(Events.AD_CLICK, {
      ad_id,
      ad_position: index,
      ad_name,
      username: user?.handle,
      community_name: group?.name,
      community_role,
      advertiser_name: advertiser?.name,
      ad_url: link_url,
    });
    await openUrlWithUtm(link_url, encodeURIComponent(group?.name), advertiser?.handle);
  }, [id, group?.id, index, group_member, link_url, user?.handle, group?.name, community_role, advertiser, ad_name]);

  return (
    <TouchableHighlight underlayColor={Colors.underlayColor} onPress={openLinkUrl}>
      <View style={styles.AdHeader}>
        <ErrorBoundary header="Error displaying header">
          {/* avatar */}
          <Image source={{ uri: avatar_url }} resizeMode="cover" style={styles.adContentImage} />
          <View style={{ flex: 1 }}>
            <View style={{ marginHorizontal: 8 }}>
              {/* advertiser name */}
              <Text
                onPress={openLinkUrl}
                maxFontSizeMultiplier={1.5}
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.advertiserText}
              >
                {name}
              </Text>
              {/* Sponsored */}
              <Text maxFontSizeMultiplier={1.5} numberOfLines={1} ellipsizeMode="tail" style={styles.advertiserSubtext}>
                Community sponsor
              </Text>
            </View>
          </View>
          {/* options */}
          {!!showOptionsMenu && <ThreeDotsButton horizontal onPress={onPress} testID="POST_3DOTS" />}
        </ErrorBoundary>
      </View>
    </TouchableHighlight>
  );
});

// - - - - - - - - - - - - - - - - - - -
// AdContent is the square-ish content block.

// eslint-disable-next-line no-unused-vars
export const AdContent: React.FC<AdContentProps> = memo(({ width, ad, group, index, group_member }) => {
  const imageHeight = (width * 200) / 375;
  const { id, image_urls, link_url, copy, button, button_url, ad_name } = ad;
  const imageIndex = image_urls && image_urls.length > 0 ? random(image_urls.length - 1) : 0;
  const { user, isGuestMode } = useContext(AppContext);
  const community_role = getCommunityRole(isGuestMode, group_member);

  const openLinkUrl = useCallback(() => {
    addTelemetryEvent(`ad.click`, { id, group_id: group?.id, index, member: !!group_member, url: link_url });
    const [, , ad_id] = id.split(`:`);
    PendoTrackEvent(Events.AD_CLICK, {
      ad_id,
      ad_name,
      ad_position: index,
      username: user?.handle,
      community_name: group?.name,
      community_role,
      advertiser_name: ad?.advertiser?.name,
      ad_url: link_url,
    });
    openUrlWithUtm(link_url, encodeURIComponent(group?.name), ad?.advertiser?.handle);
  }, [ad?.advertiser, community_role, group?.name, group?.id, group_member, id, index, link_url, user?.handle, ad_name]);

  const openButtonUrl = useCallback(() => {
    addTelemetryEvent(`ad.click`, { id, group_id: group?.id, index, member: !!group_member, url: button_url });
    const [, , ad_id] = id.split(`:`);
    PendoTrackEvent(Events.AD_CLICK, {
      ad_id,
      ad_name,
      ad_position: index,
      username: user?.handle,
      community_name: group?.name,
      community_role,
      advertiser_name: ad?.advertiser?.name,
      ad_url: button_url,
    });
    openUrlWithUtm(button_url, encodeURIComponent(group?.name), ad?.advertiser?.handle);
  }, [id, group?.id, index, group_member, button_url, user?.handle, group?.name, community_role, ad?.advertiser, ad_name]);

  return (
    <>
      <TouchableHighlight
        underlayColor={Colors.underlayColor}
        disabled={!link_url}
        onPress={openLinkUrl}
        style={{ flex: 1, width }}
      >
        <View style={{ flex: 1, width }}>
          <Image source={{ uri: image_urls[imageIndex] }} resizeMode="cover" style={{ width, height: imageHeight }} />
          <View style={{ height: 12 }} />
          <Text maxFontSizeMultiplier={1.5} style={{ ...Typography.text(`small`), lineHeight: 19, marginHorizontal: 16 }}>
            {copy}
          </Text>
          <View style={{ height: 6 }} />
        </View>
      </TouchableHighlight>
      <View style={styles.center}>
        <View style={{ height: 6 }} />
        <ThemedButton
          rounded
          title={button || `Learn More`}
          buttonStyle={styles.adButton}
          titleStyle={{ ...Typography.add(`base`), color: Colors.adButtonText }}
          onPress={openButtonUrl}
        />
        <View style={{ height: 12 }} />
      </View>
    </>
  );
});

export const openUrlWithUtm = async (url: string, community: string, _advertiser?: string) => {
  let utm_params = `?utm_source=mesh_app&utm_medium=${community}`;
  if (_advertiser) utm_params = `${utm_params}&utm_campaign=${_advertiser}`;
  if (!url.endsWith(`/`)) utm_params = `/${utm_params}`;
  await Linking.openURL(`${url}${utm_params}`);
};

const styles = StyleSheet.create({
  AdHeader: {
    flexDirection: `row`,
    alignItems: `center`,
    backgroundColor: Colors.white,
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  adContentImage: { width: 32, height: 32, borderRadius: 999 },
  center: { alignItems: `center`, justifyContent: `center` },
  adButton: { paddingVertical: 7, paddingHorizontal: 22, backgroundColor: Colors.adButtonBackground },
  advertiserText: {
    ...Typography.text(`small`, `bold`),
    letterSpacing: 0,
  },
  advertiserSubtext: {
    ...Typography.text(`small`, `gray`),
    marginTop: 4,
    letterSpacing: 0,
  },
});
