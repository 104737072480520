import { useMutation, useQuery } from '@apollo/react-hooks';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { NetworkStatus } from 'apollo-client';
import { unionBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Animated,
  SectionListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  Keyboard,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { useAppDispatch, RootState } from '../../redux/store';
import { setMemberCount } from '../../redux/feedSlice';
import { Colors, globalStyles, Typography } from '../../common-styles';
import {
  BanUserData,
  GroupMember,
  LiftBanData,
  ListGroupMembersVars,
  TargetBanUser,
  TargetLiftBan,
  UpdateRoleData,
  UpdateRoleInput,
  User,
  Group,
  BottomSheetOptions,
  MembersAndCountData,
} from '../../common-types/types';
import { Avatar, BottomSheetModal, ListItem, LoadingIndicator, QueryError, ThreeDotsButton, MeshIcon } from '../../common-ui';
import { useBottomSheetOptions } from '../../common-util/hooks/useBottomSheetOptions';
import { useDisclosure } from '../../common-util/hooks/useDisclosure';
import {
  BAN_USER_FROM_GROUP_DIRECT,
  LIFT_USER_BAN,
  loadCachedGroupMember,
  refetchQueriesFor,
  UPDATE_ROLE,
  REMOVE_USER_FROM_GROUP,
  loadCachedGroup,
  LIST_MEMBERS_AND_COUNTS,
  COMMUNITY_SETTING,
} from '../../graphql';
import { handleCreateConversation as createConversation, owner } from '../memberInfoHelpers';
import { createSectionListByRole, SectionListMembers } from './helpers';
import { useCommunityHeader } from './hooks/useCommunityHeader';
import { useCommunities } from './hooks/useCommunities';
import { MODERATION_TYPE } from '../../moderation/moderationEnums';
import { useFollow } from './hooks/useFollow';
import { Events, PendoTrackEvent } from '../../pendo/events';
import { MeshVerifiedCheck } from '../../images';

/**
 * TODO:
 * Fragment components shoul not query resources, or at least keep it at minimun
 * the parent component should be querying data and passing it to this FragmentComponent
 * It might be a good idea to use redux for sharing group, membership, and group settings
 *
 * Queries to minimize = [
 *   COMMUNITY_SETTING (shares withuseSetting)
 *   LIST_MEMBERS_AND_COUNTS (shares listGroupMember with about)
 * ]
 * this component is used in [
 *   HomeScreen.tsx,
 *   as its own screen (is it really needed now?)
 * ]
 *
 */

export type MemberListParamList = {
  MemberList: {
    groupId: string;
    applyMargin?: boolean;
    onScroll?: (...args: any[]) => void;
  };
  BannedUsersList: {
    group: Group;
  };
  SearchUsersScreen: {
    group_id: string;
    searchText: string;
  };
  Profile: {
    user: User;
  };
  ChatNavigator: {
    screen: string;
    params: {
      channel_type: string;
      channel_id: string;
    };
  };
  ProfileStack: {
    screen: string;
    params: {
      user: User;
    };
  };
};

// eslint-disable-next-line quotes
type MembersNavigationProp = StackNavigationProp<MemberListParamList, 'MemberList'>;

// eslint-disable-next-line quotes
type MemberListRoute = RouteProp<MemberListParamList, 'MemberList'>;

enum OwnerGiveUpSource {
  LEAVE_COMMUNITY = `leave_community`,
  STEP_DOWN_ROLE = `STEP_DOWN_ROLE`,
}

const PAGE_SIZE = 40;
export const MemberList = () => {
  const { params } = useRoute<MemberListRoute>();
  return <MemberListFragment groupId={params?.groupId} onScroll={params?.onScroll} feedContainerStyle={{ minHeight: `auto` }} />;
};

type MemberListFragmentProps = {
  groupId: string;
  contentOffset?: any;
  onScroll?: (...args: any[]) => void;
  feedContainerStyle?: StyleProp<ViewStyle>;
};

const ListFragment: React.FC<MemberListFragmentProps> = ({ groupId, contentOffset, onScroll, feedContainerStyle = {} }) => {
  const dispatch = useAppDispatch();
  const { user, chatNotifications, setChatNotifications, setChannel } = useContext(AppContext);
  const navigation = useNavigation<any>();
  const { setActiveCommunityAfterArchive } = useCommunities();
  const { activeCommunity } = useSelector((state: RootState) => state.feed);
  const {
    data: groupMembersData,
    networkStatus,
    refetch,
    fetchMore,
    loading,
    error,
  } = useQuery<MembersAndCountData, ListGroupMembersVars>(LIST_MEMBERS_AND_COUNTS, {
    variables: { group_id: groupId, offset: 0, limit: 40 },
  });

  const { data: listMemberVisibilitySetting } = useQuery(COMMUNITY_SETTING, {
    fetchPolicy: `cache-and-network`,
    variables: { input: { group_id: groupId, key: `group.setting.list_members` } },
  });

  const [selectedMember, setSelectedMember] = useState<GroupMember | null>(null);
  const [selectedRole, setSelectedRole] = useState(``);

  const [sections, setSections] = useState<SectionListMembers[]>([]);
  const [multiOwners, setMultiOwners] = useState(false);

  const [options, setOptions] = useState<BottomSheetOptions[]>([]);
  const [promptOwner, setPromptOwner] = useState(false);
  const [promptOwnerGiveUp, setPromptOwnerGiveUp] = useState(false);

  const [ownerGiveupType, setOwnerGiveUpType] = useState(``);

  const { onToggle: onToggleMemberMenu, isOpen, onOpen: onOpenMenu } = useDisclosure();
  const { onToggle: onToggleUnBanModal, isOpen: isOpenUnbanModal } = useDisclosure();
  const { onToggle: onTogglePromptOwner, isOpen: isOpenPromptOwner } = useDisclosure();
  const { onToggle: onTogglePromptOwnerGiveUp, isOpen: isOpenPromptOwnerGiveUp } = useDisclosure();
  const { buildMemberListOptions } = useBottomSheetOptions(onToggleMemberMenu);

  const group = useMemo(() => loadCachedGroup(groupId), [groupId]);

  const [groupMember, setGroupMember] = useState<GroupMember>(null);

  useEffect(() => {
    const gm = loadCachedGroupMember(groupId, user?.id);
    setGroupMember(gm);
  }, [groupId, user?.id]);

  const [updateRole] = useMutation<UpdateRoleData, UpdateRoleInput>(UPDATE_ROLE, {
    onCompleted: (data) => {
      setGroupMember(data?.updateGroupMemberRole2?.group_member);
    },
  });

  const [banUserMut] = useMutation<BanUserData, TargetBanUser>(BAN_USER_FROM_GROUP_DIRECT, {
    onCompleted: () => refetch(),
    update: (_cache, { data }) => {
      try {
        if (data?.banUserFromGroupDirect2) dispatch(setMemberCount(data?.banUserFromGroupDirect2?.group));
      } catch (error) {
        console.log(`cache error -> ${error}`);
      }
    },
  });
  const [liftBanMut] = useMutation<LiftBanData, TargetLiftBan>(LIFT_USER_BAN, {
    onCompleted: () => refetch(),
    update: (_cache, { data }) => {
      try {
        if (data?.liftUserBanForGroup) dispatch(setMemberCount(data?.liftUserBanForGroup?.group));
      } catch (error) {
        console.log(`cache error -> ${error}`);
      }
    },
  });

  const [removeFromGroup] = useMutation(REMOVE_USER_FROM_GROUP, {
    onCompleted: () => {
      refetch();
    },
    update: (cache, { data }) => {
      try {
        if (data?.removeUserFromGroup2 && group)
          dispatch(setMemberCount({ ...group, group_member_count: data?.removeUserFromGroup2?.count }));
      } catch (error) {
        console.log(`cache -> ${cache}`);
        console.error(`[CacheError] failed to delete cached record: GroupMember:${groupMember?.id}`, error);
      }
    },
  });

  const { handleJoinOrLeaveCommunity, justLeftGroup } = useCommunityHeader({
    mode: `home`,
    groupMember,
    onChangeMember: () => null,
    onChangeGroup: () => null,
  });

  const { followUser, unfollowUser } = useFollow();

  useEffect(() => {
    if (error) console.warn(`[MembersError]`, error.message);
    if (groupMembersData) {
      const groupMemberList = groupMembersData?.listGroupMembers;
      const owners = groupMemberList.filter((e) => e.role_name === `owner`);
      if (owners.length > 1) setMultiOwners(true);
      const sections = createSectionListByRole(groupMemberList);
      setSections(sections);
    }
  }, [groupMembersData, error]);

  const handleUpdateRole = useCallback(
    (selectedMember: any, roleId: string = ``) => {
      const variables = {
        group_id: groupId,
        persona_id: selectedMember?.persona_id,
        role_id: roleId || selectedRole,
      };
      if (isOpenPromptOwner) onTogglePromptOwner();
      if (isOpenPromptOwnerGiveUp) onTogglePromptOwnerGiveUp();

      updateRole({
        variables,
        refetchQueries: refetchQueriesFor(`GroupMember`, `Persona`, `Group`),
      });
      setSelectedMember(null);
      setPromptOwner(false);
      setOwnerGiveUpType(``);
      setPromptOwnerGiveUp(false);
    },
    [
      selectedRole,
      isOpenPromptOwner,
      isOpenPromptOwnerGiveUp,
      onTogglePromptOwner,
      onTogglePromptOwnerGiveUp,
      setSelectedMember,
      groupId,
      setOwnerGiveUpType,
      updateRole,
      setPromptOwnerGiveUp,
    ],
  );

  const handleDemoteOrPromote = useCallback(
    (toRole: string, selectedMember: GroupMember) => {
      onToggleMemberMenu();
      setSelectedMember(selectedMember);
      setSelectedRole(toRole);

      if (toRole === owner) {
        setTimeout(() => {
          onTogglePromptOwner();
        }, 500);
      } else if (toRole !== owner && selectedMember.role_name !== `owner`) {
        handleUpdateRole(selectedMember, toRole);
      } else if (toRole !== owner && selectedMember.role_name === `owner`) {
        setOwnerGiveUpType(OwnerGiveUpSource.STEP_DOWN_ROLE);
        setTimeout(() => {
          onTogglePromptOwnerGiveUp();
        }, 500);
      }
    },
    [
      handleUpdateRole,
      onToggleMemberMenu,
      onTogglePromptOwner,
      setSelectedMember,
      setSelectedRole,
      onTogglePromptOwnerGiveUp,
      setOwnerGiveUpType,
    ],
  );
  const _handleLeaveCommunity = useCallback(() => {
    setOwnerGiveUpType(``);
    setPromptOwnerGiveUp(false);
    if (isOpenPromptOwnerGiveUp) onTogglePromptOwnerGiveUp();

    setTimeout(async () => {
      handleJoinOrLeaveCommunity();
      await setActiveCommunityAfterArchive();
    }, 500);
  }, [
    handleJoinOrLeaveCommunity,
    setActiveCommunityAfterArchive,
    onTogglePromptOwnerGiveUp,
    setOwnerGiveUpType,
    setPromptOwnerGiveUp,
    isOpenPromptOwnerGiveUp,
  ]);

  useEffect(() => {
    if (justLeftGroup) navigation.navigate(`HomeScreen`);
  }, [justLeftGroup, navigation]);

  useEffect(() => {
    if (
      (promptOwner || (promptOwnerGiveUp && ownerGiveupType === OwnerGiveUpSource.STEP_DOWN_ROLE)) &&
      selectedMember &&
      selectedRole
    ) {
      handleUpdateRole(selectedMember);
    }
    if (promptOwnerGiveUp && ownerGiveupType === OwnerGiveUpSource.LEAVE_COMMUNITY) {
      _handleLeaveCommunity();
    }
  }, [promptOwner, promptOwnerGiveUp, ownerGiveupType, selectedMember, selectedRole, handleUpdateRole, _handleLeaveCommunity]);

  const handleShowReportModal = useCallback(
    (type: string, id: string | null, reportee?: string) => {
      onToggleMemberMenu();
      navigation.navigate(`ReportScreen`, {
        reportType: type,
        id,
        origin_group_id: activeCommunity?.id,
        reportee,
      });
    },
    [onToggleMemberMenu, navigation, activeCommunity],
  );

  const handleLiftBan = () => {
    if (selectedMember && groupId) {
      liftBanMut({
        variables: { persona_id: selectedMember.persona_id, group_id: groupId },
      });
    }
    onToggleUnBanModal();
  };

  const leaveCommunityFromMemberList = useCallback(() => {
    onToggleMemberMenu();
    if (user?.id === groupMember?.persona_id && groupMember?.role_name === `owner`) {
      setOwnerGiveUpType(OwnerGiveUpSource.LEAVE_COMMUNITY);
      setTimeout(() => {
        onTogglePromptOwnerGiveUp();
      }, 500);
    } else {
      _handleLeaveCommunity();
    }
  }, [
    onToggleMemberMenu,
    user?.id,
    groupMember?.persona_id,
    groupMember?.role_name,
    onTogglePromptOwnerGiveUp,
    _handleLeaveCommunity,
  ]);

  const handleMemberPress = useCallback(
    (selectedMember: GroupMember) => {
      if (groupMember) {
        const options = buildMemberListOptions({
          selectedMember,
          groupMember,
          multiOwners,
          handleRemoveGroupMember: () => {
            onToggleMemberMenu();
            if (selectedMember?.persona_id && groupId) {
              removeFromGroup({ variables: { target_persona_id: selectedMember?.persona_id, group_id: groupId } });
            }
          },
          handleCreateConversation: () =>
            createConversation(
              { me: user, chatNotifications, setChatNotifications, setChannel },
              selectedMember.persona,
              navigation.getParent(),
            ),
          handleUnban: () => {
            onToggleMemberMenu();
            setTimeout(() => {
              onToggleUnBanModal();
            }, 500);
          },
          handleBan: () => {
            onToggleMemberMenu();
            if (groupId) {
              banUserMut({ variables: { group_id: groupId, target_persona_id: selectedMember.persona_id } });
            }
          },
          handleDemoteOrPromote: (toRole) => {
            handleDemoteOrPromote(toRole, selectedMember);
          },
          handleReport: (type, id) => handleShowReportModal(type, id, selectedMember?.persona_id),
          handleViewProfile: () => {
            PendoTrackEvent(Events.PROFILE, {
              username: user?.handle,
              source: `Member list`,
              element: `Member list`,
            });
            navigation.navigate(`ProfileStack`, { screen: `Profile`, params: { user: selectedMember?.persona } });
            onToggleMemberMenu();
          },
          handleLeaveCommunity: leaveCommunityFromMemberList,
          handleReportCommunity: () => {
            handleShowReportModal(MODERATION_TYPE.community, groupId);
          },
          handleFollow: async (isFollowed, actor) => {
            try {
              if (isFollowed) {
                await unfollowUser(actor);
              } else {
                await followUser(actor);
              }
              refetch();
            } catch (error) {
              console.log(error);
            }
          },
        });
        onOpenMenu();
        setOptions(options);
      }
    },
    [
      navigation,
      user,
      chatNotifications,
      groupId,
      groupMember,
      multiOwners,
      banUserMut,
      removeFromGroup,
      buildMemberListOptions,
      onToggleMemberMenu,
      onOpenMenu,
      onToggleUnBanModal,
      handleDemoteOrPromote,
      handleShowReportModal,
      setChatNotifications,
      leaveCommunityFromMemberList,
    ],
  );

  const renderItem: SectionListRenderItem<GroupMember, SectionListMembers> = useCallback(
    ({ item }) => {
      if (item?.banned) return null;
      return <Member groupMember={item} onMemberPress={handleMemberPress} />;
    },
    [handleMemberPress],
  );

  const renderSectionHeader = useCallback(
    ({ section: { title } }) => {
      let titleValue = title;
      if (titleValue === `Admin`) titleValue = `Manager`;

      if (titleValue.includes(`Member`)) {
        const [memberCount] =
          groupMembersData?.getGroupMembersCount?.data?.filter((c) => {
            return c.role_name === `member`;
          }) || [];
        if (memberCount?.counts > 1) {
          titleValue = `${memberCount?.counts} Members`;
        } else titleValue = title;
      }

      return (
        <View
          style={{
            flex: 1,
            flexDirection: `column`,
            paddingVertical: 5,
            backgroundColor: Colors.lightWarmGray,
            borderBottomColor: Colors.lightGray,
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        >
          <Text style={{ color: Colors.mediumGray, fontSize: 16, flexShrink: 2, marginHorizontal: 10 }}>{titleValue}</Text>
        </View>
      );
    },
    [groupMembersData?.getGroupMembersCount?.data],
  );

  const renderSectionFooter = useCallback(() => {
    if (
      listMemberVisibilitySetting?.getCommunitySetting?.value === `anyone` ||
      groupMember?.role_name === `owner` ||
      groupMember?.role_name === `manager`
    )
      return null;
    return (
      <View style={styles.listEmpty}>
        <Text style={Typography.text(`theme`, `bold`, `center`)}>Member list is private for this community.</Text>
      </View>
    );
  }, [listMemberVisibilitySetting, groupMember]);

  const handleOnEndReached = () => {
    //const totalMembers = sections.reduce((result, section) => result + section.data.length, 0);
    const totalMembers = groupMembersData?.listGroupMembers?.length || 0;
    // due to some communities having fewer members we can skip an innecesary fetch for more data
    // if we know the previous batch returned less than a PAGE_SIZE items
    const skipIfPrevNotfull = totalMembers % PAGE_SIZE !== 0;
    // should not fetch more if offset is 0, if so initial query already brought all topics
    const skipIfOffsetZero = totalMembers === 0;
    if (!loading && !skipIfPrevNotfull && !skipIfOffsetZero) {
      fetchMore({
        variables: { offset: totalMembers },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult.listGroupMembers.length ||
            fetchMoreResult.listGroupMembers.slice(-1)[0].id === prev.listGroupMembers.slice(-1)[0].id
          ) {
            console.log(`[members]is the same, return prev`);
            return prev;
          }
          const newSections = createSectionListByRole(
            unionBy(prev.listGroupMembers, fetchMoreResult.listGroupMembers, (gm) => gm.persona_id),
          );
          setSections(newSections);
          return {
            ...prev,
            listGroupMembers: unionBy(prev.listGroupMembers, fetchMoreResult.listGroupMembers, (gm) => gm.persona_id),
          };
        },
      });
    }
  };

  if (loading) {
    return (
      <View style={globalStyles.centeredContainer}>
        <LoadingIndicator size="small" />
        <Text style={globalStyles.loadingText}>Loading</Text>
      </View>
    );
  }

  if (error) {
    return <QueryError error={error} networkStatus={networkStatus} refetch={refetch} />;
  }

  return (
    <Animated.SectionList
      sections={sections}
      contentOffset={contentOffset}
      contentContainerStyle={feedContainerStyle}
      keyboardShouldPersistTaps="handled"
      testID="MEMBER_LIST"
      stickySectionHeadersEnabled={false}
      ListHeaderComponent={
        <>
          <SearchBarInput groupId={groupId} />
          <BottomSheetModal
            onPressCancel={onToggleUnBanModal}
            visible={isOpenUnbanModal}
            title="Are you sure?"
            showCancelBtn={false}
            confirmTitle="Unban user"
            onPressConfirm={handleLiftBan}
          >
            <View style={{ padding: 16 }}>
              <Text style={{ marginBottom: 32 }}>
                Are you sure you want to unban {selectedMember?.persona.handle}? They will be reinstated as a member of your
                community.
              </Text>
            </View>
          </BottomSheetModal>

          <BottomSheetModal
            options={options}
            title="Member Options"
            visible={isOpen}
            onPressCancel={onToggleMemberMenu}
            onPressConfirm={onToggleMemberMenu}
          />

          <BottomSheetModal
            onPressCancel={onTogglePromptOwner}
            visible={isOpenPromptOwner}
            title="Confirm"
            cancelTitle="No"
            confirmTitle="Yes"
            onPressConfirm={() => setPromptOwner(true)}
          >
            <View style={{ padding: 16 }}>
              <Text style={{ marginBottom: 32 }}>
                You are about to make {selectedMember?.persona.handle} an owner of {group?.name}, giving them all the rights and
                privileges of ownership. Do you want to continue?
              </Text>
            </View>
          </BottomSheetModal>

          <BottomSheetModal
            onPressCancel={onTogglePromptOwnerGiveUp}
            visible={isOpenPromptOwnerGiveUp}
            title="Confirm"
            cancelTitle="No"
            confirmTitle="Yes"
            onPressConfirm={() => setPromptOwnerGiveUp(true)}
          >
            <View style={{ padding: 16 }}>
              <Text style={{ marginBottom: 32 }}>
                You are about to step down as owner of {group?.name} and will give up any rights to this community. Do you want to
                continue?
              </Text>
            </View>
          </BottomSheetModal>
        </>
      }
      refreshing={networkStatus === NetworkStatus.refetch}
      onRefresh={() => refetch()}
      onEndReachedThreshold={1.5}
      onEndReached={handleOnEndReached}
      onScroll={onScroll}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      ListFooterComponent={renderSectionFooter}
      keyExtractor={(item) => item.id}
    />
  );
};

export const MemberListFragment = React.memo(ListFragment);

type MemberProps = {
  groupMember: GroupMember;
  onMemberPress: (gm: GroupMember) => void;
};

const Member = ({ groupMember, onMemberPress }: MemberProps) => {
  const navigation = useNavigation<any>();
  const { persona, banned } = groupMember;
  const personaTitle = (
    <View style={{ flexDirection: `row`, alignItems: `center` }}>
      <Text style={{ fontWeight: `500`, fontSize: 17 }}>{persona.name || persona.handle}</Text>
      {persona.isVerified && <MeshVerifiedCheck height={16} width={16} style={{ marginLeft: 5 }} />}
    </View>
  );
  const SubTitle = (
    <View style={{ flexDirection: `row`, alignItems: `center` }} testID={persona.handle}>
      <Text style={{ color: Colors.textGray }}>{persona.handle}</Text>
      {persona.pronouns !== null && (
        <View style={{ flexDirection: `row`, justifyContent: `center`, alignItems: `center` }}>
          {persona && persona.pronouns.length > 0 && <Text style={{ fontWeight: `bold`, fontSize: 22 }}> · </Text>}
          <Text style={{ color: Colors.textGray }}>{persona && persona.pronouns.length > 0 && persona.pronouns[0].pronoun}</Text>
        </View>
      )}
    </View>
  );

  const renderIsBannedIcon = (banned?: boolean) => {
    if (!banned) return null;
    return <Text style={{ fontSize: 12, color: Colors.mediumGray }}>BANNED</Text>;
  };

  const handleMemberPress = () => {
    onMemberPress(groupMember);
  };

  return (
    <ListItem
      testID={`MEMBER_${persona.handle}`}
      title={personaTitle}
      subtitle={SubTitle}
      leftAvatar={<Avatar user={persona} size={48} testID={`TO_${persona.handle}_PROFILE`} isSameStack={true} />}
      rightIcon={renderIsBannedIcon(banned)}
      rightElement={
        <ThreeDotsButton onPress={handleMemberPress} size={24} style={styles.menuDots} testID={`OPTIONS_FOR_${persona.handle}`} />
      }
      onPress={() => navigation.navigate(`Profile`, { user: persona, isSameStack: true })}
    />
  );
};

type SearchBarInputProps = {
  groupId: string;
};

const SearchBarInput: React.FC<SearchBarInputProps> = ({ groupId }) => {
  const navigation = useNavigation<MembersNavigationProp>();
  const [searchText, setSearchText] = useState(``);
  const handleSearchUsers = () => {
    if (searchText.trim().length < 0) return;
    navigation.push(`SearchUsersScreen`, { searchText, group_id: groupId });
  };
  return (
    <View style={styles.searchBoxContainer}>
      {searchText.length > 0 && (
        <TouchableOpacity
          hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
          onPress={() => {
            Keyboard.dismiss();
            setSearchText(``);
          }}
        >
          <MeshIcon style={{ marginLeft: 10 }} name="cross" size={12} />
        </TouchableOpacity>
      )}

      <TextInput
        onSubmitEditing={handleSearchUsers}
        placeholder="Search members"
        style={{ flex: 1, padding: 10 }}
        onChangeText={setSearchText}
        value={searchText}
      />
      <TouchableOpacity
        hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
        onPress={handleSearchUsers}
        style={{ padding: 10 }}
        disabled={searchText.length < 1}
      >
        <MeshIcon name="magnifyingglass" size={16} color={searchText.length >= 1 ? Colors.brandPurple : Colors.mediumGray} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  menuDots: { alignItems: `flex-end` },
  searchBoxContainer: {
    marginHorizontal: 16,
    marginVertical: 12,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    borderRadius: 4,
    borderColor: Colors.dividerColor,
    borderWidth: 1,
  },
  listEmpty: {
    backgroundColor: Colors.badgePlusBackground,
    borderRadius: 7,
    flex: 1,
    marginHorizontal: 16,
    marginVertical: 16,
    height: 40,
    justifyContent: `center`,
    alignItems: `center`,
  },
});
