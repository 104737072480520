import { useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { TopicItem } from '../../../common-types/types';
import { GET_POPULAR_TOPICS } from '../../../graphql';

interface TopicProps {
  groupId: string;
  popularTopicLimits: number;
}

type PopularTopicProps = {
  getPopularTopicsList: TopicItem[];
};

export const useTopic = ({ groupId, popularTopicLimits }: TopicProps) => {
  const { data, refetch: refetchPopularTopic } = useQuery<PopularTopicProps>(GET_POPULAR_TOPICS, {
    variables: {
      group_id: groupId,
      limit: popularTopicLimits,
    },
  });

  useEffect(() => {
    if (popularTopicLimits && popularTopicLimits > 0) refetchPopularTopic();
  }, [popularTopicLimits]);

  return {
    topics: data?.getPopularTopicsList || [],
  };
};
