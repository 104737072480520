import RNVideoHelper from 'react-native-video-helper';
import { Platform } from 'react-native';
import { ImageLibraryOptions, ImagePickerResponse, Asset, launchImageLibrary } from 'react-native-image-picker';
import { ReactNativeFile } from 'apollo-upload-client';
import * as mime from 'react-native-mime-types';
import * as ImagePicker from 'expo-image-picker';
import MultipleImagePicker, { Results } from '@baronha/react-native-multiple-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import * as VideoThumbnails from 'expo-video-thumbnails';
import { MAX_IMAGES_PER_POST } from '../../constants';

//TODO: fix typescript definitions warning on unused vars
// eslint-disable-next-line no-unused-vars
type OnLoadCallback = (props: ImagePickerResponse & { assets?: VideoAsset }) => void;
// eslint-disable-next-line no-unused-vars
type OnProgressCallback = (progress: number) => void;
type VideoAsset = Asset & {
  thumbnail?: string;
};
export interface ReactNativeFileOptions {
  uri: string;
  type?: string | undefined;
  name?: string | undefined;
}

const VIDEO_CONFIG: ImageLibraryOptions = {
  mediaType: `video`,
  maxHeight: 1920,
  maxWidth: 1920,
};

const EXPO_VIDEO_CONFIG: ImagePicker.ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Videos,
  allowsEditing: false,
  allowsMultipleSelection: false,
  videoMaxDuration: 300,
  videoExportPreset: ImagePicker.VideoExportPreset.Passthrough,
};

const HEADER_IMAGE_CONFIG: ImageLibraryOptions = {
  mediaType: `photo`,
  maxHeight: 1920,
  maxWidth: 1920,
  quality: 0.9,
  selectionLimit: 1,
};

const POST_IMAGE_CONFIG: ImageLibraryOptions = {
  ...HEADER_IMAGE_CONFIG,
  selectionLimit: MAX_IMAGES_PER_POST,
};

const parseHeicToJpeg = (assets?: Asset[]): Asset[] | undefined =>
  assets?.map((image: Asset) => {
    const heic = image.fileName && image.fileName.toLowerCase().endsWith(`.heic`);
    const webp = image.fileName && image.fileName.toLowerCase().endsWith(`.webp`);
    if (heic || webp) image.fileName = `${image.fileName?.split(`.`)[0]}.jpg`; // eslint-disable-line no-param-reassign
    //console.log('parseHeicToJpeg -> image', image);
    return image;
  });

const cleanContentUri = (uri: string): string => {
  let cleanedUri = uri.replace(`raw/`, ``);
  cleanedUri = cleanedUri.replace(`external_files`, `storage/emulated/0`);
  return cleanedUri;
};

export const generateApolloFile = (asset: Asset): ReactNativeFile | undefined => {
  if (!asset.uri) return undefined;
  const [fileName] = asset.uri.split(`/`).reverse();
  console.log(`Uploadable File`, {
    uri: getUploadUri(asset.uri),
    type: asset.type || mime.lookup(asset.uri),
    name: fileName.split(`.`)[0] || `unnamed-file`,
  });

  return asset.uri
    ? new ReactNativeFile({
        uri: getUploadUri(asset.uri),
        type: asset.type || mime.lookup(asset.uri),
        name: fileName.split(`.`)[0] || `unnamed-file`,
      })
    : undefined;
};

const getUploadUri = (uri: string): string => {
  if (uri.startsWith(`file://`)) return uri;
  return `file://${uri}`;
};
//uploading needs file:// schema but to display it we must remove file://
export const getReadablePath = (file: string | ReactNativeFileOptions): string => {
  // fetched from server
  if (typeof file === `string`) return file?.replace(`file://`, ``);
  // for local uri clean schema from source
  return file.uri.replace(`file://`, ``);
};

export const compress = async (file: Asset, progressCallback?: OnProgressCallback): Promise<VideoAsset> => {
  if (!file || !file.uri || file.uri.endsWith(`/tmp`) || file.uri.endsWith(`/tmp/`)) {
    throw new Error(`Failed to load video ${file?.uri}`);
  }
  let thumbnail;
  const cleanUri = cleanContentUri(file.uri);
  let compressed_uri = cleanUri;
  try {
    compressed_uri = await RNVideoHelper.compress(cleanUri, {
      startTime: 0, // optional, in seconds, defaults to 0
      endTime: 300, //  optional, in seconds, defaults to video duration, min(video duration, endtime)
      quality: `high`, // default low, can be medium or high
      defaultOrientation: 0, // By default is 0, some devices not save this property in metadata. Can be between 0 - 360
    }).progress((value: number) => {
      const progress = Math.round(value * 100);
      if (progressCallback && progress % 5 === 0) progressCallback(progress);
    });
    if (!compressed_uri) throw new Error(`Failed to compress video`);
  } catch (error) {
    console.error(`[video-CompressTool]:`, error);
  }
  try {
    thumbnail = await getThumbnailAsync(compressed_uri || cleanUri);
  } catch (error) {
    console.error(`[video-CompressTool]:`, error);
  }
  return {
    ...file,
    uri: getUploadUri(compressed_uri || file.uri),
    thumbnail: thumbnail ? getUploadUri(thumbnail) : undefined,
  };
};
//Uses expo-image-picker which resolves file:// URIs
export const pickExpoVideo = async (onLoadCallback: OnLoadCallback, onProgressCallback: OnProgressCallback) => {
  try {
    const result: ImagePicker.ImagePickerResult = await ImagePicker.launchImageLibraryAsync(EXPO_VIDEO_CONFIG);
    if (result.cancelled) return onLoadCallback({ didCancel: true });
    if (!result.uri || !result.uri.length) return onLoadCallback({ errorCode: `others`, errorMessage: `Failed to load asset.` });

    const processedAssets = await compress(
      {
        fileName: `unnamed-video`,
        duration: 1,
        uri: result.uri,
        height: result.height,
        width: result.width,
      },
      onProgressCallback,
    );
    return onLoadCallback({ assets: [processedAssets] });
  } catch (error) {
    return onLoadCallback({ didCancel: false, errorCode: `others`, errorMessage: (error as Error).message });
  }
};
//Uses react-native-image-picker which may resolve content:// URIs that are difficult to parse (Google Photos, FileExplorers)
export const pickVideo = (onLoadCallback: OnLoadCallback, onProgressCallback: OnProgressCallback) => {
  launchImageLibrary(VIDEO_CONFIG, async (props: ImagePickerResponse) => {
    const { didCancel, errorCode, assets } = props;
    if (didCancel || errorCode) return onLoadCallback(props);
    if (!assets || !assets.length || assets[0]?.duration === 0) {
      return onLoadCallback({ errorCode: `others`, errorMessage: `Failed to load asset.` });
    }
    const processedAssets = await Promise.all(
      assets.map((video: Asset) => {
        if (!video.uri) return video;
        // some examples of URIs resolved
        // [File]: {"duration": 7, "fileName": "VID_20210820_231109.mp4", "fileSize": 6523383, "uri": "content://com.mi.android.globalFileexplorer.myprovider/external_files/DCIM/Camera/VID_20210820_231109.mp4"}
        // [File]: {"duration": 1, "fileName": "/storage/emulated/0/DCIM/Camera/VID_20210829_005811.mp4", "fileSize": 2832821, "uri": "content://com.miui.gallery.open/raw/%2Fstorage%2Femulated%2F0%2FDCIM%2FCamera%2FVID_20210829_005811.mp4"}
        // [File]: {"duration": 1, "fileName": "699699371", "fileSize": 2832821, "uri": "content://com.google.android.apps.photos.contentprovider/0/2/content%3A%2F%2Fmedia%2Fexternal%2Fvideo%2Fmedia%2F14721/ORIGINAL/NONE/video%2Fmp4/699699371"}
        // TODO: test getting file with inputStream
        let cleanedUri = video.uri?.replace(`raw/`, ``);
        cleanedUri = cleanedUri?.replace(`external_files`, `storage/emulated/0`);
        return compress({ ...video, uri: cleanedUri }, onProgressCallback);
      }),
    );
    return onLoadCallback({
      ...props,
      assets: [processedAssets],
    });
  });
};

export const getThumbnailAsync = async (video_uri: string): Promise<string | undefined> => {
  try {
    const source_uri = getUploadUri(video_uri);
    const { uri } = await VideoThumbnails.getThumbnailAsync(source_uri, {
      quality: 0.9,
      time: 350,
    });
    return uri;
  } catch (e) {
    console.warn(`[video-ThumbnailExtractorError]:`, e);
    return undefined;
  }
};

type IosImageFile = Omit<Results, `realPath`>;
type AndroidImageFile = Required<Results>;
export type OrderedImageFile = IosImageFile | AndroidImageFile;

//FIXME: this image picker does not support GIFs (tested on android, ios pending)

export const pickOrderedImages = async (selectedAssets: any[], maxSelectedAssets = 10) => {
  try {
    const results: OrderedImageFile[] =
      (await MultipleImagePicker.openPicker({ mediaType: `image`, selectedAssets, maxSelectedAssets })) || [];
    const assets: ReactNativeFile[] = await Promise.all(
      results.map(async (item: OrderedImageFile) => {
        //TODO: consider simply use => item.realPath || item.path
        const platform_path = Platform.OS === `android` ? (item as AndroidImageFile).realPath : item.path;
        const uri = getUploadUri(platform_path);
        const [name] = item.fileName.split(`.`);
        if ((item.mime !== ImageMimeTypes.GIF && item.width > 1024) || item.width > 1024) {
          const manipResult = await manipulateAsync(uri, [{ resize: { width: 1024 } }], {
            compress: 0.5,
            format: SaveFormat.JPEG,
          });
          return new ReactNativeFile({ uri: manipResult.uri, type: ImageMimeTypes.JPEG, name });
        }
        return new ReactNativeFile({ uri, type: item.mime || mime.lookup(item.path), name });
      }),
    );
    return { assets, rawAssets: results as any[] };
  } catch (e) {
    console.error(`[PickOrderedImagesError]`, (e as Error).message);
    return { assets: [] as ReactNativeFile[], rawAssets: [] as any[] };
  }
};

export const pickImages = (onLoadCallback: OnLoadCallback, multiple?: boolean) => {
  const configOptions: ImageLibraryOptions = multiple ? POST_IMAGE_CONFIG : HEADER_IMAGE_CONFIG;
  launchImageLibrary(configOptions, (props: ImagePickerResponse) => {
    // ios has its own heic filetype... must change extension
    // eslint-disable-next-line no-param-reassign
    const parsedAssets = parseHeicToJpeg(props.assets);
    onLoadCallback({
      ...props,
      assets: parsedAssets,
    });
  });
};

export enum ImageMimeTypes {
  JPEG = `image/jpeg`,
  BMP = `image/bmp`,
  PNG = `image/png`,
  GIF = `image/gif`,
}
