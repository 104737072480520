import { useNavigation } from '@react-navigation/native';
import React, { useContext, useEffect, useMemo } from 'react';
import { Platform, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useDispatch } from 'react-redux';
import { useSettings } from '../../common-settings/hooks/useSettings';
import { Colors } from '../../common-styles';
import { BottomSheetModal, MeshIcon } from '../../common-ui';
import { parseMemberLength } from './helpers';
import { HeaderProps } from './hooks/useCommunityHeader';
import { COMMUNITY_FEED_TITLE } from '../testLabels';
import { AppContext } from '../../../AppContext';
import CommunityMenu from './CommunityMenu';
import Banner from './Banner';
import { MeshVerifiedCheck } from '../../images';
import { setNodeMode } from '../../redux/feedSlice';
import CommunitySponsor from './CommunitySponsor';

const AGE_RESTRICTION_KEY = `group.setting.age_restriction`;
const BIRTH_KEY = `user.setting.birthday`;

type CommunityHeaderProps = {
  /**
   * Styles applied to the main container of the CommunityHeader
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Methods and data for the CommunityHeader to use
   */
  headerProps: HeaderProps;
  /**
   * RoleId
   */
  roleId?: string | null;

  mode?: `preview` | null;
};

export const CommunityHeader = React.memo(({ containerStyle, roleId, headerProps, mode }: CommunityHeaderProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const { user } = useContext(AppContext);
  const { currentCommunity, groupMember } = headerProps;
  const membersLength = parseMemberLength(currentCommunity?.group_member_count || 0);
  const handleOnPressMemberCount = () => navigation.navigate(`MemberInfo`, { groupId: currentCommunity?.id, mode });
  const isMember = !!roleId;

  const { settings: ageSetting } = useSettings(AGE_RESTRICTION_KEY, {
    user_id: undefined,
    group_id: currentCommunity?.id,
  });
  const minAge = Number(ageSetting[AGE_RESTRICTION_KEY]?.value);

  const { settings: birthSetting } = useSettings(BIRTH_KEY, {
    user_id: user?.id,
    group_id: undefined,
  });

  const isAgeInvalid = useMemo(() => {
    const userBirth = birthSetting[BIRTH_KEY]?.value;
    const userAge = new Date().getFullYear() - new Date(userBirth).getFullYear();

    if (!!user?.id && !isMember && userAge < minAge) return true;
    return false;
  }, [user?.id, minAge, birthSetting, isMember]);

  useEffect(() => {
    dispatch(setNodeMode(mode));
  }, [mode, dispatch]);

  return (
    <>
      <BottomSheetModal
        visible={isAgeInvalid}
        title="Age Restriction"
        showCancelBtn={false}
        confirmTitle="Ok"
        onPressConfirm={() => navigation.goBack()}
        onPressCancel={() => {}}
      >
        <View style={{ paddingHorizontal: 20 }}>
          <Text style={{ textAlign: `center`, marginBottom: 20 }}>
            This community only allows members who are {minAge} and over.
          </Text>
        </View>
      </BottomSheetModal>

      {!!currentCommunity && <Banner group={currentCommunity} groupMember={groupMember} mode={mode} />}

      {!!currentCommunity && (
        <View style={[styles.container, containerStyle]}>
          <View style={{ position: `absolute`, right: 16, top: 10, zIndex: 60 }}>
            <CommunityMenu community={currentCommunity} />
          </View>

          <View style={[styles.titleContainer, mode === `preview` ? styles.androidPreviewPadding : undefined]}>
            <Text ellipsizeMode="tail" numberOfLines={1} style={styles.commmunityTitle} testID={COMMUNITY_FEED_TITLE}>
              {currentCommunity?.name}
            </Text>
            {currentCommunity?.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
          </View>

          {!!currentCommunity?.group_member_count && (
            <View style={styles.membersContainer}>
              <MeshIcon name="group" size={12} style={{ paddingRight: 3 }} />
              <TouchableOpacity onPress={handleOnPressMemberCount}>
                <Text style={styles.membersText}>
                  {membersLength} {currentCommunity?.group_member_count === 1 ? `Member` : `Members`}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          <CommunitySponsor sponsor={currentCommunity?.sponsor} community_name={currentCommunity?.name} />
        </View>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    minHeight: 60,
    maxHeight: 120,
    backgroundColor: Colors.white,
    position: `relative`,
    zIndex: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
    paddingTop: 8,
    paddingBottom: 6,
  },
  androidPreviewPadding: {
    marginTop: Platform.OS === `android` ? 12 : 0,
  },
  titleContainer: {
    maxHeight: 40,
    textAlign: `center`,
    justifyContent: `center`,
    alignItems: `center`,
    marginBottom: 4,
    width: `100%`,
    flexDirection: `row`,
  },
  actionsContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 10,
  },
  commmunityTitle: {
    fontSize: 16,
    fontWeight: Platform.select({ ios: `600`, android: `bold` }),
    textAlign: `center`,
  },
  membersContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 2,
  },
  membersText: {
    fontSize: 10,
    fontWeight: Platform.select({ ios: `600`, android: `bold` }),
    color: Colors.gray,
  },
  sponsorText: {
    marginBottom: 2,
    fontSize: 12,
  },
  toolTipTriangle: {
    width: 0,
    height: 0,
    backgroundColor: Colors.transparent,
    borderStyle: `solid`,
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderLeftColor: Colors.transparent,
    borderRightColor: Colors.transparent,
    borderBottomColor: Colors.lightGray,
    marginRight: 60,
  },
});
