import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../common-styles';

export const Divider = ({ style }) => (
  <View style={StyleSheet.flatten([{ borderBottomWidth: 1, borderColor: Colors.dividerColor }, style])} />
);
Divider.propTypes = {
  style: PropTypes.any,
};

Divider.defaultProps = {
  style: undefined,
};
