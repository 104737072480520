import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { useQuery } from 'react-apollo';
import { useNavigation } from '@react-navigation/native';

import { useMessageContext } from 'stream-chat-react-native';
import { GET_PERSONA } from '../../graphql';
import { getMeshInviteToken, profileInviteRegex } from '../../post/hooks/useLinkPreview';
import { Colors } from '../../common-styles';
import { GetPersonaData, GetPersonaVars } from '../../common-types/types';
import { ChatNavigator, LeftDrawerNavigator } from '../../common-types/navigation-types';
import { Avatar } from '../../common-ui/Avatar';
import { UserHeader } from '../../images';

const truncate = (input: string, length: number, end = `...`) => {
  if (input.length > length) {
    return `${input.substring(0, length - end.length)}${end}`;
  }
  return input;
};

export const ProfilePreview: React.FC<{ inviteUrl: string }> = ({ inviteUrl }) => {
  const { isMyMessage } = useMessageContext();
  const navigation = useNavigation<ChatNavigator>();
  const invite_token = getMeshInviteToken(inviteUrl, profileInviteRegex);

  const { data, error } = useQuery<GetPersonaData, GetPersonaVars>(GET_PERSONA, {
    variables: { id: invite_token || `` },
    skip: !invite_token,
  });

  const handleNavigate = () => {
    if (!invite_token) return;
    const DrawerNavigator = navigation.getParent()?.getParent()?.getParent<LeftDrawerNavigator>();
    DrawerNavigator?.jumpTo(`ProfileStack`, {
      screen: `Profile`,
      params: { user_id: invite_token },
    });
  };
  if (data) {
    const { handle, splash_url } = data.getPersona;
    const bgColor = isMyMessage ? Colors.chatMessageBackground : Colors.otherchatMessageBackground;
    return (
      <TouchableOpacity onPress={handleNavigate} style={[styles.container, { backgroundColor: bgColor }]}>
        <View style={styles.thumbnailContainer}>
          {splash_url ? (
            <Image resizeMode="cover" style={styles.thumbnail} source={{ uri: splash_url }} />
          ) : (
            <View style={{ borderRadius: 7, overflow: `hidden` }}>
              <UserHeader width={240} height={120} />
            </View>
          )}
          <View style={styles.detailsContainer}>
            <Avatar user={data.getPersona} size={22} />
            <Text style={styles.description}>{truncate(handle, 100)}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  if (error) {
    return (
      <View>
        <Text style={{ paddingHorizontal: 15, paddingVertical: 5, color: Colors.alertColor }}>Expired invitation</Text>
      </View>
    );
  }
  return <View />;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: `column`,
    marginBottom: 8,
    borderRadius: 0,
    paddingHorizontal: 10,
  },
  description: {
    padding: 2,
    fontSize: 12.5,
  },
  detailsContainer: {
    position: `absolute`,
    bottom: 0,
    flexDirection: `row`,
    backgroundColor: `${Colors.white}88`,
    borderRadius: 20,
    margin: 10,
    padding: 8,
    minWidth: 100,
  },
  thumbnail: {
    borderRadius: 8,
    height: 150,
    width: `100%`,
  },
  thumbnailContainer: {},
});
