import { Image, StyleSheet, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import React from 'react';
import { DUMMY_UUID } from '../../constants';
import { Colors } from '../common-styles';
import { CommunityHeader } from '../images';
import { Group } from '../common-types/types';

type CommunityAvatarProsp = {
  border?: boolean;
  community: Pick<Group, `id` | `avatar_url`>;
  onPress?: () => void;
  size?: number;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  shadow?: boolean;
};

export const CommunityAvatar: React.FC<CommunityAvatarProsp> = ({
  border = false,
  community,
  size = 56,
  onPress = undefined,
  containerStyle = {},
  disabled = false,
  shadow = false,
}) => {
  const { avatar_url } = community || {};
  const avatarPress = () => (onPress ? onPress() : undefined);

  return (
    <TouchableOpacity
      key={community?.id}
      disabled={!community || community.id === DUMMY_UUID || disabled}
      onPress={avatarPress}
      style={[containerStyle, shadow ? styles.withShadow : undefined]}
      activeOpacity={0.8}
    >
      {avatar_url ? (
        <Image
          source={{ uri: avatar_url }}
          resizeMode="cover"
          style={[{ width: size, height: size, borderRadius: size / 2 }, border ? styles.withBorder : undefined]}
        />
      ) : (
        <View style={[{ borderRadius: size / 2, overflow: `hidden` }, border ? styles.withBorder : undefined]}>
          <CommunityHeader width={size} height={size} />
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  withShadow: {
    backgroundColor: Colors.avatarContainerColor,
    borderRadius: 7,
    shadowColor: Colors.avatarShadowColor,
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 5,
    shadowOpacity: 0.1,
    elevation: 3,
  },
  withBorder: {
    borderWidth: 1,
    borderColor: Colors.avatarBorderColor,
  },
});
