import gql from 'graphql-tag';
import {
  GROUP_DETAILS_FRAGMENT,
  NOTIFICATION_DETAILS_FRAGMENT,
  NOTIFICATION_REACTION_DETAILS_FRAGMENT,
  MODERATION_NOTIFICATION_DETAILS_FRAGMENT,
  ACTIVITY_DETAILS_FRAGMENT,
  REACTION_DETAILS_FRAGMENT,
  USER_DETAILS_FRAGMENT,
  // PROFILE_ACTIVITY_DETAILS_FRAGMENT,
} from './Fragments.gql';

export const PROFILE_FEED_SUBSCRIPTION = gql`
  subscription profile_sub($persona_id: ID!) {
    ProfileFeedSubscription(persona_id: $persona_id) {
      new {
        ...ActivityDetails
      }
      deleted {
        id
      }
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
  ${USER_DETAILS_FRAGMENT}
  ${GROUP_DETAILS_FRAGMENT}
  ${REACTION_DETAILS_FRAGMENT}
`;

export const MODERATOR_SUBSCRIPTION = gql`
  subscription moderationSubscription($persona_id: ID!) {
    moderationSubscription(persona_id: $persona_id) {
      id
      action_taken
      group_id
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription StreamNotificationSubscription($persona_id: ID!) {
    StreamNotificationSubscription(persona_id: $persona_id) {
      ...NotificationDetails
      activities {
        __typename
        ... on NotificationReaction {
          ...NotificationReactionDetails
        }
        __typename
        ... on Activity {
          ...ActivityDetails
        }
        __typename
        ... on ModerationNotification {
          ...ModerationNotificationDetails
        }
      }
    }
  }
  ${NOTIFICATION_DETAILS_FRAGMENT}
  ${NOTIFICATION_REACTION_DETAILS_FRAGMENT}
  ${ACTIVITY_DETAILS_FRAGMENT}
  ${USER_DETAILS_FRAGMENT}
  ${GROUP_DETAILS_FRAGMENT}
  ${REACTION_DETAILS_FRAGMENT}
  ${MODERATION_NOTIFICATION_DETAILS_FRAGMENT}
`;

export const FOLLOW_NOTIFICATION_SUBSCRIPTION = gql`
  subscription getNewFollowingNotificationCount($persona_id: ID!) {
    getNewFollowingNotificationCount(persona_id: $persona_id) {
      count
    }
  }
`;

export const COMMUNITY_FEED_SUBSCRIPTION = gql`
  subscription subscribeActivities($group_id: ID!) {
    getActivities(group_id: $group_id) {
      new {
        ...ActivityDetails
      }
      deleted {
        id
      }
    }
  }
  ${ACTIVITY_DETAILS_FRAGMENT}
`;

// export const PERSONAL_FEED_SUBSCRIPTION = gql`
//   subscription getProfileNodeFeed($persona_id: ID!) {
//     getProfileNodeFeed(persona_id: $persona_id) {
//       new {
//         ...ProfileActivityDetails
//       }
//       updated {
//         ...ProfileActivityDetails
//       }
//       deleted {
//         id
//       }
//     }
//   }
//   ${PROFILE_ACTIVITY_DETAILS_FRAGMENT}
// `;

export const PERSONA_SUBSCRIPTION = gql`
  subscription getPersonaUpdate($id: ID!) {
    getPersonaUpdate(id: $id) {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;
