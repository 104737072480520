import React, { memo, useState, useContext } from 'react';
import { Text } from 'react-native';
import {
  MessageSimple,
  ThemeProvider,
  MessageContent,
  useMessageContext,
  vw,
  TouchableHandlerPayload,
} from 'stream-chat-react-native';
import { Colors, Typography } from '../../common-styles';
import { formatDate } from '../../common-util';
import { AppContext } from '../../../AppContext';
import { fixMeshInvitelLink } from '../../post/helpers';

const CustomMessage = memo(() => {
  const { message, isMyMessage } = useMessageContext();
  const defaultTypography = Typography.text(`plusone`);
  const [messageTimestamp, setMessageTimestamp] = useState(``);
  const { user } = useContext(AppContext);
  const [, setWidth] = useState(vw(60));

  // Fix old mesh invite links
  message.text = fixMeshInvitelLink(message.text);
  message.html = fixMeshInvitelLink(message.html);

  const sentMessageStyles = {
    messageSimple: {
      content: {
        containerInner: {
          backgroundColor: Colors.chatMessageBackground,
        },
        markdown: {
          text: {
            color: Colors.textBlack,
            fontSize: defaultTypography.fontSize,
          },
        },
        textContainer: {
          backgroundColor: Colors.chatMessageBackground,
        },
      },
    },
  };

  const receivedMessageStyles = {
    messageSimple: {
      content: {
        containerInner: {
          backgroundColor: Colors.otherchatMessageBackground,
        },
        markdown: {
          text: {
            color: Colors.textBlack,
            fontSize: defaultTypography.fontSize,
          },
        },
        textContainer: {
          backgroundColor: Colors.otherchatMessageBackground,
        },
      },
    },
  };

  const handleSelectMessage = (event: TouchableHandlerPayload) => {
    // Workaround...
    // const titleLink = attachments?.map((atch) => atch.title_link)[0];
    // const token = titleLink.split(`=`)[2];

    // console.log(`token`, token);
    // if (Platform.OS === `ios` && token) {
    //   console.log(`Navigate with IOS navigation`);
    //   navigate(`CommunityFeed`, {
    //     group_id: token,
    //     invite_token: token,
    //   });
    // }
    if (messageTimestamp.length > 0) {
      setMessageTimestamp(``);
      return;
    }
    // @ts-ignore reason: wrong type given by 3rd party library, event do have prop created_at
    const { created_at } = event;
    setMessageTimestamp(formatDate(created_at, `h:mmTT`));
  };

  if (message?.shadowed && message?.user?.id !== user?.id) return null;

  return (
    <ThemeProvider style={isMyMessage ? sentMessageStyles : receivedMessageStyles}>
      <MessageSimple
        message={message}
        enableMessageGroupingByUser={false}
        MessageAvatar={() => null}
        groupStyles={[`bottom`]}
        MessageContent={() => (
          <MessageContent
            setMessageContentWidth={(width) => setWidth(width)}
            showMessageStatus={false}
            onLongPress={() => console.log(`Temporaly disabled`)}
            onPressIn={handleSelectMessage}
            MessageFooter={() =>
              messageTimestamp ? (
                <Text style={{ fontSize: 11, color: Colors.gray, marginTop: 1 }}>{formatDate(message.created_at, `h:mmTT`)}</Text>
              ) : null
            }
          />
        )}
      />
    </ThemeProvider>
  );
});

export { CustomMessage };
