/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
};

const applicationSlice = createSlice({
  name: `applicationForm`,
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    saveQuestion: (state, action) => {
      state.questions = [...state.questions, action.payload];
    },
    editQuestion: (state, action) => {
      const { newQuestionItem, questionIndex } = action.payload;
      state.questions[questionIndex] = newQuestionItem;
    },
    removeQuestion: (state, action) => {
      state.questions.splice(action.payload, 1);
    },
  },
});

export const { setQuestions, saveQuestion, editQuestion, removeQuestion } = applicationSlice.actions;

const applicationReducer = applicationSlice.reducer;

export { applicationReducer };
