import * as React from 'react';
import Svg, { Defs, Rect, Path, G, Mask, Use, Text, TSpan } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreArts = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Rect id="a" x={0} y={0} width={163} height={98} rx={7} />
        <Path id="c" d="M0.550724638 0H77.550724638V76H0.550724638z" />
        <Path id="e" d="M0.0232311097 0.00359430385L6.63486222 0.00359430385 6.63486222 15.3344474 0.0232311097 15.3344474z" />
        <Path id="g" d="M0.0232311097 0.00357391064L7.39370694 0.00357391064 7.39370694 16.9733276 0.0232311097 16.9733276z" />
        <Path id="i" d="M0.0232311097 0.034892788L7.53230186 0.034892788 7.53230186 17.1459204 0.0232311097 17.1459204z" />
        <Path id="k" d="M0.0462278248 0.0192257019L13.098882 0.0192257019 13.098882 5.04247692 0.0462278248 5.04247692z" />
        <Path id="m" d="M0.0413888784 0.0348112152L6.57820644 0.0348112152 6.57820644 5.64539207 0.0413888784 5.64539207z" />
        <Path id="o" d="M0.0515980656 0.028086146L6.57820644 0.028086146 6.57820644 5.61852401 0.0515980656 5.61852401z" />
        <Path id="q" d="M0.0242520284 0.035295554L4.70304961 0.035295554 4.70304961 1.82683935 0.0242520284 1.82683935z" />
        <Path id="s" d="M0.022569596 0.00141732833L7.56787255 0.00141732833 7.56787255 5.68327247 0.022569596 5.68327247z" />
        <Path id="u" d="M0.0377010699 0.0327667955L2.35910356 0.0327667955 2.35910356 1.04829784 0.0377010699 1.04829784z" />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Use fill="#FFEEEB" xlinkHref="#a" />
          <Path
            d="M142.209 46.52c4.56 4.26 6.78 10.487 7.548 17.405.726 6.88 0 14.454-4.561 19.66-4.52 5.207-12.916 8.083-20.423 7.282-7.507-.8-14.126-5.279-20.423-10.486C98.054 75.175 92.04 69.204 92 63.196c0-5.97 5.933-11.978 12.27-16.238 6.296-4.296 12.956-6.845 19.736-6.954 6.78-.11 13.683 2.22 18.203 6.517z"
            fill="#FF563D"
            fillRule="nonzero"
            mask="url(#b)"
          />
        </G>
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#FF563D">
          <TSpan x={12} y={22}>
            Arts and
          </TSpan>
          <TSpan x={12} y={41}>
            Crafts
          </TSpan>
        </Text>
        <G transform="translate(69 22)">
          <Mask id="d" fill="#fff">
            <Use xlinkHref="#c" />
          </Mask>
          <G mask="url(#d)">
            <G transform="translate(.55) translate(.275) translate(.528) translate(0 36.275) translate(0 .43)" fillRule="nonzero">
              <Path fill="#2C9A44" d="M0 47.1595771L40.6816954 47.1595771 40.6816954 0 0 0z" />
              <Path
                fill="#EECCDC"
                d="M1.11130485 46.0283713L39.6894589 46.0283713 39.6894589 1.09219864 1.11130485 1.09219864z"
              />
              <Path
                fill="#FCEBEC"
                d="M24.4883961 30.2695052L39.6894589 30.2695052 39.6894589 12.5602844 24.4883961 12.5602844z"
              />
              <Path
                fill="#DDB0C8"
                d="M27.5870316 28.7482285L29.6480258 28.7482285 29.6480258 14.5886533 27.5870316 14.5886533z"
              />
              <Path fill="#DDB0C8" d="M24.4883803 12.5602844L21.1147763 18.6843982 21.172445 30.2103705 24.4883803 30.2695052z" />
              <Path fill="#FCEBEC" d="M1.07161539 46.0283713L14.446963 46.0283713 14.446963 28.4751789 1.07161539 28.4751789z" />
              <Path fill="#DDB0C8" d="M4.9214929 36.393619L14.446963 36.393619 14.446963 32.0248245 4.9214929 32.0248245z" />
              <Path fill="#DDB0C8" d="M4.9214929 43.0638322L14.446963 43.0638322 14.446963 38.4219879 4.9214929 38.4219879z" />
              <Path fill="#FCEBEC" d="M21.194171 46.0283713L39.6894589 46.0283713 39.6894589 28.3191505 21.194171 28.3191505z" />
              <Path
                fill="#DDB0C8"
                d="M24.6897011 36.4716332L39.6986668 36.4716332 39.6986668 32.0248245 24.6897011 32.0248245z"
              />
              <Path
                fill="#DDB0C8"
                d="M24.7293905 43.1808534L39.7383563 43.1808534 39.7383563 38.7340447 24.7293905 38.7340447z"
              />
              <Path fill="#DDB0C8" d="M21.1941552 28.3191505L17.8205512 34.4432643 17.87822 45.9692366 21.1941552 46.0283713z" />
              <Path
                d="M14.01 10.532c0 2.327-1.919 4.213-4.286 4.213-2.367 0-4.287-1.886-4.287-4.213s1.92-4.213 4.287-4.213 4.286 1.886 4.286 4.213"
                fill="#FCEBEC"
              />
              <Path
                fill="#DDB0C8"
                d="M32.5482139 28.7482285L34.6092082 28.7482285 34.6092082 14.5886533 32.5482139 14.5886533z"
              />
              <Path
                fill="#DDB0C8"
                d="M37.6284647 28.8262427L39.6894589 28.8262427 39.6894589 14.5886533 37.6284647 14.5886533z"
              />
            </G>
            <G
              transform="translate(.55) translate(.275) translate(.528) translate(14.546 .072) translate(0 .893)"
              fillRule="nonzero"
            >
              <Path fill="#2C9A44" d="M0 30.7352122L47.5152863 30.7352122 47.5152863 0 0 0z" />
              <Path fill="#FFF" d="M1.48355345 29.2196692L45.844827 29.2196692 45.844827 1.74238949 1.48355345 1.74238949z" />
              <Path
                fill="#FC7750"
                d="M7.26835395 24.0736732L39.9808344 24.0736732 39.9808344 7.34365458 7.26835395 7.34365458z"
              />
              <Path
                d="M.025 1.23s3.949-1.137 8.736.183c4.788 1.32 8.865 1.539 12.455.44 3.591-1.1 8.117-.11 8.117-.11L25.106 5.59 13.362 8.816l-6.995-.88-6.36-4.919.018-1.787z"
                fill="#237978"
                transform="translate(7.239 11.76)"
              />
              <Path
                d="M32.654.609s-2.533-1.785-7.57.809c-5.41 2.785-8.37 8.704-8.37 8.704l15.321.715.702-2.92.005-7.293s-.014-.016-.088-.015"
                fill="#0A4343"
                transform="translate(7.239 11.76)"
              />
              <Path
                d="M1.246 5.436s2.766-1.374 8.457-.084c7.464 1.69 11.24-2.903 15.334-1.703 3.168.928 7.68 1.932 7.68 1.932l-3.308 6.056L6.656 8.452.048 5.586l1.198-.15z"
                fill="#1BC0D7"
                transform="translate(7.239 11.76)"
              />
              <Path
                d="M23.628 9.352s8.173-4.12 9.112-3.147l.002 6.108-9.114-2.961z"
                fill="#0A4343"
                transform="translate(7.239 11.76)"
              />
              <Path
                d="M6.364 12.257S18.298 3.932 24.415 5.9c6.118 1.97 8.327 6.412 8.327 6.412l-26.378-.056z"
                fill="#237978"
                transform="translate(7.239 11.76)"
              />
              <Path
                d="M.008 3.002S5.832 4.476 10.54 8.091c4.708 3.614 6.553 4.185 6.553 4.185L.03 12.313l-.022-9.31z"
                fill="#0A4343"
                transform="translate(7.239 11.76)"
              />
            </G>
            <G transform="translate(.55) translate(.275) translate(.528) translate(46.262 36.705) translate(.285)">
              <Path
                fill="#2C9A44"
                fillRule="nonzero"
                d="M0.0256271434 29.7740915L25.1839293 29.7740915 25.1839293 0 0.0256271434 0z"
              />
              <Path
                fill="#FCEBEC"
                fillRule="nonzero"
                d="M1.44204769 28.2096261L23.988048 28.2096261 23.988048 1.47850797 1.44204769 1.47850797z"
              />
              <G transform="translate(1.354 2.04)">
                <Path
                  d="M16.687 10.37l-3.355 7.015-3.685-6.387C10.168 5.665 13.402.049 13.402.049c.878 1.501 1.549 2.913 2.048 4.236l-2.032 6.28-.043 3.157 2.586-7.915c.495 1.68.708 3.2.726 4.562"
                  fill="#237978"
                  fillRule="nonzero"
                />
                <Path
                  d="M15.961 5.807l-2.586 7.915.043-3.157 2.032-6.28c.196.521.366 1.028.511 1.522"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <Path
                  d="M13.214 19.18c-3.171-1.685-3.889-4.88-3.567-8.182l3.685 6.387 3.355-7.016c.08 5.893-3.473 8.811-3.473 8.811"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <Path fill="#0A4343" fillRule="nonzero" d="M13.2141781 19.1801231L13.4016938 0.0492496102" />
                <Path
                  fill="#0A4343"
                  fillRule="nonzero"
                  d="M13.370462 19.1815914L13.0579359 19.1786343 13.2454516 0.0477609057 13.5579777 0.0507179216z"
                />
                <G transform="translate(0 3.67)">
                  <Mask id="f" fill="#fff">
                    <Use xlinkHref="#e" />
                  </Mask>
                  <Path
                    d="M6.225 7.599l.41 7.735-6.32-3.94C-1.788 6.448-1.632.004-1.632.004A28.714 28.714 0 012.21 2.799l1.224 6.478 1.48 2.806-1.521-8.176C4.637 5.16 5.555 6.406 6.225 7.599"
                    fill="#237978"
                    fillRule="nonzero"
                    mask="url(#f)"
                  />
                </G>
                <Path
                  d="M3.393 7.577l1.52 8.176-1.479-2.806L2.21 6.47c.424.37.817.739 1.183 1.107"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <Path
                  d="M7.394 20.644c-3.609-.028-5.777-2.516-7.08-5.58l6.32 3.941-.409-7.735c2.902 5.164 1.169 9.374 1.169 9.374"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <G transform="translate(0 3.67)">
                  <Mask id="h" fill="#fff">
                    <Use xlinkHref="#g" />
                  </Mask>
                  <Path fill="#0A4343" fillRule="nonzero" mask="url(#h)" d="M7.39369131 16.9733225L-1.63237611 0.00356881234" />
                </G>
                <G transform="translate(0 3.569)">
                  <Mask id="j" fill="#fff">
                    <Use xlinkHref="#i" />
                  </Mask>
                  <Path
                    fill="#0A4343"
                    fillRule="nonzero"
                    mask="url(#j)"
                    d="M7.25509118 17.1459204L-1.77097625 0.176166774 -1.49376556 0.034892788 7.53230186 17.0046464z"
                  />
                </G>
                <Path
                  d="M21.745 25.201l-1.45-.449-11.602-3.677-.023-.03 6.335-1.41c1.02.349 2.098.917 3.208 1.785l-6.772.476 2.391.73 5.366-.36c.84.786 1.693 1.753 2.547 2.935"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <G transform="translate(8.647 21.056)">
                  <Mask id="l" fill="#fff">
                    <Use xlinkHref="#k" />
                  </Mask>
                  <Path
                    d="M13.099 4.145l-.016.005c-.321.098-5.362 1.606-9.406.818L.122.122.046.019 11.65 3.696l1.45.45z"
                    fill="#237978"
                    fillRule="nonzero"
                    mask="url(#l)"
                  />
                </G>
                <Path
                  d="M19.198 22.266l-5.366.36-2.39-.73 6.77-.476c.327.256.656.537.986.846"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <Path
                  d="M8.768 21.178l3.555 4.846c-2.587-.505-4.767-1.95-5.042-5.307 0 0 .029-.023.085-.062.542-.385 3.638-2.389 7.64-1.02l-6.336 1.41.023.03.075.103z"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <Path
                  fill="#0A4343"
                  fillRule="nonzero"
                  d="M20.2542774 24.8805768L8.72669864 21.3061563 7.23964718 20.8452697 7.32272704 20.5883662 20.3374093 24.6236733z"
                />
                <G transform="translate(15.99 13.51)">
                  <Mask id="n" fill="#fff">
                    <Use xlinkHref="#m" />
                  </Mask>
                  <Path
                    d="M8.78.035l-.971.613-7.757 4.97-.01.027 4.933-.207c.677-.456 1.352-1.084 1.988-1.938l-5.064.978 1.605-1.004 4.008-.788c.455-.74.883-1.617 1.268-2.651"
                    fill="#EECCDC"
                    fillRule="nonzero"
                    mask="url(#n)"
                  />
                </G>
                <G transform="translate(15.99 13.51)">
                  <Mask id="p" fill="#fff">
                    <Use xlinkHref="#o" />
                  </Mask>
                  <Path
                    d="M8.78.035h-.013c-.255-.01-4.26-.128-7.065 1.243L.086 5.528l-.034.09L7.809.649l.97-.613z"
                    fill="#237978"
                    fillRule="nonzero"
                    mask="url(#p)"
                  />
                </G>
                <G transform="translate(17.866 16.162)">
                  <Mask id="r" fill="#fff">
                    <Use xlinkHref="#q" />
                  </Mask>
                  <Path
                    d="M5.637.035L1.628.823.024 1.827 5.087.849c.187-.252.37-.522.55-.814"
                    fill="#EECCDC"
                    fillRule="nonzero"
                    mask="url(#r)"
                  />
                </G>
                <Path
                  d="M16.077 19.04l1.616-4.252c-1.795.878-3.098 2.365-2.612 4.88l.074.03c.477.176 3.157 1.038 5.811-.75l-4.934.208.01-.027.035-.09z"
                  fill="#EECCDC"
                  fillRule="nonzero"
                />
                <G transform="translate(15.001 14.071)">
                  <Mask id="t" fill="#fff">
                    <Use xlinkHref="#s" />
                  </Mask>
                  <Path
                    fill="#0A4343"
                    fillRule="nonzero"
                    mask="url(#t)"
                    d="M0.13612076 5.68327247L0.022569596 5.51115374 8.74158009 0.00141732833 8.85513125 0.173536049z"
                  />
                </G>
                <G transform="translate(20.21 24.574)">
                  <Mask id="v" fill="#fff">
                    <Use xlinkHref="#u" />
                  </Mask>
                  <Path
                    fill="#0A4343"
                    fillRule="nonzero"
                    mask="url(#v)"
                    d="M2.32492362 1.04829784L0.0377010699 0.323828933 0.133959121 0.0327667955 2.42107749 0.7572357z"
                  />
                </G>
                <Path
                  fill="#0A4343"
                  fillRule="nonzero"
                  d="M8.64467615 21.2207292L6.82837841 20.6451307 6.92463646 20.3541196 8.7409342 20.929718z"
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};
