import React, { useState, useCallback, useEffect } from 'react';
import { View, Text, FlatList, TouchableOpacity, TextInput, StyleSheet, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-apollo';
import { useNavigation } from '@react-navigation/native';
import { commonPropTypes } from '../common-util';
import {
  Divider,
  ListItem,
  MeshIcon,
  BlobPink,
  ThemedButton,
  LoadingIndicator,
  KeyboardPaddingView,
  SafeAreaView,
} from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { countriesCodeAndFlag } from './helpers';
import { refetchQueriesFor, REQUEST_VERIFY_TOKEN, SETTING_QUERY } from '../graphql';

export const PhoneNumberSettingItem = ({ setting, listItemProps }) => {
  const navigation = useNavigation();
  const { title, titleStyle, subtitleStyle, rightElement } = listItemProps;
  const { user } = React.useContext(AppContext);

  const userAndGroup = {
    user_id: user?.id,
    group_id: undefined,
  };

  const { data } = useQuery(SETTING_QUERY, {
    fetchPolicy: `cache-and-network`,
    variables: {
      settingQuery: {
        query: `user`,
        ...userAndGroup,
      },
    },
  });

  const phoneNumberSetting = data?.getSettings.find((elem) => elem.key === `user.setting.phone_number`);
  const phoneNumberVerifiedSetting = data?.getSettings.find((elem) => elem.key === `user.setting.phone_number_verified`);

  const verified = phoneNumberVerifiedSetting?.value === `true`;

  const navigateToEditor = () =>
    navigation.navigate(`PhoneNumberEditor`, {
      title,
      setting,
      user,
    });

  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle={phoneNumberSetting?.value}
      subtitleStyle={subtitleStyle}
      rightElement={rightElement}
      onPress={navigateToEditor}
      verified={verified}
    />
  );
};

PhoneNumberSettingItem.propTypes = {
  setting: commonPropTypes.setting.isRequired,
  listItemProps: PropTypes.object.isRequired,
};

export const PhoneNumberEditor = ({ route, navigation }) => {
  const { _user, setting } = route.params || {};
  const { identity } = React.useContext(AppContext);
  const [phoneNumber, setPhoneNumber] = useState(setting?.value || ``);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countriesCodeAndFlag[0]);
  const [submitting, setSubmitting] = useState(false);

  const [requestVerifyToken] = useMutation(REQUEST_VERIFY_TOKEN, {
    refetchQueries: refetchQueriesFor(`Persona`, `Setting`),
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <ThemedButton
          clear
          title="Submit"
          containerStyle={{ right: 8 }}
          onPress={() => handleSubmit()}
          disabled={!phoneNumber}
          leftIcon={submitting && <LoadingIndicator />}
          disabledStyle={{ color: Colors.textDisabled }}
        />
      ),
    });
  }, [navigation, handleSubmit, phoneNumber, selectedCountry, submitting]);

  const keyExtractor = (item) => item.dial_code.toString() + item.code.toString();

  const handlePhoneNumberchange = (text) => {
    setPhoneNumber(text);
  };

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      const formatedPhoneNumber = selectedCountry.dial_code + phoneNumber;
      // Here we send the request to the server to generate a verification code
      const { data } = await requestVerifyToken({
        variables: { id: identity?.id, contact: formatedPhoneNumber },
      });
      console.log(`🚀 🚀  ->  ~ handleSubmit ~ data`, data);

      navigation.navigate(`PhoneVerificationScreen`, { formatedPhoneNumber });
    } catch (error) {
      console.error(`An error ocurred trying to submit -> handleSubmit()`);
    } finally {
      setSubmitting(false);
    }
  }, [navigation, phoneNumber, selectedCountry, requestVerifyToken, identity]);

  const renderItem = useCallback(
    ({ item, index }) => {
      const { flag, name, code, dial_code } = item;
      return (
        <TouchableOpacity
          onPress={() => {
            setSelectedCountry(item);
            setShowDropDown(false);
          }}
        >
          <View
            style={{
              ...styles.countryItem,
              backgroundColor: code === selectedCountry.code ? Colors.moreButton : Colors.white,
            }}
          >
            <Text style={{ fontSize: 30 }}>{flag}</Text>
            <Text style={{ marginLeft: 12, ...Typography.text(`small`) }}>{name}</Text>
            <Text style={{ marginLeft: 5, ...Typography.text(`small`) }}>{dial_code}</Text>
          </View>
          {index === 3 && <Divider />}
        </TouchableOpacity>
      );
    },
    [selectedCountry],
  );

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView alwaysBounceVertical={false} keyboardShouldPersistTaps="handled">
          <View style={styles.container}>
            <BlobPink width={25} height={25} style={styles.blobStyle} />
            <Text style={{ ...Typography.text(`large`) }}>What's your phone number?</Text>
            <Text style={{ ...Typography.text(`small`, `gray`), marginTop: 22, marginLeft: 10 }}>Phone</Text>
            <View style={styles.countryTextInputContainer}>
              {/* country flag box */}
              <TouchableOpacity style={styles.countryBox} onPress={() => setShowDropDown(!showDropDown)}>
                <Text style={styles.countryFlagItem}>{selectedCountry?.flag}</Text>
                <MeshIcon name={!showDropDown ? `chevron-down` : `chevron-up`} color={Colors.iconColor} size={15} />
              </TouchableOpacity>

              {/* phone number input box */}
              <TouchableOpacity style={{ flex: 3 }}>
                <TextInput
                  placeholder="123-456-7899"
                  placeholderTextColor={Colors.textPlaceholder}
                  autoFocus
                  keyboardType="numeric"
                  returnKeyType="done"
                  style={styles.textInputStyle}
                  value={phoneNumber}
                  onChangeText={handlePhoneNumberchange}
                  maxLength={15}
                />
              </TouchableOpacity>
            </View>

            {showDropDown && (
              <View style={styles.dropdownStyle}>
                <FlatList
                  data={countriesCodeAndFlag}
                  contentContainerStyle={{}}
                  showsVerticalScrollIndicator
                  keyExtractor={keyExtractor}
                  renderItem={renderItem}
                />
              </View>
            )}
            <Text style={{ ...Typography.text(`small`), marginTop: 12, marginLeft: 10 }}>
              You may recieve notifications from Mesh for security and login purposes. Verifying your phone number also allows you
              to join communities that require phone number verification. We will never share your phone number with communities
              or external parties.
            </Text>
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

PhoneNumberEditor.propTypes = {
  route: commonPropTypes.route().isRequired,
  navigation: commonPropTypes.navigation().isRequired,
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginVertical: 13,
  },
  countryItem: {
    flexDirection: `row`,
    alignItems: `center`,
    marginHorizontal: 9,
    height: 35,
  },
  blobStyle: {
    position: `absolute`,
    zIndex: -1,
  },
  countryTextInputContainer: {
    flexDirection: `row`,
    height: 40,
    marginTop: 12,
    justifyContent: `space-between`,
  },
  countryBox: {
    marginRight: 10,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    flex: 1,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.brandPurple,
  },
  countryFlagItem: {
    fontSize: 36,
    marginRight: 5,
  },
  textInputStyle: {
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    flex: 1,
    borderRadius: 4,
    paddingLeft: 10,
  },
  dropdownStyle: {
    width: `100%`,
    height: 186,
    borderWidth: 1,
    borderColor: Colors.mediumGray,
    borderBottomEndRadius: 8,
    borderBottomStartRadius: 8,
  },
});
