import { Linking, ScrollView, Text, View } from 'react-native';
import React from 'react';
import { Typography } from '../common-styles';
import { BlobYellow, ThemedButton } from '../common-ui';
import { ChatBubbles } from '../images';

export const EarlyAccess = () => {
  const redirectToMeshSignupWeb = () => {
    //Linking.openURL(`https://app.meshconnect.us/early-access`);
    Linking.openURL(`https://app.mymesh.io/early-access`);
  };
  return (
    <ScrollView alwaysBounceVertical={false} style={{ flex: 1 }}>
      <View style={{ padding: 16, paddingBottom: 40 }}>
        <View style={{ justifyContent: `center` }}>
          {/* <GroupChatSVG height={112} width={194} style={{ alignSelf: `center` }} /> */}
          <ChatBubbles height={112} width={194} style={{ alignSelf: `center` }} />
          <View style={{ marginTop: 34, alignSelf: `center` }}>
            <BlobYellow height={25} width={25} style={{ position: `absolute`, left: -8 }} />
            <Text style={{ ...Typography.text(`large`, `bold`, `center`) }}>Mesh is currently invite only.</Text>
          </View>
          <View style={{ marginTop: 32 }}>
            <Text style={{ ...Typography.text(`plustwo`, `bold`, `center`), marginBottom: 8 }}>Have an invite link?</Text>
            <Text style={{ ...Typography.text(`center`, `gray`) }}>
              If you have an invite link, tap the link again to join your community!
            </Text>
          </View>
          <View style={{ marginTop: 32 }}>
            <Text style={{ ...Typography.text(`center`, `plustwo`, `bold`), marginBottom: 8 }}>Don't have an invite?</Text>
            <Text style={{ ...Typography.text(`center`, `gray`) }}>
              We'll be opening up to community leaders soon. Sign up below for early access.
            </Text>
          </View>
          <View style={{ height: 64 }} />
          <ThemedButton
            rounded
            title="Request early access"
            buttonStyle={{ padding: 15 }}
            titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`) }}
            onPress={redirectToMeshSignupWeb}
          />
        </View>
      </View>
    </ScrollView>
  );
};
