import React from 'react';
import { Linking, Text, View, StyleSheet } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import ParsedText, { ParseShape } from 'react-native-parsed-text';
import { MoreText } from '../MoreText';
import { BackgroundBlob } from '../../common-ui/Blobs';
import { getTextStyleForPost, mentionPattern, urlRegex } from '../helpers';
import { Colors, Typography } from '../../common-styles';
import { getStyleConfig, getBackgroundConfig, getTextStyleConfig } from '../PostStyles';
import { POST_TEXT } from './testLabels';
import { client, GET_PERSONA } from '../../graphql';
import { Events, PendoTrackEvent } from '../../pendo/events';

type PostTextProps = {
  activity: any;
  width: number;
  text: string;
  overflowMode: `fit` | `expand`;
  startExpanded: boolean;
  onPress?: () => void;
  selectable: boolean;
  meta_style?: any;
  onLayout?: () => void;
  isMediaActivity?: boolean;
  fromPersonalFeed?: boolean;
};

const postStyles = StyleSheet.create({
  centered: {
    justifyContent: `center`,
    alignItems: `center`,
  },
});

const PostText: React.FC<PostTextProps> = React.memo(
  ({
    activity = {},
    width,
    text,
    overflowMode = `fit`,
    startExpanded = false,
    onPress = () => {},
    selectable = true,
    meta_style,
    onLayout,
    isMediaActivity = false,
    fromPersonalFeed = false,
  }) => {
    const navigation = useNavigation<any>();
    const route = useRoute();
    const { origin_group_id: group_id, id, validMentionTargets, user, as_community, origin_group } = activity || {};
    const caption = as_community ? origin_group?.name : user?.handle;
    const trimmedText = isMediaActivity ? `${caption || ``} ${text.trim()}` : text.trim();
    const styling = getTextStyleForPost(trimmedText, width, overflowMode);
    const { style, height, margin, numberOfLines } = getStyleConfig(styling, width, overflowMode, meta_style);
    const background = getBackgroundConfig(meta_style);
    const textStyle = getTextStyleConfig(styling, style, overflowMode, background.isBlob);

    // Text without blob(very long text gets a MoreText)
    if (styling.textClassification === `long`)
      return (
        <MoreText
          text={trimmedText}
          selectable={selectable}
          startingHeight={90}
          startExpanded={startExpanded}
          onPress={onPress}
          isMediaActivity={isMediaActivity}
          actor={caption}
        />
      );

    const handleGoToProfile = async (personaId: String) => {
      try {
        if (personaId) {
          const { data } = await client.query({
            query: GET_PERSONA,
            variables: { id: personaId },
            fetchPolicy: `no-cache`,
          });
          PendoTrackEvent(Events.PROFILE, {
            username: data?.getPersona?.handle,
            source: route?.name,
            element: `mention`,
          });
          navigation.navigate(`ProfileStack`, {
            screen: `Profile`,
            params: { user: data?.getPersona, from: route?.name, post_id: id, group_id },
          });
        }
      } catch (err) {
        console.error(`error in handleGoToProfile`, err);
      }
    };

    const renderTextPost = (matchingString: String) => {
      const isContained = validMentionTargets?.find(
        (elem: { handle: string }) => elem.handle.toLowerCase() === matchingString.substring(1).toLowerCase(),
      );
      const match = matchingString.match(mentionPattern) && !!isContained;

      if (match)
        return (
          <Text
            onPress={() => handleGoToProfile(isContained?.id)}
            style={{ ...Typography.text(`bold`, `theme`), color: Colors.deepPurple }}
          >
            {matchingString}
          </Text>
        );
      return <Text style={{ ...Typography.text() }}>{matchingString}</Text>;
    };

    const renderTextPostWithBG = (matchingString: String) => {
      const isContained = validMentionTargets?.find(
        (elem: { handle: string }) => elem.handle.toLowerCase() === matchingString.substring(1).toLowerCase(),
      );
      const match = matchingString.match(mentionPattern) && !!isContained;

      if (match)
        return (
          <Text
            onPress={() => handleGoToProfile(isContained?.id)}
            style={{
              ...style,
              color: background.isBlob ? Colors.deepPurple : textStyle.color,
              textDecorationLine: background.isBlob ? `none` : `underline`,
              fontFamily: textStyle.fontFamily,
            }}
          >
            {matchingString}
          </Text>
        );
      return <Text style={{ ...style, color: textStyle.color }}>{matchingString}</Text>;
    };

    const textPatterns: ParseShape[] = [
      {
        pattern: new RegExp(origin_group?.name),
        style: Typography.text(`bold`),
      },
      {
        //We need this prop because it's a must have for the ParseText lib
        //but the real job is done in renderText
        pattern: mentionPattern,
        style: { ...Typography.text(`bold`), color: Colors.deepPurple },
        // @ts-ignore reason: lib does not provide the types
        renderText: renderTextPost,
      },
      {
        pattern: urlRegex,
        style: { ...Typography.text(`bold`), color: Colors.deepPurple },
        onPress: (url) => {
          if (!url.includes(`https`)) {
            Linking.openURL(`https://${url}`);
          } else {
            Linking.openURL(url);
          }
        },
      },
      {
        type: `url`,
        style: { ...Typography.text(`bold`), color: Colors.deepPurple },
        onPress: (url) => Linking.openURL(url),
      },
    ];

    if (isMediaActivity && caption)
      textPatterns.push({
        pattern: new RegExp(`^${caption}`),
        style: Typography.text(`bold`),
      });

    //Default style: text (not too long) on white background
    if (!background.isBlob && background.backgroundIndex === 10) {
      return (
        <Text style={{ marginHorizontal: 12, marginVertical: 8 }} selectable={selectable}>
          {text?.length ? (
            <ParsedText
              onPress={() =>
                navigation.navigate(`PostDetail`, {
                  id,
                  group_id,
                  isPersonalFeed: activity.__typename === `ProfileActivity`,
                  fromPersonalFeed,
                })
              }
              numberOfLines={numberOfLines}
              ellipsizeMode="tail"
              selectable={selectable}
              style={[Typography.text(), { padding: margin, marginBottom: -10 }]}
              parse={textPatterns}
            >
              {trimmedText}
            </ParsedText>
          ) : undefined}
        </Text>
      );
    }
    // special custom-style blob/background
    return (
      <View
        style={
          {
            flex: 1,
            width,
            height,
            overflow: `hidden`,
            backgroundColor: background.backgroundColor,
            ...postStyles.centered,
          } as any
        }
        onLayout={onLayout}
      >
        <BackgroundBlob width={width} height={height} blobIndex={background.backgroundIndex} />

        <View style={{ height: height - 2 * margin, width: width - 2 * margin, justifyContent: `center` }}>
          <ParsedText
            onPress={onPress}
            numberOfLines={numberOfLines}
            ellipsizeMode="tail"
            selectable={selectable}
            style={[
              { ...style, color: textStyle.color },
              { padding: margin, color: textStyle.color, fontFamily: textStyle.fontFamily },
            ]}
            testID={POST_TEXT}
            parse={[
              {
                //We need this prop because it's a must have for the ParseText lib
                //but the real job is done in renderText
                pattern: mentionPattern,
                // @ts-ignore reason: lib does not provide the types
                renderText: renderTextPostWithBG,
              },
            ]}
          >
            {text.trim()}
          </ParsedText>
        </View>
      </View>
    );
  },
);

export default PostText;
