/* eslint-disable react/forbid-prop-types */
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle, TextStyle, KeyboardAvoidingView } from 'react-native';
import Modal, { ModalProps } from 'react-native-modal';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { MODAL_TOGGLE } from './testLabels';

type SwipableProps = Pick<ModalProps, `onSwipeComplete` | `swipeDirection` | `propagateSwipe`>;
type FastProps = Pick<ModalProps, `animationIn` | `animationOut` | `backdropTransitionInTiming` | `backdropTransitionOutTiming`>;

type MenuModalProps = {
  isVisible?: boolean;
  toggleModal: () => void;
  children: React.ReactNode;
  swipeable?: boolean;
  style?: StyleProp<ViewStyle>;
  fast?: boolean;
  avoidKeyboard?: boolean;
};

export const MenuModal = ({
  isVisible,
  toggleModal,
  children,
  swipeable = false,
  style = {},
  fast = false,
  avoidKeyboard = false,
  ...props
}: MenuModalProps) => {
  // if we want to pass onSwipe props, pass them into the prop of the wrapper MenuModal around the modal content for now...
  // issues with propagating swipe of children and the swipe to dismiss modal
  // see: https://github.com/react-native-community/react-native-modal/issues/236
  // ...OR use swipable prop
  // const swipeableProps: ModalProps = swipeable
  const swipeableProps = swipeable
    ? ({
        swipeDirection: `down`,
        propagateSwipe: true,
        onSwipeComplete: toggleModal,
      } as SwipableProps)
    : {};

  const fastProps = fast
    ? ({
        animationIn: `none`,
        animationOut: `none`,
        backdropTransitionInTiming: 1,
        backdropTransitionOutTiming: 1,
      } as FastProps)
    : {};
  return (
    <Modal
      {...swipeableProps}
      {...fastProps}
      {...props}
      isVisible={isVisible}
      onBackdropPress={avoidKeyboard ? () => {} : toggleModal}
      style={{ margin: 0, justifyContent: `flex-end` }}
      testID={MODAL_TOGGLE}
    >
      <KeyboardAvoidingView enabled={avoidKeyboard} behavior={avoidKeyboard ? 'padding' : undefined}>
        <View
          style={{
            backgroundColor: `white`,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            justifyContent: `center`,
            ...StyleSheet.flatten(style),
          }}
        >
          {/* swipeable indicator */}
          {!!swipeable && (
            <View style={{ width: `100%`, flexDirection: `row`, justifyContent: `center` }}>
              <View
                style={{ marginTop: 10, borderRadius: 3, width: 56, height: 4, backgroundColor: Colors.headerBottomBorderColor }}
              />
            </View>
          )}
          {children}
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};
// MenuModal.propTypes = {
//   isVisible: PropTypes.bool,
//   toggleModal: PropTypes.func.isRequired,
//   children: PropTypes.node.isRequired,
//   swipeable: PropTypes.bool,
//   fast: PropTypes.bool,
//   style: PropTypes.object,
// };
// MenuModal.defaultProps = {
//   isVisible: undefined,
//   swipeable: false,
//   fast: false,
//   style: {},
// };
type MenuItemProps = {
  title: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  fontStyle?: StyleProp<TextStyle>;
};
export const MenuItem = ({ title, onPress, style = {}, fontStyle = {} }: MenuItemProps) => {
  return (
    <TouchableOpacity style={[{ padding: 17 }, style]} onPress={onPress}>
      <View>
        <Text
          style={[
            {
              fontSize: Typography.buttonFontSize,
              color: `blue`,
              textAlign: `center`,
            },
            fontStyle,
          ]}
        >
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
// MenuItem.propTypes = {
//   title: PropTypes.string.isRequired,
//   onPress: PropTypes.func.isRequired,
//   style: PropTypes.object.isRequired,
//   fontStyle: PropTypes.object,
// };
// MenuItem.defaultProps = {
//   fontStyle: {},
// };
