import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Platform, ScrollView, Text, TouchableOpacity, View, Appearance } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { BackButton, MeshIcon, ThemedButton } from '../common-ui';
import { commonPropTypes, shortDateOptions } from '../common-util';
import { DOBTestIds } from './testLabels';
import { WizardLayout } from '../community-create/WizardLayout';
import { setProfileDOB, movePrev } from '../redux/createProfileSlice';
import { Colors, Typography } from '../common-styles';

const DateTimePicker = Platform.OS !== `web` ? require(`@react-native-community/datetimepicker`).default : undefined;

export const ProfileDOB = ({ navigation }) => {
  const { profileDob } = useSelector((state) => state.createProfile);
  const initialDob = profileDob
    ? new DataView(profileDob)
    : new Date(new Date(`1 January`).setFullYear(new Date().getFullYear() - 1));
  const [showDatePicker, setShowDatePicker] = useState(Platform.OS === `ios`);
  const [isOldEnough, setIsOldEnough] = useState();
  const [dob, setDob] = useState(initialDob);
  const ScrollRef = useRef();
  const dispatch = useDispatch();

  const onSubmit = () => {
    const userIs13 = checkDOB(dob);
    if (!userIs13) {
      console.log(`user is not at least 13`, userIs13);
      setIsOldEnough(false);
    } else {
      dispatch(setProfileDOB(dob.getTime()));
      console.log(`user is old enough`, userIs13);
      navigation.push(`ProfilePronouns`);
    }
  };
  const goBack = useCallback(() => {
    dispatch(movePrev());
    navigation.goBack();
  }, [dispatch, navigation]);

  const toggleDatePicker = () => {
    if (ScrollRef.current) ScrollRef.current.scrollToEnd();
    setShowDatePicker(!showDatePicker);
    setIsOldEnough();
  };

  const checkDOB = (dob) => {
    // mesh users must be at least 13 years old
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const month = today.getMonth() - dob.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < dob.getDate())) age -= 1;
    return age > 13;
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
  }, [navigation, goBack]);

  const iconName = isOldEnough === false ? `info-circle` : `check`;
  let iconColor = Colors.brandPurple;
  if (isOldEnough === false) iconColor = Colors.brightRed;
  else if (isOldEnough === true) iconColor = Colors.brandGreen;
  const darkScheme = Appearance.getColorScheme() === `dark`;
  const schemeStyles = {
    dateTimeContainer: { backgroundColor: darkScheme ? `#1B1A1E` : Colors.white },
    dateTimeHeader: { backgroundColor: darkScheme ? Colors.textBlack : Colors.backgroundColor },
    doneBtn: { color: darkScheme ? Colors.white : Colors.textBlack },
  };
  return (
    <WizardLayout total={6} selector="createProfile">
      <ScrollView
        testID={DOBTestIds.screen}
        alwaysBounceVertical={false}
        keyboardShouldPersistTaps="handled"
        ref={ScrollRef}
        style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
      >
        <View style={{ marginHorizontal: 15, marginTop: 45 }}>
          <View style={localStyles.blob} />
          <Text style={localStyles.headerText}>What's your date of birth?</Text>
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={toggleDatePicker}
            style={{ ...localStyles.dobContainer, borderColor: iconColor }}
            testID={DOBTestIds.date}
          >
            <Text>{dob && dob.toLocaleDateString(`en-us`, shortDateOptions)}</Text>
            {isOldEnough !== undefined && <MeshIcon name={iconName} color={iconColor} size={20} />}
          </TouchableOpacity>
        </View>
        {isOldEnough !== undefined && !isOldEnough && (
          <View style={localStyles.errorContainer} testID={DOBTestIds.error}>
            <Text style={localStyles.errorText}>Sorry, you must be over 13 to join Mesh</Text>
          </View>
        )}
        <View style={localStyles.hiddenTagContainer}>
          <View style={localStyles.hiddenTag}>
            <MeshIcon name="eye-hide" color={Colors.gray} size={16} />
            <Text style={localStyles.hiddenTagText1}>Hidden</Text>
          </View>
          <Text style={localStyles.hiddenTagText2}>This won't be visible on your profile and can't be changed later.</Text>
        </View>
        <ThemedButton
          rounded
          title="Continue"
          testID={DOBTestIds.continue}
          onPress={onSubmit}
          buttonStyle={{ padding: 15 }}
          containerStyle={{ marginVertical: 24, marginHorizontal: 16 }}
          titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
          // disabled={nextDisabled}
        />
        {showDatePicker && (
          <View style={[localStyles.dateTimePickerContainer, schemeStyles.dateTimeContainer]}>
            <View style={[localStyles.dateTimeHeaderContainer, schemeStyles.dateTimeHeader]}>
              <TouchableOpacity activeOpacity={0.8} onPress={onSubmit} testID={DOBTestIds.done}>
                <Text style={[localStyles.dateTimeHeaderText, schemeStyles.doneBtn]}>Done</Text>
              </TouchableOpacity>
            </View>
            <DateTimePicker
              mode="date"
              value={dob}
              display="spinner"
              is24Hour={false}
              onChange={(_evt, date) => {
                if (Platform.OS === `android`) setShowDatePicker(false);
                if (date) setDob(date);
              }}
              maximumDate={new Date(2020, 12, 30)}
              style={localStyles.dateTimePickerStyle}
              testID={DOBTestIds.modal}
            />
          </View>
        )}
      </ScrollView>
    </WizardLayout>
  );
};
ProfileDOB.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
};

const localStyles = {
  container: {
    flex: 1,
    flexDirection: `column`,
  },
  headerText: {
    ...Typography.text(`large`, `black`, `bold`),
  },
  dobContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    padding: 10,
    borderWidth: 1,
    borderRadius: 4,
    marginTop: 40,
  },
  hiddenTagContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 24,
    marginHorizontal: 16,
  },
  hiddenTag: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderRadius: 99,
    alignItems: `center`,
    shadowColor: Colors.darkGray,
    backgroundColor: Colors.lightGray,
    padding: 6,
    paddingHorizontal: 12,
  },
  hiddenTagText1: {
    ...Typography.text(`small`, `gray`),
    marginLeft: 5,
  },
  hiddenTagText2: {
    ...Typography.text(`small`, `gray`),
    flexShrink: 1,
    flexWrap: `wrap`,
    marginLeft: 8,
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(44,154,68,0.5)`,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 20,
    left: -6,
    top: 2,
  },
  dateTimePickerContainer: {
    flex: 1,
    width: `100%`,
    justifyContent: `flex-end`,
  },
  dateTimeHeaderContainer: {
    width: `100%`,
    padding: 10,
  },
  dateTimeHeaderText: {
    ...Typography.text(`base`, `black`, `bold`),
    alignSelf: `flex-end`,
  },
  dateTimePickerStyle: {
    justifyContent: `flex-end`,
    paddingBottom: 60,
  },
  errorContainer: {
    marginHorizontal: 16,
    marginTop: 8,
  },
  errorText: {
    ...Typography.text(`small`, `bold`, `red`),
  },
};
