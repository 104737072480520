import React, { useCallback } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { MeshIcon, Divider } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { DrawerSettingNavigatorScreens } from '../common-types/navigation-types';

type DrawerNavigation = StackNavigationProp<DrawerSettingNavigatorScreens, `DrawerSettingsMenu`>;

export const PaymentMethodItem = () => {
  const navigation = useNavigation<DrawerNavigation>();
  const navigateToPaymentMethod = useCallback(() => navigation.navigate(`PaymentMethodList`), [navigation]);

  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={navigateToPaymentMethod}
        style={{ flex: 1, flexDirection: `row`, justifyContent: `space-between`, backgroundColor: Colors.white, padding: 16 }}
      >
        <View style={{ flex: 1, justifyContent: `center` }}>
          <Text style={Typography.text(`plusone`, `bold`)}>Payment Methods</Text>
        </View>
        <MeshIcon name="chevron-right" color={Colors.iconColor} />
      </TouchableOpacity>
      <Divider />
    </View>
  );
};
