import React, { useState, useEffect, useContext } from 'react';
import { ActivityIndicator, Alert } from 'react-native';
import { useLazyQuery, useMutation } from 'react-apollo';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { GuestPromptModal, SafeAreaView, ThemedButton } from '../common-ui';
import { CodeOfConduct } from './index';
import { client, AGREE_TO_CODE_OF_CONDUCT, refetchQueriesFor, COMMUNITY_SETTING, GET_GROUP, updateCache } from '../graphql';
import { useJoinCommunity } from './hooks/useJoinCommunity';
import { SAVE_AND_JOIN } from './testLabels';
import { useAppDispatch } from '../redux/store';
import { setMemberCount } from '../redux/feedSlice';
import { AppContext } from '../../AppContext';
import { CommunitySettingsInput, CommunitySettingsData, Setting, Guideline } from '../common-types/types';
import { ExploreNavigator, ExploreNavigatorScreens } from '../common-types/navigation-types';
import { Colors } from '../common-styles';
import { Events, PendoTrackEvent } from '../pendo/events';

type CoCRoute = RouteProp<ExploreNavigatorScreens, `CommunityCodeOfConduct`>;
/**
 * Code of Conduct screen to replace GuidelinesModal
 * accepts navigation params of: [group: object, group_member: object, joinCommunity: function]
 */

export const CommunityCodeOfConductScreen: React.FC = () => {
  const navigation = useNavigation<ExploreNavigator>();
  const route = useRoute<CoCRoute>();
  const { group, group_member: gm, toAgree, nextDisabled = true, agreeHidden = false } = route?.params || {};
  const { user, setUser, isGuestMode } = useContext(AppContext);
  const dispatch = useAppDispatch();

  const [agree, setAgree] = useState(false);
  const [cocSetting, setCoCSetting] = useState<Setting>();
  const [fetchedGuidelines, setFetchedGuidelines] = useState<Guideline[]>();
  const [loadingGuidelines, setLoadingGuidelines] = useState(true);
  const [group_member, setGroupMember] = useState(gm);
  const [guestPromptVisible, setGuestPromptVisible] = useState(false);
  const { response, attemptJoinCommunity, loading } = useJoinCommunity(group);

  const showAgreeAndJoin = !group_member || !toAgree; // if group_member does not exist, show agree and join prompt
  const validRole = group_member?.role_name === `owner` || group_member?.role_name === `manager`;

  const [agreeToCoC] = useMutation(AGREE_TO_CODE_OF_CONDUCT, {
    variables: { group_id: group.id },
    refetchQueries: refetchQueriesFor(`Setting`, `GroupMember`),
  });

  const [getSettings] = useLazyQuery<CommunitySettingsData, CommunitySettingsInput>(COMMUNITY_SETTING, {
    variables: {
      input: {
        group_id: group?.id,
        key: `group.setting.guidelines`,
      },
    },
    onCompleted: (data) => {
      try {
        const setting = data?.getCommunitySetting;
        const parsedGuidelines = JSON.parse((setting?.value as string) || `[]`);
        setFetchedGuidelines(parsedGuidelines);
        const edit = !agree && group_member && validRole;
        if (edit) setCoCSetting(setting);
      } catch (error) {
        console.error(`Something went wrong in the onCompleted callback for getSettings query `, error);
      } finally {
        setLoadingGuidelines(false);
      }
    },
    onError: (error) => console.log(error),
    fetchPolicy: `network-only`,
  });

  const headerRightPress = React.useCallback(async () => {
    if (validRole && cocSetting && group_member?.has_agreed) {
      navigation.navigate(`EditCodeOfConduct`, { setting: cocSetting });
      return;
    }
    if (group_member) {
      await agreeToCoC();
      const { data } = await client.query({ query: GET_GROUP, variables: { group_id: group?.id }, fetchPolicy: `network-only` });
      const { group_member: membership } = data?.getGroup || {};
      // @ts-ignore TODO: refactor updateCache for TS
      if (membership) updateCache({ group_member: membership });
      navigation.goBack();
    } else {
      if (isGuestMode) setGuestPromptVisible(true);

      await attemptJoinCommunity(`CommunityCodeOfConduct`);
      PendoTrackEvent(Events.JOIN_COMMUNITY, {
        community_name: group.name,
        source: user?.pending_completition ? `create account flow` : `community preview`,
        application_required: false,
        admin_approval_required: true,
      });
      // @ts-ignore
      navigation.navigate(`HomeNavigator`, { screen: `HomeScreen` });
    }
    if (user?.pending_completition) {
      user.pending_completition = false;
      setUser(user);
    }
  }, [
    validRole,
    cocSetting,
    navigation,
    group_member,
    agreeToCoC,
    group?.id,
    group?.name,
    user,
    setUser,
    isGuestMode,
    attemptJoinCommunity,
  ]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    try {
      const { data, loading, error } = response || {};
      if (error) throw error;
      if (data && !loading) {
        const { group_member, group } = data?.joinGroup || {};
        if (group_member) {
          setGroupMember(group_member);
          dispatch(setMemberCount(group));
        }
      }
    } catch (error: any) {
      console.error(`joinCommunity error:`, error);
      setTimeout(
        () =>
          Alert.alert(`There was an error joining ${group?.name?.trim()}`, error.message, [
            { text: `OK`, style: `default`, onPress: () => null },
          ]),
        500,
      );
    }
  }, [navigation, response, group?.name, setGroupMember, dispatch]);

  useEffect(() => {
    //Note: no onpress function found for valid role to edit the CoC for this component
    navigation.setOptions({
      headerRight: () =>
        agreeHidden ? null : (
          <ThemedButton
            clear
            title={validRole && group_member?.has_agreed ? `Edit` : `Continue`}
            containerStyle={{ marginRight: 8 }}
            onPress={headerRightPress}
            disabled={(nextDisabled && !validRole) || loading}
            testID={SAVE_AND_JOIN}
            rightIcon={loading ? <ActivityIndicator color={Colors.gray} size={15} style={{ marginLeft: 5 }} /> : undefined}
          />
        ),
    });
  }, [navigation, headerRightPress, agreeToCoC, group_member, nextDisabled, validRole, loading, agreeHidden]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <CodeOfConduct
        group={group}
        fetchedGuidelines={fetchedGuidelines}
        loadingGuidelines={loadingGuidelines}
        agree={agree}
        toggleAgree={() => {
          const newAgree = !agree;
          navigation.setParams({ nextDisabled: !newAgree });
          setAgree(newAgree);
        }}
        showAgreeAndJoin={showAgreeAndJoin && !agreeHidden}
      />
      <GuestPromptModal isVisible={guestPromptVisible} setHide={() => setGuestPromptVisible(false)} />
    </SafeAreaView>
  );
};
