import { useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import ParsedText from 'react-native-parsed-text';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { LeftDrawerNavigator, MainTabsNavigator, NotificationNavigator } from '../common-types/navigation-types';
import {
  GetGroupData,
  GetGroupVars,
  GroupCoreFields,
  User,
  CommunitySettingsInput,
  CommunitySettingsData,
} from '../common-types/types';
import { formatShortRelativeDate } from '../common-util';
import { client, GET_GROUP, loadCachedGroup, loadCachedGroupMember, COMMUNITY_SETTING } from '../graphql';
import { MeshVerifiedCheck } from '../images';
import { Events, PendoTrackEvent } from '../pendo/events';
import { setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';
import { BaseNotificationItem } from './BaseNotificationItem';

enum FollowingActivityTypes {
  joinGroup = `JOIN_GROUP`,
  addPost = `ADD_POST`,
  addComment = `ADD_COMMENT`,
  createGroup = `CREATE_GROUP`,
}

interface NotiItem {
  id: string;
  actor: User;
  activity_type: FollowingActivityTypes;
  activity_at: string;
  group: GroupCoreFields;
  isRead: boolean;
  activity_id: string;
  as_community: boolean;
}

interface FollowingNotiItem {
  notification: NotiItem;
  markRead: (id: string) => void;
}

const getVerb = (activity_type: string) => {
  let verb = ``;
  let noun = ``;
  let badge = ``;
  switch (activity_type) {
    case FollowingActivityTypes.addComment:
      verb = `commented`;
      noun = `on a post in`;
      badge = `comment`;
      return { verb, noun, badge };
    case FollowingActivityTypes.addPost:
      verb = `posted`;
      noun = `in`;
      badge = `sparkle`;
      return { verb, noun, badge };
    case FollowingActivityTypes.joinGroup:
      verb = `joined`;
      badge = `sparkle`;
      return { verb, noun, badge };
    case FollowingActivityTypes.createGroup:
      verb = `created`;
      noun = `the node`;
      badge = `sparkle`;
      return { verb, noun, badge };
    default:
      return { verb, noun, badge };
  }
};

export const FollowingNotificationItem: React.FC<FollowingNotiItem> = ({ notification, markRead }) => {
  const { id, actor, activity_type, activity_at, group, isRead, activity_id, as_community } = notification;
  const { user } = useContext(AppContext);
  const navigation = useNavigation<NotificationNavigator>();
  const dispatch = useDispatch();

  const handlePress = () => {
    if (!isRead) markRead(id);
    if (activity_type === FollowingActivityTypes.joinGroup || activity_type === FollowingActivityTypes.createGroup) {
      goToCommunity();
      return;
    }
    // this might grow in the future or need to implement more cases
    const validTypes = [FollowingActivityTypes.addComment, FollowingActivityTypes.addPost];
    if (validTypes.includes(activity_type)) {
      const isComment = activity_type === FollowingActivityTypes.addComment;
      handlePostAndComment(activity_id, group.id, isComment ? activity_id : undefined);
    }
  };

  const handlePostAndComment = (id: string, group_id: string, reply_id?: string) => {
    const cachedGroup = loadCachedGroup(group.id);
    const cachedGroupMember = loadCachedGroupMember(group.id, user?.id);
    if (cachedGroupMember) {
      navigation.push(`PostDetail`, { id, group_id, reply_id });
      return;
    }
    if (cachedGroup?.archived) return;
    dispatch(setPreviewCommunity(group));
    const TabNav = navigation.getParent<MainTabsNavigator>();
    TabNav.jumpTo(`NotificationNavigator`, { screen: `CommunityPreview`, params: { previous: `FollowNotification` } });
  };

  const goToCommunity = async () => {
    const cachedGroup = loadCachedGroup(group.id);
    const cachedGroupMember = loadCachedGroupMember(group.id, user?.id);
    const TabNav = navigation.getParent<MainTabsNavigator>();
    if (!user?.id) return; // typescript requirement, should always be defined by now
    if (cachedGroupMember) {
      dispatch(setActiveCommunity({ user_id: user.id, group: cachedGroup }));
      TabNav.jumpTo(`HomeNavigator`, { screen: `HomeScreen` });
      return;
    }
    const { data, errors } = await client.query<GetGroupData, GetGroupVars>({
      query: GET_GROUP,
      variables: { group_id: group?.id || `` },
      fetchPolicy: `network-only`,
    });

    const { data: data2 } = await client.query<CommunitySettingsData, CommunitySettingsInput>({
      query: COMMUNITY_SETTING,
      variables: { input: { group_id: group?.id || ``, key: `group.setting.joinable` } },
      fetchPolicy: `network-only`,
    });
    const joinable = data2.getCommunitySetting.value;

    if (errors) {
      console.log(`refreshGroup - unexpectedly no result from getGroup`);
      return;
    }
    const { group_member, group: groupInfo } = data?.getGroup;
    if (group_member) {
      dispatch(setActiveCommunity({ user_id: user.id, group }));
      TabNav.jumpTo(`HomeNavigator`, { screen: `HomeScreen` });
    } else if (joinable === `invite_only`) {
      console.log(`Group is invite only`);
    } else {
      dispatch(setPreviewCommunity(groupInfo));
      TabNav.jumpTo(`NotificationNavigator`, { screen: `CommunityPreview`, params: { previous: `FollowNotification` } });
    }
  };

  const { badge } = getVerb(activity_type);
  return (
    <BaseNotificationItem
      containerStyle={{
        backgroundColor: isRead ? Colors.seenNotificationBackground : Colors.unseenNotificationBackground,
      }}
      title={
        <NotificationTitle
          as_community={as_community}
          actor={actor}
          group={group}
          activity_type={activity_type}
          goToCommunity={goToCommunity}
        />
      }
      date={formatShortRelativeDate(activity_at)}
      avatarData={actor}
      navigation={navigation}
      badge={badge}
      avatarSize={50}
      badgeSize={22}
      onPress={handlePress}
      isSameStack={true}
      as_community={as_community}
    />
  );
};

type NotificationTitleProps = {
  actor: Pick<User, `id` | `handle` | `isVerified`>;
  activity_type: string;
  group: Pick<GroupCoreFields, `id` | `name`>;
  goToCommunity: () => void;
  as_community: boolean;
};

const NotificationTitle: React.FC<NotificationTitleProps> = ({ activity_type, actor, group, goToCommunity, as_community }) => {
  const navigation = useNavigation();
  // going up in hierarchy Tabs->RightDrawer->LeftDrawer
  const LDrawerNav = navigation.getParent()?.getParent()?.getParent<LeftDrawerNavigator>();

  const { verb, noun } = getVerb(activity_type);
  const goToProfile = () => {
    if (actor?.id) {
      PendoTrackEvent(Events.PROFILE, {
        username: actor?.handle,
        source: `notifications`,
        element: `follow notification`,
      });
      LDrawerNav?.jumpTo(`ProfileStack`, { screen: `Profile`, params: { user_id: actor.id } });
    }
  };
  const matcher_subject = [
    {
      pattern: new RegExp(as_community ? group?.name : actor?.handle.replace(/[^a-zA-Z0-9 ]/g, '')),
      onPress: goToProfile,
      style: Typography.add(`bold`),
    },
  ];
  const matcher_object = [
    {
      pattern: new RegExp(group?.name.replace(/([.?*+^$[\]\\(){}|-])/g, `\\$1`), `i`),
      onPress: goToCommunity,
      style: Typography.add(`bold`),
    },
  ];

  const _subject = `${as_community ? group?.name : actor?.handle}`;
  const _verb = as_community && verb === `posted` ? `added a new post` : verb;
  const _object = group?.name;

  return (
    <View style={{ flexDirection: `row`, alignItems: `center`, flex: 1, flexWrap: `wrap` }}>
      <ParsedText maxFontSizeMultiplier={1.8} style={Typography.text()} parse={matcher_subject}>
        {_subject}
      </ParsedText>
      {!as_community && actor?.isVerified && (
        <MeshVerifiedCheck height={14} width={14} style={{ marginRight: 5, marginLeft: 2 }} />
      )}
      <Text style={{ marginHorizontal: 3, marginBottom: 10 }}>
        {_verb} {noun}
      </Text>
      <ParsedText maxFontSizeMultiplier={1.8} style={Typography.text()} parse={matcher_object}>
        {_object}
      </ParsedText>
    </View>
  );
};
