import { client, refetchQueriesFor, REPORT_MODERATION_ISSUE } from '../graphql';

/**
 * send in a report to moderation for a post / comment / community / user report
 * @param {object} input
 * @param {string} input.group_id
 * @param {string} input.reaction_id
 * @param {string} input.object_id
 * @param {string[]} input.reasons
 * @param {string} input.explanation
 * @param {string} input.type
 * @returns {Promise<import('../common-types/types').ModIssue>} returns promise obj of report
 */
const reportModerationIssue = async (input) => {
  try {
    const sendReport = await client.mutate({
      mutation: REPORT_MODERATION_ISSUE,
      variables: { input },
      refetchQueries: refetchQueriesFor(`ModerationIssue`),
    });
    const reported = sendReport.data.reportModerationIssue;
    return reported;
  } catch (err) {
    console.error(`exception thrown in reportModerationIssue`, err.message);
    return null;
  }
};

export { reportModerationIssue };
