import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const MaestroImage = (props) => {
  return (
    <Svg width={66} height={46} viewBox="0 0 66 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0h1300v7847H0z" />
        <Path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill="#262626"
        />
        <Path fill="#7673C0" d="M27.732 13.973H38.41V33.36H27.732z" />
        <Path
          d="M28.414 23.664a12.355 12.355 0 014.66-9.691 12.114 12.114 0 00-16.505 1.329c-4.318 4.723-4.318 12.005 0 16.728a12.114 12.114 0 0016.506 1.33 12.355 12.355 0 01-4.661-9.696z"
          fill="#EB001B"
        />
        <Path
          d="M51.654 31.774v-.786h.16v-.163h-.404v.163h.16v.786h.084zm.783 0v-.949h-.122l-.142.678-.142-.678h-.126v.95h.088v-.712l.132.617h.092l.132-.617v.718l.088-.007z"
          fill="#00A1DF"
          fillRule="nonzero"
        />
        <Path
          d="M52.817 23.664c0 4.72-2.668 9.027-6.87 11.09a12.102 12.102 0 01-12.876-1.398 12.375 12.375 0 004.663-9.693c0-3.783-1.719-7.356-4.663-9.694a12.102 12.102 0 0112.877-1.396c4.203 2.063 6.87 6.37 6.869 11.091z"
          fill="#00A1DF"
        />
      </G>
    </Svg>
  );
};
