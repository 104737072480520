import { NetworkStatus } from 'apollo-client';
import PropTypes from 'prop-types';
import { Dimensions, Platform, StyleSheet, View, Text, FlatList } from 'react-native';
import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFICATIONS_PER_FETCH } from '../../constants';
import { Colors, Typography } from '../common-styles';
import { EmptyNotifications } from '../images';
import { BottomSheetModal, CenterColumn, LoadingIndicator, ThemedButton } from '../common-ui';
import { NotificationItem } from './NotificationItem';
import { showNotImplementedOptions } from './notificationsTempConstants';
import { AppContext } from '../../AppContext';
import { refreshFollowingNotification } from '../redux/followSlice';

export const FollowingNotificationList = ({ markRead, data, loading, networkStatus, refetch, fetchMore }) => {
  const dispatch = useDispatch();
  const { user } = React.useContext(AppContext);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreToFetch, setNoMoreToFetch] = useState(false);

  const { isFollowingNotiRefresh } = useSelector((state) => state.follow);

  const notifications = data?.getFollowingNotifications?.data || [];
  // Sort the notifications by most recent at the beginning given the Date of creation
  const sortedNotifications = notifications;

  const showOptionsModal = useCallback(
    (type) => {
      setOptions(buildOptionsMenu(type));
      setShowOptions(true);
    },
    [buildOptionsMenu],
  );

  useEffect(() => {
    if (isFollowingNotiRefresh) {
      onRefresh();
    }
  }, [isFollowingNotiRefresh]);
  /**
   * return options to display for OptionsModal
   * @returns {object[]} array of objects containing what options to show for the OptionsModal
   */
  const buildOptionsMenu = useCallback(
    (notification) => {
      if (!notification) return [];
      const { verb } = notification;
      const allowedTurnOffNotifications = [`like`, `comment`, `comment_like`, `reply_comment`];
      const options = [
        {
          title: `Mark as read`,
          iconName: `eye`,
          onPress: () => {
            setShowOptions(false);
            markRead(notification.id);
          },
        },
        {
          predicate: () => !!showNotImplementedOptions && allowedTurnOffNotifications.indexOf(verb) >= 0,
          title: `Turn off notifications about this post`,
          iconName: `volume-x`,
          onPress: () => {
            setShowOptions(false);
            /** TBD We need to implement this */
          },
        },
        {
          predicate: () => !!showNotImplementedOptions,
          title: `Remove notification`,
          titleStyle: { color: Colors.alertColor },
          iconName: `minus-circle`,
          iconColor: Colors.alertColor,
          onPress: () => {
            setShowOptions(false);
            /** TBD We need to implement this */
          },
        },
      ];
      return options.filter((option) => option.predicate === undefined || option.predicate());
    },
    [markRead],
  );

  const getMoreItems = useCallback(() => {
    const total = sortedNotifications.length;
    if (!loading && !loadingMore && !noMoreToFetch) {
      setLoadingMore(true);

      fetchMore({
        variables: {
          filter: {
            skip: total,
            limit: 15,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult?.getFollowingNotifications?.data?.length ||
            fetchMoreResult?.getFollowingNotifications?.data.slice(-1)[0].id ===
              prev?.getFollowingNotifications?.data.slice(-1)[0].id
          ) {
            console.log(`Same with previous one, return prev`);
            setLoadingMore(false);
            setNoMoreToFetch(true);
            return prev;
          }

          setLoadingMore(false);
          setNoMoreToFetch(false);

          return {
            ...prev,
            getFollowingNotifications: {
              __typename: `getFollowingNotifications`,
              data: [...prev?.getFollowingNotifications?.data, ...fetchMoreResult?.getFollowingNotifications?.data],
            },
          };
        },
      });
    }
  }, [data?.getFollowingNotifications?.data, fetchMore, loading, loadingMore, noMoreToFetch, setNoMoreToFetch, setLoadingMore]);

  const onRefresh = () => {
    if (user?.id) {
      refetch({
        filter: {
          limit: NOTIFICATIONS_PER_FETCH,
        },
      });
      setNoMoreToFetch(false);
      dispatch(refreshFollowingNotification(false));
    }
  };

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <CenterColumn>
          <NotificationItem notification={item} markRead={markRead} onPressOptionsButton={showOptionsModal} status="following" />
        </CenterColumn>
      );
    },
    [markRead, showOptionsModal],
  );

  const renderFooter = () => {
    if (sortedNotifications.length)
      return (
        <CenterColumn>
          <View style={localStyles.containerListFooter}>
            {loadingMore && <LoadingIndicator size="small" />}
            {!loadingMore && !loading && !noMoreToFetch && (
              <ThemedButton
                rounded
                outline
                clear
                title="Load more"
                testID="LOAD_MORE_NOTIFICATIONS"
                onPress={getMoreItems}
                buttonStyle={{ paddingHorizontal: 30, paddingVertical: 12 }}
                disabled={loading || loadingMore || noMoreToFetch}
              />
            )}
          </View>
        </CenterColumn>
      );
    return null;
  };

  const EmptyNotificationList = useCallback(() => {
    if (!loading && sortedNotifications.length === 0)
      return (
        <View style={{ flex: 1, alignItems: `center`, paddingHorizontal: 43, paddingVertical: 48 }}>
          <EmptyNotifications style={{ marginBottom: 32, marginTop: 100 }} width="288" height="220" />
          <View style={{ justifyContent: `center` }}>
            <Text style={{ ...Typography.text(`center`) }}>
              Notifications from people you're following will appear here when you receive them.
            </Text>
          </View>
        </View>
      );
    if (loading) {
      return <LoadingIndicator />;
    }
    return null;
  }, [loading]);

  return (
    <View style={localStyles.container}>
      <FlatList
        testID="NOTIFICATION_LIST"
        ListEmptyComponent={EmptyNotificationList}
        data={sortedNotifications}
        refreshing={networkStatus === NetworkStatus.refetch}
        onRefresh={onRefresh}
        onEndReachedThreshold={0.3}
        onEndReached={getMoreItems}
        keyExtractor={(item, index) => (item && item.id + index.toString()) || uuidv4()}
        renderItem={renderItem}
        ListFooterComponent={renderFooter}
        style={localStyles.containerFlatList}
      />
      <BottomSheetModal
        title="Manage notifications"
        isVisible={showOptions}
        hideOptionsModal={() => setShowOptions(false)}
        options={options}
      />
    </View>
  );
};

FollowingNotificationList.propTypes = {
  markRead: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  networkStatus: PropTypes.number.isRequired,
  data: PropTypes.object,
};

FollowingNotificationList.defaultProps = {
  data: {
    getFollowingNotifications: {
      data: [],
    },
  },
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundColor,
  },
  containerFlatList: {
    flex: 1,
    height: Platform.select({ web: Dimensions.get(`window`).height - 48 * 2 }),
  },
  containerListFooter: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 10,
  },
});
