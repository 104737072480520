import React, { memo } from 'react';
import { StyleProp, Text, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';
import FastImage, { ImageStyle } from 'react-native-fast-image';
import { Colors } from '../common-styles';
import { Maybe } from '../common-types/types';
import { CloseBoxOverlay } from '../common-ui';
import { CommunityHeader } from '../images';

type BaseLinkPreviewProps = {
  /** Title of the linkPreview that will display as the header of the card */
  title?: string;
  /** URL of the linkPreview that will display as the subheader of the card */
  url: string;
  onClose?: () => void;
  // eslint-disable-next-line react/require-default-props
  handlePress?: () => void;
  coverImage: Maybe<string>;
  coverImageStyle?: StyleProp<ImageStyle>;
  containerStyle?: StyleProp<ViewStyle>;
};

const LinkPreviewComp = ({
  onClose,
  title,
  url,
  handlePress,
  containerStyle,
  coverImage,
  coverImageStyle,
}: BaseLinkPreviewProps) => {
  const getParsedUrl = () => {
    const regex = /(?:https?:\/\/)?(?:www\.)?([^/]+)/i;
    const parsedUrl = url?.match(regex);
    return parsedUrl && parsedUrl[1];
  };

  return (
    <CloseBoxOverlay disabled={!onClose} onPress={onClose}>
      <TouchableWithoutFeedback onPress={handlePress}>
        <View
          style={[
            {
              paddingHorizontal: 13,
              paddingVertical: 8,
              flexDirection: `row`,
              justifyContent: `center`,
              alignItems: `center`,
              backgroundColor: Colors.white,
              borderRadius: 16,
            },
            containerStyle,
          ]}
        >
          {coverImage ? (
            <FastImage source={{ uri: coverImage }} resizeMode="cover" style={[{ height: 100, flex: 1 }, coverImageStyle]} />
          ) : (
            <View style={{ width: 100, height: 100 }}>
              <CommunityHeader width="100px" height="100px" />
            </View>
          )}
          <View
            style={{
              flex: 1,
              flexDirection: `column`,
              paddingVertical: 20,
              paddingLeft: 23,
              paddingRight: 20,
            }}
          >
            <Text numberOfLines={4} ellipsizeMode="tail" style={{ fontWeight: `bold` }}>
              {title}
            </Text>
            <Text numberOfLines={1} ellipsizeMode="tail" style={{ color: Colors.textGray }}>
              {getParsedUrl()}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </CloseBoxOverlay>
  );
};

export const LinkPreview = memo(LinkPreviewComp);
