import React, { useState, useCallback, useEffect, useContext } from 'react';
import { StyleSheet, TouchableOpacity, View, Keyboard, TextInput, Text, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useQuery } from '@apollo/react-hooks';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LinearGradient } from 'expo-linear-gradient';
import { Colors, Typography } from '../common-styles';
import { MeshIcon } from '../common-ui';
import { InterestList } from './InterestList';
import { ExploreFilters } from './ExploreFilters';
import { DEFAULT_FILTERS } from './exploreHelpers';
import { RootState } from '../redux/store';
import { resetJoinMeshModal } from '../redux/guestSlice';
import { AppContext } from '../../AppContext';
import { ExploreNavigatorScreens, MainTabsNavigator } from '../common-types/navigation-types';
import { Events, PendoTrackEvent } from '../pendo/events';
import { ExploreHeroArea } from './ExploreHeroArea';
import { NewOrFeaturedCommunities } from './NewOrFeaturedCommunities';
import { loadCachedGroupMember, GET_EXPLORE_EXTRAS } from '../graphql';
import { Group } from '../common-types/types';
import { ExploreIntroVideo } from './ExploreIntroVideo';
import { setActiveCommunity } from '../redux/feedSlice';

type ExploreNavigator = StackNavigationProp<ExploreNavigatorScreens, `Explore`>;
type NewAndFeaturedData = {
  getNewCommunities: Group[];
  getFeaturedCommunities: Group[];
};

const HITSLOP = { top: 10, left: 10, right: 10, bottom: 10 };
const BIG_HITSLOP = { top: 15, left: 15, right: 15, bottom: 15 };

export const ExploreScreen = () => {
  const { isGuestMode, user } = useContext(AppContext);

  const navigation = useNavigation<ExploreNavigator>();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [postsFilter, setPostsFilter] = useState(DEFAULT_FILTERS.posts);
  const [membersFilter, setMembersFilter] = useState(DEFAULT_FILTERS.members);
  const [membershipStatus, setMembershipStatus] = useState(DEFAULT_FILTERS.membership);
  const [_filtersCount, setFiltersCount] = useState(0);
  const [featuredCommunities, setFeaturedCommunities] = useState<Group[]>([]);
  const [newCommunities, setNewCommunities] = useState<Group[]>([]);
  const dispatch = useDispatch();
  const { groupToJoin } = useSelector((state: RootState) => state.guest);
  const { width: windowWidth } = useWindowDimensions();
  const {
    data: exploreExtraData,
    loading: loadingExploreExtras,
    refetch: refetchFeatured,
  } = useQuery<NewAndFeaturedData>(GET_EXPLORE_EXTRAS, {
    variables: { user_id: user?.id, limit: 6, offset: 0 },
    fetchPolicy: `cache-and-network`,
    onError: (error) => console.error(`[GET_EXPLORE_EXTRAS error]`, error.message),
  });

  useEffect(() => {
    if (exploreExtraData) {
      const { getFeaturedCommunities = [], getNewCommunities = [] } = exploreExtraData;
      const shuffledFeatured = getFeaturedCommunities?.sort((_, __) => Math.random() - Math.random());
      const shuffledNew = getNewCommunities?.sort((_, __) => Math.random() - Math.random());
      setNewCommunities(shuffledNew.slice(0, 3));
      setFeaturedCommunities(shuffledFeatured.slice(0, 3));
    }
  }, [exploreExtraData]);

  useEffect(() => {
    const loadFilterValues = async () => {
      try {
        const loadedCommunitySizeValue = (await AsyncStorage.getItem(`communitySizeFilter`)) || DEFAULT_FILTERS.members;
        const loadedActivityValue = (await AsyncStorage.getItem(`activityFilter`)) || DEFAULT_FILTERS.posts;
        const loadedMembershipStatus = (await AsyncStorage.getItem(`membershipStatus`)) || DEFAULT_FILTERS.membership;

        setMembersFilter(+loadedCommunitySizeValue);
        setPostsFilter(+loadedActivityValue);
        setMembershipStatus(loadedMembershipStatus);

        let count = 0;
        if (+loadedCommunitySizeValue !== 0) count += 1;
        if (+loadedActivityValue !== 0) count += 1;
        if (loadedMembershipStatus !== `Any`) count += 1;

        setFiltersCount(count);
      } catch (error) {
        console.warn(error);
      }
    };
    if (!isGuestMode && !!groupToJoin) {
      const gm = loadCachedGroupMember(groupToJoin?.id, user?.id);
      dispatch(resetJoinMeshModal());
      if (user?.id && gm) {
        dispatch(setActiveCommunity({ user_id: user.id, group: groupToJoin }));
        const parent = navigation.getParent<MainTabsNavigator>();
        parent.jumpTo(`HomeNavigator`, { screen: `HomeScreen` });
      } else navigation.navigate(`CommunityPreview`, { group: groupToJoin });
      return;
    }
    loadFilterValues();
  }, [dispatch, groupToJoin, isGuestMode, navigation, user?.id]);

  const setFilters = async (communitySizeFilter: number, activityFilter: number, membershipFilter: string) => {
    setPostsFilter(+activityFilter);
    setMembersFilter(+communitySizeFilter);
    setMembershipStatus(membershipFilter || `Any`);

    try {
      await AsyncStorage.setItem(`communitySizeFilter`, communitySizeFilter.toString());
      await AsyncStorage.setItem(`activityFilter`, activityFilter.toString());
      await AsyncStorage.setItem(`membershipStatus`, membershipFilter);

      let count = 0;
      if (+communitySizeFilter !== 0) count += 1;
      if (+activityFilter !== 0) count += 1;
      if (membershipFilter !== `Any`) count += 1;

      setFiltersCount(count);
    } catch (error) {
      console.warn(error);
    }
    setShowFilterModal(false);
  };

  const handleSeeAll = useCallback(
    (screenToGo: string) => {
      const title = screenToGo === `featured` ? `Featured` : `New`;
      navigation.navigate(`ExploreSeeAllGroups`, { title });
    },
    [navigation],
  );

  const categoryPadding = windowWidth > 1000 ? 36 : 16;

  return (
    <View style={localStyles.container} testID="EXPLORESCREEN">
      <LinearGradient
        colors={[`rgba(218, 251, 255, 1)`, `rgba(252, 222, 233, 1)`]}
        style={localStyles.background}
        start={[0, 0]}
        end={[1, 0]}
      />
      <InterestList refresh={refetchFeatured}>
        <SearchInput />
        <ExploreHeroArea />
        <NewOrFeaturedCommunities
          title="Featured"
          communities={featuredCommunities}
          loading={loadingExploreExtras}
          handleSeeAll={() => handleSeeAll(`featured`)}
        />
        <ExploreIntroVideo />
        <NewOrFeaturedCommunities
          title="New"
          communities={newCommunities}
          loading={loadingExploreExtras}
          handleSeeAll={() => handleSeeAll(`new`)}
        />
        <View style={{ backgroundColor: `white`, marginTop: 12, paddingBottom: categoryPadding }}>
          <Text style={localStyles.interestListTitle}>Categories</Text>
        </View>
      </InterestList>

      {showFilterModal && (
        <ExploreFilters
          isVisible={showFilterModal}
          onBackdropPress={() => setShowFilterModal(false)}
          setFilters={setFilters}
          membersFilter={+membersFilter}
          postsFilter={+postsFilter}
          membershipStatus={membershipStatus}
        />
      )}
    </View>
  );
};

const SearchInput = () => {
  const navigation = useNavigation<ExploreNavigator>();
  const { isGuestMode } = useContext(AppContext);
  const [searchIconEnabled, setSearchIconEnabled] = useState(false);
  const [searchText, setSearchText] = useState(``);

  const onChangeSearchText = (text: string) => {
    // const cleanedText = text.replace(/[^A-Za-z0-9_]/g, ``);
    setSearchText(text);
    if (text.length < 2) setSearchIconEnabled(false);
    else setSearchIconEnabled(true);
  };

  const searchWord = useCallback(() => {
    if (searchText.trim().length < 2) return;
    if (isGuestMode) PendoTrackEvent(Events.GUEST_SEARCH, { searchText });
    navigation.navigate(`ExploreSearch`, { searchText });
  }, [navigation, searchText, isGuestMode]);

  const clearSearch = () => {
    Keyboard.dismiss();
    setSearchText(``);
    setSearchIconEnabled(false);
  };

  return (
    <View
      style={[
        localStyles.searchBoxContainer,
        searchIconEnabled ? { borderColor: Colors.brandPurple } : { borderColor: Colors.gray },
      ]}
    >
      {searchText.length > 0 && (
        <TouchableOpacity hitSlop={HITSLOP} onPress={clearSearch}>
          <MeshIcon style={{ marginLeft: 10 }} name="cross" size={12} />
        </TouchableOpacity>
      )}
      <TextInput
        onSubmitEditing={searchWord}
        placeholder="Search nodes"
        placeholderTextColor={Colors.textPlaceholder}
        style={{ flex: 1, padding: 10 }}
        testID="SEARCH_BAR"
        onChangeText={onChangeSearchText}
        value={searchText}
        returnKeyType="done"
      />
      <TouchableOpacity
        hitSlop={BIG_HITSLOP}
        onPress={searchWord}
        style={{ padding: 10 }}
        testID="SUBMIT_SEARCH"
        disabled={!searchIconEnabled}
      >
        <MeshIcon
          name="magnifyingglass"
          size={16}
          color={searchIconEnabled ? Colors.brandPurple : Colors.iconColor}
          focused={searchIconEnabled}
        />
      </TouchableOpacity>
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: { flex: 1 },
  background: {
    position: `absolute`,
    left: 0,
    right: 0,
    top: 0,
    height: `100%`,
  },
  searchBoxContainer: {
    backgroundColor: Colors.white,
    marginHorizontal: 16,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    borderRadius: 4,
    borderWidth: 0.5,
  },
  interestListTitle: {
    ...Typography.text(`plustwo`, `bold`),
    marginLeft: 32,
    marginTop: 12,
  },
});
