/* eslint-disable react/function-component-definition */
import * as React from 'react';
import Svg, { Defs, Rect, G, Mask, Use, Path, Ellipse, Text, TSpan } from 'react-native-svg';

export const ExploreRelationship = (props) => {
  return (
    <Svg
      width="163px"
      height="98px"
      viewBox="0 0 163 98"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Rect id="a" x={0} y={0} width={163} height={98} rx={7} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <Use fill="#FFEEEB" xlinkHref="#a" />
        <G fillRule="nonzero" mask="url(#b)">
          <Path
            d="M66.209 18.52c4.56 4.26 6.78 10.487 7.548 17.405.726 6.88 0 14.454-4.561 19.66-4.52 5.207-12.916 8.083-20.423 7.282-7.507-.8-14.126-5.279-20.423-10.486C22.054 47.175 16.04 41.204 16 35.196c0-5.97 5.933-11.978 12.27-16.238 6.296-4.296 12.956-6.845 19.736-6.954 6.78-.11 13.683 2.22 18.203 6.517z"
            fill="#FF563D"
            transform="translate(72 26) rotate(116 45 37.5)"
          />
          <G strokeWidth={1} fill="none" transform="translate(72 26) translate(0 8)">
            <Path
              d="M64.302 35.5c0 .828-.739 1.5-1.65 1.5-.913 0-1.652-.672-1.652-1.5 0-.829.74-1.5 1.651-1.5.912 0 1.651.671 1.651 1.5M52.38 52c0 1.657-1.417 3-3.165 3-1.747 0-3.164-1.343-3.164-3s1.417-3 3.164-3c1.748 0 3.165 1.343 3.165 3"
              fill="#F899A0"
            />
            <Path
              d="M82.789 49c0 1.104-.74 2-1.651 2-.912 0-1.651-.896-1.651-2s.739-2 1.651-2 1.651.896 1.651 2"
              fill="#FFD5BD"
            />
            <Path
              d="M58.569 11c0 1.104-.723 2-1.615 2s-1.616-.896-1.616-2c0-1.105.724-2 1.616-2 .892 0 1.615.895 1.615 2M20.23 58.5c0 .828-.723 1.5-1.615 1.5S17 59.328 17 58.5c0-.829.723-1.5 1.615-1.5s1.616.671 1.616 1.5"
              fill="#F899A0"
            />
            <Path
              d="M9.347 51c0 2.761-2.092 5-4.673 5S0 53.761 0 51s2.093-5 4.674-5c2.581 0 4.673 2.239 4.673 5M58.347 25.5c0 2.485-2.092 4.5-4.673 4.5S49 27.985 49 25.5s2.093-4.5 4.674-4.5c2.581 0 4.673 2.015 4.673 4.5"
              fill="#FFD5BD"
            />
            <Path
              d="M9.647 30.5c0 1.38-1.04 2.5-2.324 2.5C6.04 33 5 31.88 5 30.5S6.04 28 7.323 28s2.324 1.12 2.324 2.5"
              fill="#F899A0"
            />
            <Ellipse fill="#FFD5BD" cx={79.0050708} cy={28} rx={2.91381666} ry={3} />
            <Ellipse fill="#FFD5BD" cx={45.0935077} cy={4.5} rx={4.09350772} ry={4.5} />
            <Ellipse fill="#FFD5BD" cx={25.2062062} cy={50} rx={2.64874029} ry={3} />
            <Path
              d="M70.032 10.366c.392 3.768 5.731 6.238 6.58 6.609a.41.41 0 00.053.025c.013-.01.034-.02.053-.039.751-.571 5.445-4.31 5.055-8.071l-.007-.062c-.282-2.552-3.077-3.696-4.896-2.08-.471.419-.845.711-1.017.842l.007.063s-.022-.005-.06-.025c-.034.029-.047.038-.047.038l-.007-.063c-.03-.012-.068-.03-.113-.05a19.14 19.14 0 01-1.24-.623c-2.106-1.158-4.588.64-4.38 3.218.005.072.012.145.02.218M23.179 29.413c-1.308 4.84 4.952 10.635 5.957 11.529A.632.632 0 0029.2 41c.023-.005.057-.007.094-.02 1.332-.316 9.748-2.5 11.054-7.33l.02-.079c.863-3.288-2.487-6.137-5.83-5.086-.867.273-1.535.44-1.84.513l-.02.08s-.03-.018-.074-.06c-.06.017-.083.022-.083.022l.021-.081c-.036-.03-.08-.074-.135-.12-.295-.271-.815-.76-1.429-1.408-2.378-2.51-6.74-1.578-7.72 1.703-.027.093-.053.185-.078.279M22.016 16.984c.193 1.713 2.83 2.836 3.248 3.005a.211.211 0 00.026.011c.007-.004.017-.01.027-.018.37-.26 2.688-1.96 2.495-3.668l-.003-.028c-.14-1.16-1.52-1.68-2.417-.946-.233.19-.418.323-.502.383l.003.028s-.01-.002-.03-.01l-.023.016-.003-.028-.056-.023a9.797 9.797 0 01-.613-.283c-1.039-.526-2.265.29-2.162 1.462l.01.1"
              fill="#F899A0"
            />
          </G>
        </G>
        <Text fontSize={12} fontWeight="normal" letterSpacing={-0.14} fill="#FF563D">
          <TSpan x={11.5446415} y={20}>
            Relationships
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
};
