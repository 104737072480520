/* eslint-disable no-param-reassign */

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

type GuidelinesState = {
  guidelines: Guideline[];
  changes: boolean;
};

type Guideline = {
  id: string;
  title: string;
  body: string;
};

const initialState = {
  guidelines: [],
  changes: false,
};

const GuidelinesSlice = createSlice<GuidelinesState, SliceCaseReducers<GuidelinesState>, string>({
  name: `guidelines`,
  initialState,
  reducers: {
    setGuidelines: (state, action) => {
      state.guidelines = action.payload as Guideline[];
    },
    addGuideline: (state, action) => {
      const guideline = action.payload as Guideline;
      state.guidelines = [...state.guidelines, guideline];
      if (!state.changes) state.changes = true;
    },
    editGuideline: (state, action) => {
      if (!state.changes) state.changes = true;
      state.guidelines = state.guidelines.map((g) => {
        const guideline = action.payload as Guideline;
        if (g.id === guideline.id) return guideline;
        return g;
      });
    },
    removeGuideline: (state, action) => {
      if (!state.changes) state.changes = true;
      const guideline = action.payload as Guideline;
      state.guidelines = state.guidelines.filter((g) => {
        return g.id !== guideline.id;
      });
    },
  },
});

export const { addGuideline, editGuideline, removeGuideline, setGuidelines } = GuidelinesSlice.actions;

const guidelinesReducer = GuidelinesSlice.reducer;

export { guidelinesReducer };
