import PropTypes from 'prop-types';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';

const hitSlop = { top: 10, bottom: 10, left: 10, right: 10 };

const _ThemedDeleteButton = ({
  onPress,
  disabled,
  disabledStyle,
  containerStyle,
  buttonStyle,
  clear,
  rounded,
  leftIcon,
  title,
  titleStyle,
  outline,
  rightIcon,
  testID,
}) => {
  const baseStyle = {
    flexDirection: `row`,
    backgroundColor: !clear ? Colors.translucentBrightRed : `transparent`,
    borderWidth: !outline ? undefined : 1,
    borderColor: !outline ? undefined : Colors.brightRed,
    borderRadius: rounded ? 99 : 4,
    padding: 8,
    justifyContent: `center`,
    alignItems: `center`,
    ...buttonStyle,
  };
  const disabledMixin = {
    flexDirection: `row`,
    backgroundColor: !clear ? Colors.taupe : `transparent`,
    borderWidth: !outline ? undefined : 1,
    borderColor: !outline ? undefined : Colors.gray,
    ...disabledStyle,
  };
  const style = disabled ? { ...baseStyle, ...disabledMixin } : baseStyle;

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      disabled={disabled}
      activeOpacity={0.8}
      style={{ ...containerStyle }}
      hitSlop={Platform.select({ web: undefined, default: hitSlop })}
    >
      <View style={style}>
        {leftIcon && { ...leftIcon }}
        <Text
          maxFontSizeMultiplier={2}
          style={{
            ...Typography.text(`base`, `bold`),
            textAlign: `center`,
            ...titleStyle,
            color: Colors.textRed,
          }}
        >
          {title}
        </Text>
        {rightIcon && { ...rightIcon }}
      </View>
    </TouchableOpacity>
  );
};
export const ThemedDeleteButton = React.memo(_ThemedDeleteButton);
_ThemedDeleteButton.propTypes = {
  title: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  clear: PropTypes.bool,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  disabledStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  rounded: PropTypes.bool,
  buttonStyle: PropTypes.object,
  outline: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  testID: PropTypes.string,
};

_ThemedDeleteButton.defaultProps = {
  title: undefined,
  clear: false,
  containerStyle: {},
  disabledStyle: {},
  disabled: false,
  titleStyle: undefined,
  rounded: false,
  buttonStyle: {},
  outline: false,
  leftIcon: undefined,
  rightIcon: undefined,
  testID: undefined,
};
