import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Linking, Text, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ParsedText from 'react-native-parsed-text';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, Typography } from '../common-styles';
import { ThemedButton, LoadingIndicator, BottomSheetModal, ListItem } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { APIHelper } from '../contribution/helpers';
import { client, SETTING_QUERY, UPDATE_SETTING_MUTATION, refetchQueriesFor } from '../graphql';
import { BASE_PAYMENT_API_URL } from '../../constants';
import {
  setLast4DigitsFlag,
  setContributionTileFlag,
  setLast4Digits,
  setUserEmailVerified,
  setCachedGroupId,
} from '../redux/contributionSlice';
import { AppContext } from '../../AppContext';

export const BankAccountSettingItem = ({ setting, listItemProps, group }) => {
  const navigation = useNavigation();
  const { titleStyle, subtitleStyle, rightElement } = listItemProps;
  const { last4Digits } = useSelector((state) => state.contributions);
  const dispatch = useDispatch();
  //Effect to fetch last4 digits to show them in the Bank Account item subtitle
  useEffect(() => {
    fetch(`${BASE_PAYMENT_API_URL}accounts/retrieve/${setting?.value}`, {
      method: `GET`,
      headers: {
        'Content-Type': `application/json`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setLast4Digits(data?.account?.external_accounts?.data[0]?.last4));
        // setBankAccountData(data?.account?.external_accounts?.data[0]);
        return null;
      })
      .catch((error) => console.error(error));
  }, [setting.value, dispatch, last4Digits]);
  const navigateToEditor = () => navigation.push(`BankAccount`, { setting, group });
  return (
    <ListItem
      title="Incoming payments"
      titleStyle={titleStyle}
      subtitle={last4Digits ? `•••• ${last4Digits}` : ``}
      subtitleStyle={subtitleStyle}
      rightElement={rightElement}
      onPress={navigateToEditor}
    />
  );
};
BankAccountSettingItem.propTypes = {
  setting: commonPropTypes.setting.isRequired,
  listItemProps: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
};

export const BankAccount = ({ navigation, route }) => {
  const { last4Digits, userEmailVerified, cachedGroupId } = useSelector((state) => state.contributions);
  const dispatch = useDispatch();

  const [userEmail, setUserEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updateConnectedAccount] = useMutation(UPDATE_SETTING_MUTATION);
  const [idmCreateAccount, setIdmCreateAccount] = useState(``);
  const [cardDetails, setCardDetails] = useState(null);
  const [detailsSubmitted, setDetailsSubmitted] = useState(null);
  const [chargesEnabled, setChargesEnabled] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bankOrDebitAccount, setBankOrDebitAccount] = useState(``);

  const { group } = route?.params || {};
  const { user } = React.useContext(AppContext);

  //Effect to refetch queries to see the updated bank account data in the Community Settings screen
  useEffect(() => {
    refetchQueriesFor(`Group`, `GroupMember`, `Setting`);
  }, [navigation]);

  useEffect(() => {
    setIdmCreateAccount(uuidv4());
  }, []);

  useEffect(() => {
    if (group?.id) dispatch(setCachedGroupId(group?.id));
    const fetchCommunityBankAccountDetails = async (value) => {
      if (!value) return;
      try {
        setLoadingComponent(true);
        const response = await fetch(`${BASE_PAYMENT_API_URL}accounts/retrieve/${value}`, {
          method: `GET`,
          headers: {
            'Content-Type': `application/json`,
          },
        });

        const result = await response?.json();
        const last4Result = result?.account?.external_accounts?.data[0]?.last4;
        dispatch(setLast4Digits(last4Result));
        setChargesEnabled(result?.account?.charges_enabled);
        setDetailsSubmitted(result?.account?.details_submitted);
        setBankOrDebitAccount(result?.account?.external_accounts?.data[0]?.object);
        if (last4Result) {
          dispatch(setContributionTileFlag(true));
          dispatch(setLast4DigitsFlag(true));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingComponent(false);
      }
    };
    const fetchGroup = async () => {
      try {
        const { data: userData } = await client.query({
          query: SETTING_QUERY,
          variables: {
            settingQuery: {
              query: `user`,
              user_id: user?.id,
            },
          },
        });

        const { data: groupData } = await client.query({
          query: SETTING_QUERY,
          variables: {
            settingQuery: {
              query: `group`,
              group_id: group?.id ? group?.id : cachedGroupId,
            },
          },
          refetchQueries: refetchQueriesFor(`Group`, `GroupMember`, `Setting`),
        });

        const groupSettings = groupData?.getSettings || [];
        const userSettings = userData?.getSettings || [];

        const [userEmail] = userSettings.filter((setting) => setting.key === `user.setting.email`);
        const [userEmailVerified] = userSettings.filter((setting) => setting.key === `user.setting.email_verified`);
        const [bankAccount] = groupSettings.filter((setting) => setting.key === `group.setting.bank_account`);

        if (userEmailVerified?.value === `true`) {
          dispatch(setUserEmailVerified(true));
        } else {
          dispatch(setUserEmailVerified(false));
        }

        setUserEmail(userEmail?.value);
        // setUseremailVerified(userEmailVerified?.value);
        setCardDetails(bankAccount);
        fetchCommunityBankAccountDetails(bankAccount?.value);
      } catch (error) {
        console.error(error);
      }
    };
    fetchGroup();
  }, [user?.id, navigation, dispatch, group, cachedGroupId]);

  const saveBankAccount = async (accountId = ``) => {
    const input = {
      id: ``,
      key: `group.setting.bank_account`,
      value: accountId, //means that the user email is going to be verified
      group_id: group?.id,
    };

    await updateConnectedAccount({
      variables: { input },
      refetchQueries: refetchQueriesFor(`Group`, `GroupMember`, `Setting`),
    });
  };

  const fetchCreateConnectedAccountApi = async () => {
    setLoading(true);
    const input = {
      email: userEmail,
      user_id: user?.id,
      group_id: group?.id,
    };

    const result = await APIHelper(`accounts/create-express`, `POST`, idmCreateAccount, input);

    if (!userEmailVerified) {
      setErrorMessage(`Make sure your community has an associated email and it is verified.`);
    } else if (result.status === `error`) {
      setErrorMessage(result?.error?.raw?.message);
    } else {
      setErrorMessage(null);
      saveBankAccount(result.accountId);
    }

    if (result.status === `error`) {
      setErrorMessage(result?.error?.raw?.message);
    } else {
      setErrorMessage(null);
      saveBankAccount(result.accountId);
    }
    setLoading(false);
    return result;
  };

  const redirectToStripeHostedPage = async () => {
    try {
      const connectedAccount = await fetchCreateConnectedAccountApi();
      setShowModal(false);
      await Linking.openURL(connectedAccount?.accountLink?.url);
    } catch (error) {
      console.error(error);
    }
  };

  const redirectToStripeDashboard = async () => {
    try {
      const response = await fetch(`${BASE_PAYMENT_API_URL}accounts/login/${cardDetails?.value}`, {
        method: `GET`,
        headers: {
          'Content-Type': `application/json`,
        },
      });
      const result = await response.json();
      // console.log(`result -> stripe login endpoint -> ${JSON.stringify(result, null, 2)}`);
      await Linking.openURL(result?.link?.url);
    } catch (error) {
      console.error(error);
    }
  };
  const customMatcher = {
    pattern: /Verify now/,
    onPress: () => navigation.navigate(`ProfileSettings`),
    style: Typography.text(`base`, `theme`, `bold`),
  };
  return (
    <View style={{ flex: 1, backgroundColor: Colors.white, paddingHorizontal: 16 }}>
      {loadingComponent ? (
        <LoadingIndicator style={{ marginTop: 40 }} />
      ) : (
        <View>
          <Text style={{ marginTop: 21, ...Typography.text(`bold`) }}>Set up Payment Account</Text>
          {last4Digits ? (
            <View style={{ marginTop: 16 }}>
              <Text style={{ ...Typography.text(`bold`, `paymentTransparentGray`, `small`) }}>
                {bankOrDebitAccount === `bank_account` ? `Bank` : `Debit`} account
              </Text>
              <TouchableOpacity
                style={{
                  marginTop: 12,
                  borderWidth: 1,
                  borderRadius: 4,
                  borderColor: Colors.brandPurple,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 12,
                  paddingRight: 12,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                }}
                onPress={redirectToStripeDashboard}
              >
                <View style={{ flexDirection: `row`, alignItems: `center` }}>
                  {/* <PaymentMethodImage cardName={cardDetails?.card?.brand} /> */}

                  <Text style={{ marginLeft: 12 }}>••••</Text>
                  <Text style={{ marginLeft: 6 }}>{last4Digits}</Text>
                </View>
                <Text style={{ color: Colors.deepPurple, fontWeight: `bold` }}>
                  {detailsSubmitted && chargesEnabled ? `View` : `Edit`}
                </Text>
              </TouchableOpacity>
              <Text style={{ ...Typography.text(), marginTop: 24 }}>
                {detailsSubmitted && chargesEnabled
                  ? `View your payment history, edit the payment account listed above, and access your Stripe account settings.`
                  : `Edit your payment method to submit the details, or check if charges are enabled.`}
              </Text>
            </View>
          ) : (
            <View style={{ flexDirection: `row`, justifyContent: `space-between`, alignItems: `center` }}>
              <Text style={{ ...Typography.text(), marginTop: 17 }}>
                Mesh Communities uses Stripe to get you paid quickly and keep your personal and payment information secure. Set up
                a Stripe account to get paid with Mesh Communities.
              </Text>
            </View>
          )}

          {!userEmailVerified ? (
            <ParsedText
              style={{ marginTop: 20, ...Typography.text(`bold`) }}
              parse={[customMatcher]}
              // eslint-disable-next-line react-native/no-raw-text
            >
              Verified personal email is required. Verify now
            </ParsedText>
          ) : null}

          {errorMessage && errorMessage.trim().length > 0 && (
            <Text style={{ marginTop: 20, ...Typography.text(`alert`, `bold`) }}>{errorMessage}</Text>
          )}

          <ThemedButton
            rounded
            title="Set up New Payment Account"
            onPress={() => setShowModal(true)}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginTop: 40 }}
            titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`) }}
            disabled={!userEmailVerified}
          />

          <BottomSheetModal
            title="Leaving Mesh"
            visible={!!showModal}
            onPressCancel={() => setShowModal(false)}
            confirmTitle="OK"
            isConfirmDisabled={loading}
            onPressConfirm={() => redirectToStripeHostedPage()}
          >
            <View style={{ marginHorizontal: 16 }}>
              <Text style={{ marginTop: 16, marginBottom: 32 }}>
                You are leaving Mesh and will be going to Stripe to register a payment method.
                {last4Digits
                  ? ` Current existing payment account will be removed.`
                  : ` You will have the option to return to Mesh when you are done.`}
              </Text>
            </View>
          </BottomSheetModal>
        </View>
      )}
    </View>
  );
};

BankAccount.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};
