import React, { useContext, useState } from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { Avatar, BottomSheetModal, MeshIcon } from '../common-ui';

type CreatePostHeaderType = {
  group: any;
  groupMember: any;
  postingAs: string;
  onChangePostingAs: (poster: string) => void;
};

export const PostingOptions = {
  Community: `community`,
  User: `user`,
};

const CreatePostHeader: React.FC<CreatePostHeaderType> = ({ group, groupMember, postingAs, onChangePostingAs }) => {
  const { user } = useContext(AppContext);
  const [showPosterOption, setShowPosterOption] = useState(false);

  return (
    <>
      <View style={styles.createHeader}>
        {postingAs === PostingOptions.User ? (
          <View style={styles.posingUser}>
            <Avatar user={user} size={24} style={{ marginRight: 8 }} />
            <Text numberOfLines={1} ellipsizeMode="tail" style={Typography.text(`flex1`)}>
              <Text style={Typography.add(`bold`)}>{user?.handle}</Text>
              <Text>
                {` `}posting in{` `}
              </Text>
              <Text style={Typography.add(`bold`)}>{group?.name}</Text>
            </Text>
          </View>
        ) : (
          <View style={styles.postingCommunity}>
            <View style={styles.postingCommunityHeader}>
              <Avatar user={group} size={24} style={{ marginRight: 8 }} isGroup={true} />
              <View style={{ paddingVertical: 3, paddingRight: 10 }}>
                <Text numberOfLines={1} ellipsizeMode="tail" style={Typography.text(`flex1`)}>
                  {group?.name}
                </Text>
              </View>
            </View>
          </View>
        )}

        {(groupMember?.role_name === `owner` || groupMember?.role_name === `manager`) && (
          <View style={{ flexDirection: `row`, justifyContent: `flex-end` }}>
            <TouchableOpacity onPress={() => setShowPosterOption(true)}>
              <View style={styles.optionSelect}>
                <MeshIcon name="chevron-down" color={Colors.brandPurple} size={15} />
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>

      <BottomSheetModal
        visible={showPosterOption}
        title="Post as..."
        confirmTitle="Done"
        onPressConfirm={() => setShowPosterOption(false)}
        onPressCancel={() => setShowPosterOption(false)}
      >
        <View style={styles.optionRow}>
          <Image
            style={styles.optionAvatar}
            source={group?.avatar_url ? { uri: group?.avatar_url } : require(`../../assets/images/icon.png`)}
          />

          <Text style={styles.optionName}>{group?.name}</Text>
          <MeshIcon
            onPress={() => onChangePostingAs(PostingOptions.Community)}
            focused={postingAs === PostingOptions.Community}
            name="select-circle"
            color={Colors.brandPurple}
          />
        </View>

        <View style={styles.optionRow}>
          <Avatar user={user} size={30} style={{ marginRight: 8 }} />
          <Text style={styles.optionName}>{user?.name || user?.handle}</Text>
          <MeshIcon
            onPress={() => onChangePostingAs(PostingOptions.User)}
            focused={postingAs === PostingOptions.User}
            name="select-circle"
            color={Colors.brandPurple}
          />
        </View>
      </BottomSheetModal>
    </>
  );
};

const styles = StyleSheet.create({
  optionRow: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    paddingHorizontal: 20,
    marginTop: 10,
  },
  optionAvatar: {
    width: 30,
    height: 30,
    borderRadius: 30,
    marginRight: 10,
  },
  optionName: {
    flex: 1,
    paddingHorizontal: 10,
  },
  optionSelect: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    marginHorizontal: 12,
  },
  createHeader: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `space-between`,
    borderBottomColor: Colors.lightGray,
    borderBottomWidth: 1,
  },
  posingUser: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    flexDirection: `row`,
    alignItems: `center`,
    flex: 1,
  },
  postingCommunity: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    flex: 1,
  },
  postingCommunityHeader: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    flexDirection: `row`,
    alignItems: `center`,
    backgroundColor: Colors.purpleGrey,
  },
});

export default CreatePostHeader;
