import React, { useCallback, useState } from 'react';
import { FlatList, ListRenderItem, TouchableOpacity, View } from 'react-native';
import { BottomSheetModal } from './BottomSheetModal';
import { Divider } from './Divider';

interface SelectProps<TItem extends { id: string }> {
  title?: string;
  options: TItem[];
  visible: boolean;
  renderOption: ListRenderItem<TItem>;
  ListHeaderComponent?: React.ReactElement;
  onCancel: () => void;
  onSave: (values: TItem[]) => void;
  multiple?: boolean;
  maxSelected?: number;
  defaultSelected?: TItem;
  onEndReached?: () => void;
}

export const Select = <T extends { id: string }>({
  title,
  visible,
  options,
  renderOption,
  ListHeaderComponent,
  onCancel,
  onSave,
  multiple,
  defaultSelected,
  maxSelected = 3,
  onEndReached,
}: SelectProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<T[]>(defaultSelected ? [defaultSelected] : []);

  const onPressItem = (option: T, isSelected: boolean) => {
    if (isSelected) {
      setSelectedItems((prev) => (prev as T[])?.filter((it) => it.id !== option.id));
    } else if (multiple && selectedItems.length < maxSelected) {
      setSelectedItems((prev) => [...prev, option]);
    } else {
      setSelectedItems([option]);
    }
    if (!multiple) {
      onSave([option]);
      onCancel();
    }
  };

  const handleSave = useCallback(() => {
    onSave(selectedItems);
    onCancel();
  }, [selectedItems, onSave, onCancel]);

  const renderItem: ListRenderItem<T> = ({ item, index, separators }) => {
    const isSelected = selectedItems?.map((i) => i.id)?.includes(item.id) || false;
    return (
      <TouchableOpacity
        key={item.id}
        style={{ flexDirection: `row`, alignItems: `center` }}
        onPress={() => onPressItem(item, isSelected)}
        testID="MODAL_OPTION"
      >
        {renderOption({ item, index, separators })}
      </TouchableOpacity>
    );
  };

  return (
    <BottomSheetModal
      visible={visible}
      title={title || ``}
      onPressCancel={onCancel}
      confirmTitle="Save"
      showConfirmBtn={!!multiple}
      onPressConfirm={handleSave}
    >
      <View testID="MODAL_SCROLL" style={{ margin: 0 }}>
        <FlatList
          data={options}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          ListHeaderComponent={ListHeaderComponent}
          ItemSeparatorComponent={() => <Divider />}
          ListFooterComponent={<View style={{ height: 90 }} />}
          onEndReached={onEndReached}
        />
      </View>
    </BottomSheetModal>
  );
};
