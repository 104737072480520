import { useNavigation } from '@react-navigation/native';
import React, { useContext, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { AppContext } from '../../../AppContext';
import { Colors } from '../../common-styles';
import { Activity } from '../../common-types/types';

type PostReactedUsersType = {
  activity: Activity;
};

export const PostReactedUsers: React.FC<PostReactedUsersType> = ({ activity }) => {
  const navigation = useNavigation<any>();
  const { latest_reactions, id, custom_reaction_counts } = activity || {};
  const { user: currentUser } = useContext(AppContext);

  const reaction_counts = useMemo(() => {
    if (!custom_reaction_counts?.length) return 0;
    return custom_reaction_counts.reduce((totalCount, { count }) => {
      return totalCount + count;
    }, 0);
  }, [custom_reaction_counts]);
  const likes = useMemo(() => {
    if (!latest_reactions?.like?.length) return [];
    return (
      latest_reactions?.like
        // remove deleted uses
        .filter((reaction) => reaction.user.handle !== `[deleted user]`)
        // ordering user first, then friends, then others
        .sort((a, b) => {
          if (a.user?.id === currentUser?.id) return -1;
          if (b.user?.id === currentUser?.id) return 1;
          return 0;
        })
        .map((item) => (item?.user?.id === currentUser?.id ? { ...item, user: { ...item?.user, handle: `You` } } : item))
    );
  }, [currentUser?.id, latest_reactions?.like]);

  if (likes.length === 0) return null;
  const [first, second] = likes;
  const othersText = reaction_counts - 2 > 1 ? `others ` : second ? `other ` : ``;

  return (
    <TouchableOpacity
      style={styles.usersWrapper}
      onPress={() => navigation.navigate(`PostReactionUserList`, { id, kind: `post` })}
    >
      <View style={styles.users}>
        <Text style={styles.user}>{first?.user?.handle}</Text>
        {second ? (
          <>
            <Text style={[styles.normalTxt, { marginRight: 4, marginLeft: reaction_counts - 2 ? 0 : 4 }]}>
              {reaction_counts - 2 ? `,` : `and`}
            </Text>
            <Text style={styles.user}>{second.user?.handle}</Text>
          </>
        ) : null}

        {reaction_counts - 2 > 0 ? (
          <>
            <Text style={[styles.normalTxt, { marginHorizontal: 4 }]}>and</Text>
            <Text style={styles.user}>{reaction_counts - 2}</Text>
          </>
        ) : null}

        <Text style={styles.normalTxt}>{reaction_counts - 2 ? othersText : ``}reacted</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  usersWrapper: {
    paddingHorizontal: 12,
    paddingTop: 4,
    paddingBottom: 8,
  },
  users: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
  },
  user: {
    fontSize: 12,
    color: Colors.brandPurple,
    fontWeight: `600`,
  },
  normalTxt: {
    fontSize: 12,
    color: Colors.darkGray,
    marginLeft: 4,
  },
});
