import { Image, SectionList, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import ParsedText from 'react-native-parsed-text';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Colors, Typography } from '../common-styles';
import { Avatar, Divider, EmptyList, MeshIcon, ListItem, ErrorBoundary } from '../common-ui';
import { commonPropTypes, formatRelativeDate } from '../common-util';
import { client, GET_GROUP, GET_USER_MODERATION_HISTORY, loadCachedGroup, loadCachedGroupMember } from '../graphql';
import { MODERATION_CATEGORY, MODERATION_TYPE, MODERATION_ACTIONS, ACTION_VERB_MAP } from './moderationEnums';
import { ModerationIssue, getIssueTitle, getClosedIssueTitle } from './ModerationIssue';
import { AppContext } from '../../AppContext';
import { setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';

// will receive moderationIssue and status via navigation params

export const ReportDetail = React.memo(({ navigation, route }) => {
  const { user } = useContext(AppContext);
  const dispatch = useDispatch();
  const { moderationIssue } = route.params;
  const { reportee, reporter, actions, type, status = `open`, mod_explanation } = moderationIssue || {};
  const [collapsedStatus, setCollapsedStatus] = useState({
    reporter: true,
    reportee: true,
  });
  const [reporterHistory, setReporterHistory] = useState([]);
  const [reporteeHistory, setReporteeHistory] = useState([]);

  useEffect(() => {
    _getReporterModerationHistory();
    _getReporteeHistory();
  }, []);

  const goToCommunity = async (group_id) => {
    const cachedGroup = loadCachedGroup(group_id);
    const cachedGroupMember = loadCachedGroupMember(group_id, user?.id);
    if (!user?.id) return;
    if (cachedGroupMember) {
      dispatch(setActiveCommunity({ user_id: user.id, group: cachedGroup }));
      navigation.navigate(`HomeNavigator`, { screen: `HomeScreen` });
      return;
    }
    const { data, errors } = await client.query({
      query: GET_GROUP,
      variables: { group_id: group_id || `` },
      fetchPolicy: `network-only`,
    });

    if (errors) {
      console.log(`refreshGroup - unexpectedly no result from getGroup`);
      return;
    }

    const { group_member, group } = data?.getGroup;
    if (group_member) {
      dispatch(setActiveCommunity({ user_id: user.id, group }));
      navigation.navigate(`HomeNavigator`, { screen: `HomeScreen` });
    } else {
      dispatch(setPreviewCommunity(group));
      navigation.navigate(`ExploreNavigator`, { screen: `CommunityPreview` });
    }
  };

  const _getReporterModerationHistory = async () => {
    try {
      const { moderationIssue } = route.params;
      const { data } = await client.query({
        query: GET_USER_MODERATION_HISTORY,
        variables: { persona_id: moderationIssue?.reporter_id, group_id: moderationIssue?.group_id, as_reporter: true },
      });
      const sortedData =
        (data?.getUserModerationHistory && data?.getUserModerationHistory.sort((d1, d2) => d2.created_at - d1.created_at)) || [];
      setReporterHistory(sortedData);
      return reporterHistory;
    } catch (err) {
      console.error(`Error thrown in getReporterModerationHistory`, err.message);
      return null;
    }
  };

  const _getReporteeHistory = async () => {
    try {
      const { moderationIssue } = route.params;
      const { data } = await client.query({
        query: GET_USER_MODERATION_HISTORY,
        variables: { persona_id: moderationIssue?.original_author_id, group_id: moderationIssue?.group_id, as_reporter: false },
      });
      const sortedData =
        (data?.getUserModerationHistory && data?.getUserModerationHistory.sort((d1, d2) => d2.created_at - d1.created_at)) || [];
      setReporteeHistory(sortedData);
      return reporteeHistory;
    } catch (err) {
      console.error(`Error thrown in getReporteeModerationHistory`, err.message);
      return null;
    }
  };

  const formatReportType = (type) => {
    if (type === `profile_feed`) return `personal post`;
    return type;
  };

  const renderReportHeader = () => {
    const ReportComp = getReportComponent(type);
    const parsedStatus = status === `UNDEF` ? `Open` : `Closed`;
    return (
      <View>
        <View style={localStyles.reportHeaderContainer}>
          <Text style={localStyles.boldBlackBaseText}>Reported {formatReportType(moderationIssue.type)}</Text>
          <Text style={{ ...localStyles.statusTagText, color: status === `closed` ? Colors.textGray : `#2C9A44` }}>
            {parsedStatus}
          </Text>
        </View>
        <Divider />
        <ErrorBoundary header="Sorry, an error occurred while displaying this report content">
          <ReportComp navigation={navigation} modIssue={moderationIssue} goToCommunity={goToCommunity} />
        </ErrorBoundary>
      </View>
    );
  };

  const renderSectionHeader = useCallback(
    ({ section: { id, title, actor, count } }) => {
      const collapsed = collapsedStatus[id];
      if (id === `moderation`)
        return (
          <View style={{ paddingHorizontal: 16, paddingVertical: 8, backgroundColor: Colors.backgroundColor }}>
            <Text style={Typography.text(`base`, `bold`, `gray`)}>Moderation history</Text>
          </View>
        );
      return (
        <View>
          <Divider />
          <ListItem
            title={title}
            titleStyle={localStyles.reporterTitle}
            subtitle={actor && actor.handle.trim()}
            subtitleStyle={localStyles.subtitle}
            rightIcon={<MeshIcon name={collapsed ? `chevron-down` : `chevron-up`} color={Colors.iconColor} />}
            onPress={() => {
              if (id === `reporter`) setCollapsedStatus((prev) => ({ ...prev, reporter: !collapsed }));
              if (id === `reportee`) setCollapsedStatus((prev) => ({ ...prev, reportee: !collapsed }));
            }}
          />
          {count === 0 && (
            <Collapsible collapsed={collapsed}>
              <EmptyList message="There are no other reports by this user" />
            </Collapsible>
          )}
        </View>
      );
    },
    [collapsedStatus],
  );

  const renderModerationHistory = useCallback(
    (moderationAction) => {
      const { moderationIssue } = route.params;
      const { reportee } = moderationIssue || {};
      const { type, action, handle, created_at } = moderationAction;

      let effect = ACTION_VERB_MAP[action];
      if (effect === `denied`) effect = `${effect} report on`;

      const title = (
        <Text style={Typography.text(`base`, `black`)}>
          <Text style={Typography.text(`bold`)}>{handle || `[deleted user]`}</Text>
          <Text>
            {` `}
            {effect}
            {` `}
          </Text>
          {type === MODERATION_TYPE.user ? (
            <Text style={Typography.text(`bold`)}>{reportee?.handle?.trim() || `[deleted user]`}</Text>
          ) : (
            <Text>{`this ${type}`}</Text>
          )}
        </Text>
      );
      return (
        <ListItem
          title={title}
          subtitle={<Text style={Typography.text(`small`, `gray`)}>{formatRelativeDate(created_at)}</Text>}
          leftAvatar={<Avatar navigation={navigation} user={{ id: `moderator_persona_id`, handle }} size={40} />}
        />
      );
    },
    [navigation, route.params],
  );

  const renderHistoryItem = useCallback(
    ({ item, _index, section: { id } }) => {
      const collapsed = collapsedStatus[id];
      if (!item || collapsed) return null;
      // item is moderationIssue in this case
      const { activity, type, reporter, reportee, group } = item;

      if (id === `moderation`) return renderModerationHistory(item);
      if (id === `reporter` || id === `reportee`) {
        const actions = [null, null];
        return (
          <ModerationIssue
            navigation={navigation}
            group={group}
            modIssue={item}
            actions={actions}
            queryInFlight={false}
            consistence={true}
          />
        );
      }
      if (activity) return <ReportDetail.ListItem navigation={navigation} modIssue={item} />;
      if (type === MODERATION_TYPE.user)
        return (
          <View style={localStyles.reportItemContainer}>
            <Avatar navigation={navigation} user={reporter} size={40} />
            <View style={localStyles.reportTextContainer}>
              <Text style={localStyles.reportTextHeaderText}>
                <Text style={localStyles.boldBlackBaseText}>{reporter?.handle?.trim() || `[deleted user]`} </Text>
                <Text>reported </Text>
                <Text style={localStyles.boldBlackBaseText}>{reportee?.handle?.trim() || `[deleted user]`}</Text>
              </Text>
              <Text style={localStyles.smallGrayText}>{formatRelativeDate(moderationIssue.created_at)}</Text>
            </View>
          </View>
        );
      return null;
    },
    [collapsedStatus, navigation, renderModerationHistory, moderationIssue],
  );

  useEffect(() => {
    const { moderationIssue, status = `open` } = route.params;
    const { type, action_taken } = moderationIssue || {};
    const applicationReview = [MODERATION_TYPE.application, MODERATION_TYPE.verification];
    const postReview = [MODERATION_ACTIONS.post_approve, MODERATION_ACTIONS.post_reject];

    const disabled = !(applicationReview.indexOf(type) === -1 && postReview.indexOf(action_taken) === -1);
    const goToTakeAction = () =>
      navigation.push(`ModerationTakeAction`, { moderationIssue, status, prev_screen: `ReportDetail` });

    navigation.setOptions({
      headerTitle: `Report`,
      headerRight: () => (
        <TouchableOpacity onPress={goToTakeAction} disabled={disabled}>
          <View style={{ right: 16 }}>
            <Text
              style={{ ...Typography.text(`base`, `bold`), color: status === `open` ? Colors.brandPurple : Colors.iconColor }}
            >
              {status === `open` ? `Take Action` : `Edit report`}
            </Text>
          </View>
        </TouchableOpacity>
      ),
    });
  }, [navigation, route.params]);

  const sections = [];
  if (reporter)
    sections.push({
      id: `reporter`,
      title: `Reporter history`,
      actor: reporter,
      data: (!collapsedStatus.reporter && reporterHistory) || [],
      count: reporterHistory.length,
    });
  if (reportee)
    sections.push({
      id: `reportee`,
      title: `Reportee history`,
      actor: reportee,
      data: (!collapsedStatus.reportee && reporteeHistory) || [],
      count: reporteeHistory.length,
    });
  sections.push({
    id: `moderation`,
    title: `Moderation history`,
    actor: reporter,
    data: actions || [],
    count: actions ? actions.length : 0,
  });

  return (
    <SectionList
      style={{ backgroundColor: Colors.white }}
      ListHeaderComponent={renderReportHeader}
      sections={sections}
      renderSectionHeader={renderSectionHeader}
      keyExtractor={(item, index) => item && item.id + index.toString()}
      renderItem={renderHistoryItem}
      SectionSeparatorComponent={() => <Divider />}
      ListFooterComponent={() => {
        if (!mod_explanation) return null;
        return (
          <View>
            <View style={{ paddingHorizontal: 16, paddingVertical: 8, backgroundColor: Colors.backgroundColor }}>
              <Text style={{ ...Typography.text(`base`, `bold`, `gray`) }}>Internal note for moderators</Text>
            </View>
            <View style={{ padding: 16 }}>
              <Text style={{ ...Typography.text(`small`, `gray`) }}>Note</Text>
              <View style={{ padding: 10, borderRadius: 6, backgroundColor: Colors.backgroundColor }}>
                <Text style={{ ...Typography.text(`base`, `black`) }}>{mod_explanation}</Text>
              </View>
            </View>
          </View>
        );
      }}
    />
  );
});

ReportDetail.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

ReportDetail.UserOrCommunityReport = ({ navigation, modIssue, goToCommunity }) => {
  const { reasons, explanation, type, origin_group, origin_group_id, reporter, reportee, created_at } = modIssue || {};
  const target =
    type === MODERATION_TYPE.user
      ? reportee?.handle?.trim() || `[deleted user]`
      : origin_group?.name?.trim() || `[deleted community]`;
  return (
    <View>
      <View style={localStyles.reportItemContainer}>
        <Avatar navigation={navigation} user={reporter} size={40} />
        <View style={localStyles.reportTextContainer}>
          <Text style={localStyles.reportTextHeaderText}>
            <Text style={localStyles.boldBlackBaseText}>{(reporter && reporter.handle.trim()) || `[deleted user]`} </Text>
            <Text>reported </Text>
            <Text style={localStyles.boldBlackBaseText} onPress={() => goToCommunity(origin_group_id)}>
              {target}
            </Text>
          </Text>
          <Text style={localStyles.smallGrayText}>{formatRelativeDate(created_at)}</Text>
        </View>
      </View>
      <View style={{ padding: 16 }}>
        <Text style={{ ...Typography.text(`base`, `gray`, `bold`), marginBottom: 8 }}>
          This {type} has violated the following:
        </Text>
        {reasons &&
          reasons[0] &&
          reasons.map((reason, i) => {
            return (
              <View key={reason.id + i.toString()}>
                <Text style={{ ...Typography.text(`black`, `plusone`) }}>{reason && reason.label}</Text>
              </View>
            );
          })}
      </View>
      <View style={{ padding: 16 }}>
        <Text style={{ ...Typography.text(`base`, `gray`, `bold`), marginBottom: 8 }}>Additional information provided:</Text>
        <View style={{ padding: 8, backgroundColor: Colors.backgroundColor, borderRadius: 8 }}>
          <Text style={{ ...Typography.text(`plusone`, `black`) }}>{explanation}</Text>
        </View>
      </View>
    </View>
  );
};
ReportDetail.UserOrCommunityReport.propTypes = {
  ...BaseReportProp,
  goToCommunity: PropTypes.func.isRequired,
};

ReportDetail.VerificationReport = ({ navigation, modIssue }) => {
  const { application, reportee, type, created_at } = modIssue || {};
  return (
    <View>
      <View style={localStyles.reportItemContainer}>
        <Avatar navigation={navigation} user={reportee} size={54} />
        <View style={localStyles.reportTextContainer}>
          <Text style={localStyles.reportTextHeaderText}>
            <Text style={localStyles.boldBlackBaseText}>{(reportee && reportee.handle.trim()) || `[deleted user]`}'s </Text>
            <Text>{type} request</Text>
          </Text>
          <Text style={localStyles.smallGrayText}>{formatRelativeDate(created_at)}</Text>
        </View>
      </View>
      <View>
        <Text style={{ ...Typography.text(`base`, `black`), margin: 24 }}>
          <Text>Name:{` `}</Text>
          <Text style={{ ...Typography.text(`base`, `bold`, `black`) }}>{reportee && reportee.name.trim()}</Text>
        </Text>
        {!!reportee?.pronouns?.length && (
          <View style={{ flexDirection: `row`, alignItems: `center` }}>
            <Text style={{ ...Typography.text(`base`, `gray`, `bold`), marginBottom: 16 }}>
              {reportee.pronouns.map((p) => p.pronoun).join(`, `)}
            </Text>
          </View>
        )}
      </View>
      <View style={{ alignItems: `center`, paddingBottom: 16 }}>
        {application && application.photo ? (
          <Image source={{ uri: application && application.photo }} style={{ height: 343, width: 344, borderRadius: 20 }} />
        ) : (
          <View style={localStyles.noImageConteiner}>
            <Text style={{ ...Typography.text(`small`, `gray`) }}>No image</Text>
          </View>
        )}
      </View>
    </View>
  );
};
ReportDetail.VerificationReport.propTypes = BaseReportProp;

ReportDetail.ActivityPreview = ({ navigation, modIssue, goToCommunity }) => {
  const { reportee, original_action, activity, category, type, created_at } = modIssue || {};
  if (!activity) return null;
  const { imageUrls, origin_group, origin_group_id } = activity;
  if (type === MODERATION_TYPE.comment) {
    return (
      <View style={{ padding: 16 }}>
        <View style={{ flexDirection: `row`, alignItems: `center` }}>
          <Avatar navigation={navigation} user={reportee} size={32} />
          <Text style={{ marginLeft: 8 }}>
            <Text style={Typography.text(`small`, `black`, `bold`)}>
              {(reportee && reportee.handle.trim()) || `[deleted user]`}
            </Text>
            <Text style={Typography.text(`small`, `gray`)}>{formatRelativeDate(activity.time)}</Text>
          </Text>
        </View>
        <View style={localStyles.activityPreviewContent}>
          <Text style={Typography.text(`base`, `black`, `center`)}>{activity.object.trim()}</Text>
        </View>
      </View>
    );
  }
  const renderImages = (images) => {
    if (images?.length === 1) return <Image source={{ uri: images[0] }} style={{ width: `100%`, height: 192 }} />;
    if (images?.length === 2) {
      return (
        <View>
          <View style={{ marginBottom: 3 }}>
            <Image source={{ uri: images[0] }} style={{ width: `100%`, height: 170 }} />
          </View>
          <View>
            <Image source={{ uri: images[1] }} style={{ width: `100%`, height: 170 }} />
          </View>
        </View>
      );
    }
    if (images?.length === 3) {
      return (
        <View>
          <Image source={{ uri: images[0] }} style={{ width: `100%`, height: 170, marginBottom: 3 }} />
          <View style={{ flexDirection: `row` }}>
            <View style={{ flex: 1, marginRight: 3 }}>
              <Image source={{ uri: images[1] }} style={{ width: `100%`, height: 170 }} />
            </View>
            <View style={{ flex: 1 }}>
              <Image source={{ uri: images[2] }} style={{ width: `100%`, height: 170 }} />
            </View>
          </View>
        </View>
      );
    }
    if (images?.length > 3) {
      return (
        <View>
          <Image source={{ uri: images[0] }} style={{ width: `100%`, height: 170, marginBottom: 3 }} />
          <View style={{ flexDirection: `row` }}>
            <View style={{ flex: 1, marginRight: 3 }}>
              <Image source={{ uri: images[1] }} style={{ width: `100%`, height: 170 }} />
            </View>
            <View style={{ flex: 1 }}>
              <View style={localStyles.imageCountOverlay}>
                <Text style={localStyles.imageCountText}>+{images.length - 3}</Text>
              </View>
              <Image source={{ uri: images[2] }} style={{ width: `100%`, height: 170 }} />
            </View>
          </View>
        </View>
      );
    }
    return null;
  };
  const subject = reportee?.handle?.trim() || `[deleted user]`;
  const action = category !== MODERATION_CATEGORY.post_approve ? `${original_action} to` : `posted to`;
  const target = origin_group?.name?.trim();
  const fullText = `${subject} ${action} ${target}`;
  const customMatchers = [
    {
      pattern: new RegExp(subject),
      style: Typography.text(`bold`, `small`),
    },
    {
      pattern: new RegExp(target),
      style: Typography.text(`bold`, `small`),
      onPress: () => goToCommunity(origin_group_id),
    },
  ];
  return (
    <View>
      <View style={{ flexDirection: `row`, padding: 16, paddingBottom: 5 }}>
        <Avatar navigation={navigation} user={reportee} size={40} />
        <View style={[localStyles.reportTextContainer, { justifyContent: `center` }]}>
          <ParsedText style={localStyles.activityHeaderText} parse={customMatchers}>
            {fullText}
          </ParsedText>
          <Text style={localStyles.smallGrayText}>{formatRelativeDate(created_at)}</Text>
        </View>
      </View>
      <View>{renderImages(imageUrls)}</View>
      <View style={{ paddingTop: 10, paddingBottom: 15, paddingHorizontal: 16, backgroundColor: `white` }}>
        <Text style={{ ...Typography.text(`base`, `black`), fontSize: 12 }}>{activity.content}</Text>
      </View>
    </View>
  );
};
ReportDetail.ActivityPreview.propTypes = {
  ...BaseReportProp,
  goToCommunity: PropTypes.func.isRequired,
};

ReportDetail.ListItem = ({ navigation, modIssue }) => {
  const { category, reporter, status, origin_group, activity } = modIssue || {};
  const isApproval = [MODERATION_CATEGORY.post_approve, MODERATION_CATEGORY.community_application];
  const avatarSize = isApproval.includes(category) ? 32 : 54;
  const isOpen = status === `UNDEF`;
  const title = isOpen ? getIssueTitle(modIssue, origin_group) : getClosedIssueTitle(modIssue, origin_group);
  return (
    <View style={localStyles.reportItemContainer}>
      <Avatar navigation={navigation} user={reporter} size={avatarSize} />
      <View style={localStyles.reportTextContainer}>
        <Text style={localStyles.reportTextHeaderText}>{title}</Text>
        {/* <Text style={localStyles.reportTextHeaderText}>
          {category === MODERATION_CATEGORY.post_approve && <Text>New post for approval submitted by </Text>}
          {!isApproval && (
            <>
              <Text style={localStyles.boldBlackBaseText}>{(reporter && reporter.handle.trim()) || `[deleted user]`} </Text>
              <Text>reported </Text>
            </>
          )}
          <Text style={localStyles.boldBlackBaseText}>{(reporter && reporter.handle.trim()) || `[deleted user]`} </Text>
          {category === MODERATION_CATEGORY.community_application && <Text> submitted an application to join </Text>}
          {!isApproval && (
            <>
              <Text>{type} </Text>
              {!!reasons?.length && (
                <Text>
                  <Text>citing </Text>
                  <Text style={{ ...Typography.text(`bold`), color: Colors.brightRed }}>
                    {reasons.map((r) => r.label).join(`, `)}
                  </Text>
                </Text>
              )}
            </>
          )}
        </Text> */}

        <Text style={localStyles.smallGrayText}>{formatRelativeDate(activity?.time)}</Text>
      </View>
    </View>
  );
};
ReportDetail.ListItem.propTypes = BaseReportProp;

ReportDetail.PostReport = ({ navigation, modIssue, goToCommunity }) => {
  console.log(`=== rendering activity or post approval ===`);
  // console.log(`mod issue in renderActivityOrPostApproval`, modIssue);
  const { activity, group, category, status, action_taken, reportee, explanation, type, object_id } = modIssue || {};
  // safeguard if activity not loaded yet (after edition)
  if (!activity) return null;
  // object_id = activity_id for report type comment

  return (
    <View>
      <ReportDetail.ListItem navigation={navigation} modIssue={modIssue} />
      <ReportDetail.ActivityPreview navigation={navigation} modIssue={modIssue} goToCommunity={goToCommunity} />
      {!!explanation && (
        <View style={localStyles.reportExplanationContainer}>
          <Text style={{ ...Typography.text(`small`, `gray`), paddingBottom: 10 }}>Reporter note</Text>
          <View style={{ padding: 10, borderRadius: 6, backgroundColor: Colors.backgroundColor }}>
            <Text style={{ ...Typography.text(`base`, `black`) }}>{explanation}</Text>
          </View>
        </View>
      )}
      {activity && (
        <View>
          <View style={{ height: 10, backgroundColor: `#EDECEE` }} />
          <ListItem
            title="View post"
            titleStyle={localStyles.viewPostTitle}
            rightIcon={<MeshIcon name="chevron-right" color={Colors.iconColor} />}
            onPress={() => {
              const isOpen = status === `UNDEF`;
              // remove_post: special case when post was denied then approved then removed
              if (action_taken === `reject_post` || action_taken === `remove_post`) {
                navigation.push(`DeniedDetail`, { activity });
              } else if (
                (category === `post_approve` && isOpen) ||
                (category === `post_approve` && !isOpen && !activity.origin_activity_id)
              ) {
                navigation.push(`PostApprovalReview`, {
                  moderationIssue: modIssue,
                  status: isOpen ? `open` : `closed`,
                  group,
                  user: reportee,
                  prev: `Report`,
                });
              } else {
                navigation.push(`PostDetail`, {
                  id: type === `comment` ? object_id : activity?.origin_activity_id,
                  group_id: group.id,
                  isPersonalFeed: activity.__typename === `ProfileActivity`,
                  fromReportScreen: true,
                });
              }
            }}
          />
        </View>
      )}
    </View>
  );
};
ReportDetail.PostReport.propTypes = {
  ...BaseReportProp,
  goToCommunity: PropTypes.func.isRequired,
};

const BaseReportProp = {
  navigation: commonPropTypes.navigation().isRequired,
  modIssue: commonPropTypes.issue().isRequired,
};

const getReportComponent = (type) => {
  switch (type) {
    case MODERATION_TYPE.user:
      return ReportDetail.UserOrCommunityReport;
    case MODERATION_TYPE.community:
      return ReportDetail.UserOrCommunityReport;
    case MODERATION_TYPE.verification:
      return ReportDetail.VerificationReport;
    default:
      return ReportDetail.PostReport;
  }
};
const _getAvatarForPersona = (persona) => {
  const avatar = persona.avatar_url ? { uri: persona.avatar_url } : require(`../../assets/images/user_avatar.png`);
  return avatar;
};

const localStyles = {
  reportHeaderContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    padding: 16,
    alignItems: `center`,
  },
  statusTag: {
    padding: 6,
    borderRadius: 99,
    width: 66,
  },
  statusTagText: {
    ...Typography.text(`small`, `bold`, `center`),
  },
  boldBlackBaseText: {
    ...Typography.text(`plusone`, `bold`, `black`),
  },
  rowContent: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 3,
  },
  smallGrayText: {
    ...Typography.text(`small`, `gray`),
  },
  reportItemContainer: {
    flexDirection: `row`,
    padding: 16,
    backgroundColor: `#F6F5F6`,
    alignItems: `center`,
  },
  reportExplanationContainer: {
    borderTopWidth: 1,
    padding: 16,
    paddingVertical: 10,
    borderColor: `#EDECEE`,
  },
  bigAvatar: {
    height: 54,
    width: 54,
    borderRadius: 99,
  },
  reportTextContainer: {
    flex: 1,
    marginLeft: 8,
  },
  reportTextHeaderText: {
    flexWrap: `wrap`,
    flexShrink: 1,
    ...Typography.text(`base`, `black`),
  },
  activityHeaderText: {
    flexWrap: `wrap`,
    flexShrink: 1,
    ...Typography.text(`small`, `black`),
  },
  activityPreviewContent: {
    paddingVertical: 8,
    paddingRight: 8,
    backgroundColor: Colors.backgroundColor,
    borderRadius: 15,
    borderTopLeftRadius: 0,
    alignItems: `center`,
    justifyContent: `center`,
    marginLeft: 40,
  },
  viewPostTitle: {
    ...Typography.text(`base`, `bold`),
  },
  reporterTitle: {
    ...Typography.text(`small`, `gray`, `bold`),
  },
  subtitle: {
    ...Typography.text(`base`, `bold`, `black`),
  },
  imageCountOverlay: {
    backgroundColor: Colors.translucentBlack,
    position: `absolute`,
    zIndex: 200,
    width: `100%`,
    height: 170,
    alignItems: `center`,
    justifyContent: `center`,
  },
  imageCountText: {
    ...Typography.text(`large`, `white`, `bold`, `center`),
  },
  noImageContainer: {
    height: 343,
    width: 344,
    borderRadius: 20,
    backgroundColor: Colors.backgroundColor,
    justifyContent: `center`,
    alignItems: `center`,
  },
};
