import { RouteProp, useRoute } from '@react-navigation/native';
import { useEffect, useContext } from 'react';
import { useLazyQuery } from 'react-apollo';
import { AppContext } from '../../../AppContext';
import { ExploreNavigatorScreens } from '../../common-types/navigation-types';
import { GetInvitationData, GetInvitationVars } from '../../common-types/types';
import { GET_INVITATION } from '../../graphql/Queries.gql';

type InviteLandingRoute = RouteProp<ExploreNavigatorScreens, `LandingLoadingScreen`>;

type InvitationHook = {
  loading: boolean;
  invitation?: GetInvitationData[`getInvitation`];
  error?: Error;
  invite_token?: string;
};

export const useInvite = (): InvitationHook => {
  const { isGuestMode, setInviteToken, setLaunchUrl } = useContext(AppContext);
  const { params } = useRoute<InviteLandingRoute>();

  const [requestInvite, { data, loading, error }] = useLazyQuery<GetInvitationData, GetInvitationVars>(GET_INVITATION, {
    fetchPolicy: `cache-and-network`,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    onError: (err) => console.log(`[useInviteError]`, (err as Error).message),
  });

  useEffect(() => {
    if (!isGuestMode && params?.invite_token) {
      setInviteToken(undefined);
      setLaunchUrl(null);
      requestInvite({ variables: { invite_token: params?.invite_token } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.invite_token, setInviteToken, requestInvite, isGuestMode]);

  return {
    invitation: data?.getInvitation,
    loading,
    error,
    invite_token: params?.invite_token,
  };
};
