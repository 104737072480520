/* eslint-disable react/no-arrow-function-lifecycle */
import PropTypes from 'prop-types';
import { Alert, StyleSheet, Text, TextInput, View } from 'react-native';
import Modal from 'react-native-modal';
import React from 'react';
import { MESH_MODERATION_GROUP } from '../../constants';
import { Colors, Typography, _bad_do_not_use_HEIGHT, _bad_do_not_use_WIDTH } from '../common-styles';
import { Divider, KeyboardPaddingView, NoThemeButton, SafeAreaView, ThemedButton } from '../common-ui';
import { LoadingIndicator } from '../common-ui/LoadingIndicator';
import { client, refetchQueriesFor, REPORT_USER } from '../graphql';
import { AgreementsView } from './AgreementsView';
import { MODERATION_TYPE } from './moderationEnums';
import { reportModerationIssue } from './moderationServices';
import { ReportModalTestIds } from './testLabels';

/**
 *  Required params per specific reportType
 *
 *  MODERATION_TYPE.user
 *    - reportee
 *  MODERATION_TYPE.post
 *    - id
 *    - origin_group_id
 *  MODERATION_TYPE.comment
 *    - id
 *    - reaction_id
 *    - origin_group_id
 *  MODERATION_TYPE.community
 *    - origin_group_id
 */

export class ReportModal extends React.PureComponent {
  static propTypes = {
    reportType: PropTypes.string.isRequired,
    // TODO: merge the following 3 params in onlyone param "target", then any other in "extras"
    id: PropTypes.string, // activity id -- pass if reporting a type Activity OR type ActivityReaction. Reason being we need to fetch the reaction attached to parent Activity
    reaction_id: PropTypes.string, // reaction  id -- you must pass this if reporting a type ActivityReaction e.g. reporting a comment
    reportee: PropTypes.string, // for user reports only
    //
    origin_group_id: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    reaction_id: undefined,
    id: undefined,
    reportee: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      reasonsSelected: [],
      explanation: ``,
      isSubmitting: false,
      isFocused: false,
    };
    if (props.isVisible) {
      console.log(`Report Type:`, props.reportType, `(${props.id || props.origin_group_id})`);
    }
  }

  cancelReport = () => {
    const { onClose } = this.props;
    this.setState({ reasonsSelected: [], explanation: `` });
    onClose();
  };

  submitModerationIssueReport = async () => {
    console.log(`=== firing submitModerationIssueReport ===`);
    const { origin_group_id, id, reaction_id, reportType } = this.props;
    const { explanation, reasonsSelected } = this.state;

    this.setState({ isSubmitting: true });

    const reasonIds = reasonsSelected.map((reason) => `${reason.id}`);

    const input = {
      group_id: origin_group_id,
      reaction_id: reaction_id || null, // reaction id e.g. comments - report comment
      object_id: id || null, // activity id - report activity
      reasons: reasonIds,
      explanation,
      type: reportType,
    };

    try {
      const reported = await reportModerationIssue(input);
      if (reported && reported.res_status === `200OK`) {
        Alert.alert(`Report sent!`, ``, [{ text: `OK`, onPress: this.cancelReport, style: `default` }]);
      } else {
        Alert.alert(`A problem occurred submitting your report`, `Please try again later`, [
          { text: `OK`, onPress: this.cancelReport, style: `default` },
        ]);
      }
    } catch (err) {
      console.error(`exception thrown in submitModerationIssueReport in ReportScreen.jsx`, err.message);
    }

    return this.setState({ isSubmitting: false, reasonsSelected: [], explanation: `` });
  };

  reportUser = async () => {
    console.log(`=== firing report user ===`);
    try {
      const { reportee } = this.props;
      const { reasonsSelected, explanation } = this.state;
      const reasonIds = reasonsSelected.map((reason) => reason.id);

      const input = {
        group_id: MESH_MODERATION_GROUP, // note: this will ALWAYS be Mesh Moderation as per Jessica's instruction
        reportee_id: reportee, // id of the user who is being reported
        reasons: reasonIds,
        explanation,
      };

      const { data } = await client.mutate({
        mutation: REPORT_USER,
        variables: { input },
        refetchQueries: refetchQueriesFor(`ModerationIssue`),
      });
      const reportedUserIssue = data.reportUser;
      console.log(`reported user issue`, reportedUserIssue);
      if (reportedUserIssue && reportedUserIssue.res_status === `200OK`) {
        Alert.alert(`Report sent!`, ``, [{ text: `OK`, onPress: this.cancelReport, style: `default` }]);
      } else {
        Alert.alert(`A problem occurred submitting your report`, `Please try again later`, [
          { text: `OK`, onPress: this.cancelReport, style: `default` },
        ]);
      }
      return reportedUserIssue;
    } catch (err) {
      console.error(`Error thrown in reportUser() in ReportModal.jsx`, err.message);
      return null;
    } finally {
      this.setState({ reasonsSelected: [], explanation: `` });
    }
  };

  setReasons = (reasons) => {
    this.setState({ reasonsSelected: reasons });
  };

  renderReasonsMessage = (reasonsSelected) => {
    const { explanation } = this.state;
    let message = ``;
    if (reasonsSelected.length > 1) message = `You have selected ${reasonsSelected.length} reasons. `;
    if (reasonsSelected.length === 1) message = `You have selected 1 reason. `;
    if (!explanation.length > 0) message = message.concat(`Brief explanation (required)`);
    return message;
  };

  setTextboxFocus = (isFocused) => {
    this.setState({ isFocused });
  };

  handleChange = (text) => {
    this.setState({ explanation: text });
  };

  render = () => {
    const { origin_group_id, reportType, isVisible } = this.props;
    const { reasonsSelected, isFocused, isSubmitting, explanation } = this.state;

    const disabled = isSubmitting || !explanation.trim().length > 0 || !reasonsSelected.length > 0;
    return (
      <Modal isVisible={isVisible} style={localStyles.modalStyle} onBackdropPress={this.cancelReport}>
        <KeyboardPaddingView>
          <SafeAreaView style={{ flex: 1 }}>
            <View style={localStyles.headerContainer}>
              <NoThemeButton
                clear
                title="Cancel"
                onPress={this.cancelReport}
                color={Colors.iconColor}
                titleStyle={localStyles.headerCancel}
                containerStyle={{ position: `absolute`, left: 8 }}
              />
              <Text
                style={{ ...StyleSheet.flatten(localStyles.headerText), color: Colors.darkestGray }}
                testID={ReportModalTestIds.title}
              >
                Report {reportType}
              </Text>
            </View>
            <View style={localStyles.innerContainer}>
              <View style={{ margin: 10 }}>
                <Text style={{ color: Colors.darkGray }}>
                  This {reportType} violates the following agreements (select all that apply):
                </Text>
              </View>

              <Divider style={{ width: `100%` }} />
              <AgreementsView
                origin_group_id={origin_group_id}
                isUser={reportType === MODERATION_TYPE.user}
                reasons={reasonsSelected}
                setReasons={this.setReasons}
                isCommunityReport={reportType === MODERATION_TYPE.community}
              />
              <Divider style={{ width: `100%` }} />
              <View style={localStyles.textInputContainer}>
                <Text style={localStyles.briefExplanation}>{this.renderReasonsMessage(reasonsSelected)}</Text>
                <TextInput
                  multiline
                  scrollEnabled
                  blurOnSubmit
                  value={explanation}
                  testID={ReportModalTestIds.reason}
                  onChangeText={this.handleChange}
                  placeholder="Explain why you are reporting this"
                  placeholderTextColor={Colors.textPlaceholder}
                  style={{
                    ...StyleSheet.flatten(localStyles.textInput),
                    borderColor: isFocused ? Colors.iconColor : Colors.mediumGray,
                    borderWidth: isFocused ? 2 : 1,
                    textAlignVertical: `top`,
                  }}
                  onFocus={() => this.setTextboxFocus(true)}
                  onBlur={() => this.setTextboxFocus(false)}
                />
                <ThemedButton
                  rounded
                  disabled={disabled}
                  title="Submit"
                  testID={ReportModalTestIds.submit}
                  buttonStyle={{ padding: 10 }}
                  onPress={!(reportType === MODERATION_TYPE.user) ? this.submitModerationIssueReport : this.reportUser}
                  leftIcon={isSubmitting && <LoadingIndicator />}
                />
              </View>
            </View>
          </SafeAreaView>
        </KeyboardPaddingView>
      </Modal>
    );
  };
}

const localStyles = StyleSheet.create({
  modalStyle: {
    flex: 1,
    height: _bad_do_not_use_HEIGHT,
    width: _bad_do_not_use_WIDTH,
    backgroundColor: Colors.white,
    margin: 0,
    justifyContent: `flex-end`,
  },
  headerContainer: {
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: 10,
    shadowColor: Colors.gray,
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    borderColor: Colors.lightWarmGray,
    elevation: 5,
    backgroundColor: Colors.white,
    width: _bad_do_not_use_WIDTH,
    height: 48,
    borderTopWidth: 0,
  },
  headerCancel: {
    ...Typography.text(`bold`),
    color: Colors.iconColor,
  },
  headerText: {
    ...Typography.text(`plustwo`, `bold`),
    fontWeight: `600`,
  },
  innerContainer: {
    flex: 1,
    margin: 10,
    justifyContent: `flex-end`,
  },
  briefExplanation: {
    fontSize: Typography.extraSmallFontSize,
    fontWeight: `500`,
    color: Colors.mediumGray,
  },
  textInputContainer: {
    flexShrink: 1,
    margin: 10,
  },
  textInput: {
    flexShrink: 1,
    borderWidth: 1,
    borderColor: Colors.mediumGray,
    padding: 6,
    borderRadius: 4,
    margin: 10,
    minHeight: 100,
    maxHeight: 125,
  },
});
