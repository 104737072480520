import { ScrollView, StyleSheet } from 'react-native';
import React from 'react';
//import crashlytics from '@react-native-firebase/crashlytics';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ThemedButton } from '../common-ui/ThemedButton';
import { Colors, Typography } from '../common-styles';
import { HackNavigatorScreens } from '../common-types/navigation-types';
import { Divider } from '../common-ui';

type DevToolsNavigation = StackNavigationProp<HackNavigatorScreens, `DevTools`>;

export const DevTools = () => {
  const navigation = useNavigation<DevToolsNavigation>();

  return (
    <ScrollView style={styles.container}>
      <ThemedButton
        clear
        title="See Hero Assets Tool"
        onPress={() => navigation.navigate(`HeroAreaMediaTool`)}
        containerStyle={styles.button}
        buttonStyle={styles.button}
        titleStyle={styles.title}
      />
      <Divider />
      <ThemedButton
        clear
        title="See Advertiser Tool"
        onPress={() => navigation.navigate(`Ads`)}
        containerStyle={styles.button}
        buttonStyle={styles.button}
        titleStyle={styles.title}
      />
      <Divider />
      <ThemedButton
        clear
        title="See LocalStorage"
        onPress={() => navigation.navigate(`LocalStorage`)}
        containerStyle={styles.button}
        buttonStyle={styles.button}
        titleStyle={styles.title}
      />
      <Divider />
      <ThemedButton
        clear
        title="See context"
        onPress={() => navigation.navigate(`Context`)}
        containerStyle={styles.button}
        buttonStyle={styles.button}
        titleStyle={styles.title}
      />
      <Divider />
      <ThemedButton
        clear
        title="See ReduxSlice"
        onPress={() => navigation.navigate(`ReduxSlice`)}
        containerStyle={styles.button}
        buttonStyle={styles.button}
        titleStyle={styles.title}
      />
      <Divider />
      <ThemedButton
        clear
        title="trigger crash"
        onPress={() => null /*crashlytics().crash()*/}
        containerStyle={styles.button}
        buttonStyle={styles.button}
        titleStyle={styles.title}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  title: Typography.text(`bold`, `link`, `plusone`),
  button: { marginVertical: 8, padding: 6, borderRadius: 18 },
});
