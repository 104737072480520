import React, { useState } from 'react';
import { Platform, StyleSheet, TextInput, TextInputProps } from 'react-native';
import _ from 'lodash';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import { Colors, Typography } from '../common-styles';
import { KeyMap } from '../common-types/types';

type Autocomplete = `tel` | `email` | `username`;
type ContentType = `telephoneNumber` | `emailAddress` | `none`;

interface ContactInputProps extends TextInputProps {
  type?: `phone` | `email` | `both`;
  isUsername?: boolean;
  forwardRef?: any;
}

export const ContactInput: React.FC<ContactInputProps> = ({
  type = `both`,
  isUsername = true,
  onChangeText = undefined,
  style = {},
  forwardRef = undefined,
  editable = true,
  ...otherProps
}) => {
  const [text, setText] = useState(``);
  const [focused, setFocused] = useState(false);

  const textWasChanged = (incomingText: string) => {
    let newText = incomingText;

    if (text.length > newText.length) {
      setText(newText);
      if (onChangeText) onChangeText(newText);
      return;
    }
    const hasNonNumericChars = /[^0-9+\-. ()+]/.test(newText);
    if (hasNonNumericChars) {
      newText = newText.replace(/[(|)| ]/g, ``);
      if (/^\d{6,7}[-]/.test(newText)) {
        // this must only happen once just after deleting a phone format
        newText = newText.replace(`-`, ``);
      }
    }
    if (type === `phone` || (type === `both` && !hasNonNumericChars)) {
      newText = formatIncompletePhoneNumber(newText, `US`);

      // If the text ends in a close-parenthesis, add a space
      if (!text.endsWith(` `) && newText.endsWith(`)`)) {
        newText += ` `;
      }
    }
    setText(newText);

    if (onChangeText) onChangeText(newText);
  };

  const typeToAutoCompleteType: KeyMap<Autocomplete> = { phone: `tel`, email: `email`, both: `username` };
  const typeToTextContentType: KeyMap<ContentType> = { phone: `telephoneNumber`, email: `emailAddress`, both: `none` };

  return (
    <TextInput
      autoCapitalize="none"
      autoCompleteType={isUsername ? `username` : typeToAutoCompleteType[type]}
      autoCorrect={false}
      clearButtonMode="while-editing"
      disableFullscreenUI
      editable={editable}
      importantForAutofill="yes"
      keyboardType={type === `phone` ? `phone-pad` : `default`}
      onChangeText={textWasChanged}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      placeholder="Enter your username or verified phone number"
      placeholderTextColor={Colors.textPlaceholder}
      returnKeyType="next"
      selectTextOnFocus
      textContentType={isUsername ? `username` : typeToTextContentType[type]}
      value={text}
      ref={forwardRef}
      style={[{ borderColor: focused ? Colors.brandPurple : Colors.mediumGray }, localStyles.defaultStyle, style]}
      {...otherProps}
    />
  );
};

const localStyles = StyleSheet.create({
  defaultStyle: {
    ...Typography.text(),
    ...Platform.select({ default: {}, web: { outlineWidth: 0 } }),
    padding: 10,
    borderRadius: 4,
    borderWidth: 1,
  },
});
