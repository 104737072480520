import { useQuery } from '@apollo/react-hooks';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Ionicons';
import { AppContext } from '../../AppContext';
import { Colors, Typography } from '../common-styles';
import { GET_USER_FOLLOWINGS, GET_USER_FOLLOWERS, GET_FOLLOW_COUNT } from '../graphql';
import { BackButton, BottomSheetModal, CenterColumn, LoadingIndicator, ThemedButton } from '../common-ui';
import { useDisclosure } from '../common-util/hooks/useDisclosure';
import { BottomSheetOptions } from '../common-types/types';
import { PostTestIds } from '../common-util/hooks/testLabels';
import { useFollow } from '../community/v2/hooks/useFollow';
import { MeshVerifiedCheck, UserDefaultAvatar } from '../images';
import { useReportModal } from '../community/v2/hooks/useReportModal';
import { ReportModal } from '../moderation/ReportModal';
import { RootState } from '../redux/store';
import { refreshFollowInfo, setFollowCounts } from '../redux/followSlice';
import { Events, PendoTrackEvent } from '../pendo/events';

enum Status {
  FOLLOWINGS = `followings`,
  FOLLOWERS = `followers`,
}

interface FollowResponse {
  id: string;
  name: string;
  avatar_url: string;
  pronouns: any;
  send_notification: boolean;
  handle: string;
  persona_id: string;
  accepted: boolean;
  isFollowing?: boolean;
  isVerified?: boolean;
}

type UserFollowingsData = {
  getUserFollowings: {
    data: FollowResponse[];
  };
};
type UserFollowersData = {
  getUserFollowers: {
    data: FollowResponse[];
  };
};

const DEFAULT_LIMITS = 10;

const FollowInfoScreen: React.FC<any> = ({ navigation }) => {
  const dispatch = useDispatch();

  const { user } = React.useContext(AppContext);

  const { data: followCounts, refetch: onRefetchCounts } = useQuery(GET_FOLLOW_COUNT, {
    fetchPolicy: `cache-and-network`,
  });

  const [searchKey, setSearchKey] = useState(``);

  const { counts } = useSelector((state: RootState) => state.follow);
  const [status, setStatus] = useState<Status>(Status.FOLLOWINGS);
  const [data, setData] = useState<FollowResponse[]>([]);

  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreToFetch, setNoMoreToFetch] = useState(false);

  const [limitCount, setLimitCount] = useState({
    follow: counts?.followers || 0,
    following: counts?.following || 0,
  });

  const { isOpen, onClose, onToggle } = useDisclosure();
  const [options, setOptions] = useState<BottomSheetOptions[]>([]);
  const { showReportModal, isOpen: isReportModalOpen, postId, hideReportModal, reportee, reportType } = useReportModal();
  const {
    data: followers,
    loading: followLoading,
    refetch: onRefetchFollowers,
    fetchMore: fetchMoreFollower,
  } = useQuery<UserFollowersData>(GET_USER_FOLLOWERS, {
    fetchPolicy: `cache-and-network`,
    variables: {
      filter: {
        search: searchKey,
        accepted: true,
        skip: 0,
        limit: limitCount.follow,
      },
    },
  });

  const {
    data: followings,
    loading: followingLoading,
    refetch: onRefetchFollowings,
    fetchMore: fetchMoreFollowing,
  } = useQuery<UserFollowingsData>(GET_USER_FOLLOWINGS, {
    fetchPolicy: `cache-and-network`,
    variables: {
      filter: {
        search: searchKey,
        skip: 0,
        limit: limitCount.following,
      },
    },
  });

  const renderFooter = () => {
    const loading = status === Status.FOLLOWINGS ? followingLoading : followLoading;
    const counts =
      status === Status.FOLLOWERS ? followCounts?.getFollowCounts?.follwers : followCounts?.getFollowCounts?.following;

    if (counts > 0) {
      return (
        <CenterColumn>
          <View style={localStyles.containerListFooter}>
            {loadingMore && <LoadingIndicator size="small" />}
            {!loadingMore && !loading && !noMoreToFetch && (
              <ThemedButton
                rounded
                outline
                clear
                title="Load more"
                onPress={status === Status.FOLLOWINGS ? getMoreFollowingItems : getMoreFollowerItems}
                buttonStyle={{ paddingHorizontal: 30, paddingVertical: 12 }}
                disabled={loading || loadingMore || noMoreToFetch}
              />
            )}
          </View>
        </CenterColumn>
      );
    }
    return null;
  };

  const getMoreFollowingItems = useCallback(() => {
    const total = followings?.getUserFollowings?.data?.length || 0;
    if (!followingLoading && !loadingMore && !noMoreToFetch) {
      setLoadingMore(true);
      setLimitCount({ ...limitCount, following: total });
      fetchMoreFollowing({
        variables: {
          filter: {
            search: searchKey,
            skip: total,
            limit: DEFAULT_LIMITS,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult?.getUserFollowings?.data.length ||
            fetchMoreResult?.getUserFollowings?.data.slice(-1)[0].id === prev?.getUserFollowings?.data.slice(-1)[0].id
          ) {
            console.log(`Same with previous one, return prev`);
            setLoadingMore(false);
            setNoMoreToFetch(true);
            return prev;
          }

          setLoadingMore(false);
          setNoMoreToFetch(false);

          return {
            ...prev,
            getUserFollowings: {
              __typename: `getUserFollowings`,
              data: [...prev?.getUserFollowings?.data, ...fetchMoreResult?.getUserFollowings?.data],
            },
          };
        },
      });
    }
  }, [
    fetchMoreFollowing,
    followings?.getUserFollowings?.data,
    followingLoading,
    loadingMore,
    noMoreToFetch,
    setNoMoreToFetch,
    setLoadingMore,
  ]);

  const getMoreFollowerItems = useCallback(() => {
    const total: number = followers?.getUserFollowers?.data?.length || 0;
    if (!followLoading && !loadingMore && !noMoreToFetch) {
      setLoadingMore(true);
      setLimitCount({ ...limitCount, follow: total });
      fetchMoreFollower({
        variables: {
          filter: {
            search: searchKey,
            skip: total,
            limit: DEFAULT_LIMITS,
            accepted: true,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult?.getUserFollowers?.data.length ||
            fetchMoreResult?.getUserFollowers?.data.slice(-1)[0].id === prev?.getUserFollowers?.data.slice(-1)[0].id
          ) {
            console.log(`Same with previous one, return prev`);
            setLoadingMore(false);
            setNoMoreToFetch(true);
            return prev;
          }

          setLoadingMore(false);
          setNoMoreToFetch(false);

          return {
            ...prev,
            getUserFollowers: {
              __typename: `getUserFollowers`,
              data: [...prev?.getUserFollowers?.data, ...fetchMoreResult?.getUserFollowers?.data],
            },
          };
        },
      });
    }
  }, [
    fetchMoreFollower,
    followers?.getUserFollowers?.data,
    followLoading,
    loadingMore,
    noMoreToFetch,
    setNoMoreToFetch,
    setLoadingMore,
  ]);

  const { followUser, unfollowUser, updateFollowNotification, declineFollowRequest } = useFollow();
  const { isFollowInfoRefresh } = useSelector((state: RootState) => state.follow);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (status === Status.FOLLOWINGS) {
          await onRefetchFollowings();
        } else {
          await onRefetchFollowers();
        }
      } catch (error) {
        console.log(`Error refetching ${status}`, error);
      } finally {
        dispatch(refreshFollowInfo(false));
      }
    };
    if (isFollowInfoRefresh) refresh();
  }, [isFollowInfoRefresh, status, onRefetchFollowings, onRefetchFollowers, dispatch]);

  useEffect(() => {
    const goBack = () => navigation.getParent().jumpTo(`RightDrawer`);
    navigation.setOptions({
      headerTitle: user?.handle,
      headerLeft: () => <BackButton onPress={goBack} />,
    });
    return () => {
      setData([]);
    };
  }, [navigation, user?.handle]);

  useEffect(() => {
    if (followCounts?.getFollowCounts) {
      const payload = {
        followers: followCounts?.getFollowCounts?.follwers || 0,
        following: followCounts?.getFollowCounts?.following || 0,
      };
      dispatch(setFollowCounts(payload));
    }
  }, [dispatch, followCounts]);

  useEffect(() => {
    if (status === Status.FOLLOWINGS && followings?.getUserFollowings?.data) {
      setData(followings?.getUserFollowings?.data);
    } else if (status === Status.FOLLOWERS && followers?.getUserFollowers?.data) {
      setData(followers?.getUserFollowers?.data);
    }
  }, [status, followings, followers]);

  const handleUnfollowing = useCallback(
    async (id: string, accepted: boolean) => {
      try {
        if (accepted) await unfollowUser(id, true);
        else await followUser(id, true);
        await onRefetchCounts();
        await onRefetchFollowings();
        await onRefetchFollowers();
      } catch (error) {
        console.error(`Unfollower Error: ${error}`);
      }
    },
    [followUser, unfollowUser, onRefetchCounts, onRefetchFollowers, onRefetchFollowings],
  );

  const handleRemoveFollower = useCallback(
    async (id: string) => {
      try {
        await declineFollowRequest(id);
        await onRefetchCounts();
        await onRefetchFollowers();
      } catch (error) {
        console.error(error);
      }
    },
    [declineFollowRequest, onRefetchCounts, onRefetchFollowers],
  );
  const handleFollowNotification = useCallback(
    async (id: string, setting: boolean) => {
      try {
        await updateFollowNotification(id, setting);
        await onRefetchFollowings();
      } catch (error) {
        console.error(`Notification Update Error: ${error}`);
      }
    },
    [updateFollowNotification, onRefetchFollowings],
  );

  const goToProfile = useCallback(
    (user: FollowResponse) => {
      PendoTrackEvent(Events.PROFILE, {
        username: user?.handle,
        source: `notifications`,
        element: `follow request`,
      });
      navigation.navigate(`ProfileStack`, {
        screen: `Profile`,
        params: { user, from: `follow`, isSameStack: true, user_id: user.persona_id },
      });
    },
    [navigation],
  );

  const handleSelect = useCallback(
    (item: FollowResponse) => {
      let followTitle = `Remove follow request for`;
      if (item.isFollowing) followTitle = `Unfollow`;
      if (item.isFollowing === null) followTitle = `Follow`;
      const options: BottomSheetOptions[] = [
        {
          visible: status === Status.FOLLOWINGS,
          title: `Unfollow ${item?.handle}`,
          iconName: `unFollow`,
          testID: PostTestIds.unfollowerUser,
          onPress: () => {
            onToggle();
            handleUnfollowing(item.persona_id, true);
          },
        },
        {
          visible: status === Status.FOLLOWERS,
          title: `${followTitle} ${item?.handle}`,
          iconName: item.isFollowing === null ? `follow` : `unFollow`,
          testID: PostTestIds.unfollowerUser,
          onPress: () => {
            onToggle();
            handleUnfollowing(item.persona_id, !!item.isFollowing);
          },
          // disabled: typeof item.isFollowing === `boolean` && !item.isFollowing,
        },
        {
          visible: true,
          title: `View Profile`,
          iconName: `profile`,
          testID: PostTestIds.viewProfile,
          onPress: () => {
            onToggle();
            goToProfile(item);
          },
        },
        {
          visible: status === Status.FOLLOWERS,
          title: `Remove follower`,
          iconName: `close`,
          testID: PostTestIds.unfollowerUser,
          onPress: () => {
            onToggle();
            handleRemoveFollower(item.id);
          },
        },
        {
          visible: status === Status.FOLLOWINGS,
          title: `Turn notifications ${item.send_notification ? `off` : `on`}`,
          iconName: !item.send_notification ? `volume-2` : `volume-x`,
          testID: PostTestIds.followingNotifiOn,
          onPress: () => {
            onToggle();
            handleFollowNotification(item?.id, !item.send_notification);
          },
        },
        {
          visible: true,
          title: `Block ${item?.handle}`,
          iconName: `info-circle`,
          iconColor: Colors.alertColor,
          titleStyle: { color: Colors.alertColor },
          testID: PostTestIds.userReport,
          onPress: () => {
            onToggle();
          },
        },
        {
          visible: true,
          title: `Report ${item?.handle}`,
          iconName: `flag`,
          iconColor: Colors.alertColor,
          titleStyle: { color: Colors.alertColor },
          testID: PostTestIds.userReport,
          onPress: () => {
            onToggle();
            setTimeout(() => showReportModal(`user`, item.persona_id), 500);
          },
        },
      ];
      setOptions(options.filter((opt) => opt.visible));
      onToggle();
    },
    [navigation, handleUnfollowing, setOptions, onToggle, status],
  );

  const handleCloseBottomSheet = () => {
    setOptions([]);
    onClose();
  };

  const renderItem: ListRenderItem<FollowResponse> = ({ item }) => {
    return (
      <View
        style={{
          flexDirection: `row`,
          alignItems: `center`,
          justifyContent: `space-between`,
          paddingVertical: 10,
          paddingHorizontal: 15,
        }}
      >
        <TouchableOpacity style={{ flexDirection: `row`, alignItems: `center` }} onPress={() => goToProfile(item)}>
          {item.avatar_url ? (
            <Image source={{ uri: item.avatar_url }} style={{ width: 50, height: 50, borderRadius: 25 }} />
          ) : (
            <UserDefaultAvatar width={50} height={50} />
          )}

          <View style={{ marginLeft: 8 }}>
            <View style={{ flexDirection: `row`, alignItems: `center` }}>
              <Text style={{ ...Typography.text(`bold`) }}>{item?.handle}</Text>
              {item.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
            </View>
            <Text style={{ ...Typography.text(`small`, `gray`) }}>{item.pronouns?.[0]?.pronoun}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleSelect(item)}>
          <Icon name="ellipsis-vertical" size={18} color={Colors.iconColor} />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={localStyles.searchContent}>
        <TextInput
          placeholder="Search usernames"
          placeholderTextColor={Colors.textPlaceholder}
          style={localStyles.textInput}
          onChangeText={setSearchKey}
          value={searchKey}
        />
        <TouchableOpacity disabled={!searchKey} onPress={() => setSearchKey(``)}>
          <Icon name={searchKey ? `close` : `search`} color={Colors.iconColor} size={24} />
        </TouchableOpacity>
      </View>
      <View style={localStyles.butttonContent}>
        <TouchableOpacity
          onPress={() => {
            setStatus(Status.FOLLOWINGS);
            setLoadingMore(false);
            setNoMoreToFetch(false);
          }}
          style={localStyles.buttonContainer}
          activeOpacity={0.8}
        >
          <View style={{ ...localStyles.buttonStyle, borderBottomWidth: status === Status.FOLLOWINGS ? 1 : 0 }}>
            <Text
              style={{ ...localStyles.buttonText, color: status === Status.FOLLOWINGS ? Colors.brandPurple : Colors.iconColor }}
            >
              Following ({counts?.following ? counts?.following : 0})
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setStatus(Status.FOLLOWERS);
            setLoadingMore(false);
            setNoMoreToFetch(false);
          }}
          style={localStyles.buttonContainer}
          activeOpacity={0.8}
        >
          <View style={{ ...localStyles.buttonStyle, borderBottomWidth: status === Status.FOLLOWERS ? 1 : 0 }}>
            <Text
              style={{ ...localStyles.buttonText, color: status === Status.FOLLOWERS ? Colors.brandPurple : Colors.iconColor }}
            >
              Followers ({counts?.followers ? counts?.followers : 0})
            </Text>
          </View>
        </TouchableOpacity>
      </View>

      <FlatList
        style={localStyles.containerFlatlist}
        data={data}
        extraData={data}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        ListFooterComponent={renderFooter}
        onEndReachedThreshold={0.3}
        onEndReached={status === Status.FOLLOWINGS ? getMoreFollowingItems : getMoreFollowerItems}
        scrollIndicatorInsets={{ right: 1 }}
        ListEmptyComponent={() => {
          if (followLoading || followingLoading) {
            return <ActivityIndicator />;
          }
          if (!followingLoading && !followLoading && data?.length === 0) {
            return (
              <Text style={{ textAlign: `center`, paddingVertical: 10, color: Colors.gray }}>
                {status === Status.FOLLOWINGS ? `You're not following anyone yet` : `You don't have any followers yet`}
              </Text>
            );
          }
          return null;
        }}
        refreshControl={
          <RefreshControl
            refreshing={status === Status.FOLLOWINGS ? followingLoading : followLoading}
            onRefresh={status === Status.FOLLOWINGS ? onRefetchFollowings : onRefetchFollowers}
          />
        }
      />
      <BottomSheetModal
        showConfirmBtn={false}
        showCancelBtn={false}
        onPressCancel={handleCloseBottomSheet}
        onPressConfirm={handleCloseBottomSheet}
        visible={isOpen}
        title="Options"
        options={options}
      />

      <ReportModal
        origin_group_id=""
        id={postId}
        reportee={reportee}
        isVisible={isReportModalOpen}
        onClose={hideReportModal}
        reportType={reportType}
      />
    </View>
  );
};

export default FollowInfoScreen;

const localStyles = StyleSheet.create({
  searchContent: {
    flexDirection: `row`,
    alignItems: `center`,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    margin: 15,
    borderRadius: 6,
    justifyContent: `space-between`,
  },
  textInput: {
    width: `90%`,
    paddingVertical: 5,
  },
  butttonContent: {
    flexDirection: `row`,
    justifyContent: `space-evenly`,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 0.2,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: `center`,
  },
  buttonStyle: {
    borderBottomColor: Colors.brandPurple,
    padding: 10,
  },
  buttonText: {
    fontSize: Typography.baseFontSize,
    fontFamily: `inter-semibold`,
    fontWeight: `500`,
    textAlign: `center`,
  },
  containerListFooter: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 10,
  },
  containerFlatlist: {
    flex: 1,
  },
});
