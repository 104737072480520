import { useState, useCallback, useContext } from 'react';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';
import { AppManifest } from 'expo-constants/build/Constants.types';
import * as Sentry from '../constants/Sentry';
import { compareVersions, getManifest, getReleaseChannel } from '../../environment';
import { client, VERSION_CHECK } from '../graphql';
import { AppContext } from '../../AppContext';

const NO_UPDATE = { appUpdate: false, jsUpdate: false };

export const useUpdateHandler = () => {
  const [appUpdate, setAppUpdate] = useState(false);
  const { versionInfo, setLatestVersionInfo } = useContext(AppContext);
  const { version, publishID, hash } = getVersionInfo();

  const checkForUpdate = useCallback(async () => {
    //if (__DEV__) return NO_UPDATE;
    if (Platform.OS === `web`)
      return {
        ...NO_UPDATE,
        versionInfo,
      };

    // Get our current publish version and manifest
    const [platform, platform_version] = [Platform.OS, `${Platform.Version}`]; // NB: version comes back as a number on Android
    const release_channel = getReleaseChannel() || `dev`;
    console.log(`checkForUpdate: version ${version}, release_channel ${release_channel}, publish ${publishID}`);

    // Ask the server about the latest version published for our app version.
    const { data } = await client.query({
      query: VERSION_CHECK,
      variables: { input: { platform, platform_version, release_channel, publish: publishID, hash } },
      fetchPolicy: `no-cache`,
    });
    const { latest_version, latest_publish, latest_hash } = data.versionCheck;

    // If we weren't on the latest publish, we should have updated by now (unless all downloads failed).
    // Double-check the hash as a sanity check. If we are doing everything right these should just match.
    // If we aren't, someone broke something in the update system.
    if (publishID === latest_publish && hash !== latest_hash) {
      // only complain if not dev build
      if (!__DEV__ || hash !== `dev`)
        console.warn(`checkForUpdate: WARNING: version and publish match, but hash ${hash} != latestHash ${latest_hash}`);
    }

    // Array comparison function
    const appUpdate = compareVersions(version, latest_version) < 0;
    const jsUpdate = !!(latest_publish && publishID < latest_publish); // force casted to bool for falsy values not interpreted by proptype

    // Set global state.
    if (
      versionInfo?.latestVersion !== latest_version &&
      versionInfo?.latestPublish !== latest_publish &&
      versionInfo?.latestHash !== latest_hash
    ) {
      setLatestVersionInfo({ latestVersion: latest_version, latestPublish: latest_publish, latestHash: latest_hash });
    }
    return { appUpdate, jsUpdate, versionInfo };
  }, [hash, publishID, version, versionInfo, setLatestVersionInfo]);

  const downloadUpdateAndRelaunch = useCallback(async () => {
    const { latestVersion, latestPublish } = versionInfo || {};
    if (Platform.OS === `web` || __DEV__) return;

    // What if the native code needs an update? eg 1.0.9 has been published and we are running 1.0.8.
    // FIXME: no implementation or user notification yet. some subtleties with TestFlight/GPlay beta vs release.
    if (appUpdate) {
      console.log(`downloadUpdateAndRelaunch: NOTE: new app version (have ${version}, latest ${latestVersion})`);
    }

    // Regardless of latestVersion, we also get back the latest publish for our release channel. If that's not
    // us, call expo-updates to fetch the latest JavaScript.
    console.log(`[UPDATES] ${publishID} < ${latestPublish} = ${latestPublish ? publishID < latestPublish : false}`);
    console.log(
      `[UPDATES] ${version} < ${latestVersion} === ${latestVersion ? compareVersions(version, latestVersion) < 0 : false}`,
    );

    const updateable = !!latestPublish && publishID < latestPublish;
    if (!updateable) console.warn(`downloadUpdateAndRelaunch: nothing to download (have ${publishID}, latest ${latestPublish})`);

    console.log(`downloadUpdateAndRelaunch: new publish for ${version} (have ${publishID}, latest ${latestPublish})`);

    /* eslint-disable no-await-in-loop */
    for (let retry = 0, max = 5; retry < max; retry += 1) {
      try {
        if (retry)
          await new Promise((resolve) => {
            setTimeout(resolve, 1000);
          }); // delay 1s
        console.log(`downloadUpdateAndRelaunch: download attempt ${retry + 1}/${max}`);
        const results = await Updates.fetchUpdateAsync();
        if (results.isNew) {
          const { releaseChannel: downloadedReleaseChannel, extra: downloadedExtra } = (results?.manifest as AppManifest) || {};
          const { publishID: downloadedPublishID } = downloadedExtra || {};
          console.log(`downloadUpdateAndRelaunch: ok! ${downloadedReleaseChannel} publishID ${downloadedPublishID}`);
          await Updates.reloadAsync(); // terminates and relaunches the app
        } else {
          console.warn(`downloadUpdateAndRelaunch: expo-update server claims there is no new update???`);
        }
      } catch (err) {
        console.warn(`downloadUpdateAndRelaunch: caught an exception from fetchUpdateAsync: ${err}`);
        if (retry === max - 1) {
          Sentry.captureMessage(
            `downloadUpdateAndRelaunch: (${publishID}->${latestPublish}) caught an exception from fetchUpdateAsync: ${err}`,
          );
        }
      }
    }
  }, [appUpdate, publishID, version, versionInfo]);

  const checkForAndDownloadUpdate = useCallback(async () => {
    // When updatable (ie, not __DEV__) we issue a graphql query to check what the latest version is.
    const { appUpdate, jsUpdate } = await checkForUpdate();
    setAppUpdate(!!appUpdate);
    if (jsUpdate) await downloadUpdateAndRelaunch();
  }, [checkForUpdate, downloadUpdateAndRelaunch]);

  return { appUpdate, checkForUpdate, downloadUpdateAndRelaunch, checkForAndDownloadUpdate };
};

export const getVersionInfo = () => {
  let { version, extra } = getManifest() || {}; // eslint-disable-line prefer-const
  let { publishID } = extra || {};
  let hash = `dev`;
  try {
    const version_js = require(`./version`); // version.js
    hash = version_js.hash;
  } catch (err) {} // eslint-disable-line no-empty

  if (!version) version = `dev`;
  if (!publishID) publishID = `dev`;
  return { version, publishID, hash };
};
