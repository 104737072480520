import React, { useState } from 'react';
import { Image, Platform, Text, TouchableOpacity, View, Dimensions, StyleSheet } from 'react-native';
import { ReactNativeFile } from 'apollo-upload-client';
import ImagePicker, { Image as PickedImage } from 'react-native-image-crop-picker';
import { useMutation } from 'react-apollo';
import { v4 as uuidv4 } from 'uuid';
import { addPersona } from '../auth/helpers';
import { Colors, Spacing } from '../common-styles';
import { MeshIcon, Avatar } from '../common-ui';
import { setAsyncStorageObject } from '../common-util';
import { refetchQueriesFor, UPDATE_PERSONA } from '../graphql';
import { UserHeader } from '../images';
import { AppContext } from '../../AppContext';
import useMediaPermissons from '../hooks/useMediaPermissions';
import { User, ReactNativeFileOptions, UpdateUserData, UpdateUserVars } from '../common-types/types';

const SPLASH_ASPECT_RATIO = 0.5189;

type ProfileSettingsHeaderProps = {
  user: User;
};

export function ProfileSettingsHeader({ user }: ProfileSettingsHeaderProps) {
  const { user: currentUser, setUser } = React.useContext(AppContext);
  const [avatar, setAvatar] = useState<ReactNativeFileOptions>();
  const [splash, setSplash] = useState<ReactNativeFileOptions>();
  const { checkPermission } = useMediaPermissons();
  const me = user?.id === currentUser?.id;

  const [updatePersona] = useMutation<UpdateUserData, UpdateUserVars>(UPDATE_PERSONA, {
    refetchQueries: refetchQueriesFor(`Persona`),
  });

  const updateImage = React.useCallback(
    async (variables) => {
      try {
        const { data, errors } = await updatePersona({ variables });
        if (errors?.length) throw new Error(errors[0].message);
        const result = data?.updatePersona;
        const newUser = {
          ...user,
          avatar_url: result?.avatar_url ?? user.avatar_url,
          splash_url: result?.splash_url ?? user.splash_url,
        };
        setUser(newUser);
        await setAsyncStorageObject(`currentUser`, newUser);
        await addPersona(newUser);
      } catch (error: any) {
        console.error(`error thrown in updateLocalUser()`, error.message);
      }
    },
    [setUser, user, updatePersona],
  );

  const pickAvatar = async () => {
    const valid = await checkPermission();
    if (!valid) return;

    try {
      const image: PickedImage = await ImagePicker.openPicker({
        cropperCircleOverlay: true,
        cropping: true,
        compressImageQuality: 0.9,
        sortOrder: `asc`,
        smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
      });
      const imageFile = new ReactNativeFile({
        //uri: image.path || image.localUri,
        uri: image.path,
        type: Platform.OS === `android` ? `image/jpeg` : image.mime,
        name: uuidv4(),
      });
      setAvatar(imageFile);
      const input = { avatar_image_file: imageFile };
      await updateImage({ input });
    } catch (error) {
      console.log(`Error: picking image cancelled`);
    }
  };

  const pickSplash = async () => {
    const valid = await checkPermission();
    if (!valid) return;

    try {
      const image: PickedImage = await ImagePicker.openPicker({
        cropperCircleOverlay: false,
        cropping: false,
        compressImageQuality: 0.9,
        sortOrder: `asc`,
        smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
      });

      const imageFile = new ReactNativeFile({
        //uri: image.path || image.localUri,
        uri: image.path,
        type: Platform.OS === `android` ? `image/jpeg` : image.mime,
        name: uuidv4(),
      });
      setSplash(imageFile);
      const input = { splash_image_file: imageFile };
      await updateImage({ input });
    } catch (error) {
      console.log(`Error: picking image cancelled`);
    }
  };

  const renderSplash = (user: User) => {
    const has_splash = splash || !!user?.splash_url;
    const splashImage =
      splash || (user?.splash_url && { uri: user?.splash_url }) || require(`../../assets/images/UserHeader.png`);
    const size = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width - 16 * 2);
    return (
      <TouchableOpacity
        style={[localStyles.splashImageContainer, { width: size, height: size * SPLASH_ASPECT_RATIO }]}
        disabled={!me}
        onPress={pickSplash}
        activeOpacity={0.8}
        testID="PROFILESETTINGS_SPLASHIMAGE"
      >
        <View style={localStyles.splashSpacer} />
        {has_splash ? (
          <Image source={splashImage} style={localStyles.splashImage} resizeMode="cover" />
        ) : (
          <View style={{ zIndex: 0 }}>
            <UserHeader height={232} width={343} />
          </View>
        )}
        <View style={localStyles.photoTouchableOpacityContainer} hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}>
          <View style={localStyles.photoEditContainer}>
            <MeshIcon name="camera" focused color="white" size={24} />
            <Text style={{ marginLeft: 5, color: `white` }}>Edit</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderAvatar = (user: User) => {
    const has_avatar = avatar || !!user?.avatar_url;

    const avatarImage = has_avatar ? avatar || { uri: user.avatar_url } : require(`../../assets/images/user_avatar.png`);
    return (
      <TouchableOpacity
        style={localStyles.avatarContainer}
        onPress={pickAvatar}
        activeOpacity={0.9}
        testID="PROFILESETTINGS_AVATARIMAGE"
      >
        {has_avatar ? <Image source={avatarImage} style={localStyles.avatar} /> : <Avatar user={user} size={96} />}
        <View style={localStyles.imageSpacer}>
          <View
            style={{
              alignItems: `center`,
              flexDirection: `row`,
              justifyContent: `center`,
              backgroundColor: Colors.translucentBlack,
              marginHorizontal: 18,
              paddingVertical: 2,
            }}
          >
            <MeshIcon name="camera" focused color="white" size={20} />
            <Text style={{ marginLeft: 4, color: `white` }}>Edit</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <View style={localStyles.container}>
      <View style={{ padding: 16, marginBottom: 25 }}>
        {renderSplash(user)}
        {renderAvatar(user)}
      </View>
    </View>
  );
}

const localStyles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
  },
  splashSpacer: {
    flex: 1,
    position: `absolute`,
    zIndex: 1,
    height: `100%`,
    width: `100%`,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  imageSpacer: {
    zIndex: 4,
    position: `absolute`,
    alignSelf: `center`,
    flex: 1,
    justifyContent: `center`,
    borderRadius: 999,
    height: `100%`,
    width: `100%`,
  },
  splashImageContainer: {
    marginTop: 10,
    borderRadius: 7,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: `hidden`,
    backgroundColor: Colors.white,
    alignSelf: `center`,
  },
  splashImage: {
    flex: 1,
    zIndex: 0,
    //height: null,
    //width: null,
  },
  avatarContainer: {
    alignSelf: `center`,
    position: `absolute`,
    bottom: -25,
    zIndex: 2,
    flex: 1,
  },
  avatar: {
    height: 96,
    width: 96,
    borderRadius: 99,
    borderColor: Colors.white,
    borderWidth: 1,
  },
  photoTouchableOpacityContainer: {
    position: `absolute`,
    right: 8,
    bottom: 8,
    zIndex: 2,
  },
  photoEditContainer: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    marginLeft: Dimensions.get(`window`).width / 1.35,
    marginTop: Dimensions.get(`window`).height / 6.5,
    backgroundColor: Colors.translucentBlack,
    padding: 5,
    borderRadius: 5,
  },
});
