import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

export const MeshStackRGB = (props) => {
  return (
    <Svg width="480px" height="345px" viewBox="0 0 480 345" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M177.38 143.346c-2.16-34-7.72-69.16-1.5-102.7 1.87-10.11 1.85-20.05 8.09-26.5-8.44-11.07-20.5-15.83-33.21-9.54-10.95 3.93-18.47 10-24.13 17.91a77.84 77.84 0 011.55 10.61 940.72 940.72 0 013.67 126.29c-.32 8.62-1.05 17.83-4 25.79 4.29 8.25 10.22 15.54 18.76 18.7 14.49 5.36 31.14-3.9 39.18-17.08.41-.68.79-1.36 1.17-2.05a52.62 52.62 0 01-3.85-8c-4-10.59-5-22.07-5.71-33.38"
          fill="#4FCEDD"
          transform="translate(80 .004)"
        />
        <Path
          d="M266.76 73.606c-2.97-18.93-12.58-36.48-25.58-50.48-6.81-7.36-14.82-14-24.37-17.07-9.55-3.07-20.89-1.95-28.36 4.75a18.69 18.69 0 00-4.44 3.38c.4.53.81 1 1.19 1.61 8.48 12.21 10.45 27.69 11.46 42.51 1.9 27.91 1.27 55.94-.42 83.85-.88 14.63-2.25 29.84-9.26 42.62 4.29 7.29 10.42 13.47 18.19 16.63 16.62 6.76 36.46-2.34 47-16.86s13.61-33.1 14.81-51c.71-10.67.86-21.37 1-32.05.13-9.31.26-18.66-1.19-27.85"
          fill="#F2E35A"
          transform="translate(80 .004)"
        />
        <Path
          d="M196.62 58.266c-1-14.83-3-30.3-11.46-42.51-.39-.56-.79-1.08-1.19-1.61-6.24 6.44-6.22 16.39-8.1 26.5-6.21 33.54-.65 68.66 1.51 102.7.72 11.31 1.69 22.79 5.71 33.38a52.62 52.62 0 003.85 8c7-12.78 8.38-28 9.26-42.62 1.7-27.91 2.32-55.94.42-83.85"
          fill="#2C9A44"
          transform="translate(80 .004)"
        />
        <Path
          d="M110.92 110.126c-1.64-21.33-1.93-43.22 4.58-63.6 3-9.3 6.4-17.31 11.14-24a31.65 31.65 0 00-1.73-5.28C119.86 5.556 106.25-1.234 93.6.186a37.77 37.77 0 00-21.74 10.68l.24-.31c-14.15 12.38-17.6 32.77-19 51.52a463.84 463.84 0 00.46 76.11c1.63 18.41 4.81 37.78 16.7 51.93 11.89 14.15 35.25 20.13 49.1 7.89a31.56 31.56 0 008.47-12.88 86.69 86.69 0 01-4.11-9.29c-7.9-21-11.09-43.43-12.8-65.78"
          fill="#FD5793"
          transform="translate(80 .004)"
        />
        <Path
          d="M128.18 33.126a79.42 79.42 0 00-1.55-10.57c-4.75 6.67-8.16 14.68-11.14 24-6.51 20.37-6.22 42.27-4.58 63.6 1.71 22.35 4.9 44.81 12.8 65.79a87.43 87.43 0 004.11 9.28c3-8 3.71-17.17 4-25.79a940.72 940.72 0 00-3.64-126.31"
          fill="#333895"
          transform="translate(80 .004)"
        />
        <Path
          d="M0 275.346h18.81v7.55a24.58 24.58 0 0119.89-9.72c14 0 19.43 8.8 21.12 12.19 3.24-5.25 9.87-12.19 21.43-12.19 10.34 0 15.42 4.78 18.05 8.18 4.32 5.71 4.62 11.41 4.62 18.81v41.96H85.11v-36.41c0-7.86-1.7-11.41-3.08-13.1a9.45 9.45 0 00-7.71-3.4 11 11 0 00-10.33 5.91c-2.47 4-2.62 9.41-2.62 12.95v34.05H42.55v-36.41c0-7.86-1.69-11.41-3.08-13.1a9.45 9.45 0 00-7.71-3.4 11 11 0 00-10.33 5.91c-2.47 4-2.62 9.41-2.62 12.95v34.05H0v-66.78zM184.24 323.446c-2 4.94-9.55 20.82-32.83 20.82-10.8 0-19-3.08-25.61-9.41-7.4-6.94-10.48-15.57-10.48-26.05 0-13.26 5.39-21.44 10.18-26.22 7.86-7.71 17.11-9.4 25.13-9.4 13.57 0 21.43 5.39 26.06 10.94 7.09 8.49 8 19 8 26.22v1.54h-50c0 4 1.08 8.32 3.24 11.25 2 2.78 6.16 6.33 13.42 6.33a17.2 17.2 0 0015.56-8.79l17.33 2.77zm-17.27-23.59a16 16 0 00-31.6 0h31.6zM232.5 291.226a15.6 15.6 0 00-10.95-4.47c-4.47 0-7.25 2.15-7.25 5.39 0 1.7.77 4.32 6 6l4.47 1.39c5.25 1.7 13.11 4.32 17 9.56a19.41 19.41 0 013.39 11.1 22.2 22.2 0 01-7.4 17c-5.4 4.93-11.87 7.09-20.05 7.09-13.88 0-21.74-6.63-25.9-11.1l9.87-11.42c3.7 4.32 9.25 7.72 14.8 7.72 5.25 0 9.25-2.62 9.25-7.25 0-4.16-3.39-5.86-5.86-6.78l-4.32-1.54c-4.78-1.71-10.33-3.86-14.33-8a17.91 17.91 0 01-5.09-12.8 19.87 19.87 0 016.94-15.12c5.23-4.16 12-4.78 17.42-4.78a33.3 33.3 0 0121.28 7.1l-9.27 10.91zM255.31 229.706h18.81v54.27c3.55-4.93 9.87-10.8 20.66-10.8 5.86 0 14.5 1.7 19.74 8.18 4.63 5.71 5.25 12.33 5.25 18.81v41.96h-18.82v-36.41c0-3.7-.15-9.56-3.54-13.11a12.06 12.06 0 00-8.79-3.39c-4.94 0-8.79 1.7-11.57 5.86-2.77 4.32-2.93 9.1-2.93 12.95v34.1h-18.81v-112.42z"
          fill="#373232"
          transform="translate(80 .004)"
        />
      </G>
    </Svg>
  );
};
