import 'react-native-get-random-values';
import { StreamChat } from 'stream-chat';
//TODO: test import { StreamChat } from 'stream-chat-react-native-core';
import { DefaultStreamChatGenerics } from 'stream-chat-react-native';
import { getEnvVariables } from '../../environment';
import { User } from '../common-types/types';

//defines type for chatClient.state.member.user: User
export type MeshChatGenerics = DefaultStreamChatGenerics & { userType: { user: User } };

const { streamApiKey } = getEnvVariables();

const chatClient: StreamChat<MeshChatGenerics> = StreamChat.getInstance(streamApiKey);

export { chatClient };
