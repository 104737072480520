export type AvailableCategory = keyof typeof AVAILABLE_CATEGORIES;
export const AVAILABLE_CATEGORIES = {
  activism: `Activism`,
  animals: `Animals`,
  architecture_and_design: `Architecture and Design`,
  arts_and_crafts: `Arts and Crafts`,
  arts_and_entertainment: `Arts and Entertainment`,
  beauty_and_fashion: `Beauty and Fashion`,
  business: `Business`,
  career_advice: `Career Advice`,
  current_events: `Current Events`,
  culture: `Culture`,
  education: `Education`,
  finance: `Finance`,
  fitness: `Fitness`,
  food_and_drink: `Food and Drink`,
  fun: `Just for Fun`,
  gaming: `Gaming`,
  general: `General`,
  health_and_wellness: `Health and Wellness`,
  hobbies: `Hobbies`,
  home_and_gardening: `Home and Gardening`,
  humor: `Humor`,
  'lgbtq+': `LGBTQ+`,
  lifestyle: `Lifestyle`,
  literature: `Literature`,
  money: `Money`,
  motivation_and_inspiration: `Motivation and Inspiration`,
  music: `Music`,
  nature: `Nature`,
  networking: `Networking`,
  parenting: `Parenting`,
  photography: `Photography`,
  politics: `Politics`,
  relationships_and_dating: `Relationships and Dating`,
  religion: `Religion and Spirituality`,
  science: `Science`,
  sports: `Sports`,
  sustainability: `Sustainability`,
  technology: `Technology`,
  travel: `Travel`,
  vehicles: `Vehicles`,
  women: `Women`,
};
