import { Dimensions, Platform } from 'react-native';
import { Colors, Spacing } from '../common-styles';
import { getBackgrounds } from '../common-ui/Blobs';

//TODO: bring and merge getTextStyleForPost from ./helpers.ts
export const getStyleConfig = (styling, width, overflowMode, meta_style) => {
  let { height, margin, numberOfLines } = styling;
  const { style } = styling;
  if (meta_style) {
    //case for new posts that have the meta_style data
    const { fontSize, lineHeight, align } = meta_style;
    margin = width * (16 / 375);
    const windowWidth = width - 2 * margin;
    // make sure font size cannot be smaller than minimum font size or bigger than max fontSize
    // scale fontSize between 72 and 14
    style.fontSize = (windowWidth * Math.min(72, Math.max(14, fontSize / 1000))) / 375;
    // scale lineHeight between 90 and 17
    style.lineHeight = (windowWidth * Math.min(90, Math.max(17, lineHeight / 1000))) / 375;
    style.textAlign = align;
    // style.fontFamily = align === `center` ? `inter-semibold` : `inter-regular`;
    height = width;

    if (overflowMode === `fit`) numberOfLines = Math.floor((height - 2 * margin) / style.lineHeight);
  }
  return { style, height, margin, numberOfLines };
};

export const getBackgroundConfig = (meta_style) => {
  // constraint blobIndex to be within 0 - 10, default 10: white background
  const { blobIndex = 10 } = meta_style || {};
  const backgroundIndex = Math.min(10, Math.max(0, blobIndex));
  const isBlob = blobIndex === 0 || blobIndex < 6;
  const backgrounds = getBackgrounds();
  // if no backgroundIndex(More text post) defaults to white
  const backgroundColor = backgroundIndex === null || isBlob ? Colors.white : backgrounds[backgroundIndex];

  return {
    isBlob,
    backgroundIndex,
    backgroundColor,
  };
};

export const getTextStyleConfig = (styling, style, overflowMode, isBlob) => {
  let { allowFontScaling = true } = styling;
  let color = Colors.black;
  let fontFamily = `inter-regular`;
  if (!isBlob) {
    color = Colors.white;
    fontFamily = `inter-semibold`;
  }
  if (overflowMode === `fit`) allowFontScaling = false;

  return {
    color,
    fontFamily,
    allowFontScaling,
  };
};

//
// Naming conventions
//
// Components used to build styles
//  -Margins: may only contain marginLeft, marginRight, marginTop, marginBottom
//  -Font: may only contain fontFamily, fontSize, lineHeight, letterSpacing, color
//  -Color: may only contain color, backgroundColor
//
// Styles meant to be directly applied to components
//  -Text: recommended to only contain same props as -Font, but may also contain -Margins
//

// Margins
export const cardLeftRightMargins = {
  marginLeft: 15,
  marginRight: 15,
};

// Fonts
const cardTitleFont = {
  color: Colors.textBlack,
  fontFamily: `inter-regular`,
  fontSize: 14,
  lineHeight: 19,
};
const cardTitleUserFont = {
  color: Colors.textBlack,
  fontFamily: `inter-semibold`,
  fontSize: 14,
  lineHeight: 19,
};
const cardTitleGroupFont = {
  color: Colors.textBlack,
  fontFamily: `inter-semibold`,
  fontSize: 14,
  lineHeight: 19,
};

const postHeaderFont = {
  color: Colors.textBlack,
  fontFamily: `inter-regular`,
  fontSize: 12,
  lineHeight: 20,
};
const postHeaderBoldFont = {
  color: Colors.textBlack,
  fontFamily: `inter-semibold`,
  fontSize: 12,
  letterSpacing: -0.13,
  lineHeight: 20,
};

// Text styles
export const postHeaderText = { ...postHeaderFont };
export const postHeaderUserText = { ...postHeaderBoldFont };
export const postHeaderGroupText = { ...postHeaderBoldFont };
export const postHeaderTimestampText = { ...postHeaderFont, color: Colors.textGray };

export const postReactionsText = {
  fontFamily: `inter-semibold`,
  fontSize: 12,
  lineHeight: 15,
  color: Colors.iconColor,
};
export const postLikesText = {
  fontFamily: `inter-semibold`,
  fontSize: 12,
  lineHeight: 20,
  color: Colors.textBlack,
};

export const warningContainer = {
  alignItems: `center`,
  borderRadius: 5,
  margin: 16,
  backgroundColor: Colors.warningBackground,
  padding: 8,
};
export const warningText = {
  fontFamily: `inter-semibold`,
  fontSize: 12,
  lineHeight: 15,
  letterSpacing: -0.14,
  color: Colors.warningText,
};

export const postCommentHeaderText = {
  fontFamily: `inter-regular`,
  fontSize: 12,
  lineHeight: 18,
  letterSpacing: -0.14,
  color: Colors.textGray,
};
export const postCommentUserHandleText = {
  ...postCommentHeaderText,
  fontFamily: `inter-semibold`,
  color: Colors.textBlack,
};
export const postCommentTimestampText = {
  ...postCommentHeaderText,
  color: Colors.textGray,
};
export const postCommentBodyText = {
  fontFamily: `inter-regular`,
  fontSize: 15,
  lineHeight: 18,
  letterSpacing: -0.5,
  color: Colors.textBlack,
  marginVertical: 8,
  marginHorizontal: 10,
};
export const postCommentReplyButtonText = {
  fontFamily: `inter-semibold`,
  fontSize: 12,
  lineHeight: 15,
  letterSpacing: -0.2,
  color: Colors.iconColor,
};
export const postReplyingToText = {
  fontFamily: `inter-regular`,
  color: Colors.textBlack,
  fontSize: 12,
  lineHeight: 15,
  letterSpacing: -0.14,
};
export const postReplyingToUserHandleText = {
  ...postReplyingToText,
  fontFamily: `inter-semibold`,
};
export const postReplyingToCancelButtonText = {
  fontFamily: `inter-semibold`,
  color: Colors.iconColor,
  fontSize: 12,
  lineHeight: 15,
};

export const postModalHeaderText = {
  fontFamily: `inter-semibold`,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.14,
  color: Colors.textBlack,
  marginTop: 15,
  marginBottom: 13,
};

export const postModalBodyText = {
  fontFamily: `inter-regular`,
  fontSize: 14,
  lineHeight: 22,
  letterSpacing: -0.14,
  color: Colors.textBlack,
  marginHorizontal: 16,
  marginVertical: 24,
};

export const postModalButtonText = {
  fontFamily: `inter-semibold`,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: -0.14,
  color: Colors.white,
};
export const postModalButton = {
  paddingVertical: 12,
};
export const postModalButtonContainer = {
  marginHorizontal: 16,
  marginTop: 16,
  marginBottom: 48,
};

export const postStyles = {
  card: {
    width: `100%`,
    marginLeft: Platform.OS === `web` ? `0 !important` : 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 8,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
  },

  cardAvatarContainer: {
    marginRight: 12,
    marginLeft: 0,
  },
  cardAvatar: {},
  cardTitleHeadlineContainer: {
    marginBottom: 2,
    flexShrink: 1,
    flex: 1,
  },
  cardTitleHeadlineOtherText: {},
  cardTitleUserName: { ...cardTitleUserFont },
  cardTitleGroupName: { ...cardTitleGroupFont },
  cardTitleSubhead: {},
  cardTitleSubheadText: {
    ...cardTitleFont,
    fontSize: 12,
    color: `#666`,
  },
  cardTitleSubheadDateText: {},

  cardTitle: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    backgroundColor: Colors.white,
    ...cardLeftRightMargins,
  },
  imageContainer: {
    flexDirection: `row`,
    justifyContent: `center`,
    width: `100%`,
    marginTop: 0,
  },
  lightboxContainer: {
    flex: 1,
    width: `100%`,
    flexDirection: `column`,
    alignContent: `center`,
    justifyContent: `center`,
  },
  lightboxCloseButton: {
    color: Colors.white,
    borderWidth: 1,
    borderColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1.0,
    shadowRadius: 1.5,
    padding: 8,
    borderRadius: 3,
    textAlign: `center`,
    margin: 12,
    alignSelf: `flex-end`,
  },
  lightboxCaption: {
    color: Colors.white,
    fontSize: 16,
    padding: 15,
  },
  imageInLightBox: {
    width: Dimensions.get(`window`).width,
    maxWidth: `100%`,
    minWidth: Spacing.standardWidth,
    alignSelf: `center`,
  },
  imageInPost: {
    alignSelf: `center`,
    width: Dimensions.get(`window`).width,
    maxWidth: `100%`,
    minWidth: Spacing.standardWidth,
  },
  reactionButtonContainer: {
    flex: 1,
    flexDirection: `row`,
    justifyContent: `space-around`,
    marginTop: 8,
    ...cardLeftRightMargins,
  },
  linkText: {
    color: Colors.linkColor,
  },
};
