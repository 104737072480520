const TYPES = [
  {
    id: 0,
    name: `Short answer`,
    iconName: ``,
  },
  {
    id: 1,
    name: `Paragraph`,
    iconName: ``,
  },
  {
    id: 2,
    name: `Date`,
    iconName: ``,
  },
  {
    id: 3,
    name: `Time`,
    iconName: ``,
  },
  {
    id: 4,
    name: `Multiple choice`,
    iconName: `radio-on`,
  },
  {
    id: 5,
    name: `Checkboxes`,
    iconName: `check-box-selected`,
  },
  {
    id: 6,
    name: `Written answer`,
    iconName: `list-alt`,
  },
];

const questionTypes = {
  shortAnswer: TYPES[0],
  paragraph: TYPES[1],
  date: TYPES[2],
  time: TYPES[3],
  multiple_choice: TYPES[4],
  checkbox: TYPES[5],
  writtenAnswer: TYPES[6],
};

export { TYPES, questionTypes };
