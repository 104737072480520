import React, { useState, useCallback, useEffect } from 'react';
import { Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import ImagePicker from 'react-native-image-crop-picker';
import { useSelector, useDispatch } from 'react-redux';
import { ReactNativeFile } from 'apollo-upload-client';
import { v4 as uuidv4 } from 'uuid';
import { WizardLayout } from '../community-create/WizardLayout';
import { setProfileImage, moveNext, finishWizard, setProfileIdentity } from '../redux/createProfileSlice';
import { Avatar, MeshIcon, ThemedButton, BackButton, VisibleTag } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { commonPropTypes } from '../common-util';
import { AvatarTestIds } from './testLabels';
import useMediaPermissons from '../hooks/useMediaPermissions';
//import { AppContext } from '../../AppContext';

export function ProfileAvatar({ navigation, route }) {
  //const { user } = React.useContext(AppContext);
  const { profileImage } = useSelector((state) => state.createProfile);
  const [avatarFile, setAvatarFile] = useState(profileImage);
  const dispatch = useDispatch();
  const { checkPermission } = useMediaPermissons();

  const onSubmit = (skip = false) => {
    if (!skip) dispatch(setProfileImage(avatarFile));
    else dispatch(moveNext());
    navigation.push(`ProfileName`);
  };
  const pickImage = async () => {
    const isValid = checkPermission();
    if (!isValid) return;

    const image = await ImagePicker.openPicker({
      cropperCircleOverlay: true,
      cropping: true,
      compressImageQuality: 0.95,
      sortOrder: `asc`,
      smartAlbums: [`UserLibrary`, `PhotoStream`, `Panoramas`, `Bursts`, `Favorites`, `RecentlyAdded`, `Screenshots`],
    });

    const heic = image.filename && image.filename.toLowerCase().endsWith(`.heic`);
    if (heic) image.filename = `${image.filename.split(`.`)[0]}.JPG`;

    setAvatarFile(
      new ReactNativeFile({
        uri: image.path || image.localUri,
        type: Platform.select({ android: `image/jpeg`, default: image.mime }),
        name: (image.filename && image.filename.split(`.`)[0]) || uuidv4(),
      }),
    );
  };

  const goBack = useCallback(() => {
    dispatch(finishWizard());
    navigation.popToTop();
  }, [dispatch, navigation]);

  useEffect(() => {
    //dispatch();
    navigation.setOptions({
      headerLeft: () => <BackButton onPress={goBack} />,
    });
    const identity = route.params?.identity_id;
    console.log(`ProfileAvatar -> identity`, identity);
    if (!identity) console.warn(`no identity passed ${identity}`);
    dispatch(setProfileIdentity(identity));
  }, [navigation, route, goBack, dispatch]);
  return (
    <WizardLayout total={6} selector="createProfile">
      <ScrollView keyboardShouldPersistTaps="handled">
        <View style={localStyles.innerContainer} testID={AvatarTestIds.screen}>
          <View style={{ marginTop: 80 }}>
            <Text style={{ ...Typography.text(`large`, `bold`, `black`) }}>
              Let's build your profile. Want to add a profile pic?
            </Text>
          </View>
          <View style={{ alignItems: `center` }}>
            <TouchableOpacity activeOpacity={0.8} style={{ marginTop: 24, marginBottom: 32 }} onPress={pickImage}>
              <Avatar localImage={avatarFile && avatarFile.uri} size={97} border disabled />
              <View style={localStyles.cameraIconContainer}>
                <MeshIcon name="camera" color={Colors.white} focused size={24} />
              </View>
            </TouchableOpacity>
          </View>
          <VisibleTag infoText="Your profile picture is visible on your profile." />
          <View style={{ marginTop: 32 }}>
            <ThemedButton
              rounded
              testID={AvatarTestIds.continue}
              title="Continue"
              onPress={onSubmit}
              buttonStyle={{ padding: 15 }}
              containerStyle={{}}
              titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`) }}
              disabled={!avatarFile}
            />
            <ThemedButton
              rounded
              clear
              testID={AvatarTestIds.skip}
              title="Skip for now"
              containerStyle={{ marginHorizontal: 16, marginTop: 24 }}
              onPress={() => onSubmit(true)}
              titleStyle={{ ...Typography.text(`base`, `theme`, `bold`) }}
            />
          </View>
        </View>
      </ScrollView>
    </WizardLayout>
  );
}
ProfileAvatar.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const localStyles = {
  innerContainer: {
    padding: 16,
    justifyContent: `center`,
  },
  cameraIconContainer: {
    padding: 8,
    height: 40,
    width: 40,
    borderRadius: 99,
    position: `absolute`,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.brandPurple,
    alignItems: `center`,
    justifyContent: `center`,
    alignContent: `center`,
  },
};
