import { Pressable, PressableProps } from 'react-native';
import React, { useState } from 'react';

type DoubleTouchableProps = PressableProps & {
  onDoublePress?: (e: any) => void;
  onDoublePressIn?: (e: any) => void;
  onDoublePressOut?: (e: any) => void;
  onSinglePress?: (e: any) => void;
  onSinglePressIn?: (e: any) => void;
  onSinglePressOut?: (e: any) => void;
  delayBeforeAction: number;
};

export const DoubleTouchable: React.FC<DoubleTouchableProps> = ({
  onDoublePress = () => {},
  onDoublePressIn = () => {},
  onDoublePressOut = () => {},
  onSinglePress = () => {},
  onSinglePressIn = () => {},
  onSinglePressOut = () => {},
  delayBeforeAction = 200,
  children,
  ...props
}) => {
  const [pressTimeout, setPressTimeout] = useState<any>();
  const [pressCount, setPressCount] = useState(0);
  const dispatch = (e: any) => {
    setPressTimeout(null);
    if (pressCount === 2 && onDoublePress) onDoublePress(e);
    else if (pressCount === 1 && onSinglePress) onSinglePress(e);
  };
  return (
    <Pressable
      {...props}
      onPressIn={(e) => {
        if (!pressTimeout) {
          setPressCount(1);
          if (pressCount + 1 === 1 && onSinglePressIn) onSinglePressIn(e);
        } else {
          setPressCount(pressCount + 1);
          clearTimeout(pressTimeout);
          setPressTimeout(null);
          if (pressCount + 1 === 2 && onDoublePressIn) onDoublePressIn(e);
        }
      }}
      onPressOut={(e) => {
        setPressTimeout(setTimeout(() => dispatch(e), delayBeforeAction));
        if (pressCount === 2 && onDoublePressOut) onDoublePressOut(e);
        else if (pressCount === 1 && onSinglePressOut) onSinglePressOut(e);
      }}
    >
      {children}
    </Pressable>
  );
};
