import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../common-styles';
import { MeshIcon } from '../../common-ui';

interface AddMediaButtonProps {
  onPress: () => void;
}

export const AddMediaButton: React.FC<AddMediaButtonProps> = ({ onPress = () => {} }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress} activeOpacity={0.8}>
      <View>
        <MeshIcon name="circle-plus" size={40} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 64,
    height: 64,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    borderColor: Colors.iconColor,
    borderWidth: 1,
    borderRadius: 8,
    borderStyle: `dashed`,
  },
});
