import React, { useEffect, useCallback } from 'react';
import { View, FlatList, TouchableWithoutFeedback, StyleSheet, TouchableOpacity } from 'react-native';
import FastImage from 'react-native-fast-image';
import { Divider, MeshIcon } from '../../common-ui';
import { Colors } from '../../common-styles';
import { AddMediaButton } from './AddMediaButton';
import { MAX_IMAGES_PER_POST } from '../../../constants';

type PostImageSelectorType = {
  imageUrls?: string[];
  imagesToUpload?: any[];
  onLayout?: () => void;
  onPress?: () => void;
  addPhoto?: () => void;
  removePhoto: (index: number) => void;
};

export const PostImageSelector: React.FC<PostImageSelectorType> = React.memo(
  ({
    imageUrls: existing = [],
    imagesToUpload = [],
    onLayout,
    onPress = () => {},
    addPhoto = () => {},
    removePhoto = () => {},
  }) => {
    const appending = imagesToUpload.map((rnf) => rnf.uri || rnf);
    const imageUrls = [...existing, ...appending];
    const imageUrlLinks = imageUrls?.map((image) => {
      // normalize local and server data
      return { url: image.url || image.uri || image };
    });

    const isMounted: any = React.useRef(null);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    // FlatList callbacks
    const keyExtractor = useCallback((image) => image.url, []);

    const renderImage = useCallback(
      ({ item: imageSource, index }) => {
        return (
          <View style={{ paddingVertical: 16, paddingHorizontal: 10, flexDirection: `row` }}>
            <TouchableWithoutFeedback key={index} onPress={onPress} style={{}}>
              <FastImage source={{ uri: imageSource.url }} resizeMode="cover" style={styles.img} />
            </TouchableWithoutFeedback>
            <View style={{ position: `relative` }}>
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={() => removePhoto(index)}
                style={styles.closeIconContainer}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              >
                <MeshIcon name="circle-x" size={20} color={Colors.brandPurple} />
              </TouchableOpacity>
            </View>
          </View>
        );
      },
      [onPress, removePhoto],
    );

    return (
      <View onLayout={onLayout}>
        <FlatList
          contentContainerStyle={{ flexDirection: `row`, alignItems: `center` }}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          initialNumToRender={2}
          maxToRenderPerBatch={3}
          data={imageUrlLinks}
          keyExtractor={keyExtractor}
          renderItem={renderImage}
          ListFooterComponent={
            imageUrlLinks?.length < MAX_IMAGES_PER_POST ? (
              <View style={{ margin: 16 }}>
                <AddMediaButton onPress={addPhoto} />
              </View>
            ) : undefined
          }
        />
        <Divider />
      </View>
    );
  },
);

const styles = StyleSheet.create({
  img: {
    width: 64,
    height: 64,
    marginLeft: 5,
    borderRadius: 8,
  },
  closeIconContainer: {
    position: `absolute`,
    top: -10,
    right: -10,
    backgroundColor: Colors.white,
    borderRadius: 100,
  },
});
