import { useQuery } from 'react-apollo';
import { Image, Text, View, Dimensions, TouchableOpacity, StyleSheet } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import ImageViewer from 'react-native-image-zoom-viewer';
import Modal from 'react-native-modal';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Autolink from 'react-native-autolink';
import Icon from 'react-native-vector-icons/Feather';
import { formatDate } from '../common-util';
import { Colors, Spacing, Typography } from '../common-styles';
import { Avatar, MeshIcon, BottomSheetModal, Button } from '../common-ui';
import { GET_FAVORITE_COMMUNITIES, GET_PROFILE_INVITE, SETTING_QUERY } from '../graphql';
import { CommunityHeader, MeshVerifiedCheck, UserHeader } from '../images';
import { getEnvVariables } from '../../environment';
import { handleCreateConversation } from '../community/memberInfoHelpers';
import { AppContext } from '../../AppContext';
import { useFollow } from '../community/v2/hooks/useFollow';
import { refreshCounts, refreshFollowInfo } from '../redux/followSlice';
import { GroupAndGroupMember, Setting, User, FollowRequest } from '../common-types/types';
import { setActiveCommunity, setPreviewCommunity } from '../redux/feedSlice';
import { LeftDrawerNavigator, ProfileScreensParamList } from '../common-types/navigation-types';
import { fixMeshInvitelLink } from '../post/helpers';
import InviteModal from './InviteModal';

const statusValues = { IDLE: `IDLE`, CREATING_CONVERSATION: `CREATING_CONVERSATION` };

interface UserProps {
  user: User;
}

interface ProfileHeaderProps extends UserProps {}

type ProfileNavigation = StackNavigationProp<ProfileScreensParamList, `Profile`>;

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({ user }) => {
  const { follow_request } = user;
  const navigation = useNavigation<ProfileNavigation>();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(statusValues.IDLE);
  const { user: me, chatNotifications, setChatNotifications, setChannel } = useContext(AppContext);
  const isCurrentUser = me?.id === user?.id;
  const [favoriteCommunities, setFavoriteCommunities] = useState<GroupAndGroupMember[]>([]);

  const userAndGroup = {
    user_id: me?.id,
    group_id: undefined,
  };

  const { data } = useQuery(SETTING_QUERY, {
    fetchPolicy: `cache-and-network`,
    variables: {
      settingQuery: {
        query: `user`,
        ...userAndGroup,
      },
    },
  });

  const { followUser, unfollowUser } = useFollow();

  const { data: favoriteCommunitiesData } = useQuery(GET_FAVORITE_COMMUNITIES, {
    fetchPolicy: `cache-and-network`,
    variables: {
      filter: {
        persona_id: isCurrentUser ? me?.id : user?.id,
      },
    },
    skip: isCurrentUser ? !me?.id : !user?.id,
  });

  useEffect(() => {
    setFavoriteCommunities(favoriteCommunitiesData?.getFavoriteGroup2?.data);
  }, [favoriteCommunitiesData]);

  const showBirthdateSetting = data?.getSettings?.find((elem: { key: string }) => elem.key === `user.setting.show_birthday`);

  const handleGoToChat = useCallback(async () => {
    try {
      if (!user || status !== statusValues.IDLE) {
        return;
      }
      await handleCreateConversation({ me, chatNotifications, setChatNotifications, setChannel }, user, navigation);
    } catch (error: any) {
      setStatus(statusValues.IDLE);
      console.error(`Error thrown in handleCreateConversation() in ProfileScreen.tsx`, error.message);
    }
  }, [user, me, status, chatNotifications, setChatNotifications, navigation]);

  const handleFollow = useCallback(
    async (is_not_followed: boolean) => {
      try {
        if (!user?.id) throw new Error(`No user to follow/unfollow`);
        if (is_not_followed) await followUser(user.id);
        else {
          await unfollowUser(user.id);
        }
        dispatch(refreshCounts(true));
        dispatch(refreshFollowInfo(true));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, followUser, unfollowUser, user],
  );

  const onSelectCommunity = (comm: GroupAndGroupMember) => {
    if (comm.group_member === null || comm?.group.application_pending) navigateToPreview(comm.group);
    else handleGoToCommunity(comm);
  };

  const handleGoToCommunity = useCallback(
    async (selectedGroup) => {
      if (user?.id && selectedGroup?.group.id) {
        await dispatch(setActiveCommunity({ user_id: user?.id, group: selectedGroup?.group }));
        const parentNav = navigation.getParent<LeftDrawerNavigator>();
        parentNav.jumpTo(`RightDrawer`, {
          screen: `MainTabs`,
          // @ts-ignore - issues with types in nested navigation events
          params: {
            screen: `HomeNavigator`,
            params: { screen: `HomeScreen` },
          },
        });
      }
    },
    [navigation, dispatch, user],
  );

  const navigateToPreview = useCallback(
    (group) => {
      dispatch(setPreviewCommunity(group));
      navigation.navigate(`CommunityPreview`);
      // const parentNav = navigation.getParent<LeftDrawerNavigator>();
      // parentNav.jumpTo(`RightDrawer`, {
      //   screen: `MainTabs`,
      //   // @ts-ignore - issues with types in nested navigation events
      //   params: {
      //     screen: `Explore`,
      //     params: { screen: `CommunityPreview` },
      //   },
      // });
    },
    [dispatch, navigation],
  );
  if (!user?.id) return null;
  return (
    <View style={localStyles.container}>
      <View style={{ paddingBottom: 16 }}>
        <View style={{ marginBottom: 12 }}>
          <View style={{ zIndex: 1 }}>
            <SplashImage user={user} />

            <View style={localStyles.avatarContainer as any}>
              <AvatarImage user={user} />
            </View>
          </View>

          <View style={localStyles.buttonsContainer}>
            <MessageButton user={user} handleGoToChat={handleGoToChat} />
            <EditButton user={user} />
            <ShareButton user={user} />
            <FollowButton user={user} follow_request={follow_request} handleFollow={handleFollow} />
          </View>
        </View>

        <UserInfo user={user} showBirthdateSetting={showBirthdateSetting} />

        {(favoriteCommunities && favoriteCommunities.length > 0) || isCurrentUser ? (
          <View style={{ flexDirection: `row`, alignItems: `center`, paddingHorizontal: 16 }}>
            <Text style={{ ...Typography.text(`bold`), fontSize: 13, lineHeight: 16, marginTop: 12, marginBottom: 10 }}>
              Favorites
            </Text>

            {isCurrentUser ? (
              <MeshIcon
                name="edit"
                size={20}
                onPress={() => navigation.navigate(`FavouriteCommunitiesSelection`, { selectedGroupsParam: favoriteCommunities })}
                style={{ marginLeft: 10 }}
              />
            ) : undefined}
          </View>
        ) : undefined}

        <View
          style={{
            flexDirection: `row`,
            marginHorizontal: 16,
            justifyContent: favoriteCommunities?.length >= 4 ? `space-evenly` : `flex-start`,
          }}
        >
          {favoriteCommunities &&
            favoriteCommunities.map((comm) => (
              <TouchableOpacity
                onPress={() => onSelectCommunity(comm)}
                activeOpacity={0.5}
                key={comm?.group.id}
                style={localStyles.favCommunityContainer}
              >
                {comm?.group.avatar_url ? (
                  <Image style={localStyles.groupIcon} source={{ uri: comm?.group.avatar_url }} />
                ) : (
                  <Image
                    style={{ marginBottom: 5, width: 60, height: 60 }}
                    resizeMode="cover"
                    source={require(`../../assets/images/node.png`)}
                  />
                )}
                <View style={{ flexDirection: `row`, alignItems: `flex-start` }}>
                  <Text numberOfLines={1} ellipsizeMode="tail" style={localStyles.carouselText}>
                    {comm?.group.name}
                  </Text>
                  {comm?.group.isVerified && <MeshVerifiedCheck height={10} width={10} style={{ marginLeft: 2, marginTop: 3 }} />}
                </View>
              </TouchableOpacity>
            ))}

          {!!isCurrentUser && favoriteCommunities && favoriteCommunities.length < 5 && (
            <MeshIcon
              name="circle-plus"
              size={60}
              // style={favoriteCommunities && favoriteCommunities.length && { marginLeft: 10 }}
              onPress={() => navigation.navigate(`FavouriteCommunitiesSelection`, { selectedGroupsParam: favoriteCommunities })}
            />
          )}
        </View>
      </View>

      <View style={localStyles.myPostsHeaderContainer}>
        <Text style={localStyles.myPostsText}>{me ? `My` : `${user.handle}'s`} posts</Text>
      </View>
    </View>
  );
};

interface AvatarImageProps extends UserProps {}

const AvatarImage: React.FC<AvatarImageProps> = ({ user }) => {
  const [showImageViewer, setShowImageViewer] = useState(false);
  const { avatar_url } = user || {};
  const size = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);

  return (
    <TouchableWithoutFeedback onPress={() => setShowImageViewer(true)}>
      <Avatar user={user} size={96} border isSameStack={true} />
      {!!avatar_url && (
        <Modal isVisible={showImageViewer} onBackdropPress={() => setShowImageViewer(false)} style={{ margin: 0 }}>
          <ImageViewer
            enableSwipeDown
            enablePreload
            backgroundColor="#000000E0"
            imageUrls={[avatar_url].map((url) => ({ url, width: size, height: size }))}
            onCancel={() => setShowImageViewer(false)}
            onClick={() => setShowImageViewer(false)}
            onSwipeDown={() => setShowImageViewer(false)}
            swipeDownThreshold={32}
          />
        </Modal>
      )}
    </TouchableWithoutFeedback>
  );
};

interface SplashImageProps extends UserProps {}

const SplashImage: React.FC<SplashImageProps> = ({ user }) => {
  const [showImage, setShowImage] = useState(false);
  const { splash_url } = user || {};
  const size = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width - 16 * 2);
  const width = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);
  const aspect_ratio = 0.5189;

  return (
    <TouchableWithoutFeedback onPress={() => setShowImage(true)}>
      <View style={{ ...localStyles.splashImageContainer, height: size * aspect_ratio }}>
        {splash_url ? (
          <Image source={{ uri: splash_url }} style={localStyles.splashImage} resizeMode="cover" />
        ) : (
          <View style={{ borderRadius: 7, overflow: `hidden` }}>
            <UserHeader width={size} height={size * aspect_ratio} />
          </View>
        )}
      </View>

      {splash_url ? (
        <Modal isVisible={showImage} onBackdropPress={() => setShowImage(false)} style={{ margin: 0 }}>
          <ImageViewer
            enableSwipeDown
            enablePreload
            backgroundColor="#000000E0"
            imageUrls={[{ url: splash_url, width, height: width * aspect_ratio }]}
            onCancel={() => setShowImage(false)}
            onClick={() => setShowImage(false)}
            onSwipeDown={() => setShowImage(false)}
            swipeDownThreshold={32}
          />
        </Modal>
      ) : null}
    </TouchableWithoutFeedback>
  );
};

interface UserInfoProps extends UserProps {
  showBirthdateSetting: Setting;
}

const UserInfo: React.FC<UserInfoProps> = ({ user, showBirthdateSetting }) => {
  return (
    <View style={{ marginBottom: 0, paddingHorizontal: 16 }}>
      {!!user?.name && (
        <View style={{ display: `flex`, flexDirection: `row`, alignItems: `center` }}>
          <Text style={localStyles.userNameText as any}>{user.name.trim()}</Text>
          {user?.isVerified && <MeshVerifiedCheck width={14} height={14} style={{ marginLeft: 2 }} />}
        </View>
      )}

      <ProfilePronouns user={user} />
      <ProfileBirthDay user={user} birthdateSetting={showBirthdateSetting} />
      <ProfileDescription user={user} />
    </View>
  );
};

interface MessageButtonProps extends UserProps {
  handleGoToChat: () => void;
}
const MessageButton: React.FC<MessageButtonProps> = ({ user, handleGoToChat }) => {
  const { user: currentUser } = useContext(AppContext);
  if (user.id === currentUser?.id || !getEnvVariables().newChat) return null;
  return (
    <TouchableOpacity style={localStyles.chatButton} onPress={handleGoToChat}>
      <Icon name="mail" size={20} color={Colors.deepPurple} />
    </TouchableOpacity>
  );
};

interface FollowButtonProps extends UserProps {
  follow_request?: FollowRequest;
  handleFollow: (follow: boolean) => void;
}
const FollowButton: React.FC<FollowButtonProps> = ({ user, follow_request, handleFollow }) => {
  const { user: currentUser } = useContext(AppContext);
  const isCurrentUser = user && user.id === currentUser?.id;
  if (isCurrentUser) return null;
  if (!follow_request)
    return (
      <TouchableOpacity style={localStyles.followButton} onPress={() => handleFollow(true)}>
        <Text style={{ ...Typography.text(`bold`, `small`), color: Colors.deepPurple }}>Follow</Text>
      </TouchableOpacity>
    );
  let followStatusLabel = `Follow`;
  if (follow_request) followStatusLabel = follow_request.accepted ? `Following` : `Requested`;
  return (
    <TouchableOpacity style={localStyles.followButton} onPress={() => handleFollow(false)}>
      <Text style={{ ...Typography.text(`bold`, `small`), color: Colors.deepPurple }}>{followStatusLabel}</Text>
    </TouchableOpacity>
  );
};

const EditButton: React.FC<UserProps> = ({ user }) => {
  const navigation = useNavigation<ProfileNavigation>();
  const { user: currentUser } = useContext(AppContext);
  if (user.id !== currentUser?.id) return null;
  return (
    <TouchableOpacity style={localStyles.editButton} onPress={() => navigation.navigate(`ProfileSettings`)}>
      <Text style={{ ...Typography.text(`bold`, `small`), lineHeight: 24, color: Colors.deepPurple }}>Edit profile</Text>
    </TouchableOpacity>
  );
};

const ShareButton: React.FC<UserProps> = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const [inviteUrl, setInviteUrl] = useState<string>(``);
  const { user: currentUser } = useContext(AppContext);

  const { data } = useQuery(GET_PROFILE_INVITE, {
    variables: { user_id: user?.id, version: 2 },
  });

  useEffect(() => {
    const link = data?.getProfileInvite?.invite_url;
    if (link) {
      setInviteUrl(fixMeshInvitelLink(link) || ``);
    }
  }, [data?.getProfileInvite?.invite_url]);

  return (
    <>
      {user.id === currentUser?.id ? (
        <TouchableOpacity style={localStyles.shareButton} onPress={() => setShowModal(true)}>
          <Icon name="share" size={20} color={Colors.deepPurple} />
        </TouchableOpacity>
      ) : (
        <Button
          onlyIconRender
          leftIcon={<MeshIcon name="invite" color={Colors.brandPurple} size={16} />}
          onPress={() => setShowModal(true)}
          containerStyle={{ justifyContent: `center`, padding: 9, marginLeft: 10, borderRadius: 8 }}
        />
      )}

      <BottomSheetModal
        visible={showModal && !!inviteUrl}
        title="Invite your friends to the profile"
        showCancelBtn={false}
        confirmTitle="Done"
        onPressConfirm={() => setShowModal(false)}
        onPressCancel={() => setShowModal(false)}
      >
        <InviteModal inviteUrl={inviteUrl} user={user} onClose={() => setShowModal(false)} />
      </BottomSheetModal>
    </>
  );
};

const ProfilePronouns: React.FC<UserProps> = ({ user }) => {
  if (!user?.pronouns?.length) return null;
  const hasDescription = !!user.description;
  const content = user.pronouns.map((p) => p.pronoun).join(`,`);
  return (
    <Text style={[Typography.text(`small`, `gray`, `bold`), { lineHeight: 20, marginBottom: hasDescription ? 4 : 0 }]}>
      {content}
    </Text>
  );
};
interface ProfileBirthDayProps extends UserProps {
  birthdateSetting: Setting;
}

const ProfileBirthDay: React.FC<ProfileBirthDayProps> = ({ user, birthdateSetting }) => {
  if (!user?.dob || birthdateSetting?.value === `false`) return null;
  return (
    <View style={{ marginBottom: 4, marginLeft: -2, flexDirection: `row`, alignItems: `center` }}>
      <MeshIcon name="gift" size={18} color={Colors.iconColor} />
      <Text style={{ ...Typography.text(`small`, `gray`, `bold`), marginLeft: 4 }}>{formatDate(user?.dob, `MMMM dd`)}</Text>
    </View>
  );
};

const ProfileDescription: React.FC<UserProps> = ({ user }) => {
  if (!user?.description?.length) return null;
  return <Autolink text={user.description} style={{ fontSize: 14, lineHeight: 22, color: Colors.black, marginBottom: 4 }} />;
};

const localStyles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
  },
  groupIcon: {
    borderRadius: 150 / 2,
    overflow: `hidden`,
    marginBottom: 5,
    width: 60,
    height: 60,
  },
  splashImageContainer: {
    backgroundColor: Colors.avatarContainerColor,
    width: `100%`,
    alignSelf: `center`,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: 0,
    shadowColor: Colors.avatarShadowColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,
    elevation: 3,
  },
  splashImage: {
    flex: 1,
    height: `100%`,
    width: `100%`,
    borderRadius: 0,
  },
  avatarContainer: {
    position: `absolute`,
    bottom: -50,
    zIndex: 2,
    paddingHorizontal: 16,
  },
  chatButton: {
    backgroundColor: Colors.purpleGrey,
    padding: 7,
    borderRadius: 8,
  },
  editButton: {
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 8,
    marginLeft: 8,
  },
  shareButton: {
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    padding: 6,
    borderRadius: 8,
    marginLeft: 8,
  },
  followButton: {
    backgroundColor: Colors.purpleGrey,
    paddingHorizontal: 16,
    paddingVertical: 9.5,
    borderRadius: 8,
    marginLeft: 8,
  },
  // followText: {
  //   ...Typography.text(`bold`, `plusone`),
  //   lineHeight: 28,
  // },
  userNameText: {
    ...Typography.text(`plustwo`, `bold`, `black`),
    lineHeight: 22,
    marginRight: 5,
    flexWrap: `wrap`,
  },
  myPostsHeaderContainer: {
    backgroundColor: Colors.backgroundColor,
    padding: 8,
  },
  myPostsText: {
    ...Typography.text(`base`, `gray`, `bold`),
  },
  carouselText: {
    fontSize: 9.8,
    letterSpacing: -0.1,
    lineHeight: 14,
    justifyContent: `center`,
    maxWidth: 63,
    textAlign: `center`,
    overflow: `hidden`,
  },
  buttonsContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-end`,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  favCommunityContainer: {
    width: 70,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    marginHorizontal: 5,
  },
});
