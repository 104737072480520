import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreLGBTQ = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path id="a" d="M0 0H101V60H0z" />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Rect fill="#FCE8F0" x={0} y={0} width={163} height={98} rx={7} />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#EA236C">
            <TSpan x={12} y={22}>
              LGBTQ+
            </TSpan>
          </Text>
          <Path
            d="M137.032 75.843c-9.312 15.58-52.68 12.442-65.053-4.82-12.373-17.262 6.123-48.535 27.807-46.966 21.684 1.681 46.43 36.093 37.246 51.786z"
            fill="#EA236C"
            fillRule="nonzero"
          />
        </G>
        <G transform="translate(54 38)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)" fillRule="nonzero">
            <Path fill="#E47272" d="M4.92682927 12.2844311L5.03897744 114.284431 12.3170732 110.506973 12.3170732 16.3321529z" />
            <Path fill="#F4B05F" d="M22.8230959 1.28443114L22.1707317 143.284431 29.5609756 140.436175 29.5609756 6.16736255z" />
            <Path fill="#A68AFE" d="M94.2621203 4.28443114L93.6097561 146.284431 101 143.436175 101 9.16736255z" />
            <Path fill="#FFF274" d="M41.5204225 9.28443114L41.0569106 95.2844311 50.0894309 91.7684934 50.0894309 17.6682209z" />
            <Path fill="#8BE685" d="M60.3481501 0.284431138L59.9430894 141.284431 67.6983736 136.96896 68.1544715 7.50625971z" />
            <Path fill="#7DA4FF" d="M77.1869919 12.2844311L77.1869919 120.284431 83.0766599 117.584527 83.7560976 15.5242202z" />
            <Path
              fill="#C33E3E"
              d="M4.81467506 12.2844311L-9.33526066e-14 17.0063739 -9.33526066e-14 110.506973 4.92682927 114.284431z"
            />
            <Path fill="#E07A00" d="M15.601626 5.89568717L15.601626 140.074904 22.2988166 143.284431 22.9918699 1.28443114z" />
            <Path fill="#775EC6" d="M87.0406504 8.89568717L87.0406504 143.074904 93.737841 146.284431 94.4308943 4.28443114z" />
            <Path fill="#65C05F" d="M60.7642276 0.284431138L53.3739837 8.58536704 53.3739837 137.77841 60.351226 141.284431" />
            <Path fill="#EAD50B" d="M41.8780488 9.28443114L33.6666667 17.9386037 33.6666667 90.4165792 41.439813 95.2844311z" />
            <Path fill="#5076D0" d="M77.1869919 12.2844311L70.6178862 15.6594311 70.6178862 114.884144 77.1869919 120.284431z" />
            <Path
              d="M4.516 99.284c-.227 0-.41-.214-.41-.479V16.764c0-.265.183-.48.41-.48.227 0 .41.215.41.48v82.041c0 .265-.183.48-.41.48M22.581 90.284c-.227 0-.41-.214-.41-.479V7.764c0-.265.183-.48.41-.48.227 0 .41.215.41.48v82.041c0 .265-.183.48-.41.48M94.02 94.284c-.227 0-.41-.214-.41-.479V11.764c0-.265.183-.48.41-.48.227 0 .41.215.41.48v82.041c0 .265-.183.48-.41.48M41.467 91.284c-.227 0-.41-.216-.41-.482V18.767c0-.266.183-.483.41-.483.228 0 .411.217.411.483v72.035c0 .266-.183.482-.41.482M60.354 87.284c-.227 0-.41-.216-.41-.482V14.767c0-.266.183-.483.41-.483.227 0 .41.217.41.483v72.035c0 .266-.183.482-.41.482M77.598 92.284c-.227 0-.411-.216-.411-.482V19.767c0-.266.184-.483.41-.483.228 0 .411.217.411.483v72.035c0 .266-.183.482-.41.482"
              fill="#FFF"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
};
