import 'intl';
import 'intl/locale-data/jsonp/en';
import { SafeAreaView, StyleSheet, Switch, Text, TextInput, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  confirmPayment,
  ConfirmPaymentError,
  PaymentIntents,
  retrievePaymentIntent,
  StripeError,
  StripeProvider,
} from '@stripe/stripe-react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { useNavigation } from '@react-navigation/native';
import { Colors, Typography } from '../common-styles';
import { BottomSheetModal, Divider, ThemedButton } from '../common-ui';
import { APIHelper } from './helpers';
import { PaymentMethodButton } from './PaymentMethodButton';
import { setShowThanksModal } from '../redux/contributionSlice';
import { _INCLUDE_FEE, _FEE_VIEW, _SELECTED_AMOUNT_FORMATED, _SEND_CONTRIBUTION_BUTTON } from './testLabels';
import { getEnvVariables } from '../../environment';
import { BASE_PAYMENT_API_URL } from '../../constants';
import { PaymentNavigator } from '../common-types/navigation-types';
import { useSettings } from '../common-settings/hooks/useSettings';
import { RootState } from '../redux/store';
import { CreditCardNames } from './creditCardsHelpers';

type PaymentIntent = {
  amount: number;
  communityName: string;
  customer: string;
  paymentMethod: string;
  accountToPaid: string;
  originalAmount?: number;
};

const Contribute: React.FC = () => {
  const navigation = useNavigation<PaymentNavigator>();
  const publishKey = getEnvVariables().stripe_pub_key;
  const { amount, customer, group, defaultPaymentMethod, paymentMethods } = useSelector(
    (state: RootState) => state.contributions,
  );
  const [collectFee, setCollectFee] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idmUpdateKey, setidmUpdateKey] = useState<string>();
  const [optionalFee, setOptionalFee] = useState(0);

  const [isVisible, setIsVisible] = useState(false);
  const [isPayoutDisabled, setPayoutDisabled] = useState(false);

  const [errorObj, setErrorObj] = useState<StripeError<ConfirmPaymentError>>();
  const [cvc, setCVC] = useState(``);

  const dispatch = useDispatch();

  const { settings = {} } = useSettings(`group`, {
    user_id: undefined,
    group_id: group?.id,
  });
  const bankSetting = settings[`group.setting.bank_account`];
  const accountToPaid = bankSetting?.value as string;

  useEffect(() => {
    setidmUpdateKey(uuidv4());
  }, []);

  const calculateFeeV2 = async (amount: number) => {
    try {
      const body = {
        centAmount: amount,
        isSending: false,
      };
      const result = await APIHelper(`payments/calculate_fee`, `POST`, idmUpdateKey, body);
      if (result) {
        setOptionalFee(result?.fee / 100);
      }
    } catch (error) {
      console.log(`Error while Calculating Fee:`, error);
    }
  };

  useEffect(() => {
    if (amount) calculateFeeV2(amount * 100);
  }, [amount]);

  const formatter = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: `USD`,
  });

  const handleSwitchCollectFeeChange = () => {
    setCollectFee((prevValue) => !prevValue);
  };

  const handleCreatePaymentIntent = async (cvc: string) => {
    if (!group || !defaultPaymentMethod || !accountToPaid) return;
    dispatch(setShowThanksModal(false));
    try {
      setLoading(true);
      setErrorObj(undefined);
      console.log(`=== handleCreatePaymentIntent ====`);

      const paymentIntentBody: PaymentIntent = {
        amount: Number(amount) + optionalFee,
        communityName: group?.name,
        customer: customer?.id,
        paymentMethod: defaultPaymentMethod.id,
        accountToPaid,
        originalAmount: undefined,
      };

      if (collectFee) {
        // collectFee is true, add the original amount to the payment so we can calculate the stripe fee over
        // the expected donation amount to the group.
        paymentIntentBody.originalAmount = Number(amount);
      }
      if (accountToPaid) {
        try {
          const result = await APIHelper(`payments/create-payment-intent`, `POST`, idmUpdateKey, paymentIntentBody);
          if (!result.clientSecret) {
            setErrorObj(result.error);
            return;
          }
          console.log(`=== confirm payment Intent ====`);
          const { paymentIntent } = await retrievePaymentIntent(result.clientSecret);
          if (paymentIntent?.status === PaymentIntents.Status.Succeeded) {
            setIsVisible(false);
            navigation.navigate(`HomeScreen`);
            setTimeout(() => {
              dispatch(setShowThanksModal(true));
            }, 100);
            return;
          }
          // Attempt payment comfirmation
          const { error, paymentIntent: paymentIntent2 } = await confirmPayment(result.clientSecret, {
            // @ts-ignore: TODO: verify confirmPaymentInput.type is needed
            type: `Card`,
            cvc,
            paymentMethodId: defaultPaymentMethod.id,
          });

          if (error) setErrorObj(error);
          else if (paymentIntent2?.status === PaymentIntents.Status.Succeeded) {
            console.log(`confirm payment success`);
            setIsVisible(false);
            navigation.navigate(`HomeScreen`);
            setTimeout(() => {
              dispatch(setShowThanksModal(true));
            }, 100);
          }
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setCVC(``);
    }
  };
  const formatBrand = (brand: CreditCardNames) => {
    if (brand) return brand.charAt(0).toUpperCase() + brand.slice(1);
    return ``;
  };

  const checkPayoutEnabled = async () => {
    if (!accountToPaid) {
      setIsVisible(false);
      setPayoutDisabled(true);
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${BASE_PAYMENT_API_URL}accounts/retrieve/${accountToPaid}`, {
        method: `GET`,
        headers: {
          'Content-Type': `application/json`,
        },
      });
      const result = await response?.json();
      console.log(result?.account?.payouts_enabled, result?.account?.charges_enabled);
      if (result?.account?.payouts_enabled && result?.account?.charges_enabled) {
        setIsVisible(true);
        setPayoutDisabled(false);
      } else {
        setIsVisible(false);
        setPayoutDisabled(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    // @ts-ignore
    <StripeProvider publishableKey={publishKey}>
      <SafeAreaView>
        <View style={styles.sectionContainerOne}>
          <Text style={styles.label}>You're about to help {group?.name}</Text>
          <Text style={styles.description}>Confirm your payment details and send your tip.</Text>
        </View>
        <Divider />
        <View style={styles.sectionContainerTwo}>
          <View style={styles.row}>
            <Text style={styles.labelGhost}>Optional fee</Text>
            <Switch
              testID="INCLUDE_FEE"
              value={collectFee}
              onValueChange={handleSwitchCollectFeeChange}
              trackColor={{ true: Colors.brandPurple }}
            />
          </View>
          {optionalFee > 0 && (
            <Text style={[styles.description, { maxWidth: 281 }]}>Optionally add ${optionalFee} to cover processing fee.</Text>
          )}
        </View>
        <Divider />
        <View style={styles.sectionContainerThree}>
          <Text style={styles.labelGhost}>Payment details</Text>
          <View style={[styles.row, { paddingBottom: 8 }]}>
            <Text style={styles.description}>Tip</Text>
            <Text style={styles.amountLabel}>{formatter.format(amount)}</Text>
          </View>
          {collectFee && (
            <View testID="FEE_VIEW" style={[styles.row, { paddingBottom: 8 }]}>
              <Text style={styles.description}>Fee</Text>
              <Text style={styles.amountLabel}>{formatter.format(optionalFee)}</Text>
            </View>
          )}

          <Divider />
          <View style={[styles.row, { paddingTop: 8 }]}>
            <Text style={styles.description}>Total</Text>
            <Text testID="SELECTED_AMOUNT_FORMATED" style={styles.amountLabel}>
              {formatter.format(amount + (collectFee ? optionalFee : 0))}
            </Text>
          </View>
        </View>
        <Divider />
        <View style={styles.sectionContainerThree}>
          <Text style={styles.labelGhost}>Default payment method</Text>
          <PaymentMethodButton paymentMethods={paymentMethods} />

          <Text style={{ ...styles.label, textAlign: `center`, paddingHorizontal: 16, marginTop: 12, marginBottom: 0 }}>
            Tips are not refundable.
          </Text>

          <ThemedButton
            rounded
            title={`Send tip: ${formatter.format(amount + (collectFee ? optionalFee : 0))}`}
            onPress={checkPayoutEnabled}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginTop: 12 }}
            testID="SEND_CONTRIBUTION_BUTTON"
            titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`) }}
            disabled={loading || !customer || !defaultPaymentMethod}
          />
        </View>
      </SafeAreaView>
      <BottomSheetModal
        title="Warning"
        visible={isPayoutDisabled}
        onPressCancel={() => {
          setPayoutDisabled(false);
        }}
        onPressConfirm={() => {
          if (!group) return;
          setPayoutDisabled(false);
          navigation.navigate(`BankAccount`, { group });
        }}
        showCancelBtn={true}
      >
        <View style={{ padding: 16 }}>
          <Text>You are not able to send payouts. Please check the connected account again.</Text>
        </View>
      </BottomSheetModal>
      {defaultPaymentMethod && Object.keys(defaultPaymentMethod).length > 0 ? (
        <BottomSheetModal
          visible={isVisible}
          title="Enter security code"
          showCancelBtn={true}
          onPressCancel={() => {
            setIsVisible(false);
            setCVC(``);
            setErrorObj(undefined);
          }}
          confirmTitle="Submit"
          onPressConfirm={() => {
            if (cvc) handleCreatePaymentIntent(cvc);
          }}
          isConfirmDisabled={!cvc || loading}
          avoidKeyboard={true}
          confirmLoading={loading}
        >
          <View style={{ padding: 16 }}>
            <Text>
              Enter the CVC for {formatBrand(defaultPaymentMethod?.card?.brand)} ····{defaultPaymentMethod?.card?.last4}.
            </Text>
            <Text>After you confirm, your payment will be processed.</Text>
            <Text style={{ fontSize: 12, marginTop: 20 }}>Security Code</Text>

            <View style={{ flexDirection: `row`, marginTop: 8, alignItems: `center` }}>
              <TextInput
                placeholder="CVC"
                placeholderTextColor={Colors.textPlaceholder}
                onChangeText={setCVC}
                keyboardType="numeric"
                value={cvc}
                style={{ padding: 10, borderRadius: 6, borderWidth: 1, borderColor: Colors.gray, width: 80, marginRight: 10 }}
              />
              <Icon name="credit-card" size={35} color={Colors.iconColor} />
            </View>
            {errorObj ? (
              <Text style={{ textAlign: `center`, marginTop: 10, ...Typography.text(`bold`, `small`, `alert`) }}>
                {errorObj?.message || `Error occured while processing`}
              </Text>
            ) : null}
          </View>
        </BottomSheetModal>
      ) : (
        <View />
      )}
    </StripeProvider>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: `row`,
    justifyContent: `space-between`,
  },
  amountLabel: {
    fontWeight: `600`,
    lineHeight: 19,
    fontSize: 14,
  },
  sectionContainerOne: {
    padding: 16,
  },
  sectionContainerTwo: {
    paddingTop: 26,
    paddingBottom: 26,
    paddingLeft: 16,
    paddingRight: 16,
  },
  sectionContainerThree: {
    paddingTop: 26,
    paddingLeft: 16,
    paddingBottom: 26,
    paddingRight: 16,
  },
  labelGhost: {
    fontSize: 12,
    lineHeight: 15,
    marginBottom: 12,
    fontWeight: `600`,
    color: `rgba(55,50,50,0.5)`,
  },
  label: {
    fontWeight: `600`,
    fontSize: 16,
    color: `#373232`,
    marginBottom: 8,
  },
  description: {
    letterSpacing: -0.14,
    lineHeight: 22,
    color: `#373232`,
  },
});

export { Contribute };
