import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Insets,
  ScrollView,
  StyleProp,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
  ViewStyle,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { Colors, Typography, MeshIconsNames } from '../common-styles';
import { BottomSheetOptions } from '../common-types/types';
import { withTheme } from '../themes/ThemeProvider';
import { MenuModal } from './MenuModal';
import { MeshIcon } from './MeshIcon';
import { NoThemeButton } from './NoThemeButton';
import { ThemedButton } from './ThemedButton';
import { MODAL_TITLE } from './testLabels';

// TODO: merge changes for buttons and content

type OptionItemProps = BottomSheetOptions & {
  pressFx: () => void;
  theme: any; // FIXME: Add the type of the theme object we're using
};

export type BottomSheetModalProps = {
  title: string;
  options?: BottomSheetOptions[];
  visible: boolean;
  cancelTitle?: string;
  confirmTitle?: string;
  isCancelDisabled?: boolean;
  isConfirmDisabled?: boolean;
  showCancelBtn?: boolean;
  showConfirmBtn?: boolean;
  onPressCancel: () => void;
  onPressConfirm: () => void;
  children?: any;
  avoidKeyboard?: boolean;
  confirmLoading?: boolean;
};

export const BottomSheetModal: React.FC<BottomSheetModalProps> = ({
  title,
  options,
  visible,
  cancelTitle = `Cancel`,
  confirmTitle = `Confirm`,
  isCancelDisabled = false,
  isConfirmDisabled = false,
  showCancelBtn = true,
  showConfirmBtn = true,
  onPressCancel,
  onPressConfirm,
  children,
  avoidKeyboard,
  confirmLoading = false,
}) => {
  const { height } = Dimensions.get(`window`);

  // This is needed for updating visibility manually
  // Followed this guide : https://github.com/react-native-modal/react-native-modal/issues/161#issuecomment-1033334813
  // It is needed to update if there is any other good solution
  const [additionalVisible, setAdditionalVisible] = useState(false);

  useEffect(() => {
    if (visible)
      setTimeout(() => {
        setAdditionalVisible(true);
      }, 250);
    else setAdditionalVisible(false);
  }, [visible]);

  return (
    <MenuModal isVisible={visible && additionalVisible} toggleModal={() => onPressCancel()} avoidKeyboard={avoidKeyboard}>
      <SafeAreaInsetsContext.Consumer>
        {(insets) => (
          //@ts-ignore
          <View style={{ marginBottom: insets?.bottom, maxHeight: height * 0.8 }}>
            <View
              style={{
                padding: 12,
                paddingTop: 26,
                borderBottomColor: Colors.lightGray,
                borderBottomWidth: 1,
                flexDirection: `row`,
                alignItems: `center`,
                justifyContent: `center`,
              }}
            >
              <Text
                maxFontSizeMultiplier={2}
                style={{ flex: 1, textAlign: `center`, ...Typography.text(`bold`, `plusone`) }}
                testID={MODAL_TITLE}
              >
                {title}
              </Text>

              {showCancelBtn && (
                <NoThemeButton
                  clear
                  title={cancelTitle}
                  titleStyle={{ ...Typography.text(`bold`), color: isCancelDisabled ? Colors.gray : Colors.deepPurple }}
                  containerStyle={{ position: `absolute`, right: 14, paddingTop: 12 }}
                  buttonStyle={{ alignItems: `center` }}
                  onPress={onPressCancel}
                  disabled={isCancelDisabled}
                />
              )}
            </View>
            {children}
            <ScrollView alwaysBounceVertical={false}>
              {options?.map((option) => {
                return (
                  <OptionItem
                    key={option.title}
                    disabled={option.disabled}
                    title={option.title}
                    titleStyle={option.titleStyle}
                    iconName={option.iconName}
                    iconColor={option.iconColor || Colors.iconColor}
                    pressFx={option.onPress}
                    testID={option.testID}
                  />
                );
              })}
            </ScrollView>
            {showConfirmBtn && (
              <View style={{ marginTop: 12, marginBottom: insets ? Math.max(0, 24 - insets?.bottom) : 0 }}>
                <ThemedButton
                  rounded
                  title={confirmTitle}
                  titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.lightGray }}
                  containerStyle={{ marginHorizontal: 16 }}
                  buttonStyle={{ paddingVertical: 10, alignItems: `center` }}
                  onPress={onPressConfirm}
                  disabled={isConfirmDisabled}
                  leftIcon={confirmLoading ? <ActivityIndicator /> : null}
                />
              </View>
            )}
          </View>
        )}
      </SafeAreaInsetsContext.Consumer>
    </MenuModal>
  );
};

type ThreeDotsButtonProps = {
  horizontal?: boolean;
  onPress?: () => void;
  testID?: string;
  size?: number;
  color?: string;
  iconName?: MeshIconsNames;
  style?: StyleProp<ViewStyle>;
  hitSlop?: Insets;
};

const DEFAULT_HIT_SLOP = { top: 20, bottom: 20, left: 20, right: 20 };

export const ThreeDotsButton = React.memo(
  ({
    horizontal = false,
    size = 18,
    onPress,
    iconName,
    color = Colors.iconColor,
    style,
    hitSlop = DEFAULT_HIT_SLOP,
    testID = `3_DOTS`,
  }: ThreeDotsButtonProps) => {
    const icon = iconName || (horizontal ? `dots-three-horizontal` : `dots-three-vertical`);
    // disallowInterruption: prevents press being spread when button is absolute positioned (grid header)
    return (
      <TouchableOpacity
        onPress={onPress}
        hitSlop={hitSlop}
        disallowInterruption
        style={StyleSheet.flatten([{ maxHeight: 40 }, style])}
        testID={testID}
      >
        <View style={{ flexDirection: `row`, justifyContent: `center`, maxHeight: 40 }}>
          <MeshIcon name={icon} size={size} color={color} />
        </View>
      </TouchableOpacity>
    );
  },
);

export const _OptionItem: React.FC<OptionItemProps> = ({
  title,
  iconName,
  pressFx,
  theme,
  titleStyle,
  iconColor,
  testID,
  disabled = false,
}) => {
  const disabledColor = disabled ? Colors.disabledIconColor : iconColor;
  return (
    <View testID={testID}>
      <TouchableHighlight disabled={disabled} underlayColor={Colors.themeTranslucentHighlight} onPress={pressFx}>
        <View style={{ flexDirection: `row`, alignItems: `center` }}>
          <MeshIcon name={iconName} size={22} color={disabledColor || theme.primary} style={{ margin: 15 }} />
          <Text
            maxFontSizeMultiplier={1.8}
            style={{
              ...Typography.text(`bold`, `plusone`, `flex1`),
              marginVertical: 12,
              marginRight: 16,
              color: disabled ? Colors.textDisabled : Colors.textBlack,
            }}
          >
            {!!title && <Text style={titleStyle}>{title}</Text>}
          </Text>
        </View>
      </TouchableHighlight>
    </View>
  );
};

const OptionItem = withTheme(_OptionItem);
