import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Dimensions, StyleProp, View, ViewStyle } from 'react-native';
import _ from 'lodash';
import { AdContent, AdHeader } from '../../ad/Ad';
import { Colors, Spacing } from '../../common-styles';
import { Activity as IActivity, CommunityAd, GroupCoreFields, GroupMember, WithChildren } from '../../common-types/types';
import { CenterColumn, ErrorBoundary } from '../../common-ui';
import PostContent from '../../post/components/PostContent';
import PostFooter from '../../post/components/PostFooter';
import PostHeader from '../../post/components/PostHeader';
import { COMMUNITY_FEED_ACTIVITY } from '../testLabels';
import { useResponsiveDimensions } from '../../common-util/hooks/useResponsiveDimensions';
import PostPolls from '../../post/components/PostPolls';

type ActivityProps = {
  activity: IActivity;
  onSelectActivity: (activity: IActivity) => void;
  onScreen?: boolean;
  feedIndex?: number;
  // eslint-disable-next-line
  isFocused?: boolean;
  showGroup?: boolean;
  fromPersonalFeed?: boolean;
};

type ContainerProps = {
  testID?: string;
  containerStyle?: StyleProp<ViewStyle>;
} & WithChildren;

export const ActivityContainer = ({ children, containerStyle, testID }: ContainerProps) => {
  return (
    <CenterColumn>
      <View style={[{ width: `100%`, backgroundColor: Colors.white }, containerStyle]} testID={testID}>
        <ErrorBoundary header="Sorry, there was an error displaying this post">{children}</ErrorBoundary>
      </View>
    </CenterColumn>
  );
};

const areEqualActivity = (prevProps: ActivityProps, nextProps: ActivityProps) => {
  const isEqualActivity = prevProps.activity.id === nextProps.activity.id;
  const isEqualOnScreen = prevProps.onScreen === nextProps.onScreen;
  const isEqualFocus = prevProps.isFocused === nextProps.isFocused;

  //FIXME: stream generated value we can't rely upon
  const isEqualLikeCount = prevProps.activity.reaction_counts?.like === nextProps.activity.reaction_counts?.like;
  const isEqualCommentCount = prevProps.activity.reaction_counts?.comment === nextProps.activity.reaction_counts?.comment;
  const isEqualReplyCount =
    prevProps.activity.reaction_counts?.comment_reply === nextProps.activity.reaction_counts?.comment_reply;
  const isEqualReactionCount = isEqualLikeCount && isEqualCommentCount;
  const isEqualImageUrls = prevProps?.activity?.imageUrls?.join(`,`) === nextProps?.activity?.imageUrls?.join(`,`);
  const isEqualVideoUrls = prevProps?.activity?.video_urls?.join(`,`) === nextProps?.activity?.video_urls?.join(`,`);

  const isEqualFollowRequest = prevProps.activity.user?.follow_request === nextProps.activity.user?.follow_request;
  const isEqualTopics = _.isEqual(prevProps.activity.topics?.sort(), nextProps.activity.topics?.sort());
  const isEqualPinned = prevProps.activity.pinned === nextProps.activity.pinned;

  const prevReactionCounts = prevProps?.activity?.custom_reaction_counts?.reduce(
    (total: number, item: any) => total + item.count,
    0,
  );
  const nextReactionCounts = nextProps?.activity?.custom_reaction_counts?.reduce(
    (total: number, item: any) => total + item.count,
    0,
  );
  const areEqualReactionCounts = prevReactionCounts === nextReactionCounts;
  const prevVoteCount = prevProps?.activity?.poll?.options?.reduce((total, option) => total + option.vote_count, 0) || 0;
  const currentVoteCount = nextProps?.activity?.poll?.options?.reduce((total, option) => total + option.vote_count, 0) || 0;
  const isEqualVotes = prevVoteCount === currentVoteCount;

  return (
    isEqualActivity &&
    isEqualOnScreen &&
    isEqualFocus &&
    isEqualReactionCount &&
    isEqualImageUrls &&
    isEqualVideoUrls &&
    isEqualFollowRequest &&
    isEqualTopics &&
    areEqualReactionCounts &&
    isEqualReplyCount &&
    isEqualPinned &&
    isEqualVotes
  );
};

const ActivityComp = ({
  activity,
  onScreen,
  onSelectActivity,
  feedIndex,
  showGroup = false,
  fromPersonalFeed = false,
}: ActivityProps) => {
  const navigation = useNavigation<any>();
  const width = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);

  const handleShowOptionsMenu = useCallback(() => {
    onSelectActivity(activity);
  }, [onSelectActivity, activity]);

  return (
    <ActivityContainer testID={COMMUNITY_FEED_ACTIVITY} containerStyle={{ minHeight: 50 }}>
      <PostHeader
        activity={activity}
        showOptionsMenu={handleShowOptionsMenu}
        index={feedIndex}
        showGroup={showGroup}
        fromPersonalFeed={fromPersonalFeed}
      />
      <PostContent
        onScreen={onScreen}
        activity={activity}
        width={width}
        textOverflowMode="expand"
        fromPersonalFeed={fromPersonalFeed}
      />
      <PostPolls activity={activity} />
      <PostFooter
        activity={activity}
        onPressComment={() =>
          navigation.navigate(`PostDetail`, {
            id: activity?.id,
            group_id: activity?.origin_group_id,
            addComment: true,
            isPersonalFeed: activity.__typename === `ProfileActivity`,
            fromPersonalFeed,
          })
        }
        fromPersonalFeed={fromPersonalFeed}
      />
    </ActivityContainer>
  );
};

export const Activity = React.memo(ActivityComp, areEqualActivity);

type ActivityAdProps = {
  activity: CommunityAd;
  index: number;
  group: GroupCoreFields;
  groupMember?: GroupMember;
  onScreen: boolean;
  showAdOptionsModal?: (activity: CommunityAd) => void;
};

export const ActivityAd = ({ activity, index, group, groupMember, onScreen, showAdOptionsModal }: ActivityAdProps) => {
  const { responsiveWidth } = useResponsiveDimensions();
  if (group.id !== activity.group_id) return null;
  return (
    <ActivityContainer testID="COMMUNITY_FEED_AD" containerStyle={{ minHeight: 50 }}>
      <AdHeader
        showOptionsMenu={showAdOptionsModal}
        ad={activity}
        onScreen={onScreen}
        index={index}
        group={group}
        group_member={groupMember}
      />
      <AdContent width={responsiveWidth} ad={activity} index={index} group={group} group_member={groupMember} />
    </ActivityContainer>
  );
};

ActivityContainer.displayName = `ActivityContainer`;
