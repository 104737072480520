import * as ImageManipulator from 'expo-image-manipulator';
import PropTypes from 'prop-types';
import { Dimensions, Image, View } from 'react-native';
import React from 'react';
import { Colors } from '../common-styles';
import { LoadingIndicator } from './LoadingIndicator';

export class AndroidImage extends React.PureComponent {
  static propTypes = {
    uri: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      resized: undefined,
    };
    const { uri } = this.props;
    this.resizeImage(uri);
  }

  resizeImage = async (uri) => {
    const resized = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: Dimensions.get(`window`).width } }]);
    this.setState({ resized, isLoading: false });
  };

  renderImage = (isLoading) => {
    const { resized } = this.state;
    const { uri } = this.props;
    if (!isLoading) {
      return <Image source={{ uri: uri || `` }} resizeMode="cover" style={{ height: resized.height, width: resized.width }} />;
    }
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.mediumGray,
          width: Dimensions.get(`window`).width,
          height: 300,
          justifyContent: `center`,
        }}
      >
        <LoadingIndicator color="white" size="large" />
      </View>
    );
  };

  render = () => {
    const { isLoading } = this.state;
    return this.renderImage(isLoading);
  };
}
