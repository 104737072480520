import React, { useContext, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useDispatch } from 'react-redux';
import { useWindowDimensions, FlatList, Image, ListRenderItem, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { setPreviewCommunity, setActiveCommunity } from '../../redux/feedSlice';
import { Typography, Colors } from '../../common-styles';
import { GroupAndGroupMember, GroupCoreFields, GroupMember, Setting } from '../../common-types/types';
import { CommunityHeader, MeshVerifiedCheck } from '../../images';
import { AppContext } from '../../../AppContext';
import { MeshIcon } from '../../common-ui';
import { useSettings } from '../../common-settings/hooks/useSettings';
import { PendoTrackEvent, Events, extractSourceFromRoute } from '../../pendo/events';

type AffiliatedCommunityProps = {
  affiliatedCommunities: GroupAndGroupMember[];
  group_member?: GroupMember; // current community membership
  source_group: GroupCoreFields;
  source: `About` | `HomeScreen` | `Community preview`;
};

type AffiliatedCommunitiesNavigationScreens = {
  CommunityPreview: undefined;
  HomeScreen: undefined;
  AffiliatedCommunities: {
    affiliatedGroupsParam: GroupAndGroupMember[];
    setting: Setting;
  };
};

type AffiliatedCommunitiesNavigation = StackNavigationProp<AffiliatedCommunitiesNavigationScreens>;

export const AffiliatedCommunityList: React.FC<AffiliatedCommunityProps> = ({
  affiliatedCommunities,
  group_member,
  source,
  source_group,
}) => {
  const navigation = useNavigation<AffiliatedCommunitiesNavigation>();
  const dispatch = useDispatch();
  const { user } = useContext(AppContext);
  const { role_name } = group_member || {};
  const isHighAdmin = role_name === `owner` || role_name === `manager`;
  const limitReached = (affiliatedCommunities.length || 0) > 4;

  const isSmall = source === `About`;
  const { settings } = useSettings(`group.setting.affiliated_communities`, {
    user_id: undefined,
    group_id: group_member?.group_id,
  });

  const { width } = useWindowDimensions();

  const onSelectCommunity = (comm: GroupAndGroupMember) => {
    const { group, group_member } = comm;
    PendoTrackEvent(Events.VIEW_COMMUNITY, {
      from_community: source_group.name,
      community_name: group.name,
      association: `Affiliated`,
      source: extractSourceFromRoute(source),
    });
    if (!group_member || group.application_pending) {
      dispatch(setPreviewCommunity(group));
      navigation.navigate(`CommunityPreview`);
    } else if (!group_member.banned && !!user?.id) {
      dispatch(setActiveCommunity({ user_id: user.id, group }));
      navigation.navigate(`HomeScreen`);
    }
  };

  const goToAffiliations = () => {
    navigation.navigate(`AffiliatedCommunities`, {
      affiliatedGroupsParam: affiliatedCommunities,
      //FIXME: this should be fetched from AffiliatedCommunities screens
      setting: settings[`group.setting.affiliated_communities`],
    });
  };

  const marginForItems = useMemo(() => {
    let marginHorizontal;
    if (isSmall) marginHorizontal = 5;
    else if (affiliatedCommunities?.length > 3) marginHorizontal = 15;
    else marginHorizontal = 24;
    return marginHorizontal;
  }, [isSmall, affiliatedCommunities]);

  const renderAffiliatedItem: ListRenderItem<GroupAndGroupMember> = ({ item }) => {
    const { group } = item;
    const size = source === `About` ? 40 : 87;
    return (
      <TouchableOpacity
        onPress={() => onSelectCommunity(item)}
        activeOpacity={0.5}
        key={group.id}
        style={[styles.communityAvatarContainer, { marginHorizontal: marginForItems }]}
      >
        {group.avatar_url ? (
          <Image style={[styles.groupIcon, { width: size, height: size }]} source={{ uri: group.avatar_url }} />
        ) : (
          <CommunityHeader style={{ marginBottom: 5 }} width={size} height={size} />
        )}
        <View style={{ flexDirection: `row`, alignItems: `flex-start` }}>
          <Text numberOfLines={1} ellipsizeMode="tail" style={[styles.carouselText, isSmall ? styles.smallText : null]}>
            {group.name}
          </Text>
          {group?.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4, marginTop: 2 }} />}
        </View>
      </TouchableOpacity>
    );
  };

  const renderFooter = () => {
    if (!isHighAdmin || limitReached || source !== `About`) return null;
    return <MeshIcon name="circle-plus" size={40} style={{ marginLeft: 10 }} onPress={goToAffiliations} />;
  };

  if (role_name !== `owner` && role_name !== `manager` && !affiliatedCommunities.length) {
    return null;
  }
  return (
    <>
      <View style={styles.row}>
        <Text style={[styles.headerTitle, isSmall ? styles.header1 : styles.header2]}>Affiliated communities</Text>
        {isHighAdmin && source === `About` ? (
          <MeshIcon name="edit" size={20} onPress={goToAffiliations} style={{ marginLeft: 10 }} />
        ) : undefined}
      </View>
      <FlatList<GroupAndGroupMember>
        contentContainerStyle={{
          justifyContent: affiliatedCommunities?.length === 3 ? `center` : `flex-start`,
          maxWidth: affiliatedCommunities?.length === 3 ? width : 3000,
        }}
        horizontal
        data={affiliatedCommunities || []}
        keyExtractor={({ group }) => group.id}
        renderItem={renderAffiliatedItem}
        ListFooterComponent={renderFooter}
        showsHorizontalScrollIndicator={false}
        bounces={false}
      />
    </>
  );
};

const styles = StyleSheet.create({
  row: { flexDirection: `row`, alignItems: `center` },
  headerTitle: { marginVertical: 12 },
  header1: Typography.text(`black`, `plusone`, `bold`),
  header2: {
    ...Typography.text(`black`, `plustwo`, `bold`),
    marginHorizontal: 16,
  },
  groupIcon: {
    borderRadius: 150 / 2,
    overflow: `hidden`,
    marginBottom: 5,
  },
  carouselText: {
    maxWidth: 95,
    color: Colors.textBlack,
    textAlign: `center`,
    overflow: `hidden`,
  },
  smallText: {
    fontSize: 9.8,
    letterSpacing: -0.1,
    lineHeight: 14,
    maxWidth: 63,
  },
  communityAvatarContainer: {
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `flex-start`,
  },
});
