import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Text, View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import FastImage from 'react-native-fast-image';
import { useDebouncedCallback } from 'use-debounce/lib';
import { setActiveCommunity } from '../../redux/feedSlice';
import { Colors, Spacing, Typography } from '../../common-styles';
import { formatRelativeDate } from '../../common-util';
import { MeshIcon, Button } from '../../common-ui'; //eslint-disable-line
import { JOIN_GROUP, client } from '../../graphql';
import { AppContext } from '../../../AppContext';
import { Group, GroupMember, User, parseIntroDataType } from '../../common-types/types';
import { useCommunityHeader } from '../v2/hooks/useCommunityHeader';

import { validGuidelines } from '../hooks/useJoinCommunity';
import { parseMemberLength } from '../v2/helpers';
import { useAgeVerification } from '../hooks/useAgeVerification';
import { VideoPlayer } from '../../post/components/VideoPlayer';
import { HomeNavigator } from '../../common-types/navigation-types';
import { Events, PendoTrackEvent } from '../../pendo/events';

type RequireGroup = {
  group: Group;
};

const maxDescriptionLength = 300;

const AboutCommunityDescription: React.FC<RequireGroup> = ({ group }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { description = `` } = group || {};
  if (typeof description !== `string` || description.trim().length === 0) return null;
  if (showFullDescription || description.length < maxDescriptionLength)
    return (
      <View style={{ marginTop: 16, marginHorizontal: 16 }}>
        <Text style={Typography.text(`black`, `plustwo`, `bold`)}>About us</Text>
        <Text style={{ ...Typography.text(`base`, `black`, `small`), marginTop: 6 }}>{description}</Text>
      </View>
    );

  const abbreviatedDescription = description.substring(0, 200);

  return (
    <View style={{ marginTop: 16, marginHorizontal: 16 }}>
      <Text style={Typography.text(`black`, `plustwo`, `bold`)}>About us</Text>
      <TouchableOpacity
        activeOpacity={0.8}
        style={{ marginTop: 6 }}
        disabled={!!group?.description && group?.description.length < maxDescriptionLength}
        onPress={() => setShowFullDescription(!showFullDescription)}
        hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
      >
        <Text style={Typography.text(`small`)}>
          <Text>{abbreviatedDescription}... </Text>
          <Text style={Typography.text(`bold`, `link`, `small`)}>Show all</Text>
        </Text>
      </TouchableOpacity>
    </View>
  );
};

type RenderVideoProps = {
  introVideo?: parseIntroDataType;
};

const RenderVideo: React.FC<RenderVideoProps> = ({ introVideo }) => {
  const width = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);
  const videoDim = { width, height: (width * 9) / 16 };
  const [showVideo, setShowVideo] = useState(false);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (showVideo) setShowVideo(false);
      };
    }, [showVideo]),
  );

  const setVideoDisplayed = () => setShowVideo(true);

  if (!introVideo) return null;

  if (showVideo) {
    return (
      <View style={{ marginTop: 16 }}>
        <VideoPlayer
          width={width}
          height={(width * 9) / 16}
          video_url={introVideo?.video_url}
          onScreen={true}
          onLayout={() => {}}
        />
      </View>
    );
  }
  return (
    <View style={{ position: `relative`, display: `flex`, alignItems: `center`, justifyContent: `center`, marginTop: 16 }}>
      <FastImage
        style={{ ...videoDim, backgroundColor: Colors.black }}
        source={{ uri: introVideo?.thumbnail_url }}
        resizeMode="cover"
      />
      <View style={{ position: `absolute` }}>
        <MeshIcon
          name="play-circle"
          size={62}
          color={Colors.white}
          onPress={setVideoDisplayed}
          style={{ backgroundColor: Colors.black, borderRadius: 31, overflow: `hidden` }}
        />
      </View>
    </View>
  );
};

type CommunityDisplayProps = {
  group: Group;
  group_member?: GroupMember;
  asFragment?: boolean /* true if rendered inside a modal (not a screen), false otherwise */;
  setShowInviteModal: (showInvite: boolean) => void;
  setShowAboutModal?: (showAbout: boolean) => void;
  introVideo?: parseIntroDataType;
};

export const CommunityDisplay: React.FC<CommunityDisplayProps> = ({
  group,
  group_member,
  asFragment,
  setShowInviteModal,
  setShowAboutModal,
  introVideo,
}) => {
  const navigation = useNavigation<HomeNavigator>();
  const { user, setUser } = useContext(AppContext);
  const dispatch = useDispatch();

  const isAgeInvalid = useAgeVerification(user as User, group);
  const { handleJoinOrLeaveCommunity, justLeftGroup, currentCommunity } = useCommunityHeader({
    mode: `preview`,
    groupMember: group_member,
    onChangeMember: () => null,
    onChangeGroup: () => null,
  });

  const membersLength = parseMemberLength(group?.group_member_count || 0);
  const attemptJoinInitialCommunity = useCallback(
    async () => {
      const { id, application, guidelines } = group;
      if (application && application.questions.length !== 0) {
        console.log(`The group has an application, guidelines managed be it `);
        navigation.navigate(`CommunityApplication`, { group });
        if (asFragment && setShowAboutModal) setShowAboutModal(false);
        return;
      }
      if (!application && validGuidelines(guidelines)) {
        console.log(`The group has no application, but it has custom guidelines - showing guidelines`);
        navigation.navigate(`CommunityCodeOfConduct`, { group });
        if (asFragment && setShowAboutModal) setShowAboutModal(false);
        return;
      }
      if (user?.id) {
        const { data } = await client.mutate({
          mutation: JOIN_GROUP,
          variables: { group_id: id },
        });
        const { application_pending, join_request_pending, group: joinedGroup } = data?.joinGroup || {};
        if (!application_pending && !join_request_pending && joinedGroup) {
          PendoTrackEvent(Events.JOIN_COMMUNITY, {
            community_name: joinedGroup.name,
            source: `about`,
            application_required: false,
            admin_approval_required: false,
          });
          dispatch(setActiveCommunity({ user_id: user.id, group: joinedGroup }));
        }
        if (user?.pending_completition) {
          user.pending_completition = false;
          setUser(user);
        }
      }
    },
    // reason: effect should not trigger on every user obj change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, group, navigation, user?.id],
  );
  const debouncedJoinOrLeaveCommunity = useDebouncedCallback(handleJoinOrLeaveCommunity, 500);

  useEffect(() => {
    if (justLeftGroup) navigation.navigate(`HomeScreen`);
  }, [justLeftGroup, navigation]);

  return (
    <>
      {/* community avatar */}
      <View style={{ flexDirection: `row`, flex: 1, alignItems: `center`, marginHorizontal: 16 }}>
        {group?.avatar_url ? (
          <FastImage style={localStyles.avatarImage} source={{ uri: group.avatar_url, priority: FastImage.priority.high }} />
        ) : (
          <FastImage style={localStyles.avatarImage} source={require(`../../../assets/images/node.png`)} />
        )}
        {/* community name - member count - last post time */}
        <View style={{ flexDirection: `column`, justifyContent: `center`, marginLeft: 8, flex: 1 }}>
          <Text ellipsizeMode="tail" numberOfLines={2} style={{ ...Typography.text(`black`, `plustwo`) }}>
            {group?.name}
          </Text>
          <View style={{ flexDirection: `row`, alignItems: `center` }}>
            <MeshIcon name="group" color={Colors.gray} size={10} />
            <Text style={{ ...Typography.text(`gray`, `smallest`), marginLeft: 2, marginRight: 10 }}>
              {membersLength} {group?.group_member_count === 1 ? `Member` : `Members`}
            </Text>

            <MeshIcon name="clock" color={Colors.gray} size={10} />
            <Text style={{ ...Typography.text(`gray`, `smallest`), marginLeft: 2 }}>
              {formatRelativeDate(group?.latest_post)}
            </Text>
          </View>
        </View>
        {/* join and share buttons */}
        <View style={{ flexWrap: `nowrap` }}>
          {currentCommunity?.join_request_pending || currentCommunity?.application_pending ? (
            <Button
              title="Pending"
              containerStyle={localStyles.joinButton}
              textStyle={{ fontSize: 13 }}
              onPress={() => null}
              disabled
            />
          ) : (
            <Button
              title={group_member ? `Member` : `Join`}
              containerStyle={localStyles.joinButton}
              textStyle={{ fontSize: 13, fontFamily: `inter-regular` }}
              onPress={async () => {
                if (asFragment) await attemptJoinInitialCommunity();
                else debouncedJoinOrLeaveCommunity();
                // navigation.goBack();
              }}
              //TODO: add leavingGroup variable
              disabled={(group_member && group_member?.role_id !== `7c062da3-3ccf-4431-8fc3-3def7bf65b00`) || isAgeInvalid}
            />
          )}

          <Button
            leftIcon={<MeshIcon name="invite" color={Colors.brandPurple} size={14} />}
            onPress={() => setShowInviteModal(true)}
            onlyIconRender
            containerStyle={localStyles.shareButton}
          />
        </View>
      </View>
      {/* intro video */}
      <RenderVideo introVideo={introVideo} />

      {/* description */}
      <AboutCommunityDescription group={group} />
    </>
  );
};

const localStyles = StyleSheet.create({
  avatarImage: {
    borderRadius: 150 / 2,
    overflow: `hidden`,
    width: 60,
    height: 60,
  },
  joinButton: {
    height: 35,
    width: 80,
    justifyContent: `center`,
    marginHorizontal: 4,
    borderRadius: 8,
    marginVertical: 8,
    padding: 0,
  },
  shareButton: {
    height: 35,
    width: 80,
    justifyContent: `center`,
    marginHorizontal: 4,
    borderRadius: 8,
  },
});
