import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import LottieView from 'lottie-react-native';
import { Colors, Typography } from '../common-styles';
import { AuthSubText } from '../common-ui';
import { useCreatePersona } from './hooks/useCreatePersona';
import { AuthNavigationContainer } from '../common-types/navigation-types';

type CreatingAccountNavigator = StackNavigationProp<AuthNavigationContainer, `Persona`>;

export const CreatingAccount = () => {
  const navigation = useNavigation<CreatingAccountNavigator>();

  const { createPersona } = useCreatePersona();

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => null,
      headerRight: () => null,
    });

    let timer: any;

    const create = async () => {
      try {
        await createPersona([]);
        timer = setTimeout(() => {
          navigation.navigate(`Persona`, { screen: `ProfilePhoto` });
        }, 5000);
      } catch (e) {
        console.error(e);
      }
    };
    create();
    return () => clearTimeout(timer);
  }, [navigation, createPersona]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <LottieView
          resizeMode="cover"
          style={{ width: 200, height: 200 }}
          source={require(`../images/animated/animatedLoading.json`)}
          autoPlay
          loop
        />
        <AuthSubText text="We are creating your account" style={styles.subtext} />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 100,
  },
  subtext: {
    color: Colors.black,
    fontSize: Typography.headerFontSize,
    marginRight: 10,
    marginTop: 20,
  },
});
