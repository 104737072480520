import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const MainCategoryIcon = (props) => {
  return (
    <Svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path d="M0 0H24V24H0z" />
        <Path
          d="M12 .5C5.649.5.5 5.649.5 12S5.649 23.5 12 23.5 23.5 18.351 23.5 12 18.351.5 12 .5z"
          fill="#333895"
          fillRule="nonzero"
        />
        <Path
          d="M9.441 9.327l-4.357.637-.078.016a.682.682 0 00-.3 1.147l3.153 3.07-.744 4.338-.009.075c-.033.52.521.895.999.644L12 17.205l3.897 2.05.068.03a.682.682 0 00.921-.75l-.744-4.338 3.152-3.07.054-.058a.682.682 0 00-.43-1.105l-4.359-.637-1.947-3.947a.682.682 0 00-1.223 0L9.44 9.327z"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
};

export { MainCategoryIcon };
