import React, { useState, useEffect } from 'react';
import { Animated, Text, View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { useQuery } from '@apollo/react-hooks';
import { Colors, Typography, MeshIconsNames } from '../common-styles';
import { formatDate, formatRelativeDate } from '../common-util';
import { MeshIcon, BottomSheetModal } from '../common-ui';
import { GET_GROUP } from '../graphql';
import { useSettings } from '../common-settings/hooks/useSettings';
import { Group, GroupMember, Category, KeyMap, parseIntroDataType, Setting } from '../common-types/types';
import InviteFriends from './v2/InviteFriends';
import { CommunityDisplay } from './About/CommunityInfo';
import { CommunityCategoryTiles } from './About/Categorytiles';
import { MemberListSummary } from './v2/MemberListSumary';
import { HomeNavigatorScreens } from '../common-types/navigation-types';

type AboutFragmentProps = {
  group_fragment: Group;
  group_member?: GroupMember;
  asFragment?: boolean /* true if rendered inside a modal (not a screen), false otherwise */;
  setShowAboutModal?: (showAbout: boolean) => void;
  onScroll?: () => null;
  contentOffset?: undefined; //just to maintain compatibility
  feedContainerStyle?: StyleProp<ViewStyle>;
};

type ParsedGuideline = {
  id: string;
  title: string;
  body: string;
};

type AboutRoute = RouteProp<HomeNavigatorScreens, `AboutScreen`>;

const approvalToJoinMessages: KeyMap<string> = {
  anyone: `Anyone can join this community`,
  by_approval: `Admin approval required to join`,
  invite_only_anyone: `Invite by anyone in the community required to join`,
  invite_only_admin_mod: `Invite-only by Admins`,
};

const whoCanPostMessages: KeyMap<string> = {
  anyone: `Anyone in the community can post`,
  creator: `Admins and contributors can post`,
  admin_mod: `Only Admins can post`,
  admin: `Owners and Managers can post`,
};

const postApprovalMessages: KeyMap<string> = {
  none: `Posts are published immediately`,
  approve: `Posts require approval before posting`,
};

export const AboutScreen = () => {
  const route = useRoute<AboutRoute>();
  const { group, group_member } = route.params; // group_fragment
  return <AboutFragment group_fragment={group} group_member={group_member} asFragment={false} />;
};

export const AboutFragment: React.FC<AboutFragmentProps> = ({
  group_fragment,
  group_member,
  asFragment = true,
  setShowAboutModal,
  onScroll = () => null,
  contentOffset,
  feedContainerStyle,
}) => {
  const [group, setGroup] = useState(group_fragment);
  const [accessToMemberListAvailable, setAccessToMemberListAvailable] = useState(false);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [introVideo, setIntroVideo] = useState<parseIntroDataType>();
  const [guidelines, setGuidelines] = useState<ParsedGuideline[]>();

  const { data: groupRequest } = useQuery(GET_GROUP, {
    variables: { group_id: group_fragment?.id },
    fetchPolicy: `cache-and-network`,
  });

  const { settings } = useSettings(`group`, {
    user_id: undefined,
    group_id: group?.id,
  });

  const approvalToJoin = settings[`group.setting.joinable`];
  const whoCanPost = settings[`group.setting.can_post`];
  const postApproval = settings[`group.setting.mod.post_approval`];
  const memberlist = settings[`group.setting.list_members`]?.value;

  useEffect(() => {
    if (settings[`group.setting.intro_video`]?.value) {
      const introVideoData = settings[`group.setting.intro_video`].value as string;
      const parsedValue = JSON.parse(introVideoData);
      setIntroVideo(parsedValue);
    } else setIntroVideo(undefined);
    //TODO: merge age_restriction and list_members for accessToMemberListAvailable
    if (settings[`group.setting.age_restriction`]?.value) {
      const ageSetting = settings[`group.setting.age_restriction`].value;
      setAccessToMemberListAvailable(ageSetting === `anyone`);
    }
    if (settings[`group.setting.type`]?.value) {
      setCategoryList(parseCategorySetting(settings[`group.setting.type`]));
    }
  }, [settings]);

  useEffect(() => {
    const { group } = groupRequest?.getGroup || {};
    if (group) setGroup(group);
    if (group?.guidelines) setGuidelines(JSON.parse(group?.guidelines));
  }, [groupRequest]);

  return (
    <Animated.ScrollView onScroll={onScroll} contentOffset={contentOffset} style={{ backgroundColor: Colors.white }}>
      <View style={feedContainerStyle}>
        <View>
          {/* community avatar and description*/}
          <CommunityDisplay
            group={group}
            group_member={group_member}
            asFragment={asFragment}
            setShowInviteModal={setShowInviteModal}
            setShowAboutModal={setShowAboutModal}
            introVideo={introVideo}
          />
          <View style={{ marginHorizontal: 16 }}>
            {/* private content pill */}
            <PrivacyPill display={!group_member && group?.content_visibility === `private`} />
            {/* categories */}
            <CommunityCategoryTiles asFragment={asFragment} group={group} categoryList={categoryList} />
            {/* affiliated communities - currently it's commented because it will have its own community tab */}
            {/* <AffiliatedCommunityList
              affiliatedCommunities={affiliatedGroups?.getAffiliatedGroups2.data || []}
              group_member={group_member}
              source_group={group}
              source="About"
            /> */}
            {/* Community members */}
            <MemberListSummary
              group={group}
              group_member={group_member}
              access={accessToMemberListAvailable}
              memberList={memberlist as string}
            />
            <CommunityGuidelines guidelines={guidelines} updated_at={group?.guidelines_updated_at} />
            <View style={{ marginTop: 30 }}>
              <Text style={{ ...Typography.text(`black`, `bold`, `plusone`) }}>Community info</Text>
              {/* created on - privacy */}
              {group?.created_at ? (
                <CommuntiyInfoPill
                  icon="clock"
                  message={`Community created on ${formatDate(group?.created_at, `MMMM dd yyyy`)}`}
                />
              ) : null}
              <CommuntiyInfoPill
                icon={group?.privacy === `public` ? `unlock` : `lock`}
                message={`${group?.privacy === `public` ? `Discoverable` : `Unlisted`} node`}
              />
              <CommuntiyInfoPill
                icon={group?.content_visibility === `public` ? `unlock` : `lock`}
                message={`${group?.content_visibility === `public` ? `Public` : `Private`} content`}
              />
              {/* Approval to join */}
              <CommuntiyInfoPill
                icon={approvalToJoin?.value === `anyone` ? `unlock` : `lock`}
                message={approvalToJoinMessages[approvalToJoin?.value] || ``}
              />
              {/* Who can post */}
              <CommuntiyInfoPill
                icon={whoCanPost?.value === `anyone` ? `unlock` : `lock`}
                message={whoCanPostMessages[whoCanPost?.value] || ``}
              />
              {/* Post approval */}
              <CommuntiyInfoPill
                icon={postApproval?.value === `none` ? `unlock` : `lock`}
                message={postApprovalMessages[postApproval?.value] || ``}
              />
              <View style={{ marginBottom: 50 }} />
            </View>
          </View>
        </View>
      </View>
      <BottomSheetModal
        visible={showInviteModal}
        title={`Invite your friends to ${group?.name}`}
        showCancelBtn={false}
        confirmTitle="Done"
        onPressConfirm={() => setShowInviteModal(false)}
        onPressCancel={() => setShowInviteModal(false)}
      >
        <InviteFriends groupName={group?.name} groupId={group?.id} />
      </BottomSheetModal>
    </Animated.ScrollView>
  );
};

export default React.memo(AboutFragment);

type CommunityGuidelinesProps = {
  guidelines?: ParsedGuideline[];
  updated_at?: string;
};
const CommunityGuidelines: React.FC<CommunityGuidelinesProps> = ({ guidelines, updated_at }) => {
  if (!guidelines?.length) return null;
  return (
    <View style={{ marginTop: 16 }}>
      <Text style={Typography.text(`plusone`, `bold`)}>Code of Conduct</Text>
      <Text style={Typography.text(`base`, `gray`)}>Last updated on {formatRelativeDate(updated_at)}</Text>
      <Text style={Typography.text()}>In order to join this node, you must agree to:</Text>

      <View style={localStyles.guidelinesContainer}>
        {guidelines.map((guideline, index: number) => {
          return (
            <View key={guideline.id} style={{ marginTop: index === 0 ? 0 : 10 }}>
              <Text style={localStyles.guidelineTitle}>
                {index + 1}. {guideline?.title}
              </Text>
              <Text style={localStyles.guidelineBody}>{guideline?.body}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const PrivacyPill: React.FC<{ display: boolean }> = ({ display }) => {
  if (!display) return null;
  return (
    <View style={localStyles.pillsContainer}>
      <MeshIcon name="lock" color={Colors.orange} size={14} />
      <Text style={{ ...Typography.text(`small`, `orange`), marginLeft: 5 }}>This community's content is private</Text>
    </View>
  );
};

const CommuntiyInfoPill: React.FC<{ icon: MeshIconsNames; message?: string }> = ({ icon, message = `` }) => {
  if (message === ``) return null;
  return (
    <View style={{ marginTop: 12, flexDirection: `row` }}>
      <MeshIcon name={icon} color={Colors.taupe} size={18} />
      <Text style={{ ...Typography.text(`black`, `base`), marginLeft: 8 }}>{message}</Text>
    </View>
  );
};
const parseCategorySetting = (categoriesSetting?: Setting): Category[] => {
  if (!categoriesSetting) return [];
  const { value, value_label } = categoriesSetting;
  const categoriesValueList = (value as string)?.split(`,`);
  const categoriesLabelList = value_label?.split(`,`) || [];

  const categories: Category[] = [];
  categoriesValueList.forEach((item, index) => {
    categories.push({
      value: item,
      label: categoriesLabelList[index] ?? ``,
    });
  });

  return categories;
};

const localStyles = StyleSheet.create({
  pillsContainer: {
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: 25,
    backgroundColor: Colors.badgeWarningBackground,
    height: 24,
    width: 250,
    borderRadius: 12,
  },
  guidelinesContainer: {
    backgroundColor: Colors.lightGray,
    paddingVertical: 10,
    paddingHorizontal: 6,
    borderRadius: 5,
    marginTop: 10,
  },
  guidelineTitle: Typography.text(`base`, `bold`),
  guidelineBody: Typography.text(),
});
