import React from 'react';
import { SafeAreaView as RNSafeAreaView, ViewStyle } from 'react-native';
import { SafeAreaView as SACSafeAreaView } from 'react-native-safe-area-context';
import { SafeAreaProvider } from './SafeAreaProvider';
import { appVersionIsAtLeast } from '../../environment';

type SafeAreaViewProps = {
  style?: ViewStyle;
  testID?: string;
};

export const SafeAreaView: React.FC<SafeAreaViewProps> = (props) => {
  const { children, style = {}, testID } = props;
  if (!appVersionIsAtLeast(`1.0.13`)) {
    return (
      <RNSafeAreaView style={style} testID={testID}>
        {children}
      </RNSafeAreaView>
    );
  }

  return (
    <SafeAreaProvider>
      <SACSafeAreaView style={style} testID={testID}>
        {children}
      </SACSafeAreaView>
    </SafeAreaProvider>
  );
};
