export const _AMOUNT_LIST = `AMOUNT_LIST`;
export const _AMOUNT_BOX = `AMOUNT_BOX`;
export const _CONTRIBUTION_AMOUNT_INPUT = `CONTRIBUTION_AMOUNT_INPUT`;
export const _CONTINUE_CONTRIBUTION_BUTTON = `CONTINUE_CONTRIBUTION_BUTTON`;
export const _INCLUDE_FEE = `INCLUDE_FEE`;
export const _FEE_VIEW = `FEE_VIEW`;
export const _SELECTED_AMOUNT_FORMATED = `SELECTED_AMOUNT_FORMATED`;
export const _SEND_CONTRIBUTION_BUTTON = `SEND_CONTRIBUTION_BUTTON`;
export const SET_DEFAULT_PAYMETHOD = `SET_DEFAULT_PAYMETHOD`;
export const DELETE_PAYMETHOD = `DELETE_PAYMETHOD`;
export const EDIT_PAYMETHOD = `EDIT_PAYMETHOD`;
export const CANCEL_PAYMETHOD_FINAL = `CANCEL_PAYMETHOD_FINAL`;
export const DELETE_PAYMETHOD_FINAL = `DELETE_PAYMETHOD_FINAL`;
export const ADD_NEW_PAYMETHOD = `ADD_NEW_PAYMETHOD`;
export const CC_NUMBER = `CC_NUMBER`;
export const CC_ZIP = `CC_ZIP`;
export const CC_CVV = `CC_CVV`;
export const CC_EXPIRATION = `CC_EXPIRATION`;
export const CC_SAVE = `CC_SAVE`;
