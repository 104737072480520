import * as SplashScreen from 'expo-splash-screen';
import { Dimensions, Image, Platform, StyleSheet, Text, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { MeshStackRGB } from '../images';
import { Colors } from '../common-styles';
import { getManifest, getAppVersion } from '../../environment';

export const LoadingScreen: React.FC = () => {
  const [publishID, setpublishID] = useState<string>();
  const version = getAppVersion();
  const { width, height } = Dimensions.get(`window`);

  const logoWidth = Math.min(width * 0.2, height * 0.2); // from mocks
  const logoHeight = logoWidth * 1.1; // from SVG aspect ratio

  useEffect(() => {
    const asyncInit = async () => {
      // now that we are ready to render we can hide the native splash screen
      if (Platform.OS !== `web`) {
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        }); // delay 100ms
        SplashScreen.hideAsync();
      }
    };
    try {
      const { extra } = getManifest() || {};
      const { publishID } = extra || {};
      setpublishID(publishID);
    } catch (err) {
      console.error(`LoadingScreen error getting the publish ID:`, err);
    }
    asyncInit();
  }, []);

  return (
    <View style={[localStyles.container, { width, height }]}>
      {Platform.select({
        default: <MeshStackRGB width={width} height={height} />,
        web: (
          <Image
            source={require(`../../assets/images/mesh-stack.png`)}
            width={logoWidth}
            height={logoHeight}
            style={{ width: logoWidth, height: logoHeight }}
          />
        ),
      })}

      {Platform.OS !== `web` && (
        <View style={[localStyles.webContainer, { marginBottom: height / 16 }]}>
          {/* Can't use Typography here, because fonts are not loaded */}
          <Text allowFontScaling={false} style={{ textAlign: `center`, color: Colors.textGray }}>
            {version || `dev build`} {!!publishID && ` (${publishID})`}
          </Text>
        </View>
      )}
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  webContainer: {
    position: `absolute`,
    left: 0,
    bottom: 0,
    width: `100%`,
    justifyContent: `center`,
    alignItems: `center`,
  },
});
