import React, { forwardRef } from 'react';
import { View, useWindowDimensions, NativeSyntheticEvent, TextInputSelectionChangeEventData } from 'react-native';
import { Colors, Typography } from '../common-styles';
import { getBackgrounds } from '../common-ui';
import { MetaStyle, UserMention } from '../common-types/types';
import { getTextStyleForPost, MediaTypes } from './helpers';
import { MentionTextInput } from './MentionTextInput';

type ContentEditorProps = {
  groupId: string | null;
  contentMode: string;
  metaStyle: MetaStyle;
  currentText: string;
  // eslint-disable-next-line react/no-unused-prop-types
  mediaCount?: number;
  emojiFontSize: number;
  resolvedMentions: UserMention[];
  addToResolvedMentions: (mention: UserMention[]) => void;
  onChangeText: (text: string) => void;
  onSelectionChange: (event: NativeSyntheticEvent<TextInputSelectionChangeEventData>) => void;
};
export const ContentEditor = forwardRef<any, ContentEditorProps>(
  (
    {
      groupId,
      metaStyle,
      currentText,
      contentMode,
      emojiFontSize,
      resolvedMentions,
      addToResolvedMentions,
      onChangeText,
      onSelectionChange,
    }: ContentEditorProps,
    textInputRef,
  ) => {
    const { width } = useWindowDimensions();
    if (contentMode !== MediaTypes.TEXT && contentMode !== MediaTypes.MEME) {
      return (
        <MentionTextInput
          ref={textInputRef}
          //@ts-ignore
          groupId={groupId}
          style={{ ...Typography.text(), padding: 16, maxWidth: width, flex: 1, flexWrap: `wrap` }}
          placeholder="Write a description"
          onChangeText={onChangeText}
          backgroundColor={Colors.white}
          resolvedMentions={resolvedMentions}
          addToResolvedMentions={addToResolvedMentions}
          onSelectionChange={onSelectionChange}
          value={currentText}
          scrollEnabled={false}
          testID="POST_CONTENT"
          multiline
        />
      );
    }

    const {
      height,
      margin,
      allowFontScaling,
      hasBlob: hasBlobOrBackground,
      style,
    } = getTextStyleForPost(currentText, width, `expand`);
    const backgrounds = getBackgrounds();
    let background = backgrounds[10];
    const { hasBlob } = metaStyle;
    const isBlob = metaStyle.blobIndex < 6;
    const index = metaStyle.blobIndex;
    let BlobWithSizeAndPosition;
    let color = Colors.black;
    let placeHolderColor = Colors.textPlaceholder;
    let fontFamily = `inter-regular`;
    if (height && hasBlobOrBackground && isBlob && hasBlob) {
      const Blob = backgrounds[index] as any;
      const blobHeight = height * 0.7;
      const blobWidth = width;
      const style = { position: `absolute`, left: width * 1.2 - blobWidth, bottom: height * 0.1, zIndex: -1 };
      BlobWithSizeAndPosition = <Blob width={blobWidth} height={blobHeight} style={style} />;
    } else if (!isBlob) {
      background = backgrounds[index];
      if (index > 5 && index < 10) {
        color = Colors.white;
        fontFamily = `inter-semibold`;
        placeHolderColor = Colors.mediumLighterGray;
      }
      if (background === Colors.white || !hasBlob) {
        background = Colors.white;
        color = Colors.black;
        placeHolderColor = Colors.textPlaceholder;
        fontFamily = `inter-regular`;
        style.textAlign = `left`;
        style.fontSize = width * (14 / 375);
        style.lineHeight = width * (17 / 375);
      }
    }

    return (
      <View style={{ width, backgroundColor: background as string }}>
        {hasBlobOrBackground && isBlob && BlobWithSizeAndPosition}
        <View
          style={{
            overflow: `visible`,
            width: width - 2 * margin,
            minHeight: width - 2 * margin,
            height: height && height - 2 * margin,
            margin,
            justifyContent: !isBlob && background === Colors.white ? `flex-start` : `center`,
          }}
        >
          <MentionTextInput
            key={emojiFontSize}
            ref={textInputRef}
            //@ts-ignore
            groupId={groupId}
            style={{ ...style, color, fontFamily }}
            backgroundColor={(background as string) || Colors.white}
            value={currentText}
            resolvedMentions={resolvedMentions}
            addToResolvedMentions={addToResolvedMentions}
            allowFontScaling={allowFontScaling}
            placeholder="What's going on?"
            placeholderTextColor={placeHolderColor}
            onChangeText={onChangeText}
            onSelectionChange={onSelectionChange}
            multiline
            scrollEnabled
            testID="POST_CONTENT"
          />
          <View style={{ height: 32 }} />
        </View>
      </View>
    );
  },
);
