import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { View, Text, StyleSheet, SectionList, SectionListRenderItem } from 'react-native';
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Divider, CenterColumn, ThemedButton, MeshIcon } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { GroupAndGroupMember, GroupCoreFields, GroupMember, ListedGroups } from '../common-types/types';
import { ADD_FAVORITE_GROUP, LIST_GROUPS, refetchQueriesFor } from '../graphql';
import { useModerationGroups } from '../moderation/hooks/useModerationGroups';
import { LoadingIndicator } from '../common-ui/LoadingIndicator';
import { ProfileScreensParamList } from '../common-types/navigation-types';
import { MeshVerifiedCheck } from '../images';

type ModeratedGroups = {
  group: GroupCoreFields;
  group_member?: GroupMember;
  count_open?: number;
};

type FavouriteCommunitiesSelectionRoute = RouteProp<ProfileScreensParamList, `FavouriteCommunitiesSelection`>;

export const FavouriteCommunitiesSelection = () => {
  const route = useRoute<FavouriteCommunitiesSelectionRoute>();
  const navigation = useNavigation();

  const { selectedGroupsParam } = route?.params;
  const [managedGroups, setmanagedGroups] = useState<ModeratedGroups[]>([]);
  const [creatorGroups, setCreatorGroups] = useState<ModeratedGroups[]>([]);
  const [joinedGroups, setjoinedGroups] = useState<ModeratedGroups[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupAndGroupMember[]>(selectedGroupsParam || []);
  const [loading, setLoading] = useState(false);

  const { moderatedGroups, loadingModeratedGroups } = useModerationGroups();

  const {
    data: listedGroups,
    loading: loadingListedGroups,
    refetch,
  } = useQuery<ListedGroups>(LIST_GROUPS, {
    fetchPolicy: `cache-and-network`,
    onError: (error) => console.error(`[FavouriteError]`, error.message),
  });

  const [addFavoriteGroup] = useMutation(ADD_FAVORITE_GROUP, {
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    const managedGroups =
      moderatedGroups?.getModeratedGroups2
        .filter((g) => g?.group?.discoverable === `public`)
        .sort((a, b) => Number(b?.group?.isVerified) - Number(a?.group?.isVerified)) || [];
    const allGroups = listedGroups?.listJoinedGroups.filter((g) => g?.group?.discoverable === `public`) || [];
    setmanagedGroups(managedGroups);
    setCreatorGroups(
      allGroups
        .filter(({ group_member }) => group_member.role_name === `creator`)
        .sort((a, b) => Number(b?.group?.isVerified) - Number(a?.group?.isVerified)),
    );
    setjoinedGroups(
      allGroups
        .filter(({ group_member }) => group_member.role_name === `member`)
        .sort((a, b) => Number(b?.group?.isVerified) - Number(a?.group?.isVerified)),
    );
  }, [moderatedGroups, listedGroups]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const selectedGroupsIds = selectedGroups?.map((g) => {
        if (g && g?.group) return g?.group?.id;
        return g?.id;
      });

      await addFavoriteGroup({
        variables: { group_ids: selectedGroupsIds },
        refetchQueries: refetchQueriesFor(`Group`),
      });

      navigation.goBack();
    } catch (error) {
      console.error(`Something went wrong trying to execute addFavoriteGroup mutation `, error);
    } finally {
      setLoading(false);
    }
  }, [selectedGroups, addFavoriteGroup, navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <ThemedButton clear title="Save" containerStyle={{ right: 8 }} onPress={handleSave} disabled={loading} />
      ),
    });
  }, [navigation, handleSave, selectedGroups?.length, loading]);

  const sections = useMemo(() => {
    const sections = [
      { title: `Admin`, data: managedGroups },
      { title: `Creator`, data: creatorGroups },
      { title: `Member`, data: joinedGroups },
    ].filter((section) => section.data && section.data.length > 0);

    return sections;
  }, [creatorGroups, managedGroups, joinedGroups]);

  const ListHeaderComponent = useCallback(() => {
    return (
      <View>
        <Text style={{ marginVertical: 20, ...Typography.text(`center`, `small`) }}>
          Choose up to 5 nodes to display on your profile
        </Text>
      </View>
    );
  }, []);

  const renderSectionHeader = useCallback(({ section }) => {
    const { title } = section;
    return (
      <CenterColumn>
        <View style={{ flexDirection: `row`, backgroundColor: Colors.commentGray, paddingVertical: 3, paddingHorizontal: 16 }}>
          <Text maxFontSizeMultiplier={2} style={{ ...Typography.text(`bold`, `gray`), paddingVertical: 4 }}>
            {title}
          </Text>
        </View>
      </CenterColumn>
    );
  }, []);

  const isCommunitySelected = useCallback(
    (id: String) =>
      selectedGroups.some((comm) => {
        if (comm && comm.group) return comm.group.id === id;
        return comm.id === id;
      }),
    [selectedGroups],
  );

  const handleSwitchCommunity = useCallback(
    (selectedGroup) => {
      try {
        if (isCommunitySelected(selectedGroup?.id)) {
          // means that we have to turn off the switch and remove it from the stored favorite communities
          const filteredArray = selectedGroups.filter((g) => {
            const idToCompare = g?.group?.id ? g?.group?.id : g?.id;
            return idToCompare && idToCompare !== selectedGroup?.id;
          });

          setSelectedGroups(filteredArray);
          return;
        }

        if (!isCommunitySelected(selectedGroup?.id) && selectedGroups.length === 5) {
          console.error(`You can add up to 5 communities`);
          return;
        }

        setSelectedGroups([...selectedGroups, selectedGroup]);
      } catch (error) {
        console.error(`An error ocurred while trying to add a favourite group - handleSwitchCommunity()`, error);
      }
    },
    [selectedGroups, isCommunitySelected],
  );

  const renderItem: SectionListRenderItem<ModeratedGroups> = useCallback(
    ({ item }) => {
      if (!item) return null;
      const { group } = item || {};
      let radioButtonValue = false;
      if (isCommunitySelected(group.id)) {
        radioButtonValue = true;
      }

      return (
        <View style={styles.groupItemContainer}>
          <View style={styles.groupItemLabels}>
            <Text maxFontSizeMultiplier={2} numberOfLines={2} ellipsizeMode="tail" style={styles.groupLabel}>
              {group?.name}
            </Text>
            {group?.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4, marginTop: 3 }} />}
          </View>
          <MeshIcon name="select-circle" focused={radioButtonValue} onPress={() => handleSwitchCommunity(group)} />
        </View>
      );
    },
    [handleSwitchCommunity, isCommunitySelected],
  );

  const keyExtractor = useCallback((item) => item.group.id, []);

  const ListEmptyComponent = useCallback(() => {
    if (loadingModeratedGroups || loadingListedGroups)
      return <LoadingIndicator size={28} style={{ alignItems: `center`, justifyContent: `center` }} />;
    return (
      <View style={{ alignItems: `center`, justifyContent: `center`, flex: 1 }}>
        <Text style={{ ...Typography.text(`center`, `bold`), marginTop: 20 }}>No communities to show</Text>
      </View>
    );
  }, [loadingListedGroups, loadingModeratedGroups]);

  return (
    <View>
      <SectionList
        sections={sections}
        ListHeaderComponent={ListHeaderComponent}
        renderSectionHeader={renderSectionHeader}
        renderItem={renderItem}
        extraData={sections}
        SectionSeparatorComponent={Divider}
        ItemSeparatorComponent={Divider}
        keyExtractor={keyExtractor}
        ListEmptyComponent={ListEmptyComponent}
        showsVerticalScrollIndicator={false}
        refreshing={loadingModeratedGroups || loadingListedGroups}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  groupItemContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `space-between`,
    marginVertical: 10,
    marginHorizontal: 16,
    flex: 1,
  },
  groupItemLabels: { flexDirection: `row`, alignItems: `flex-start`, flex: 1, maxWidth: `80%` },
  groupLabel: { ...Typography.text(`bold`), marginLeft: 10 },
});
