import PropTypes from 'prop-types';
import React, { createContext, useState, useEffect } from 'react';
import { SETTING_QUERY } from '../../graphql/Queries.gql';
import { client, refetchQueriesFor } from '../../graphql';
import { BASE_PAYMENT_API_URL } from '../../../constants';
import { AppContext } from '../../../AppContext';

export const ContributionContext = createContext({
  customer: null,
  email: ``,
  loading: false,
  customerId: ``,
  defaultPaymentMethod: ``,
  setDefaultPaymentMethod: () => {},
  paymentMethods: [],
  cleanContext: () => {},
  // eslint-disable-next-line no-unused-vars
  getBankAccountSetting: async (groupId) => {},
  // eslint-disable-next-line no-unused-vars
  getPaymentMethods: async (id) => {},
  showThanksModal: false,
  setShowThanksModal: () => {},
  contributionTileFlag: false,
  setContributionTileFlag: () => {},
  last4DigitsFlag: false,
  setLast4DigitsFlag: () => {},
});

export const ContributionProvider = ({ children }) => {
  const { user } = React.useContext(AppContext);
  const [customer, setCustomer] = useState(null);
  const [email, setEmail] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(``);
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [contributionTileFlag, setContributionTileFlag] = useState(false);
  const [last4DigitsFlag, setLast4DigitsFlag] = useState(false);

  /**
   * Get the required values from the settings.
   */
  useEffect(() => {
    const getUserSettings = async () => {
      try {
        const { data } = await client.query({
          query: SETTING_QUERY,
          variables: {
            settingQuery: {
              query: `user`,
              user_id: user?.id,
            },
          },
        });
        const settings = data?.getSettings || [];
        const [userEmail] = settings.filter((setting) => setting.key === `user.setting.email`);
        const [customerSetting] = settings.filter((setting) => setting.key === `user.setting.customer_id`);

        if (customerSetting?.value) {
          const response = await fetch(`${BASE_PAYMENT_API_URL}customers/retrieve/${customerSetting.value}`, {
            method: `GET`,
            headers: {
              'Content-Type': `application/json`,
            },
          });
          const { customer, paymentMethods } = await response.json();
          setCustomer(customer);
          setDefaultPaymentMethod(customer?.invoice_settings.default_payment_method);
          setPaymentMethods(paymentMethods?.data);
        }

        setEmail(userEmail?.value);
        setCustomerId(customerSetting?.value);
      } catch (error) {
        // TODO: Should Handle this error better
        console.error(`Error while getting the user email ===> `, error);
      }
    };
    if (!user?.id) return;
    if (!email || !customer) {
      getUserSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  /**
   * Retrieve the payments methods for the user.
   */
  const getPaymentMethods = async () => {
    if (customer) {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_PAYMENT_API_URL}payments/list/${customer?.id}`, {
          method: `GET`,
          headers: {
            'Content-Type': `application/json`,
          },
        });
        const data = await response.json();
        setPaymentMethods(data?.paymentMethods);
      } catch (error) {
        console.error(`Error while getting payment methods`, error);
      }
      setLoading(false);
    }
  };

  const getBankAccountSetting = async (group) => {
    const { data } = await client.query({
      query: SETTING_QUERY,
      variables: {
        settingQuery: {
          query: `group`,
          group_id: group?.id,
        },
      },
      refetchQueries: refetchQueriesFor(`Group`, `GroupMember`, `Setting`),
    });
    const settings = data?.getSettings || [];
    const [bankAccount] = settings.filter((setting) => setting.key === `group.setting.bank_account`);
    return bankAccount?.value ?? ``;
  };
  /**
   * Clean the context if the user log out.
   */
  const cleanContext = () => {
    setCustomer(null);
    setEmail(null);
    setCustomerId(null);
    setPaymentMethods([]);
    setDefaultPaymentMethod(``);
  };

  return (
    <ContributionContext.Provider
      value={{
        customer,
        email,
        loading,
        getPaymentMethods,
        defaultPaymentMethod,
        setDefaultPaymentMethod,
        paymentMethods,
        customerId,
        cleanContext,
        setCustomer,
        setCustomerId,
        setPaymentMethods,
        getBankAccountSetting,
        showThanksModal,
        setShowThanksModal,
        contributionTileFlag,
        setContributionTileFlag,
        last4DigitsFlag,
        setLast4DigitsFlag,
      }}
    >
      {children}
    </ContributionContext.Provider>
  );
};

ContributionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
