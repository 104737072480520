import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreTravel = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path id="a" d="M0 0.00190943105L11.3379209 0.00190943105 11.3379209 10.5438363 0 10.5438363z" />
        <Path id="c" d="M0.00776624359 0.000779988724L4.4214607 0.000779988724 4.4214607 7.69982337 0.00776624359 7.69982337z" />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Rect fill="#FCE8F0" x={0} y={0} width={163} height={98} rx={7} />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#EA236C">
            <TSpan x={12} y={22}>
              Travel
            </TSpan>
          </Text>
          <Path
            d="M147.032 72.843c-9.312 15.58-52.68 12.442-65.053-4.82-12.373-17.262 6.123-48.535 27.807-46.966 21.684 1.681 46.43 36.093 37.246 51.786z"
            fill="#EA236C"
            fillRule="nonzero"
          />
        </G>
        <G transform="translate(49 18) rotate(-9 100.555 7.76)" fillRule="nonzero">
          <Path
            fill="#B0B1DD"
            transform="rotate(-69 41.745 30.564)"
            d="M41.7144309 -7.13681888L60.0066385 68.0984207 23.483294 68.2642831z"
          />
          <Path
            fill="#CBCCF1"
            transform="rotate(-75 49.033 31.52)"
            d="M51.9811294 -13.2639458L67.6798823 70.1353037 52.1005614 65.1802241 30.3858201 76.3048259z"
          />
          <Path fill="#EEEEFE" d="M6.55711211 17.0747199L82.3473315 37.2603663 87.4718756 61.1122849z" />
        </G>
        <Path
          d="M19.014 5.77s-.096 2.129-1.338 2.88c-1.127.682 1.26 4.638 3.828 4.402 1.405-.13 1.734-3.973 1.734-3.973s-1.134-.22-.715-1.95L19.014 5.77z"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574)"
        />
        <Path
          d="M21.07 7.935c-1.664-.202-2.865-1.296-3.034-3.112-.483.077-.598-1.183-.463-1.588.135-.406.575-.169.575-.169.025-.064.045-.131.062-.201C18.938-.118 20.974 0 20.974 0c3.742.195 2.865 4.015 2.652 5.029-.214 1.013-.947 3.099-2.556 2.905z"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574)"
        />
        <G transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574) matrix(-1 0 0 1 40.759 8.708) translate(3.226 10.96)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Path
            d="M7.944.002s-.198 3.427-.868 3.93c-.67.503-5.263 1.805-5.263 1.805s-.205 2.703.537 2.419c.741-.284 8.229-1.34 8.305-1.797.076-.458.683-5.75.683-5.75L7.944.003z"
            fill="#C26E5E"
            fillRule="nonzero"
            mask="url(#b)"
          />
        </G>
        <Path
          d="M17.846 2.271c1.119 1.772 1.04 3.52 1.833 4.079.792.56 1.118 1.352 1.352 2.377.233 1.026 0 .475 0 .475l-3.945 2.71s-2.348-4.863-1.929-3.325c.42 1.538 1.41 8.008 1.01 8.38-.4.372-4.153 1.81-7.553 1.655-2.978-.136-5.784-.93-5.784-1.148 0-.466.674-5.717.674-5.717L.01 10.964s.293-6.974 3.28-9.318c0 0 1.617-1.182 3.608-1.617 0 0 .58 1.265 2.406 1.306 1.825.042 2.417-.61 2.872-1.332 0 0 4.552.497 5.671 2.268z"
          transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574) matrix(-1 0 0 1 40.759 8.708) translate(10.911 .023)"
          fill="#FC7750"
          fillRule="nonzero"
        />
        <Path
          d="M4.473 16.872a.414.414 0 01.075.078l.504.68a.427.427 0 01-.642.56.428.428 0 01-.64.531.427.427 0 01-.697.46.428.428 0 01-.727.444l-.504-.68a.425.425 0 01-.07-.145l-.989-1.683a1.2 1.2 0 00.126-.11c.362-.357 1.317-1.183 1.667-1.626.868.314 1.519.476 1.952.486.649.014 1.65-.334 1.708.04.066.425-1.126.943-1.739.965h-.024z"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574) matrix(-1 0 0 1 40.759 8.708) scale(-1 1) rotate(-41 0 26.98)"
        />
        <G fillRule="nonzero">
          <Path
            d="M12.967 4.945s2.031 3.436.037 4.144c-1.994.709-3.765.65-8.04.697-1.68.019-5.81.117-5.654-.555.086-.373 3.1-.95 4.507-1.484.415-.157 4.738-.967 4.97-1.219.155-.168 1.548-.696 4.18-1.583z"
            fill="#C26E5E"
            transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574) translate(0 9.101) rotate(55 6.593 7.374)"
          />
          <Path
            d="M4.917 5.25s.328-.545-.898-1.718c-.647-.619-1.136-2.048-1.495-1.802-.36.246.387 1.93.387 1.93s-.783-.709-1.246-1.49C1.202 1.39.772.757.772.757S.404.52.379.89c-.025.372.08.49.08.49s-.283-.066-.288.218c-.006.284.046.343.046.343S.005 1.92 0 2.168a.636.636 0 00.134.407s-.16.036-.024.335c.135.3.902 1.861 1.441 2.365.54.504 1.947 1.364 1.947 1.364s1.543-.961 1.419-1.39"
            fill="#C26E5E"
            transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574) translate(0 9.101)"
          />
          <Path
            d="M1.633 4.218c-.017-.012-.43-.315-.87-1.023C.37 2.56.222 2.601.22 2.601L.223 2.6.18 2.533c.007-.004.032-.017.078-.007.097.021.275.148.573.628.43.694.844.998.848 1l-.046.064zM1.849 3.873a32.653 32.653 0 00-.428-.385C1.2 3.291.927 2.851.727 2.53a5.755 5.755 0 00-.218-.338C.357 1.99.213 1.978.212 1.978L.215 1.9c.008 0 .18.01.357.245.053.07.131.197.222.343.197.318.467.752.68.942.312.278.427.384.428.385l-.053.058zM2.017 3.587a6.896 6.896 0 01-.727-.774C1.015 2.455.792 1.97.672 1.71l-.028-.06c-.098-.21-.186-.237-.187-.237l.018-.077c.012.003.125.033.24.282l.028.06c.119.257.34.735.61 1.086.288.377.712.76.717.764l-.053.058zM3.645 4.251c-.003-.002-.24-.234-.43-.299a.744.744 0 01-.342-.27l.066-.044a.675.675 0 00.302.24c.206.07.448.307.459.317l-.055.056z"
            fill="#995B5D"
            transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(25.343 1.574) translate(0 9.101)"
          />
        </G>
        <G>
          <G transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) matrix(-1 0 0 1 52.49 14.767)">
            <Path
              fill="#F8D8B8"
              fillRule="nonzero"
              d="M42.6552234 16.7221781L41.9208269 19.4199576 43.2584778 19.8695919 44.0977881 17.2205186z"
            />
            <Path
              fill="#E4F9FF"
              fillRule="nonzero"
              d="M44.0191028 17.0369195L43.0748786 20.0269626 44.4387579 20.4728463 45.435439 17.5614885z"
            />
            <G transform="translate(0 .013)">
              <Path
                d="M45.54 17.234s-.21 0-.472.13c-.262.132-1.626 1.942-.84 3.07 0 0 .132.078.29.105.157.026.445.498.944.76 0 0-.105-.34.288-1.364.394-1.023 2.65-6.452 3.725-6.504 0 0-.42-.341-1.705 1.127-1.214 1.388-2.23 2.676-2.23 2.676"
                fill="#2E2C36"
                fillRule="nonzero"
              />
              <Path
                d="M45.462 21.3s.446.76 1.154.629.913-.78 1.152-1.321c.238-.542 2.649-6.495 2.649-6.495s-.266-.858-.993-.691c-.841.192-1.628 1.976-1.628 1.976l-2.282 4.72s-.236.683-.052 1.181"
                fill="#E4F9FF"
                fillRule="nonzero"
              />
              <G transform="translate(46.083 13.901)">
                <Mask id="d" fill="#fff">
                  <Use xlinkHref="#c" />
                </Mask>
                <Path
                  d="M.008 7.136L2.919.893S3.522-.182 4.126.028c.603.21.13 1.18-.08 1.94-.209.761-2.307 5.22-2.858 5.64C.803 7.9.034 7.424.008 7.136"
                  fill="#2E2C36"
                  fillRule="nonzero"
                  mask="url(#d)"
                />
              </G>
              <Path
                d="M44.517 20.539s-.288-.761.053-1.679c.34-.918.97-1.626.97-1.626s-.663-.208-1.223 1.129c-.56 1.336-.455 2.044.2 2.228"
                fill="#3B3D4A"
                fillRule="nonzero"
              />
              <Path
                d="M44.731 19.797s-.084-.758.31-.979c.394-.22.998-.325 1.076-.64.08-.315.027-.787.027-.787"
                stroke="#FFF"
                strokeWidth={1.50969114}
              />
              <Path
                d="M12.233 20.348s.288 1.15.421 1.357c.133.207 2.597 1.317 3.384 1.241.786-.076 2.108-.576 2.282-.953.173-.377-.06-1.54-.31-1.754-.248-.214-5.777.109-5.777.109"
                fill="#E4F9FF"
                fillRule="nonzero"
              />
              <Path
                d="M.424 1.641s-1.937 7.278 2.469 8.406c4.406 1.128 9.33.108 14.74-1.652 4.433-1.443 4.25-3.095 9.888-1.469 4.035 1.164 14.426 4.065 14.426 4.065l1.075-3.147s-15.5-8-21.664-7.816c-2.309.069-9.578 1.547-9.578 1.547L.424 1.641z"
                transform="translate(0 8.708)"
                fill="#204EB2"
                fillRule="nonzero"
              />
              <Path
                d="M.025 11.247S4.86 1.234 6.696.343C8.532-.55 9.24.526 9.398 1.707c.157 1.18 3.567 12.406 3.567 12.406l-4.066 1.075-8.874-3.941z"
                transform="translate(.97)"
                fill="#204EB2"
                fillRule="nonzero"
              />
              <Path
                fill="#000"
                fillRule="nonzero"
                d="M7.41089761 9.59928158L9.91566198 14.8409059 9.79733564 14.8974493 7.29257126 9.65582503z"
              />
              <Path
                d="M13.346 14.123l.308 1.007c-1.222-.002-2.19.554-2.454.687l-.276-.987 2.422-.707z"
                fill="#F7C8A9"
                fillRule="nonzero"
              />
              <Path
                d="M14.328 17.031l-2.671.664-.555-2.009.053-.026c.264-.132 1.315-.622 2.536-.62"
                fill="#E4F9FF"
                fillRule="nonzero"
              />
              <Path
                d="M13.446 21.019s1.872.77 3.14.282c1.267-.488 1.559-.813 1.552-.845-.008-.032-2.105-1.842-2.518-2.02-.413-.177-1.477-.95-1.9-.28-.423.669-1.054 1.182-.973 1.67.082.489.7 1.193.7 1.193"
                fill="#353644"
                fillRule="nonzero"
              />
              <Path
                d="M11.72 18.066s.102 1.731.571 2.538l1.225.467s-.354-.932-.064-1.302c.29-.369.456-.778.46-1.015.004-.238 1.062-.51 1.062-.51s.176.4.49.396c.316-.003 1.938 1.175 1.938 1.175s-.397-.419-.814-.865c-.416-.447-1.875-1.863-1.875-1.863l-2.994.979z"
                fill="#2C262B"
                fillRule="nonzero"
              />
              <Path
                d="M11.58 17.363s-.261.274-.096.532c.166.259.723.106 1.255-.04.532-.147 1.805-.461 1.898-.643.094-.183-.075-.575-.417-.66 0 0 .1.328-.041.413-.142.086-2.365.722-2.46.662-.093-.06-.14-.264-.14-.264"
                fill="#3E454F"
                fillRule="nonzero"
              />
              <Path
                d="M13.481 18.763s.692-.748 1.464-.545c0 0 .253.05.162-.117-.092-.168-.226-.165-.226-.165s.017-.165-.122-.187c0 0 .042-.17-.149-.182 0 0-.06-.39-.473-.189-.412.2-1.21.354-1.108.572.103.22.163.125.163.125s-.048.145.063.282c.112.136.094.059.094.059s-.127.27.132.347"
                fill="#FFF"
                fillRule="nonzero"
              />
              <Path
                d="M13.095 18.124s.156-.143.412-.2M14.707 17.519s-.288.037-.455.129M15.004 17.93s-.396-.022-.57.099M13.233 18.496s.247-.217.6-.323"
                stroke="#000"
                strokeWidth={0.603876456}
              />
            </G>
          </G>
        </G>
        <G fillRule="nonzero">
          <Path
            d="M2.595.014l.1.032-.01.03a6.152 6.152 0 00-.105.382 5.378 5.378 0 00-.07.329l-.006.03c-.046.266-.052.468-.011.577l.002.006c.233.576-.083.868-.747.664l-.02-.007.032-.1c.598.192.837-.024.638-.518-.055-.136-.047-.366.008-.67a5.48 5.48 0 01.143-.603l.01-.031.015-.054.013-.042.008-.025z"
            fill="#000"
            transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(44.936 4.538) translate(0 .577)"
          />
          <Path
            d="M.005 4.027l.051-.092.019.01.017.01L.181 4l.014.007c.11.054.233.108.364.158.3.116.597.195.873.22.125.012.243.012.354 0l.022-.002.013.104a1.863 1.863 0 01-.399.003 3.428 3.428 0 01-.901-.227A4.78 4.78 0 01.149 4.1L.106 4.08a3.608 3.608 0 01-.051-.026l-.03-.016-.02-.011z"
            fill="#C86A5D"
            transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(44.936 4.538) translate(0 .577)"
          />
          <G fill="#000">
            <Path
              d="M1.837 3.462c-.495 0-.868-.227-.886-.238a.079.079 0 01.083-.133c.007.004.446.268.974.203a.079.079 0 11.019.156 1.572 1.572 0 01-.19.012"
              transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(44.936 4.538) translate(.052) rotate(15 1.505 3.27)"
            />
            <Path
              d="M2.067 2.706c-.106 0-.238-.02-.403-.074l.032-.1c.307.099.535.09.64-.022.089-.096.088-.272-.002-.496-.061-.151-.046-.438.045-.854A7.1 7.1 0 012.531.59l.1.032c-.092.286-.307 1.089-.2 1.354.137.337.065.516-.019.606-.06.065-.164.125-.345.125"
              transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(44.936 4.538) translate(.052)"
            />
            <Path
              d="M1.63.978a.085.085 0 01-.048-.016C1.43.86.987.588.782.558.59.531.295.714.198.787.124.842.04.772.01.63-.02.487.018.327.093.27.109.26.505-.036.803.006c.285.04.85.418.874.434.076.05.115.208.089.352-.02.114-.077.186-.136.186"
              transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(44.936 4.538) translate(.052) translate(0 .025)"
            />
            <Path
              d="M4.057 1.027A.14.14 0 013.974 1C3.894.94 3.646.782 3.466.752c-.192-.032-.634.004-.788.02a.14.14 0 01-.03-.28C2.67.49 3.242.43 3.511.475c.284.048.614.289.628.299a.14.14 0 01-.083.254M1.354 1.289a.078.078 0 01-.053-.021.489.489 0 00-.318-.11c-.194.008-.322.103-.324.104a.079.079 0 01-.095-.126.772.772 0 01.413-.135.645.645 0 01.43.15.079.079 0 01-.053.138M3.56 1.528a.078.078 0 01-.053-.02.488.488 0 00-.318-.11.626.626 0 00-.323.103.079.079 0 01-.096-.125.772.772 0 01.414-.135.645.645 0 01.43.15.079.079 0 01-.053.137"
              transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(44.936 4.538) translate(.052)"
            />
          </G>
        </G>
        <G fill="#66231C" fillRule="nonzero">
          <Path
            d="M7.005 5.693c0-.492-.11-1.114.185-1.36.295-.245.934-.663.565-1.18-.368-.515.05-1.523-.59-1.646-.638-.122-.665-.325-.837-.791C6.156.249 5.425.618 5.125.382 4.842.16 4.505-.223 3.721.17 3.299.382 2.579-.442 2.055.72c-.337.746-.692.093-1.179.48-.578.46-.015 1.134-.531 1.478-.516.344-.067.738-.253 1.126-.306.64.218 2.887.562 3.084.344.196.522.527.522.527s-.159-.539-.198-1.03c0 0-.266.066-.41-.409C.421 5.502.37 4.693.684 4.602c.314-.091.372.164.372.164s.103.391.044.69c-.06.298.134.477.238.03.105-.448.627-.463.523-1.075-.105-.612.104-.97.522-1.149.418-.179.537-1.09.895-1 .358.09.761-.298 1.313-.03.552.27.508.642.985.732.478.09.731.388.731.641 0 .254.329.344.418.702.09.358.011 1.42-.176 2.311 0 0 .455-.434.455-.925z"
            transform="translate(49 18) scale(-1 1) rotate(-7 43.813 767.172) translate(42.418)"
          />
        </G>
      </G>
    </Svg>
  );
};
