import { useQuery, useMutation } from '@apollo/react-hooks';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../../AppContext';
import {
  GroupCoreFields,
  JoinedGroup,
  ListedJoinedGroups,
  MarkAsSeenData,
  MarkAsSeenVariables,
} from '../../../common-types/types';
import { getAsyncStorageObject } from '../../../common-util';
import { LIST_GROUPS, MARK_GROUP_AS_SEEN } from '../../../graphql';
import { setActiveCommunity, setJoinedCommunities } from '../../../redux/feedSlice';
import { RootState, useAppDispatch } from '../../../redux/store';
import { sortByLatestPost } from '../helpers';

/**
 * This hook is used to handle the communities the user is a member of and the actions that can be performed on them
 * in the homescreen and desendant components.
 */
export const useCommunities = () => {
  const { user, isGuestMode } = React.useContext(AppContext);
  const navigation = useNavigation<any>();
  const [latestPost, setLatestPost] = useState(``);
  //FIXME: this triggers 2 errors on logout, query called with user_id but without access token
  const { data, loading, error, refetch } = useQuery<ListedJoinedGroups>(LIST_GROUPS, {
    //HACK: send current user id to invalidate app-side query cache between persona switches
    variables: { persona_id: user?.id },
    onError: (error) => console.error(`[CommunitiesError]`, error.message),
    onCompleted: (data) => {
      if (!data?.listJoinedGroups) return;
      const [latest] = data.listJoinedGroups.map((i: JoinedGroup) => i.group).sort(sortByLatestPost);
      if (latest?.latest_post) setLatestPost(latest.latest_post);
    },
    fetchPolicy: `cache-and-network`,
    skip: !user?.id || isGuestMode,
  });
  const [markAsSeen] = useMutation<MarkAsSeenData, MarkAsSeenVariables>(MARK_GROUP_AS_SEEN);
  const { activeCommunity } = useSelector((state: RootState) => state.feed);
  const dispatch = useAppDispatch();

  /**
   * Should only be called after leaving a community(reset active as lates community)
   * Set the most recent(latest_post) community as the active community
   * */
  const setActiveCommunityAfterArchive = React.useCallback(() => {
    // refetch is done by refetchQueriesFor(LIST_JOINED_GROUPS)
    // we just need to reorganize data if available
    if (!data) return;
    const mappedCommunities = data?.listJoinedGroups.map(({ group }) => group) || [];
    const joinedAndSortedGroups = mappedCommunities.sort(sortByLatestPost);
    dispatch(setJoinedCommunities(joinedAndSortedGroups));
    if (user?.id && joinedAndSortedGroups.length) {
      const [leftmost, ...rest] = joinedAndSortedGroups;
      if (leftmost.id !== activeCommunity?.id) {
        dispatch(setActiveCommunity({ user_id: user.id, group: leftmost, from: `AfterArchive` }));
      } else if (rest.length) {
        dispatch(setActiveCommunity({ user_id: user.id, group: rest[0], from: `AfterArchive` }));
      }
    }
  }, [data, dispatch, user?.id, activeCommunity?.id]);

  /**
   * Set the input community as active and marks it as seen.
   * The selected community will be stored in the redux store and saved to the AsyncStorage for later use
   * If the user pass a null community the default behavior will be select the first community of joinedCommunities which always will be
   * the community with most recent activity.
   * @param community Community to set at the top of the carousel list
   */
  const setCommunity = React.useCallback(
    (community?: GroupCoreFields) => {
      try {
        if (!user?.id || isGuestMode) return;
        if (community && community.id !== activeCommunity?.id) {
          //if different, forces a community change and mark it as seen
          markAsSeen({ variables: { group_id: community.id } });
          dispatch(setActiveCommunity({ user_id: user.id, group: community, from: `setCommunity` }));
        } else if (community && community.id === activeCommunity?.id) {
          navigation.navigate(`HomeScreen`);
        } else if (!community) {
          //leaved community, force a community change and set latest as active
          setActiveCommunityAfterArchive();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [navigation, user?.id, activeCommunity?.id, dispatch, setActiveCommunityAfterArchive, markAsSeen, isGuestMode],
  );

  const refreshCarouselItems = React.useCallback(
    async (onRefresh?: () => void) => {
      const response = await refetch();
      if (user?.id && response.data && onRefresh) onRefresh();
    },
    [refetch, user?.id],
  );

  React.useEffect(() => {
    // Whenever query updates, reorganize current carousel communities
    if (data?.listJoinedGroups) {
      const groups = data.listJoinedGroups.map(({ group }) => group) || [];
      const joinedAndSortedCommunities = groups.sort(sortByLatestPost);
      // update list of communities
      dispatch(setJoinedCommunities(joinedAndSortedCommunities));
    }
  }, [data?.listJoinedGroups, dispatch, latestPost]);

  React.useEffect(() => {
    const setFromLocal = async (user_id: string) => {
      const latestStored = await getAsyncStorageObject(`${user_id}_Latest`);
      if (latestStored && latestStored?.id)
        dispatch(setActiveCommunity({ user_id, group: latestStored, from: `useCommunities` }));
    };
    if (activeCommunity?.id) return;
    //if (!data?.listJoinedGroups || data?.listJoinedGroups.length === 0 || !user?.id) return;
    if (user?.id) {
      setFromLocal(user.id);
      return;
    }
    if (user?.id && data?.listJoinedGroups) {
      const [mostRecent] = data.listJoinedGroups;
      if (mostRecent?.group_member && mostRecent?.group?.id) {
        dispatch(setActiveCommunity({ user_id: user.id, group: mostRecent.group, from: `useCommunities.useEffect` }));
      } else console.warn(`data.listJoinedGroups is outdated (ref points to prev user)`);
    }
  }, [activeCommunity?.id, data?.listJoinedGroups, dispatch, user?.id]);

  // memo
  const memo_communities = React.useMemo(() => {
    if (!data?.listJoinedGroups) return [];
    const ordered = data?.listJoinedGroups.map(({ group }) => group);
    return ordered.sort((g1, g2) => {
      return new Date(g2.latest_post).getTime() - new Date(g1.latest_post).getTime();
    });
  }, [data?.listJoinedGroups]);

  // This is useful when the cache of the active community is updated
  const updatedActiveCommunity = memo_communities.find((item) => item.id === activeCommunity?.id);

  return {
    communities: memo_communities,
    loading,
    error,
    activeCommunity: updatedActiveCommunity,
    refreshCarouselItems,
    setCommunity,
    refetch,
    setActiveCommunityAfterArchive,
  };
};
