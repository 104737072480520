import React from 'react';
import { View, Text } from 'react-native';
import { Typography } from '../common-styles';
import { User } from '../common-types/types';

type SubtileProps = {
  persona: User;
};

export const PersonaSubtitle: React.FC<SubtileProps> = ({ persona }) => {
  const [mainPronoun] = persona?.pronouns || [];
  return (
    <View style={{ flexDirection: `row`, alignItems: `center` }}>
      <Text style={{ ...Typography.text(`gray`) }}>{persona?.handle}</Text>
      {!!mainPronoun && (
        <View style={{ flexDirection: `row`, justifyContent: `center`, alignItems: `center` }}>
          <Text style={{ ...Typography.text(`bold`, `large`) }}> · </Text>
          <Text style={{ ...Typography.text(`gray`) }}>{mainPronoun.pronoun}</Text>
        </View>
      )}
    </View>
  );
};
