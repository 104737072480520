import React, { useState, useEffect, useCallback } from 'react';
import { Text, View, ScrollView, TextInput, TouchableOpacity } from 'react-native';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { commonPropTypes } from '../common-util';
import { Colors, Typography } from '../common-styles';
import { LoadingIndicator, SafeAreaView, KeyboardPaddingView, ThemedButton, BottomSheetModal } from '../common-ui';
import { AppContext } from '../../AppContext';
import { refetchQueriesFor, SETTING_QUERY, UPDATE_SETTING_MUTATION, VERIFY, client, REQUEST_VERIFY_TOKEN } from '../graphql';

export const PhoneVerificationScreen = ({ navigation, route }) => {
  const { user, identity } = React.useContext(AppContext);
  const { formatedPhoneNumber } = route.params;

  const [verificationCode, setVerificationCode] = useState(``);
  const [error, setError] = useState(false);
  const [loadingResendSms, setLoadingResendSms] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showResendVerificationCodeModal, setShowResendVerificationCodeModal] = useState(false);

  const [requestVerifyToken] = useMutation(REQUEST_VERIFY_TOKEN, {
    refetchQueries: refetchQueriesFor(`Persona`, `Setting`),
  });

  const [verifyPhoneNumber] = useMutation(VERIFY, {
    refetchQueries: refetchQueriesFor(`Persona`, `Setting`),
  });

  const userAndGroup = {
    user_id: user?.id,
    group_id: undefined,
  };

  const { data } = useQuery(SETTING_QUERY, {
    fetchPolicy: `cache-and-network`,
    variables: {
      settingQuery: {
        query: `user`,
        ...userAndGroup,
      },
    },
  });

  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries: refetchQueriesFor(`Persona`, `Setting`),
  });

  const phoneNumberSetting = data?.getSettings.find((elem) => elem.key === `user.setting.phone_number`);
  const phoneNumberVerifiedSetting = data?.getSettings.find((elem) => elem.key === `user.setting.phone_number_verified`);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <ThemedButton
          clear
          title="Submit"
          containerStyle={{ right: 8 }}
          onPress={() => handleVerifyPhoneNumber()}
          disabled={verificationCode.length < 6}
          leftIcon={submitting && <LoadingIndicator />}
          disabledStyle={{ color: Colors.textDisabled }}
        />
      ),
    });
  }, [navigation, submitting, verificationCode, handleVerifyPhoneNumber]);

  const handleChangeText = (text) => {
    setVerificationCode(text);
    setError(false);
  };

  const handleVerifyPhoneNumber = useCallback(async () => {
    try {
      setSubmitting(true);
      const input = { id: identity?.id, verification_token: verificationCode };

      const { data } = await verifyPhoneNumber({
        variables: { input },
      });

      if (!data || !data?.VerifyAccount) setError(true);

      const input2 = {
        key: phoneNumberVerifiedSetting?.key,
        value: data?.VerifyAccount?.verified.toString(),
        id: phoneNumberVerifiedSetting?.id,
        user_id: user?.id,
      };

      const { data: updatedSettingData } = await updateSetting({ variables: { input: input2 } });

      const input3 = {
        key: phoneNumberSetting?.key,
        value: formatedPhoneNumber,
        id: phoneNumberSetting?.id,
        user_id: user?.id,
      };

      const { data: _updatedSettingPhoneData } = await updateSetting({ variables: { input: input3 } });

      const [retrievedSettingValue] = updatedSettingData?.updateSettings || [];

      if (retrievedSettingValue?.value === `true`) navigation.popToTop();
    } catch (error) {
      console.error(`An error ocurred in handleVerifyPhoneNumber()`, error);
    } finally {
      setSubmitting(false);
    }
  }, [
    navigation,
    verificationCode,
    identity,
    verifyPhoneNumber,
    phoneNumberVerifiedSetting,
    user,
    updateSetting,
    formatedPhoneNumber,
    phoneNumberSetting,
  ]);

  const resendPhoneNumberVerificationCode = async () => {
    try {
      setLoadingResendSms(true);
      setShowResendVerificationCodeModal(true);

      // Here we must call the same function to resend the phone number verification code.
      const { data } = await requestVerifyToken({
        variables: { id: identity?.id, contact: formatedPhoneNumber },
      });
      console.log(`🚀 🚀  ->  ~ resendPhoneNumberVerificationCode ~ data`, data);
    } catch (error) {
      console.error(`resendEmailVerificationCode`, error);
    } finally {
      setLoadingResendSms(false);
      setShowResendVerificationCodeModal(false);
    }
  };

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1, backgroundColor: Colors.white, width: `100%` }}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={localStyles.container}>
            <View>
              <View style={localStyles.blob} />
              <Text allowFontScaling={false} style={localStyles.headerText}>
                Enter your verification code
              </Text>
            </View>
            {formatedPhoneNumber && (
              <Text style={localStyles.subtext}>Sent to {formatedPhoneNumber} This code is valid for 10 minutes.</Text>
            )}
            <TextInput
              blurOnSubmit
              autoFocus
              value={verificationCode}
              onChangeText={handleChangeText}
              placeholder="______"
              placeholderTextColor={Colors.textPlaceholder}
              keyboardType="number-pad"
              style={localStyles.inputStyle}
              maxLength={6}
              returnKeyType="done"
              clearButtonMode="always"
            />

            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => resendPhoneNumberVerificationCode()}
              style={{ marginTop: 24 }}
              disabled={loadingResendSms}
            >
              <Text style={localStyles.requestNewCodeText}>Request a new verification code</Text>
            </TouchableOpacity>

            {error && (
              <View style={localStyles.errorBox}>
                <Text style={localStyles.errorBoxText}>
                  This code is invalid. Please double check the code or request a new one
                </Text>
              </View>
            )}

            {showResendVerificationCodeModal && (
              <BottomSheetModal
                title="Verification code sent"
                visible={!!showResendVerificationCodeModal}
                onPressCancel={() => setShowResendVerificationCodeModal(false)}
                showCancelBtn={false}
                confirmTitle="Ok"
                onPressConfirm={() => setShowResendVerificationCodeModal(false)}
              >
                <View style={{ marginHorizontal: 16 }}>
                  <Text style={{ marginTop: 16, marginBottom: 32, textAlign: `center` }}>
                    We have sent you a new verification code
                  </Text>
                </View>
              </BottomSheetModal>
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

PhoneVerificationScreen.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
};

const localStyles = {
  container: {
    flex: 1,
    margin: 15,
    marginTop: 20,
  },
  errorBox: {
    width: `100%`,
    height: 53,
    backgroundColor: Colors.deleteChannelBackgroundColor,
    borderRadius: 4,
    marginTop: 13,
  },
  errorBoxText: {
    ...Typography.text(`red`, `small`),
    marginLeft: 13,
    marginTop: 10,
    marginRight: 12,
  },
  headerText: {
    ...Typography.text(`large`, `black`, `bold`),
  },
  subtext: {
    ...Typography.text(`base`, `gray`),
    marginTop: 15,
    marginBottom: 24,
  },
  inputStyle: {
    padding: 10,
    borderColor: Colors.brandPurple,
    borderWidth: 1,
    borderRadius: 4,
    letterSpacing: 3,
  },
  errorContainer: {
    padding: 10,
    borderRadius: 4,
    marginTop: 10,
  },
  errorText: {
    ...Typography.text(`small`, `red`, `bold`),
  },
  blob: {
    flex: 1,
    position: `absolute`,
    height: 25,
    width: 25,
    backgroundColor: `rgba(79,206,221,0.7)`,
    borderTopStartRadius: 50,
    borderTopEndRadius: 18,
    borderBottomStartRadius: 30,
    borderBottomEndRadius: 25,
    left: -10,
    top: 2,
  },
  requestNewCodeText: {
    ...Typography.text(`base`, `theme`, `bold`),
  },
  meshHeaderText: {
    left: -14,
    bottom: 4,
    fontWeight: `bold`,
    color: Colors.darkestGray,
  },
  headerRightButtonText: {
    ...Typography.text(`base`, `bold`),
    marginLeft: 4,
  },
  wizardProgressContainer: {
    marginTop: 15,
  },
};
