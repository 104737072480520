import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreLiterature = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <Rect x={0} y={0} width={163} height={98} rx={7} fill="#ECE8F6" />
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#4722A7">
          <TSpan x={12} y={22}>
            Literature
          </TSpan>
        </Text>
        <Path
          d="M108.022 86.857c18.212-1.716 33.285-14.087 39.89-29.855 6.604-15.769-18.265-29.203-36.176-29.973C93.825 26.259 79.999 41.012 80 57.817c0 16.805 9.81 30.756 28.022 29.04z"
          fill="#4722A7"
          style={{
            mixBlendMode: `multiply`,
          }}
          transform="rotate(180 114.5 57)"
        />
        <G transform="rotate(-10 237.45 -587.223)">
          <Path fill="#3973B0" d="M0.0987496054 2.87128685L11.0987496 6.35198001 11.0987496 24.8712868 0.0987496054 21.390782z" />
          <Path
            fill="#3973B0"
            transform="matrix(1 0 0 -1 0 27.97)"
            d="M12.263739 2.98544947L23.263739 6.46614263 23.263739 24.9854495 12.263739 21.5049447z"
          />
          <Path
            d="M12.913 24.72s-.384.272-1.055.272c-.578 0-.945-.272-.945-.272V5.992s.76.202.983.202c.278 0 1.017-.202 1.017-.202V24.72z"
            fill="#285483"
            fillRule="nonzero"
          />
          <Path
            stroke="#FFF"
            strokeWidth={1.69352107}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="rotate(-7 5.985 3.556)"
            d="M3.10292416 1.0562635L10.9848208 6.0562635 0.984820779 1.71838548"
          />
          <Path
            stroke="#FFF"
            strokeWidth={1.69352107}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="rotate(8 17.255 3.78)"
            d="M20.1369334 1.28056219L12.2549984 6.28056219 22.2549984 1.94268417"
          />
        </G>
        <G transform="translate(83.92 52.56)">
          <Path
            stroke="#FFF"
            strokeWidth={1.69}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M24.4432103 10.0801515L11.6180354 21.8983333"
          />
          <Path
            stroke="#FFF"
            strokeWidth={1.69}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.07957389 17.44L11.0795739 22.44"
          />
          <Path
            fill="#5BAC68"
            transform="scale(1 -1) rotate(-47 -16.406 0)"
            d="M6.97446737 1.25L18.416994 4.85849889 18.416994 24.0578063 6.97446737 20.4495027z"
          />
        </G>
        <G transform="rotate(-69 117.268 -46.781)">
          <Path
            stroke="#FFF"
            strokeWidth={1.69}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M22.9886648 10.0309091L11.0795739 20.94"
          />
          <Path
            stroke="#FFF"
            strokeWidth={1.69}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M1.57957389 16.44L10.6825894 21.2592435"
          />
          <Path
            fill="#D7C8F1"
            transform="scale(1 -1) rotate(-47 -15.75 0)"
            d="M6.69548867 1.2L17.6803142 4.66415894 17.6803142 23.0954941 6.69548867 19.6315226z"
          />
        </G>
      </G>
    </Svg>
  );
};
