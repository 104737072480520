import { useMutation } from 'react-apollo';
import { useCallback } from 'react';
import { Comment } from '../../common-types/types';
import {
  client,
  GET_CUSTOM_REACTION_COUNTS,
  REACTION_DETAILS_FRAGMENT,
  REMOVE_COMMENT_REACTION,
  ADD_COMMENT_REACTION,
} from '../../graphql';

type AddReactionInput = {
  reaction_id: string;
  group_id: string;
};

type RemoveReactionInput = Pick<AddReactionInput, `reaction_id`>;

export const useCommentReaction = (parent_id: string, comment: Comment) => {
  const onComplete = useCallback(
    (own_like: boolean) => {
      client.writeFragment({
        id: `ActivityReaction:${parent_id}`,
        fragment: REACTION_DETAILS_FRAGMENT,
        fragmentName: `ReactionDetails`,
        data: { ...comment, own_like },
      });
    },
    [comment, parent_id],
  );

  // response is not necessary to process results hence typed any
  const [addReaction] = useMutation<any, AddReactionInput>(ADD_COMMENT_REACTION, {
    refetchQueries: [
      {
        query: GET_CUSTOM_REACTION_COUNTS,
        variables: { input: { parent_id, parent_kind: `comment` } },
      },
    ],
    onCompleted: () => onComplete(true),
  });

  // response is not necessary to process results hence typed any
  const [removeReaction] = useMutation<any, RemoveReactionInput>(REMOVE_COMMENT_REACTION, {
    //FIXME: Invariant Violation: Expecting a parsed GraphQL document. Perhaps you need to wrap the query string in a "gql" tag? http://docs.apollostack.com/apollo-client/core.html#gql
    // query completes and refetch also completes, cache is updated but for some reason it still rejects a promise
    refetchQueries: [
      {
        query: GET_CUSTOM_REACTION_COUNTS,
        variables: { input: { parent_id, parent_kind: `comment` } },
      },
    ],
    onCompleted: () => onComplete(false),
  });

  return { addReaction, removeReaction };
};
