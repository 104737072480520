import PropTypes from 'prop-types';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { setPreviewCommunity } from '../redux/feedSlice';
import { Colors, Typography } from '../common-styles';
import { MeshIcon } from '../common-ui';
import { formatShortRelativeDate } from '../common-util';
import { MODERATION_ACTIONS } from '../moderation/moderationEnums';
import { BaseNotificationItem } from './BaseNotificationItem';
import { notificationStyles } from './notificationItemStyles';
import { showNotImplementedOptions } from './notificationsTempConstants';
import { hasValidUrl } from '../post/helpers';
import { CREATE_LINK_PREVIEW } from '../graphql';
import { MeshVerifiedCheck } from '../images';

const allowedNotifications = {
  approvePost: MODERATION_ACTIONS.post_approve,
  like: `like`,
  comment: `comment`,
  commentLike: `comment_like`,
  commentReply: `comment_reply`,
  post: `post`,
};

export const allowed_types = Object.values(allowedNotifications);

export const ActivityNotificationItem = ({ notification, activity, type, markRead, onPressOptionsButton }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const onPress = () => {
    const activity = notification.activities[0];
    if (!notification.is_read) {
      markRead(notification.id);
    }
    if (activity.origin_group_member && activity.origin_group_member.banned) {
      console.log(`user is banned`);
      return null;
    }

    if (notification?.isMention && !activity?.origin_group_member) {
      dispatch(setPreviewCommunity(activity.origin_group));
      navigation.navigate(`CommunityPreview`);
      return null;
    }

    /* const type = allowedNotifications.approvePost; */
    switch (type) {
      case allowedNotifications.approvePost:
        return goToPost(activity.origin_activity_id);
      case allowedNotifications.like:
        return goToPost(activity.reaction.activity_id);
      case allowedNotifications.comment:
        return goToPost(activity.reaction.activity_id);
      case allowedNotifications.commentReply:
        return goToPost(activity.reaction.activity_id);
      case allowedNotifications.commentLike:
        return goToPost(activity.reaction.activity_id);

      default:
        return null;
    }
  };

  const goToPost = (id) => {
    // console.log('id in goToPost', id);
    if (!id) {
      console.log(`no activity id passed to goToPost`);
      return;
    }

    if (notification?.verb?.includes(`comment`)) {
      navigation.push(`PostDetail`, {
        id,
        group_id: activity.origin_group.id,
        reply_id: activity?.reaction?.id,
        addComment: true,
      });
    } else {
      navigation.push(`PostDetail`, { id, group_id: activity.origin_group.id, addComment: true });
    }
  };

  const handlePressOptionsButton = () => {
    onPressOptionsButton(notification);
  };

  // const getDescription = () => {
  //   switch (type) {
  //     case allowedNotifications.approvePost:
  //       return null;

  //     default:
  //       return <View style={notificationStyles.previewContainer}>{renderPreview(notification)}</View>;
  //   }
  // };

  const getRightActions = () => {
    const { activity_count } = notification;
    switch (type) {
      case allowedNotifications.comment:
      case allowedNotifications.commentReply:
        return (
          <View style={{ flexDirection: `row` }}>
            <TouchableOpacity onPress={onPress}>
              <View style={{ ...notificationStyles.rightActionsIconHolder, marginRight: 21 }}>
                <MeshIcon size={18} name="heart" focused color={Colors.heartColor} style={notificationStyles.rightActionsIcon} />
                <Text maxFontSizeMultiplier={1.8} style={{ minWidth: 8, color: Colors.heartColor }}>
                  3
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={onPress}>
              <View style={notificationStyles.rightActionsIconHolder}>
                <MeshIcon size={16} name="comment" color={Colors.iconColor} style={notificationStyles.rightActionsIcon} />
                <Text maxFontSizeMultiplier={1.8} style={{ minWidth: 8, color: Colors.iconColor }}>
                  {activity_count}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        );

      default:
        return null;
    }
  };

  const getBadge = () => {
    /* const type = allowedNotifications.approvePost; */
    switch (type) {
      case allowedNotifications.approvePost:
        return `check`;

      case allowedNotifications.like:
      case allowedNotifications.commentLike:
        return `like`;

      case allowedNotifications.comment:
      case allowedNotifications.commentReply:
        return `comment`;

      default:
        return null;
    }
  };

  const getAvatarInfo = () => {
    const avatarInfo = {
      data: null,
      type: null,
    };
    switch (type) {
      case allowedNotifications.approvePost:
        avatarInfo.data = activity.origin_group;
        avatarInfo.type = `community`;
        return avatarInfo;

      default:
        avatarInfo.data = activity.user;
        avatarInfo.type = `persona`;
        return avatarInfo;
    }
  };

  // eslint-disable-next-line complexity
  const renderNotificationText = (notification) => {
    const { isMention, actor_count, activities } = notification;
    const [activity] = activities || [];
    const group = activity.origin_group || {};
    const group_name = !!group.name && group.name.trim();
    const user = activity.user || {};
    const user_name = (!!user.handle && user.handle.trim()) || (!!user.name && user.name.trim());

    const userNameText = (
      <>
        <Text onPress={() => navigation.navigate(`Profile`, { user })} maxFontSizeMultiplier={1.8} style={Typography.add(`bold`)}>
          {user_name}
        </Text>
        {user.isVerified && <MeshVerifiedCheck height={12} width={12} style={{ marginRight: 5, marginLeft: 2 }} />}
      </>
    );
    const groupNameText = (
      <Text maxFontSizeMultiplier={1.8} style={Typography.add(`bold`)}>
        {group_name}
      </Text>
    );
    const others = actor_count === 1 ? `other` : `others`;
    const andOthers = actor_count > 1 ? ` and ${actor_count - 1} ${others}` : ``;

    if (isMention) {
      return (
        <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
          {userNameText} mentioned you in a {type === allowedNotifications.post ? `post` : `comment`} in {groupNameText}
        </Text>
      );
    }

    if (type === allowedNotifications.approvePost) {
      return (
        <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
          Your post has been approved in {groupNameText}
        </Text>
      );
    }

    if (type === allowedNotifications.comment || type === allowedNotifications.commentReply) {
      const what = type === allowedNotifications.commentReply ? `replied to your comment` : `commented on your post`;
      return (
        <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
          {userNameText}
          {andOthers} {what} in {groupNameText}
        </Text>
      );
    }

    if (type === allowedNotifications.like || type === allowedNotifications.commentLike) {
      const what = type === allowedNotifications.commentLike ? `comment` : `post`;
      return (
        <Text maxFontSizeMultiplier={1.8} style={Typography.text()}>
          {userNameText}
          {andOthers} liked your {what} in {groupNameText}
        </Text>
      );
    }

    return null;
  };

  // const renderPreview = () => {
  //   const { preview } = notification;
  //   let { image_previews } = notification;

  //   if (image_previews) image_previews = image_previews.filter((item) => !item.includes(`null`));

  //   if (!preview && !image_previews) {
  //     return null;
  //   }
  //   return (
  //     <View>
  //       {preview ? <PreviewText text={preview} /> : null}
  //       {image_previews && <SmallImagePreview image_previews={image_previews} />}
  //     </View>
  //   );
  // };

  // if (!allowed_types.includes(type)) return null;
  // if (!activity || !activity.origin_group)  return null;

  const avatarInfo = getAvatarInfo();

  let notificationTime = ``;
  if (notification.updated_at) notificationTime = notification.updated_at;
  else if (activity.time) notificationTime = activity.time;
  else if (activity.reaction.created_at) notificationTime = activity.reaction.created_at;
  else notificationTime = notification.created_at;
  if (!notificationTime.endsWith(`Z`)) notificationTime = `${notificationTime}Z`;

  return (
    <BaseNotificationItem
      containerStyle={{
        backgroundColor: notification.is_read ? Colors.seenNotificationBackground : Colors.unseenNotificationBackground,
      }}
      title={<View style={{ marginBottom: 0 }}>{renderNotificationText(notification)}</View>}
      // description={getDescription()}
      date={formatShortRelativeDate(notificationTime)}
      RightActions={!!showNotImplementedOptions && getRightActions()}
      avatarData={avatarInfo.data}
      avatarType={avatarInfo.type}
      avatarSize={50}
      badgeSize={22}
      navigation={navigation}
      badge={getBadge()}
      showMoreOptionsButton
      onPressOptionsButton={handlePressOptionsButton}
      onPress={onPress}
      isSameStack={true}
    />
  );
};

ActivityNotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  markRead: PropTypes.func.isRequired,
  onPressOptionsButton: PropTypes.func,
};

ActivityNotificationItem.defaultProps = {
  onPressOptionsButton: () => {},
};

const PreviewText = ({ text }) => {
  const [result, setResult] = useState(text);
  const [validLink, setValidLink] = useState(``);

  const [requestLinkPreview, { data: linkPreview, loading: gettingPreview }] = useMutation(CREATE_LINK_PREVIEW);

  // Formatted Text Feature
  // 1. Convert URL to linked title

  useEffect(() => {
    const validUrl = hasValidUrl(text);
    if (validUrl) {
      setValidLink(validUrl);
      requestLinkPreview({ variables: { link: validUrl } });
    }
  }, [text, requestLinkPreview]);

  useEffect(() => {
    if (linkPreview && validLink) {
      if (text.startsWith(validLink) || text.endsWith(validLink)) {
        const temp = text.replace(validLink, linkPreview?.createLinkPreview?.title);
        setResult(temp);
      }
    }
  }, [linkPreview, validLink, text]);

  if (gettingPreview) {
    return <ActivityIndicator />;
  }

  if (result && result.length > 200) {
    return (
      <Text maxFontSizeMultiplier={1.8} style={{ ...Typography.text(`darkGray`), paddingTop: 5 }}>
        {`${result.substring(0, 180 - 1)} ...(show more)`}
      </Text>
    );
  }
  return (
    <Text maxFontSizeMultiplier={1.8} style={{ ...Typography.text(`darkGray`), paddingTop: 5 }}>
      {result}
    </Text>
  );
};

PreviewText.propTypes = {
  text: PropTypes.string.isRequired,
};
