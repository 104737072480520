/* eslint-disable no-param-reassign */
/**
 * We disable the no-param-reassign because redux-toolkit is using under the hood immer
 * so they take care of the innmutability of the state.
 * */

import { createSlice } from '@reduxjs/toolkit';
import { GroupCoreFields, PaymentMethod } from '../common-types/types';

export type ContributionState = {
  amount: number;
  group: GroupCoreFields | null;
  customer: {
    id: string;
  } & any;
  email: string;
  loading: boolean;
  customerId: string;
  defaultPaymentMethod?: PaymentMethod | null;
  paymentMethods: PaymentMethod[];
  showThanksModal: boolean;
  contributionTileFlag: boolean;
  last4DigitsFlag: boolean;
  last4Digits: string;
  userEmailVerified: boolean;
  communityEmailverified: boolean;
  cachedGroupId: string;
};

const initialState: ContributionState = {
  amount: 0,
  group: null,
  customer: null,
  email: ``,
  loading: false,
  customerId: ``,
  defaultPaymentMethod: null,
  paymentMethods: [],
  showThanksModal: false,
  contributionTileFlag: false,
  last4DigitsFlag: false,
  last4Digits: ``,
  userEmailVerified: false,
  communityEmailverified: false,
  cachedGroupId: ``,
};

const contributionsSlice = createSlice({
  name: `contributions`,
  initialState,
  reducers: {
    setGroup: (state, action) => {
      state.group = action.payload;
    },

    setAmount: (state, action) => {
      state.amount = action.payload;
    },

    setEmail: (state, action) => {
      state.email = action.payload;
    },

    setCustomer: (state, action) => {
      state.customer = action.payload;
      state.customerId = action.payload?.id;
      state.defaultPaymentMethod = action.payload?.invoice_settings?.default_payment_method;
    },

    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },

    setDefaultPaymentMethod: (state, action) => {
      state.defaultPaymentMethod = action.payload;
    },

    setShowThanksModal: (state, action) => {
      state.showThanksModal = action.payload;
    },

    setContributionTileFlag: (state, action) => {
      state.contributionTileFlag = action.payload;
    },

    setLast4DigitsFlag: (state, action) => {
      state.last4DigitsFlag = action.payload;
    },

    setLast4Digits: (state, action) => {
      state.last4Digits = action.payload;
    },

    setUserEmailVerified: (state, action) => {
      state.userEmailVerified = action.payload;
    },

    setCommunityEmailVerified: (state, action) => {
      state.communityEmailverified = action.payload;
    },

    finishContribution: (state) => {
      state.amount = 0;
      state.group = null;
      state.loading = false;
      state.showThanksModal = true;
      state.contributionTileFlag = false;
    },

    setCachedGroupId: (state, action) => {
      state.cachedGroupId = action.payload;
    },
  },
});

export const {
  setGroup,
  finishContribution,
  setAmount,
  setCustomer,
  setEmail,
  setPaymentMethods,
  setShowThanksModal,
  setDefaultPaymentMethod,
  setContributionTileFlag,
  setLast4DigitsFlag,
  setLast4Digits,
  setUserEmailVerified,
  setCommunityEmailVerified,
  setCachedGroupId,
} = contributionsSlice.actions;

const contributionsReducer = contributionsSlice.reducer;

export { contributionsReducer };
