import PropTypes from 'prop-types';
import { Alert, Image, ScrollView, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Divider, OverlayModal, ThemedButton } from '../common-ui';
import { commonPropTypes } from '../common-util';
import { client } from '../graphql';
import { chatClient } from './chatClient';
import { PERSONA_RELATIONSHIP_PERMISSIONS } from './chatHelpers';
import { AppContext } from '../../AppContext';
import { ChatMenuOption } from './ChatMenuOption';
import {
  checkPersonaRelationship,
  deleteChannel,
  getCurrentChatUserInfo,
  updateChannelMuteStatus,
  updatePersonaRelationship,
} from './chatServices';
import { MODAL_SWITCH_TIME } from '../../constants';
import { chatModalStyles } from './styles';

export const ChatUserOverlaySettings = ({
  optionsModal,
  toggleOptionsModal,
  channel,
  toggleShowAddMembers,
  theme,
  otherMembers,
  navigation,
}) => {
  const { user: currentUser } = React.useContext(AppContext);

  const [photoSharingEnabled, setPhotoSharingEnabled] = useState(false);
  const [channelMuted, setChannelMuted] = useState(false);
  const [otherMember, setOtherMember] = useState({});

  useEffect(() => {
    const currentChatUser = getCurrentChatUserInfo();
    const { channel_mutes } = currentChatUser;
    if (Array.isArray(channel_mutes) && channel_mutes.length > 0) {
      // check to see if this channel is muted by current user
      if (channel_mutes.find((item) => item.channel.id === channel.id)) {
        // channel is muted
        setChannelMuted(true);
      }
    }

    const memberIds = otherMembers.map((item) => item.id);

    const checkOwnPersonaRelationshipSetting = async (other_persona_ids, check_own) => {
      const check_rows = await checkPersonaRelationship(other_persona_ids, check_own);
      // console.log('check_rows', check_rows);
      // check permissions of current user TO other member for photo share
      if (otherMembers.length === 1) {
        setOtherMember(otherMembers[0]);
        if (check_rows.some((item) => item.other_persona_id === otherMembers[0].id)) {
          const found = check_rows.find((item) => item.other_persona_id === otherMembers[0].id);
          const { permissions } = found;
          if (
            Array.isArray(permissions) &&
            permissions.find((item) => {
              if (item && item === PERSONA_RELATIONSHIP_PERMISSIONS.photo_sharing_enabled) {
                return true;
              }
              return false;
            })
          ) {
            console.log(`ALLOW PHOTO SHARE FROM OTHER USER`);
            setPhotoSharingEnabled(true);
          }
        }
      }
      return undefined;
    };

    checkOwnPersonaRelationshipSetting(memberIds, true);
  }, [channel.id, otherMembers]);

  //#region setting functions
  const updatePhotoSharingPermission = async (bool) => {
    try {
      if (bool === true) {
        const newPermissions = [...otherMember.permissions, PERSONA_RELATIONSHIP_PERMISSIONS.photo_sharing_enabled];

        const updated = await updatePersonaRelationship(otherMember.id, newPermissions, false);

        setPhotoSharingEnabled(true);
        setOtherMember({ ...otherMember, permissions: newPermissions });
        return updated;
      }

      const newPermissions = otherMember.permissions.filter(
        (item) => item !== PERSONA_RELATIONSHIP_PERMISSIONS.photo_sharing_enabled,
      );
      const updated = await updatePersonaRelationship(otherMember.id, newPermissions, false);
      setPhotoSharingEnabled(false);
      setOtherMember({ ...otherMember, permissions: newPermissions });
      return updated;
    } catch (err) {
      console.error(`error thrown in update photo sharing`);
      return null;
    }
  };

  const updateMuteStatus = async (bool) => {
    try {
      await updateChannelMuteStatus(channel.type, channel.id, bool);
    } catch (err) {
      console.error(`error thrown in updateMuteStatus(): `, err);
    }
  };

  // this current just adds someone and doesn't invite yet. working on invite vs. raw add
  const inviteSomeoneToChannel = () => {
    toggleOptionsModal();
    setTimeout(() => {
      toggleShowAddMembers();
    }, MODAL_SWITCH_TIME);
  };

  const _deleteChannel = () => {
    Alert.alert(
      `Are you sure you want to delete this channel?`,
      `Press OK to confirm deletion`,
      [
        {
          text: `Cancel`,
          style: `cancel`,
          onPress: () => {
            console.log(`canceled`);
          },
        },
        {
          text: `OK`,
          style: `default`,
          onPress: async () => {
            try {
              console.log(`deleting...`);
              const deleted = await deleteChannel(channel.type, channel.id);
              toggleOptionsModal();
              navigation.goBack();
              return deleted;
            } catch (err) {
              console.error(`error deleting channel`, err);
              return null;
            }
          },
        },
      ],
      { cancelable: true },
    );
  };

  const blockUser = () => {
    Alert.alert(
      `Are you sure you want to block ${otherMember.name}?`,
      `You will no longer see each other's posts or be able to chat. You can manage your Blocked Users in your Profile Settings.`,
      [
        {
          text: `Cancel`,
          style: `cancel`,
          onPress: () => console.log(`canceled`),
        },
        {
          text: `Block User`,
          style: `default`,
          onPress: async () => {
            try {
              const blocked = await updatePersonaRelationship(currentUser?.id, otherMember.id, otherMember?.permissions, true);
              // mute user in stream-chat and then apply filter to channelList view
              await chatClient.muteUser(otherMember.id);
              // mute channel
              await updateMuteStatus(true);
              // delete this channel with blocked user

              toggleOptionsModal();
              client.reFetchObservableQueries();
              navigation.goBack();
              return blocked;
            } catch (err) {
              console.error(`error in blockUser(): `, err);
              return null;
            }
          },
        },
      ],
      { cancelable: true },
    );
  };

  //#endregion

  return (
    <OverlayModal
      isVisible={optionsModal}
      toggle={toggleOptionsModal}
      containerStyle={chatModalStyles.modalContainerStyle}
      swipeToDismiss
    >
      <View style={{ flex: 1 }}>
        <View style={chatModalStyles.headerContainer}>
          <Text style={chatModalStyles.headerText}>{otherMember.handle || otherMember.name}</Text>
          <ThemedButton clear title="Done" onPress={toggleOptionsModal} />
        </View>
        <ScrollView>
          <Image
            source={{
              uri: otherMember.avatar_url || ``,
            }}
            resizeMode="cover"
            style={chatModalStyles.image}
          />
          <View style={{ margin: 10 }}>
            <Text style={chatModalStyles.memberNameText}>{otherMember.name}</Text>
            <Text style={chatModalStyles.memberDesciptionText}>{otherMember.description}</Text>
          </View>
          <Divider />
          <View style={{ marginBottom: 10 }}>
            <ChatMenuOption divider title="Add to chat" theme={theme} handleOnPress={inviteSomeoneToChannel} />
            <ChatMenuOption
              divider
              hasSwitch
              value={channelMuted}
              changeSwitchValue={updateMuteStatus}
              title="Hide alerts"
              theme={theme}
            />
            <ChatMenuOption
              divider
              hasSwitch
              value={photoSharingEnabled}
              changeSwitchValue={updatePhotoSharingPermission}
              title="Photo sharing"
              theme={theme}
            />
            <ChatMenuOption divider title="Block user" theme={theme} handleOnPress={blockUser} />
            <ChatMenuOption title="Delete channel" theme={theme} handleOnPress={_deleteChannel} />
          </View>
        </ScrollView>
      </View>
    </OverlayModal>
  );
};
ChatUserOverlaySettings.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  optionsModal: PropTypes.bool.isRequired,
  toggleOptionsModal: PropTypes.func.isRequired,
  toggleShowAddMembers: PropTypes.func.isRequired,
  channel: PropTypes.object.isRequired,
  theme: commonPropTypes.theme().isRequired,
  otherMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
};
