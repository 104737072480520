// colors
import Color from 'color';

export const black = `#000000`;
export const gray = `#827C7C`;
export const darkestGray = `#29292c`;
export const darkGray = `#3d3e44`;
export const mediumDarkGray = `#696A6A`;
export const mediumGray = `#868686`; // lightest gray that should be used for text
export const mediumLightGray = `#a6a6a6`;
export const mediumLighterGray = `#c0c0c0`;
export const lightGray = `#EDECEE`;
export const lightWarmGray = `#f5f5f5`;
export const commentGray = `#F0F0F0`;
export const translucentBlack = `rgba(9, 1, 7, 0.42)`;
export const translucentCloseIcon = `rgba(0,0,0,0.7)`;
export const translucentGray = `rgba(55,50,50,0.6)`;
export const brickRed = `#C23232`;
export const brightRed = `#EA2323`;
export const translucentBrightRed = `rgba(234,35,35,0.15)`;
export const paleLightRed = `#FEF4F4`;
export const mustardYellow = `#FFC300`;
export const paleYellow = `#FFEAA4`;
export const mintGreen = `#88D6A4`;
export const materialGreen = `#239B56`;
export const materialYellow = `#F4D03F`;
export const materialBlue = `#3498DB`;
export const hotPink = `#EA236C`;
export const deepPink = `#CB3666`;
export const chartreuse = `#F2E54D`;
export const taupe = `#BEB9B8`;
export const deepPurple = `#333895`;
export const cautionYellow = `#ECDE3A`;
export const teal = `#006273`;
export const orange = `#FF563D`;
export const purpleGrey = `#E8E9FF`;

// badge colors
export const badgeComment = `#4FCEDD`;
export const badgeCommentBackground = `#DAFBFF`;
export const badgeLike = `#EA236C`;
export const badgeLikeBackground = `#FCDEE9`;
export const badgeCheck = `#2C9A44`;
export const badgeCheckBackground = `#C6FFD3`;
export const badgeModeration = `#E59611`;
export const badgeModerationBackground = `#FFF7AE`;
export const badgePlusBackground = `#E8E9FF`;
export const badgePlus = `#333895`;
export const badgeWarning = `#FF563D`;
export const badgeWarningBackground = `#FFE6E2`;
export const badgeDefaultBackground = `#ffffff`;

// notification status colors
export const seenNotificationBackground = `#ffffff`;
export const unseenNotificationBackground = `#F5F3F7`;
export const underlayColorNotification = `#F5F3F7`;

// switch colors
export const switchBackground = `#a6a6a6`;
export const switchThumbColor = `#ffffff`;
export const switchActiveTrackColor = `#2C9A44`;
export const switchInactiveTrackColor = `#a6a6a6`;

export const avatarBorderColor = `#EFEDED`;
export const avatarShadowColor = `#000000`;
export const avatarContainerColor = `#FFFFFF`;

export const logoBackground = `#ffffff`;
export const placeholderVideoColor = `#DCDDEA`;

export const valuesOfMeshBoxColor = `#EDECEE`;
export const unacceptableBehaviorsBoxColor = `#FFF2F7`;

//chat
export const chatBadgeColor = `#333895`;
export const chatPreviewUnderlayColor = `#F5F3F7`;
export const chatPreviewBackground = `#FFFFFF`;
export const chatPreviewMuteButton = `#E8E9FF`;
export const chatPreviewDeleteButton = `#F15B57`;
export const ownchatMessageGradientColors = [`#C7AAE0`, `#AC91C3`, `#D9A7E3`];
export const otherchatMessageBackground = `#F2F1F1`;
export const chatMessageBackground = `#eae1f0`;
export const chatSendIconColor = `#AFADAD`;
export const chatSendIconFocusedColor = `#333895`;
export const chatCameraIconColor = `#9B88AF`;
export const chatRetryIconColor = `#F15B57`;
export const chatNewMessagesButtonColor = `#333895`;
export const chatEditOverlayColor = `#000000`;
export const chatTextInputDefaultBorderColor = `#D8D8D8`;
export const chatTextInputFocusedBorderColor = `#333895`;
export const chatTypingIndicatorContainer = `#F8F8F8`;
export const chatTypingIndicatorDot1 = `#EA236C`;
export const chatTypingIndicatorDot2 = `#4FCEDD`;
export const chatTypingIndicatorDot3 = `#EEDE4C`;
export const deleteChannelBackgroundColor = `#FBDADA`;
export const deleteChannelTextColor = `#EA2323`;
export const blockUserBackgroundColor = `#FBDADA`;
export const blockUserTextColor = `#EA2323`;

//warning box
export const warningBoxBackground = `#DFF6F9`;
export const warningBoxTextColor = `#2A98A5`;

export const textBlack = `#373232`;
export const textGray = `#827C7C`;
export const textWhite = `#FFFFFF`;
export const textRed = `#EA2323`;
export const textPlaceholder = mediumGray;
export const textDisabled = taupe;

export const disabledIconColor = `#DEDCDB`;
export const focusedIconColor = `#333895`;
export const iconColor = `#9B88AF`;
export const heartColor = `#EA236C`;
export const heartBackground = `#FCDEE9`;

export const headerBottomBorderColor = `#D8D8D8`;
export const cancelButton = `#E5E5E5`;
export const moreButton = `#D8D8D8`;

export const buttonColor = `#333895`;
export const alertColor = `#F15B57`;
export const buttonTextColor = `#ffffff`;

export const scrollDotColor = `#E8E9FF`;
export const focusedScrollDotColor = `#333895`;

export const transparent = `rgba(0, 0, 0, 0)`;
export const paymentTransparentGray = `rgba(55,50,50,0.5)`;
export const white = `#ffffff`;

export const warningBackground = `#DFF6F9`;
export const warningText = `#2A98A5`;

export const dividerColor = `#EDECEE`;
export const imageBorderColor = `rgba(190,185,184,0.2)`;
export const badgeColor = `#EA236C`;
export const underlayColor = `rgba(190,185,184,0.4)`;

export const adButtonBackground = `#E8E9FF`;
export const adButtonText = `#333895`;

export const brokenImageBackground = `#DEDCDB`;

export const linkColor = `#333895`;
export const linkPreviewBackground = `#F2F1F1`;

export const deletedBorder = `#F2F1F1`;
export const deletedBackground = `#FFFFFF`;

export const validText = `#2C9A44`;
export const invalidText = `#F15B57`;

// logo colors from our new branding
export const brandPink = `#FD5794`;
export const brandPurple = `#333895`;
export const brandCyan = `#1AD4E5`;
export const brandGreen = `#029102`;
export const brandChartreuse = `#F2E35A`;
export const brandCoral = `#FF563D`;
export const brandBlack = `#373232`;

// old Mesh brand color -- a deep purple
export const oldBrandColor = `#5d41c0`;

// theme colors -- all derived from a single base theme color. (deprecated now)
export const themeColor = oldBrandColor;
export const themeColorLight = `#8761c0`;

export const themeLighter = Color(themeColor).lighten(0.5).string();
export const themeLight = Color(themeColor).lighten(0.3).string();
export const themeDark = Color(themeColor).darken(0.25).string();
export const themeDarker = Color(themeColor).darken(0.5).string();
export const themeTranslucentHighlight = Color(themeLighter).alpha(0.2).string();

export const baseText = darkGray;
export const backgroundColor = lightGray;

// Text Post Backgrounds
export const orangeBackground = `#FF563D`;
export const purpleBackground = `#323794`;
export const tealBackground = `#37AABA`;
export const greenBackground = `#279B48`;
