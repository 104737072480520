import React from 'react';
import { NetworkStatus } from 'apollo-client';
import { useQuery } from 'react-apollo';
import { Text, TouchableOpacity, View, FlatList, Image } from 'react-native';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import Emoji from 'react-native-emoji-v2';
import { Typography } from '../common-styles';
import { Avatar, Divider, LoadingIndicator, QueryError } from '../common-ui';
import { GET_REACTORS_LIST } from '../graphql';
import { DELETED_USER } from '../../constants';
import { User, ReactionItem, GetReactionsInput } from '../common-types/types';
import { MeshVerifiedCheck } from '../images';

type PostReactionUserParamList = {
  PostReactionUserList: {
    id: string;
    kind: string;
  };
  ProfileStack: {
    screen: string;
    params: UserItemProps;
  };
};

// eslint-disable-next-line quotes
type PostReactionUserRoute = RouteProp<PostReactionUserParamList, 'PostReactionUserList'>;

export const PostReactionUserList: React.FC = () => {
  const route = useRoute<PostReactionUserRoute>();
  const { id, kind } = route.params;
  const input: GetReactionsInput = { parent_id: id, parent_kind: kind };

  const { data, error, networkStatus, refetch } = useQuery(GET_REACTORS_LIST, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: `cache-and-network`,
  });

  const renderUser = ({ item: { user, kind, url } }: { item: ReactionItem }) => {
    return <UserItem user={user} kind={kind} url={url} />;
  };

  const keyExtractor = (item: ReactionItem, index: number) => `${item.user.id}${index}`;

  //this should be done
  const renderList = data?.getReactorsList?.filter((item: any) => item?.user?.handle !== `[deleted user]`) || [];

  if (networkStatus === NetworkStatus.loading)
    return (
      <View style={{ flex: 1, justifyContent: `center` }}>
        <LoadingIndicator size="large" style={{ alignSelf: `center` }} />
      </View>
    );

  if (error || !data || networkStatus === NetworkStatus.error) {
    return <QueryError error={error || {}} networkStatus={networkStatus} refetch={refetch} />;
  }

  return (
    <FlatList
      style={{ flex: 1 }}
      data={renderList}
      renderItem={renderUser}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={Divider}
    />
  );
};

type UserItemProps = {
  user: User;
  kind: string;
  url?: string;
};

const UserItem: React.FC<UserItemProps> = React.memo(({ user, kind, url }) => {
  const navigation = useNavigation<any>();

  const goToProfile = () => {
    if (!user || user.handle === DELETED_USER.handle) return;
    navigation.push(`Profile`, { user });
  };

  return (
    <TouchableOpacity activeOpacity={0.8} onPress={goToProfile}>
      <View style={{ flexDirection: `row`, justifyContent: `space-between`, alignItems: `center`, paddingHorizontal: 10 }}>
        <View style={{ flexDirection: `row`, alignItems: `center`, padding: 8 }}>
          <Avatar user={user} size={40} />
          <View style={{ flexDirection: `row`, alignItems: `center` }}>
            <Text style={{ ...Typography.text(`base`, `black`, `bold`), marginLeft: 8 }}>{user.handle}</Text>
            {user?.isVerified && <MeshVerifiedCheck height={14} width={14} style={{ marginLeft: 4 }} />}
          </View>
        </View>

        <View style={{ flexDirection: `row`, alignItems: `center` }}>
          {url ? (
            <Image source={{ uri: url }} style={{ width: 32, height: 32 }} />
          ) : (
            <View style={{ width: 32, height: 32, overflow: `hidden`, alignItems: `center`, justifyContent: `center` }}>
              <View style={{ overflow: `hidden`, width: `100%`, height: `100%`, alignItems: `center` }}>
                <Emoji name={kind} style={{ fontSize: 24 }} />
              </View>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
});
