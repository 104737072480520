import PropTypes from 'prop-types';
import { FlatList, Image, Text, View } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { BottomSheetModal } from '../common-ui';

export class WhatIsMeshVerificationModal extends React.PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    enableMeshVerification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      bulletPoints: [
        {
          id: 0,
          body: `We're verifying you are an actual person (preferred names and names on your ID do not need to match, so don't worry about former names or deadnames).`,
        },
        {
          id: 1,
          body: `Holding up a government ID is an assurance that you will follow our Ground Rules (members can contact us if they need an alternative way to verify).`,
        },
        {
          id: 2,
          body: `And above all, to ensure the safety of your communities!`,
        },
      ],
    };
  }

  renderBulletPoints = ({ item }) => {
    return (
      <View style={localStyles.bulletContainer}>
        <Text style={localStyles.bullet}>{`\u2022`}</Text>
        <Text style={localStyles.bulletText}>{item.body}</Text>
      </View>
    );
  };

  keyExtractor = (item, index) => item.id + index.toString();

  render = () => {
    const { isVisible, toggleModal, enableMeshVerification } = this.props;
    const { bulletPoints } = this.state;

    return (
      <BottomSheetModal
        title="What's Mesh Verification?"
        visible={isVisible}
        showCancelBtn={false}
        onPressCancel={toggleModal}
        onPressConfirm={() => {
          console.log(`take me to the mesh verification process`);
          enableMeshVerification();
          toggleModal();
          //TODO: this.props.navigation to mesh verify
        }}
      >
        <View style={localStyles.bodyContainer}>
          <Image source={require(`../../assets/images/mesh_verification.png`)} style={localStyles.imageStyle} />
          <Text style={localStyles.bodyText}>
            <Text>
              The fabulous Mesh Verification badge is earned after you send us a selfie of yourself with a government issued ID.
              We take your privacy seriously. This photo is only shared with Mesh staff and{` `}
            </Text>
            <Text style={localStyles.bodySemiBold}>we do not conduct background checks.{` `}</Text>
            <Text>Why do we do this?</Text>
          </Text>
          <FlatList
            scrollEnabled={false}
            data={bulletPoints}
            renderItem={this.renderBulletPoints}
            keyExtractor={this.keyExtractor}
            style={{ margin: 15 }}
          />
        </View>
      </BottomSheetModal>
    );
  };
}

const localStyles = {
  headerContainer: { flexDirection: `row`, justifyContent: `space-between`, alignItems: `center` },
  headerText: { fontSize: 15, fontWeight: `bold`, color: Colors.textBlack },
  doneText: { fontSize: Typography.baseFontSize },
  bodyContainer: { margin: 10, justifyContent: `center`, marginVertical: 20 },
  imageStyle: { height: 197, width: 287, alignSelf: `center` },
  bodyText: { fontSize: Typography.baseFontSize, color: Colors.textBlack, marginTop: 20 },
  bodySemiBold: { fontWeight: `500` },
  bulletContainer: { flexDirection: `row`, justifyContent: `space-between` },
  bullet: { marginRight: 15, fontWeight: `bold` },
  bulletText: {
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
  },
  buttonTitleStyle: {
    fontSize: 15,
    fontWeight: `bold`,
  },
};
