import React from 'react';
import { Text } from 'react-native';
import { InlineDateSeparatorProps } from 'stream-chat-react-native';
import { formatDate } from '../../common-util';
import { Typography } from '../../common-styles';

export const DateSeparator: React.FC<InlineDateSeparatorProps> = ({ date }) => {
  //const { date } = props.message || {};
  return (
    <Text style={{ ...Typography.text(`small`, `gray`, `center`), marginTop: 4, marginBottom: 12 }}>
      {formatDate(date, `MMM dd yyyy, h:mmTT`)}
    </Text>
  );
};
