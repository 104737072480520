import React, { useCallback, useContext, useState } from 'react';
import { Image, StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { Asset } from 'react-native-image-picker';

import { Colors, Typography } from '../../common-styles';
import { ThemedButton, LoadingIndicator } from '../../common-ui';
import { client, SINGLE_IMG_UPLOAD, UPDATE_GROUP_PHOTO } from '../../graphql';
import useMediaPermissons from '../../hooks/useMediaPermissions';
import { setActiveCommunity } from '../../redux/feedSlice';
import { useAppDispatch } from '../../redux/store';
import { AppContext } from '../../../AppContext';
import { grantBannerMedia } from './helpers';

const bannerImg = require(`../../../assets/images/node.png`);

const EditCommunityBanner: React.FC<any> = ({ route }) => {
  const { group } = route.params;
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [bannerUrl, setBannerUrl] = useState(group?.splash_url || group?.avatar_url);
  const { checkPermission } = useMediaPermissons();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const setCommunity = useCallback(
    (url) => {
      if (!user) return;
      const community = { ...group, splash_url: url };
      dispatch(setActiveCommunity({ user_id: user.id || ``, group: community }));
    },
    [dispatch, group, user],
  );

  const handleUploadPress = async () => {
    const valid = await checkPermission();
    if (!valid) return;
    grantBannerMedia(width, async (file: Asset) => {
      try {
        setLoading(true);
        const { data } = await client.mutate({
          mutation: SINGLE_IMG_UPLOAD,
          variables: { file },
        });

        const { url } = data.singleImgUpload;
        const update = await client.mutate({
          mutation: UPDATE_GROUP_PHOTO,
          variables: {
            group_id: group?.id,
            splash_url: url,
          },
        });

        setBannerUrl(update?.data?.updateGroupPhoto2?.splash_url);
        client.reFetchObservableQueries();
        setCommunity(update?.data?.updateGroupPhoto2?.splash_url);
        setLoading(false);
      } catch (error) {
        console.error(`Failed to upload: `, error);
        setLoading(false);
      }
    });
  };

  const handleRemovePress = async () => {
    try {
      setLoading(true);
      await client.mutate({
        mutation: UPDATE_GROUP_PHOTO,
        variables: {
          group_id: group?.id,
          splash_url: null,
        },
      });
      setBannerUrl(group?.avatar_url || undefined);
      setCommunity(group?.avatar_url || undefined);
      setLoading(false);
    } catch (error) {
      console.error(`Failed to remove: `, error);
      setLoading(false);
    }
  };

  return (
    <View style={styles.editBannerContainer}>
      <Text style={styles.editDescription}>Upload an image for your node's banner</Text>
      <View style={styles.bannerWrapper}>
        {loading && (
          <View style={styles.loadingWrapper}>
            <LoadingIndicator />
          </View>
        )}

        {bannerUrl ? (
          <Image source={{ uri: bannerUrl }} resizeMode="cover" style={{ height: 100, width: `100%` }} />
        ) : (
          <Image source={bannerImg} resizeMode="contain" style={{ height: 100, width: `100%` }} />
        )}
      </View>

      <View style={styles.actionButtons}>
        <ThemedButton
          rounded
          title="Upload"
          titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.lightGray }}
          containerStyle={{ marginHorizontal: 16 }}
          buttonStyle={{ paddingVertical: 8, paddingHorizontal: 16, alignItems: `center` }}
          onPress={handleUploadPress}
          disabled={loading}
        />

        <ThemedButton
          rounded
          title="Remove"
          titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.lightGray }}
          buttonStyle={{ paddingVertical: 8, paddingHorizontal: 16, alignItems: `center` }}
          onPress={handleRemovePress}
          disabled={!bannerUrl || bannerUrl === group.avatar_url || loading}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  editBannerContainer: {
    paddingVertical: 24,
  },
  editDescription: {
    fontSize: 14,
    paddingHorizontal: 10,
    alignSelf: `center`,
    color: Colors.mediumLightGray,
  },
  bannerWrapper: {
    paddingVertical: 12,
    position: `relative`,
  },
  loadingWrapper: {
    position: `absolute`,
    top: 12,
    left: 0,
    right: 0,
    bottom: 12,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    zIndex: 2,
    backgroundColor: `#222A`,
  },
  actionButtons: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    paddingHorizontal: 10,
  },
});

export default EditCommunityBanner;
