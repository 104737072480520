import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Activity } from '../../../common-types/types';
import { PIN_POST, refetchQueriesFor, UNPIN_POST } from '../../../graphql';

type PinPostData = {
  pin_post2: Activity[];
};
type UnpinPostData = {
  unpin_post2: Activity[];
};
type PinPostVars = {
  group_id: string;
  post_id: number;
};
// eslint-disable-next-line quotes
type UnpinPostVars = Pick<PinPostVars, 'group_id'>;

export const usePin = () => {
  const [pinPostMutation] = useMutation<PinPostData, PinPostVars>(PIN_POST);
  const [unPinPostMutation] = useMutation<UnpinPostData, UnpinPostVars>(UNPIN_POST);

  const pinPost = useCallback(
    async (groupId: string, postId: string) => {
      try {
        await pinPostMutation({
          variables: { group_id: groupId, post_id: +postId },
          refetchQueries: refetchQueriesFor(`ActivityDetails`),
        });
      } catch (error) {
        console.error(error);
      }
    },
    [pinPostMutation],
  );

  const unpinPost = useCallback(
    async (groupId: string) => {
      try {
        await unPinPostMutation({
          variables: { group_id: groupId },
          refetchQueries: refetchQueriesFor(`ActivityDetails`),
        });
      } catch (error) {
        console.error(error);
      }
    },
    [unPinPostMutation],
  );

  return { pinPost, unpinPost };
};
