/* eslint-disable no-return-await */
import React, { useCallback, useEffect, useRef, useState, useContext, useMemo, ReactSVG } from 'react';
import {
  StyleSheet,
  Share,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
  Linking,
  Platform,
  Keyboard,
  NativeSyntheticEvent,
  TextInputChangeEventData,
} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import { unionBy } from 'lodash';
import { useLazyQuery } from 'react-apollo';
import { useNavigation } from '@react-navigation/native';
import SocialShare, { Social } from 'react-native-share';
import { User } from '../common-types/types';
import { Colors, Typography } from '../common-styles';
import { Avatar, MeshIcon, ThemedButton } from '../common-ui';
import { AppContext } from '../../AppContext';
import { handleCreateConversation } from '../community/memberInfoHelpers';
import { SEARCH_FRIENDS, SEARCH_USERS_TO_PROFILE } from '../graphql';

// @ts-ignore reason: ts svg import definition errors
import ShareViaIcon from '../../assets/images/ShareVia.svg';
// @ts-ignore
import CopyLinkIcon from '../../assets/images/CopyLink.svg';
// @ts-ignore
import MessageIcon from '../../assets/images/Messages.svg';
// @ts-ignore
import GoogleMessageIcon from '../../assets/images/GoogleMessage.svg';
// @ts-ignore
import WhatsAppIcon from '../../assets/images/WhatsApp.svg';
// @ts-ignore
import EmailIcon from '../../assets/images/Email.svg';
// @ts-ignore
import MessengerIcon from '../../assets/images/Messenger.svg';
// @ts-ignore
import InstagramIcon from '../../assets/images/Instagram.svg';
// @ts-ignore
import TelegramIcon from '../../assets/images/Telegram.svg';
// @ts-ignore
import TwitterIcon from '../../assets/images/Twitter.svg';

type InviteFriendsProps = {
  inviteUrl: string;
  user: Pick<User, `id`>;
  onClose: () => void;
};

type InviteOptions = {
  img: ReactSVG;
  label: string;
  onClick: () => void;
  installed: () => boolean | Promise<boolean>;
  enabled?: boolean;
};

// TODO: merge with packages/community/v2/InviteFriends
const InviteModal: React.FC<InviteFriendsProps> = ({ inviteUrl, user, onClose }) => {
  const [searchTxt, setSearchTxt] = useState(``);
  const [searched, setSearched] = useState(false);
  const [options, setOptions] = useState<InviteOptions[]>([]);
  const navigation = useNavigation();
  const { user: me, chatNotifications, setChatNotifications, setChannel } = useContext(AppContext);

  const carouselScroll = useRef<FlatList>(null);
  const [invitedList, setInvitedList] = useState<string[]>([]);
  const [searchFriends, { data, fetchMore, loading }] = useLazyQuery(SEARCH_FRIENDS, { fetchPolicy: `network-only` });
  const [searchUsers, { data: searchedData, fetchMore: fetchMoreSearched, loading: loadingSearched }] = useLazyQuery(
    SEARCH_USERS_TO_PROFILE,
    { fetchPolicy: `network-only` },
  );

  const handleChangeText = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    const txt = e.nativeEvent.text;
    setSearched(false);
    setSearchTxt(txt);
  };

  const handleSearchFriends = useCallback(() => {
    if (!searchTxt) return;
    searchUsers({ variables: { keyword: searchTxt, persona_id: user.id, limit: 15, offset: 0 } });
    setSearched(true);
    Keyboard.dismiss();
  }, [searchUsers, user, searchTxt]);

  const handleCopyLink = (url: string) => {
    Clipboard.setString(`Join me on mesh ${url}`);
    console.log(`copy invite link to clipboard: `, url);
  };

  const handleShareVia = async (url: string) => {
    try {
      await Share.share({ message: `Join me on mesh ${url}` });
    } catch (err) {
      console.error(`share via failed: `, err);
    }
  };

  const handleInvite = useCallback(
    async (item: User) => {
      console.log(`frined invite: `, JSON.stringify(item));
      try {
        await handleCreateConversation({ me, chatNotifications, setChatNotifications, setChannel }, item, navigation);
        onClose();
        setInvitedList((prev) => (item?.id ? [...prev, item.id] : prev));
      } catch (error: any) {
        console.error(`Error in create conversation in invite modal: `, error);
      }
    },
    [me, chatNotifications, setChatNotifications, setChannel, navigation, onClose],
  );

  const handleSocialShare = async (SocialType: Social, url: string) => {
    try {
      if (SocialType === SocialShare.Social.INSTAGRAM) {
        const shareOptions = {
          social: SocialShare.Social.INSTAGRAM,
          url,
          type: `*`,
          forceDialog: true,
        };
        await SocialShare.shareSingle(shareOptions);
      } else if (SocialType === SocialShare.Social.MESSENGER) {
        Linking.openURL(`fb-messenger://share?link=${url}`);
      } else if (SocialType === SocialShare.Social.SMS && Platform.OS === `ios`) {
        const text = encodeURIComponent(url);
        Linking.openURL(`sms:${Platform.OS === `ios` ? `&` : `?`}body=${text}`);
      } else if (SocialType === SocialShare.Social.EMAIL) {
        const shareOptions: any = {
          subject: `Invitation to join mesh profile`,
          message: `I'd like to invite you to my profile`,
          url,
          social: SocialType,
        };
        await SocialShare.shareSingle(shareOptions);
      } else {
        const shareOptions: any = {
          title: `Invitation to mesh profile`,
          message: `I'd like to invite you to my profile`,
          url,
          social: SocialType,
        };
        await SocialShare.shareSingle(shareOptions);
      }
    } catch (err) {
      console.error(`share via messages failed: `, err);
    }
  };

  const inviteOptions: InviteOptions[] = useMemo(
    () => [
      {
        img: ShareViaIcon,
        label: `Share via...`,
        onClick: () => handleShareVia(inviteUrl),
        installed: () => true,
      },
      {
        img: CopyLinkIcon,
        label: `Copy link`,
        onClick: () => handleCopyLink(inviteUrl),
        installed: () => true,
      },
      {
        img: Platform.OS === `ios` ? MessageIcon : GoogleMessageIcon,
        label: `Messages`,
        onClick: () => handleSocialShare(SocialShare.Social.SMS, inviteUrl),
        installed: () => true,
      },
      {
        img: WhatsAppIcon,
        label: `WhatsApp`,
        onClick: () => handleSocialShare(SocialShare.Social.WHATSAPP, inviteUrl),
        installed: async () => await Linking.canOpenURL(`whatsapp://`),
      },
      {
        img: EmailIcon,
        label: `Email`,
        onClick: () => handleSocialShare(SocialShare.Social.EMAIL, inviteUrl),
        installed: () => true,
      },
      {
        img: MessengerIcon,
        label: `Messenger`,
        onClick: () => handleSocialShare(SocialShare.Social.MESSENGER, inviteUrl),
        installed: async () => await Linking.canOpenURL(`fb-messenger://`),
      },
      {
        img: InstagramIcon,
        label: `Instagram`,
        onClick: () => handleSocialShare(SocialShare.Social.INSTAGRAM, inviteUrl),
        installed: async () => await Linking.canOpenURL(`instagram://`),
      },
      {
        img: TelegramIcon,
        label: `Telegram`,
        onClick: () => handleSocialShare(SocialShare.Social.TELEGRAM, inviteUrl),
        installed: () => (Platform.OS === `android` ? Linking.canOpenURL(`instagram://`) : false),
      },
      {
        img: TwitterIcon,
        label: `Twitter`,
        onClick: () => handleSocialShare(SocialShare.Social.TWITTER, inviteUrl),
        installed: async () => await Linking.canOpenURL(`twitter://`),
      },
    ],
    [inviteUrl],
  );

  useEffect(() => {
    const asyncOptions = async () => {
      const optionPromise = inviteOptions.map(async (op) => {
        const enabled = await op.installed();
        return { ...op, enabled };
      });
      const data = await Promise.all(optionPromise);
      setOptions(data.filter((it) => it.enabled));
    };
    asyncOptions();
  }, [inviteUrl, inviteOptions]);

  useEffect(() => {
    searchFriends({ variables: { filter: { keyword: ``, persona_id: user?.id, limit: 15, offset: 0 } } });
  }, [searchFriends, user]);

  const fetchMoreFriends = useCallback(() => {
    if (loading) return;
    const friendsLength = data?.searchForFriends?.length;
    const filter = { keyword: searchTxt, offset: friendsLength, limit: friendsLength + 15 };
    fetchMore({
      variables: { filter },
      updateQuery: (prev, { fetchMoreResult }) => {
        const results = fetchMoreResult?.searchForFriends;
        if (!friendsLength || (results?.length > 0 && results.slice(-1)[0].id === prev.searchForFriends.slice(-1)[0].id)) {
          console.log(`same result`);
          return prev;
        }

        return {
          ...prev,
          searchForFriends: unionBy(prev.searchForFriends, fetchMoreResult.searchForFriends),
        };
      },
    });
  }, [data?.searchForFriends, fetchMore, loading, searchTxt]);

  const fetchMoreUsers = useCallback(() => {
    if (loadingSearched) return;
    const friendsLength = searchedData?.searchUsersToProfileInvite.length;
    const variables = { keyword: searchTxt, persona_id: user.id, offset: friendsLength, limit: friendsLength + 15 };

    fetchMoreSearched({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          friendsLength === 0 ||
          (fetchMoreResult?.searchUsersToProfileInvite?.length > 0 &&
            fetchMoreResult.searchUsersToProfileInvite.slice(-1)[0].id === prev.searchUsersToProfileInvite.slice(-1)[0].id)
        ) {
          console.log(`same result`);
          return prev;
        }
        return {
          ...prev,
          searchForFriends: unionBy(prev.searchUsersToProfileInvite, fetchMoreResult.searchUsersToProfileInvite),
        };
      },
    });
  }, [searchedData?.searchUsersToProfileInvite, user, fetchMoreSearched, searchTxt, loadingSearched]);

  const renderItem = ({ item }: any) => {
    const { img: ImageIcon, label, onClick } = item;
    return (
      <TouchableOpacity onPress={() => onClick()}>
        <View style={styles.shareItemWrapper}>
          <View style={styles.manualShare}>
            <ImageIcon width={70} height={70} />
          </View>
          <Text style={styles.shareTxt}>{label}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  const renderFriend = ({ item }: { item: User }) => {

    return (
      <View style={styles.friendItem}>
        <Avatar border user={item} size={40} />
        <View style={{ flex: 1, marginLeft: 10 }}>
          <Text style={Typography.text(`bold`)}>{item?.handle}</Text>
          <Text style={styles.friendName as any}>{item?.name}</Text>
        </View>

        <ThemedButton
          rounded
          title={invitedList.includes(item?.id || ``) ? `Sent` : `Share`}
          titleStyle={{ fontSize: Typography.baseFontSize }}
          onPress={() => handleInvite(item)}
          disabled={invitedList.includes(item?.id || ``)}
          buttonStyle={{ paddingVertical: 6, paddingHorizontal: 12 }}
        />
      </View>
    );
  };

  return (
    <View>
      <View style={styles.shareWrapper}>
        <FlatList<InviteOptions>
          horizontal
          ref={carouselScroll}
          bounces={false}
          onScrollToIndexFailed={() => carouselScroll.current?.scrollToIndex({ index: 0, viewPosition: 0 })}
          getItemLayout={(_, index) => ({ length: 72, offset: 72 * index, index })}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          initialNumToRender={10}
          maxToRenderPerBatch={12}
          data={options}
          contentContainerStyle={{ alignItems: `center` }}
          renderItem={renderItem}
          keyExtractor={(item: any) => item.label}
        />
      </View>

      <View style={{ paddingHorizontal: 20 }}>
        <View style={styles.inputWrapper}>
          <TextInput style={styles.textInput} onChange={handleChangeText} onEndEditing={handleSearchFriends}>
            {searchTxt}
          </TextInput>

          <TouchableOpacity
            hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
            style={{ padding: 10 }}
            onPress={handleSearchFriends}
          >
            <MeshIcon name="magnifyingglass" size={20} color={Colors.mediumGray} />
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.friendsList}>
        <FlatList<User>
          data={searched ? searchedData?.searchUsersToProfileInvite : data?.searchForFriends || []}
          keyExtractor={(item, index) => `${item.id}-${index}`}
          renderItem={renderFriend}
          onEndReachedThreshold={0.3}
          onEndReached={searched ? fetchMoreUsers : fetchMoreFriends}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  shareWrapper: {
    display: `flex`,
    flexDirection: `row`,
    marginBottom: 15,
    paddingLeft: 20,
  },
  shareItemWrapper: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
  },
  manualShare: {
    borderColor: Colors.mediumLightGray,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    overflow: `hidden`,
  },
  shareTxt: {
    fontSize: 12,
    color: Colors.darkGray,
  },
  inputWrapper: {
    borderWidth: 1,
    borderColor: `#D8D8D8`,
    borderRadius: 4,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    width: `100%`,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 10,
  },
  friendsList: {
    height: 260,
    paddingTop: 10,
  },
  friendItem: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    paddingVertical: 4,
    paddingHorizontal: 20,
  },
  friendName: {
    ...Typography.text(`small`, `gray`, `bold`),
    paddingTop: 4
  },
});

export default InviteModal;
