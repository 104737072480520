/* eslint-disable react/require-default-props */
import React from 'react';
import { ReactElementLike } from 'prop-types';
import { StyleSheet, Text, View, Platform, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MeshIcon } from './MeshIcon';
import { Colors } from '../common-styles';

const ANDROID_SECONDARY = `rgba(0, 0, 0, 0.54)`;

// A test friendly ListItem based on React Native Elements

interface ListItemProps {
  title: string | ReactElementLike;
  titleProps?: any;
  titleStyle?: StyleProp<TextStyle>;
  subtitle?: string | ReactElementLike;
  subtitleProps?: any;
  subtitleStyle?: StyleProp<TextStyle>;
  onPress?: () => void | null;
  onLongPress?: () => void | null;
  containerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  leftAvatar?: ReactElementLike;
  leftIcon?: ReactElementLike;
  rightContentContainerStyle?: StyleProp<ViewStyle>;
  rightIcon?: ReactElementLike | null;
  rightElement?: ReactElementLike | null;
  disabled?: boolean;
  disabledStyle?: StyleProp<ViewStyle>;
  testID?: string;
  padding?: number;
  style?: StyleProp<ViewStyle>;
  underlayColor?: string;
  verified?: boolean;
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    title = ``,
    titleProps,
    titleStyle,
    subtitle,
    subtitleProps,
    subtitleStyle,
    containerStyle,
    onPress = () => console.log(`onPress not passed`),
    onLongPress = () => null,
    contentContainerStyle,
    leftAvatar,
    leftIcon,
    rightContentContainerStyle,
    rightIcon,
    rightElement,
    disabled = false,
    disabledStyle,
    testID,
    padding = 14,
    style,
    verified = false,
  } = props;

  const hasHorizontalPadding = {
    paddingLeft: leftIcon !== undefined || leftAvatar !== undefined ? 14 : 0,
    paddingRight: rightIcon !== undefined || rightElement !== undefined ? 14 : 0,
  };
  const mainCompStyle: StyleProp<ViewStyle> = StyleSheet.flatten([
    styles.container,
    { padding },
    style,
    containerStyle,
    disabled && disabledStyle,
  ]);
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      onLongPress={onLongPress}
      testID={testID}
      disallowInterruption
      style={mainCompStyle}
    >
      <>
        <View>
          {leftAvatar}
          {leftIcon}
        </View>
        <View style={StyleSheet.flatten([{ flex: 1 }, hasHorizontalPadding, contentContainerStyle])}>
          <Text style={StyleSheet.flatten([styles.title, titleStyle])} {...titleProps}>
            {title}
          </Text>
          {!!subtitle && (
            <View style={{ flexDirection: `row` }}>
              <Text style={StyleSheet.flatten([styles.subtitle, subtitleStyle])} {...subtitleProps}>
                {subtitle}
              </Text>
              {verified && (
                <MeshIcon name="select-circle" size={18} color={Colors.brandGreen} focused style={{ marginLeft: 10 }} />
              )}
            </View>
          )}
        </View>
        <View style={rightContentContainerStyle}>
          {rightElement}
          {rightIcon}
        </View>
      </>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 14,
    flexDirection: `row`,
    alignItems: `center`,
    backgroundColor: Colors.white,
  },
  title: {
    backgroundColor: `transparent`,
    ...Platform.select({
      ios: {
        fontSize: 17,
      },
      default: {
        fontSize: 16,
      },
    }),
  },
  subtitle: {
    backgroundColor: `transparent`,
    ...Platform.select({
      ios: {
        fontSize: 15,
      },
      default: {
        color: ANDROID_SECONDARY,
        fontSize: 14,
      },
    }),
  },
});
