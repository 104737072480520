import React, { useContext, useEffect } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import LottieView from 'lottie-react-native';
import { useAppDispatch } from '../redux/store';
import { AppContext } from '../../AppContext';
import { setPreviewCommunity, setActiveCommunity } from '../redux/feedSlice';
import { MainTabsNavigator, ExploreNavigator } from '../common-types/navigation-types';
import { LoadingModal } from '../common-ui';
import { useInvite } from '../community/hooks/useInvite';

export const LandingLoaderScreen = () => {
  const { user, identity } = useContext(AppContext);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<ExploreNavigator>();
  const { invitation, loading, error } = useInvite();

  useEffect(() => {
    if (loading) return;
    if (!error && invitation) {
      const { group, group_member } = invitation;
      if (user?.id && identity?.id && group && group_member?.role_name && !group_member?.banned) {
        dispatch(setActiveCommunity({ user_id: user.id, group }));
        const parent = navigation.getParent<MainTabsNavigator>();
        navigation.goBack();
        parent.jumpTo(`HomeNavigator`, { screen: `HomeScreen` });
      } else {
        dispatch(setPreviewCommunity(group));
        navigation.replace(`CommunityPreview`);
      }
    }
  }, [navigation, invitation, loading, error, user?.id, identity?.id, dispatch]);

  return (
    <View style={{ flex: 1, justifyContent: `center`, alignItems: `center` }}>
      {!error ? (
        <LottieView
          resizeMode="cover"
          style={{ width: 200, height: 200 }}
          source={require(`../images/animated/animatedLoading.json`)}
          autoPlay
          loop
        />
      ) : (
        <LoadingModal isVisible={true} content="Invitation is invalid or expired..." error={error?.message} />
      )}
    </View>
  );
};
