import * as React from 'react';
import Svg, { Defs, Path, G, Mask, Use } from 'react-native-svg';

export const NoCategories = (props) => {
  return (
    <Svg width="157px" height="154px" viewBox="0 0 157 154" {...props}>
      <Defs>
        <Path id="a" d="M0.0633034094 0.0959915931L111.537125 0.0959915931 111.537125 73.8150354 0.0633034094 73.8150354z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H375V808H0z" />
        <Path
          d="M151.594 54.227c6.606 18.874-7.373 39.986-30.352 53.34-22.883 13.428-54.671 19.023-71.044 9.4-16.372-9.55-17.33-34.317-8.138-55.951 9.287-21.634 28.724-40.135 52.182-41.18 23.362-.97 50.65 15.592 57.352 34.391z"
          fill="#2C9A44"
          fillRule="nonzero"
          transform="translate(.368 .245) rotate(170 94.735 70.796)"
        />
        <Path
          d="M72.033 23.501s-.392 8.67-5.422 11.73c-4.563 2.776 5.105 18.887 15.506 17.926 5.693-.526 7.028-16.18 7.028-16.18s-4.597-.892-2.9-7.94L72.034 23.5z"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(.368 .245) translate(.632 6.755)"
        />
        <Path
          d="M80.362 32.316c-6.743-.82-11.61-5.277-12.29-12.671-1.96.313-2.423-4.822-1.877-6.471.546-1.652 2.33-.686 2.33-.686a6.92 6.92 0 00.252-.82C71.722-.48 79.972.003 79.972.003c15.16.794 11.607 16.354 10.741 20.482-.866 4.128-3.833 12.623-10.351 11.83z"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(.368 .245) translate(.632 6.755)"
        />
        <Path
          d="M133.744 21.56s-.846-2.445 4.993-6.088c3.082-1.923 6.218-7.21 7.435-5.928 1.217 1.282-3.15 7.366-3.15 7.366s3.697-2.165 6.185-4.886c2.487-2.721 4.724-4.888 4.724-4.888s1.653-.63 1.441.87c-.211 1.5-.722 1.884-.722 1.884s1.173-.026.958 1.111c-.216 1.138-.472 1.33-.472 1.33s.86.087.671 1.082c-.188.996-.87 1.507-.87 1.507s.605.279-.184 1.359c-.789 1.08-5.133 6.658-7.691 8.213-2.559 1.554-8.856 3.798-8.856 3.798s-5.312-5.127-4.462-6.73"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742)"
        />
        <Path
          d="M147.62 20.211l-.129-.292c.02-.009 1.915-.875 4.2-3.276 1.582-1.663 2.393-2.017 2.794-2.022.19.001.28.073.303.096l-.226.227a.073.073 0 00.014.012c-.005-.003-.56-.294-2.655 1.908-2.332 2.452-4.22 3.312-4.3 3.347M147.054 18.653l-.164-.274c.005-.004.55-.331 2.022-1.177 1.003-.577 2.435-2.081 3.48-3.18.482-.506.898-.943 1.169-1.181.896-.789 1.59-.684 1.618-.679l-.05.317c-.006 0-.585-.077-1.358.603-.26.228-.672.66-1.148 1.162-1.06 1.112-2.51 2.636-3.553 3.236-1.468.844-2.01 1.17-2.016 1.173M146.627 17.373l-.16-.277c.02-.012 2.024-1.183 3.482-2.44 1.36-1.172 2.637-2.894 3.323-3.818l.16-.216c.666-.893 1.138-.919 1.19-.919l.005.32c-.004 0-.375.034-.94.791l-.16.215c-.693.935-1.984 2.675-3.37 3.87-1.482 1.277-3.51 2.462-3.53 2.474M139.62 18.651l-.171-.27c.049-.031 1.209-.768 2.084-.878.783-.099 1.389-.697 1.395-.703l.225.227c-.027.028-.686.681-1.58.794-.804.1-1.942.823-1.953.83"
          fill="#995B5D"
          fillRule="nonzero"
          transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742)"
        />
        <Path
          d="M138.206 28.29s-1.169.855-3.187 5.021c-2.02 4.166-9.35 22.006-9.35 22.006l-5.227-18.546s10.264-14.352 14.758-18.36c4.494-4.008 3.006 9.88 3.006 9.88"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742)"
        />
        <Path
          d="M19.917 18.694s1.329-2.218-3.637-6.995c-2.62-2.52-4.603-8.344-6.057-7.342-1.454 1.002 1.57 7.86 1.57 7.86S8.62 9.332 6.743 6.154C4.87 2.974 3.126.39 3.126.39s-1.488-.96-1.59.552c-.1 1.512.32 1.993.32 1.993s-1.142-.27-1.165.889C.67 4.983.88 5.224.88 5.224S.02 5.13 0 6.144a2.6 2.6 0 00.542 1.654s-.648.147-.098 1.368c.55 1.22 3.655 7.58 5.84 9.632 2.184 2.052 7.885 5.555 7.885 5.555s6.25-3.915 5.748-5.66"
          transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742) translate(0 .017)"
          fill="#C26E5E"
          fillRule="nonzero"
        />
        <Path
          d="M6.615 14.51c-.07-.05-1.742-1.284-3.52-4.167C1.495 7.753.894 7.922.888 7.925l.016-.009-.174-.269c.027-.017.13-.072.315-.03.392.087 1.113.602 2.32 2.557 1.745 2.83 3.42 4.065 3.436 4.077l-.187.26zM7.49 13.104c-.005-.005-.468-.437-1.732-1.567-.898-.803-2.004-2.595-2.812-3.904-.363-.589-.677-1.097-.884-1.375-.616-.824-1.198-.871-1.204-.872l.014-.32c.03.001.73.042 1.445 1 .215.289.533.803.9 1.398.798 1.292 1.89 3.061 2.753 3.834 1.267 1.133 1.732 1.567 1.737 1.57l-.217.236zM8.17 11.94c-.017-.016-1.758-1.597-2.946-3.154C4.114 7.329 3.208 5.359 2.721 4.3l-.112-.243c-.397-.858-.754-.968-.758-.969l.071-.312c.05.01.508.134.976 1.145l.113.245c.48 1.047 1.377 2.996 2.466 4.425 1.17 1.534 2.89 3.095 2.907 3.11l-.213.238zM14.764 14.644c-.01-.01-.974-.952-1.74-1.218-.852-.295-1.362-1.071-1.384-1.104l.267-.175c.005.007.475.717 1.22.976.835.29 1.819 1.251 1.86 1.292l-.223.23z"
          fill="#995B5D"
          fillRule="nonzero"
          transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742)"
        />
        <Path
          d="M14.169 24.37s.968 1.078 2.088 5.573c1.12 4.496 4.63 23.472 4.63 23.472l8.922-17.061S22.713 20.18 19.14 15.326c-3.575-4.854-4.97 9.043-4.97 9.043"
          fill="#C26E5E"
          fillRule="nonzero"
          transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742)"
        />
        <G transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742) translate(16.788 19.565)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Path
            d="M49.912.607s-13.441 3.125-19.1 12.779c-5.657 9.653-9.8 11.042-9.8 11.042s-4.357-11.216-6.695-10.789C11.98 14.066-.45 33.615.08 35.431c.531 1.816 10.696 27.082 21.658 22.407 10.96-4.674 13.83-12.793 14.467-10.977.638 1.816-2.444 24.676.213 25.317 2.656.641 17.766 1.857 31.556 1.603 11.582-.214 18.594.32 19.125-4.594.532-4.914-1.275-22.005-1.275-22.005s5.844 7.264 10.944 4.913c5.1-2.35 14.77-10.148 14.77-10.148L104.1 15.775l-7.226 6.944S88.8 9.152 84.018 6.589C79.237 4.025 69.938.096 69.938.096s-5.364 4.677-7.701 7.988c-2.338 3.312-2.975 5.02-2.975 5.02S54.055 3.065 49.912.608"
            fill="#F9C818"
            fillRule="nonzero"
            mask="url(#b)"
          />
        </G>
        <G fill="#FFE89D" fillRule="nonzero">
          <Path
            d="M35.975 24.263l6.694 8.546s.956-3.74-.957-6.73c-1.912-2.991-5.737-1.816-5.737-1.816M68.806.227L75.5 8.773s.957-3.738-.956-6.73C72.632-.947 68.806.227 68.806.227M38.525 54.28l6.694 8.546s.956-3.739-.956-6.73c-1.913-2.99-5.738-1.816-5.738-1.816M68.169 37.616l6.694 8.546s.956-3.74-.956-6.73c-1.913-2.991-5.738-1.816-5.738-1.816M56.047 55.54l7.215-8.105s-3.828-.3-6.427 2.11c-2.6 2.41-.788 5.996-.788 5.996M52.116 33.855l7.215-8.105s-3.828-.301-6.428 2.11c-2.6 2.41-.787 5.995-.787 5.995M67.444 18.948l-2.567 10.567s3.517-1.548 4.681-4.906c1.164-3.357-2.114-5.661-2.114-5.661M82.531 11.47l-2.567 10.567s3.518-1.547 4.682-4.905c1.163-3.358-2.115-5.662-2.115-5.662M36.527 45.163l10.599-2.187s-2.896-2.535-6.413-2.156c-3.517.379-4.186 4.343-4.186 4.343M28.3 31.534l-10.622-2.067s1.707 3.458 5.1 4.464c3.391 1.007 5.522-2.397 5.522-2.397M5.484 20.886L0 30.262s3.812-.464 5.888-3.342c2.076-2.88-.404-6.034-.404-6.034M7.728 41.639l9.086 5.906s-.3-3.848-3.072-6.056c-2.773-2.207-6.014.15-6.014.15M36.716 10.282l7.824-7.514s-3.793-.6-6.573 1.599c-2.779 2.199-1.25 5.915-1.25 5.915M21.907 19.15l10.136-3.804s-3.25-2.056-6.666-1.136c-3.417.919-3.47 4.94-3.47 4.94M55.057 15.304L65.194 11.5s-3.25-2.055-6.667-1.136c-3.417.92-3.47 4.94-3.47 4.94M83.426 34.318l10.137-3.803s-3.25-2.056-6.667-1.136c-3.417.919-3.47 4.94-3.47 4.94M66.32 63.16l10.136-3.803s-3.25-2.055-6.666-1.136c-3.417.919-3.47 4.94-3.47 4.94"
            transform="translate(.368 .245) translate(.632 6.755) translate(0 14.742) translate(20.843 26.46)"
          />
        </G>
        <G transform="translate(.368 .245) matrix(-1 0 0 1 138.632 98.755)">
          <Path
            d="M62.379 32.483s-4.568 1.965-5.118 1.2c-.55-.764 4.182 3.985 4.182 3.985l3.412-1.91-2.476-3.275z"
            fill="#C26E5E"
            fillRule="nonzero"
          />
          <Path
            d="M62.379 32.483s-4.568 1.965-5.118 1.2c-.55-.764 4.182 3.985 4.182 3.985l3.412-1.91-2.476-3.275z"
            stroke="#000"
            strokeWidth={1.01142768}
          />
          <Path
            d="M25.783 34.884s-6.714 3.875-1.376 9.934c5.338 6.058 24.214 1.91 24.214 1.91l.495-2.401s-18.985-9.115-23.333-9.443"
            fill="#F0ECEC"
            fillRule="nonzero"
          />
          <Path
            d="M25.783 34.884s-2.641.546-3.467 3.548c-.825 3.002.994 6.498 3.661 7.67 0 0-3.404-7.493 2.576-10.584l-2.77-.634z"
            fill="#E0D3CC"
            fillRule="nonzero"
          />
          <Path
            d="M27.94 46.953s2.41-4.864 3.511-4.755c1.1.11 13.015.722 14.212.498l3.949 1.576-.699 2.499s-14.689 3.41-20.973.182"
            fill="#E7DADA"
            fillRule="nonzero"
          />
          <Path
            d="M31.176 37.777s-.88 6.331.385 6.331c1.266 0 1.596-7.095 1.816-4.64.22 2.457.386 5.568 1.211 4.64.826-.928-.88-7.805-.165-5.894.715 1.91 1.156 5.512 2.311 5.458 1.156-.055-.44-3.712.165-3.821.606-.11 1.816 4.748 2.862 4.148 1.046-.6-.88-4.585.22-3.111 1.1 1.474 1.431 3.22 2.422 3.002.99-.218-.11-2.129-.716-3.057-.605-.927-9.905-4.475-10.18-4.038-.276.436-.33.982-.33.982"
            fill="#F9F9F9"
            fillRule="nonzero"
          />
          <Path
            d="M45.374 43.916S23.142 37.53 12.796 29.398C2.45 21.265-3.55 13.35 2.285 4.454c5.833-8.896 19.536-2.128 26.14.765 6.603 2.892 28.258 6.983 38.59 6.957 10.332-.026 24.2-.79 31.41-2.918 7.209-2.13 18.71-5.35 22.232 3.329 3.522 8.678-.605 17.193-6.659 20.085-6.053 2.893-24.709 9.115-34.284 8.515l-9.576-.6s-4.127-4.53-3.026-11.135L61.333 33.6s-3.302-.054-4.512-.49c-1.21-.438-7.925 15.554-11.447 10.806"
            transform="translate(0 .083)"
            fill="#165C5D"
            fillRule="nonzero"
          />
          <Path
            d="M51.263 44.763s9.685 3.548 11.996 5.022c2.312 1.473 7.1 2.729 9.41 1.146 2.312-1.583.936-9.443.936-9.443s-7.76-1.746-8.64-2.947c-.88-1.2-7.924-5.185-7.924-5.185l-5.778 11.407z"
            fill="#C26E5E"
            fillRule="nonzero"
          />
          <Path
            d="M72.775 42.11s.5-1.277 2.041-1.004c1.54.273 15.904-1.31 20.031-2.456 4.128-1.146 12.878 6.986 9.08 12.717-3.797 5.731-8.97 3.002-13.647 2.62-4.678-.382-13.758.928-15.794.273-2.036-.655-4.073-1.856-4.128-3.275-.055-1.419 2.417-8.875 2.417-8.875"
            fill="#F0ECEC"
            fillRule="nonzero"
          />
          <Path
            d="M93.776 39.398s6.08 2.964 7.18 7.057c1.1 4.094.59 7.37.59 7.37s5.769-2.696 2.48-9.099c-3.29-6.403-8.46-6.93-10.25-5.984-1.79.945 0 .656 0 .656"
            fill="#E0D3CC"
            fillRule="nonzero"
          />
          <Path
            d="M93.103 39.26s-2.493 3.92-2.823 6.758c0 0 .22.546-2.532.492-2.751-.055-15.58-2.847-15.58-2.847s-2.759 6.556-1.761 8.25c.997 1.692 19.873-1.528 19.873-1.528s.77-.437 1.266-.055c.495.382 3.052 3.882 6.561 4.588 0 0-5.46-2.896-5.901-6.716-.44-3.82.897-8.943.897-8.943"
            fill="#EEDFD9"
            fillRule="nonzero"
          />
          <Path
            d="M74.981 43.235s.165 3.711-.55 5.785c-.716 2.075-1.31 3.884-.077 3.661 1.232-.222 2.553-8.464 1.838-9.173-.716-.71-1.156-.928-1.21-.273"
            fill="#FFF"
            fillRule="nonzero"
          />
          <Path
            d="M77.017 43.672s.165 3.711-.55 5.785c-.716 2.074-1.31 3.883-.077 3.66 1.233-.221 2.553-8.463 1.838-9.172-.715-.71-1.156-.928-1.21-.273M84.226 43.802s.165 3.395-.55 5.292c-.715 1.897-1.31 3.551-.077 3.348 1.233-.203 2.553-7.742 1.838-8.39-.715-.65-1.156-.85-1.21-.25M86.978 44.215s.165 3.168-.55 4.939c-.716 1.77-1.31 3.315-.077 3.125 1.232-.19 2.553-7.226 1.838-7.831-.716-.606-1.156-.792-1.211-.233M88.955 43.986s.617 3.113.163 4.967c-.455 1.853-.822 3.466.371 3.105 1.193-.362 1.493-7.513.698-8.012-.795-.499-1.257-.621-1.232-.06"
            fill="#FFF"
            fillRule="nonzero"
          />
          <Path
            d="M79.576 43.91s-.218 3.709-1.144 5.7c-.925 1.99-1.703 3.73-.454 3.633 1.25-.096 3.413-8.16 2.775-8.939-.639-.778-1.054-1.04-1.177-.394"
            fill="#FFF"
            fillRule="nonzero"
          />
          <Path
            d="M80.655 43.841s.744 3.641.362 5.8c-.382 2.16-.686 4.038.497 3.629 1.182-.41 1.198-8.755.38-9.345-.818-.591-1.287-.74-1.239-.084M72.403 43.414s3.459.749 2.743 4.897c-.715 4.148-5.061 2.979-5.061 2.979s-1.03-6.823 2.318-7.876"
            fill="#FFF"
            fillRule="nonzero"
          />
          <Path
            fill="#000"
            fillRule="nonzero"
            d="M23.7242175 7.13708881L54.1006013 30.9016973 60.6767097 33.6487927 67.6399471 29.3470802 91.5511747 14.3436967 91.7796467 14.7018728 67.8671522 29.706045 60.7148649 34.1246057 53.8814764 31.2700468 23.459558 7.46985927z"
          />
          <Path
            d="M68.377 27.08s-4.952 1.364-6.328 3.71c-1.376 2.348-.936 6.386 3.137 8.079 4.072 1.692 5.723 3.11 4.677.436-1.045-2.674-2.972-7.587-1.486-12.226M53.96 30.19s-.496 5.458-3.523 9.716c-3.027 4.257-5.723 4.693-4.733 5.73.991 1.037 2.532 1.529 3.522 2.839.991 1.31.936 1.255 3.247-1.474 2.312-2.73 7.375-8.787 7.485-14.19 0 0-5.559-4.422-5.999-2.62"
            fill="#46AFAC"
            fillRule="nonzero"
          />
        </G>
        <G fillRule="nonzero">
          <Path
            d="M10.927.055l.42.13-.04.126-.11.353c-.114.375-.227.778-.333 1.192-.118.46-.219.907-.298 1.33l-.023.124c-.192 1.073-.219 1.892-.047 2.333l.01.025c.98 2.332-.351 3.514-3.146 2.686l-.085-.025.135-.405c2.517.774 3.527-.098 2.686-2.097-.231-.55-.196-1.484.035-2.717.081-.431.184-.887.304-1.355.095-.374.197-.739.3-1.082l.038-.128.066-.215.054-.17.034-.105z"
            fill="#000"
            transform="translate(.368 .245) translate(74.632 17.755) translate(0 4.035)"
          />
          <Path
            d="M.02 16.302l.217-.37.078.041.073.037c.11.056.234.117.373.182l.06.029c.466.218.982.437 1.531.64 1.265.47 2.514.789 3.676.893a7.815 7.815 0 001.494 0l.093-.01.054.421a8.158 8.158 0 01-1.681.012c-1.208-.108-2.495-.438-3.794-.92a20.56 20.56 0 01-1.567-.654l-.18-.086a15.432 15.432 0 01-.216-.106l-.125-.063-.086-.046z"
            fill="#C86A5D"
            transform="translate(.368 .245) translate(74.632 17.755) translate(0 4.035)"
          />
          <G transform="translate(.368 .245) translate(74.632 17.755) translate(1.988 4.778)">
            <Path
              d="M7.114 10.23c0 .345-.29.625-.65.625a.637.637 0 01-.649-.625c0-.345.291-.624.65-.624.358 0 .65.28.65.624"
              fill="#000"
            />
            <Path fill="#FFF" d="M0.139379385 1.85360941L2.79001746 0.154706359" />
            <Path fill="#000" d="M0.23121295 1.9861557L0.0474353771 1.72112682 2.69807345 0.0222237755 2.88185102 0.287252651z" />
            <Path fill="#FFF" d="M1.35414472 2.49069805L4.0047828 0.791795002" />
            <Path fill="#000" d="M1.44608873 2.62324434L1.26231116 2.35821547 3.91294923 0.659312419 4.0967268 0.924341295z" />
            <Path fill="#FFF" d="M10.5209347 2.70306093L13.1715728 1.00415788" />
            <Path fill="#000" d="M10.6128787 2.83560723L10.4291012 2.57057835 13.0797392 0.8716753 13.2635168 1.13670418z" />
            <Path fill="#FFF" d="M11.7358105 3.34014958L14.386559 1.64124653" />
            <Path fill="#000" d="M11.8277545 3.47269587L11.6439769 3.20766699 14.294615 1.50876394 14.4783926 1.77379282z" />
          </G>
          <Path
            d="M3.548.047c.337-.044.65-.058.936-.038.403.029.937.133 1.581.296A34.65 34.65 0 017.722.78l.222.071.22.072c.316.107.484.44.373.744a.613.613 0 01-.75.367l-.032-.01-.103-.035a31.337 31.337 0 00-1.896-.554l-.123-.031c-.522-.129-.95-.21-1.239-.23a3.495 3.495 0 00-.683.03 7.68 7.68 0 00-.996.206c-.173.047-.338.096-.492.146l-.076.025a8.436 8.436 0 00-.133.045l-.08.03a.616.616 0 01-.788-.331.577.577 0 01.344-.757l.064-.023a11.586 11.586 0 01.833-.26c.397-.107.789-.19 1.161-.238zM14.283 1.754l.147-.004c.6-.013 1.094.005 1.46.064.202.032.413.087.632.16a7 7 0 011.007.442c.22.115.43.237.63.36l.084.053c.134.086.234.153.292.194.264.186.321.543.127.797a.609.609 0 01-.807.137l-.082-.056-.068-.045a9.091 9.091 0 00-.746-.44 5.829 5.829 0 00-.83-.366 2.737 2.737 0 00-.434-.112c-.301-.048-.78-.062-1.376-.045-.364.01-.754.032-1.151.061l-.172.013c-.113.01-.222.018-.325.028l-.296.028a.588.588 0 01-.653-.506.575.575 0 01.501-.625l.088-.01.134-.012.037-.004c.183-.016.384-.033.596-.048.414-.03.822-.053 1.205-.064z"
            fill="#000"
            transform="translate(.368 .245) translate(74.632 17.755)"
          />
        </G>
        <G fill="#66231C" fillRule="nonzero">
          <Path
            d="M27.366 23.629c0-2.04-.428-4.623.724-5.643 1.152-1.02 3.648-2.753 2.208-4.895-1.44-2.142.192-6.324-2.304-6.834-2.496-.51-2.601-1.35-3.273-3.287-.672-1.938-3.526-.406-4.698-1.384-1.105-.923-2.427-2.514-5.487-.881-1.648.88-4.461-2.538-6.51 2.288-1.312 3.095-2.702.385-4.603 1.993-2.259 1.911-.06 4.705-2.076 6.133s-.26 3.065-.986 4.674c-1.196 2.655.85 11.985 2.194 12.8 1.344.817 2.04 2.19 2.04 2.19s-.62-2.237-.773-4.277c0 0-1.039.276-1.606-1.695-.567-1.972-.768-5.331.458-5.71 1.227-.378 1.456.682 1.456.682s.403 1.624.17 2.862c-.233 1.24.525 1.982.933.124.408-1.858 2.448-1.92 2.04-4.46-.408-2.539.408-4.025 2.04-4.768 1.632-.744 2.099-4.522 3.498-4.15 1.399.371 2.973-1.239 5.13-.124 2.157 1.115 1.982 2.663 3.847 3.035 1.866.371 2.856 1.61 2.856 2.663 0 1.053 1.283 1.425 1.633 2.911.35 1.487.043 5.894-.689 9.595 0 0 1.778-1.802 1.778-3.842z"
            transform="translate(.368 .245) translate(65.632 .755)"
          />
        </G>
        <G fill="#000" fillRule="nonzero">
          <Path
            d="M9.627.05c-2.276 0-4.12 1.79-4.12 3.997s1.844 3.996 4.12 3.996 4.12-1.789 4.12-3.996S11.904.05 9.628.05zm0 .942c1.74 0 3.15 1.368 3.15 3.055s-1.41 3.055-3.15 3.055-3.15-1.368-3.15-3.055S7.887.992 9.627.992zM19.76 1.003c-2.276 0-4.121 1.79-4.121 3.997s1.845 3.996 4.12 3.996c2.277 0 4.121-1.789 4.121-3.996s-1.844-3.997-4.12-3.997zm0 .942c1.74 0 3.15 1.368 3.15 3.055s-1.41 3.055-3.15 3.055S16.61 6.687 16.61 5s1.41-3.055 3.15-3.055z"
            transform="translate(.368 .245) translate(68.632 18.755)"
          />
          <Path
            d="M.039 1.183a.49.49 0 01.609-.27L.67.921l5.394 2.093c.249.096.37.37.27.612a.49.49 0 01-.609.27l-.022-.008L.31 1.795a.466.466 0 01-.27-.612zM12.822 4.244a.481.481 0 01.513-.417l.023.002 2.828.305a.474.474 0 01.43.52.481.481 0 01-.514.418l-.023-.002-2.829-.306a.474.474 0 01-.428-.52z"
            transform="translate(.368 .245) translate(68.632 18.755)"
          />
        </G>
      </G>
    </Svg>
  );
};
