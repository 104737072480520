import { v4 as uuidv4 } from 'uuid';

export const CONTRIBUTION_AMOUNTS = [
  {
    id: `price-${uuidv4()}`,
    price: 2, // Equivalent -> 2$
  },
  {
    id: `price-${uuidv4()}`,
    price: 5, // Equivalent -> 5$
  },
  {
    id: `price-${uuidv4()}`,
    price: 10, // Equivalent -> 10$
  },
  {
    id: `price-${uuidv4()}`,
    price: 20, // Equivalent -> 20$
  },
  {
    id: `price-${uuidv4()}`,
    price: 50, // Equivalent -> 50$
  },
  {
    id: `price-${uuidv4()}`,
    price: 100, // Equivalent -> 100$
  },
];
