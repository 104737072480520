import { useState, useCallback, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { checkAlpha, checkBeta, decodeSecureStoreAndSignIn, loginIdentity } from '../helpers';
import { getAsyncStorageObject, setAsyncStorageObject } from '../../common-util';
import { getReleaseChannel } from '../../../environment';
import { hash_alpha, hash_beta } from '../../common-auth';
import { AppContext } from '../../../AppContext';
import * as Sentry from '../../constants/Sentry';
import { GUEST_ACCOUNT } from '../../../constants';
import { PendoTrackEvent, Events } from '../../pendo/events';
import { AuthNavigationContainer } from '../../common-types/navigation-types';
import { Identity } from '../../common-types/types';

type LoginNavigator = StackNavigationProp<AuthNavigationContainer, `Auth`>;

const APPLE_APP_STORE_ID = `ddd157c6-147f-4461-a3ed-09c9b47b4462`;

const getPushToken = async (): Promise<string> => {
  const pushPermissionStatus = await getAsyncStorageObject(`pushPermissionStatus`);
  const push_token = pushPermissionStatus && pushPermissionStatus.token;
  return push_token;
};

export const useLogin = () => {
  const navigation = useNavigation<LoginNavigator>();
  const { invite_token, setUser, identity, setIdentity, setIsGuestMode } = useContext(AppContext);
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [needsCompletition, setNeedsCompletition] = useState(false);

  const finishLoginWithMessage = useCallback((message: string | null) => {
    setLoading(false);
    setError(message);
  }, []);

  const logUserIn = async (cleanedContact: string, password: string) => {
    try {
      setLoading(true);
      /**
       * alpha hash contact / sms, return salts
       * beta hash contact / sms with salts, match in table
       * if match, user exists in table, return secure_store + hashed password (key to de-encrypt secure_store on client)
       */
      const alpha = hash_alpha(cleanedContact);
      const { salts, beta_type } = await checkAlpha(alpha, cleanedContact);
      const keys_to_test = salts.map((salt: string) => {
        const beta = hash_beta(salt, cleanedContact, beta_type);
        return { beta, salt };
      });

      const checkedBeta = await checkBeta(keys_to_test);
      if (!checkedBeta) {
        // check beta returned null in this case - we don't have an account registered with this beta
        finishLoginWithMessage(`Phone number or password do not match an account`);
        return;
      }

      const { id, has_password } = checkedBeta;

      // user has registered with their number but didn't complete account creation / verification
      // take to veification and then walk through create password => createprofile.jsx
      if (id && !has_password) {
        setNeedsCompletition(true);
        return;
      }

      if (!id) {
        finishLoginWithMessage(`Phone number or password do not match an account`);
        return;
      }

      const push_token = await getPushToken();

      // Call LoginV2 to log in the identity.
      let loginResult;

      try {
        loginResult = await loginIdentity(id, password, push_token, invite_token);
        const identity = {
          ...loginResult,
          loginTime: new Date(),
          env: getReleaseChannel() || `dev`,
        };
        if (identity.id) {
          PendoTrackEvent(Events.LOGIN_SUCCESS, {
            contact: cleanedContact,
            loginTime: new Date(),
          });
        }
        setIdentity(identity);
        await setAsyncStorageObject(`identity`, identity);
      } catch (err) {
        console.error(`login exception:`, err);
        Sentry.withScope((scope: any) => {
          scope.setExtra(`err`, err);
          Sentry.captureException(err as Error);
        });
        finishLoginWithMessage(`An unexpected problem occurred on the server`);
        return;
      }

      //return loginResult;

      if (!loginResult) {
        finishLoginWithMessage(`Phone number or password do not match an account`);
        return;
      }

      // To proceed, user must verify their account.
      // if (!loginResult.is_verified) {
      //   setLoading(false);
      //   navigation.push(`Verify`, { id, cleaned: cleanedContact });
      //   return;
      // }

      // To proceed, user must also agree to terms & conditions
      if (loginResult.agreed_terms !== true || id === APPLE_APP_STORE_ID) {
        setLoading(false);
        navigation.navigate(`Auth`, { screen: `TermsScreen`, params: { identity_id: id, prev_screen: `Login` } });
        return;
      }

      // Do we have personas in our secure store?
      if (!loginResult.secure_store) {
        // no personas connected with identity yet, let's go create one
        //navigation.replace(`Auth`, { screen:`ProfileAvatar`, params: { identity_id: id }});
        navigation.replace(`Persona`, { screen: `DOB` });
        return;
      }

      signInPersonas(loginResult);
    } catch (err) {
      console.error(`login exception:`, err);
      Sentry.withScope((scope: any) => {
        scope.setExtra(`err`, err);
        Sentry.captureException(err as Error);
      });
      finishLoginWithMessage(`An unexpected problem occurred in the app`);
    }
  };

  const signInPersonas = async (loginResult: Pick<Identity, `secure_store`>) => {
    const push_token = await getPushToken();
    const signed_users = await decodeSecureStoreAndSignIn(loginResult.secure_store, { push_token, invite_token });

    // console.log(`logUserIn: signed_users = ${JSON.stringify(signed_users)}`);
    if (!signed_users) {
      finishLoginWithMessage(`Persona login failed`);
      throw new Error(`Persona login failed`);
    }
    const newUser = signed_users[0];
    setUser(newUser);
    setIsGuestMode(newUser.id === GUEST_ACCOUNT);

    // Configure Sentry so that all future calls will include identity & user information
    Sentry.configureScope((scope: any) => {
      scope.setUser({ username: newUser?.handle });
      scope.setExtras({ 'global.identity': identity, 'global.user': newUser });
    });

    finishLoginWithMessage(null);
  };

  return {
    logUserIn,
    loading,
    error,
    needsCompletition,
  };
};
