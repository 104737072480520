import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Text, View, ListRenderItem, FlatList, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { Colors, Typography } from '../../common-styles';
import { Activity, PollOption } from '../../common-types/types';
import { MeshIcon } from '../../common-ui';
import { ACTIVITY_DETAILS_FRAGMENT, ADD_VOTE, client, PROFILE_ACTIVITY_DETAILS_FRAGMENT } from '../../graphql';
import { toggleJoinMeshModal } from '../../redux/guestSlice';
import { Modals, setModal } from '../../redux/postSlice';
import { MediaTypes } from '../helpers';

type PostPollProps = {
  activity: Activity;
  action?: boolean;
};

const PostPolls: React.FC<PostPollProps> = ({ activity, action = true }) => {
  const [voted, setVoted] = useState(false);
  const [endLabel, setEndLabel] = useState(``);
  const dispatch = useDispatch();
  const { name: routeName } = useRoute();
  const { user, isGuestMode } = useContext(AppContext);
  const navigation = useNavigation<any>();

  const [key, setKey] = useState(``);

  const { __typename, id, poll, media_type, origin_group, origin_group_id, origin_group_member: group_member } = activity || {};

  useEffect(() => {
    if (media_type !== MediaTypes.POLL) return;
    const endTime = Number(activity?.poll?.end_time) || 0;

    const currentTime = new Date().getTime();
    const delta = endTime - currentTime;

    if (delta <= 0) {
      setEndLabel(`Poll ended`);
      return;
    }

    const daySeconds = 1000 * 60 * 60 * 24;
    const remainingDays = Math.floor(delta / daySeconds);
    const remainingHrs = Math.floor((delta % daySeconds) / (1000 * 60 * 60));
    setEndLabel(`Poll ends in ${remainingDays}d ${remainingHrs}hrs`);
  }, [activity?.poll?.end_time, media_type]);

  useEffect(() => {
    if (media_type !== MediaTypes.POLL) return;
    const has_voted =
      poll?.options?.reduce((voted, option) => {
        return voted || option?.voted;
      }, false) || false;
    setVoted(has_voted);
  }, [media_type, poll?.options]);

  const [addVote] = useMutation(ADD_VOTE, {
    onCompleted: (data) => {
      const temp = poll?.options;

      temp?.map((p) => {
        data?.addVote?.map((av: any) => {
          if (av?.option_id === p.id) {
            p.voted = av.voted;
            p.vote_count = av.count;
          }
        });
      });

      client.writeFragment({
        id: __typename === `ProfileActivity` ? `ProfileActivity:${id}` : `Activity:${id}`,
        fragment: __typename === `ProfileActivity` ? PROFILE_ACTIVITY_DETAILS_FRAGMENT : ACTIVITY_DETAILS_FRAGMENT,
        fragmentName: __typename === `ProfileActivity` ? `ProfileActivityDetails` : `ActivityDetails`,
        data: {
          ...activity,
          poll: {
            ...poll,
            options: temp,
          },
        },
      });
      setKey(id);
    },
  });

  const handleClickPollItem = useCallback(
    (item: PollOption) => {
      if (isGuestMode) {
        dispatch(toggleJoinMeshModal({ open: true, group: origin_group }));
        return;
      }
      if (!group_member) {
        if (routeName === `HomeScreen` || routeName === `CommunityPreview`) {
          navigation.push(`PostDetail`, { id, group_id: origin_group_id });
        }
        dispatch(setModal(Modals.JOIN));
        return;
      }
      console.log('🚀 ~ addVote.input', { option_id: item?.id, activity_id: id, user_id: user?.id });

      addVote({ variables: { option_id: item?.id, activity_id: id, user_id: user?.id } });
      setVoted(true);
    },
    [isGuestMode, group_member, addVote, id, user?.id, dispatch, origin_group, routeName, navigation, origin_group_id],
  );

  const totalVotes = useMemo(() => {
    return activity?.poll?.options?.reduce((total, option) => total + option.vote_count, 0) || 0;
  }, [activity, key]);

  const renderPollOption: ListRenderItem<PollOption> = useCallback(
    ({ item }) => {
      if (action || true) {
        return (
          <TouchableOpacity onPress={() => handleClickPollItem(item)}>
            <View style={{ flexDirection: `row`, alignItems: `center`, paddingVertical: 4 }}>
              <MeshIcon
                name={item?.voted ? `radio-on` : `radio-off`}
                size={24}
                color={Colors.brandPurple}
                style={{ marginRight: 16 }}
              />
              <Text>{item.content}</Text>
            </View>
          </TouchableOpacity>
        );
      }

      return (
        <View style={{ flexDirection: `row`, alignItems: `center`, paddingVertical: 4 }}>
          <MeshIcon
            name={item?.voted ? `radio-on` : `radio-off`}
            size={24}
            color={Colors.brandPurple}
            style={{ marginRight: 16 }}
          />
          <Text>{item.content}</Text>
        </View>
      );
    },
    [action, handleClickPollItem],
  );

  const renderPollVotedOption: ListRenderItem<PollOption> = useCallback(
    ({ item }) => {
      const { vote_count, voted } = item;
      const percentage = (vote_count / totalVotes) * 100 || 0;

      return (
        <View style={{ flexDirection: `row`, alignItems: `center`, paddingVertical: 4, marginVertical: 4 }} key={key}>
          <View style={{ width: 50, alignItems: `center`, justifyContent: `center` }}>
            <Text style={Typography.text(`base`, `gray`)}>{percentage.toFixed(0)}%</Text>
          </View>
          <View>
            <Text style={Typography.text(`base`, `gray`)}>{item.content}</Text>
          </View>

          <View
            style={{
              position: `absolute`,
              top: 0,
              left: 0,
              bottom: 0,
              backgroundColor: voted ? Colors.brandCyan : Colors.gray,
              borderRadius: 8,
              opacity: 0.3,
              width: `${percentage}%`,
            }}
          />
        </View>
      );
    },
    [totalVotes],
  );

  if (media_type !== MediaTypes.POLL) return null;
  return (
    <View style={{ paddingHorizontal: 16 }}>
      <View style={{ flexDirection: `row`, justifyContent: `flex-end` }}>
        <Text style={{ color: Colors.gray }}>{totalVotes} votes</Text>
      </View>

      {voted ? (
        <FlatList keyExtractor={(item) => item.id} data={activity?.poll?.options} renderItem={renderPollVotedOption} />
      ) : (
        <FlatList keyExtractor={(item) => item.id} data={activity?.poll?.options} renderItem={renderPollOption} />
      )}

      <View style={{ flexDirection: `row`, justifyContent: `flex-end`, paddingVertical: 4 }}>
        <Text style={{ color: Colors.gray, fontSize: 12 }}>{endLabel}</Text>
      </View>
    </View>
  );
};

export default PostPolls;
