import gql from 'graphql-tag';

//TODO:
// Replace complex fragments into simpler and more flexible ones
// UserDetails => UserCoreDetails and per-request fields (e. description, pronouns, splash)
// GroupDetails => GroupCoreDetails and per-request fields
// Activity => replace mentions of the previous fragments

export const TOPIC_GROUP_CORE_DETAILS_FRAGMENT = gql`
  fragment TopicGroupCoreDetails on GroupTopics {
    id
    topic
    post_count
    status
  }
`;

export const TOPIC_GROUP_FRAGMENT = gql`
  fragment TopicGroupDetails on GroupTopics {
    id
    group_id
    topic
    hidden
    active
    post_count
    created_at
    updated_at
    favorite
    following
    status
    suggested_count
  }
`;

export const FOLLOW_REQUEST_FRAGMENT = gql`
  fragment FollowRequestDetails on UserFollowItem {
    id
    pronouns
    requested_at
    isFollowing
    persona_id
    name
    avatar_url
    send_notification
    handle
    accepted
  }
`;

export const USER_DETAILS_FRAGMENT = gql`
  fragment UserDetails on Persona {
    id
    handle
    name
    description
    avatar_url
    splash_url
    identity_verified
    pronouns
    dob
    isVerified
    isBanned
    follow_request {
      ...FollowRequestDetails
    }
  }
  ${FOLLOW_REQUEST_FRAGMENT}
`;

export const USER_CORE_DETAILS_FRAGMENT = gql`
  fragment UserCoreDetails on Persona {
    id
    handle
    name
    avatar_url
    dob
  }
`;

export const USER_MENTION_FRAGMENT = gql`
  fragment UserMention on Persona {
    id
    handle
    name
  }
`;

export const POLL_OPTION_FRAGMENT = gql`
  fragment PollOption on Option {
    post_id
    id
    media_type
    content
    display_order
    vote_count
    voted
    removed
    end_time
  }
`;

export const GROUP_CORE_DETAILS_FRAGMENT = gql`
  fragment GroupCoreDetails on Group {
    id
    name
    splash_url
    avatar_url
    group_member_count
    latest_post
    application
    application_pending
    join_request_pending
    guidelines_updated_at
    created_at
    latest_post_actor_id
    discoverable
    privacy
    isVerified
    sponsor {
      id
      name
      group_id
      avatar_url
      link_url
      end_date
      sponsor_ad
    }
  }
`;
export const GROUP_EXTRA_DETAILS_FRAGMENT = gql`
  fragment GroupExtraDetails on Group {
    privacy
    description
    created_at
    updated_at
    guidelines
    guidelines_updated_at
    application
    application_pending
    join_request_pending
    group_member_count
    suspended
    suspend_expiration
    archived
    banned
    #type
    discoverable
    joinable
    content_visibility
    who_can_invite
    # pinned
    category {
      label
      value
    }
  }
`;

// If you want to add a property make sure to add it on GroupCoreDetails
export const GROUP_DETAILS_FRAGMENT = gql`
  fragment GroupDetails on Group {
    id
    name
    privacy
    description
    avatar_url
    splash_url
    created_at
    updated_at
    guidelines
    guidelines_updated_at
    application
    application_pending
    join_request_pending
    group_member_count
    suspended
    suspend_expiration
    latest_post
    latest_post_actor_id
    archived
    banned
    #type
    discoverable
    joinable
    content_visibility
    who_can_invite
    # pinned
    category {
      label
      value
    }
    isVerified
    sponsor {
      id
      name
      group_id
      avatar_url
      link_url
      end_date
      sponsor_ad
    }
  }
`;

export const GROUP_CAROUSEL_FRAGMENT = gql`
  fragment GroupCarousel on Group {
    id
    name
    avatar_url
    group_member_count
  }
`;

export const GROUP_MEMBER_DETAILS_FRAGMENT = gql`
  fragment GroupMemberDetails on GroupMember {
    id
    group_id
    persona_id
    persona {
      ...UserDetails
    }
    role_id
    role_name
    has_agreed
    agreement_timestamp
    banned
    ban_expiration
    restricted
    restricted_expiration
    created_at
    can_participate
    can_post
    can_invite
    last_seen_at
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const GROUP_INVITATION_DETAILS_FRAGMENT = gql`
  fragment GroupInvitationDetails on GroupInvitation {
    id
    invite_token
    persona_id
    group_id
    role_id
    role_name
    inviter_id
    inviter {
      ...UserDetails
    }
    created_at
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const REACTION_DETAILS_FRAGMENT = gql`
  fragment ReactionDetails on ActivityReaction {
    id
    kind
    activity_id
    user_id
    user {
      name
      handle
      id
      isVerified
      avatar_url
    }
    data {
      object
      group_id
      user_id
      removed
      comment_image
      link_preview {
        url
        entered
        title
        imageUrls
      }
      validMentionTargets {
        id
        name
        handle
      }
      customReaction {
        name
        url
      }
    }
    created_at
    updated_at
    parent
    own_like
    children_counts {
      comment_like
      comment_reply
    }
    latest_children {
      comment_like {
        id
        kind
        activity_id
        user_id
        user {
          id
          name
          handle
          isVerified
          avatar_url
        }
        created_at
        updated_at
      }
      comment_reply {
        id
        kind
        activity_id
        user_id
        own_like
        children_counts {
          comment_like
        }
        user {
          id
          name
          handle
          isVerified
          avatar_url
        }
        data {
          object
          group_id
          user_id
          removed
          comment_image
          validMentionTargets {
            id
            name
            handle
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const ACTIVITY_DETAILS_FRAGMENT = gql`
  fragment ActivityDetails on Activity {
    id
    post_id
    origin_activity_id
    removed
    frozen # flag if this particular post has been frozen by an admin / moderator of community
    actor
    actor_role
    original_verb # only used for displaying mod notifications in notification screen
    is_admin_or_mod
    verb
    object
    content
    foreign_id
    time
    pinned
    created_at
    updated_at
    imageUrls
    video_urls
    media_type
    meta_style {
      fontSize
      lineHeight
      align
      hasBlob
      blobIndex
    }
    linkPreview {
      entered
      url
      title
      imageUrls
      imageResizeMode
      imageBackgroundColor
    }
    user {
      ...UserDetails
    }
    origin_group_id
    origin_group {
      ...GroupDetails
    }
    origin_group_member {
      ...GroupMemberDetails
    }
    can_participate
    is_frozen # used if the current user is frozen in community
    own_like
    reaction_counts {
      comment_reply
      comment
      like
    }
    latest_reactions {
      comment {
        ...ReactionDetails
      }
      like {
        ...ReactionDetails
      }
    }
    pending
    validMentionTargets {
      id
      name
      handle
    }
    topics {
      ...TopicGroupDetails
    }
    custom_reaction_counts {
      kind
      url
      count
      own_like
    }
    as_community
    title
    poll {
      options {
        ...PollOption
      }
      end_time
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
  ${REACTION_DETAILS_FRAGMENT}
  ${USER_DETAILS_FRAGMENT}
  ${TOPIC_GROUP_FRAGMENT}
  ${POLL_OPTION_FRAGMENT}
`;

export const PROFILE_ACTIVITY_DETAILS_FRAGMENT = gql`
  fragment ProfileActivityDetails on ProfileActivity {
    id
    post_id
    origin_activity_id
    removed
    frozen # flag if this particular post has been frozen by an admin / moderator of community
    actor
    actor_role
    original_verb # only used for displaying mod notifications in notification screen
    is_admin_or_mod
    verb
    object
    content
    foreign_id
    time
    pinned
    created_at
    updated_at
    imageUrls
    video_urls

    linkPreview {
      entered
      url
      title
      imageUrls
      imageResizeMode
      imageBackgroundColor
    }

    origin_group_id

    reaction_counts {
      comment_reply
      comment
      like
    }
    latest_reactions {
      comment {
        ...ReactionDetails
      }
      like {
        ...ReactionDetails
      }
    }
    pending
    media_type
    meta_style {
      fontSize
      lineHeight
      align
      hasBlob
      blobIndex
    }
    custom_reaction_counts {
      kind
      url
      count
      own_like
    }

    validMentionTargets {
      id
      name
      handle
    }
    topics {
      ...TopicGroupDetails
    }
    as_community
    title
    user {
      ...UserDetails
    }
    origin_group {
      ...GroupDetails
    }
    origin_group_member {
      ...GroupMemberDetails
    }
    own_like
    can_participate
    is_frozen # used if the current user is frozen in community
    poll {
      options {
        ...PollOption
      }
      end_time
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
  ${REACTION_DETAILS_FRAGMENT}
  ${USER_DETAILS_FRAGMENT}
  ${TOPIC_GROUP_FRAGMENT}
  ${POLL_OPTION_FRAGMENT}
`;

export const ADVERTISER_DETAILS_FRAGMENT = gql`
  fragment AdvertiserDetails on Advertiser {
    id
    name
    handle
    avatar_url
  }
`;

export const AD_DETAILS_FRAGMENT = gql`
  fragment AdDetails on Ad {
    id
    type
    ad_name
    advertiser_id
    advertiser {
      ...AdvertiserDetails
    }
    image_urls
    link_url
    copy
    button
    button_url
  }
  ${ADVERTISER_DETAILS_FRAGMENT}
`;

export const CUSTOM_QUESTION_DETAILS_FRAGMENT = gql`
  fragment CustomQuestionDetails on CustomApplicationQuestion {
    key
    type {
      id
      name
    }
    options {
      id
      value
    }
  }
`;

export const MODERATION_APPLICATION_DETAILS_FRAGMENT = gql`
  fragment ModerationApplicationDetails on GroupApplication {
    photo
    identity_id
    full_name
    short_name
    questions {
      key
      value
    }
  }
`;

export const MODERATION_ISSUE_DETAILS_FRAGMENT = gql`
  fragment ModerationIssueDetails on ModerationIssue {
    id
    status
    type
    category
    reporter_id
    group_id
    action_taken
    object_id
    reporter {
      ...UserDetails
    }
    reportee {
      ...UserDetails
    }
    report_time
    reasons {
      id
      label
      body
    }
    moderator_persona_id
    moderator {
      ...UserDetails
    }
    explanation
    mod_explanation
    original_action
    original_author_id
    created_at
    updated_at
    escalated
    #TODO: move origin_group_id to origin_group
    origin_group_id
    origin_group {
      id
      name
    }
    group {
      ...GroupDetails
    }
    origin_group_member {
      ...GroupMemberDetails
    }
    application {
      ...ModerationApplicationDetails
    }
    res_status # still used by code (ReportModal.jsx)
    activity {
      __typename
      ... on ProfileActivity {
        ...ProfileActivityDetails
      }
      ... on Activity {
        ...ActivityDetails
      }
    }
    actions {
      type
      action
      created_at
      handle
    }
  }
  ${MODERATION_APPLICATION_DETAILS_FRAGMENT}
  ${ACTIVITY_DETAILS_FRAGMENT}
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
  ${USER_DETAILS_FRAGMENT}
  ${PROFILE_ACTIVITY_DETAILS_FRAGMENT}
`;

export const MODERATION_CATEGORY_DETAILS_FRAGMENT = gql`
  fragment ModerationCategoryDetails on ModerationGroupCategory {
    category_name
    count
    issues {
      ...ModerationIssueDetails
    }
  }
  ${MODERATION_ISSUE_DETAILS_FRAGMENT}
`;

export const MODERATED_GROUP_DETAILS_FRAGMENT = gql`
  fragment ModeratedGroupDetails on ModeratedGroup {
    group {
      ...GroupDetails
    }
    categories {
      ...ModerationCategoryDetails
    }
    count_open
    admin_mod_count
    group_members {
      ...GroupMemberDetails # list of all entries in group_member table for specified group
    }
    admin_mods {
      ...GroupMemberDetails
    }
    group_member {
      ...GroupMemberDetails # current user GroupMember info
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${MODERATION_CATEGORY_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const MODERATED_GROUP_MEMBERS_FRAGMENT = gql`
  fragment ModeratedGroupMembersDetails on ModeratedGroupMembers {
    #categories {
    #  ...ModerationCategoryDetails
    #}
    admin_mod_count
    group_members {
      ...GroupMemberDetails # list of all entries in group_member table for specified group
    }
    admin_mods {
      ...GroupMemberDetails
    }
    group_member {
      ...GroupMemberDetails # current user GroupMember info
    }
  }
  #$ {MODERATION_CATEGORY_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const MODERATED_GROUP_DETAILS_2_FRAGMENT = gql`
  fragment ModeratedGroupDetails2 on ModeratedGroup2 {
    group {
      ...GroupDetails
    }
    count_open
    admin_mod_count
  }
  ${GROUP_DETAILS_FRAGMENT}
`;

// export const MODERATION_COMMENT_DETAILS_FRAGMENT = gql`
//   fragment ModerationCommentDetails on ModerationComment {
//     __typename
//     id
//     internal
//     author_id
//     group_id
//     content
//     timestamp
//     author {
//       __typename
//       id
//       name
//       avatar_url
//       description
//     }
//     role
//   }
// `;

export const NOTIFICATION_DETAILS_FRAGMENT = gql`
  fragment NotificationDetails on Notification {
    id
    unseen
    unread
    verb
    updated_at
    preview
    image_previews
    is_seen
    is_read
    activity_count
    actor_count
    created_at
    isMention
    invite_token
  }
`;

// FIXME: resolve tension between ActivityReaction and StreamReactionActivity
export const NOTIFICATION_REACTION_DETAILS_FRAGMENT = gql`
  fragment NotificationReactionDetails on NotificationReaction {
    user {
      ...UserDetails
    }
    origin_group {
      ...GroupDetails
    }
    origin_group_member {
      ...GroupMemberDetails
    }
    reaction {
      activity_id
      id
      data {
        user_id
        object
        group_id
      }
      kind
      created_at
    }
  }
  ${USER_DETAILS_FRAGMENT}
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
`;

export const MODERATION_NOTIFICATION_DETAILS_FRAGMENT = gql`
  fragment ModerationNotificationDetails on ModerationNotification {
    id
    actor
    verb
    original_verb
    object
    content
    mod_explanation
    foreign_id
    time
    imageUrl
    imageUrls
    mod_group_id
    origin_group {
      ...GroupDetails
    }
    origin_group_member {
      ...GroupMemberDetails
    }
    target
    origin_activity_id
    linkPreview {
      entered
      url
      title
      imageUrls
      imageResizeMode
      imageBackgroundColor
    }
    reaction_id
    reporter_id
    reporter {
      ...UserDetails
    }
    reportee_id
    reportee {
      ...UserDetails
    }
  }
  ${GROUP_DETAILS_FRAGMENT}
  ${GROUP_MEMBER_DETAILS_FRAGMENT}
  ${USER_DETAILS_FRAGMENT}
`;

export const UPDATE_ACTIVITY_LIKE_FRAGMENT = gql`
  fragment ActivityOwnLike on Activity {
    id
    own_like
    reaction_counts {
      like
    }
  }
`;

export const UPDATE_ACTIVITY_CONTENT = gql`
  fragment ActivityContent on Activity {
    id
    content
    imageUrls
    video_urls
    meta_style {
      fontSize
      lineHeight
      align
      hasBlob
      blobIndex
    }
    linkPreview {
      entered
      url
      title
      imageUrls
      imageResizeMode
      imageBackgroundColor
    }
  }
`;
export const UPDATE_REACTION_LIKE_FRAGMENT = gql`
  fragment ReactionOwnLike on ActivityReaction {
    id
    own_like
    children_counts {
      comment_like
    }
  }
`;

export const UPDATE_ACTIVITY_COMMENT_FRAGMENT = gql`
  fragment ActivityCommentCount on Activity {
    id
    reaction_counts {
      comment
    }
  }
`;

export const UPDATE_REACTION_COMMENT_FRAGMENT = gql`
  fragment ReactionCommentCount on ActivityReaction {
    id
    children_counts {
      comment
      comment_reply
    }
  }
`;

export const PERSONA_RELATIONSHIP_FRAGMENT = gql`
  fragment PersonaRelationshipFragment on PersonaRelationship {
    owner_id
    other_persona_id
    permissions
    blocked
    created_at
    updated_at
    blocked_user {
      ...UserDetails
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const IDENTITY_DETAILS_FRAGMENT = gql`
  fragment IdentityDetails on Identity {
    id
    secure_store
    password
    accessToken
    refreshToken
    invite_group_id
    res_status
    is_verified
    agreed_terms
    agreed_coc
  }
`;

export const SETTING_DETAILS_FRAGMENT = gql`
  fragment SettingDetails on Setting {
    id
    key
    label
    value
    value_label
    visibility
    max_length
    type
    created_at
    updated_at
    section
    index
    group_id
    user_id
  }
`;

export const SETTING_VALUE_FRAGMENT = gql`
  fragment SettingValue on Setting {
    id
    value
  }
`;
