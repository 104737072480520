import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import React from 'react';
import { getReleaseChannel } from '../../environment';
import { getAllPersonas } from '../auth/helpers';
import { Colors, Typography } from '../common-styles';
import { MeshIcon, ListItem } from '../common-ui';
import { commonPropTypes, setAsyncStorageObject } from '../common-util';
import { themes, withTheme } from './ThemeProvider';
import { AppContext } from '../../AppContext';

const _ThemeSelector = React.memo(({ setting, saveSetting, setTheme, theme }) => {
  const { user } = React.useContext(AppContext);
  const [collapsed, setCollapsed] = React.useState(true);

  const setSetting = React.useCallback(
    async (key) => {
      const newSetting = {
        ...setting,
        value: key,
        user_id: user?.id,
        group_id: null,
      };
      // console.log(`newSetting`, newSetting);
      await saveSetting(newSetting, true);
      // update color_theme for the current user && persona obj
      const personas = (await getAllPersonas()).flat();
      const found = personas.find((item) => item.id === user?.id);
      const updated = { ...found, color_theme: key };
      const ENV = getReleaseChannel() || `dev`;
      await setAsyncStorageObject(`persona-${ENV}-${updated.id}`, updated);
    },
    [setting, user?.id, saveSetting],
  );

  const renderItem = React.useCallback(
    (item) => {
      return (
        <TouchableOpacity
          key={item.key}
          activeOpacity={0.8}
          onPress={() => {
            setTheme(item.key);
            setSetting(item.key);
          }}
        >
          <View style={[itemStyle.container, { backgroundColor: item.primary }]}>
            <Text style={[itemStyle.text, { color: item.fontColor }]}>{item.key}</Text>
          </View>
        </TouchableOpacity>
      );
    },
    [setSetting, setTheme],
  );

  return (
    <View style={{ flex: 1, justifyContent: `center` }}>
      <ListItem
        title="Change theme color"
        titleStyle={{ fontWeight: `bold` }}
        subtitle={`Current theme: ${theme.key}`}
        subtitleStyle={{
          fontSize: Typography.smallFontSize,
          color: Colors.darkGray,
        }}
        onPress={() => setCollapsed((prev) => !prev)}
        rightIcon={<MeshIcon name={!collapsed ? `chevron-down` : `chevron-right`} size={20} />}
      />
      <Collapsible collapsed={collapsed} style={{ flex: 1 }}>
        {themes.map(renderItem)}
      </Collapsible>
    </View>
  );
});

_ThemeSelector.propTypes = {
  setting: PropTypes.object.isRequired,
  saveSetting: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  theme: commonPropTypes.theme().isRequired,
};

const itemStyle = StyleSheet.create({
  container: {
    flex: 1,
    height: 50,
    padding: 10,
    justifyContent: `center`,
  },
  text: { fontWeight: `600` },
});

export const ThemeSelector = withTheme(_ThemeSelector);
