import { Dimensions, StyleSheet } from 'react-native';
import { Colors } from '../common-styles';

export const profileStyles = StyleSheet.create({
  feedContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundColor,
  },
  profileHeaderContainer: {
    alignItems: `center`,
    paddingTop: 20,
    overflow: `hidden`,
    backgroundColor: `white`,
    paddingBottom: 20,
    marginBottom: 8,
  },
  splashImgContainer: {
    width: Dimensions.get(`window`).width / 1.1,
    height: Dimensions.get(`window`).height / 3.8,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    position: `absolute`,
    marginTop: 18,
    overflow: `hidden`,
    backgroundColor: Colors.darkestGray,
  },
  splashImg: {
    flex: 1,
    width: null,
    height: null,
    backgroundColor: Colors.mediumGray,
  },
  splashFiller: {
    width: Dimensions.get(`window`).width / 1.1,
    height: Dimensions.get(`window`).height / 3.8,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    position: `absolute`,
    zIndex: -200,
    backgroundColor: Colors.darkestGray,
    marginTop: 18,
  },
  avatarContainer: {
    alignSelf: `center`,
    paddingTop: Dimensions.get(`window`).height / 7.5,
  },
  textContainer: {
    alignItems: `center`,
    justifyContent: `center`,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `flex-end`,
  },
  imageButtonContainer: {
    flex: 1,
    flexDirection: `row`,
    justifyContent: `flex-start`,
  },
  settingsButton: {
    width: 40,
    marginHorizontal: 10,
    alignSelf: `flex-end`,
  },
  buttonStyle: {
    alignSelf: `flex-end`,
    marginHorizontal: 10,
    backgroundColor: Colors.themeColor,
  },
});
