import React from 'react';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { ContributionContext } from '../../contribution/context/ContributionContext';
import { disablePush } from '../../push-notification/helpers';
import { removeAllPersonas } from '../../auth/helpers';
import { removeAsyncStorageKeys } from '../../common-util';
import { client } from '../../graphql';

export const useLogoutAll = () => {
  const { logout, pushToken } = React.useContext(AppContext);
  const contribution = React.useContext(ContributionContext);
  const dispatch = useDispatch();

  const [loggingOut, setLoggingOut] = React.useState(false);

  const logoutAll = async () => {
    console.log(`- Calling logoutAll Hook -`);
    contribution?.cleanContext();
    dispatch({ type: `LOG_OUT` });
    setLoggingOut(true);
    await disablePush(pushToken);
    await removeAllPersonas();
    await removeAsyncStorageKeys([`identity`, `currentUser`, `launchUrl`]);
    client.cache.reset();
    logout();
    setLoggingOut(false);
  };

  return {
    loggingOut,
    logoutAll,
  };
};
