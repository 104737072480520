// import { DrawerNavigationProp } from '@react-navigation/drawer';
import React, { useContext, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AppContext } from '../../AppContext';
import { multiplePersonasAllowed, switchPersona } from '../auth/helpers';
import { Colors } from '../common-styles';
import { User } from '../common-types/types';
import { Avatar, MeshIcon } from '../common-ui';
import { client } from '../graphql';
import { Events, PendoTrackEvent } from '../pendo/events';
import { LeftDrawerNavigator } from '../common-types/navigation-types';
import { useDispatch } from 'react-redux';
import { setPersonalFeedRefresh } from '../redux/feedSlice';

type AvatarSizes = {
  [key: string]: number;
  big: number;
  small: number;
};
type PersonaMenuProps = {
  personas: User[];
};

const avatarSizes: AvatarSizes = { big: 69, small: 32 };

export const PersonaMenu: React.FC<PersonaMenuProps> = ({ personas }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<LeftDrawerNavigator>();

  const { identity, user, setUser } = useContext(AppContext);
  const [switching, setSwitching] = useState(false);
  const [creating, setCreating] = useState(false);
  // Usar otra variable de control para observar y detectar que cambio la persona.

  if (personas.length < 1) return null;
  const activeIndex = personas?.findIndex((persona) => persona.id === user?.id);
  const canCreateExtraPersona = identity && multiplePersonasAllowed(identity);

  const switchToPersona = async (persona: User): Promise<void> => {
    setSwitching(true);
    try {
      if (persona.id && user?.id !== persona.id) {
        await switchPersona(persona.id);
        setUser(persona);
        client.reFetchObservableQueries();
        dispatch(setPersonalFeedRefresh(true));
      }
    } catch (err) {
      console.error(`PersonaMenu->switchToPersona ${persona.handle} failed: ${err}`);
      throw err;
    } finally {
      setSwitching(false);
    }
  };

  const createPersona = () => {
    setCreating(true);
    try {
      if (!identity) return;
      navigation.navigate(`CreateAdditionalProfile`, { identity_id: identity.id, cancelable: true });
    } catch (err) {
      console.error(`createPersona failed: ${err}`);
      throw err;
    } finally {
      setCreating(false);
    }
  };

  const renderAvatar = (persona: User, sizeName: string, onPress: () => void) => {
    const size = avatarSizes[sizeName];
    if (!size) throw new Error(`renderAvatar: unknown sizeName ${sizeName}`);
    const disabled = sizeName === `small` && (switching || creating);
    return (
      <View key={persona?.id} style={{ opacity: disabled ? 0.8 : 1.0 }}>
        <Avatar style={styles.avatar} border disabled={disabled} user={persona} size={size} onPress={onPress} />
      </View>
    );
  };
  return (
    <View style={{ flex: 1, flexDirection: `row`, flexWrap: `wrap`, margin: 6, marginLeft: 12, alignItems: `center` }}>
      {personas.map((persona, index) => {
        // focused persona is a big icon
        if (index === activeIndex) {
          const updatedPersona = { ...persona, avatar_url: user?.avatar_url };
          return renderAvatar(updatedPersona, `big`, () => {
            PendoTrackEvent(Events.PROFILE, {
              username: persona?.handle,
              source: `Drawer`,
              element: `Persona Icon`,
            });
            if (!persona?.id) return;
            navigation.navigate(`ProfileStack`, { screen: `Profile`, params: { user_id: persona.id } });
          });
        }
        // other personas are small icons
        return renderAvatar(persona, `small`, () => switchToPersona(persona));
      })}
      {canCreateExtraPersona && (
        <TouchableOpacity
          key="create"
          disabled={switching || creating}
          hitSlop={{ top: 3, left: 3, right: 3, bottom: 3 }}
          onPress={createPersona}
        >
          <View style={{ height: 44, width: 44, justifyContent: `center`, alignItems: `center` }}>
            <MeshIcon name="circle-plus" size={32} focused color={Colors.mediumLighterGray} style={{ margin: 6 }} />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  avatar: { margin: 6 },
});
