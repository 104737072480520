import { useNavigation } from '@react-navigation/native';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Platform } from 'react-native';
import { Extrapolate } from 'react-native-reanimated';
import { useSelector } from 'react-redux';
import { useHeaderHeight } from '@react-navigation/elements';
import { RootState } from '../../../redux/store';
import { NavigationHeader, NavigationHeaderRight, NavigationHeaderTitle } from '../NavigationHeader';

const { Value } = Animated;

type HeaderAnimationConfig = {
  headerHeight?: number;
  minScroll?: number;
  nativeHeader?: number;
};

/**
 * This hook is used to hide the header when the user scrolls down.
 *
 * Notice that we use a MIN_SCROLL value to prevent IOS from hiding the header when the user scrolls up.
 * @returns {[Animated.Value, Animated.Value]}
 */
export const useHideHeader = (community: any, config?: HeaderAnimationConfig) => {
  const navigation = useNavigation<any>();
  const header = useHeaderHeight();

  const { openItems } = useSelector((state: RootState) => state.feed);
  const { headerHeight = 259, minScroll = 0, nativeHeader = header } = config || {};
  const offset = useRef(new Value(0)).current;
  const realDistance = useRef(0);
  const [contentOffset, setContentOffset] = useState({ x: 0, y: 0 });

  const clampedScrollY = offset.interpolate({
    inputRange: [minScroll, minScroll + 1],
    outputRange: [0, 1],
    extrapolateLeft: Extrapolate.CLAMP,
  });

  const updateNavigation = debounce((collapsed) => {
    navigation?.setOptions(
      Platform.select({
        ios: {
          header: () => (
            <NavigationHeader collapsed={collapsed} openItems={openItems} community={community} nativeHeader={nativeHeader} />
          ),
        },
        android: {
          headerRight: () => <NavigationHeaderRight collapsed={collapsed} openItems={openItems} community={community} />,
          headerTitle: () => <NavigationHeaderTitle collapsed={collapsed} community={community} />,
        },
        default: {}, // mesh doesn't work on any other platform
      }),
    );
  }, 1000);

  useEffect(() => {
    offset.addListener(({ value }) => {
      const collapsed = value > 259 && realDistance.current < value;
      realDistance.current = value;
      updateNavigation(collapsed);
    });
    return () => offset.removeAllListeners();
  }, [navigation, offset, updateNavigation]);

  const minusScrollY = Animated.multiply(clampedScrollY, -1);
  const translateY = Animated.diffClamp(minusScrollY, -headerHeight, 0);

  const handleScroll = Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], { useNativeDriver: true });

  const updateContentOffset = useCallback(() => {
    // @ts-ignore: //TODO: verify translateY.__getValue typings
    const y = Math.abs(Number(translateY.__getValue()));
    setContentOffset({ x: 0, y });
  }, [translateY]);

  return { translateY, handleScroll, updateContentOffset, contentOffset };
};
