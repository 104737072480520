import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Colors, Typography } from '../common-styles';
import { BottomSheetModal } from '../common-ui/BottomSheetModal';
import { StarImage } from '../images/StarImage';

export const StripeProccessFinishedScreen = () => {
  const navigation = useNavigation<any>();
  const [showModal, setShowModal] = useState(true);

  const handlePressClose = () => {
    setShowModal(false);
    // TODO: CommunityPreview expect to receive a group actually, since we already have the group when we press contribute button
    // maybe is not necessary to send something here
    navigation.navigate(`CommunityPreview`);
  };

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white, paddingHorizontal: 16 }}>
      <BottomSheetModal
        title=""
        visible={showModal}
        onPressCancel={() => setShowModal(false)}
        confirmTitle="Close"
        onPressConfirm={handlePressClose}
      >
        <View style={{ justifyContent: `center`, flexDirection: `row` }}>
          <StarImage />
        </View>
        <Text style={{ marginTop: 26, marginHorizontal: 18, ...Typography.text(`center`, `bold`, `large`, `black`) }}>
          Stripe registration finished
        </Text>
        <Text style={{ marginTop: 9, marginHorizontal: 54, ...Typography.text(`center`, `base`) }}>
          Stripe registration succeed, now the community members are able to contribute
        </Text>
      </BottomSheetModal>
    </View>
  );
};
