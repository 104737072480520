import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreJustForFun = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G>
          <Rect fill="#FFEEEB" x={0} y={0} width={163} height={98} rx={7} />
          <Path
            d="M141.754 46.52c4.56 4.26 6.78 10.487 7.547 17.405.727 6.88 0 14.454-4.56 19.66-4.521 5.207-12.916 8.083-20.423 7.282-7.507-.8-14.127-5.279-20.423-10.486-6.296-5.206-12.31-11.177-12.35-17.185 0-5.97 5.933-11.978 12.27-16.238 6.296-4.296 12.955-6.845 19.736-6.954 6.78-.11 13.682 2.22 18.203 6.517z"
            fill="#FF563D"
            fillRule="nonzero"
          />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#FF563D">
            <TSpan x={11.5446415} y={22}>
              Just for Fun
            </TSpan>
          </Text>
        </G>
        <G transform="translate(57 34)" fillRule="nonzero">
          <Path
            d="M82.147 21.413L94.11 9.67 77.9 5.643l4.248 15.77zm-2.33-13.889l11.598 2.88-8.559 8.403-3.04-11.283z"
            fill="#FB70D1"
            transform="rotate(23 86.004 13.528)"
          />
          <Path d="M64.433 12.646L54.173 1.135a.682.682 0 011.016-.907L65.45 11.74a.681.681 0 01-1.017.907z" fill="#CDF267" />
          <Path
            d="M7.758 1.374a6.375 6.375 0 00-6.367 6.37 6.375 6.375 0 006.367 6.368 6.375 6.375 0 006.367-6.369 6.375 6.375 0 00-6.367-6.37m0 14.102c-4.262 0-7.73-3.468-7.73-7.732S3.497.011 7.759.011c4.262 0 7.73 3.468 7.73 7.732s-3.468 7.732-7.73 7.732"
            transform="translate(14 5)"
            fill="#F9648E"
          />
          <Path
            d="M97.616 35.31A4.309 4.309 0 1193.308 31c2.38 0 4.308 1.93 4.308 4.31M48.616 16.31A4.309 4.309 0 1144.308 12c2.38 0 4.308 1.93 4.308 4.31"
            fill="#7CE8FF"
          />
          <Path
            d="M24.452 54.672a7.8 7.8 0 01-1.074-.075.682.682 0 01.189-1.35 6.377 6.377 0 007.185-5.428 6.329 6.329 0 00-1.224-4.718 6.324 6.324 0 00-4.202-2.47.681.681 0 01.189-1.35 7.678 7.678 0 015.1 2.998 7.684 7.684 0 011.487 5.728c-.539 3.862-3.858 6.664-7.65 6.665"
            fill="#FB8779"
            transform="rotate(31 27.484 46.973)"
          />
          <Path
            d="M46.725 33.053L41.3 29.39a.681.681 0 11.763-1.13l4.662 3.149L51.77 28l5.043 3.407L61.858 28l5.426 3.663a.682.682 0 01-.763 1.13l-4.663-3.149-5.045 3.408-5.044-3.406-5.044 3.407z"
            fill="#FA0"
            transform="rotate(-24 54.292 30.527)"
          />
          <Path fill="#7782FD" d="M66.1628085 40.3164047L69.8069454 53.8368042 80.0683633 43.7683635z" />
          <Path fill="#7782FD" transform="rotate(75 8.953 35.76)" d="M2 29L5.64413687 42.5203995 15.9055548 32.4519588z" />
        </G>
      </G>
    </Svg>
  );
};
