import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { MeshIcon } from './MeshIcon';

export class RadioButtons extends React.PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    questionKey: PropTypes.string.isRequired,
    onSelectChange: PropTypes.func,
    row: PropTypes.bool, // flexDirection of radio buttons - true for flexDirection = 'row' false for default col
    selectedValue: PropTypes.any,
  };

  static defaultProps = {
    onSelectChange: undefined,
    row: false,
    selectedValue: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {};

    const { selectedValue } = this.props;
    if (selectedValue !== null && selectedValue !== undefined) this.state.value = selectedValue;
    else this.state.value = null;
  }

  renderChecked = (value, item) => {
    return <MeshIcon name="select-circle" color={Colors.iconColor} size={22} focused={value === item.id} />;
  };

  render = () => {
    const { options, onSelectChange, row, questionKey } = this.props;
    const { value } = this.state;
    return (
      <View style={{ flex: 1, flexDirection: row ? `row` : `column` }}>
        {options.map((item, index) => {
          const selectRadio = (selected) => {
            onSelectChange(selected.value);
            this.setState({ value: selected.id });
          };

          return (
            <View key={questionKey + item.id + index.toString()} style={localStyles.buttonContainer}>
              <TouchableOpacity activeOpacity={0.8} onPress={() => selectRadio(item)}>
                <View style={{ marginRight: 10 }}>{this.renderChecked(value, item)}</View>
              </TouchableOpacity>
              <Text onPress={() => selectRadio(item)} style={localStyles.text}>
                {item.value}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };
}

const localStyles = {
  buttonContainer: {
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    marginBottom: 15,
  },
  text: {
    fontSize: Typography.baseFontSize,
  },
};
