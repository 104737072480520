import React, { useState } from 'react';
import { Animated } from 'react-native';
// import Animated from 'react-native-reanimated';
import { WithChildren } from '../../common-types/types';

type AnimatedHeaderProps = WithChildren & {
  animatedValue: Animated.AnimatedDiffClamp;
};
export const AnimatedHeader = ({ animatedValue, children }: AnimatedHeaderProps) => {
  const [animValue] = useState(animatedValue);

  return (
    <Animated.View
      style={{
        transform: [{ translateY: animValue }],
        position: `absolute`,
        elevation: 10,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      }}
    >
      {children}
    </Animated.View>
  );
};
