import { useIsFocused, useNavigation, RouteProp, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Animated,
  Keyboard,
  SectionListData,
  SectionListRenderItem,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useQuery } from '@apollo/react-hooks';
import _, { unionBy } from 'lodash';
import { NetworkStatus } from 'apollo-client';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../common-styles';
import {
  BottomSheetOptions,
  GroupAndGroupMember,
  GroupCoreFields,
  GroupMember,
  TopicItem,
  TopicListData,
} from '../common-types/types';
import { BackButton, BottomSheetModal, HeaderSubmitButton, LoadingIndicator, MeshIcon } from '../common-ui';
import { useDisclosure } from '../common-util/hooks/useDisclosure';
import { client, CREATE_TOPICS, GET_TOPICS_LIST } from '../graphql';
import { Events, PendoTrackEvent } from '../pendo/events';
import { setTopic } from '../redux/feedSlice';
import { HomeNavigator, HomeNavigatorScreens } from '../common-types/navigation-types';
import { setHideSecondModal, setSelectedTopics } from '../redux/postSlice';
import { RootState } from '../redux/store';

type SectionData = {
  title: string;
  data: TopicItem[];
};
type SectionListHeader = ((info: { section: SectionListData<TopicItem, SectionData> }) => React.ReactElement | null) | undefined;

type _CommunityTopicsProps = GroupAndGroupMember & {
  contentOffset: undefined;
  onScroll?: (...args: any[]) => void;
  feedContainerStyle: StyleProp<ViewStyle>;
};

type CommunityTopicsRoute = RouteProp<HomeNavigatorScreens, `CommunityTopics`>;

const PAGE_SIZE = 20;

const prepareSectionData = (lists: TopicItem[]): SectionData[] => [{ title: `Topics`, data: lists }];

const CommunityTopics: React.FC = () => {
  const route = useRoute<CommunityTopicsRoute>();
  const navigation = useNavigation<HomeNavigator>();
  const dispatch = useDispatch();
  const { selectedTopics } = useSelector((state: RootState) => state.post);
  const { group, group_member, status = undefined } = route?.params;
  const isFocused = useIsFocused();

  const { isOpen, onClose } = useDisclosure();
  const [sections, setSections] = useState<SectionData[]>([]);
  const [searchKey, setSearchKey] = useState(``);
  const [options, setOptions] = useState<BottomSheetOptions[]>([]);
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [suggestCreate, setSuggestCreate] = useState(false);

  const { data, refetch, fetchMore, loading, networkStatus } = useQuery<TopicListData>(GET_TOPICS_LIST, {
    variables: {
      filter: {
        keyword: searchKey,
        limit: PAGE_SIZE,
        offset: 0,
        group_id: group?.id || route?.params?.group?.id,
        sortBy: `TOPIC`,
        sortOrder: `ASC`,
      },
    },
  });

  const contentOffset = undefined;
  const onScroll = () => null;

  const handleBack = useCallback(() => {
    dispatch(setHideSecondModal(false));
    if (navigation.canGoBack()) navigation.goBack();
  }, [navigation, dispatch]);

  useEffect(() => {
    if (group && group_member)
      navigation.setOptions({
        headerTitle: `Topics`,
        headerRight: () => (status === `creating` ? null : <HeaderRight group={group} group_member={group_member} />),
        headerLeft: () => <BackButton onPress={handleBack} />,
      });
  }, [group, group_member, navigation, handleBack, status]);

  useEffect(() => {
    if (isFocused && refetch) refetch();
  }, [isFocused, refetch]);

  useEffect(() => {
    const communityTopics = data?.getGroupTopicsList_V2 || [];
    if (searchKey && !loading) {
      const exist = communityTopics.some((t: TopicItem) => t.topic !== searchKey);
      setSuggestCreate(!communityTopics.length || !exist);
    } else {
      setSuggestCreate(false);
    }
    if (communityTopics) setSections(prepareSectionData(communityTopics));
  }, [data, searchKey, loading]);

  const toggleCategory = useCallback(
    (category) => {
      const hasCategory = collapsed?.includes(category);
      if (hasCategory) setCollapsed(collapsed.filter((item) => item !== category));
      else setCollapsed([...collapsed, category]);
    },
    [collapsed, setCollapsed],
  );

  const renderSectionHeader: SectionListHeader = useCallback(
    ({ section }) => {
      const { title } = section;
      const isCollapsed = collapsed?.includes(title);
      return <SectionHeader title={title} collapsed={isCollapsed} toggleCategory={() => toggleCategory(title)} />;
    },
    [collapsed, toggleCategory],
  );

  const handleGoToTopicFeed = useCallback(
    (topic: TopicItem) => {
      PendoTrackEvent(Events.VIEW_TOPIC, {
        source: topic.following ? `my-topics` : `comm-topics`,
        screen: `community feed`,
        community_name: group?.name || route?.params?.group?.name,
        topic_name: topic.topic,
      });
      dispatch(setTopic(topic));
      navigation.navigate(`TopicFeedScreen`, { groupMember: group_member, group });
    },
    [navigation, dispatch, group_member, group],
  );

  const handleSelectTopic = useCallback(
    (topic: TopicItem) => {
      if (selectedTopics.findIndex((st) => st.id === topic.id) > -1) {
        dispatch(setSelectedTopics(selectedTopics.filter((t) => t?.id !== topic?.id)));
      } else {
        if (selectedTopics.length < 3) {
          dispatch(setSelectedTopics([...selectedTopics, topic]));
        }
      }
    },
    [dispatch, setSelectedTopics, selectedTopics],
  );

  const renderItem: SectionListRenderItem<TopicItem, SectionData> = useCallback(
    ({ item, section: { title } }) => {
      const { topic, post_count } = item;
      const isCollapsed = collapsed && collapsed.includes(title);
      const isSelected = selectedTopics.findIndex((st) => st.id === item.id) > -1;
      const optionIcon = isSelected ? `circle-check` : `radio-off`;
      return (
        <Collapsible collapsed={isCollapsed}>
          <TouchableOpacity
            style={styles.topicItem}
            onPress={() => (status === `creating` ? handleSelectTopic(item) : handleGoToTopicFeed(item))}
          >
            <View>
              <Text style={{ fontWeight: `bold` }}>{topic}</Text>
              <Text style={{ color: Colors.textGray, fontSize: 12 }}>
                {post_count} {post_count > 1 ? `posts` : `post`}
              </Text>
            </View>
            {status === `creating` && (
              <MeshIcon name={optionIcon} size={24} color={Colors.brandPurple} style={{ marginRight: 16 }} />
            )}
            {/* <View style={{ flexDirection: `row` }}>
              {item.favorite && <MeshIcon name="star" size={22} style={{ marginRight: 15 }} />}
              {!item.topic.toLocaleLowerCase().includes(`announcements`) && (
                <TouchableOpacity onPress={() => handleSelect(item)} hitSlop={{ left: 15, right: 15, bottom: 10, top: 10 }}>
                  <Icon name="ellipsis-vertical" size={18} color={Colors.iconColor} />
                </TouchableOpacity>
              )}
            </View> */}
          </TouchableOpacity>
        </Collapsible>
      );
    },
    [collapsed, handleGoToTopicFeed, status, selectedTopics],
  );

  const handleOnEndReached = () => {
    //const totalTopics = sections.reduce((result, section) => result + section.data.length, 0);
    const totalTopics = data?.getGroupTopicsList_V2?.length || 0;
    // due to some communities having few topics we can skip an innecesary fetch for more data
    // if we know the previous batch returned less than a PAGE_SIZE items
    // const skipIfPrevNotfull = totalTopics % PAGE_SIZE !== 0;    //Not sure why its needed. If many topics, will not work due to this skip.
    // should not fetch more if offset is 0, if so initial query already brought all topics
    const skipIfOffsetZero = totalTopics === 0;
    if (!loading && !skipIfOffsetZero) {
      fetchMore({
        variables: {
          filter: { offset: totalTopics, group_id: group?.id, sortBy: `TOPIC`, sortOrder: `ASC`, keyword: searchKey },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult.getGroupTopicsList_V2.length ||
            fetchMoreResult.getGroupTopicsList_V2.slice(-1)[0].id === prev.getGroupTopicsList_V2.slice(-1)[0].id
          ) {
            console.log(`[topics]is the same, return prev`);
            return prev;
          }
          const newSections = unionBy(
            prev.getGroupTopicsList_V2,
            fetchMoreResult.getGroupTopicsList_V2,
            (li: TopicItem) => li.id,
          );
          setSections(prepareSectionData(newSections));
          return {
            ...prev,
            getGroupTopicsList_V2: unionBy(
              prev.getGroupTopicsList_V2,
              fetchMoreResult.getGroupTopicsList_V2,
              (li: TopicItem) => li.id,
            ),
          };
        },
      });
    }
  };

  const handleCloseBottomSheet = useCallback(() => {
    setOptions([]);
    onClose();
  }, [setOptions, onClose]);

  const renderSectionFooter = useCallback(() => {
    if (loading) {
      return (
        <View style={{ flex: 1, justifyContent: `center`, padding: 16 }}>
          <LoadingIndicator />
        </View>
      );
    }
    return null;
  }, [loading]);

  const handleCreateNewTopic = useCallback(async () => {
    if (!group?.id || !searchKey) return;
    try {
      const { data } = await client.mutate({
        mutation: CREATE_TOPICS,
        variables: {
          groupID: group?.id,
          topic: searchKey,
        },
      });
      if (data && data?.addGroupTopics_V2) {
        // PendoTrackEvent(Events.CREATE_TOPIC, {
        //   source: `PostTopics`, // ???? should be determinded
        //   community_name: group?.name,
        //   community_role: groupMember?.role_name,
        //   topic_name: searchKey,
        // });
        setSearchKey(``);
        await refetch();
      }
    } catch (error) {
      console.error(`🚀[handleCreateNewTopicError]`, (error as Error).message);
    }
  }, [group?.id, searchKey, refetch]);

  const isAdmin = [`owner`, `manager`, `moderator`].includes(group_member?.role_name || ``);
  return (
    <Animated.SectionList
      style={{ flex: 1 }}
      sections={sections}
      contentOffset={contentOffset}
      ListHeaderComponent={
        <>
          <View style={styles.searchContent}>
            <TextInput
              placeholder="Search topics"
              placeholderTextColor={Colors.textPlaceholder}
              style={styles.textInput}
              onChangeText={setSearchKey}
              value={searchKey}
              returnKeyType="done"
            />
            <TouchableOpacity
              disabled={!searchKey}
              onPress={() => setSearchKey(``)}
              hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
              style={{ padding: 10 }}
            >
              <Icon name={searchKey ? `close` : `search`} color={Colors.iconColor} size={20} />
            </TouchableOpacity>
          </View>
          {/* TODO: feature removed in dev */}
          {/* eslint-disable-next-line no-constant-condition */}
          {suggestCreate ? (
            <TouchableOpacity style={styles.addTopic} onPress={handleCreateNewTopic}>
              <Text style={{ fontSize: 12 }}>
                <Text style={styles.grayText}>{isAdmin ? `Add` : `Suggest`} </Text>
                <Text style={{ fontWeight: `bold` }}>{searchKey} </Text>
                <Text style={styles.grayText}>{isAdmin ? `to Topics` : `as a topic`}</Text>
              </Text>
              <MeshIcon name="circle-plus" color={Colors.iconColor} size={24} />
            </TouchableOpacity>
          ) : null}
          <BottomSheetModal
            showConfirmBtn={false}
            showCancelBtn
            onPressCancel={handleCloseBottomSheet}
            onPressConfirm={handleCloseBottomSheet}
            visible={isOpen}
            title="Topic Options"
            options={options}
          />
        </>
      }
      keyboardShouldPersistTaps="handled"
      refreshing={networkStatus === NetworkStatus.refetch}
      onRefresh={refetch}
      onEndReachedThreshold={1.5}
      onEndReached={handleOnEndReached}
      keyExtractor={(item) => item.id}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
      renderSectionFooter={renderSectionFooter}
      onScroll={onScroll}
      onMomentumScrollBegin={() => Keyboard.dismiss()}
    />
  );
};

export default React.memo(CommunityTopics);

type HeaderRightProps = {
  group: GroupCoreFields;
  group_member: GroupMember;
};

const HeaderRight: React.FC<HeaderRightProps> = ({ group_member, group }) => {
  const navigation = useNavigation<HomeNavigator>();
  const isAdmin = [`owner`, `manager`, `moderator`].includes(group_member?.role_name || ``);
  if (!group_member) return null;
  if (isAdmin) {
    return (
      <HeaderSubmitButton
        disabled={false}
        title="Manage"
        onPress={() => navigation.navigate(`EditTopics`, { group, group_member })}
      />
    );
  }
  return null;
};

type SectionHeaderProps = {
  title: string;
  collapsed: boolean;
  toggleCategory: (category: string) => void;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, collapsed, toggleCategory }) => {
  const toggle = () => toggleCategory(title);
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={toggle}>
      <View style={styles.sectionHeader}>
        <Text style={styles.sectionHeaderText}>{title}</Text>
        <MeshIcon name={!collapsed ? `chevron-down` : `chevron-up`} color={Colors.iconColor} size={18} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  searchContent: {
    flexDirection: `row`,
    alignItems: `center`,
    borderWidth: 1,
    borderColor: Colors.dividerColor,
    margin: 10,
    borderRadius: 4,
    justifyContent: `space-between`,
  },
  sectionHeader: {
    width: `100%`,
    backgroundColor: Colors.backgroundColor,
    paddingVertical: 6,
    justifyContent: `space-between`,
    flexDirection: `row`,
    paddingHorizontal: 12,
  },
  sectionHeaderText: {
    color: Colors.textGray,
    fontWeight: `500`,
  },
  topicItem: {
    paddingHorizontal: 12,
    paddingVertical: 15,
    flexDirection: `row`,
    justifyContent: `space-between`,
    borderBottomColor: Colors.lightGray,
    borderBottomWidth: 1,
    alignItems: `center`,
  },
  textInput: {
    flex: 1,
    padding: 10,
  },
  addTopic: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
  },
  grayText: {
    color: Colors.textGray,
  },
});
