import React from 'react';
import { SectionList, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import { NetworkStatus } from 'apollo-client';
import { Colors, Typography } from '../common-styles';

// GraphQL errors are singular
const formatGraphQLError = (graphQLError) => {
  let output = ``;

  /* eslint-disable no-unused-vars */
  const { message, locations, path, extensions, ...err_remainder } = graphQLError;
  const { code, exception, ...extensions_remainder } = extensions || {};
  const { stacktrace, ...exception_remainder } = exception || {};
  /* eslint-enable no-unused-vars */

  if (message) output += `${message}\n`;
  //if (Array.isArray(path)) output += ` in ${path.join(`, `)}\n`;
  if (Array.isArray(stacktrace)) output += stacktrace.slice(1).join(`\n`);

  if (Object.keys(err_remainder).length) output += `err: ${JSON.stringify(err_remainder)}\n`;
  if (Object.keys(extensions_remainder).length) output += `extensions: ${JSON.stringify(err_remainder)}\n`;
  if (Object.keys(exception_remainder).length) output += `exception: ${JSON.stringify(err_remainder)}\n`;

  return output.trim();
};

// Network errors may have multiple errors in one object, which is why we return multiple rows
const formatNetworkError = (networkError) => {
  const { name, status, type, message, result = {} } = networkError;
  const { errors } = result;

  const shift_rows = [];
  const push_rows = [];
  if (name && status) shift_rows.push(`${name}: ${status}`); // eg ServerError: 400
  if (Array.isArray(errors)) {
    push_rows.push(
      ...errors.map((err) => {
        const { message, locations, extensions } = err;
        if (message && locations && extensions) return formatGraphQLError(err);
        return JSON.stringify(err);
      }),
    );
  }
  if (type === `WriteError`) {
    // Example of this type of error:
    // `Error writing result to store for query:\n ${JSON.stringify(query)}\nnull is not an object (evaluating 'object.reaction.id')`
    // where query is a huge json dump
    let output;
    const match = message.match(/(?<error>.*query):\n (?<query>{.*})\n(?<details>.*)/);
    if (match) {
      try {
        const query = JSON.parse(match.groups.query);
        if (Array.isArray(query.definitions)) {
          const queryNames = query.definitions
            .filter((d) => d.operation === `query`)
            .map((d) => d.name.value)
            .join();

          output = `${match.groups.error} ${queryNames}: ${match.groups.details}`;
        }
      } catch (err) {} // eslint-disable-line no-empty
    }
    if (!output) output = `${type}: ${message}`;
    push_rows.push(output);
  }
  return { shift_rows, push_rows };
};

export const QueryError = ({ error, networkStatus, refetch, ListHeaderComponent }) => {
  const noDataError = { networkError: { result: { errors: [`no data`] } } };
  const { graphQLErrors, networkError } = error || noDataError;

  const errors_to_display = [];

  // Construct rows from each graphQLError
  if (Array.isArray(graphQLErrors) && graphQLErrors.length > 0)
    graphQLErrors.forEach((graphQLError) => errors_to_display.push(formatGraphQLError(graphQLError)));

  // Construct rows from the networkError
  if (networkError) {
    const { shift_rows, push_rows } = formatNetworkError(networkError);
    errors_to_display.shift(...shift_rows);
    errors_to_display.push(...push_rows);
  }
  // Make sure we have at least one row
  if (errors_to_display.length === 0) {
    console.log(`some other exception`);
    errors_to_display.push(JSON.stringify(error));
  }

  return (
    <SectionList
      refreshing={networkStatus === NetworkStatus.refetch}
      onRefresh={() => refetch()}
      sections={[{ data: errors_to_display }]}
      ListHeaderComponent={ListHeaderComponent}
      renderSectionHeader={() => (
        <View style={{ backgroundColor: Colors.white, width: `100%` }}>
          <Text style={{ ...Typography.boldHeaderStyle, margin: 20 }}>Server error</Text>
        </View>
      )}
      keyExtractor={(_error_to_display, index) => `err:${index}`}
      renderItem={({ item: error_to_display }) => (
        <Text style={{ fontSize: Typography.smallFontSize, color: Colors.textGray, margin: 10 }}>{error_to_display}</Text>
      )}
    />
  );
};
QueryError.propTypes = {
  error: PropTypes.object.isRequired,
  networkStatus: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  ListHeaderComponent: PropTypes.any,
};
QueryError.defaultProps = {
  ListHeaderComponent: undefined,
};
