import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { setCommunityName, moveNext, movePrev } from '../redux/createCommunitySlice';
import { BackButton, ThemedButton } from '../common-ui';
import Input from '../common-ui/inputs/Input';
import { CHECK_COMMUNITY_NAME_AVAILABLE } from '../graphql';
import { Typography } from '../common-styles';
import { WizardLayout } from './WizardLayout';
import { client } from '../graphql/Client';
import { CommunityNameTestIds } from './testLabels';
import Badwords from '../common-util/badwords';
import { RootState } from '../redux/store';
import { HomeNavigator } from '../common-types/navigation-types';

// Step 2: Community Details
const CommunityName = () => {
  const navigation = useNavigation<HomeNavigator>();
  const badwords = new Badwords();

  const { communityName } = useSelector((state: RootState) => state.createCommunity);
  const [currentCommunityName, setCurrentCommunityName] = useState(communityName);
  const dispatch = useDispatch();

  const [validLength, setValidLength] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [profane, setProfane] = useState(false);

  const isError = (isDuplicated || profane) && validLength;
  const isEnableSubmit = validLength && !isDuplicated && !profane;

  useLayoutEffect(() => {
    const handlePrev = () => {
      dispatch(movePrev());
      navigation.goBack();
    };
    navigation.setOptions({
      title: `Node Details`,
      headerLeft: () => <BackButton onPress={handlePrev} />,
    });
  }, [dispatch, navigation]);

  useEffect(() => {
    const checkNameAvailability = async (name: string) => {
      const trimedName = name.trim();

      if (trimedName.length < 3 || trimedName.length > 36) {
        setValidLength(false);
        return null;
      }
      setValidLength(true);

      if (badwords.isProfane(name)) {
        setProfane(true);
        return null;
      }
      setProfane(false);

      try {
        const { data } = await client.query({ query: CHECK_COMMUNITY_NAME_AVAILABLE, variables: { name: trimedName } });
        const { available } = data?.checkCommunityNameAvailability || {};

        setIsDuplicated(!available);
        return available;
      } catch (err) {
        console.error(`Error thrown in checkNameAvailability() in CommunityName.jsx`, (err as Error).message);
        return null;
      }
    };

    const timer = setTimeout(async () => {
      await checkNameAvailability(currentCommunityName);
      dispatch(setCommunityName(currentCommunityName));
    }, 75);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCommunityName, dispatch]);

  const onChangeText = (value: string) => {
    setCurrentCommunityName(value);
  };

  const errorMessage = useMemo(() => {
    if (profane) {
      return (
        <View style={{ ...styles.errorWrapper }}>
          <Text style={{ ...Typography.text(`small`, `red`, `bold`) }}>
            Oop, Your node name contains some words that violate Mesh's Ground Rules. Please try a different name to help your
            node stand out!
          </Text>
        </View>
      );
    }

    if (isDuplicated) {
      return (
        <View style={{ ...styles.errorWrapper }}>
          <Text style={{ ...Typography.text(`small`, `red`, `bold`) }}>
            Sadly, it looks like this name is already taken &#128579;. Please try a different name to help your node stand out!
          </Text>
        </View>
      );
    }

    return null;
  }, [profane, isDuplicated]);

  const suffixMessage = useMemo(() => {
    return (
      <>
        <Text style={{ ...Typography.text(`right`), ...Typography.text(`miniscule`), paddingTop: 10 }}>
          {currentCommunityName.length}/36 Characters
        </Text>
        <Text style={{ ...Typography.text(`plusone`), ...Typography.text(`small`), paddingTop: 10 }}>
          For best results, give your node a {`\n`}name that's catchy and descriptive.
        </Text>
      </>
    );
  }, [currentCommunityName]);

  const handleOnSubmit = useCallback(() => {
    if (!isEnableSubmit) return;
    dispatch(moveNext());
    navigation.navigate(`CommunityImage`);
  }, [navigation, isEnableSubmit, dispatch]);

  return (
    <WizardLayout total={5} selector="createCommunity">
      <View style={styles.container}>
        <View style={styles.inputContainer} testID={CommunityNameTestIds.screen}>
          <Text style={styles.titleStyle}>Give your node a name</Text>
          <Input
            testID={CommunityNameTestIds.input}
            autoFocus
            blurOnSubmit
            isError={isError}
            value={currentCommunityName}
            autoCompleteType="off"
            maxLength={80}
            placeholder="Enter a node name"
            onChangeText={onChangeText}
            underlineColorAndroid="transparent"
          />
          {isError ? errorMessage : suffixMessage}
        </View>

        <ThemedButton
          testID={CommunityNameTestIds.continue}
          title="Continue"
          disabled={!isEnableSubmit}
          rounded
          onPress={handleOnSubmit}
          titleStyle={{ fontSize: Typography.baseFontSize, fontWeight: `600`, padding: 4 }}
        />
      </View>
    </WizardLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  inputContainer: {
    paddingVertical: 24,
    marginBottom: 60,
  },
  errorWrapper: {
    padding: 8,
    marginTop: 8,
    backgroundColor: `#fadad9`,
    borderRadius: 4,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: `600`,
    height: 20,
  },
});

export default CommunityName;
