import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import React from 'react';
import { Colors, Typography } from '../common-styles';
import { commonPropTypes, formatShortRelativeDate } from '../common-util';
import { MeshIconImage } from '../images/MeshIconImage';
import { MeshIcon } from './MeshIcon';
import { NoThemeButton } from './NoThemeButton';

export const MeshTip = ({ group, group_member, onDeleteTip, image, buttonAction, title, text, buttonText }) => {
  if (!group_member) return null;
  if (group_member.role_name === `owner` || group_member.role_name === `manager`) {
    return (
      <View style={{ backgroundColor: Colors.lightWarmGray }}>
        <View style={{ backgroundColor: Colors.white, marginBottom: 8 }}>
          <View style={{ marginBottom: 8, marginHorizontal: 16 }}>
            <View style={{ flexDirection: `row`, marginTop: 16 }}>
              <View style={{ borderRadius: 999 }}>
                <MeshIconImage height={40} width={40} style={{ marginRight: 12, marginBottom: 16, alignSelf: `center` }} />
              </View>
              <View style={{ flexDirection: `row`, flex: 1 }}>
                <View style={{ flexDirection: `column`, flex: 1, marginBottom: 8 }}>
                  <Text style={Typography.text(`bold`)}>Mesh Tip</Text>
                  <Text style={Typography.text(`small`, `gray`)}>
                    {formatShortRelativeDate(group.created_at)} • Visible only to you
                  </Text>
                </View>
                <MeshIcon
                  name="close"
                  color={Colors.iconColor}
                  size={22}
                  style={{ marginRight: 5 }}
                  onPress={() => onDeleteTip()}
                />
              </View>
            </View>
            {image}
            {title ? (
              <Text style={{ marginBottom: 16, ...Typography.text() }}>
                <Text>Create a</Text>
                <Text style={{ ...Typography.text(`bold`) }}> {title} </Text>
                <Text>{text}</Text>
              </Text>
            ) : (
              <Text style={{ marginBottom: 16, ...Typography.text() }}>{text}</Text>
            )}
            <NoThemeButton
              rounded
              title={buttonText}
              color={Colors.badgePlusBackground}
              containerStyle={{ flex: 1, alignItems: `center`, justifyContent: `center` }}
              titleStyle={Typography.text(`theme`, `header`, `center`)}
              buttonStyle={{
                justifyContent: `center`,
                marginBottom: 16,
                alignItems: `center`,
                paddingHorizontal: 20,
              }}
              onPress={() => buttonAction()}
            />
          </View>
        </View>
      </View>
    );
  }
  return null;
};

MeshTip.propTypes = {
  group: commonPropTypes.group().isRequired,
  group_member: commonPropTypes.group_member(),
  onDeleteTip: PropTypes.func.isRequired,
  image: PropTypes.object,
  buttonAction: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

MeshTip.defaultProps = {
  group_member: {},
  image: null,
  title: ``,
  buttonText: ``,
};
