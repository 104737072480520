import React, { useEffect, useMemo } from 'react';
import { View, Platform, Linking } from 'react-native';
import { useLazyQuery } from 'react-apollo';
import { useNavigation } from '@react-navigation/native';
import { isAMeshLink, replaceHeaderTitle } from '../helpers';
import { LinkPreview } from '../LinkPreview';
import { LinkPreview as ILinkPreview } from '../../common-types/types';
import { getMeshInviteToken, inviteRegex } from '../hooks/useLinkPreview';
import { GET_INVITATION } from '../../graphql';
import { useAppDispatch } from '../../redux/store';
import PostText from './PostText';
import { setPreviewCommunity, setActiveCommunity } from '../../redux/feedSlice';
import { ExploreNavigator, HomeNavigator } from '../../common-types/navigation-types';

type LinkPreviewProps = {
  linkPreview: ILinkPreview;
  textComponentProps?: any;
  onClose?: () => void;
};

const areEqualLinkPreview = (prevProps: LinkPreviewProps, nextProps: LinkPreviewProps) => {
  const areEqualLink = prevProps.linkPreview.url === nextProps.linkPreview.url;
  const areEqualText = prevProps?.textComponentProps?.activity?.content === nextProps?.textComponentProps?.activity?.content;
  const areEqualActivity = areEqualLink && areEqualText;
  return areEqualActivity;
};
const urlRegex =
  /\b((?:(?:http|https)+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/gi;

const PostContentLinkPreview: React.FC<LinkPreviewProps> = React.memo(({ linkPreview, textComponentProps = {}, onClose }) => {
  const navigation = useNavigation<HomeNavigator | ExploreNavigator>();
  const {
    activity = {},
    width = null,
    getTrimmedActivityText = null,
    textOverflowMode = null,
    startExpanded = null,
    meta_style = null,
    onLayout = null,
    navigateToPost = null,
  } = textComponentProps;
  const { url, imageUrls, title } = linkPreview || {};
  const dispatch = useAppDispatch();
  const [getInvite, result] = useLazyQuery(GET_INVITATION, {
    fetchPolicy: `cache-and-network`,
    onError: (err) => console.log(`[PostLinkPreviewError]`, (err as Error).message),
  });

  const isMeshLink = useMemo(() => isAMeshLink(url), [url]);
  useEffect(() => {
    const invite_token = getMeshInviteToken(url);
    if (invite_token) getInvite({ variables: { invite_token } });
  }, [getInvite, url]);

  const { data: linkPreviewData } = result;
  let meshLinksUrls: string[] | null = null;
  let headerMessage = title;

  if (linkPreviewData && isMeshLink) {
    const { group: recievedGroup } = linkPreviewData.getInvitation;
    meshLinksUrls = [...imageUrls];
    if (recievedGroup?.avatar_url) meshLinksUrls.unshift(recievedGroup.avatar_url);
    if (recievedGroup?.splash_url) meshLinksUrls.unshift(recievedGroup.splash_url);
    headerMessage = replaceHeaderTitle(linkPreviewData.getInvitation, title?.trim(), true);
  }

  const imageLinkUrl = meshLinksUrls && meshLinksUrls.length ? meshLinksUrls[0] : null;
  const image_url = imageUrls && imageUrls.length ? imageUrls[0] : null;

  const handleOpenUrl = () => {
    try {
      if (isMeshLink && Platform.OS === `ios` && linkPreviewData) {
        const groupData = linkPreviewData.getInvitation;
        const { group_member } = groupData;
        if (group_member) {
          if (group_member.role_id) {
            dispatch(setActiveCommunity({ user_id: group_member.persona_id, group: groupData.group }));
            (navigation as HomeNavigator).navigate(`HomeScreen`);
          }
        } else {
          dispatch(setPreviewCommunity(groupData.group));
          const validInvite = url.match(inviteRegex);
          if (validInvite) {
            const [, , invite_token] = validInvite;
            (navigation as ExploreNavigator).navigate(`CommunityPreview`, { invite_token });
          } else {
            (navigation as ExploreNavigator).navigate(`CommunityPreview`);
          }
        }
      } else {
        Linking.openURL(url);
      }
    } catch (error) {
      console.error(error, `[POST]: `, error);
    }
  };

  let cleanedText = ``;
  if (textComponentProps?.getTrimmedActivityText) {
    cleanedText = getTrimmedActivityText(activity).replace(urlRegex, ``);
  }

  return (
    <View>
      {!!textComponentProps?.getTrimmedActivityText && !!(cleanedText.length > 0) && (
        <PostText
          activity={activity}
          width={width}
          text={cleanedText}
          overflowMode={textOverflowMode}
          startExpanded={startExpanded}
          selectable={false}
          meta_style={meta_style}
          onLayout={onLayout}
          onPress={navigateToPost}
        />
      )}
      <LinkPreview
        handlePress={handleOpenUrl}
        title={headerMessage}
        url={url}
        onClose={onClose}
        coverImage={imageLinkUrl || image_url}
      />
    </View>
  );
}, areEqualLinkPreview);

export default PostContentLinkPreview;
