import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { BottomSheetModal, LoadingModal, ThreeDotsButton } from '../../common-ui';
import { useCommunityHeader } from './hooks/useCommunityHeader';
import { loadCachedGroupMember } from '../../graphql';
import { AppContext } from '../../../AppContext';
import { useReportModal } from './hooks/useReportModal';
import { BottomSheetOptions, GroupCoreFields } from '../../common-types/types';
import { ReportModal } from '../../moderation/ReportModal';
import { RootState } from '../../redux/store';

type CommunityMenuProps = {
  community: Pick<GroupCoreFields, `id` | `name`>;
};

const CommunityMenu: React.FC<CommunityMenuProps> = ({ community }) => {
  const [menuOptions, setMenuOptions] = useState<BottomSheetOptions[]>([]);
  const { user } = useContext(AppContext);
  const groupMember = loadCachedGroupMember(community?.id, user?.id);
  const { activeCommunity } = useSelector((state: RootState) => state.feed);

  const { isOpen: isOpenReportModal, hideReportModal, reportType, reportee, postId } = useReportModal();
  const headerProps = useCommunityHeader({
    mode: activeCommunity?.id === community?.id ? `home` : `preview`,
    groupMember,
    onChangeMember: () => {},
    onChangeGroup: () => {},
  });
  const { leavingGroup, getBottomSheetOptions, onCloseBottomSheet, onToggleBottomSheet, isBottomSheetOpen } = headerProps;

  const handleMenuPress = () => {
    const options = getBottomSheetOptions();
    setMenuOptions(options);
    onToggleBottomSheet();
  };

  return (
    <>
      <ThreeDotsButton horizontal onPress={handleMenuPress} />

      <ReportModal
        origin_group_id={community?.id}
        id={postId}
        reportee={reportee}
        isVisible={isOpenReportModal}
        onClose={hideReportModal}
        reportType={reportType}
      />

      <BottomSheetModal
        onPressCancel={onCloseBottomSheet}
        onPressConfirm={onCloseBottomSheet}
        showCancelBtn={false}
        confirmTitle="Close"
        title="Options"
        options={menuOptions}
        visible={isBottomSheetOpen}
      />

      <LoadingModal isVisible={leavingGroup} content={`Leaving ${community?.name}`} />
    </>
  );
};

export default CommunityMenu;
