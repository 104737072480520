import { View, ScrollView } from 'react-native';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { ListItem, ThemedButton, SafeAreaView } from '../common-ui';
import { RootState } from '../redux/store';
import { Typography } from '../common-styles';
import { HackNavigator } from '../common-types/navigation-types';
import { ContributionState } from '../redux/contributionSlice';

type SliceKeys = keyof ContributionState;

export const ViewReduxSlice = () => {
  const navigation = useNavigation<HackNavigator>();
  const slice = useSelector((state: RootState) => state.contributions) || {};
  const keys = Object.keys(slice) as SliceKeys[];

  return (
    <SafeAreaView>
      <ScrollView>
        <View style={{ flexDirection: `row` }}>
          <ThemedButton
            clear
            title="See context"
            onPress={() => navigation.navigate(`Context`)}
            containerStyle={{ marginBottom: 10, marginHorizontal: 16 }}
            titleStyle={Typography.text(`bold`, `link`)}
          />
        </View>
        {keys.map((key) => {
          const value: any = slice[key];
          const showValue = JSON.stringify(value, null, 2);
          return <ListItem key={key} title={key} subtitle={showValue} />;
        })}
      </ScrollView>
    </SafeAreaView>
  );
};
