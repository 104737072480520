import * as React from 'react';
import Svg, { Defs, Path, G, Rect, Text, TSpan, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExplorePolitics = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Path id="a" d="M0.554054054 0H68.554054054V80H0.554054054z" />
        <Path id="c" d="M0 0.00223423216L13.2163111 0.00223423216 13.2163111 12.3373809 0 12.3373809z" />
        <Path d="M146.183 11.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 00153 17.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 00146.471 11l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147l-8.167 8.039-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <G fill="#2C9A44">
          <Rect fillOpacity={0.1} x={0} y={0} width={163} height={98} rx={7} />
          <Path
            d="M146.102 52.904c3.614 10.362-4.033 21.953-16.603 29.284-12.517 7.372-29.905 10.444-38.861 5.16-8.956-5.242-9.48-18.84-4.452-30.717 5.08-11.878 15.712-22.035 28.544-22.609 12.78-.532 27.706 8.56 31.372 18.882z"
            fillRule="nonzero"
            transform="rotate(170 115 62)"
          />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14}>
            <TSpan x={12} y={22}>
              Politics
            </TSpan>
          </Text>
        </G>
        <G transform="translate(69.446 18)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <G
              transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(9.264 28.358) matrix(-1 0 0 1 26.293 0)"
              fillRule="nonzero"
            >
              <Path
                d="M.182 28.7c-.12 5.194-.18 12.622-.18 12.622l5.767.223s1.203.334 1.95-14.203c.097-1.885 3.849-15.822 3.729-14.169-.14 1.914 8.78 27.407 8.78 27.407l5.16-.035L18.436 0H5.198S.302 23.507.182 28.7z"
                fill="#000"
              />
              <Path fill="#204EB2" d="M10.5930484 8.58716675L11.5967557 13.1401513 11.2982393 13.2064606 10.294532 8.653476z" />
            </G>
            <Path
              d="M22.165 6.752s-.113 2.49-1.56 3.37c-1.314.797 1.468 5.426 4.461 5.15 1.638-.151 2.022-4.648 2.022-4.648s-1.322-.257-.834-2.282l-4.09-1.59z"
              fill="#C26E5E"
              fillRule="nonzero"
              transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534)"
            />
            <Path
              d="M24.561 9.284c-1.94-.235-3.34-1.516-3.536-3.64-.565.09-.698-1.385-.54-1.86.157-.474.67-.196.67-.196.028-.075.053-.154.072-.236C22.075-.138 24.45.001 24.45.001c4.362.228 3.34 4.698 3.09 5.884-.249 1.186-1.102 3.627-2.978 3.4z"
              fill="#C26E5E"
              fillRule="nonzero"
              transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534)"
            />
            <G transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534) matrix(-1 0 0 1 48.512 10.19) translate(4.76 12.825)">
              <Mask id="d" fill="#fff">
                <Use xlinkHref="#c" />
              </Mask>
              <Path
                d="M9.26.002s-.23 4.01-1.012 4.599c-.781.588-6.134 2.112-6.134 2.112s-.24 3.163.625 2.83c.864-.332 9.593-1.567 9.681-2.103.09-.535.796-6.727.796-6.727L9.26.003z"
                fill="#C26E5E"
                fillRule="nonzero"
                mask="url(#d)"
              />
            </G>
            <Path
              d="M20.803 2.658c1.304 2.072 1.212 4.118 2.136 4.772.924.655 1.304 1.582 1.576 2.782.271 1.2 0 .556 0 .556l-4.599 3.171s-2.736-5.69-2.247-3.891c.489 1.8 1.643 9.37 1.177 9.805-.466.435-4.842 2.118-8.804 1.937-3.472-.16-6.743-1.089-6.743-1.343 0-.546.786-6.69.786-6.69L.01 12.83S.352 4.669 3.833 1.926c0 0 1.886-1.383 4.207-1.892 0 0 .677 1.48 2.804 1.528 2.128.049 2.817-.714 3.348-1.558 0 0 5.307.581 6.61 2.654z"
              transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534) matrix(-1 0 0 1 48.512 10.19) translate(13.719 .027)"
              fill="#FFF"
              fillRule="nonzero"
            />
            <Path
              d="M6.212 19.74c.01.007.018.015.027.023a.48.48 0 01.06.068l.587.792a.5.5 0 01-.75.656.502.502 0 01-.749.622.5.5 0 01-.815.539.502.502 0 01-.151.622.498.498 0 01-.698-.102l-.587-.793a.495.495 0 01-.082-.17l-1.15-1.964c.048-.036.097-.078.147-.128.423-.418 1.54-1.385 1.95-1.904 1.012.366 1.772.553 2.277.564.758.015 1.927-.394 1.995.044.076.495-1.317 1.104-2.032 1.13a.7.7 0 01-.03 0z"
              fill="#C26E5E"
              fillRule="nonzero"
              transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534) matrix(-1 0 0 1 48.512 10.19) scale(-1 1) rotate(-41 0 34.193)"
            />
            <G fillRule="nonzero">
              <Path
                d="M15.126 6.35s2.38 4.014.051 4.837c-2.329.823-4.398.751-9.395.797-1.963.017-6.789.123-6.608-.66.1-.435 3.621-1.102 5.265-1.723.484-.183 5.535-1.118 5.805-1.412.18-.196 1.808-.809 4.882-1.839z"
                fill="#C26E5E"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534) translate(0 10.107) rotate(55 7.684 9.174)"
              />
              <Path
                d="M5.731 6.685s.382-.637-1.046-2.01C3.93 3.953 3.36 2.279 2.942 2.567c-.419.288.451 2.259.451 2.259s-.913-.83-1.452-1.743C1.4 2.17.899 1.427.899 1.427s-.428-.276-.457.158c-.029.435.092.573.092.573S.205 2.081.2 2.413c-.007.333.054.402.054.402S.006 2.788 0 3.08a.746.746 0 00.156.475s-.187.042-.028.393c.158.35 1.052 2.178 1.68 2.767.629.59 2.269 1.596 2.269 1.596s1.799-1.125 1.654-1.626"
                fill="#C26E5E"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534) translate(0 10.107)"
              />
              <Path
                d="M1.904 5.479c-.02-.015-.502-.37-1.014-1.198-.46-.744-.632-.695-.634-.694l.004-.003-.05-.077c.008-.005.038-.021.091-.01.113.026.32.174.667.736.503.813.984 1.168.99 1.171l-.054.075zM2.155 5.074a38.113 38.113 0 00-.498-.45c-.258-.23-.577-.745-.81-1.121a6.736 6.736 0 00-.254-.395c-.177-.237-.344-.25-.346-.25l.004-.093c.008 0 .21.012.416.287.062.083.153.231.259.402.23.371.543.88.792 1.102.364.325.498.45.5.451l-.063.067zM2.351 4.74a8.056 8.056 0 01-.848-.906c-.32-.419-.58-.985-.72-1.289l-.032-.07C.636 2.23.534 2.197.533 2.197l.02-.09c.015.003.146.039.28.33l.033.07c.139.3.397.86.71 1.27.337.442.832.89.837.894l-.062.069zM4.248 5.517c-.002-.003-.28-.274-.5-.35a.868.868 0 01-.398-.317l.076-.05a.787.787 0 00.352.28c.24.083.523.36.535.371l-.065.066z"
                fill="#995B5D"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(0 1.534) translate(0 10.107)"
              />
            </G>
            <G fillRule="nonzero">
              <G fill="#000">
                <Path
                  d="M1.135 3.578s.494.767 1.165.323l-1.165-.323zM3.814 1.606a.184.184 0 11-.368 0 .184.184 0 01.368 0"
                  transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(22.839 5.156) translate(.092)"
                />
                <Path
                  d="M0 .368S.467.085.822.02c.22-.04 1.433.132 1.394.38-.037.242-.157.21-.636.127C1.101.445.883.39.606.42.329.45.007.51 0 .368"
                  transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(22.839 5.156) translate(.092) translate(0 .016)"
                />
                <Path
                  d="M4.894.751s-.08-.245-.212-.363C4.551.27 3.561.015 3.393.173c-.266.25-.075.34.46.395.429.045.599.06.798.147.272.118.237.082.243.036"
                  transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(22.839 5.156) translate(.092)"
                />
              </G>
              <Path
                d="M3.025.016l.116.037L3.13.09a7.22 7.22 0 00-.123.446c-.032.134-.06.263-.082.385l-.006.036c-.053.31-.06.547-.013.674l.002.007c.272.674-.097 1.016-.87.777l-.024-.008.037-.117c.697.224.977-.028.744-.606-.064-.159-.054-.429.01-.785a8.601 8.601 0 01.167-.704l.01-.037.019-.063.014-.049.01-.03z"
                fill="#000"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(22.839 5.156) translate(0 .522)"
              />
              <Path
                d="M.006 4.712l.06-.107.021.012.02.01c.03.017.065.034.104.053l.016.008c.13.063.272.127.424.186.35.135.696.227 1.018.257.146.014.284.014.413 0l.026-.003.015.122a2.163 2.163 0 01-.465.004 3.984 3.984 0 01-1.05-.266 5.562 5.562 0 01-.434-.19l-.05-.024a4.2 4.2 0 01-.06-.03l-.035-.019-.023-.013z"
                fill="#C86A5D"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(22.839 5.156) translate(0 .522)"
              />
              <Path
                d="M1.367.994a.184.184 0 11-.367 0 .184.184 0 01.367 0"
                fill="#000"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(22.839 5.156)"
              />
            </G>
            <G fill="#000" fillRule="nonzero">
              <Path
                d="M0 10.824s.304-4.273 1.285-6.808C2.517.83 4.606-.466 6.402.148c0 0 2.56-.307 3.362 1.956.803 2.263.497 9.474.497 9.474l-2.025-.154s-.287-.767-.153-1.342c0 0 1.138-1.205 1.458-3.015.43-2.43-.951-2.461-2.117-2.98C6.26 3.57 5.58 2.89 5.58 2.89s-1.908 2.605-1.582 8.125L0 10.824z"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(18.039)"
              />
            </G>
            <G fill="#000" fillRule="nonzero">
              <Path
                d="M2.728.015A1.17 1.17 0 001.56 1.187a1.17 1.17 0 001.168 1.172 1.17 1.17 0 001.168-1.172A1.17 1.17 0 002.728.015zm0 .276c.493 0 .893.401.893.896s-.4.896-.893.896a.894.894 0 01-.892-.896c0-.495.4-.896.892-.896zM5.6.294a1.17 1.17 0 00-1.168 1.173A1.17 1.17 0 005.6 2.639a1.17 1.17 0 001.167-1.172A1.17 1.17 0 005.6.294zm0 .276c.493 0 .892.402.892.897 0 .494-.4.896-.892.896a.894.894 0 01-.893-.896c0-.495.4-.897.893-.897z"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(21.157 4.972)"
              />
              <Path
                d="M.011.347a.137.137 0 01.173-.08L.19.27l1.528.614a.138.138 0 01-.095.259l-.007-.003L.088.527A.138.138 0 01.01.347zM3.633 1.245a.138.138 0 01.146-.123l.006.001.802.09a.138.138 0 01-.024.275l-.006-.001-.802-.09a.138.138 0 01-.122-.152z"
                transform="translate(-20.446 -10) translate(33.435 26.942) translate(.038 .079) translate(21.157 4.972)"
              />
            </G>
            <G transform="translate(-20.446 -10) rotate(29 22.182 86.535)" fillRule="nonzero">
              <Path
                fill="#D74A4A"
                d="M26.6958721 57.6456611L35.0712479 57.6456611 36.7134783 91.9287767 25.0536416 91.9287767z"
              />
              <Path
                d="M29.86.01c16.477 0 29.834 13.44 29.834 30.022 0 16.581-13.357 30.023-29.834 30.023S.026 46.613.026 30.032 13.383.01 29.86.01zm0 5.091c-13.682 0-24.774 11.162-24.774 24.931s11.092 24.93 24.774 24.93 24.773-11.161 24.773-24.93S43.542 5.102 29.86 5.102z"
                fill="#5780DD"
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};
