import { ScrollView, Text, View, SafeAreaView } from 'react-native';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Colors, Typography } from '../common-styles';
import { UserCommunityPostNotificationListItem } from '../common-settings';
import { UpdatePasswordItem } from './UpdatePassword';
import { PaymentMethodItem } from './PaymentMethod';
// import { AccessManage } from './AccessManage';
import { BackButton, CenterColumn } from '../common-ui';
import { BlockedUsersItem } from './BlockedUsersList';
import { HiddenCommunitiesItem } from './HiddenCommunitiesList';
import { ArchivedCommunitiesItem } from './ArchivedCommunitiesList';
import { AppContext } from '../../AppContext';
import { client, SETTING_QUERY } from '../graphql';
import { BASE_PAYMENT_API_URL } from '../../constants';
import { setCustomer, setPaymentMethods } from '../redux/contributionSlice';
import { FollowManage } from './FollowManage';
import { WhoCanComment } from './WhoCanComment';
import { MessagingManage } from './MessagingManage';
import { UserFollowingNotificationListItem } from '../common-settings/UserFollowingNotificationSettings';
import { RootState } from '../redux/store';
import { Setting } from '../common-types/types';
import { LeftDrawerNavigator } from '../common-types/navigation-types';
import { VisibilityManage } from './VisibilityManage';

export const DrawerSettings: React.FC = () => {
  const navigation = useNavigation<LeftDrawerNavigator>();
  const dispatch = useDispatch();
  const { user } = React.useContext(AppContext);
  const { customer } = useSelector((state: RootState) => state.contributions);

  useEffect(() => {
    navigation.setOptions({
      // @ts-ignore reason: navigation should default to last seen screen
      headerLeft: () => <BackButton onPress={() => navigation.navigate(`RightDrawer`, { screen: `MainTabs` })} />,
    });
  }, [navigation]);

  useEffect(() => {
    const getUserSettings = async () => {
      try {
        const { data } = await client.query({
          query: SETTING_QUERY,
          variables: {
            settingQuery: {
              query: `user`,
              user_id: user?.id,
            },
          },
        });
        const settings = data?.getSettings || [];
        const [customerSetting] = settings.filter((setting: Setting) => setting.key === `user.setting.customer_id`);

        if (customerSetting?.value) {
          const response = await fetch(`${BASE_PAYMENT_API_URL}customers/retrieve/${customerSetting.value}`, {
            method: `GET`,
            headers: {
              'Content-Type': `application/json`,
            },
          });
          const { customer, paymentMethods } = await response.json();
          dispatch(setCustomer(customer));
          dispatch(setPaymentMethods(paymentMethods?.data));
        }
      } catch (error) {
        // TODO: Should Handle this error better
        console.error(`Error while getting the user data ===> `, error);
      }
    };
    if (!user?.id) return;
    if (!customer) {
      getUserSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <SectionHeader title="Notifications" />
        <UserCommunityPostNotificationListItem />
        <UserFollowingNotificationListItem />
        <SectionHeader title="Privacy & safety" />
        <FollowManage />
        <MessagingManage />
        <WhoCanComment />
        <VisibilityManage />
        {/* <AccessManage /> */}
        {/* <DiscoverabilityAndContacts /> */}
        <BlockedUsersItem />
        <SectionHeader title="Security" />
        <UpdatePasswordItem />
        <SectionHeader title="Payment" />
        <PaymentMethodItem />
        <SectionHeader title="Nodes" />
        <HiddenCommunitiesItem />
        <ArchivedCommunitiesItem />
      </ScrollView>
    </SafeAreaView>
  );
};

type SectionHeaderProps = {
  title: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
  return (
    <CenterColumn>
      <View style={{ flexDirection: `row`, backgroundColor: Colors.commentGray, paddingVertical: 3, paddingHorizontal: 16 }}>
        <Text maxFontSizeMultiplier={2} style={{ ...Typography.text(`bold`, `gray`), paddingVertical: 4 }}>
          {title}
        </Text>
      </View>
    </CenterColumn>
  );
};
