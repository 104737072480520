/* eslint-disable react/function-component-definition */
import * as React from 'react';
import Svg, { G, Rect, Text, TSpan, Path, Ellipse } from 'react-native-svg';

export const ExploreVehicles = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-959 -1041) translate(238 491) translate(721 550)">
          <Rect fill="#FFF7AE" x={0} y={0} width={163} height={98} rx={7} />
          <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#E59611">
            <TSpan x={12} y={21}>
              Vehicles
            </TSpan>
          </Text>
          <Path
            d="M137.032 75.903c-9.312 15.598-52.68 12.456-65.053-4.826-12.373-17.282 6.123-48.591 27.807-47.02 21.684 1.683 46.43 36.135 37.246 51.846z"
            fill="#E59611"
            fillRule="nonzero"
          />
        </G>
        <G transform="translate(-959 -1041) translate(238 491) translate(721 550) translate(14 30)" fillRule="nonzero">
          <Path
            d="M6.926 24.558h89.87A1.21 1.21 0 0198 25.772V48.51c0 .335-.27.607-.602.607H.602a.605.605 0 01-.578-.777l6.902-23.782z"
            fill="#CECCFF"
          />
          <Path
            d="M14.073 0h50.675c.537 0 1.01.358 1.158.879l6.752 23.68H6.926L12.906.913A1.207 1.207 0 0114.072 0z"
            fill="#A39FFF"
          />
          <Path
            d="M61.414 4.753a1.581 1.581 0 011.534 1.189l4.368 18.616h-27.92V4.753h22.018zM36.228 24.558H12.047a.455.455 0 01-.44-.56l4.237-18.056a1.582 1.582 0 011.534-1.189h18.85v19.805z"
            fill="#5628D2"
          />
          <Ellipse fill="#5628D2" cx={18.805404} cy={48.3247463} rx={12.6711414} ry={12.6752537} />
          <Ellipse fill="#E8E7FF" cx={18.805404} cy={48.3247463} rx={6.335646} ry={6.33762687} />
          <Ellipse fill="#5628D2" cx={80.5772371} cy={48.3247463} rx={12.6711414} ry={12.6752537} />
          <Ellipse fill="#E8E7FF" cx={80.5772371} cy={48.3247463} rx={6.335646} ry={6.33762687} />
        </G>
      </G>
    </Svg>
  );
};
