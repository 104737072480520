import * as React from 'react';
import Svg, { Defs, LinearGradient, Stop, Path, G, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const JcbImage = (props) => {
  return (
    <Svg
      width={66}
      height={46}
      viewBox="0 0 66 46"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <LinearGradient x1="100%" y1="43.985%" x2="3.381%" y2="43.985%" id="prefix__b">
          <Stop stopColor="#43C311" offset="0%" />
          <Stop stopColor="#288C00" offset="100%" />
        </LinearGradient>
        <LinearGradient x1=".258%" y1="49.75%" x2="101.258%" y2="49.75%" id="prefix__d">
          <Stop stopColor="#21469E" offset="0%" />
          <Stop stopColor="#1E81EB" offset="100%" />
        </LinearGradient>
        <LinearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="prefix__f">
          <Stop stopColor="#F42D3E" offset="0%" />
          <Stop stopColor="#9C0017" offset="100%" />
        </LinearGradient>
        <Path
          d="M3.984 0c-.232 0-.463.009-.69.034a3.67 3.67 0 00-2.33 1.233 3.893 3.893 0 00-.887 1.814 5.211 5.211 0 00-.073 1.004v3.643c0 .085-.02.092.071.092H4.346c.466 0 .936-.018 1.4.026.459.043.925.178 1.295.47.52.41.732 1.116.554 1.767-.17.624-.683 1.043-1.268 1.217-.065.019-.13.035-.196.05-.039.008-.09.006-.125.023-.017.008-.06.018-.029.05.008.008.042.008.067.008h.024c.184.018.366.051.545.102.545.154 1.074.485 1.326 1.028.3.643.147 1.44-.336 1.938-.38.393-.911.594-1.437.66-.263.033-.528.026-.793.026H.206c-.04 0-.156-.02-.189 0-.02.013-.013.03-.013.063V22.904c0 .015-.01.078 0 .09.012.013.107.001.13.001h5.163c.847 0 1.655-.228 2.342-.76.931-.722 1.482-1.87 1.487-3.075V18.952.011H4.899c-.282 0-.568-.01-.854-.011h-.061zM1.789 8.984c-.011.026 0 .085 0 .113V10.594c0 .04-.018.176.01.196.024.018.127 0 .158 0H3.113c.215 0 .43.004.646 0 .266-.004.524-.084.713-.28a.932.932 0 00.226-.844.89.89 0 00-.172-.361.93.93 0 00-.659-.336c-.122-.01-.244-.005-.366-.005H1.854l-.038-.002c-.013 0-.019.004-.027.022zm1.407 3.003H1.789v1.898c0 .032-.013.098 0 .127.019.043.194.019.25.019h1.767c.23 0 .45-.015.654-.141.248-.154.416-.421.459-.72a1.069 1.069 0 00-.308-.925c-.198-.187-.445-.256-.707-.258l-.236-.001h-.472z"
          id="prefix__a"
        />
        <Path
          d="M3.135.051A3.679 3.679 0 001.65.658C.622 1.369.009 2.574.004 3.842c-.002.373 0 .746 0 1.119V13.24c0 .07-.001.056.077.093.087.043.174.084.262.124.7.317 1.437.559 2.198.654.548.069 1.132.088 1.65-.136.413-.18.743-.51.913-.939.109-.275.14-.567.14-.861V7.937c0-.023-.013-.096 0-.116.016-.025.144-.009.181-.009h3.036v4.43c0 .441-.064.884-.24 1.29a2.502 2.502 0 01-.805 1.005c-.844.63-1.963.795-2.983.842-.73.034-1.463.005-2.19-.07-.75-.077-1.51-.19-2.24-.392v8c0 .01-.007.066 0 .075.016.017.154 0 .181 0h4.312c.637 0 1.289.045 1.91-.116a3.796 3.796 0 002.482-2.112c.258-.572.343-1.18.343-1.803V0H3.978c-.282 0-.563.005-.843.051"
          id="prefix__c"
        />
        <Path
          d="M3.466.01c-.4.026-.795.119-1.166.276A3.782 3.782 0 00.402 2.095a3.936 3.936 0 00-.397 1.72v4.993l.077-.066c.091-.076.186-.148.285-.215.61-.416 1.324-.65 2.04-.787.582-.112 1.175-.16 1.767-.172.583-.012 1.167.009 1.746.073.591.066 1.179.169 1.76.298l.214.048c.038.01.184.022.204.052.014.021 0 .107 0 .132v1.48a10.245 10.245 0 00-.25-.125 7.184 7.184 0 00-1.99-.65c-.93-.152-1.981-.147-2.784.426-.774.554-1.045 1.536-.962 2.47.082.91.57 1.689 1.39 2.052.81.36 1.752.31 2.6.138a7.771 7.771 0 001.996-.729v1.479c0 .026.015.112 0 .134-.02.03-.168.042-.204.05a15.94 15.94 0 01-2.218.371c-.583.054-1.172.065-1.756.042a9.638 9.638 0 01-1.777-.22c-.768-.175-1.534-.477-2.138-1.012v8.692c0 .018-.011.093 0 .107.025.032.233.006.281.006h3.795c.535 0 1.079.03 1.612-.01a3.677 3.677 0 002.52-1.296c.612-.722.913-1.636.913-2.588V.002H4.153L3.82 0c-.118 0-.236.002-.354.01"
          id="prefix__e"
        />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0h1300v7847H0z" />
        <Path
          d="M62.993.5c.691 0 1.317.281 1.77.734A2.49 2.49 0 0165.5 3h0v40a2.503 2.503 0 01-2.507 2.5h0H3.007a2.501 2.501 0 01-1.77-.734A2.49 2.49 0 01.5 43h0V3A2.503 2.503 0 013.007.5h0z"
          stroke="#E6E7E8"
          fill="#FFF"
        />
        <Use fill="url(#prefix__b)" xlinkHref="#prefix__a" transform="translate(38.77 12)" />
        <Use fill="url(#prefix__d)" xlinkHref="#prefix__c" transform="translate(18 12)" />
        <Use fill="url(#prefix__f)" xlinkHref="#prefix__e" transform="translate(28.385 12)" />
      </G>
    </Svg>
  );
};
