import React from 'react';
import { Dimensions, Easing } from 'react-native';
import { useSelector } from 'react-redux';
// NAV
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
// NAV END
import { useNavigation } from '@react-navigation/native';
import { Colors, Typography, headerStyle } from '../common-styles';
import { BackButton } from '../common-ui';
import { Drawer as DrawerComponent } from '../drawer/Drawer';
import { DrawerSettings } from '../drawer/DrawerSettings';
// import { LoadingScreen } from './LoadingScreen';
import { getTabBarStacks } from './MainTabNavigator';
import { AppContext } from '../../AppContext';

import { VerificationSelfieUpload } from '../mesh-verification';
import { ProfileScreen } from '../profile/ProfileScreen';
import { FavouriteCommunitiesSelection } from '../profile/FavouriteCommunitiesSelection';
import { ProfileSettings } from '../profile/ProfileSettings';
import {
  HandleEditor,
  EmailEditor,
  BankAccount,
  EmailVerificationScreen,
  PronounSettingEditor,
  TextEditor,
  UserCommunityPostNotificationSettings,
  UserFollowingNotificationSettings,
} from '../common-settings';
import { PostReactionUserList } from '../post';
import { ModerationDrawer } from '../moderation/ModerationDrawer';
import { PhoneNumberEditor } from '../common-settings/PhoneNumberEditor';
import { PhoneVerificationScreen } from '../common-settings/PhoneVerificationScreen';
import { BirthdayEditor } from '../common-settings/BirthdayEditor';
import { VerificationSelfieSentScreen } from '../mesh-verification/VerificationSelfieSentScreen';
import { BirthdaysByCommunity } from '../community/BirthdaysByCommunity';
import { UpdatePassword } from '../drawer/UpdatePassword';
import { HiddenCommunitiesList } from '../drawer/HiddenCommunitiesList';
import { ArchivedCommunitiesList } from '../drawer/ArchivedCommunitiesList';
import { BlockedUsersList } from '../drawer/BlockedUsersList';
import { AccessSettings } from '../drawer/AccessManage';
import { FollowSettings } from '../drawer/FollowManage';
import { WhoCanCommentSettings } from '../drawer/WhoCanComment';
import { VisibilitySettings } from '../drawer/VisibilityManage';
import { MessagingSettings } from '../drawer/MessagingManage';
import { PaymentMethodList } from '../contribution/PaymentMethodList';
import { AddPaymentMethod } from '../contribution/AddPaymentMethod';
import { EditPaymentMethod } from '../contribution/EditPaymentMethod';
import FollowInfoScreen from '../drawer/FollowInfoScreen';
import { DiscoverabilityAndContactsSetting } from '../drawer/DiscoverabilityAndContacts';
import { EnterYourPasswordScreen } from '../profile/EnterYourPasswordScreen';
import { EditOwnershipScreen } from '../profile/EditOwnershipScreen';
import { PreparingAccountForDeletion } from '../profile/PreparingAccountForDeletion';
import { MemberList } from '../community/v2/MemberList';
//import { ManifestViewer } from '../debug/ManifestViewer';

const RightDrawer = createDrawerNavigator();
const LeftDrawer = createDrawerNavigator();

const MainTabNavigator = () => {
  const MainTabs = createBottomTabNavigator();
  const screens = getTabBarStacks();
  const keys = Object.keys(screens);
  const { chatNotifications, moderationNotificationCount, notificationCount, feedActivityCount } = React.useContext(AppContext);
  const { groupToJoin } = useSelector((state) => state.guest);
  const navigation = useNavigation();
  return (
    <MainTabs.Navigator
      screenOptions={{
        tabBarActiveTintColor: Colors.focusedIconColor,
        tabBarInactiveTintColor: Colors.iconColor,
        labelStyle: { ...Typography.text(`miniscule`, `bold`, `center`, `uncolored`), paddingBottom: 4 },
        keyboardHidesTabBar: true,
        headerShown: false,
        // unmountOnBlur: true,
      }}
      initialRouteName={groupToJoin ? `ExploreNavigator` : `PersonalFeedNavigator`}
    >
      {keys.map((screenKey) => (
        <MainTabs.Screen
          name={screenKey}
          key={screenKey}
          component={screens[screenKey]}
          options={(props) =>
            screens[screenKey].options({
              ...props,
              moderationNotificationCount,
              chatNotifications,
              notificationCount,
              feedActivityCount,
            })
          }
          listeners={{
            tabPress: (e) => {
              if (screenKey === `ChatNavigator`) {
                e.preventDefault();
                navigation.navigate(`ChatNavigator`, { screen: `chat`, initial: true });
              }
            },
          }}
        />
      ))}
    </MainTabs.Navigator>
  );
};

const transitionSpecTiming = {
  animation: `timing`,
  config: { duration: 300, easing: Easing.out(Easing.poly(4)) },
};

const ProfileStackProps = () => {
  return {
    headerTitleStyle: { alignSelf: `center` },
    headerLeft: () => <BackButton />,
    headerTintColor: Colors.textBlack, // probably doesn't matter since we override headerTitleStyle and the back button
    headerStyle,
    headerTitleAlign: `center`,
    transitionSpec: { open: transitionSpecTiming, close: transitionSpecTiming },
    contentStyle: { backgroundColor: Colors.white },
  };
};

const ProfileStackNavigator = () => {
  const ProfileStack = createNativeStackNavigator();

  return (
    <ProfileStack.Navigator screenOptions={ProfileStackProps}>
      <ProfileStack.Screen name="Profile" component={ProfileScreen} />
      <ProfileStack.Screen name="ProfileCompat" component={ProfileScreen} />
      <ProfileStack.Screen
        name="FavouriteCommunitiesSelection"
        component={FavouriteCommunitiesSelection}
        options={{ headerTitle: `Favorites` }}
      />
      <ProfileStack.Screen name="ProfileSettings" component={ProfileSettings} options={{ headerTitle: `Edit your profile` }} />
      <ProfileStack.Screen name="TextSettingEditor" component={TextEditor} options={{ headerTitle: `Edit setting` }} />
      <ProfileStack.Screen name="BankAccount" component={BankAccount} options={{ headerTitle: `Payment account` }} />
      <ProfileStack.Screen name="HandleEditor" component={HandleEditor} options={{ headerTitle: `Edit username` }} />
      <ProfileStack.Screen name="EmailEditor" component={EmailEditor} options={{ headerTitle: `Edit email` }} />
      <ProfileStack.Screen name="BirthdayEditor" component={BirthdayEditor} options={{ headerTitle: `Birthday` }} />
      <ProfileStack.Screen name="BirthdaysByCommunity" component={BirthdaysByCommunity} options={{ headerTitle: `Birthdays` }} />
      <ProfileStack.Screen name="PhoneNumberEditor" component={PhoneNumberEditor} options={{ headerTitle: `Verify phone` }} />
      <ProfileStack.Screen
        name="EmailVerificationScreen"
        component={EmailVerificationScreen}
        options={{ headerTitle: `Email verification` }}
      />
      <ProfileStack.Screen
        name="PhoneVerificationScreen"
        component={PhoneVerificationScreen}
        options={{ headerTitle: `Verify phone` }}
      />
      <ProfileStack.Screen
        name="PronounSettingEditor"
        component={PronounSettingEditor}
        options={{ headerTitle: `Edit your pronouns` }}
      />
      <ProfileStack.Screen
        name="VerificationSelfieUpload"
        component={VerificationSelfieUpload}
        options={{ headerTitle: `Mesh verification` }}
      />
      <ProfileStack.Screen name="VerificationSelfieSentScreen" component={VerificationSelfieSentScreen} />
      <ProfileStack.Screen name="PostReactionUserList" component={PostReactionUserList} options={{ headerTitle: `Reactions` }} />
      <ProfileStack.Screen name="FollowInfoScreen" component={FollowInfoScreen} options={{ headerTitle: `` }} />
      <ProfileStack.Screen name="EnterYourPasswordScreen" component={EnterYourPasswordScreen} />
      <ProfileStack.Screen
        name="EditOwnershipScreen"
        component={EditOwnershipScreen}
        options={{ headerTitle: `Manage communities` }}
      />
      <ProfileStack.Screen name="MemberInfo" component={MemberList} options={{ headerTitle: `Members and roles` }} />
      <ProfileStack.Screen
        name="PreparingAccountForDeletion"
        component={PreparingAccountForDeletion}
        options={{ headerTitle: `` }}
      />
    </ProfileStack.Navigator>
  );
};

const RightDrawerNavigator = () => {
  // TODO: reanimated2 need to be configured correctly (useLegacyImplementation)
  // https://reactnavigation.org/docs/drawer-navigator/#uselegacyimplementation
  return (
    <RightDrawer.Navigator
      id="RightDrawer"
      drawerType="slide"
      useLegacyImplementation={true}
      drawerContent={({ navigation }) => <ModerationDrawer navigation={navigation} />}
      overlayColor={`${Colors.mediumGray}80`}
      drawerStyle={{
        width: Math.min(400, Dimensions.get(`window`).width * 0.75), // iPhone 11 = 310.5
      }}
      screenOptions={{
        swipeEdgeWidth: 15,
        drawerPosition: `right`,
        activeTintColor: `white`,
        activeBackgroundColor: Colors.themeLight,
        headerShown: false,
      }}
      sceneContainerStyle={{
        width: Math.min(400, Dimensions.get(`window`).width * 0.75), // iPhone 11 = 310.5
      }}
      detachInactiveScreens={false}
    >
      <RightDrawer.Screen name="MainTabs" component={MainTabNavigator} />
    </RightDrawer.Navigator>
  );
};

const LeftDrawerNavigator = () => {
  // TODO: reanimated2 need to be configured correctly (useLegacyImplementation)
  // https://reactnavigation.org/docs/drawer-navigator/#uselegacyimplementation
  return (
    <LeftDrawer.Navigator
      id="LeftDrawer"
      backBehavior="none"
      drawerType="slide"
      useLegacyImplementation={true}
      drawerContent={(props) => <DrawerComponent {...props} />}
      drawerStyle={{
        width: Math.min(400, Dimensions.get(`window`).width * 0.75), // iPhone 11 = 310.5
      }}
      overlayColor={`${Colors.mediumGray}80`}
      screenOptions={{
        swipeEdgeWidth: 15,
        activeTintColor: `white`,
        activeBackgroundColor: Colors.themeLight,
        headerShown: false,
      }}
    >
      <LeftDrawer.Screen name="RightDrawer" component={RightDrawerNavigator} />
      <LeftDrawer.Screen name="ProfileStack" component={ProfileStackNavigator} />
      <LeftDrawer.Screen name="DrawerSettings" component={DrawerSettingsNavigator} />
      {/* <LeftDrawer.Screen name="Manifest" component={ManifestViewer} /> */}
      {/* <LeftDrawer.Screen name="FindFriendsScreen" component={FindFriendsScreen} options={{ headerShown: true }} />*/}
    </LeftDrawer.Navigator>
  );
};

const DrawerSettingsNavigator = () => {
  const SettingsStack = createNativeStackNavigator();
  return (
    <SettingsStack.Navigator screenOptions={ProfileStackProps} initialRouteName="DrawerSettingsMenu">
      <SettingsStack.Screen name="DrawerSettingsMenu" component={DrawerSettings} options={{ headerTitle: `Settings` }} />
      <SettingsStack.Screen
        name="UserCommunityPostNotificationSettings"
        component={UserCommunityPostNotificationSettings}
        options={{ headerTitle: `Push notifications` }}
      />
      <SettingsStack.Screen
        name="UserFollowingNotificationSettings"
        component={UserFollowingNotificationSettings}
        options={{ headerTitle: `Follow notifications` }}
      />
      <SettingsStack.Screen name="Profile" component={ProfileScreen} />
      <SettingsStack.Screen
        name="HiddenCommunitiesList"
        component={HiddenCommunitiesList}
        options={{ headerTitle: `Hidden Nodes` }}
      />
      <SettingsStack.Screen
        name="ArchivedCommunitiesList"
        component={ArchivedCommunitiesList}
        options={{ headerTitle: `Archived Nodes` }}
      />
      <SettingsStack.Screen name="BlockedUsersList" component={BlockedUsersList} options={{ headerTitle: `Blocked Users` }} />
      <SettingsStack.Screen name="UpdatePassword" component={UpdatePassword} options={{ headerTitle: `Update Password` }} />
      <SettingsStack.Screen name="AccessSettings" component={AccessSettings} options={{ headerTitle: `Access | Manage` }} />
      <SettingsStack.Screen name="FollowSettings" component={FollowSettings} options={{ headerTitle: `Follow` }} />
      <SettingsStack.Screen
        name="WhoCanCommentSettings"
        component={WhoCanCommentSettings}
        options={{ headerTitle: `Who can comment` }}
      />
      <SettingsStack.Screen
        name="VisibilitySettings"
        component={VisibilitySettings}
        options={{ headerTitle: `Profile visibility` }}
      />
      <SettingsStack.Screen name="MessagingSettings" component={MessagingSettings} options={{ headerTitle: `Messaging` }} />
      <SettingsStack.Screen
        name="DiscoverabilityAndContactsSetting"
        component={DiscoverabilityAndContactsSetting}
        options={{ headerTitle: `Discoverability and contacts` }}
      />
      {/* Copy of Contribution screens */}
      <SettingsStack.Screen name="PaymentMethodList" component={PaymentMethodList} options={{ headerTitle: `Payment Methods` }} />
      <SettingsStack.Screen
        name="AddPaymentMethod"
        component={AddPaymentMethod}
        options={{ headerTitle: `Add payment method` }}
      />
      <SettingsStack.Screen
        name="EditPaymentMethod"
        component={EditPaymentMethod}
        options={{ headerTitle: `Edit payment method` }}
      />
    </SettingsStack.Navigator>
  );
};

export const AppNavigator = LeftDrawerNavigator;
