import React, { useCallback, useContext, useEffect } from 'react';
import { Dimensions, Image, Platform, View, Text, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Colors, Typography } from '../common-styles';
import { CenterColumn, MeshIcon, ThemedButton } from '../common-ui';
import { getEnvVariables } from '../../environment';
// @ts-ignore reason: svg import type definition does not exist
import MeshStackSVG from '../../assets/images/mesh-stack.svg';
import { AppContext } from '../../AppContext';
import { useLogin } from './hooks/useLogin';
import { WELCOMESCREEN, WELCOMESCREEN_LOGIN, WELCOMESCREEN_CONTINUE_AS_GUEST, WELCOMESCREEN_SIGNUP } from './testLabels';
import { useAppDispatch } from '../redux/store';
import { cleanState } from '../redux/feedSlice';
import { AuthNavigationContainer } from '../common-types/navigation-types';
import { Events, PendoTrackEvent } from '../pendo/events';

type WelcomeNavigator = StackNavigationProp<AuthNavigationContainer, `Auth`>;

export const WelcomeScreen = () => {
  const { height, width } = Dimensions.get(`window`);
  const logoWidth = Math.min(width * 0.425, height * 0.425); // from mocks
  const logoHeight = logoWidth * 1.1; // from SVG aspect ratio
  const dispatch = useAppDispatch();

  const navigation = useNavigation<WelcomeNavigator>();
  const { invite_token, isGuestMode, setIsGuestMode } = useContext(AppContext);
  const { loading, logUserIn } = useLogin();

  const navigateToRegister = useCallback(() => {
    const { anyoneCanRegister } = getEnvVariables();
    setIsGuestMode(false);
    // only allow sign-up for __DEV__ OR if invite token exists
    PendoTrackEvent(Events.START_SIGNUP, {
      open_registration: anyoneCanRegister,
      hasInvite: !!invite_token,
      invite_token,
    });
    if (anyoneCanRegister || invite_token) navigation.push(`Auth`, { screen: `RegisterUsername` });
    else navigation.push(`Auth`, { screen: `EarlyAccess` });
  }, [navigation, invite_token, setIsGuestMode]);

  const navigateToLogin = useCallback(() => {
    PendoTrackEvent(Events.START_LOGIN, {
      hasInvite: !!invite_token,
      invite_token,
    });
    navigation.push(`Auth`, { screen: `Login` });
  }, [navigation, invite_token]);

  const navigateToGuestMode = useCallback(async () => {
    try {
      PendoTrackEvent(Events.START_GUEST);
      if (!isGuestMode) await logUserIn(`guest`, `test.123`);
      navigation.push(`Guest`, { screen: `Explore` });
    } catch (e) {
      console.error(e);
    }
  }, [isGuestMode, logUserIn, navigation]);

  useEffect(() => {
    dispatch(cleanState());
  }, [dispatch]);

  return (
    <View testID={WELCOMESCREEN} style={{ flex: 1 }}>
      <CenterColumn style={{ flex: 1 }}>
        <View style={{ flex: 1, alignItems: `center` }}>
          <View style={{ flex: 2 }} />
          <View style={styles.centered}>
            {Platform.select({
              default: <MeshStackSVG width={logoWidth} height={logoHeight} />,
              web: (
                <Image
                  source={require(`../../assets/images/mesh-stack.png`)}
                  width={logoWidth}
                  height={logoHeight}
                  style={{ width: logoWidth, height: logoHeight }}
                />
              ),
            })}
          </View>
          <View style={{ flex: 1 }} />
          <View style={styles.centered}>
            <Text allowFontScaling={false} style={styles.subtitle}>
              It's your community.
            </Text>
            <Text allowFontScaling={false} style={styles.subtitle}>
              Own it.
            </Text>
          </View>
          <View style={{ flex: 2 }} />
        </View>

        <View style={{ paddingBottom: 60 }}>
          <ThemedButton
            rounded
            title="Sign up"
            onPress={navigateToRegister}
            buttonStyle={{ padding: 15 }}
            containerStyle={{ marginBottom: 20, marginHorizontal: 16 }}
            titleStyle={styles.signUpTitle}
            testID={WELCOMESCREEN_SIGNUP}
            disabled={loading}
          />
          <ThemedButton
            outline={true}
            rounded
            clear
            title="Continue as guest"
            onPress={navigateToGuestMode}
            buttonStyle={{ padding: 15 }}
            titleStyle={styles.guestTitle}
            containerStyle={{ marginBottom: 15, marginHorizontal: 16 }}
            testID={WELCOMESCREEN_CONTINUE_AS_GUEST}
            disabled={loading}
          />
          <ThemedButton
            clear
            title="Already have an account? Log in."
            rightIcon={<MeshIcon name="arrow-right" color={loading ? Colors.disabledIconColor : Colors.brandPurple} size={22} />}
            onPress={navigateToLogin}
            containerStyle={{ marginBottom: 10, marginHorizontal: 16 }}
            titleStyle={styles.loginTitle}
            testID={WELCOMESCREEN_LOGIN}
            disabled={loading}
          />
        </View>
      </CenterColumn>
    </View>
  );
};

const styles = StyleSheet.create({
  centered: { justifyContent: `center`, alignItems: `center` },
  subtitle: { ...Typography.text(`bold`, `center`), fontSize: 24, lineHeight: 29 },
  signUpTitle: Typography.text(`plustwo`, `bold`, `white`),
  guestTitle: Typography.text(`plustwo`, `bold`, `link`),
  loginTitle: Typography.text(`bold`, `link`),
});
