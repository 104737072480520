import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import { useDispatch } from 'react-redux';
import { Colors, Typography } from '../../common-styles';
import { NoThemeButton } from '../../common-ui';
import { refreshList } from '../../redux/inboxSlice';
import { CHAT_CHANNEL_TYPES } from '../chatHelpers';
import { acceptMessageRequest, hideChannel, rejectMessageRequest } from '../chatServices';

const MessageRequestBottomSheet: React.FC<{ user: string; common: number; navigation: any; channelId: string }> = ({
  user,
  common,
  channelId,
  navigation,
}) => {
  const { width } = Dimensions.get(`window`);
  const dispatch = useDispatch();

  const onAccept = async () => {
    if (!channelId) return;
    await acceptMessageRequest(CHAT_CHANNEL_TYPES.messaging, channelId);
    dispatch(refreshList(true));
    navigation.goBack();
  };

  const onReject = async () => {
    if (!channelId) return;
    await rejectMessageRequest(CHAT_CHANNEL_TYPES.messaging, channelId);
    dispatch(refreshList(true));
    navigation.goBack();
  };

  const onDelete = async () => {
    if (!channelId) return;
    await hideChannel(CHAT_CHANNEL_TYPES.messaging, channelId);
    dispatch(refreshList(true));
    navigation.goBack();
  };

  return (
    <View style={[styles.conatiner, { width }]}>
      <View style={styles.content}>
        <Text style={{ textAlign: `center`, fontSize: 16 }}>
          <Text style={{ fontWeight: `bold` }}>{user} </Text>
          <Text>wants to send you a message</Text>
        </Text>
      </View>

      <Text maxFontSizeMultiplier={2} style={{ padding: 10 }}>
        <Text>You have{` `}</Text>
        <Text style={{ fontWeight: `bold` }}>
          {common} {common > 1 ? `communities` : `community`}
          {` `}
        </Text>
        <Text>
          {` `}in common. Do you want to allow them to message you? They won't know you've seen their messages unless you accept.
        </Text>
      </Text>

      <View style={styles.buttonContainer}>
        <NoThemeButton
          rounded
          title="Accept"
          titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.white }}
          containerStyle={{ marginHorizontal: 10 }}
          buttonStyle={{ ...styles.buttonStyle, backgroundColor: Colors.deepPurple, borderColor: Colors.deepPurple }}
          onPress={onAccept}
        />
        <NoThemeButton
          rounded
          title="Block"
          titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.textRed }}
          containerStyle={{ marginHorizontal: 10 }}
          buttonStyle={{
            ...styles.buttonStyle,
            backgroundColor: Colors.translucentBrightRed,
            borderColor: Colors.translucentBrightRed,
          }}
          onPress={onReject}
        />
        <NoThemeButton
          rounded
          title="Delete"
          titleStyle={{ ...Typography.text(`bold`, `plusone`), color: Colors.deepPurple }}
          containerStyle={{ marginHorizontal: 10 }}
          buttonStyle={{ ...styles.buttonStyle, backgroundColor: Colors.white, borderColor: Colors.deepPurple }}
          onPress={onDelete}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  conatiner: {
    position: `absolute`,
    bottom: 0,
    display: `flex`,
    borderTopLeftRadius: 18,
    borderTopRightRadius: 18,
    borderColor: Colors.lightGray,
    borderWidth: 2,
    borderBottomColor: Colors.white,
    backgroundColor: `white`,
  },
  content: {
    padding: 12,
    paddingTop: 26,
    borderBottomColor: Colors.lightGray,
    borderBottomWidth: 1,
    alignItems: `center`,
    justifyContent: `center`,
  },
  buttonContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `space-between`,
  },
  buttonStyle: {
    paddingVertical: 10,
    paddingHorizontal: 25,
    alignItems: `center`,
    borderWidth: 1,
    marginBottom: 10,
  },
});

export default MessageRequestBottomSheet;
