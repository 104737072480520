import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ScrollView, Text, TouchableOpacity, View, Alert } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from 'react-apollo';
import { Colors, Typography } from '../common-styles';
import {
  BottomSheetModal,
  KeyboardPaddingView,
  MeshIcon,
  NoThemeButton,
  ThemedButton,
  Divider,
  ThemedDeleteButton,
} from '../common-ui';
import { commonPropTypes } from '../common-util';
import { CREATE_COMMUNITY_APPLICATION, DELETE_COMMUNITY_APPLICATION, refetchQueriesFor } from '../graphql';
import { WhatIsMeshVerificationModal } from '../mesh-verification';
import { TYPES } from './helpers';
import { MODAL_SWITCH_TIME } from '../../constants';
import { setQuestions } from '../redux/applicationSlice';

const newTypes = [TYPES[4], TYPES[5], TYPES[6]];
const verifyOptions = {
  yes: { id: 0, body: `Yes` },
  no: { id: 1, body: `No` },
};

const modalTitleFromState = {
  '3dots': `Manage`,
  delete: `Are you sure?`,
  add: `Question type`,
};

export const CreateCommunityApplication = ({ navigation, route }) => {
  const { group } = route.params;
  const dispatch = useDispatch();
  const { application } = group || {};
  const { questions } = useSelector((state) => state.applicationForm);
  const [selectedType, setSelectedType] = useState({});
  const [showWhatIsMeshVerifyModal, setShowWhatIsMeshVerifyModal] = useState(false);
  const [showModal, setShowModal] = useState(); //add | 3dot | delete
  const [meshVerifyRequired, setMeshVerifyRequired] = useState(application?.require_mesh_identity || false);
  const defaultQuestion = { key: ``, type: { id: selectedType.id, name: selectedType.name } };
  const didMountRef = useRef(false);
  //initial application setup
  useEffect(() => {
    console.log(`Effect #1: initial redux/applicacion setup`);
    const { questions } = group?.application || {};
    if (questions) dispatch(setQuestions(questions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(`Effect #2: navigation bar setup`);
    navigation.setOptions({
      title: `Membership application`,
      headerRight: () => (
        <ThemedButton
          clear
          containerStyle={{ right: 10 }}
          leftIcon={<MeshIcon name="dots-three-vertical" color={Colors.iconColor} size={24} />}
          onPress={() => setShowModal(`3dots`)}
        />
      ),
    });
  }, [navigation]);

  useEffect(() => {
    if (didMountRef.current) {
      console.log(`Effect #3: question data changed`, questions);
      if (questions.length) setApplication();
      else deleteApplication();
    } else {
      didMountRef.current = true;
    }
  }, [questions, setApplication, deleteApplication]);

  const deleteAllQuestions = () => {
    dispatch(setQuestions([]));
    setShowModal();
    setTimeout(() => deleteApplication(), 500);
  };

  const setApplication = useCallback(async () => {
    const require_mesh_identity = meshVerifyRequired.id === 0 || meshVerifyRequired === true;
    const input = {
      group_id: group?.id,
      require_mesh_identity,
      questions,
    };
    try {
      await createApplicationForm({ variables: { input } });
    } catch (error) {
      console.warn(`[createApplication]`, error);
    }
  }, [meshVerifyRequired, group?.id, questions, createApplicationForm]);

  const deleteApplication = useCallback(async () => {
    try {
      await deleteApplicationForm();
    } catch (error) {
      console.warn(`[deletepplication]`, error);
    }
  }, [deleteApplicationForm]);

  const _showAlert = () =>
    Alert.alert(
      `There are changes that have not been saved`,
      `Do you want to return without saving your changes?`,
      [
        { text: `Cancel`, style: `cancel` },
        { text: `OK`, style: `default`, onPress: () => navigation.goBack() },
      ],
      { cancelable: true },
    );

  const [deleteApplicationForm] = useMutation(DELETE_COMMUNITY_APPLICATION, {
    variables: { group_id: group?.id },
    refetchQueries: refetchQueriesFor(`Group`),
  });

  const [createApplicationForm] = useMutation(CREATE_COMMUNITY_APPLICATION, {
    refetchQueries: refetchQueriesFor(`Group`),
  });

  return (
    <KeyboardPaddingView>
      <ScrollView style={localStyles.scrollViewContainer} keyboardShouldPersistTaps="handled">
        <View style={{ flex: 1 }}>
          {!questions.length && (
            <View style={{ marginHorizontal: 16, marginTop: 20 }}>
              <Text style={{ ...Typography.text(`plustwo`, `gray`, `center`) }}>
                You have no membership application questions for your community
              </Text>
            </View>
          )}
          <View style={{ flex: 1 }}>
            {questions.map((question, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <View key={`${Math.random().toString()}-${question.key}`}>
                  <TouchableOpacity
                    style={{ marginTop: 5 }}
                    onPress={() => {
                      navigation.push(`EditCommunityApplicationQuestion`, {
                        isEditingQuestion: true,
                        questionIndex: index,
                        question,
                      });
                    }}
                  >
                    <View style={{ flexDirection: `row`, justifyContent: `space-between`, marginHorizontal: 16 }}>
                      <Text style={{ marginVertical: 16, fontSize: 15, fontWeight: `500`, flex: 1 }}>{question.key}</Text>
                      <MeshIcon name="create" color={Colors.iconColor} focused style={{ marginVertical: 16 }} />
                    </View>
                  </TouchableOpacity>
                  <Divider />
                </View>
              );
            })}
            <View style={{ flex: 1, justifyContent: `space-between`, marginHorizontal: 14 }}>
              <NoThemeButton
                clear
                outline
                rounded={false}
                color={Colors.gray}
                title="Add question"
                titleStyle={{ fontSize: Typography.baseFontSize, color: Colors.brandPurple, marginLeft: 8 }}
                containerStyle={{ flex: 1, marginTop: 20 }}
                buttonStyle={{ justifyContent: `flex-start`, borderColor: Colors.lightGray }}
                leftIcon={<MeshIcon name="circle-plus" size={22} color={Colors.brandPurple} />}
                onPress={() => setShowModal(`add`)}
              />
            </View>
          </View>
        </View>
      </ScrollView>

      <BottomSheetModal //first modal in the delete guidelines flow - 3 dot menu modal
        title={showModal ? modalTitleFromState[showModal] : ``}
        visible={!!showModal}
        onPressCancel={() => setShowModal()}
        showCancelBtn={showModal === `3dots`}
        showConfirmBtn={showModal === `add`}
        isConfirmDisabled={showModal === `add` && selectedType.name === undefined}
        onPressConfirm={
          showModal === `add`
            ? () => {
                setShowModal(undefined);
                navigation.push(`EditCommunityApplicationQuestion`, {
                  isEditingQuestion: false,
                  defaultQuestion,
                  selectedType,
                });
              }
            : undefined
        }
      >
        {showModal === `3dots` && (
          <View style={{ marginTop: 20, marginBottom: 32, marginHorizontal: 16 }}>
            <TouchableOpacity
              style={{ flexDirection: `row` }}
              onPress={() => {
                setTimeout(() => setShowModal(`delete`), MODAL_SWITCH_TIME);
              }}
            >
              <MeshIcon name="minus-circle" color={Colors.alertColor} size={24} style={{ marginRight: 14 }} />
              <Text style={{ color: Colors.alertColor, ...Typography.text(`bold`, `alert`, `plusone`), marginTop: 1 }}>
                Delete membership application
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {showModal === `delete` && (
          <View style={{ marginTop: 12, marginHorizontal: 16 }}>
            <Text style={{ textAlign: `center`, ...Typography.text(`plustwo`) }}>
              Are you sure you want to delete the entire membership application?
            </Text>
            <ThemedDeleteButton
              rounded
              containerStyle={{ marginTop: 33 }}
              title="Delete"
              titleStyle={{ paddingVertical: 5 }}
              onPress={() => deleteAllQuestions()}
            />
          </View>
        )}
        {showModal === `add` && (
          <View style={{ marginTop: 24 }}>
            {newTypes.map((type) => (
              <TouchableOpacity key={type.id} onPress={() => setSelectedType(type)}>
                <View style={{ display: `flex`, flexDirection: `row`, marginHorizontal: 16, marginBottom: 36 }}>
                  <MeshIcon name={type.iconName} color={Colors.iconColor} />
                  <View style={{ display: `flex`, flexDirection: `row`, justifyContent: `space-between`, flex: 1 }}>
                    <Text style={{ marginLeft: 10, marginTop: 4 }}>{type.name}</Text>
                    <MeshIcon
                      name={selectedType.name === type.name ? `circle-check` : `radio-off`}
                      color={selectedType.name === type.name ? Colors.brandPurple : Colors.iconColor}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        )}
      </BottomSheetModal>
      {showWhatIsMeshVerifyModal && (
        <WhatIsMeshVerificationModal
          isVisible
          toggleModal={() => setShowWhatIsMeshVerifyModal((prev) => !prev)}
          navigation={navigation}
          enableMeshVerification={() => setMeshVerifyRequired(verifyOptions.yes)}
        />
      )}
    </KeyboardPaddingView>
  );
};

CreateCommunityApplication.propTypes = {
  navigation: commonPropTypes.navigation().isRequired,
  route: commonPropTypes.route().isRequired,
  screenProps: PropTypes.shape({
    theme: commonPropTypes.theme(),
  }).isRequired,
};

/*
  renderMeshVerificationSection = () => {
    const { meshVerifyRequired, verifyOptions, showMeshVerifySelector, showWhatIsMeshVerifyModal } = this.state;
    const { navigation } = this.props;
    return (
      <View>
        <View style={{ marginVertical: 10 }}>
          <Text style={{ fontSize: Typography.smallFontSize, fontWeight: `500`, color: Colors.gray, marginBottom: 10 }}>
            Should all members be Mesh Verified?
          </Text>
          <TouchableOpacity activeOpacity={0.8} onPress={this.toggleMeshVerifyModalSelector}>
            <View style={localStyles.selector}>
              <Text>{meshVerifyRequired === false ? `No` : `Yes`}</Text>
              <MeshIcon name="chevron-down" size={20} />
            </View>
          </TouchableOpacity>
        </View>
        <View style={localStyles.infoBoxContainer}>
          <Text style={localStyles.infoBoxText}>
            <Text style={localStyles.infoBoxText}>
              Protect your community and require applicants to be Mesh Verified. Applicants easily verify their identity without
              compromising their privacy.{` `}
            </Text>
            <Text style={localStyles.infoBoxLearnMore} onPress={this.toggleWhatIsMeshVerifyModal}>
              Learn more.
            </Text>
          </Text>
        </View>

        <MenuModal isVisible={showMeshVerifySelector} toggleModal={this.toggleMeshVerifyModalSelector}>
          <View style={{ flexDirection: `row`, justifyContent: `space-between`, alignItems: `center` }}>
            <View style={{ flex: 0.3 }} />
            <Text style={{ fontSize: 15, fontWeight: `500`, color: Colors.textBlack }}>Mesh Verification required</Text>
            <ThemedButton clear title="Done" onPress={this.toggleMeshVerifyModalSelector} />
          </View>
          <View style={{ marginBottom: 40 }}>
            {verifyOptions.map((option) => {
              return (
                <View key={option.id.toString()}>
                  <Divider />
                  <TouchableOpacity
                    onPress={() => {
                      this.setMeshVerifyRequired(option);
                    }}
                  >
                    <View style={{ padding: 15, paddingVertical: 20 }}>
                      <Text style={localStyles.optionBodyText}>{option.body}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>
        </MenuModal>

        {showWhatIsMeshVerifyModal && (
          <WhatIsMeshVerificationModal
            isVisible={showWhatIsMeshVerifyModal}
            toggleModal={this.toggleWhatIsMeshVerifyModal}
            navigation={navigation}
            enableMeshVerification={() => this.setState({ meshVerifyRequired: { id: 0, body: `Yes` } })}
          />
        )}
      </View>
    );
  };
*/

//#region constants and styles
const localStyles = {
  headerTextModal: Typography.text(`header`),
  buttonContainer: {
    flex: 0.1,
    flexDirection: `row`,
    alignSelf: `flex-end`,
    marginTop: 10,
  },
  questionInputStyle: {
    flex: 1,
    fontSize: Typography.baseFontSize,
    borderColor: Colors.lightGray,
    borderWidth: 1,
    marginVertical: 10,
    padding: 10,
    borderRadius: 4,
    textAlign: `left`,
    textAlignVertical: `top`,
    height: 60,
    marginHorizontal: 10,
  },
  dropDownModalContainer: {
    alignSelf: `flex-start`,
    borderRadius: 4,
    borderColor: Colors.lightGray,
    borderWidth: 1,
    paddingHorizontal: 1,
    marginTop: 10,
  },
  scrollViewContainer: {
    flex: 1,
    // margin: 10,
    backgroundColor: `transparent`,
  },
  headerText: {
    fontSize: 22,
    fontWeight: `600`,
    color: Colors.textBlack,
    marginBottom: 10,
  },
  titleContainer: {
    flexDirection: `row`,
    marginHorizontal: 10,
    justifyContent: `space-between`,
  },
  titleText: {
    fontSize: Typography.smallFontSize,
    fontWeight: `500`,
    textAlign: `center`,
    color: Colors.gray,
  },
  selector: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    padding: 6,
    borderRadius: 4,
  },
  questionContainer: {
    flex: 1,
    justifyContent: `center`,
  },
  rowContainer: {
    flex: 1,
    margin: 10,
    flexDirection: `row`,
    alignItems: `center`,
  },
  minusButtonContainer: {
    alignSelf: `flex-start`,
    marginTop: 10,
  },
  dropDownStyle: {
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    marginVertical: 10,
    marginHorizontal: 5,
  },
  dropDownTextStyle: {
    fontSize: Typography.baseFontSize,
    fontWeight: `600`,
    color: Colors.textBlack,
  },
  inputStyle: {
    flex: 1,
    borderColor: Colors.lightGray,
    borderWidth: 1,
    padding: 4,
    borderRadius: 4,
    margin: 10,
    fontSize: Typography.baseFontSize,
  },
  infoBoxContainer: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: Colors.lightGray,
    marginBottom: 20,
  },
  infoBoxText: {
    fontSize: Typography.smallFontSize,
    color: Colors.darkGray,
    lineHeight: 20,
    fontWeight: `300`,
  },
  infoBoxLearnMore: {
    color: Colors.brandPurple,
    fontWeight: `500`,
  },
  optionBodyText: {
    fontSize: Typography.baseFontSize,
    color: Colors.textBlack,
    fontWeight: `bold`,
    paddingHorizontal: 10,
  },
};

//#endregion
