import { Alert, Image, ListRenderItem, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { ReactNativeFile } from 'apollo-upload-client';
import { useDebouncedCallback } from 'use-debounce/lib';
import { unionBy } from 'lodash';
import { Colors, Typography } from '../common-styles';
import Input from '../common-ui/inputs/Input';
import { Avatar, KeyboardPaddingView, ListItem, MeshIcon, SafeAreaView, ThemedButton } from '../common-ui';
// import useMediaPermissons from '../hooks/useMediaPermissions';
import { pickImages } from '../common-util/FilePicker';
import { CREATE_AD, GET_ADVERTISERS, GET_ADS, CREATE_CAMPAIGN, GET_GROUPS_BY_NAME, CREATE_ADVERTISER } from '../graphql';
import { Select } from '../common-ui/Select';
import { Ad, GroupCoreFields, GetGroupByName } from '../common-types/types';
import { AppContext } from '../../AppContext';

type Advertiser = {
  id: string;
  name: string;
  handle: string;
  avatar_url: string;
};

type AdQuery = {
  getAvailableAds: Ad[];
};

export const AdTool = () => {
  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView style={styles.container} alwaysBounceVertical={false} keyboardShouldPersistTaps="handled">
          {/* Ad Content*/}
          <AdvertiserForm />
          {/* Ad Content*/}
          <AdForm />
          {/* Ad Campaign*/}
          <CampaignForm />
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

const AdvertiserForm: React.FC = () => {
  const [advertiserName, setAdvertiserName] = useState<string>(``);
  const [advertiserHandle, setAdvertiserHandle] = useState<string>(``);
  const [imageToUpload, setImageToUpload] = useState<ReactNativeFile>();
  const [_submitAdvertiser] = useMutation(CREATE_ADVERTISER);

  const pickImage = () => {
    //const isGranted = await checkPermission();
    //if (!isGranted) return;

    pickImages(({ assets, didCancel, errorCode, errorMessage }) => {
      if (didCancel) return;
      if (errorCode || errorMessage) {
        // eslint-disable-next-line no-alert
        Alert.alert(`Error`, errorMessage);
        return;
      }
      const [asset] = assets || [];
      if (asset) {
        const uploadFile = new ReactNativeFile({
          uri: asset?.uri || ``,
          type: asset.type,
          name: uuidv4(),
        });
        setImageToUpload(uploadFile);
      }
    });
  };

  const submitAdvertiser = async () => {
    if (!advertiserName?.length || !advertiserHandle?.length) return;
    const input = {
      advertiser_name: advertiserName,
      advertiser_handle: advertiserHandle,
    };
    const files = [imageToUpload];
    await _submitAdvertiser({
      variables: { input, files },
      refetchQueries: [{ query: GET_ADVERTISERS }],
    });
  };

  return (
    <View>
      <FormInput title="Advertiser name">
        <Input
          autoFocus
          value={advertiserName}
          onChangeText={setAdvertiserName}
          style={styles.inputStyle}
          textAlignVertical="center"
        />
      </FormInput>
      <FormInput title="Advertiser handle">
        <Input
          autoFocus
          value={advertiserHandle}
          onChangeText={setAdvertiserHandle}
          style={styles.inputStyle}
          textAlignVertical="center"
        />
      </FormInput>
      <FormInput title="Advertiser Image">
        <View style={styles.imageInput}>
          <TouchableOpacity onPress={pickImage}>
            <MeshIcon name="image" size={24} color={Colors.iconColor} style={styles.iconMargins} />
          </TouchableOpacity>
          {imageToUpload?.uri ? <Image source={{ uri: imageToUpload?.uri }} style={styles.image} /> : null}
        </View>
      </FormInput>
      <ThemedButton rounded title="Submit Advertiser" buttonStyle={styles.submit} onPress={submitAdvertiser} />
    </View>
  );
};

const AdForm: React.FC = () => {
  //const { checkPermission } = useMediaPermissons();
  const [advertiser, setAdvertiser] = useState<Advertiser>();
  const [content, setContent] = useState<string>(`Test Ad`);
  const [ad_name, setAdName] = useState<string>(``);
  const [modal, setModal] = useState<string>();
  const [btnLabel, setBtnLabel] = useState<string>(`Test`);
  const [imageToUpload, setImageToUpload] = useState<ReactNativeFile>();
  const [link, setLink] = useState<string>(`http://www.google.com`);

  const { data: advertiserData } = useQuery(GET_ADVERTISERS);
  const [_submitAd] = useMutation(CREATE_AD);

  const pickImage = () => {
    //const isGranted = await checkPermission();
    //if (!isGranted) return;

    pickImages(({ assets, didCancel, errorCode, errorMessage }) => {
      if (didCancel) return;
      if (errorCode || errorMessage) {
        // eslint-disable-next-line no-alert
        Alert.alert(`Error`, errorMessage);
        return;
      }
      const [asset] = assets || [];
      if (asset) {
        const uploadFile = new ReactNativeFile({
          uri: asset?.uri || ``,
          type: asset.type,
          name: uuidv4(),
        });
        setImageToUpload(uploadFile);
      }
    });
  };

  const submitAd = async () => {
    const input = {
      advertiser_id: advertiser?.id,
      content,
      ad_name,
      button: btnLabel,
      link_url: link,
    };
    const files = [imageToUpload];
    await _submitAd({
      variables: { input, files },
      refetchQueries: [{ query: GET_ADS }],
    });
  };

  const renderAdvertiserOptions: ListRenderItem<Advertiser> = ({ item }) => {
    return (
      <View style={{ flex: 1, flexDirection: `row`, alignItems: `center`, margin: 12 }}>
        <Avatar size={32} user={item} />
        <Text style={{ marginLeft: 12 }}>{item.name}</Text>
      </View>
    );
  };

  return (
    <View>
      <Text style={styles.title}>Ad Content</Text>
      <FormInput title="Advertiser">
        <ListItem title={advertiser?.handle || ``} containerStyle={styles.imageInput} onPress={() => setModal(`Advertiser`)} />
      </FormInput>
      <FormInput title="Ad name">
        <Input autoFocus value={ad_name} onChangeText={setAdName} style={styles.inputStyle} textAlignVertical="center" />
      </FormInput>
      <FormInput title="Image">
        <View style={styles.imageInput}>
          <TouchableOpacity onPress={pickImage}>
            <MeshIcon name="image" size={24} color={Colors.iconColor} style={styles.iconMargins} />
          </TouchableOpacity>
          {imageToUpload?.uri ? <Image source={{ uri: imageToUpload?.uri }} style={styles.image} /> : null}
        </View>
      </FormInput>
      <FormInput title="Text content">
        <Input autoFocus value={content} onChangeText={setContent} style={styles.inputStyle} textAlignVertical="center" />
      </FormInput>
      <FormInput title="Button label">
        <Input autoFocus value={btnLabel} onChangeText={setBtnLabel} style={styles.inputStyle} textAlignVertical="center" />
      </FormInput>
      <FormInput title="Link">
        <Input
          autoFocus
          autoCapitalize="none"
          value={link}
          onChangeText={setLink}
          style={styles.inputStyle}
          textAlignVertical="center"
        />
      </FormInput>
      <ThemedButton rounded title="Submit Ad" buttonStyle={styles.submit} titleStyle={{}} onPress={submitAd} />
      <Select<Advertiser>
        title={modal}
        options={advertiserData?.getAdvertisers || []}
        visible={modal === `Advertiser`}
        renderOption={renderAdvertiserOptions}
        onCancel={() => setModal(undefined)}
        onSave={(selected) => setAdvertiser(selected[0])}
      />
    </View>
  );
};

const CampaignForm: React.FC = () => {
  const [ad, setAd] = useState<Ad>();
  const { user } = useContext(AppContext);
  const [community, setCommunity] = useState<GroupCoreFields>();
  const [search, setSearch] = useState<string>();
  const [modal, setModal] = useState<string>();
  const [range, setRange] = useState<string>(`1`);

  const { data: adData, fetchMore } = useQuery<AdQuery>(GET_ADS);
  const [_submitCampaign] = useMutation(CREATE_CAMPAIGN);
  const [searchCommunity, { data: groupData }] = useLazyQuery<GetGroupByName>(GET_GROUPS_BY_NAME);

  const submitCampaign = async () => {
    const now = new Date();
    const end = now.getTime() + (+range || 1) * 24 * 60 * 60 * 1000; // adds 1 Day

    const input = {
      ad_id: ad?.id,
      group_id: [community?.id],
      range: {
        start: now,
        end: new Date(end),
      },
    };
    await _submitCampaign({
      variables: { input },
    });
    Alert.alert(`Sumbit content`, JSON.stringify(input, null, 2));
  };

  const renderAdOptions: ListRenderItem<Ad> = ({ item }) => {
    const { copy = ``, image_urls } = item;
    const [image_uri] = image_urls || [];
    return (
      <View style={{ flex: 1, flexDirection: `row`, margin: 12 }}>
        {image_uri ? <Image source={{ uri: image_uri }} style={{ width: 60, height: 60 }} /> : null}
        <Text style={{ marginLeft: 12, maxWidth: 300 }} ellipsizeMode="tail">
          {copy.substring(0, 100)}
          {copy.length > 100 ? `...` : ``}
        </Text>
      </View>
    );
  };

  const renderCommunityOptions: ListRenderItem<GroupCoreFields> = ({ item }) => {
    return (
      <View style={{ flex: 1, flexDirection: `row`, alignItems: `center`, margin: 12 }}>
        <Avatar size={32} localImage={item.avatar_url} />
        <Text style={{ marginLeft: 12 }}>{item.name}</Text>
      </View>
    );
  };

  const onTextChanged = (text: string) => {
    setSearch(text);
    if ((text.length || 0) > 2) searchBy(text);
  };

  // Must be debounced to prevent refetching on every typing ~500ms
  const searchBy = useDebouncedCallback((group_name: string) => {
    searchCommunity({
      variables: { group_name, user_id: user?.id, limit: 15, offset: 0 },
    });
  }, 500);

  const fetchMoreAds = async () => {
    await fetchMore({
      variables: {
        offset: adData?.getAvailableAds?.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          console.log(`same as before, return prev..`);
          return prev;
        }
        const prevAds = prev?.getAvailableAds;
        const moreAds = fetchMoreResult?.getAvailableAds;
        const newAds = unionBy(prevAds, moreAds, (a: any) => a.id);
        return {
          getAvailableAds: newAds,
        };
      },
    });
  };

  return (
    <View>
      <Text style={styles.title}>Ad Campaign</Text>
      <FormInput title="Ad">
        <ListItem title={ad?.copy || ``} containerStyle={styles.imageInput} onPress={() => setModal(`Ad`)} />
      </FormInput>
      <FormInput title="Date range (days)">
        <Input
          autoFocus
          value={range}
          keyboardType="numeric"
          placeholder="1"
          onChangeText={setRange}
          style={styles.inputStyle}
          textAlignVertical="center"
        />
      </FormInput>
      <FormInput title="Target community">
        <ListItem title={community?.name || ``} containerStyle={styles.imageInput} onPress={() => setModal(`Community`)} />
      </FormInput>
      <ThemedButton rounded title="Submit Campaign" buttonStyle={styles.submit} titleStyle={{}} onPress={submitCampaign} />
      <Select<Ad>
        title={modal}
        options={adData?.getAvailableAds || []}
        visible={modal === `Ad`}
        renderOption={renderAdOptions}
        onCancel={() => setModal(undefined)}
        onSave={(selected) => setAd(selected[0])}
        onEndReached={fetchMoreAds}
      />
      <Select<GroupCoreFields>
        title={modal}
        ListHeaderComponent={<SearchHeader value={search} setValue={onTextChanged} />}
        options={groupData?.getGroupsByName?.map((g) => g.group) || []}
        visible={modal === `Community`}
        renderOption={renderCommunityOptions}
        onCancel={() => setModal(undefined)}
        onSave={(selected) => setCommunity(selected[0])}
      />
    </View>
  );
};
type SearchHeaderProps = {
  value?: string;
  setValue: (s: string) => void;
};

const SearchHeader: React.FC<SearchHeaderProps> = ({ value, setValue }) => {
  return (
    <View style={{ flex: 1, padding: 12 }}>
      <Text style={{ marginBottom: 12 }}>Community to display Ad</Text>
      <TextInput
        onSubmitEditing={(e) => setValue(e.nativeEvent.text)}
        placeholder="Search communities"
        style={styles.imageInput}
        testID="SEARCH_BAR"
        onChangeText={(text) => setValue(text)}
        value={value}
      />
    </View>
  );
};

const FormInput: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <View style={styles.block}>
      <Text style={styles.label}>{title}</Text>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 8,
    backgroundColor: Colors.white,
  },
  iconMargins: {
    marginLeft: 8,
    marginVertical: 12,
    marginRight: 11,
  },
  block: {
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    fontSize: 24,
    fontFamily: `inter-semibold`,
    marginLeft: 8,
    marginTop: 32,
  },
  label: { ...Typography.text(`plusone`, `gray`), marginBottom: 6 },
  inputStyle: {
    minHeight: 40,
  },
  imageInput: {
    flexDirection: `row-reverse`,
    borderRadius: 4,
    borderColor: Colors.mediumGray,
    borderWidth: 1,
    minHeight: 40,
  },
  image: {
    flex: 1,
    resizeMode: `contain`,
    minHeight: 200,
    alignItems: `center`,
    justifyContent: `center`,
  },
  submit: { paddingVertical: 12, paddingHorizontal: 24, margin: 16 },
});
