import { ApolloProvider as ApolloHooksProvider, useLazyQuery } from '@apollo/react-hooks';
import { ApolloProvider } from 'react-apollo';
import { Platform, StatusBar, View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';
import React, { useContext, useEffect } from 'react';
import { NavigationContainer, NavigationState } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { withPendo } from 'rn-pendo-sdk';
import analytics from '@react-native-firebase/analytics';
import { appVersionIsAtLeast } from './environment';
import { Colors } from './packages/common-styles';
import { SafeAreaProvider } from './packages/common-ui';
import { ContributionProvider } from './packages/contribution/context/ContributionContext';
import { CHECK_AGREED_TERMS, client } from './packages/graphql';
import { PushNotificationRegistry } from './packages/notification/PushNotificationRegistry';
import { AppNavigator } from './packages/navigation/AppNavigator';
import { AuthNavigator } from './packages/navigation/AuthNavigator';
import { PersonaNavigator } from './packages/navigation/PersonaNavigator';
import { AppContext } from './AppContext';
import { linking } from './packages/navigation/meshLinking';
import { ThemeContext } from './packages/themes/ThemeProvider';
import { useMeshSubscription } from './packages/subscriptions/useMeshSubscription';
import { useBadgeCounter } from './packages/notification/helpers';
import { PendoComponent } from './packages/pendo/PendoComponent';
import { GuestStackNavigator } from './packages/navigation/GuestNavigator';
import { AuthNavigationContainer, MainScreensParamList } from './packages/common-types/navigation-types';
import { useActiveCommunity } from './packages/community/hooks/useActiveCommunity';
import { GUEST_ACCOUNT } from './constants';

type AppChildProps = {
  firstScreen?: string;
  onStateChange?: (state?: Readonly<NavigationState>) => void; // undefined on dev
  onReady?: (state?: NavigationState) => void; // undefined on dev
};

console.log(`=== AppChild NATIVE ====`);
const AuthStack = createStackNavigator<AuthNavigationContainer>();
const AppStack = createStackNavigator<MainScreensParamList>();

const _AppChild: React.FC<AppChildProps> = ({ firstScreen, onStateChange = () => null, onReady = () => null }) => {
  const navigationRef = React.useRef<any>(null);
  const routerRef = React.useRef<string>();
  const { fetchLatestCommunities } = useActiveCommunity(`AppChild`);
  const { user, identity } = useContext(AppContext);
  const { setThemeID } = useContext(ThemeContext);
  useMeshSubscription();
  useBadgeCounter();
  const [checkAgreedTerms, { data }] = useLazyQuery(CHECK_AGREED_TERMS, { client });

  useEffect(() => {
    setThemeID(`MESH BLURPLE`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (identity?.id) checkAgreedTerms({ variables: { identity_id: identity?.id } });
  }, [checkAgreedTerms, identity?.id]);

  useEffect(() => {
    // prefetch carousel data
    if (user?.pending_completition) return;
    if (user?.id) fetchLatestCommunities(user.id);
    // Reason: only run on user login, never when active community updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.pending_completition]);

  useEffect(() => {
    if (data?.agreed_terms === false && !!identity?.id) {
      console.log(`push to terms screen`);
      navigationRef?.current?.navigate(`Auth`, {
        screen: `TermsScreen`,
        params: {
          identity_id: identity.id,
          prev_screen: `AppChild`,
        },
      });
    }
  }, [identity?.id, data, firstScreen]);

  const onNavigationReady = React.useCallback(
    (state?: NavigationState) => {
      if (state) onReady(state);
      routerRef.current = navigationRef?.current?.getCurrentRoute()?.name;
    },
    [onReady],
  );
  const onNavigationStateChanged = React.useCallback(
    async (state?: NavigationState) => {
      if (state) onStateChange(state);
      const previousRouteName = routerRef.current;
      const currentRouteName: string | undefined = navigationRef?.current?.getCurrentRoute()?.name || routerRef.current;
      routerRef.current = currentRouteName;

      if (previousRouteName !== currentRouteName) {
        await analytics().logScreenView({
          screen_name: currentRouteName,
          screen_class: currentRouteName,
        });
      }
    },
    [onStateChange],
  );

  const isGuestAccount = user?.id === GUEST_ACCOUNT;

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <ContributionProvider>
          <SafeAreaProvider>
            <View
              style={{
                flex: 1,
                backgroundColor: `#fff`,
                paddingTop: Platform.select({ android: appVersionIsAtLeast(`1.0.13`) ? 0 : StatusBar.currentHeight }),
                paddingBottom: Platform.select({ android: appVersionIsAtLeast(`1.0.12`) ? 0 : 48 }),
              }}
            >
              <PendoComponent />
              <PushNotificationRegistry />
              <StatusBar barStyle="dark-content" backgroundColor={Colors.white} />
              <NavigationContainer
                ref={navigationRef}
                linking={linking}
                onStateChange={onNavigationStateChanged}
                onReady={onNavigationReady}
              >
                {user && !isGuestAccount && !user.pending_completition ? (
                  <AppStack.Navigator screenOptions={{ headerShown: false }}>
                    <AppStack.Screen name="Main" component={AppNavigator} />
                  </AppStack.Navigator>
                ) : (
                  <AuthStack.Navigator screenOptions={{ headerShown: false }}>
                    <AuthStack.Screen name="Auth" component={AuthNavigator} />
                    <AuthStack.Screen name="Persona" component={PersonaNavigator} />
                    <AuthStack.Screen name="Guest" component={GuestStackNavigator} />
                  </AuthStack.Navigator>
                )}
              </NavigationContainer>
              <FlashMessage position="top" animationDuration={750} />
            </View>
          </SafeAreaProvider>
        </ContributionProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export const AppChild = withPendo(gestureHandlerRootHOC(_AppChild));
