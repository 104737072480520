import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import * as Sentry from 'sentry-expo';
import { Text, SafeAreaView, ScrollView, StyleSheet, View, TextInput } from 'react-native';
import { Colors, Typography } from '../common-styles';
import { KeyboardPaddingView, getRandomBlob, ThemedButton } from '../common-ui';
import { UPDATE_REFERRAL } from '../graphql';
import { ReferralsTestIds } from '../profile-create/testLabels';

const Blob = getRandomBlob();

const InvalidReferralWarning = ({ warningMessage }) => {
  if (warningMessage.length === 0) return null;

  return (
    <View testID={ReferralsTestIds.warning} style={styles.warning}>
      <Text style={styles.warningText}>{warningMessage}</Text>
    </View>
  );
};

InvalidReferralWarning.propTypes = {
  warningMessage: PropTypes.string.isRequired,
};

const ReferralScreen = (props) => {
  const [referralName, setReferralName] = useState(``);
  const [loading, setLoading] = useState(false);
  const [updateReferral] = useMutation(UPDATE_REFERRAL);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warningMessage, setWarningMessage] = useState(``);

  const onChangeReferral = (value) => {
    setReferralName(value);
    if (referralName.length > 0 && warningMessage.length > 0) {
      setWarningMessage(``);
    }
  };

  const handleContinue = async () => {
    if (referralName.length === 0) return;
    try {
      setLoading(true);
      const result = await updateReferral({ variables: { input: { handle: referralName } } });
      if (result.data?.updateReferral?.id) {
        setIsSubmitting(true);
        const { wizardNext, wizardSetState } = props;
        wizardSetState(6, { referralName });
        wizardNext();
      } else {
        setWarningMessage(`Sorry, this referral code is invalid: ${referralName}`);
      }
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtra(`where`, `ProfileInterests.goToNextWizardStep`);
        scope.setExtra(`err`, error);
        Sentry.captureException(error);
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = () => {
    const { wizardSetState, wizardNext } = props;
    setIsSubmitting(true);
    wizardSetState(6);
    wizardNext();
  };

  return (
    <KeyboardPaddingView>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          testID={ReferralsTestIds.screen}
          alwaysBounceVertical={false}
          style={styles.root}
        >
          <View style={styles.container}>
            <Blob width={25} height={25} style={{ position: `absolute`, left: 8, top: 45, zIndex: -1 }} />
            <Text style={styles.headerText}>Add a referral code</Text>
            <TextInput
              autoFocus
              blurOnSubmit
              disabled={isSubmitting}
              value={referralName}
              onChangeText={onChangeReferral}
              returnKeyType="done"
              placeholder="Write the referral code"
              placeholderTextColor={Colors.textPlaceholder}
              style={styles.textInput}
              clearButtonMode="always"
              autoCorrect={false}
              autoCapitalize="none"
              testID={ReferralsTestIds.input}
            />
            <InvalidReferralWarning warningMessage={warningMessage} />
            <ThemedButton
              rounded
              disabled={loading || isSubmitting}
              title="Continue"
              onPress={handleContinue}
              buttonStyle={{ padding: 15 }}
              testID={ReferralsTestIds.continue}
              titleStyle={{ ...Typography.text(`plustwo`, `bold`, `white`) }}
            />
            <ThemedButton
              rounded
              clear
              testID={ReferralsTestIds.skip}
              disabled={loading || isSubmitting}
              title="Skip for now"
              containerStyle={{ marginHorizontal: 16, marginTop: 24 }}
              onPress={handleSkip}
              titleStyle={{ ...Typography.text(`base`, `theme`, `bold`) }}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    </KeyboardPaddingView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: Colors.white,
    width: `100%`,
  },
  container: {
    flex: 1,
    position: `relative`,
    paddingTop: 47,
    paddingHorizontal: 16,
  },
  headerText: {
    ...Typography.text(`bold`, `large`, `black`),
    paddingBottom: 21,
  },
  warning: {
    backgroundColor: Colors.warningBoxBackground,
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginBottom: 16,
    borderRadius: 6,
  },
  warningText: {
    ...Typography.text(`base`, `theme`, `bold`),
    color: Colors.warningText,
  },
  textInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
    borderRadius: 4,
    marginBottom: 16,
  },
});

ReferralScreen.propTypes = {
  wizardSetState: PropTypes.func.isRequired,
  wizardNext: PropTypes.func.isRequired,
};

export { ReferralScreen };
