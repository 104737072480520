import { Switch, View } from 'react-native';
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Colors } from '../common-styles';
import { ListItem } from '../common-ui';
import { ThemeSelector } from '../themes/ThemeSelector';
import { getListItemProps, retrieveMainCategoryName } from './helpers';

import { GuidelineSettingItem } from './EditCodeOfConduct';
import { PronounSettingItem } from './PronounSettingEditor';
import { HandleSettingItem } from './HandleEditor';
import { BankAccountSettingItem } from './BankAccount';
import { EmailSettingItem } from './EmailEditor';
import { TextSettingItem } from './TextEditor';
import { BirthdaySettingItem } from './BirthdayEditor';
import { AppContext } from '../../AppContext';
import { PhoneNumberSettingItem } from './PhoneNumberEditor';
import { ReactionSettingItem } from './EditReactions';
import { TopicSettingItem } from './EditTopics';
import { CHECK_PENDING_MESH_IDENTITY } from '../graphql';
import { ListItemProps, SettingItem, GroupCoreFields, GroupMember, Setting } from '../common-types/types';
import { SettingScreensParamList } from '../common-types/navigation-types';
import { AffiliatedCommunitiesSettingItem } from './AffiliatedCommunities';
import { IntroVideoSettingItem } from './IntroVideoSetting';

interface SettingRenderProps {
  setting: Setting;
  listItemProps: ListItemProps;
  setSelectedSetting: () => void;
  saveSetting: () => void;
  group?: GroupCoreFields;
  group_member?: GroupMember;
  openCodeOfConduct?: boolean;
  verified?: boolean;
}
interface OptionSettingItemProps extends SettingItem {
  setSelectedSetting: (setting: Setting) => void;
}
interface BooleanSettingItemProps extends SettingItem {
  saveSetting: (key: string, val: number) => void;
}
interface HumanSettingItemProps {
  listItemProps: ListItemProps;
}

type HumanSettingNavigation = StackNavigationProp<SettingScreensParamList, `VerificationSelfieUpload`>;

const OptionSettingItem: React.FC<OptionSettingItemProps> = ({ setting, listItemProps, setSelectedSetting }) => {
  const { title, titleStyle, subtitleStyle, rightElement } = listItemProps;
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle={retrieveMainCategoryName(setting)}
      subtitleStyle={subtitleStyle}
      rightElement={rightElement}
      onPress={() => setSelectedSetting(setting)}
    />
  );
};

const BooleanSettingItem: React.FC<BooleanSettingItemProps> = ({ setting, saveSetting, listItemProps }) => {
  const [value, setValue] = useState<number>(parseInt(`${setting.value}`, 10));
  const { title, titleStyle, subtitleStyle, subtitle } = listItemProps;
  const onValueChange = async (val: boolean) => {
    setValue(val ? 1 : 0);
    await saveSetting(setting.key, val ? 1 : 0);
  };
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle={subtitle}
      subtitleStyle={subtitleStyle}
      rightElement={<Switch value={!!value} onValueChange={onValueChange} />}
    />
  );
};

const HumanSettingItem: React.FC<HumanSettingItemProps> = ({ listItemProps }) => {
  const navigation = useNavigation<HumanSettingNavigation>();
  const { user } = React.useContext(AppContext);
  const { title, titleStyle, rightElement, subtitleStyle } = listItemProps;

  const { data } = useQuery(CHECK_PENDING_MESH_IDENTITY, { fetchPolicy: `cache-and-network` });
  const navigateToSelfieVerification = () => navigation.navigate(`VerificationSelfieUpload`);

  const subtitleMessage = () => {
    let message;
    if (user?.identity_verified) message = `Verified`;
    else if (data?.checkPendingIdentityVerification?.pending) message = `Verification pending`;
    else message = ``;
    return message;
  };

  let onPress = () => {};
  let rightElementProp;
  if (!user?.identity_verified && !data?.checkPendingIdentityVerification?.pending) {
    onPress = navigateToSelfieVerification;
    rightElementProp = rightElement;
  }
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      onPress={onPress}
      rightElement={rightElementProp}
      subtitle={subtitleMessage()}
      subtitleStyle={subtitleStyle}
      verified={user?.identity_verified}
    />
  );
};

//#endregion

// value must be a prop so that setting re-renders when it changes
export const SettingRender: React.FC<SettingRenderProps> = React.memo(
  // eslint-disable-next-line complexity
  ({ setting, setSelectedSetting, saveSetting, openCodeOfConduct, group, group_member, verified }) => {
    const { role_name } = group_member || {};
    const listItemProps = getListItemProps(setting);
    const skip = [`user.setting.message_request`, `user.setting.follow_enabled`, `user.setting.follow`];
    if (!setting?.visibility || skip.includes(setting.key)) return null;
    let Comp;
    if (setting.type === `option`) {
      Comp = OptionSettingItem;
    } else if (setting.type === `boolean`) {
      Comp = BooleanSettingItem;
    } else if (setting.type === `guidelines`) {
      Comp = GuidelineSettingItem;
    } else if (setting.type === `color_theme`) {
      Comp = ThemeSelector;
    } else if (setting.type === `handle`) {
      Comp = HandleSettingItem;
    } else if (setting.type === `pronouns`) {
      Comp = PronounSettingItem;
    } else if (setting.type === `email` || listItemProps.title === `Email`) {
      if (role_name === `manager`) return null; // We don't want to show the community email setting to the CL, this is just for the community owner
      Comp = EmailSettingItem;
    } else if (setting.type === `affiliated_communities`) {
      Comp = AffiliatedCommunitiesSettingItem;
    } else if (setting.type === `intro_video`) {
      Comp = IntroVideoSettingItem;
    } else if (setting.type === `bank_account`) {
      if (role_name === `manager`) return null; // We don't want to show the bank account setting to the CL, this is just for the community owner
      Comp = BankAccountSettingItem;
    } else if (setting.type === `date`) {
      Comp = BirthdaySettingItem;
    } else if (setting.type === `human`) {
      Comp = HumanSettingItem;
    } else if (setting.type === `phone_number`) {
      Comp = PhoneNumberSettingItem;
    } else if (setting.type === `reactions`) {
      Comp = ReactionSettingItem;
    } else if (setting.type === `topics`) {
      Comp = TopicSettingItem;
    } else {
      Comp = TextSettingItem;
    }
    return (
      <View
        key={setting.key}
        style={{
          borderBottomColor: Colors.headerBottomBorderColor,
          borderBottomWidth: 1,
        }}
      >
        <Comp
          listItemProps={listItemProps}
          setting={setting}
          setSelectedSetting={setSelectedSetting}
          saveSetting={saveSetting}
          openCodeOfConduct={openCodeOfConduct}
          group={group}
          group_member={group_member}
          verified={verified}
        />
      </View>
    );
  },
);
