import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { View, Text, StyleSheet, SectionList } from 'react-native';
import { useMutation, useQuery } from 'react-apollo';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { CenterColumn, Divider, ListItem, MeshIcon, ThemedButton } from '../common-ui';
import { Colors, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import {
  refetchQueriesFor,
  LIST_GROUPS,
  GET_AFFILIATED_COMMUNITIES,
  ADD_AFFILIATED_GROUP,
  REMOVE_AFFILIATED_GROUP,
} from '../graphql';
import { AffiliatedCommunitiesResp, GroupAndGroupMember, SettingItem } from '../common-types/types';
import { SettingScreensParamList } from '../common-types/navigation-types';
import { LoadingIndicator } from '../common-ui/LoadingIndicator';

type AffiliatedCommunitiesSettingRoute = RouteProp<SettingScreensParamList, `AffiliatedCommunities`>;
type AffiliatedCommunitiesSettingNavigation = StackNavigationProp<SettingScreensParamList, `AffiliatedCommunities`>;

export const AffiliatedCommunitiesSettingItem: React.FC<SettingItem> = ({ setting }) => {
  const navigation = useNavigation<AffiliatedCommunitiesSettingNavigation>();
  const { user } = React.useContext(AppContext);

  const { data: affiliatedGroups } = useQuery<AffiliatedCommunitiesResp>(GET_AFFILIATED_COMMUNITIES, {
    fetchPolicy: `cache-and-network`,
    variables: { origin_group_id: setting?.group_id },
  });

  const navigateToEditor = () => {
    if (!user) return;
    if (affiliatedGroups?.getAffiliatedGroups2.data)
      navigation.navigate(`AffiliatedCommunities`, {
        affiliatedGroupsParam: affiliatedGroups?.getAffiliatedGroups2.data,
        setting,
      });
  };
  return (
    <ListItem
      title="Manage Affiliates"
      titleStyle={{ ...Typography.text(`gray`, `small`) }}
      rightElement={<MeshIcon focused={false} name="chevron-right" size={20} testID="ICON" />}
      onPress={navigateToEditor}
    />
  );
};

export const AffiliatedCommunities = () => {
  const navigation = useNavigation<AffiliatedCommunitiesSettingNavigation>();
  const route = useRoute<AffiliatedCommunitiesSettingRoute>();
  const { affiliatedGroupsParam, setting } = route?.params || {};

  const [managedCommunities, setManagedCommunities] = useState<GroupAndGroupMember[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupAndGroupMember[]>(affiliatedGroupsParam || []);
  const {
    data: listedGroups,
    loading: loadingListedGroups,
    refetch,
  } = useQuery(LIST_GROUPS, {
    fetchPolicy: `cache-and-network`,
  });

  const [addAffiliatedGroup] = useMutation(ADD_AFFILIATED_GROUP, {
    onCompleted: () => refetch(),
  });

  const [removeAffiliatedGroup] = useMutation(REMOVE_AFFILIATED_GROUP, {
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    const managedGroups =
      listedGroups?.listJoinedGroups.filter(({ group, group_member }: any) => {
        if (group?.id !== setting?.group_id && (group_member?.role_name === `owner` || group_member?.role_name === `manager`)) {
          return { group, group_member };
        }
        return null;
      }) || [];
    setManagedCommunities(managedGroups);
  }, [listedGroups, setting]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <ThemedButton clear title="Save" containerStyle={{ right: 8 }} onPress={() => navigation.goBack()} />,
    });
  }, [navigation]);

  const sections = useMemo(() => {
    const sections = [{ title: `Admin`, data: managedCommunities }].filter((section) => section.data && section.data.length > 0);
    return sections;
  }, [managedCommunities]);

  const isCommunitySelected = useCallback(
    (id: String) => selectedGroups.length && selectedGroups.some((comm) => comm.group.id === id),
    [selectedGroups],
  );

  const handleSwitchCommunity = useCallback(
    async (selectedGroup) => {
      try {
        if (isCommunitySelected(selectedGroup?.group.id)) {
          // means that we have to turn off the switch and remove it from the stored affiliated communities
          const filteredArray = selectedGroups.filter(({ group }) => {
            return group?.id && group?.id !== selectedGroup?.group?.id;
          });
          setSelectedGroups(filteredArray);
          await removeAffiliatedGroup({
            variables: { origin_group_id: setting?.group_id, affiliated_group_id: selectedGroup?.group?.id },
            refetchQueries: refetchQueriesFor(`Group`, `Setting`),
          });
          return;
        }

        if (!isCommunitySelected(selectedGroup?.group?.id) && selectedGroups.length === 5) {
          console.error(`You can add up to 5 communities`);
          return;
        }

        setSelectedGroups([...selectedGroups, selectedGroup]);
        await addAffiliatedGroup({
          variables: { origin_group_id: setting?.group_id, affiliated_group_id: selectedGroup?.group?.id },
          refetchQueries: refetchQueriesFor(`Group`, `Setting`),
        });
      } catch (error) {
        console.error(`An error ocurred while trying to add an affiliated group - handleSwitchCommunity()`, error);
      }
    },
    [selectedGroups, isCommunitySelected, addAffiliatedGroup, removeAffiliatedGroup, setting?.group_id],
  );

  const ListHeaderComponent = useCallback(() => {
    return (
      <View>
        <Text style={{ marginVertical: 20, marginHorizontal: 16, ...Typography.text(`center`, `small`) }}>
          Choose up to 5 affiliated nodes to promote in your node.
        </Text>
      </View>
    );
  }, []);

  const renderSectionHeader = useCallback(({ section }) => {
    const { title } = section;
    return (
      <CenterColumn>
        <View style={{ flexDirection: `row`, backgroundColor: Colors.commentGray, paddingVertical: 3, paddingHorizontal: 16 }}>
          <Text maxFontSizeMultiplier={2} style={{ ...Typography.text(`bold`, `gray`), paddingVertical: 4 }}>
            {title}
          </Text>
        </View>
      </CenterColumn>
    );
  }, []);

  const renderItem = useCallback(
    ({ item }) => {
      if (!item) return null;
      let radioButtonValue = false;

      if (isCommunitySelected(item?.group.id)) {
        radioButtonValue = true;
      }

      return (
        <View style={styles.groupItemContainer}>
          <View style={styles.groupItemLabels}>
            <Text maxFontSizeMultiplier={2} numberOfLines={2} ellipsizeMode="tail" style={styles.groupLabel}>
              {item?.group?.name}
            </Text>
          </View>
          <MeshIcon name="select-circle" focused={radioButtonValue} onPress={() => handleSwitchCommunity(item)} />
        </View>
      );
    },
    [handleSwitchCommunity, isCommunitySelected],
  );

  const ListEmptyComponent = useCallback(() => {
    if (loadingListedGroups) return <LoadingIndicator size={28} style={{ alignItems: `center`, justifyContent: `center` }} />;
    return (
      <View style={{ alignItems: `center`, justifyContent: `center`, flex: 1 }}>
        <Text style={{ ...Typography.text(`center`, `bold`), marginTop: 20 }}>No communities to show</Text>
      </View>
    );
  }, [loadingListedGroups]);

  const keyExtractor = useCallback((item) => item.group.id, []);

  return (
    <View>
      <SectionList
        sections={sections}
        ListHeaderComponent={ListHeaderComponent}
        renderSectionHeader={renderSectionHeader}
        renderItem={renderItem}
        extraData={sections}
        SectionSeparatorComponent={Divider}
        keyExtractor={keyExtractor}
        ListEmptyComponent={ListEmptyComponent}
        showsVerticalScrollIndicator={false}
        refreshing={loadingListedGroups}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  groupItemContainer: { flexDirection: `row`, alignItems: `center`, marginVertical: 10, marginHorizontal: 16 },
  groupItemLabels: { flexDirection: `row`, alignItems: `center`, justifyContent: `space-between`, flex: 1 },
  groupLabel: { ...Typography.text(`bold`), marginLeft: 10, flex: 1 },
});
