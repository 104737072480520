import React, { useState } from 'react';
import { ScrollView, View, TouchableHighlight, StyleSheet } from 'react-native';
import { Colors } from '../../common-styles';
import { getBackgrounds, MeshIcon } from '../../common-ui';
import { IActionType, IBlob, ITexter, ITexterType } from '../components/PostContentMeme';
import MemeActionButtonContainer from './MemeActionButtonContainer';
import { MemeColorPicker } from './MemeColorPicker';
import OutsidePressHandler from 'react-native-outside-press';
import { useDispatch } from 'react-redux';
import { setMemeTexterEditorClicked } from '../../redux/postSlice';

const MemeStyleEditor: React.FC<{
  type: IActionType;
  blobIndex: number;
  focusedTexterIndex: number;
  onSetBG: (payload: IBlob) => void;
  onChangeTexter: (type: ITexterType, isFontSizeUp?: boolean, color?: string, isTextAligned?: boolean) => void;
  texter: ITexter[];
  showTexterEditor: boolean;
}> = ({ type, blobIndex, focusedTexterIndex, onSetBG, onChangeTexter, texter, showTexterEditor }) => {
  const isAligned = focusedTexterIndex > -1 && texter[focusedTexterIndex]?.aligned;
  const [showPicker, setShowPicker] = useState(false);
  const dispatch = useDispatch();

  const handleChangeTexter = (type: ITexterType, isFontSizeUp?: boolean, color?: string, isTextAligned?: boolean) => {
    dispatch(setMemeTexterEditorClicked(true));
    onChangeTexter(type, isFontSizeUp, color, isTextAligned);
  };
  return (
    <View style={{ flexDirection: `row` }}>
      {type === `background` && (
        <ScrollView horizontal showsHorizontalScrollIndicator={false} keyboardDismissMode="none">
          <View style={{ flexDirection: `row` }}>
            {getBackgrounds()
              .filter((bg) => bg !== Colors.white)
              .map((Background, index) => {
                const selected = index === blobIndex;
                const isBlob = index < 6;
                const borderColor = selected ? Colors.focusedIconColor : Colors.gray;
                const borderWidth = selected ? 2 : 1;
                const backgroundColor = isBlob ? Colors.white : (Background as string);
                const BlobBackGround = Background as any;
                return (
                  <TouchableHighlight
                    underlayColor={Colors.disabledIconColor}
                    key={index.toString() + (selected ? `-selected` : ``)}
                    hitSlop={{ top: 8, left: 8, right: 8, bottom: 8 }}
                    style={[localStyles.postStyleBtn, { borderColor, borderWidth, backgroundColor }]}
                    onPress={() => onSetBG({ blobIndex: index, isBlob, background: Background })}
                  >
                    {isBlob ? (
                      <BlobBackGround width={22} height={22} style={{ margin: 4 }} />
                    ) : (
                      <View style={[localStyles.solidColorBtn, { backgroundColor: Background as string }]} />
                    )}
                  </TouchableHighlight>
                );
              })}
          </View>
        </ScrollView>
      )}
      {type === `text` && focusedTexterIndex > -1 && showTexterEditor && (
        <OutsidePressHandler
          onOutsidePress={() => {
            dispatch(setMemeTexterEditorClicked(false));
          }}
        >
          <View style={{ flexDirection: `row` }}>
            <MemeActionButtonContainer
              selected
              onPress={() => {
                handleChangeTexter(`align`, undefined, undefined, !isAligned);
              }}
            >
              <MeshIcon name={isAligned ? 'align-center' : 'align-left'} size={22} color={Colors.deepPurple} />
            </MemeActionButtonContainer>
            <MemeActionButtonContainer onPress={() => handleChangeTexter(`size`, true, undefined, undefined)} selected>
              <MeshIcon name="plus" size={22} color={Colors.deepPurple} />
            </MemeActionButtonContainer>
            <MemeActionButtonContainer onPress={() => handleChangeTexter(`size`, false, undefined, undefined)} selected>
              <MeshIcon name="minus" size={22} color={Colors.deepPurple} />
            </MemeActionButtonContainer>
            <MemeActionButtonContainer
              onPress={() => {
                dispatch(setMemeTexterEditorClicked(true));
                setShowPicker(true);
              }}
              selected
            >
              <View
                style={{
                  borderRadius: 6,
                  backgroundColor: texter[focusedTexterIndex]?.color,
                  width: 20,
                  height: 20,
                  borderWidth: 1,
                  borderColor: Colors.gray,
                }}
              />
            </MemeActionButtonContainer>
            {showPicker && focusedTexterIndex > -1 && (
              <MemeColorPicker
                isVisible={showPicker}
                selectedColor={texter[focusedTexterIndex]?.color}
                setHide={() => setShowPicker(false)}
                onSelectColor={(color) => onChangeTexter(`color`, false, color, undefined)}
              />
            )}
          </View>
        </OutsidePressHandler>
      )}
    </View>
  );
};

export default MemeStyleEditor;

const localStyles = StyleSheet.create({
  postStyleBtn: {
    backgroundColor: Colors.white,
    borderRadius: 3,
    borderWidth: 1,
    marginVertical: 5,
    marginHorizontal: 8,
  },
  solidColorBtn: { height: 30, width: 30 },
});
