import React, { useState } from 'react';
import { View, Switch } from 'react-native';
import { useMutation, useQuery } from 'react-apollo';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { formatDate } from '../common-util';
import { Divider, ListItem } from '../common-ui';
import { Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { SETTING_QUERY, client, refetchQueriesFor, UPDATE_SETTING_MUTATION } from '../graphql';
import { Setting, SettingItem } from '../common-types/types';
import { SettingScreensParamList } from '../common-types/navigation-types';

type BirthdaySettingRoute = RouteProp<SettingScreensParamList, `BirthdayEditor`>;

type BirthdaySettingNavigation = StackNavigationProp<SettingScreensParamList, `BirthdayEditor`>;

export const BirthdaySettingItem: React.FC<SettingItem> = ({ setting, listItemProps }) => {
  const navigation = useNavigation<BirthdaySettingNavigation>();
  const { title, titleStyle, subtitleStyle, rightElement } = listItemProps;
  const { user } = React.useContext(AppContext);
  const navigateToEditor = () => {
    if (!user) return;
    navigation.navigate(`BirthdayEditor`, {
      title,
      setting,
      user,
    });
  };
  return (
    <ListItem
      title={title}
      titleStyle={titleStyle}
      subtitle={user?.dob ? formatDate(user?.dob, `MMMM dd yyyy`) : undefined}
      subtitleStyle={subtitleStyle}
      rightElement={rightElement}
      onPress={navigateToEditor}
    />
  );
};

export const BirthdayEditor = () => {
  const route = useRoute<BirthdaySettingRoute>();
  const { user, title } = route.params || {};

  const userAndGroup = {
    user_id: user?.id,
    group_id: undefined,
  };

  const { data } = useQuery(SETTING_QUERY, {
    fetchPolicy: `cache-and-network`,
    variables: {
      settingQuery: {
        query: `user`,
        ...userAndGroup,
      },
    },
  });

  const [updateSetting] = useMutation(UPDATE_SETTING_MUTATION, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries: refetchQueriesFor(`Persona`, `Setting`),
  });

  // const { saveSetting, settings } = useSettings(`user`, {
  //   user_id: user?.id,
  //   group_id: undefined,
  // });

  const showBirthdateSetting = data?.getSettings.find((s: Setting) => s.key === `user.setting.show_birthday`);

  const sliderActive = showBirthdateSetting?.value === `true`;
  const [value, setValue] = useState(sliderActive);

  const onValueChange = async (val: boolean) => {
    setValue(val);
    const input = {
      key: showBirthdateSetting?.key,
      value: val.toString(),
      id: showBirthdateSetting?.id,
      user_id: user?.id,
    };
    const { data } = await updateSetting({ variables: { input } });
    console.log(`🚀 🚀  ->  ~ onValueChange ~ data`, data);
  };

  return (
    <View style={{ marginHorizontal: 16, marginVertical: 13 }}>
      <ListItem
        title={title}
        titleStyle={{ ...Typography.text(`small`) }}
        subtitle={user?.dob ? formatDate(user?.dob, `MMMM dd yyyy`) : null}
        subtitleStyle={{ ...Typography.text(`small`, `gray`) }}
      />
      <Divider />
      <ListItem
        title="Share Birthday"
        titleStyle={{ ...Typography.text() }}
        rightElement={<Switch value={value} onValueChange={onValueChange} />}
        style={{ marginVertical: 10 }}
      />
      <Divider />
    </View>
  );
};
