// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, Alert, ActivityIndicator, ScrollView } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from 'react-apollo';
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import * as VideoThumbnails from 'expo-video-thumbnails';
import FastImage from 'react-native-fast-image';
import { Tooltip } from 'react-native-elements';
import { client, DELETE_INTRO_VIDEO, GET_INTRO_VIDEO, refetchQueriesFor, UPLOAD_INTRO_VIDEO } from '../graphql';
import { Colors, Spacing, Typography } from '../common-styles';
import { AppContext } from '../../AppContext';
import { parseIntroDataType, SettingItem } from '../common-types/types';
import { SettingScreensParamList } from '../common-types/navigation-types';
import { VideoPlayer } from '../post/components/VideoPlayer';
import useMediaPermissons from '../hooks/useMediaPermissions';
import { generateApolloFile, pickExpoVideo, pickImages } from '../common-util/FilePicker';
import { BlobBlue, BottomSheetModal, ListItem, MeshIcon, ThemedButton } from '../common-ui';
import { introVideoTooltipText, introVideoFormatErrorText, introVideoWeightErrorText, thumbnailFormatErrorText } from './helpers';

type IntroVideoSettingRoute = RouteProp<SettingScreensParamList, `IntroVideoSetting`>;
type IntroVideoSettingNavigation = StackNavigationProp<SettingScreensParamList, `IntroVideoSetting`>;

interface ModalContent {
  show?: boolean;
  type?: `removeVideo` | `removeImage` | `formatError`;
  title?: string;
  description?: string;
}

export const IntroVideoSettingItem: React.FC<SettingItem> = ({ setting }) => {
  const navigation = useNavigation<IntroVideoSettingNavigation>();
  const { user } = React.useContext(AppContext);

  const navigateToEditor = () => {
    if (!user && setting) return;
    navigation.push(`IntroVideoSetting`, {
      setting,
    });
  };
  return (
    <ListItem
      title="Intro Video"
      subtitle="Press to view and edit..."
      titleStyle={{ ...Typography.text(`gray`, `small`) }}
      subtitleStyle={{ ...Typography.text() }}
      rightElement={<MeshIcon focused={false} name="chevron-right" size={20} testID="ICON" />}
      onPress={navigateToEditor}
    />
  );
};

export const IntroVideoSetting = () => {
  const navigation = useNavigation<IntroVideoSettingNavigation>();
  const route = useRoute<IntroVideoSettingRoute>();
  const { setting } = route?.params || {};
  const { checkPermission } = useMediaPermissons();
  const width = Math.min(Spacing.standardWidth, Dimensions.get(`window`).width);

  const [videoToUpload, setVideoToUpload] = useState<ReactNativeFile[]>();
  const [customThumbnail, setCustomThumbnail] = useState<ReactNativeFile[]>();
  const [originalThumbnail, setOriginalThumbnail] = useState(``);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(``);
  const [showVideo, setShowVideo] = useState(false);
  const [modal, setModal] = useState<ModalContent>({
    show: false,
    type: ``,
    title: ``,
    description: ``,
  });
  const [parsedIntroData, setParsedIntroData] = useState<parseIntroDataType>({});

  const [uploadIntroVideo, { loading: loadingUpload }] = useMutation(UPLOAD_INTRO_VIDEO, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries: refetchQueriesFor(`Setting`, `Group`),
  });
  const [deleteIntroVideo] = useMutation(DELETE_INTRO_VIDEO, {
    onCompleted: client.reFetchObservableQueries,
    refetchQueries: refetchQueriesFor(`Setting`, `Group`),
  });

  const generateThumbnail = useCallback(async () => {
    try {
      if (parsedIntroData?.video_url) {
        const { uri } = await VideoThumbnails.getThumbnailAsync(parsedIntroData?.video_url, {
          quality: 0.9,
          time: 350,
        });
        setOriginalThumbnail(uri);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [parsedIntroData?.video_url]);

  useEffect(() => {
    generateThumbnail();
  }, [generateThumbnail]);

  useEffect(() => {
    if (setting?.value) setParsedIntroData(JSON.parse(setting?.value));
  }, [setting?.value]);

  useEffect(() => {
    navigation?.setOptions({
      headerRight: () => (
        <ThemedButton
          title="Save"
          clear
          onPress={handleSave}
          disabled={(!videoToUpload && !customThumbnail) || loading || loadingUpload}
          rightIcon={loadingUpload ? <ActivityIndicator /> : undefined}
        />
      ),
    });
  }, [loading, handleSave, navigation, videoToUpload, customThumbnail, loadingUpload]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (showVideo) setShowVideo(false);
      };
    }, [showVideo]),
  );

  const setVideoDisplayed = () => setShowVideo(true);

  const addVideo = useCallback(async () => {
    setLoading(true);
    try {
      const isGranted = checkPermission();
      if (!isGranted) return;

      await pickExpoVideo(
        ({ assets, didCancel, errorCode, errorMessage }) => {
          if (didCancel) {
            setProgress(``);
            return;
          }
          if (errorCode || errorMessage) {
            Alert.alert(errorMessage!);
            setProgress(``);
            return;
          }
          const [asset] = assets;

          //the video must be landscape
          if (asset.height >= asset.width) {
            setModal({
              show: true,
              title: `Whoops!`,
              description: introVideoFormatErrorText,
              type: `formatError`,
            });
            setProgress(``);
            return;
          }

          // 120 -> 2 minutes
          if (asset.duration < 120) {
            const urls = [];
            const video = generateApolloFile(asset);
            urls.push(video);
            if (asset.thumbnail) {
              const thumbnail = generateApolloFile({ uri: asset.thumbnail });
              urls.push(thumbnail);
            }
            setParsedIntroData({ ...parsedIntroData, video_url: ``, thumbnail_url: `` });
            setVideoToUpload(urls);
            setProgress(``);
          } else {
            setModal({
              show: true,
              title: `Whoops!`,
              description: introVideoWeightErrorText,
              type: `formatError`,
            });
            setProgress(``);
          }
        },
        (current_progress) => {
          const percent = `${current_progress}%`;
          if (percent !== progress) setProgress(percent);
        },
      );
    } catch (error) {
      console.error(`Error while adding video:`, error);
      setModal({
        show: true,
        title: `Whoops!`,
        description: `An error ocurred trying to upload an intro video, please try again`,
        type: `formatError`,
      });
    } finally {
      setLoading(false);
    }
  }, [checkPermission, progress, parsedIntroData]);

  const addThumbnail = useCallback(async () => {
    try {
      const isGranted = await checkPermission();
      if (!isGranted) return;

      pickImages(({ assets, didCancel, errorCode, errorMessage }) => {
        if (didCancel) {
          setProgress(``);
          return;
        }
        if (errorCode || errorMessage) {
          Alert.alert(`Error`, errorMessage);
          setProgress(``);
          return;
        }
        const [asset] = assets || [];
        if (asset) {
          //the image must be landscape
          if (asset.height >= asset.width) {
            setModal({
              show: true,
              title: `Whoops!`,
              description: thumbnailFormatErrorText,
              type: `formatError`,
            });
            setProgress(``);
            return;
          }

          const uploadFile = new ReactNativeFile({
            uri: asset?.uri || ``,
            type: asset.type,
            name: uuidv4(),
          });
          setCustomThumbnail(uploadFile);
        }
      });
    } catch (error) {
      console.error(`Error while adding photo:`, error);
    } finally {
      setLoading(false);
    }
  }, [checkPermission]);

  const handleSave = useCallback(async () => {
    try {
      if (!videoToUpload && !parsedIntroData) return null;
      let curatedFile = [];
      if (videoToUpload && customThumbnail) {
        curatedFile.push(videoToUpload[0]); //this is the video media data
        curatedFile.push(customThumbnail); //this is the thumbnail that the user chose
      }

      if (videoToUpload && !customThumbnail) {
        // it means that the user haven't picked a thumbnail so we are going to send the default thumbnail to the server
        // the thumbnail is stored in the second position of the videoToUpload array, that's why we send the original array here
        curatedFile = videoToUpload;
      }

      if (parsedIntroData?.video_url && customThumbnail) {
        // it means the community already has a video and has selected a custom thumbnail
        curatedFile.push(parsedIntroData?.video_url);
        curatedFile.push(customThumbnail);
      }

      const input = {
        group_id: setting?.group_id,
        file: curatedFile,
      };

      const { data } = await uploadIntroVideo({
        variables: { input },
      });
      if (data?.uploadIntroVideo) navigation.goBack();
      else
        setModal({
          show: true,
          title: `Whoops!`,
          description: `An error ocurred trying to upload an intro video, please try again`,
          type: `formatError`,
        });
    } catch (error) {
      console.error(`An error ocurred in the handleSave function - IntroVideoSetting.tsx `, error);
    }
    return null;
  }, [videoToUpload, customThumbnail, uploadIntroVideo, setting, navigation, parsedIntroData]);

  // The porpuse of this function is to call the setModal state changer once only and then avoid the "many re-renders error"
  const handleRemoveMedia = (targetMedia: string) => {
    let show;
    let title;
    let description;
    let type;

    if (targetMedia === `video`) {
      show = true;
      title = `Are you sure`;
      description = `Do you want to remove the community's intro video? This action cannot be undone.`;
      type = `removeVideo`;
    } else {
      show = true;
      title = `Are you sure`;
      description = `Do you want to remove the community's thumbnail? This action cannot be undone.`;
      type = `removeImage`;
    }

    setModal({ show, title, description, type });
  };

  const handleDeleteIntroVideo = async () => {
    try {
      if (parsedIntroData?.video_url) {
        const { data } = await deleteIntroVideo({
          variables: { group_id: setting?.group_id },
          refetchQueries: [{ query: GET_INTRO_VIDEO, variables: { group_id: setting?.group_id } }],
        });
        if (data?.deleteIntroVideo2) {
          setVideoToUpload(undefined);
          setParsedIntroData({ ...parsedIntroData, video_url: ``, thumbnail_url: `` });
          setModal({ ...modal, show: false });
          navigation.goBack();
        }
      } else {
        setVideoToUpload(undefined);
        setParsedIntroData({ ...parsedIntroData, video_url: ``, thumbnail_url: `` });
        setModal({ ...modal, show: false });
      }
    } catch (error) {
      console.error(`An error ocurred trying to delete the intro video`);
      Alert.alert(`An unexpected error ocurred trying to remove the intro video`);
    }
  };

  const renderHeaderText = useCallback((targetMedia: string) => {
    return (
      <View style={styles.headerContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.headerText}>
            {targetMedia === `video`
              ? `Upload a landscape video of up to 2 minutes in length to introduce your node to prospective members`
              : `Upload a custom thumbnail for your intro video. (Optional)`}
          </Text>
        </View>

        {targetMedia === `video` ? (
          <Tooltip
            popover={<Text style={{ ...Typography.text(`white`) }}>{introVideoTooltipText}</Text>}
            backgroundColor={Colors.brandPurple}
            height={150}
            width={300}
          >
            <MeshIcon name="info-circle" color={Colors.brandPurple} size={24} />
          </Tooltip>
        ) : undefined}
      </View>
    );
  }, []);

  const renderButtons = useCallback(
    (targetMedia: string) => {
      return (
        <View style={styles.buttonsContainer}>
          <ThemedButton
            rounded
            title="Upload"
            titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`) }}
            containerStyle={{}}
            buttonStyle={styles.submit}
            onPress={targetMedia === `video` ? addVideo : addThumbnail}
            rightIcon={loading ? <ActivityIndicator style={{ marginLeft: 5 }} /> : null}
            disabled={loading}
          />
          <ThemedButton
            rounded
            title="Remove"
            onPress={() => handleRemoveMedia(targetMedia)}
            containerStyle={{}}
            buttonStyle={styles.remove}
            titleStyle={{ ...Typography.text(`plustwo`, `bold`, `theme`) }}
            disabled={
              (targetMedia === `video` && !videoToUpload && !parsedIntroData?.video_url) ||
              (targetMedia === `image` && !customThumbnail)
            }
          />
        </View>
      );
    },
    [addVideo, loading, addThumbnail, videoToUpload, customThumbnail, parsedIntroData],
  );

  const renderVideoOrImagePlaceholder = useCallback(
    (targetMedia: string) => {
      const videoDim = { width, height: (width * 9) / 16 };
      return (
        <View style={{ ...videoDim, ...styles.videoPlaceholder }}>
          <BlobBlue width={80} height={80} style={styles.blobStyle} />
          <MeshIcon name={targetMedia === `video` ? `video` : `image`} size={64} color={Colors.brandPurple} />
        </View>
      );
    },
    [width],
  );

  const renderVideoComponent = useCallback(
    (videoUrlToRender: string, videoThumbnailUrlToRender: string) => {
      const videoDim = { width, height: (width * 9) / 16 };
      if (showVideo) {
        return (
          <VideoPlayer width={width} height={(width * 9) / 16} video_url={videoUrlToRender} onScreen={true} onLayout={() => {}} />
        );
      }
      return (
        <View style={{ position: `relative`, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
          <FastImage
            style={{ ...videoDim, backgroundColor: Colors.black }}
            source={{ uri: videoThumbnailUrlToRender }}
            resizeMode="cover"
          />
          <View style={{ position: `absolute` }}>
            <MeshIcon
              name="play-circle"
              size={62}
              color={Colors.white}
              onPress={setVideoDisplayed}
              style={{ backgroundColor: Colors.black, borderRadius: 31, overflow: `hidden` }}
            />
          </View>
        </View>
      );
    },
    [showVideo, width],
  );

  const renderVideo = useCallback(() => {
    let videoUrlToRender = ``;
    let videoThumbnailUrlToRender = ``;

    if (parsedIntroData?.video_url) {
      videoUrlToRender = parsedIntroData?.video_url;
      if (originalThumbnail) {
        videoThumbnailUrlToRender = originalThumbnail;
      }
      return renderVideoComponent(videoUrlToRender, videoThumbnailUrlToRender);
    }

    if (videoToUpload) {
      const [videoMedia] = videoToUpload;
      videoUrlToRender = videoMedia?.uri;
      videoThumbnailUrlToRender = videoToUpload[1]?.uri;
      return renderVideoComponent(videoUrlToRender, videoThumbnailUrlToRender);
    }

    return renderVideoOrImagePlaceholder(`video`);
  }, [parsedIntroData, videoToUpload, renderVideoComponent, renderVideoOrImagePlaceholder, originalThumbnail]);

  const renderCustomThumbnail = useCallback(() => {
    const imageDim = { width, height: (width * 9) / 16 };
    let thumbnail = ``;

    if (parsedIntroData?.thumbnail_url) thumbnail = parsedIntroData?.thumbnail_url;
    if (customThumbnail) thumbnail = customThumbnail?.uri;
    if (!customThumbnail && !parsedIntroData?.thumbnail_url) return renderVideoOrImagePlaceholder(`image`);

    return <FastImage source={{ uri: thumbnail }} resizeMode="cover" style={{ ...imageDim, backgroundColor: Colors.black }} />;
  }, [customThumbnail, renderVideoOrImagePlaceholder, width, parsedIntroData?.thumbnail_url]);

  return (
    <ScrollView bounces={false}>
      {renderHeaderText(`video`)}
      {renderVideo()}
      {renderButtons(`video`)}
      {loading ? (
        <View style={{ justifyContent: `center`, alignItems: `center`, flexDirection: `row` }}>
          <Text style={styles.progressText}>{progress}</Text>
        </View>
      ) : undefined}
      {videoToUpload || parsedIntroData?.video_url ? (
        <>
          {renderHeaderText(`image`)}
          {renderCustomThumbnail()}
          {renderButtons(`image`)}
        </>
      ) : undefined}
      <BottomSheetModal
        title={modal?.title}
        visible={modal?.show}
        showCancelBtn={false}
        showConfirmBtn={false}
        onPressCancel={() => setModal({ ...modal, show: false })}
        onPressConfirm={() => {}}
      >
        <View style={{ flexDirection: `column` }}>
          <Text style={styles.modalDesc}>{modal?.description}</Text>

          {modal?.type?.includes(`remove`) ? (
            <>
              <ThemedButton
                rounded
                title="Remove"
                onPress={
                  modal?.type === `removeVideo`
                    ? () => handleDeleteIntroVideo()
                    : () => {
                        setCustomThumbnail(undefined);
                        setModal({ ...modal, show: false });
                      }
                }
                buttonStyle={{ padding: 15 }}
                containerStyle={{ marginTop: 12, marginHorizontal: 16 }}
                titleStyle={{ ...Typography.text(`plustwo`, `white`, `bold`), marginLeft: 4 }}
              />
              <ThemedButton
                rounded
                title="Cancel"
                onPress={() => setModal({ ...modal, show: false })}
                buttonStyle={{ padding: 15, backgroundColor: Colors.white, borderWidth: 1, borderColor: Colors.brandPurple }}
                containerStyle={{ marginTop: 12, marginBottom: 20, marginHorizontal: 16 }}
                titleStyle={{ ...Typography.text(`plustwo`, `theme`, `bold`), marginLeft: 4 }}
              />
            </>
          ) : (
            <ThemedButton
              rounded
              title="OK"
              onPress={() => setModal({ ...modal, show: false })}
              containerStyle={styles.okButtonContainer}
              buttonStyle={styles.okButton}
              titleStyle={{
                ...Typography.text(`plustwo`, `bold`),
                marginLeft: 4,
                color: Colors?.brandPurple,
              }}
            />
          )}
        </View>
      </BottomSheetModal>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    marginTop: 16,
    marginBottom: 16,
    marginHorizontal: 25,
  },
  headerText: {
    ...Typography.text(`small`, `gray`),
    paddingHorizontal: 10,
  },
  videoPlaceholder: {
    backgroundColor: Colors.placeholderVideoColor,
    justifyContent: `center`,
    alignItems: `center`,
  },
  blobStyle: {
    position: `absolute`,
    left: 160,
    top: 75,
    zIndex: -1,
  },
  buttonsContainer: {
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-end`,
    marginHorizontal: 16,
  },
  submit: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    margin: 16,
  },
  remove: {
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
  },
  okButtonContainer: {
    marginTop: 12,
    marginBottom: 20,
    marginHorizontal: 16,
  },
  okButton: {
    padding: 15,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.brandPurple,
  },
  modalDesc: {
    ...Typography.text(`center`, `base`),
    marginHorizontal: 22,
    marginVertical: 16,
  },
  progressText: { minWidth: 60, padding: 10, ...Typography.text(`plustwo`, `bold`, `darkGray`, `center`) },
});
