import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';
import { View, Text, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { Typography } from '../../common-styles';
import { GroupMember } from '../../common-types/types';
import { FloatingButton } from '../../common-ui/Button';
import { loadCachedGroupMember } from '../../graphql';
import { RootState } from '../../redux/store';
import { AnimatedHeader } from './AnimatedHeader';
import { Carousel } from './Carousel';
import { CommunityFeed } from './CommunityFeed';
import { CommunityHeader } from './CommunityHeader';
import { useCommunities } from './hooks/useCommunities';
import { useCommunityHeader } from './hooks/useCommunityHeader';
import { useHideHeader } from './hooks/useHideHeader';
import { HOMESCREEN_TAG } from '../testLabels';
import { BottomSheetModal } from '../../common-ui/BottomSheetModal';
import { setShowThanksModal } from '../../redux/contributionSlice';
import { StarImage } from '../../images/StarImage';
import { setTopic } from '../../redux/feedSlice';
import { VersionUpdateModal } from '../../update/VersionUpdateModal';
import AgreeToCoCBottomSheet from '../../common-ui/AgreeToCoCBottomSheet';
import { HomeNavigator } from '../../common-types/navigation-types';
import { NavigationHeader, NavigationHeaderRight, NavigationHeaderTitle } from './NavigationHeader';

export const HomeScreen = () => {
  const dispatch = useDispatch();
  const headerHeight = useHeaderHeight();
  const [nativeHeaderHeight] = useState(headerHeight);

  const navigation = useNavigation<HomeNavigator>();
  const { user } = useContext(AppContext);
  const { showThanksModal } = useSelector((state: RootState) => state.contributions);
  const { openItems } = useSelector((state: RootState) => state.feed);
  const { activeCommunity, loading, communities, setCommunity, error, refetch, refreshCarouselItems } = useCommunities();
  const [groupMember, setGroupMember] = useState<GroupMember>();
  const [showAgreeToCoCModal, setShowAgreeToCoCModal] = useState(false);
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const { handleScroll, translateY } = useHideHeader(activeCommunity, { nativeHeader: nativeHeaderHeight });
  const isFocused = useIsFocused();

  const headerProps = useCommunityHeader({
    mode: `home`,
    groupMember,
    onChangeMember: setGroupMember,
    onChangeGroup: setCommunity,
    refreshCarouselItems,
  });

  const handleGoToCreatePost = useCallback(() => {
    if (activeCommunity?.id && user?.id) {
      const gm: GroupMember | undefined = loadCachedGroupMember(activeCommunity.id, user.id);
      setGroupMember(gm);
      if (!gm?.has_agreed && gm?.role_name === `member`) {
        setShowAgreeToCoCModal(true);
      } else if (activeCommunity) {
        navigation.navigate(`PostEditor`, { group: activeCommunity, mode: `create` });
      }
    }
    // reason: activeCommunity?.id is enough for memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, activeCommunity?.id, user?.id]);

  // Effect #1: wait for loading to finish so that loadCachedGroupMember doesn't fail;
  // optimized - 1 call & 1 skip on startup, 1 skip on community change
  useEffect(() => {
    if (!loading && activeCommunity?.id && user?.id) {
      const gm: GroupMember | undefined = loadCachedGroupMember(activeCommunity.id, user.id);
      if (gm?.id !== groupMember?.id || gm?.role_id !== groupMember?.role_id) {
        setGroupMember(gm);
      }
    }
  }, [activeCommunity?.id, user?.id, groupMember, loading]);

  //Effect #2: display create post
  // optimized - 1 call on startup, 1 call at most on community change
  useEffect(() => {
    const needsToAgree = !groupMember?.has_agreed && groupMember?.role_name === `member`;
    const displayPostBtn = !!groupMember?.can_post || needsToAgree;
    if (showFloatingButton !== displayPostBtn) {
      setShowFloatingButton(displayPostBtn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMember?.has_agreed, groupMember?.role_name, groupMember?.can_post]);

  //Effect #3: default header funtionality
  // optimized - 2 call on startup, 0 calls on community change
  useEffect(() => {
    navigation?.setOptions(
      Platform.select({
        ios: {
          header: () => <NavigationHeader collapsed={false} openItems={openItems} nativeHeader={nativeHeaderHeight} />,
        },
        android: {
          headerRight: () => <NavigationHeaderRight collapsed={false} openItems={openItems} />,
          headerTitle: () => <NavigationHeaderTitle collapsed={false} />,
        },
        default: {}, // mesh doesn't work on any other platform
      }),
    );
  }, [navigation, openItems, nativeHeaderHeight]);

  //Effect #4: clear selected topic from shared feed component
  useEffect(() => {
    if (isFocused) {
      dispatch(setTopic(null));
    }
  }, [dispatch, isFocused]);

  return (
    <View style={{ flex: 1, overflow: `hidden` }} testID={HOMESCREEN_TAG}>
      <AnimatedHeader animatedValue={translateY}>
        <Carousel
          activeItem={activeCommunity || null}
          communities={communities}
          isLoading={loading}
          onCommunityPress={setCommunity}
          error={error}
        />
        <CommunityHeader roleId={groupMember?.role_id} headerProps={headerProps} />
      </AnimatedHeader>

      <CommunityFeed
        onPullRefresh={refetch}
        onScroll={handleScroll}
        groupMember={groupMember}
        group={activeCommunity}
        feedContainerStyle={{ paddingTop: activeCommunity?.sponsor?.id ? 265 : 250 }}
      />

      {showFloatingButton && !user?.isBanned ? <FloatingButton onPress={handleGoToCreatePost} /> : null}

      <AgreeToCoCBottomSheet
        visible={showAgreeToCoCModal}
        onPressCancel={() => setShowAgreeToCoCModal(false)}
        navigation={navigation}
        activeCommunity={activeCommunity}
        groupMember={groupMember}
      />

      <BottomSheetModal
        title=""
        showCancelBtn={false}
        visible={showThanksModal}
        onPressCancel={() => dispatch(setShowThanksModal(false))}
        confirmTitle="Close"
        onPressConfirm={() => dispatch(setShowThanksModal(false))}
      >
        <View>
          <View style={{ justifyContent: `center`, flexDirection: `row` }}>
            <StarImage />
          </View>
          <Text style={{ marginTop: 26, marginHorizontal: 18, ...Typography.text(`center`, `bold`, `large`, `black`) }}>
            Thank you for tipping!
          </Text>
          <Text style={{ marginTop: 9, marginHorizontal: 54, ...Typography.text(`center`, `base`) }}>
            We just emailed you a receipt. Your tip helps your community grow.
          </Text>
        </View>
      </BottomSheetModal>
      <VersionUpdateModal />
    </View>
  );
};
