import * as React from 'react';
import Svg, { Defs, Ellipse, Path, G, Rect, Text, TSpan, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const ExploreScience = (props) => {
  return (
    <Svg width="163px" height="98px" viewBox="0 0 163 98" {...props}>
      <Defs>
        <Ellipse id="a" cx={14.1420578} cy={14.1042053} rx={14.1420578} ry={14.1042053} />
        <Ellipse id="c" cx={14.1420578} cy={14.1042053} rx={14.1420578} ry={14.1042053} />
        <Ellipse id="e" cx={14.1420578} cy={14.1042053} rx={14.1420578} ry={14.1042053} />
        <Path d="M16.183.006a6.576 6.576 0 00-4.329 1.877l-.354.348-.355-.348c-2.55-2.51-6.683-2.51-9.233 0a6.356 6.356 0 000 9.089l8.878 8.738a1.016 1.016 0 001.42 0l8.877-8.738A6.375 6.375 0 0023 6.427c0-1.704-.688-3.34-1.913-4.544A6.58 6.58 0 0016.471 0l-.288.006zm.288 1.971c1.199 0 2.348.469 3.196 1.304a4.414 4.414 0 011.325 3.146c0 1.18-.477 2.312-1.325 3.147L11.5 17.613l-8.168-8.04a4.4 4.4 0 010-6.292 4.571 4.571 0 016.393 0l1.065 1.048a1.016 1.016 0 001.42 0l1.064-1.048a4.556 4.556 0 013.197-1.304z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H1300V7847H0z" />
        <Rect fill="#E5F8FA" x={0} y={0} width={163} height={98} rx={7} />
        <Text fontSize={12} fontWeight={500} letterSpacing={-0.14} fill="#2A98A5">
          <TSpan x={12} y={22}>
            Science
          </TSpan>
        </Text>
        <Path
          d="M129.437 56.447c5.845 5.452 12.372 9.715 14.078 15.377 1.75 5.696-1.365 12.756-7.21 16.984-5.886 4.194-14.504 5.487-22.737 5.138-8.19-.385-15.955-2.412-20.647-6.605-4.693-4.194-6.357-10.555-7.338-17.719-.98-7.13-1.237-15.097 3.456-20.514 4.692-5.452 14.419-8.387 21.841-6.57 7.466 1.852 12.67 8.457 18.557 13.909z"
          fill="#2A98A5"
          fillRule="nonzero"
        />
        <G fillRule="nonzero">
          <G transform="translate(62 17) translate(59 40) translate(.74) translate(6.683 16.329)">
            <Path fill="#DCD2FA" d="M1.10397735 2.85473113H10.22201247V5.22007978H1.10397735z" />
            <Rect fill="#DCD2FA" x={0.0613320748} y={4.30248762} width={11.1624376} height={12.7239444} rx={5.5812188} />
            <Path fill="#DCD2FA" d="M0 0H11.2851018V3.54802297H0z" />
            <Path fill="#8965F6" d="M0.12266415 7.66699217H11.20332565V14.477565H0.12266415z" />
          </G>
          <G transform="translate(62 17) translate(59 40) translate(.74) translate(0 20.325)">
            <Path fill="#D2E7D6" d="M1.0630893 1.10111058H4.90656599V3.09942237H1.0630893z" />
            <Rect fill="#D2E7D6" x={0.0613320748} y={2.30417584} width={5.84699113} height={10.7256327} rx={2.92349556} />
            <Path fill="#D2E7D6" d="M0 0H5.96965528V1.63127493H0z" />
            <Path fill="#52C866" d="M0.0817760997 5.13851603H5.8878791797V10.92954203H0.0817760997z" />
          </G>
          <G transform="translate(62 17) translate(59 40) translate(.74) translate(14.5)">
            <Path
              d="M11.74 2H6.26v15.478a8.52 8.52 0 00-4.185 3.113A8.438 8.438 0 00.5 25.514c0 2.343.951 4.465 2.49 6A8.48 8.48 0 009 34a8.48 8.48 0 006.01-2.486 8.453 8.453 0 002.49-6 8.438 8.438 0 00-1.575-4.923 8.52 8.52 0 00-4.185-3.113V2z"
              fill="#E1E4F8"
            />
            <Path
              d="M9 32c3.59 0 6.5-3.015 6.5-6.734 0-1.725-.876 1.192-1.906 0-1.19-1.377-2.67 1.264-4.594 1.264-1.858 0-4.17-1.731-4.718-2.136-.548-.404-1.782-.922-1.782.872C2.5 28.986 5.41 32 9 32z"
              fill="#2945FF"
            />
            <Path fill="#E1E4F8" d="M4.5 0H13.5V2H4.5z" />
          </G>
        </G>
        <G transform="translate(62 17) translate(0 37) rotate(45 11.795 29.405)" fillRule="nonzero">
          <Path
            d="M6.991 27.189a2.945 2.945 0 012.936-2.955 2.945 2.945 0 012.936 2.955c0 1.631-2.936 5.17-2.936 5.17s-2.936-3.539-2.936-5.17z"
            fill="#CC471D"
          />
          <Path
            d="M8.405 27.48c0-.803.682-1.454 1.522-1.454s1.522.651 1.522 1.454c0 .802-1.522 2.18-1.522 2.18s-1.522-1.378-1.522-2.18z"
            fill="#E4D125"
          />
          <Path
            d="M6.725 27.258c-1.132 0-2.05-.376-2.05-1.585V7.688c0-.478 1.477-2.858 4.43-7.142a1.099 1.099 0 011.8-.001c2.937 4.255 4.413 6.613 4.426 7.074l.001.069v17.985c0 1.189-.886 1.552-1.992 1.584l-.057.001H6.725z"
            fill="#C383F5"
          />
          <Path
            d="M15.41 24.96v.609c0 1.146-.888 1.497-1.993 1.528H6.802c-1.132 0-2.05-.362-2.05-1.528v-.61H15.41z"
            fill="#8F3ED8"
          />
          <Path d="M.185 23.108c1.136-2.91 2.761-5.326 4.567-7.56v8.284L.185 25.17v-2.062z" fill="#8134C8" />
          <Path
            d="M15.256 23.108c1.136-2.91 2.76-5.326 4.567-7.56v8.284l-4.567 1.338v-2.062z"
            fill="#8134C8"
            transform="matrix(-1 0 0 1 35.078 0)"
          />
          <Ellipse fill="#7B34BB" cx={10.0039792} cy={10.6822509} rx={2.82721151} ry={2.77972021} />
          <Ellipse fill="#551D89" cx={10.0039792} cy={10.8334249} rx={2.06603918} ry={2.031334} />
        </G>
        <Ellipse
          cx={14.1420578}
          cy={14.1042053}
          rx={14.1420578}
          ry={14.1042053}
          fill="#613CAB"
          fillRule="nonzero"
          transform="translate(62 17) translate(20 .486) translate(0 .514) rotate(10 1.634 29.494) translate(12.486)"
        />
        <G transform="translate(62 17) translate(20 .486) translate(0 .514) rotate(10 1.634 29.494) translate(12.486)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Path
            d="M34.58 17.027c.05.21.074.421.074.635 0 4.14-9.297 7.497-20.767 7.497-11.47 0-20.767-3.357-20.767-7.497 0-.214.025-.426.074-.635.893 3.843 9.817 6.861 20.693 6.861 10.781 0 19.644-2.966 20.669-6.76l.025-.101z"
            fill="#155EC2"
            fillRule="nonzero"
            mask="url(#b)"
          />
        </G>
        <G transform="translate(62 17) translate(20 .486) translate(0 .514) rotate(10 1.634 29.494) translate(12.486)">
          <Mask id="d" fill="#fff">
            <Use xlinkHref="#c" />
          </Mask>
          <Path
            d="M43.681 4.574c.097.335.147.674.147 1.017 0 5.614-13.291 10.165-29.686 10.165s-29.686-4.551-29.686-10.165c0-.343.05-.682.147-1.017 1.49 5.137 14.146 9.149 29.54 9.149 15.246 0 27.809-3.936 29.493-9.003l.045-.146z"
            fill="#1A6FE4"
            fillRule="nonzero"
            mask="url(#d)"
          />
        </G>
        <G transform="translate(62 17) translate(20 .486) translate(0 .514) rotate(10 1.634 29.494) translate(12.486)">
          <Mask id="f" fill="#fff">
            <Use xlinkHref="#e" />
          </Mask>
          <Path
            d="M36.105 2.287c.052.21.078.422.078.635 0 4.14-9.925 7.497-22.168 7.497-12.244 0-22.169-3.356-22.169-7.497 0-.213.027-.425.078-.635.954 3.843 10.48 6.862 22.09 6.862 11.51 0 20.97-2.966 22.064-6.76l.027-.102z"
            fill="#1A6FE4"
            fillRule="nonzero"
            mask="url(#f)"
          />
        </G>
        <Path
          d="M53.256 15.248c0 3.93-11.922 7.115-26.628 7.115S0 19.178 0 15.248c0-2.645 5.4-4.953 13.414-6.18a13.999 13.999 0 00-.504 1.593c-2.956.676-4.756 1.572-4.756 2.554 0 2.105 8.271 3.812 18.474 3.812s18.474-1.707 18.474-3.812c0-.982-1.8-1.878-4.756-2.554a13.956 13.956 0 00-.504-1.592c8.014 1.226 13.414 3.534 13.414 6.179z"
          fill="#E09E3B"
          fillRule="nonzero"
          transform="translate(62 17) translate(20 .486) translate(0 .514) rotate(10 1.634 29.494)"
        />
      </G>
    </Svg>
  );
};
